import { Box, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { ScaleLoaderComponent } from '../../../../../shared/components/Common/ScaleLoaderComponent';
import { ProductionSiteCandidateToAdd } from '../../services/dataContracts/controller/ProductionSiteCandidateToAdd';
import { SearchProductionSiteStepComponent } from './SearchProductionSiteStepComponent';
import { SelectProductionSiteStepComponent } from './SelectProductionSiteStepComponent';

interface AddProductionSiteDialogComponentProperties {
    activeStep: number,
    productionSiteSearchLoading: boolean,
    productionSiteId: string,
    productionSiteChoices: ProductionSiteCandidateToAdd,
    handleProductionSiteIdentifierChange: (value: string) => void,
    searchProductionSiteByIdentifier: (agencyId: string) => void,
    handleSearchInReferentialGrid: (agencyId: string) => void,
    handlePreviousStep: () => void,
    confirmAddProductionSite: (isLogisticZone: boolean) => void,
    role: string
}

export const AddProductionSiteDialogComponent = (props: AddProductionSiteDialogComponentProperties): JSX.Element => {

    const steps: string[] = ["Chercher", "Confirmer"];

    const getStepContent = (stepIndex: number): JSX.Element => {
        switch (stepIndex) {
            case 0:
                return <SearchProductionSiteStepComponent
                    productionSiteSearchLoading={props.productionSiteSearchLoading}
                    productionSiteId={props.productionSiteId}
                    handleProductionSiteIdentifierChange={props.handleProductionSiteIdentifierChange}
                    searchProductionSiteByIdentifier={props.searchProductionSiteByIdentifier}
                />;
            case 1:
                return <SelectProductionSiteStepComponent
                    productionSiteChoices={props.productionSiteChoices}
                    handleSearchInReferentialGrid={props.handleSearchInReferentialGrid}
                    confirmAddProductionSite={props.confirmAddProductionSite}
                    handlePreviousStep={props.handlePreviousStep}
                    role={props.role}
                />;
            default:
                return <></>;
        }
    }

    return (
        <Box className="add-production-site-component">
            <Box display="flex" flexDirection="row" justifyContent="center" className="loading-content">
                <ScaleLoaderComponent loading={props.productionSiteSearchLoading} />
            </Box>
            <Stepper activeStep={props.activeStep} alternativeLabel>
                {steps.map((label: string) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box>{getStepContent(props.activeStep)}</Box>
        </Box>
    );
}