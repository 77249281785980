import axios, { AxiosResponse } from "axios";
import { WebAppActionResult } from "src/shared/models/WebAppActionResult";
import { AddOverheadExpenseRequestArgs } from "./dataContracts/controller/AddOverheadExpenseRequestArgs";
import { OverheadExpenseItemRequestArgs } from "./dataContracts/controller/OverheadExpenseItemRequestArgs";
import { RemoveOverheadExpensesRequestArgs } from "./dataContracts/controller/RemoveOverheadExpensesRequestArgs";
import { OverheadExpenseLightModel } from "./dataContracts/queryStack/OverheadExpenseLightModel";

const controllerUrl = 'api/OverheadExpensesReferential/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export class OverheadExpensesReferentialApiClient {
    public static GetOverheadExpenses = (logisticsUnits: Array<string>, inputSearchText: string)
        : Promise<AxiosResponse<Array<OverheadExpenseLightModel>>> => {
        return axios.post(`${controllerUrl}GetOverheadExpenses?searchText=${encodeURIComponent(inputSearchText)}`,
            JSON.stringify(logisticsUnits), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
    }

    public static RemoveOverheadExpenses = (overheadExpenses: Array<OverheadExpenseItemRequestArgs>)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const requestArgs: RemoveOverheadExpensesRequestArgs = {
            overheadExpenses: overheadExpenses
        }
        const url = `${controllerUrl}RemoveOverheadExpenses`;
        return axios.post(url, requestArgs, { headers: headers });
    }

    public static AddOverheadExpense = (requestArgs: AddOverheadExpenseRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const url = controllerUrl + "AddOverheadExpense";
        return axios.post(url, requestArgs, { headers: headers });
    }
}