import axios, { AxiosResponse } from 'axios';
import { DeliveryTripStatus } from '../../../shared/models/DeliveryTripStatus';
import { PagedResult } from '../../../shared/models/PagedResult';
import { WebAppActionResult } from '../../../shared/models/WebAppActionResult';
import { DeliveryTripLightModelExtended } from '../models/DeliveryTripLightModelExtended';
import { TransportFlowLightModelExtended } from '../models/TransportFlowLightModelExtended';
import { TransportRequestLightModelExtended } from '../models/TransportRequestLightModelExtended';
import { DeliveryTripChangeRequestArgs } from './dataContracts/controller/DeliveryTripChangeRequestArgs';
import { DeliveryTripLongIdentifierRequestArgs } from './dataContracts/controller/DeliveryTripLongIdentifierRequestArgs';
import { TransportFlowChangeRequestArgs } from './dataContracts/controller/TransportFlowChangeRequestArgs';
import { TransportFlowAttachmentFileLightModel } from './dataContracts/queryStack/TransportFlowAttachmentFileLightModel';
import { TransportFlowComplementaryDetailsLightModel } from './dataContracts/queryStack/TransportFlowComplementaryDetailsLightModel';
import { TransportRequestComplementaryDetailsLightModel } from './dataContracts/queryStack/TransportRequestComplementaryDetailsLightModel';
import { VehicleTypeLightModel } from './dataContracts/queryStack/VehicleTypeLightModel';

const controllerUrl = "api/TransportPlan/";

export class TransportPlanApiClient {
    //TODO SO '=null' à virer qaund je bosserai sur l'ecran cartography
    public static FindTransportRequests = (pageSize: number, pageIndex: number, searchText: string, fromTime: Date, toTime: Date, logisticsUnits: Array<string> = null, transportRequestId = '')
        : Promise<AxiosResponse<PagedResult<TransportRequestLightModelExtended>>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const url = controllerUrl + "GetTransportRequests?searchText=" + encodeURIComponent(searchText) + "&pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&fromTime=" + encodeURIComponent(fromTime.toJSON()) + "&toTime=" + encodeURIComponent(toTime.toJSON()) + "&transportRequestId=" + encodeURIComponent(transportRequestId);
        return axios.post(url, logisticsUnits, { headers: _headers });
    }

    public static FindTransportFlow = (pageSize: number, pageIndex: number, searchTextTransportReq: string, searchText: string, transportRequestId: string, fromTime: Date, toTime: Date, logisticsUnits: Array<string> = null)
        : Promise<AxiosResponse<PagedResult<TransportFlowLightModelExtended>>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const url = controllerUrl + "GetTransportFlows?searchText=" + encodeURIComponent(searchText) + "&searchTransportRequest=" + encodeURIComponent(searchTextTransportReq) + "&pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&transportRequestId=" + encodeURIComponent(transportRequestId) + "&fromTime=" + encodeURIComponent(fromTime.toJSON()) + "&toTime=" + encodeURIComponent(toTime.toJSON());
        return axios.post(url, logisticsUnits, { headers: _headers });
    }

    public static GetVehicleTypes()
        : Promise<AxiosResponse<Array<VehicleTypeLightModel>>> {
        return axios.get(`${controllerUrl}GetVehicleTypes`);
    }
    
    public static GetTransportFlowsByIds = (transportFlowIds: Array<string>, fromTime: Date, toTime: Date)
        : Promise<AxiosResponse<Array<TransportFlowLightModelExtended>>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const url = controllerUrl + "GetTransportFlowsByIds?fromTime=" + encodeURIComponent(fromTime.toJSON()) + "&toTime=" + encodeURIComponent(toTime.toJSON());
        return axios.post(url, transportFlowIds, { headers: _headers });
    }

    public static GetTransportRequestComplementaryDetailsById = (transportRequestId: string)
        : Promise<AxiosResponse<TransportRequestComplementaryDetailsLightModel>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + "GetTransportRequestComplementaryDetailsById?transportRequestId=" + encodeURIComponent(transportRequestId),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetTransportFlowComplementaryDetailsById = (transportFlowId: string)
        : Promise<AxiosResponse<TransportFlowComplementaryDetailsLightModel>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + "GetTransportFlowComplementaryDetailsById?transportFlowId=" + encodeURIComponent(transportFlowId),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetTransportFlowComplementaryDetailsAndAttachmentFileNamesId(transportFlowId: string)
        : Promise<[AxiosResponse<TransportFlowComplementaryDetailsLightModel>, AxiosResponse<Array<TransportFlowAttachmentFileLightModel>>]> {
        return Promise.all(
            [
                this.GetTransportFlowComplementaryDetailsById(transportFlowId),
                this.GetAttachmentFileNames(transportFlowId)
            ]);
    }

    public static RemoveTransportFlow = (transportFlowId: string, transportRequestId: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(controllerUrl + "RemoveTransportFlow?transportFlowId=" + encodeURIComponent(transportFlowId) + "&transportRequestId=" + encodeURIComponent(transportRequestId));
    }

    public static FindTransportRequestWithFlowAndVehicleTypes(pageSize: number, pageIndex: number, searchTrip: string, transportRequestId: string, fromTime: Date, toTime: Date, searchTextTransportReq: string, searchTextDTFlow: string, logisticsUnits: Array<string>, shouldRefreshVehicleTypes: boolean)
        : Promise<[AxiosResponse<PagedResult<TransportRequestLightModelExtended>>, AxiosResponse<PagedResult<TransportFlowLightModelExtended>>, AxiosResponse<Array<VehicleTypeLightModel>>]> {
        return Promise.all(
            [
                this.FindTransportRequests(pageSize, pageIndex, searchTextTransportReq, fromTime, toTime, logisticsUnits),
                this.FindTransportFlow(pageSize, pageIndex, searchTextTransportReq, searchTextDTFlow, '', fromTime, toTime, logisticsUnits),
                shouldRefreshVehicleTypes && this.GetVehicleTypes()
            ]);
    }

    public static FindDeliveryTripWithFlow(pageSize: number, pageIndex: number, searchTrip: string, transportRequestId: string, fromTime: Date, toTime: Date, searchTextTransportReq: string, searchTextDTFlow: string, logisticsUnits: Array<string>)
        : Promise<[AxiosResponse<PagedResult<DeliveryTripLightModelExtended>>, AxiosResponse<PagedResult<TransportFlowLightModelExtended>>]> {
        return Promise.all(
            [
                this.FindDeliveryTrip(pageSize, pageIndex, searchTrip, transportRequestId, '', fromTime, toTime, searchTextTransportReq, searchTextDTFlow, logisticsUnits),
                this.FindTransportFlow(pageSize, pageIndex, searchTextTransportReq, searchTextDTFlow, transportRequestId, fromTime, toTime, logisticsUnits)
            ]);
    }

    public static UpdateTransportFlow = (requestArgs: TransportFlowChangeRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + "UpdateTransportFlowStatus",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateTransportFlowTransportersInstructions = (transportRequestId: string, transportFlowId: string, TransportersInstructions: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateTransportFlowTransportersInstructions?transportRequestId=${encodeURIComponent(transportRequestId)}&transportFlowId=${encodeURIComponent(transportFlowId)}&TransportersInstructions= ${encodeURIComponent(TransportersInstructions)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static FindDeliveryTrip = (pageLength: number, pageNumber: number, searchText: string, transportRequestId: string, transportFlowId: string, fromTime: Date, toTime: Date, searchTextTransportReq: string, searchTextTransportFlow: string, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<PagedResult<DeliveryTripLightModelExtended>>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const url = controllerUrl + "GetDeliveryTrips?searchText=" + encodeURIComponent(searchText) + "&pageIndex=" + pageNumber + "&pageSize=" + pageLength + "&transportRequestId=" + encodeURIComponent(transportRequestId) + "&transportFlowId=" + encodeURIComponent(transportFlowId)
            + "&fromTime=" + encodeURIComponent(fromTime.toJSON()) + "&toTime=" + encodeURIComponent(toTime.toJSON()) + "&searchTextTransportReq=" + encodeURIComponent(searchTextTransportReq) + "&searchTextTransportFlow=" + encodeURIComponent(searchTextTransportFlow);
        return axios.post(url, logisticsUnits, { headers: _headers });
    }

    public static UpdateDeliveryTrip = (requestArgs: DeliveryTripChangeRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'UpdateDeliveryTrip',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static DuplicateDeliveryTrip = (requestArgs: DeliveryTripLongIdentifierRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(controllerUrl + 'DuplicateDeliveryTrip',
            JSON.stringify(requestArgs), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static AddDeliveryTrip = (selectedId: string, deliveryDate: Date, transportFlowId: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddDeliveryTrip?transportRequestId=' + encodeURIComponent(selectedId) + '&planningDate=' + encodeURIComponent(deliveryDate.toJSON()) + '&transportFlowId=' + encodeURIComponent(transportFlowId),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static RemoveDeliveryTrips = (requestArgs: DeliveryTripLongIdentifierRequestArgs[])
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(controllerUrl + 'RemoveDeliveryTrips',
            JSON.stringify(requestArgs), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static AssignDeliveryTripStatus = (requestArgs: DeliveryTripLongIdentifierRequestArgs[], deliveryTripStatus: DeliveryTripStatus)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AssignDeliveryStatus?deliveryTripStatus=' + deliveryTripStatus,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static GetAttachmentFileNames = (transportFlowId: string)
        : Promise<AxiosResponse<Array<TransportFlowAttachmentFileLightModel>>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const url = controllerUrl + "GetAttachmentFileNames?transportFlowId=" + encodeURIComponent(transportFlowId);
        return axios.post(url, { headers: _headers });
    }

    public static GetFlowAttachmentsSaveUrl = (transportRequestId: string, transportFlowId: string): string => {
        return `${controllerUrl}AddFlowAttachments?transportRequestId=${encodeURIComponent(transportRequestId)}&transportFlowId=${encodeURIComponent(transportFlowId)}`;
    }

    public static DeleteTransportFlowAttachment = (transportRequestId: string, transportFlowId: string, attachmentId: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(controllerUrl + "DeleteTransportFlowAttachment?transportRequestId=" + encodeURIComponent(transportRequestId) + "&transportFlowId=" + encodeURIComponent(transportFlowId) + "&attachmentId=" + encodeURIComponent(attachmentId));
    }

}