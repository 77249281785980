import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Input, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import { UserSignatureDialogComponent } from '../../../../shared/UserSignature/UserSignatureDialogComponent';

interface HeaderContentComponentProps {
    inputSearchLogisticsUnitsValue: string,
    inputSearchLogisticsUnitsRef: React.RefObject<HTMLInputElement>,
    selectedLogisticianUserName: string,
    selectedLogisticianUserEmail: string,
    selectedLogisticianUserPhoneNumber: string,
    handleLogisticsUnitsKeyPress: (text: string) => void,
    handleClearSearchText: () => void,
    handleAfterSignatureAddedOrUpdated: () => void
}

export const HeaderContentComponent = (props: HeaderContentComponentProps): JSX.Element => {

    const [isUserSignatureDialogOpened, setIsUserSignatureDialogOpened] = useState<boolean>(false);

    const inputSearchExternalContactsClassName: string = props.inputSearchLogisticsUnitsValue.length > 2 ? 'search-text-active' : '';

    return (
        <Box display="flex" flexDirection="row" flex="wrap" justifyContent="space-between" alignItems="center" className="header-content">
            <Box>
                <Input disableUnderline className={inputSearchExternalContactsClassName} inputRef={props.inputSearchLogisticsUnitsRef}
                    startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                    endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => props.handleClearSearchText()} /></InputAdornment>}
                    placeholder="Rechercher ..." onChange={(event) => props.handleLogisticsUnitsKeyPress(event.target.value)} />
            </Box>
            <Button className={`btn-signature ${props.selectedLogisticianUserName ? ' enabled' : ''}`}
                disabled={!props.selectedLogisticianUserName}
                onClick={() => setIsUserSignatureDialogOpened(true)} >
                Signature
            </Button>

            {isUserSignatureDialogOpened &&
                <UserSignatureDialogComponent
                    isUserSignatureDialogOpened={isUserSignatureDialogOpened}
                    userName={props.selectedLogisticianUserName}
                    userEmail={props.selectedLogisticianUserEmail}
                    userPhoneNumber={props.selectedLogisticianUserPhoneNumber}
                    onClose={() => setIsUserSignatureDialogOpened(false)}
                    handleAfterSignatureAddedOrUpdated={props.handleAfterSignatureAddedOrUpdated}
                />
            }
        </Box>
    );
}
