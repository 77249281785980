import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Input } from '@mui/material';
import React, { useState } from 'react';
import Utilities from '../../../../../utils/Utilities';
import { CustomerContactRequestArgsExtended } from '../../models/CustomerContactRequestArgsExtended';

interface ConfirmAddCustomerStepComponentProperties {
    confirmAddCustomer: (customerContacts: CustomerContactRequestArgsExtended[]) => void,
    handlePreviousStep: () => void
}

export const ConfirmAddCustomerStepComponent = (props: ConfirmAddCustomerStepComponentProperties): JSX.Element => {

    const [customerContacts, setCustomerContacts] = useState<CustomerContactRequestArgsExtended[]>([
        {
            index: 0,
            contactId: '',
            firstName: '',
            lastName: '',
            phoneNumber: ''
        }
    ]);

    const confirmAddCustomer = (): void => {
        props.confirmAddCustomer(customerContacts);
    }

    const addContactRecord = (): void => {
        const customerContactsArray = [...customerContacts];
        const lastIndex = customerContactsArray.reduce((max, b) => Math.max(max, b.index), customerContactsArray[0].index);
        customerContactsArray.push({
            index: lastIndex + 1,
            contactId: '',
            firstName: '',
            lastName: '',
            phoneNumber: ''
        });
        setCustomerContacts(customerContactsArray);
    }

    const removeContactRecord = (index: number): void => {
        const customerContactsArray = [...customerContacts];
        var index = customerContactsArray.findIndex(x => x.index === index);
        customerContactsArray.splice(index, 1);
        verifyOtherContactNames(customerContactsArray, index);
        verifyOtherPhoneNumbers(customerContactsArray, index);
        setCustomerContacts(customerContactsArray);
    }

    const handleChangeCustomerContactLastName = (value: string, index: number): void => {
        const customerContactsArray = [...customerContacts];
        var index = customerContactsArray.findIndex(x => x.index === index);
        customerContactsArray[index].lastName = value;
        setCustomerContacts(customerContactsArray);
    }

    const handleChangeCustomerContactFirstName = (value: string, index: number): void => {
        const customerContactsArray = [...customerContacts];
        var index = customerContactsArray.findIndex(x => x.index === index);
        customerContactsArray[index].firstName = value;
        setCustomerContacts(customerContactsArray);
    }

    const handleChangeCustomerContactPhoneNumber = (value: string, index: number): void => {
        const customerContactsArray = [...customerContacts];
        var index = customerContactsArray.findIndex(x => x.index === index);
        customerContactsArray[index].phoneNumber = value;
        setCustomerContacts(customerContactsArray);
    }

    const handleBlurCustomerContactLastName = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, index: number): void => {
        const customerContactsArray = [...customerContacts];
        const elementIndex = customerContactsArray.findIndex(x => x.index === index);

        if (e.target.value.trim() === '') {
            customerContactsArray[elementIndex].contactNameAlreadyExists = null;
        } else {
            const value = e.target.value.trim().toLowerCase();
            const alreadyExists = customerContactsArray.some(e => e.index !== index &&
                e.lastName.trim().toLowerCase() === value &&
                e.firstName.trim().toLowerCase() === customerContactsArray[elementIndex].firstName.trim().toLowerCase());

            customerContactsArray[elementIndex].contactNameAlreadyExists = alreadyExists ? alreadyExists : null;
        }
        verifyOtherContactNames(customerContactsArray, index);

        setCustomerContacts(customerContactsArray);
    }

    const handleBlurCustomerContactFirstName = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, index: number): void => {
        const customerContactsArray = [...customerContacts];
        const elementIndex = customerContactsArray.findIndex(x => x.index === index);

        if (e.target.value.trim() === '') {
            customerContactsArray[elementIndex].contactNameAlreadyExists = null;
        } else {
            const value = e.target.value.trim().toLowerCase();
            const alreadyExists = customerContactsArray.some(e => e.index !== index &&
                e.firstName.trim().toLowerCase() === value &&
                e.lastName.trim().toLowerCase() === customerContactsArray[elementIndex].lastName.trim().toLowerCase());

            customerContactsArray[elementIndex].contactNameAlreadyExists = alreadyExists ? alreadyExists : null;
        }
        verifyOtherContactNames(customerContactsArray, index);

        setCustomerContacts(customerContactsArray);
    }

    const verifyOtherContactNames = (customerContactsArray: CustomerContactRequestArgsExtended[], index: number): void => {
        customerContactsArray.filter(x => x.contactNameAlreadyExists === true && x.index !== index).forEach(element => {
            const firstName = element.firstName.trim().toLowerCase();
            const lastName = element.lastName.trim().toLowerCase();
            const alreadyExists = customerContactsArray.some(e => e.index !== element.index && e.firstName.trim().toLowerCase() === firstName && e.lastName.trim().toLowerCase() === lastName);
            element.contactNameAlreadyExists = alreadyExists ? alreadyExists : null;
        });
    }

    const handleBlurCustomerContactPhoneNumber = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, index: number): void => {
        const customerContactsArray = [...customerContacts];
        const elementIndex = customerContactsArray.findIndex(x => x.index === index);

        if (e.target.value.trim() === '') {
            customerContactsArray[elementIndex].isValidPhoneNumber = false;
            customerContactsArray[elementIndex].phoneNumberAlreadyExists = null;
        } else {
            const value = cleanPhoneNumber(e.target.value);
            const alreadyExists = customerContactsArray.some(e => e.index !== index && cleanPhoneNumber(e.phoneNumber) === value);
            const isValidPhoneNumber = Utilities.isValidPhoneNumber(value);
            customerContactsArray[elementIndex].isValidPhoneNumber = isValidPhoneNumber;
            customerContactsArray[elementIndex].phoneNumberAlreadyExists = alreadyExists ? alreadyExists : null;
        }
        verifyOtherPhoneNumbers(customerContactsArray, index);

        setCustomerContacts(customerContactsArray);
    }

    const verifyOtherPhoneNumbers = (customerContactsArray: CustomerContactRequestArgsExtended[], index: number): void => {
        customerContactsArray.filter(x => x.phoneNumberAlreadyExists === true && x.index !== index).forEach(element => {
            const phoneNumber = cleanPhoneNumber(element.phoneNumber);
            const alreadyExists = customerContactsArray.some(e => e.index !== element.index && cleanPhoneNumber(e.phoneNumber) === phoneNumber);
            const isValidPhoneNumber = Utilities.isValidPhoneNumber(phoneNumber);
            element.isValidPhoneNumber = isValidPhoneNumber;
            element.phoneNumberAlreadyExists = alreadyExists ? alreadyExists : null;
        });
    }

    const cleanPhoneNumber = (value: string): string => {
        return value.replace(/[ ]+/g, "");
    }

    const isConfirmButtonEnabled: boolean = customerContacts.every((item) => item.isValidPhoneNumber === true && item.phoneNumberAlreadyExists !== true && item.contactNameAlreadyExists !== true);

    return (
        <Box className="confirm-add-customer">
            <Box pb={"10px"}>Contact(s) du client externe référencé</Box>
            <Box display="flex" flexDirection="column" className="input-texts-content">
                {customerContacts.map((element: CustomerContactRequestArgsExtended, index: number) => {
                    const validContact = element.firstName && element.lastName && element.phoneNumber && element.isValidPhoneNumber === true && element.phoneNumberAlreadyExists !== true && element.contactNameAlreadyExists !== true;
                    return (
                        <Box key={`contact-${index}`} display="flex" flexDirection="row" mb={"10px"}>
                            <Box display="flex" flexDirection="column" className={validContact ? "contact-content" : "contact-content invalid"}>
                                <Box>
                                    <Input
                                        id="customer-last-name"
                                        value={element.lastName}
                                        placeholder="Nom"
                                        className={element.lastName && !element.contactNameAlreadyExists ? "customer-input-text" : "customer-input-text invalid"}
                                        onChange={(e) => handleChangeCustomerContactLastName(e.target.value, element.index)}
                                        onBlur={(e) => handleBlurCustomerContactLastName(e, element.index)}
                                    />
                                </Box>
                                <Box>
                                    <Input
                                        id="customer-first-name"
                                        value={element.firstName}
                                        placeholder="Prénom"
                                        className={element.firstName && !element.contactNameAlreadyExists ? "customer-input-text" : "customer-input-text invalid"}
                                        onChange={(e) => handleChangeCustomerContactFirstName(e.target.value, element.index)}
                                        onBlur={(e) => handleBlurCustomerContactFirstName(e, element.index)}
                                    />
                                </Box>
                                <Box>
                                    <Input
                                        id="customer-phone-number"
                                        value={element.phoneNumber ? Utilities.formatPhoneNumber(element.phoneNumber) : ''}
                                        placeholder="Téléphone"
                                        className={element.isValidPhoneNumber && !element.phoneNumberAlreadyExists ? "customer-input-text" : "customer-input-text invalid"}
                                        onChange={(e) => handleChangeCustomerContactPhoneNumber(e.target.value, element.index)}
                                        onBlur={(e) => handleBlurCustomerContactPhoneNumber(e, element.index)}
                                    />
                                </Box>
                            </Box>
                            <Box style={{ cursor: 'pointer' }} alignSelf="center" pl={1} pr={1} onClick={addContactRecord}>
                                <FontAwesomeIcon size="lg" icon={faPlusCircle} />
                            </Box>
                            {(customerContacts.length > 1) &&
                                <Box style={{ cursor: 'pointer' }} alignSelf="center" onClick={() => removeContactRecord(element.index)}>
                                    <FontAwesomeIcon size="lg" icon={faTimes} />
                                </Box>
                            }
                        </Box>
                    );
                })}
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                <Button variant="contained" title="Retour" className="previous-button" onClick={props.handlePreviousStep}>
                    Retour
                </Button>
                <Button variant="contained" color="primary" title="Confirmer" disabled={!isConfirmButtonEnabled} onClick={confirmAddCustomer}>
                    Confirmer
                </Button>
            </Box>
        </Box>
    );
}