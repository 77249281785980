import { Box, Button, debounce } from '@mui/material';
import { GridHeaderSelectionChangeEvent, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { LogisticsUnitChoice } from 'src/shared/models/LogisticsUnitChoice';
import ToastService from 'src/ToastService';
import BusinessErrors from 'src/utils/BusinessErrors';
import { AddOverheadExpenseComponent } from './components/AddOverheadExpenseComponent';
import { ContentHeaderComponent } from './components/ContentHeaderComponent';
import { OverheadExpenseComponent } from './components/OverheadExpenseComponent';
import { OverheadExpenseLightModelExtended } from './models/OverheadExpenseLightModelExtended';
import './OverheadExpensesReferentialStyles.scss';
import { OverheadExpenseItemRequestArgs } from './services/dataContracts/controller/OverheadExpenseItemRequestArgs';
import { OverheadExpensesReferentialApiClient } from './services/OverheadExpensesReferentialApiClient';

interface OverheadExpensesProperties{
    logisticsUnits: Array<LogisticsUnitChoice>,
    logisticsUnitIds: Array<string>
}

export const OverheadExpensesReferentialView = (props: OverheadExpensesProperties): JSX.Element => {
    const [inputSearchOverheadExpenseValue, setInputSearchOverheadExpenseValue] = useState<string>('');
    const [requestRemovePending, setRequestRemovePending] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [overheadExpensesSelected, setOverheadExpensesSelected] = useState<Array<OverheadExpenseItemRequestArgs>>([]);
    const [openDialogNewOverheadExpense, setOpenDialogNewOverheadExpense] = useState<boolean>(false);
    const [overheadExpenses, setOverheadExpenses] = useState<Array<OverheadExpenseLightModelExtended>>([]);
    const [lastTimeStampGetOverheadExpenses, setLastTimeStampGetOverheadExpenses] = useState<number>(Date.now());
    const [showConfirmationRemoveOverheadExpenses, setShowConfirmationRemoveOverheadExpenses] = useState<boolean>(false);
    
    const inputSearchOverheadExpenseRef: React.RefObject<HTMLInputElement> = React.useRef(null);

    useEffect(()=> {
        SearchOverheadExpenses("");
    }, []);

    const ClearSearchInputOverheadExpenseText = (): void => {
        inputSearchOverheadExpenseRef.current.value = '';
        setInputSearchOverheadExpenseValue('');

        SearchOverheadExpenses("");
    }

    const HandleClickOpenNewOverheadExpense = (opened: boolean): void => {
        setOpenDialogNewOverheadExpense(opened);
    }

    const HandleOverheadExpenseKeyPress = debounce((searchText: string): void => {
        if (searchText.length >= 3 || searchText.length === 0) {
            inputSearchOverheadExpenseRef.current.value = searchText;
            setInputSearchOverheadExpenseValue(searchText);

            SearchOverheadExpenses(searchText);
        }
    }, 500);

    const HandleAddOverheadExpenseResult = (errors: Array<string>): void => {
        if (errors.length > 0) {
            ToastService.showErrorToast("Erreur lors de la création des frais de structure", errors);
        }
        else {
            ToastService.showSuccessToast("Création des frais de structure réalisée avec succès");
            SearchOverheadExpenses(inputSearchOverheadExpenseValue);
        }

        HandleClickOpenNewOverheadExpense(false);
    }

    const HandleRemoveOverheadExpensesResult = (errors: Array<string>): void => {
        if (errors.length > 0) {
            ToastService.showErrorToast("Erreur lors de la suppression des frais de structure", errors);
        }
        else {
            ToastService.showSuccessToast("Suppression des frais de structure réalisée avec succès");
            SearchOverheadExpenses(inputSearchOverheadExpenseValue);
        }

        HandleClickOpenNewOverheadExpense(false);
    }

    const DisplayError = (error: string): void => {
        ToastService.showErrorToast(error);
    }

    const SearchOverheadExpenses = (inputSearchText: string): void => {
        setLoading(true);
        const currentTimeStamp = Date.now();

        OverheadExpensesReferentialApiClient.GetOverheadExpenses(props.logisticsUnitIds, inputSearchText)
            .then(res => {
                if (currentTimeStamp < lastTimeStampGetOverheadExpenses)
                    return;

                const overheadExpensesData = res.data as Array<OverheadExpenseLightModelExtended>;

                overheadExpensesData?.forEach(x => {
                    x.selected = false;
                });

                setOverheadExpenses(overheadExpensesData);
            })
            .finally(() => {
                setLoading(false);
                setLastTimeStampGetOverheadExpenses(currentTimeStamp);
            });
    }

    const SelectionChange = (event: GridSelectionChangeEvent): void => {
        const overheadExpensesData = [...overheadExpenses];

        const dataItem = event.dataItem as OverheadExpenseLightModelExtended;

        const data = overheadExpensesData.map((item: OverheadExpenseLightModelExtended) => {
            if (item.overheadExpenseId === dataItem.overheadExpenseId) {
                item.selected = !dataItem.selected;
            }
            return item;
        });

        const dataSelected = data.filter(x => x.selected === true);
        
        setOverheadExpensesSelected(dataSelected.map(x => {
            return {
                logisticsUnitId: x.logisticsUnitId,
                overheadExpenseId: x.overheadExpenseId
            };
        }));

        setOverheadExpenses(data);
    }

    const HeaderSelectionChange = (event: GridHeaderSelectionChangeEvent): void => {
        const checked = event.nativeEvent.target.checked;
        const overheadExpensesData = [...overheadExpenses];

        const data = overheadExpensesData.map((item: OverheadExpenseLightModelExtended) => {
            item.selected = checked;
            return item;
        });

        if(checked){
            setOverheadExpensesSelected(data.map(x => {
                return {
                    logisticsUnitId: x.logisticsUnitId,
                    overheadExpenseId: x.overheadExpenseId
                };
            }));
        }
        else{
            setOverheadExpensesSelected([]);
        }

        setOverheadExpenses(data);
    }

    const HandleHideModal = (): void => {
        setShowConfirmationRemoveOverheadExpenses(false);
    }

    const RemoveOverheadExpenses = (): void => {
        setShowConfirmationRemoveOverheadExpenses(true);
    }

    const HandleRemoveOverheadExpenses = (): void => {
        setRequestRemovePending(true);
        OverheadExpensesReferentialApiClient.RemoveOverheadExpenses(overheadExpensesSelected)
            .then(res => {
                const errors = BusinessErrors.Get(res.data);

                HandleRemoveOverheadExpensesResult(errors);
            })
            .finally(() => {
                HandleHideModal();
                setRequestRemovePending(false);
            });
    }

    const contentHeaderComponent: JSX.Element = useMemo(() =>
        <ContentHeaderComponent 
            requestRemovePending={requestRemovePending} 
            overheadExpensesSelected={overheadExpensesSelected}
            inputSearchRef={inputSearchOverheadExpenseRef} 
            inputSearchValue={inputSearchOverheadExpenseValue}
            ClearSearchText={ClearSearchInputOverheadExpenseText} 
            HandleOverheadExpenseKeyPress={HandleOverheadExpenseKeyPress} 
            HandleClickOpenNewOverheadExpense={HandleClickOpenNewOverheadExpense} 
            RemoveOverheadExpenses={RemoveOverheadExpenses} 
        />, [requestRemovePending, overheadExpensesSelected, inputSearchOverheadExpenseValue]);

    const overheadExpenseComponent: JSX.Element = useMemo(() =>
        <OverheadExpenseComponent 
            data={overheadExpenses} 
            loading={loading}    
            HeaderSelectionChange={HeaderSelectionChange} 
            SelectionChange={SelectionChange} 
        />, [overheadExpenses, loading]);

    return(
        <div className="overhead-expense-referential-view">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box pt={2} pl={2} pr={2}>
                        <h5>
                            Frais de structure
                        </h5>
                        <hr className='separator' />
                    </Box>
                    <Box width="100%" display="flex" flexDirection="row" justifyContent="center">
                        <Box display="flex" flexDirection="column" minWidth="750px" maxWidth="900px" p={2}>
                            {contentHeaderComponent}
                            {overheadExpenseComponent}
                        </Box>
                    </Box>
                    {openDialogNewOverheadExpense &&
                        <AddOverheadExpenseComponent logisticsUnits={props.logisticsUnits}
                            HandleResult={HandleAddOverheadExpenseResult} HandleCloseDialog={() => HandleClickOpenNewOverheadExpense(false)} DisplayError={DisplayError} />
                    }
                    <Modal show={showConfirmationRemoveOverheadExpenses} onHide={HandleHideModal} className='mt-5'>
                        <Modal.Header closeButton>
                            <Modal.Title>ATTENTION</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Souhaitez-vous vraiment supprimer ces frais de structure ?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="secondary" onClick={HandleHideModal}>
                                ANNULER
                            </Button>
                            <Button className="primary" onClick={HandleRemoveOverheadExpenses}>
                                CONFIRMER
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}