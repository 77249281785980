import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { NegotiatedPurchasePriceLightModelExtended } from '../models/NegotiatedPurchasePriceLightModelExtended';
import { SizingUtilities } from '../SizingUtilities';
import { CustomCheckBoxCell } from './CustomCheckBoxCell';

interface NegotiatedPurchasePricesComponentProps {
    loading: boolean,
    negotiatedPurchasePrices: NegotiatedPurchasePriceLightModelExtended[],
    handleSelectionItemChange: (checked: boolean, dataItem: NegotiatedPurchasePriceLightModelExtended) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const NegotiatedPurchasePricesReferentialGridName = 'negotiatedPurchasePricesReferential';
const ModuleKey = AppModule.NegotiatedPurchasePricesReferential;

export const NegotiatedPurchasePricesComponent = (props: NegotiatedPurchasePricesComponentProps): JSX.Element => {

    const initialSort: SortDescriptor[] = [{ field: 'startDate', dir: 'desc' }];
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const [skip, setSkip] = useState<number>(0);

    const forceUpdate = useForceUpdate();

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const getColumnWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, NegotiatedPurchasePricesReferentialGridName, fieldName, columnWidth);
    }

    const getGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, NegotiatedPurchasePricesReferentialGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            NegotiatedPurchasePricesReferentialGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, NegotiatedPurchasePricesReferentialGridName, event.columns);
        forceUpdate();
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.negotiatedPurchasePrices.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: NegotiatedPurchasePriceLightModelExtended[] = orderBy(props.negotiatedPurchasePrices, sort).slice(skip, skip + gridPageSize);

    return (
        props.loading ?
            <div className='sweet-loading spinnerClass'>
                <ScaleLoader
                    width={5}
                    height={20}
                    radius={50}
                    color="#000000"
                    loading={props.loading}
                />
            </div>
            :
            <Box className="negotiated-purchase-prices-content">
                <Grid
                    className="negotiated-purchase-prices-grid"
                    data={gridData}
                    selectedField="selected"
                    sortable
                    sort={sort}
                    onSortChange={handleSortChange}
                    reorderable
                    resizable
                    onColumnResize={onResizeHandler}
                    onColumnReorder={(e) => onReorderHandler(e)}
                    scrollable="virtual"
                    skip={skip}
                    pageSize={gridPageSize}
                    total={totalGrid}
                    onPageChange={pageChange}
                    rowHeight={rowHeight}
                    style={gridStyle}
                >
                    <GridColumn field="selected" orderIndex={getGridOrderIndexColumn("selected", 0)} width={getColumnWidth("selected", 50)}
                        headerCell={() => <></>}
                        cell={(properties: GridCellProps) =>
                            <CustomCheckBoxCell
                                dataItem={properties.dataItem}
                                field={properties.field}
                                handleSelectionItemChange={props.handleSelectionItemChange}
                            />}
                    />
                    <GridColumn field="label" title="Libellé" orderIndex={getGridOrderIndexColumn("label", 1)} width={getColumnWidth("label", 150)} editable={false} />
                    <GridColumn field="senderSiteResolutionLabel" title="Site départ" orderIndex={getGridOrderIndexColumn("senderSiteResolutionLabel", 2)} width={getColumnWidth("senderSiteResolutionLabel", 150)} editable={false} />
                    <GridColumn field="senderSiteLevel2ActivityLabel" title="Activité départ" orderIndex={getGridOrderIndexColumn("senderSiteLevel2ActivityLabel", 3)} width={getColumnWidth("senderSiteLevel2ActivityLabel", 250)} editable={false} />
                    <GridColumn field="senderSiteAgencyLabel" title="Agence départ" orderIndex={getGridOrderIndexColumn("senderSiteAgencyLabel", 4)} width={getColumnWidth("senderSiteAgencyLabel", 150)} editable={false} />
                    <GridColumn field="receiverSiteResolutionLabel" title="Site arrivée" orderIndex={getGridOrderIndexColumn("receiverSiteResolutionLabel", 5)} width={getColumnWidth("receiverSiteResolutionLabel", 150)} editable={false} />
                    <GridColumn field="receiverSiteLevel2ActivityLabel" title="Activité arrivée" orderIndex={getGridOrderIndexColumn("receiverSiteLevel2ActivityLabel", 6)} width={getColumnWidth("receiverSiteLevel2ActivityLabel", 250)} editable={false} />
                    <GridColumn field="receiverSiteAgencyLabel" title="Agence arrivée" orderIndex={getGridOrderIndexColumn("receiverSiteAgencyLabel", 7)} width={getColumnWidth("receiverSiteAgencyLabel", 150)} editable={false} />
                    <GridColumn field="vehicleTypeGroupId" title="Famille de véhicule" orderIndex={getGridOrderIndexColumn("vehicleTypeGroupId", 8)} width={getColumnWidth("vehicleTypeGroupId", 150)} editable={false} />
                    <GridColumn field="priceKindLabel" title="Type de tarification" orderIndex={getGridOrderIndexColumn("priceKindLabel", 9)} width={getColumnWidth("priceKindLabel", 100)} editable={false} />
                    <GridColumn field="price" title="Coût" orderIndex={getGridOrderIndexColumn("price", 10)} width={getColumnWidth("price", 100)} editable={false}
                        cell={(props: GridCellProps) => <td>
                            {props.dataItem.price ? props.dataItem.price + ' €' : ''}
                        </td>}
                    />
                    <GridColumn field="zephyrOrderNumber" title="N° commande" orderIndex={getGridOrderIndexColumn("zephyrOrderNumber", 11)} width={getColumnWidth("zephyrOrderNumber", 150)} editable={false} />
                    <GridColumn field="startDate" title="Date début" orderIndex={getGridOrderIndexColumn("startDate", 12)} width={getColumnWidth("startDate", 150)} format="{0:dd-MM-yyyy}" editable={false} />
                    <GridColumn field="endDate" title="Date fin" orderIndex={getGridOrderIndexColumn("endDate", 13)} width={getColumnWidth("endDate", 150)} format="{0:dd-MM-yyyy}" editable={false} />
                </Grid>
            </Box>
    );

}
