import React from 'react';
import { ScaleLoader } from 'react-spinners';

interface ScaleLoaderProps {
    loading: boolean
}

export const ScaleLoaderComponent = (props: ScaleLoaderProps) => {
    return (
        <div className='sweet-loading spinnerClass'>
            <ScaleLoader
                width={5}
                height={20}
                radius={50}
                color={'#000000'}
                loading={props.loading}
            />
        </div>
    );
}
