import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderSelectionChangeEvent, GridPageChangeEvent, GridSelectionChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import { AppModule, LocalStorage } from 'src/utils/Storage';
import { OverheadExpenseLightModelExtended } from '../models/OverheadExpenseLightModelExtended';
import { SizingUtilities } from '../SizingUtilities';

interface OverheadExpenseProperties {
    data: Array<OverheadExpenseLightModelExtended>,
    loading: boolean,
    SelectionChange: (event: GridSelectionChangeEvent) => void,
    HeaderSelectionChange: (event: GridHeaderSelectionChangeEvent) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const OverheadExpensesReferentialGridName = 'overheadExpensesReferential';
const ModuleKey = AppModule.OverheadExpensesReferential;

export const OverheadExpenseComponent = (props: OverheadExpenseProperties): JSX.Element => {
    const initialSort: SortDescriptor[] = [{ field: 'startDate', dir: 'desc' }];
    const [sort, setSort] = useState<Array<SortDescriptor>>(initialSort);
    const [skip, setSkip] = useState<number>(0);

    const forceUpdate = useForceUpdate();

    const getOverheadExpenseWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, OverheadExpensesReferentialGridName, fieldName, defaultWidth);
    }

    const getOverheadExpenseGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, OverheadExpensesReferentialGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            OverheadExpensesReferentialGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }
    
    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, OverheadExpensesReferentialGridName, event.columns);
        forceUpdate();
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip ? event.page.skip : 0);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.data.length;

    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: OverheadExpenseLightModelExtended[] = orderBy(props.data, sort).slice(skip, skip + gridPageSize);
    
    return (
        <Box className="overhead-expense-grid">
                {
                    props.loading ? <div className='sweet-loading'>
                        <ScaleLoader
                            width={5}
                            height={20}
                            radius={50}
                            color={'#000000'}
                            loading={props.loading}
                        />
                    </div> :
                        <Grid
                            className="grid"
                            data={gridData}
                            sortable
                            reorderable
                            resizable
                            selectedField="selected"
                            sort={sort}
                            onHeaderSelectionChange={props.HeaderSelectionChange}
                            onSelectionChange={props.SelectionChange}
                            onSortChange={handleSortChange}
                            onColumnResize={onResizeHandler}
                            onColumnReorder={(e) => onReorderHandler(e)}
                            rowHeight={40}
                            scrollable="virtual"
                            skip={skip}
                            total={totalGrid}
                            pageSize={gridPageSize}
                            onPageChange={pageChange}
                            style={{height: gridHeight}}
                        >
                            <GridColumn field="selected" width={getOverheadExpenseWidth("selected", 25)} orderIndex={getOverheadExpenseGridOrderIndexColumn("selected", 0)}
                                    headerSelectionValue={props.data.findIndex((dataItem: OverheadExpenseLightModelExtended) => dataItem.selected === false) === -1}
                                    headerClassName="cell-center" className="cell-center" />
                            <GridColumn field="logisticsUnitLabel" orderIndex={getOverheadExpenseGridOrderIndexColumn("logisticsUnitLabel", 1)} width={getOverheadExpenseWidth("logisticsUnitLabel", 120)} title="Zone logistique" />
                            <GridColumn field="dailyValue" orderIndex={getOverheadExpenseGridOrderIndexColumn("dailyValue", 2)} width={getOverheadExpenseWidth("dailyValue", 170)} 
                                title="Frais structure journaliers" headerClassName="cell-center" cell={(p: GridCellProps) => 
                                    {
                                    return <td className="cell-center">
                                        {p.dataItem.dailyValue ? p.dataItem.dailyValue.toCurrencyString() : ""}
                                    </td>
                                    }} />
                            <GridColumn field="startDate" orderIndex={getOverheadExpenseGridOrderIndexColumn("startDate", 3)} width={getOverheadExpenseWidth("startDate", 120)} 
                                title="Date de début" format="{0:dd-MM-yyyy}" headerClassName="cell-center" className="cell-center" />
                            <GridColumn field="endDate" orderIndex={getOverheadExpenseGridOrderIndexColumn("endDate", 4)} width={getOverheadExpenseWidth("endDate", 120)} 
                                title="Date de fin" format="{0:dd-MM-yyyy}" headerClassName="cell-center" className="cell-center" />
                        </Grid>
                }
            </Box>
    );
}