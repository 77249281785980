import { Box, Button, Input } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Utilities from '../../../../utils/Utilities';
import { InternalDriverLightModelExtended } from '../models/InternalDriverLightModelExtended';
import { AddTemporaryDriverRequestArgs } from '../services/dataContracts/controller/AddTemporaryDriverRequestArgs';
import { InternalDriversReferentialApiClient } from '../services/InternalDriversReferentialApiClient';

interface TemporaryDriverDialogComponentProps {
    temporaryDriver: AddTemporaryDriverRequestArgs,
    selectedTemporaryDriver: InternalDriverLightModelExtended,
    editMode: boolean;
    handleTemporaryDriverLastNameChange: (value: string) => void,
    handleTemporaryDriverFirstNameChange: (value: string) => void,
    handleTemporaryDriverPhoneNumberChange: (value: string) => void,
    handleCloseTemporaryDriverDialog: () => void,
    handleChangeInternalTransporter: (internalTransporterId: string) => void,
    saveTemporaryDriver: () => void,
    editTemporaryDriver: (employeeId: string, phoneNumber: string) => void
}

interface SelectModel {
    value: string,
    label: string
}

export const TemporaryDriverDialogComponent = (props: TemporaryDriverDialogComponentProps): JSX.Element => {

    const { temporaryDriver, editMode, selectedTemporaryDriver } = props;

    const [internalTransporters, setInternalTransporters] = useState<SelectModel[]>([]);
    const [selectedInternalTransporter, setSelectedInternalTransporter] = useState<SelectModel>(editMode ? { value: selectedTemporaryDriver.transporterLabel, label: selectedTemporaryDriver.transporterLabel } : null);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(false);
    const [driverPhoneNumberToEdit, setDriverPhoneNumberToEdit] = useState<string>('');

    useEffect(() => {
        if (!editMode)
            InternalDriversReferentialApiClient.GetInternalTransporters()
                .then((res) => {
                    if (res.data) {
                        setInternalTransporters([...res.data.map(x => ({ value: x.transporterId, label: x.label }))]);
                    }
                });
    }, []);

    useEffect(() => {
        if (selectedTemporaryDriver) {
            const isValidePhoneNumber: boolean = Utilities.isValidPhoneNumber(selectedTemporaryDriver.phoneNumber);
            setIsValidPhoneNumber(isValidePhoneNumber);
            setDriverPhoneNumberToEdit(selectedTemporaryDriver.phoneNumber);
        }
    }, [selectedTemporaryDriver]);

    const handleChangeInternalTransporter = (event: SelectModel): void => {
        if (event) {
            const internalTransporterId = event.value;
            props.handleChangeInternalTransporter(internalTransporterId);
            setSelectedInternalTransporter(internalTransporters.find(x => x.value === internalTransporterId));
            return;
        }

        props.handleChangeInternalTransporter('');
        setSelectedInternalTransporter(null);
    }

    const handleChangePhoneNumber = (value: string): void => {
        if (editMode) {
            setDriverPhoneNumberToEdit(value.trim());
        }
        else {
            props.handleTemporaryDriverPhoneNumberChange(value);
        }

        const isValidePhoneNumber: boolean = Utilities.isValidPhoneNumber(value);
        setIsValidPhoneNumber(isValidePhoneNumber);
    }

    const saveDriver = (): void => {
        if (editMode) {
            props.editTemporaryDriver(selectedTemporaryDriver.employeeId, driverPhoneNumberToEdit);
        }
        else {
            props.saveTemporaryDriver();
        }
    }

    const enableSaveButton: boolean = editMode ? isValidPhoneNumber && driverPhoneNumberToEdit !== '' : temporaryDriver.lastName.trim() !== '' && temporaryDriver.firstName.trim() !== '' && temporaryDriver.phoneNumber.trim() !== '' && temporaryDriver.internalTransporterId.trim() !== '' && isValidPhoneNumber;

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="column" className="input-text-content">
                <Box className={editMode || temporaryDriver.lastName.trim() ? "temporary-driver-input-text-label" : "temporary-driver-input-text-label required"}>Nom</Box>
                <Box display="flex" flexDirection="row">
                    <Input
                        value={editMode ? selectedTemporaryDriver.lastName : temporaryDriver.lastName}
                        className={editMode || temporaryDriver.lastName.trim() ? "temporary-driver-input-text-value" : "temporary-driver-input-text-value required"}
                        onChange={(e) => props.handleTemporaryDriverLastNameChange(e.target.value)}
                        disabled={editMode}
                    />
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" className="input-text-content">
                <Box className={editMode || temporaryDriver.firstName.trim() ? "temporary-driver-input-text-label" : "temporary-driver-input-text-label required"}>Prénom</Box>
                <Box display="flex" flexDirection="row">
                    <Input
                        value={editMode ? selectedTemporaryDriver.firstName : temporaryDriver.firstName}
                        className={editMode || temporaryDriver.firstName.trim() ? "temporary-driver-input-text-value" : "temporary-driver-input-text-value required"}
                        onChange={(e) => props.handleTemporaryDriverFirstNameChange(e.target.value)}
                        disabled={editMode}
                    />
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" className="input-text-content">
                <Box className={editMode ? (driverPhoneNumberToEdit ? "temporary-driver-input-text-label" : "temporary-driver-input-text-label required") : (temporaryDriver.phoneNumber.trim() ? "temporary-driver-input-text-label" : "temporary-driver-input-text-label required")}>Téléphone</Box>
                <Box display="flex" flexDirection="row">
                    <Input
                        value={editMode ? driverPhoneNumberToEdit : temporaryDriver.phoneNumber}
                        className={editMode ? (driverPhoneNumberToEdit ? "temporary-driver-input-text-value" : "temporary-driver-input-text-value required") : (temporaryDriver.phoneNumber.trim() ? "temporary-driver-input-text-value" : "temporary-driver-input-text-value required")}
                        onChange={(e) => handleChangePhoneNumber(e.target.value)}
                    />
                </Box>
                {!isValidPhoneNumber && <Box className="invalid-phone-number-label">Le numéro n'est pas valide</Box>}
            </Box>
            <Box display="flex" flexDirection="column" className="input-text-content">
                <Box className={editMode || temporaryDriver.internalTransporterId.trim() ? "temporary-driver-input-text-label" : "temporary-driver-input-text-label required"}>Agence d'appartenance</Box>
                <Box display="flex" flexDirection="row">
                    <Select
                        className={editMode || temporaryDriver.internalTransporterId.trim() ? "temporary-driver-select-value" : "temporary-driver-select-value required"}
                        isClearable
                        placeholder=""
                        options={internalTransporters}
                        value={selectedInternalTransporter}
                        onChange={(e) => handleChangeInternalTransporter(e)}
                        menuPosition="fixed"
                        menuShouldBlockScroll={true}
                        isDisabled={editMode}
                    />
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                <Button variant="contained" title="Annuler" className="cancel-button" onClick={props.handleCloseTemporaryDriverDialog}>
                    Annuler
                </Button>
                <Button variant="contained" color="primary" title="Sauvegarder" disabled={!enableSaveButton} onClick={() => saveDriver()}>
                    Sauvegarder
                </Button>
            </Box>
        </Box>
    );
}
