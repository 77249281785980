import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import Utilities from '../../../utils/Utilities';

export const PhoneNumberCell = (props: GridCellProps) => {

    const fieldValue: string = props.dataItem[props.field];
    let defaultRendering = null;

    defaultRendering = (
        <td className="phone-number-ref">
             { /*L'attribut "data-rel" indique le type de relation entre la page cible du lien et celle où est situé ce lien.
             La valeur "external" indique que la page vers laquelle redirige le lien n'est pas située sur le même site que le lien.*/}
            <a href={`callTo:${Utilities.formatPhoneNumber(fieldValue)}`} data-rel="external">
                {Utilities.formatPhoneNumber(fieldValue)}
            </a>
        </td>
    );
    
    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering
}