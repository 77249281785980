import { Chart, ChartAxisDefaults, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem } from '@progress/kendo-react-charts';
import React from 'react';
import { HistogramDataModel } from '../models/HistogramDataModel';

interface MissingVehicleTypeGroupChartComponentProps {
    weekDays: string[],
    histogramData: HistogramDataModel[]
}

export const MissingVehicleTypeGroupChartComponent = (props: MissingVehicleTypeGroupChartComponentProps): JSX.Element => {

    const { weekDays, histogramData } = props;

    return (
        <Chart zoomable={false} style={{ width: '100%', height: '100%' }} transitions={false}>
            <ChartAxisDefaults labels={{
                content: (e) => {
                    if (typeof e.value === 'number' && e.value % 1 !== 0) {
                        return "";
                    }
                    return e.value;
                }
            }} />
            <ChartLegend position="top" orientation="horizontal" />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={weekDays} />
            </ChartCategoryAxis>
            <ChartSeries>
                {histogramData !== undefined && histogramData.map((item, index) => (
                    <ChartSeriesItem
                        key={index}
                        type="column"
                        data={item.weeklyNeededNumberOfVehiclesArray}
                        name={item.family}
                    />))}
            </ChartSeries>
        </Chart>
    );
}