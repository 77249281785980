import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import React from 'react';

//RENAME v1 ModifiedProperty
interface ModifiedPropertyComponentProps {
    propertyLabels: Map<string, string>,
    hiddenProperties: string[],
    elem: string,
    entityType: string,
    oldValuesParsedTracking: any,
    newValuesParsedTracking: any,
    vehicleTypesLabels?: Map<string, string>,
    getValueOfParsedJson: (objectValues: any, nameField: string) => any
}
export default class ModifiedPropertyComponent extends React.PureComponent<ModifiedPropertyComponentProps, {}>{
    render() {
        const { oldValuesParsedTracking, newValuesParsedTracking, elem, entityType, propertyLabels, hiddenProperties, vehicleTypesLabels, getValueOfParsedJson } = this.props;
    
        let oldValue = getValueOfParsedJson(oldValuesParsedTracking, elem);
        let newValue = getValueOfParsedJson(newValuesParsedTracking, elem);
        const propertyLabel = propertyLabels.get(`${entityType}.${elem}`);

        if(elem === "VehicleTypeId") {
            oldValue = vehicleTypesLabels.get(oldValue) ?? oldValue;
            newValue = vehicleTypesLabels.get(newValue) ?? newValue;
        }

        return (
            hiddenProperties.indexOf(elem) === -1 ? (
                <Box pb={1}>
                    <Box display="flex" flexWrap="nowrap" flexDirection="column">
                        <Box>
                            <div className="field-title">{propertyLabel ? propertyLabel : elem}</div>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box width="45%">
                                <Typography color="inherit" className="field-old-value">
                                    {oldValue ? oldValue : ''}
                                </Typography>
                            </Box>
                            <Box width="10%" display="flex" justifyContent="center" alignItems="center">
                                <div>
                                    <FontAwesomeIcon className="icon-chevron" icon={faChevronRight} />
                                </div>
                            </Box>
                            <Box width="45%">
                                <Typography className="field-new-value">
                                    {newValue ? newValue : ''}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : ''
        );
    }
}