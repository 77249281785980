import axios, { AxiosResponse } from 'axios';

const controllerUrl = 'api/Attachments/';

export class AttachmentsApiClient {

    public static GetAttachment = (attachmentId: string)
        : Promise<AxiosResponse<Blob>> => {
        return axios.get(controllerUrl + "GetAttachment?attachmentId=" + attachmentId, { responseType: 'blob' });
    }

}