import { faEye, faEyeSlash, faLock, faLongArrowAltRight, faPen, faPlusCircle, faSearch, faTimes, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Checkbox, Drawer, FormControlLabel, IconButton, Input, InputAdornment, Tooltip } from "@mui/material";
import { NumericTextBox, NumericTextBoxBlurEvent, NumericTextBoxChangeEvent, NumericTextBoxHandle } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { OverflowTextComponent } from 'src/shared/components/Common/OverflowTextComponent';
import { toMinutes } from 'src/utils/TimeSpanString';
import { TransportFlowLightModelExtended } from '../models/TransportFlowLightModelExtended';
import { SizingUtilities } from '../SizingUtilities';

interface FlowManagementDrawerComponentProperties {
    isFlowManagementDrawerOpened: boolean,
    isNegativeFilterActived: boolean,
    inputSearchFlowValue: string,
    inputSearchFlowRef: React.RefObject<HTMLInputElement>,
    addedDaysNumberChoice: number,
    transportFlowList: Array<TransportFlowLightModelExtended>,
    selectedFlow: string,
    handleFlowManagementDrawerClose: () => void,
    searchVehiclesTripsByTransportFlowId: (businessId: string) => void,
    searchTransportFlow: (isNegativeRemainingQtyFilterSelected: boolean) => void,
    clearSearchFlowText: () => void,
    handleSearchFlowKeyPress: (searchText: string) => void,
    handlePeriodChoiceChange: (period: number) => void,
    handleOpenPopupCreateFlow: () => void,
    handleHideFlowChange: (transportFlowId: string, toHide: boolean) => void,
    editFlow: (transportFlowId: string, flowBusinessId: string) => void,
    handleUpdateTripsNumberTransportFlow: (transportFlowId: string, tripsNumber: number) => void,
    handleUpdateTripsNumberIsForcedTransportFlow: (transportFlowId: string, tripsNumberIsForced: boolean) => void
    isNegativeRemainingQtyFilterSelected: boolean,
    hasNegativeFlows: boolean
}

export const FlowManagementDrawerComponent = (props: FlowManagementDrawerComponentProperties): JSX.Element => {

    const [showHiddenFlows, setShowHiddenFlows] = useState<boolean>(false);
    const [transportFlowList, setTransportFlowList] = useState<Array<TransportFlowLightModelExtended>>([]);

    useEffect(() => {

        const hiddenFlowList = props.transportFlowList.filter(x => x.isHidden);

        if (showHiddenFlows && hiddenFlowList.length > 0)
            setTransportFlowList([...hiddenFlowList]);
        else {
            setTransportFlowList([...props.transportFlowList.filter(x => !x.isHidden)]);

            if (showHiddenFlows)
                setShowHiddenFlows(false);
        }


    }, [props.transportFlowList]);

    const handlePeriodChoiceChange = (e: ChangeEvent<HTMLInputElement>): void => {
        props.handlePeriodChoiceChange(e.target.checked ? Number(e.target.value) : 0);
    }

    const editFlow = (transportFlowId: string, flowBusinessId: string, transportFlowStatus: string): void => {
        if (transportFlowStatus == 'Finished') {
            return;
        }
        props.editFlow(transportFlowId, flowBusinessId);
    }

    const checkboxFilter = (label: string, value: number): JSX.Element => {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        className="checkbox-filter"
                        checked={props.addedDaysNumberChoice === value}
                        onChange={handlePeriodChoiceChange}
                        value={value}
                        color="primary"
                    />
                }
                label={label}
            />
        )
    }

    const hasNegativeFlowCLassName: string = props.hasNegativeFlows && !props.isNegativeFilterActived ? 'negative-flow' : 'positive-flow';
    const hiddenFlows: Array<TransportFlowLightModelExtended> = props.transportFlowList.filter(f => {
        return f.isHidden == true;
    });

    const translateTransportFlowStatus = (transportFlowStatus: string): string => {
        switch (transportFlowStatus) {
            case "Pending":
                return "En attente";
            case "Optimized":
                return "Optimisé";
            case "Planned":
                return "Planifié";
            case "Confirmed":
                return "Confirmé";
            case "InProgress":
                return 'En cours';
            case "Finished":
                return 'Terminé';
            case "Canceled":
                return 'Annulé';
            default:
                return '';
        }
    }

    const renderRow = (properties: ListChildComponentProps): JSX.Element => {
        const inputTripRef = useRef<NumericTextBoxHandle>(null);
        const { index, style } = properties;
        const itemStyle: React.CSSProperties = { ...style, width: "99%" };
        const dataItem = transportFlowList[index];
        const flowTypeColorClassName = dataItem.serviceKind != 'Delivery' ? dataItem.serviceKind : (dataItem.isPerishableProduct ? 'Delivery_Perishable' : 'Delivery_Not_Perishable');
        const remainingQuantityColorClassName = (dataItem.remainingQuantityToBePlanned >= 0 && dataItem.remainingQuantityToBeDelivered >= 0) 
            ? 'flow-card-font-p' : 'flow-card-font-negative-p';

        const arrivalEndTime = (dataItem.arrivalTimeAtReceiverSite && dataItem.arrivalSlotDuration)
            ? new Date(dataItem.arrivalTimeAtReceiverSite).addMinutes(toMinutes(dataItem.arrivalSlotDuration))
            : null;

        const handleChangeNbTrips = (event: NumericTextBoxChangeEvent) => {
            setIsEditingNbTrips(true);
            if (event.target.value >= 1 && event.target.value <= 9) {
                setTripsNumber(event.target.value);
                inputTripRef.current.element.blur();
            }
        }

        const handleBlurNbTrips = (event: NumericTextBoxBlurEvent): void => {
            props.handleUpdateTripsNumberTransportFlow(dataItem.transportFlowId, event.target.value);
            setIsEditingNbTrips(false);
        }

        const handleChangeTripsNumberIsForce = (): void => {
            const newTripsNumberIsForced = !tripsNumberIsForced;
            setTripsNumberIsForced(newTripsNumberIsForced);
            props.handleUpdateTripsNumberIsForcedTransportFlow(dataItem.transportFlowId, newTripsNumberIsForced);

        }

        const [tripsNumber, setTripsNumber] = useState<number>(dataItem.tripsNumber);
        const [tripsNumberIsForced, setTripsNumberIsForced] = useState<boolean>(dataItem.tripsNumberIsForced);

        const translatePriceKind = (priceKind: string): string => {
            switch (priceKind) {
                case 'PerTurn':
                    return 'Tr';
                case 'PerTon':
                    return 'T';
                case 'PerHour':
                    return 'H';
                default:
                    return priceKind;
            }
        }

        //ODA permet d'éviter le bug du drag du flux avec un écart lorsqu'on est en entrain de saisir le nombre de tours
        const [isEditingNbTrips, setIsEditingNbTrips] = useState<boolean>(false);

        const negotiatedPurchasePriceKindLabel = translatePriceKind(dataItem.negotiatedPurchasePriceKind);
        return (
            <Box style={itemStyle}>
                <Box display="flex" flexWrap="nowrap" flexDirection="column" className={dataItem.businessId === props.selectedFlow ? 'flow-content selected' : 'flow-content'} id={dataItem.businessId}>
                    <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center" className={flowTypeColorClassName} height={'50px'}>
                        <Box className={`drag-icon${(!isEditingNbTrips && tripsNumber >= 1 && tripsNumber <= 9) ? " drag" : ""}`} id={dataItem.businessId} data-transportflow={`${JSON.stringify(dataItem)}`}>
                        </Box>
                        <Box pl="5px" pr="5px">
                            <LocalizationProvider language="fr-FR">
                                <IntlProvider locale="fr" >
                                    <NumericTextBox ref={inputTripRef} className="input-nb-trips"
                                        min={1}
                                        max={9}
                                        format={{
                                            maximumFractionDigits: 0
                                        }}
                                        onChange={(event) => handleChangeNbTrips(event)}
                                        onBlur={(event) => handleBlurNbTrips(event)}
                                        width={'35px'}
                                        value={tripsNumber}
                                    />
                                </IntlProvider>
                            </LocalizationProvider>
                        </Box>
                        <Box pl="3px" pr="5px">
                            {
                                dataItem.tripsNumberIsForced
                                    ?
                                    <Tooltip title="Prendre en compte le reste à livrer du Flux" placement="bottom">
                                        <Box display="flex" flexDirection="column" alignItems="center" className="trips-number-forced" onClick={() => handleChangeTripsNumberIsForce()}>
                                            <FontAwesomeIcon size="1x" icon={faUnlock} />
                                        </Box>
                                    </Tooltip>
                                    : <Tooltip title="Ne pas prendre en compte le reste à livrer du Flux" placement="bottom">
                                        <Box display="flex" flexDirection="column" alignItems="center" className="trips-number-not-forced" onClick={() => handleChangeTripsNumberIsForce()}>
                                            <FontAwesomeIcon size="1x" icon={faLock} />
                                        </Box>
                                    </Tooltip>
                            }
                        </Box>
                        <Box display="flex" flexWrap="nowrap" flexDirection="row" justifyContent="space-between" width={'73%'}>
                            <Box className="flow-site-resolution-label" alignSelf="center" height="1em" width="180px">
                                <OverflowTextComponent text={dataItem.siteResolutionLabel} customTooltip={dataItem.businessId} customTooltipWhenOverflow={`${dataItem.siteResolutionLabel} / ${dataItem.businessId}`} />
                            </Box>
                            <Box className={'flow-status ' + dataItem.transportFlowStatus} alignSelf="center">
                                {translateTransportFlowStatus(dataItem.transportFlowStatus)}
                            </Box>
                        </Box>
                        <Box pr={'1px'} pl={'1px'}>
                            <Tooltip title='Modifier les Flux dont le statut est " Attente", Planifié", "Confirmé" ou "En cours"' placement="bottom">
                                <Box display="flex" flexDirection="column" alignItems="center" className={dataItem.transportFlowStatus == 'Finished' ? 'oval-icon-disabled oval-icon' : 'oval-icon'}
                                    onClick={() => editFlow(dataItem.transportFlowId, dataItem.businessId, dataItem.transportFlowStatus)}>
                                    <FontAwesomeIcon icon={faPen} size="1x" />
                                </Box>
                            </Tooltip>
                        </Box>
                        <Box pr={'1px'}>
                            {!dataItem.isHidden ?
                                (<Tooltip title="Cacher le Flux" placement="bottom">
                                    <Box display="flex" flexDirection="column" alignItems="center" className="oval-icon" onClick={() => props.handleHideFlowChange(dataItem.transportFlowId, true)}>
                                        <FontAwesomeIcon icon={faEye} size="1x" />
                                    </Box>
                                </Tooltip>)
                                :
                                (<Tooltip title="Afficher le Flux" placement="bottom">
                                    <Box display="flex" flexDirection="column" alignItems="center" className="oval-icon" onClick={() => props.handleHideFlowChange(dataItem.transportFlowId, false)}>
                                        <FontAwesomeIcon icon={faEyeSlash} size="1x" />
                                    </Box>
                                </Tooltip>)
                            }
                        </Box>
                        <Box onClick={() => props.searchVehiclesTripsByTransportFlowId(dataItem.businessId)}>
                            <div className={dataItem.businessId === props.selectedFlow ? "oval-icon oval-filter-icon selected" : "oval-icon oval-filter-icon"}>
                            </div>
                        </Box>
                    </Box>
                    <Box>
                        <Box display="flex" flexWrap="nowrap" flexDirection="row" width={'100%'}>
                            <Box display="flex" flexWrap="nowrap" flexDirection="column" width={'98%'}>
                                <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center" justifyContent="space-between">
                                    <Box pl={2} flexWrap="nowrap" width="47%">
                                        <Box className="site-label" height="1em">
                                            <OverflowTextComponent text={dataItem.senderSite} />
                                        </Box>
                                    </Box>
                                    <Box width="6%">
                                        <FontAwesomeIcon size="lg" icon={faLongArrowAltRight} />
                                    </Box>
                                    <Box pl={2} flexWrap="nowrap" width="47%" textAlign="right">
                                        <Box className="site-label" height="1em">
                                            <OverflowTextComponent text={dataItem.receiverSite} />
                                        </Box>
                                    </Box>
                                </Box>
                                <span className="line-copy"></span>
                                <Box display="flex" flexWrap="nowrap" flexDirection="row" pl={2}>
                                    <Box width="50%">
                                        <span className="flow-card-font">N° commande</span>
                                        <Box className="flow-card-font-p" maxHeight="2em">
                                            <OverflowTextComponent text={dataItem.zephyrOrderNumber} />
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <span className="flow-card-font">Nom bénéficiaire</span>
                                        <Box className="flow-card-font-p" maxHeight="2em">
                                            <OverflowTextComponent text={dataItem.beneficiaryName} />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" flexWrap="nowrap" flexDirection="row" pl={2}>
                                    <Box width="32%">
                                        <span className="flow-card-font">Produit transporté</span>
                                        <Box className="flow-card-font-p" maxHeight="2em">
                                            <OverflowTextComponent text={dataItem.productCode} />
                                        </Box>
                                    </Box>
                                    <Box width="28%">
                                        <span className="flow-card-font">Heure(s) sur site</span>
                                        <p className="flow-card-font-p" id="flow-number">{dataItem.arrivalTimeAtReceiverSite
                                            ? `${Date.getTimeFromIsoString(dataItem.arrivalTimeAtReceiverSite)} ${arrivalEndTime ?
                                                ' -> ' + Date.getTimeFromIsoString(arrivalEndTime) : ''}`
                                            : ''}</p>
                                    </Box>
                                    <Box width="40%">
                                        <span className="flow-card-font">J/ RAP/ RAL</span>
                                        <p className={remainingQuantityColorClassName}>
                                            {dataItem.remainingDays}<span className="unit-label">J</span>
                                            /&nbsp;{dataItem.remainingQuantityToBePlanned ?? 0 }<span className="unit-label">T</span>
                                            /&nbsp;{dataItem.remainingQuantityToBeDelivered ?? 0}<span className="unit-label">T</span>
                                        </p>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {(dataItem.negotiatedPurchasePriceKind && dataItem.negotiatedPurchaseUnitPrice) ?
                        (<>
                            <span className="line-copy"></span>
                            <Box pl={2} pt="8px" pb="8px" display="flex" flexDirection="row" alignItems="center">
                                <Box className="flow-card-font" pr={1}>Tarif achat négocié :</Box>
                                <table className="table-negotiated-purchase-price">
                                    <tbody>
                                        <tr>
                                            <td>
                                                {dataItem.negotiatedPurchasePriceVehicleTypeGroupId}
                                            </td>
                                            <td>
                                                {`${dataItem.negotiatedPurchaseUnitPrice?.toCurrencyString()} / ${negotiatedPurchasePriceKindLabel}`}
                                            </td>
                                            {(dataItem.hasOverridesOnNegotiatedTripsOfExternalTransporters || dataItem.hasOverridesOnNegotiatedTripsOfInternalTransporters) && (
                                                <td>
                                                    {(dataItem.hasOverridesOnNegotiatedTripsOfExternalTransporters && dataItem.hasOverridesOnNegotiatedTripsOfInternalTransporters)
                                                        ? "Transp. Int/Ext"
                                                        : dataItem.hasOverridesOnNegotiatedTripsOfExternalTransporters
                                                            ? "Transp. externes"
                                                            : "Transp. internes"
                                                    }
                                                </td>
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                                <Box ml={1} className="negotiated-purchase-price-kind-logo">{negotiatedPurchasePriceKindLabel}</Box>
                            </Box>
                        </>)
                        : <Box height="43px" />}
                </Box>
            </Box>
        )
    }

    const itemSize = SizingUtilities.itemSize;
    const listWidth = SizingUtilities.listWidth;

    const handleShowFlowsChange = (): void => {
        const newShowHiddenFlowsValue = !showHiddenFlows;
        const transportFlows = newShowHiddenFlowsValue ? props.transportFlowList.filter(x => x.isHidden) : props.transportFlowList.filter(x => !x.isHidden);
        setTransportFlowList(transportFlows);
        setShowHiddenFlows(newShowHiddenFlowsValue)
    }

    return (
        <Drawer
            className='drawer-flow-management'
            variant="persistent"
            anchor="right"
            open={props.isFlowManagementDrawerOpened}
            classes={{
                paper: 'drawer-flow-management-paper'
            }}
        >
            <div className='toolbar-styles' />
            <Box display="flex" flexDirection="column" flex="nowrap" className="content-drawer-flows">
                <Box display="flex" pt={2} flexDirection="column" flex="nowrap" className="content-header-drawer-flows">
                    <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center">
                        <Box width="60%" pl={1} className="title-flow">
                            Gestion des Flux
                        </Box>
                        <Box width="20%">
                            <Tooltip title="Créer un nouveau Flux" placement="bottom">
                                <Avatar className="btn-actions">
                                    <IconButton className="btn-icon" size="small" aria-label="Add" onClick={() => props.handleOpenPopupCreateFlow()}>
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </IconButton>
                                </Avatar>
                            </Tooltip>
                        </Box>
                        <Box pr={1} display="flex" width="20%" flexDirection="row" justifyContent="flex-end">
                            <Box style={{ cursor: 'pointer' }} onClick={props.handleFlowManagementDrawerClose}>
                                <FontAwesomeIcon size="lg" icon={faTimes} />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center" className="header-item-section">
                        <Input disableUnderline className={props.inputSearchFlowValue.length > 2 ? 'input-search-vehicles search-text-active' : 'input-search-vehicles'} inputRef={props.inputSearchFlowRef}
                            endAdornment={<>
                                <InputAdornment position="end" classes={
                                    {
                                        root: 'input-icon-close-root'
                                    }
                                }>
                                    <FontAwesomeIcon icon={faTimes} onClick={() => props.clearSearchFlowText()} />
                                </InputAdornment>
                                <InputAdornment position="end" classes={
                                    {
                                        root: 'input-icon-search-root'
                                    }
                                }>
                                    <FontAwesomeIcon icon={faSearch} className="icon-search" />
                                </InputAdornment>
                            </>}
                            placeholder="Rechercher ..." onChange={(event) => props.handleSearchFlowKeyPress(event.target.value)} />
                    </Box>
                    <Box display="flex" flexWrap="nowrap" flexDirection="row">
                        <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center" className="header-item-section" width="50%">
                            {checkboxFilter("J+1", 1)}
                            {checkboxFilter("J+2", 2)}
                            {checkboxFilter("J+3", 3)}
                        </Box>
                        <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center" className="header-item-section" width="50%" justifyContent="flex-end">
                            <Box ml='4px'>
                                <Tooltip title="Afficher / masquer les Flux masqués" placement="bottom">
                                    <Box className={hiddenFlows?.length > 0 ? (showHiddenFlows ? "btn-remainning-quantity filter-selected" : "btn-remainning-quantity") : "btn-remainning-quantity disabled"}
                                        onClick={() => hiddenFlows?.length > 0 ? handleShowFlowsChange() : {}}>
                                        <FontAwesomeIcon icon={faEyeSlash} />
                                    </Box>
                                </Tooltip>
                            </Box>
                            <Box ml='4px' width="44px" className={hasNegativeFlowCLassName + ''}>
                                {!props.isNegativeRemainingQtyFilterSelected ?
                                    <Box className="btn-remainning-quantity" onClick={() => props.searchTransportFlow(true)}>&le;&nbsp;0</Box>
                                    :
                                    <Box className="btn-remainning-quantity filter-selected" onClick={() => props.searchTransportFlow(false)}>&le;&nbsp;0</Box>
                                }
                            </Box>
                        </Box>
                    </Box >
                </Box >
                <Box display="flex" p={2} flexDirection="column" flex="nowrap" className="container-flows" id="container-flows">
                    {transportFlowList.length > 0 ?
                        <Box height="100%">
                            <AutoSizer>
                                {({ height }) => (
                                    <FixedSizeList height={height} width={listWidth} itemSize={itemSize} itemCount={transportFlowList.length}>
                                        {renderRow}
                                    </FixedSizeList>
                                )}
                            </AutoSizer>
                        </Box>
                        :
                        <></>
                    }
                </Box>
            </Box >
        </Drawer >
    );
}
