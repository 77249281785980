import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { SizingUtilities } from '../../SizingUtilities';
import { LogisticsUnitLightModelExtended } from '../models/LogisticsUnitLightModelExtended';

interface LogisticsUnitProps {
    logisticsUnitsArray: Array<LogisticsUnitLightModelExtended>,
    sort: SortDescriptor[],
    handleSortChange: (e: GridSortChangeEvent) => void,
    handleSortColumnChange: (sortItems: SortDescriptor[]) => void,
    handleSelectedLogisticsUnit: (logisticsUnitId: string, name: string, selected: boolean) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const LogisticsUnitsGridName = 'logisticsUnits';
const ModuleKey = AppModule.LogisticsUnitLogiticiansReferential;

export const LogisticsUnitComponent = (props: LogisticsUnitProps): JSX.Element => {
    const [skip, setSkip] = useState<number>(0);

    const forceUpdate = useForceUpdate();

    const getLogisticsUnitLogisticsUnitsWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, LogisticsUnitsGridName, fieldName, columnWidth);
    }

    const getLogisticsUnitGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, LogisticsUnitsGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            LogisticsUnitsGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, LogisticsUnitsGridName, event.columns);
        forceUpdate();
    }

    const handleRowClick = (event: GridRowClickEvent): void => {
        props.handleSelectedLogisticsUnit(event.dataItem.logisticsUnitId, event.dataItem.name, !event.dataItem.selected);
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.logisticsUnitsArray.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: LogisticsUnitLightModelExtended[] = orderBy(props.logisticsUnitsArray, props.sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="colas_logistics-unit-grid">
            <Grid
                className="logistics-unit-grid"
                data={gridData}
                selectedField="selected"
                sortable
                reorderable
                resizable
                sort={props.sort}
                onRowClick={e => handleRowClick(e)}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={props.handleSortChange}
                editField="inEdit"
                rowHeight={rowHeight}
                scrollable="virtual"
                skip={skip}
                total={totalGrid}
                pageSize={gridPageSize}
                onPageChange={pageChange}
                style={gridStyle}
            >
                <GridColumn field="empty" orderIndex={0} title=" " width={10} resizable={false} reorderable={false} />
                <GridColumn field="label" orderIndex={getLogisticsUnitGridOrderIndexColumn("label", 1)} width={getLogisticsUnitLogisticsUnitsWidth("label", 240)} title="Zone Logistique" />
                <GridColumn field="agencyId" orderIndex={getLogisticsUnitGridOrderIndexColumn("agencyId", 2)} width={getLogisticsUnitLogisticsUnitsWidth("agencyId", 80)} title="Agence (UE)" />
                <GridColumn field="agencyLabel" orderIndex={getLogisticsUnitGridOrderIndexColumn("agencyLabel", 3)} width={getLogisticsUnitLogisticsUnitsWidth("agencyLabel", 210)} title="Libéllé UE" />
                <GridColumn field="logisticsUnitId" orderIndex={getLogisticsUnitGridOrderIndexColumn("logisticsUnitId", 4)} width={getLogisticsUnitLogisticsUnitsWidth("logisticsUnitId", 100)} title="Site (UP)" />
                <GridColumn field="businessActivitiesString" orderIndex={getLogisticsUnitGridOrderIndexColumn("businessActivitiesString", 5)} width={getLogisticsUnitLogisticsUnitsWidth("businessActivitiesString", 180)} title="BU" />
            </Grid>
        </Box>
    );
}
