import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import Select, { ActionMeta, components, OptionProps } from 'react-select';
import { ReactSelectModel } from '../models/ReactSelectModel';
import { AgencyLightModel } from '../services/dataContracts/queryStack/AgencyLightModel';

interface AddDispatcherAgenciesDialogComponentProps {
    agencyList: AgencyLightModel[],
    dispatcherAgenciesList: AgencyLightModel[],
    selectedAgencies: ReactSelectModel[],
    handleChangeAgenciesSelected: (opts: Array<ReactSelectModel>) => void,
    handleClickValidateDispatcherAgencies: () => void
}

const OptionItem = (props: OptionProps<ReactSelectModel>): JSX.Element => {
    return (
        <div>
            <components.Option {...props} className="selected-item">
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label className="selected-item-label">{props.data.label}</label>
            </components.Option>
        </div>
    );
}

export const AddDispatcherAgenciesDialogComponent = (props: AddDispatcherAgenciesDialogComponentProps): JSX.Element => {
    const [selectedOptions, setSelectedOptions] = useState<ReactSelectModel[]>([]);

    const dispatcherAgencies = props.dispatcherAgenciesList.map(x => x.agencyId);
    const agencyArray: ReactSelectModel[] = props.agencyList.filter(x => !dispatcherAgencies.includes(x.agencyId)).map(x => {
        return { label: x.agencyLabel + " - " + x.agencyId, value: x.agencyId }
    });

    const handleChangeTransportersSelected = (opts: Array<ReactSelectModel>, event: ActionMeta<ReactSelectModel>): void => {
        switch (event.action) {
            case "select-option":
                setOptions(event.option.value, opts);
                break;
            case "deselect-option":
                removeOptions(event.option.value, opts);
                break;
            case "remove-value":
                removeOptions(event.removedValue.value, opts);
                break;
            case "clear":
                setSelectedOptions([]);
                props.handleChangeAgenciesSelected([]);
                break;
            default:
                return;
        }
    }

    const setOptions = (value: string, opts: Array<ReactSelectModel>): void => {
        setSelectedOptions(opts);
        props.handleChangeAgenciesSelected(opts);
    }

    const removeOptions = (value: string, opts: Array<ReactSelectModel>): void => {
        const options: Array<ReactSelectModel> = opts.filter(x => x.value !== value);
        setSelectedOptions(options);
        props.handleChangeAgenciesSelected(options);
    }

    return (
        <Box display="flex" flexDirection="column" className="add-dispatcher-agencies-dialog-content">
            <Box display="flex" flexDirection="column" className="content">
                <Box className="label">Agences</Box>
                <Box display="flex" flexDirection="row">
                    <Select
                        className="input-select"
                        menuPosition="fixed"
                        menuShouldBlockScroll={true}
                        isMulti
                        options={agencyArray}
                        value={selectedOptions}
                        placeholder="Sélectionner"
                        onChange={(opts, event) => handleChangeTransportersSelected(opts as Array<ReactSelectModel>, event)}
                        components={{
                            Option: (props) => <OptionItem {...props} />
                        }}
                        hideSelectedOptions={false}
                        blurInputOnSelect={false}
                        closeMenuOnSelect={false}
                    />
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Button variant="contained" color="primary" title="Valider" onClick={props.handleClickValidateDispatcherAgencies} disabled={props.selectedAgencies.length == 0}>
                    Valider
                </Button>
            </Box>
        </Box>
    );
}
