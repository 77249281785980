import { GridCellProps, GridRowClickEvent } from '@progress/kendo-react-grid';
import React from 'react';
import { DeliveryTripLightModelExtended } from '../models/DeliveryTripLightModelExtended';

interface StatusCellProps extends GridCellProps{
    selectRowHandler: (dataItem: DeliveryTripLightModelExtended, event?: GridRowClickEvent) => void
}

export const CustomTripStatusCellComponent = (props: StatusCellProps): JSX.Element => {

    const labelValue: string = props.dataItem.tripStatus;
    const fieldValue: string = props.dataItem.statusField;
    let defaultRendering: JSX.Element = null;

    defaultRendering = (
        <td>
            <div onClick={() => props.selectRowHandler(props.dataItem)} className={fieldValue + '-trip'}>{labelValue}</div>
        </td>
    );

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}
