import { Box, Button } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ToastService from 'src/ToastService';
import { AppModule, LocalStorage } from 'src/utils/Storage';
import { ScaleLoaderComponent } from '../../../shared/components/Common/ScaleLoaderComponent';
import SimpleDialog from '../../../shared/components/Common/SimpleDialog';
import BusinessErrors from '../../../utils/BusinessErrors';
import '../ReferentialsStyles.scss';
import { AddDriverDialogComponent } from './components/AddDriverDialog/AddDriverDialogComponent';
import { EditInternalDriverDialogComponent } from './components/EditInternalDriverDialogComponent';
import { HeaderContentComponent } from './components/HeaderContentComponent';
import { InternalDriversComponent } from './components/InternalDriversComponent';
import { TemporaryDriverDialogComponent } from './components/TemporaryDriverDialogComponent';
import './InternalDriversReferentialStyles.scss';
import { DriverCandidateToAddExtended } from './models/DriverCandidateToAddExtended';
import { InternalDriverLightModelExtended } from './models/InternalDriverLightModelExtended';
import { SelectedInternalDriverModel } from './models/SelectedInternalDriverModel';
import { AddDriverRequestArgs } from './services/dataContracts/controller/AddDriverRequestArgs';
import { AddTemporaryDriverRequestArgs } from './services/dataContracts/controller/AddTemporaryDriverRequestArgs';
import { ChangeInternalDriverEnabledStatusRequestArgs } from './services/dataContracts/controller/ChangeInternalDriverEnabledStatusRequestArgs';
import { DriverCandidateToAdd } from './services/dataContracts/controller/DriverCandidateToAdd';
import { UpdateDriverRequestArgs } from './services/dataContracts/controller/UpdateDriverRequestArgs';
import { UpdateTemporaryDriverRequestArgs } from './services/dataContracts/controller/UpdateTemporaryDriverRequestArgs';
import { InternalDriversReferentialApiClient } from './services/InternalDriversReferentialApiClient';

interface InternalDriversReferentialViewProps {
    isForTemporaryDrivers: boolean
}

export const InternalDriversReferentialView = (props: InternalDriversReferentialViewProps): JSX.Element => {

    const inputSearchInternalDriversRef: React.RefObject<HTMLInputElement> = React.useRef(null);
    const initialSort: SortDescriptor[] = [{ field: 'lastName', dir: 'asc' }];

    const [internalDriversList, setInternalDriversList] = useState<Array<InternalDriverLightModelExtended>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isAddInternalDriverDialogOpened, setIsAddInternalDriverDialogOpened] = useState<boolean>(false);
    const [isEditInternalDriverDialogOpened, setIsEditInternalDriverDialogOpened] = useState<boolean>(false);
    const [isTemporaryDriverDialogOpened, setIsTemporaryDriverDialogOpened] = useState<boolean>(false);
    const [selectedInternalDriver, setSelectedInternalDriver] = useState<SelectedInternalDriverModel>({ employeeId: null, fullName: null });
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);

    const [activeStep, setActiveStep] = useState<number>(0);
    const [mgrh, setMgrh] = useState<string>('');
    const [driverLastName, setDriverLastName] = useState<string>('');
    const [driverSearchLoading, setDriverSearchLoading] = useState<boolean>(false);
    const [driverChoices, setDriverChoices] = useState<DriverCandidateToAddExtended[]>([]);
    const [driverToAdd, setDriverToAdd] = useState<DriverCandidateToAdd>(null);
    const [temporaryDriverDialogEditMode, setTemporaryDriverDialogEditMode] = useState<boolean>(false);
    const [showConfirmationAddTemporaryDriverModal, setShowConfirmationAddTemporaryDriverModal] = useState<boolean>(false);
    const [temporaryDriver, setTemporaryDriver] = useState<AddTemporaryDriverRequestArgs>({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        internalTransporterId: ''
    });
    const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(false);
    const [reloading, setReloading] = useState<boolean>(false);

    useEffect(() => {
        getInternalDrivers("");
    }, []);

    const clearSearchText = (): void => {
        inputSearchInternalDriversRef.current.value = "";
        driversKeyPressed("");
    }

    const InternalDriversKeyPressed = debounce((text: string): void => {
        if (text.length >= 3) {
            driversKeyPressed(text);
        } else if (text.length === 0) {
            driversKeyPressed("");
        }
    }, 500);

    const driversKeyPressed = (text: string): void => {
        getInternalDrivers(text);
    }

    const getInternalDrivers = (searchText: string, errorMessage?: string, listErrorMessage?: Array<string>, successMessage?: string, endMessage?: string): void => {
        setLoading(true);
        setSelectedInternalDriver({ employeeId: null, fullName: null });
        InternalDriversReferentialApiClient.GetInternalDrivers(searchText, props.isForTemporaryDrivers)
            .then(response => {
                setInternalDriversList(response.data);

                if (errorMessage)
                    ToastService.showErrorToast(errorMessage, listErrorMessage, endMessage);
                else if (successMessage)
                    ToastService.showSuccessToast(successMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const inputSearchInternalDriversValue: string = inputSearchInternalDriversRef.current === null || inputSearchInternalDriversRef.current === undefined ? '' : inputSearchInternalDriversRef.current.value;

    const handleAddInternalDriverDialogClick = (): void => {
        setIsAddInternalDriverDialogOpened(true);
    }

    const handleCloseAddInternalDriverDialog = (): void => {
        setIsAddInternalDriverDialogOpened(false);
        setActiveStep(0);
        setDriverLastName('');
        setMgrh('');
        setDriverChoices([]);
        setDriverToAdd(null);
    }

    const handleEditInternalDriverDialogClick = (): void => {
        setIsEditInternalDriverDialogOpened(true);
    }

    const handleCloseEditInternalDriverDialog = (): void => {
        setIsEditInternalDriverDialogOpened(false);
    }

    const handleAddTemporaryDriverDialogClick = (): void => {
        setIsTemporaryDriverDialogOpened(true);
    }

    const handleCloseTemporaryDriverDialog = (): void => {
        setTemporaryDriver({
            firstName: '',
            lastName: '',
            phoneNumber: '',
            internalTransporterId: ''
        });
        setIsTemporaryDriverDialogOpened(false);
        setTemporaryDriverDialogEditMode(false);
    }

    const handleEditTemporaryDriverDialogClick = (): void => {
        setTemporaryDriverDialogEditMode(true);
        setIsTemporaryDriverDialogOpened(true);
    }

    const handleSelectedInternalDriver = (employeeId: string, fullName: string): void => {
        const newInternalDriversList = [...internalDriversList];
        if (selectedInternalDriver.employeeId) {
            newInternalDriversList.find(d => d.employeeId === selectedInternalDriver.employeeId).selected = false;
        }

        if (employeeId) {
            newInternalDriversList.find(d => d.employeeId === employeeId).selected = true;
        }

        setInternalDriversList(newInternalDriversList);
        setSelectedInternalDriver({ employeeId: employeeId, fullName: fullName });
    }

    const handleDriverMgrhChange = (value: string) => {
        setMgrh(value);
    }

    const handleDriverLastNameChange = (value: string) => {
        setDriverLastName(value);
    }

    const searchDriverByMgrh = (mgrh: string): void => {
        setDriverSearchLoading(true);

        InternalDriversReferentialApiClient.SearchDriverByMgrh(mgrh.trim())
            .then((res) => {

                setDriverSearchLoading(false);

                const driverCandidateToAdd: DriverCandidateToAdd = res.data;
                if (driverCandidateToAdd) {
                    switch (driverCandidateToAdd.existInLorieDb) {
                        case true:
                            handleSearchInReferentialGrid(driverCandidateToAdd.mgrh);
                            return;

                        case false:
                            var driverCandidateToAddExtended: DriverCandidateToAddExtended = driverCandidateToAdd;
                            driverCandidateToAddExtended.selected = false;
                            setDriverChoices([driverCandidateToAddExtended]);
                            setActiveStep(1);
                            return;

                        default:
                            return;
                    }
                }

                ToastService.showErrorToast("Aucun chauffeur trouvé dans le référentiel");
            });
    }

    const searchDriverByLastName = (lastName: string): void => {
        setDriverSearchLoading(true);

        InternalDriversReferentialApiClient.SearchDriverByLastName(lastName.trim())
            .then((res) => {

                setDriverSearchLoading(false);

                const driverCandidatesToAdd: DriverCandidateToAdd[] = res.data;
                if (driverCandidatesToAdd.length > 0) {
                    if (driverCandidatesToAdd.length >= 10) {
                        ToastService.showErrorToast("Trop de chauffeurs récupérés. Merci de saisir le matricule du chauffeur.");
                        return;

                    } else {
                        driverCandidatesToAdd.forEach((item: DriverCandidateToAddExtended) => item.selected = false);
                        setDriverChoices(driverCandidatesToAdd);
                        setActiveStep(1);
                        return;
                    }
                }

                ToastService.showErrorToast("Aucun chauffeur trouvé dans le référentiel");
            });
    }

    const handleSearchInReferentialGrid = (mgrh: string): void => {
        inputSearchInternalDriversRef.current.value = mgrh;
        handleCloseAddInternalDriverDialog();
        getInternalDrivers(mgrh);

        ToastService.showErrorToast("Ce chauffeur est déjà présent dans LORIE, il faut vérifier que ce dernier soit bien visible pour votre zone logistique");
    }

    const handleConfirmStep = (driver: DriverCandidateToAddExtended): void => {
        setDriverToAdd(driver);
        setActiveStep(2);
    }

    const handlePreviousStep = (): void => {
        setActiveStep(activeStep - 1);
    }

    const confirmAddDriver = (phoneNumber: string): void => {
        const fullName: string = (driverToAdd.lastName && driverToAdd.firstName) ? driverToAdd.lastName + " " + driverToAdd.firstName :
            ((driverToAdd.lastName && !driverToAdd.firstName) ? driverToAdd.lastName :
                ((!driverToAdd.lastName && driverToAdd.firstName) ? driverToAdd.firstName : ""));

        const driverRequestArgs: AddDriverRequestArgs = {
            employeeId: driverToAdd.employeeId,
            firstName: driverToAdd.firstName,
            lastName: driverToAdd.lastName,
            fullName: fullName,
            contentVersion: driverToAdd.contentVersion,
            functionIdentifier: driverToAdd.functionIdentifier,
            level3Segmentation: driverToAdd.level3Segmentation,
            level4Segmentation: driverToAdd.level4Segmentation,
            mgrh: driverToAdd.mgrh,
            ownerAgencyId: driverToAdd.ownerAgencyId,
            standardCostCategoryIdentifier: driverToAdd.standardCostCategoryIdentifier,
            phoneNumber: phoneNumber,
            longDistanceTravelAllowance: driverToAdd.longDistanceTravelAllowance,
            dailyCost: driverToAdd.dailyCost,
            nightlyCost: driverToAdd.nightlyCost,
            exceptionalCost: driverToAdd.exceptionalCost,
            isTimeClockable: driverToAdd.isTimeClockable,
            gender: driverToAdd.gender
        };

        InternalDriversReferentialApiClient.AddDriver(driverRequestArgs)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                inputSearchInternalDriversRef.current.value = driverToAdd.employeeId;
                handleCloseAddInternalDriverDialog();
                getInternalDrivers(driverToAdd.employeeId);
            });
    }

    const handleEditInternalDriverClick = (requestArgs: UpdateDriverRequestArgs): void => {
        setIsEditInternalDriverDialogOpened(false);
        InternalDriversReferentialApiClient.UpdateDriver(requestArgs)
            .then((res) => {
                const errors: string[] = BusinessErrors.Get(res.data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                }
                else {
                    //On ne raffraichit que la ligne car le composant avec la virtualisation ne gère pas correctement le scroll
                    const newList = [...internalDriversList];
                    const currentDriver = newList.find(x => x.employeeId == requestArgs.employeeId);
                    currentDriver.phoneNumber = requestArgs.phoneNumber;

                    setInternalDriversList(newList);
                }
            });
    }

    const handleTemporaryDriverLastNameChange = (value: string): void => {
        setTemporaryDriver({
            ...temporaryDriver,
            lastName: value.trim()
        });
    }

    const handleTemporaryDriverFirstNameChange = (value: string): void => {
        setTemporaryDriver({
            ...temporaryDriver,
            firstName: value.trim()
        });
    }

    const handleTemporaryDriverPhoneNumberChange = (value: string): void => {
        setTemporaryDriver({
            ...temporaryDriver,
            phoneNumber: value.trim()
        });
    }

    const handleChangeInternalTransporter = (internalTransporterId: string): void => {
        setTemporaryDriver({
            ...temporaryDriver,
            internalTransporterId: internalTransporterId
        });
    }

    const saveTemporaryDriver = (): void => {
        InternalDriversReferentialApiClient.TemporaryDriverExists(temporaryDriver.lastName, temporaryDriver.firstName, temporaryDriver.phoneNumber)
            .then((res) => {
                if (res.data) {
                    setIsTemporaryDriverDialogOpened(false);
                    setShowConfirmationAddTemporaryDriverModal(true);
                } else {
                    addTemporaryDriver();
                }
            });
    }

    const addTemporaryDriver = (): void => {
        InternalDriversReferentialApiClient.AddTemporaryDriver(temporaryDriver)
            .then((res) => {
                const errors: string[] = BusinessErrors.Get(res.data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                const employeeId = res.data.extraResult;
                inputSearchInternalDriversRef.current.value = employeeId;
                handleCloseTemporaryDriverDialog();
                if (showConfirmationAddTemporaryDriverModal)
                    setShowConfirmationAddTemporaryDriverModal(false);
                getInternalDrivers(employeeId);
            });
    }

    const handleHideConfirmationAddTemporaryDriverModal = (): void => {
        setShowConfirmationAddTemporaryDriverModal(false);
        handleCloseTemporaryDriverDialog();
    }

    const editTemporaryDriver = (employeeId: string, phoneNumber: string): void => {
        const requestArgs: UpdateTemporaryDriverRequestArgs = {
            employeeId: employeeId,
            phoneNumber: phoneNumber
        };

        InternalDriversReferentialApiClient.UpdateTemporaryDriver(requestArgs)
            .then((res) => {
                const errors: string[] = BusinessErrors.Get(res.data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                const newList = [...internalDriversList];
                const currentDriver = newList.find(x => x.employeeId == employeeId);
                currentDriver.phoneNumber = phoneNumber;
                setInternalDriversList(newList);
                handleCloseTemporaryDriverDialog();
            });
    }

    const handleDriverEnableStatusChanged = (employeeId: string, isEnabled: boolean): void => {
        const requestArgs: ChangeInternalDriverEnabledStatusRequestArgs = {
            employeeId: employeeId,
            isEnabled: isEnabled
        };

        InternalDriversReferentialApiClient.ChangeInternalDriverEnabledStatus(requestArgs)
            .then((res) => {
                const errors: string[] = BusinessErrors.Get(res.data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                const internalDrivers = [...internalDriversList];
                const driver = internalDrivers.find(x => x.employeeId === requestArgs.employeeId);
                driver.isEnabled = requestArgs.isEnabled;
                setInternalDriversList(internalDrivers);
            });
    }

    const handleCloseConfirmModal = (): void => {
        setIsConfirmModalOpened(false);
    }

    const handleConfirmUpdateAgencyForInternalDriver = (): void => {
        InternalDriversReferentialApiClient.UpdateInternalDriverFromReferential(selectedInternalDriver.employeeId)
            .then(res => {
                const data = res?.data;
                if (data.hasBusinessErrors && data.errorType == 'AgencyNullOrEmpty') {
                    ToastService.showErrorToast("Aucune agence de rattachement pour ce chauffeur");
                    setIsConfirmModalOpened(false);
                    return;
                }
                if (data.hasBusinessErrors && data.errorType == 'AgencyIdDoesntMatchAnExistingAgency') {
                    ToastService.showErrorToast(`La nouvelle agence ${data.ownerAgencyIdFromMdm} est absente de LORIE, vous devez la rajouter dans le référentiel \"Gestion des unités d\'exploitation travaux Colas\" et relancer la mise à jour de l\'agence`);
                    setIsConfirmModalOpened(false);
                    return;
                }

                setIsConfirmModalOpened(false);
                setReloading(true);
                getInternalDrivers("");
                const ModuleKey = props.isForTemporaryDrivers ? AppModule.TemporaryDriversReferential : AppModule.InternalDriversReferential;
                var employeeId = LocalStorage.GetItem(ModuleKey, 'lastEmployeeIdSelected');
                var driverName = LocalStorage.GetItem(ModuleKey, 'lastDriverNameSelected');
                console.log(driverName);
                LocalStorage.SetItem(ModuleKey, 'lastDriverIndexSelected', 'true');
                LocalStorage.SetItem(ModuleKey, 'selected', 'true');
                handleSelectedInternalDriver(employeeId, driverName);
                setReloading(true);
            })
            .catch(e => {
                ToastService.showErrorToast("L'action a échoué en raison d'une erreur technique. Si ce problème persiste, veuillez contacter le support.");
            });
    }

    const handleEditAgencyForInternalDriverClick = (): void => {
        setIsConfirmModalOpened(true);
        setIsEditInternalDriverDialogOpened(false);
    }

    const headerContentComponent: JSX.Element = useMemo(() =>
        <HeaderContentComponent
            inputSearchInternalDriversValue={inputSearchInternalDriversValue}
            inputSearchInternalDriversRef={inputSearchInternalDriversRef}
            loadingInternalDrivers={loading}
            selectedInternalDriver={selectedInternalDriver}
            isForTemporaryDrivers={props.isForTemporaryDrivers}
            handleInternalDriversKeyPress={InternalDriversKeyPressed}
            handleClearSearchText={clearSearchText}
            handleAddInternalDriverDialogClick={handleAddInternalDriverDialogClick}
            handleEditInternalDriverDialogClick={handleEditInternalDriverDialogClick}
            handleAddTemporaryDriverDialogClick={handleAddTemporaryDriverDialogClick}
            handleEditTemporaryDriverDialogClick={handleEditTemporaryDriverDialogClick}
        />, [inputSearchInternalDriversValue, inputSearchInternalDriversRef, selectedInternalDriver.employeeId, loading, , reloading]);

    const internalDriversComponent: JSX.Element = useMemo(() =>
        <InternalDriversComponent
            internalDrivers={internalDriversList}
            sort={sort}
            isForTemporaryDrivers={props.isForTemporaryDrivers}
            handleSelectedInternalDriver={handleSelectedInternalDriver}
            handleSortChange={handleSortChange}
            handleDriverEnableStatusChanged={handleDriverEnableStatusChanged}
        />, [internalDriversList, selectedInternalDriver.employeeId, sort, reloading]);

    return (
        <Box className="internal-Drivers referencial">
            <Box display="flex" flexDirection="row" flex="wrap" className="view-title">
                {props.isForTemporaryDrivers ? "Gestion des chauffeurs intérimaires" : "Gestion des chauffeurs internes"}
            </Box>
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box display="flex" flexDirection="column" flex="wrap">
                        {headerContentComponent}
                        {(loading ?
                            <ScaleLoaderComponent loading={loading} />
                            :
                            internalDriversComponent
                        )}
                    </Box>
                </IntlProvider>
            </LocalizationProvider>
            <SimpleDialog isOpen={isAddInternalDriverDialogOpened}
                onClose={handleCloseAddInternalDriverDialog}
                closeIcon={true}
                dialogTitle="Ajouter un chauffeur Colas"
                classNameVal="add-internal-driver-dialog"
                component={
                    <AddDriverDialogComponent
                        activeStep={activeStep}
                        driverSearchLoading={driverSearchLoading}
                        mgrh={mgrh}
                        driverLastName={driverLastName}
                        driverChoices={driverChoices}
                        handleDriverMgrhChange={handleDriverMgrhChange}
                        handleDriverLastNameChange={handleDriverLastNameChange}
                        searchDriverByMgrh={searchDriverByMgrh}
                        searchDriverByLastName={searchDriverByLastName}
                        handleSearchInReferentialGrid={handleSearchInReferentialGrid}
                        confirmAddDriver={confirmAddDriver}
                        handleConfirmStep={handleConfirmStep}
                        handlePreviousStep={handlePreviousStep}
                    />
                }
            />
            <SimpleDialog isOpen={isEditInternalDriverDialogOpened}
                onClose={() => handleCloseEditInternalDriverDialog()}
                closeIcon={true}
                dialogTitle="Modifier un chauffeur interne"
                classNameVal="edit-driver-dialog"
                component={
                    <EditInternalDriverDialogComponent
                        internalDriverSelected={internalDriversList.find(d => d.employeeId === selectedInternalDriver.employeeId)}
                        handleEditInternalDriverClick={handleEditInternalDriverClick}
                        handleEditAgencyForInternalDriverClick={handleEditAgencyForInternalDriverClick}
                    />
                }
            />
            <SimpleDialog isOpen={isTemporaryDriverDialogOpened}
                onClose={handleCloseTemporaryDriverDialog}
                closeIcon={true}
                dialogTitle="Ajouter un chauffeur Colas intérimaire"
                classNameVal="add-temporary-driver-dialog"
                component={
                    <TemporaryDriverDialogComponent
                        temporaryDriver={temporaryDriver}
                        selectedTemporaryDriver={internalDriversList.find(d => d.employeeId === selectedInternalDriver.employeeId)}
                        editMode={temporaryDriverDialogEditMode}
                        handleTemporaryDriverLastNameChange={handleTemporaryDriverLastNameChange}
                        handleTemporaryDriverFirstNameChange={handleTemporaryDriverFirstNameChange}
                        handleTemporaryDriverPhoneNumberChange={handleTemporaryDriverPhoneNumberChange}
                        handleCloseTemporaryDriverDialog={handleCloseTemporaryDriverDialog}
                        handleChangeInternalTransporter={handleChangeInternalTransporter}
                        saveTemporaryDriver={saveTemporaryDriver}
                        editTemporaryDriver={editTemporaryDriver}
                    />
                }
            />
            <Modal show={showConfirmationAddTemporaryDriverModal} onHide={handleHideConfirmationAddTemporaryDriverModal} className="add-temporary-driver-modal">
                <Modal.Header closeButton>
                    <Modal.Title>ATTENTION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Voulez vous vraiment ajouter cet intérimaire qui semble déjà présent sur LORIE ?
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary" onClick={handleHideConfirmationAddTemporaryDriverModal}>
                        NON
                    </Button>
                    <Button className="primary" onClick={addTemporaryDriver}>
                        OUI
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isConfirmModalOpened} onHide={() => handleCloseConfirmModal()} className='mt-5'>
                <Modal.Header closeButton>
                    <Modal.Title>ATTENTION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    La mise à jour de l'agence sera effective dès confirmation et concernera toutes les nouvelles planifications à venir de ce chauffeur. <br /><br />

                    Les planifications et demandes de confirmation de commande dans une date future mais réalisées avant cette mise à jour resteront sur l'ancienne agence.<br /><br />

                    Libre à vous d'annuler ou modifier ces opérations si nécessaire.<br /><br />

                    Confirmez-vous la mise à jour ?
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary" onClick={() => handleCloseConfirmModal()}>
                        Annuler
                    </Button>
                    <Button className="primary" onClick={() => handleConfirmUpdateAgencyForInternalDriver()}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>
        </Box>
    );
}
