import { Box, Button } from '@mui/material';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React from 'react';
import Select from 'react-select';
import { TransporterSelectModel } from '../models/TransporterSelectModel';

interface HeaderProperties {
    isForInternalTransporters: boolean,
    tokenBcDate: Date,
    tokenAcces: boolean,
    selectedDate: Date,
    numberOfUnconfirmedCanceledVehicles: number,
    listTransporters: TransporterSelectModel[],
    tokenTransporterName: string,
    disabledButtonMngt: boolean,
    selectedTransporter: TransporterSelectModel,
    handleSearchDatePickerChange: (date: Date) => void,
    handleTransporterChange: (e: TransporterSelectModel) => void,
    handleClickOpenVehiclesDrawer: () => void,
    handleClickOpenDriversDrawer: () => void,
    handleClickOpenVehiclesPublishedCosts: () => void
}

export const HeaderComponent = (props: HeaderProperties): JSX.Element => {
    const datePickerRef: React.RefObject<DatePicker> = React.useRef(null);
    const [dateInputValue, setDateInputValue] = React.useState<Date>(props.selectedDate);
    const { isForInternalTransporters, tokenBcDate, tokenAcces, numberOfUnconfirmedCanceledVehicles, listTransporters, tokenTransporterName, selectedTransporter, disabledButtonMngt,
        handleClickOpenVehiclesDrawer, handleClickOpenDriversDrawer, handleSearchDatePickerChange, handleTransporterChange,
        handleClickOpenVehiclesPublishedCosts } = props;

    const handleDatePickerChange = (e: DatePickerChangeEvent): void => {
        const date: Date = e.target.value;
        setDateInputValue(date);
        //Quand la popup est visible ou bien lors du changement de date avec les flèches du haut ou du bas
        if (e.target.state.show || e.syntheticEvent.type == "keydown" || e.syntheticEvent.type == "keyup") {
            handleSearchDatePickerChange(date);
            (datePickerRef.current as any).shouldFocusDateInput = false;
            (datePickerRef.current as any).handleBlur = null;
        }
        else {
            (datePickerRef.current as any).handleBlur = (event) => {
                handleSearchDatePickerChange(date);
            }
        }
    }

    return (
        <Box display="flex" pt={2} pb={2} flexWrap="wrap" flexDirection="row" alignItems="center" className="transporter-header">
            <Box pr={2} width="50%" display="flex" flexWrap="wrap" flexDirection="row" alignItems="center">
                {!tokenAcces &&
                    <>
                        <Box pr={2}>
                            <LocalizationProvider language="fr-FR">
                                <IntlProvider locale="fr" >
                                    <DatePicker
                                        ref={datePickerRef} onChange={(e) => handleDatePickerChange(e)} disabled={tokenAcces}
                                        value={dateInputValue} valid={dateInputValue !== null}
                                    />
                                </IntlProvider>
                            </LocalizationProvider>
                        </Box>
                        <Box pr={2}>
                            <Select
                                className="transporter-select"
                                isDisabled={tokenAcces || listTransporters.length === 0}
                                isClearable
                                placeholder=""
                                menuPosition="fixed"
                                menuShouldBlockScroll={true}
                                options={listTransporters}
                                value={selectedTransporter}
                                onChange={(e) => handleTransporterChange(e)}
                            />
                        </Box>
                    </>
                }
                {tokenAcces &&
                    <>
                        <Box pr={2}>{`${Date.getDateFromIsoString(tokenBcDate)}`}</Box>
                        <Box pr={2}>{tokenTransporterName}</Box>
                    </>
                }
                {numberOfUnconfirmedCanceledVehicles && <Box className="not-confirmed-canceled-vehicle-number">{`camion(s) annulé(s) non confirmé(s) : ${numberOfUnconfirmedCanceledVehicles}`}</Box>}
            </Box>
            <Box width="50%" display="flex" flexDirection="row" flexWrap='wrap' justifyContent="flex-end" alignItems="center">
                <Box mb={1}>
                    <Button variant="contained" className="transporter-button" onClick={handleClickOpenVehiclesPublishedCosts} disabled={disabledButtonMngt}>
                        Préfacturation
                    </Button>
                </Box>
                {!isForInternalTransporters &&
                    <Box mb={1}>
                        <Button variant="contained" className="transporter-button" onClick={handleClickOpenVehiclesDrawer} disabled={disabledButtonMngt}>
                            Gestion des camions
                        </Button>
                    </Box>
                }
                <Box mb={1}>
                    <Button variant="contained" className="transporter-button" onClick={handleClickOpenDriversDrawer} disabled={disabledButtonMngt}>
                        Gestion des chauffeurs
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}