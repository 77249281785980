import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardContent, Divider } from '@mui/material';
import React from 'react';
import { toMinutes } from 'src/utils/TimeSpanString';
import Utilities from '../../../utils/Utilities';
import { TransportRequestComplementaryDetailsLightModel } from '../services/dataContracts/queryStack/TransportRequestComplementaryDetailsLightModel';
import './DetailsComponentStyles.scss';

interface TransportRequestDetailsProperties {
    handleDrawerRightClose: () => void,
    detailsData: TransportRequestComplementaryDetailsLightModel
}

export const TransportRequestDetailsComponent = (props: TransportRequestDetailsProperties): JSX.Element => {
    const details: TransportRequestComplementaryDetailsLightModel = props.detailsData;
    const transportRequestId: string = details ? details.transportRequestId : '';

    return (
        <Box display="flex" flexDirection="column" flex='nowrap' className='content-drawer-details'>
            <Box display="flex" pt={2} flexDirection="column" flex='nowrap' className='content-header-drawer-details'>
                <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems='center'>
                    <Box width="80%" pl={1} className='title-details'>
                        Complément d'information
                    </Box>
                    <Box pr={1} display="flex" width="20%" flexDirection="row" justifyContent="flex-end">
                        <Box style={{ cursor: 'pointer' }} onClick={props.handleDrawerRightClose}>
                            <FontAwesomeIcon size="lg" icon={faTimes} />
                        </Box>
                    </Box>
                </Box>
                <Divider style={{ height: '2px' }} />
                {transportRequestId ?
                    (
                        <>
                            <Box display="flex" p={2} flexDirection="column" flex='nowrap' className='container-details'>
                                <Box display="none" flexDirection="row">
                                    <Box pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                AL
                                            </Box>
                                            <Box className='column-value'>
                                                {transportRequestId}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Box width="65%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Modificateur
                                            </Box>
                                            <Box className='column-value'>
                                                {details.lastWriteUser}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box width="35%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Date modification
                                            </Box>
                                            <Box className='column-value'>
                                                {details.lastWriteTime ? ((new Date(details.lastWriteTime)).toShortDateTimeString()) : ''}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="column" flex='nowrap'>
                                    <Box className="column-title">
                                        Créateur
                                    </Box>
                                    <Box className='column-value'>
                                        {details.creationUser}
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Source d'application
                                            </Box>
                                            <Box className='column-value'>
                                                {details.sourceApplication}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Travail de nuit
                                            </Box>
                                            <Box className='column-value'>
                                                {details.isNightWork === null ? 'Non défini' : details.isNightWork ? 'Oui' : 'Non'}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Tel demandeur
                                            </Box>
                                            <Box className='column-value phone-number-ref'>
                                                <a href={`callTo:${Utilities.formatPhoneNumber(details.requestedByPhoneNumber)}`} data-rel="external">
                                                    {Utilities.formatPhoneNumber(details.requestedByPhoneNumber)}
                                                </a>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Tel bénéficiaire
                                            </Box>
                                            <Box className='column-value phone-number-ref'>
                                                <a href={`callTo:${Utilities.formatPhoneNumber(details.beneficiaryPhoneNumber)}`} data-rel="external">
                                                    {Utilities.formatPhoneNumber(details.beneficiaryPhoneNumber)}
                                                </a>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                N° chantier départ
                                            </Box>
                                            <Box>
                                                {details.supplierJobSiteMdmId}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                N° chantier arrivée
                                            </Box>
                                            <Box className='column-value'>
                                                {details.customerJobSiteMdmId}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {
                                    props.detailsData.transportations.map((d, index) => {
                                        return (
                                            <Box key={`d-${index}`} pb={2}>
                                                <Card>
                                                    <CardContent>
                                                        <Box display='flex' flexDirection='column' flex='nowrap'>
                                                            <Box display='flex' pb={2} flexDirection='row'>
                                                                <Box width='15%' className='title-bold-column'>
                                                                    {d.numberOfVehicles}
                                                                </Box>
                                                                <Box width='60%' className='title-bold-column'>
                                                                    {d.vehicleTypeLabel}
                                                                </Box>
                                                                <Box width='25%'>
                                                                    {d.quantity && d.quantity > 0 ? `${d.quantity}${d.unitOfMeasure}` : ''}
                                                                </Box>
                                                            </Box>
                                                            <Box display='flex' flexDirection='row'>
                                                                <Box width='25%'>
                                                                    <Box display='flex' flexDirection='column'>
                                                                        <Box className='column-title'>
                                                                            Tour(s)
                                                                        </Box>
                                                                        <Box>
                                                                            {d.numberOfTurns}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box width='25%'>
                                                                    <Box display='flex' flexDirection='column'>
                                                                        <Box className='column-title'>
                                                                            Heure début
                                                                        </Box>
                                                                        <Box>
                                                                            {`${d.arrivalTimeAtReceiverSite ? Date.getTimeFromIsoString(d.arrivalTimeAtReceiverSite) : ""}`}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box width='25%'>
                                                                    <Box display='flex' flexDirection='column'>
                                                                        <Box className='column-title'>
                                                                            Heure fin
                                                                        </Box>
                                                                        <Box>
                                                                            {`${(d.arrivalTimeAtReceiverSite && d.arrivalSlotDuration)
                                                                                ? Date.getTimeFromIsoString(new Date(d.arrivalTimeAtReceiverSite).addMinutes(toMinutes(d.arrivalSlotDuration)))
                                                                                : ''}`}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box width='25%'>
                                                                    <Box display='flex' flexDirection='column'>
                                                                        <Box className='column-title'>
                                                                            Chargement
                                                                        </Box>
                                                                        <Box>
                                                                            {`${d.loadingTime ? Date.getTimeFromIsoString(d.loadingTime) : ""}`}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box pt={1} display='flex' flexDirection='row'>
                                                                <Box width='25%'>
                                                                    <Box display='flex' flexDirection='column'>
                                                                        <Box className='column-title'>
                                                                            Finishers
                                                                        </Box>
                                                                        <Box>
                                                                            {d.numberOfFinishers}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box width='25%'>
                                                                    <Box display='flex' flexDirection='column'>
                                                                        <Box className='column-title'>
                                                                            Continuité
                                                                        </Box>
                                                                        <Box>
                                                                            {d.isUnloadingContinuityRequired ? 'Oui' : 'Non'}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box width='25%'>
                                                                    <Box display='flex' flexDirection='column'>
                                                                        <Box className='column-title'>
                                                                            Coût
                                                                        </Box>
                                                                        <Box>
                                                                            {d.estimatedCost ? `${d.estimatedCost} ` : ''}{d.estimatedCost ? (<span>&euro;</span>) : ''}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box width='25%'>
                                                                    <Box display='flex' flexDirection='column'>
                                                                        <Box className='column-title'>
                                                                            Déchargement
                                                                        </Box>
                                                                        <Box>
                                                                            {d.unloadingDuration && d.unloadingDuration !== '00:00:00' ? d.unloadingDuration : ''}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        </>
                    ) : ''
                }
            </Box>
        </Box>
    );
}