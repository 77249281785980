import { Box, TextField } from '@mui/material';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import { ScaleLoaderComponent } from 'src/shared/components/Common/ScaleLoaderComponent';
import { NewPreInvoiceRegularizationInitModelDetailExtended } from '../models/NewPreInvoiceRegularizationInitModelDetailExtended';
import { NewPreInvoiceRegularizationInitModelExtended } from '../models/NewPreInvoiceRegularizationInitModelExtended';
import { NewPreInvoiceRegularizationInitModelVehicleStatus } from '../services/dataContracts/queryStack/NewPreInvoiceRegularizationInitModelVehicleStatus';
import { TransportSalesApiClient } from '../services/TransportSalesApiClient';

interface RegularizationPreInvoiceTemplateProps {
    preInvoiceId: string,
    handleRefreshPreInvoiceRegularizations: (preInvoiceRegularizations: NewPreInvoiceRegularizationInitModelExtended) => void
}

export const RegularizationPreInvoiceTemplateComponent = (props: RegularizationPreInvoiceTemplateProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [preInvoiceRegularizations, setPreInvoiceRegularizations] = useState<NewPreInvoiceRegularizationInitModelExtended>();
    
    useEffect(() => {
        TransportSalesApiClient.GetNewPreInvoiceRegularizationInitData(props.preInvoiceId)
        .then(res => {
            setPreInvoiceRegularizations(res.data);
        })
        .finally(()=> setLoading(false));
    }, []);

    const handleChangeRegularizationPrice = (event: NumericTextBoxChangeEvent, index: number): void => {
        const preInvoiceRegularizationsData = {...preInvoiceRegularizations};
        preInvoiceRegularizationsData.details[index].regularizationPrice = event.target.value === 0 ? null : event.target.value;
        setPreInvoiceRegularizations(preInvoiceRegularizationsData);

        props.handleRefreshPreInvoiceRegularizations(preInvoiceRegularizationsData);
    }

    const handleChangeComment = (comment: string, index: number): void => {
        const preInvoiceRegularizationsData = {...preInvoiceRegularizations};
        preInvoiceRegularizationsData.details[index].regularizationComments = comment;
        setPreInvoiceRegularizations(preInvoiceRegularizationsData);

        props.handleRefreshPreInvoiceRegularizations(preInvoiceRegularizationsData);
    }

    return (
        <>
            {(loading ?
                <div className="loading-div">
                    <ScaleLoaderComponent loading={loading} />
                </div>
                :
                <Box display='flex' flexDirection='column' p={1}>
                    <Box pb={2}>
                        <Box display="flex" flexDirection="row">
                            <Box width="50%" />
                            <Box width="20%" className="title" pr={1}>
                                Ajouter / Soustraire
                            </Box>
                            <Box width="30%" className="title">
                                Commentaire facturation
                            </Box>
                        </Box>
                        {preInvoiceRegularizations.details.map((p: NewPreInvoiceRegularizationInitModelDetailExtended, index: number) => {
                            const title = p.transporterId 
                                ? (p.vehicleId !== null && p.vehicleLicencePlate) 
                                    ? `${p.vehicleLicencePlate} / ${p.transporterName}`
                                    : `${p.planningVehicleId} / ${p.transporterName}`
                                : `${p.planningVehicleId} /`;
                            const vehicleClassName = p.vehicleStatus === NewPreInvoiceRegularizationInitModelVehicleStatus.new 
                                ? "vehicle-added" 
                                : p.vehicleStatus === NewPreInvoiceRegularizationInitModelVehicleStatus.removed
                                    ? "vehicle-removed"
                                    : "";

                            return (
                                <Box display="flex" flexDirection="row" className="vehicle-line" alignItems="center" key={`line-${index}`} pb={1}>
                                    <Box width="50%" className={`vehicle-title ${vehicleClassName}`} display="flex" flexDirection="row">
                                        <Box width="5px" mr={3}>
                                            {p.hasAlreadyBeenRegularized ? 
                                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className="icon-regularization">
                                                    R
                                                </Box>
                                            : ''}
                                        </Box>
                                        <Box>
                                            {title}
                                        </Box>
                                    </Box>
                                    <Box width="20%" pr={1}>
                                        <LocalizationProvider language="fr-FR">
                                            <IntlProvider locale="fr" >
                                                <NumericTextBox
                                                format={{
                                                    maximumFractionDigits: 2,
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                    currencyDisplay: 'symbol'
                                                }}
                                                onChange={(event) => handleChangeRegularizationPrice(event, index)}
                                                value={p.regularizationPrice}
                                                spinners={false} />
                                            </IntlProvider>
                                        </LocalizationProvider>
                                    </Box>
                                    <Box width="30%">
                                        <TextField
                                            id="outlined-dense-multiline"
                                            margin="normal"
                                            multiline
                                            rows="2"
                                            className="txt-field-vehicle-comments"
                                            onBlur={(e) => handleChangeComment(e.target.value, index)}
                                            InputProps={{ disableUnderline: true }}
                                        />
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            )}
        </>
    );
}