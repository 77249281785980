import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import { AccordionDetails, Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import { FlowDiff } from 'src/shared/Differences/dataContracts/FlowDiff';
import { FlowDiffProperty } from 'src/shared/Differences/dataContracts/FlowDiffProperty';
import { AppModule, LocalStorage } from '../../../utils/Storage';
import { FormatTypeEnum } from '../models/FormatTypeEnum';
import { TitleValueDiffModel } from '../models/TitleValueDiffModel';
import { TransportFlowLightModelExtended } from '../models/TransportFlowLightModelExtended';
import { CustomFlowStatusCellComponent } from './CustomFlowStatusCellComponent';
import { CustomCellWrapperHOC } from './FlowDiff/CustomDiffCell';
import TimeEditorComponent from './TimeEditorComponent';

interface TransportFlowProperties {
    loading: boolean,
    data: Array<TransportFlowLightModelExtended>,
    getTransportFlowWidth: (propName: string, width: number) => number,
    onReorderHandler: (event: GridColumnReorderEvent) => void,
    onResizeHandler: (event: GridColumnResizeEvent) => void,
    searchDetailFlowWithTrip: (dataItem: TransportFlowLightModelExtended) => void,
    getValueTitle: (diffsFromRequest: Array<FlowDiff>, valueField: any, field: FlowDiffProperty, formatter?, formatType?: FormatTypeEnum) => TitleValueDiffModel,
    defaultFormatter: (fieldValue: any, format: FormatTypeEnum) => string,
    vehicleTypeLabelProvider: (vehicleTypeId: string) => string
}

export const TransportFlowsGridName = 'transportFlows';

export const TransportFlowComponent = (props: TransportFlowProperties): JSX.Element => {
    const initialSort: SortDescriptor[] = [{ field: 'transportFlowId', dir: 'asc' }];

    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const handleRowClick = (e: GridRowClickEvent): void => {
        props.searchDetailFlowWithTrip(e.dataItem);
    }

    const getTransportFlowOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(AppModule.TransportPlan, TransportFlowsGridName, fieldName, defaultIndex);
    }

    const DefaultComponent = (): JSX.Element => <></>;
    const TimeEditorWrapped = CustomCellWrapperHOC(TimeEditorComponent);
    const DefaultComponentWrapped = CustomCellWrapperHOC(DefaultComponent);

    return (
        <>
            {(props.loading ?
                (<div className='sweet-loading spinnerClass'>
                    <ScaleLoader
                        width={5}
                        height={20}
                        radius={50}
                        color={'#000000'}
                        loading={props.loading} />
                </div>) :
                (<AccordionDetails className="container-expansionPanel-details">
                    <Box className="transport-request-dc" width="100%">
                        <div id="transportFlows" className="del-cal-trp" style={{ height: '100%' }}>
                            <LocalizationProvider language="fr-FR">
                                <IntlProvider locale="fr" >
                                    <Grid
                                        style={{ height: '98%' }}
                                        data={orderBy(props.data, sort)}
                                        sortable
                                        rowHeight={40}
                                        reorderable
                                        resizable
                                        selectedField="selected"
                                        sort={sort}
                                        onSortChange={handleSortChange}
                                        onRowClick={(e) => handleRowClick(e)}
                                        onColumnResize={props.onResizeHandler}
                                        onColumnReorder={(e) => props.onReorderHandler(e)}>
                                        <GridColumn field="diffsFromRequestCount"
                                            cell={(properties: GridCellProps) => {
                                                return <td className="tf-has-diff-from-request">
                                                    {properties.dataItem.diffsFromRequestCount ?
                                                        <Box style={{ cursor: 'pointer' }}>
                                                            <FontAwesomeIcon size="lg" icon={faExclamationTriangle} />
                                                        </Box> : ''}
                                                </td>
                                            }}
                                            orderIndex={getTransportFlowOrderIndexColumn("diffsFromRequestCount", 0)} title=" " width={40} resizable={false} />
                                        <GridColumn field="flowStatus" orderIndex={getTransportFlowOrderIndexColumn("flowStatus", 1)} title="Statut" width={props.getTransportFlowWidth("flowStatus", 60)}
                                            cell={(properties: GridCellProps) => <CustomFlowStatusCellComponent {...properties} selectRowHandler={() => props.searchDetailFlowWithTrip(properties.dataItem)} />} />
                                        <GridColumn field="priority" orderIndex={getTransportFlowOrderIndexColumn("priority", 2)} title="Criticité" width={props.getTransportFlowWidth("priority", 85)} />
                                        <GridColumn field="businessId" orderIndex={getTransportFlowOrderIndexColumn("businessId", 3)} title="Id" width={props.getTransportFlowWidth("businessId", 90)} />
                                        <GridColumn field="zephyrOrderNumber" orderIndex={getTransportFlowOrderIndexColumn("zephyrOrderNumber", 4)} title="N° commande" width={props.getTransportFlowWidth("zephyrOrderNumber", 90)} />
                                        <GridColumn field="serviceKindLabel" orderIndex={getTransportFlowOrderIndexColumn("serviceKindLabel", 5)} title="Prestation" width={props.getTransportFlowWidth("serviceKindLabel", 90)} />
                                        <GridColumn field="deliveryStartDate" orderIndex={getTransportFlowOrderIndexColumn("deliveryStartDate", 6)} title="Date début" width={props.getTransportFlowWidth("deliveryStartDate", 130)}
                                            cell={(properties: GridCellProps) => {
                                                const tooltip = props.getValueTitle(properties.dataItem.diffsFromRequest, properties.dataItem[properties.field], FlowDiffProperty.deliveryStartDate, props.defaultFormatter, FormatTypeEnum.Date);
                                                return (<DefaultComponentWrapped {...properties} tooltipValue={tooltip.title} hasDiffFromRequest={tooltip.hasDiffFromRequest}
                                                    rawValue={tooltip.value} onRowClick={() => props.searchDetailFlowWithTrip(properties.dataItem)} />)
                                            }} />
                                        <GridColumn field="deliveryEndDate" orderIndex={getTransportFlowOrderIndexColumn("deliveryEndDate", 7)} title="Date fin" width={props.getTransportFlowWidth("deliveryEndDate", 130)}
                                            cell={(properties: GridCellProps) => {
                                                const tooltip = props.getValueTitle(properties.dataItem.diffsFromRequest, properties.dataItem[properties.field], FlowDiffProperty.deliveryEndDate, props.defaultFormatter, FormatTypeEnum.Date);
                                                return (<DefaultComponentWrapped {...properties} tooltipValue={tooltip.title} hasDiffFromRequest={tooltip.hasDiffFromRequest}
                                                    rawValue={tooltip.value} onRowClick={() => props.searchDetailFlowWithTrip(properties.dataItem)} />)
                                            }} />
                                        <GridColumn field="senderSite" orderIndex={getTransportFlowOrderIndexColumn("senderSite", 8)} title="Site départ" width={props.getTransportFlowWidth("senderSite", 85)}
                                            cell={(properties: GridCellProps) => {
                                                const tooltip = props.getValueTitle(properties.dataItem.diffsFromRequest, properties.dataItem[properties.field], FlowDiffProperty.senderSiteResolution_Label);
                                                return (<DefaultComponentWrapped {...properties} tooltipValue={tooltip.title} hasDiffFromRequest={tooltip.hasDiffFromRequest}
                                                    rawValue={tooltip.value} onRowClick={() => props.searchDetailFlowWithTrip(properties.dataItem)} />)
                                            }} />
                                        <GridColumn field="pickupAddressCity" orderIndex={getTransportFlowOrderIndexColumn("pickupAddressCity", 9)} title="Ville départ" width={props.getTransportFlowWidth("pickupAddressCity", 80)}
                                            cell={(properties: GridCellProps) => {
                                                const tooltip = props.getValueTitle(properties.dataItem.diffsFromRequest, properties.dataItem[properties.field], FlowDiffProperty.pickupAddress_City);
                                                return (<DefaultComponentWrapped {...properties} tooltipValue={tooltip.title} hasDiffFromRequest={tooltip.hasDiffFromRequest}
                                                    rawValue={tooltip.value} onRowClick={() => props.searchDetailFlowWithTrip(properties.dataItem)} />)
                                            }} />
                                        <GridColumn field="hasMissingPickupCoordinates"
                                            cell={(properties: GridCellProps) => {
                                                return <td className="has-missing-coordinates">
                                                    {properties.dataItem.hasMissingPickupCoordinates ?
                                                        <Box>
                                                            <LocationOffIcon />
                                                        </Box> : ''}
                                                </td>
                                            }}
                                            orderIndex={getTransportFlowOrderIndexColumn("hasMissingPickupCoordinates", 10)} title="Pb adresse départ" width={props.getTransportFlowWidth("hasMissingPickupCoordinates", 110)} />
                                        <GridColumn field="receiverSite" orderIndex={getTransportFlowOrderIndexColumn("receiverSite", 11)} title="Site arrivée" width={props.getTransportFlowWidth("receiverSite", 85)}
                                            cell={(properties: GridCellProps) => {
                                                const tooltip = props.getValueTitle(properties.dataItem.diffsFromRequest, properties.dataItem[properties.field], FlowDiffProperty.receiverSiteResolution_Label, props.defaultFormatter);
                                                return (<DefaultComponentWrapped {...properties} tooltipValue={tooltip.title} hasDiffFromRequest={tooltip.hasDiffFromRequest}
                                                    rawValue={tooltip.value} onRowClick={() => props.searchDetailFlowWithTrip(properties.dataItem)} />)
                                            }} />
                                        <GridColumn field="deliveryAddressCity" orderIndex={getTransportFlowOrderIndexColumn("deliveryAddressCity", 12)} title="Ville arrivée" width={props.getTransportFlowWidth("deliveryAddressCity", 80)}
                                            cell={(properties: GridCellProps) => {
                                                const tooltip = props.getValueTitle(properties.dataItem.diffsFromRequest, properties.dataItem[properties.field], FlowDiffProperty.deliveryAddress_City, props.defaultFormatter, FormatTypeEnum.Time);
                                                return (<DefaultComponentWrapped {...properties} tooltipValue={tooltip.title} hasDiffFromRequest={tooltip.hasDiffFromRequest}
                                                    rawValue={tooltip.value} onRowClick={() => props.searchDetailFlowWithTrip(properties.dataItem)} />)
                                            }} />
                                        <GridColumn field="hasMissingDeliveryCoordinates"
                                            cell={(properties: GridCellProps) => {
                                                return <td className="has-missing-coordinates">
                                                    {properties.dataItem.hasMissingDeliveryCoordinates ?
                                                        <Box>
                                                            <LocationOffIcon />
                                                        </Box> : ''}
                                                </td>
                                            }}
                                            orderIndex={getTransportFlowOrderIndexColumn("hasMissingDeliveryCoordinates", 13)} title="Pb adresse arrivée" width={props.getTransportFlowWidth("hasMissingDeliveryCoordinates", 110)} />
                                        <GridColumn field="arrivalTimeAtSenderSite" orderIndex={getTransportFlowOrderIndexColumn("arrivalTimeAtSenderSite", 12)} title="Heure chargement" width={props.getTransportFlowWidth("arrivalTimeAtSenderSite", 95)}
                                            cell={(properties: GridCellProps) => {
                                                const tooltip = props.getValueTitle(properties.dataItem.diffsFromRequest, properties.dataItem[properties.field], FlowDiffProperty.arrivalTimeAtSenderSite, props.defaultFormatter, FormatTypeEnum.Time);
                                                return (<TimeEditorWrapped {...properties} hasDiffFromRequest={tooltip.hasDiffFromRequest}
                                                    tooltipValue={tooltip.title} rawValue={tooltip.value} onRowClick={() => props.searchDetailFlowWithTrip(properties.dataItem)} shouldRenderWrapped={true} format={"HH:mm dd-MM-yyyy"} />)
                                            }} />

                                        <GridColumn field="vehicleTypeLabel" orderIndex={getTransportFlowOrderIndexColumn("vehicleTypeLabel", 14)} title="Type camion" width={props.getTransportFlowWidth("vehicleTypeLabel", 80)}
                                            cell={(properties: GridCellProps) => {
                                                const dataItem = properties.dataItem as TransportFlowLightModelExtended;
                                                const tooltip = props.getValueTitle(dataItem.diffsFromRequest, dataItem.vehicleTypeId, FlowDiffProperty.vehicleTypeId, props.vehicleTypeLabelProvider);
                                                return (<DefaultComponentWrapped {...properties} tooltipValue={tooltip.title} hasDiffFromRequest={tooltip.hasDiffFromRequest}
                                                    rawValue={dataItem.vehicleTypeLabel} onRowClick={() => props.searchDetailFlowWithTrip(dataItem)} />)
                                            }} />
                                        <GridColumn field="numberOfVehicles" orderIndex={getTransportFlowOrderIndexColumn("numberOfVehicles", 15)} title="Camions demandés" width={props.getTransportFlowWidth("numberOfVehicles", 100)}
                                            cell={(properties: GridCellProps) => {
                                                const tooltip = props.getValueTitle(properties.dataItem.diffsFromRequest, properties.dataItem[properties.field], FlowDiffProperty.numberOfVehicles);
                                                return (<DefaultComponentWrapped {...properties} tooltipValue={tooltip.title} hasDiffFromRequest={tooltip.hasDiffFromRequest}
                                                    rawValue={tooltip.value} onRowClick={() => props.searchDetailFlowWithTrip(properties.dataItem)} />)
                                            }} />
                                        <GridColumn field="numberOfTurns" orderIndex={getTransportFlowOrderIndexColumn("numberOfTurns", 16)} title="Tours demandés" width={props.getTransportFlowWidth("numberOfTurns", 100)} />
                                        <GridColumn field="quantityOfDay" orderIndex={getTransportFlowOrderIndexColumn("quantityOfDay", 17)} title="Qté planifiée" format="{0:0.00}" width={props.getTransportFlowWidth("quantityOfDay", 100)} />
                                        <GridColumn field="deliveredQuantity" orderIndex={getTransportFlowOrderIndexColumn("deliveredQuantity", 18)} title="Qté livrée" format="{0:0.00}" width={props.getTransportFlowWidth("deliveredQuantity", 100)} />
                                        <GridColumn field="requestedQuantity" orderIndex={getTransportFlowOrderIndexColumn("requestedQuantity", 19)} title="Qté demandée" format="{0:0.00}" width={props.getTransportFlowWidth("requestedQuantity", 100)}
                                            cell={(properties: GridCellProps) => {
                                                const tooltip = props.getValueTitle(properties.dataItem.diffsFromRequest, properties.dataItem[properties.field], FlowDiffProperty.product_Quantity);
                                                return (<DefaultComponentWrapped {...properties} tooltipValue={tooltip.title} hasDiffFromRequest={tooltip.hasDiffFromRequest}
                                                    rawValue={tooltip.value} onRowClick={() => props.searchDetailFlowWithTrip(properties.dataItem)} />)
                                            }} />
                                        <GridColumn field="remainingQuantity" orderIndex={getTransportFlowOrderIndexColumn("remainingQuantity", 20)} title="RAP" format="{0:0.00}" width={props.getTransportFlowWidth("remainingQuantity", 100)} />
                                        <GridColumn field="requestedBy" orderIndex={getTransportFlowOrderIndexColumn("requestedBy", 21)} title="Demandeur" width={props.getTransportFlowWidth("requestedBy", 100)} 
                                            cell={(properties: GridCellProps) => {
                                                const tooltip = props.getValueTitle(properties.dataItem.diffsFromRequest, properties.dataItem[properties.field], FlowDiffProperty.requestedBy_Name);
                                                return (<DefaultComponentWrapped {...properties} tooltipValue={tooltip.title} hasDiffFromRequest={tooltip.hasDiffFromRequest}
                                                    rawValue={tooltip.value} onRowClick={() => props.searchDetailFlowWithTrip(properties.dataItem)} />)
                                            }} />
                                        <GridColumn field="receivedBy" orderIndex={getTransportFlowOrderIndexColumn("receivedBy", 22)} title="Bénéficiaire" width={props.getTransportFlowWidth("receivedBy", 100)}
                                            cell={(properties: GridCellProps) => {
                                                const tooltip = props.getValueTitle(properties.dataItem.diffsFromRequest, properties.dataItem[properties.field], FlowDiffProperty.beneficiary_Name);
                                                return (<DefaultComponentWrapped {...properties} tooltipValue={tooltip.title} hasDiffFromRequest={tooltip.hasDiffFromRequest}
                                                    rawValue={tooltip.value} onRowClick={() => props.searchDetailFlowWithTrip(properties.dataItem)} />)
                                            }} />
                                        <GridColumn field="logisticsUnitLabel" orderIndex={getTransportFlowOrderIndexColumn("logisticsUnitLabel", 23)} title="Zone logistique" width={props.getTransportFlowWidth("logisticsUnitLabel", 100)} />
                                    </Grid>
                                </IntlProvider>
                            </LocalizationProvider>
                        </div>
                    </Box>
                </AccordionDetails>)
            )}
        </>
    );
}