import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Input, Tooltip } from '@mui/material';
import React from 'react';

interface SearchTransporterStepComponentProperties {
    transporterSearchLoading: boolean,
    transporterIdMdm: string,
    transporterName: string,
    handleTransporterIdMdmChange: (value: string) => void,
    handleTransporterNameChange: (value: string) => void,
    searchTransporterByMdmId: (transporterIdMdm: string) => void,
    searchTransporterByName: (transporterName: string) => void
}

export const SearchTransporterStepComponent = (props: SearchTransporterStepComponentProperties): JSX.Element => {

    const AddTransporterTooltipImage = "/static/Referentials/TransportPurchasePricesReferential/AddTransporterTooltipImage.png";

    const searchTransporter = (): void => {
        if (props.transporterIdMdm !== '')
            props.searchTransporterByMdmId(props.transporterIdMdm);
        else if (props.transporterName !== '')
            props.searchTransporterByName(props.transporterName);
    }

    const isSearchButtonDisabled: boolean = props.transporterSearchLoading || (props.transporterIdMdm.length == 0 && props.transporterName.length < 3);

    return (
        <Box className="search-transporter-component">
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column" className="transporter-infos">
                    <Box display="flex" flexDirection="row" justifyContent="flex-start">
                        <Box display="flex" flexDirection="column" className="input-text-content">
                            <Box className="idmdm-input-text-label">IdMdm du transporteur</Box>
                            <Box display="flex" flexDirection="row">
                                <Input
                                    id="transporter-idmdm"
                                    value={props.transporterIdMdm}
                                    className="transporter-idmdm-input-text"
                                    onChange={(e) => props.handleTransporterIdMdmChange(e.target.value)}
                                    disabled={props.transporterName !== ''}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center" ml={"10px"}>
                            <Tooltip id="add-transporter-tooltip-image" placement="right-start" title={
                                <Box>
                                    <p className="info-user-text">Pour récupérer l’idMdM du transporteur, vous pouvez le récupérer sur le ZEPHYR TRANSPORT de votre Zone Logistique: </p>
                                    <img src={AddTransporterTooltipImage} />
                                </Box>
                            }>
                                <Avatar>
                                    <FontAwesomeIcon icon={faQuestion} />
                                </Avatar>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box pt={"20px"} pb={"20px"}>OU</Box>
                    <Box display="flex" flexDirection="column" className="input-text-content">
                        <Box className="transporter-name-input-text-label">Nom du transporteur</Box>
                        <Box display="flex" flexDirection="row">
                            <Input
                                id="transporter-name"
                                value={props.transporterName}
                                className="transporter-name-input-text"
                                onChange={(e) => props.handleTransporterNameChange(e.target.value)}
                                disabled={props.transporterIdMdm !== ''}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                    <Button variant="contained" color="primary" title="Chercher" onClick={() => searchTransporter()} disabled={isSearchButtonDisabled}>
                        Chercher
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}