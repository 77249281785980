import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useEffect, useRef, useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import Utilities from '../../../../utils/Utilities';
import { SizingUtilities } from '../../SizingUtilities';
import { InternalDriverLightModelExtended } from '../models/InternalDriverLightModelExtended';
import { InternalDriverLightModel } from '../services/dataContracts/queryStack/InternalDriverLightModel';

interface InternalDriversComponentProps {
    internalDrivers: Array<InternalDriverLightModelExtended>,
    sort: SortDescriptor[],
    isForTemporaryDrivers: boolean,
    handleSortChange: (e: GridSortChangeEvent) => void,
    handleSelectedInternalDriver: (employeeId: string, fullName: string) => void,
    handleDriverEnableStatusChanged: (employeeId: string, isEnabled: boolean) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

export const InternalDriversComponent = (props: InternalDriversComponentProps): JSX.Element => {

    const { internalDrivers, sort, isForTemporaryDrivers, handleSortChange, handleSelectedInternalDriver, handleDriverEnableStatusChanged } = props;

    const ReferentialGridName = isForTemporaryDrivers ? 'temporaryDriversReferential' : 'internalDriversReferential';
    const ModuleKey = isForTemporaryDrivers ? AppModule.TemporaryDriversReferential : AppModule.InternalDriversReferential;

    const [skip, setSkip] = useState<number>(0);
    const gridRef = useRef<any>();
    const [selectedRowId, setSelectedRowId] = useState(null);

    useEffect(() => {
        var driverIndexString = LocalStorage.GetItem(ModuleKey, 'lastDriverIndexSelected');
        LocalStorage.SetItem(ModuleKey, 'selected', 'false');
        if (driverIndexString !== null && gridRef.current) {
            var grid = orderBy(props.internalDrivers, props.sort);
            var driverIdString = LocalStorage.GetItem(ModuleKey, 'lastDriverIdSelected');
            var driverId = parseInt(driverIdString, 10);
            var index = grid.findIndex(row => row.id === driverId);
            LocalStorage.SetItem(ModuleKey, 'selected', 'true');
            gridRef.current.scrollIntoView({ rowIndex: index - 2 });
            LocalStorage.RemoveItem(ModuleKey, 'lastDriverIndexSelected');
        }
    }, [props.sort]);

    const forceUpdate = useForceUpdate();

    const getInternalDriversWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, ReferentialGridName, fieldName, columnWidth);
    }

    const getDriverGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, ReferentialGridName, fieldName, defaultIndex);
    }

    const handleRowClick = (driver): void => {
        const dataItem = driver as InternalDriverLightModelExtended;
        var driverIdString = LocalStorage.GetItem(ModuleKey, 'lastDriverIdSelected');
        var driverId = parseInt(driverIdString, 10);
        var selected = LocalStorage.GetItem(ModuleKey, 'selected') === 'true';

        if (dataItem.selected || (selected && dataItem.id == driverId)) {
            LocalStorage.SetItem(ModuleKey, 'lastDriverIdSelected', "0");
            LocalStorage.SetItem(ModuleKey, 'lastEmployeeIdSelected', "0");
            LocalStorage.SetItem(ModuleKey, 'lastDriverNameSelected', "");
            LocalStorage.SetItem(ModuleKey, 'selected', 'false');
            setSelectedRowId(null)
            handleSelectedInternalDriver(null, null);
        }

        else {
            LocalStorage.SetItem(ModuleKey, 'lastDriverIdSelected', dataItem.id.toString());
            var driverName = `${dataItem.lastName} ${dataItem.firstName}`;
            LocalStorage.SetItem(ModuleKey, 'lastDriverNameSelected', driverName);
            LocalStorage.SetItem(ModuleKey, 'lastEmployeeIdSelected', dataItem.employeeId.toString());
            LocalStorage.SetItem(ModuleKey, 'selected', 'true');
            setSelectedRowId(dataItem.id)
            handleSelectedInternalDriver(dataItem.employeeId, driverName);
        }
    }

    const rowRender = (trElement, row) => {
        var vehicleIndexString = LocalStorage.GetItem(ModuleKey, 'lastDriverIdSelected');
        var selected = LocalStorage.GetItem(ModuleKey, 'selected') === 'true';
        var selectedRowIdFromStorage = parseInt(vehicleIndexString, 10);
        const data = row.dataItem;
        const className = selected && (selectedRowId == row.dataItem.id || selectedRowIdFromStorage == row.dataItem.id) ? 'k-table-row k-master-row k-selected' : 'k-table-row k-master-row';
        const onClickHandler = () => handleRowClick(data);
        return React.cloneElement(trElement, { onClick: onClickHandler, className: className });
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            ReferentialGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, ReferentialGridName, event.columns);
        forceUpdate();
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = internalDrivers.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: InternalDriverLightModelExtended[] = orderBy(internalDrivers, sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="internal-drivers-grid">
            <Grid
                ref={gridRef}
                className="drivers-grid"
                data={gridData}
                selectedField="selected"
                sortable
                reorderable
                resizable
                sort={sort}
                onRowClick={e => handleRowClick(e)}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={handleSortChange}
                editField="inEdit"
                rowHeight={rowHeight}
                scrollable="virtual"
                skip={skip}
                total={totalGrid}
                pageSize={gridPageSize}
                onPageChange={pageChange}
                style={gridStyle}
                rowRender={rowRender}
            >
                <GridColumn field="hasDifference"
                    cell={(props: GridCellProps) => {
                        const dataItem = props.dataItem as InternalDriverLightModel;
                        return <td className="warning-icon-cell">
                            {dataItem.hasDifference ?
                                <Box>
                                    <FontAwesomeIcon size="lg" icon={faExclamationTriangle} />
                                </Box> : ''
                            }
                        </td>
                    }}
                    orderIndex={getDriverGridOrderIndexColumn("hasDifference", 0)} width={getInternalDriversWidth("hasDifference", 120)} title="Différence" />
                <GridColumn field="lastName" orderIndex={getDriverGridOrderIndexColumn("lastName", 1)} width={getInternalDriversWidth("lastName", 120)} title="Nom chauffeur" />
                <GridColumn field="firstName" orderIndex={getDriverGridOrderIndexColumn("firstName", 2)} width={getInternalDriversWidth("firstName", 120)} title="Prénom chauffeur" />
                <GridColumn field="employeeId" orderIndex={getDriverGridOrderIndexColumn("employeeId", 3)} width={getInternalDriversWidth("employeeId", 120)} title="Id du chauffeur" />
                <GridColumn field="phoneNumber" orderIndex={getDriverGridOrderIndexColumn("phoneNumber", 4)} width={getInternalDriversWidth("phoneNumber", 120)} title="Téléphone"
                    cell={(properties: GridCellProps) => <td className="phone-number-ref">{properties.dataItem?.phoneNumber ? <a href={`callTo:${Utilities.formatPhoneNumber(properties.dataItem.phoneNumber)}`}>{Utilities.formatPhoneNumber(properties.dataItem.phoneNumber)}</a> : ''}</td>}
                />
                {!isForTemporaryDrivers && <GridColumn field="mgrh" orderIndex={getDriverGridOrderIndexColumn("mgrh", 4)} width={getInternalDriversWidth("mgrh", 120)} title="Matricule" />}
                <GridColumn field="transporterLabel"
                    cell={(props: GridCellProps) => {
                        const dataItem = props.dataItem as InternalDriverLightModel;
                        return <td className={dataItem.hasDifferentAgencyOnColasReferential ? "field-changed" : ""}>
                            {dataItem.transporterLabel}
                        </td>
                    }}
                    orderIndex={getDriverGridOrderIndexColumn("transporterLabel", !isForTemporaryDrivers ? 6 : 5)} width={getInternalDriversWidth("transporterLabel", 200)} title="Transporteur interne" />
                <GridColumn field="vehiclesLabel" orderIndex={getDriverGridOrderIndexColumn("vehiclesLabel", !isForTemporaryDrivers ? 7 : 6)} width={getInternalDriversWidth("vehiclesLabel", 200)} title="Camion(s) associé(s)" />
                <GridColumn field="isEnabled" orderIndex={getDriverGridOrderIndexColumn("isEnabled", !isForTemporaryDrivers ? 8 : 7)} width={getInternalDriversWidth("isEnabled", 50)} title="Actif"
                    cell={(properties: GridCellProps) => <td><input type="checkbox" className="checkbox" checked={properties.dataItem?.isEnabled} onChange={() => handleDriverEnableStatusChanged(properties.dataItem?.employeeId, !properties.dataItem?.isEnabled)} /></td>}
                />
            </Grid>
        </Box>
    );
}
