import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, FormControlLabel, Input, InputAdornment, Switch } from '@mui/material';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useRef } from 'react';

interface VehiclesCountWithCheckedProperperties {
    genericVehiclesCount: number,
    transporterVehiclesCount: number,
    internalVehiclesCount: number,
    dailyVehiclesCount: number,
    contractedVehiclesCount: number,
    genericVehiclesChecked: boolean,
    transporterVehiclesChecked: boolean,
    internalVehiclesChecked: boolean,
    dailyVehiclesChecked: boolean,
    contractedVehiclesChecked: boolean,
    byVehicleTypeGroupIdsFilterEnabled: boolean,
    includingAlreadyUsedFilterEnabled: boolean,
    dailyVehicleDateRef: React.Ref<any>,
    dailyVehicleDate: Date,
    handleGenericChange: (searchText: string, targetChecked: boolean) => void,
    handleTransporterChange: (searchText: string, targetChecked: boolean) => void,
    handleInternalChange: (searchText: string, targetChecked: boolean) => void,
    handleDailyChange: (searchText: string, targetChecked: boolean) => void,
    handleContractedChange: (searchText: string, targetChecked: boolean) => void,
    handleDrawerLeftClose: () => void,
    handleChangeVehicleInput: (searchText: string) => void,
    clearSearchText: () => void,
    handleVehicleTypeGroupIdsFilterChange: () => void,
    handleIncludingAlreadyUsedFilterChange: () => void,
    handleDailyVehicleDateChange: (event: DatePickerChangeEvent) => void
}

export const VehiclesCountWithCheckedComponent = (props: VehiclesCountWithCheckedProperperties): JSX.Element => {
    const searchRef = useRef<HTMLInputElement>();
    return (
        <Box display="flex" flexDirection="column" flex="nowrap" className="content-header-drawer-vehicle">
            <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center">
                <Box width="80%" className="title-vehicle">
                    Camions
                </Box>
                <Box display="flex" width="20%" flexDirection="row" justifyContent="flex-end">
                    <Box style={{ cursor: 'pointer' }} onClick={props.handleDrawerLeftClose}>
                        <FontAwesomeIcon size="lg" icon={faTimes} />
                    </Box>
                </Box>
            </Box>
            <Box width="100%" pt={1}>
                <Input disableUnderline className={(searchRef.current !== undefined && searchRef.current.value.length > 2) ? "input-search-vehicles search-text-active" : "input-search-vehicles"} inputRef={searchRef}
                    endAdornment={<>
                        <InputAdornment position="end" classes={
                            {
                                root: 'input-icon-close-root'
                            }
                        }>
                            <FontAwesomeIcon icon={faTimes} onClick={() => props.clearSearchText()} />
                        </InputAdornment>
                        <InputAdornment position="end" classes={
                            {
                                root: 'input-icon-search-root'
                            }
                        }>
                            <FontAwesomeIcon icon={faSearch} className="icon-search" />
                        </InputAdornment>
                    </>}
                    id="search-text-drawer" placeholder="Rechercher ..." onChange={(event) => props.handleChangeVehicleInput(event.target.value)} />
            </Box>
            <Box display="flex" flexDirection="column" flexWrap="nowrap">
                <Box width="100%">
                    <Box display="flex" flexDirection="row" flexWrap="nowrap">
                        <Box display="flex" width="50%" flexDirection="row" flexWrap="nowrap" alignItems="center">
                            <Box width="80%">
                                <FormControlLabel style={{ margin: '0rem' }} value="generic" className="label-generic" label="Générique" labelPlacement="end"
                                    checked={props.genericVehiclesChecked}
                                    onChange={(e: React.ChangeEvent<any>) => props.handleGenericChange(searchRef.current.value, e.target.checked)}
                                    control={<Checkbox />} />
                            </Box>
                            <Box width="20%" className="track-count">
                                {props.genericVehiclesCount}
                            </Box>
                        </Box>
                        <Box display="flex" pl={1} width="50%" flexDirection="row" flexWrap="nowrap" alignItems="center">
                            <Box width="80%">
                                <FormControlLabel style={{ margin: '0rem' }} value="transporter" className="label-transporter" label="Transporteurs" labelPlacement="end"
                                    checked={props.transporterVehiclesChecked}
                                    onChange={(e: React.ChangeEvent<any>) => props.handleTransporterChange(searchRef.current.value, e.target.checked)}
                                    control={<Checkbox />} />
                            </Box>
                            <Box width="20%" className="track-count">
                                {props.transporterVehiclesCount}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box width="100%">
                    <Box display="flex" flexDirection="row" flexWrap="nowrap">
                        <Box display='flex' width='50%' flexDirection='row' flexWrap='nowrap' alignItems="center">
                            <Box width="80%">
                                <FormControlLabel style={{ margin: '0rem' }} value="internal" className="label-internal" label="Internes" labelPlacement="end"
                                    checked={props.internalVehiclesChecked}
                                    onChange={(e: React.ChangeEvent<any>) => props.handleInternalChange(searchRef.current.value, e.target.checked)}
                                    control={<Checkbox />} />
                            </Box>
                            <Box width="20%" className="track-count" alignContent="flex-end">
                                {props.internalVehiclesCount}
                            </Box>
                        </Box>
                        <Box display="flex" pl={1} width="50%" flexDirection="row" flexWrap="nowrap" alignItems="center">
                            <Box width="80%">
                                <FormControlLabel style={{ margin: '0rem' }} value="daily" className="label-dailyVehicle" label="Camion(s) du :" labelPlacement="end"
                                    checked={props.dailyVehiclesChecked}
                                    onChange={(e: React.ChangeEvent<any>) => props.handleDailyChange(searchRef.current.value, e.target.checked)}
                                    control={<Checkbox />} />
                            </Box>
                            <Box width="20%" className="track-count" alignContent="flex-end">
                                {props.dailyVehiclesCount}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="daily-vehicles-datepicker">
                    <Box display="flex" flexDirection="row" flexWrap="nowrap">
                        <Box display="flex" width="50%" flexDirection="row" flexWrap="nowrap" alignItems="center"></Box>
                        <Box display="flex" width="50%" flexDirection="row" className="datepicker">
                            <LocalizationProvider language="fr-FR">
                                <IntlProvider locale="fr" >
                                    <DatePicker defaultValue={new Date()} ref={props.dailyVehicleDateRef} onChange={props.handleDailyVehicleDateChange} />
                                </IntlProvider>
                            </LocalizationProvider>
                        </Box>
                    </Box>
                </Box>
                <Box className="contracted-vehicles-section">
                    <Box display="flex" flexDirection="row" flexWrap="nowrap">
                        <Box display='flex' width='50%' flexDirection='row' flexWrap='nowrap' alignItems="center">
                            <Box width="80%">
                                <FormControlLabel style={{ margin: '0rem' }} value="internal" className="label-pac" label="PAC" labelPlacement="end"
                                    checked={props.contractedVehiclesChecked}
                                    onChange={(e: React.ChangeEvent<any>) => props.handleContractedChange(searchRef.current.value, e.target.checked)}
                                    control={<Checkbox />} />
                            </Box>
                            <Box width="20%" className="track-count" alignContent="flex-end">
                                {props.contractedVehiclesCount}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" flexWrap="nowrap">
                <Box width="50%">
                    <FormControlLabel
                        control={<Switch
                            checked={props.byVehicleTypeGroupIdsFilterEnabled}
                            onChange={props.handleVehicleTypeGroupIdsFilterChange}
                            color="primary"
                        />}
                        label="Filtrer par type"
                    />
                </Box>
                <Box width="50%">
                    {(props.internalVehiclesChecked || props.dailyVehiclesChecked) &&
                        <FormControlLabel
                            control={<Switch
                                checked={props.includingAlreadyUsedFilterEnabled}
                                onChange={props.handleIncludingAlreadyUsedFilterChange}
                                color="primary"
                            />}
                            label="Voir les camions utilisés"
                        />
                    }
                </Box>
            </Box>
        </Box>
    )
}