import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridRowProps, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { PriceGrid } from 'src/shared/Quotation/services/dataContracts/admin/PriceGrid';
import { AppModule, LocalStorage } from 'src/utils/Storage';
import { EditedFieldModel } from '../models/EditedFieldModel';
import { PerTimePeriodRowModel } from '../models/PerTimePeriodRowModel';
import { SizingUtilities } from '../SizingUtilities';
import { CustomPriceCellComponent } from './CustomPriceCellComponent';

interface PerTimePeriodGridProps {
    priceGridData: PriceGrid,
    hasMaintenanceLock: boolean,
    handlePriceGridParsed: (data: Array<PerTimePeriodRowModel>) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const ModuleKey = AppModule.QuotationPricesReferential;

export const PerTimePeriodGridComponent = (props: PerTimePeriodGridProps) => {
    let preventExit: boolean;
    let preventExitTimeout: ReturnType<typeof setTimeout>;
    let blurTimeout: ReturnType<typeof setTimeout>;

    const [gridDataArray, setGridDataArray] = useState<Array<PerTimePeriodRowModel>>([]);
    const [vehicleTypeGroups, setVehicleTypeGroups] = useState<Array<string>>([]);

    const [sort, setSort] = useState<Array<SortDescriptor>>([{ field: 'id', dir: 'asc' }]);
    const [editField, setEditField] = useState<EditedFieldModel>(null);

    const { priceGridData, hasMaintenanceLock } = props;

    const priceGridKind = priceGridData?.priceGridKind ?? "";

    useEffect(() => {
        if (priceGridData?.id) {
            prepareData();
        }
    }, [priceGridData]);

    const prepareData = (): void => {
        const dataArray: Array<PerTimePeriodRowModel> = [];

        const perTimePeriodGrid = priceGridData?.perTimePeriodGrid
        if (perTimePeriodGrid?.timePeriods?.ids) {
            perTimePeriodGrid.timePeriods.ids.map((id: string, index: number) => {
                let element: PerTimePeriodRowModel = {
                    id: id,
                    changes: new Map<string, number>()
                };

                // Génération des colonnes dynamiques
                if (perTimePeriodGrid.timePeriodPricesRows?.length > 0) {
                    if (index === 0) {
                        const firstTimePeriodPricesRow = perTimePeriodGrid.timePeriodPricesRows[0];

                        const vehicleTypeGroups = firstTimePeriodPricesRow.pricesRow?.map(x => x.vehicleTypeGroup);
                        const uniqVehicleTypeGroups = vehicleTypeGroups?.length > 0 ? uniq(vehicleTypeGroups) : [];

                        setVehicleTypeGroups(uniqVehicleTypeGroups);
                    }

                    perTimePeriodGrid.timePeriodPricesRows.filter(r => r.timePeriodId === id).map(row => {
                        (row.pricesRow ?? []).map(priceRow => {
                            element[priceRow.vehicleTypeGroup] = priceRow.price;
                            element.changes.set(priceRow.vehicleTypeGroup, priceRow.price);
                        });
                    });
                }

                dataArray.push(element);
            });

            setGridDataArray(dataArray);
        }
    }

    const [skip, setSkip] = useState<number>(0);

    const forceUpdate = useForceUpdate();

    const getTimePeriodWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, priceGridKind, fieldName, columnWidth);
    }

    const getTimePeriodGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, priceGridKind, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            priceGridKind,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, priceGridKind, event.columns);
        forceUpdate();
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = gridDataArray.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, dataItem: GridRowProps): React.ReactElement<HTMLTableRowElement> => {
        const trProps = {
            ...trElement.props,
            onMouseDown: () => {
                preventExit = true;
                clearTimeout(preventExitTimeout);
                preventExitTimeout = setTimeout(() => { preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(blurTimeout);
                if (!preventExit) {
                    blurTimeout = setTimeout(() => { exitEdit(); });
                }
            },
            onFocus: () => { clearTimeout(blurTimeout); }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as any);
    }

    const cellRender = (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps): React.ReactElement<HTMLTableCellElement> => {
        const dataItem: PerTimePeriodRowModel = cellProps.dataItem;
        const field: string = cellProps.field;
        const additionalProps = (dataItem.inEdit && (cellProps.field === dataItem.inEdit)) ?
            {
                ref: (td: any) => {
                    const input = td && td.querySelector('input');
                    if (!input || (input === document.activeElement)) { return; }
                    if (input.type === 'checkbox') {
                        input.focus();
                    } else {
                        input.select();
                    }
                }
            } : {
                onClick: () => { enterEdit(dataItem, field); }
            };
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children as any);
    }

    const enterEdit = (dataItem: PerTimePeriodRowModel, field: string) => {
        if (dataItem.inEdit && field === editField.field && editField.id === dataItem.id)
            return;

        exitEdit();
        dataItem.inEdit = field;
        setEditField({
            field: field,
            id: dataItem.id
        });
    };

    const exitEdit = () => {
        let wasChanged = false;

        gridDataArray.forEach(dataItem => {
            if (dataItem.inEdit !== undefined) {
                wasChanged = true;
            }
            dataItem.inEdit = undefined;
        });

        setEditField(null);

        if (!wasChanged)
            return;

        props.handlePriceGridParsed(gridDataArray);
    };

    const gridData = orderBy(gridDataArray, sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="grid-content">
            <Grid
                className="grid"
                data={gridData}
                sortable
                reorderable
                resizable
                sort={sort}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={handleSortChange}
                editField="inEdit"
                rowHeight={rowHeight}
                total={totalGrid}
                pageSize={gridPageSize}
                onPageChange={pageChange}
                rowRender={rowRender}
                cellRender={cellRender}
                style={gridStyle}
            >
                <GridColumn field="empty" width={50} title=" " sortable={false} reorderable={false} editable={false} />
                <GridColumn field="id" orderIndex={getTimePeriodGridOrderIndexColumn("id", 1)} width={getTimePeriodWidth("id", 120)} title="Id" editable={false} />
                {vehicleTypeGroups.map((vehicleTypeGroup: string, i: number) =>
                    <GridColumn key={`vehicleTypeGroup-${i}`} field={vehicleTypeGroup}
                        orderIndex={getTimePeriodGridOrderIndexColumn(vehicleTypeGroup, 2 + i)} width={getTimePeriodWidth(vehicleTypeGroup, 150)} title={vehicleTypeGroup}
                        cell={(props: GridCellProps) => <CustomPriceCellComponent isReadOnly={hasMaintenanceLock} {...props} />} />
                )}
            </Grid>
        </Box>
    )
}