import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderCellProps, GridPageChangeEvent, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { CustomCheckboxCell } from '../../Common/CustomCheckboxCell';
import { CustomHeaderCellComponent } from '../../Common/CustomHeaderCellComponent';
import { SizingUtilities } from '../../SizingUtilities';
import { CustomerLightModelExtended } from '../models/CustomerLightModelExtended';
import { CustomerContactsReferentialApiClient } from '../services/CustomerContactsReferentialApiClient';
import { LogisticsUnitChoiceOfCustomersLightModel } from '../services/dataContracts/queryStack/LogisticsUnitChoiceOfCustomersLightModel';

interface CustomerComponentProps {
    customersList: Map<string, CustomerLightModelExtended>,
    customersArray: Array<CustomerLightModelExtended>,
    sort: SortDescriptor[],
    logisticsUnitsChoices: Array<LogisticsUnitChoiceOfCustomersLightModel>,
    handleSortChange: (e: GridSortChangeEvent) => void,
    handleSortColumnChange: (sortItems: SortDescriptor[]) => void,
    handleSelectedCustomer: (customerMdmId: string, name: string) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const ExternalCustomersGridName = 'externalCustomers';
const ModuleKey = AppModule.CustomerContactsReferential;

export const CustomerComponent = (props: CustomerComponentProps): JSX.Element => {
    const [customersChoicesMap] = useState<Map<string, CustomerLightModelExtended>>(props.customersList);
    const [skip, setSkip] = useState<number>(0);

    const forceUpdate = useForceUpdate();

    const getCustomerLogisticsUnitsWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, ExternalCustomersGridName, fieldName, columnWidth);
    }

    const getCustomerGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, ExternalCustomersGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            ExternalCustomersGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, ExternalCustomersGridName, event.columns);
        forceUpdate();
    }

    const handleRowClick = (event: GridRowClickEvent): void => {
        if (event.dataItem.selected)
            props.handleSelectedCustomer(null, null);
        else
            props.handleSelectedCustomer(event.dataItem.customerMdmId, event.dataItem.name);
    }

    const updateCustomersChoicesLogisticsUnit = (checked: boolean, logisticsUnit: string, customerMdmId: string): void => {
        CustomerContactsReferentialApiClient.AddOrRemoveCustomerChoice({
            customerMdmId: customerMdmId,
            logisticsUnitId: logisticsUnit,
            shouldAdd: checked
        })
            .then(() => {
                const item: CustomerLightModelExtended = customersChoicesMap.get(customerMdmId);
                item[logisticsUnit] = checked;

                let checkedSelect = true;
                let indeterminateAll = true;
                let numberOfChecked = 0;
                let numberOfUnchecked = 0;
                props.logisticsUnitsChoices.forEach((transporter: LogisticsUnitChoiceOfCustomersLightModel) => {
                    if (!item[transporter.logisticsUnitId]) {
                        checkedSelect = false;
                        numberOfUnchecked += 1;
                    } else {
                        numberOfChecked += 1;
                    }
                });
                if (props.logisticsUnitsChoices.length === numberOfUnchecked || props.logisticsUnitsChoices.length === numberOfChecked) {
                    indeterminateAll = false;
                }

                item.isSelectedAll = checkedSelect ? true : (indeterminateAll ? null : false);
                item.isIndeterminateAll = indeterminateAll;

                forceUpdate();
            });
    }

    const updateCustomersChoicesAllLogisticsUnits = (checked: boolean, customerMdmId: string): void => {
        const logisticsUnitIds: Array<string> = [];
        props.logisticsUnitsChoices.forEach((element: LogisticsUnitChoiceOfCustomersLightModel) => {
            logisticsUnitIds.push(element.logisticsUnitId);
        });

        CustomerContactsReferentialApiClient.SetOrUnsetCustomerChoiceToAllTransportLogisticsUnits({
            customerMdmId: customerMdmId,
            shouldAdd: checked,
            logisticsUnitIds: logisticsUnitIds
        })
            .then(() => {
                const item: CustomerLightModelExtended = customersChoicesMap.get(customerMdmId);

                logisticsUnitIds.forEach((customerMdmId: string) => {
                    item[customerMdmId] = checked;
                });

                item.isSelectedAll = checked ? true : false;
                item.isIndeterminateAll = false;

                forceUpdate();
            });
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.customersList.size;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: CustomerLightModelExtended[] = orderBy(props.customersArray, props.sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="colas_customers-grid">
            <Grid
                className="customer-grid"
                data={gridData}
                selectedField="selected"
                sortable
                reorderable
                resizable
                sort={props.sort}
                onRowClick={e => handleRowClick(e)}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={props.handleSortChange}
                editField="inEdit"
                rowHeight={rowHeight}
                scrollable="virtual"
                skip={skip}
                total={totalGrid}
                pageSize={gridPageSize}
                onPageChange={pageChange}
                style={gridStyle}
            >
                <GridColumn field="isSelectedAll" orderIndex={getCustomerGridOrderIndexColumn("isSelectedAll", 0)} width={getCustomerLogisticsUnitsWidth("isSelectedAll", 60)} title="Sélection"
                    cell={(props: GridCellProps) =>
                        <CustomCheckboxCell
                            {...props}
                            keyName="customerMdmId"
                            updateChoicesAllLogisticsUnits={updateCustomersChoicesAllLogisticsUnits}
                        />}
                    headerCell={(_props: GridHeaderCellProps) =>
                        <CustomHeaderCellComponent tooltip="Activer/Désactiver un client pour toutes les zones logistiques" title={_props.title} field="isSelectedAll" sort={props.sort}
                            handleSortColumnChange={props.handleSortColumnChange} />
                    }
                />
                <GridColumn field="name" orderIndex={getCustomerGridOrderIndexColumn("name", 1)} width={getCustomerLogisticsUnitsWidth("name", 115)} title="Nom" />
                <GridColumn field="customerMdmId" orderIndex={getCustomerGridOrderIndexColumn("customerMdmId", 2)} width={getCustomerLogisticsUnitsWidth("customerMdmId", 55)} title="Id mdm" />
                <GridColumn field="freeFormAddress" orderIndex={getCustomerGridOrderIndexColumn("freeFormAddress", 3)} width={getCustomerLogisticsUnitsWidth("freeFormAddress", 250)} title="adresse" />
                {props.logisticsUnitsChoices.map((customer: LogisticsUnitChoiceOfCustomersLightModel, i: number) =>
                    <GridColumn key={i} field={customer.logisticsUnitId} orderIndex={getCustomerGridOrderIndexColumn(customer.logisticsUnitId, 4 + i)} width={getCustomerLogisticsUnitsWidth(customer.logisticsUnitId, 95)} title={customer.label}
                        cell={(props: GridCellProps) =>
                            <CustomCheckboxCell
                                {...props}
                                keyName="customerMdmId"
                                updateChoicesLogisticsUnit={updateCustomersChoicesLogisticsUnit}
                            />}
                        headerCell={(_props: GridHeaderCellProps) =>
                            <CustomHeaderCellComponent tooltip="Activer/Désactiver un client pour la Zone Logistique" title={_props.title} field={customer.logisticsUnitId} sort={props.sort}
                                handleSortColumnChange={props.handleSortColumnChange} />
                        }
                    />
                )}
            </Grid>
        </Box>
    );
}
