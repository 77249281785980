import { WebAppActionResultEx, CommandResultLight, WebAppActionResult, BusinessErrorLight } from '../shared/models/WebAppActionResult';

export default class BusinessErrors {

    private static readonly _emptyErrors: string[] = [];

    private static readonly _emptyMessages: string[] = [];

    private static readonly ErrorsMessages = {

        Generic: new Map<string, string>(
            [
                ['DeliveryStartDateIsMissingOrHasInvalidFormat', 'date de début mal formatée ou absente'],
                ['DeliveryEndDateIsMissingOrHasInvalidFormat', 'date de fin mal formatée ou absente'],
                ['IdentifierIsMissingOrHasInvalidFormat', 'identifiant Besoin mal formaté ou absent'],
                ['StatusIsMissing', 'état du Besoin absent'],
                ['IdentifierExists', 'Besoin déjà existant dans LORIE, la modification est à réaliser directement sur LORIE'],
                ['SenderSiteIsMissing', 'site de départ absent'],
                ['PickupAddressIsInvalid', 'adresse de départ mal formatée'],
                ['ReceiverSiteIsMissing', 'site d\'arrivée absent'],
                ['SenderAddressIsInvalid', 'adresse d\'arrivée mal formatée'],
                ['BeneficiaryPhoneNumberIsInvalidFormat', 'téléphone du bénéficiaire mal formaté'],
                ['UnitOfMeasureIsInvalidFormat', 'tonnage mal formaté'],
                ['CoatingApplicationModeIsMissing', 'type de chantier absent'],
                ['PreferredArrivalTimeIsMissingOrHasInvalidFormat', 'heure sur chantier mal formatée ou absente'],
                ['MissionEndTimeIsInvalidFormat', 'heure de fin souhaitée mal formatée'],
                ['EstimatedDurationIsMissingOrHasInvalidFormat', 'temps de tour mal formaté ou absent'],
                ['UnloadingDurationIsInvalidFormat', 'durée sur chantier mal formatée'],
                ['NbVehiclesIsInvalid', 'nombre de camions mal formaté'],
                ['NbOfTurnsIsInvalid', 'nombre de tours mal formaté'],
                ['ImportBesoinTransportLorieNotAllowded', 'Vous ne pouvez plus importer de Besoins de transport avec cette version obsolète de fichier'],
                ['ImportBesoinTransportLorieUnknown', 'Vous n\'utilisez pas la bonne version du fichier Excel d\'import des Besoins.'],
                ['ImportTransportRequestForThisLogisticsUnitNotAllowded', 'Vous ne disposez pas des droits pour créer des demandes de transport sur la zone logistique '],
                ['ChangedOrUndefinedVehicle', 'Camion inconnu ou modifié'],
                ['ChangedOrUndefinedDriver', 'Chauffeur inconnu ou modifié'],
                ['TransporterIsUnknownOrHasModified', 'Transporteur inconnu ou modifié'],
                ['PurchasePriceIsUnknownOrHasModified', 'Tarif inconnu ou modifié'],
                ['IncorrectVisibilty', 'Visibilité incorrecte'],
                ['ServiceKindIsMissing', 'Type de prestation absent'],
                ['RequestedByIsMissing', 'Il faut renseigner un demandeur'],
                ['BeneficiaryIsMissing', 'Il faut renseigner un bénéficiaire'],
                ['RequestedByAndBeneficiaryHasFilledInTheMultipleColumns', 'Vous devez renseigner soit "Demandeur" et "Bénéficiaire", ou soit  "demandeur externe référencé" et "Bénéficiaire externe référencé" et "Société externe référencée" ou soit "demandeur externe " et "Bénéficiaire externe " et "Société externe "'],
                ['RequestedByAndBeneficiaryMustBeExclusive', '"Demandeur" et "Bénéficiaire" doivent être renseignés exclusivement'],
                ['ExternalReferencedRequestedByAndBeneficiaryMustBeExclusive', '"Demandeur externe référencé" et "Bénéficiaire externe référencé" et "Société externe référencée" doivent être renseignés exclusivement'],
                ['ExternalRequestedByAndBeneficiaryMustBeExclusive', '"Demandeur externe" et "Bénéficiaire externe" et "Société externe" doivent être renseignés exclusivement'],
                ['DeliveryStartDateIsBiggerThanDeliveryEndDate', 'La date de début doit être inférieure ou égale à la date de fin'],
                ['IdentifierAlreadyExists', 'Le même identifiant est déjà présent sur une autre ligne du fichier'],
                ['HighPriorityFlowsCannotBeRequestedForMoreThanOneDay', 'Un Besoin dont la criticité est "Fixe" ne peut pas être planifié sur plusieurs jours'],
                ['JobsiteVehicleFlowsCannotBeRequestedForMoreThanOneDay', 'Un camion de chantier ne peut pas être planifié sur plusieurs jours. Veuillez créer une ligne de Besoin par jour'],
                ['ChangedOrUndefinedWorkAgency', 'Zone logistique inconnu ou modifié'],
                ['ChangedOrUndefinedProductionSite', 'UP inconnu ou modifié'],
                ['PlanningVehicleHasFinishedOrInProgressOrConfirmedTrip', "Suppression impossible car les tours associés sont au statut 'Confirmé', 'En cours' ou 'Terminé'"],
                ['PlanningVehicleHasTripsFromForeignLogisticsUnits', 'Suppression interdite car le camion contient des tours affectés à des zones logistiques non autorisées'],
                ['PlanningVehicleHasAnExternalClientConfirmed', 'Suppression impossible car le camion est un camion avec une immatriculation confirmée.'],
                ['CannotRemoveTripAsExpectedStatusShouldBeTransportNeededOrTransportPlanned', 'Suppression de tour(s) impossible car il existe un tour avec le statut "Terminé", "Confirmé" ou "En cours"'],
                ['CannotRemoveTripOnACanceledPlanningVehicleOrAsExpectedStatusShouldBeTransportNeededOrTransportPlanned', 'Suppression de tour(s) impossible car il existe un tour avec le statut "Terminé", "Confirmé" ou "En cours" ou faisant partie d`un camion annulé.'],
                ['CannotEditFinishedFlow', 'Modification de Flux impossible car le statut du Flux est terminé'],
                ['SenderAndReceiverSiteAreTiers', "le site de départ et le site d'arrivée ne peuvent pas être des clients \"Tiers\" pour le même Besoin"],
                ['SenderSiteIsMissingJobSiteMdmId', "le site de départ est une agence travaux, il faut également renseigner un idMDM chantier de départ"],
                ['ReceiverSiteIsMissingJobSiteMdmId', "le site d'arrivée est une agence travaux, il faut également renseigner un idMDM chantier d'arrivée"],
                ['CannotChangeTurnStatusToPlanifiedBecauseItsIncomplete', 'Modification du statut de(s) tour(s) impossible car un des champs suivant n\'est pas renseigné: "Type Camion", "Heure de chargement", "Heure d\'arrivée" et "Quantité"'],
                ['CannotChangeTurnStatusToConfirmedPendingOrFinishedBecauseItsIncomplete', 'Modification du statut de(s) tour(s) impossible car un des champs suivant n\'est pas renseigné: "Camion", "Chauffeur", "Type Camion", "Heure de chargement", "Heure d\'arrivée" et "Quantité"'],
                ['ChangedOrUndefinedContact', 'Contact inconnu ou modifié'],
                ['ImportCustomerContactForThisLogisticsUnitNotAllowded', 'Vous ne disposez pas des droits pour importer les visiblités zones logistiques des bénéficiaires et demandeurs de clients externes'],
                ['ImportCustomerForThisLogisticsUnitNotAllowded', 'Vous ne disposez pas des droits pour importer les visiblités zones logistiques des clients externes'],
                ['CustomerContactIsUnknownOrHasModified', 'Contact inconnu ou modifié'],
                ['CustomerIsUnknownOrHasModified', 'Client inconnu ou modifié'],
                ['PlanningVehicleDoesntExist', 'Action impossible car le camion a été supprimé.'],
                ['DailyPlanningVehicleDoesntExist', 'Le véhicule du jour du planning n\'existe pas ou a été déjà utilisé'],
                ['PlanningVehicleAlreadyExist', 'Le véhicule est déjà utilisé'],
                ['PlanningVehicleHasConfirmationOrder', 'Le camion cible est associé à un bon de commande déjà confirmé ou d\'un transporteur different.'],
                ['PlanningVehicleHasAtLeastOneDeliveryTrip', 'Le camion cible est associé à au moins une livraison.'],
                ['ImportPurchasePriceForThisLogisticsUnitNotAllowded', 'Certains sites de transport ne font pas (ou plus) partie de votre périmètre.'],
                ['PurchasePriceStartDateIsMissingOrHasInvalidFormat', 'date de début mal formatée ou absente.'],
                ['PurchasePriceEndDateIsMissingOrHasInvalidFormat', 'date de fin mal formatée ou absente.'],
                ['PurchasePriceKindIsUnknownOrHasModified', 'Type de tarif incorrect ou absent.'],
                ['TransporterIdIsMissing', 'Id du transporteur absent.'],
                ['VehicleTypeIdIsMissing', 'Type de véhicule absent.'],
                ['PriceIsMissingOrHasInvalidFormat', 'Tarif mal formaté ou absent.'],
                ['PriceMustBeGreaterThanZero', 'le tarif doit être supérieure ou égale à zéro.'],
                ['PurchasePriceStartDateIsLaterThanDeliveryEndDate', 'La date de début doit être inférieure ou égale à la date de fin.'],
                ['ContractedNumberOfDaysCannotBeLessThanZero', 'Le nbr jours contrat ne doit pas être inférieur à zéro.'],
                ['UpdateSpecificPriceFromDeliveryTripsMustBeOneVehicleNotCanceledWithTransporter', 'La modification du tarif spécifique d&rsquo;achat ne peut se faire que sur les tours d&rsquo;un unique camion, non annulé et avec un transporteur affecté.'],
                ['ModelUpdatesAreUnavailableDueToMaintenanceLock', 'La mise à jour n\'est pas possible en raison d\'une opération de maintenance.'],
                ['ThePriceGridStructureHasChangedInTheMeantime', 'La structure de la grille a changé : sauvegarde impossible.']
            ]),

        PerBusinessErrorType: new Map<string, Map<string, string>>(
            [
                ['AgencyError', new Map<string, string>([
                    ['AgencyIdIsNullOrWhiteSpace', 'AgencyId est vide ou null.'],
                    ['LabelIsNullOrWhiteSpace', 'Libellé est vide ou null.'],
                    ['CompanyIdDoesntMatchAnExistingCompany', 'CompanyId ne correspond pas à une société existante.'],
                    ['WorkAgencyAlreadyExists', 'L\'Unité d\'Exploitation existe déjà.'],
                    ['AgencyAlreadyExists', 'L\'Unité d\'Exploitation existe déjà, mais n\'est pas une UE Travaux.'],
                    ['AgencyIsDisabledOnColasApi', 'Impossible d\'ajouter cette UE car elle n\'est plus active.'],
                    ['AgencyNotFoundOnColasApi', 'Cette Unité d\'Exploitation Travaux n\'est pas présente dans le référentiel COLAS, vérifier l\'identifiant.'],
                    ['ParentCompanyCannotBeDeterminedOnColasApi', 'La Société Juridique à laquelle appartient l\'Unité d\'Exploitation n\'a pas pu être déterminée. Veuillez contacter votre administrateur LORIE.'],
                ])],
                ['AttachmentError', new Map<string, string>([
                    ['FileNameCannotBeEmpty', 'Le nom de fichier ne peut pas être vide.'],
                    ['ContentCannotBeNullOrEmpty', 'Le contenu ne peut pas être vide.']
                ])],
                ['CompanyError', new Map<string, string>([
                    ['CompanyIdIsNullOrWhiteSpace', 'CompanyId est vide ou null.'],
                    ['LabelIsNullOrWhiteSpace', 'Libellé est vide ou null.'],
                    ['CompanyNameIsNullOrWhiteSpace', 'CompanyName est vide ou null.'],
                    ['CompanyNotFoundOnColasApi', 'La Société Juridique n\'est pas présente dans le référentiel COLAS, vérifier l\'identifiant.']
                ])],
                ['CustomerError', new Map<string, string>([
                    ['ContactMdmIdIsNullOrWhiteSpace', 'ConactMdmId est vide ou null.'],
                    ['CustomerAlreadyExist', 'Client externe référencé existe déjà.'],
                    ['CustomerMdmIdDoesntMatchAnExistingCustomer', 'CustomerMdmId ne correspond pas à un client externe existant.']
                ])],
                ['DeliveryNoteError', new Map<string, string>([
                    ['NotificationDeliveryNoteIsNull', 'NotificationDeliveryNote est null.'],
                    ['NotificationDeliveryNoteIdentifierIsNullOrWhitespace', 'NotificationDeliveryNoteIdentifier est null ou vide.']
                ])],
                ['InternalDriverError', new Map<string, string>([
                    ['EmployeeIdIsNullOrWhiteSpace', 'EmployeeId est vide ou null.'],
                    ['EmployeeIsNull', 'l\'employé est null.'],
                    ['EmployeeEmployeeIdIsNullOrWhiteSpace', 'EmployeeId est vide ou null.'],
                    ['EmployeeIdCannotBeDifferentBetweenDriverAndEmployee', 'EmployeeId ne peut pas être différent entre le chauffeur et l\'employé.'],
                    ['FullNameIsNullOrWhiteSpace', 'FullName est vide ou null.'],
                    ['DriverAlreadyExist', 'Le chauffeur existe déjà.'],
                    ['AgencyIdDoesntMatchAnExistingAgency', 'L\'agence propriétaire du chauffeur n\'est pas connue. Veuillez contacter votre administrateur LORIE.'],
                    ['InternalTransporterNotFound', 'Le transporteur interne n\'existe pas.'],
                    ['DriverNotFound', 'Le chauffeur n\'existe pas.']
                ])],
                ['InternalVehicleError', new Map<string, string>([
                    ['EquipmentIdIsNullOrWhiteSpace', 'EquipmentId est vide ou null.'],
                    ['VehicleTypeIdIsNullOrWhiteSpace', 'VehicleTypeId est vide ou null.'],
                    ['VehicleTypeIdDoesntMatchAnExistingVehicleType', 'VehicleTypeId ne correspond pas à un type de véhicule existant.'],
                    ['EquipmentIsNull', 'Equipement est null.'],
                    ['EquipmentEquipmentIdIsNullOrWhiteSpace', 'EquipmentId est vide ou null.'],
                    ['EquipmentIdCannotBeDifferentBetweenVehicleAndEquipment', 'EquipmentId ne peut pas être différent entre le véhicule et l\'équipement.'],
                    ['DriverEmployeeIdDoesntMatchAnExistingInternalDriver', 'EmployeeId du chauffeur ne correspond pas à un chauffeur interne existant.'],
                    ['VehicleAlreadyExist', 'Camion existe déjà.'],
                    ['AgencyIdDoesntMatchAnExistingAgency', 'L\'agence propriétaire du camion n\'est pas connue. Veuillez contacter votre administrateur LORIE.'],
                    ['InternalTransporterNotFound', 'Le transporteur interne n\'existe pas.'],
                    ['VehicleNotFound', 'Le camion n\'existe pas.'],
                    ['DriverNotFound', 'Le chauffeur n\'existe pas.'],
                    ['DriverIsNotInternal', 'Le chauffeur n\'est pas un chauffeur Colas ou intérimaire.']
                ])],
                ['Level2ActivityError', new Map<string, string>([
                    ['Level2ActivityIdIsNullOrWhiteSpace', 'Level2ActivityId est vide ou null.'],
                    ['Level3SegmentationIdIsNullOrWhiteSpace', 'Level3SegmentationId est vide ou null.']
                ])],
                ['Level3SegmentationError', new Map<string, string>([
                    ['Level3SegmentationIdIsNullOrWhiteSpace', 'Level3SegmentationId est vide ou null.'],
                    ['LabelIsNullOrWhiteSpace', 'Libellé est vide ou null.']
                ])],
                ['PlanningVehicleError', new Map<string, string>([
                    ['PlanningVehicleDateAndPlanningIdDoNotMatch', 'PlanningVehicleDate et PlanningId ne correspondent pas.'],
                    ['PlanningVehicleDoesntExist', 'Action impossible car le camion a été supprimé.'],
                    ['VehicleAlreadyAssociatedOnPlanningDate', 'Véhicule déjà associé au PlanningDate.'],
                    ['ExpectedVehicleTypeCapacityIsMissingOrInvalid', 'La capacité prévue du type de véhicule est manquante ou invalide.'],
                    ['LogisticsUnitIdDoesntMatchAnExistingLogisticsUnit', 'La zone logistique ne correspond pas à une zone logistique existante.'],
                    ['VehicleTypeIdDoesntMatchAnExistingVehicleType', 'VehicleTypeId ne correspond pas à un type de véhicule existant.'],
                    ['PlanningVehicleHasInProgressOrFinishedTrip', 'Le camion contient des tours au statut "En cours" ou "Terminé"'],
                    ['PlanningVehicleHasFinishedOrInProgressOrConfirmedTrip', 'Le camion contient des tours au statut "Confirmé", "En cours" ou "Terminé"'],
                    ['PlanningVehicleHasAnExternalClientConfirmed', 'Suppression impossible car le camion est un camion avec une immatriculation confirmée.'],
                    ['PlanningVehicleIsEarlierThanToday', 'PlanningVehicle est plus tôt qu\'aujourd\'hui.'],
                    ['PlanningVehicleHasTripsFromForeignLogisticsUnits', 'Le camion contient des tours affectés à des zones logistiques non autorisées'],
                    ['CannotChangeTurnStatusToPlanifiedBecauseItsIncomplete', 'Modification du statut de(s) tour(s) impossible car un des champs suivant n\'est pas renseigné: "Type Camion", "Heure de chargement", "Heure d\'arrivée" et "Quantité"'],
                    ['CannotChangeTurnStatusToConfirmedPendingOrFinishedBecauseItsIncomplete', 'Modification du statut de(s) tour(s) impossible car un des champs suivant n\'est pas renseigné: "Camion", "Chauffeur", "Type Camion", "Heure de chargement", "Heure d\'arrivée" et "Quantité"'],
                    ['DailyPlanningVehicleDoesntExist', 'Le véhicule du jour du planning n\'existe pas ou a été déjà utilisé'],
                    ['PlanningVehicleHasTransporterOrderLine', 'PlanningVehicle a une ligne de commande de transporteur.'],
                    ['PlanningVehicleAlreadyExist', 'Le véhicule est déjà utilisé'],
                    ['PlanningVehicleHasConfirmationOrder', 'Le camion cible est associé à un bon de commande déjà confirmé ou d\'un transporteur different.'],
                    ['PlanningVehicleHasTripsWithSpecificPrices', 'Le camion cible est associé à des trajets avec des tarifs specifiqués d\'un transporteur different.'],
                    ['PlanningVehicleHasAtLeastOneDeliveryTrip', 'Le camion cible est associé à au moins une livraison.'],
                    ['VehicleAlreadySendedToZephyr', 'Le camion cible est associé à au moins une livraison.'],
                    ['PlanningVehicleDosentMatchWithTransporterId', 'Vous n\'avez pas le droit de modifier ce camion.'],
                    ['CannotDisagreePublishedCostsWithEmptyReasons', 'Vous ne pouvez pas contester ce camion sans commentaire.'],
                    ['TransportFlowNotFound', 'Le Flux n\'existe pas.'],
                    ['DriverIdDoesntMatchAnExistingDriver', 'DriverId ne correspond pas à un chauffeur existant.'],
                    ['PlanningVehicleHasFinishedOrInProgressTrips', 'Le camion est associé à des tours au statut EN COURS ou TERMINÉ.'],
                    ['PlanningVehicleTransporterAndOrderTransporterDoNotMatch', 'Le transporteur déjà affecté au camion de planning et le transporteur spécifié dans la confirmation de commande ne correspondent pas.'],
                    ['PlanningVehicleTransporterOrderIdAndConfirmedTransporterOrderIdDoNotMatch', 'Le camion de planning n\'est pas (ou plus) sur la commande transporteur.'],
                    ['VehicleTypePurchasePriceNotFound', 'Il n\'y a pas de coût journalier moyen pour ce type de véhicule sur la zone logistique.'],
                    ['TransportPurchasePriceChoiceNotFound', 'Il n\'y a pas de tarif d\'achat JOUR/NUIT sur le type de véhicule, pour le transporteur et la zone logistique demandés.'],
                    ['CannotRemoveContractualPurchaseBecauseThereIsNoMainPriceAvailable', "Il n'y a pas de tarif d'achat JOUR/NUIT (hors PAC) sur le type de véhicule, pour le transporteur et la zone logistique demandés."],
                    ['PlanningVehicleHasProvisionings', "Suppression impossible car le camion fait partie d`un lot dans les provisions d`achat."],
                    ['PlanningVehicleHasTransportFlowsWithPreInvoices', "Suppression impossible car le camion est associé à un flux préfacturé dans l`écran Ventes."],
                    ['CannotChangeTransporterVehicleTypeWhenPlanningVehicleHasProvisionings', "Impossible de changer de transporteur car le camion fait partie d'un lot."],
                    ['ContractualPurchasePriceAlreadyUsedOnThePlanningDate', "Le tarif PAC est déjà associé à un autre camion du planning."],
                    ['ThePlanningVehicleIsNotContractualPriced', "Aucun tarif PAC associé au camion."],
                    ['ActionCannotBePerformedOnACanceledPlanningVehicle', 'Impossible d\'effectuer l\'action sur un camion au statut "Annulé".'],
                    ['ActionCannotBePerformedOnAPurchaseCanceledPlanningVehicle', 'Impossible d\'effectuer l\'action sur un camion au statut "Annulé" (achats).'],
                    ['PlanningVehicleHasBeenCanceledInTheMeantime', 'Le camion a été annulé entre-temps.'],
                    ['PlanningVehicleHasBeenReinstatedInTheMeantime', 'Le camion a été rétabli entre-temps.'],
                    ['InvalidDeliveryOverrideKindString', 'Le type de surcharge n\'existe pas.'],
                    ['InvalidSpecificPurchasePriceKindString', 'Le type du tarif d\'achat spécifique n\'existe pas.'],
                    ['CannotOverrideNumberOfTurnsForSpecificPrice', 'Impossible de surcharger le nombre de tours sur un tarif d\'achat spécifique.']
                ])],
                ['ProductionSiteError', new Map<string, string>([
                    ['AssociatedManagerUserNameIsNullOrWhiteSpace', 'ManagerUserName est null ou vide.'],
                    ['AssociatedManagerUserNotFound', 'ManagerUserName n\'existe pas.'],
                    ['ProductionSiteIdIsNullOrWhiteSpace', 'ProductionSiteId est vide ou null.'],
                    ['AgencyIdIsNullOrWhiteSpace', 'AgencyId est vide ou null.'],
                    ['LabelIsNullOrWhiteSpace', 'Libellé est vide ou null.'],
                    ['AgencyIdDoesntMatchAnExistingAgency', 'AgencyId ne correspond pas à une agence existante.'],
                    ['Level2ActivityIdIsNullOrWhiteSpace', 'Level2ActivityId est vide ou null.'],
                    ['AssignedLogisticianUserNameIsNullOrWhiteSpace', 'Le nom de logisticien est vide ou null.'],
                    ['AssignedLogisticianUserNameDoesntMatchAnExistingLogistician', 'Le logicticien ne correspond pas à un logicticien existant.'],
                    ['LogisticsUnitIdIsNullOrWhiteSpace', 'La zone logistique est vide ou nulle.'],
                    ['LogisticsUnitIdDoesntMatchAnExistingLogisticsUnit', 'La zone logistique ne correspond pas à une zone logistique existante.'],
                    ['ProductionSiteNotFound', 'L\'Unité de Production n\'existe pas.'],
                    ['CannotRemoveLogisticsUnitWithPurchaseProvisionings', 'La zone logistique ne peut pas être supprimée car elle contient au moins un lot de provision d\'achats.'],
                    ['CannotRemoveLogisticsUnitWithPlanningVehicles', 'La zone logistique ne peut pas être supprimée car elle contient au moins un camion de planning.'],
                    ['CannotRemoveLogisticsUnitWithTransportFlows', 'La zone logistique ne peut pas être supprimée car elle contient au moins un flux.'],
                    ['CannotRemoveLogisticsUnitWithTransportRequests', 'La zone logistique ne peut pas être supprimée car elle contient au moins un besoin.'],
                    ['CannotRemoveLogisticsUnitWithNegotiatedSellPrices', 'La zone logistique ne peut pas être supprimée car elle contient au moins un prix de vente négocié.'],
                    ['ProductionSiteAlreadyExists', 'L\'Unité de Production existe déjà.'],
                    ['ProductionSiteNotFoundOnColasApi', 'Cette Unité de Production n\'est pas présente dans le référentiel COLAS, vérifier l\'identifiant.'],
                    ['ProductionSiteIsDisabledOnColasApi', 'Impossible d\'ajouter cette UP car elle n\'est plus active.'],
                    ['BUsActivitiesOfProductionSiteNotFoundOnColasApi', 'Les BUs de cette Unité de Production ne sont pas présents dans le référentiel COLAS, vérifier l\'identifiant.'],
                    ['ParentAgencyCannotBeDeterminedOnColasApi', 'L\'Unité d\'Exploitation à laquelle appartient l\'Unité de Production n\'a pas pu être déterminée. Veuillez contacter votre administrateur LORIE.'],
                    ['LogisticsUnitLogisticianNotFound', 'Le logisticien n\'est plus associé à cette Zone Logistique.']
                ])],
                ['TransporterError', new Map<string, string>([
                    ['TransporterIdIsNullOrWhiteSpace', 'TransporterId est vide ou null.'],
                    ['SupplierIdIsNullOrWhiteSpace', 'SupplierId est vide ou null.'],
                    ['TransporterIdAndSupplierIdAreNotEqual', 'TransporterId et SupplierId ne ne correspondent pas.'],
                    ['SupplierLabelIsNullOrWhiteSpace', 'SupplierLabel est vide ou null.'],
                    ['SupplierCompanyNameIsNullOrWhiteSpace', 'SupplierCompanyName est vide ou null.'],
                    ['SupplierShortLabelIsNullOrWhiteSpace', 'SupplierShortLabel est vide ou null.'],
                    ['SupplierColasCompanyIdentifierIsNullOrWhiteSpace', 'SupplierColasCompanyIdentifier est vide ou null.'],
                    ['SupplierMdmIdentifierIsNullOrWhiteSpace', 'SupplierMdmIdentifier est vide ou null.'],
                    ['PurchasePriceVehicleTypeIdIsNullOrWhiteSpace', 'PurchasePriceVehicleTypeId est vide ou null.'],
                    ['TransporterIdDoesntMatchAnExistingTransporter', 'TransporterId ne correspond pas à un transporteur existant.'],
                    ['PurchasePriceVehicleTypeIdDoesntMatchAnExistingVehicleType', 'PurchasePriceVehicleTypeId ne correspond pas à un type de véhicule existant.'],
                    ['VehicleTypeIdIsNullOrWhiteSpace', 'VehicleTypeId est vide ou null.'],
                    ['VehicleTypeIdDoesntMatchAnExistingVehicleType', 'VehicleTypeId ne correspond pas à un type de véhicule existant.'],
                    ['DriverFullNameIsNullOrWhiteSpace', 'DriverFullName est vide ou null.'],
                    ['TransporterDriverAlreadyExists', 'Le chauffeur existe déjà.'],
                    ['TransporterVehicleAlreadyExists', 'Le camion existe déjà.'],
                    ['TransporterDriverFullNameIsMandatory', 'Le nom de chauffeur est obligatoire.'],
                    ['TransporterDriverPhoneNumberIsMandatory', 'Le numéro de téléphone de chauffeur est obligatoire.'],
                    ['TransporterVehicleLicencePlateIsMandatory', 'Le numéro d\'immatriculation est obligatoire.'],
                    ['TransporterVehicleLoadCapacityMustBeGreaterThanZero', 'La capacité de camion doit être supérieure à zéro.'],
                    ['PurchasePriceChoiceAlreadyExists', 'Le choix du prix d\'achat existe déjà.'],
                    ['TransporterChoiceAlreadyExists', 'Le choix du transporteur existe déjà.'],
                    ['PurchasePriceChoiceNotFound', 'Le choix du prix d\'achat n\'existe pas.'],
                    ['TransporterChoiceNotFound', 'Le choix du transporteur n\'existe pas.'],
                    ['TransporterManagerUserNameOrEmailAlreadyUsed', 'L\'email principal saisi est déjà le contact principal d\'un autre transporteur, veuillez saisir un autre email de contact principal.'],
                    ['TransporterAlreadyExist', 'Le transporteur existe déjà.'],
                    ['TransporterDoesntExist', 'Le transporteur n\'existe pas.'],
                    ['TransporterManagerUserNameNotExists', 'Le transporteur n\'a pas de contact principal défini.']
                ])],
                ['TransporterOrderError', new Map<string, string>([
                    ['TransporterIdDoesntMatchAnExistingTransporter', 'TransporterId ne correspond pas à un transporteur existant.'],
                    ['TransporterOrderIdDoesntMatchAnExistingOrder', 'TransporterOrderId ne correspond pas à un bon de commande existant.'],
                    ['TransporterOrderLineIdDoesntMatchAnExistingOrderLine', 'TransporterOrderLineId ne correspond pas à une ligne de commande existante.'],
                    ['SomePlanningVehiclesNoLongerExistsOrHaveBeenAlreadyAssignedToAnotherTransporter', 'Certains camions de planning n\'existent plus ou sont déjà attribués à un autre transporteur.'],
                    ['TransporterOrderLineOrderedVehicleTypeDoesntMatchAnExistingVehicleType', 'TransporterOrderLineOrderedVehicleType ne correspond pas à un type de véhicule existant.'],
                    ['TransporterOrderLineSuppliedVehicleTypeGroupAndOrderedVehicleTypeGroupDoesntMatch', 'TransporterOrderLineSuppliedVehicleTypeGroup et OrderedVehicleTypeGroup ne correspond pas.']
                ])],
                ['TransportRequestError', new Map<string, string>([
                    ['NotificationTransportRequestIsNull', 'NotificationTransportRequest est null.'],
                    ['NotificationTransportRequestdentifierIsNullOrWhitespace', 'NotificationTransportRequestdentifier est null ou vide.'],
                    ['DeliveryTripIdDoesntMatchAnExistingDeliveryTrip', 'DeliveryTripId ne correspond pas à un tour existant.'],
                    ['CannotDragTripWhenExistingLoadingTimeIsNull', 'Impossible de faire glisser le tour lorsque le temps de chargement existant est null.'],
                    ['CannotDragTripWhenExistingDeliveryTimeIsNull', 'Impossible de faire glisser le tour lorsque le délai de livraison existant est null.'],
                    ['CannotResizeTripWhenExistingLoadingTimeIsNull', 'Impossible de redimensionner le tour lorsque le temps de chargement existant est null.'],
                    ['CannotResizeTripWhenExistingDeliveryTimeIsNull', 'Impossible de redimensionner le tour lorsque le délai de livraison existant est null.'],
                    ['JobForemanUserNameNotFound', 'Le nom de bénéficiaire n\'existe pas.'],
                    ['DeliveryTripNotFound', 'Le tour n\'existe plus.'],
                    ['LastObservedRootChangeIdDoesntMatchAnExistingRootChange', 'Le dernier RootChangeId observé ne correspond pas à un RootChange existant.'],
                    ['CannotDragTripAsTheVehicleWasNotFound', 'Impossible de faire glisser le tour car le camion n\'existe pas.'],
                    ['CannotUndoWhenNotAlreadyDone', 'Impossible d\'annuler lorsque ce n\'est pas déjà terminé.'],
                    ['AlreadyFinished', 'Déjà terminé.'],
                    ['ItsToLateToDeclareFees', 'Il est trop tard pour déclarer les frais.'],
                    ['NoPriceIsAvailableForThisTransporter', 'Aucun prix n\'est disponible pour ce transporteur.'],
                    ['ThisVehicleIsNotavailableForThisTransporter', 'Ce camion n\'est pas disponible pour ce transporteur.'],
                    ['DeliveryTripNotFoundByRotationNumber', 'Le tour est introuvable par numéro de rotation.'],
                    ['TransportFlowNotFound', 'Le Flux n\'existe pas.'],
                    ['JobsiteTransportFlowIsMissingArrivalTimeAtReceiverSite', 'L\'heure d\'arrivée sur le chantier est manquante.'],
                    ['JobsiteTransportFlowIsMissingUnloadingDuration', 'La durée de déchargement sur le chantier est manquante.'],
                    ['CreatingTripsByDraggingJobsiteVehicleFlowsIsNotSupported', 'La création des tours en faisant glisser les Flux de type camion de chantier n\'est pas prise en charge.'],
                    ['CannotRefreshRawCostsAsTheFlowVehicleTypeWasNotFound', 'Impossible d\'actualiser les coûts car le type de véhicule de Flux n\'a pas été trouvé.'],
                    ['CannotRefreshRawCostsAsTheFlowVehicleTypeHasNoPurchaseCostDefinedForTheLogisticsUnit', 'Impossible d\'actualiser les coûts car le type de véhicule de Flux n\'a aucun coût d\'achat défini pour la zone logistique.'],
                    ['TransportFlowIsMissingEstimatedTripDuration', 'Le Flux n\'a pas la durée estimée du tour.'],
                    ['InvalidCoatingApplicationModeString', 'Type de chantier invalid.'],
                    ['TransportFlowCannotBeCreatedAsTheLogisticsUnitIsDifferentThanLogisticsUnitOfTheRelatedTransportRequest', 'Le Flux ne peut pas être créé car la zone logistique est différente de la zone logistique de la demande de transport associée.'],
                    ['SenderSiteResolutionFailedBecauseOfInvalidOrIncompleteData', 'Les données du site de départ son invalides ou incomplètes.'],
                    ['ReceiverSiteResolutionFailedBecauseOfInvalidOrIncompleteData', 'Les données du site d\'arrivée son invalides ou incomplètes.'],
                    ['Level3SegmentationNotFound', 'Segmentation niveau 3 n\'existe pas.'],
                    ['BeneficiaryNotFound', 'Le bénéficiaire n\'existe pas.'],
                    ['RequesterNotFound', 'Le demandeur n\'existe pas.'],
                    ['SenderSiteAgencyNotFound', 'L\'agence de site de production du site de départ n\'existe pas.'],
                    ['SenderSiteWorkAgencyNotFound', 'L\'agence travaux du site de départ n\'existe pas.'],
                    ['ReceiverSiteAgencyNotFound', 'L\'agence de site de production de site d\'arrivé n\'existe pas.'],
                    ['ReceiverSiteWorkAgencyNotFound', 'L\'agence travaux de site d\'arrivé n\'existe pas.'],
                    ['ReceiverProductionSiteNotFound', 'ReceiverProductionSite n\'existe pas.'],
                    ['SenderProductionSiteNotFound', 'SenderProductionSite n\'existe pas.'],
                    ['ReceiverSiteCustomerNotFound', 'ReceiverSiteCustomer n\'existe pas.'],
                    ['SenderSiteCustomerNotFound', 'SenderSiteCustomer n\'existe pas.'],
                    ['PlanningVehicleDoesntExist', 'Action impossible car le camion a été supprimé.'],
                    ['SpecificPriceRemovalWasNotConfirmed', 'La suppression d\'un prix spécifique n\'a pas été confirmée.'],
                    ['SenderSiteCompanyNotfound', 'SenderSiteCompany n\'existe pas.'],
                    ['ReceiverSiteCompanyNotfound', 'ReceiverSiteCompany n\'existe pas.'],
                    ['SenderLevel2ActivityNotFound', 'SenderLevel2Activity n\'existe pas.'],
                    ['ReceiverLevel2ActivityNotFound', 'ReceiverLevel2Activity n\'existe pas.'],
                    ['JobsiteVehicleCustomerCannotBeNull', 'JobsiteVehicleCustomer ne peut être null.'],
                    ['TransportFlowAttachmentNotFound', 'La pièce jointe n\'existe pas.'],
                    ['AttachmentNotFound', 'La pièce jointe n\'existe pas.'],
                    ['LogisticsUnitCannotBeNull', 'La zone logistique ne peut pas être nulle.'],
                    ['LogisticsUnitNotFound', 'La zone logistique n\'existe pas.'],
                    ['VehicleTypeNotFound', 'Le type de vehicule n\'existe pas.'],
                    ['PayerLevel2ActivityRequiredWhenPayerEntityIsProductionSite', 'l\'activité du payeur est obligatoire.'],
                    ['PayerBeneficiaryRequiredWhenPayerEntityIsAgency', 'le chef de chantier du payeur est obligatoire.'],
                    ['PayerJobSiteMdmIdRequiredWhenPayerEntityIsAgency', 'l\'idMdm chantier du payeur est obligatoire.'],
                    ['NbTripsMustBeBetweenOneAndNine', 'Le nombre de tours doit être entre 1 et 9.'],
                    ['CannotChangeTransportFlowEndDateWhenPriorityIsOtherThanLow', 'Impossible de modifier la date de fin d\'un flux non modulable.'],
                    ['CannotChangeTransportFlowEndDateWithAnEndDateOlderThanTheExisting', 'La nouvelle Date de fin du flux doit être ultérieure à la date de fin initiale.'],
                    ['PlanningVehicleHasPlannedFlowDeliveryTripsChanged', 'Ce camion a subi un changement de planification pour ce Flux.'],
                    ['TheRemainingQuantityIsZeroOrNegativeOnTransportFlow', 'Impossible de créer des tours pour un Flux avec une quantité restante à livrer nulle ou négative.'],
                    ['RequestQuantityMoreThanTransportFlowRemainingQuantity', 'Le reste à livrer d\'au moins un Flux ne permet pas le déplacement de tous les tours sélectionnés.'],
                    ['CannotDuplicateDeliveryTripForJobSiteVehicle', 'Impossible de dupliquer le tour car il est issue d\'un flux de type camion de chantier'],
                    ['CannotRemoveTransportFlowWithIntegratedPreInvoice', 'Impossible de supprimer un flux avec un BL de ventes intégré'],
                    ['ActionCannotBePerformedOnACanceledPlanningVehicle', 'Impossible d\'effectuer l\'action sur un camion au statut "Annulé".'],
                    ['ActionCannotBePerformedOnAPurchaseCanceledPlanningVehicle', 'Impossible d\'effectuer l\'action sur un camion au statut "Annulé" (achats).'],
                    ['CannotRemoveTripAsExpectedStatusShouldBeTransportNeededOrTransportPlanned', 'Le statut du tour a changé entre temps, merci d\'actualiser votre page.'],
                    ['InvalidTransportPurchasePriceKindString', 'Le type de tarification est invalide.'],
                    ['ActionCannotBePerformedBecauseThePlanningVehicleTransporterChanged', 'Le transporteur du camion a changé entre temps, veuillez réactualiser la page.'],
                    ['ActionCannotBePerformedBecauseOneOrMoreDeliveryTripsDoesntExist', 'Au moins un tour a été supprimé entre temps, veuillez réactualiser la page.'],
                    ['ActionCannotBePerformedBecauseThePlanningVehicleChangedForOneOrMoreDeliveryTrips', 'Au moins un tour a subi des modifications entre temps, veuillez réactualiser la page.'],
                    ['UnconfirmedExistenceOfDeliveryOverridesOnNegotiatedTrips', 'Le Flux est déjà associé à un tarif achat négocié pour lequel il existe au moins une surcharge dans l\'écran Achats.'],
                    ['UnconfirmedExistenceOfDeliveryOverridesOnSpecificOrNegotiatedTrips', 'Au moins un tour sélectionné est déjà associé à un tarif achat négocié ou spécifique pour lequel il existe au moins une surcharge dans l\'écran Achats.'],
                    ['AtLeastOneChoiceOfTransporterEntityTypeBetweenInternalAndOrExternalIsMandatoryForTheNegotiatedPurchasePrice', 'Au moins un choix de type de transporteur entre interne et/ou externe est obligatoire pour le prix d\'achat négocié'],
                    ['AVehicleTypeWithNonNullVehicleTypeGroupIdIsMandatoryForNegotiatedPurchasePrice', 'Un type de camion avec une famille associée est obligatoire pour le prix d\'achat négocié'],
                    ['CannotResizeTripWhenResuestedDeliveryTimeExceed24Hours', 'Modification impossible: La durée totale d\'utilisation du camion ne peut dépasser 24h']
                ])],
                ['UserError', new Map<string, string>([
                    ['UserNameIsNullOrWhiteSpace', 'UserName est vide ou null.'],
                    ['EmailIsNullOrWhiteSpace', 'Email est vide ou null.'],
                    ['ContentIdIsNullOrWhiteSpace', 'ContentId est vide ou null.'],
                    ['UserExistWithDifferentRole', 'Cet utilisateur est déjà présent dans LORIE avec un autre profil (ADM, LOG ou DIS).'],
                    ['UserNotFoundInColasRepository', 'Cet utilisateur n\'est pas présent dans le référentiel COLAS, vérifier le login.'],
                    ['UserNotFound', 'L\'utilisateur n\'existe pas.'],
                    ['DisabledUser', 'Cet utilisateur est déjà présent dans LORIE, mais est actuellement désactivé. Veuillez contacter votre administrateur.'],
                    ['InvalidUserSignatureFieldType', 'Type de champ invalide.']
                ])],
                ['PreInvoiceError', new Map<string, string>([
                    ['PreInvoiceDoesntExist', 'La synchronisation n\'existe pas.'],
                    ['PreInvoiceDetailDoesntExist', 'Le détail de synchronisation n\'existe pas.'],
                    ['PreInvoiceVersionOfAggregateHasChangedInTheMeantime', 'La synchronisation a changé entre temps. Veuillez réessayer.'],
                    ['TransportFlowDoesntExist', 'Le Flux n\'existe pas.'],
                    ['PreInvoiceAlreadySentToZephyr', 'Le BL de vente a été déjà envoyé.'],
                    ['PreInvoiceDeliveredQuantityShouldBeGreaterThanZeroWhenPerTonPriceKind', 'La quantité doit être supérieure à zéro pour une vente à la tonne'],
                    ['PreInvoiceDeliveredNumberOfTurnShouldBeGreaterThanZeroWhenPerTurnPriceKind', 'Le nombre des tours doit être supérieur à zéro pour une vente au tour'],
                    ['PreInvoiceInvoicePriceShouldBeDifferentThanZero', 'Le prix à facturer doit être différent de zéro'],
                    ['PreInvoicePriceKindShouldBeDifferentThanNull', 'Le type de tarification est obligatoire sur le Flux'],
                    ['OriginalPreInvoiceDoesntExist', 'Le BL de vente d\'origine n\'existe pas.'],
                    ['OriginalPreInvoiceNotSuccessfullySentToZephyr', 'Le BL de vente d\'origine doit être d\'abord integré.'],
                    ['OriginalPreInvoiceHasAnotherRegularizationPending', 'Une régularisation en attente d\'intégration existe déjà sur ce BL de vente.'],
                    ['AnotherRegularizationPreInvoiceHasBeenCreatedAndSentInTheMeantime', 'Une autre régularisation a été crée et intégrée entre temps. Rafraîchissez les données et réessayez si nécessaire.'],
                    ['CannotPreInvoiceRegularization', 'Seules les BLs de vente non intégré peuvent être synchronisé.'],
                    ['PreInvoiceAlreadyDeleted', 'Le BL n\'est plus disponible pour une suppression.'],
                    ['PlanningVehicleDoesntExist', 'Le camion de planning n\'existe pas.'],
                    ['PlanningVehicleStatusChangedInTheMeantime', 'Le statut d\'un camion associé à au moins un Flux a changé entre temps, merci d\'actualiser votre page.']
                ])],
                ['VehicleTypeError', new Map<string, string>([
                    ['VehicleTypeIdIsNullOrWhiteSpace', 'VehicleTypeId est vide ou null.'],
                    ['LabelIsNullOrWhiteSpace', 'Libellé est vide ou null.'],
                    ['PurchaseCostLogisticsUnitIdIsNullOrWhitespace', 'VehicleTypePurchasePrice.LogisticsUnitId est vide ou null.'],
                    ['PurchaseCostLogisticsUnitIdDoesntMatchAnExistingLogisticsUnit', 'VehicleTypePurchasePrice.LogisticsUnitId ne correspond pas à une zone logistique existante.'],
                    ['LogisticsUnitVehicleTypePurchasePriceMustBeGreaterThanZero', 'Le coût du tarif négocié doit être supérieure à zéro.'],
                    ['LogisticsUnitNotFound', 'La zone logistique n\'existe pas'],
                    ['VehicleTypeNotFound', 'Type du vehicule introuvable.']

                ])],
                ['DieselIndexTemplateError', new Map<string, string>([
                    ['DieselIndexTemplateIdIsNullOrWhiteSpace', 'L\'identifiant du modèle d\'indice gazole est vide ou null'],
                    ['LogisticsUnitIdDoesntMatchAnExistingLogisticsUnit', 'La zone logistique ne correspond pas à une zone logistique existante.'],
                    ['VehicleTypeGroupIdDoesntMatchAnExistingVehicleTypeGroup', 'La famille de camions ne correspond pas à une famille existante.'],
                    ['EndDateShouldBeGreaterThanStartDate', 'La date de fin doit être supérieure à la date de début'],
                    ['StartDateCannotBeNull', 'La date de début doit être différente de null'],
                    ['EndDateCannotBeNull', 'La date de fin doit être différente de null'],
                    ['WeightingValueShouldBeBetweenZeroAndOne', 'La valeur de l\'indice de pondération doit être entre 0% et 100%']
                ])],
                ['DieselIndexError', new Map<string, string>([
                    ['DieselIndexIdIsNullOrWhiteSpace', 'L\'identifiant d\'indice gazole est vide ou null'],
                    ['DieselIndexNotFound', 'L\'indice gazole n\'existe plus.'],
                    ['LogisticsUnitIdDoesntMatchAnExistingLogisticsUnit', 'La zone logistique ne correspond pas à une zone logistique existante.'],
                    ['TransporterIdDoesntMatchAnExistingTransporter', 'Le transporteur ne correspond pas à un transporteur existant.'],
                    ['VehicleTypeGroupIdDoesntMatchAnExistingVehicleTypeGroup', 'La famille de camions ne correspond pas à une famille existante.'],
                    ['EndDateShouldBeGreaterThanStartDate', 'La date de fin doit être supérieure à la date de début'],
                    ['StartDateCannotBeNull', 'La date de début doit être différente de null'],
                    ['EndDateCannotBeNull', 'La date de fin doit être différente de null'],
                    ['WeightingValueShouldBeBetweenZeroAndOne', 'La valeur de l\'indice de pondération doit être entre 0% et 100%']
                ])],
                ['CnrGasoilIndexError', new Map<string, string>([
                    ['FirstDateOfMonthCannotBeNull', 'La date de début doit être différente de null.'],
                    ['CnrGasoilIndexAlreadyExists', 'L\'index existe déjà.'],
                    ['CnrGasoilIndexDoesntMatchAnExistingIndex', 'L\'index n\'existe pas.'],
                    ['FirstDateDosentMatchWithTheFirstDayOfMonth', 'La date de début ne corrspond pas au premier jour du mois.']
                ])],
                ['PurchaseProvisioningError', new Map<string, string>([
                    ['PurchaseProvisioningDetailNotFound', 'Le détail de la provision ne correspond pas à un détail existant ou a été supprimé entre-temps.'],
                    ['PurchaseProvisioningNotFound', 'La provision ne correspond pas à une provision existante ou a été supprimée entre-temps.'],
                    ['TransporterDoesntExist', 'Le transporteur ne correspond pas à un transporteur existant.'],
                    ['AtLeastOnePlanningVehicleNotFound', 'Un ou plusieurs camions n\'est (ne sont) plus disponible(s).'],
                    ['PurchaseProvisioningDetailCountShouldBeGreaterThanZero', 'Le détail des camions du lot est vide.'],
                    ['PurchaseProvisioningShouldBePurchaseCostOrFeesDifferentThanNullOrZero', 'Un des éléments Coût/Frais doit être renseigné et supérieur à zéro.'],
                    ['PlanningVehiclePurchaseCostAlreadyProvisioned', 'Un des coûts de(s) camion(s) a déjà été pris en compte dans un lot.'],
                    ['PlanningVehicleFeesAlreadyProvisioned', 'Un des frais de(s) camion(s) a déjà été pris en compte dans un lot.'],
                    ['SumOfPurchaseCostAndGasoilExceedsTheMaximumAllowedLimit', 'La somme des lignes "Transport" et "Gazole" dépasse la limite autorisée.'],
                    ['PurchaseProvisioningIsAlreadySuccessfullySent', 'La provision a déjà été integrée dans Zephyr.'],
                    ['PurchaseProvisioningAlreadyDeleted', 'Le lot n\'est plus disponible pour une suppression car déjà supprimé.'],
                    ['PurchaseProvisioningAlreadyIntegrated', 'Le lot n\'est plus disponible pour une suppression car déjà intégré.'],
                    ['ChangingProvisioningRemarksNotAllowedForExternalTransportersProvisioningIntegrated', 'Le lot n\'est plus disponible pour la mise à jour du commentaire car déjà intégré.'],
                    ['ChangingProvisioningVisiblityInBINotAllowedForExternalTransporters', 'Il n\'est pas possible de changer la prise en compte dans les KPI pour les provisions des transporteurs externes.'],
                    ['TheTransporterHasChangedForAtLeastOneOfThePlanningVehicles', 'Le transporteur a entre-temps changé pour (au moins) un des camions.'],
                    ['TheVehicleTypeHasChangedForAtLeastOneOfThePlanningVehicles', 'Le type de véhicule a entre-temps changé pour (au moins) un des camions.'],
                    ['AtLeastOnePlanningVehicleNotRegularizable', 'Il existe au moins un camion dont la provision n\'existe plus ou n\'est plus incluse dans les KPI'],
                    ['ActionCannotBePerformedOnACanceledPlanningVehicle', 'Le statut du camion a changé entre temps, merci d\'actualiser votre page.'],
                    ['ActionCannotBePerformedOnAPurchaseCanceledPlanningVehicle', 'Le statut du camion a changé entre temps, merci d\'actualiser votre page.']
                ])],
                ['SmsNotificationError', new Map<string, string>([
                    ['PlanningVehicleIdShouldBeDifferentThatNullOrEmpty', 'Le camion de planning ne correspond pas à un camion existant ou a été supprimé entre-temps.'],
                    ['TransportFlowIdShouldBeDifferentThatNullOrEmpty', 'Le flux ne correspond pas à un flux existant.'],
                    ['DriverIdDoesntMatchAnExistingDriver', 'Le chauffeur n\'existe pas.'],
                    ['DriverNotPlannedOnVehicleAndFlow', 'Le chauffeur ne travaille plus sur ce camion et flux.'],
                    ['MissingOrangeDiffusionIdIndicatesSendFailed', 'La demande de diffusion SMS a échoué.']
                ])],
                ['OverheadExpenseError', new Map<string, string>([
                    ['OverheadExpenseDoesntMatchAnExistingExpense', 'L\'identifiant des frais de structure n\'existe pas.'],
                    ['LogisticsUnitIdDoesntMatchAnExistingLogisticsUnit', 'La zone logistique ne correspond pas à une zone logistique existante.'],
                    ['EndDateShouldBeGreaterThanStartDate', 'La date de fin doit être supérieure à la date de début.'],
                    ['StartDateCannotBeNull', 'La date de début doit être différente de null.'],
                    ['EndDateCannotBeNull', 'La date de fin doit être différente de null.'],
                    ['DailyValueShouldBeGreaterThanZero', 'Les frais journaliers doivent être supérieure à zéro.'],
                    ['OverheadExpenseWithDateDoesMatchAnExistingOverheadExpense', 'Les frais de structure se chevauchent avec des frais existants.']
                ])],
                ['NegotiatedSellPriceError', new Map<string, string>([
                    ['LogisticsUnitCannotBeNull', 'La zone logistique ne peut pas être nulle.'],
                    ['LogisticsUnitNotFound', 'La zone logistique n\'existe pas.'],
                    ['SenderSiteWorkAgencyNotFound', 'L\'agence travaux du site de départ n\'existe pas.'],
                    ['SenderSiteAgencyNotFound', 'L\'agence de site de production du site de départ n\'existe pas.'],
                    ['SenderProductionSiteNotFound', 'L\'unité de production du site de départ n\'existe pas.'],
                    ['SenderSiteCustomerNotFound', 'Le client externe référencé du site de départ n\'existe pas.'],
                    ['SenderSiteLevel2ActivityNotFound', 'L\'activité du site de départ n\'existe pas.'],
                    ['SenderSiteResolutionFailedBecauseOfInvalidOrIncompleteData', 'Les données du site de départ son invalides ou incomplètes.'],
                    ['ReceiverSiteWorkAgencyNotFound', 'L\'agence travaux du site d\'arrivée n\'existe pas.'],
                    ['ReceiverSiteAgencyNotFound', 'L\'agence de site de production du site d\'arrivée n\'existe pas.'],
                    ['ReceiverProductionSiteNotFound', 'L\'unité de production du site d\'arrivée n\'existe pas.'],
                    ['ReceiverSiteCustomerNotFound', 'Le client externe référencé du site d\'arrivée n\'existe pas.'],
                    ['ReceiverSiteLevel2ActivityNotFound', 'L\'activité du site d\'arrivée n\'existe pas.'],
                    ['ReceiverSiteResolutionFailedBecauseOfInvalidOrIncompleteData', 'Les données du site d\'arrivée son invalides ou incomplètes.'],
                    ['InvalidTransportSellPriceKindString', ' Le type de tarification est invalide.'],
                    ['NegotiatedSellPriceDoesNotExist', 'Le tarif de vente négocié n\'existe pas.'],
                    ['NegotiatedSellPricesOverlappingDatesCannotBeAdded', 'La plage de date saisie se chevauche avec un autre tarif négocié pour la même livraison.'],
                    ['NegotiatedSellPriceMustBeGreaterThanZero', 'Le coût du tarif négocié doit être supérieure à zéro.']
                ])],
                ['NegotiatedPurchasePriceError', new Map<string, string>([
                    ['LogisticsUnitCannotBeNull', 'La zone logistique ne peut pas être nulle.'],
                    ['LogisticsUnitNotFound', 'La zone logistique n\'existe pas.'],
                    ['SenderSiteWorkAgencyNotFound', 'L\'agence travaux du site de départ n\'existe pas.'],
                    ['SenderSiteAgencyNotFound', 'L\'agence de site de production du site de départ n\'existe pas.'],
                    ['SenderProductionSiteNotFound', 'L\'unité de production du site de départ n\'existe pas.'],
                    ['SenderSiteCustomerNotFound', 'Le client externe référencé du site de départ n\'existe pas.'],
                    ['SenderSiteLevel2ActivityNotFound', 'L\'activité du site de départ n\'existe pas.'],
                    ['SenderSiteResolutionFailedBecauseOfInvalidOrIncompleteData', 'Les données du site de départ son invalides ou incomplètes.'],
                    ['ReceiverSiteWorkAgencyNotFound', 'L\'agence travaux du site d\'arrivée n\'existe pas.'],
                    ['ReceiverSiteAgencyNotFound', 'L\'agence de site de production du site d\'arrivée n\'existe pas.'],
                    ['ReceiverProductionSiteNotFound', 'L\'unité de production du site d\'arrivée n\'existe pas.'],
                    ['ReceiverSiteCustomerNotFound', 'Le client externe référencé du site d\'arrivée n\'existe pas.'],
                    ['ReceiverSiteLevel2ActivityNotFound', 'L\'activité du site d\'arrivée n\'existe pas.'],
                    ['ReceiverSiteResolutionFailedBecauseOfInvalidOrIncompleteData', 'Les données du site d\'arrivée son invalides ou incomplètes.'],
                    ['InvalidTransportSellPriceKindString', ' Le type de tarification est invalide.'],
                    ['NegotiatedPurchasePriceDoesNotExist', 'Le tarif d\'achat négocié n\'existe pas.'],
                    ['NegotiatedPurchasePriceMustBeGreaterThanZero', 'Le coût du tarif négocié doit être supérieure à zéro.']
                ])]
            ])
    };

    private static getMessages = <T>(commandResult: CommandResultLight, commandResultIndex?: number, extraResult?: T, applyDelegate?: (extraResult: T, index: number) => string) => {

        if (commandResult && commandResult.businessErrors && commandResult.businessErrors.length > 0) {
            const errorMessages: string[] = [];
            commandResult.businessErrors.forEach(error => {
                const errorType = error.type.substring(error.type.lastIndexOf('.') + 1, error.type.length);
                const perTypeBusinessErrors = BusinessErrors.ErrorsMessages.PerBusinessErrorType.get(errorType) ? BusinessErrors.ErrorsMessages.PerBusinessErrorType.get(errorType) : BusinessErrors.ErrorsMessages.Generic;

                let errorText = perTypeBusinessErrors.get(error.name);
                errorText = errorText ? errorText : error.name;

                if (extraResult && applyDelegate) {
                    const errorPrefix = (extraResult && applyDelegate ? `${applyDelegate(extraResult, commandResultIndex)}: ` : '');
                    errorMessages.push(errorPrefix + errorText);
                }
                else {
                    errorMessages.push(errorText);
                }
            });

            return errorMessages;
        }

        return BusinessErrors._emptyMessages;
    }

    public static GetEx = <T>(data: WebAppActionResultEx<T>, applyDelegate?: (extraResult: T, index: number) => string): string[] => {

        if (data && data.commandResults && data.commandResults.length > 0) {
            let index = 0;
            let businessErrors: string[] = null;
            data.commandResults.forEach(item => {
                if (item.hasBusinessErrors) {
                    businessErrors = (businessErrors ?? []).concat(BusinessErrors.getMessages(item, index, data.extraResult, applyDelegate));
                }
                index++;
            });
            return businessErrors ?? BusinessErrors._emptyErrors;
        }

        return BusinessErrors._emptyErrors;
    }

    public static Get = (data: WebAppActionResult): string[] => {

        if (data && data.commandResults && data.commandResults.length > 0) {
            let businessErrors: string[] = null;
            data.commandResults.forEach(item => {
                if (item.hasBusinessErrors) {
                    businessErrors = (businessErrors ?? []).concat(BusinessErrors.getMessages(item));
                }
            });
            return businessErrors ?? BusinessErrors._emptyErrors;
        }

        return BusinessErrors._emptyErrors;
    }

    private static GetGeneric = (errorCode: string): string => {
        return BusinessErrors.ErrorsMessages.Generic.get(errorCode);
    }

    private static GetTyped = (error: BusinessErrorLight): string => {
        const errorType: string = error.type.substring(error.type.lastIndexOf('.') + 1, error.type.length);
        const dictionary = BusinessErrors.ErrorsMessages.PerBusinessErrorType.get(errorType) ? BusinessErrors.ErrorsMessages.PerBusinessErrorType.get(errorType) : BusinessErrors.ErrorsMessages.Generic;
        let errorText: string = dictionary.get(error.name);
        errorText = errorText ? errorText : error.name;
        return errorText;
    }

    public static GetError = (error: BusinessErrorLight | string): string => {
        if (typeof error === "string")
            return BusinessErrors.GetGeneric(error);

        return BusinessErrors.GetTyped(error)
    }
}