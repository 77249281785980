import axios, { AxiosResponse } from "axios";
import { WebAppActionResult } from "../../../../shared/models/WebAppActionResult";
import { AddNegotiatedPurchasePricesArgs } from "./dataContracts/controller/AddNegotiatedPurchasePricesArgs";
import { UpdateNegotiatedPurchasePriceArgs } from "./dataContracts/controller/UpdateNegotiatedPurchasePriceArgs";
import { AgencyLightModel } from "./dataContracts/queryStack/AgencyLightModel";
import { Level2ActivityLightModel } from "./dataContracts/queryStack/Level2ActivityLightModel";
import { NegotiatedPurchasePriceLightModel } from "./dataContracts/queryStack/NegotiatedPurchasePriceLightModel";
import { ProductionSiteLightModel } from "./dataContracts/queryStack/ProductionSiteLightModel";
import { ReferencedCustomerLightModel } from "./dataContracts/queryStack/ReferencedCustomerLightModel";

const controllerUrl = "api/NegotiatedPurchasePricesReferential/";

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export class NegotiatedPurchasePricesReferentialApiClient {

    public static GetProductionSites = (logisticsUnitId: string)
        : Promise<AxiosResponse<Array<ProductionSiteLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetProductionSites?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: headers
        });
    }

    public static GetWorkAgencies = (logisticsUnitId: string)
        : Promise<AxiosResponse<Array<AgencyLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetWorkAgencies?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: headers
        });
    }

    public static GetLevel2Activities = (productionSiteId: string, logisticsUnitId: string)
        : Promise<AxiosResponse<Array<Level2ActivityLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetLevel2Activities?productionSiteId=${encodeURIComponent(productionSiteId)}&logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: headers
        });
    }

    public static GetReferencedCustomers = (logisticsUnitId: string)
        : Promise<AxiosResponse<Array<ReferencedCustomerLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetReferencedCustomers?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: headers
        });
    }

    public static GetVehicleTypeGroups = ()
        : Promise<AxiosResponse<Array<string>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetVehicleTypeGroups`,
            headers: headers
        });
    }

    public static AddNegotiatedPurchasePrices = (requestArgs: AddNegotiatedPurchasePricesArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AddNegotiatedPurchasePrices`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateNegotiatedPurchasePrice = (requestArgs: UpdateNegotiatedPurchasePriceArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateNegotiatedPurchasePrice`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static SearchNegotiatedPurchasePrices = (logisticsUnitId: string, searchText: string)
        : Promise<AxiosResponse<Array<NegotiatedPurchasePriceLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}SearchNegotiatedPurchasePrices?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}&searchText=${encodeURIComponent(searchText)}`,
            headers: headers
        });
    }

    public static RemoveNegotiatedPurchasePrices = (negotiatedPurchasePricesToRemove: string[])
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}RemoveNegotiatedPurchasePrices`,
            headers: headers,
            data: JSON.stringify(negotiatedPurchasePricesToRemove)
        });
    }
}
