import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import React from 'react';

interface VehicleInternalExternalDetailProperties {
    vehicleTypeLabel: string,
    vehicleTypeIsEnabled: boolean,
    licencePlate: string,
    driverId?: number,
    equipmentId: string,
    driverFullName: string,
    firstBeneficiaryName: string,
    firstDeliveryAddressCity: string,
    receiverSiteLabel: string,
    transporterId: string,
    transporterLabel: string,
    isDailyVehicle: boolean,
    isContractualPurchasePriceVehicle?: boolean,
    contractNumber?: number,
    priceKind?: string,
    remainingNumberOfDays?: number,
    remainingNumberOfWorkDays?: number
}

export const VehicleInternalExternalDetailComponent = (props: VehicleInternalExternalDetailProperties): JSX.Element => {

    const translatePriceKind = (priceKind: string): string => {
        switch (priceKind) {
            case 'Day':
                return 'J';
            case 'HalfDay':
                return '1/2 J';
            case 'Night':
                return 'N';
            case 'PerTon':
                return 'T';
            case 'PerHour':
                return 'H';
            default:
                return priceKind;
        }
    }

    return (
        <>
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row" className="vehicle-title" flexWrap="wrap">
                    <Tooltip classes={{ tooltip: 'vehicle-tooltip' }} title={
                        <React.Fragment>
                            <div>{`${props.equipmentId ? props.equipmentId : ''} ${props.driverFullName ? props.driverFullName : ''}`}</div>
                        </React.Fragment>
                    } placement="top">
                        <Box className={props.isDailyVehicle ? "vehicle-title-row" : ""} display="flex" flexDirection="column">
                            <Box display="flex" flexDirection="row" flexWrap="wrap" className={!props.vehicleTypeIsEnabled ? " vehicle-disabled" : ""}>
                                <Box pr='2px'>
                                    {props.vehicleTypeLabel}
                                    {
                                        (props.driverId) ?
                                            (
                                                <span className="icon-user-internal-external-vehicle">
                                                    <FontAwesomeIcon size="sm" icon={faUser} />
                                                </span>
                                            ) : " - "
                                    }
                                    <span>{props.licencePlate}</span>
                                    {props.isContractualPurchasePriceVehicle &&
                                        <span>{` - ${translatePriceKind(props.priceKind)} (${props.contractNumber})`}</span>
                                    }
                                </Box>
                            </Box>
                            <Box className="vehicle-libelle">
                                {props.transporterLabel}
                            </Box>
                        </Box>
                    </Tooltip>
                    {props.isDailyVehicle ? (
                        <Box width="40%" justifyContent="flex-end" textAlign="end" className="vehicle-driver-destination" display="flex" flexDirection="row" flexWrap="wrap">
                            <Box>
                                {`${props.firstBeneficiaryName ?? ''} / ${props.firstDeliveryAddressCity ?? ''} / ${props.receiverSiteLabel ?? ''}`}
                            </Box>
                            {props.isContractualPurchasePriceVehicle &&
                                <Tooltip placement="top" title={`${props.remainingNumberOfDays} d'utilisation restant(s) sur les ${props.remainingNumberOfWorkDays}j ouvré(s) à venir`}>
                                    <Box width="100%" className={props.remainingNumberOfDays > props.remainingNumberOfWorkDays ? "remaining-numbers red" : "remaining-numbers"}>
                                        {`${props.remainingNumberOfDays}j / ${props.remainingNumberOfWorkDays}j`}
                                    </Box>
                                </Tooltip>
                            }
                        </Box>) : ""
                    }
                </Box>
            </Box>
        </>
    )
}