import { Drawer } from '@mui/material';
import { orderBy } from '@progress/kendo-data-query';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { debounce, groupBy, map } from 'lodash';
import React from 'react';
import { AppModule, LocalStorage } from '../../utils/Storage';
import { VehiclesComponent } from './components/VehiclesComponent';
import { VehicleItemModel } from './models/VehicleItemModel';
import { VehicleSelectorType } from './models/VehicleSelectorType';
import { VehicleTypeModel } from './models/VehicleTypeModel';
import { InternalVehiclesChoiceLightModel } from './services/dataContracts/queryStack/InternalVehiclesChoiceLightModel';
import { ReservedTransporterVehicleTypeLightModel } from './services/dataContracts/queryStack/ReservedTransporterVehicleTypeLightModel';
import { TransportPurchasePriceDatesLightModel } from './services/dataContracts/queryStack/TransportPurchasePriceDatesLightModel';
import { VehiclesSelectorApiClient } from './services/VehiclesSelectorApiClient';
import './VehiclesSelectorDrawerStyles.scss';

interface VehiclesSelectorDrawerProperties {
    handleDrawerLeftClose: () => void,
    handleAssignGenericVehicleType: (vehicleTypeId: string, vehicleTypeGroupId: string) => void,
    handleAssignTransporterVehicleType: (vehicleTypeId: string, transporterId: string, transportPurchasePriceDatesLightModel: TransportPurchasePriceDatesLightModel[], vehicleTypeGroupId: string) => void,
    handleAssignInternalVehicle: (transporterId: string, vehicleId: number, vehicleTypeId: string, equipmentId: string, driverId: number, licencePlate: string, internalVehiclesChoicesLightModel: InternalVehiclesChoiceLightModel[], vehicleTypeGroupId: string) => void,
    handleAssignDailyPlanningVehicle: (dailyPlanningVehicleId: string, vehicleId: number, licencePlate: string, transporterId: string, vehicleTypeId: string, vehicleTypeGroupId: string) => void,
    handleAssignContractedVehicle: (contractualPurchasePriceId: number, transporterId: string, vehicleTypeGroupId: string) => void,
    handleChangeRefreshVehicles: () => void,
    userName: string,
    isVehiclesSelectorOpen: boolean,
    byVehicleTypeGroupIdsFilterValue: string[],
    byVehicleTypeGroupIdsFilterChanged: boolean,
    planningDate: Date,
    logisticsUnitIds: Array<string>,
    getUnusedReservationsChanged: boolean,
    refreshDailyVehicles: boolean,
    refreshContractualVehicles: boolean
}

interface VehiclesState {
    genericVehicles: VehicleTypeModel,
    transporterVehicles: VehicleTypeModel,
    internalVehicles: VehicleTypeModel,
    dailyVehicles: VehicleTypeModel,
    contractedVehicles: VehicleTypeModel,
    genericVehiclesChecked: boolean,
    transporterVehiclesChecked: boolean,
    internalVehiclesChecked: boolean,
    dailyVehiclesChecked: boolean,
    contractedVehiclesChecked: boolean,
    valueInput: string,
    pageLength: number,
    pageNumber: number,
    alreadyLoaded: boolean,
    confirmedReservationsDictionary: Map<string, Map<string, ReservedTransporterVehicleTypeLightModel>>,
    lastTimeStampSearch: number,
    byVehicleTypeGroupIdsFilterEnabled: boolean,
    includingAlreadyUsedFilterEnabled: boolean,
    hiddenVehicles: Set<string>,
    dailyVehicleDate: Date
}

const ModuleKey = AppModule.VehiclesSelector;
const LocalStorage_byVehicleTypeGroupIdsFilterEnabled = 'vehiclesSelector.byVehicleTypeGroupIdsFilterEnabled';
const LocalStorage_includingAlreadyUsedFilterEnabled = 'vehiclesSelector.includingAlreadyUsedFilterEnabled';

export class VehiclesSelectorDrawerView extends React.Component<VehiclesSelectorDrawerProperties, VehiclesState>{
    _isMounted: boolean;
    today = new Date();
    currentNumericDate: number = this.today.stripTime().toNumericDate();
    datePickerRef: React.RefObject<DatePicker>;

    constructor(props: VehiclesSelectorDrawerProperties) {
        super(props);
        //Get All vehicles checked from localstorage

        const genericVehicleSelectorLocalStorage = LocalStorage.GetItem(ModuleKey, "vehiclesSelector.generic");
        const genericChecked = genericVehicleSelectorLocalStorage === null ? true : (genericVehicleSelectorLocalStorage === "false" ? false : true);

        const transporterVehiclesSelectorLocalStorage = LocalStorage.GetItem(ModuleKey, "vehiclesSelector.transporter");
        const transporterChecked = transporterVehiclesSelectorLocalStorage == null ? true : (transporterVehiclesSelectorLocalStorage === "false" ? false : true);

        const internalVehicleSelectorLocalStorage = LocalStorage.GetItem(ModuleKey, "vehiclesSelector.internal");
        const internalChecked = internalVehicleSelectorLocalStorage == null ? true : (internalVehicleSelectorLocalStorage === "false" ? false : true);

        const dailyVehicleSelectorLocalStorage = LocalStorage.GetItem(ModuleKey, "vehiclesSelector.daily");
        const dailyChecked = dailyVehicleSelectorLocalStorage == null ? true : (dailyVehicleSelectorLocalStorage === "false" ? false : true);

        const contractedVehicleSelectorLocalStorage = LocalStorage.GetItem(ModuleKey, "vehiclesSelector.contracted");
        const contractedChecked = contractedVehicleSelectorLocalStorage == null ? true : (contractedVehicleSelectorLocalStorage === "false" ? false : true);

        const byVehicleTypeGroupIdsFilterEnabledVehicleSelectorLocalStorage = LocalStorage.GetItem(ModuleKey, LocalStorage_byVehicleTypeGroupIdsFilterEnabled);
        const byVehicleTypeGroupIdsFilterEnabled = byVehicleTypeGroupIdsFilterEnabledVehicleSelectorLocalStorage == null ? true : (byVehicleTypeGroupIdsFilterEnabledVehicleSelectorLocalStorage === "false" ? false : true);

        const includingAlreadyUsedFilterEnabledVehicleSelectorLocalStorage = LocalStorage.GetItem(ModuleKey, LocalStorage_includingAlreadyUsedFilterEnabled);
        const includingAlreadyUsedFilterEnabled = includingAlreadyUsedFilterEnabledVehicleSelectorLocalStorage == null ? true : (includingAlreadyUsedFilterEnabledVehicleSelectorLocalStorage === "false" ? false : true);

        this.datePickerRef = React.createRef();

        this.state = {
            genericVehicles: {
                checked: genericChecked,
                vehicles: [],
                allVehicles: [],
                countItems: 0,
                sort: [
                    { field: 'vehicleTypeLabel', dir: 'asc' }
                ]
            },
            transporterVehicles: {
                checked: transporterChecked,
                vehicles: [],
                allVehicles: [],
                countItems: 0,
                sort: [
                    { field: 'vehicleTypeLabel', dir: 'asc' }, { field: 'transporterLabel', dir: 'asc' }
                ]
            },
            internalVehicles: {
                checked: internalChecked,
                vehicles: [],
                allVehicles: [],
                countItems: 0,
                sort: [
                    { field: 'vehicleTypeLabel', dir: 'asc' }, { field: 'transporterLabel', dir: 'asc' }, { field: 'licencePlate', dir: 'asc' }
                ]
            },
            dailyVehicles: {
                checked: dailyChecked,
                vehicles: [],
                allVehicles: [],
                countItems: 0,
                sort: [
                    { field: 'vehicleTypeLabel', dir: 'asc' }, { field: 'transporterLabel', dir: 'asc' }, { field: 'licencePlate', dir: 'asc' }
                ]
            },
            contractedVehicles: {
                checked: contractedChecked,
                vehicles: [],
                allVehicles: [],
                countItems: 0,
                sort: [
                    { field: 'vehicleTypeLabel', dir: 'asc' }, { field: 'transporterLabel', dir: 'asc' }
                ]
            },
            genericVehiclesChecked: genericChecked,
            transporterVehiclesChecked: transporterChecked,
            internalVehiclesChecked: internalChecked,
            dailyVehiclesChecked: dailyChecked,
            contractedVehiclesChecked: contractedChecked,
            valueInput: "",
            pageLength: 100000,
            pageNumber: 0,
            alreadyLoaded: false,
            confirmedReservationsDictionary: new Map<string, Map<string, ReservedTransporterVehicleTypeLightModel>>(),
            lastTimeStampSearch: Date.now(),
            byVehicleTypeGroupIdsFilterEnabled: byVehicleTypeGroupIdsFilterEnabled,
            includingAlreadyUsedFilterEnabled: includingAlreadyUsedFilterEnabled,
            hiddenVehicles: new Set([]),
            dailyVehicleDate: new Date().getDayStart()
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.planningDate !== null) {
            VehiclesSelectorApiClient.GetUnusedReservationsWithHiddenVehicles(this.state.dailyVehicleDate, this.props.planningDate)
                .then(res => {
                    if (this._isMounted) {
                        const dataDictionary: Map<string, Map<string, ReservedTransporterVehicleTypeLightModel>> = this.getConfirmedReservationsData(res[0].data);

                        this.setState({
                            confirmedReservationsDictionary: dataDictionary,
                            hiddenVehicles: new Set(res[1].data.map(v => this.generateHiddenVehicleKey(v.planningVehicleId, v.vehicleId)))
                        });

                        this.setFilterToGetVehiclesList(this.state.valueInput, this.state.genericVehiclesChecked, this.state.transporterVehiclesChecked
                            , this.state.internalVehiclesChecked, this.state.dailyVehiclesChecked, this.state.contractedVehiclesChecked);
                    }
                });
        } else {
            this.setFilterToGetVehiclesList(this.state.valueInput, this.state.genericVehiclesChecked, this.state.transporterVehiclesChecked
                , this.state.internalVehiclesChecked, this.state.dailyVehiclesChecked, this.state.contractedVehiclesChecked);
        }
    }

    componentDidUpdate(prevProps: VehiclesSelectorDrawerProperties) {
        if (!Date.equals(prevProps.planningDate, this.props.planningDate) || prevProps.getUnusedReservationsChanged != this.props.getUnusedReservationsChanged) {
            if (this.props.planningDate !== null) {
                VehiclesSelectorApiClient.GetUnusedReservationsWithHiddenVehicles(this.state.dailyVehicleDate, this.props.planningDate)
                    .then(res => {
                        if (this._isMounted) {

                            const dataDictionary: Map<string, Map<string, ReservedTransporterVehicleTypeLightModel>> = this.getConfirmedReservationsData(res[0].data);
                            this.setState({
                                confirmedReservationsDictionary: dataDictionary,
                                hiddenVehicles: new Set(res[1].data.map(v => this.generateHiddenVehicleKey(v.planningVehicleId, v.vehicleId)))
                            });
                        }
                    });
            }
            else {
                this.setState({
                    confirmedReservationsDictionary: new Map<string, Map<string, ReservedTransporterVehicleTypeLightModel>>()
                });
            }
        }

        if (!Date.equals(prevProps.planningDate, this.props.planningDate)) {
            this.handleTransporterChange(this.state.valueInput, this.state.transporterVehiclesChecked);
            this.handleInternalChange(this.state.valueInput, this.state.internalVehiclesChecked);
            this.handleDailyChange(this.state.valueInput, this.state.dailyVehiclesChecked);
            this.handleContractedChange(this.state.valueInput, this.state.contractedVehiclesChecked);
        }
        else if ((prevProps.refreshDailyVehicles != this.props.refreshDailyVehicles && this.props.refreshDailyVehicles) || (prevProps.refreshContractualVehicles != this.props.refreshContractualVehicles && this.props.refreshContractualVehicles)) {
            this.refreshAssignedDailyVehiclesWithInternalVehiclesAndContractedVehicles();
        }

        if (prevProps.byVehicleTypeGroupIdsFilterChanged != this.props.byVehicleTypeGroupIdsFilterChanged && this.state.byVehicleTypeGroupIdsFilterEnabled == true) {
            this.setFiltredVehicleGroupActivatedTrue(this.props.byVehicleTypeGroupIdsFilterValue, this.state.byVehicleTypeGroupIdsFilterEnabled);
        }
    }

    generateHiddenVehicleKey = (planningVehicleId: string, vehicleId: number): string => {
        return `${planningVehicleId}#${vehicleId}`;
    }

    shouldComponentUpdate(nextProps: VehiclesSelectorDrawerProperties, nextState: VehiclesState) {
        if (this.state.lastTimeStampSearch > nextState.lastTimeStampSearch)
            return false;

        return true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getConfirmedReservationsData = (data: ReservedTransporterVehicleTypeLightModel[]): Map<string, Map<string, ReservedTransporterVehicleTypeLightModel>> => {
        const dataDictionary: Map<string, Map<string, ReservedTransporterVehicleTypeLightModel>> = new Map<string, Map<string, ReservedTransporterVehicleTypeLightModel>>();
        const group: _.Dictionary<ReservedTransporterVehicleTypeLightModel[]> = groupBy(data, x => x.transporterId)
        const groupedReservedTransporterVehicleType: ReservedTransporterVehicleTypeLightModel[][] = map(group, (value: ReservedTransporterVehicleTypeLightModel[]) => (value));

        groupedReservedTransporterVehicleType.forEach((element: ReservedTransporterVehicleTypeLightModel[]) => {
            const byVehicleTypeDataDictionary: Map<string, ReservedTransporterVehicleTypeLightModel> = new Map<string, ReservedTransporterVehicleTypeLightModel>();
            element.forEach((item: ReservedTransporterVehicleTypeLightModel) => {
                byVehicleTypeDataDictionary.set(item.vehicleTypeId, item);
            });

            dataDictionary.set(element[0].transporterId, byVehicleTypeDataDictionary);
        });

        return dataDictionary;
    }

    clearFilter = (): void => {
        const tds = document.getElementsByClassName("filter-h2");
        this.setState({ valueInput: "" });
        for (let i = 0; i < tds.length; i++) {
            const divTd: Element = tds[i];
            divTd.classList.remove("filtered");
        }
        LocalStorage.SetItem(ModuleKey, 'vehiclesSelector.generic', '' + true);
        LocalStorage.SetItem(ModuleKey, 'vehiclesSelector.transporter', '' + true);
        LocalStorage.SetItem(ModuleKey, 'vehiclesSelector.internal', '' + true);
        LocalStorage.SetItem(ModuleKey, 'vehiclesSelector.daily', '' + true);
        LocalStorage.SetItem(ModuleKey, 'vehiclesSelector.contracted', '' + true);
        this.setFilterToGetVehiclesList("", true, true, true, true, true);
    }

    handleChangeVehicleInput = debounce((searchText): void => {
        this.setState({ valueInput: searchText });
        const tds = document.getElementsByClassName("filter-h2");
        if (searchText.length >= 3) {
            for (let i = 0; i < tds.length; i++) {
                const divTd: Element = tds[i];
                divTd.classList.add("filtered");
            }
            this.setFilterToGetVehiclesList(searchText, this.state.genericVehiclesChecked, this.state.transporterVehiclesChecked
                , this.state.internalVehiclesChecked, this.state.dailyVehiclesChecked, this.state.contractedVehiclesChecked);
        } else if (searchText.length === 0) {
            for (let i = 0; i < tds.length; i++) {
                const divTd: Element = tds[i];
                divTd.classList.remove("filtered");
            }
            this.setFilterToGetVehiclesList("", this.state.genericVehiclesChecked, this.state.transporterVehiclesChecked
                , this.state.internalVehiclesChecked, this.state.dailyVehiclesChecked, this.state.contractedVehiclesChecked);
        }
    }, 500);

    clearSearchText = (): void => {
        const divSearch = document.getElementById('search-text-drawer') as HTMLInputElement;
        divSearch.value = '';
        this.setState({ valueInput: '' });
        const filtredDvi = document.getElementsByClassName("filter-h2");
        for (let i = 0; i < filtredDvi.length; i++) {
            const divTd: Element = filtredDvi[i];
            divTd.classList.remove("filtered");
        }
        this.setFilterToGetVehiclesList("", this.state.genericVehiclesChecked, this.state.transporterVehiclesChecked
            , this.state.internalVehiclesChecked, this.state.dailyVehiclesChecked, this.state.contractedVehiclesChecked);
    }

    translateAllInternalDailyVehicles = (dailyVehicles: VehicleItemModel[], internalVehicles: VehicleItemModel[], targetInternalChecked: boolean, targetDailyChecked: boolean): void => {
        const { hiddenVehicles } = this.state;

        if (!targetDailyChecked && targetInternalChecked || targetDailyChecked && !targetInternalChecked) {

            if (targetInternalChecked) {
                //réafficher les camion internes indépendamment des camions du jour
                internalVehicles.filter(v => {
                    return v.isDailyVehicle
                }).forEach(d => {
                    d.isDailyVehicle = false;
                    d.isHiddenDaily = false;
                });
            }

            //reset les camion du jour
            dailyVehicles?.forEach(v => {
                const isHidden = hiddenVehicles?.has(this.generateHiddenVehicleKey(v.planningVehicleId, v.vehicleId)) ?? false;
                v.isHidden = isHidden;
            });
        }
        else if (dailyVehicles?.length > 0) {
            if (internalVehicles?.length > 0) {
                internalVehicles.filter(v => {
                    return v.isDailyVehicle
                }).forEach(d => {
                    d.isDailyVehicle = false;
                    d.isHiddenDaily = false;
                    d.firstBeneficiaryName = null;
                    d.firstDeliveryAddressCity = null;
                    d.receiverSiteLabel = null;
                });

                //mettre à jour les camions du jour
                dailyVehicles.forEach(v => {
                    const indexInternalVehicle = internalVehicles.findIndex(d => {
                        return d.vehicleId == v.vehicleId;
                    });

                    const isHidden = hiddenVehicles?.has(this.generateHiddenVehicleKey(v.planningVehicleId, v.vehicleId)) ?? false;

                    v.isHidden = (indexInternalVehicle != -1 || isHidden);
                });

                //mettre à jour les camions internes
                internalVehicles.forEach((v, indexInternalVehicle) => {
                    const indexDailyVehicle = dailyVehicles.findIndex(d => {
                        return d.vehicleId == v.vehicleId;
                    });

                    if (indexDailyVehicle != -1) {
                        internalVehicles[indexInternalVehicle].isDailyVehicle = true;
                        internalVehicles[indexInternalVehicle].firstBeneficiaryName = dailyVehicles[indexDailyVehicle].firstBeneficiaryName;
                        internalVehicles[indexInternalVehicle].firstDeliveryAddressCity = dailyVehicles[indexDailyVehicle].firstDeliveryAddressCity;
                        internalVehicles[indexInternalVehicle].receiverSiteLabel = dailyVehicles[indexDailyVehicle].receiverSiteLabel;
                        internalVehicles[indexInternalVehicle].planningVehicleId = dailyVehicles[indexDailyVehicle].planningVehicleId;

                        const isHidden = hiddenVehicles?.has(this.generateHiddenVehicleKey(v.planningVehicleId, v.vehicleId)) ?? false;
                        internalVehicles[indexInternalVehicle].isHiddenDaily = isHidden;
                    }
                    else {
                        internalVehicles[indexInternalVehicle].isDailyVehicle = false;
                        internalVehicles[indexInternalVehicle].isHiddenDaily = false;
                        internalVehicles[indexInternalVehicle].firstBeneficiaryName = null;
                        internalVehicles[indexInternalVehicle].firstDeliveryAddressCity = null;
                        internalVehicles[indexInternalVehicle].receiverSiteLabel = null;
                        internalVehicles[indexInternalVehicle].planningVehicleId = null;
                    }
                });
            }
            else {
                dailyVehicles.forEach(v => {
                    const isHidden = hiddenVehicles?.has(this.generateHiddenVehicleKey(v.planningVehicleId, v.vehicleId)) ?? false;
                    v.isHidden = isHidden;
                });
            }
        }
        else if (internalVehicles?.length > 0) {
            const internalDailyVehicle = internalVehicles.filter(v => {
                return v.isDailyVehicle == true;
            });

            internalDailyVehicle.forEach(v => {
                v.isDailyVehicle = false;
                v.isHiddenDaily = false;
                v.firstBeneficiaryName = null;
                v.firstDeliveryAddressCity = null;
                v.receiverSiteLabel = null;
            });
        }
    }

    setFilterToGetVehiclesList = (searchText: string, targetGenericChecked: boolean, targetTransporterChecked: boolean
        , targetInternalChecked: boolean, targetDailyChecked: boolean, targetContractedChecked: boolean): void => {

        const lastTimeStamp = this.state.lastTimeStampSearch;
        const currentTimeStamp = Date.now();
        const vehicleTypeGroupId = this.props.byVehicleTypeGroupIdsFilterValue;
        const newTargetDailyChecked = targetDailyChecked;
        const targetDailyCheckedToSearch = true;

        VehiclesSelectorApiClient.FindTransportersVehiclesAll(this.state.pageLength, this.state.pageNumber, searchText,
            targetGenericChecked, targetTransporterChecked, targetInternalChecked, targetDailyCheckedToSearch, targetContractedChecked, this.state.includingAlreadyUsedFilterEnabled, this.props.planningDate, null, this.state.dailyVehicleDate, this.props.logisticsUnitIds)
            .then(res => {
                if (lastTimeStamp !== this.state.lastTimeStampSearch)
                    return;

                if (this._isMounted && res.length > 0) {
                    const genericVehiclesResult = res[0];
                    const transporterVehiclesResult = res[1];
                    const internalVehiclesResult = res[2];
                    const dailyVehiclesResult = res[3];
                    const contractedVehiclesResult = res[4];

                    const genericVehicles = genericVehiclesResult.data.items as VehicleItemModel[];
                    const transporterVehicles = transporterVehiclesResult.data.items as VehicleItemModel[];
                    const internalVehicles = internalVehiclesResult.data.items as VehicleItemModel[];
                    const dailyVehicles = dailyVehiclesResult.data.items as VehicleItemModel[];
                    const contractedVehicles = contractedVehiclesResult.data.items as VehicleItemModel[];

                    const newStateObj = {} as VehiclesState;

                    if (genericVehiclesResult != null) {
                        let genVehicles: VehicleItemModel[] = [];
                        if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true)
                            vehicleTypeGroupId.forEach(function (element) {
                                genVehicles.push.apply(genVehicles, genericVehicles.filter(d => d.vehicleTypeGroupId === element).map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.generic })))
                            });
                        else
                            genVehicles = null;

                        const genCount = genVehicles?.length > 0 ? (genVehicles).length : genericVehiclesResult.data.count;
                        newStateObj.genericVehicles = {
                            checked: targetGenericChecked,
                            vehicles: orderBy(genVehicles ? genVehicles : genericVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.generic })), this.state.genericVehicles.sort),
                            allVehicles: orderBy(genericVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.generic })), this.state.genericVehicles.sort),
                            countItems: genCount,
                            sort: this.state.genericVehicles.sort
                        };
                    }

                    if (transporterVehiclesResult != null) {
                        let trnrVehicles: VehicleItemModel[] = [];
                        if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true)
                            vehicleTypeGroupId.forEach(function (element) {
                                trnrVehicles.push.apply(trnrVehicles, transporterVehicles.filter(d => d.vehicleTypeGroupId === element).map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.transporter })))
                            });
                        else
                            trnrVehicles = null;

                        const trnCount = trnrVehicles?.length > 0 ? (trnrVehicles).length : transporterVehiclesResult.data.count;
                        newStateObj.transporterVehicles = {
                            checked: targetTransporterChecked,
                            vehicles: orderBy(trnrVehicles ? trnrVehicles : transporterVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.transporter })), this.state.transporterVehicles.sort),
                            allVehicles: orderBy(transporterVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.transporter })), this.state.transporterVehicles.sort),
                            countItems: trnCount,
                            sort: this.state.transporterVehicles.sort
                        };
                    }

                    if (internalVehiclesResult != null) {
                        let intVehicles: VehicleItemModel[] = [];
                        if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true)
                            vehicleTypeGroupId.forEach(function (element) {
                                intVehicles.push.apply(intVehicles, internalVehicles.filter(d => d.vehicleTypeGroupId === element).map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.internal })))
                            });
                        else
                            intVehicles = null;

                        var intCount = intVehicles?.length > 0 ? (intVehicles).length : internalVehiclesResult.data.count;
                        newStateObj.internalVehicles = {
                            checked: targetInternalChecked,
                            vehicles: orderBy(intVehicles ? intVehicles : internalVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.internal })), this.state.internalVehicles.sort),
                            allVehicles: orderBy(internalVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.internal })), this.state.internalVehicles.sort),
                            countItems: intCount,
                            sort: this.state.internalVehicles.sort
                        };
                    }

                    if (dailyVehiclesResult != null) {
                        let intDailyVehicles: VehicleItemModel[] = [];
                        if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true)
                            vehicleTypeGroupId.forEach(function (element) {
                                intDailyVehicles.push.apply(intDailyVehicles, (dailyVehicles as VehicleItemModel[])
                                    .filter(d => d.vehicleTypeGroupId === element)
                                    .map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.daily })))
                            });
                        else
                            intDailyVehicles = null;

                        var intCount = intDailyVehicles?.length > 0 ? (intDailyVehicles).length : dailyVehiclesResult.data.count;
                        newStateObj.dailyVehicles = {
                            checked: newTargetDailyChecked,
                            vehicles: orderBy(intDailyVehicles ? intDailyVehicles : dailyVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.daily })), this.state.dailyVehicles.sort),
                            allVehicles: orderBy(dailyVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.daily })), this.state.dailyVehicles.sort),
                            countItems: intCount,
                            sort: this.state.dailyVehicles.sort
                        };
                    }

                    if (contractedVehiclesResult != null) {
                        let cntrdVehicles: VehicleItemModel[] = [];
                        if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true)
                            vehicleTypeGroupId.forEach((element) => {
                                cntrdVehicles.push.apply(cntrdVehicles, contractedVehicles.filter(d => d.vehicleTypeGroupId === element).map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.contracted })))
                            });
                        else
                            cntrdVehicles = null;

                        const cntrdCount = cntrdVehicles?.length > 0 ? (cntrdVehicles).length : contractedVehiclesResult.data.count;
                        newStateObj.contractedVehicles = {
                            checked: targetContractedChecked,
                            vehicles: orderBy(cntrdVehicles ? cntrdVehicles : contractedVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.contracted })), this.state.contractedVehicles.sort),
                            allVehicles: orderBy(contractedVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.contracted })), this.state.contractedVehicles.sort),
                            countItems: cntrdCount,
                            sort: this.state.contractedVehicles.sort
                        };
                    }

                    if (lastTimeStamp !== this.state.lastTimeStampSearch)
                        return;

                    newStateObj.lastTimeStampSearch = currentTimeStamp;

                    this.translateAllInternalDailyVehicles(newStateObj.dailyVehicles?.vehicles as VehicleItemModel[], newStateObj.internalVehicles?.vehicles as VehicleItemModel[], targetInternalChecked, targetDailyChecked);
                    if (newStateObj.dailyVehicles) {
                        newStateObj.dailyVehicles.countItems = (newStateObj.dailyVehicles
                            ? (newStateObj.dailyVehicles.vehicles as VehicleItemModel[]).filter(v => !v.isHidden).length : 0)
                            + (newStateObj.internalVehicles ? (newStateObj.internalVehicles.vehicles as VehicleItemModel[]).filter(v => !v.isHiddenDaily && v.isDailyVehicle).length : 0);
                    }

                    if (this._isMounted) {
                        this.setState(newStateObj);
                    }

                }

            });
    }

    handleGenericChange = (searchText: string, targetChecked: boolean): void => {
        VehiclesSelectorApiClient.FindVehicleTypeTrip(this.state.pageLength, this.state.pageNumber, searchText, targetChecked).then(res => {
            if (this._isMounted) {
                const vehicleTypeGroupId = this.props.byVehicleTypeGroupIdsFilterValue;
                const genericVehiclesItems = res.data.items as VehicleItemModel[];

                let genVehicles: VehicleItemModel[] = [];
                if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true) {
                    vehicleTypeGroupId.forEach(function (element) {
                        genVehicles.push.apply(genVehicles, genericVehiclesItems.filter(d => d.vehicleTypeGroupId === element)
                            .map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.generic })))
                    });
                }
                else
                    genVehicles = null;

                const genCount = genVehicles?.length > 0 ? (genVehicles).length : res.data.count;
                this.setState({
                    genericVehiclesChecked: targetChecked,
                    genericVehicles: {
                        checked: targetChecked,
                        vehicles: orderBy(genVehicles ? genVehicles : genericVehiclesItems.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.generic })), this.state.genericVehicles.sort),
                        allVehicles: orderBy(genericVehiclesItems.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.generic })), this.state.genericVehicles.sort),
                        countItems: genCount,
                        sort: this.state.genericVehicles.sort
                    }
                });
            }
        });
        LocalStorage.SetItem(ModuleKey, 'vehiclesSelector.generic', '' + targetChecked);
    }

    handleTransporterChange = (searchText: string, targetChecked: boolean): void => {
        VehiclesSelectorApiClient.FindTransportersVehicleTypeTrip(this.state.pageLength, this.state.pageNumber, searchText, this.props.planningDate, null, this.props.logisticsUnitIds, targetChecked).then(res => {
            if (this._isMounted) {
                const vehicleTypeGroupId = this.props.byVehicleTypeGroupIdsFilterValue;
                const transporterVehiclesItems = res.data.items as VehicleItemModel[];

                let transporterVehicles: VehicleItemModel[] = [];
                if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true) {
                    vehicleTypeGroupId.forEach(function (element: string) {
                        transporterVehicles.push.apply(transporterVehicles, transporterVehiclesItems.filter(d => d.vehicleTypeGroupId === element)
                            .map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.transporter })))
                    });
                }
                else
                    transporterVehicles = null;

                const transporterCount = transporterVehicles?.length > 0 ? (transporterVehicles).length : res.data.count;
                this.setState({
                    transporterVehiclesChecked: targetChecked,
                    transporterVehicles: {
                        checked: targetChecked,
                        vehicles: orderBy(transporterVehicles ? transporterVehicles : transporterVehiclesItems.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.transporter })), this.state.transporterVehicles.sort),
                        allVehicles: orderBy(transporterVehiclesItems.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.transporter })), this.state.transporterVehicles.sort),
                        countItems: transporterCount,
                        sort: this.state.transporterVehicles.sort
                    }
                });
            }
        });
        LocalStorage.SetItem(ModuleKey, 'vehiclesSelector.transporter', '' + targetChecked);
    }

    handleInternalChange = (searchText: string, targetChecked: boolean): void => {
        VehiclesSelectorApiClient.FindInternalVehicles(this.state.pageLength, this.state.pageNumber, this.props.planningDate, searchText, this.props.logisticsUnitIds, targetChecked, this.state.includingAlreadyUsedFilterEnabled).then(res => {
            if (this._isMounted) {
                const vehicleTypeGroupId = this.props.byVehicleTypeGroupIdsFilterValue;
                const internalVehiclesItems = res.data.items as VehicleItemModel[];

                let internalVehicles: VehicleItemModel[] = [];
                if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true) {
                    vehicleTypeGroupId.forEach(function (element) {
                        internalVehicles.push.apply(internalVehicles, internalVehiclesItems.filter(d => d.vehicleTypeGroupId === element)
                            .map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.internal })))
                    });
                }
                else
                    internalVehicles = internalVehiclesItems.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.internal }));

                this.translateAllInternalDailyVehicles(this.state.dailyVehicles.vehicles as VehicleItemModel[], internalVehicles, targetChecked, this.state.dailyVehiclesChecked);

                const intCount = internalVehicles?.length > 0 ? (internalVehicles).length : res.data.count;
                this.setState({
                    internalVehiclesChecked: targetChecked,
                    internalVehicles: {
                        checked: targetChecked,
                        vehicles: orderBy(internalVehicles, this.state.internalVehicles.sort),
                        allVehicles: orderBy(internalVehiclesItems.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.internal })), this.state.internalVehicles.sort),
                        countItems: intCount,
                        sort: this.state.internalVehicles.sort
                    },
                    dailyVehicles: {
                        checked: this.state.dailyVehiclesChecked,
                        vehicles: orderBy(this.state.dailyVehicles.vehicles, this.state.dailyVehicles.sort),
                        allVehicles: orderBy(this.state.dailyVehicles.allVehicles, this.state.dailyVehicles.sort),
                        countItems: !targetChecked ? (this.state.dailyVehicles.vehicles as VehicleItemModel[]).filter(v => !v.isHidden).length : (this.state.dailyVehicles.vehicles as VehicleItemModel[]).filter(v => !v.isHidden).length + ((internalVehicles ? internalVehicles : res.data.items) as VehicleItemModel[]).filter(v => !v.isHiddenDaily && v.isDailyVehicle).length,
                        sort: this.state.dailyVehicles.sort
                    }
                });
            }
        });
        LocalStorage.SetItem(ModuleKey, 'vehiclesSelector.internal', '' + targetChecked);
    }

    handleDailyChange = (searchText: string, targetChecked: boolean, dailyVehicleDate?: Date): void => {
        //Ramener toujours le détail des camions avec le nombre sur les camions des jours
        var dailyVehicleDate = dailyVehicleDate ? dailyVehicleDate : this.state.dailyVehicleDate;
        VehiclesSelectorApiClient.FindAssignedDailyVehicles(this.state.pageLength, this.state.pageNumber, this.props.planningDate, dailyVehicleDate, searchText, this.props.logisticsUnitIds, true, this.state.includingAlreadyUsedFilterEnabled).then(res => {
            if (this._isMounted) {
                const vehicleTypeGroupId = this.props.byVehicleTypeGroupIdsFilterValue;
                const dailyVehiclesItems = res.data.items as VehicleItemModel[];

                let dailyVehicles: VehicleItemModel[] = [];
                if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true) {
                    vehicleTypeGroupId.forEach(function (element) {
                        dailyVehicles.push.apply(dailyVehicles, (dailyVehiclesItems as VehicleItemModel[]).filter(d => d.vehicleTypeGroupId === element)
                            .map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.daily })))
                    });
                }
                else
                    dailyVehicles = dailyVehiclesItems.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.daily })) as VehicleItemModel[];

                const { internalVehicles, internalVehiclesChecked } = this.state;

                this.translateAllInternalDailyVehicles(dailyVehicles, internalVehicles.vehicles as VehicleItemModel[], this.state.internalVehiclesChecked, targetChecked);

                this.setState({
                    dailyVehiclesChecked: targetChecked,
                    dailyVehicles: {
                        checked: targetChecked,
                        vehicles: orderBy(dailyVehicles, this.state.dailyVehicles.sort),
                        allVehicles: orderBy(dailyVehiclesItems.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.daily })), this.state.dailyVehicles.sort),
                        countItems: dailyVehicles.filter(v => !v.isHidden).length + (internalVehicles.vehicles as VehicleItemModel[]).filter(v => !v.isHiddenDaily && v.isDailyVehicle).length,
                        sort: this.state.dailyVehicles.sort
                    },
                    internalVehicles: {
                        checked: internalVehiclesChecked,
                        vehicles: orderBy(internalVehicles.vehicles, internalVehicles.sort),
                        allVehicles: orderBy(internalVehicles.allVehicles, internalVehicles.sort),
                        countItems: internalVehicles.countItems,
                        sort: internalVehicles.sort
                    }
                });
            }
        });
        LocalStorage.SetItem(ModuleKey, 'vehiclesSelector.daily', '' + targetChecked);
    }

    handleContractedChange = (searchText: string, targetChecked: boolean): void => {
        VehiclesSelectorApiClient.FindContractedVehicles(this.state.pageLength, this.state.pageNumber, this.props.planningDate, searchText, this.props.logisticsUnitIds, targetChecked)
            .then((res) => {
                if (this._isMounted) {
                    const vehicleTypeGroupId = this.props.byVehicleTypeGroupIdsFilterValue;
                    const contractedVehiclesItems = res.data.items as VehicleItemModel[];

                    let contractedVehicles: VehicleItemModel[] = [];
                    if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true) {
                        vehicleTypeGroupId.forEach(function (element: string) {
                            contractedVehicles.push.apply(contractedVehicles, contractedVehiclesItems.filter(d => d.vehicleTypeGroupId === element)
                                .map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.contracted })))
                        });
                    }
                    else {
                        contractedVehicles = null;
                    }

                    const contractedCount = contractedVehicles?.length > 0 ? (contractedVehicles).length : res.data.count;
                    this.setState({
                        contractedVehiclesChecked: targetChecked,
                        contractedVehicles: {
                            checked: targetChecked,
                            vehicles: orderBy(contractedVehicles ? contractedVehicles : contractedVehiclesItems.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.contracted })), this.state.contractedVehicles.sort),
                            allVehicles: orderBy(contractedVehiclesItems.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.contracted })), this.state.contractedVehicles.sort),
                            countItems: contractedCount,
                            sort: this.state.contractedVehicles.sort
                        }
                    });
                    LocalStorage.SetItem(ModuleKey, 'vehiclesSelector.contracted', '' + targetChecked);
                }
            });
    }

    handleIncludingAlreadyUsedFilterChange = (): void => {
        const includingAlreadyUsed = !this.state.includingAlreadyUsedFilterEnabled;

        LocalStorage.SetItem(ModuleKey, LocalStorage_includingAlreadyUsedFilterEnabled, includingAlreadyUsed.toString());

        //Ramener toujours le détail des camions avec le nombre sur les camions des jours
        var dailyVehicleDate = dailyVehicleDate ? dailyVehicleDate : this.state.dailyVehicleDate;
        const vehicleTypeGroupId = this.props.byVehicleTypeGroupIdsFilterValue;
        const lastTimeStamp = this.state.lastTimeStampSearch;
        const currentTimeStamp = Date.now();

        VehiclesSelectorApiClient.FindAssignedDailyVehiclesWithInternalVehicles(this.state.pageLength, this.state.pageNumber, this.state.valueInput, this.state.internalVehiclesChecked, this.state.dailyVehiclesChecked, includingAlreadyUsed
            , this.props.planningDate, this.state.dailyVehicleDate, this.props.logisticsUnitIds).then(res => {
                if (this._isMounted) {
                    const dailyVehiclesResult = res[0];
                    const internalVehiclesResult = res[1];

                    const internalVehicles = internalVehiclesResult.data.items as VehicleItemModel[];
                    const dailyVehicles = dailyVehiclesResult.data.items as VehicleItemModel[];

                    const newStateObj = { includingAlreadyUsedFilterEnabled: includingAlreadyUsed } as VehiclesState;

                    if (internalVehiclesResult != null) {
                        let intVehicles: VehicleItemModel[] = [];
                        if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true)
                            vehicleTypeGroupId.forEach(function (element) {
                                intVehicles.push.apply(intVehicles, internalVehicles.filter(d => d.vehicleTypeGroupId === element).map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.internal })))
                            });
                        else
                            intVehicles = null;

                        var intCount = intVehicles?.length > 0 ? (intVehicles).length : internalVehiclesResult.data.count;
                        newStateObj.internalVehicles = {
                            checked: this.state.internalVehiclesChecked,
                            vehicles: orderBy(intVehicles ? intVehicles : internalVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.internal })), this.state.internalVehicles.sort),
                            allVehicles: orderBy(internalVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.internal })), this.state.internalVehicles.sort),
                            countItems: intCount,
                            sort: this.state.internalVehicles.sort
                        };
                    }

                    if (dailyVehiclesResult != null) {
                        let intDailyVehicles: VehicleItemModel[] = [];
                        if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true)
                            vehicleTypeGroupId.forEach(function (element) {
                                intDailyVehicles.push.apply(intDailyVehicles, (dailyVehicles as VehicleItemModel[])
                                    .filter(d => d.vehicleTypeGroupId === element)
                                    .map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.daily })))
                            });
                        else
                            intDailyVehicles = null;

                        var intCount = intDailyVehicles?.length > 0 ? (intDailyVehicles).length : dailyVehiclesResult.data.count;
                        newStateObj.dailyVehicles = {
                            checked: this.state.dailyVehiclesChecked,
                            vehicles: orderBy(intDailyVehicles ? intDailyVehicles : dailyVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.daily })), this.state.dailyVehicles.sort),
                            allVehicles: orderBy(dailyVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.daily })), this.state.dailyVehicles.sort),
                            countItems: intCount,
                            sort: this.state.dailyVehicles.sort
                        };
                    }

                    if (lastTimeStamp !== this.state.lastTimeStampSearch)
                        return;

                    newStateObj.lastTimeStampSearch = currentTimeStamp;

                    this.translateAllInternalDailyVehicles(newStateObj.dailyVehicles?.vehicles as VehicleItemModel[], newStateObj.internalVehicles?.vehicles as VehicleItemModel[], this.state.internalVehiclesChecked, this.state.dailyVehiclesChecked);
                    if (newStateObj.dailyVehicles) {
                        newStateObj.dailyVehicles.countItems = (newStateObj.dailyVehicles
                            ? (newStateObj.dailyVehicles.vehicles as VehicleItemModel[]).filter(v => !v.isHidden).length : 0)
                            + (newStateObj.internalVehicles ? (newStateObj.internalVehicles.vehicles as VehicleItemModel[]).filter(v => !v.isHiddenDaily && v.isDailyVehicle).length : 0);
                    }

                    if (this._isMounted) {
                        this.setState(newStateObj);
                    }
                }
            });
    }

    refreshAssignedDailyVehiclesWithInternalVehiclesAndContractedVehicles = (): void => {
        const lastTimeStamp = this.state.lastTimeStampSearch;
        const currentTimeStamp = Date.now();
        const vehicleTypeGroupId = this.props.byVehicleTypeGroupIdsFilterValue;

        VehiclesSelectorApiClient.FindAssignedDailyVehiclesWithInternalVehiclesAndContractedVehicles(this.state.pageLength, this.state.pageNumber, this.state.valueInput,
            this.state.internalVehiclesChecked, this.state.dailyVehiclesChecked, this.state.contractedVehiclesChecked, this.state.includingAlreadyUsedFilterEnabled, this.props.planningDate, null, this.state.dailyVehicleDate, this.props.logisticsUnitIds)
            .then(res => {
                if (lastTimeStamp !== this.state.lastTimeStampSearch)
                    return;

                if (this._isMounted && res.length > 0) {
                    const dailyVehiclesResult = res[0];
                    const internalVehiclesResult = res[1];
                    const contractedVehiclesResult = res[2];

                    const internalVehicles = internalVehiclesResult.data.items as VehicleItemModel[];
                    const dailyVehicles = dailyVehiclesResult.data.items as VehicleItemModel[];
                    const contractedVehicles = contractedVehiclesResult.data.items as VehicleItemModel[];

                    const newStateObj = {} as VehiclesState;

                    if (internalVehiclesResult != null) {
                        let intVehicles: VehicleItemModel[] = [];
                        if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true)
                            vehicleTypeGroupId.forEach(function (element) {
                                intVehicles.push.apply(intVehicles, internalVehicles.filter(d => d.vehicleTypeGroupId === element).map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.internal })))
                            });
                        else
                            intVehicles = null;

                        var intCount = intVehicles?.length > 0 ? (intVehicles).length : internalVehiclesResult.data.count;
                        newStateObj.internalVehicles = {
                            checked: this.state.internalVehiclesChecked,
                            vehicles: orderBy(intVehicles ? intVehicles : internalVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.internal })), this.state.internalVehicles.sort),
                            allVehicles: orderBy(internalVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.internal })), this.state.internalVehicles.sort),
                            countItems: intCount,
                            sort: this.state.internalVehicles.sort
                        };
                    }

                    if (dailyVehiclesResult != null) {
                        let intDailyVehicles: VehicleItemModel[] = [];
                        if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true)
                            vehicleTypeGroupId.forEach(function (element) {
                                intDailyVehicles.push.apply(intDailyVehicles, (dailyVehicles as VehicleItemModel[])
                                    .filter(d => d.vehicleTypeGroupId === element)
                                    .map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.daily })))
                            });
                        else
                            intDailyVehicles = null;

                        var intCount = intDailyVehicles?.length > 0 ? (intDailyVehicles).length : dailyVehiclesResult.data.count;
                        newStateObj.dailyVehicles = {
                            checked: this.state.dailyVehiclesChecked,
                            vehicles: orderBy(intDailyVehicles ? intDailyVehicles : dailyVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.daily })), this.state.dailyVehicles.sort),
                            allVehicles: orderBy(dailyVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.daily })), this.state.dailyVehicles.sort),
                            countItems: intCount,
                            sort: this.state.dailyVehicles.sort
                        };
                    }

                    if (contractedVehiclesResult != null) {
                        let cntrdVehicles: VehicleItemModel[] = [];
                        if (vehicleTypeGroupId && this.state.byVehicleTypeGroupIdsFilterEnabled == true)
                            vehicleTypeGroupId.forEach((element) => {
                                cntrdVehicles.push.apply(cntrdVehicles, contractedVehicles.filter(d => d.vehicleTypeGroupId === element).map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.contracted })))
                            });
                        else
                            cntrdVehicles = null;

                        const cntrdCount = cntrdVehicles?.length > 0 ? (cntrdVehicles).length : contractedVehiclesResult.data.count;
                        newStateObj.contractedVehicles = {
                            checked: this.state.contractedVehiclesChecked,
                            vehicles: orderBy(cntrdVehicles ? cntrdVehicles : contractedVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.contracted })), this.state.contractedVehicles.sort),
                            allVehicles: orderBy(contractedVehicles.map(obj => ({ ...obj, vehicleItemType: VehicleSelectorType.contracted })), this.state.contractedVehicles.sort),
                            countItems: cntrdCount,
                            sort: this.state.contractedVehicles.sort
                        };
                    }

                    if (lastTimeStamp !== this.state.lastTimeStampSearch)
                        return;

                    newStateObj.lastTimeStampSearch = currentTimeStamp;

                    this.translateAllInternalDailyVehicles(newStateObj.dailyVehicles?.vehicles as VehicleItemModel[], newStateObj.internalVehicles?.vehicles as VehicleItemModel[], this.state.internalVehiclesChecked, this.state.dailyVehiclesChecked);
                    if (newStateObj.dailyVehicles) {
                        newStateObj.dailyVehicles.countItems = (newStateObj.dailyVehicles
                            ? (newStateObj.dailyVehicles.vehicles as VehicleItemModel[]).filter(v => !v.isHidden).length : 0)
                            + (newStateObj.internalVehicles ? (newStateObj.internalVehicles.vehicles as VehicleItemModel[]).filter(v => !v.isHiddenDaily && v.isDailyVehicle).length : 0);
                    }

                    if (this._isMounted) {
                        this.setState(newStateObj);
                        this.props.handleChangeRefreshVehicles();
                    }

                }
            });
    }

    handleOpened = (): void => {
        this.setState({
            alreadyLoaded: true
        });
    }

    handleVehicleTypeGroupIdsFilterChange = (): void => {
        LocalStorage.SetItem(ModuleKey, LocalStorage_byVehicleTypeGroupIdsFilterEnabled, (!this.state.byVehicleTypeGroupIdsFilterEnabled).toString())
        if (!this.state.byVehicleTypeGroupIdsFilterEnabled == false)
            this.setFiltredVehicleGroupActivatedFalse();
        else {
            this.setFiltredVehicleGroupActivatedTrue(this.props.byVehicleTypeGroupIdsFilterValue, true);
        }

        this.setState({
            byVehicleTypeGroupIdsFilterEnabled: !this.state.byVehicleTypeGroupIdsFilterEnabled
        })
    }

    setFiltredVehicleGroupActivatedFalse = (): void => {
        const stateObj: any = {};

        if (this.state.genericVehiclesChecked) {
            const genericVehicles: any = this.state.genericVehicles.allVehicles;

            stateObj.genericVehicles = {
                checked: this.state.genericVehiclesChecked,
                vehicles: orderBy(genericVehicles, this.state.genericVehicles.sort),
                allVehicles: orderBy(this.state.genericVehicles.allVehicles, this.state.genericVehicles.sort),
                countItems: genericVehicles.length,
                sort: this.state.genericVehicles.sort
            };
        }

        if (this.state.transporterVehiclesChecked) {
            const transporterVehicles: any = this.state.transporterVehicles.allVehicles;

            stateObj.transporterVehicles = {
                checked: this.state.transporterVehiclesChecked,
                vehicles: orderBy(transporterVehicles, this.state.transporterVehicles.sort),
                allVehicles: orderBy(this.state.transporterVehicles.allVehicles, this.state.transporterVehicles.sort),
                countItems: transporterVehicles.length,
                sort: this.state.transporterVehicles.sort
            };
        }

        if (this.state.internalVehiclesChecked) {
            const internalVehicles: any = this.state.internalVehicles.allVehicles;

            stateObj.internalVehicles = {
                checked: this.state.internalVehiclesChecked,
                vehicles: orderBy(internalVehicles, this.state.internalVehicles.sort),
                allVehicles: orderBy(this.state.internalVehicles.allVehicles, this.state.internalVehicles.sort),
                countItems: internalVehicles.length,
                sort: this.state.internalVehicles.sort
            };
        }

        if (this.state.contractedVehiclesChecked) {
            const contractedVehicles: any = this.state.contractedVehicles.allVehicles;

            stateObj.contractedVehicles = {
                checked: this.state.contractedVehiclesChecked,
                vehicles: orderBy(contractedVehicles, this.state.contractedVehicles.sort),
                allVehicles: orderBy(this.state.contractedVehicles.allVehicles, this.state.contractedVehicles.sort),
                countItems: contractedVehicles.length,
                sort: this.state.contractedVehicles.sort
            };
        }

        //if (this.state.dailyVehiclesChecked) {
        const dailyVehicles: any = this.state.dailyVehicles.allVehicles;

        stateObj.dailyVehicles = {
            checked: this.state.dailyVehiclesChecked,
            vehicles: orderBy(dailyVehicles, this.state.dailyVehicles.sort),
            allVehicles: orderBy(this.state.dailyVehicles.allVehicles, this.state.dailyVehicles.sort),
            countItems: dailyVehicles.filter(v => !v.isHidden).length
                + this.state.internalVehicles ? (this.state.internalVehicles.vehicles as VehicleItemModel[]).filter(v => !v.isHiddenDaily && v.isDailyVehicle).length : 0,
            sort: this.state.dailyVehicles.sort
        };
        //}
        this.translateAllInternalDailyVehicles(stateObj.dailyVehicles?.vehicles, stateObj.internalVehicles?.vehicles, this.state.internalVehiclesChecked, this.state.dailyVehiclesChecked);

        if (stateObj.dailyVehicles)
            stateObj.dailyVehicles.countItems = (stateObj.dailyVehicles
                ? stateObj.dailyVehicles.vehicles.filter(v => !v.isHidden).length : 0)
                + (stateObj.internalVehicles ? stateObj.internalVehicles.vehicles.filter(v => !v.isHiddenDaily && v.isDailyVehicle).length : 0);

        this.setState(stateObj);
    }

    setFiltredVehicleGroupActivatedTrue = (vehicleTypeGroupId: string[], byVehicleTypeGroupIdsFilterEnabled: boolean): void => {
        const stateObj: any = {};

        if (this.state.genericVehiclesChecked) {
            let genericVehicles = [];
            if (vehicleTypeGroupId && byVehicleTypeGroupIdsFilterEnabled) {
                var allVehicles = this.state.genericVehicles.allVehicles;
                vehicleTypeGroupId.forEach(function (element) {
                    genericVehicles.push.apply(genericVehicles, allVehicles.filter(d => d.vehicleTypeGroupId === element))
                });
            }
            else
                genericVehicles = this.state.genericVehicles.allVehicles;

            stateObj.genericVehicles = {
                checked: this.state.genericVehiclesChecked,
                vehicles: orderBy(genericVehicles, this.state.genericVehicles.sort),
                allVehicles: orderBy(this.state.genericVehicles.allVehicles, this.state.genericVehicles.sort),
                countItems: genericVehicles.length,
                sort: this.state.genericVehicles.sort
            };
        }

        if (this.state.transporterVehiclesChecked) {
            let transporterVehicles = [];
            if (vehicleTypeGroupId && byVehicleTypeGroupIdsFilterEnabled) {
                var allVehicles = this.state.transporterVehicles.allVehicles;
                vehicleTypeGroupId.forEach(function (element) {
                    transporterVehicles.push.apply(transporterVehicles, allVehicles.filter(d => d.vehicleTypeGroupId === element))
                });
            }
            else
                transporterVehicles = this.state.transporterVehicles.allVehicles;

            stateObj.transporterVehicles = {
                checked: this.state.transporterVehiclesChecked,
                vehicles: orderBy(transporterVehicles, this.state.transporterVehicles.sort),
                allVehicles: orderBy(this.state.transporterVehicles.allVehicles, this.state.transporterVehicles.sort),
                countItems: transporterVehicles.length,
                sort: this.state.transporterVehicles.sort
            };
        }

        if (this.state.internalVehiclesChecked) {
            let internalVehicles = [];
            if (vehicleTypeGroupId && byVehicleTypeGroupIdsFilterEnabled) {
                var allVehicles = this.state.internalVehicles.allVehicles;
                vehicleTypeGroupId.forEach(function (element) {
                    internalVehicles.push.apply(internalVehicles, allVehicles.filter(d => d.vehicleTypeGroupId === element));
                });
            }
            else
                internalVehicles = this.state.internalVehicles.allVehicles;

            stateObj.internalVehicles = {
                checked: this.state.internalVehiclesChecked,
                vehicles: orderBy(internalVehicles, this.state.internalVehicles.sort),
                allVehicles: orderBy(this.state.internalVehicles.allVehicles, this.state.internalVehicles.sort),
                countItems: internalVehicles.length,
                sort: this.state.internalVehicles.sort
            };
        }

        if (this.state.contractedVehiclesChecked) {
            let contractedVehicles = [];
            if (vehicleTypeGroupId && byVehicleTypeGroupIdsFilterEnabled) {
                var allVehicles = this.state.contractedVehicles.allVehicles;
                vehicleTypeGroupId.forEach((element) => {
                    contractedVehicles.push.apply(contractedVehicles, allVehicles.filter(d => d.vehicleTypeGroupId === element))
                });
            }
            else
                contractedVehicles = this.state.contractedVehicles.allVehicles;

            stateObj.contractedVehicles = {
                checked: this.state.contractedVehiclesChecked,
                vehicles: orderBy(contractedVehicles, this.state.contractedVehicles.sort),
                allVehicles: orderBy(this.state.contractedVehicles.allVehicles, this.state.contractedVehicles.sort),
                countItems: contractedVehicles.length,
                sort: this.state.contractedVehicles.sort
            };
        }

        //if (this.state.dailyVehiclesChecked) {
        let dailyVehicles = [];

        if (vehicleTypeGroupId && byVehicleTypeGroupIdsFilterEnabled) {
            var allVehicles = this.state.dailyVehicles.allVehicles;
            vehicleTypeGroupId.forEach(function (element) {
                dailyVehicles.push.apply(dailyVehicles, allVehicles.filter(d => d.vehicleTypeGroupId === element));
            });
        }
        else
            dailyVehicles = this.state.dailyVehicles.allVehicles;

        stateObj.dailyVehicles = {
            checked: this.state.dailyVehiclesChecked,
            vehicles: orderBy(dailyVehicles, this.state.dailyVehicles.sort),
            allVehicles: orderBy(this.state.dailyVehicles?.allVehicles, this.state.dailyVehicles.sort),
            sort: this.state.dailyVehicles.sort
        };
        //}

        this.translateAllInternalDailyVehicles(stateObj.dailyVehicles?.vehicles, stateObj.internalVehicles?.vehicles, this.state.internalVehiclesChecked, this.state.dailyVehiclesChecked);

        if (stateObj.dailyVehicles)
            stateObj.dailyVehicles.countItems = (stateObj.dailyVehicles
                ? stateObj.dailyVehicles.vehicles.filter(v => !v.isHidden).length : 0)
                + (stateObj.internalVehicles ? stateObj.internalVehicles.vehicles.filter(v => !v.isHiddenDaily && v.isDailyVehicle).length : 0);

        this.setState(stateObj);
    }

    handleVehicleHiddenStateChange = (planningVehicleId: string, vehicleId: number): void => {
        const { hiddenVehicles, dailyVehicles } = this.state;

        VehiclesSelectorApiClient.UpdateIsHiddenForVehiclesSelector(planningVehicleId, vehicleId, this.props.planningDate, true, this.props.userName).then(res => {
            if (hiddenVehicles) {
                const hiddenVehicleKey = this.generateHiddenVehicleKey(planningVehicleId, vehicleId);
                const vehicleExists = hiddenVehicles?.has(hiddenVehicleKey) ?? false;
                if (!vehicleExists)
                    hiddenVehicles.add(hiddenVehicleKey);
            }

            const indexVehicleOfList = (dailyVehicles.vehicles as VehicleItemModel[]).findIndex(v => {
                return v.vehicleId == vehicleId
            });

            if (indexVehicleOfList != -1) {
                (dailyVehicles.vehicles[indexVehicleOfList] as VehicleItemModel).isHidden = true;
                dailyVehicles.countItems = (dailyVehicles.vehicles as VehicleItemModel[]).filter(v => {
                    return !v.isHidden
                }).length;
            }

            const { internalVehicles, internalVehiclesChecked, dailyVehiclesChecked } = this.state;
            this.translateAllInternalDailyVehicles(dailyVehicles?.vehicles as VehicleItemModel[], internalVehicles?.vehicles as VehicleItemModel[], internalVehiclesChecked, dailyVehiclesChecked);

            if (dailyVehicles) {
                dailyVehicles.countItems = (dailyVehicles.vehicles as VehicleItemModel[]).filter(v => !v.isHidden).length + (internalVehicles.vehicles as VehicleItemModel[]).filter(v => !v.isHiddenDaily && v.isDailyVehicle).length;
            }

            this.setState({
                hiddenVehicles: hiddenVehicles,
                dailyVehicles: dailyVehicles,
                internalVehicles: internalVehicles
            });

        });
    }

    handleDailyVehicleDateChange = (event: DatePickerChangeEvent): void => {
        let date: Date = event.target.value;

        //When the popup is visible
        if (event.target.state.show) {
            (this.datePickerRef.current as any).shouldFocusDateInput = false;
            this.handleSearchDatePickerChange(date);
        } else {
            if (!date)
                date = new Date(null);

            (this.datePickerRef.current as any).handleBlur = (e) => {
                this.handleSearchDatePickerChange(date);
            }
        }
    }

    handleSearchDatePickerChange = (date: Date): void => {
        const selectedDate: Date = date.getDayStart();
        if (!Date.equals(selectedDate, this.state.dailyVehicleDate)) {
            VehiclesSelectorApiClient.GetHiddenVehicles(selectedDate, this.props.planningDate)
                .then(res => {
                    if (this._isMounted) {
                        this.setState({
                            dailyVehicleDate: selectedDate,
                            hiddenVehicles: new Set(res.data.map(v => this.generateHiddenVehicleKey(v.planningVehicleId, v.vehicleId)))
                        });
                    }
                });

            this.handleDailyChange(this.state.valueInput, this.state.dailyVehiclesChecked, selectedDate);
        }
    }

    render() {
        return (
            <Drawer
                className='drawer-vehicle'
                variant="persistent"
                anchor="left"
                open={this.props.isVehiclesSelectorOpen}
                classes={{
                    paper: 'drawer-vehicle-paper'
                }}
            >
                <div className='toolbar-styles' />
                <VehiclesComponent handleDrawerLeftClose={this.props.handleDrawerLeftClose} handleAssignGenericVehicleType={this.props.handleAssignGenericVehicleType}
                    handleAssignTransporterVehicleType={this.props.handleAssignTransporterVehicleType} handleAssignInternalVehicle={this.props.handleAssignInternalVehicle}
                    handleAssignDailyPlanningVehicle={this.props.handleAssignDailyPlanningVehicle} handleAssignContractedVehicle={this.props.handleAssignContractedVehicle}
                    handleGenericChange={this.handleGenericChange} handleTransporterChange={this.handleTransporterChange}
                    handleInternalChange={this.handleInternalChange} handleDailyChange={this.handleDailyChange} handleContractedChange={this.handleContractedChange}
                    handleChangeVehicleInput={this.handleChangeVehicleInput}
                    clearSearchText={this.clearSearchText}
                    genericVehiclesChecked={this.state.genericVehiclesChecked} transporterVehiclesChecked={this.state.transporterVehiclesChecked}
                    internalVehiclesChecked={this.state.internalVehiclesChecked} dailyVehiclesChecked={this.state.dailyVehiclesChecked} contractedVehiclesChecked={this.state.contractedVehiclesChecked}
                    genericVehicles={this.state.genericVehicles} transporterVehicles={this.state.transporterVehicles}
                    internalVehicles={this.state.internalVehicles} dailyVehicles={this.state.dailyVehicles} contractedVehicles={this.state.contractedVehicles}
                    confirmedReservations={this.state.confirmedReservationsDictionary}
                    byVehicleTypeGroupIdsFilterEnabled={this.state.byVehicleTypeGroupIdsFilterEnabled}
                    includingAlreadyUsedFilterEnabled={this.state.includingAlreadyUsedFilterEnabled}
                    handleVehicleTypeGroupIdsFilterChange={this.handleVehicleTypeGroupIdsFilterChange}
                    handleIncludingAlreadyUsedFilterChange={this.handleIncludingAlreadyUsedFilterChange}
                    handleVehicleHiddenStateChange={this.handleVehicleHiddenStateChange}
                    dailyVehicleDateRef={this.datePickerRef} dailyVehicleDate={this.state.dailyVehicleDate} handleDailyVehicleDateChange={this.handleDailyVehicleDateChange}
                />
            </Drawer>
        );
    }
}