import axios, { AxiosResponse } from "axios";
import { WebAppActionResult } from "../../../../shared/models/WebAppActionResult";
import { AddDispatcherManagedAgenciesRequestArgs } from "./dataContracts/controller/AddDispatcherManagedAgenciesRequestArgs";
import { AddDispatcherRequestArgs } from "./dataContracts/controller/AddDispatcherRequestArgs";
import { DispatcherCandidateToAdd } from "./dataContracts/controller/DispatcherCandidateToAdd";
import { RemoveDispatcherManagedAgenciesRequestArgs } from "./dataContracts/controller/RemoveDispatcherManagedAgenciesRequestArgs";
import { AgencyLightModel } from "./dataContracts/queryStack/AgencyLightModel";
import { DispatcherAgencyLightModel } from "./dataContracts/queryStack/DispatcherAgencyLightModel";
import { DispatcherLightModel } from "./dataContracts/queryStack/DispatcherLightModel";

const controllerUrl = "api/DispatchersReferential/";
const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

export class DispatchersReferentialApiClient {

    public static GetDispatchers = (searchText: string)
        : Promise<AxiosResponse<Array<DispatcherLightModel>>> => {
        return axios.get(`${controllerUrl}GetDispatchers?searchText=${encodeURIComponent(searchText)}`);
    }

    public static SearchDispatcher = (userName: string)
        : Promise<AxiosResponse<DispatcherCandidateToAdd>> => {
        return axios.get(`${controllerUrl}SearchDispatcher?userName=${encodeURIComponent(userName)}`);
    }

    public static AddDispatcher = (requestArgs: AddDispatcherRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddDispatcher',
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static GetDispatcherAgencies = (userName: string)
        : Promise<AxiosResponse<Array<DispatcherAgencyLightModel>>> => {
        return axios.get(`${controllerUrl}GetDispatcherAgencies?userName=${encodeURIComponent(userName)}`);
    }

    public static GetAgencies = ()
        : Promise<AxiosResponse<Array<AgencyLightModel>>> => {
        return axios.get(`${controllerUrl}GetAgencies`);
    }

    public static AddDispatcherManagedAgencies = (requestArgs: AddDispatcherManagedAgenciesRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddDispatcherManagedAgencies',
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static RemoveDispatcherManagedAgencies = (requestArgs: RemoveDispatcherManagedAgenciesRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'RemoveDispatcherManagedAgencies',
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

}
