import { faMoon, faSun, faTruckMoving, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardContent } from '@mui/material';
import { PDFExport } from '@progress/kendo-react-pdf';
import QRCode from 'qrcode.react';
import React, { Fragment, useEffect } from 'react';
import LorieIcon from 'src/shared/components/Common/LorieIcon';
import Utilities from 'src/utils/Utilities';
import { SiteKindLightModel } from '../ThirdPartyTransporter/services/dataContracts/queryStack/SiteKindLightModel';
import { ThirdPartyTransporterPrintData, ThirdPartyTransporterPrintDataProperty } from './models/TransporterOrderPrintData';
import './ThirdPartyTransporterPrintStyles.scss';

interface QrCodeData {
    codeUE: string,
    codeUP: string,
    dateDemarrage: string,
    idChantier: string,
    nameSite: string,
    adresse: string,
    ville: string,
    cp: string,
    produits: Array<QrCodeProductData>
}

interface QrCodeProductData {
    codeProduit: string,
    heure_demarrage: string
}

export const ThirdPartyTransporterPrintView = () => {
    const transporterOrder: ThirdPartyTransporterPrintData = window.opener[ThirdPartyTransporterPrintDataProperty];
    const pdfExportComponent = React.useRef<PDFExport>(null);

    useEffect(() => {
        if (transporterOrder) {
            if (transporterOrder.requestToGenerateFile) {
                if (pdfExportComponent?.current)
                    pdfExportComponent.current.save();
            }
            else {
                //ODA CMA Pour éviter à ce que le window.print se déclenche trop tôt (avant le rendu)
                setTimeout(() => {
                    window.print();
                }, 500);
            }
        }
    }, []);

    const getAddress = (addressLine1: string, addressLine2: string): string => {
        return `${addressLine1 && addressLine2 ? `${addressLine1}, ${addressLine2}`
            : `${addressLine1 ? addressLine1 : addressLine2}`}`;
    }

    return (
        <>
            {transporterOrder !== undefined ?
                transporterOrder.lines.map((line, index) => {
                    const vehicleLabel = `${line.vehicleTypeLabel} - ${line.licencePlate} ${transporterOrder.isForInternalTransportes ? ' - ' + line.equipmentId : ''}`;

                    return (
                        <PDFExport
                            fileName={`LORIE ${line.planningId}${line.driverName ? " " + line.driverName : ""}`}
                            scale={0.5}
                            paperSize="A4"
                            margin="0.5cm"
                            ref={pdfExportComponent}
                            key={`transporter-print-${index}`}
                        >
                            <div className="transporter-print">
                                <Box p="10px"><span><b>CONFIRMATION de COMMANDE – PRESTATION de TRANSPORT</b></span> (Article L.3222-4 du Code des Transports)</Box>
                                <Box p="10px" display='flex' flexDirection='column'>
                                    <Box>Nous entendons que vous exécutez personnellement en tant que voiturier, les transports que nous vous confions. Il ne pourra être recouru à la sous-traitance, à titre exceptionnel, qu’en cas de nécessité absolue et après approbation par nos soins du nom de votre affrété.</Box>
                                    <Box>Le transport s’effectue dans le respect du contrat cadre et la prise en compte des informations ci-dessous. Le conducteur doit être en règle avec la réglementation en vigueur.</Box>
                                    <Box>A compter de la réception du mail, le transporteur doit nous informer dans les plus brefs délais de la non-exécution de la commande. Dans le cas contraire, la tacite acceptation prévaut.</Box>
                                    <Box> En votre qualité de transporteur, nous vous demandons de fournir l’exemplaire « expéditeur » de la lettre de voiture (prévue à l’article du 9 Novembre 1999) par mail à votre donneur d’ordre.</Box>
                                    <Box> Nous vous rappelons que : <span className="important-information">« Le port des EPI, le respect des consignes de sécurité et le bâchage des bennes sont OBLIGATOIRES »</span>.</Box>
                                </Box>
                                <Box pl={2} pr={2} width="100%" display="flex" flexDirection="row" className="transporter-print-header" alignItems="center">
                                    <Box width="33%">
                                        <LorieIcon name="logoLorie" height="55px" width="55px" />
                                    </Box>
                                    <Box width="33%">
                                        <div className="transporter-name">
                                            {transporterOrder.transporter}
                                        </div>
                                        <div className="delivery-date">
                                            {`${Date.getDateFromIsoString(transporterOrder.date)}`}
                                        </div>
                                    </Box>
                                    <Box width="34%" className="reference">
                                        Confirmation
                                        <div>
                                            {line.orderReference}
                                        </div>
                                    </Box>
                                </Box>
                                <div className="transporter-print-content">
                                    {line.logisticsUnit?.billingAddress &&
                                        <div className="billing-address">
                                            {`${line.logisticsUnit.billingAddress ?? ''}${line.logisticsUnit.europeanVatNumber ? ` / TVA intracommunautaire : ${line.logisticsUnit.europeanVatNumber}` : ''}`}
                                        </div>
                                    }
                                    <Card className="transporter-print-card-vehicle">
                                        <CardContent>
                                            <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" width="90%" pb={0.5}>
                                                <FontAwesomeIcon className="icon-transporter" size="lg" icon={faTruckMoving} />
                                                <Box pr={1} className="vehicle-type">
                                                    {vehicleLabel}
                                                </Box>
                                                <Box display="flex" flexDirection="row" alignItems="center" pr={1}>
                                                    {line.isNightWork == true ? (
                                                        <FontAwesomeIcon size="1x" icon={faMoon} className="icon-work" />
                                                    ) :
                                                        <FontAwesomeIcon size="1x" icon={faSun} className="icon-work" />
                                                    }
                                                    {line.mainPriceKindLabel && (
                                                        <>
                                                            -
                                                            {/*NOTE ODA on n'a pas utilisé le pseudo-element CSS "::first-letter" car pas supporté par le composant PDFExport*/}
                                                            <div className="main-price-kind-first-letter">{line.mainPriceKindLabel.substring(0, 1)}</div>
                                                            <div className="main-price-kind-ending-letters">{line.mainPriceKindLabel.length > 1 ? line.mainPriceKindLabel.substring(1) : ''}</div>
                                                        </>
                                                    )}
                                                    {line.contractualPurchasePriceContractNumber &&
                                                        <div className="contract-number">{`PAC (${line.contractualPurchasePriceContractNumber})`}</div>
                                                    }
                                                </Box>
                                            </Box>
                                            <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" width="90%" pb={0.5}>
                                                <Box display="flex" flexDirection="row" width="60%">
                                                    <FontAwesomeIcon className="icon-transporter" size="lg" icon={faUser} />
                                                    <Box pl={1}>
                                                        <div className="value-field">
                                                            {line.driverName}
                                                        </div>
                                                        <div className="value-field">
                                                            {Utilities.formatPhoneNumber(line.driverPhone)}
                                                        </div>
                                                    </Box>
                                                </Box>

                                                <Box width="40%">
                                                    Charge Utile: <span className="value-field">{line.loadCapacity ? `${line.loadCapacity.toStringFixed('FR-fr')} Kg` : ''}</span>
                                                </Box>
                                            </Box>
                                            {line.transportRequests.length > 0 &&
                                                <>
                                                    <Box pl={1} className="delivery-title">
                                                        <span className="nb-delivery">{line.transportRequests.length} livraison(s)</span> associée(s) à ce camion :
                                                    </Box>
                                                    {line.labeledTripsOrdered.length > 0 &&
                                                        <Box display="flex" flexDirection="row" flexWrap="nowrap">
                                                            <Box className="trip-order-title">
                                                                Ordre des tours:
                                                            </Box>
                                                            {!line.transportRequests.some(x => x.serviceKind === "JobsiteVehicle") &&
                                                                <Box display="flex" flexDirection="row" flexWrap="wrap">
                                                                    {[...line.labeledTripsOrdered].map((value, index) => {
                                                                        return <Box display="flex" key={`trip-order-card-${index}`} flexDirection="row" className="trip-order-card" pr={1}>
                                                                            <Box pr={0.2} className="trip-number">
                                                                                {value.number}
                                                                            </Box>
                                                                            <Box textAlign="center" className="trip-label">
                                                                                {value.label}
                                                                            </Box>
                                                                        </Box>
                                                                    })}
                                                                </Box>
                                                            }
                                                        </Box>}
                                                    <Box className="transport-request-print">
                                                        {line.transportRequests.map((tr, indexTr) => {
                                                            const dataQrCode: QrCodeData = {
                                                                codeUE: tr.senderSiteKind === SiteKindLightModel.jobSite ? tr.senderSiteAgencyId : tr.receiverSiteAgencyId,
                                                                codeUP: tr.senderSiteKind === SiteKindLightModel.productionSite ? tr.senderProductionSiteAlternateCode : tr.receiverProductionSiteAlternateCode,
                                                                dateDemarrage: Date.getDateFormatterFromIsoString(transporterOrder.date, 'DD-MM-YYYY'),
                                                                nameSite: tr.senderSiteKind === SiteKindLightModel.productionSite ? tr.senderSiteLabel : tr.receiverSiteLabel,
                                                                idChantier: tr.senderSiteKind === SiteKindLightModel.jobSite ? tr.senderSiteJobSiteMdmId : tr.receiverSiteJobSiteMdmId,
                                                                adresse: tr.senderSiteKind === SiteKindLightModel.jobSite ? getAddress(tr.pickupAddressLine1, tr.pickupAddressLine2) : getAddress(tr.deliveryAddressLine1, tr.deliveryAddressLine2),
                                                                ville: tr.senderSiteKind === SiteKindLightModel.jobSite ? tr.pickupAddressCity : tr.deliveryAddressCity,
                                                                cp: tr.senderSiteKind === SiteKindLightModel.jobSite ? tr.pickupAddressZipCode : tr.deliveryAddressZipCode,
                                                                produits: [{
                                                                    codeProduit: tr.formule,
                                                                    heure_demarrage: Date.getTimeFromIsoString(tr.firstStartTime).toString()
                                                                }]
                                                            };

                                                            return (
                                                                <Fragment key={`transporter-print-card-${index}-tr-${indexTr}`}>
                                                                    <div className="separator-card"></div>
                                                                    <Card className="transport-request-print-card">
                                                                        <CardContent>
                                                                            <Box display="flex" flexDirection="column">
                                                                                <Box pl={2} pr={2} width="100%" display="flex" flexDirection="row" className="transport-request-print-header" alignItems="center">
                                                                                    <Box width="33%">
                                                                                        {tr.businessId}
                                                                                    </Box>
                                                                                    <Box width="33%" className="formule">
                                                                                        Formule {tr.formule}
                                                                                    </Box>
                                                                                    <Box width="33%" className="jobForeman">
                                                                                        {tr.jobForemanName}
                                                                                        <div>
                                                                                            {tr.jobForemanTel ? Utilities.formatPhoneNumber(tr.jobForemanTel) : ''}
                                                                                        </div>
                                                                                    </Box>
                                                                                </Box>
                                                                                <Box className="transport-request-print-content">
                                                                                    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
                                                                                        <Box className="details">
                                                                                            <Box display="flex" flexDirection="row" alignItems="center" pb={2}>
                                                                                                <Box>
                                                                                                    Site de départ
                                                                                                    <div className="value-field">
                                                                                                        {`${tr.senderSiteLabel}${(tr.senderSiteKind === "jobSite" && tr.senderSiteAgencyLabel) ? (' - ' + tr.senderSiteAgencyLabel) : ''}${(tr.senderSiteKind === "jobSite" && tr.senderSiteJobSiteLabel) ? (' - ' + tr.senderSiteJobSiteLabel) : ''}`}
                                                                                                    </div>
                                                                                                </Box>
                                                                                            </Box>
                                                                                            <Box display="flex" flexDirection="row" alignItems="center" pb={2}>
                                                                                                <Box>
                                                                                                    Adresse de départ
                                                                                                    <Box className="value-field">
                                                                                                        {`${tr.pickupAddressLine1 ? (tr.pickupAddressLine1 + ' ') : ''}`}{`${tr.pickupAddressLine2 ? tr.pickupAddressLine2 : ''}`}
                                                                                                        {`${tr.pickupAddressZipCode && tr.pickupAddressCity ? ' - ' + tr.pickupAddressZipCode + ' ' + tr.pickupAddressCity : ''}`}
                                                                                                        {`${tr.pickupAddressZipCode && !tr.pickupAddressCity ? ' - ' + tr.pickupAddressZipCode : ''}`}
                                                                                                        {`${!tr.pickupAddressZipCode && tr.pickupAddressCity ? ' - ' + tr.pickupAddressCity : ''}`}
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Box>
                                                                                            <Box display="flex" flexDirection="row" alignItems="center" pb={2}>
                                                                                                <Box>
                                                                                                    Site d'arrivée
                                                                                                    <div className="value-field">
                                                                                                        {`${tr.receiverSiteLabel}${(tr.receiverSiteKind === "jobSite" && tr.receiverSiteAgencyLabel) ? (' - ' + tr.receiverSiteAgencyLabel) : ''}${(tr.receiverSiteKind === "jobSite" && tr.receiverSiteJobSiteLabel) ? (' - ' + tr.receiverSiteJobSiteLabel) : ''}`}
                                                                                                    </div>
                                                                                                </Box>
                                                                                            </Box>
                                                                                            <Box display="flex" flexDirection="row" alignItems="center" pb={2}>
                                                                                                <Box>
                                                                                                    Adresse d'arrivée
                                                                                                    <Box className="value-field">
                                                                                                        {`${tr.deliveryAddressLine1 ? (tr.deliveryAddressLine1 + ' ') : ''}`}{`${tr.deliveryAddressLine2 ? (tr.deliveryAddressLine2) : ''}`}
                                                                                                        {`${tr.deliveryAddressZipCode && tr.deliveryAddressCity ? ' - ' + tr.deliveryAddressZipCode + ' ' + tr.deliveryAddressCity : ''}`}
                                                                                                        {`${tr.deliveryAddressZipCode && !tr.deliveryAddressCity ? ' - ' + tr.deliveryAddressZipCode : ''}`}
                                                                                                        {`${!tr.deliveryAddressZipCode && tr.deliveryAddressCity ? ' - ' + tr.deliveryAddressCity : ''}`}
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Box>
                                                                                            <Box display="flex" flexDirection="row" alignItems="center" pb={2}>
                                                                                                <Box width="25%">
                                                                                                    1<sup>er</sup> chargement
                                                                                                    <div className="value-field">
                                                                                                        {`${tr.firstStartTime === null ? '' : Date.getTimeFromIsoString(tr.firstStartTime)}`}
                                                                                                    </div>
                                                                                                </Box>
                                                                                                <Box width="25%" pl={1}>
                                                                                                    {tr.serviceKind !== "JobsiteVehicle" &&
                                                                                                        <>
                                                                                                            Tonnage estimé
                                                                                                            <div className="value-field">
                                                                                                                {tr.serviceKind !== "JobsiteVehicle" && tr.estimatedTonnage}
                                                                                                            </div>
                                                                                                        </>}
                                                                                                </Box>
                                                                                                <Box width="25%" pl={1}>
                                                                                                    {tr.serviceKind === "JobsiteVehicle" ? "Durée sur chantier" : "Tours estimé"}
                                                                                                    <div className="value-field">
                                                                                                        {tr.serviceKind === "JobsiteVehicle" ? tr.unloadingDuration : tr.tripsNumber}
                                                                                                    </div>
                                                                                                </Box>
                                                                                                <Box width="25%" pl={1}>
                                                                                                    {tr.serviceKind !== "JobsiteVehicle" &&
                                                                                                        <>
                                                                                                            Ordre des tours
                                                                                                            <div className="value-field">
                                                                                                                {tr.tripsOrderNumbers.join('; ')}
                                                                                                            </div>
                                                                                                        </>}
                                                                                                </Box>
                                                                                            </Box>
                                                                                            {(tr.transportersInstructions && tr.transportersInstructions !== '') && <Box pb={1}>
                                                                                                Instructions transporteurs: <span className="value-field">{tr.transportersInstructions}</span>
                                                                                            </Box>}
                                                                                            {tr.instructions.length > 0 && (<Box>
                                                                                                Instructions chauffeurs: <span className="value-field">
                                                                                                    {tr.instructions.length == 1 ? tr.instructions[0].instructionText : (
                                                                                                        tr.instructions.map((instructionModel, instructionIndex) =>
                                                                                                            instructionModel.instructionText !== '' && <div key={instructionIndex}> <b> {'Tour n° ' + instructionModel.rotationNumber} </b> {' : ' + instructionModel.instructionText}</div>
                                                                                                        )
                                                                                                    )}
                                                                                                </span>
                                                                                            </Box>)}
                                                                                        </Box>
                                                                                        <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                                                                            {((tr.senderSiteKind == SiteKindLightModel.productionSite && tr.receiverSiteKind == SiteKindLightModel.jobSite)
                                                                                                || (tr.senderSiteKind == SiteKindLightModel.jobSite && tr.receiverSiteKind == SiteKindLightModel.productionSite))
                                                                                                &&
                                                                                                <QRCode value={JSON.stringify(dataQrCode)} size={256} />
                                                                                            }
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Fragment>
                                                            )
                                                        })
                                                        }
                                                    </Box>
                                                </>
                                            }
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </PDFExport>
                    )
                }) : <></>
            }
        </>
    )
}
