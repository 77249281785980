import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ClickAwayListener } from "@mui/material";
import { Popup } from "@progress/kendo-react-popup";
import React from "react";
import { UserSignatureFieldType } from "../services/dataContracts/controller/UserSignatureFieldType";

interface UserSignatureFieldTypeMenuComponentProps {
    handleAddSignatureFieldLine: (type: UserSignatureFieldType) => void
}

export const UserSignatureFieldTypeMenuComponent = (props: UserSignatureFieldTypeMenuComponentProps): JSX.Element => {

    const anchor = React.useRef<any>(null);

    const [show, setShow] = React.useState(false);

    const handleAddLine = (type: UserSignatureFieldType): void => {
        props.handleAddSignatureFieldLine(type);
        setShow(false)
    }

    return (
        <ClickAwayListener onClickAway={() => setShow(false)}>
            <Box width="16px" ml="10px">
                <Box ref={anchor}>
                    <FontAwesomeIcon icon={faPlus} color="#6098f2" style={{ cursor: "pointer" }} onClick={() => setShow(true)} />
                </Box>
                <Popup
                    anchor={anchor.current}
                    show={show}>
                    <Button onClick={() => handleAddLine(UserSignatureFieldType.email)}>Email</Button>
                    <Button onClick={() => handleAddLine(UserSignatureFieldType.text)}>Texte</Button>
                    <Button onClick={() => handleAddLine(UserSignatureFieldType.phoneNumber)}>Téléphone</Button>
                </Popup>
            </Box>
        </ClickAwayListener>
    );
}