import React from 'react';
import { DeliveryTripInstructionLightModel } from '../services/dataContracts/queryStack/DeliveryTripInstructionLightModel';

interface InstructionsComponentProperties {
    transportersInstructions: string,
    instructions: DeliveryTripInstructionLightModel[]
}

export const InstructionsComponent = (props: InstructionsComponentProperties): JSX.Element => {
    const instructions: DeliveryTripInstructionLightModel[] = props.instructions.filter(d => d.instructionText !== '');
    const transportersInstructions: string = props.transportersInstructions;

    return (
        <>
            {
                (transportersInstructions && transportersInstructions !== '') ? (
                    <p className="loading-card-font-p loading-card-mg-b">
                        {transportersInstructions}
                    </p>
                ) : ''
            }
            {
                instructions.length == 1 ?
                    instructions[0].instructionText !== '' && <p className="loading-card-font-p loading-card-mg-b">{instructions[0].instructionText}</p>
                    :
                    instructions.length > 1 && instructions.map((instructionModel, instructionIndex) =>
                        instructionModel.instructionText !== '' && <p key={`trip-instruction${instructionIndex}`} className="loading-card-font-p loading-card-mg-b"> <b> {'Tour n° ' + instructionModel.rotationNumber} </b> {' : ' + instructionModel.instructionText}</p>
                    )
            }
        </>
    );
}