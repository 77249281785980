import Axios, { AxiosResponse } from 'axios';
import { DateRange } from '../../../../shared/models/DateRange';
import { WebAppActionResult } from '../../../../shared/models/WebAppActionResult';
import { PublishedCostsExportRequestArgs } from './dataContracts/controller/PublishedCostsExportRequestArgs';
import { PlanningVehiclesPublishedCostsLightModel } from './dataContracts/queryStack/PlanningVehiclesPublishedCostsLightModel';

const controllerUrl = 'api/ThirdPartyTransporterPublishedCosts/';

export class ThirdPartyTransporterPublishedCostsApiClient {

    public static GetPlanningVehiclesPublisedCosts = (date: DateRange, transporterId: string, searchText: string)
        : Promise<AxiosResponse<Array<PlanningVehiclesPublishedCostsLightModel>>> => {
        return Axios({
            method: 'POST',
            url: `${controllerUrl}GetPlanningVehiclesPublisedCosts?fromTime=${encodeURIComponent(date.start.toJSON())}&untilTime=${encodeURIComponent(date.end.toJSON())}&transporterId=${encodeURIComponent(transporterId)}&searchText=${encodeURIComponent(searchText)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static ChangePublishedCostsAgreedAndRemarksOfTransporter = (planningVehicleId: string, transporterId: string, costsRemarksOfTransporter: string, costsAreAgreed?: boolean)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        let url = `${controllerUrl}ChangePublishedCostsAgreedAndRemarksOfTransporter?planningVehicleId=${encodeURIComponent(planningVehicleId)}&transporterId=${encodeURIComponent(transporterId)}`;
        if(costsAreAgreed !== null) {
            url += `&costsAreAgreed=${costsAreAgreed}`;
        }

        if(costsRemarksOfTransporter !== null) {
            url += `&costsRemarksOfTransporter=${encodeURIComponent(costsRemarksOfTransporter)}`;
        }

        return Axios({
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }
    
    public static ExportPublishedCosts(requestArgs: PublishedCostsExportRequestArgs)
        : Promise<AxiosResponse<Blob>> {
        const _headers = {
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return Axios.post(controllerUrl + 'ExportPublishedCosts', requestArgs, { headers: _headers, responseType: 'blob' });
    }

}