import { Box, Button, TextField } from '@mui/material';
import React from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import ToastService from 'src/ToastService';
import BusinessErrors from '../../../../utils/BusinessErrors';
import Utilities from '../../../../utils/Utilities';
import { AddedDriverRequestArgs } from '../services/dataContracts/controller/AddedDriverRequestArgs';
import { UpdatedDriverRequestArgs } from '../services/dataContracts/controller/UpdatedDriverRequestArgs';
import { DriverLightModel } from '../services/dataContracts/queryStack/DriverLightModel';
import { ThirdPartyTransporterApiClient } from '../services/ThirdPartyTransporterApiClient';

interface IChildComponentProps {
    onClose: () => void,
    onAddOrUpdate: (transporterId: string) => void,
    transporterId: string,
    driver?: DriverLightModel
}

interface AddOrUpdateDriverComponentState {
    fullName: string,
    phoneNumber: string,
    isvalidPhoneNumber: boolean
}

class AddOrUpdateDriverComponent extends React.Component<IChildComponentProps, AddOrUpdateDriverComponentState> {
    constructor(props: IChildComponentProps) {
        super(props);
        this.state = {
            fullName: '',
            phoneNumber: '',
            isvalidPhoneNumber: false
        }
    }

    componentDidMount() {
        this.loadDriver();
    }

    loadDriver = (): void => {
        if (this.props.driver) {
            this.setState({
                fullName: this.props.driver.fullName,
                phoneNumber: this.props.driver.phoneNumber
            });
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, property: string): void => {
        if (property === 'fullName') {
            this.setState({ fullName: event.target.value });
        }
        else if (property === 'phoneNumber') {
            this.setState({ phoneNumber: event.target.value, isvalidPhoneNumber: true });
        }
    }

    hasValidInformations = (): boolean => {
        let isValid = true;
        const phoneNumberValid = Utilities.isValidPhoneNumber(this.state.phoneNumber);
        if (!phoneNumberValid) {
            ToastService.showErrorToast("Numéro de téléphone Invalide");
            isValid = false;
        }
        return isValid;
    }

    addDriver = (): void => {
        if (this.hasValidInformations()) {
            ThirdPartyTransporterApiClient.SearchDrivers(this.props.transporterId, true)
                .then(res => {
                    let isNewDriver = true;
                    const driver: AddedDriverRequestArgs = {
                        transporterId: this.props.transporterId,
                        fullName: this.state.fullName,
                        phoneNumber: this.state.phoneNumber,
                    }
                    res.data.forEach(function (element: DriverLightModel) {
                        if (element.fullName.toUpperCase() === (driver.fullName).toUpperCase())
                            isNewDriver = false;
                    });

                    if (!isNewDriver)
                        ToastService.showErrorToast("Le chaffeur existe déjà.");
                    else {
                        ThirdPartyTransporterApiClient.AddDriver(driver)
                            .then(json => {
                                const errors: string[] = BusinessErrors.Get(json.data);
                                if (errors.length > 0) {
                                    ToastService.showErrorToast("L'ajout n'est pas pris en compte.");
                                    return;
                                }
                                this.props.onAddOrUpdate(this.props.transporterId);
                            });
                    }
                });
        }
    }

    updateDriver = (): void => {
        if (this.hasValidInformations()) {
            const driver: UpdatedDriverRequestArgs = {
                id: this.props.driver.driverId,
                transporterId: this.props.transporterId,
                fullName: this.state.fullName,
                phoneNumber: this.state.phoneNumber
            }
            ThirdPartyTransporterApiClient.UpdateDriver(driver)
                .then(json => {
                    const errors: string[] = BusinessErrors.Get(json.data);
                    if (errors.length > 0) {
                        ToastService.showErrorToast("Les modifications ne sont pas pris en compte.");
                        return;
                    }
                    this.props.onAddOrUpdate(this.props.transporterId);
                });
        }
    }

    addOrUpdateDriver = (): void => {
        if (!this.props.driver)
            this.addDriver();
        else
            this.updateDriver();
    }

    render() {
        const { onClose } = this.props;
        const isEnabledBtn: boolean = this.state.fullName !== "" && this.state.phoneNumber !== "";
        const fullName: string = this.state.fullName ? this.state.fullName : '';
        const phoneNumber: string = this.state.phoneNumber ? this.state.phoneNumber : '';

        return (
            <>
                <Box display="flex" flexWrap="wrap" flexDirection="row" className="add-update-driver">
                    <Box mb={"20px"} width="100%">
                        <TextField
                            label="Nom Prénom"
                            className="input-driver"
                            variant="outlined"
                            value={fullName}
                            onChange={(e) => this.handleChange(e, 'fullName')}
                        />
                    </Box>
                    <Box mb={"20px"} width="100%">
                        <TextField
                            variant="outlined"
                            className="input-driver"
                            label="Numéro de téléphone"
                            value={phoneNumber}
                            onChange={(e) => this.handleChange(e, 'phoneNumber')}
                        />
                    </Box>
                    <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="flex-end" width="100%">
                        <Button variant="contained" color="secondary" style={{ 'margin': '5px' }} onClick={onClose}>
                            Annuler
                        </Button>
                        <Button variant="contained" color="primary" style={{ 'margin': '5px' }} disabled={!isEnabledBtn} onClick={this.addOrUpdateDriver}>
                            Sauvegarder
                        </Button>
                    </Box>
                </Box>
            </>
        );
    }
}

export default AddOrUpdateDriverComponent;