import { faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { TimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderCellProps, GridItemChangeEvent, GridPageChangeEvent, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import { EditableHeaderComponent } from 'src/shared/components/Common/EditableHeaderComponent';
import { PhoneNumberCell } from '../../../shared/components/Common/PhoneNumberCell';
import { PlanningVehicleStatusComponent } from '../../../shared/components/PlanningVehicleStatus/PlanningVehicleStatusComponent';
import { AppModule, LocalStorage, SessionStorage } from '../../../utils/Storage';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';
import { PurchaseCostUsedQuantitiesKindEnum } from '../models/PurchaseCostUsedQuantitiesKindEnum';
import { VehiclePriceKind } from '../models/VehiclePriceKind';
import { DriverLightModel } from '../services/dataContracts/queryStack/DriverLightModel';
import { SizingUtilities } from '../SizingUtilities';
import { CustomBilledTimeCell } from './CustomBilledTimeCell';
import { CustomCheckBoxCell } from './CustomCheckBoxCell';
import { CustomCostsPublishedCell } from './CustomCostsPublishedCell';
import { CustomDriverCell } from './CustomDriverCell';
import { CustomIsNightCell } from './CustomIsNightCell';
import { CustomPriceKindCell } from './CustomPriceKindCell';
import { CustomPricesCell } from './CustomPricesCell';
import { DetailComponent } from './DetailComponent';
import RemarksCell from './RemarksCell';

interface TransportPurchasesComponentProps {
    planningVehiclesList: Array<PlanningVehicleLightModelExtended>,
    driversList: Array<DriverLightModel>,
    selectedAll: boolean,
    showOnlyWithCostsInDisagreement: boolean,
    showOnlyCanceledVehicles: boolean,
    cellRender: (tdElement: any, cellProps: any) => React.ReactElement,
    rowRender: (trElement: any, dataItem: any) => React.ReactElement,
    selectionChange: (checked: boolean, dataItem?: PlanningVehicleLightModelExtended) => void,
    headerSelectionChange: (checked: boolean) => void,
    rowClick: (event: GridRowClickEvent) => void,
    onChangeHandler: (item: PlanningVehicleLightModelExtended) => void,
    handleBilledTimeChange: (item: PlanningVehicleLightModelExtended) => void,
    getLabelPrice: (priceKind: string) => string,
    displayErrorsMessages: (errors: string[]) => void,
    refreshPlanningVehicles: () => void,
    refreshPlanningVehicleWithDetails:(planningVehicleId: string) => void,
    handleUpdatingPlanningVehicle: (planningVehicleId: string) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const TransportPurchasesGridName = 'transportPurchases';
const ModuleKey = AppModule.TransportPurchases;

export const TransportPurchasesComponent = (props: TransportPurchasesComponentProps) => {

    const VehiclesGridSortDescriptorsStorageKey = "vehicleManagement_vehicleGrid_sortItem";
    const storedSortDescriptors = SessionStorage.GetItem(ModuleKey, VehiclesGridSortDescriptorsStorageKey);
    const initialSort: SortDescriptor[] = storedSortDescriptors ? JSON.parse(storedSortDescriptors) : [{ field: 'licencePlate', dir: 'asc' }];

    const [sort, setSort] = useState<Array<SortDescriptor>>(initialSort);
    const [skip, setSkip] = useState<number>(0);

    const forceUpdate = useForceUpdate();

    const getVehicleGridOrderIndexColumn = (propName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, TransportPurchasesGridName, propName, defaultIndex);
    }

    const getVehicleGridWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, TransportPurchasesGridName, fieldName, columnWidth);
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        // Gérer le tri par date: displayDate est de type string (pour afficher le vrai date si fuseau de la machine es changé)
        // => Trier avec les données de la colonne caché rawDate (de type Date)
        if (e.sort.length > 0 && e.sort[0].field === "displayDate") {
            let sortItem: SortDescriptor[] = null;
            if (sort.length > 0 && sort[0].dir === 'asc' && sort[0].field === "rawDate") {
                sortItem = [{ field: 'rawDate', dir: 'desc' }, { field: 'displayDate', dir: 'desc' }];
            } else if (sort.length > 0 && sort[0].dir === 'desc' && sort[0].field === "rawDate") {
                sortItem = [];
            } else {
                sortItem = [{ field: 'rawDate', dir: 'asc' }, { field: 'displayDate', dir: 'asc' }];
            }
            setSort(sortItem);
            SessionStorage.SetItem(ModuleKey, VehiclesGridSortDescriptorsStorageKey, JSON.stringify(sortItem));
        } else {
            setSort(e.sort);
            SessionStorage.SetItem(ModuleKey, VehiclesGridSortDescriptorsStorageKey, JSON.stringify(e.sort));
        }
    }

    const handleSortEditableColumnChange = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, field: string): void => {
        let sortItem: SortDescriptor[] = null;
        if (field === "priceKindName") {
            if (sort.length > 0 && sort[0].dir === 'desc')
                sortItem = [];
            else if (sort.length > 0 && sort[0].dir === 'asc')
                sortItem = [{ field: 'hiddenPriceKind', dir: "desc" }, { field: 'hiddenUnitPrice', dir: "desc" }, { field: 'priceKindName', dir: "desc" }]
            else
                sortItem = [{ field: 'hiddenPriceKind', dir: "asc" }, { field: 'hiddenUnitPrice', dir: "asc" }, { field: 'priceKindName', dir: "asc" }]
        } else if (sort.length > 0 && sort[0].dir === 'asc' && sort[0].field === field) {
            sortItem = [{ field: field, dir: 'desc' }];
        } else if (sort.length > 0 && sort[0].dir === 'desc' && sort[0].field === field) {
            sortItem = [];
        } else {
            sortItem = [{ field: field, dir: 'asc' }];
        }
        setSort(sortItem);
        SessionStorage.SetItem(ModuleKey, VehiclesGridSortDescriptorsStorageKey, JSON.stringify(sortItem));
    }

    const itemChange = (event: GridItemChangeEvent): void => {
        event.dataItem[event.field] = event.value;
        forceUpdate();
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            TransportPurchasesGridName,
            currentColumn.field,
            currentColumn.width as number);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, TransportPurchasesGridName, event.columns);
        forceUpdate();
    }

    const handleBilledTimeChange = (dataItem: PlanningVehicleLightModelExtended, field: string, event: TimePickerChangeEvent): void => {
        let eventTargetValue = null;
        if (event.target.value !== null) {
            eventTargetValue = new Date(event.target.value);
            const roundedMinutes = eventTargetValue.getMinutes() - eventTargetValue.getMinutes() % 15;
            eventTargetValue.setMinutes(roundedMinutes, 0)
        }
        dataItem.inEdit = field;
        dataItem.overridenBilledTime = eventTargetValue == null ? null : eventTargetValue.toCustomTimeString();
        let billableHours = dataItem.overridenBilledHours;
        if (eventTargetValue != null)
            billableHours = eventTargetValue.computeBilledHours();

        dataItem.overridenBilledHours = billableHours;

        dataItem.billedHours = dataItem.overridenBilledHours ? dataItem.overridenBilledHours : dataItem.expectedHoursBillableOnMainPrice;
        dataItem.billedTime = dataItem.overridenBilledTime ? dataItem.overridenBilledTime : dataItem.expectedTimeBillableOnMainPrice;
        dataItem.updating = true;
    }

    const handleChange = (dataItem: PlanningVehicleLightModelExtended): void => {
        props.onChangeHandler(dataItem);
    }

    const getSortedCostsPublished = (sort: SortDescriptor[]): PlanningVehicleLightModelExtended[] => {
        const costsAreNotAgreedList = props.planningVehiclesList.filter(x => x.costsArePublished && x.costsAreAgreed === false);
        const costsArePublishedList = props.planningVehiclesList.filter(x => x.costsArePublished && x.costsAreAgreed === null);
        const costsAreAgreedList = props.planningVehiclesList.filter(x => x.costsArePublished && x.costsAreAgreed);
        const costsAreNotPublishedList = props.planningVehiclesList.filter(x => !x.costsArePublished);

        if (sort[0].dir === "asc")
            return costsAreNotAgreedList.concat(costsArePublishedList).concat(costsAreAgreedList).concat(costsAreNotPublishedList);
        else if (sort[0].dir === "desc")
            return costsAreNotPublishedList.concat(costsAreAgreedList).concat(costsArePublishedList).concat(costsAreNotAgreedList);
        else
            return props.planningVehiclesList;
    }

    const { planningVehiclesList, driversList, selectedAll
        , getLabelPrice, cellRender, rowRender, selectionChange
        , headerSelectionChange, rowClick } = props;

    const _gridOffsetFromWindowTop = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight = SizingUtilities.computeGridHeight(_gridOffsetFromWindowTop);
    let gridPageSize = SizingUtilities.computeGridPageSize(gridHeight, SizingUtilities.rowHeight);
    const resize = (): void => {
        gridHeight = window.innerHeight - _gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / SizingUtilities.rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    let data: PlanningVehicleLightModelExtended[] = sort.find(x => x.field === "costsArePublished") ? getSortedCostsPublished(sort) : orderBy(planningVehiclesList, sort);
    if (props.showOnlyWithCostsInDisagreement || props.showOnlyCanceledVehicles) {
        data = data.filter(x =>
            (!props.showOnlyWithCostsInDisagreement || (props.showOnlyWithCostsInDisagreement && x.costsAreAgreed === false)) &&
            (!props.showOnlyCanceledVehicles || (props.showOnlyCanceledVehicles && x.isCanceled === true))
        );
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip ? event.page.skip : 0);
    }
    
    const nbElements = data.length;
    
    return (
        <Box id="vehicle-grid-id" className="vehicles-trip-grid">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Grid
                        className="vehicles-grid"
                        data={data}
                        sortable
                        reorderable
                        resizable
                        sort={sort}
                        selectedField="selected"
                        cellRender={cellRender}
                        rowRender={rowRender}
                        onItemChange={itemChange}
                        onRowClick={rowClick}
                        onColumnResize={onResizeHandler}
                        onColumnReorder={(e) => onReorderHandler(e)}
                        onSortChange={handleSortChange}
                        expandField="expanded"
                        editField="inEdit"
                        scrollable="virtual"
                        skip={skip}
                        //ODA charger plus d'élements pour éviter d'avoir des lignes vides dans l'affichage
                        pageSize={(gridPageSize * 2)}
                        total={nbElements}
                        onPageChange={pageChange}
                        rowHeight={40}
                        detailRowHeight={40}
                        style={{ height: gridHeight }}
                        detail={(_props) => <DetailComponent {..._props} inEditSelect={false} inEditInput={false} refreshPlanningVehicleWithDetails={props.refreshPlanningVehicleWithDetails} handleUpdatingPlanningVehicle={props.handleUpdatingPlanningVehicle}
                        handleChange={handleChange} getPriceKind={getLabelPrice} refreshPlanningVehicles={props.refreshPlanningVehicles} displayErrorsMessages={props.displayErrorsMessages} />}
                        //Nécessaire pour afficher le +
                        //Gérer par le cellRender dans le TransportPurchasesView
                        onExpandChange={() => { }}
                    >
                        <GridColumn field="selected" orderIndex={getVehicleGridOrderIndexColumn("selected", 0)} width={getVehicleGridWidth("selected", 50)}
                            headerCell={(props: GridHeaderCellProps) =>
                                <CustomCheckBoxCell
                                    field="selectedAll"
                                    selectedAll={selectedAll}
                                    _updateElements={headerSelectionChange}
                                />
                            }
                            cell={(props: GridCellProps) =>
                                <CustomCheckBoxCell
                                    dataItem={props.dataItem}
                                    field={props.field}
                                    _updateElements={selectionChange}
                                />} />
                        {
                        <GridColumn field="warning" orderIndex={getVehicleGridOrderIndexColumn("warning", 1)} width={getVehicleGridWidth("warning", 50)} title=" " editable={false}
                            cell={(properties: GridCellProps) => {
                                const dataItem = properties.dataItem as PlanningVehicleLightModelExtended;
                                return dataItem.updating 
                                    ? (<td className="pv-in-progress">
                                    <Tooltip title="En cours de modification" placement="bottom">
                                        <Box style={{ cursor: 'pointer' }}>
                                            <FontAwesomeIcon size="lg" icon={faSpinner} />
                                        </Box>
                                    </Tooltip>
                                </td>)
                                    : ((dataItem.mainPriceKind === VehiclePriceKind.PerTurn || dataItem.mainPriceKind === VehiclePriceKind.PerTon) 
                                        && dataItem.flowsWithInconsistentOverridesArray.length > 0)
                                        ? (<td className="pv-has-inconsistent-flow">
                                            <Tooltip title="Il existe une incohérence liée à la surcharge d'au moins un Flux" placement="bottom">
                                                <Box style={{ cursor: 'pointer' }}>
                                                    <FontAwesomeIcon size="lg" icon={faExclamationTriangle} />
                                                </Box>
                                            </Tooltip>
                                        </td>)
                                        : <td></td>}
                            }
                        />}
                        <GridColumn field="statusOrderValue" orderIndex={getVehicleGridOrderIndexColumn("statusOrderValue", 2)} width={getVehicleGridWidth("statusOrderValue", 50)} title="Statut" editable={false}
                            cell={(properties: GridCellProps) => <td>
                                <PlanningVehicleStatusComponent
                                    isCanceled={properties.dataItem.isCanceled}
                                    transporterConfirmationDate={properties.dataItem.orderConfirmationDate}
                                    transporterOrderId={properties.dataItem.transporterOrderId}
                                    transporterIsInternal={properties.dataItem.isInternal}
                                    cancellationReason={properties.dataItem.cancellationReason}
                                    cancellationRemarks={properties.dataItem.cancellationRemarks}
                                    purchaseIsCanceled={properties.dataItem.purchaseIsCanceled}
                                    saleIsCanceled={properties.dataItem.saleIsCanceled}
                                    cancellationOrderSentDate={properties.dataItem.cancellationOrderSentDate}
                                    cancellationOrderConfirmationDate={properties.dataItem.cancellationOrderConfirmationDate}
                                />
                            </td>}
                        />
                        <GridColumn field="licencePlate" orderIndex={getVehicleGridOrderIndexColumn("licencePlate", 3)} width={getVehicleGridWidth("licencePlate", 120)} title="Immatriculation" editable={false} />
                        <GridColumn field="equipmentId" orderIndex={getVehicleGridOrderIndexColumn("equipmentId", 4)} width={getVehicleGridWidth("equipmentId", 100)} title="IdGM" editable={false} />
                        <GridColumn field="vehicleNumber" orderIndex={getVehicleGridOrderIndexColumn("vehicleNumber", 5)} title="N°" editable={false} width={getVehicleGridWidth("vehicleNumber", 60)} />
                        <GridColumn field="transporter" orderIndex={getVehicleGridOrderIndexColumn("transporter", 6)} width={getVehicleGridWidth("transporter", 100)} title="Transporteur" editable={false} />
                        <GridColumn field="displayDate" orderIndex={getVehicleGridOrderIndexColumn("displayDate", 7)} width={getVehicleGridWidth("displayDate", 100)} title="Date" format="{0:dd-MM-yyyy}" editable={false} />
                        <GridColumn field="confirmationNumber" orderIndex={getVehicleGridOrderIndexColumn("confirmationNumber", 8)} width={getVehicleGridWidth("confirmationNumber", 100)} title="Bon confirmation" editable={false} />
                        <GridColumn field="sendingDate" orderIndex={getVehicleGridOrderIndexColumn("sendingDate", 9)} width={getVehicleGridWidth("sendingDate", 100)} title="Envoi BC" format="{0:dd-MM-yyyy HH:mm}" editable={false} />
                        <GridColumn field="expectedVehicleTypeLabel" orderIndex={getVehicleGridOrderIndexColumn("expectedVehicleTypeLabel", 10)} width={getVehicleGridWidth("expectedVehicleTypeLabel", 100)} title="Type véhicule" editable={false} />
                        <GridColumn field="priceKindName" orderIndex={getVehicleGridOrderIndexColumn("priceKindName", 11)} width={getVehicleGridWidth("priceKindName", 100)} title="Type tarif"
                            headerCell={() => <EditableHeaderComponent field="priceKindName" sort={sort} _handleSortEditableColumnChange={handleSortEditableColumnChange} title="Type tarif" />} editable={true}
                            cell={(_props: GridCellProps) => <CustomPriceKindCell {..._props}
                                field={"mainPriceKind"} handleChange={() => handleChange(_props.dataItem)} getPriceKind={getLabelPrice} />} />
                        <GridColumn field="totalPurchasePrice" orderIndex={getVehicleGridOrderIndexColumn("totalPurchasePrice", 12)} width={getVehicleGridWidth("totalPurchasePrice", 100)} title="Coût"
                            headerCell={() => <EditableHeaderComponent field="totalPurchasePrice" sort={sort} _handleSortEditableColumnChange={handleSortEditableColumnChange} title="Coût" />} editable={true}
                            cell={(props: GridCellProps) => <CustomPricesCell {...props} vehicle={props.dataItem} />} />
                        <GridColumn field="totalFees" orderIndex={getVehicleGridOrderIndexColumn("totalFees", 13)} width={getVehicleGridWidth("totalFees", 100)} title="Frais supp" editable={false}
                            cell={(props: GridCellProps) => <td>
                                {props.dataItem.totalFees ? props.dataItem.totalFees + ' €' : ''}
                            </td>}
                        />
                        <GridColumn field="totalPurchasePriceWithFees" orderIndex={getVehicleGridOrderIndexColumn("totalPurchasePriceWithFees", 14)} width={getVehicleGridWidth("totalPurchasePriceWithFees", 100)} title="Coût total" editable={false} editor="numeric"
                            cell={(props: GridCellProps) => {
                            const dataItem = props.dataItem as PlanningVehicleLightModelExtended;
                            const className = `quantities-used-${!dataItem.kindOfValuesUsedForPrice
                                ? "old"
                                : dataItem.kindOfValuesUsedForPrice === PurchaseCostUsedQuantitiesKindEnum.MixOfPlannedAndDelivered
                                ? "mixed"
                                : dataItem.kindOfValuesUsedForPrice === PurchaseCostUsedQuantitiesKindEnum.Planned
                                    ? "planned"
                                    : "delivered"}`;
                            return (<td className={className}>
                                {props.dataItem.totalPurchasePriceWithFees ? props.dataItem.totalPurchasePriceWithFees + ' €' : ''}
                            </td>)}
                            }
                        />
                        <GridColumn field="driverName" orderIndex={getVehicleGridOrderIndexColumn("driverName", 15)} width={getVehicleGridWidth("driverName", 100)} title="Chauffeur"
                            headerCell={() => <EditableHeaderComponent field="driverName" sort={sort} _handleSortEditableColumnChange={handleSortEditableColumnChange} title="Chauffeur" />} editable={true}
                            cell={(props: GridCellProps) => <CustomDriverCell {...props} driversList={driversList} />} />
                        <GridColumn field="driverPhone" orderIndex={getVehicleGridOrderIndexColumn("driverPhone", 16)} width={getVehicleGridWidth("driverPhone", 100)} title="Tel"
                            editable={false} cell={(props: GridCellProps) => <PhoneNumberCell {...props} />} />
                        <GridColumn field="expectedStartTime" orderIndex={getVehicleGridOrderIndexColumn("expectedStartTime", 17)} width={getVehicleGridWidth("expectedStartTime", 100)} format="{0:HH:mm}" title="Heure début prévue" editable={false} />
                        <GridColumn field="expectedEndTime" orderIndex={getVehicleGridOrderIndexColumn("expectedEndTime", 18)} width={getVehicleGridWidth("expectedEndTime", 100)} title="Heure fin prévue" format="{0:HH:mm}" editable={false} />
                        <GridColumn field="billedTime" orderIndex={getVehicleGridOrderIndexColumn("billedTime", 19)} width={getVehicleGridWidth("billedTime", 100)}
                            headerCell={() => <EditableHeaderComponent field="billedTime" sort={sort} _handleSortEditableColumnChange={handleSortEditableColumnChange} title="Tps à facturer" />} editable={true}
                            cell={(p: GridCellProps) => <CustomBilledTimeCell {...p} handleChange={() => props.handleBilledTimeChange(p.dataItem)} handleBilledTimeChange={handleBilledTimeChange} />} />
                        <GridColumn field="deliveredQuantity" orderIndex={getVehicleGridOrderIndexColumn("deliveredQuantity", 20)} width={getVehicleGridWidth("deliveredQuantity", 100)} title="Qté livrée" editable={false} />
                        <GridColumn field="transportFlowCount" orderIndex={getVehicleGridOrderIndexColumn("transportFlowCount", 21)} width={getVehicleGridWidth("transportFlowCount", 100)} title="Nb missions" editable={false} />
                        <GridColumn field="internalExternalLabel" orderIndex={getVehicleGridOrderIndexColumn("internalExternalLabel", 22)} width={getVehicleGridWidth("internalExternalLabel", 100)} title="Int/Ext" editable={false} />
                        <GridColumn field="isNightWork" orderIndex={getVehicleGridOrderIndexColumn("isNightWork", 23)} width={getVehicleGridWidth("isNightWork", 100)} title="Nuit" editable={false}
                            cell={(props: GridCellProps) => <CustomIsNightCell {...props} />} />
                        <GridColumn field="remarks" orderIndex={getVehicleGridOrderIndexColumn("remarks", 24)} width={getVehicleGridWidth("remarks", 300)} title="Commentaire camion"
                            headerCell={() => <EditableHeaderComponent field="remarks" sort={sort} _handleSortEditableColumnChange={handleSortEditableColumnChange} title="Commentaire camion" />} editable={true}
                            cell={(props: GridCellProps) => <RemarksCell {...props} width={getVehicleGridWidth("remarks", 340)} />} />
                        <GridColumn field="costsRemarksOfLogistician" orderIndex={getVehicleGridOrderIndexColumn("costsRemarksOfLogistician", 25)} width={getVehicleGridWidth("costsRemarksOfLogistician", 300)} title="Commentaire préfac"
                            headerCell={() => <EditableHeaderComponent field="costsRemarksOfLogistician" sort={sort} _handleSortEditableColumnChange={handleSortEditableColumnChange} title="Commentaire préfac" />} editable={true}
                            cell={(props: GridCellProps) => <RemarksCell {...props} width={getVehicleGridWidth("costsRemarksOfLogistician", 340)} />} />
                        <GridColumn field="buyerLogisticsUnitLabel" orderIndex={getVehicleGridOrderIndexColumn("buyerLogisticsUnitLabel", 26)} width={getVehicleGridWidth("buyerLogisticsUnitLabel", 100)} title="Zone logistique" editable={false} />
                        <GridColumn field="costsArePublished" orderIndex={getVehicleGridOrderIndexColumn("costsArePublished", 27)} width={getVehicleGridWidth("costsArePublished", 100)} title="Préfac" editable={false}
                            cell={(props: GridCellProps) => <CustomCostsPublishedCell {...props} />} />
                        <GridColumn field="planningDate" orderIndex={getVehicleGridOrderIndexColumn("planningDate", 28)} width={getVehicleGridWidth("planningDate", 35)} title="D" format="{0:dd-MM-yyyy}" editable={false} headerClassName="hide-title-column" className="hide-title-column" resizable={false} />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}