import { Divider } from '@mui/material';
import React from 'react';
import { MarginsModel } from '../models/MarginsModel';

interface TransportMarginsProperties {
    transportMargins: MarginsModel
}

export const TransportMarginsComponent = (props: TransportMarginsProperties): JSX.Element => {

    return (
        <div className="transport-margin-container">
            <div className='table-title table-row'>
                Rentabilité
            </div>
            <Divider className='divider-title' />
            <div>
                <div className='table-row'>
                    <span className='title'>Marge brute :</span>
                    <span className={`value ${props.transportMargins.grossMargin < 0 ? 'value-negatif-marge' : ''}`}>{props.transportMargins.grossMargin?.toCurrencyString()}</span>
                </div>
                <div className='table-row'>
                    <span className='title'>Marge nette :</span>
                    <span className={`value ${props.transportMargins.netMargin < 0 ? 'value-negatif-marge' : ''}`}>
                        {props.transportMargins.netMargin?.toCurrencyString()}
                    </span>
                </div>
                <div className='table-row'>
                    <span className='title'>% charge :</span>
                    <span className={'value value-negatif-marge'}>{props.transportMargins.averageLoadUtilizationRate} %</span>
                </div>
            </div>
        </div>
    );
}