import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import { DeliveryTripLightModelExtended } from '../models/DeliveryTripLightModelExtended';

interface CustomDeliveryNoteState {
    dataValue: string;
}

export default class CustomDeliveryNoteCellComponent extends React.Component<GridCellProps, CustomDeliveryNoteState> {
    constructor(props: GridCellProps) {
        super(props);
        this.state = {
            dataValue: this.props.dataItem[this.props.field]
        }
    }

    render() {
        const dataItem: DeliveryTripLightModelExtended = this.props.dataItem;
        const dataValue: string = dataItem[this.props.field];
        const deparatureTime: string = new Date(dataItem.deliveryNoteDeparatureTime).toShortDateTimeString()

        let defaultRendering: JSX.Element = null;
        if (dataValue != null) {
            defaultRendering = (
                <td>
                    <Tooltip
                        title={
                            <React.Fragment>
                                <Typography color="inherit">Id BL : {dataValue}</Typography>
                                <Typography color="inherit">Date Départ :{deparatureTime}</Typography>
                            </React.Fragment>
                        }
                    >
                        <CheckCircleIcon style={{ color: 'green' }} />
                    </Tooltip>
                </td>
            );

        } else {
            defaultRendering = (
                <td></td>
            );
        }
        return this.props.render ? this.props.render.call(undefined, defaultRendering, this.props) : defaultRendering;
    }
}