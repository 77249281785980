import { CoatingApplicationMode } from "../../shared/Quotation/services/dataContracts/CoatingApplicationMode";
import { PayerSiteOrigin } from "../../shared/Quotation/services/dataContracts/PayerSiteOrigin";
import { PriceGridKind } from "../../shared/Quotation/services/dataContracts/PriceGridKind";
import { SiteEntityType } from "../../shared/Quotation/services/dataContracts/SiteEntityType";
import { TransportBillingMode } from "../../shared/Quotation/services/dataContracts/TransportBillingMode";
import { TransportPriority } from "../../shared/Quotation/services/dataContracts/TransportPriority";
import { TransportServiceKind } from "../../shared/Quotation/services/dataContracts/TransportServiceKind";
import { CoatingApplicationModeEnum } from "./models/CoatingApplicationModeEnum";
import { PayerSiteOriginEnum } from "./models/PayerSiteOriginEnum";
import { PriceGridKindEnum } from "./models/PriceGridKindEnum";
import { SiteEntityTypeEnum } from "./models/SiteEntityTypeEnum";
import { TransportBillingModeEnum } from "./models/TransportBillingModeEnum";
import { TransportPriorityEnum } from "./models/TransportPriorityEnum";
import { TransportServiceKindEnum } from "./models/TransportServiceKindEnum";

export default class ModelMappings {

    public static mapCoatingApplicationMode = (coatingApplicationMode: CoatingApplicationModeEnum): CoatingApplicationMode => {
        switch (coatingApplicationMode) {
            case CoatingApplicationModeEnum.Manual:
                return CoatingApplicationMode.manual;

            case CoatingApplicationModeEnum.Mechanical:
                return CoatingApplicationMode.mechanical;

            case CoatingApplicationModeEnum.Mixed:
                return CoatingApplicationMode.mixed;

            case CoatingApplicationModeEnum.NotApplicable:
                return CoatingApplicationMode.notApplicable;

            default:
                return null;
        }
    }

    public static mapPriceGridKind = (priceGridKind: PriceGridKindEnum): PriceGridKind => {
        switch (priceGridKind) {
            case PriceGridKindEnum.PerTimePeriod:
                return PriceGridKind.perTimePeriod;

            case PriceGridKindEnum.PerTon:
                return PriceGridKind.perTon;

            case PriceGridKindEnum.PerTurn:
                return PriceGridKind.perTurn;

            default:
                return null;
        }
    }

    public static mapQuotationPriceGridKind = (priceGridKind: PriceGridKind): PriceGridKindEnum => {
        switch (priceGridKind) {
            case PriceGridKind.perTimePeriod:
                return PriceGridKindEnum.PerTimePeriod;

            case PriceGridKind.perTon:
                return PriceGridKindEnum.PerTon;

            case PriceGridKind.perTurn:
                return PriceGridKindEnum.PerTurn;

            default:
                return null;
        }
    }

    public static mapQuotationPayerSiteOrigin = (payerSiteOrigin: PayerSiteOrigin): PayerSiteOriginEnum => {
        switch (payerSiteOrigin) {
            case PayerSiteOrigin.receiver:
                return PayerSiteOriginEnum.Receiver;

            case PayerSiteOrigin.sender:
                return PayerSiteOriginEnum.Sender;

            default:
                return null;
        }
    }

    public static mapTransportBillingMode = (transportBillingMode: TransportBillingModeEnum): TransportBillingMode => {
        switch (transportBillingMode) {
            case TransportBillingModeEnum.Departure:
                return TransportBillingMode.departure;

            case TransportBillingModeEnum.Inclusive:
                return TransportBillingMode.inclusive;

            default:
                return null;
        }
    }

    public static mapTransportPriority = (transportPriority: TransportPriorityEnum): TransportPriority => {
        switch (transportPriority) {
            case TransportPriorityEnum.high:
                return TransportPriority.high;

            case TransportPriorityEnum.low:
                return TransportPriority.low;

            case TransportPriorityEnum.medium:
                return TransportPriority.medium;

            default:
                return null;
        }
    }

    public static mapTransportServiceKind = (transportServiceKind: TransportServiceKindEnum): TransportServiceKind => {
        switch (transportServiceKind) {
            case TransportServiceKindEnum.delivery:
                return TransportServiceKind.delivery;

            case TransportServiceKindEnum.jobsiteVehicle:
                return TransportServiceKind.jobsiteVehicle;

            case TransportServiceKindEnum.removal:
                return TransportServiceKind.removal;

            default:
                return null;
        }
    }

    public static mapQuotationSiteEntityType = (siteEntityType: SiteEntityType): SiteEntityTypeEnum => {
        switch (siteEntityType) {
            case SiteEntityType.agency:
                return SiteEntityTypeEnum.Agency;

            case SiteEntityType.customer:
                return SiteEntityTypeEnum.Customer;

            case SiteEntityType.jobSite:
                return SiteEntityTypeEnum.JobSite;

            case SiteEntityType.productionSite:
                return SiteEntityTypeEnum.ProductionSite;

            case SiteEntityType.supplier:
                return SiteEntityTypeEnum.Supplier;

            case SiteEntityType.unreferencedCustomer:
                return SiteEntityTypeEnum.UnreferencedCustomer;

            default:
                return null;
        }
    }

}