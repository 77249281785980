import { TransporterOrderLineSeenByTransporterLightModelExtended } from "../../ThirdPartyTransporter/models/TransporterOrderLineSeenByTransporterLightModelExtended";

export const ThirdPartyTransporterPrintDataProperty = 'ThirdPartyTransporterPrintData';

export interface ThirdPartyTransporterPrintData {
    transporter: string,
    date: Date,
    isForInternalTransportes: boolean,
    requestToGenerateFile: boolean,
    lines: Array<TransporterOrderLineSeenByTransporterLightModelExtended>
}