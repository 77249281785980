import axios, { AxiosResponse } from "axios";
import { WebAppActionResult } from "src/shared/models/WebAppActionResult";
import { AddCnrGasoilIndexRequestArgs } from './dataContracts/controller/AddCnrGasoilIndexRequestArgs';
import { AddDieselIndexRequestArgs } from "./dataContracts/controller/AddDieselIndexRequestArgs";
import { AddDieselIndexTemplateRequestArgs } from "./dataContracts/controller/AddDieselIndexTemplateRequestArgs";
import { RemoveDieselIndexesRequestArgs } from "./dataContracts/controller/RemoveDieselIndexesRequestArgs";
import { UpdateCnrGasoilIndexRequestArgs } from './dataContracts/controller/UpdateCnrGasoilIndexRequestArgs';
import { UpdateDieselIndexTemplateRequestArgs } from "./dataContracts/controller/UpdateDieselIndexTemplateRequestArgs";
import { CnrGasoilIndexLightModel } from './dataContracts/queryStack/CnrGasoilIndexLightModel';
import { DieselIndexLightModel } from "./dataContracts/queryStack/DieselIndexLightModel";
import { DieselIndexTemplateLightModel } from "./dataContracts/queryStack/DieselIndexTemplateLightModel";
import { TransporterLightModel } from "./dataContracts/queryStack/TransporterLightModel";

const controllerUrl = 'api/DieselIndexesReferential/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export class DieselIndexesReferentialApiClient {

    public static GetDieselIndexTemplates = (logisticsUnitId: string, startDate: Date, endDate: Date)
        : Promise<AxiosResponse<Array<DieselIndexTemplateLightModel>>> => {
        return axios.get(`${controllerUrl}GetDieselIndexTemplates?logisticsUnitId=${logisticsUnitId}&startDate=${encodeURIComponent(startDate.toJSON())}&endDate=${encodeURIComponent(endDate.toJSON())}`);
    }

    public static GetDieselIndexes = (logisticsUnitId: string, inputSearchText: string, startDate: Date, endDate: Date, onlyChosenLogisticsUnit: boolean)
        : Promise<AxiosResponse<Array<DieselIndexLightModel>>> => {
        return axios.get(`${controllerUrl}GetDieselIndexes?logisticsUnitId=${logisticsUnitId}&searchText=${encodeURIComponent(inputSearchText)}&onlyChosen=${onlyChosenLogisticsUnit}&startDate=${encodeURIComponent(startDate.toJSON())}&endDate=${encodeURIComponent(endDate.toJSON())}`);
    }

    public static GetForeignDieselIndexTemplates = (selectedLogisticsUnitId: string, startDate: Date, endDate: Date)
        : Promise<AxiosResponse<Array<DieselIndexTemplateLightModel>>> => {
        return axios.get(`${controllerUrl}GetForeignDieselIndexTemplates?selectedLogisticsUnitId=${selectedLogisticsUnitId}&startDate=${encodeURIComponent(startDate.toJSON())}&endDate=${encodeURIComponent(endDate.toJSON())}`);
    }

    public static GetVehicleTypeGroups = ()
        : Promise<AxiosResponse<Array<string>>> => {
        return axios.get(`${controllerUrl}GetVehicleTypeGroups`);
    }

    public static GetTransporters = (logisticsUnitId: string, onlyChosenLogisticsUnitTransporters: boolean)
        : Promise<AxiosResponse<Array<TransporterLightModel>>> => {
        return axios.get(`${controllerUrl}GetTransporters?logisticsUnitId=${logisticsUnitId}&onlyChosen=${onlyChosenLogisticsUnitTransporters}`);
    }

    public static GetDieselIndexTemplatesWithDieselIndexesAndVehicleTypeGroupsAndTransporters(logisticsUnitId: string, startDate: Date, endDate: Date, inputSearchDieselIndexText: string, onlyChosenLogisticsUnitTransporters: boolean, onlyChosenLogisticsUnitDieselIndexes: boolean)
        : Promise<[AxiosResponse<Array<DieselIndexTemplateLightModel>>, AxiosResponse<Array<DieselIndexLightModel>>, AxiosResponse<Array<string>>, AxiosResponse<Array<TransporterLightModel>>]> {
        return Promise.all([
            this.GetDieselIndexTemplates(logisticsUnitId, startDate, endDate),
            this.GetDieselIndexes(logisticsUnitId, inputSearchDieselIndexText, startDate, endDate, onlyChosenLogisticsUnitDieselIndexes),
            this.GetVehicleTypeGroups(),
            this.GetTransporters(logisticsUnitId, onlyChosenLogisticsUnitTransporters)
        ]);
    }

    public static GetDieselIndexesWithTransporters(logisticsUnitId: string, startDate: Date, endDate: Date, inputSearchDieselIndexText: string, onlyChosenLogisticsUnitTransporters: boolean, onlyChosenLogisticsUnitDieselIndexes: boolean)
        : Promise<[AxiosResponse<Array<DieselIndexLightModel>>, AxiosResponse<Array<TransporterLightModel>>]> {
        return Promise.all([
            this.GetDieselIndexes(logisticsUnitId, inputSearchDieselIndexText, startDate, endDate, onlyChosenLogisticsUnitDieselIndexes),
            this.GetTransporters(logisticsUnitId, onlyChosenLogisticsUnitTransporters)
        ]);
    }

    public static GetDieselIndexTemplatesWithDieselIndexes(logisticsUnitId: string, startDate: Date, endDate: Date, inputSearchDieselIndexText: string, onlyChosenLogisticsUnit: boolean)
        : Promise<[AxiosResponse<Array<DieselIndexTemplateLightModel>>, AxiosResponse<Array<DieselIndexLightModel>>]> {
        return Promise.all([
            this.GetDieselIndexTemplates(logisticsUnitId, startDate, endDate),
            this.GetDieselIndexes(logisticsUnitId, inputSearchDieselIndexText, startDate, endDate, onlyChosenLogisticsUnit)
        ]);
    }

    public static GetDieselIndexTemplatesWithDieselIndexesAndTransporters(logisticsUnitId: string, startDate: Date, endDate: Date, inputSearchDieselIndexText: string, onlyChosenLogisticsUnitTransporters: boolean, onlyChosenLogisticsUnit: boolean)
        : Promise<[AxiosResponse<Array<DieselIndexLightModel>>, AxiosResponse<Array<DieselIndexTemplateLightModel>>, AxiosResponse<Array<TransporterLightModel>>]> {
        return Promise.all([
            this.GetDieselIndexes(logisticsUnitId, inputSearchDieselIndexText, startDate, endDate, onlyChosenLogisticsUnit),
            this.GetDieselIndexTemplates(logisticsUnitId, startDate, endDate),
            this.GetTransporters(logisticsUnitId, onlyChosenLogisticsUnitTransporters)
        ]);
    }

    public static GetTransportersHavingDieselIndexes = (selectedLogisticsUnitId: string, startDate: Date, endDate: Date)
        : Promise<AxiosResponse<Array<string>>> => {
        return axios.get(`${controllerUrl}GetTransportersHavingDieselIndexes?logisticsUnitId=${selectedLogisticsUnitId}&startDate=${encodeURIComponent(startDate.toJSON())}&endDate=${encodeURIComponent(endDate.toJSON())}`);
    }

    public static AddDieselIndexTemplate = (requestArgs: AddDieselIndexTemplateRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {

        const url = controllerUrl + "AddDieselIndexTemplate";
        return axios.post(url, requestArgs, { headers: headers });
    }

    public static AddDieselIndexTemplates = (listRequestArgs: Array<AddDieselIndexTemplateRequestArgs>)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const url = controllerUrl + "AddDieselIndexTemplates";
        return axios.post(url, listRequestArgs, { headers: headers });
    }

    public static UpdateDieselIndexTemplate = (requestArgs: UpdateDieselIndexTemplateRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const url = controllerUrl + "UpdateDieselIndexTemplate";
        return axios.post(url, requestArgs, { headers: headers });
    }

    public static RemoveDieselIndexTemplate = (dieselIndexTemplateId: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const url = `${controllerUrl}RemoveDieselIndexTemplate?dieselIndexTemplateId=${encodeURIComponent(dieselIndexTemplateId)}`;
        return axios.post(url);
    }

    public static RemoveDieselIndexes = (dieselIndexIds: Array<string>)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const requestArgs: RemoveDieselIndexesRequestArgs = {
            dieselIndexIds: dieselIndexIds
        }
        const url = `${controllerUrl}RemoveDieselIndexes`;
        return axios.post(url, requestArgs, { headers: headers });
    }

    public static AddDieselIndexes = (requestArgs: AddDieselIndexRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const url = controllerUrl + "AddDieselIndexes";
        return axios.post(url, requestArgs, { headers: headers });
    }

    public static GetCnrGasoilIndexes = (startDate: Date, endDate: Date)
        : Promise<AxiosResponse<Array<CnrGasoilIndexLightModel>>> => {
        return axios.get(`${controllerUrl}GetCnrGasoilIndexes?startDate=${encodeURIComponent(startDate.toJSON())}&endDate=${encodeURIComponent(endDate.toJSON())}`);
    }

    public static AddCnrGasoilIndex = (requestArgs: AddCnrGasoilIndexRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const url = controllerUrl + "AddCnrGasoilIndex";
        return axios.post(url, requestArgs, { headers: headers });
    }

    public static UpdateCnrGasoilIndex = (requestArgs: UpdateCnrGasoilIndexRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const url = controllerUrl + "UpdateCnrGasoilIndex";
        return axios.post(url, requestArgs, { headers: headers });
    }

    public static RemoveCnrGasoilIndex = (cnrGasoilIndexId: number)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const url = `${controllerUrl}RemoveCnrGasoilIndex?cnrGasoilIndexId=${encodeURIComponent(cnrGasoilIndexId)}`;
        return axios.post(url, { headers: headers });
    }
}