export const enumValues = new Map([
    ['TransportRequestStatus.Pending','En attente'],
    ['TransportRequestStatus.Approved','Validé'],
    ['TransportRequestStatus.ProductionPlanned','Planifié'],
    ['TransportRequestStatus.ProductionPlanPublished','Publié'],
    ['TransportRequestStatus.Rejected','Rejeté'],
    ['SourceApplication.Planeo', 'Planeo'],
    ['SourceApplication.EPlanning', 'EPlanning'],
    ['SourceApplication.Lorie', 'Lorie'],
    ['SourceApplication.Zephyr', 'Zephyr'],
    ['SourceApplication.EDispatch', 'EDispatch'],
    ['CoatingApplicationMode.Manual', 'Manuel'],
    ['CoatingApplicationMode.Mechanical', 'Mécanique'],
    ['CoatingApplicationMode.Mixed', 'Mixte'],
    ['CoatingApplicationMode.NotApplicable', 'Non applicable'],
    ['DayPeriod.Day', 'Journée'],
    ['DayPeriod.HalfDay', 'Demi-journée'],
    ['DayPeriod.Night', 'Nuit'],
    ['DayPeriod.Morning', 'Matin'],
    ['DayPeriod.Afternoon', 'Après-midi'],
    ['DayPeriod.Evening', 'Soir'],
    ['TransportPriority.High', 'Fixe'],
    ['TransportPriority.Medium', 'Journée'],
    ['TransportPriority.Low', 'Modulable'],
    ['TransportServiceKind.Delivery', 'Livraison'],
    ['TransportServiceKind.Removal', 'Enlèvement'],
    ['TransportServiceKind.JobsiteVehicle', 'Camion de chantier'],
    
]);