import { Box, Card, CardContent, Checkbox } from '@mui/material';
import React, { useState } from 'react';
import { DieselIndexTemplateLightModel } from '../services/dataContracts/queryStack/DieselIndexTemplateLightModel';

interface ForeignDieselIndexTemplatesProperties {
    dieselIndexTemplate: DieselIndexTemplateLightModel,
    HandleRefreshDuplicateTemplateIds: (template: DieselIndexTemplateLightModel, toAdd: boolean) => void
}

export const ForeignDieselIndexTemplateComponent = (props: ForeignDieselIndexTemplatesProperties) => {
    const [templateSelected, setTemplateSelected] = useState<boolean>(false);

    const GetTemplateWeighting = (): Array<JSX.Element> => {
        const content = [];
        const vehicleTypeGroupsWeightings = props.dieselIndexTemplate.weightings;
        const vehicleTypeGroupsWeightingsLength = vehicleTypeGroupsWeightings.length;

        let currentIndex = 0;
        let lineIndex = 0;

        while (currentIndex < vehicleTypeGroupsWeightingsLength) {
            const elemFirst = GetLabelElement(vehicleTypeGroupsWeightings[currentIndex].vehicleTypeGroupId, vehicleTypeGroupsWeightings[currentIndex].weightingValue, currentIndex);
            currentIndex++;
            const elemSecond = currentIndex == vehicleTypeGroupsWeightingsLength ? <></> : GetLabelElement(vehicleTypeGroupsWeightings[currentIndex].vehicleTypeGroupId, vehicleTypeGroupsWeightings[currentIndex].weightingValue, currentIndex);
            currentIndex++;
            lineIndex++;
            content.push(<Box display="flex" flexDirection="row" className="weighting-line" key={`line-card-${lineIndex}`} pb={1}>{elemFirst}{elemSecond}</Box>);
        }

        return content;
    }

    const GetLabelElement = (label: string, value: number, index: number): JSX.Element => {
        return (<Box width="40%" display="flex" flexDirection="row" alignItems="center" paddingLeft={`${index % 2 == 0 ? '0em' : '1em'}`}>
            <Box className="weighting-title">
                {label} : &nbsp;
            </Box>
            <Box className="template-weighting-label-value">
                {`${(value * 100).toStringFixed("FR-fr")} %`}
            </Box>
        </Box>);
    }

    const HandleSelectedTemplate = (): void => {
        const selected = !templateSelected;
        setTemplateSelected(selected);
        props.HandleRefreshDuplicateTemplateIds(props.dieselIndexTemplate, selected);
    }

    return (
        <Card className="template-card-duplicate">
            <CardContent className="template-content">
                <div className="template-header">
                    <div className="template-label template-title">
                        {props.dieselIndexTemplate.label}
                    </div>
                    <div className="template-checkBox">
                        <Checkbox
                            checked={templateSelected}
                            onChange={HandleSelectedTemplate}
                        />
                    </div>
                </div>
                <div>
                    {`${Date.getDateFromIsoString(props.dieselIndexTemplate.startDate)} au ${Date.getDateFromIsoString(props.dieselIndexTemplate.endDate)}`}
                </div>
                <div className="template-reference">
                    <span className="template-title template-reference-title">C0 : </span>
                    {`${props.dieselIndexTemplate.referenceValue.toStringFixed("FR-fr", 4)}`}
                </div>
                <div className="template-title">
                    Pondération / K
                </div>
                {
                    GetTemplateWeighting().map(elemLine => (
                        elemLine
                    ))
                }
            </CardContent>
        </Card>
    );
}