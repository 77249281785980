import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, TextField } from '@mui/material';
import { AuthenticationType, data, layer, Map, MapMouseEvent, Shape, source } from 'azure-maps-control';
import { cloneDeep, debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { SettingsProvider } from 'src/SettingsProvider';
import { Address, GpsCoordinates, MapsService } from 'src/shared/MapsService/MapsService';
import Utilities from 'src/utils/Utilities';
import { UpdateExternalTransporterRequestArgs } from '../services/dataContracts/controller/UpdateExternalTransporterRequestArgs';

import AsyncSelect from 'react-select/async';
import { MapMarkerButton } from 'src/shared/components/Common/MapMarkerButton';
import { TransportPurchaseLightModelExtended } from '../models/TransportPurchaseLightModelExtended';

interface EditTransporterDialogComponentProps {
    transporterSelected: TransportPurchaseLightModelExtended,
    handleClose: () => void,
    handleEditTransporterClick: (requestArgs: UpdateExternalTransporterRequestArgs) => void
}

interface InputActionMeta {
    action: string
}

interface AddressModel {
    freeFormAddress?: string,
    line1?: string,
    line2?: string,
    zipCode?: string,
    city?: string,
    country?: string,
}

interface SelectInputItemModel {
    label: string,
    value: string,
    disabled?: boolean,
    logisticsUnitId?: string,
    phoneNumber?: string,
    freeFormAddress?: string,
    line1?: string,
    line2?: string,
    zipCode?: string,
    city?: string,
    country?: string,
    latitude?: number,
    longitude?: number,
    agencyId?: string
}

interface Email {
    email: string,
    isValid: boolean
}


export const EditTransporterDialogComponent = (props: EditTransporterDialogComponentProps): JSX.Element => {
    const [transporter, setTransporter] = useState<TransportPurchaseLightModelExtended>({...props.transporterSelected});
    const [emails, setEmails] = useState<Array<Email>>(props.transporterSelected.managerEmail ? props.transporterSelected.managerEmail.split(',').map(email => ({email, isValid: Utilities.validateEmailAddress(email)})) : [{email: '', isValid: false}]);
    const [isTransporterChanged, setIsTransporterChanged] = useState<boolean>(false);
    const [myMap, setMyMap] = useState<Map>(null);
    const [clickedMapPosition, setClickedMapPosition] = useState<GpsCoordinates>(null);
    const [isMapMarkerActive, setIsMapMarkerActive] = useState<boolean>(false);
    const [address, setAddress] = useState<AddressModel>({ freeFormAddress: null, line1: null, zipCode: null, city: null });
    const [coordinates, setCoordinates] = useState<GpsCoordinates>(null);
    const [failedAddress, setFailedAddress] = useState<boolean>(false);
    const [addressInputValue, setAddressInputValue] = useState<string>("");
    const [isAddressInputLoading, setIsAddressInputLoading] = useState<boolean>(false);

    const mapDataSourceId = "mapDataSourceId";
    const pinId = "pinId";

    useEffect(() => {
        initialiseMap();
    }, []);

    useEffect(() => {
        const coordinates: GpsCoordinates = { latitude: clickedMapPosition?.latitude, longitude: clickedMapPosition?.longitude };

        if (isMapMarkerActive) {
            setMapPin(coordinates, pinId);
            setIsMapMarkerActive(false);

            MapsService.GetAddressFromCoordinates(coordinates)
                .then((res: Address) => {
                    setAddress({ freeFormAddress: res.freeformAddress, line1: res.line1, line2: null, zipCode: res.zipCode, city: res.city, country: res.country });
                    if (failedAddress)
                        setFailedAddress(false);
                });
        }

    }, [clickedMapPosition]);

    useEffect(() => {
        setIsTransporterChanged(hasTransporterChanged(transporter));
        if (address && address.freeFormAddress)
            setAddressInputValue(address.freeFormAddress);
        else
            setAddressInputValue("");
    }, [address]);

    useEffect(() => {
        setIsTransporterChanged(hasTransporterChanged(transporter));
        if (coordinates?.latitude && coordinates?.longitude && myMap) {
            setMapPin(coordinates, pinId);
        }
    }, [coordinates]);

    const initialiseMap = (): void => {
        const map: Map = createMap(4, { latitude: 47.0853, longitude: 2.3945 });

        map.events.add('ready', function () {
            map.resize();
            const dataSource: source.DataSource = new source.DataSource(mapDataSourceId);
            map.sources.add(dataSource);

            map.events.add('click', handleMapClick);

            setMapWithCoordinates(transporter);
        });

        setMyMap(map);
    }
    
    const createMap = (zoom: number, center?: GpsCoordinates): Map => {
        return new Map('myMap', {
            zoom: zoom,
            center: center ? [center.longitude, center.latitude] : null,
            view: 'Auto',
            authOptions: {
                authType: AuthenticationType.subscriptionKey,
                subscriptionKey: SettingsProvider.Get().azureMapsApiKey
            },
        });
    }

    const handleChangeField = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, nameField: string): void => {
        const newTransporter = {...transporter};
        newTransporter[nameField] = event.target.value;

        setTransporter(newTransporter);
        setIsTransporterChanged(hasTransporterChanged(newTransporter));
    }
    
    const handleChangeEmail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number): void => {
        const newEmails = [...emails];

        newEmails[index].email = event.target.value;
        newEmails[index].isValid = Utilities.validateEmailAddress(event.target.value);

        setEmails(newEmails);

        const newTransporter = {...transporter};
        newTransporter.managerEmail = newEmails.map(x => x.email).join(',');
        setTransporter(newTransporter);
        setIsTransporterChanged(hasTransporterChanged(newTransporter));
    }

    const removeEmail = (index: number): void => {
        const emailsClone: Email[] = cloneDeep(emails);

        emailsClone.splice(index, 1);

        setEmails(emailsClone);

        const newTransporter = {...transporter};
        newTransporter.managerEmail = emailsClone.map(x => x.email).join(',');
        setTransporter(newTransporter);
        setIsTransporterChanged(hasTransporterChanged(newTransporter));
    }

    const addEmail = (): void => {
        const emailsClone: Email[] = cloneDeep(emails);

        emailsClone.push(
            {
                email: '',
                isValid: false
            }
        );

        setEmails(emailsClone);
    }

    const hasTransporterChanged = (newTransporter: TransportPurchaseLightModelExtended): boolean => {
        return (
            props.transporterSelected.managerEmail != newTransporter.managerEmail ||
            props.transporterSelected.phoneNumber != newTransporter.phoneNumber ||
            props.transporterSelected.addressFreeFormAddress != address.freeFormAddress ||
            props.transporterSelected.coordinatesLatitude != coordinates?.latitude ||
            props.transporterSelected.coordinatesLongitude != coordinates?.longitude
        );
    }

    const handleMapClick = (e): void => {
        setClickedMapPosition({ latitude: e.position[1], longitude: e.position[0] });
    }

    const setMapPin = (position: GpsCoordinates, pinId: string): void => {
        setCoordinates(position);
        const dataSource = myMap.sources.getById(mapDataSourceId) as source.DataSource;
        const existingPin: Shape = dataSource.getShapeById(pinId);

        if (position) {
            if (!existingPin) {
                //add pin
                addPin(position, pinId, dataSource);
                addPinSymbol(dataSource);
            } else {
                //update pin position
                existingPin.setCoordinates([position.longitude, position.latitude]);
            }

            myMap.setCamera({
                zoom: 10,
                center: [position.longitude, position.latitude]
            });
        } else {
            if (existingPin) {
                dataSource.remove(existingPin);
            }
        }
    }

    const addPin = (coordinates: GpsCoordinates, pinId: string, dataSource: source.DataSource): void => {
        const pin: data.Feature<data.Point, { title: string }> = new data.Feature(new data.Point([coordinates.longitude, coordinates.latitude]), { title: 'L' }, pinId);
        dataSource.add(pin);
    }

    const addPinSymbol = (dataSource: source.DataSource): void => {
        let layer: layer.Layer<layer.LayerEvents> = myMap.layers.getLayerById("layer");
        if (!layer) {
            //custom pin
            const symbolLayer: layer.SymbolLayer = createSymbolLayer(dataSource);
            myMap.layers.add(symbolLayer);
            layer = symbolLayer;
        }

        //Add related event
        myMap.events.add('mouseover', layer, () => myMap.getCanvasContainer().style.cursor = 'pointer');
        myMap.events.add('mouseout', layer, () => myMap.getCanvasContainer().style.cursor = 'default');
        myMap.events.add('mousedown', layer, mousedown);
        myMap.events.add('mousemove', mousemove);
        myMap.events.add('mouseup', mouseup);
    }

    const createSymbolLayer = (dataSource: source.DataSource): layer.SymbolLayer => {
        return new layer.SymbolLayer(dataSource, "layer", {
            textOptions: {
                textField: ['get', 'title'],
                offset: [0, -1.2],
                color: 'white'
            },
            iconOptions: {
                ignorePlacement: true,
                allowOverlap: true
            },
            filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']]
        });
    }

    let selectedShape: Shape = null;

    const mousedown = (e: MapMouseEvent): void => {
        if (e.shapes && e.shapes.length > 0) {
            selectedShape = e.shapes[0] as Shape;
            myMap.setUserInteraction({
                dragPanInteraction: false
            });
        }
    }
    const mousemove = (e: MapMouseEvent) => {
        if (selectedShape) {
            selectedShape.setCoordinates(e.position);
        }
    }
    const mouseup = (): void => {
        if (selectedShape) {
            const dataSource = myMap.sources.getById(mapDataSourceId) as source.DataSource;
            const shape: Shape = dataSource.getShapeById(pinId);
            const sourcesCoordinates = shape?.getCoordinates() as data.Position;

            let coord = {} as GpsCoordinates;

            //update coordinates
            if (sourcesCoordinates) {
                coord = { latitude: sourcesCoordinates[1], longitude: sourcesCoordinates[0] };
                if (selectedShape.getId() === pinId) {
                    MapsService.GetAddressFromCoordinates(coord)
                        .then((res: Address) => {
                            setAddress({ freeFormAddress: res.freeformAddress, line1: res.line1, line2: null, zipCode: res.zipCode, city: res.city, country: res.country });
                            if (failedAddress)
                                setFailedAddress(false);
                            setCoordinates(coord);
                        });
                }
            }
        }

        selectedShape = null;
        myMap.setUserInteraction({
            dragPanInteraction: true
        });
    }

    const loadAddressesOptions = debounce((inputValue: string, callback): void => {
        loadOptions(inputValue, callback);
    }, 500);

    const loadOptions = (inputValue: string, callback): void => {
        if (inputValue.trim().length > 2) {
            setIsAddressInputLoading(true);
            
            MapsService.SearchAddress(inputValue)
                .then((res: Address[]) => {
                    const listAddress: SelectInputItemModel[] = [{ label: "", value: "" }];
                    res.forEach(x => {
                        const address: SelectInputItemModel = {
                            value: x.id,
                            label: x.freeformAddress,
                            line1: x.line1,
                            zipCode: x.zipCode,
                            city: x.city,
                            country: x.country,
                            latitude: x.position.latitude,
                            longitude: x.position.longitude
                        };

                        listAddress.push(address);
                    });

                    callback(listAddress);
                    setIsAddressInputLoading(false);
                });
        }
    }

    const onFreeFormAddressFocus = (): void => {
        setAddressInputValue(address.freeFormAddress ?? '');
    }

    const handleChangeAddress = (e: SelectInputItemModel): void => {
        if (e) {
            setAddressInputValue(e.label);
            setAddress({ freeFormAddress: e.label, line1: e.line1, line2: e.line2, zipCode: e.zipCode, city: e.city, country: e.country });
            setCoordinates({ latitude: e.latitude, longitude: e.longitude });
            setFailedAddress(false);
            
            if (e.value) {
                setMapPin({ latitude: e.latitude, longitude: e.longitude }, pinId);
            } else {
                setMapPin(null, pinId);
            }
        } else {
            setAddressInputValue("");
            setAddress({ freeFormAddress: "", line1: "", line2: "", zipCode: "", city: "", country: "" });
            setFailedAddress(true);
            setMapPin(null, pinId);
        }
    }

    const handleInputChangeAddress = (value: string, inputActionMeta: InputActionMeta): void => {
        if (inputActionMeta.action === "input-change") {
            setAddressInputValue(value);
            setAddress({ freeFormAddress: value, line1: "", line2: "", zipCode: "", city: "", country: "" })
        }
    }

    const setMapWithCoordinates = (transporter: TransportPurchaseLightModelExtended): void => {
        if (transporter.coordinatesLatitude && transporter.coordinatesLongitude) {
            const coordinates = { latitude: transporter.coordinatesLatitude, longitude: transporter.coordinatesLongitude };
            MapsService.GetAddressFromCoordinates(coordinates)
                        .then((res: Address) => {
                            setAddress({ freeFormAddress: res.freeformAddress, line1: res.line1, line2: null, zipCode: res.zipCode, city: res.city, country: res.country });
                            if (failedAddress)
                                setFailedAddress(false);
                            setCoordinates(coordinates);
                        });
        } else {
            setAddress({ freeFormAddress: transporter.addressFreeFormAddress ?? transporter.address, line1: transporter.addressLine1, line2: null, zipCode: transporter.addressZipCode, city: transporter.addressCity, country: transporter.addressCountry });
            setFailedAddress(true);
        }
    }

    const btnEditDisabled = !isTransporterChanged
        || failedAddress
        || !Utilities.isValidPhoneNumber(transporter.phoneNumber) || !transporter.phoneNumber
        || emails.some(e => !e.isValid);

    let addressLabelClassName = "";
    let addressSelectClassName = "";
    if (address.line1?.trim() && address.freeFormAddress?.trim() && address.zipCode?.trim() && address.city?.trim() && coordinates?.latitude && coordinates?.longitude) {
        addressLabelClassName = "label";
        addressSelectClassName = "async-select";
    } else if (!address.line1?.trim() && address.freeFormAddress?.trim() && address.zipCode?.trim() && address.city?.trim()) {
        addressLabelClassName = "warning-label";
        addressSelectClassName = "async-select warning";
    } else {
        addressLabelClassName = "required-label";
        addressSelectClassName = "async-select required";
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row">
                <Box width="50%" display="flex" flexDirection="column" className="edit-content">
                    <Box className="transporter-title">
                        <span className="transporter-name">{props.transporterSelected.transporterName}</span>&nbsp;-&nbsp;{props.transporterSelected.transporterId}
                    </Box>
                    <Box pt={2} display="flex" flexDirection="row" alignItems="center">
                        <Box width="32px">
                            <MapMarkerButton text="L" handleClickButton={() => setIsMapMarkerActive(true)}
                                isDisable={coordinates || isMapMarkerActive ? true : false}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="flex-start" pb={'16px'}>
                            <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="flex-start">   
                                <Box display="flex" flexDirection="column">
                                    <Box className={addressLabelClassName}>Adresse</Box>
                                    <Box display="flex" flexDirection="row">
                                        <AsyncSelect
                                            value={{ label: address.freeFormAddress, value: address.freeFormAddress }}
                                            inputValue={addressInputValue}
                                            className={addressSelectClassName}
                                            loadOptions={loadAddressesOptions}
                                            onFocus={() => onFreeFormAddressFocus()}
                                            onChange={(e) => handleChangeAddress(e)}
                                            onInputChange={(value, inputActionMeta) => handleInputChangeAddress(value, inputActionMeta)}
                                            blurInputOnSelect={true}
                                            openMenuOnClick={false}
                                            isClearable={true}
                                            isLoading={isAddressInputLoading}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box pl={"32px"} pt={1}>
                        <TextField error={!transporter.phoneNumber || !Utilities.isValidPhoneNumber(transporter.phoneNumber)} label="Téléphone" value={transporter.phoneNumber} className="text-field"
                            helperText={!transporter.phoneNumber || !Utilities.isValidPhoneNumber(transporter.phoneNumber) ? "Le numéro n'est pas valide." : ''} variant="outlined" onChange={(event) => handleChangeField(event, "phoneNumber")} />
                    </Box>
                    {emails.map((currentEmail, index) => {
                        return (<Box key={`edit-email-address-${index}`} display="flex" flexDirection="row" pl={"32px"} pt={2}>
                            <TextField error={!currentEmail || !currentEmail.isValid} label={`Email ${(index + 1)}`} value={currentEmail.email} className="text-field"
                                helperText={!currentEmail || !currentEmail.isValid ? "L'adresse mail est invalide ou vide." : ''} variant="outlined" onChange={(event) => handleChangeEmail(event, index)} />
                            <Box display="flex" width="20%" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                <Box style={{ cursor: 'pointer' }} pl={2} pr={2} onClick={addEmail}>
                                    <FontAwesomeIcon size="lg" icon={faPlusCircle} />
                                </Box>
                                {(emails.length > 1) &&
                                    <Box style={{ cursor: 'pointer' }} onClick={(e) => removeEmail(index)}>
                                        <FontAwesomeIcon size="lg" icon={faTimes} />
                                    </Box>
                                }
                            </Box>
                        </Box>)
                    })}
                </Box>
                <div className="map-content">
                    <Box width="50%" id="myMap" className="content"></Box>
                </div>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" pt={3} pl={2} pr={3}>
                <Button variant="contained" color="primary" title="Annuler" onClick={props.handleClose} className="mr-1">Annuler</Button>
                <Button variant="contained" color="primary" title="Modifier l'utilisateur" onClick={() => props.handleEditTransporterClick({
                        transporterMdmId: transporter.transporterId,
                        addressFreeFormAddress: address.freeFormAddress,
                        addressLine1: address.line1,
                        addressLine2: address.line2,
                        addressZipCode: address.zipCode,
                        addressCity: address.city,
                        addressCountry: address.country,
                        coordinatesLatitude: coordinates.latitude,
                        coordinatesLongitude: coordinates.longitude,
                        managerEmail: transporter.managerEmail,
                        phoneNumber: transporter.phoneNumber
                    })}

                    disabled={btnEditDisabled}>
                    Modifier
                </Button>
            </Box>
        </Box>
    );
}
