import { faSearch, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton, Input, InputAdornment, Tooltip } from '@mui/material';
import React from 'react';
import { OverheadExpenseItemRequestArgs } from '../services/dataContracts/controller/OverheadExpenseItemRequestArgs';

interface ContentHeaderComponentProps {
    requestRemovePending: boolean,
    overheadExpensesSelected: Array<OverheadExpenseItemRequestArgs>,
    inputSearchRef: React.MutableRefObject<HTMLInputElement>,
    inputSearchValue: string,
    ClearSearchText: () => void,
    HandleOverheadExpenseKeyPress: (searchText: string) => void,
    HandleClickOpenNewOverheadExpense: (opened: boolean) => void,
    RemoveOverheadExpenses: () => void
}

export const ContentHeaderComponent = (props: ContentHeaderComponentProps) => {
    const disabledBtnTrash = props.overheadExpensesSelected.length === 0  || props.requestRemovePending;

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="content-header" alignItems="center">
            <Box width="35%" display="flex" flexDirection="row" flex="wrap" alignItems="center">
            <Button className="btn-actions" onClick={() => props.HandleClickOpenNewOverheadExpense(true)}>
                    Ajouter des nouveaux frais
                </Button>
            </Box>
            <Box width="65%" justifyContent="flex-end" display="flex" flexDirection="row" alignItems="center" className="header">
                <Box pr={3}>
                    <Tooltip title="Supprimer les frais sélectionnés" placement="bottom">
                        <Box>
                            <IconButton size="small" className="btn-icon" aria-label="Add"
                                disabled={disabledBtnTrash} onClick={() => props.RemoveOverheadExpenses()}>
                                <FontAwesomeIcon className={disabledBtnTrash ? 'fa-trash-disabled' : 'fa-trash'} icon={faTrash} />
                            </IconButton>
                        </Box>
                    </Tooltip>
                </Box>
                <Input disableUnderline className={`input-search-overhead-expense${props.inputSearchValue.length > 2 ? ' search-text-active' : ''}`} inputRef={props.inputSearchRef}
                    endAdornment={<>
                        <InputAdornment position="end" classes={
                            {
                                root: 'input-icon-close-root'
                            }
                        }>
                            <FontAwesomeIcon icon={faTimes} onClick={() => props.ClearSearchText()} />
                        </InputAdornment>
                        <InputAdornment position="end" classes={
                            {
                                root: 'input-icon-search-root'
                            }
                        }>
                            <FontAwesomeIcon icon={faSearch} className="icon-search" />
                        </InputAdornment>
                    </>}
                    id="input-search-text" placeholder="Rechercher..." onChange={(event) => props.HandleOverheadExpenseKeyPress(event.target.value)} />
            </Box>
        </Box>
    );
}
