import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { VehicleItemModel } from '../models/VehicleItemModel';
import { ReservedTransporterVehicleTypeLightModel } from '../services/dataContracts/queryStack/ReservedTransporterVehicleTypeLightModel';

interface ContractedVehicleItemComponentProps {
    style: React.CSSProperties,
    dataItem: VehicleItemModel,
    handleAssignContractedVehicle: (contractualPurchasePriceId: number, transporterId: string, vehicleTypeGroupId: string) => void,
    confirmedReservations: Map<string, Map<string, ReservedTransporterVehicleTypeLightModel>>
}

export const ContractedVehicleItemComponent = (props: ContractedVehicleItemComponentProps): JSX.Element => {

    const { dataItem, style } = props;

    const translatePriceKind = (priceKind: string): string => {
        switch (priceKind) {
            case 'Day':
                return 'J';
            case 'HalfDay':
                return '1/2 J';
            case 'Night':
                return 'N';
            case 'PerTon':
                return 'T';
            case 'PerHour':
                return 'H';
            default:
                return priceKind;
        }
    }

    const transporterConfirmedReservations: ReservedTransporterVehicleTypeLightModel = props.confirmedReservations.get(dataItem.transporterId)?.get(dataItem.vehicleTypeId);
    const hasReservedVehicles = transporterConfirmedReservations !== undefined && Number(transporterConfirmedReservations.reservedNumberOfVehicles) > 0;

    return (
        <Box style={style} display="flex" flexDirection="row" className="vehicle-card" onDoubleClick={() => props.handleAssignContractedVehicle(dataItem.contractualPurchasePriceId, dataItem.transporterId, dataItem.vehicleTypeGroupId)}>
            <Box className="header-vehicle header-contracted"></Box>
            <Box className="hover-vehicle contracted">
                <span className={`vehicle-title${!dataItem.vehicleTypeIsEnabled ? " vehicle-disabled" : ""}`}>
                    {`${dataItem.vehicleTypeLabel} - ${translatePriceKind(dataItem.priceKind)} (${dataItem.contractNumber})`}
                </span>
                <div className="vehicle-libelle">
                    {dataItem.transporterLabel}
                </div>
            </Box>
            <Tooltip placement="top" title={`${dataItem.remainingNumberOfDays}j d'utilisation restant(s) sur les ${dataItem.remainingNumberOfWorkDays}j ouvré(s) à venir`}>
                <Box className={dataItem.remainingNumberOfDays > dataItem.remainingNumberOfWorkDays ? "remaining-numbers contracted red" : "remaining-numbers contracted"}>
                    {`${dataItem.remainingNumberOfDays}j / ${dataItem.remainingNumberOfWorkDays}j`}
                </Box>
            </Tooltip>
            {hasReservedVehicles &&
                <Box className="reservation-numbers">
                    {transporterConfirmedReservations.reservedNumberOfVehicles}
                </Box>
            }
        </Box>
    )
}
