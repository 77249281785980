import styles from '../../_variables.scss';
let _gridOffsetFromWindowTop: number = null;
export class SizingUtilities {

    //item size d'un flow de la liste (row height) fixé dans le css (_variables.scss)
    public static itemSize = Number(styles.flowsSelectorItemSize);

    //width de la liste des flux fixé dans le css (_variables.scss)
    public static listWidth: string = styles.flowsSelectorListWidth;

    //40 is the row height defined in prop of the kendo grid
    public static rowHeight = 40;

    // gridOffsetFromWindowTop is the height of elements above grid.
    public static gridOffsetFromWindowTop = () => {
        if (!_gridOffsetFromWindowTop) {
            _gridOffsetFromWindowTop = parseInt(styles.attachZephyrFlowManuallyGridFromWindowTop);
        }

        return _gridOffsetFromWindowTop;
    }

    // window.innerHeight get the hight of page from navigator size
    public static computeGridHeight = (gridOffsetFromWindowTop: number) => {
        return (window.innerHeight * 80) / 100 - gridOffsetFromWindowTop;
    }

    // rowHeight is the row height defined in prop of the kendo grid
    public static computeGridPageSize = (gridHeight: number, rowHeight: number) => {
        return Number((gridHeight / rowHeight).toFixed(0));
    }
}
