import { GridCellProps } from '@progress/kendo-react-grid';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import React, { useState } from 'react';
import { BaseRowModel } from '../models/BaseRowModel';

interface CustomPriceCellComponent extends GridCellProps {
    isReadOnly: boolean
}

export const CustomPriceCellComponent = (props: CustomPriceCellComponent): JSX.Element => {
    const dataItem = props.dataItem as BaseRowModel;


    const [newValue, setNewValue] = useState<number>(dataItem.changes.get(props.field));

    let defaultRendering: JSX.Element = null;

    const handleChange = (event: NumericTextBoxChangeEvent, props: GridCellProps): void => {
        dataItem.inEdit = props.field;
        const newValue = event.value != null && event.value >= 0 ? event.value : dataItem[props.field];
        dataItem.changes.set(props.field, newValue);
        setNewValue(newValue);
    }

    if (props.isReadOnly || !dataItem.inEdit || dataItem.inEdit !== props.field) {
        const oldValue = dataItem[props.field];
        defaultRendering = (
            <td className={newValue !== oldValue ? "field-changed" : ""}>
                {!newValue ? '' : newValue}
            </td>
        );
    } else {
        defaultRendering = (
            <td>
                <NumericTextBox
                    value={newValue}
                    min={0}
                    width={110}
                    onChange={(event) => handleChange(event, props)} />
            </td>
        );
    }
    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}