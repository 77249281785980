import { DateInput, DateInputChangeEvent } from '@progress/kendo-react-dateinputs';
import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import { DeliveryTripLightModelExtended } from '../models/DeliveryTripLightModelExtended';

interface TimeEditorState {
    dataValue: Date;
}
interface TimeEditorProperties extends GridCellProps {
    format: string,
    isWrapped?: boolean
}

export default class TimeEditorComponent extends React.Component<TimeEditorProperties, TimeEditorState> {
    constructor(props: TimeEditorProperties) {
        super(props);
        this.state = {
            dataValue: this.props.dataItem[this.props.field]
        }
    }

    //TODO Saber : Ce composant doit etre générique
    handleTripDateChange = (props: TimeEditorProperties, event: DateInputChangeEvent<DateInput>): void => {
        const dataItem: DeliveryTripLightModelExtended = props.dataItem;

        const jmin: Date = dataItem.transportRequestDate.getDayStart();
        const jmax: Date = dataItem.transportRequestDate.getDayEnd();
        const j1max: Date = dataItem.transportRequestDate.getDayEnd().addDays(1);

        if ((props.field === "deliveryTime" && (event.value > j1max || event.value < jmin)) ||
            (props.field === "loadingTime" && (event.value > jmax || event.value < jmin)))
            return;

        this.setState({
            dataValue: event.value
        });
        dataItem.inEdit = props.field;
        dataItem[props.field] = event.value;
        dataItem[props.field] = event.value
    }

    render() {
        const dataItem: DeliveryTripLightModelExtended = this.props.dataItem;
        const dataValue: Date = dataItem[this.props.field];
        let defaultRendering: JSX.Element = null;
        const _isWrapped: boolean = this.props.isWrapped;
        if (!dataItem.inEdit || dataItem.inEdit !== this.props.field) {
            const _elementValue: string = !dataValue || dataValue === null ? '' : new Date(dataValue).getHours() + ((new Date(dataValue).getMinutes() < 10) ? ":0" : ":") + new Date(dataValue).getMinutes();
            defaultRendering = _isWrapped
                ? <>{_elementValue}</>
                : <td>{_elementValue}</td>;
        } else {
            defaultRendering = (
                <td>
                    <DateInput
                        spinners={true}
                        format={this.props.format}
                        width={'100%'}
                        value={this.state.dataValue === null ? dataItem.transportRequestDate : this.state.dataValue}
                        onChange={(event) => this.handleTripDateChange(this.props, event)}
                        disabled={dataItem.planningVehicleIsCanceled}
                    />
                </td>
            );
        }
        return this.props.render ? this.props.render.call(undefined, defaultRendering, this.props) : defaultRendering;
    }
}