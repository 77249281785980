import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import Select from 'react-select';
import SimpleDialog from 'src/shared/components/Common/SimpleDialog';
import { PriceGridSelectionRule } from 'src/shared/Quotation/services/dataContracts/admin/PriceGridSelectionRule';
import { PriceGridsListEntry } from 'src/shared/Quotation/services/dataContracts/admin/PriceGridsListEntry';
import { LogisticsUnitChoice } from '../../../../shared/models/LogisticsUnitChoice';
import { LogisticsUnitSelectModel } from '../../../../shared/models/LogisticsUnitSelectModel';
import { FixedLists } from '../models/FixedLists';

interface ContentHeaderComponentProps {
    logisticsUnits: Array<LogisticsUnitChoice>,
    selectedLogisticsUnit: LogisticsUnitSelectModel,
    priceGrids: Array<PriceGridsListEntry>,
    pricingSelectionRulesData: PriceGridSelectionRule[],
    selectedPriceGrid: PriceGridsListEntry,
    isPriceDataChanged: boolean,
    handleLogisticsUnitChange: (event: LogisticsUnitSelectModel) => void,
    handlePriceGridChange: (event: PriceGridsListEntry) => void,
    handleConfirmSaveData: () => void
}

export const ContentHeaderComponent = (props: ContentHeaderComponentProps): JSX.Element => {
    const [popupRulesOpened, setPopupRulesOpened] = useState<boolean>(false);

    const handleOpenPopupRules = () => {
        setPopupRulesOpened(true);
    }

    const handleClosePopupRules = () => {
        setPopupRulesOpened(false);
    }

    const pricingSelectionRulesOfSelectedPrice = props.selectedPriceGrid
        ? props.pricingSelectionRulesData?.filter(p => p.selection?.priceGrid === props.selectedPriceGrid.id) ?? []
        : [];

    return (
        <>
            <Box display="flex" flexDirection="row" className="header-content">
                <Box pr={3}>
                    <Select
                        className="logistics-unit-select"
                        id="vehicle-type"
                        isClearable
                        placeholder="Zone logistique"
                        menuPosition="fixed"
                        menuShouldBlockScroll={true}
                        isDisabled={props.logisticsUnits.length < 2}
                        options={props.logisticsUnits.map(x => { return { label: x.label, value: x.logisticsUnitId } })}
                        value={props.selectedLogisticsUnit}
                        onChange={(e) => props.handleLogisticsUnitChange(e)}
                    />
                </Box>
                <Box sx={{
                    paddingRight: "2em"
                }}>
                    <Select
                        className="grids-list"
                        id="grids-list"
                        isClearable
                        placeholder="Grille de tarif"
                        menuPosition="fixed"
                        menuShouldBlockScroll={true}
                        isDisabled={!props.selectedLogisticsUnit?.value}
                        options={props.priceGrids}
                        value={props.selectedPriceGrid}
                        getOptionLabel={option => option.id}
                        getOptionValue={option => option.id}
                        onChange={(e) => props.handlePriceGridChange(e)}
                    />
                </Box>
                <Box sx={{
                    paddingRight: "2em"
                }}>
                    <Tooltip title="Afficher règles d'application">
                        <Avatar className={props.selectedPriceGrid ? "btn-icon-pricing-rules" : "btn-icon-pricing-rules-disabled"}>
                            <IconButton disabled={!props.selectedPriceGrid} size="small" onClick={handleOpenPopupRules}>
                                <FontAwesomeIcon icon={faQuestion} />
                            </IconButton>
                        </Avatar>
                    </Tooltip>
                </Box>
                <Box>
                    <Button variant="contained" color="primary" disabled={!props.isPriceDataChanged}
                        onClick={() => props.handleConfirmSaveData()}>
                        Sauvegarder
                    </Button>
                </Box>
                <SimpleDialog isOpen={popupRulesOpened}
                    onClose={() => handleClosePopupRules()}
                    dialogTitleComponent={<div className="title">Règle d'application de la grille</div>}
                    closeIcon={true}
                    closeOnClickOutside={true}
                    draggable={true}
                    classNameVal="pricing-selection-rules-dialog"
                    headerBorder={true}
                    component={
                        <div>
                            {pricingSelectionRulesOfSelectedPrice.map((pricing: PriceGridSelectionRule, index: number) => (
                                <Box display="flex" flexDirection="column" key={`pricing-${index}`} pb={2}>
                                    <Box display="flex" flexDirection="row">
                                        <div className="pricing-title">Type de produit: </div>
                                        <div className="pricing-value">{pricing.productTypeGroups?.join(" ") ?? "Tous"}</div>
                                    </Box>
                                    <Box display="flex" flexDirection="row">
                                        <div className="pricing-title">Prestation: </div>
                                        <div className="pricing-value">{pricing.serviceKinds?.map(x => FixedLists.serviceKindList.find(s => s.value === x)?.label)?.join(" ou ") ?? "Toutes"}</div>
                                    </Box>
                                    <Box display="flex" flexDirection="row">
                                        <div className="pricing-title">Type de tarification: </div>
                                        <div className="pricing-value">{pricing.forcedGridTypes?.map(x => FixedLists.priceKindList.find(s => s.value === x)?.label)?.join(" ") ?? "Tous"}</div>
                                    </Box>
                                </Box>
                            ))}
                        </div>
                    }
                />
            </Box>
        </>
    );

}
