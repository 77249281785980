import { Box } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { UploadOnBeforeUploadEvent, UploadResponse } from '@progress/kendo-react-upload';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import SimpleDialog from 'src/shared/components/Common/SimpleDialog';
import { ImportErrorsExtraResult, LineImportErrors } from 'src/shared/models/ImportErrorsExtraResult';
import { WebAppActionResultEx } from 'src/shared/models/WebAppActionResult';
import ToastService from 'src/ToastService';
import { LocalStorage } from 'src/utils/Storage';
import { ScaleLoaderComponent } from '../../../shared/components/Common/ScaleLoaderComponent';
import BusinessErrors from '../../../utils/BusinessErrors';
import '../ReferentialsStyles.scss';
import { AddProductionSiteDialogComponent } from './components/AddProductionSiteDialog/AddProductionSiteDialogComponent';
import { EditProductionSiteLogisticalZoneDialogComponent } from './components/EditProductionSiteLogisticalZoneDialog/EditProductionSiteLogisticalZoneDialogComponent';
import { HeaderContentComponent } from './components/HeaderContentComponent';
import { ProductionSitesReferentialComponent } from './components/ProductionSitesReferentialComponent';
import { ProductionSiteLightModelExtended } from './models/ProductionSiteLightModelExtended';
import './ProductionSitesReferentialStyles.scss';
import { EditProductionSiteLogisticUnitRequestArgs } from './services/dataContracts/controller/EditProductionSiteLogisticUnitRequestArgs';
import { ProductionSiteCandidateToAdd } from './services/dataContracts/controller/ProductionSiteCandidateToAdd';
import { LogisticsUnitChoiceOfProductionSitesLightModel } from './services/dataContracts/queryStack/LogisticsUnitChoiceOfProductionSitesLightModel';
import { ProductionSitesReferentialApiClient } from './services/ProductionSitesReferentialApiClient';
interface ProductionSitesReferentialViewProps {
    handleLogisticsUnitsChanges: (logisticsUnits: Array<any>) => void,
    role: string
}

export const ProductionSitesReferentialView = (props: ProductionSitesReferentialViewProps) => {

    const inputSearchProductionSitesRef: React.RefObject<HTMLInputElement> = React.useRef(null);
    const initialSort: SortDescriptor[] = [{ field: 'label', dir: 'asc' }];

    const [productionSitesList, setProductionSitesList] = useState<Array<ProductionSiteLightModelExtended>>([]);
    const [productionSiteChoicesLogisticsUnit, setProductionSiteChoicesLogisticsUnit] = useState<Array<LogisticsUnitChoiceOfProductionSitesLightModel>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [sort, setSort] = useState<Array<SortDescriptor>>(initialSort);

    const [activeStep, setActiveStep] = useState<number>(0);
    const [productionSiteId, setProductionSiteId] = useState<string>('');
    const [isAddProductionSiteDialogOpened, setIsAddProductionSiteDialogOpened] = useState<boolean>(false);
    const [productionSiteSearchLoading, setProductionSiteSearchLoading] = useState<boolean>(false);
    const [productionSiteToAdd, setProductionSiteToAdd] = useState<ProductionSiteCandidateToAdd>(null);
    const [productionSiteIsLogisticZone, setProductionSiteIsLogisticZone] = useState<boolean>(false);
    const [selectedProductionSiteId, setSelectedProductionSiteId] = useState<string>(null);
    const [logisticalZoneDialogEditMode, setLogisticalZoneDialogEditMode] = useState<boolean>(false);
    const [productionSiteToEdit, setProductionSiteToEdit] = useState<ProductionSiteLightModelExtended>(null);

    useEffect(() => {
        if (!productionSiteIsLogisticZone) {
            getProductionSites("");
        }
    }, []);

    const clearSearchText = (): void => {
        inputSearchProductionSitesRef.current.value = "";
        logisticsUnitsKeyPressed("");
    }

    const ProductionSitesKeyPressed = debounce((text: string): void => {
        if (text.length >= 3 || text.length === 0) {
            logisticsUnitsKeyPressed(text);
        }
    }, 500);

    const logisticsUnitsKeyPressed = (text: string): void => {
        if (!productionSiteIsLogisticZone) {
            getProductionSites(text);
        } else {
            getProductionSitesLogistiqueZone(text);
        }

    }

    const getProductionSites = (searchText: string, errorMessage?: string, listErrorMessage?: Array<string>, successMessage?: string, endMessage?: string): void => {
        setLoading(true);
        setSelectedProductionSiteId(null);
        ProductionSitesReferentialApiClient.GetProductionSitesWithLogisticsUnitChoicesLogisticsUnit(searchText)
            .then(response => {
                const productionSites = response[0].data as ProductionSiteLightModelExtended[];
                setProductionSitesList(productionSites);
                setProductionSiteChoicesLogisticsUnit(response[1].data);

                if (errorMessage)
                    ToastService.showErrorToast(errorMessage, listErrorMessage, endMessage);
                else if (successMessage)
                    ToastService.showSuccessToast(successMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const editLogisticUnit = (productionSiteChoiceLogisticsUnits: EditProductionSiteLogisticUnitRequestArgs) => {
        setLoading(true);
        ProductionSitesReferentialApiClient.EditLogisticUnit(productionSiteChoiceLogisticsUnits)
            .then(() => {
                setLogisticalZoneDialogEditMode(true);
                const index = LocalStorage.LogisticsUnitsChoices.findIndex(item => item.logisticsUnitId === productionSiteChoiceLogisticsUnits.productionSiteId);
                LocalStorage.LogisticsUnitsChoices[index].label = productionSiteChoiceLogisticsUnits.label;
                ToastService.showSuccessToast("La zone logistique a bien été modifiée");
                props.handleLogisticsUnitsChanges(LocalStorage.LogisticsUnitsChoices);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    const getProductionSitesLogistiqueZone = (searchText: string, errorMessage?: string, listErrorMessage?: Array<string>, successMessage?: string, endMessage?: string) => {
        setLoading(true);
        ProductionSitesReferentialApiClient.GetProductionSitesLogistiqueZone(searchText)
            .then(response => {
                const productionSites = response.data as ProductionSiteLightModelExtended[];
                setProductionSitesList(productionSites);

                if (errorMessage)
                    ToastService.showErrorToast(errorMessage, listErrorMessage, endMessage);
                else if (successMessage)
                    ToastService.showSuccessToast(successMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const handleSortColumnChange = (sortItems: SortDescriptor[]): void => {
        setSort(sortItems);
    }

    const inputSearchProductionSitesValue = inputSearchProductionSitesRef.current === null || inputSearchProductionSitesRef.current === undefined ? '' : inputSearchProductionSitesRef.current.value;

    const handlerAfterUpload = (response: UploadResponse): void => {
        const _response: WebAppActionResultEx<ImportErrorsExtraResult> = response ? response.response : null;
        let _successMessage = '';
        const _listErrorMessage = [];
        let _errorMessage = '';
        let _endMessage = '';

        if (response.status === 200 && _response) {
            const importLines: Array<LineImportErrors> = _response.extraResult.linesWithErrors;

            if (importLines.length > 0) {
                importLines.forEach(e => {
                    e.errors.forEach(eError => {
                        let _libMsg = BusinessErrors.GetError(eError);
                        if (!_libMsg && eError)
                            _libMsg = eError;
                        _listErrorMessage.push(`- Ligne ${e.rowIndex} - ${_libMsg}`);
                    });
                });

                _errorMessage = 'Erreur lors de l\'import de la visibilité des Unités de Production Colas:';
                _endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des Unités de Production Colas sur vos zones logistiques';

            }
            else {
                _successMessage = 'La visibilité des Unités de Production Colas pour vos zones logistiques a bien été intégrée';
            }

        } else {
            _errorMessage = 'Erreur lors de l\'import de la visibilité des Unités de Production Colas, veuillez vérifier votre fichier';
            _endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des Unités de Production Colas sur vos zones logistiques';
            _successMessage = '';
        }
        getProductionSites(inputSearchProductionSitesValue, _errorMessage, _listErrorMessage, _successMessage, _endMessage);

    }

    const displayErrorResponse = (): void => {
        const _errorMessage = 'Erreur lors de l\'import de la visibilité des Unités de Production Colas, veuillez vérifier votre fichier';
        const _endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des Unités de Production sur vos zones logistiques';
        const _successMessage = '';
        getProductionSites(inputSearchProductionSitesValue, _errorMessage, [], _successMessage, _endMessage);
    }

    const handlerBeforeUpload = (e: UploadOnBeforeUploadEvent): void => {
        setLoading(true);
    }

    const searchProductionSiteByIdentifier = (productionSiteId: string): void => {
        setProductionSiteSearchLoading(true);

        ProductionSitesReferentialApiClient.SearchProductionSiteByIdentifier(productionSiteId.trim().toUpperCase())
            .then((res) => {

                setProductionSiteSearchLoading(false);

                const productionSiteCandidateToAdd: ProductionSiteCandidateToAdd = res.data;

                if (productionSiteCandidateToAdd) {
                    if (productionSiteCandidateToAdd.existInLorieDb) {
                        handleSearchInReferentialGrid(productionSiteCandidateToAdd.productionSiteId);
                    }
                    else if (productionSiteCandidateToAdd.existOnColasAPI) {
                        setProductionSiteToAdd(productionSiteCandidateToAdd);
                        setActiveStep(1);
                    }
                    else {
                        ToastService.showErrorToast("Aucune Unité de Production trouvée dans le référentiel");
                    }
                }
            });
    }

    const handleSearchInReferentialGrid = (productionSiteId: string): void => {
        inputSearchProductionSitesRef.current.value = productionSiteId;
        handleCloseAddProductionSiteDialog();
        getProductionSites(productionSiteId);

        ToastService.showErrorToast("Cette Unité de Production est déjà présente dans LORIE");
    }

    const confirmAddProductionSite = (isLogisticZone: boolean): void => {
        ProductionSitesReferentialApiClient.AddProductionSite(productionSiteToAdd.productionSiteId, productionSiteToAdd.productionSiteIsLogisticZone)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                inputSearchProductionSitesRef.current.value = productionSiteToAdd.productionSiteId;
                handleCloseAddProductionSiteDialog();
                if (isLogisticZone) {
                    ToastService.showSuccessToast("La zone logistique a bien été créée");
                    LocalStorage.LogisticsUnitsChoices.unshift({
                        logisticsUnitId: productionSiteToAdd.productionSiteId,
                        label: productionSiteToAdd.label,
                        checked: true,
                        isQuotationAdministrator: true
                    });
                    props.handleLogisticsUnitsChanges(LocalStorage.LogisticsUnitsChoices);
                } else {
                    ToastService.showSuccessToast("L'UP est insérée dans LORIE. Si vous souhaitez recevoir des appels de livraison PLANEO de cette UP, veuillez prévenir votre administrateur CDS préféré afin de l'associer à votre zone logistique");
                }
                getProductionSites(productionSiteToAdd.productionSiteId);
            });
    }

    const handleAddProductionSiteDialogClick = (): void => {
        setIsAddProductionSiteDialogOpened(true);
    }

    const handleProductionSiteIdentifierChange = (value: string) => {
        setProductionSiteId(value);
    }

    const handleCloseAddProductionSiteDialog = (): void => {
        setIsAddProductionSiteDialogOpened(false);
        setActiveStep(0);
        setProductionSiteId('');
        setProductionSiteToAdd(null);
    }

    const handlePreviousStep = (): void => {
        setActiveStep(activeStep - 1);
    }

    const handleCheckBoxProductionSiteIsLogisticZoneClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const checked: boolean = event.target.checked;
        setProductionSiteIsLogisticZone(checked);
        if (checked) {
            getProductionSitesLogistiqueZone(inputSearchProductionSitesValue)
        } else {
            getProductionSites(inputSearchProductionSitesValue);
        }
    }

    const handleSelectedLogisticZone = (logisticZoneId: string): void => {
        var selectedProductionSiteToEdit = null;
        if (selectedProductionSiteId) {
            productionSitesList.find(d => d.productionSiteId === selectedProductionSiteId).selected = false;
        }
        if (logisticZoneId) {
            productionSitesList.find(d => d.productionSiteId === logisticZoneId).selected = true;
            selectedProductionSiteToEdit = productionSitesList.find(d => d.productionSiteId === logisticZoneId)
            setProductionSiteToEdit(selectedProductionSiteToEdit);
        }
        setProductionSitesList(productionSitesList);
        setSelectedProductionSiteId(logisticZoneId);
    }

    const handleEditLogisticalZoneDialogClick = (): void => {
        setLogisticalZoneDialogEditMode(true);
    }

    const handleEditLogisticalZoneCloseDialogClick = (): void => {
        setLogisticalZoneDialogEditMode(false);
    }

    const headerContentComponent: JSX.Element = useMemo(() =>
        <HeaderContentComponent
            inputSearchProductionSitesValue={inputSearchProductionSitesValue}
            inputSearchProductionSitesRef={inputSearchProductionSitesRef}
            sort={sort}
            handleProductionSitesKeyPress={ProductionSitesKeyPressed}
            handleClearSearchText={clearSearchText}
            handleAddProductionSiteDialogClick={handleAddProductionSiteDialogClick}
            handlerAfterUpload={handlerAfterUpload}
            handlerBeforeUpload={handlerBeforeUpload}
            displayErrorResponse={displayErrorResponse}
            productionSiteIsLogisticZone={productionSiteIsLogisticZone}
            handleCheckBoxProductionSiteIsLogisticZoneClick={handleCheckBoxProductionSiteIsLogisticZoneClick}
            selectedProductionSiteId={selectedProductionSiteId}
            handleEditLogisticalZoneDialogClick={handleEditLogisticalZoneDialogClick}
            role={props.role}
        />, [inputSearchProductionSitesValue, inputSearchProductionSitesRef, sort, productionSiteIsLogisticZone, selectedProductionSiteId, props.role]);

    const productionSitesComponent: JSX.Element = useMemo(() =>
        <ProductionSitesReferentialComponent
            productionSites={productionSitesList}
            productionSiteChoicesLogisticsUnit={productionSiteChoicesLogisticsUnit}
            sort={sort}
            handleSortChange={handleSortChange}
            handleSortColumnChange={handleSortColumnChange}
            productionSiteIsLogisticZone={productionSiteIsLogisticZone}
            handleSelectedLogisticZone={handleSelectedLogisticZone}
        />, [productionSitesList, productionSiteChoicesLogisticsUnit, sort, productionSiteIsLogisticZone, selectedProductionSiteId]);

    return (
        <Box className="internal-production-sites referencial">
            <Box display="flex" flexDirection="row" flex="wrap" className="view-title">
                Gestion des Unités de Production Colas
            </Box>
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box display="flex" flexDirection="column" flex="wrap">
                        {headerContentComponent}
                        {(loading ?
                            <ScaleLoaderComponent loading={loading} />
                            :
                            productionSitesComponent
                        )}
                    </Box>
                </IntlProvider>
            </LocalizationProvider>
            <SimpleDialog isOpen={isAddProductionSiteDialogOpened}
                onClose={handleCloseAddProductionSiteDialog}
                closeIcon={true}
                dialogTitle="Ajouter une Unité de Production"
                classNameVal="add-production-site-dialog"
                component={
                    <AddProductionSiteDialogComponent
                        activeStep={activeStep}
                        productionSiteSearchLoading={productionSiteSearchLoading}
                        productionSiteId={productionSiteId}
                        productionSiteChoices={productionSiteToAdd}
                        handleProductionSiteIdentifierChange={handleProductionSiteIdentifierChange}
                        searchProductionSiteByIdentifier={searchProductionSiteByIdentifier}
                        handleSearchInReferentialGrid={handleSearchInReferentialGrid}
                        confirmAddProductionSite={confirmAddProductionSite}
                        handlePreviousStep={handlePreviousStep}
                        role={props.role}
                    />
                }
            />
            <SimpleDialog isOpen={logisticalZoneDialogEditMode}
                closeIcon={true}
                classNameVal="edit-production-site-dialog"
                onClose={handleEditLogisticalZoneCloseDialogClick}
                dialogTitle="Modifier les caractéristiques de la zone logistique"
                component={
                    <EditProductionSiteLogisticalZoneDialogComponent
                        productionSiteToEdit={productionSiteToEdit}
                        editLogisticUnit={editLogisticUnit}
                    />
                }
            />
        </Box>
    );
}
