import { MenuItem, Select } from '@mui/material';
import { GridCellProps } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { AvailablePurchasePriceLightModelExtended } from '../models/AvailablePurchasePriceLightModelExtended';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';
import { AvailablePurchasePriceLightModel } from '../services/dataContracts/queryStack/AvailablePurchasePriceLightModel';

interface CustomPriceKindCellProps extends GridCellProps{
    getPriceKind: (priceKind: string) => string,
    handleChange: (planningVehicle: PlanningVehicleLightModelExtended) => void
}

export const CustomPriceKindCell = (props: CustomPriceKindCellProps) => {
    const dataItem: PlanningVehicleLightModelExtended = props.dataItem;
    const [state, setState] = useState({
        selectedValue: dataItem.mainPriceKind + ':' + dataItem.mainUnitPrice
    });

    const handleTransporterVehicleSelectChange = (dataItem: PlanningVehicleLightModelExtended, field: string, value: string): void => {
        const eventTargetValue = value;
        const values = eventTargetValue.split(":");
        setState({
            ...state,
            selectedValue: value
        });
        dataItem.inEdit = field;
        dataItem.mainPriceKind = values[0];
        dataItem.mainUnitPrice = Number(values[1]);
        dataItem.updating = true;
        props.handleChange(dataItem);
    }

    const handleInternalVehicleSelectChange = (dataItem: PlanningVehicleLightModelExtended, field: string, value: string): void => {
        setState({
            ...state,
            selectedValue: value
        });
        dataItem.inEdit = field;
        dataItem.mainPriceKind = value;
        dataItem.updating = true;
        props.handleChange(dataItem);
    }

    const availablePurchasePrices: Array<AvailablePurchasePriceLightModelExtended> = dataItem.availablePurchasePrices;
    const dataValue: string = dataItem.mainPriceKind !== null ? dataItem.mainPriceKind : null;
    const hasSpecificPriceOrNegotiatedPrice: boolean = (dataItem.hasSpecificPrice === true || dataItem.hasNegotiatedPrice === true);

    let defaultRendering: JSX.Element = <></>;

    if (!dataItem.inEdit || dataItem.inEdit !== props.field || hasSpecificPriceOrNegotiatedPrice) {
        defaultRendering = (hasSpecificPriceOrNegotiatedPrice 
            ? <td>Multiple</td> 
            : (availablePurchasePrices === null 
                ? <td className={dataItem.purchaseIsCanceled ? "purchase-is-canceled" : ""}>{dataValue === null ? '' 
                : props.getPriceKind(dataValue)}</td> : <td className={dataItem.purchaseIsCanceled ? "purchase-is-canceled" : ""}>{props.getPriceKind(dataValue)} : {dataItem.mainUnitPrice}€</td>)
            );
    } else {
        defaultRendering = (
            <td>
                {(dataItem.transporterId != null && !dataItem.relatedDataIsLoaded) ?
                    (<Select style={{ width: '100%', fontSize: '12px' }}
                        value=''>
                    </Select>)
                : (!availablePurchasePrices || availablePurchasePrices.length === 0)
                    ? <Select style={{ width: '100%', fontSize: '12px' }}
                        onChange={(event) => handleInternalVehicleSelectChange(dataItem, props.field, event.target.value)}
                        value={state.selectedValue}>
                        <MenuItem style={{ fontSize: '12px' }} value={0}>Jour</MenuItem>
                        <MenuItem style={{ fontSize: '12px' }} value={2}>Nuit</MenuItem>
                        <MenuItem style={{ fontSize: '12px' }} value={1}>Demi-journée</MenuItem>
                        <MenuItem style={{ fontSize: '12px' }} value={3}>Tonne</MenuItem>
                        <MenuItem style={{ fontSize: '12px' }} value={4}>Heure</MenuItem>
                        <MenuItem style={{ fontSize: '12px' }} value={5}>Tour</MenuItem>
                    </Select>
                    : <Select style={{ width: '100%', fontSize: '12px' }}
                        onChange={(event) => handleTransporterVehicleSelectChange(dataItem, props.field, event.target.value)}
                        value={state.selectedValue}>
                        {availablePurchasePrices.map((elem: AvailablePurchasePriceLightModel, index) => <MenuItem key={index} value={elem.priceKind + ':' + elem.price} title={elem.description}>{props.getPriceKind(elem.priceKind)} : {elem.price}€</MenuItem>)}
                    </Select>
                }
            </td >
        );
    }
    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}