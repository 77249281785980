import { Box } from '@mui/material';
import { NumericTextBoxBlurEvent, NumericTextBoxChangeEvent, NumericTextBoxHandle } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import BusinessErrors from '../../../utils/BusinessErrors';
import { HTMLElementExtended } from '../models/HtmlInputExtended';
import { FeesProperty } from '../services/dataContracts/controller/FeesProperty';
import { PlanningVehicleFeesChangeRequestArgs } from '../services/dataContracts/controller/PlanningVehicleFeesChangeRequestArgs';
import { TransportRequestFeesLightModel } from '../services/dataContracts/queryStack/TransportRequestFeesLightModel';
import { TransportPurchasesApiClient } from '../services/TransportPurchasesApiClient';
import { PlanningVehicleFeesCell } from './PlanningVehicleFeesCell';

interface FeesComponentProps {
    item: TransportRequestFeesLightModel,
    planningVehicleId: string,
    vehicleId: number,
    planningDate: Date,
    transportRequestId: string,
    purchaseIsCanceled: boolean,
    displayErrorsMessages: (errors: string[]) => void,
    refreshPlanningVehicles: () => void
    refreshPlanningVehicleWithDetails: (planningVehicleId: string) => void
    handleUpdatingPlanningVehicle: (planningVehicleId: string) => void
}

export const FeesComponent = (props: FeesComponentProps) => {

    const TabKey = "Tab";
    const ShiftKey = "Shift";
    const componentId = "feesComponent";

    const tollFeesRef: React.RefObject<NumericTextBoxHandle> = React.useRef(null);
    const waitFeesRef: React.RefObject<NumericTextBoxHandle> = React.useRef(null);
    const othersFeesRef: React.RefObject<NumericTextBoxHandle> = React.useRef(null);

    const [tollFees, setTollFees] = useState<number>(props.item.tollFees);
    const [waitFees, setWaitFees] = useState<number>(props.item.waitFees);
    const [othersFees, setOthersFees] = useState<number>(props.item.othersFees);
    const [isTabKeyPressed, setIsTabKeyPressed] = useState<boolean>(false);
    const [isShiftKeyPressed, setIsShiftKeyPressed] = useState<boolean>(false);
    const [inEdit, setInEdit] = useState<FeesProperty>(null);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    });

    useEffect(() => {
        setTollFees(props.item.tollFees);
        setWaitFees(props.item.waitFees);
        setOthersFees(props.item.othersFees);
    }, [props.planningVehicleId, props.item]);

    useEffect(() => {
        //Note: à un instant donné, une seule cellule est en edit (composant NumericTextBox)
        // getElementById trouvera toujours un seul dans notre cas
        switch (inEdit) {
            case FeesProperty.tollFees:
                tollFeesRef.current.focus();
                (document.getElementById(componentId + '-' + FeesProperty.tollFees) as HTMLElementExtended).select();
                break;
            case FeesProperty.waitFees:
                waitFeesRef.current.focus();
                (document.getElementById(componentId + '-' + FeesProperty.waitFees) as HTMLElementExtended).select();
                break;
            case FeesProperty.othersFees:
                othersFeesRef.current.focus();
                (document.getElementById(componentId + '-' + FeesProperty.othersFees) as HTMLElementExtended).select();
                break;
            default:
                return;
        }
    }, [inEdit]);

    const handleKeyDown = (event: KeyboardEvent): void => {
        if (!event.altKey && !event.ctrlKey && !event.metaKey && event.key == TabKey)
            setIsTabKeyPressed(true);

        if (event.key == ShiftKey)
            setIsShiftKeyPressed(true);
    };

    const handleKeyUp = (event: KeyboardEvent): void => {
        //on gère séparement le shift car on peut faire plusieurs shift - tab en gardant la touche shift appué
        if (event.key == ShiftKey)
            setIsShiftKeyPressed(false);
    };

    const handleChange = (event: NumericTextBoxChangeEvent, feesProperty: string): void => {
        switch (feesProperty) {
            case FeesProperty.tollFees:
                setTollFees(event.target.value);
                break;
            case FeesProperty.waitFees:
                setWaitFees(event.target.value);
                break;
            case FeesProperty.othersFees:
                setOthersFees(event.target.value);
                break;
            default:
                return;
        }
    }

    const handleBlur = (event: NumericTextBoxBlurEvent, feesProperty: string): void => {
        if (isTabKeyPressed) {
            setIsTabKeyPressed(false);
            switch (feesProperty) {
                case FeesProperty.tollFees:
                    isShiftKeyPressed ? setInEdit(null) : setInEdit(FeesProperty.waitFees);
                    break;
                case FeesProperty.waitFees:
                    isShiftKeyPressed ? setInEdit(FeesProperty.tollFees) : setInEdit(FeesProperty.othersFees);
                    break;
                case FeesProperty.othersFees:
                    isShiftKeyPressed ? setInEdit(FeesProperty.waitFees) : setInEdit(null);
                    break;
                default:
                    setInEdit(null);
            }
        } else {
            setInEdit(null);
        }

        switch (feesProperty) {
            case FeesProperty.tollFees:
                if (event.target.value === props.item.tollFees)
                    return;
                break;
            case FeesProperty.waitFees:
                if (event.target.value === props.item.waitFees)
                    return;
                break;
            case FeesProperty.othersFees:
                if (event.target.value === props.item.othersFees)
                    return;
                break;
            default:
                return;
        }

        const vehicleFeeChange: PlanningVehicleFeesChangeRequestArgs = {
            transportRequestId: props.transportRequestId,
            planningVehicleId: props.planningVehicleId,
            planningDate: props.planningDate,
            feesProperty: feesProperty,
            value: event.target.value
        }

        props.handleUpdatingPlanningVehicle(props.planningVehicleId);
        TransportPurchasesApiClient.UpdatePlanningVehicleFees(vehicleFeeChange)
            .then(res => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    props.displayErrorsMessages(errors);
                    props.refreshPlanningVehicles();
                    return;
                }

                props.refreshPlanningVehicleWithDetails(props.planningVehicleId);
            });
    }

    const clickFees = (feesProperty: FeesProperty): void => {
        setIsTabKeyPressed(false);
        setInEdit(feesProperty);
    }

    return (
        <LocalizationProvider language="fr-FR">
            <IntlProvider locale="fr" >
                <Box display="flex" flexDirection="row" width="100%" className="vehicles-fees">
                    <PlanningVehicleFeesCell
                        id={componentId + "-" + FeesProperty.tollFees}
                        inEdit={inEdit}
                        label="Péage"
                        feesProperty={FeesProperty.tollFees}
                        feesRef={tollFeesRef}
                        feesValue={tollFees}
                        purchaseIsCanceled={props.purchaseIsCanceled}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        clickFeesValue={clickFees}
                    />
                    <PlanningVehicleFeesCell
                        id={componentId + "-" + FeesProperty.waitFees}
                        inEdit={inEdit}
                        label="Attente"
                        feesProperty={FeesProperty.waitFees}
                        feesRef={waitFeesRef}
                        feesValue={waitFees}
                        purchaseIsCanceled={props.purchaseIsCanceled}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        clickFeesValue={clickFees}
                    />
                    <PlanningVehicleFeesCell
                        id={componentId + "-" + FeesProperty.othersFees}
                        inEdit={inEdit}
                        label="Autres"
                        feesProperty={FeesProperty.othersFees}
                        feesRef={othersFeesRef}
                        feesValue={othersFees}
                        purchaseIsCanceled={props.purchaseIsCanceled}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        clickFeesValue={clickFees}
                    />
                </Box>
            </IntlProvider>
        </LocalizationProvider>
    )
}
