import { Box } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ScaleLoaderComponent } from '../../../shared/components/Common/ScaleLoaderComponent';
import { LogisticsUnitLightModel } from '../ProductionSitesReferential/services/dataContracts/queryStack/LogisticsUnitLightModel';
import { LogisticsUnitLogisticianLightModel } from '../ProductionSitesReferential/services/dataContracts/queryStack/LogisticsUnitLogisticianLightModel';
import '../ReferentialsStyles.scss';
import { HeaderContentComponent } from './components/HeaderContentComponent';
import { LogisticiansComponent } from './components/LogisticiansComponent';
import { LogisticsUnitComponent } from './components/LogisticsUnitComponent';
import './LogisticsUnitLogisticiansReferentialStyles.scss';
import { LogisticsUnitLightModelExtended } from './models/LogisticsUnitLightModelExtended';
import { LogisticsUnitLogisticianLightModelExtended } from './models/LogisticsUnitLogisticianLightModelExtended';
import { SelectedLogisticsUnitModel } from './models/SelectedLogisticsUnitModel';
import { LogisticsUnitLogisticiansReferentialApiClient } from './services/LogisticsUnitLogisticiansReferentialApiClient';

export const LogisticsUnitLogisticiansReferentialView = () => {
    const inputSearchLogisticsUnitsRef = React.useRef(null);
    const initialSortLogisticsUnits: SortDescriptor[] = [{ field: 'label', dir: 'asc' }];
    const initialSortLogisticians: SortDescriptor[] = [{ field: 'lastName', dir: 'asc' }];

    const [logisticsUnitsArray, setLogisticsUnitsArray] = useState<Array<LogisticsUnitLightModelExtended>>([]);
    const [logisticsUnitsList, setLogisticsUnitsList] = useState<Map<string, LogisticsUnitLightModelExtended>>(new Map<string, LogisticsUnitLightModelExtended>());

    const [selectedLogisticsUnit, setSelectedLogisticsUnit] = useState<SelectedLogisticsUnitModel>({ logisticsUnitId: null, name: null });

    const [selectedLogisticiansArray, setSelectedLogisticiansArray] = useState<Array<LogisticsUnitLogisticianLightModelExtended>>([]);
    const [selectedLogisticiansList, setSelectedLogisticiansList] = useState<Map<string, LogisticsUnitLogisticianLightModelExtended>>(new Map<string, LogisticsUnitLogisticianLightModelExtended>());

    const [selectedLogisticianUserName, setSelectedLogisticianUserName] = useState<string>(null);
    const [selectedLogisticianUserEmail, setSelectedLogisticianUserEmail] = useState<string>(null);
    const [selectedLogisticianUserPhoneNumber, setSelectedLogisticianUserPhoneNumber] = useState<string>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingLogisticiansOfLogisticsUnit, setLoadingLogisticiansOfLogisticsUnit] = useState<boolean>(false);
    const [sortLogisticsUnit, setSortLogisticsUnit] = useState<SortDescriptor[]>(initialSortLogisticsUnits);
    const [sortLogisticians, setSortLogisticians] = useState<SortDescriptor[]>(initialSortLogisticians);

    useEffect(() => {
        getLogisticians("");
    }, []);

    const clearSearchText = (): void => {
        inputSearchLogisticsUnitsRef.current.value = "";
        getLogisticians("");
    }

    const logisticsUnitsKeyPressed = debounce((text: string): void => {
        if (text.length >= 3) {
            getLogisticians(text);
        } else if (text.length === 0) {
            getLogisticians("");
        }
    }, 500);

    const getLogisticians = (searchText: string): void => {
        setLoading(true);
        LogisticsUnitLogisticiansReferentialApiClient.GetLogisticsUnits(searchText)
            .then(response => {
                const dataArray = new Array<LogisticsUnitLightModelExtended>();
                const dataDictionary: Map<string, LogisticsUnitLightModelExtended> = new Map<string, LogisticsUnitLightModelExtended>();

                let logisticsUnitsData: Array<LogisticsUnitLightModel> = response.data;

                logisticsUnitsData?.forEach((logisticsUnit: LogisticsUnitLightModel) => {
                    let element: LogisticsUnitLightModelExtended = { ...logisticsUnit, businessActivitiesString: logisticsUnit.productionSiteActivities?.join(", ") ?? "" };
                    dataArray.push(element);
                    dataDictionary.set(element.logisticsUnitId, element);
                });

                setLogisticsUnitsArray(dataArray);
                setLogisticsUnitsList(dataDictionary);
                setSelectedLogisticsUnit({ logisticsUnitId: null, name: null });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleLogisticsUnitSortChange = (e: GridSortChangeEvent): void => {
        setSortLogisticsUnit(e.sort);
    }

    const handleLogisticianSortChange = (e: GridSortChangeEvent): void => {
        setSortLogisticians(e.sort);
    }

    const handleSortLogisticsUnitColumnChange = (sortItems: SortDescriptor[]): void => {
        setSortLogisticsUnit(sortItems);
    }

    const handleSortLogisticianColumnChange = (sortItems: SortDescriptor[]): void => {
        setSortLogisticians(sortItems);
    }

    const handleSelectedLogisticsUnit = (logisticsUnitId: string, name: string, selected: boolean): void => {
        if (selected) {
            logisticsUnitsList.forEach((l: LogisticsUnitLightModelExtended) => {
                if (l.logisticsUnitId === logisticsUnitId)
                    l.selected = true;
                else
                    l.selected = false;
            })
        }
        else {
            logisticsUnitsList.get(logisticsUnitId).selected = false;
        }

        if (selected) {
            setLoadingLogisticiansOfLogisticsUnit(true);
            getLogisticiansByLogisticsUnit(logisticsUnitId, () => setSelectedLogisticsUnit({ logisticsUnitId: logisticsUnitId, name: name }));
        }
        else {
            setSelectedLogisticiansArray([]);
            setSelectedLogisticiansList(new Map<string, LogisticsUnitLogisticianLightModelExtended>());

            setSelectedLogisticsUnit({ logisticsUnitId: null, name: null });
        }

        setSelectedLogisticianUserName(null);
        setSelectedLogisticianUserEmail(null);
        setSelectedLogisticianUserPhoneNumber(null);
    }

    const getLogisticiansByLogisticsUnit = (logisticsUnitId: string, callback?: () => void): void => {
        LogisticsUnitLogisticiansReferentialApiClient.GetLogisticiansByLogisticsUnit(logisticsUnitId)
            .then(response => {

                let logisticians = new Array<LogisticsUnitLogisticianLightModelExtended>();
                let logisticiansDico: Map<string, LogisticsUnitLogisticianLightModelExtended> = new Map<string, LogisticsUnitLogisticianLightModelExtended>();
                response.data.forEach((element: LogisticsUnitLogisticianLightModel) => {
                    let logistician: LogisticsUnitLogisticianLightModelExtended = {
                        ...element,
                        selected: selectedLogisticianUserName === element.userName ?? false
                    };
                    logisticians.push(logistician);
                    logisticiansDico.set(logistician.userName, logistician);
                });

                if (!logisticians) {
                    logisticians = [];
                    logisticiansDico = new Map<string, LogisticsUnitLogisticianLightModelExtended>();
                }

                setSelectedLogisticiansArray(logisticians);
                setSelectedLogisticiansList(logisticiansDico);

                if (callback) {
                    callback();
                }
            })
            .finally(() => {
                setLoadingLogisticiansOfLogisticsUnit(false);
            });
    }

    const handleAfterSignatureAddedOrUpdated = (): void => {
        setLoadingLogisticiansOfLogisticsUnit(true);
        getLogisticiansByLogisticsUnit(selectedLogisticsUnit.logisticsUnitId);
    }

    const handleSelectedLogistician = (userName: string, userEmail: string, userPhoneNumber: string, selected: boolean): void => {
        if (selected) {
            selectedLogisticiansList.forEach((l: LogisticsUnitLogisticianLightModelExtended) => {
                if (l.userName === userName) {
                    l.selected = true;
                    setSelectedLogisticianUserName(userName);
                    setSelectedLogisticianUserEmail(userEmail);
                    setSelectedLogisticianUserPhoneNumber(userPhoneNumber);
                }
                else {
                    l.selected = false;
                }
            });
        }
        else {
            selectedLogisticiansList.get(userName).selected = false;
            setSelectedLogisticianUserName(null);
            setSelectedLogisticianUserEmail(null);
            setSelectedLogisticianUserPhoneNumber(null);
        }
    }

    const inputSearchContactsValue = inputSearchLogisticsUnitsRef.current === null || inputSearchLogisticsUnitsRef.current === undefined ? '' : inputSearchLogisticsUnitsRef.current.value;


    const headerContentComponent: JSX.Element = useMemo(() =>
        <HeaderContentComponent inputSearchLogisticsUnitsValue={inputSearchContactsValue}
            inputSearchLogisticsUnitsRef={inputSearchLogisticsUnitsRef}
            selectedLogisticianUserName={selectedLogisticianUserName}
            selectedLogisticianUserEmail={selectedLogisticianUserEmail}
            selectedLogisticianUserPhoneNumber={selectedLogisticianUserPhoneNumber}
            handleLogisticsUnitsKeyPress={logisticsUnitsKeyPressed}
            handleClearSearchText={clearSearchText}
            handleAfterSignatureAddedOrUpdated={handleAfterSignatureAddedOrUpdated}
        />, [inputSearchContactsValue, inputSearchLogisticsUnitsRef, selectedLogisticianUserName, selectedLogisticianUserEmail, selectedLogisticianUserPhoneNumber]);

    const logisticsUnitComponent: JSX.Element = useMemo(() =>
        <LogisticsUnitComponent
            logisticsUnitsArray={logisticsUnitsArray}
            sort={sortLogisticsUnit}
            handleSortChange={handleLogisticsUnitSortChange}
            handleSortColumnChange={handleSortLogisticsUnitColumnChange}
            handleSelectedLogisticsUnit={handleSelectedLogisticsUnit}
        />, [logisticsUnitsArray, selectedLogisticsUnit, sortLogisticsUnit]);

    const logisticiansComponent: JSX.Element = useMemo(() =>
        <LogisticiansComponent
            selectedLogisticsUnitId={selectedLogisticsUnit.logisticsUnitId}
            sort={sortLogisticians}
            handleSortChange={handleLogisticianSortChange}
            handleSortColumnChange={handleSortLogisticianColumnChange}
            handleSelectedLogistician={handleSelectedLogistician}
            logisticians={selectedLogisticiansArray}
        />, [selectedLogisticsUnit.logisticsUnitId, sortLogisticians, selectedLogisticiansArray, selectedLogisticiansList, selectedLogisticianUserName]);

    return (
        <Box className="logisticsUnits-logisticians referencial">
            <Box display="flex" flexDirection="row" flex="wrap" className="view-title">
                Logisticiens et options
            </Box>
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box display="flex" flexDirection="column" flex="wrap">
                        {headerContentComponent}
                        {(loading ?
                            <ScaleLoaderComponent loading={loading} />
                            :
                            (<Box display='flex' flexDirection="row" width='100%'>
                                <Box width='45%'>
                                    {logisticsUnitComponent}
                                </Box>
                                <Box pl={2} width='55%'>
                                    {loadingLogisticiansOfLogisticsUnit ?
                                        <ScaleLoaderComponent loading={loadingLogisticiansOfLogisticsUnit} />
                                        :
                                        selectedLogisticsUnit.logisticsUnitId &&
                                        logisticiansComponent
                                    }
                                </Box>
                            </Box>
                            ))}
                    </Box>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}
