import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult, WebAppActionResultEx } from '../../../shared/models/WebAppActionResult';
import { ContactRequestArgs } from './dataContracts/controller/ContactRequestArgs';
import { DeleteAttachmentArgs } from './dataContracts/controller/DeleteAttachmentArgs';
import { NegotiatedPurchasePricesRequestArgs } from './dataContracts/controller/NegotiatedPurchasePricesRequestArgs';
import { TransportFlowPayerZephyrJobCodeRequestArgs } from './dataContracts/controller/TransportFlowPayerZephyrJobCodeRequestArgs';
import { TransportFlowRequestArgs } from './dataContracts/controller/TransportFlowRequestArgs';
import { AgencyLightModel } from './dataContracts/queryStack/AgencyLightModel';
import { ContactLightModel } from './dataContracts/queryStack/ContactLightModel';
import { Level2ActivityLightModel } from './dataContracts/queryStack/Level2ActivityLightModel';
import { Level3SegmentationLightModel } from './dataContracts/queryStack/Level3SegmentationLightModel';
import { LogisticsUnitLightModel } from './dataContracts/queryStack/LogisticsUnitLightModel';
import { NegotiatedPurchasePriceLightModel } from './dataContracts/queryStack/NegotiatedPurchasePriceLightModel';
import { ProductionSiteLightModel } from './dataContracts/queryStack/ProductionSiteLightModel';
import { ReferencedCustomerLightModel } from './dataContracts/queryStack/ReferencedCustomerLightModel';
import { TransportFlowLightModel } from './dataContracts/queryStack/TransportFlowLightModel';
import { TransportRequestLightModel } from './dataContracts/queryStack/TransportRequestLightModel';
import { VehicleTypeEstimatedLoadCapacityLightModel } from './dataContracts/queryStack/VehicleTypeEstimatedLoadCapacityLightModel';
import { JobSiteInfo } from './dataContracts/controller/JobSiteInfo';

const controllerUrl = 'api/TransportFlow/';

export class TransportFlowApiClient {
    public static SearchTransportRequestsByDates = (fromTime: Date, untilTime: Date, logisticsUnitId: string)
        : Promise<AxiosResponse<Array<TransportRequestLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}SearchTransportRequestsByDates?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}&fromTime=${encodeURIComponent(fromTime.toJSON())}&untilTime=${encodeURIComponent(untilTime.toJSON())}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static SearchLevel3Seg = (logisticsUnitId: string)
        : Promise<AxiosResponse<Array<Level3SegmentationLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}SearchLevel3SegmentationByLogisticsUnit?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetVehicleTypes = ()
        : Promise<AxiosResponse<Array<VehicleTypeEstimatedLoadCapacityLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetVehicleTypes`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static SearchRequestedByList = (requestArgs: ContactRequestArgs)
        : Promise<AxiosResponse<Array<ContactLightModel>>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SearchRequestedByList`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static SearchBeneficiaryList = (requestArgs: ContactRequestArgs)
        : Promise<AxiosResponse<Array<ContactLightModel>>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SearchBeneficiaryList`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static SearchProductionSiteList = (logisticsUnitId: string)
        : Promise<AxiosResponse<Array<ProductionSiteLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}SearchProductionSiteList?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static SearchAgencyList = (logisticsUnitId: string)
        : Promise<AxiosResponse<Array<AgencyLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}SearchAgencyList?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static SearchReferencedCustomerList = (logisticsUnitId: string)
        : Promise<AxiosResponse<Array<ReferencedCustomerLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}SearchReferencedCustomerList?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static SearchLevel2ActivityList = (productionSiteId: string, logisticsUnitId: string)
        : Promise<AxiosResponse<Array<Level2ActivityLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}SearchLevel2ActivityList?productionSiteId=${encodeURIComponent(productionSiteId)}&logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetTransportFlowById = (transportFlowId: string)
        : Promise<AxiosResponse<TransportFlowLightModel>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetTransportFlowById?transportFlowId=${encodeURIComponent(transportFlowId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetLevel3SegmentationById = (level3SegmentationId: string)
        : Promise<AxiosResponse<Level3SegmentationLightModel>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetLevel3SegmentationById?level3SegmentationId=${encodeURIComponent(level3SegmentationId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetContactByFullName = (lastName: string, firstName: string, isInternal: boolean)
        : Promise<AxiosResponse<ContactLightModel>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetContactByFullName?lastName=${encodeURIComponent(lastName)}&firstName=${encodeURIComponent(firstName)}&isInternal=${encodeURIComponent(isInternal)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetProductionSiteById = (productionSiteId: string)
        : Promise<AxiosResponse<ProductionSiteLightModel>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetProductionSiteById?productionSiteId=${encodeURIComponent(productionSiteId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetAgencyById = (agencyId: string)
        : Promise<AxiosResponse<AgencyLightModel>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetAgencyById?agencyId=${encodeURIComponent(agencyId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetCustomerById = (customerMdmId: string)
        : Promise<AxiosResponse<ReferencedCustomerLightModel>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetCustomerById?customerMdmId=${encodeURIComponent(customerMdmId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetLevel2ActivityById = (level2ActivityId: string)
        : Promise<AxiosResponse<Level2ActivityLightModel>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetLevel2ActivityById?level2ActivityId=${encodeURIComponent(level2ActivityId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetLogisticsUnitById = (logisticsUnitId: string)
        : Promise<AxiosResponse<LogisticsUnitLightModel>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetLogisticsUnitById?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetPayerLabel = (payerIdentifier: string, payerEntity: string)
        : Promise<AxiosResponse<string>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetPayerLabel?payerIdentifier=${encodeURIComponent(payerIdentifier)}&payerEntity=${encodeURIComponent(payerEntity)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetJobCodeZephyr = (requestArgs: TransportFlowPayerZephyrJobCodeRequestArgs, refreshCache: boolean)
        : Promise<AxiosResponse<string>> => {
        return axios({
            method: 'POST',
            data: requestArgs,
            url: `${controllerUrl}GetJobCodeZephyr?refreshCache=${refreshCache}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetTransportRequestDeliveryRemarks = (transportRequestId: string)
        : Promise<AxiosResponse<string>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetTransportRequestDeliveryRemarks?transportRequestId=${encodeURIComponent(transportRequestId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetProductionSiteByIdAndLevel2ActivityById = (productionSiteId: string, level2ActivityId: string)
        : Promise<[AxiosResponse<ProductionSiteLightModel>, AxiosResponse<Level2ActivityLightModel>]> => {
        return Promise.all([
            TransportFlowApiClient.GetProductionSiteById(productionSiteId),
            TransportFlowApiClient.GetLevel2ActivityById(level2ActivityId)
        ]);
    }

    public static SearchProductionSitesWithAgenciesAndReferencedCustomersAndInternalBeneficiariesAndRequesters = (logisticsUnitId: string)
        : Promise<[AxiosResponse<Array<ProductionSiteLightModel>>, AxiosResponse<Array<AgencyLightModel>>, AxiosResponse<Array<ReferencedCustomerLightModel>>, AxiosResponse<Array<ContactLightModel>>
        , AxiosResponse<Array<ContactLightModel>>]> => {
        const contactRequestArgs: ContactRequestArgs = {
            logisticsUnitId: logisticsUnitId,
            isInternal: true,
            referencedCustomerMdmId: null
        };
        return Promise.all([
            TransportFlowApiClient.SearchProductionSiteList(logisticsUnitId),
            TransportFlowApiClient.SearchAgencyList(logisticsUnitId),
            TransportFlowApiClient.SearchReferencedCustomerList(logisticsUnitId),
            TransportFlowApiClient.SearchBeneficiaryList(contactRequestArgs),
            TransportFlowApiClient.SearchRequestedByList(contactRequestArgs)
        ]);
    }

    public static CreateFlow = (requestArgs: TransportFlowRequestArgs)
        : Promise<AxiosResponse<WebAppActionResultEx<string>>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}CreateTransportFlow`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateFlow = (transportFlowRequestArgs: TransportFlowRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateTransportFlow`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(transportFlowRequestArgs)
        });
    }

    public static GetAttachmentsSaveUrl = (): string => {
        return `${controllerUrl}AddAttachments`;
    }

    public static DeleteAttachments = (requestArgs: DeleteAttachmentArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}DeleteAttachments`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static GetNegotiatedPurchasePrices(requestArgs: NegotiatedPurchasePricesRequestArgs): Promise<AxiosResponse<Array<NegotiatedPurchasePriceLightModel>>> {
        return axios({
            method: 'POST',
            data: requestArgs,
            url: `${controllerUrl}GetNegotiatedPurchasePrices`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static SearchJobSiteByMdmId(mdmId: string): Promise<AxiosResponse<JobSiteInfo>> {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SearchJobSiteByMdmId?mdmId=${mdmId}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }
}
