import axios from 'axios';
import { SettingsProvider } from 'src/SettingsProvider';

const baseUrl = "https://atlas.microsoft.com/";

export interface GpsCoordinates {
    longitude: number,
    latitude: number
}

export interface TripEstimation {
    durationInSeconds: number,
    distanceInMeters: number,
    legs: Array<Leg>
}

export interface Leg {
    points: Array<GpsCoordinates>
}

export interface Address {
    id?: string,
    line1?: string,
    zipCode: string,
    city: string,
    country: string,
    freeformAddress?: string,
    position?: GpsCoordinates
}

interface IAzureMapsAddress {
    entityType?: string,
    streetNumber: string,
    streetName: string,
    municipalitySubdivision: string,
    municipality: string,
    postalCode: string,
    country: string,
    freeformAddress: string
}

export class MapsService {
    private static _citiesWithMunicipalArrondissements: Map<string, string> = null;

    private static GetAzureMapsApiKey = (): string => { return SettingsProvider.Get().azureMapsApiKey; }

    private static EnsureCitiesWithMunicipalArrondissements() {
        if (MapsService._citiesWithMunicipalArrondissements !== null)
            return;

        const config = SettingsProvider.Get();
        const citiesWithMunicipalArrondissements = new Map<string, string>();
        config.citiesWithMunicipalArrondissements.split(",").forEach((city: string) => {
            citiesWithMunicipalArrondissements.set(city, city);
        });
        MapsService._citiesWithMunicipalArrondissements = citiesWithMunicipalArrondissements;
    }

    public static GetCitiesWithMunicipalArrondissements(): Map<string, string> {
        MapsService.EnsureCitiesWithMunicipalArrondissements();
        return MapsService._citiesWithMunicipalArrondissements;
    }

    public static ResolveAddress(address: string): Promise<GpsCoordinates> {
        const url = `${baseUrl}search/address/json?api-version=1.0&subscription-key=${MapsService.GetAzureMapsApiKey()}&countrySet=FR&query=${encodeURIComponent(address)}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            const position = res.data.results[0]?.position;
            if (position) {
                const ret: GpsCoordinates =
                {
                    latitude: position.lat,
                    longitude: position.lon
                };
                return ret;
            }
            return null;
        });
    }

    public static GetTripEstimation(pickupCoord: GpsCoordinates, deliveryCoord: GpsCoordinates, arrivalTimeAtSenderSiteIsFixed: boolean, arrivalTimeAtSenderSite: Date, arrivalTimeAtReceiverSite: Date): Promise<TripEstimation> {
        const routingRequestUrl = `${baseUrl}route/directions/json?api-version=1&subscription-key={subscription-key}&query={query}`;

        const query = [];
        query.push(pickupCoord.latitude + ',' + pickupCoord.longitude);
        query.push(deliveryCoord.latitude + ',' + deliveryCoord.longitude);
        const waypointQuery = query.join(':');
        let requestUrl = routingRequestUrl.replace('{subscription-key}', MapsService.GetAzureMapsApiKey()).replace('{query}', waypointQuery);

        //SYNC WITH AzureMapsTripEstimator.cs
        requestUrl += '&routeRepresentation=polyline&view=Auto&computeBestOrder=true';
        requestUrl += '&travelMode=truck&vehicleAxleWeight=1000&vehicleHeight=3.8&vehicleLength=18.75&vehicleWidth=2.54&vehicleWeight=25500&vehicleMaxSpeed=80&vehicleLoadType=otherHazmatGeneral';

        //NOTE YBE : Ancien code développé pour palier au problème de la map Azure qui supportait pas les date du passé 
        // la version actuelle supporte le passé
        // L'ancien code reste présent pour garder l'historique
        /* 
        const now = new Date();
        if (arrivalTimeAtSenderSiteIsFixed)
            arrivalTimeAtSenderSite > now ? requestUrl += '&departAt=' + encodeURIComponent(arrivalTimeAtSenderSite.toJSON()) : requestUrl += '&departAt=' + encodeURIComponent(getNextFutureDateOnIdenticalCalendar(arrivalTimeAtSenderSite, now).toJSON());
        else
            arrivalTimeAtReceiverSite > now ? requestUrl += '&arriveAt=' + encodeURIComponent(arrivalTimeAtReceiverSite.toJSON()) : requestUrl += '&arriveAt=' + encodeURIComponent(getNextFutureDateOnIdenticalCalendar(arrivalTimeAtReceiverSite, now).toJSON());
        */

        if (arrivalTimeAtSenderSiteIsFixed)
            requestUrl += '&departAt=' + encodeURIComponent(arrivalTimeAtSenderSite.toJSON());
        else
            requestUrl += '&arriveAt=' + encodeURIComponent(arrivalTimeAtReceiverSite.toJSON());

        return axios.get(requestUrl)
            .then((response) => {
                return response ? response.data : null;
            }).then((res) => {
                if (!res) {
                    return null;
                }

                const route = res.routes[0];
                if (!route) {
                    return null;
                }
                const ret: TripEstimation = {
                    distanceInMeters: route.summary.lengthInMeters,
                    durationInSeconds: route.summary.travelTimeInSeconds,
                    legs: route.legs as Array<Leg>
                }
                return ret;
            });
    }

    public static GetAddressFromCoordinates(coordinates: GpsCoordinates): Promise<Address> {
        const requestUrl = `${baseUrl}search/address/reverse/json?subscription-key=${MapsService.GetAzureMapsApiKey()}&countrySet=FR&api-version=1.0&query=${coordinates.latitude},${coordinates.longitude}`;

        return axios({
            method: 'GET',
            url: requestUrl,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            const address: IAzureMapsAddress = res.data?.addresses[0].address;
            if (!address) {
                return null;
            }

            const addressItem: Address = MapsService.GetAddress(address);
            addressItem.line1 = MapsService.GetLine1(address.streetNumber?.trim(), address.streetName?.trim());
            return addressItem;
        });
    }

    public static SearchAddress(searchText: string): Promise<Address[]> {
        const autorizedCountries = "FR,ES,IT,BE,LU,DE,CH,MC,AD,GB,PT";
        const url = `${baseUrl}search/address/json?api-version=1.0&subscription-key=${MapsService.GetAzureMapsApiKey()}&countrySet=${autorizedCountries}&limit=100&query=${encodeURIComponent(searchText)}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            const addresses: Address[] = [];

            const results = res.data?.results;
            if (results && results.length > 0) {
                for (const x of results) {
                    const entityType = x.entityType;
                    const address: IAzureMapsAddress = x.address;
                    const addressPosition = x.position;
                    if (address && address.postalCode) {
                        const line1 = MapsService.GetLine1(address.streetNumber?.trim(), address.streetName?.trim());
                        address.entityType = entityType;
                        const addressItem: Address = MapsService.GetAddress(address);
                        const position: GpsCoordinates = {
                            latitude: addressPosition?.lat,
                            longitude: addressPosition?.lon
                        };
                        addressItem.id = x.id;
                        addressItem.line1 = line1;
                        addressItem.position = position;

                        addresses.push(addressItem);
                        if (addresses.length >= 5)
                            break;
                    }
                }
            }

            return addresses;
        });
    }

    private static GetLine1 = (streetNumber: string, streetName: string): string => {
        let line1 = "";
        if (streetNumber && streetName)
            line1 = streetNumber + " " + streetName;
        else if (!streetNumber && streetName)
            line1 = streetName;
        else if (streetNumber && !streetName)
            line1 = streetNumber;

        return line1;
    }

    private static GetAddress = (address: IAzureMapsAddress): Address => {
        let city = "";
        let formatedFreeformAddress = "";
        const citiesWithMunicipalArrondissements: Map<string, string> = MapsService.GetCitiesWithMunicipalArrondissements();
        const isCityWithMunicipalArrondissement = citiesWithMunicipalArrondissements.get(address.municipality) ?? false;

        //en cas de recherche avec un code postal commun (ex: "90140 Recouvrance"), les resultats de type "PostalCodeArea" comportent dans "municipality" la liste des villes associées. On récupère dans ce cas le premier uniquement, en phase avec celui affiché dans "freeformAddress".
        //voir bug #17929 https://dev.azure.com/digitalfactory-speig-1/transporteo/_workitems/edit/17929
        if (address.entityType === "PostalCodeArea" && address.municipality?.includes(",")) {
            city = address.municipality?.substring(0, address.municipality.indexOf(","));
            formatedFreeformAddress = address.freeformAddress;
        } else {
            city = !isCityWithMunicipalArrondissement && address.municipalitySubdivision ? address.municipalitySubdivision : address.municipality;
            formatedFreeformAddress = !isCityWithMunicipalArrondissement && address.municipalitySubdivision ? `${address.freeformAddress} (${address.municipalitySubdivision})` : address.freeformAddress;
        }

        const addressItem: Address = {
            freeformAddress: formatedFreeformAddress,
            city: city,
            zipCode: address.postalCode,
            country: address.country
        };

        return addressItem;
    }

}
