import { Box } from '@mui/material';
import React, { useState } from 'react';
import Select, { ActionMeta, components, OptionProps } from 'react-select';
import { SelectOptionModel } from '../models/SelectOptionModel';

interface AddExistingPurchasePricesTemplateProperties {
    options: Array<SelectOptionModel>,
    onClose: () => void,
    handleChangeTransportersSelected: (selectedTransportersIds: Array<string>) => void
}

export interface OptionPropsModelExtended extends OptionProps<SelectOptionModel> {
    isCheckAllSelected?: boolean
}

const OptionItem = (props: OptionPropsModelExtended): JSX.Element => {

    const isSelectAllItemChecked: boolean = props.data.value === "selectAllTransporters" && props.isCheckAllSelected;

    return (
        <div>
            <components.Option {...props} className={isSelectAllItemChecked ? "selected-item" : ''}>
                <input
                    type="checkbox"
                    checked={props.data.value === "selectAllTransporters" ? props.isCheckAllSelected : props.isSelected}
                    onChange={() => null}
                />{" "}
                <label className={isSelectAllItemChecked ? "selected-item-label" : ''}>{props.data.label}</label>
            </components.Option>
        </div>
    );
}

export const AddExistingPurchasePricesTemplateComponent = (props: AddExistingPurchasePricesTemplateProperties): JSX.Element => {

    const selectAllOption: SelectOptionModel = { label: "Sélectionner tout", value: "selectAllTransporters" };

    const [selectedOptions, setSelectedOptions] = useState<SelectOptionModel[]>([]);
    const [isCheckAllSelected, setIsCheckAllSelected] = useState<boolean>(false);

    const handleChangeTransportersSelected = (opts: Array<SelectOptionModel>, event: ActionMeta<SelectOptionModel>): void => {
        if (event.action === "select-option" && event.option.value === "selectAllTransporters" && isCheckAllSelected)
        {
            setIsCheckAllSelected(false);
            setSelectedOptions([]);
            props.handleChangeTransportersSelected([]);
            return;
        }
        
        switch (event.action) {
            case "select-option":
                setOptions(event.option.value, opts);
                break;
            case "deselect-option":
                removeOptions(event.option.value, opts);
                break;
            case "remove-value":
                removeOptions(event.removedValue.value, opts);
                break;
            case "clear":
                if (isCheckAllSelected)
                    setIsCheckAllSelected(false);

                setSelectedOptions([]);
                props.handleChangeTransportersSelected([]);
                break;
            default:
                return;
        }
    }

    const setOptions = (value: string, opts: Array<SelectOptionModel>): void => {
        if (value === "selectAllTransporters") {
            setSelectedOptions(props.options);
            setIsCheckAllSelected(true);
            props.handleChangeTransportersSelected(props.options.map(o => o.value));
        } else {
            if (opts.length === props.options.length)
                setIsCheckAllSelected(true);

            setSelectedOptions(opts);
            props.handleChangeTransportersSelected(opts.map(o => o.value));
        }
    }

    const removeOptions = (value: string, opts: Array<SelectOptionModel>): void => {
        if (value === "selectAllTransporters") {
            setSelectedOptions([]);
            setIsCheckAllSelected(false);
            props.handleChangeTransportersSelected([]);
        } else {
            if (isCheckAllSelected)
                setIsCheckAllSelected(false);

            const options: Array<SelectOptionModel> = opts.filter(x => x.value !== value);
            setSelectedOptions(options);
            props.handleChangeTransportersSelected(options.map(o => o.value));
        }
    }

    return (
        <Box className="add-existing-purchase-prices-template-dialog" display='flex' flexDirection='column' p={1}>
            <Box pb={1} maxWidth={'700px'}>
                <Select
                    isMulti
                    options={[selectAllOption, ...props.options]}
                    value={selectedOptions}
                    onChange={(opts: SelectOptionModel[], event) => handleChangeTransportersSelected(opts, event)}
                    menuPosition="fixed"
                    menuShouldBlockScroll={true}
                    components={{
                        Option: (props) => <OptionItem {...props} isCheckAllSelected={isCheckAllSelected} />
                    }}
                    hideSelectedOptions={false}
                    blurInputOnSelect={false}
                    closeMenuOnSelect={false}
                />
            </Box>
            <Box pb={2} className="warning-message">
                Seuls les tarifs des types de véhicule actifs seront exportés
            </Box>
        </Box>
    );
}