import { faFileExcel, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, IconButton, Input, InputAdornment, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import Select from 'react-select';
import { CalendarComponent } from '../../../../shared/components/Common/CalendarComponent';
import { DateRange } from '../../../../shared/models/DateRange';
import '../../../../utils/Number';
import { ProductionForemanSelectModel } from '../models/ProductionForemanSelectModel';

interface ContentHeaderComponentProps {
    inputSearchVehiclesValue: string,
    inputSearchVehiclesRef: React.RefObject<HTMLInputElement>,
    path: string,
    date: DateRange,
    listProductionForeman: ProductionForemanSelectModel[],
    btnExportEnabled: boolean,
    selectedProductionForeman: ProductionForemanSelectModel,
    handleChangeDateRange: (range: DateRange) => void,
    clearSearchText: () => void,
    handleProductionForemanChange: (event: ProductionForemanSelectModel) => void,
    handleVehiclesTripsKeyPress: (text: string) => void,
    exportToExcel: () => void
}

export const ContentHeaderComponent = (props: ContentHeaderComponentProps) => {
    const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);

    const handleIsDateRangeValidChanged = (isValid: boolean): void => {
        setIsDateRangeValid(isValid);
    }

    return (
        <Box display="flex" flexDirection="row" flex='wrap' justifyContent="space-between" className="content-header">
            <Box display="flex" flexDirection="row" flex='wrap' minWidth={'650px'} width="60%" alignItems="center">
                <Box>
                    <CalendarComponent calendarId='production-foreman-management-daterange-picker'
                        startDate={props.date.start} endDate={props.date.end} maximumNumberOfMonths={2} isValid={isDateRangeValid}
                        handleIsValidChanged={handleIsDateRangeValidChanged} handlerFromChildToParent={props.handleChangeDateRange} />
                </Box>
                <Box pl={4}>
                    <Input disableUnderline className={props.inputSearchVehiclesValue.length > 2 ? 'input-search-vehicles search-text-active' : 'input-search-vehicles'} inputRef={props.inputSearchVehiclesRef}
                        endAdornment={<>
                            <InputAdornment position="end" classes={
                                {
                                    root: 'input-icon-close-root'
                                }
                            }>
                                <FontAwesomeIcon icon={faTimes} onClick={() => props.clearSearchText()} />
                            </InputAdornment>
                            <InputAdornment position="end" classes={
                                {
                                    root: 'input-icon-search-root'
                                }
                            }>
                                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                            </InputAdornment>
                        </>}
                        id="search-text-vehicle-grid" placeholder="Rechercher..." onChange={(event) => props.handleVehiclesTripsKeyPress(event.target.value)} />
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" flex='wrap' width="40%" justifyContent="flex-end">
                {props.path === "ThirdPartyProduction" &&
                    <Box display="flex" flexDirection="row" flex='nowrap' justifyContent="center" className="production-foreman-section" pr={4}>
                        <Box className="production-foreman-label">
                            Production :
                        </Box>
                        <Box>
                            <Select
                                className="production-foreman-select"
                                isClearable
                                placeholder=""
                                menuPosition="fixed"
                                menuShouldBlockScroll={true}
                                options={props.listProductionForeman}
                                value={props.selectedProductionForeman}
                                onChange={(e) => props.handleProductionForemanChange(e)}
                            />
                        </Box>
                    </Box>
                }
                <Tooltip title="Exporter sous format Excel" placement="bottom">
                    <Avatar className={`btn-actions ${props.btnExportEnabled ? "btn-file-excel-avatar" : ""}`}>
                        <IconButton size="small" disabled={!props.btnExportEnabled} className={props.btnExportEnabled ? "btn-file-excel-icon" : ""} aria-label="Add" onClick={props.exportToExcel}>
                            <FontAwesomeIcon icon={faFileExcel} />
                        </IconButton>
                    </Avatar>
                </Tooltip>
            </Box>
        </Box>
    );
}
