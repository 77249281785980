import { Box } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { UploadOnBeforeUploadEvent, UploadResponse } from '@progress/kendo-react-upload';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ImportErrorsExtraResult, LineImportErrors } from 'src/shared/models/ImportErrorsExtraResult';
import { WebAppActionResultEx } from 'src/shared/models/WebAppActionResult';
import ToastService from 'src/ToastService';
import { ScaleLoaderComponent } from '../../../shared/components/Common/ScaleLoaderComponent';
import SimpleDialog from '../../../shared/components/Common/SimpleDialog';
import BusinessErrors from '../../../utils/BusinessErrors';
import Utilities from '../../../utils/Utilities';
import '../ReferentialsStyles.scss';
import { AddCustomerDialogComponent } from './components/AddCustomerDialog/AddCustomerDialogComponent';
import { CustomerComponent } from './components/CustomerComponent';
import { CustomerContactsComponent } from './components/CustomerContactsComponent';
import { EditCustomerDialogComponent } from './components/EditCustomerDialogComponent';
import { HeaderContentComponent } from './components/HeaderContentComponent';
import './CustomerContactsReferentialStyles.scss';
import { ContactCustomerData } from './models/ContactCustomerData';
import { ContactCustomerLightModelExtended } from './models/ContactCustomerLightModelExtended';
import { CustomerCandidateToAddExtended } from './models/CustomerCandidateToAddExtended';
import { CustomerContactRequestArgsExtended } from './models/CustomerContactRequestArgsExtended';
import { CustomerData } from './models/CustomerData';
import { CustomerLightModelExtended } from './models/CustomerLightModelExtended';
import { SelectedCustomerModel } from './models/SelectedCustomerModel';
import { CustomerContactsReferentialApiClient } from './services/CustomerContactsReferentialApiClient';
import { AddCustomerRequestArgs } from './services/dataContracts/controller/AddCustomerRequestArgs';
import { CustomerCandidateToAdd } from './services/dataContracts/controller/CustomerCandidateToAdd';
import { CustomerContactRequestArgs } from './services/dataContracts/controller/CustomerContactRequestArgs';
import { UpdateCustomerRequestArgs } from './services/dataContracts/controller/UpdateCustomerRequestArgs';
import { ContactCustomerLightModel } from './services/dataContracts/queryStack/ContactCustomerLightModel';
import { CustomerLightModel } from './services/dataContracts/queryStack/CustomerLightModel';
import { LogisticsUnitChoiceOfContactsLightModel } from './services/dataContracts/queryStack/LogisticsUnitChoiceOfContactsLightModel';
import { LogisticsUnitChoiceOfCustomersLightModel } from './services/dataContracts/queryStack/LogisticsUnitChoiceOfCustomersLightModel';

const IMPORT_CUSTOMERCONTACTS_CHOICES = 'CustomerContactsChoices';

export const CustomerContactsReferentialView = () => {
    const inputSearchCustomersRef = React.useRef(null);
    const initialSort: SortDescriptor[] = [{ field: 'lastName', dir: 'asc' }];

    const [customersList, setCustomersList] = useState<Map<string, CustomerLightModelExtended>>(new Map<string, CustomerLightModelExtended>());
    const [customersArray, setCustomersArray] = useState<Array<CustomerLightModelExtended>>([]);
    const [customersChoicesLogisticsUnit, setCustomersChoicesLogisticsUnit] = useState<Array<LogisticsUnitChoiceOfCustomersLightModel>>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<SelectedCustomerModel>({ customerMdmId: null, name: null });
    const [selectedCustomerContacts, setSelectedCustomerContacts] = useState<Map<string, ContactCustomerLightModelExtended>>(new Map<string, ContactCustomerLightModelExtended>());
    const [selectedCustomerContactsArray, setSelectedCustomerContactsArray] = useState<Array<ContactCustomerLightModelExtended>>([]);
    const [contactLogisticsUnitsChoices, setContactLogisticsUnitsChoices] = useState<Array<LogisticsUnitChoiceOfContactsLightModel>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingContactsOfCustomer, setLoadingContactsOfCustomer] = useState<boolean>(false);
    const [isGlobalSearch, setIsGlobalSearch] = useState<boolean>(false);
    const [isAddCustomerDialogOpened, setIsAddCustomerDialogOpened] = useState<boolean>(false);
    const [isEditCustomerDialogOpened, setIsEditCustomerDialogOpened] = useState<boolean>(false);
    const [sortCustomer, setCustomerSort] = useState<SortDescriptor[]>(initialSort);
    const [sortContact, setContactSort] = useState<SortDescriptor[]>(initialSort);
    
    const [activeStep, setActiveStep] = useState<number>(0);
    const [customerIdMdm, setCustomerIdMdm] = useState<string>('');
    const [customerName, setCustomerName] = useState<string>('');
    const [customerSearchLoading, setCustomerSearchLoading] = useState<boolean>(false);
    const [customerChoices, setCustomerChoices] = useState<CustomerCandidateToAddExtended[]>([]);
    const [customerToAdd, setCustomerToAdd] = useState<CustomerCandidateToAdd>(null);

    useEffect(() => {
        getCustomers("", false);
    }, []);

    const clearSearchText = (): void => {
        setIsGlobalSearch(false);
        inputSearchCustomersRef.current.value = "";
        CustomersKeyPressed("", false);
    }

    const ExternalCustomersKeyPressed = debounce((text: string): void => {
        if (text.length >= 3) {
            CustomersKeyPressed(text, isGlobalSearch);
        } else if (text.length === 0) {
            setIsGlobalSearch(false);
            CustomersKeyPressed(text, false);
        }
    }, 500);

    const CustomersKeyPressed = (text: string, isGlobalSearch: boolean): void => {
        getCustomers(text, isGlobalSearch);
    }

    const getCustomers = (searchText: string, isGlobalSearch: boolean, errorMessage?: string, listErrorMessage?: Array<string>, successMessage?: string, endMessage?: string): void => {
        setLoading(true);
        CustomerContactsReferentialApiClient.GetCustomersWithCustomerChoicesLogisticsUnit(searchText, isGlobalSearch)
            .then(response => {
                let customers: Array<CustomerLightModel> = response[0].data;

                if (!customers)
                    customers = [];

                const customerData: CustomerData = getCustomersChoicesData(customers, response[1].data);
                setCustomersList(customerData.map);
                setCustomersArray(customerData.array);
                setCustomersChoicesLogisticsUnit(response[1].data);
                setSelectedCustomer({ customerMdmId: null, name: null });

                if (errorMessage)
                {
                    ToastService.showErrorToast(errorMessage, listErrorMessage, endMessage);
                }
                else if (successMessage){
                    ToastService.showSuccessToast(successMessage);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleCustomerSortChange = (e: GridSortChangeEvent): void => {
        setCustomerSort(e.sort);
    }

    const handleContactSortChange = (e: GridSortChangeEvent): void => {
        setContactSort(e.sort);
    }

    const handleSortCustomerColumnChange = (sortItems: SortDescriptor[]): void => {
        setCustomerSort(sortItems);
    }

    const handleSortContactColumnChange = (sortItems: SortDescriptor[]): void => {
        setContactSort(sortItems);
    }

    const handleSelectedCustomer = (customerMdmId: string, name: string): void => {
        if (selectedCustomer.customerMdmId)
            customersList.get(selectedCustomer.customerMdmId).selected = false;

        if (customerMdmId)
            customersList.get(customerMdmId).selected = true;

        if (customerMdmId) {
            setLoadingContactsOfCustomer(true);

            CustomerContactsReferentialApiClient.GetContactsByCustomerWithContactChoicesLogisticsUnit(customerMdmId)
                .then(response => {
                    let contactsOfCustomer: Array<ContactCustomerLightModel> = response[0].data;

                    if (!contactsOfCustomer)
                        contactsOfCustomer = [];

                    const contactsChoicesData: ContactCustomerData = getContactsChoicesData(contactsOfCustomer, response[1].data);
                    setSelectedCustomerContacts(contactsChoicesData.map);
                    setSelectedCustomerContactsArray(contactsChoicesData.array);
                    setSelectedCustomer({ customerMdmId: customerMdmId, name: name });
                    setContactLogisticsUnitsChoices(response[1].data);
                })
                .finally(() => {
                    setLoadingContactsOfCustomer(false);
                });
        }
    }

    const getContactsChoicesData = (data: Array<ContactCustomerLightModel>, logisticsUnits: Array<LogisticsUnitChoiceOfContactsLightModel>): ContactCustomerData => {
        const dataDictionary = new Map<string, ContactCustomerLightModelExtended>();
        const dataArray = new Array<ContactCustomerLightModelExtended>();
        data.forEach((contacts: ContactCustomerLightModel) => {
            const element: ContactCustomerLightModelExtended = { ...contacts };
            element.isSelectedAll = false;

            let checked_beneficiary = true;
            let checked_requester = true;
            let indeterminateAll = true;
            let numberOfChecked = 0;
            let numberOfUnchecked = 0;
            logisticsUnits.forEach((logisticsUnit: LogisticsUnitChoiceOfContactsLightModel) => {
                const existBeneficiary: boolean = logisticsUnit.chosenBeneficiaries.includes(element.contactId);
                const existRequester: boolean = logisticsUnit.chosenRequesters.includes(element.contactId);

                element[`${logisticsUnit.logisticsUnitId}_beneficiary`] = existBeneficiary;
                element[`${logisticsUnit.logisticsUnitId}_requester`] = existRequester;
                if (!existBeneficiary) {
                    checked_beneficiary = false;
                    numberOfUnchecked += 1;
                } else {
                    numberOfChecked += 1;
                }
                if (!existRequester) {
                    checked_requester = false;
                    numberOfUnchecked += 1;
                } else {
                    numberOfChecked += 1;
                }
            });
            //*2 car il y'a bénéficiaire et demandeur
            if ((logisticsUnits.length * 2) === numberOfUnchecked
                || (logisticsUnits.length * 2) === numberOfChecked) {
                indeterminateAll = false;
            }

            element.isIndeterminateAll = indeterminateAll;
            element.isSelectedAll = (checked_requester && checked_beneficiary) ? true : (indeterminateAll ? null : false);

            dataDictionary.set(element.contactId, element);
            dataArray.push(element);
        });
        return {
            map: dataDictionary,
            array: dataArray
        };
    }

    const getCustomersChoicesData = (data: Array<CustomerLightModel>, logisticsUnits: Array<LogisticsUnitChoiceOfCustomersLightModel>): CustomerData => {
        const dataDictionary: Map<string, CustomerLightModelExtended> = new Map<string, CustomerLightModelExtended>();
        const dataArray: Array<CustomerLightModelExtended> = new Array<CustomerLightModelExtended>();
        data.forEach((purchasePrice: CustomerLightModel) => {
            const element: CustomerLightModelExtended = { ...purchasePrice };
            element.isSelectedAll = false;

            let checked = true;
            let disabled = true;
            let numberOfChecked = 0;
            let numberOfUnchecked = 0;
            logisticsUnits.forEach((logisticsUnit: LogisticsUnitChoiceOfCustomersLightModel) => {
                const exist: boolean = logisticsUnit.chosenExternalCustomers.includes(element.customerMdmId);
                element[logisticsUnit.logisticsUnitId] = exist;
                if (!exist) {
                    checked = false;
                    numberOfUnchecked += 1;
                } else {
                    numberOfChecked += 1;
                }
            });
            if (logisticsUnits.length === numberOfUnchecked || logisticsUnits.length === numberOfChecked) {
                disabled = false;
            }
            element.isIndeterminateAll = disabled;
            element.isSelectedAll = checked ? true : (disabled ? null : false);

            dataDictionary.set(element.customerMdmId, element);
            dataArray.push(element);
        });
        return {
            map: dataDictionary,
            array: dataArray
        };
    }

    const inputSearchContactsValue = inputSearchCustomersRef.current === null || inputSearchCustomersRef.current === undefined ? '' : inputSearchCustomersRef.current.value;

    const handlerAfterUpload = (res: UploadResponse): void => {
        const response: WebAppActionResultEx<ImportErrorsExtraResult> = res ? res.response : null;

        const listErrorMessage: string[] = [];
        let errorMessage = '';
        let successMessage = '';
        let endMessage = '';

        if (res.status === 200 && response) {
            const importLines: Array<LineImportErrors> = response.extraResult.linesWithErrors;
            if (importLines.length > 0) {
                importLines.forEach(e => {
                    e.errors.forEach(eError => {
                        let _libMsg = BusinessErrors.GetError(eError);
                        if (!_libMsg && eError)
                            _libMsg = eError;
                        listErrorMessage.push(`- Ligne ${e.rowIndex} - ${_libMsg}`);
                    });
                });

                if (response.extraResult.importName === IMPORT_CUSTOMERCONTACTS_CHOICES) {
                    errorMessage = 'Erreur lors de l\'import de la visibilité des clients externes:';
                    endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des clients externes sur vos zones logistiques';
                }
                else {
                    errorMessage = 'Erreur lors de l\'import de la visibilité des demandeurs et bénéficiaires du client externe:';
                    endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des demandeurs et bénéficiaires du client externe sur vos zones logistiques';
                }
            }
            else {
                if (response.extraResult.importName === IMPORT_CUSTOMERCONTACTS_CHOICES)
                    successMessage = 'La visibilité des clients externes pour vos zones logistiques a bien été intégrée';
                else
                    successMessage = 'La visibilité des demandeurs et bénéficiaires du client externe pour vos zones logistiques a bien été intégrée';
            }
        }
        else {
            errorMessage = 'Erreur lors de l\'import de la visibilité des clients externes, veuillez vérifier votre fichier';
        }

        getCustomers(inputSearchContactsValue, isGlobalSearch, errorMessage, listErrorMessage, successMessage, endMessage);
    }

    const handlerBeforeUpload = (e: UploadOnBeforeUploadEvent): void => {
        setLoading(true);
    }

    const handleIsGlobalSearchChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const checked: boolean = event.target.checked;
        setIsGlobalSearch(checked);
        getCustomers(inputSearchContactsValue, checked);
    }

    const handleAddContactDialogClick = (): void => {
        setIsAddCustomerDialogOpened(true);
    }

    const handleEditContactDialogClick = (): void => {
        setIsEditCustomerDialogOpened(true);
    }

    const handleCustomerIdMdmChange = (value: string) => {
        setCustomerIdMdm(value);
    }

    const handleCustomerNameChange = (value: string) => {
        setCustomerName(value);
    }

    const searchCustomerByMdmId = (mdmId: string): void => {
        setCustomerSearchLoading(true);

        CustomerContactsReferentialApiClient.SearchCustomerByMdmId(mdmId.trim())
            .then((res) => {

                setCustomerSearchLoading(false);

                const customerCandidateToAdd: CustomerCandidateToAdd = res.data;
                if (customerCandidateToAdd) {
                    switch (customerCandidateToAdd.existInLorieDb) {
                        case true:
                            handleSearchInReferentialGrid(customerCandidateToAdd.mdmId);
                            return;

                        case false:
                            const customerCandidateToAddExtended: CustomerCandidateToAddExtended = customerCandidateToAdd;
                            customerCandidateToAddExtended.selected = false;
                            setCustomerChoices([customerCandidateToAddExtended]);
                            setActiveStep(1);
                            return;

                        default:
                            return;
                    }
                }

                ToastService.showErrorToast("Aucun client externe référencé trouvé dans le référentiel");
            });
    }

    const searchCustomerByName = (transporterName): void => {
        setCustomerSearchLoading(true);

        CustomerContactsReferentialApiClient.SearchCustomerByName(transporterName.trim())
            .then((res) => {

                setCustomerSearchLoading(false);

                const customerCandidatesToAdd: CustomerCandidateToAdd[] = res.data;
                if (customerCandidatesToAdd.length > 0) {
                    if (customerCandidatesToAdd.length >= 10) {
                        ToastService.showErrorToast("Trop de clients externes référencés. Merci d'affiner la recherche par un nom plus précis");
                        return;

                    } else {
                        customerCandidatesToAdd.forEach((item: CustomerCandidateToAddExtended) => item.selected = false);
                        setCustomerChoices(customerCandidatesToAdd);
                        setActiveStep(1);
                        return;
                    }
                }

                ToastService.showErrorToast("Aucun client externe référencé trouvé dans le référentiel");

            });
    }

    const handleSearchInReferentialGrid = (customerMdmId: string): void => {
        inputSearchCustomersRef.current.value = customerMdmId;
        setIsGlobalSearch(true);
        handleCloseAddCustomerDialog();
        getCustomers(customerMdmId, true);

        ToastService.showSuccessToast("Ce client externe référencé est déjà présent dans LORIE, il faut vérifier que ce dernier soit bien visible pour votre zone logistique");
    }

    const handleCloseAddCustomerDialog = (): void => {
        setActiveStep(0);
        setCustomerIdMdm('');
        setCustomerName('');
        setCustomerChoices([]);
        setCustomerToAdd(null);
        setIsAddCustomerDialogOpened(false);
    }
    
    const handleCloseEditCustomerDialog = (): void => {
        setIsEditCustomerDialogOpened(false);
    }

    const handleConfirmStep = (customer: CustomerCandidateToAddExtended): void => {
        setCustomerToAdd(customer);
        setActiveStep(2);
    }

    const handlePreviousStep = (): void => {
        setActiveStep(activeStep - 1);
    }

    const confirmAddCustomer = (customerContacts: CustomerContactRequestArgsExtended[]): void => {
        const customerContactsArgs: CustomerContactRequestArgs[] = [];
        customerContacts.forEach(element => {
            customerContactsArgs.push({
                contactId: null,
                firstName: element.firstName.trim(),
                lastName: element.lastName.trim(),
                phoneNumber: element.phoneNumber
            });
        });

        const customerRequestArgs: AddCustomerRequestArgs = {
            customerMdmId: customerToAdd.mdmId,
            customLongName: customerToAdd.customLongName.trim(),
            addressLine1: customerToAdd.addressLine1,
            addressLine2: customerToAdd.addressLine2,
            addressZipCode: customerToAdd.addressZipCode,
            addressStatisticZipCode: customerToAdd.addressStatisticZipCode,
            addressCity: customerToAdd.addressCity,
            addressCountryCode: customerToAdd.addressCountryCode,
            customerContacts: customerContactsArgs
        }

        CustomerContactsReferentialApiClient.AddCustomer(customerRequestArgs)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                inputSearchCustomersRef.current.value = customerToAdd.mdmId;
                setIsGlobalSearch(true);
                handleCloseAddCustomerDialog();
                getCustomers(customerToAdd.mdmId, true);
            });
    }

    const handleEditCustomerClick = (requestArgs: UpdateCustomerRequestArgs): void => {
        setIsEditCustomerDialogOpened(false);
        CustomerContactsReferentialApiClient.UpdateCustomer(requestArgs)
            .then((res) => {
                const errors: string[] = BusinessErrors.Get(res.data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("Erreur lors de la mise à jour du client externe: ", errors);
                }
                else {
                    const customer = customersList.get(requestArgs.customerMdmId);
                    customer.addressLine1 = requestArgs.addressLine1;
                    customer.addressLine2 = requestArgs.addressLine2;
                    customer.addressZipCode = requestArgs.addressZipCode;
                    customer.addressCity = requestArgs.addressCity;
                    customer.freeFormAddress = Utilities.formatAddress(requestArgs.addressLine1, requestArgs.addressLine2, requestArgs.addressZipCode, requestArgs.addressCity);

                    const customers = [...customersArray];
                    const customerElement = customers.find(x => x.customerMdmId == requestArgs.customerMdmId);
                    customerElement.addressLine1 = requestArgs.addressLine1;
                    customerElement.addressLine2 = requestArgs.addressLine2;
                    customerElement.addressZipCode = requestArgs.addressZipCode;
                    customerElement.addressCity = requestArgs.addressCity;
                    customerElement.freeFormAddress = customer.freeFormAddress;
                    setCustomersArray(customers);
                    
                    handleSelectedCustomer(selectedCustomer.customerMdmId, selectedCustomer.name);
                }
            });
    }

    const headerContentComponent: JSX.Element = useMemo(() =>
        <HeaderContentComponent inputSearchExternalContactsValue={inputSearchContactsValue}
            inputSearchExternalContactsRef={inputSearchCustomersRef}
            handleExternalContactsKeyPress={ExternalCustomersKeyPressed}
            handleClearSearchText={clearSearchText}
            isGlobalSearch={isGlobalSearch}
            sort={sortCustomer}
            loadingContactsOfCustomer={loadingContactsOfCustomer}
            selectedCustomer={selectedCustomer}
            handlerAfterUpload={handlerAfterUpload}
            handlerBeforeUpload={handlerBeforeUpload}
            handleIsGlobalSearchChanged={handleIsGlobalSearchChanged}
            handleAddContactDialogClick={handleAddContactDialogClick}
            handleEditContactDialogClick={handleEditContactDialogClick}
        />, [inputSearchContactsValue, inputSearchCustomersRef, sortCustomer, selectedCustomer.customerMdmId, loadingContactsOfCustomer, isGlobalSearch]);

    const customerComponent: JSX.Element = useMemo(() =>
        <CustomerComponent
            customersList={customersList}
            customersArray={customersArray}
            logisticsUnitsChoices={customersChoicesLogisticsUnit}
            sort={sortCustomer}
            handleSortChange={handleCustomerSortChange}
            handleSortColumnChange={handleSortCustomerColumnChange}
            handleSelectedCustomer={handleSelectedCustomer}
        />, [customersList, customersArray, customersChoicesLogisticsUnit, selectedCustomer, sortCustomer]);

    const customerContactsComponent: JSX.Element = useMemo(() =>
        <CustomerContactsComponent
            selectedCustomerMdmId={selectedCustomer.customerMdmId}
            sort={sortContact}
            handleSortChange={handleContactSortChange}
            handleSortColumnChange={handleSortContactColumnChange}
            contacts={selectedCustomerContacts}
            contactsArray={selectedCustomerContactsArray}
            contactChoicesLogisticsUnit={contactLogisticsUnitsChoices}
        />, [selectedCustomer.customerMdmId, sortContact, selectedCustomerContacts, selectedCustomerContactsArray, contactLogisticsUnitsChoices]);

    return (
        <Box className="external-contacts referencial">
            <Box display="flex" flexDirection="row" flex="wrap" className="view-title">
                Gestion des clients externes
            </Box>
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box display="flex" flexDirection="column" flex="wrap">
                        {headerContentComponent}
                        {(loading ?
                            <ScaleLoaderComponent loading={loading} />
                            :
                            (<Box display='flex' flexDirection="row" width='100%'>
                                <Box width='40%'>
                                    {customerComponent}
                                </Box>
                                <Box pl={2} width='60%'>
                                    {loadingContactsOfCustomer ?
                                        <ScaleLoaderComponent loading={loadingContactsOfCustomer} />
                                        :
                                        selectedCustomer.customerMdmId &&
                                        customerContactsComponent
                                    }
                                </Box>
                            </Box>
                            ))}
                    </Box>
                </IntlProvider>
            </LocalizationProvider>
            <SimpleDialog isOpen={isAddCustomerDialogOpened}
                onClose={() => handleCloseAddCustomerDialog()}
                closeIcon={true}
                dialogTitle="Ajouter un client externe"
                classNameVal="add-customer-dialog"
                component={
                    <AddCustomerDialogComponent
                        activeStep={activeStep}
                        customerSearchLoading={customerSearchLoading}
                        customerIdMdm={customerIdMdm}
                        customerName={customerName}
                        customerChoices={customerChoices}
                        handleCustomerIdMdmChange={handleCustomerIdMdmChange}
                        handleCustomerNameChange={handleCustomerNameChange}
                        searchCustomerByMdmId={searchCustomerByMdmId}
                        searchCustomerByName={searchCustomerByName}
                        handleSearchInReferentialGrid={handleSearchInReferentialGrid}
                        confirmAddCustomer={confirmAddCustomer}
                        handleConfirmStep={handleConfirmStep}
                        handlePreviousStep={handlePreviousStep}
                    />
                }
            />
            <SimpleDialog isOpen={isEditCustomerDialogOpened}
                onClose={() => handleCloseEditCustomerDialog()}
                closeIcon={true}
                dialogTitle="Modifier un client externe"
                classNameVal="edit-customer-dialog"
                component={
                    <EditCustomerDialogComponent
                        customerSelected={customersList.get(selectedCustomer.customerMdmId)}
                        customerContactsSelectedArray={selectedCustomerContactsArray}
                        handleEditCustomerClick={handleEditCustomerClick}
                    />
                }
            />
        </Box>
    );
}
