import { Box } from '@mui/material';
import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';

interface BeneficiaryAndRequesterChoiceCellComponentProperties extends GridCellProps {
    updateChoicesLogisticsUnit?: (checked: boolean, logisticsUnitId: string, isBeneficiary: boolean, contactId: string) => void
}

export const BeneficiaryAndRequesterChoiceCellComponent = (props: BeneficiaryAndRequesterChoiceCellComponentProperties): JSX.Element => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: string, isBeneficiary: boolean): void => {
        props.updateChoicesLogisticsUnit(event.target.checked, field, isBeneficiary, props.dataItem.contactId);
    };

    const checkedRequester: boolean = props.dataItem[`${props.field}_requester`];
    const checkedBeneficiary: boolean = props.dataItem[`${props.field}_beneficiary`];

    const defaultRendering: JSX.Element = (
        <td className="logistics-unit-cell">
            <Box display="flex" flexDirection="row" width="100%">
                <span className="checkbox-label">Bénéficiaire:</span>
                <input type="checkbox" checked={checkedBeneficiary} onChange={(e) => handleChange(e, props.field, true)} className="custom-checkbox" />
                <span className="checkbox-label">Demandeur:</span>
                <input type="checkbox" checked={checkedRequester} onChange={(e) => handleChange(e, props.field, false)} className="custom-checkbox" />
            </Box>
        </td>
    );

    return defaultRendering;
}
