import { GridCellProps } from '@progress/kendo-react-grid';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import React from 'react';
import { TransportSaleLightModelExtended } from '../models/TransportSaleLightModelExtended';
import { TransportSaleEntityLightModel } from '../services/dataContracts/queryStack/TransportSaleEntityLightModel';

interface PreInvoiceCommentsCellProperties extends GridCellProps{
    width: number
}

interface PreInvoiceCommentsCellState {
    dataValue: string|string[]|number
}

export default class PreInvoiceCommentsCell extends React.Component<PreInvoiceCommentsCellProperties, PreInvoiceCommentsCellState> {
    constructor(props: PreInvoiceCommentsCellProperties) {
        super(props);
        this.state = {
            dataValue: this.props.dataItem[this.props.field],
        }
    }

    handleChange = (dataItem: TransportSaleLightModelExtended, field: string, event: InputChangeEvent): void => {
        this.setState({
            dataValue: event.target.value
        });

        dataItem.inEdit = field;
        dataItem[field] = event.target.value;
    }

    render() {
        const dataItem: TransportSaleLightModelExtended = this.props.dataItem;
        let dataValue: string = dataItem[this.props.field];
        dataValue = dataValue ? dataValue : '';
        let defaultRendering: JSX.Element = <></>;
        if (dataItem.entity != TransportSaleEntityLightModel.planningVehicle || !dataItem.inEdit || dataItem.inEdit !== this.props.field) {
            defaultRendering = (
                <td>{dataValue}</td>
            );
        } else {
            defaultRendering = (
                <td>
                    <Input
                        value={dataValue}
                        onChange={(event) => this.handleChange(dataItem, this.props.field, event)}
                        width={this.props.width} />
                </td>
            );
        }
        return this.props.render ?
            this.props.render.call(undefined, defaultRendering, this.props) :
            defaultRendering;
    }
}