import { GridCellProps } from '@progress/kendo-react-grid';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import React, { useState } from 'react';

interface CustomCostsRemarksOfTransporterCellComponentProps extends GridCellProps {
    width: number
}

export const CustomCostsRemarksOfTransporterCellComponent = (props: CustomCostsRemarksOfTransporterCellComponentProps): JSX.Element => {
    let costsRemarksOfTransporter: string = props.dataItem.costsRemarksOfTransporter;
    costsRemarksOfTransporter = costsRemarksOfTransporter ? costsRemarksOfTransporter : '';

    const [dataValue, setDataValue] = useState<string|string[]|number>(costsRemarksOfTransporter);

    const handleChange = (props: CustomCostsRemarksOfTransporterCellComponentProps, event: InputChangeEvent): void => {
        props.dataItem.inEdit = props.field;
        setDataValue(event.target.value);
        props.dataItem.costsRemarksOfTransporter = event.target.value;
    }

    let defaultRendering: JSX.Element = null;
    if (!props.dataItem.vehicleLine) {
        defaultRendering = (
            <td></td>
        );
    } else if (!props.dataItem.inEdit || props.dataItem.inEdit !== props.field) {
        defaultRendering = (
            <td>{dataValue}</td>
        );
    } else {
        defaultRendering = (
            <td>
                <Input value={dataValue}
                    onChange={(event) => handleChange(props, event)}
                    width={props.width}
                />
            </td>
        );
    }

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}
