import { Popup } from '@bryntum/scheduler/scheduler.umd.js';
import { faExclamationTriangle, faPen, faPhoneAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import { NumericTextBox, NumericTextBoxChangeEvent, NumericTextBoxHandle } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import { OverflowTextComponent } from 'src/shared/components/Common/OverflowTextComponent';
import { DeliveryTripCancellationReason } from '../../../shared/models/DeliveryTripCancellationReason';
import { DeliveryTripStatus } from '../../../shared/models/DeliveryTripStatus';
import { EventModelData } from '../models/EventModelData';
import { PriceKind } from '../models/PriceKind';
import { PurchasePriceLightModel } from '../services/dataContracts/queryStack/PurchasePriceLightModel';

interface TripDetailPopupProperties {
    role: string
    eventData: EventModelData,
    popup: Popup,
    availablePurchasePrices: PurchasePriceLightModel[],
    isPlanningVehicleCanceled: boolean,
    handleDriverInstructionsChange: (transportRequestId: string, deliverTripId: number, driverInstructions: string) => void,
    handleTransporterInstructionsChange: (transportRequestId: string, transportFlowId: string, transporterInstructions: string) => void,
    handlePlannedQuantityChange: (transportRequestId: string, deliveryTripId: number, quantity: number) => void,
    handleSpecificPriceChange: (deliverTripId: number, transportRequestId: string, propertyName: string, priceKind: string, price?: number, overridedPrice?: number) => void,
    handleCancellationRemarksChange: (transportRequestId: string, deliveryTripId: number, cancellationRemarks: string) => void,
}

export const TripDetailPopupComponent = (props: TripDetailPopupProperties) => {
    const price: number = props.eventData.price ? Number(props.eventData.price) : null;
    const cancellationReasonLabel =
        props.eventData.cancellationReason === DeliveryTripCancellationReason.badWeather ? "Intempéries"
            : props.eventData.cancellationReason === DeliveryTripCancellationReason.customerCancellation ? "Annulation client"
                : props.eventData.cancellationReason === DeliveryTripCancellationReason.supplierCancellation ? "Annulation fournisseur du client"
                    : props.eventData.cancellationReason === DeliveryTripCancellationReason.transporterCancellation ? "Annulation transporteur"
                        : props.eventData.cancellationReason === DeliveryTripCancellationReason.other ? "Autre"
                            : props.eventData.cancellationReason === DeliveryTripCancellationReason.sourceApplicationRelated ? "Annulation PLANEO/EDISPATCH" : "";

    const [isTxtTransporterInstructionsEditable, setIsTxtTransporterInstructionsEditable] = useState<boolean>(false);
    const [isTxtDriverInstructionEditable, setIsTxtDriverInstructionEditable] = useState<boolean>(false);
    const [isTxtCancellationRemarksEditable, setIsTxtCancellationRemarksEditable] = useState<boolean>(false);
    const [isEditingPlannedQuantity, setIsEditingPlannedQuantity] = useState<boolean>(false);
    const [plannedQuantityValue, setPlannedQuantityValue] = useState<number>(props.eventData.quantity);

    const txtTransporterInstructionsRef: React.RefObject<HTMLTextAreaElement> = React.useRef(null);
    const txtDriverInstructionsRef: React.RefObject<HTMLTextAreaElement> = React.useRef(null);
    const txtPlannedQuantityRef: React.RefObject<NumericTextBoxHandle> = React.useRef(null);
    const txtCancellationRemarksRef: React.RefObject<HTMLTextAreaElement> = React.useRef(null);

    const quantity: string = !plannedQuantityValue ? props.eventData.unitOfMeasure : plannedQuantityValue + '' + props.eventData.unitOfMeasure;
    const realQuantity: string = !props.eventData.realQuantity ? "" : props.eventData.realQuantity + '' + props.eventData.unitOfMeasure;
    const beneficiaryPhone: string = props.eventData.beneficiaryPhoneNumber == null ? "" : props.eventData.beneficiaryPhoneNumber;
    const senderSiteLabel: string = props.eventData.senderSiteLabel == null ? "" : props.eventData.senderSiteLabel;
    const receiverSiteLabel: string = props.eventData.receiverSiteLabel == null ? "" : props.eventData.receiverSiteLabel;
    const realDepartureTime: string = props.eventData.realDepartureTime == null ? "" : new Date(props.eventData.realDepartureTime).toShortTimeString();

    useEffect(() => {
        txtTransporterInstructionsRef.current.value = props.eventData.transportersInstructions ?? "";
        txtDriverInstructionsRef.current.value = props.eventData.driverInstructions ?? "";

        if (txtCancellationRemarksRef.current) {
            txtCancellationRemarksRef.current.value = props.eventData.cancellationRemarks ?? "";
        }
    }, []);

    useEffect(() => {
        if (isTxtTransporterInstructionsEditable)
            txtTransporterInstructionsRef.current.focus();
    }, [isTxtTransporterInstructionsEditable]);

    useEffect(() => {
        if (isEditingPlannedQuantity)
            txtPlannedQuantityRef.current.focus();
    }, [isEditingPlannedQuantity]);

    useEffect(() => {
        if (isTxtDriverInstructionEditable)
            txtDriverInstructionsRef.current.focus();
    }, [isTxtDriverInstructionEditable]);

    useEffect(() => {
        if (isTxtCancellationRemarksEditable && txtCancellationRemarksRef.current)
            txtCancellationRemarksRef.current.focus();
    }, [isTxtCancellationRemarksEditable]);

    const getPriceKind = (priceKind: string): string => {
        if (priceKind === PriceKind.PerTon.toString()) {
            return "Tonne";
        } else if (priceKind === PriceKind.PerHour.toString()) {
            return "Heure";
        } else if (priceKind === PriceKind.PerTurn.toString()) {
            return "Tour";
        }

        return "";
    }

    const handleBlurPlannedQuantity = (): void => {
        if (plannedQuantityValue >= 0) {
            setIsEditingPlannedQuantity(false);
            if (props.eventData.quantity != plannedQuantityValue) {
                props.handlePlannedQuantityChange(props.eventData.transportRequestId, props.eventData.deliveryTripId, plannedQuantityValue);
            }
        }
    }

    const handleChangePlannedQuantity = (event: NumericTextBoxChangeEvent): void => {
        const value = event.target.value;
        if (value != null && value >= 0) {
            setPlannedQuantityValue(event.target.value);
        }
        else {
            setPlannedQuantityValue(props.eventData.quantity);
        }
    }

    const closePopup = (): void => {
        props.popup.destroy();
    }

    const updateTransporterInstructions = (value: boolean): void => {
        const oldValue = props.eventData.transportersInstructions ?? "";
        const newValue = txtTransporterInstructionsRef.current.value;

        if (!value && oldValue !== newValue) {
            props.handleTransporterInstructionsChange(props.eventData.transportRequestId, props.eventData.transportFlowId, txtTransporterInstructionsRef.current.value);
        }

        setIsTxtTransporterInstructionsEditable(value);
    }

    const updateDriverInstructions = (value: boolean): void => {
        const oldValue = props.eventData.driverInstructions ?? "";
        const newValue = txtDriverInstructionsRef.current.value;

        if (!value && oldValue !== newValue) {
            props.handleDriverInstructionsChange(props.eventData.transportRequestId, props.eventData.deliveryTripId, txtDriverInstructionsRef.current.value);
        }

        setIsTxtDriverInstructionEditable(value);
    }

    const updateCancellationRemarks = (value: boolean): void => {
        const oldValue = props.eventData.cancellationRemarks ?? "";
        const newValue = txtCancellationRemarksRef.current.value;
        if (!value && oldValue !== newValue) {
            props.handleCancellationRemarksChange(props.eventData.transportRequestId, props.eventData.deliveryTripId, newValue);
        }

        setIsTxtCancellationRemarksEditable(value);
    }

    const isLogisticianUser: boolean = props.role === 'LOG' || props.role === 'ADM';

    const transportSellPriceKindLabel = props.eventData.transportFlowSellPriceKind === "PerTon"
        ? "Tonne"
        : props.eventData.transportFlowSellPriceKind === "PerTurn"
            ? "Tour"
            : null;

    const transportFlowNegotiatedPurchasePriceKindLabel = props.eventData.transportFlowNegotiatedPurchasePriceKind === "PerTon"
        ? "Tonne"
        : props.eventData.transportFlowNegotiatedPurchasePriceKind === "PerTurn"
            ? "Tour"
            : props.eventData.transportFlowNegotiatedPurchasePriceKind === "PerHour"
                ? "Heure"
                : null;

    return (
        <>
            <Box display="flex" flexDirection="row" flexWrap="wrap" className="panel-header-details">
                <Box className="header-title" display="flex" flexDirection="row" alignItems="center" width="95%">
                    <Box>Détail du tour - Camion {props.eventData.licencePlate ? props.eventData.licencePlate : props.eventData.vehicleNumber} / Tour {props.eventData.rotationNumber}</Box>
                    {(props.eventData.eventStatus == "Finished" && props.eventData.finishedDateByForeman) &&
                        <Box className="finished-trip-by-foreman">
                            &nbsp;- Terminé le&nbsp;{new Date(props.eventData.finishedDateByForeman).toShortDateTimeString()}
                        </Box>
                    }
                </Box>
                <Box display="flex" flexDirection="column" pr={1} pt={1} width="5%">
                    <IconButton size="small" onClick={() => closePopup()}>
                        <FontAwesomeIcon size="1x" className='icon-pen-blue' icon={faTimes} />
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" className="trip-detail">
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                    <Box display="flex" flexDirection="column" pr={1} width="33%">
                        <div className="title">
                            N° Flux
                        </div>
                        <div className="value">
                            {props.eventData.businessId}
                        </div>
                    </Box>
                    <Box display="flex" flexDirection="column" pr={1} width="33%">
                        <div className="title">
                            Bénéficiaire
                        </div>
                        <div className="value">
                            {props.eventData.nameCCH}
                        </div>
                    </Box>
                    <Box display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" width="33%">
                        <Box display="flex" flexDirection="column">
                            <div className="title">
                                Tel bénéficiaire
                            </div>
                            {beneficiaryPhone && <div className="value phone-number-ref">
                                {<a href={`callTo:${beneficiaryPhone}`}>{beneficiaryPhone}</a>}
                            </div>}
                        </Box>
                        {beneficiaryPhone && <Box pl={1}>
                            <FontAwesomeIcon size="sm" icon={faPhoneAlt} className="icon-phone-number" />
                        </Box>}
                        <>
                        </>
                    </Box>
                </Box>
                {
                    props.eventData.serviceKind !== "JobsiteVehicle" &&
                    <Box display="flex" flexDirection="row" flexWrap="wrap" pt={1}>
                        <Box display="flex" flexDirection="column" pr={1} width="33%">
                            <div className="title">
                                Produit transporté
                            </div>
                            <div className="value">
                                {props.eventData.product}
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="column" width="33%">
                            <div className="title">
                                Qté planifiée
                                &nbsp;<FontAwesomeIcon size="1x" className='icon-pen-blue' icon={faPen} />&nbsp;
                                / livrée
                            </div>
                            {(isEditingPlannedQuantity && isLogisticianUser)
                                ? (<Box onBlur={handleBlurPlannedQuantity}>
                                    <LocalizationProvider language="fr-FR">
                                        <IntlProvider locale="fr" >
                                            <NumericTextBox className="input-planned-quantity"
                                                min={0}
                                                format={{
                                                    maximumFractionDigits: 2
                                                }}
                                                spinners={false}
                                                onChange={(event) => handleChangePlannedQuantity(event)}
                                                width={'75px'}
                                                value={plannedQuantityValue}
                                                ref={txtPlannedQuantityRef}
                                                disabled={!isLogisticianUser || props.isPlanningVehicleCanceled || props.eventData.isManuallyAttachedToZephyr}
                                            />
                                        </IntlProvider>
                                    </LocalizationProvider>
                                    &nbsp; / {realQuantity}
                                </Box>) :
                                (<div className={isLogisticianUser ? "value planned-quantity" : "value"} onClick={() => isLogisticianUser && !props.isPlanningVehicleCanceled ? setIsEditingPlannedQuantity(true) : {}}>
                                    {quantity} / {realQuantity}
                                </div>)
                            }
                        </Box>
                        <Box display="flex" flexDirection="column" width="33%">
                            <div className="title">
                                ID BL Zéphyr
                            </div>
                            <div className={props.eventData.isManuallyAttachedToZephyr ? "icon-pen-blue" : "value"}>
                                {props.eventData.deliveryNoteId}
                            </div>
                        </Box>
                    </Box>
                }
                <Box width="100%" display="flex" flexDirection="row" pt={1}>
                    <Box width="33%">
                        <div className="title">
                            Site départ
                        </div>
                        <div className="value">
                            {senderSiteLabel}
                        </div>
                    </Box>
                    <Box width="67%">
                        <div className="title">
                            Adresse départ
                        </div>
                        <div className="value">
                            <OverflowTextComponent text={props.eventData.supplierJobSite} />
                        </div>
                    </Box>
                </Box>
                <Box width="100%" display="flex" flexDirection="row" pt={1}>
                    <Box width="33%">
                        <div className="title">
                            Site arrivée
                        </div>
                        <div className="value">
                            {receiverSiteLabel}
                        </div>
                    </Box>
                    <Box width="67%">
                        <div className="title">
                            Adresse arrivée
                        </div>
                        <div className="value">
                            <OverflowTextComponent text={props.eventData.customerJobSite} />
                        </div>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" flexWrap="wrap" pt={1}>
                    <Box display="flex" flexDirection="column" pr={1} width="33%">
                        <div className="title">
                            Chargement prévu
                        </div>
                        <div className="value">
                            {new Date(props.eventData.startDate).toShortTimeString()}
                        </div>
                    </Box>
                    <Box display="flex" flexDirection="column" pr={1} width="33%">
                        <div className="title">
                            Départ réel
                        </div>
                        <div className="value">
                            {realDepartureTime}
                        </div>
                    </Box>
                    <Box display="flex" flexDirection="column" width="33%">
                        <div className="title">
                            Fin déchargement prévu
                        </div>
                        <div className="value">
                            {new Date(props.eventData.endDate).toShortTimeString()}
                        </div>
                    </Box>
                </Box>

                <Box width="100%" display="flex" flexDirection="row" pt={1}>
                    <Box width="38%">
                        <div className="title">
                            Tarification de vente
                        </div>
                        <div className={transportSellPriceKindLabel ? "value" : "value-empty"}>
                            {transportSellPriceKindLabel}
                        </div>
                    </Box>
                    <Box width="62%">
                        <div className="title">
                            Prix de vente unitaire
                        </div>
                        <div className="value">
                            {(props.eventData.transportFlowSellPriceKind === "PerTon" || props.eventData.transportFlowSellPriceKind === "PerTurn") ? props.eventData.transportFlowUnitPrice?.toCurrencyString() : null}
                        </div>
                    </Box>
                </Box>

                {(props.eventData.negotiatedPurchasePriceAppliesOnTrip && props.eventData.transportFlowNegotiatedPurchasePriceVehicleTypeGroupId === props.eventData.transportFlowVehicleTypeGroupId
                    && props.eventData.transportFlowNegotiatedPurchasePriceKind && props.eventData.transportFlowNegotiatedPurchaseUnitPrice) &&
                    (<Box width="100%" display="flex" flexDirection="row" pt={1}>
                        <Box width="38%">
                            <div className="title">
                                Tarification d'achat négocié
                            </div>
                            <div className={"value"}>
                                {`${props.eventData.transportFlowNegotiatedPurchasePriceVehicleTypeGroupId} | ${transportFlowNegotiatedPurchasePriceKindLabel}`}
                            </div>
                        </Box>
                        <Box width="62%">
                            <div className="title">
                                Prix à l'unité
                            </div>
                            <div className="value">
                                {props.eventData.transportFlowNegotiatedPurchaseUnitPrice?.toCurrencyString()}
                            </div>
                        </Box>
                    </Box>)}

                <Box display="flex" flexDirection="row" flexWrap="wrap" pt={1}>
                    <Box display="flex" flexDirection="column" pr={1} width="38%">
                        <div className="title">
                            Tarification spécifique d'achat
                        </div>
                        <div className={props.eventData.priceKind ? "value" : "value-empty"}>
                            <div>{getPriceKind(props.eventData.priceKind)}</div>
                        </div>
                    </Box>
                    <Box width="62%">
                        <div className="title">
                            Prix à l'unité
                        </div>
                        <div className="value">
                            {price?.toCurrencyString()}
                        </div>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" flexWrap="wrap" pt={1}>
                    <Box display="flex" flexDirection="row" width="50%">
                        <Box width="100%" display="flex" flexDirection="column">
                            <Tooltip title="Saisir dans le champs Instructions aux transporteurs">
                                <Box display="flex" flexDirection="row">
                                    <Box className="title" pr={1}>
                                        Instructions aux transporteurs
                                    </Box>
                                    <FontAwesomeIcon size="1x" className='icon-pen-blue' icon={faPen} />
                                </Box>
                            </Tooltip>
                            <Box className="alert-message">
                                <FontAwesomeIcon size="1x" icon={faExclamationTriangle} className="icon-alert" /><span className="title">(Impacte tous les tours du Flux)</span>
                            </Box>
                            <Box display="flex" alignItems="flex-end" pb={1} width="100%"
                                onClick={() => isTxtTransporterInstructionsEditable ? null : updateTransporterInstructions(true)}>
                                <TextField
                                    id="outlined-dense-multiline"
                                    margin="normal"
                                    multiline
                                    rows="3"
                                    className={`txt-field-flow-complementary-detail ${(!isTxtTransporterInstructionsEditable || !isLogisticianUser || props.isPlanningVehicleCanceled) ? "disabled" : ""}`}
                                    onBlur={() => updateTransporterInstructions(false)}
                                    inputRef={txtTransporterInstructionsRef}
                                    InputProps={{
                                        disableUnderline: true,
                                        readOnly: !isTxtTransporterInstructionsEditable || !isLogisticianUser || props.isPlanningVehicleCanceled
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" width="50%">
                        <Box width="100%">
                            <Tooltip title="Saisir dans le champs Instruction au chauffeur">
                                <Box display="flex" flexDirection="row">
                                    <Box className="title" pr={1}>
                                        Instructions au chauffeur
                                    </Box>
                                    <FontAwesomeIcon size="1x" className='icon-pen-blue' icon={faPen} />
                                </Box>
                            </Tooltip>
                            <Box className="alert-message"></Box>
                            <Box display="flex" alignItems="flex-end" pb={1} width="100%"
                                onClick={() => isTxtDriverInstructionEditable ? null : updateDriverInstructions(true)}>
                                <TextField
                                    id="outlined-dense-multiline"
                                    margin="normal"
                                    multiline
                                    rows="3"
                                    className={`txt-field-flow-complementary-detail ${(!isTxtDriverInstructionEditable || !isLogisticianUser || props.isPlanningVehicleCanceled) ? "disabled" : ""}`}
                                    onBlur={() => updateDriverInstructions(false)}
                                    inputRef={txtDriverInstructionsRef}
                                    InputProps={{
                                        disableUnderline: true,
                                        readOnly: !isTxtDriverInstructionEditable || !isLogisticianUser || props.isPlanningVehicleCanceled
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {props.eventData.eventStatus === DeliveryTripStatus.canceled &&
                    <Box display="flex" flexDirection="row" flexWrap="wrap" pt={1}>
                        <Box display="flex" flexDirection="row" width="100%" pb={1}>
                            <div className="title">
                                Motif de l'annulation : {cancellationReasonLabel}
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row" width="100%" pb={1}>
                            <Tooltip title="Commentaire annulation">
                                <Box display="flex" flexDirection="row">
                                    <Box className="title" pr={1}>
                                        Commentaire annulation
                                    </Box>
                                    <FontAwesomeIcon size="1x" className='icon-pen-blue' icon={faPen} />
                                </Box>
                            </Tooltip>
                        </Box>
                        <Box display="flex" alignItems="flex-end" pb={1} width="100%"
                            onClick={() => isTxtCancellationRemarksEditable ? null : updateCancellationRemarks(true)}>
                            <TextField
                                id="outlined-dense-multiline"
                                margin="normal"
                                multiline
                                rows="3"
                                className={`txt-field-flow-complementary-detail ${(!isTxtCancellationRemarksEditable || !isLogisticianUser || props.isPlanningVehicleCanceled) ? "disabled" : ""}`}
                                onBlur={() => updateCancellationRemarks(false)}
                                inputRef={txtCancellationRemarksRef}
                                InputProps={{
                                    disableUnderline: true,
                                    readOnly: !isTxtCancellationRemarksEditable || !isLogisticianUser || props.isPlanningVehicleCanceled
                                }}
                            />
                        </Box>
                    </Box>
                }
            </Box>
        </>);
}