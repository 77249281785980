import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import Utilities from '../../../utils/Utilities';
import { WeeklyRequestedByBeneficiaryModel } from '../models/WeeklyRequestedByBeneficiaryModel';
import { NeededVehicleDetailLightModel } from '../services/dataContracts/queryStack/NeededVehicleDetailLightModel';

interface WeeklyRequestedVehiclesByBeneficiaryComponentProps {
    neededVehicleDetailArray: Array<NeededVehicleDetailLightModel>
}

export const WeeklyRequestedVehiclesByBeneficiaryComponent = (props: WeeklyRequestedVehiclesByBeneficiaryComponentProps): JSX.Element => {

    const groupBeneficiaryProjectedNeedOfVehiclesByWeek = (neededVehicleDetailArray: Array<NeededVehicleDetailLightModel>): WeeklyRequestedByBeneficiaryModel[] => {
        const groupedneededVehicleDetailByWeekArray: NeededVehicleDetailLightModel[][] = Utilities.groupBy(neededVehicleDetailArray, "beneficiaryName");
        const WeeklyRequestedByBeneficiaryArray: Array<WeeklyRequestedByBeneficiaryModel> = [];

        groupedneededVehicleDetailByWeekArray.forEach((neededVehicleDetailByBeneficiare: Array<NeededVehicleDetailLightModel>) => {
            let dayTimePlannedNumberOfVehicles = 0;
            let nightTimePlannedNumberOfVehicles = 0;
            const weeklyRequestedByBeneficiary = {} as WeeklyRequestedByBeneficiaryModel;

            weeklyRequestedByBeneficiary.beneficiaryName = neededVehicleDetailByBeneficiare[0].beneficiaryName;
            neededVehicleDetailByBeneficiare.forEach((ele: NeededVehicleDetailLightModel) => {
                dayTimePlannedNumberOfVehicles += ele.dayTimePlannedNumberOfVehicles;
                nightTimePlannedNumberOfVehicles += ele.nightTimePlannedNumberOfVehicles;
            });
            weeklyRequestedByBeneficiary.beneficiaryDayTimePlannedNumberOfVehicles = dayTimePlannedNumberOfVehicles;
            weeklyRequestedByBeneficiary.beneficiaryNightTimePlannedNumberOfVehicles = nightTimePlannedNumberOfVehicles;
            WeeklyRequestedByBeneficiaryArray.push(weeklyRequestedByBeneficiary);
        });
        return WeeklyRequestedByBeneficiaryArray;
    }

    const weeklyRequestedByBeneficiareArray: WeeklyRequestedByBeneficiaryModel[] = groupBeneficiaryProjectedNeedOfVehiclesByWeek(props.neededVehicleDetailArray);

    return (
        <Table>
            <TableHead>
                <TableRow className="table-header-cell">
                    <TableCell className="fixed-head"></TableCell>
                    <TableCell className="fixed-head" align="right">Jour</TableCell>
                    <TableCell className="fixed-head" align="right">Nuit</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {weeklyRequestedByBeneficiareArray.map((row: WeeklyRequestedByBeneficiaryModel, index) => (
                    <TableRow key={index}>
                        <TableCell component="th" scope="row" style={{ 'whiteSpace': 'nowrap' }}>
                            {row.beneficiaryName}
                        </TableCell>
                        <TableCell align="right">{row.beneficiaryDayTimePlannedNumberOfVehicles}</TableCell>
                        <TableCell align="right">{row.beneficiaryNightTimePlannedNumberOfVehicles}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
