import axios, { AxiosResponse } from "axios";
import { VehicleTypeLightModel } from "./dataContracts/queryStack/VehicleTypeLightModel";
import { UpdateLogisticsUnitGenericPurchasePricesArgs } from "./dataContracts/controller/UpdateLogisticsUnitGenericPurchasePricesArgs";
import { WebAppActionResult } from "src/shared/models/WebAppActionResult";

const apiUrl = "api/GenericPurchasePricesReferential/";

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export class GenericPurchasePricesReferentialApiClient {

    public static GetVehicleTypes = (logisticUnit: string)
        : Promise<AxiosResponse<Array<VehicleTypeLightModel>>> => {
        return axios.get(`${apiUrl}GetVehicleTypes?logisticUnit=${logisticUnit}`);
    }

    public static UpdateGenericPurchasePrices = (updateRequest: UpdateLogisticsUnitGenericPurchasePricesArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(`${apiUrl}/UpdateGenericPurchasePrices`, updateRequest, {
            headers: headers,
        });
    }

    public static GeneratePurchasePricesForNewLogiticUnit = (logisticUnit: string)
        : Promise<AxiosResponse<Array<VehicleTypeLightModel>>> => {
        return axios.get(`${apiUrl}GeneratePurchasePricesForNewLogiticUnit?logisticUnit=${logisticUnit}`);
    }
}