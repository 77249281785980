import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { CalendarComponent } from 'src/shared/components/Common/CalendarComponent';
import { DateRange } from 'src/shared/models/DateRange';
import BusinessErrors from 'src/utils/BusinessErrors';
import { AddDieselIndexTemplateRequestArgs } from '../services/dataContracts/controller/AddDieselIndexTemplateRequestArgs';
import { DieselIndexTemplateWeightingRequestArgs } from '../services/dataContracts/controller/DieselIndexTemplateWeightingRequestArgs';
import { DieselIndexesReferentialApiClient } from '../services/DieselIndexesReferentialApiClient';

interface AddDieselIndexTemplateProperties {
    selectedLogisticsUnitId: string,
    minStartDate?: Date,
    maxStartDate?: Date,
    vehicleTypeGroups: Array<string>,
    HandleCloseDialog: () => void,
    HandleResult: (errors: Array<string>) => void,
    DisplayError: (error) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

export const AddDieselIndexTemplateComponent = (props: AddDieselIndexTemplateProperties) => {
    const [newIndexesTemplateLabel, setNewIndexesTemplateLabel] = useState<string>();
    const [newWeightings, setNewWeightings] = useState<Array<DieselIndexTemplateWeightingRequestArgs>>([]);
    const [requestPending, setRequestPending] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<DateRange>({
        start: props.minStartDate,
        end: props.maxStartDate
    });
    const [referenceValue, setReferenceValue] = useState<number>(0);
    const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        const newWeightingData: Array<DieselIndexTemplateWeightingRequestArgs> = new Array<DieselIndexTemplateWeightingRequestArgs>();
        props.vehicleTypeGroups.forEach(vehicleTypeGroup => {
            newWeightingData.push({
                vehicleTypeGroupId: vehicleTypeGroup,
                weightingValue: 0
            });
        });

        setNewWeightings([...newWeightingData]);
    }, []);

    const HandleChangeDateRange = (event: DateRange): void => {
        const startDate = event.start?.getDayStart();
        const endDate = event.end?.getDayStart();

        setDateRange({ start: startDate, end: endDate });
    }

    const GetTemplateWeighting = (): Array<JSX.Element> => {
        const content = [];
        const newWeightingsLength = newWeightings.length;

        let currentIndex = 0;
        let lineIndex = 0;
        while (currentIndex < newWeightingsLength) {
            const elemFirst = GetLabelElement(newWeightings[currentIndex].vehicleTypeGroupId, currentIndex);
            currentIndex++;
            const elemSecond = currentIndex == props.vehicleTypeGroups.length ? <></> : GetLabelElement(newWeightings[currentIndex].vehicleTypeGroupId, currentIndex);
            currentIndex++;
            lineIndex++;
            content.push(<Box display="flex" flexDirection="row" className="weighting-line" key={`line-${lineIndex}`} pb={1}>{elemFirst}{elemSecond}</Box>);
        }

        return content;
    }

    const HandleChangeTemplateWeighting = (event: NumericTextBoxChangeEvent, label: string): void => {
        const value = event.target.value != null ? event.target.value : 0;

        if (value >= 0 && value <= 1) {
            const newWeightingData = cloneDeep(newWeightings);

            const elem = newWeightingData.find(x => x.vehicleTypeGroupId == label);
            elem.weightingValue = event.target.value;
            setNewWeightings([...newWeightingData]);

            forceUpdate();
        }
        else {
            event.nativeEvent.preventDefault();
        }
    }

    const HandleChangeReferenceValue = (event: NumericTextBoxChangeEvent): void => {
        const value = event.target.value != null ? event.target.value : 0;
        //specifier "min=0" sur le composant bloque uniquement la saisi des negatifs avec des fleches
        //si on ecrit directement il n'ya pas de blocage
        if (value >= 0) {
            setReferenceValue(value);
        }
        else {
            event.nativeEvent.preventDefault();
        }
    }

    const GetLabelElement = (label: string, index: number): JSX.Element => {
        return (<Box paddingLeft={`${index % 2 == 0 ? '0em' : '1em'}`}>
            <Label editorId={`label${index}`} className="weighting-title">
                {label} : &nbsp;
            </Label>
            <NumericTextBox id={`label${index}`}
                min={0}
                max={1}
                step={0.1}
                format={{
                    maximumFractionDigits: 2,
                    style: 'percent'
                }}
                value={newWeightings[index].weightingValue}
                spinners={false}
                onChange={(event) => HandleChangeTemplateWeighting(event, label)} />
        </Box>);
    }

    const HandleChangeLabel = (text: string): void => {
        setNewIndexesTemplateLabel(text);
    }

    const AddDieselIndexTemplate = (): void => {
        let hasWeightingValue = false;
        newWeightings.forEach((element) => {
            if (element.weightingValue === null) {
                hasWeightingValue = true;
            }
        });

        if (hasWeightingValue) {
            props.DisplayError("Les champs de pondération K et le champ C0 doivent être complétés pour ajouter le modèle.");
            return;
        }

        const requestArgs: AddDieselIndexTemplateRequestArgs = {
            label: newIndexesTemplateLabel,
            logisticsUnitId: props.selectedLogisticsUnitId,
            startDate: dateRange.start,
            endDate: dateRange.end,
            referenceValue: referenceValue,
            weightings: newWeightings
        };

        setRequestPending(true);
        DieselIndexesReferentialApiClient.AddDieselIndexTemplate(requestArgs)
            .then(res => {
                const data = res?.data;

                const errors = BusinessErrors.Get(data);
                props.HandleResult(errors);
            })
            .finally(() => {
                setRequestPending(false);
            });
    }

    const handleIsDateRangeValidChanged = (isValid: boolean): void => {
        setIsDateRangeValid(isValid);
    }

    const handleClose = (_event, reason: string) => {
        if (reason !== "backdropClick") {
            props.HandleCloseDialog();
        }
    }

    return (
        <Dialog
            open={true}
            disableEscapeKeyDown
            aria-modal="false"
            onClose={handleClose}
            scroll="paper"
            className="simple-dialog-new-template"
        >
            <DialogTitle id="scroll-dialog-title">
                Créer un nouveau modèle
            </DialogTitle>
            <DialogContent dividers>
                <Box display="flex" flexDirection="column" className="dialog-add-new-template">
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box width="35%">
                            <input type="text" maxLength={30} placeholder="Libellé ..." className="input-label" onChange={(e) => HandleChangeLabel(e.target.value)} />
                        </Box>
                        <Box width="65%">
                            <CalendarComponent handlerFromChildToParent={HandleChangeDateRange} startDate={dateRange.start} endDate={dateRange.end}
                                minStartDate={props.minStartDate} maxStartDate={props.maxStartDate} calendarId='referential-gazole-daterange-picker'
                                isValid={isDateRangeValid} handleIsValidChanged={handleIsDateRangeValidChanged} />
                        </Box>
                    </Box>
                    <Box pb={1} width="50%">
                        <Label editorId="referenceValue" className="diesel-template-label diesel-template-label-rv">
                            C0 : &nbsp;
                        </Label>
                        <NumericTextBox id="referenceValue"
                            min={0}
                            format={{
                                maximumFractionDigits: 4
                            }}
                            onChange={(event) => HandleChangeReferenceValue(event)}
                            value={referenceValue}
                            spinners={false} />
                    </Box>
                    <Box pb={1} className="diesel-template-label">
                        Pondération / K :
                    </Box>
                    {
                        GetTemplateWeighting().map(elemLine => (
                            elemLine
                        ))
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.HandleCloseDialog} color="primary">
                    Annuler
                </Button>
                <Button color="primary" disabled={!isDateRangeValid || requestPending} onClick={AddDieselIndexTemplate}>
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    );
}