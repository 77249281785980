import axios, { AxiosResponse } from 'axios';
import { TransportPlanExportRequestArgs } from './dataContracts/controller/TransportPlanExportRequestArgs';
const controllerUrl = "api/TransportPlanExport/";
const axiosConfigHeaders = { 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/json' };

export class TransportPlanExportApiClient {
    public static Export = (requestArgs: TransportPlanExportRequestArgs)
        : Promise<AxiosResponse<Blob>> => {
            return axios.post(`${controllerUrl}Export`, requestArgs, { headers: axiosConfigHeaders, responseType: 'blob' });
    }
}
