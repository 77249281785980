export function fromMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const minutesPart = minutes % 60;
    return hours.padZero() + ":" + minutesPart.padZero() + ":00";
}

export function fromHours(hours: number): string {
    const minutes = (Math.floor(hours) * 60 + (hours % 1) * 60);
    if (minutes < 0)
        return "-" + fromMinutes((minutes * -1));
    return fromMinutes(minutes);
}

export function fromSeconds(seconds: number): string {
    const hours = Math.floor(seconds / (60 * 60));
    const divisor_for_minutes = seconds % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);
    const divisor_for_seconds = divisor_for_minutes % 60;
    const secondsPart = Math.ceil(divisor_for_seconds);
    return hours.padZero() + ":" + minutes.padZero() + ":" + secondsPart.padZero();
}

export function toMinutes(timeSpanString: string): number {
    if (timeSpanString) {
        const value = timeSpanString.split(":");
        return (Number(value[0]) * 60) + Number(value[1]);
    } else {
        return null;
    }
}