import { Box, Divider, TextField } from '@mui/material';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import Utilities from 'src/utils/Utilities';
import { RegularizationPurchaseProvisioningExtended } from '../models/RegularizationPurchaseProvisioningExtended';

interface RegularizationTemplateProps {
    purchaseProvisioningMaxAllowedCostLimit: number,
    regularizations: Array<RegularizationPurchaseProvisioningExtended>,
    selectedLogisticsUnitId: string,
    handleRefreshRegularizations: (newRegularizations: Array<RegularizationPurchaseProvisioningExtended>) => void
}

export const RegularizationTemplateComponent = (props: RegularizationTemplateProps) => {
    const [regularizations, setRegularizations] = useState<Array<RegularizationPurchaseProvisioningExtended>>([...props.regularizations]);

    const { purchaseProvisioningMaxAllowedCostLimit } = props;

    const handleChangeRegularizationPurchaseCostVehicle = (purchaseCost: number, transporterId: string, planningVehicleId: string): void => {
        const regularizationsArray = [...regularizations];
        const regularizationOfTransporter = regularizationsArray.find(r => r.transporterId === transporterId);
        const vehicle = regularizationOfTransporter.details.find(v => v.planningVehicleId === planningVehicleId);
        vehicle.purchaseCost = purchaseCost;
        vehicle.baseOfPurchaseCostIndexableWithGasoilIndex = purchaseCost;
        vehicle.gasoilIndexCost = (vehicle.applicableGasoilIndexValue ?? 0 ) * (purchaseCost ?? 0);

        regularizationOfTransporter.purchaseCost = Utilities.sum(regularizationOfTransporter.details, 'purchaseCost');
        regularizationOfTransporter.gasoilIndexCost = Utilities.sum(regularizationOfTransporter.details.filter(x => x.purchaseCost), 'gasoilIndexCost');
        regularizationOfTransporter.total = (regularizationOfTransporter.purchaseCost ?? 0) + (regularizationOfTransporter.fees ?? 0) + (regularizationOfTransporter.gasoilIndexCost ?? 0);

        setRegularizations(regularizationsArray);
        props.handleRefreshRegularizations(regularizationsArray);
    }

    const handleChangeRegularizationFeesVehicle = (fees: number, transporterId: string, planningVehicleId: string): void => {
        const regularizationsArray = [...regularizations];
        const regularizationOfTransporter = regularizationsArray.find(r => r.transporterId === transporterId);
        const vehicle = regularizationOfTransporter.details.find(v => v.planningVehicleId === planningVehicleId);
        vehicle.fees = fees;

        regularizationOfTransporter.fees = Utilities.sum(regularizationOfTransporter.details, 'fees');
        regularizationOfTransporter.total = (regularizationOfTransporter.purchaseCost ?? 0) + (regularizationOfTransporter.fees ?? 0) + (regularizationOfTransporter.gasoilIndexCost ?? 0);

        setRegularizations(regularizationsArray);
        props.handleRefreshRegularizations(regularizationsArray);
    }

    const handleChangeRemarks = (remarks: string, transporterId: string, planningVehicleId: string): void => {
        const regularizationsArray = [...regularizations];
        const regularizationOfTransporter = regularizationsArray.find(r => r.transporterId === transporterId);
        const vehicle = regularizationOfTransporter.details.find(v => v.planningVehicleId === planningVehicleId);
        vehicle.regularizationRemarks = remarks;

        setRegularizations(regularizationsArray);
        props.handleRefreshRegularizations(regularizationsArray);
    }

    return (<Box display='flex' flexDirection='column' p={1}>
                    <Box pb={2} display="flex" flexDirection="column">
                        <Box display="flex" flexDirection="row" pr={3}>
                            <Box width="50%" />
                            <Box width="50%" display="flex" flexDirection="row">
                                <Box width="50%" display="flex" flexDirection="column">
                                    <Box display="flex" flexDirection="row">
                                        <Box width="50%" className="title" >
                                            Coût
                                        </Box>
                                        <Box width="50%" className="title" >
                                            Frais
                                        </Box>
                                    </Box>
                                    <Box className="title title-help">
                                        Ajouter/Soustraire
                                    </Box>
                                </Box>
                                <Box width="50%" className="title" >
                                    Commentaire régul.
                                </Box>
                            </Box>
                        </Box>
                        <LocalizationProvider language="fr-FR">
                            <IntlProvider locale="fr" >
                                <div className="transporter-content">
                                    {
                                        regularizations.map((r, transporterIndex) => {
                                            return (
                                                <Box key={`regularization-transporter-${transporterIndex}`} pt={1}>
                                                    <Box display="flex" flexDirection="row" className="transporter-header">
                                                        <Box width="50%" pl={0.5}>
                                                            {r.transporterName}
                                                        </Box>
                                                        <Box width="12.5%" className="cell">
                                                            {r.purchaseCost ? Number(r.purchaseCost.toFixed(2)).toCurrencyString() : ''}
                                                        </Box>
                                                        <Box width="12.5%" className="cell">
                                                            {r.fees ? Number(r.fees.toFixed(2)).toCurrencyString() : ''}
                                                        </Box>
                                                        <Box width="25%" />
                                                    </Box>
                                                    {
                                                        r.details?.map((v, vehicleIndex) => {
                                                            return(
                                                                <Box key={`vehicle-${transporterIndex}-${vehicleIndex}`} display="flex" flexDirection="row" pt={1} pb={1}>
                                                                    <Box width="50%" display="flex" flexDirection="row" alignItems="center">
                                                                        <Box width="20%" className="cell">
                                                                            {v.planningDate.toShortDateString()}
                                                                        </Box>
                                                                        <Divider orientation="vertical" />
                                                                        <Box width="15%" className="cell">
                                                                            {v.planningVehicleNumber}
                                                                        </Box>
                                                                        <Divider orientation="vertical" />
                                                                        <Box width="35%" className="cell">
                                                                            {v.expectedVehicleTypeLabel}
                                                                        </Box>
                                                                        <Divider orientation="vertical" />
                                                                        <Box width="30%" className="cell">
                                                                            {v.vehicleLicencePlate ?? ''}
                                                                        </Box>
                                                                    </Box>
                                                                    <Box width="50%" display="flex" flexDirection="row">
                                                                        <Box width="25%" display="flex" flexDirection="row" alignContent="center">
                                                                            {v.purchaseCostCanBeRegularized && <NumericTextBox
                                                                                format={{
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'EUR',
                                                                                    currencyDisplay: 'symbol'
                                                                                }}
                                                                                onChange={(event) => handleChangeRegularizationPurchaseCostVehicle(event.target.value, r.transporterId, v.planningVehicleId)}
                                                                                value={v.purchaseCost ? v.purchaseCost : null}
                                                                                spinners={false} />}
                                                                        </Box>
                                                                        <Box width="25%" display="flex" flexDirection="row" alignContent="center">
                                                                            {v.feesCanBeRegularized && <NumericTextBox
                                                                                format={{
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'EUR',
                                                                                    currencyDisplay: 'symbol'
                                                                                }}
                                                                                onChange={(event) => handleChangeRegularizationFeesVehicle(event.target.value, r.transporterId, v.planningVehicleId)}
                                                                                value={v.fees ? v.fees : null}
                                                                                spinners={false} />}
                                                                        </Box>
                                                                        <Box width="50%">
                                                                            <TextField
                                                                                id="outlined-dense-multiline"
                                                                                margin="normal"
                                                                                multiline
                                                                                rows="2"
                                                                                className="txt-field-vehicle-remarks"
                                                                                onBlur={(e) => handleChangeRemarks(e.target.value, r.transporterId, v.planningVehicleId)}
                                                                                InputProps={{ disableUnderline: true }}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            );
                                                        })
                                                    }
                                                    {
                                                        (r.supplierMdmIdentifier && r.total >= purchaseProvisioningMaxAllowedCostLimit) && 
                                                        <div className="error-message">
                                                            {`Le montant d’un lot doit être inférieur à ${Number(purchaseProvisioningMaxAllowedCostLimit.toFixed(2)).toCurrencyString()} (indice Gazole: ${r.gasoilIndexCost ? Number(r.gasoilIndexCost.toFixed(2)).toCurrencyString():''})`}
                                                        </div>
                                                    }
                                                </Box>
                                            )
                                        })
                                    }
                                </div>
                            </IntlProvider>
                        </LocalizationProvider>
                    </Box>
                </Box>
    );
}