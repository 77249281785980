import { faClone, faPen, faPlusCircle, faSave, faSearch, faTag, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, Box, Button, IconButton, Input, InputAdornment, MenuItem, Select, Tooltip } from '@mui/material';
import { GridCellProps, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderSelectionChangeEvent, GridRowClickEvent, GridRowProps, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import { Splitter, SplitterOnChangeEvent, SplitterPaneProps } from '@progress/kendo-react-layout';
import { UploadFileInfo, UploadOnAddEvent, UploadOnBeforeUploadEvent } from '@progress/kendo-react-upload';
import clsx from 'clsx';
import { debounce, find, map } from 'lodash';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { FlowDiff } from 'src/shared/Differences/dataContracts/FlowDiff';
import { FlowDiffProperty } from 'src/shared/Differences/dataContracts/FlowDiffProperty';
import { VehicleGroupCountersLightModel } from 'src/shared/TransportIndicators/services/dataContracts/queryStack/VehicleGroupCountersLightModel';
import { EntityChangeAction } from 'src/shared/TransportRequestChanges/models/EntityChangeAction';
import ToastService from 'src/ToastService';
import BusinessWarnings from 'src/utils/BusinessWarnings';
import { CalendarComponent } from '../../shared/components/Common/CalendarComponent';
import SimpleDialog from '../../shared/components/Common/SimpleDialog';
import { DateRange } from '../../shared/models/DateRange';
import { DeliveryTripStatus } from '../../shared/models/DeliveryTripStatus';
import { LogisticsUnitChoice } from '../../shared/models/LogisticsUnitChoice';
import { WebAppActionResult } from '../../shared/models/WebAppActionResult';
import VehicleGroupCountersComponent from '../../shared/TransportIndicators/components/VehicleGroupCountersComponent';
import { TransportIndicatorsApiClient } from '../../shared/TransportIndicators/services/TransportIndicatorsApiClient';
import BusinessErrors from '../../utils/BusinessErrors';
import '../../utils/Date';
import { AppModule, LocalStorage, SessionStorage } from '../../utils/Storage';
import Utilities from '../../utils/Utilities';
import { RouteComponentProps, withRouter } from '../../withRouter';
import { TransportFlowHeaderComponent } from '../TransportFlow/components/TransportFlowHeaderComponent';
import { creationMode, duplicateMode, TransportFlowForm, updateMode } from '../TransportFlow/TransportFlowForm';
import DeliveryTripComponent, { DeliveryTripsGridName } from './components/DeliveryTripComponent';
import { enumValues } from './components/FlowDiff/CustomDiffCell.Resources';
import { TransportFlowComponent, TransportFlowsGridName } from './components/TransportFlowComponent';
import { TransportFlowDetailsDrawerComponent } from './components/TransportFlowDetailsDrawerComponent';
import TransportRequestComponent, { TransportRequestsGridName } from './components/TransportRequestComponent';
import { TransportRequestDetailsDrawerComponent } from './components/TransportRequestDetailsDrawerComponent';
import { DeliveryTripLightModelExtended } from './models/DeliveryTripLightModelExtended';
import { FormatTypeEnum } from './models/FormatTypeEnum';
import { PaneModel } from './models/PaneModel';
import { TitleValueDiffModel } from './models/TitleValueDiffModel';
import { TransportFlowComplementaryDetailsLightModelExtended } from './models/TransportFlowComplementaryDetailsLightModelExtended';
import { TransportFlowLightModelExtended } from './models/TransportFlowLightModelExtended';
import { TransportRequestLightModelExtended } from './models/TransportRequestLightModelExtended';
import { DeliveryTripChangeRequestArgs } from './services/dataContracts/controller/DeliveryTripChangeRequestArgs';
import { DeliveryTripLongIdentifierRequestArgs } from './services/dataContracts/controller/DeliveryTripLongIdentifierRequestArgs';
import { TransportFlowChangeRequestArgs } from './services/dataContracts/controller/TransportFlowChangeRequestArgs';
import { TransportFlowAttachmentFileLightModel } from './services/dataContracts/queryStack/TransportFlowAttachmentFileLightModel';
import { TransportRequestComplementaryDetailsLightModel } from './services/dataContracts/queryStack/TransportRequestComplementaryDetailsLightModel';
import { TransportPlanApiClient } from './services/TransportPlanApiClient';
import './TransportPlanStyles.scss';

interface TransportPlanState {
    data: Array<TransportRequestLightModelExtended>;
    detailsData: TransportRequestComplementaryDetailsLightModel;
    detailsTransportFlowData: TransportFlowComplementaryDetailsLightModelExtended;
    transportFlowAttachmentFiles: Array<TransportFlowAttachmentFileLightModel>;
    dataTrip: Array<DeliveryTripLightModelExtended>;
    dataFlow: Array<TransportFlowLightModelExtended>;
    loadingAL: boolean;
    loadingTrip: boolean;
    loadingFlow: boolean;
    pageLength: number;
    pageNumber: number;
    fromTime: Date,
    toTime: Date,
    searchDc: string;
    searchTrip: string;
    searchTransportFlow: string,
    isDetailsEnabled: boolean;
    isDetailsTransportFlowEnabled: boolean,
    isAddBtnEnabled: boolean;
    isUpdateBtnEnabled: boolean;
    isRemoveBtnEnabled: boolean;
    isDuplicBtnEnabled: boolean,
    isDuplicFlowBtnEnabled: boolean,
    isDeleteFlowBtnEnabled: boolean,
    isUpdateStatusTripBtnEnabled: boolean,
    isUpdateStatusFlowBtnEnabled: boolean,
    isLoading: boolean;
    selectedID: string;
    selectedTransportFlowID: string;
    selectedTransportFlowBusinessId: string;
    selectedTransportFlowStatus: string;
    selectedTransportRequestIDForFlow: string;
    selectedDeliveryTrips: Array<number>;
    deliveryTrips: Array<DeliveryTripLongIdentifierRequestArgs>;
    deliveryTrip: DeliveryTripLongIdentifierRequestArgs;
    editFieldDt: string;
    isOpenTransportFlowStatus: boolean;
    isTransportFlowStatusSelected: boolean;
    selectedValueTransportFlow: string;
    selectedValueDellCall: string;
    isOpenDelTrip: boolean;
    isDeliveryTripStatusSelected: boolean;
    selectedValueDelTrip: DeliveryTripStatus;
    areRequestDetailsOpened: boolean;
    areFlowDetailsOpened: boolean;
    verticalPanes: SplitterPaneProps[];
    vehicleTypeCount: VehicleGroupCountersLightModel,
    showconfirmationRemoveTripsModal: boolean,
    lastTimeStampGetVehicleTypeCount: number,
    lastTimeStampFindTransportRequestWithTrip: number,
    lastTimeStampFindTransportRequestWithFlowTrip: number,
    lastTimeStampFindTransportationsByTransportRequestID: number,
    lastTimeStampGetTransportFlowComplementaryDetailsByID: number,
    lastTimeStampFindTransportRequests: number,
    lastTimeStampFindDeliveryTrip: number,
    lastTimeStampFindDeliveryTripWithFlow: number,
    lastTimeStampFindTransportFlow: number,
    expandedPanels: Map<string, boolean>,
    getUnusedReservationsChanged: boolean,
    isTransportFlowFormViewOpened: boolean,
    flowDialogMode: string,
    flowDialogTransportFlowId: string,
    deleteMessage: string,
    toRemove: string,
    disableHandleConfirmRemoveButton: boolean,
    isLoadingAttachment: boolean,
    showConfirmationDeleteAttachmentModal: boolean,
    attachmentIdToDelete: string,
    vehicleTypesLabels: Map<string, string>,
    isDateRangeValid: boolean
}

interface TransportPlanProperties {
    location?: any,
    render?: any,
    areRequestDetailsOpened?: boolean,
    logisticsUnitIds: Array<string>,
    logisticsUnits: Array<LogisticsUnitChoice>
}

const propertyLabels: Map<string, string> = new Map<string, string>([
    ['TransportFlowStatus.Pending', 'En attente'],
    ['TransportFlowStatus.Initialized', 'Initial'],
    ['TransportFlowStatus.Optimized', 'Optimisé'],
    ['TransportFlowStatus.InProgress', 'En cours'],
    ['TransportFlowStatus.Planned', 'Planifié'],
    ['TransportFlowStatus.Confirmed', 'Confirmé'],
    ['TransportFlowStatus.Finished', 'Terminé'],
    ['TransportFlowStatus.Canceled', 'Annulé'],
    ['TransportPriority.High', 'Fixe'],
    ['TransportPriority.Medium', 'Journée'],
    ['TransportPriority.Low', 'Modulable'],
    ['TransportServiceKind.Delivery', 'Livraison'],
    ['TransportServiceKind.Removal', 'Enlèvements'],
    ['TransportServiceKind.JobsiteVehicle', 'Camion chantier'],
    ['TransportServiceKind.Delivery_Perishable', 'Périssables'],
    ['TransportServiceKind.Delivery_Not_Perishable', 'Non périssables'],
    ['CoatingApplicationMode.Manual', 'Manuel'],
    ['CoatingApplicationMode.Mechanical', 'Mécanique'],
    ['CoatingApplicationMode.Mixed', 'Mixte'],
    ['CoatingApplicationMode.NotApplicable', 'Blanc']
]);

//Fields
enum KnownFields {
    Selected = "selected",
    DeliveryTime = "deliveryTime",
    LoadingTime = "loadingTime",
    ExpectedQuantity = "expectedQuantity",
    Instructions = "instructions"
}

enum KnownTypes {
    String = 'String',
    DateTimeOffsetNullable = 'DateTimeOffset?',
    DateTimeOffset = 'DateTimeOffset',
    TimeStampNullable = 'TimeStamp?',
    TimeStamp = 'TimeStamp',
    BooleanNullable = 'Boolean?',
    Boolean = 'Boolean',
    CoatingApplicationModeNullable = 'CoatingApplicationMode?',
    CoatingApplicationMode = 'CoatingApplicationMode',
}

const ModuleKey = AppModule.TransportPlan;

enum Panels {
    TransportRequest = 'panel-besoin',
    TransportFlow = 'panel-flux',
    Trip = 'panel-trajet'
}

class TransportPlanView extends React.Component<TransportPlanProperties & RouteComponentProps, TransportPlanState>{
    _isMounted: boolean;
    searchDcRef: React.RefObject<HTMLInputElement>;
    searchDtRef: React.RefObject<HTMLInputElement>;
    searchFlowRef: React.RefObject<HTMLInputElement>;
    defaultValue: { start: Date, end: Date };
    today: Date;
    lastSelectedIndex = 0;

    preventExitDt: boolean;
    preventExitTimeoutDt: ReturnType<typeof setTimeout>;
    blurTimeoutDt: ReturnType<typeof setTimeout>;
    fieldValueBeforeEntering: string | number | Date;

    sortByAlert: boolean;

    constructor(props) {
        super(props);
        this.searchDcRef = React.createRef();
        this.searchDtRef = React.createRef();
        this.searchFlowRef = React.createRef();
        this.defaultValue = { start: SessionStorage.ActiveStartDate, end: SessionStorage.ActiveEndDate };
        this.today = new Date();
        this._isMounted = false;
        this.sortByAlert = false;

        this.state = {
            data: [],
            detailsData: null,
            detailsTransportFlowData: null,
            transportFlowAttachmentFiles: null,
            dataTrip: [],
            dataFlow: [],
            loadingAL: true,
            loadingTrip: true,
            loadingFlow: false,
            pageLength: 100000,
            pageNumber: 0,
            searchDc: '',
            searchTrip: '',
            searchTransportFlow: '',
            selectedID: null,
            selectedTransportFlowID: null,
            selectedTransportFlowBusinessId: null,
            selectedTransportFlowStatus: null,
            selectedTransportRequestIDForFlow: null,
            fromTime: SessionStorage.ActiveStartDate,
            toTime: SessionStorage.ActiveEndDate,
            isAddBtnEnabled: false,
            isDetailsEnabled: false,
            isDetailsTransportFlowEnabled: false,
            isUpdateBtnEnabled: false,
            isRemoveBtnEnabled: false,
            isDuplicBtnEnabled: false,
            isDuplicFlowBtnEnabled: false,
            isDeleteFlowBtnEnabled: false,
            isUpdateStatusTripBtnEnabled: false,
            isUpdateStatusFlowBtnEnabled: false,
            isLoading: false,
            selectedDeliveryTrips: [],
            deliveryTrips: [],
            deliveryTrip: null,
            editFieldDt: null,
            selectedValueDellCall: "",
            isOpenTransportFlowStatus: false,
            isTransportFlowStatusSelected: false,
            selectedValueTransportFlow: "",
            isOpenDelTrip: false,
            isDeliveryTripStatusSelected: false,
            selectedValueDelTrip: null,
            areRequestDetailsOpened: false,
            areFlowDetailsOpened: false,
            verticalPanes: this.getVerticalPanesFromSessionStorage(),
            vehicleTypeCount: { missingOthersTypeCount: 0, missingSemiCount: 0, missingX6Count: 0, missingX8Count: 0, othersTypeCount: 0, semiCount: 0, x6Count: 0, x8Count: 0 },
            lastTimeStampGetVehicleTypeCount: Date.now(),
            lastTimeStampFindTransportRequestWithTrip: Date.now(),
            lastTimeStampFindTransportRequestWithFlowTrip: Date.now(),
            lastTimeStampFindTransportationsByTransportRequestID: Date.now(),
            lastTimeStampGetTransportFlowComplementaryDetailsByID: Date.now(),
            lastTimeStampFindTransportRequests: Date.now(),
            lastTimeStampFindDeliveryTrip: Date.now(),
            lastTimeStampFindDeliveryTripWithFlow: Date.now(),
            lastTimeStampFindTransportFlow: Date.now(),
            showconfirmationRemoveTripsModal: false,
            expandedPanels: this.getExpandedPanels(),
            getUnusedReservationsChanged: false,
            isTransportFlowFormViewOpened: false,
            flowDialogMode: "",
            flowDialogTransportFlowId: null,
            toRemove: "",
            deleteMessage: "",
            disableHandleConfirmRemoveButton: false,
            isLoadingAttachment: false,
            showConfirmationDeleteAttachmentModal: false,
            attachmentIdToDelete: '',
            vehicleTypesLabels: new Map<string, string>(),
            isDateRangeValid: true
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const location: any = this.props.location;
        if (location.aboutProps && location.aboutProps.needLoaded && location.aboutProps.deliveryDate) {
            if (location.aboutProps.businessId)
                this.loadNotifAL(false);
            else {
                this.loadNotifAL(true);
            }
        }
        else {
            this.FindTransportRequestWithFlowAndVehicleTypes(this.state.pageLength, this.state.pageNumber, this.state.searchTrip, '', this.defaultValue.start, this.defaultValue.end, '', '', this.props.logisticsUnitIds, true);
            this.getVehicleTypeCount(this.defaultValue.start, this.defaultValue.end);
        }
    }

    componentDidUpdate() {
        const location: any = this.props.location;
        if (location.aboutProps && location.aboutProps.needLoaded && location.aboutProps.deliveryDate) {
            let shouldSearchByDate = true;
            if (location.aboutProps.businessId)
                shouldSearchByDate = false;

            this.loadNotifAL(shouldSearchByDate);
        }
    }
    shouldComponentUpdate(nextProps: TransportPlanProperties, nextState: TransportPlanState) {
        if (this.state.lastTimeStampFindTransportRequests > nextState.lastTimeStampFindTransportRequests
            || this.state.lastTimeStampFindDeliveryTrip > nextState.lastTimeStampFindDeliveryTrip
            || this.state.lastTimeStampFindTransportRequestWithTrip > nextState.lastTimeStampFindTransportRequestWithTrip
            || this.state.lastTimeStampFindTransportationsByTransportRequestID > nextState.lastTimeStampFindTransportationsByTransportRequestID
            || this.state.lastTimeStampGetVehicleTypeCount > nextState.lastTimeStampGetVehicleTypeCount
        )
            return false;
        return true;
    }

    //#region functions
    getVerticalPanesFromSessionStorage = (): SplitterPaneProps[] => {

        return [
            { size: '50px', scrollable: false, min: '50px' },
            { size: 'calc(100% - 117px)', scrollable: false, min: '50px' },
            { min: '50px', scrollable: false }
        ];
    }

    getExpandedPanels = (): Map<string, boolean> => {
        return new Map([
            [Panels.TransportRequest, false],
            [Panels.TransportFlow, true],
            [Panels.Trip, false]
        ]);
    }

    getVehicleTypeCount = (fromDate: Date, untilDate: Date): void => {
        const fromTime: Date = fromDate.stripTime();
        const untilTime: Date = untilDate.stripTime();

        const lastTimeStamp: number = this.state.lastTimeStampGetVehicleTypeCount;
        const currentTimeStamp: number = Date.now();

        TransportIndicatorsApiClient.GetVehicleGroupCounters(fromTime, untilTime, this.props.logisticsUnitIds, false)
            .then(res => {
                if (lastTimeStamp !== this.state.lastTimeStampGetVehicleTypeCount)
                    return;
                const data: VehicleGroupCountersLightModel = res.data;
                if (this._isMounted)
                    this.setState({ vehicleTypeCount: data, lastTimeStampGetVehicleTypeCount: currentTimeStamp });
            });
    }

    updateFilteredStyle = (divClassName: string, text: string): void => {
        const tdsG: HTMLCollectionOf<Element> = document.getElementById(divClassName)!.getElementsByClassName("k-grid-table");
        let isFiltered = false;
        if (text.length >= 3) {
            isFiltered = true;
        }
        Utilities.updateFilteredStyle(tdsG, isFiltered);
    }

    FindTransportRequestWithFlowAndVehicleTypes = (pageLength: number, pageNumber: number, searchTrip: string, transportRequestId: string, fromTime: Date, toTime: Date, searchTextTransportReq: string, searchTextTransportFlow: string, logisticsUnitIds: Array<string>, shouldRefreshVehicleTypes: boolean): void => {
        const lastTimeStamp: number = this.state.lastTimeStampFindTransportRequestWithFlowTrip;
        const currentTimeStamp: number = Date.now();

        //Fermer le panel des tours si ouvert
        if (this.state.expandedPanels.get(Panels.Trip)) {
            this.handleExpansionsChange(Panels.Trip);
        }

        this.setState({
            loadingAL: true, loadingTrip: true, loadingFlow: true, isDuplicFlowBtnEnabled: false, isDeleteFlowBtnEnabled: false, selectedValueTransportFlow: '',
            isUpdateStatusTripBtnEnabled: false, selectedValueDelTrip: null, isDeliveryTripStatusSelected: false, isUpdateStatusFlowBtnEnabled: false, isAddBtnEnabled: false
        });

        TransportPlanApiClient.FindTransportRequestWithFlowAndVehicleTypes(pageLength, pageNumber, searchTrip, transportRequestId, fromTime, toTime, searchTextTransportReq, searchTextTransportFlow, logisticsUnitIds, shouldRefreshVehicleTypes)
            .then(res => {
                if (lastTimeStamp !== this.state.lastTimeStampFindTransportRequestWithFlowTrip)
                    return;
                if (res.length > 0 && this._isMounted) {
                    const requests = res[0];
                    const flows = res[1];

                    let vehicleTypesMap = this.state.vehicleTypesLabels;

                    if (shouldRefreshVehicleTypes) {
                        vehicleTypesMap = new Map(res[2].data.map(obj => {
                            return [obj.vehicleTypeId, obj.label]
                        }));
                    }

                    const dataRequests: Array<TransportRequestLightModelExtended> = requests.data.pageItems;
                    const dataFlows: Array<TransportFlowLightModelExtended> = flows.data.pageItems;

                    dataRequests.forEach((transportRequest: TransportRequestLightModelExtended) => {
                        this.translateTransportRequest(transportRequest);
                    });

                    dataFlows.forEach((transportFlow: TransportFlowLightModelExtended) => {
                        this.translateTransportFlow(transportFlow, false);
                    });

                    if (lastTimeStamp !== this.state.lastTimeStampFindTransportRequestWithFlowTrip)
                        return;
                    if (this._isMounted) {

                        this.setState({
                            loadingAL: false,
                            loadingTrip: false,
                            loadingFlow: false,
                            data: dataRequests,
                            dataFlow: dataFlows,
                            dataTrip: [],
                            selectedID: null,
                            selectedTransportFlowID: null,
                            fromTime: fromTime,
                            toTime: toTime,
                            selectedDeliveryTrips: new Array<number>(),
                            deliveryTrips: new Array<DeliveryTripLongIdentifierRequestArgs>(),
                            vehicleTypesLabels: vehicleTypesMap,
                            isRemoveBtnEnabled: false, isDuplicBtnEnabled: false, isUpdateStatusTripBtnEnabled: false,
                            //Pour fermer le drawer des informations complémentaires lors d'une nouvelle recherche
                            areRequestDetailsOpened: transportRequestId === undefined || transportRequestId === "" ? false : true,
                            lastTimeStampFindTransportRequestWithFlowTrip: currentTimeStamp
                        }, () => {
                            this.updateFilteredStyle("TransportRequestGrid", this.searchDcRef.current.value);
                            this.updateFilteredStyle("transportFlows", this.searchFlowRef.current.value);
                        });
                    }
                    let filtredlabel: HTMLCollectionOf<Element> = document.getElementsByClassName("removed-chekbox");
                    if (transportRequestId === undefined || transportRequestId === "") {
                        if (filtredlabel.length > 0) {
                            filtredlabel[0].classList.add("k-checkbox-label");
                            filtredlabel[0].classList.remove("removed-chekbox");
                        }
                        const filtredDivs = document.getElementsByClassName("k-checkbox-label");
                        if (filtredDivs.length > 0) {
                            filtredDivs[0].classList.add("removed-chekbox");
                            filtredDivs[0].classList.remove("k-checkbox-label");
                        }
                    } else {
                        filtredlabel = document.getElementsByClassName("removed-chekbox");
                        if (filtredlabel.length > 0) {
                            filtredlabel[0].classList.add("k-checkbox-label");
                            filtredlabel[0].classList.remove("removed-chekbox");
                        }
                    }
                }
            })
            .catch(() => {
                this.setState({ loadingAL: false, loadingTrip: false, loadingFlow: false }, () => {
                    this.updateFilteredStyle("TransportRequestGrid", this.searchDcRef.current.value);
                    this.updateFilteredStyle("transportFlows", this.searchFlowRef.current.value);
                });
            });
    }

    //shouldSearchByDate when the click on the date of alert
    //else when click on transportRequestId
    loadNotifAL = (shouldSearchByDate: boolean): void => {
        const location: any = this.props.location;
        const deliveryDate: Date = new Date(location.aboutProps.deliveryDate);
        const fromTime: Date = deliveryDate.getDayStart();
        const toTime: Date = deliveryDate.getDayEnd();
        location.aboutProps.needLoaded = false;

        if (!shouldSearchByDate)
            this.searchDcRef.current.value = location.aboutProps.businessId;
        else
            this.sortByAlert = true;

        this.FindTransportRequestWithFlowAndVehicleTypes(this.state.pageLength, this.state.pageNumber, this.state.searchTrip, '', fromTime, toTime, this.searchDcRef.current.value, this.searchFlowRef.current.value, this.props.logisticsUnitIds, false);
        this.getVehicleTypeCount(fromTime, toTime);
    }

    onTransportRequestsResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            TransportRequestsGridName,
            currentColumn.field,
            currentColumn.width);
    }

    onTransportFlowsResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            TransportFlowsGridName,
            currentColumn.field,
            currentColumn.width);
    }

    onDeliveryTripsResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            DeliveryTripsGridName,
            currentColumn.field,
            currentColumn.width);
    }

    onTransportRequestsReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, TransportRequestsGridName, event.columns);
        this.forceUpdate();
    }

    onTransportFlowsReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, TransportFlowsGridName, event.columns);
        this.forceUpdate();
    }

    onDeliveryTripsReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, DeliveryTripsGridName, event.columns);
        this.forceUpdate();
    }

    handlerCalender = (range: DateRange): void => {
        this.setState({
            selectedID: "", areRequestDetailsOpened: false, areFlowDetailsOpened: false, isDetailsEnabled: false,
            isDuplicFlowBtnEnabled: false, isDeleteFlowBtnEnabled: false, selectedValueTransportFlow: '', selectedValueDellCall: '', selectedValueDelTrip: null, isUpdateStatusFlowBtnEnabled: false,
            isAddBtnEnabled: false, isUpdateBtnEnabled: false, isDuplicBtnEnabled: false, isRemoveBtnEnabled: false
        });

        if (range.end != null && range.start != null) {
            const fromTime: Date = range.start.getDayStart();
            const toTime: Date = range.end.getDayEnd();

            //Check if the dates has not similars
            if ((this.state.fromTime && this.state.fromTime.getTime() !== fromTime.getTime()) || (this.state.toTime && this.state.toTime.getTime() !== toTime.getTime())) {
                this.setState({ fromTime: fromTime, toTime: toTime });
                this.forceUpdate();
                this.FindTransportRequestWithFlowAndVehicleTypes(this.state.pageLength, this.state.pageNumber, this.state.searchTrip, '', fromTime, toTime, this.searchDcRef.current.value, this.searchFlowRef.current.value, this.props.logisticsUnitIds, false);
                this.getVehicleTypeCount(fromTime, toTime);
            }
        }
    }

    getTransportRequestsColumnWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, TransportRequestsGridName, fieldName, defaultWidth);
    }

    getTransportFlowsColumnWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, TransportFlowsGridName, fieldName, defaultWidth);
    }

    getDeliveryTripsColumnWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, DeliveryTripsGridName, fieldName, defaultWidth);
    }

    translateTransportRequest = (transportRequest: TransportRequestLightModelExtended): void => {
        transportRequest.statusField = transportRequest.status;
        transportRequest.status = (transportRequest.status === "InProgress") ? "En cours" : (transportRequest.status === "Approved" ? "Validé" : (transportRequest.status === "ProductionPlanned" ? "(P) Planifié"
            : (transportRequest.status === "ProductionPlanPublished" ? "(P) Publié" : (transportRequest.status === "Rejected" ? "Rejeté" : transportRequest.status === "Finished" ? "Terminé" : (transportRequest.status === "TransportPlanned" ? "Planifié"
                : (transportRequest.status === "TransportPlanPublished" ? "Publié" : (transportRequest.status === "Pending" ? "En attente" : "INCONNU")))))));
        transportRequest.coatingApplicationMode = transportRequest.coatingApplicationMode === "Manual" ? "Manuel" : (transportRequest.coatingApplicationMode === "Mechanical" ? "Mécanique" : (transportRequest.coatingApplicationMode === "Mixed" ? "Mixte" : (transportRequest.coatingApplicationMode === "NotApplicable" || transportRequest.coatingApplicationMode === null ? "" : "INCONNU")));
        transportRequest.deliveryDate = transportRequest.deliveryDate === null ? null : new Date(transportRequest.deliveryDate);
        transportRequest.deliveryEndDate = transportRequest.deliveryEndDate === null ? null : new Date(transportRequest.deliveryEndDate);
        transportRequest.firstLoadingTime = transportRequest.firstLoadingTime === null ? null : Date.getTimeFromIsoString(transportRequest.firstLoadingTime);
        transportRequest.creationTime = transportRequest.creationTime === null ? null : new Date(transportRequest.creationTime);
        transportRequest.serviceKindLabel = this.getLabelOfServiceKind(transportRequest.transportRequestType, transportRequest.isPerishableProduct);
        transportRequest.priority = this.getLabelFromDict('TransportPriority', transportRequest.priority);

        const fromDate: Date = (new Date()).stripTime();
        const deliveryDate: Date = new Date(transportRequest.deliveryDate);
        let untilDate: Date = (new Date()).stripTime();
        untilDate = new Date(untilDate.setDate(untilDate.getDate() + 2));

        transportRequest.hasUnobservedChanged = (
            transportRequest.lastEntityChangeAction === EntityChangeAction.Added ?
                (
                    transportRequest.hasUnobservedChanged && deliveryDate >= fromDate && deliveryDate < untilDate)
                :
                (
                    transportRequest.lastEntityChangeAction === EntityChangeAction.Modified
                        ? (transportRequest.hasUnobservedChanged && deliveryDate >= fromDate) : false
                )
        );
        transportRequest.selected = false;
        transportRequest.lblTransportRequired = transportRequest.isTransportRequired ? '' : 'Emp.';
    }

    transportRequestKeyPressed = (searchtext: string): void => {
        this.setState({ searchDc: searchtext, isUpdateBtnEnabled: false, selectedValueDellCall: '' });
        this.FindTransportRequestWithFlowAndVehicleTypes(this.state.pageLength, this.state.pageNumber, this.state.searchTrip, '', this.state.fromTime, this.state.toTime, searchtext, this.state.searchTransportFlow, this.props.logisticsUnitIds, false);
        this.getVehicleTypeCount(this.state.fromTime, this.state.toTime);
    }

    transportFlowKeyPressed = (searchText: string): void => {
        //Fermer le panel des tours si ouvert
        if (!this.state.selectedID && this.state.expandedPanels.get(Panels.Trip)) {
            this.handleExpansionsChange(Panels.Trip);
        }

        this.setState({ searchTransportFlow: searchText });
        if (this.state.selectedID) {
            this.FindDeliveryTripWithFlow(this.state.pageLength, this.state.pageNumber, this.state.searchTrip, this.state.selectedID, this.state.fromTime, this.state.toTime, this.state.searchDc, searchText);
        }
        else {
            this.FindFlow(this.state.pageLength, this.state.pageNumber, "", this.state.fromTime, this.state.toTime, this.state.searchDc, searchText);
        }
        this.getVehicleTypeCount(this.state.fromTime, this.state.toTime);
    }

    handleTransportRequestKeyPress = debounce((text: string): void => {
        if (text.length >= 3 || text.length === 0) {
            this.transportRequestKeyPressed(text);
        }
    }, 500);

    FindDeliveryTrip = (pageLength: number, pageNumber: number, searchText: string, transportRequestId: string, transportFlowId: string, fromTime: Date, toTime: Date, searchTextTransportReq: string, searchTextTransportFlow: string): void => {
        if (transportRequestId == null)
            transportRequestId = "";

        if (transportFlowId == null)
            transportFlowId = "";

        const lastTimeStamp: number = this.state.lastTimeStampFindDeliveryTrip;
        const currentTimeStamp: number = Date.now();
        this.setState({ loadingTrip: true });

        TransportPlanApiClient.FindDeliveryTrip(pageLength, pageNumber, searchText, transportRequestId, transportFlowId, fromTime, toTime, searchTextTransportReq, searchTextTransportFlow, this.props.logisticsUnitIds)
            .then(json => {
                if (lastTimeStamp !== this.state.lastTimeStampFindDeliveryTrip)
                    return;

                if (this._isMounted) {
                    const dataTrips = json.data;
                    const data: Array<DeliveryTripLightModelExtended> = dataTrips.pageItems;
                    data.forEach((deliveryTrip: DeliveryTripLightModelExtended) => {
                        this.translateDeliveryTrip(deliveryTrip);
                    });

                    if (lastTimeStamp !== this.state.lastTimeStampFindDeliveryTrip)
                        return;

                    const { selectedTransportFlowID, dataFlow } = this.state;

                    if (this._isMounted) {
                        this.setState({
                            loadingTrip: false,
                            dataTrip: data,
                            isDeleteFlowBtnEnabled: selectedTransportFlowID ? dataFlow.find(fl => fl.transportFlowId === selectedTransportFlowID).isRemovable : false,
                            selectedDeliveryTrips: new Array<number>(), deliveryTrips: new Array<DeliveryTripLongIdentifierRequestArgs>(),
                            isRemoveBtnEnabled: false, isDuplicBtnEnabled: false, isUpdateStatusTripBtnEnabled: false,
                            //Pour fermer le drawer des informations complémentaires lors d'une nouvelle recherche
                            areRequestDetailsOpened: transportRequestId === undefined || transportRequestId === "" ? false : true,
                            lastTimeStampFindDeliveryTrip: currentTimeStamp
                        }, () => {
                            this.updateFilteredStyle("deliveryTrips", this.searchDtRef.current.value);
                        });
                    }
                    let filtredlabel: HTMLCollectionOf<Element> = document.getElementsByClassName("removed-chekbox");
                    if (transportRequestId === undefined || transportRequestId === "") {
                        if (filtredlabel.length > 0) {
                            filtredlabel[0].classList.add("k-checkbox-label");
                            filtredlabel[0].classList.remove("removed-chekbox");
                        }
                        const filtredDivs: HTMLCollectionOf<Element> = document.getElementsByClassName("k-checkbox-label");
                        if (filtredDivs.length > 0) {
                            filtredDivs[0].classList.add("removed-chekbox");
                            filtredDivs[0].classList.remove("k-checkbox-label");
                        }
                    } else {
                        filtredlabel = document.getElementsByClassName("removed-chekbox");
                        if (filtredlabel.length > 0) {
                            filtredlabel[0].classList.add("k-checkbox-label");
                            filtredlabel[0].classList.remove("removed-chekbox");
                        }
                    }
                }
            })
            .catch(e => {
                this.setState({ loadingTrip: false }, () => {
                    this.updateFilteredStyle("deliveryTrips", this.searchDtRef.current.value);
                });
            });
    }

    FindDeliveryTripWithFlow = (pageLength: number, pageNumber: number, searchText: string, transportRequestId: string, fromTime: Date, toTime: Date, searchTextTransportReq: string, searchTextTransportFlow: string): void => {
        if (transportRequestId == null)
            transportRequestId = "";

        const lastTimeStamp: number = this.state.lastTimeStampFindDeliveryTrip;
        const currentTimeStamp: number = Date.now();

        this.setState({
            loadingTrip: true, loadingFlow: true, isAddBtnEnabled: false,
            isLoading: true, areFlowDetailsOpened: false, isDuplicFlowBtnEnabled: false, isDeleteFlowBtnEnabled: false, selectedValueTransportFlow: '',
            selectedValueDelTrip: null, isDeliveryTripStatusSelected: false, isTransportFlowStatusSelected: false, isUpdateStatusFlowBtnEnabled: false, detailsTransportFlowData: null, transportFlowAttachmentFiles: null, selectedTransportFlowID: null, selectedTransportFlowBusinessId: null,
        });

        TransportPlanApiClient.FindDeliveryTripWithFlow(pageLength, pageNumber, searchText, transportRequestId, fromTime, toTime, searchTextTransportReq, searchTextTransportFlow, this.props.logisticsUnitIds)
            .then(res => {
                if (this._isMounted) {
                    if (lastTimeStamp !== this.state.lastTimeStampFindDeliveryTrip)
                        return;

                    if (res.length > 0 && this._isMounted) {
                        const trips = res[0];
                        const delFlows = res[1];

                        const dataTrips: Array<DeliveryTripLightModelExtended> = trips.data.pageItems;
                        const flows: Array<TransportFlowLightModelExtended> = delFlows.data.pageItems;

                        dataTrips.forEach((deliveryTrip: DeliveryTripLightModelExtended) => {
                            this.translateDeliveryTrip(deliveryTrip);
                        });

                        flows.forEach((transportFlow: TransportFlowLightModelExtended) => {
                            this.translateTransportFlow(transportFlow, false);
                        });

                        if (lastTimeStamp !== this.state.lastTimeStampFindDeliveryTrip)
                            return;

                        if (this._isMounted) {
                            this.setState({
                                loadingTrip: false,
                                loadingFlow: false,
                                dataTrip: dataTrips,
                                dataFlow: flows,
                                selectedDeliveryTrips: new Array<number>(), deliveryTrips: new Array<DeliveryTripLongIdentifierRequestArgs>(),
                                isRemoveBtnEnabled: false, isDuplicBtnEnabled: false, isUpdateStatusTripBtnEnabled: false,
                                //Pour fermer le drawer des informations complémentaires lors d'une nouvelle recherche
                                areRequestDetailsOpened: transportRequestId === undefined || transportRequestId === "" ? false : true,
                                lastTimeStampFindDeliveryTrip: currentTimeStamp
                            }, () => {
                                this.updateFilteredStyle("deliveryTrips", this.searchDtRef.current.value);
                                this.updateFilteredStyle("transportFlows", this.searchFlowRef.current.value);
                            });
                        }
                        let filtredlabel: HTMLCollectionOf<Element> = document.getElementsByClassName("removed-chekbox");
                        if (transportRequestId === undefined || transportRequestId === "") {
                            if (filtredlabel.length > 0) {
                                filtredlabel[0].classList.add("k-checkbox-label");
                                filtredlabel[0].classList.remove("removed-chekbox");
                            }
                            const filtredDivs: HTMLCollectionOf<Element> = document.getElementsByClassName("k-checkbox-label");
                            if (filtredDivs.length > 0) {
                                filtredDivs[0].classList.add("removed-chekbox");
                                filtredDivs[0].classList.remove("k-checkbox-label");
                            }
                        } else {
                            filtredlabel = document.getElementsByClassName("removed-chekbox");
                            if (filtredlabel.length > 0) {
                                filtredlabel[0].classList.add("k-checkbox-label");
                                filtredlabel[0].classList.remove("removed-chekbox");
                            }
                        }
                    }
                }
            })
            .catch(e => {
                this.setState({ loadingTrip: false, loadingFlow: false }, () => {
                    this.updateFilteredStyle("deliveryTrips", this.searchDtRef.current.value);
                });
            });
    }

    FindFlow = (pageLength: number, pageNumber: number, transportRequestId: string, fromTime: Date, toTime: Date, searchTextTransportReq: string, searchTextTransportFlow: string): void => {
        if (transportRequestId == null)
            transportRequestId = "";

        const lastTimeStamp: number = this.state.lastTimeStampFindDeliveryTrip;
        const currentTimeStamp: number = Date.now();

        this.setState({
            loadingFlow: true, loadingTrip: true, isAddBtnEnabled: false,
            isLoading: true, areFlowDetailsOpened: false, isDuplicFlowBtnEnabled: false, isDeleteFlowBtnEnabled: false, selectedValueTransportFlow: '',
            selectedValueDelTrip: null, isDeliveryTripStatusSelected: false, isTransportFlowStatusSelected: false, isUpdateStatusFlowBtnEnabled: false, detailsTransportFlowData: null, transportFlowAttachmentFiles: null, selectedTransportFlowID: null, selectedTransportFlowBusinessId: null,
        });

        TransportPlanApiClient.FindTransportFlow(pageLength, pageNumber, searchTextTransportReq, searchTextTransportFlow, transportRequestId, fromTime, toTime, this.props.logisticsUnitIds)
            .then(res => {
                if (this._isMounted) {
                    if (lastTimeStamp !== this.state.lastTimeStampFindDeliveryTrip)
                        return;

                    if (this._isMounted) {
                        const flows: Array<TransportFlowLightModelExtended> = res.data.pageItems;

                        flows.forEach((transportFlow: TransportFlowLightModelExtended) => {
                            this.translateTransportFlow(transportFlow, false);
                        });

                        if (lastTimeStamp !== this.state.lastTimeStampFindDeliveryTrip)
                            return;

                        if (this._isMounted) {
                            this.setState({
                                loadingFlow: false,
                                dataFlow: flows,
                                loadingTrip: false,
                                dataTrip: [],
                                selectedDeliveryTrips: new Array<number>(),
                                deliveryTrips: new Array<DeliveryTripLongIdentifierRequestArgs>(),
                                isRemoveBtnEnabled: false, isDuplicBtnEnabled: false, isUpdateStatusTripBtnEnabled: false,
                                //Pour fermer le drawer des informations complémentaires lors d'une nouvelle recherche
                                areRequestDetailsOpened: transportRequestId === undefined || transportRequestId === "" ? false : true,
                                lastTimeStampFindDeliveryTrip: currentTimeStamp
                            }, () => {
                                this.updateFilteredStyle("transportFlows", this.searchFlowRef.current.value);
                            });
                        }
                        let filtredlabel: HTMLCollectionOf<Element> = document.getElementsByClassName("removed-chekbox");
                        if (transportRequestId === undefined || transportRequestId === "") {
                            if (filtredlabel.length > 0) {
                                filtredlabel[0].classList.add("k-checkbox-label");
                                filtredlabel[0].classList.remove("removed-chekbox");
                            }
                            const filtredDivs: HTMLCollectionOf<Element> = document.getElementsByClassName("k-checkbox-label");
                            if (filtredDivs.length > 0) {
                                filtredDivs[0].classList.add("removed-chekbox");
                                filtredDivs[0].classList.remove("k-checkbox-label");
                            }
                        } else {
                            filtredlabel = document.getElementsByClassName("removed-chekbox");
                            if (filtredlabel.length > 0) {
                                filtredlabel[0].classList.add("k-checkbox-label");
                                filtredlabel[0].classList.remove("removed-chekbox");
                            }
                        }
                    }
                }
            })
            .catch(e => {
            });
    }

    translateDeliveryTrip = (deliveryTrip: DeliveryTripLightModelExtended): void => {
        deliveryTrip.statusField = deliveryTrip.tripStatus;
        deliveryTrip.tripStatus = deliveryTrip.tripStatus === "TransportNeeded" ? "Générique" : (deliveryTrip.tripStatus === "TransportPlanned" ? "Planifié" : (deliveryTrip.tripStatus === "TransportConfirmed" ? "Confirmé" : (deliveryTrip.tripStatus === "InProgress" ? "En cours" :
            (deliveryTrip.tripStatus === "Finished" ? "Terminé" : (deliveryTrip.tripStatus === "Canceled" ? "Annulé" : (deliveryTrip.tripStatus === "Rejected" ? "Rejeté" : "INCONNU"))))));
        deliveryTrip.loadingTime = deliveryTrip.loadingTime === null ? null : new Date(deliveryTrip.loadingTime);
        deliveryTrip.deliveryTime = deliveryTrip.deliveryTime === null ? null : new Date(deliveryTrip.deliveryTime);
        deliveryTrip.transportRequestDate = deliveryTrip.transportRequestDate === null ? null : new Date(deliveryTrip.transportRequestDate);
        deliveryTrip.tripDriverPhoneNumber = Utilities.formatPhoneNumber(deliveryTrip.tripDriverPhoneNumber);
        deliveryTrip.selected = false;
    }

    getLabelFromDict = (objectName: string, fieldName: string): string => {
        const res: string = propertyLabels.get(`${objectName}.${fieldName}`);
        return res !== undefined ? res : 'INCONNU';
    }

    getLabelOfServiceKind = (serviceKind: string, isPerishableProduct: boolean): string => {
        if (serviceKind != 'Delivery')
            return this.getLabelFromDict('TransportServiceKind', serviceKind);

        return this.getLabelFromDict('TransportServiceKind', (isPerishableProduct ? 'Delivery_Perishable' : 'Delivery_Not_Perishable'));
    }

    translateTransportFlow = (transportFlow: TransportFlowLightModelExtended, flowSelected: boolean): void => {
        transportFlow.statusField = transportFlow.flowStatus;
        transportFlow.flowStatus = this.getLabelFromDict('TransportFlowStatus', transportFlow.flowStatus);
        transportFlow.priority = this.getLabelFromDict('TransportPriority', transportFlow.priority);
        transportFlow.serviceKindLabel = this.getLabelOfServiceKind(transportFlow.serviceKind, transportFlow.isPerishableProduct);
        transportFlow.deliveryStartDate = transportFlow.deliveryStartDate === null ? null : new Date(transportFlow.deliveryStartDate);
        transportFlow.deliveryEndDate = transportFlow.deliveryEndDate === null ? null : new Date(transportFlow.deliveryEndDate);
        transportFlow.arrivalTimeAtSenderSite = transportFlow.arrivalTimeAtSenderSite === null ? null : new Date(transportFlow.arrivalTimeAtSenderSite);
        transportFlow.lblPerishable = transportFlow.isPerishableProduct ? 'Oui' : 'Non';
        transportFlow.selected = flowSelected;
        transportFlow.remainingQuantity = transportFlow.remainingQuantity ?? 0;
        transportFlow.diffsFromRequest = transportFlow.diffsFromRequestJson ? JSON.parse(transportFlow.diffsFromRequestJson) : null;
    }

    deliveryTripKeyPressed = (searchtext: string): void => {
        this.setState({ searchTrip: searchtext, isDeliveryTripStatusSelected: false, isUpdateStatusTripBtnEnabled: false, selectedValueDelTrip: null });
        this.FindDeliveryTrip(this.state.pageLength, this.state.pageNumber, searchtext, this.state.selectedID, this.state.selectedTransportFlowID, this.state.fromTime, this.state.toTime, this.state.searchDc, this.state.searchTransportFlow);
    }

    handleDeliveryTripKeyPress = debounce((text: string): void => {
        if (text.length >= 3 || text.length === 0) {
            this.deliveryTripKeyPressed(text);
        }
    }, 500);

    handleTransportFlowKeyPress = debounce((text: string) => {
        if (text.length >= 3 || text.length === 0) {
            this.transportFlowKeyPressed(text);
        }
    }, 500);


    searchTransportFlowWithTrip = (dataItem: TransportRequestLightModelExtended): void => {
        this.setState({
            isLoading: true, areFlowDetailsOpened: false, isDuplicFlowBtnEnabled: false, isDeleteFlowBtnEnabled: false, selectedValueTransportFlow: '',
            selectedValueDelTrip: null, isDeliveryTripStatusSelected: false, isTransportFlowStatusSelected: false, isUpdateStatusFlowBtnEnabled: false, detailsTransportFlowData: null, transportFlowAttachmentFiles: null, selectedTransportFlowID: null, selectedTransportFlowBusinessId: null,
        });
        if (dataItem.transportRequestId === this.state.selectedID) {
            dataItem.selected = !dataItem.selected;

            //Fermer le panel des tours si ouvert
            if (this.state.expandedPanels.get(Panels.Trip))
                this.handleExpansionsChange(Panels.Trip);

            //areRequestDetailsOpened à false pour fermer le drawer des informations complémentaires lors d'une déselection
            this.setState({ selectedID: null, isAddBtnEnabled: false, isDetailsEnabled: false, detailsData: null, areRequestDetailsOpened: false, isUpdateBtnEnabled: false, selectedValueDellCall: '' });
            this.FindFlow(10000, 0, "", this.state.fromTime, this.state.toTime, this.state.searchDc, this.state.searchTransportFlow);
        } else {
            this.setState({ selectedID: dataItem.transportRequestId, isUpdateBtnEnabled: true });
            this.FindDeliveryTripWithFlow(10000, 0, this.state.searchTrip, dataItem.transportRequestId, this.state.fromTime, this.state.toTime, this.state.searchDc, this.state.searchTransportFlow);
            const lastTimeStamp: number = this.state.lastTimeStampFindTransportationsByTransportRequestID;
            const currentTimeStamp: number = Date.now();

            TransportPlanApiClient.GetTransportRequestComplementaryDetailsById(dataItem.transportRequestId)
                .then(json => {
                    if (this._isMounted) {
                        if (lastTimeStamp !== this.state.lastTimeStampFindTransportationsByTransportRequestID)
                            return;

                        const details: TransportRequestComplementaryDetailsLightModel = json.data;

                        this.setState({ detailsData: details, isDetailsEnabled: true, areRequestDetailsOpened: true, lastTimeStampFindTransportationsByTransportRequestID: currentTimeStamp });
                    }
                });

            let last: number = this.lastSelectedIndex;
            const current: number = this.state.data.findIndex((item: TransportRequestLightModelExtended) => item.transportRequestId === dataItem.transportRequestId);

            this.lastSelectedIndex = last = current;

            this.state.data.forEach((item: TransportRequestLightModelExtended) => item.selected = false);
            const select = !dataItem.selected;
            for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
                this.state.data[i].selected = select;
            }
        }
        //au cas ou y a des tours sélectionnés on les vire.
        //this.state.deliveryTrips.splice(0, this.state.deliveryTrips.length);
        //this.state.selectedDeliveryTrips.splice(0, this.state.selectedDeliveryTrips.length);
        this.setState({
            deliveryTrips: [],
            selectedDeliveryTrips: [],
            isLoading: false,
            isRemoveBtnEnabled: false,
            isDuplicBtnEnabled: false,
            isUpdateStatusTripBtnEnabled: false
        });
        this.forceUpdate();
    }

    searchDetailFlowWithTrip = (dataItem: TransportFlowLightModelExtended): void => {
        this.setState({
            isLoading: true, areRequestDetailsOpened: false, isUpdateStatusFlowBtnEnabled: false, isDuplicFlowBtnEnabled: false, isDeleteFlowBtnEnabled: false,
            isRemoveBtnEnabled: false, isDuplicBtnEnabled: false, isUpdateStatusTripBtnEnabled: false,
            isDeliveryTripStatusSelected: false, selectedValueDelTrip: null, isAddBtnEnabled: false
        });
        if (dataItem.transportFlowId === this.state.selectedTransportFlowID) {
            dataItem.selected = !dataItem.selected;

            //Fermer le panel des tours si aucun besoin ou flux séléctionné
            if (!this.state.selectedID && this.state.expandedPanels.get(Panels.Trip))
                this.handleExpansionsChange(Panels.Trip);

            if (this.state.selectedID) {
                this.setState({ selectedTransportFlowID: null, selectedTransportFlowBusinessId: null, selectedTransportFlowStatus: null, selectedTransportRequestIDForFlow: null, isDetailsTransportFlowEnabled: false, detailsTransportFlowData: null, transportFlowAttachmentFiles: null, areFlowDetailsOpened: false });
                this.FindDeliveryTrip(10000, 0, this.state.searchTrip, this.state.selectedID, "", this.state.fromTime, this.state.toTime, this.state.searchDc, this.state.searchTransportFlow);
            }
            else {
                //areFlowDetailsOpened à false pour fermer le drawer des informations complémentaires lors d'une déselection
                this.setState({
                    dataTrip: [], selectedDeliveryTrips: new Array<number>(), deliveryTrips: new Array<DeliveryTripLongIdentifierRequestArgs>(),
                    isRemoveBtnEnabled: false, isDuplicBtnEnabled: false, isUpdateStatusTripBtnEnabled: false,
                    areRequestDetailsOpened: false,
                    selectedTransportFlowID: null, selectedTransportFlowBusinessId: null, selectedTransportFlowStatus: null, selectedTransportRequestIDForFlow: null, isDetailsTransportFlowEnabled: false, detailsTransportFlowData: null, transportFlowAttachmentFiles: null, areFlowDetailsOpened: false
                });
            }
        }
        else {
            let isButtonAddEnabled = false;
            const startDate: Date = this.state.fromTime;
            const endDate: Date = this.state.toTime;
            if (startDate.toDateString() == endDate.toDateString()) {
                isButtonAddEnabled = true;
            }
            const lastTimeStamp: number = this.state.lastTimeStampGetTransportFlowComplementaryDetailsByID;
            const currentTimeStamp: number = Date.now();
            const isRemovableFlow: boolean = dataItem.isRemovable
            this.setState({
                selectedTransportFlowID: dataItem.transportFlowId, selectedTransportFlowBusinessId: dataItem.businessId, selectedTransportFlowStatus: dataItem.flowStatus,
                selectedTransportRequestIDForFlow: dataItem.transportRequestId, isUpdateStatusFlowBtnEnabled: true, isDuplicFlowBtnEnabled: true, isDeleteFlowBtnEnabled: isRemovableFlow, isAddBtnEnabled: isButtonAddEnabled
            });
            this.FindDeliveryTrip(10000, 0, this.state.searchTrip, this.state.selectedID, dataItem.transportFlowId, this.state.fromTime, this.state.toTime, this.state.searchDc, this.state.searchTransportFlow);

            TransportPlanApiClient.GetTransportFlowComplementaryDetailsAndAttachmentFileNamesId(dataItem.transportFlowId)
                .then(json => {
                    if (this._isMounted) {
                        if (lastTimeStamp !== this.state.lastTimeStampGetTransportFlowComplementaryDetailsByID)
                            return;

                        const details = json[0].data as TransportFlowComplementaryDetailsLightModelExtended;
                        if (details) {
                            details.diffsFromRequest = details.diffsFromRequestJson ? JSON.parse(details.diffsFromRequestJson) : null;
                        }

                        const transportFlowAttachmentFiles: Array<TransportFlowAttachmentFileLightModel> = json[1].data;
                        this.setState({ detailsTransportFlowData: details, transportFlowAttachmentFiles: transportFlowAttachmentFiles, isDetailsTransportFlowEnabled: true, areFlowDetailsOpened: true, lastTimeStampGetTransportFlowComplementaryDetailsByID: currentTimeStamp });
                    }
                });

            let last: number = this.lastSelectedIndex;
            const current: number = this.state.dataFlow.findIndex((item: TransportFlowLightModelExtended) => item === dataItem);

            this.lastSelectedIndex = last = current;

            this.state.dataFlow.forEach((item: TransportFlowLightModelExtended) => item.selected = false);
            const select = !dataItem.selected;
            for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
                this.state.dataFlow[i].selected = select;
            }
        }

        this.setState({
            isLoading: false
        });
        this.forceUpdate();
    }

    //Clic sur la case coché se trouvant dans l'entête
    headerSelectionChange = (event: GridHeaderSelectionChangeEvent): void => {
        const checked: boolean = event.nativeEvent.target.checked;
        let isRemoveBtnNeedDisabled = false;
        let isUpdateStatusTripBtnDisabled = false;
        const newSelectedDeliveryTrips: number[] = [];
        const newDeliveryTrips: Array<DeliveryTripLongIdentifierRequestArgs> = [];

        if (this.state.selectedTransportFlowID == null) {
            if (!toast.isActive("del-all-selection")) {
                ToastService.showWarningToast("Il faut sélectionner un Flux.");
            }
        } else {
            //au cas ou y a des tours sélectionnés on les vire.
            const trips = this.state.dataTrip;
            trips.forEach((item: DeliveryTripLightModelExtended) => {
                item.selected = checked;
                if (checked === true) {
                    newSelectedDeliveryTrips.push(item.deliveryTripId)
                    newDeliveryTrips.push({
                        deliveryTripId: item.deliveryTripId,
                        transportRequestId: item.transportRequestId,
                        transportFlowId: item.transportFlowId
                    });
                }
            });

            isRemoveBtnNeedDisabled = this.isTripsNeedDisabledBtnRemoved(trips, checked);
            isUpdateStatusTripBtnDisabled = trips.some(x => x.planningVehicleIsCanceled);

            this.setState({
                deliveryTrips: newDeliveryTrips,
                selectedDeliveryTrips: newSelectedDeliveryTrips
            });
        }

        if (newSelectedDeliveryTrips.length === 0)
            this.setState({ isRemoveBtnEnabled: false, isDuplicBtnEnabled: false, isUpdateStatusTripBtnEnabled: false })
        else if (newSelectedDeliveryTrips.length === 1)
            this.setState({
                isRemoveBtnEnabled: !isRemoveBtnNeedDisabled,
                isDuplicBtnEnabled: true,
                isUpdateStatusTripBtnEnabled: !isUpdateStatusTripBtnDisabled,
                deliveryTrip: this.state.deliveryTrips[0]
            })
        else
            this.setState({ isRemoveBtnEnabled: !isRemoveBtnNeedDisabled, isDuplicBtnEnabled: false, isUpdateStatusTripBtnEnabled: isUpdateStatusTripBtnDisabled })
        this.forceUpdate();
    }

    //Evenement clic sur la case à cocher
    selectionChange = (event: GridSelectionChangeEvent): void => {
        event.dataItem.selected = !event.dataItem.selected;
        const selectedDeliveryTripsClone: number[] = this.state.selectedDeliveryTrips.slice();

        if (event.dataItem.selected === true) {
            selectedDeliveryTripsClone.push(event.dataItem.deliveryTripId);
            this.setState({
                selectedDeliveryTrips: [...this.state.selectedDeliveryTrips, event.dataItem.deliveryTripId],
                deliveryTrips: [...this.state.deliveryTrips, {
                    deliveryTripId: event.dataItem.deliveryTripId,
                    transportRequestId: event.dataItem.transportRequestId,
                    transportFlowId: event.dataItem.transportFlowId
                }]
            });
        }
        else {
            const deliveryTripsClone: DeliveryTripLongIdentifierRequestArgs[] = this.state.deliveryTrips.slice();

            // get index of object with deliveryTripId
            const removeIndexTrip: number = map(deliveryTripsClone, 'deliveryTripId').findIndex((o) => {
                return o === event.dataItem.deliveryTripId
            });

            const removeIndexDelivery: number = selectedDeliveryTripsClone.findIndex((o) => {
                return o === event.dataItem.deliveryTripId
            });

            // remove objects
            selectedDeliveryTripsClone.splice(removeIndexDelivery, 1);
            deliveryTripsClone.splice(removeIndexTrip, 1);

            this.setState({
                selectedDeliveryTrips: selectedDeliveryTripsClone,
                deliveryTrips: deliveryTripsClone
            });
        }

        const selectedTrips: Array<DeliveryTripLightModelExtended> = this.state.dataTrip.filter(t => t.selected === true);
        const isRemoveBtnNeedDisabled: boolean = this.isTripsNeedDisabledBtnRemoved(selectedTrips, true);
        const isUpdateStatusTripBtnDisabled: boolean = selectedTrips.some(x => x.planningVehicleIsCanceled);

        if (selectedTrips.length === 0)
            this.setState({ isRemoveBtnEnabled: false, isDuplicBtnEnabled: false, isUpdateStatusTripBtnEnabled: false })
        else if (selectedTrips.length === 1) {
            const { dataFlow } = this.state;
            const flow = dataFlow.find(tf => tf.transportFlowId == event.dataItem.transportFlowId);
            const isJobSiteVehicle = flow?.serviceKind == "JobsiteVehicle";

            this.setState({
                isRemoveBtnEnabled: !isRemoveBtnNeedDisabled,
                isDuplicBtnEnabled: !isJobSiteVehicle && !selectedTrips[0].planningVehicleIsCanceled,
                isUpdateStatusTripBtnEnabled: !isUpdateStatusTripBtnDisabled,
                deliveryTrip: {
                    deliveryTripId: event.dataItem.deliveryTripId,
                    transportRequestId: event.dataItem.transportRequestId,
                    transportFlowId: event.dataItem.transportFlowId
                }
            })
        }
        else {
            this.setState({ isRemoveBtnEnabled: !isRemoveBtnNeedDisabled, isDuplicBtnEnabled: false, isUpdateStatusTripBtnEnabled: !isUpdateStatusTripBtnDisabled });
        }
        this.forceUpdate();
    }

    //Evenement clic sur la ligne
    rowClickDeliveryTrip = (dataItem: DeliveryTripLightModelExtended, event?: GridRowClickEvent): void => {
        const newSelectedDeliveryTrips: number[] = [];
        const newDeliveryTrips: Array<DeliveryTripLongIdentifierRequestArgs> = [];
        let select = !dataItem.selected;
        newSelectedDeliveryTrips.push(dataItem.deliveryTripId);
        newDeliveryTrips.push({
            deliveryTripId: dataItem.deliveryTripId,
            transportRequestId: dataItem.transportRequestId,
            transportFlowId: dataItem.transportFlowId
        });

        this.setState({
            deliveryTrip: {
                deliveryTripId: dataItem.deliveryTripId,
                transportRequestId: dataItem.transportRequestId,
                transportFlowId: dataItem.transportFlowId
            },
            selectedDeliveryTrips: newSelectedDeliveryTrips,
            deliveryTrips: newDeliveryTrips
        });

        let last: number = this.lastSelectedIndex;
        const current: number = this.state.dataTrip.findIndex((item: DeliveryTripLightModelExtended) => item === dataItem);
        const newDataTrip: DeliveryTripLightModelExtended[] = this.state.dataTrip.slice();

        if (event) {
            if (!event.nativeEvent.shiftKey) {
                this.lastSelectedIndex = last = current;
            }

            if (!event.nativeEvent.ctrlKey) {
                select = true;
                newDataTrip.forEach((item: DeliveryTripLightModelExtended) => item.selected = false);
            }
        } else {
            this.lastSelectedIndex = last = current;
            select = true;
            newDataTrip.forEach((item: DeliveryTripLightModelExtended) => item.selected = false);
        }

        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
            newDataTrip[i].selected = select;
        }

        let isJobSiteVehicle = false;
        if (select) {
            const { dataFlow } = this.state;
            const flow = dataFlow.find(tf => tf.transportFlowId == dataItem.transportFlowId);
            isJobSiteVehicle = flow?.serviceKind == "JobsiteVehicle";
        }

        const selectedTrips: Array<DeliveryTripLightModelExtended> = newDataTrip.filter(t => t.selected === true);
        const isRemoveBtnNeedDisabled: boolean = selectedTrips.length == 0 ? true : this.isTripsNeedDisabledBtnRemoved(selectedTrips, true);
        const isDuplicBtnNeedDisabled: boolean = selectedTrips.length == 0 ? true : isJobSiteVehicle;
        const isUpdateStatusTripBtnNeedEnabled: boolean = selectedTrips.length >= 1 && !selectedTrips.some(x => x.planningVehicleIsCanceled);

        this.setState({
            isUpdateStatusTripBtnEnabled: isUpdateStatusTripBtnNeedEnabled,
            isDuplicBtnEnabled: !isDuplicBtnNeedDisabled && !selectedTrips[0].planningVehicleIsCanceled,
            isRemoveBtnEnabled: !isRemoveBtnNeedDisabled,
            dataTrip: newDataTrip
        });
        this.forceUpdate();
    }

    isTripsNeedDisabledBtnRemoved = (dataTrips: Array<DeliveryTripLightModelExtended>, checked: boolean): boolean => {
        let isRemoveBtnNeedDisabled = false;
        const blockingTrips = dataTrips.filter(e => { return e.tripStatus == 'Confirmé' || e.tripStatus == 'Terminé' || e.tripStatus == 'En cours' || e.planningVehicleIsCanceled });
        if (checked && blockingTrips.length > 0) {
            isRemoveBtnNeedDisabled = true;
        }
        return isRemoveBtnNeedDisabled;
    }

    rowRenderDeliveryTrip = (trElement: React.ReactElement<HTMLTableRowElement>, dataItem: GridRowProps): React.ReactElement<HTMLTableRowElement> => {
        const trProps = {
            ...trElement.props,
            onMouseDown: () => {
                this.preventExitDt = true;
                clearTimeout(this.preventExitTimeoutDt);
                this.preventExitTimeoutDt = setTimeout(() => { this.preventExitDt = undefined; });
            },
            onBlur: () => {
                clearTimeout(this.blurTimeoutDt);
                if (!this.preventExitDt) {
                    this.blurTimeoutDt = setTimeout(() => { this.exitEditDeliveryTrip(); });
                }
            },
            onFocus: () => { clearTimeout(this.blurTimeoutDt); }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as any);
    }

    cellRenderDeliveryTrip = (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps): React.ReactElement<HTMLTableCellElement> => {
        const dataItem: DeliveryTripLightModelExtended = cellProps.dataItem;
        const field: string = cellProps.field;
        const additionalProps = (dataItem.inEdit && (cellProps.field === dataItem.inEdit)) ?
            {
                ref: (td: any) => {
                    const input = td && td.querySelector('input');
                    if (!input || (input === document.activeElement)) { return; }
                    if (input.type === 'checkbox') {
                        input.focus();
                    } else {
                        input.select();
                    }
                }
            } : {
                onClick: () => { this.enterEditDeliveryTrip(dataItem, field); }
            };
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children as any);
    }

    enterEditDeliveryTrip = (dataItem: DeliveryTripLightModelExtended, field: string): void => {
        if (dataItem.inEdit && field === this.state.editFieldDt) {
            return;
        }

        this.exitEditDeliveryTrip();
        this.fieldValueBeforeEntering = dataItem[field];
        dataItem.inEdit = field;
        this.setState({
            editFieldDt: field,
            dataTrip: this.state.dataTrip
        });
    }

    exitEditDeliveryTrip = (): void => {
        let wasChanged = false;
        let deliveryTripIdToUpdate: number = null;
        let transportRequestIdToUpdate: string = null;
        let propertyName: string = null;
        let propertyValue: string | number | Date = null;

        const newDataTrip: DeliveryTripLightModelExtended[] = this.state.dataTrip.slice();

        newDataTrip.forEach((dataItem: DeliveryTripLightModelExtended) => {
            if (dataItem.inEdit !== undefined && dataItem.inEdit !== KnownFields.Selected) {
                if (dataItem.inEdit === KnownFields.DeliveryTime && dataItem[KnownFields.DeliveryTime] < dataItem[KnownFields.LoadingTime] && dataItem[KnownFields.LoadingTime] !== null) {
                    dataItem[KnownFields.LoadingTime] = dataItem[KnownFields.DeliveryTime]
                    this.notify();
                } else if (dataItem.inEdit === KnownFields.LoadingTime && dataItem[KnownFields.LoadingTime] > dataItem[KnownFields.DeliveryTime] && dataItem[KnownFields.DeliveryTime] !== null) {
                    dataItem[KnownFields.DeliveryTime] = dataItem[KnownFields.LoadingTime]
                    this.notify();
                }
                propertyName = dataItem.inEdit;
                deliveryTripIdToUpdate = dataItem.deliveryTripId;
                transportRequestIdToUpdate = dataItem.transportRequestId;
                propertyValue = dataItem[propertyName]
                if (this.fieldValueBeforeEntering !== propertyValue)
                    wasChanged = true;
            }
            dataItem.inEdit = undefined;
        });
        this.setState({
            dataTrip: newDataTrip,
            editFieldDt: null
        });

        if (!wasChanged)
            return;

        const delTripchange: DeliveryTripChangeRequestArgs = {
            deliveryTripId: deliveryTripIdToUpdate,
            transportRequestId: transportRequestIdToUpdate,
            propertyName: propertyName,
            expectedQuantity: propertyName === KnownFields.ExpectedQuantity ? propertyValue as number : null,
            loadingTime: propertyName === KnownFields.LoadingTime ? (propertyValue as Date) : null,
            deliveryTime: propertyName === KnownFields.DeliveryTime ? (propertyValue as Date) : null,
            instructions: propertyName === KnownFields.Instructions ? propertyValue as string : null
        };

        TransportPlanApiClient.UpdateDeliveryTrip(delTripchange)
            .then(json => {
                if (this._isMounted) {
                    if (propertyName == KnownFields.ExpectedQuantity) {
                        const transportFlowId: string = this.state.dataTrip.find(x => x.deliveryTripId === deliveryTripIdToUpdate).transportFlowId;

                        TransportPlanApiClient.GetTransportFlowsByIds([transportFlowId], this.state.fromTime, this.state.toTime)
                            .then((res) => {
                                const dataFlowArray: TransportFlowLightModelExtended[] = this.state.dataFlow;
                                const currentTransportFlowIndex: number = dataFlowArray.findIndex(x => x.transportFlowId === transportFlowId);
                                const updatedTransportFlow: TransportFlowLightModelExtended = res.data.find(x => x.transportFlowId === transportFlowId);
                                this.translateTransportFlow(updatedTransportFlow, false);
                                dataFlowArray[currentTransportFlowIndex] = updatedTransportFlow;

                                const data = json?.data;
                                const errors = BusinessErrors.Get(data);
                                let warnings: string[] = [];

                                if (errors.length > 0) {
                                    ToastService.showErrorToast("", errors);
                                    return;
                                } else {
                                    warnings = BusinessWarnings.Get(data);
                                    if (warnings.length > 0) {
                                        ToastService.showWarningToast("", warnings);
                                    }
                                }

                                if (!updatedTransportFlow.isHidden) {
                                    TransportPlanApiClient.FindTransportRequests(this.state.pageLength, this.state.pageNumber, null, this.state.fromTime, this.state.toTime, this.props.logisticsUnitIds, updatedTransportFlow.transportRequestId)
                                        .then((result) => {
                                            const dataTransportRequestArray: TransportRequestLightModelExtended[] = this.state.data;
                                            const currentTransportRequestIndex: number = dataTransportRequestArray.findIndex(x => x.transportRequestId === updatedTransportFlow.transportRequestId);
                                            const updatedTransportRequest: TransportRequestLightModelExtended = result.data.pageItems.find(x => x.transportRequestId === updatedTransportFlow.transportRequestId);
                                            this.translateTransportRequest(updatedTransportRequest);
                                            dataTransportRequestArray[currentTransportRequestIndex] = updatedTransportRequest;

                                            this.setState({
                                                dataFlow: dataFlowArray,
                                                data: dataTransportRequestArray,
                                            });
                                        });
                                } else {
                                    this.setState({
                                        dataFlow: dataFlowArray
                                    });
                                }
                            });
                    }
                }
            });
    }

    notify = (): void => {
        if (!toast.isActive("time-editor-notif-Id")) {
            ToastService.showWarningToast("L'heure de chargement doit être antérieure à l'heure d'arrivée. Merci de modifier votre saisie. En attendant, les heures de chargement et livraison sur ce tour ont été positionnées à la même heure.");
        }
    }

    duplicateDeliveryTrips = (): void => {
        const trip: DeliveryTripLightModelExtended = this.state.dataTrip.find(x => x.deliveryTripId === this.state.deliveryTrip.deliveryTripId);
        const tripToDuplicated: DeliveryTripLongIdentifierRequestArgs = this.state.deliveryTrip;
        tripToDuplicated.transportFlowId = trip.transportFlowId;
        //On duplique pas les anciens tours(tours sans vehicle de planning)
        if (trip.vehicleNumber)
            TransportPlanApiClient.DuplicateDeliveryTrip(tripToDuplicated)
                .then(json => {
                    const data: WebAppActionResult = json?.data;
                    const errors: string[] = BusinessErrors.Get(data);
                    if (errors.length > 0) {
                        ToastService.showErrorToast("", errors);
                        return;
                    }

                    const warnings = BusinessWarnings.Get(data);

                    TransportPlanApiClient.GetTransportFlowsByIds([tripToDuplicated.transportFlowId], this.state.fromTime, this.state.toTime)
                        .then((res) => {
                            if (this._isMounted) {
                                this.FindDeliveryTrip(this.state.pageLength, this.state.pageNumber, this.state.searchTrip, this.state.selectedID, this.state.selectedTransportFlowID, this.state.fromTime, this.state.toTime, this.state.searchDc, this.state.searchTransportFlow);
                                const dataFlowArray: TransportFlowLightModelExtended[] = this.state.dataFlow;
                                const currentTransportFlowIndex: number = dataFlowArray.findIndex(x => x.transportFlowId === tripToDuplicated.transportFlowId);
                                const updatedTransportFlow: TransportFlowLightModelExtended = res.data.find(x => x.transportFlowId === tripToDuplicated.transportFlowId);
                                this.translateTransportFlow(updatedTransportFlow, this.state.selectedTransportFlowID != null);
                                dataFlowArray[currentTransportFlowIndex] = updatedTransportFlow;
                                if (warnings.length > 0) {
                                    ToastService.showWarningToast("", warnings);
                                }

                                this.setState({
                                    dataFlow: dataFlowArray,
                                    isRemoveBtnEnabled: false,
                                    isDuplicBtnEnabled: false,
                                    deliveryTrip: null
                                });
                            }
                        });
                });
    };

    duplicateTransportFlow = (): void => {
        this.setState({
            isTransportFlowFormViewOpened: true,
            flowDialogMode: duplicateMode,
            flowDialogTransportFlowId: this.state.selectedTransportFlowID
        });
    };

    addNewDeliveryTrip = (): void => {
        this.setState({ isLoading: true })
        const deliveryDate: Date = this.state.fromTime;
        const transportRequestId: string = this.state.dataFlow.find(x => x.transportFlowId === this.state.selectedTransportFlowID).transportRequestId;
        TransportPlanApiClient.AddDeliveryTrip(transportRequestId, deliveryDate, this.state.selectedTransportFlowID)
            .then(json => {
                const data: WebAppActionResult = json?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                const warnings = BusinessWarnings.Get(data);

                TransportPlanApiClient.GetTransportFlowsByIds([this.state.selectedTransportFlowID], this.state.fromTime, this.state.toTime)
                    .then((res) => {
                        if (this._isMounted) {
                            this.FindDeliveryTrip(this.state.pageLength, this.state.pageNumber, this.state.searchTrip, this.state.selectedID, this.state.selectedTransportFlowID, this.state.fromTime, this.state.toTime, this.state.searchDc, this.state.searchTransportFlow);
                            const dataFlowArray: TransportFlowLightModelExtended[] = this.state.dataFlow;
                            const currentTransportFlowIndex: number = dataFlowArray.findIndex(x => x.transportFlowId === this.state.selectedTransportFlowID);
                            const updatedTransportFlow: TransportFlowLightModelExtended = res.data.find(x => x.transportFlowId === this.state.selectedTransportFlowID);
                            this.translateTransportFlow(updatedTransportFlow, this.state.selectedTransportFlowID != null);
                            dataFlowArray[currentTransportFlowIndex] = updatedTransportFlow;
                            if (warnings.length > 0) {
                                ToastService.showWarningToast("", warnings);
                            }

                            this.setState({
                                isLoading: false,
                                dataFlow: dataFlowArray
                            });
                        }
                    });
            });
    };

    removeDeliveryTrips = (): void => {
        this.setState({ isLoading: true, showconfirmationRemoveTripsModal: true, disableHandleConfirmRemoveButton: false, toRemove: "DeliveryTrips", deleteMessage: ' Merci de confirmer la suppression des tours sélectionnés' })
    };

    handleHideModal = (): void => {
        this.setState({
            isLoading: false, showconfirmationRemoveTripsModal: false
        });
    }

    deleteFlow = (): void => {
        this.setState({
            isLoading: true, showconfirmationRemoveTripsModal: true, disableHandleConfirmRemoveButton: false, toRemove: "TransportFlow", deleteMessage: 'Merci de confirmer la suppression du Flux sélectionné'
        })
    }

    HandleConfirmRemove = debounce((toRemove: string): void => {
        this.setState({ disableHandleConfirmRemoveButton: true });
        if (toRemove === "TransportFlow") {
            this.handleConfirmRemoveFlow();
        } else if (toRemove === "DeliveryTrips") {
            this.handleConfirmRemoveTrips();
        }
    }, 500);

    handleConfirmRemoveFlow = (): void => {
        TransportPlanApiClient.RemoveTransportFlow(this.state.selectedTransportFlowID, this.state.selectedTransportRequestIDForFlow)
            .then(json => {
                const data: WebAppActionResult = json?.data;
                const errors: string[] = BusinessErrors.Get(data);
                let newStateObj = {} as TransportPlanState;

                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    newStateObj = {
                        ...newStateObj,
                        showconfirmationRemoveTripsModal: false
                    };
                }

                newStateObj = {
                    ...newStateObj,
                    isTransportFlowStatusSelected: false, selectedValueTransportFlow: "", selectedTransportFlowID: null, selectedTransportFlowBusinessId: null, areFlowDetailsOpened: false, isDeleteFlowBtnEnabled: false, isDuplicFlowBtnEnabled: false,
                    isLoading: true, areRequestDetailsOpened: false, isUpdateStatusFlowBtnEnabled: false, isRemoveBtnEnabled: false, isDuplicBtnEnabled: false, isUpdateStatusTripBtnEnabled: false, isDeliveryTripStatusSelected: false, selectedValueDelTrip: null, isAddBtnEnabled: false, showconfirmationRemoveTripsModal: false
                };

                this.setState(newStateObj);
                this.FindTransportRequestWithFlowAndVehicleTypes(this.state.pageLength, this.state.pageNumber, this.state.searchTrip, '', this.state.fromTime, this.state.toTime, this.searchDcRef.current.value, this.searchFlowRef.current.value, this.props.logisticsUnitIds, false);
            });
    }

    handleConfirmRemoveTrips = (): void => {
        TransportPlanApiClient.RemoveDeliveryTrips(this.state.deliveryTrips)
            .then(json => {
                const transportFlowIds: string[] = new Array<string>();
                const data: WebAppActionResult = json?.data;
                if (data.commandResults &&
                    data.commandResults.some(x => x.hasBusinessErrors &&
                        x.businessErrors.some(e => e.name === 'ActionCannotBePerformedOnACanceledPlanningVehicle'))) {
                    ToastService.showErrorToast("Le statut du tour a changé entre temps, merci d'actualiser votre page.");

                    this.setState({
                        showconfirmationRemoveTripsModal: false
                    });
                    return;
                }

                const errors: string[] = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        showconfirmationRemoveTripsModal: false
                    });
                    return;
                }

                this.state.deliveryTrips.forEach((trip: DeliveryTripLongIdentifierRequestArgs) => {
                    transportFlowIds.push(trip.transportFlowId);
                });
                TransportPlanApiClient.GetTransportFlowsByIds(transportFlowIds, this.state.fromTime, this.state.toTime)
                    .then((res) => {
                        if (this._isMounted) {
                            const dataFlowArray: TransportFlowLightModelExtended[] = this.state.dataFlow;
                            this.FindDeliveryTrip(this.state.pageLength, this.state.pageNumber, this.state.searchTrip, this.state.selectedID, this.state.selectedTransportFlowID, this.state.fromTime, this.state.toTime, this.state.searchDc, this.state.searchTransportFlow);
                            res.data.forEach((flow: TransportFlowLightModelExtended) => {
                                const currentTransportFlowIndex: number = dataFlowArray.findIndex(x => x.transportFlowId === flow.transportFlowId);
                                this.translateTransportFlow(flow, this.state.selectedTransportFlowID != null);
                                dataFlowArray[currentTransportFlowIndex] = flow;
                            });
                            this.setState({
                                dataFlow: dataFlowArray,
                                isRemoveBtnEnabled: false, isDuplicBtnEnabled: false, selectedDeliveryTrips: new Array<number>(), deliveryTrips: new Array<DeliveryTripLongIdentifierRequestArgs>(), isLoading: false, showconfirmationRemoveTripsModal: false
                            });
                        }
                    });
            });
    }

    handleCloseDelTrip = (): void => {
        this.setState({ isOpenDelTrip: false });
    }

    handleOpenDelTrip = (): void => {
        this.setState({ isOpenDelTrip: true });
    }

    handleSelectDeliveryTripStatusChange = (event: any): void => {
        if (event.target.value !== "")
            this.setState({ isDeliveryTripStatusSelected: true })
        else
            this.setState({ isDeliveryTripStatusSelected: false })
        this.setState({ selectedValueDelTrip: event.target.value })
    }

    handleAssignDeliveryTripStatus = (): void => {
        this.setState({ isLoading: true });

        const transportFlowIds: string[] = new Array<string>();
        this.state.deliveryTrips.forEach((trip: DeliveryTripLongIdentifierRequestArgs) => {
            transportFlowIds.push(trip.transportFlowId);
        });

        TransportPlanApiClient.AssignDeliveryTripStatus(this.state.deliveryTrips, this.state.selectedValueDelTrip)
            .then(json => {
                const data: WebAppActionResult = json?.data;
                const errors: string[] = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                TransportPlanApiClient.GetTransportFlowsByIds(transportFlowIds, this.state.fromTime, this.state.toTime)
                    .then((res) => {
                        if (this._isMounted) {
                            this.FindDeliveryTrip(this.state.pageLength, this.state.pageNumber, this.state.searchTrip, this.state.selectedID, this.state.selectedTransportFlowID, this.state.fromTime, this.state.toTime, this.state.searchDc, this.state.searchTransportFlow);

                            const dataFlowArray: Array<TransportFlowLightModelExtended> = this.state.dataFlow;
                            res.data.forEach((flow: TransportFlowLightModelExtended) => {
                                const currentTransportFlowIndex: number = dataFlowArray.findIndex(x => x.transportFlowId === flow.transportFlowId);
                                this.translateTransportFlow(flow, this.state.selectedTransportFlowID != null);
                                dataFlowArray[currentTransportFlowIndex] = flow;
                            });
                            this.setState({
                                dataFlow: dataFlowArray,
                                isLoading: false, selectedValueDelTrip: null, selectedDeliveryTrips: new Array<number>(), deliveryTrips: new Array<DeliveryTripLongIdentifierRequestArgs>(), isRemoveBtnEnabled: false, isDuplicBtnEnabled: false, isDeliveryTripStatusSelected: false, isUpdateStatusTripBtnEnabled: false
                            });
                        }
                    });
            });
    }

    handleCloseTransportFlowStatus = (): void => {
        this.setState({ isOpenTransportFlowStatus: false });
    }

    handleOpenTransportFlowStatus = (): void => {
        this.setState({ isOpenTransportFlowStatus: true });
    }

    handleSelectTransportFlowStatusChange = (event: any): void => {
        if (event.target.value !== "")
            this.setState({ isTransportFlowStatusSelected: true })
        else
            this.setState({ isTransportFlowStatusSelected: false })
        this.setState({ selectedValueTransportFlow: event.target.value })
    }

    handleAssignTransportFlowStatus = (): void => {
        const transportRequestId: string = this.state.dataFlow.find(fl => fl.transportFlowId === this.state.selectedTransportFlowID).transportRequestId;
        const transportFlowChange: TransportFlowChangeRequestArgs = {
            transportRequestId: transportRequestId,
            transportFlowId: this.state.selectedTransportFlowID,
            status: this.state.selectedValueTransportFlow
        };
        TransportPlanApiClient.UpdateTransportFlow(transportFlowChange)
            .then(json => {
                const data: WebAppActionResult = json?.data;
                const errors: string[] = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                if (this._isMounted) {
                    this.FindTransportRequestWithFlowAndVehicleTypes(this.state.pageLength, this.state.pageNumber, this.state.searchTrip, '', this.state.fromTime, this.state.toTime, this.searchDcRef.current.value, this.searchFlowRef.current.value, this.props.logisticsUnitIds, false);
                    this.setState({ isTransportFlowStatusSelected: false, selectedValueTransportFlow: "", selectedTransportFlowID: null, selectedTransportFlowBusinessId: null, areFlowDetailsOpened: false });
                }
            });
    }

    handleDrawerRightClose = (): void => {
        this.setState({
            areRequestDetailsOpened: false,
            areFlowDetailsOpened: false
        });
    }

    onVerticalLayoutChange = (event: SplitterOnChangeEvent): void => {
        const updatedState = event.newState;
        const expandedPanelsMap: Map<string, boolean> = this.state.expandedPanels;
        if (updatedState[0].size && updatedState[0].size != '50px' && !expandedPanelsMap.get(Panels.TransportRequest))
            return;

        if (updatedState[2].size && updatedState[2].size != '50px' && !expandedPanelsMap.get(Panels.Trip))
            return;

        if ((updatedState[2].size == this.state.verticalPanes[2].size && updatedState[1].size != this.state.verticalPanes[1].size && !expandedPanelsMap.get(Panels.TransportRequest)) ||
            (updatedState[0].size == this.state.verticalPanes[0].size && updatedState[1].size != this.state.verticalPanes[1].size && !expandedPanelsMap.get(Panels.Trip)) ||
            !expandedPanelsMap.get(Panels.TransportFlow)
        )
            return;

        this.setState({
            verticalPanes: updatedState
        });
    }

    clearSearchText = (category: string): void => {
        switch (category) {
            case 'dc-grid':
                this.searchDcRef.current.value = '';
                this.transportRequestKeyPressed('');
                break;
            case 'trips-grid':
                this.searchDtRef.current.value = '';
                this.deliveryTripKeyPressed('');
                break;
            case 'flux-grid':
                this.searchFlowRef.current.value = '';
                this.transportFlowKeyPressed('');
                break;
        }
    }

    handleExpansionsChange = (panel: string): void => {
        const expandedPanels: Map<string, boolean> = this.state.expandedPanels;

        const isExpanded: boolean = expandedPanels.get(panel);

        if (!(this.getPanelsOpenedCount(expandedPanels) == 1 && isExpanded))
            expandedPanels.set(panel, !isExpanded);

        //si le panel à fermer est celui qui est ouvert et que c'est le seul ouvert ou bien si on essaie de fermer tout les panels
        if ((this.state.expandedPanels.get(panel) && this.getPanelsOpenedCount(expandedPanels) == 1) || this.getPanelsOpenedCount(expandedPanels) == 0)
            return;

        this.setState({
            expandedPanels: expandedPanels,
            verticalPanes: this.calculateNewPanes(expandedPanels)
        });
    }

    getPanelsOpenedCount = (expandedPanels: Map<string, boolean>): number => {
        let cpPanelsOpened = 0;

        expandedPanels.forEach(p => {
            if (p)
                cpPanelsOpened++;
        });

        return cpPanelsOpened;
    };

    calculateNewPanes = (expandedPanels: Map<string, boolean>): Array<PaneModel> => {
        let panes: PaneModel[] = [];

        const cpPanelsOpened: number = this.getPanelsOpenedCount(expandedPanels);

        if (cpPanelsOpened == 0) {
            panes = [
                { size: '50px', scrollable: false, min: '50px' },
                { size: '50px', scrollable: false, min: '50px' },
                { min: '50px', scrollable: false }
            ];
        }
        else {
            let panelsMarge = 0;
            if (cpPanelsOpened == 1)
                panelsMarge = 117;
            else if (cpPanelsOpened == 2) {
                panelsMarge = 33;
            }

            const calcPanelBesoin: string = expandedPanels.get(Panels.TransportRequest) ? `calc(${100 / cpPanelsOpened}% - ${panelsMarge}px)` : null;
            const calcPanelFlux: string = expandedPanels.get(Panels.TransportFlow) ? `calc(${100 / cpPanelsOpened}% - ${panelsMarge}px)` : null;
            const calcPanelTrajet: string = expandedPanels.get(Panels.Trip) ? `calc(${100 / cpPanelsOpened}% - ${panelsMarge}px)` : null;

            panes = [
                { scrollable: false, min: '50px' },
                { scrollable: false, min: '50px' },
                { scrollable: false, min: '50px' }
            ];

            if (calcPanelBesoin)
                panes[0]['size'] = calcPanelBesoin;

            if (calcPanelFlux)
                panes[1]['size'] = calcPanelFlux;

            if (calcPanelTrajet && (cpPanelsOpened != 3))
                panes[2]['size'] = calcPanelTrajet;
        }

        return panes;
    }

    addNewFlow = (): void => {
        this.setState({
            isTransportFlowFormViewOpened: true,
            flowDialogMode: creationMode
        });
    }

    handleCloseTransportFlowForm = (): void => {
        this.setState({
            isTransportFlowFormViewOpened: false
        });
    }

    handleCloseTransportFlowFormAfterValidation = (hasBusinessErrors: boolean, hasBusinessWarnings: boolean, warnings: string[], businessId: string, mode: string): void => {
        if (!hasBusinessErrors) {
            const transportRequestId: string = this.state.selectedID !== null ? this.state.selectedID : '';
            TransportPlanApiClient.FindTransportFlow(this.state.pageLength, this.state.pageNumber, this.searchDcRef.current.value, this.searchFlowRef.current.value, transportRequestId, this.state.fromTime, this.state.toTime, this.props.logisticsUnitIds)
                .then((res) => {
                    const dataFlows: Array<TransportFlowLightModelExtended> = res.data.pageItems;
                    dataFlows.forEach((transportFlow: TransportFlowLightModelExtended) => {
                        this.translateTransportFlow(transportFlow, false);
                    });

                    let newStateObj = {
                        isTransportFlowFormViewOpened: false,
                        dataFlow: dataFlows,
                        selectedTransportFlowID: null,
                        selectedTransportFlowBusinessId: null,
                        areFlowDetailsOpened: false,
                        isDuplicFlowBtnEnabled: false,
                        isDeleteFlowBtnEnabled: false,
                        isAddBtnEnabled: false,
                    } as TransportPlanState;

                    if (hasBusinessWarnings) {
                        ToastService.showWarningToast("", warnings);
                    }

                    ToastService.showSuccessToast(mode == updateMode ? `La modification du Flux ${businessId} s'est déroulée avec succès` : `La création du Flux ${businessId} s'est déroulée avec succès`);


                    if (!this.state.selectedID && !this.state.selectedTransportFlowID) {
                        newStateObj = {
                            ...newStateObj,
                            loadingTrip: false,
                            dataTrip: [],
                            selectedDeliveryTrips: new Array<number>(),
                            deliveryTrips: new Array<DeliveryTripLongIdentifierRequestArgs>(),
                            isRemoveBtnEnabled: false,
                            isDuplicBtnEnabled: false,
                            isUpdateStatusTripBtnEnabled: false,
                            //Pour fermer le drawer des informations complémentaires lors d'une nouvelle recherche
                            areRequestDetailsOpened: transportRequestId === undefined || transportRequestId === "" ? false : true
                        }
                    }

                    this.setState(newStateObj);

                    if (this.state.selectedID) {
                        this.FindDeliveryTrip(this.state.pageLength, this.state.pageNumber, this.state.searchTrip, this.state.selectedID, this.state.selectedTransportFlowID, this.state.fromTime, this.state.toTime, this.state.searchDc, this.state.searchTransportFlow);
                    }
                });
        } else {
            ToastService.showErrorToast(mode == updateMode ? `La modification du Flux ${businessId} a échoué. Veuillez contacter votre administrateur` : `La création du Flux a échoué. Veuillez contacter votre administrateur`);
        }
    }

    //#endregion

    editFlow = (): void => {
        this.setState({
            isTransportFlowFormViewOpened: true,
            flowDialogMode: updateMode,
            flowDialogTransportFlowId: this.state.selectedTransportFlowID
        });
    }

    getNameValue = (enumElem: string, objectName: string): string => {
        return enumValues.get(`${objectName}.${enumElem}`);
    }

    getValueTitle = (diffsFromRequest: Array<FlowDiff>, valueField: any, diffProperty: FlowDiffProperty, formatField?, formatTypeDate?: FormatTypeEnum): TitleValueDiffModel => {
        let title: string = null;
        const value: string = formatTypeDate ? formatField(valueField, formatTypeDate) : valueField;
        const flowDiff = this.getFlowDiffProperty(diffsFromRequest, diffProperty);
        let hasDiffFromRequest = false;

        if (flowDiff) {
            hasDiffFromRequest = true;
            switch (flowDiff.type) {
                case KnownTypes.String:
                    title = flowDiff.requestValue ?
                        formatField
                            ? formatField(flowDiff.requestValue) : flowDiff.requestValue
                        : '';
                    break;
                case KnownTypes.DateTimeOffsetNullable:
                case KnownTypes.DateTimeOffset:
                    title = flowDiff.requestValue ?
                        formatField ? formatField(flowDiff.requestValue, formatTypeDate) : Date.getDateFromIsoString(flowDiff.requestValue) + ''
                        : '';
                    break;
                case KnownTypes.TimeStampNullable:
                case KnownTypes.TimeStamp:
                    title = flowDiff.requestValue ? flowDiff.requestValue : '';
                    break;
                case KnownTypes.BooleanNullable:
                case KnownTypes.Boolean:
                    title = 'Non';
                    if (flowDiff.requestValue === true)
                        title = 'Oui';
                    break;
                case KnownTypes.CoatingApplicationModeNullable:
                case KnownTypes.CoatingApplicationMode:
                    {
                        const titleEnum = this.getNameValue(flowDiff.requestValue + '', KnownTypes.CoatingApplicationMode);
                        title = titleEnum ? titleEnum : '';
                        break;
                    }
                default:
                    title = flowDiff.requestValue;
                    break;
            }
        }
        return {
            title: title,
            value: value,
            hasDiffFromRequest: hasDiffFromRequest
        };
    }

    getFlowDiffProperty = (diffsFromRequest: Array<FlowDiff>, diffProperty: FlowDiffProperty): FlowDiff => {
        return find(diffsFromRequest, t => t.property == diffProperty);
    }

    defaultFormatter = (fieldValue: any, format: FormatTypeEnum): string => {
        let res = '';
        if (!fieldValue)
            res = "";

        switch (format) {
            case FormatTypeEnum.Time:
                res = new Date(fieldValue).getHours() + ((new Date(fieldValue).getMinutes() < 10) ? ":0" : ":") + new Date(fieldValue).getMinutes();
                break;
            case FormatTypeEnum.Date:
                res = Date.getDateFormatterFromIsoString(new Date(fieldValue), 'DD-MM-YYYY');
                break;
            //A compléter
            default:
                break;
        }
        return res;
    }

    getVehicleTypeLabel = (vehicleTypeId: string): string => {
        return this.state.vehicleTypesLabels.get(vehicleTypeId) ?? vehicleTypeId;
    }

    handleDeleteAttachment = (attachmentId: string): void => {
        this.setState({
            showConfirmationDeleteAttachmentModal: true,
            attachmentIdToDelete: attachmentId
        });
    }

    handleHideConfirmationDeleteAttachmentModal = (): void => {
        this.setState({
            isLoadingAttachment: false, showConfirmationDeleteAttachmentModal: false
        });
    }

    handleConfirmDeleteAttachment = (): void => {
        this.setState({ isLoadingAttachment: true });
        TransportPlanApiClient.DeleteTransportFlowAttachment(this.state.selectedTransportRequestIDForFlow, this.state.selectedTransportFlowID, this.state.attachmentIdToDelete)
            .then((res) => {
                const data: WebAppActionResult = res?.data;
                const errors: string[] = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    this.setState({
                        showConfirmationDeleteAttachmentModal: false,
                        isLoadingAttachment: false
                    });
                    return;
                }

                TransportPlanApiClient.GetAttachmentFileNames(this.state.selectedTransportFlowID)
                    .then((json) => {
                        const transportFlowAttachmentFiles: Array<TransportFlowAttachmentFileLightModel> = json.data;
                        this.setState({
                            transportFlowAttachmentFiles: transportFlowAttachmentFiles,
                            isLoadingAttachment: false,
                            showConfirmationDeleteAttachmentModal: false
                        });
                    });
            });
    }

    handlerBeforeUploadFlowAttachment = (e: UploadOnBeforeUploadEvent): void => {
        this.setState({
            isLoadingAttachment: true
        });
    }

    handleAddFlowAttachments = (e: UploadOnAddEvent): void => {
        e.affectedFiles.forEach((file: UploadFileInfo) => {
            if (file.size > 10000000) {
                ToastService.showErrorToast("Les fichiers déposés ne doivent pas dépasser la taille de 10 Mo");

                this.setState({
                    showconfirmationRemoveTripsModal: false
                });
                return;
            }
        });
    }

    handlerDisplayErrorsAfterUpload = (errors: string[]): void => {
        ToastService.showErrorToast("", errors);

        this.setState({
            showconfirmationRemoveTripsModal: false,
            isLoadingAttachment: false
        });
    }

    handlerAfterUploadFlowAttachment = (): void => {
        TransportPlanApiClient.GetAttachmentFileNames(this.state.selectedTransportFlowID)
            .then((json) => {
                const transportFlowAttachmentFiles: Array<TransportFlowAttachmentFileLightModel> = json.data;
                this.setState({ transportFlowAttachmentFiles: transportFlowAttachmentFiles, isLoadingAttachment: false });
            });
    }

    handleIsDateRangeValidChanged = (isValid: boolean): void => {
        this.setState({ isDateRangeValid: isValid });
    }

    render() {
        const vehicleTypeCount: VehicleGroupCountersLightModel = this.state.vehicleTypeCount;
        const searchTextDcGridValue: string = this.searchDcRef.current === null || this.searchDcRef.current === undefined ? '' : this.searchDcRef.current.value;
        const searchTextTripsGridValue: string = this.searchDtRef.current === null || this.searchDtRef.current === undefined ? '' : this.searchDtRef.current.value;
        const searchTextFlowGridValue: string = this.searchFlowRef.current === null || this.searchFlowRef.current === undefined ? '' : this.searchFlowRef.current.value;

        const { expandedPanels, isUpdateStatusFlowBtnEnabled, isTransportFlowStatusSelected, isOpenTransportFlowStatus, selectedValueTransportFlow, isDuplicFlowBtnEnabled
            , isDeleteFlowBtnEnabled, selectedTransportFlowID, selectedTransportFlowStatus, isUpdateStatusTripBtnEnabled, isDeliveryTripStatusSelected, isAddBtnEnabled
            , isDuplicBtnEnabled, isRemoveBtnEnabled, selectedValueDelTrip, dataTrip, selectedID, loadingTrip, dataFlow, isOpenDelTrip, vehicleTypesLabels } = this.state;

        const panelsCount: number = this.getPanelsOpenedCount(expandedPanels);
        const expandPanelBesoin: boolean = expandedPanels.get(Panels.TransportRequest);
        const expandPanelFlow: boolean = expandedPanels.get(Panels.TransportFlow);
        const expandPanelTrajet: boolean = expandedPanels.get(Panels.Trip);

        const countDiff: number = this.state.dataFlow.filter(x => x.diffsFromRequestCount > 0).length;

        const btn_enabled_flow_SaveStatus: boolean = isTransportFlowStatusSelected && isUpdateStatusFlowBtnEnabled;
        const btn_enabled_flow_editFlow: boolean = selectedTransportFlowID !== null && selectedTransportFlowStatus !== "Terminé";

        return (
            <>
                <Box display="flex" flexWrap="nowrap" minHeight="450px" flexDirection="column" className={clsx('container-dc', 'delivery-call-content', {
                    ['delivery-call-content-shiftRight']: this.state.areRequestDetailsOpened || this.state.areFlowDetailsOpened
                })}>
                    <Box display="flex" width='100%' flexWrap="nowrap" flexDirection="row" alignItems='center' className='header-dc' pt={1} pb={1}>
                        <Box minWidth='750px' width='40%' p={1} flexWrap="wrap" flexDirection="row" alignItems='center'>
                            <CalendarComponent render={this.props.render} handlerFromChildToParent={this.handlerCalender} calendarId='daterange-picker' maximumNumberOfMonths={2} isValid={this.state.isDateRangeValid}
                                handleIsValidChanged={this.handleIsDateRangeValidChanged} />
                        </Box>
                        <Box minWidth='450px' width='60%' paddingRight={'5px'} flexWrap="wrap" flexDirection="row" alignItems='center' justifyContent="flex-end">
                            <Box width="100%" display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" justifyContent="flex-end">
                                <Box minWidth='250px'>
                                    <VehicleGroupCountersComponent {...this.props} vehicleGroupCounters={vehicleTypeCount} showDailyCounters={false} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Splitter className='pt-splitter' panes={this.state.verticalPanes} orientation={'vertical'} onChange={this.onVerticalLayoutChange}>
                        <Box pb={1} height="100%" className="parent-card-dc">
                            <Accordion className='container-expansionPanel' expanded={expandPanelBesoin}
                                onChange={() => this.handleExpansionsChange(Panels.TransportRequest)}>
                                <AccordionSummary className="container-expansionPanel-title" expandIcon={<ExpandMoreIcon className={panelsCount == 1 && expandPanelBesoin ? "icon-collapse-disabled" : ""} />}>
                                    <Box className="delivery-call-card card-dc" width='100%'>
                                        <Box className="content-card-dc">
                                            <Box display="flex" flexWrap="nowrap" flexDirection="column" className="content-box-dc">
                                                <Box display="flex" flexWrap="nowrap" flexDirection="row" className='title-card-dc' alignItems='center'>
                                                    <Box display="flex" width='60%' flexWrap="nowrap" flexDirection="row" alignItems='center' justifyContent="flex-start">
                                                        <Box className="title-dc">
                                                            Besoins exprimés
                                                        </Box>
                                                        <Box className="count-transportplan">
                                                            {this.state.data.length}
                                                        </Box>
                                                    </Box>
                                                    <Box width='40%' display={expandPanelBesoin ? 'flex' : 'none'} flexWrap="nowrap" flexDirection="row" justifyContent="flex-end">
                                                        <Input disableUnderline className={searchTextDcGridValue.length > 2 ? 'search-text-active default-width' : 'default-width'}
                                                            startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => this.clearSearchText('dc-grid')} /></InputAdornment>}
                                                            onClick={(event) => event.stopPropagation()}
                                                            id="search-text-dc-grid" inputRef={this.searchDcRef} placeholder="Rechercher ..." onChange={e => this.handleTransportRequestKeyPress(e.target.value)} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <TransportRequestComponent
                                    searchTransportFlow={this.searchTransportFlowWithTrip} onResizeHandler={this.onTransportRequestsResizeHandler}
                                    loading={this.state.loadingAL} getTransportRequestTripWidth={this.getTransportRequestsColumnWidth}
                                    data={this.state.data} vehicleTypesLabels={vehicleTypesLabels} onReorderHandler={this.onTransportRequestsReorderHandler} sortByAlert={this.sortByAlert} />
                            </Accordion>
                        </Box>
                        <Box pb={1} height="100%" className="parent-card-dc">
                            <Accordion className='container-expansionPanel' expanded={expandPanelFlow}
                                onChange={() => this.handleExpansionsChange(Panels.TransportFlow)}>
                                <AccordionSummary className="container-expansionPanel-title" expandIcon={<ExpandMoreIcon className={panelsCount == 1 && expandPanelFlow ? "icon-collapse-disabled" : ""} />}>
                                    <Box className="delivery-call-card card-dc" width='100%'>
                                        <Box className="content-card-dc">
                                            <Box display="flex" flexWrap="nowrap" flexDirection="column" className="content-box-dc">
                                                <Box display="flex" flexWrap="nowrap" flexDirection="row" className='title-card-dc' alignItems='center'>
                                                    <Box display="flex" width='60%' flexWrap="nowrap" flexDirection="row" alignItems='center' justifyContent="flex-start">
                                                        <Box className="title-dc">
                                                            Flux
                                                        </Box>
                                                        <Box className="count-transportplan">
                                                            {dataFlow.length}
                                                        </Box>
                                                        {countDiff > 0 && <Box className="count-diff">
                                                            {countDiff} en différence
                                                        </Box>}
                                                        <Box display={expandPanelFlow ? 'flex' : 'none'} flexDirection='row'>
                                                            <Box pl={4} alignSelf="center" onClick={event => event.stopPropagation()}>
                                                                <Select variant="standard" disableUnderline IconComponent={() => (
                                                                    <Tooltip title="Modifie le statut" placement="bottom">
                                                                        <span className={isUpdateStatusFlowBtnEnabled ? '' : 'span-disabled'}>
                                                                            <IconButton size="small" onClick={this.handleOpenTransportFlowStatus} disabled={!isUpdateStatusFlowBtnEnabled}>
                                                                                <FontAwesomeIcon className={isUpdateStatusFlowBtnEnabled ? "fa-card" : "fa-card-disabled"} size="lg" icon={faTag} />
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                )}
                                                                    open={isOpenTransportFlowStatus}
                                                                    onClose={this.handleCloseTransportFlowStatus}
                                                                    onOpen={this.handleOpenTransportFlowStatus}
                                                                    onChange={this.handleSelectTransportFlowStatusChange}
                                                                    value={selectedValueTransportFlow}
                                                                    inputProps={{
                                                                        name: 'status',
                                                                        id: 'flow-status-select',
                                                                    }}
                                                                    className="status-select"
                                                                    disabled={!isUpdateStatusFlowBtnEnabled}
                                                                >
                                                                    <MenuItem value={''}>
                                                                        <em></em>
                                                                    </MenuItem>
                                                                    <MenuItem value={'Pending'}>En attente</MenuItem>
                                                                    <MenuItem value={'Planned'}>Planifié</MenuItem>
                                                                    <MenuItem value={'Confirmed'}>Confirmé</MenuItem>
                                                                    <MenuItem value={'InProgress'}>En cours</MenuItem>
                                                                    <MenuItem value={'Finished'}>Terminé</MenuItem>
                                                                    <MenuItem value={'Canceled'}>Annulé</MenuItem>
                                                                </Select>
                                                            </Box>
                                                            <Box pl={2} onClick={event => event.stopPropagation()}>
                                                                <Tooltip title="Sauvegarde le statut" placement="bottom">
                                                                    <span className={btn_enabled_flow_SaveStatus ? '' : 'span-disabled'}>
                                                                        <IconButton size='small' aria-label="Add" onClick={this.handleAssignTransportFlowStatus} disabled={!btn_enabled_flow_SaveStatus}>
                                                                            <FontAwesomeIcon className={btn_enabled_flow_SaveStatus ? "fa-card" : "fa-card-disabled"} size="lg" icon={faSave} />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Box>
                                                            <Box pl={2} className='btn-flow-divider-left' onClick={event => event.stopPropagation()}>
                                                                <Tooltip title="Créer un nouveau Flux" placement="bottom">
                                                                    <span>
                                                                        <IconButton size="small" aria-label="Add" onClick={this.addNewFlow} >
                                                                            <FontAwesomeIcon className="fa-card" size="lg" icon={faPlusCircle} />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Box>
                                                            <Box pl={2} onClick={event => event.stopPropagation()}>
                                                                <Tooltip title="Dupliquer le Flux" placement="bottom">
                                                                    <span className={isDuplicFlowBtnEnabled ? '' : 'span-disabled'}>
                                                                        <IconButton size="small" onClick={this.duplicateTransportFlow} aria-label="Update" disabled={!isDuplicFlowBtnEnabled}>
                                                                            <FontAwesomeIcon className={isDuplicFlowBtnEnabled ? "fa-card" : "fa-card-disabled"} size="lg" icon={faClone} />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Box>
                                                            <Box pl={2} className='btn-trip-divider-left' onClick={event => event.stopPropagation()}>
                                                                <Tooltip title='Supprimer le Flux créé dans LORIE dont les TOURS ne sont pas au statut "En cours" ou "Terminé" ET ne faisant pas partie d’un BL de Ventes intégré dans Zéphyr.' placement="bottom">
                                                                    <span className={isDeleteFlowBtnEnabled ? '' : 'span-disabled'}>
                                                                        <IconButton size="small" aria-label="Add" onClick={this.deleteFlow} disabled={!isDeleteFlowBtnEnabled}>
                                                                            <FontAwesomeIcon className={isDeleteFlowBtnEnabled ? "fa-card" : "fa-card-disabled"} size="lg" icon={faTrash} />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Box>
                                                            <Box pl={2} className='btn-trip-divider-left' onClick={event => event.stopPropagation()}>
                                                                <Tooltip title={`Modifier les Flux dont le statut n'est pas "Terminé"`} placement="bottom">
                                                                    <span className={btn_enabled_flow_editFlow ? '' : 'span-disabled'}>
                                                                        <IconButton size="small" aria-label="Add" onClick={this.editFlow} disabled={!btn_enabled_flow_editFlow}>
                                                                            <FontAwesomeIcon className={btn_enabled_flow_editFlow ? "fa-card" : "fa-card-disabled"} size="lg" icon={faPen} />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box width='40%' display={expandPanelFlow ? 'flex' : 'none'} flexWrap="nowrap" flexDirection="row" justifyContent="flex-end">
                                                        <Input disableUnderline className={searchTextFlowGridValue.length > 2 ? 'search-text-active default-width' : 'default-width'}
                                                            startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => this.clearSearchText('flux-grid')} /></InputAdornment>}
                                                            onClick={event => event.stopPropagation()}
                                                            id="search-text-flux-grid" inputRef={this.searchFlowRef} placeholder="Rechercher ..." onChange={e => this.handleTransportFlowKeyPress(e.target.value)} />
                                                    </Box>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <TransportFlowComponent searchDetailFlowWithTrip={this.searchDetailFlowWithTrip}
                                    onResizeHandler={this.onTransportFlowsResizeHandler}
                                    loading={this.state.loadingFlow} getTransportFlowWidth={this.getTransportFlowsColumnWidth}
                                    data={this.state.dataFlow} onReorderHandler={this.onTransportFlowsReorderHandler}
                                    getValueTitle={this.getValueTitle} defaultFormatter={this.defaultFormatter} vehicleTypeLabelProvider={this.getVehicleTypeLabel} />
                            </Accordion>
                        </Box>
                        <Box pb={1} height="100%" className="parent-card-dc">
                            <Accordion className='container-expansionPanel' expanded={expandPanelTrajet} disabled={!selectedID && !selectedTransportFlowID}
                                onChange={() => this.handleExpansionsChange(Panels.Trip)}>
                                <AccordionSummary className="container-expansionPanel-title" expandIcon={<ExpandMoreIcon className={panelsCount == 1 && expandPanelTrajet ? "icon-collapse-disabled" : ""} />}>
                                    <Box className="delivery-trip-card card-dc" width='100%'>
                                        <Box className="content-card-dc">
                                            <Box display="flex" flexWrap="nowrap" flexDirection="column" className="content-box-dc">
                                                <Box display="flex" flexWrap="nowrap" flexDirection="row" className='title-card-dc' alignItems='center'>
                                                    <Box display="flex" width='60%' flexWrap="nowrap" flexDirection="row" alignItems='center' justifyContent="flex-start">
                                                        <Box className="title-dc">
                                                            Tour(s)
                                                        </Box>
                                                        <Box className="count-transportplan">
                                                            {(!loadingTrip && (selectedID || selectedTransportFlowID)) ? dataTrip.length : ''}
                                                        </Box>
                                                        <Box display={expandPanelTrajet ? 'flex' : 'none'} flexDirection='row'>
                                                            <Box pl={4} alignSelf="center" onClick={event => event.stopPropagation()}>
                                                                <Select variant="standard" disableUnderline IconComponent={() => (
                                                                    <Tooltip title="Modifie le statut des tours sélectionnés qui ne sont pas associés à un camion annulé." placement="bottom">
                                                                        <span className={isUpdateStatusTripBtnEnabled ? '' : 'span-disabled'}>
                                                                            <IconButton size="small" onClick={this.handleOpenDelTrip} disabled={!isUpdateStatusTripBtnEnabled}>
                                                                                <FontAwesomeIcon className={isUpdateStatusTripBtnEnabled ? "fa-card" : "fa-card-disabled"} size="lg" icon={faTag} />
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                )}
                                                                    open={isOpenDelTrip}
                                                                    onClose={this.handleCloseDelTrip}
                                                                    onOpen={this.handleOpenDelTrip}
                                                                    onChange={this.handleSelectDeliveryTripStatusChange}
                                                                    value={selectedValueDelTrip ?? ''}
                                                                    inputProps={{
                                                                        name: 'status',
                                                                        id: 'trip-status-select',
                                                                    }}
                                                                    className="status-select"
                                                                    disabled={!isUpdateStatusTripBtnEnabled}
                                                                >
                                                                    <MenuItem value={''}>
                                                                        <em></em>
                                                                    </MenuItem>
                                                                    <MenuItem value={DeliveryTripStatus.transportNeeded}>Générique</MenuItem>
                                                                    <MenuItem value={DeliveryTripStatus.transportPlanned}>Planifié</MenuItem>
                                                                    <MenuItem value={DeliveryTripStatus.transportConfirmed}>Confirmé</MenuItem>
                                                                    <MenuItem value={DeliveryTripStatus.inProgress}>En cours</MenuItem>
                                                                    <MenuItem value={DeliveryTripStatus.finished}>Terminé</MenuItem>
                                                                </Select>
                                                            </Box>
                                                            <Box pl={2} onClick={event => event.stopPropagation()}>
                                                                <Tooltip title="Sauvegarde le statut" placement="bottom">
                                                                    <span className={isDeliveryTripStatusSelected ? '' : 'span-disabled'}>
                                                                        <IconButton size='small' aria-label="Add" onClick={this.handleAssignDeliveryTripStatus} disabled={!isDeliveryTripStatusSelected}>
                                                                            <FontAwesomeIcon className={isDeliveryTripStatusSelected ? "fa-card" : "fa-card-disabled"} size="lg" icon={faSave} />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Box>
                                                            <Box pl={2} className='btn-trip-divider-left' onClick={event => event.stopPropagation()}>
                                                                <Tooltip title="Ajoute une ligne dans la grille des tours" placement="bottom">
                                                                    <span className={isAddBtnEnabled ? '' : 'span-disabled'}>
                                                                        <IconButton size="small" aria-label="Add" onClick={this.addNewDeliveryTrip} disabled={!isAddBtnEnabled}>
                                                                            <FontAwesomeIcon className={isAddBtnEnabled ? "fa-card" : "fa-card-disabled"} size="lg" icon={faPlusCircle} />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Box>
                                                            <Box pl={2} onClick={event => event.stopPropagation()}>
                                                                <Tooltip title="Duplique le tour sélectionné hors prestation Camion de chantier et non associé à un camion annulé" placement="bottom">
                                                                    <span className={isDuplicBtnEnabled ? '' : 'span-disabled'}>
                                                                        <IconButton size="small" aria-label="Update" onClick={this.duplicateDeliveryTrips} disabled={!isDuplicBtnEnabled}>
                                                                            <FontAwesomeIcon className={isDuplicBtnEnabled ? "fa-card" : "fa-card-disabled"} size="lg" icon={faClone} />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Box>
                                                            <Box pl={2} onClick={event => event.stopPropagation()} className='btn-trip-divider-left' >
                                                                <Tooltip title='Supprime les tours sélectionnés ayant un statut "Générique" ou "Planifié" ou "Annulé" et non associé à un camion annulé' placement="bottom">
                                                                    <span className={isRemoveBtnEnabled ? '' : 'span-disabled'}>
                                                                        <IconButton size="small" aria-label="Remove" onClick={this.removeDeliveryTrips} disabled={!isRemoveBtnEnabled}>
                                                                            <FontAwesomeIcon className={isRemoveBtnEnabled ? "fa-card" : "fa-card-disabled"} size="lg" icon={faTrash} />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box width='40%' display={expandPanelTrajet ? 'flex' : 'none'} flexWrap="nowrap" flexDirection="row" justifyContent="flex-end">
                                                        <Input disableUnderline className={searchTextTripsGridValue.length > 2 ? 'search-text-active default-width' : 'default-width'}
                                                            startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => this.clearSearchText('trips-grid')} /></InputAdornment>}
                                                            onClick={(event) => event.stopPropagation()}
                                                            id="search-text-trips-grid" inputRef={this.searchDtRef} placeholder="Rechercher ..." onChange={e => this.handleDeliveryTripKeyPress(e.target.value)} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <DeliveryTripComponent rowClickDeliveryTrip={this.rowClickDeliveryTrip} rowRenderDeliveryTrip={this.rowRenderDeliveryTrip}
                                    cellRenderDeliveryTrip={this.cellRenderDeliveryTrip}
                                    onResizeHandler={this.onDeliveryTripsResizeHandler} onReorderHandler={this.onDeliveryTripsReorderHandler}
                                    loading={this.state.loadingTrip} getTransportRequestTripWidth={this.getDeliveryTripsColumnWidth}
                                    data={this.state.dataTrip} headerSelectionChange={this.headerSelectionChange} selectionChange={this.selectionChange} />
                            </Accordion>
                        </Box>
                    </Splitter>
                </Box>
                <Modal show={this.state.showconfirmationRemoveTripsModal} onHide={this.handleHideModal} className='mt-5'>
                    <Modal.Header closeButton>
                        <Modal.Title>ATTENTION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.deleteMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="secondary" onClick={this.handleHideModal}>
                            NON
                        </Button>
                        <Button className="primary" onClick={() => this.HandleConfirmRemove(this.state.toRemove)}
                            disabled={this.state.disableHandleConfirmRemoveButton}>
                            OUI
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showConfirmationDeleteAttachmentModal} onHide={this.handleHideConfirmationDeleteAttachmentModal} className='mt-5'>
                    <Modal.Header closeButton>
                        <Modal.Title>ATTENTION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Ce document sera supprimé et ne sera plus partagé avec le transporteur.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="secondary" onClick={this.handleHideConfirmationDeleteAttachmentModal}>
                            ANNULER
                        </Button>
                        <Button className="primary" onClick={this.handleConfirmDeleteAttachment}>
                            CONTINUER
                        </Button>
                    </Modal.Footer>
                </Modal>
                <TransportRequestDetailsDrawerComponent areRequestDetailsOpened={this.state.areRequestDetailsOpened} handleDrawerRightClose={this.handleDrawerRightClose} detailsData={this.state.detailsData} />
                <TransportFlowDetailsDrawerComponent getValueTitle={this.getValueTitle} formatter={this.defaultFormatter}
                    getLabelFromDict={this.getLabelFromDict} areFlowDetailsOpened={this.state.areFlowDetailsOpened}
                    handleDrawerRightClose={this.handleDrawerRightClose} detailsData={this.state.detailsTransportFlowData}
                    attachmentFiles={this.state.transportFlowAttachmentFiles} handleDeleteAttachment={this.handleDeleteAttachment}
                    handlerAfterUpload={this.handlerAfterUploadFlowAttachment}
                    handlerBeforeUpload={this.handlerBeforeUploadFlowAttachment}
                    handleAdd={this.handleAddFlowAttachments}
                    handlerDisplayErrorsAfterUpload={this.handlerDisplayErrorsAfterUpload}
                    isLoadingAttachments={this.state.isLoadingAttachment}
                />
                <SimpleDialog isOpen={this.state.isTransportFlowFormViewOpened}
                    onClose={this.handleCloseTransportFlowForm}
                    dialogTitleComponent={<TransportFlowHeaderComponent mode={this.state.flowDialogMode}
                        flowBusinessId={this.state.selectedTransportFlowBusinessId} />}
                    closeIcon={true}
                    classNameVal="transport-flow-dialog"
                    headerBorder={true}
                    component={
                        <TransportFlowForm logisticsUnits={this.props.logisticsUnits}
                            chosenLogisticsUnitIds={this.props.logisticsUnitIds}
                            onClose={this.handleCloseTransportFlowForm}
                            onCloseAfterValidation={this.handleCloseTransportFlowFormAfterValidation}
                            mode={this.state.flowDialogMode}
                            transportFlowId={this.state.flowDialogTransportFlowId}
                            flowBusinessId={this.state.selectedTransportFlowBusinessId}
                        />
                    }
                />
            </>
        );
    }
}

export default React.forwardRef(withRouter(TransportPlanView));