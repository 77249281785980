import { Box } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { UploadOnBeforeUploadEvent, UploadResponse } from '@progress/kendo-react-upload';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ImportErrorsExtraResult, LineImportErrors } from 'src/shared/models/ImportErrorsExtraResult';
import { WebAppActionResultEx } from 'src/shared/models/WebAppActionResult';
import ToastService from 'src/ToastService';
import { ScaleLoaderComponent } from '../../../shared/components/Common/ScaleLoaderComponent';
import BusinessErrors from '../../../utils/BusinessErrors';
import '../ReferentialsStyles.scss';
import { HeaderContentComponent } from './components/HeaderContentComponent';
import { Level2ActivitiesComponent } from './components/Level2ActivitiesComponent';
import './Level2ActivitiesReferentialStyles.scss';
import { Level2ActivityLightModel } from './services/dataContracts/queryStack/Level2ActivityLightModel';
import { LogisticsUnitChoiceOfLevel2ActivitiesLightModel } from './services/dataContracts/queryStack/LogisticsUnitChoiceOfLevel2ActivitiesLightModel';
import { Level2ActivitiesReferentialApiClient } from './services/Level2ActivitiesReferentialApiClient';

export const Level2ActivitiesReferentialView = () => {

    const inputSearchLevel2ActivitiesRef: React.RefObject<HTMLInputElement> = React.useRef(null);
    const initialSort: SortDescriptor[] = [{ field: 'id', dir: 'asc' }];

    const [level2ActivitiesList, setLevel2ActivitiesList] = useState<Array<Level2ActivityLightModel>>([]);
    const [level2ActivityChoicesLogisticsUnit, setLevel2ActivityChoicesLogisticsUnit] = useState<Array<LogisticsUnitChoiceOfLevel2ActivitiesLightModel>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [sort, setSort] = useState<Array<SortDescriptor>>(initialSort);

    useEffect(() => {
        getLevel2Activities("");
    }, []);

    const clearSearchText = (): void => {
        inputSearchLevel2ActivitiesRef.current.value = "";
        level2ActivitiesKeyPressed("");
    }

    const handleLevel2ActivitiesKeyPressed = debounce((text: string): void => {
        if (text.length >= 2 || text.length === 0) {
            level2ActivitiesKeyPressed(text);
        }
    }, 500);

    const level2ActivitiesKeyPressed = (text: string): void => {
        getLevel2Activities(text);
    }

    const getLevel2Activities = (searchText: string, errorMessage?: string, listErrorMessage?: Array<string>, successMessage?: string, endMessage?: string): void => {
        setLoading(true);
        Level2ActivitiesReferentialApiClient.GetLevel2ActivitiesWithLevel2ActivityChoicesLogisticsUnit(searchText)
            .then(response => {
                setLevel2ActivitiesList(response[0].data);
                setLevel2ActivityChoicesLogisticsUnit(response[1].data);

                if (errorMessage)
                    ToastService.showErrorToast(errorMessage, listErrorMessage, endMessage);
                else if (successMessage)
                    ToastService.showSuccessToast(successMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const handleSortColumnChange = (sortItems: SortDescriptor[]): void => {
        setSort(sortItems);
    }

    const inputSearchLevel2ActivitiesValue = inputSearchLevel2ActivitiesRef.current === null || inputSearchLevel2ActivitiesRef.current === undefined ? '' : inputSearchLevel2ActivitiesRef.current.value;

    const handlerAfterUpload = (res: UploadResponse): void => {
        const response: WebAppActionResultEx<ImportErrorsExtraResult> = res ? res.response : null;
        const listErrorMessage = [];
        let errorMessage = '';
        let successMessage = '';
        let endMessage = '';

        if (res.status === 200 && response) {
            const importLines: Array<LineImportErrors> = response.extraResult.linesWithErrors;
            if (importLines.length > 0) {
                importLines.forEach(e => {
                    e.errors.forEach(eError => {
                        let libMsg = BusinessErrors.GetError(eError);
                        if (!libMsg && eError)
                            libMsg = eError;
                        listErrorMessage.push(`- Ligne ${e.rowIndex} - ${libMsg}`);
                    });
                });

                errorMessage = 'Erreur lors de l\'import de la visibilité des activités niveau 2:';
                endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des activités niveau 2 sur vos zones logistiques';

            } 
            else{
                successMessage = 'La visibilité des activités niveau 2 pour vos zones logistiques a bien été intégrée';
            }
        } 
        else {
            errorMessage = 'Erreur lors de l\'import de la visibilité des activités niveau 2, veuillez vérifier votre fichier';
            endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des activités niveau 2 sur vos zones logistiques';
            successMessage = '';
        }
        
        getLevel2Activities(inputSearchLevel2ActivitiesValue, errorMessage, listErrorMessage, successMessage, endMessage);
    }

    const displayErrorResponse = (): void => {
        const errorMessage = 'Erreur lors de l\'import de la visibilité des activités niveau 2, veuillez vérifier votre fichier';
        const endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des activités niveau 2 sur vos zones logistiques';
        const successMessage = '';
        getLevel2Activities(inputSearchLevel2ActivitiesValue, errorMessage, [], successMessage, endMessage);
    }

    const handlerBeforeUpload = (e: UploadOnBeforeUploadEvent): void => {
        setLoading(true);
    }

    const headerContentComponent: JSX.Element = useMemo(() =>
        <HeaderContentComponent inputSearchLevel2ActivitiesValue={inputSearchLevel2ActivitiesValue}
            inputSearchLevel2ActivitiesRef={inputSearchLevel2ActivitiesRef}
            sort={sort}
            handleLevel2ActivitiesKeyPress={handleLevel2ActivitiesKeyPressed}
            handleClearSearchText={clearSearchText}
            handlerAfterUpload={handlerAfterUpload}
            handlerBeforeUpload={handlerBeforeUpload}
            displayErrorResponse={displayErrorResponse}
        />, [inputSearchLevel2ActivitiesValue, inputSearchLevel2ActivitiesRef, sort]);

    const level2ActivitiesComponent: JSX.Element = useMemo(() =>
        <Level2ActivitiesComponent
            level2Activities={level2ActivitiesList}
            level2ActivityChoicesLogisticsUnit={level2ActivityChoicesLogisticsUnit}
            sort={sort}
            handleSortChange={handleSortChange}
            handleSortColumnChange={handleSortColumnChange}
            />, [level2ActivitiesList, level2ActivityChoicesLogisticsUnit, sort]);

    return (
        <Box className="level2Activities referencial">
            <Box display="flex" flexDirection="row" flex="wrap" className="view-title">
                Gestion des activités niveau 2
            </Box>
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box display="flex" flexDirection="column" flex="wrap">
                        {headerContentComponent}
                        {(loading ?
                            <ScaleLoaderComponent loading={loading} />
                            :
                            level2ActivitiesComponent
                        )}
                    </Box>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}
