import axios, { AxiosResponse } from 'axios';
import { flatMap } from 'lodash';
import { LogisticsUnitChoice } from '../../../../shared/models/LogisticsUnitChoice';
import { WebAppActionResult, WebAppActionResultEx } from '../../../../shared/models/WebAppActionResult';
import { ImportErrorsWithCountersExtraResult } from '../models/ImportErrorsWithCountersExtraResult';
import { AddTransporterRequestArgs } from './dataContracts/controller/AddTransporterRequestArgs';
import { ExportExistingPurchasePricesRequestArgs } from './dataContracts/controller/ExportExistingPurchasePricesRequestArgs';
import { ExportNewPurchasePricesRequestArgs } from './dataContracts/controller/ExportNewPurchasePricesRequestArgs';
import { ImportNewPurchasePricesRequestArgs } from './dataContracts/controller/ImportNewPurchasePricesRequestArgs';
import { PurchasePriceChoiceLogisticsUnitRequestArgs } from './dataContracts/controller/PurchasePriceChoiceLogisticsUnitRequestArgs';
import { PurchasePriceChoiceLogisticsUnitsRequestArgs } from './dataContracts/controller/PurchasePriceChoiceLogisticsUnitsRequestArgs';
import { PurchasePriceChoicesExportRequestArgs } from './dataContracts/controller/PurchasePriceChoicesExportRequestArgs';
import { SetOrUnsetAllPurchasePricesChoicesForLogisticsUnitRequestArgs } from './dataContracts/controller/SetOrUnsetAllPurchasePricesChoicesForLogisticsUnitRequestArgs';
import { TransporterCandidateToAdd } from './dataContracts/controller/TransporterCandidateToAdd';
import { TransporterChoiceLogisticsUnitRequestArgs } from './dataContracts/controller/TransporterChoiceLogisticsUnitRequestArgs';
import { TransporterChoiceLogisticsUnitsRequestArgs } from './dataContracts/controller/TransporterChoiceLogisticsUnitsRequestArgs';
import { TransporterChoicesExportRequestArgs } from './dataContracts/controller/TransporterChoicesExportRequestArgs';
import { UpdateExternalTransporterRequestArgs } from './dataContracts/controller/UpdateExternalTransporterRequestArgs';
import { LogisticsUnitChoiceOfTransportersLightModel } from './dataContracts/queryStack/LogisticsUnitChoiceOfTransportersLightModel';
import { LogisticsUnitChoiceOfTransportPurchasePricesLightModel } from './dataContracts/queryStack/LogisticsUnitChoiceOfTransportPurchasePricesLightModel';
import { TransporterPurchasePriceLightModel } from './dataContracts/queryStack/TransporterPurchasePriceLightModel';
import { TransportPurchaseLightModel } from './dataContracts/queryStack/TransportPurchaseLightModel';
import { VehicleTypeLightModel } from './dataContracts/queryStack/VehicleTypeLightModel';

const controllerUrl = "api/TransportPurchasePricesReferential/";

export class TransportPurchasePricesReferentialApiClient {

    public static GetTransporters = (logisticsUnits: Array<string>, searchText: string, isGlobalSearch: boolean, isForInternalTransporters: boolean)
        : Promise<AxiosResponse<Array<TransportPurchaseLightModel>>> => {
        return axios.post(`${controllerUrl}GetTransporters?searchText=${encodeURIComponent(searchText)}&isGlobalSearch=${isGlobalSearch}&isForInternalTransporters=${isForInternalTransporters}`
            , JSON.stringify(logisticsUnits), {
            headers: {
                'Accept': 'application / json',
                'Content-Type': 'application/json',
            }
        });
    }

    public static GetPurchasePricesByTransporter = (logisticsUnits: Array<string>, onlyChosenPurchasePrices: boolean, showOnlyContractedPrices: boolean, transporterId: string)
        : Promise<AxiosResponse<Array<TransporterPurchasePriceLightModel>>> => {
        return axios.post(`${controllerUrl}GetPurchasePricesByTransporter?transporterId=${encodeURIComponent(transporterId)}`
            + '&onlyChosenPurchasePrices=' + onlyChosenPurchasePrices + '&showOnlyContractedPrices=' + showOnlyContractedPrices + '&currentDate=' + encodeURIComponent((new Date()).toJSON())
            , JSON.stringify(logisticsUnits), {
            headers: {
                'Accept': 'application / json',
                'Content-Type': 'application/json',
            }
        });
    }

    public static GetPurchasePricesChoicesLogisticsUnits = ()
        : Promise<AxiosResponse<Array<LogisticsUnitChoiceOfTransportPurchasePricesLightModel>>> => {
        return axios.get(`${controllerUrl}GetPurchasePricesChoicesLogisticsUnits`);
    }

    public static GetTranporterChoicesLogisticsUnits = ()
        : Promise<AxiosResponse<Array<LogisticsUnitChoiceOfTransportersLightModel>>> => {
        return axios.get(`${controllerUrl}GetTranporterChoicesLogisticsUnits`);
    }

    public static GetPurchasePricesByTransporterWithPurchasePriceChoicesLogisticsUnit(logisticsUnits: Array<string>, onlyChosenPurchasePrices: boolean, showOnlyContractedPrices: boolean, transporterId: string)
        : Promise<[AxiosResponse<Array<TransporterPurchasePriceLightModel>>, AxiosResponse<Array<LogisticsUnitChoiceOfTransportPurchasePricesLightModel>>]> {
        return Promise.all(
            [
                this.GetPurchasePricesByTransporter(logisticsUnits, onlyChosenPurchasePrices, showOnlyContractedPrices, transporterId),
                this.GetPurchasePricesChoicesLogisticsUnits()
            ]);
    }

    public static GetTransportersWithTransporterChoicesLogisticsUnit(logisticsUnits: Array<string>, searchText: string, isGlobalSearch: boolean, isForInternalTransporters: boolean)
        : Promise<[AxiosResponse<Array<TransportPurchaseLightModel>>, AxiosResponse<Array<LogisticsUnitChoiceOfTransportersLightModel>>]> {
        return Promise.all(
            [
                this.GetTransporters(logisticsUnits, searchText, isGlobalSearch, isForInternalTransporters),
                this.GetTranporterChoicesLogisticsUnits()
            ]);
    }

    public static UpdatePurchasePriceChoiceLogisticsUnit = (requestArgs: PurchasePriceChoiceLogisticsUnitRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'UpdatePurchasePriceChoiceLogisticsUnit',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static SetOrUnsetPurchasePriceChoiceToAllLogisticsUnits = (requestArgs: PurchasePriceChoiceLogisticsUnitsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'SetOrUnsetPurchasePriceChoiceToAllLogisticsUnits',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateTransporterChoiceLogisticsUnit = (requestArgs: TransporterChoiceLogisticsUnitRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'UpdateTransporterChoiceLogisticsUnit',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static SetOrUnsetTransporterChoiceToAllLogisticsUnits = (requestArgs: TransporterChoiceLogisticsUnitsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'SetOrUnsetTransporterChoiceToAllLogisticsUnits',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static ExportTransporterPurchasePriceChoices(requestArgs: TransporterChoicesExportRequestArgs, purchasePriceChoicesExportRequestArgs: PurchasePriceChoicesExportRequestArgs)
        : Promise<[AxiosResponse<Blob>, AxiosResponse<Blob>]> {
        return Promise.all(
            [
                this.ExportTransporterChoices(requestArgs),
                this.ExportPurchasePricesByTransporterChoices(purchasePriceChoicesExportRequestArgs)
            ]);
    }

    public static ExportTransporterChoices = (requestArgs: TransporterChoicesExportRequestArgs)
        : Promise<AxiosResponse<Blob>> => {
        const _headers = {
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return axios.post(controllerUrl + 'ExportTransportersChoices', requestArgs, { headers: _headers, responseType: 'blob' });
    }

    public static ExportPurchasePricesByTransporterChoices = (requestArgs: PurchasePriceChoicesExportRequestArgs)
        : Promise<AxiosResponse<Blob>> => {
        const _headers = {
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return axios.post(controllerUrl + 'ExportPurchasePricesByTransporterChoices', requestArgs, { headers: _headers, responseType: 'blob' });
    }

    public static ExportPurchasePricesTemplateFromExisting = (requestArgs: ExportExistingPurchasePricesRequestArgs)
        : Promise<AxiosResponse<Blob>> => {
        const _headers = {
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return axios.post(controllerUrl + 'ExportPurchasePricesTemplateFromExisting', requestArgs, { headers: _headers, responseType: 'blob' });
    }

    public static ExportPurchasePricesTemplateFromNew = (requestArgs: ExportNewPurchasePricesRequestArgs)
        : Promise<AxiosResponse<Blob>> => {
        const _headers = {
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return axios.post(controllerUrl + 'ExportPurchasePricesTemplateFromNew', requestArgs, { headers: _headers, responseType: 'blob' });
    }

    public static GetImportUrl = (isForInternalTransporters: boolean): string => {
        return controllerUrl + `${isForInternalTransporters ? 'ImportInternalTransportersFile' : 'ImportExternalTransportersFile'}`;
    }

    public static SetOrUnsetAllPurchasePricesChoicesForLogisticsUnit = (requestArgs: SetOrUnsetAllPurchasePricesChoicesForLogisticsUnitRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'SetOrUnsetAllPurchasePricesChoicesForLogisticsUnit',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static ImportNewPurchasePrices = (requestArgs: ImportNewPurchasePricesRequestArgs)
        : Promise<AxiosResponse<WebAppActionResultEx<ImportErrorsWithCountersExtraResult>>> => {
        const config = {
            headers: {
                'Accept': 'application / json',
                'Content-Type': 'application/json',
            },
            __colas_lorie_disableGenericErrorHandler: true
        }
        return axios.post(controllerUrl + 'ImportNewPurchasePrices', JSON.stringify(requestArgs), config);
    }

    public static GetChosenLogisticsUnits(logisticsUnits: Array<LogisticsUnitChoice>)
        : Array<string> {
        const listLogisticsUnits = logisticsUnits.filter(e => { return e.checked === true });

        if (listLogisticsUnits)
            return this.GetLogisticsUnitIds(listLogisticsUnits);

        return [];
    }

    public static GetLogisticsUnitIds = (logisticsUnits: Array<LogisticsUnitChoice>)
        : Array<string> => {
        return flatMap(logisticsUnits, 'logisticsUnitId');
    }

    public static GetVehicleTypesList = ()
        : Promise<AxiosResponse<Array<VehicleTypeLightModel>>> => {
        return axios.get(`${controllerUrl}GetVehicleTypesList`);
    }

    public static SearchTransporterByMdmId = (mdmId: string)
        : Promise<AxiosResponse<TransporterCandidateToAdd>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SearchTransporterByMdmId?mdmId=${mdmId}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static SearchTransporterByName = (name: string)
        : Promise<AxiosResponse<TransporterCandidateToAdd[]>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SearchTransporterByName?name=${name}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static TransporterExists = (mdmId: string)
        : Promise<AxiosResponse<boolean>> => {
        return axios.get(`${controllerUrl}TransporterExists?mdmId=${mdmId}`);
    }

    public static AddTransporter = (requestArgs: AddTransporterRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddTransporter',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateExternalTransporter = (requestArgs: UpdateExternalTransporterRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateExternalTransporter`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }
}
