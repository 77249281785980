import { Box } from '@mui/material';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { countBy, debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ToastService from 'src/ToastService';
import SimpleDialog from '../../../shared/components/Common/SimpleDialog';
import { LogisticsUnitChoice } from '../../../shared/models/LogisticsUnitChoice';
import { LogisticsUnitSelectModel } from '../../../shared/models/LogisticsUnitSelectModel';
import BusinessErrors from '../../../utils/BusinessErrors';
import { ContentHeaderComponent } from './components/ContentHeaderComponent';
import { NegotiatedPurchasePricesComponent } from './components/NegotiatedPurchasePricesComponent';
import { NegotiatedPurchasePricesForm } from './components/NegotiatedPurchasePricesForm';
import { ActionType } from './models/ActionType';
import { NegotiatedPurchasePriceLightModelExtended } from './models/NegotiatedPurchasePriceLightModelExtended';
import './NegotiatedPurchasePricesReferentialStyles.scss';
import { AddNegotiatedPurchasePricesArgs } from './services/dataContracts/controller/AddNegotiatedPurchasePricesArgs';
import { UpdateNegotiatedPurchasePriceArgs } from './services/dataContracts/controller/UpdateNegotiatedPurchasePriceArgs';
import { NegotiatedPurchasePricesReferentialApiClient } from './services/NegotiatedPurchasePricesReferentialApiClient';

interface NegotiatedPurchasePricesReferentialProperties {
    logisticsUnits: Array<LogisticsUnitChoice>,
}

export const NegotiatedPurchasePricesReferentialView = (props: NegotiatedPurchasePricesReferentialProperties): JSX.Element => {

    const [inputSearchPurchasePriceValue, setInputSearchPurchasePriceValue] = useState<string>("");
    const [selectedLogisticsUnit, setSelectedLogisticsUnit] = useState<LogisticsUnitSelectModel>(props.logisticsUnits.length == 1 ? { value: props.logisticsUnits[0].logisticsUnitId, label: props.logisticsUnits[0].label } : null);
    const [isPurchasePriceDialogOpened, setIsPurchasePriceDialogOpened] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isCreateNegotiatedPurchasePriceInfoModalOpened, setCreateNegotiatedPurchasePriceInfoModalOpened] = useState<boolean>(false);
    const [negotiatedPurchasePrices, setNegotiatedPurchasePrices] = useState<NegotiatedPurchasePriceLightModelExtended[]>([]);
    const [actionType, setActionType] = useState<ActionType>(ActionType.Add);

    const inputSearchPurchasePriceRef: React.RefObject<HTMLInputElement> = React.useRef(null);

    useEffect(() => {
        if (selectedLogisticsUnit)
            getNegotiatedPurchasePrices(selectedLogisticsUnit.value, "");
    }, []);

    const handleLogisticsUnitChange = (event: LogisticsUnitSelectModel): void => {
        setSelectedLogisticsUnit(event);
        getNegotiatedPurchasePrices(event ? event.value : "", "");
    }

    const handleOpenPurchasePriceDialog = (actionType: ActionType): void => {
        setActionType(actionType);
        setIsPurchasePriceDialogOpened(true);
    }

    const handleClosePurchasePriceDialog = (): void => {
        setIsPurchasePriceDialogOpened(false);
    }

    const handleRemovePurchasePrices = debounce((): void => {
        const negotiatedPurchasePricesToRemove: string[] = negotiatedPurchasePrices.filter(x => x.selected)
            .map(x => x.negotiatedPurchasePriceId);

        NegotiatedPurchasePricesReferentialApiClient.RemoveNegotiatedPurchasePrices(negotiatedPurchasePricesToRemove)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }
                getNegotiatedPurchasePrices(selectedLogisticsUnit?.value, inputSearchPurchasePriceRef.current.value);
            });
    }, 500);

    const clearSearchText = (): void => {
        inputSearchPurchasePriceRef.current.value = "";
        setInputSearchPurchasePriceValue("");
        getNegotiatedPurchasePrices(selectedLogisticsUnit?.value, "");
    }

    const handlePurchasePriceKeyPress = debounce((searchText: string): void => {
        if (searchText.length >= 3 || searchText.length === 0) {
            inputSearchPurchasePriceRef.current.value = searchText;
            setInputSearchPurchasePriceValue(searchText);
            getNegotiatedPurchasePrices(selectedLogisticsUnit?.value, searchText);
        }
    }, 500);

    const getNegotiatedPurchasePrices = (logisticsUnitId: string, searchText: string): void => {
        setLoading(true);
        NegotiatedPurchasePricesReferentialApiClient.SearchNegotiatedPurchasePrices(logisticsUnitId, searchText)
            .then((res) => {
                const negotiatedPurchasePricesArray: Array<NegotiatedPurchasePriceLightModelExtended> = res.data;
                negotiatedPurchasePricesArray.forEach((element: NegotiatedPurchasePriceLightModelExtended) => {
                    translatePlanningVehicles(element);
                });
                setNegotiatedPurchasePrices(negotiatedPurchasePricesArray);
                setLoading(false);
            });
    }

    const translatePlanningVehicles = (element: NegotiatedPurchasePriceLightModelExtended): void => {
        element.selected = false;
        element.price = Number(element.price.toFixed(2));
        element.priceKindLabel = element.priceKind === "PerTurn"
            ? "Tour"
            : (element.priceKind === "PerTon"
                ? "Tonne"
                : element.priceKind === "PerHour"
                    ? "Heure" : "");
    }

    const handleSelectionItemChange = (checked: boolean, dataItem: NegotiatedPurchasePriceLightModelExtended): void => {
        const negotiatedPurchasePricesArray = [...negotiatedPurchasePrices];
        const element = negotiatedPurchasePricesArray.find(p => p.negotiatedPurchasePriceId === dataItem.negotiatedPurchasePriceId);
        element.selected = checked;
        setNegotiatedPurchasePrices(negotiatedPurchasePricesArray);
    }

    const addNegotiatedPurchasePrices = debounce((requestArgs: AddNegotiatedPurchasePricesArgs): void => {
        NegotiatedPurchasePricesReferentialApiClient.AddNegotiatedPurchasePrices(requestArgs)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }
                setIsPurchasePriceDialogOpened(false);

                ToastService.showSuccessToast("Création du tarif d'achat négocié réalisée avec succès");
                getNegotiatedPurchasePrices(selectedLogisticsUnit?.value, inputSearchPurchasePriceRef.current.value);

                // Pour l'instant la prise en compte des nouveaux tarifs est immédiat, on garde l'info invisible.
                setCreateNegotiatedPurchasePriceInfoModalOpened(false);
            });
    }, 500);

    const updateNegotiatedPurchasePrice = debounce((requestArgs: UpdateNegotiatedPurchasePriceArgs): void => {
        NegotiatedPurchasePricesReferentialApiClient.UpdateNegotiatedPurchasePrice(requestArgs)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }
                setIsPurchasePriceDialogOpened(false);

                ToastService.showSuccessToast("Mise à jour du tarif d'achat négocié réalisée avec succès");
                getNegotiatedPurchasePrices(selectedLogisticsUnit?.value, inputSearchPurchasePriceRef.current.value);

                // Pour l'instant la prise en compte des nouveaux tarifs est immédiat, on garde l'info invisible.
                setCreateNegotiatedPurchasePriceInfoModalOpened(false);
            });
    }, 500);

    const handleCreateNegotiatedPurchasePriceInfoModal = (): void => {
        setCreateNegotiatedPurchasePriceInfoModalOpened(false);
    }

    const enableBtnTrash: boolean = negotiatedPurchasePrices.some(x => x.selected === true);
    const purchasePricesSelectedCount = countBy(negotiatedPurchasePrices, x => x.selected === true);
    const disabledBtnAdd: boolean = selectedLogisticsUnit ? false : true;
    const disabledBtnDup: boolean = purchasePricesSelectedCount.true !== 1;
    const disabledBtnEdit: boolean = purchasePricesSelectedCount.true !== 1;

    const contentHeaderComponent: JSX.Element = useMemo(() =>
        <ContentHeaderComponent
            logisticsUnits={props.logisticsUnits}
            selectedLogisticsUnit={selectedLogisticsUnit}
            enableBtnTrash={enableBtnTrash}
            disabledBtnAdd={disabledBtnAdd}
            disabledBtnDup={disabledBtnDup}
            disabledBtnEdit={disabledBtnEdit}
            inputSearchPurchasePriceValue={inputSearchPurchasePriceValue}
            inputSearchPurchasePriceRef={inputSearchPurchasePriceRef}
            handleLogisticsUnitChange={handleLogisticsUnitChange}
            handleOpenPurchasePriceDialog={handleOpenPurchasePriceDialog}
            handleRemovePurchasePrices={handleRemovePurchasePrices}
            handlePurchasePriceKeyPress={handlePurchasePriceKeyPress}
            clearSearchText={clearSearchText}
        />, [selectedLogisticsUnit, enableBtnTrash, disabledBtnDup, disabledBtnEdit, inputSearchPurchasePriceValue]);

    const negotiatedPurchasePricesComponent: JSX.Element = useMemo(() =>
        <NegotiatedPurchasePricesComponent
            loading={loading}
            negotiatedPurchasePrices={negotiatedPurchasePrices}
            handleSelectionItemChange={handleSelectionItemChange}
        />, [loading, selectedLogisticsUnit, negotiatedPurchasePrices]);

    const dialogTitle = actionType === ActionType.Add
        ? "Ajouter un tarif d'achat négocié"
        : actionType === ActionType.Edit
            ? "Modifier un tarif d'achat négocié"
            : "Dupliquer un tarif d'achat négocié";

    return (
        <div className="negotiated-purchase-prices-referential-view">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box className="title-section">
                        <h5>
                            Tarifs d'achat négociés
                        </h5>
                        <hr className="separator" />
                    </Box>
                    <Box display="flex" flexDirection="column" className="content-section">
                        {contentHeaderComponent}
                        {negotiatedPurchasePricesComponent}
                    </Box>
                    <SimpleDialog isOpen={isPurchasePriceDialogOpened}
                        onClose={handleClosePurchasePriceDialog}
                        dialogTitle={dialogTitle}
                        closeIcon={true}
                        classNameVal="add-purchase-price-dialog"
                        headerBorder={true}
                        component={
                            <NegotiatedPurchasePricesForm
                                selectedLogisticsUnit={selectedLogisticsUnit}
                                negotiatedPurchasePrices={negotiatedPurchasePrices}
                                addNegotiatedPurchasePrices={addNegotiatedPurchasePrices}
                                updateNegotiatedPurchasePrice={updateNegotiatedPurchasePrice}
                                actionType={actionType}
                            />
                        }
                    />
                </IntlProvider>
            </LocalizationProvider>
            <Modal show={isCreateNegotiatedPurchasePriceInfoModalOpened} onHide={handleCreateNegotiatedPurchasePriceInfoModal} className='mt-5'
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    La prise en compte des nouveaux tarifs ajoutés peut prendre 3 minutes.
                </Modal.Body>
            </Modal>
        </div>
    );

}