import { faFilePdf, faMoon, faPhoneAlt, faPrint, faSun, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Divider, IconButton, Tooltip } from '@mui/material';
import React, { useRef } from 'react';
import Select, { components, OptionProps } from 'react-select';
import { AttachmentsApiClient } from '../../../../shared/Attachments/services/AttachmentsApiClient';
import { PlanningVehicleStatusComponent } from '../../../../shared/components/PlanningVehicleStatus/PlanningVehicleStatusComponent';
import { downloadFileFromBlob, getFileName } from '../../../../utils/DownloadFile';
import Utilities from '../../../../utils/Utilities';
import { SelectOptionModel } from '../models/SelectOptionModel';
import { TransporterOrderLineSeenByTransporterLightModelExtended } from '../models/TransporterOrderLineSeenByTransporterLightModelExtended';
import { DeliveryTripInstructionLightModel } from '../services/dataContracts/queryStack/DeliveryTripInstructionLightModel';
import { DriverLightModel } from '../services/dataContracts/queryStack/DriverLightModel';
import { TransporterOrderLineDetailSeenByTransporterLightModel } from '../services/dataContracts/queryStack/TransporterOrderLineDetailSeenByTransporterLightModel';
import { TransportFlowAttachmentFileLightModel } from '../services/dataContracts/queryStack/TransportFlowAttachmentFileLightModel';
import { VehicleLightModel } from '../services/dataContracts/queryStack/VehicleLightModel';

interface InstructionsComponentProperties {
    item: TransporterOrderLineDetailSeenByTransporterLightModel
}

const InstructionsComponent = (props: InstructionsComponentProperties): JSX.Element => {
    const instructions: DeliveryTripInstructionLightModel[] = props.item.instructions.filter(d => d.instructionText !== '');
    const transportersInstructions: string = props.item.transportersInstructions;

    return (
        <>
            {
                (transportersInstructions && transportersInstructions !== '') ? (
                    <p className="transporters-instructions">
                        {transportersInstructions}
                    </p>
                ) : ''
            }
            {
                instructions.length == 1 ?
                    instructions[0].instructionText !== '' && <p>{instructions[0].instructionText}</p>
                    :
                    instructions.length > 1 && props.item.instructions.map((instructionModel, instructionIndex) =>
                        instructionModel.instructionText !== '' && <p key={instructionIndex}> <b> {'Tour n° ' + instructionModel.rotationNumber} </b> {' : ' + instructionModel.instructionText}</p>
                    )
            }
        </>
    );
}

interface TransporterCardVehicleProperties {
    indexGroup: number,
    index: number,
    orderId: string,
    isForInternalTransporters: boolean,
    orderReference: string,
    item: TransporterOrderLineSeenByTransporterLightModelExtended,
    licencePlatesListSortedByVehicleTypeId: VehicleLightModel[],
    selectedVehicleId: number,
    selectedDriverId: number,
    driversList: DriverLightModel[],
    expandedPanelsTransportRequests: Map<string, boolean>,
    isDriverListOpened: boolean,
    isVehicleListOpened: boolean,
    readOnly: boolean,
    disableChanges: boolean,
    identicalDrivers: boolean,
    vehicleTypeGroupDoesNotMatch: boolean,
    handleDriverListOpen: (isOpen: boolean, readOnly: boolean) => void,
    handleVehicleListOpen: (isOpen: boolean, readOnly: boolean) => void,
    handleChange: (panel: string) => void,
    handleLicencePlateChange: (licenceRef: React.MutableRefObject<HTMLInputElement>, selectedOption: SelectOptionModel, planningVehicleId: string, isNightWork: boolean) => void,
    handleLicencePlateBlur: (licenceRef: React.MutableRefObject<HTMLInputElement>, selectedVehicleId: number, planningVehicleId: string, isNightWork: boolean, vehicleId: number) => void,
    handleDriverChange: (driverRef: React.MutableRefObject<HTMLInputElement>, selectedOption: SelectOptionModel, planningVehicleId: string, isNightWork: boolean) => void,
    handleDriverBlur: (driverRef: React.MutableRefObject<HTMLInputElement>, selectedDriver: DriverLightModel, planningVehicleId: string, isNightWork: boolean, driverId: number) => void,
    handleDriverPhoneChange: (event: React.ChangeEvent<HTMLInputElement>, planningVehicleId: string) => void,
    handleDriverPhoneBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleLoadCapacityChange: (event: React.ChangeEvent<HTMLInputElement>, planningVehicleId: string) => void,
    handleLoadCapacityBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
    printOrGenerateFileSpecifiedSection: (lines: TransporterOrderLineSeenByTransporterLightModelExtended[], requestToGenerateFile: boolean) => void,
    getValueField: (fieldValue: string) => string,
    role: string
}

const Option = (props: OptionProps<SelectOptionModel>) => {
    const data = props.data.value as DriverLightModel;

    return (
        <div>
            <components.Option {...props}>
                <Box display="flex" flexDirection="row" alignItems="center" className="driver-item">
                    {data?.driverId > 0 &&
                        <FontAwesomeIcon icon={faUser} className={`fa-user-driver-${(data.isInternal && !data.isTemporary) ? "internal" : "temporary"}`} />}
                    <Box pl={1} key={`driver-`} className="driver-item-name">
                        {data?.fullName}
                    </Box>
                </Box>
            </components.Option>
        </div>
    );
};

export const TransporterCardVehicleComponent = (props: TransporterCardVehicleProperties): JSX.Element => {

    const licenceRef = useRef();
    const driverRef = useRef();

    const getAttachment = (attachmentId: string) => {
        AttachmentsApiClient.GetAttachment(attachmentId)
            .then(response => {
                const fileName: string = getFileName(response);
                const blob: Blob = response.data;
                downloadFileFromBlob(blob, fileName);
            });
    }

    const { indexGroup, index, item, orderId, licencePlatesListSortedByVehicleTypeId, driversList, expandedPanelsTransportRequests: expandedPanelsTransportRequests, isDriverListOpened, isVehicleListOpened, handleDriverListOpen, handleVehicleListOpen,
        disableChanges, identicalDrivers, readOnly, vehicleTypeGroupDoesNotMatch,
        handleChange, handleLicencePlateChange, handleLicencePlateBlur, handleDriverChange, handleDriverBlur,
        handleDriverPhoneChange, handleDriverPhoneBlur, handleLoadCapacityChange, handleLoadCapacityBlur
        , printOrGenerateFileSpecifiedSection, role, selectedVehicleId, selectedDriverId, isForInternalTransporters } = props;

    const licencesPlatesOptions: Array<SelectOptionModel> = [...licencePlatesListSortedByVehicleTypeId.filter(x => x.vehicleTypeGroupId === item.orderedVehicleTypeGroupeId).map((v, index) => {
        return {
            label: `${v.vehicleTypeLabel} - ${v.licencePlate}${props.isForInternalTransporters ? (' - ' + v.equipmentId) : ''}`,
            value: v.id
        };
    })];

    const driversOptions: Array<SelectOptionModel> = [...driversList.map((driver, index) => {
        return {
            label: driver.fullName,
            value: driver
        };
    })];

    const selectedLicencePlateValue = selectedVehicleId !== -1 ? licencesPlatesOptions.find(l => l.value === selectedVehicleId) : null;
    const selectedDriverValue = selectedDriverId !== -1 ? driversOptions.find(l => (l.value as DriverLightModel)?.driverId === selectedDriverId) : null;

    return (
        <Card>
            <CardContent className="transporter-card-content tr-section">
                <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center" className="transporter-title-card">
                    <Box pl={1} className="transporter-card-content-vehicle-title" flexGrow="1" display="flex" flexDirection="row" alignItems="center">
                        <PlanningVehicleStatusComponent
                            isCanceled={item.isCanceled}
                            transporterConfirmationDate={item.confirmDate}
                            transporterOrderId={orderId}
                            transporterIsInternal={isForInternalTransporters}
                            cancellationReason={item.cancellationReason}
                            cancellationRemarks={item.cancellationRemarks}
                            purchaseIsCanceled={item.purchaseIsCanceled}
                            saleIsCanceled={item.saleIsCanceled}
                            cancellationOrderSentDate={item.cancellationOrderSentDate}
                            cancellationOrderConfirmationDate={item.cancellationOrderConfirmationDate}
                        />
                        {item.isCanceled && <div className="canceled-label">ANNULÉ</div>}

                        {`${item.vehicleTypeLabel}`} <br /> {item.isNightWork == true ? (
                            <FontAwesomeIcon size="sm" icon={faMoon} className="icon-night-work" />
                        ) :
                            <FontAwesomeIcon size="sm" icon={faSun} className="icon-night-work" />
                        }
                        {item.mainPriceKindLabel && (
                            <>
                                -
                                {/*NOTE ODA on n'a pas utilisé le pseudo-element CSS "::first-letter" car pas supporté par le composant PDFExport*/}
                                <div className="main-price-kind-first-letter">{item.mainPriceKindLabel.substring(0, 1)}</div>
                                <div className="main-price-kind-ending-letters">{item.mainPriceKindLabel.length > 1 ? item.mainPriceKindLabel.substring(1) : ''}</div>
                            </>
                        )}
                        {item.contractualPurchasePriceContractNumber &&
                            <div className="contract-number">{`PAC (${item.contractualPurchasePriceContractNumber})`}</div>
                        }
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                        {((!item.isCanceled && item.confirmDate) || (item.isCanceled && item.cancellationOrderConfirmationDate === null && item.confirmDate)) && <Box className="confirmation-date">
                            Confirmé le {item.confirmDate !== null ? ` ${new Date(item.confirmDate).toShortDateTimeString()}` : ''}
                        </Box>}
                        {item.isCanceled && item.cancellationOrderConfirmationDate !== null && <Box className="confirmation-canceled-date">
                            {`Annulation confirmée le  ${new Date(item.cancellationOrderConfirmationDate).toShortDateTimeString()}`}
                        </Box>}

                        <Box display="flex" flexDirection="column">
                            <Box display="flex" flexDirection="row">
                                <Box pr={2}>
                                    <Tooltip title="Imprimer" placement="bottom">
                                        <IconButton color="primary" size="small" onClick={(e) => printOrGenerateFileSpecifiedSection([item], false)}>
                                            <FontAwesomeIcon size="1x" className="icon-print" icon={faPrint} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Box pr={1}>
                                    <Tooltip title="Générer PDF" placement="bottom">
                                        <IconButton color="primary" size="small" onClick={(e) => printOrGenerateFileSpecifiedSection([item], true)}>
                                            <FontAwesomeIcon size="1x" className="icon-file-pdf" icon={faFilePdf} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <div className="vehicle-number-id">
                                idCam: {item.vehicleNumber}
                            </div>
                        </Box>
                    </Box>
                </Box>
                <Divider style={{ height: '1px' }} />
                <Box width="100%">
                    <Card className="transporter-sub-card-vehicle-type tr-section">
                        <CardContent className="transporter-card-content" style={{ overflowX: "auto" }}>
                            <Box display="flex" flexWrap="nowrap" flexDirection="column" className="transporter">
                                <Box display="flex" m={1} flexDirection="row" flexWrap="wrap">
                                    <Box width="30%" mt={1} minWidth='260px' mr={2} className="control-box">
                                        <label className="libelle-control" htmlFor="Immatriculation">Immatriculation*</label>
                                        {((item.vehicleId && !licencePlatesListSortedByVehicleTypeId.find(x => x.id === item.vehicleId)) && !isVehicleListOpened) &&
                                            <p onClick={() => handleVehicleListOpen(true, readOnly)}>
                                                {item.licencePlate + ' ' + item.vehicleTypeLabel}
                                            </p>
                                        }
                                        {(licencePlatesListSortedByVehicleTypeId.find(x => x.id === item.vehicleId) || !item.vehicleId || isVehicleListOpened) &&
                                            <Select
                                                id={'licencePlate' + index}
                                                name="licencePlate"
                                                isClearable
                                                isDisabled={disableChanges || identicalDrivers || readOnly || item.isCanceled}
                                                menuPosition="fixed"
                                                menuShouldBlockScroll={true}
                                                options={licencesPlatesOptions}
                                                placeholder="Choisir une immatriculation"
                                                ref={licenceRef}
                                                value={selectedLicencePlateValue}
                                                onChange={(event) => handleLicencePlateChange(licenceRef, event, item.planningVehicleId, item.isNightWork)}
                                                onBlur={(event) => handleLicencePlateBlur(licenceRef, (selectedLicencePlateValue?.value ?? -1) as number, item.planningVehicleId, item.isNightWork, item.vehicleId)}
                                            />
                                        }
                                    </Box>
                                    <Box width="35%" mt={1} mr={2} minWidth='260px' className="control-box">
                                        <label className="libelle-control" htmlFor="Chauffeur">Chauffeur*</label>
                                        {((item.driverId && !driversList.find(x => x.driverId === item.driverId)) && !isDriverListOpened) &&
                                            <p onClick={() => handleDriverListOpen(true, readOnly)}>
                                                {item.driverName}
                                            </p>
                                        }
                                        {(driversList.find(x => x.driverId === item.driverId) || !item.driverId || isDriverListOpened) &&
                                            <>
                                                <Select
                                                    id={'driver' + index}
                                                    name="driver"
                                                    components={{ Option }}
                                                    isClearable
                                                    isDisabled={disableChanges || vehicleTypeGroupDoesNotMatch || readOnly || item.isCanceled}
                                                    menuPosition="fixed"
                                                    menuShouldBlockScroll={true}
                                                    options={driversOptions}
                                                    placeholder="Choisir un chauffeur"
                                                    ref={driverRef}
                                                    value={selectedDriverValue ?? null}
                                                    onChange={(event: SelectOptionModel) => handleDriverChange(driverRef, event, item.planningVehicleId, item.isNightWork)}
                                                    onBlur={(event) => handleDriverBlur(driverRef, (selectedDriverValue?.value ?? null) as DriverLightModel, item.planningVehicleId, item.isNightWork, item.driverId)}
                                                />
                                            </>
                                        }
                                    </Box>
                                    <Box width="15%" mt={1} mr={2} minWidth='150px' className="control-box">
                                        <label className="libelle-control" htmlFor="telChauffeur">Tel chauffeur*</label>
                                        <input type="text" className="form-control"
                                            value={item.driverPhone !== null ? Utilities.formatPhoneNumber(item.driverPhone) : ''}
                                            onChange={(event) => handleDriverPhoneChange(event, item.planningVehicleId)}
                                            onBlur={(event) => handleDriverPhoneBlur(event)}
                                            disabled={readOnly || item.isCanceled}
                                        />
                                    </Box>
                                    <Box width="15%" mt={1} minWidth='150px' className="control-box">
                                        <label className="libelle-control" htmlFor="chargeUtil">Charge utile - kg*</label>
                                        <input type="text" className="form-control"
                                            value={item.loadCapacity !== null ? item.loadCapacity.toStringFixed('FR-fr') : ''}
                                            onChange={(event) => handleLoadCapacityChange(event, item.planningVehicleId)}
                                            onBlur={(event) => handleLoadCapacityBlur(event)}
                                            disabled={readOnly || item.isCanceled}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Box>
                    {
                        item.transportRequests && item.transportRequests.length > 0 ? (
                            <Accordion className="transporter-dc-expansionPanel" expanded={expandedPanelsTransportRequests.get(`panel-dc-${indexGroup}-${index}`)}
                                onChange={() => handleChange(`panel-dc-${indexGroup}-${index}`)}>
                                <AccordionSummary className="transporter-dc-expansionPanel-summary" expandIcon={<ExpandMoreIcon />}>
                                    <div className="container-expansionPanel-title">
                                        <span className="nb-vehicles">{item.transportRequests.length}</span>Flux associé(s) à ce camion
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className="transporter-dc-expansionPanel-details" style={{ overflowX: "auto" }}>
                                    <Box display="flex" flexDirection="column">
                                        {item.labeledTripsOrdered.length > 0 &&
                                            <Box display="flex" flexDirection="row" flexWrap="nowrap">
                                                <Box className="trip-order-title">
                                                    Ordre des tours:
                                                </Box>
                                                {!item.transportRequests.some(x => x.serviceKind === "JobsiteVehicle") &&
                                                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                                                        {[...item.labeledTripsOrdered].map((value, index) => {
                                                            return <Box display="flex" key={`trip-order-card-${index}`} flexDirection="row" className="trip-order-card" pr={1}>
                                                                <Box pr={0.2} className="trip-number">
                                                                    {value.number}
                                                                </Box>
                                                                <Box textAlign="center" className="trip-label">
                                                                    {value.label}
                                                                </Box>
                                                            </Box>
                                                        })}
                                                    </Box>
                                                }
                                            </Box>}
                                        <Box display="flex" flexDirection="row" flexWrap="wrap">
                                            {item.transportRequests.map((dcItem, dcIndex) =>
                                                <Card className="transporter-dc-card tr_dc_section" key={`tr-${index}-dc-${dcIndex}`}>
                                                    <CardContent className="transporter-card-content">
                                                        <Box key={dcIndex} p={1} display="flex" flexDirection="column" flexWrap="nowrap">
                                                            <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                                                <Box width="25%" pr={1} className="transporter-dc-details transporter-card-content-vehicle-type-title">
                                                                    {dcItem.businessId}
                                                                </Box>
                                                                <Box width="25%" pr={1} className="transporter-dc-details">
                                                                    <span className="title-field">Produit à transporter :</span>
                                                                    <span className='value-field'>{props.getValueField(dcItem.formule)}</span>
                                                                </Box>
                                                                <Box width="50%" display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" justifyContent="flex-end" className="transporter-dc-details">
                                                                    <Box pr={1} className="transporter-dc-details job-foreman">
                                                                        {props.getValueField(dcItem.jobForemanName)}
                                                                    </Box>
                                                                    {dcItem.jobForemanTel ?
                                                                        <Box className="transporter-dc-details phone-number" display="flex" alignItems="center" justifyContent="flex-end" flexDirection="row">
                                                                            <Box pr={1} className="phone-number-ref">
                                                                                <a href={role === "TRA" ? `tel:${dcItem.jobForemanTel}` : `callTo:${dcItem.jobForemanTel}`} data-rel="external">
                                                                                    {Utilities.formatPhoneNumber(dcItem.jobForemanTel)}
                                                                                </a>
                                                                            </Box>
                                                                            <FontAwesomeIcon size="lg" icon={faPhoneAlt} className="icon-phone-number" />
                                                                        </Box> : ''}
                                                                </Box>
                                                            </Box>
                                                            <Box display="flex" pt={2} flexDirection="row" flexWrap="wrap">
                                                                <Box width="70%" display="flex" flexDirection="column">
                                                                    <Box pr={2}>
                                                                        <div className="title-field">
                                                                            Site départ
                                                                        </div>
                                                                        <div className="value-field">
                                                                            {`${dcItem.senderSiteLabel}${(dcItem.senderSiteKind === "jobSite" && dcItem.senderSiteAgencyLabel) ? (' - ' + dcItem.senderSiteAgencyLabel) : ''}${(dcItem.senderSiteKind === "jobSite" && dcItem.senderSiteJobSiteLabel) ? (' - ' + dcItem.senderSiteJobSiteLabel) : ''}`}
                                                                        </div>
                                                                    </Box>
                                                                    <Box pr={2}>
                                                                        <div className="title-field">
                                                                            Adresse départ
                                                                        </div>
                                                                        <div className="value-field">
                                                                            {`${dcItem.pickupAddressLine1 ? (dcItem.pickupAddressLine1 + ' ') : ''}`}{`${dcItem.pickupAddressLine2 ? dcItem.pickupAddressLine2 : ''}`}
                                                                            {`${dcItem.pickupAddressZipCode && dcItem.pickupAddressCity ? ' - ' + dcItem.pickupAddressZipCode + ' ' + dcItem.pickupAddressCity : ''}`}
                                                                            {`${dcItem.pickupAddressZipCode && !dcItem.pickupAddressCity ? ' - ' + dcItem.pickupAddressZipCode : ''}`}
                                                                            {`${!dcItem.pickupAddressZipCode && dcItem.pickupAddressCity ? ' - ' + dcItem.pickupAddressCity : ''}`}
                                                                        </div>
                                                                    </Box>
                                                                </Box>
                                                                <Box width="30%" className='transporter-dc-details'>
                                                                    <div className="title-field">
                                                                        1<sup>er</sup> chargement
                                                                    </div>
                                                                    <div className="value-field">
                                                                        {`${dcItem.firstStartTime === null ? '' : Date.getTimeFromIsoString(dcItem.firstStartTime)}`}
                                                                    </div>
                                                                </Box>
                                                            </Box>
                                                            <Box display="flex" pt={1} flexDirection="row" flexWrap="wrap">
                                                                <Box width="70%" display="flex" flexDirection="column">
                                                                    <Box pr={2} className='transporter-dc-details'>
                                                                        <div className="title-field">
                                                                            Site arrivée
                                                                        </div>
                                                                        <div className="value-field">
                                                                            {`${dcItem.receiverSiteLabel}${(dcItem.receiverSiteKind === "jobSite" && dcItem.receiverSiteAgencyLabel) ? (' - ' + dcItem.receiverSiteAgencyLabel) : ''}${(dcItem.receiverSiteKind === "jobSite" && dcItem.receiverSiteJobSiteLabel) ? (' - ' + dcItem.receiverSiteJobSiteLabel) : ''}`}
                                                                        </div>
                                                                    </Box>
                                                                    <Box pr={2} className='transporter-dc-details'>
                                                                        <div className="title-field">
                                                                            Adresse arrivée
                                                                        </div>
                                                                        <div className="value-field">
                                                                            {`${dcItem.deliveryAddressLine1 ? (dcItem.deliveryAddressLine1 + ' ') : ''}`}{`${dcItem.deliveryAddressLine2 ? (dcItem.deliveryAddressLine2) : ''}`}
                                                                            {`${dcItem.deliveryAddressZipCode && dcItem.deliveryAddressCity ? ' - ' + dcItem.deliveryAddressZipCode + ' ' + dcItem.deliveryAddressCity : ''}`}
                                                                            {`${dcItem.deliveryAddressZipCode && !dcItem.deliveryAddressCity ? ' - ' + dcItem.deliveryAddressZipCode : ''}`}
                                                                            {`${!dcItem.deliveryAddressZipCode && dcItem.deliveryAddressCity ? ' - ' + dcItem.deliveryAddressCity : ''}`}
                                                                        </div>
                                                                    </Box>
                                                                </Box>
                                                                {dcItem.serviceKind !== "JobsiteVehicle" && <Box width="15%" className='transporter-dc-details'>
                                                                    <div className="title-field">
                                                                        Tonnage estimé
                                                                    </div>
                                                                    <div className="value-field">
                                                                        {dcItem.estimatedTonnage}
                                                                    </div>
                                                                </Box>}
                                                                <Box width="15%" className='transporter-dc-details'>
                                                                    <div className="title-field">
                                                                        {dcItem.serviceKind === "JobsiteVehicle" ? "Durée sur chantier" : "Tours estimés"}
                                                                    </div>
                                                                    <div className="value-field">
                                                                        {dcItem.serviceKind === "JobsiteVehicle" ? dcItem.unloadingDuration : dcItem.tripsNumber}
                                                                    </div>
                                                                </Box>
                                                            </Box>
                                                            <Box display="flex" pt={1} flexDirection="row" flexWrap="wrap">
                                                                <Box width="70%">
                                                                    {dcItem && dcItem.instructions && (dcItem.instructions.length > 0 || (dcItem.transportersInstructions && dcItem.transportersInstructions !== '')) ?
                                                                        <Box display="flex" flexDirection="row" flexWrap="nowrap">
                                                                            <Box pr={2} width="100%">
                                                                                <div className="title-field">
                                                                                    Instructions
                                                                                </div>
                                                                                <Box width="100%" className="value-field">
                                                                                    {<InstructionsComponent item={dcItem} />}
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                        : ''
                                                                    }
                                                                </Box>
                                                                {dcItem.serviceKind !== "JobsiteVehicle" && <Box width="30%" className='transporter-dc-details'>
                                                                    <span className="title-field">
                                                                        Ordre des tours:
                                                                    </span>
                                                                    <span className="value-field">
                                                                        {dcItem.tripsOrderNumbers.join('; ')}
                                                                    </span>
                                                                </Box>}
                                                            </Box>
                                                            <Box display="flex" pt={1} flexDirection="row" flexWrap="wrap">
                                                                {dcItem.transportFlowAttachments.length > 0 && dcItem.transportFlowAttachments.map((file: TransportFlowAttachmentFileLightModel, index: number) => {
                                                                    return (
                                                                        <Box key={index} pr={1} className="attachment-name" onClick={() => getAttachment(file.attachmentId)}>{file.name}</Box>
                                                                    )
                                                                })}
                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            )}
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ) : ''
                    }
                </Box>
            </CardContent>
        </Card>
    );
}