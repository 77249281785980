import { faCheck, faCheckDouble, faMinus, fas, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface PlanningVehicleCostsProperties {
    costsArePublished: boolean,
    costsAreAgreed: boolean,
    costsDisagreementReason: string
}

export const PlanningVehicleCosts = (props: PlanningVehicleCostsProperties) => {

    let ret = <div></div>;
    if (!props.costsArePublished) {
        return <FontAwesomeIcon className="planning-vehicle_costs-are-not-published" size="lg" icon={faMinus} />;
    }
    else {
        if (props.costsAreAgreed === null) {
            ret = <FontAwesomeIcon className="planning-vehicle_costs-are-published" size="lg" icon={fas.faClock} />;
        }
        else {
            if (props.costsAreAgreed === true) {
                if (props.costsDisagreementReason) {
                    ret = <FontAwesomeIcon className="planning-vehicle_costs-are-agreed" size="lg" icon={faCheckDouble} />;
                }
                else {
                    ret = <FontAwesomeIcon className="planning-vehicle_costs-are-agreed" size="lg" icon={faCheck} />;
                }
            }
            else {
                ret = <FontAwesomeIcon className="planning-vehicle_costs-are-not-agreed" size="lg" icon={faTimes} />;
            }
        }
    }
    return <div title={props.costsDisagreementReason}>
        {ret}
    </div>;
}

export const getCostsArePublishedOrderValue = (costsArePublished: boolean, costsAreAgreed: boolean): number => {
    if (costsArePublished && costsAreAgreed === false)
        return 0;

    if (costsArePublished && costsAreAgreed === null)
        return 1;

    if (costsArePublished && costsAreAgreed)
        return 2;

    if (costsArePublished)
        return 3;

    return 4;
}