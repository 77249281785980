import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Drawer, IconButton, TextField, Tooltip } from '@mui/material';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import Utilities from 'src/utils/Utilities';
import { FeatureEntityDataModel } from '../models/FeatureEntityDataModel';
import { VehicleTypeGroupId } from '../models/VehicleTypeGroupId';
import { CartographyApiClient } from '../services/CartographyApiClient';
import { SiteKind } from '../services/dataContracts/queryStack/SiteKind';
import { TransportPriority } from '../services/dataContracts/queryStack/TransportPriority';


interface DetailsDrawerComponentProps {
    positionCLickedId: string,
    isDawerDetailsOpened: boolean,
    data: FeatureEntityDataModel,
    updateSiteBulletinBoardRemarks: (id: string, remarks: string) => void
}

export const DetailsDrawerComponent = (props: DetailsDrawerComponentProps): JSX.Element => {
    const { positionCLickedId, isDawerDetailsOpened, data } = props;

    const txtRemarksRef: React.RefObject<HTMLTextAreaElement> = React.useRef(null);

    const [isTxtRemarksEditable, setIsTxtRemarksEditable] = useState<boolean>(false);

    useEffect(() => {
        if (txtRemarksRef && txtRemarksRef.current) {
            txtRemarksRef.current.value = data.siteBulletinBoardRemarks;
        }
    }, [data.siteBulletinBoardRemarks, data.siteId]);

    useEffect(() => {
        if (isTxtRemarksEditable)
            txtRemarksRef.current.focus();
    }, [isTxtRemarksEditable]);

    const getAdressPart1 = (line1: string, line2: string): string => {
        let result = "";

        if (line1 && line2)
            result += line1 + ", " + line2;

        if (line1 && !line2)
            result += line1;

        if (!line1 && line2)
            result += line2;

        return result;
    }

    const getAdressPart2 = (city: string, zipCode: string): string => {
        let result = "";

        if (city && zipCode)
            result += zipCode + " " + city;

        if (city && !zipCode)
            result += city;

        if (!city && zipCode)
            result += zipCode;

        return result;
    }

    const handleEditRemarks = (value: boolean): void => {
        const remarks = txtRemarksRef.current.value;
        if (!value && data.siteBulletinBoardRemarks !== remarks && data.siteBulletinBoardId) {
            CartographyApiClient.AddOrUpdateSiteBulletinBoard(data.siteBulletinBoardId, remarks)
                .then(() => {
                    props.updateSiteBulletinBoardRemarks(data.siteId, remarks);
                });
        }
        setIsTxtRemarksEditable(value);
    }

    const highDeliveryQuantity = data.deliveryQuantityByPriority ? data.deliveryQuantityByPriority.get(TransportPriority.high) : 0;
    const mediumDeliveryQuantity = data.deliveryQuantityByPriority ? data.deliveryQuantityByPriority.get(TransportPriority.medium) : 0;
    const lowDeliveryQuantity = data.deliveryQuantityByPriority ? data.deliveryQuantityByPriority.get(TransportPriority.low) : 0;

    const highLoadingQuantity = data.loadingQuantityByPriority ? data.loadingQuantityByPriority.get(TransportPriority.high) : 0;
    const mediumLoadingQuantity = data.loadingQuantityByPriority ? data.loadingQuantityByPriority.get(TransportPriority.medium) : 0;
    const lowLoadingQuantity = data.loadingQuantityByPriority ? data.loadingQuantityByPriority.get(TransportPriority.low) : 0;

    const planningVehiclesCountByGroupGridData = data.planningVehiclesCountByGroup ?
        [{
            [`${VehicleTypeGroupId.Id4x2}`]: data.planningVehiclesCountByGroup.get(VehicleTypeGroupId.Id4x2) > 0 ? data.planningVehiclesCountByGroup.get(VehicleTypeGroupId.Id4x2) : "",
            [`${VehicleTypeGroupId.Id6x4}`]: data.planningVehiclesCountByGroup.get(VehicleTypeGroupId.Id6x4) > 0 ? data.planningVehiclesCountByGroup.get(VehicleTypeGroupId.Id6x4) : "",
            [`${VehicleTypeGroupId.Id8x4}`]: data.planningVehiclesCountByGroup.get(VehicleTypeGroupId.Id8x4) > 0 ? data.planningVehiclesCountByGroup.get(VehicleTypeGroupId.Id8x4) : "",
            [`${VehicleTypeGroupId.IdSemi}`]: data.planningVehiclesCountByGroup.get(VehicleTypeGroupId.IdSemi) > 0 ? data.planningVehiclesCountByGroup.get(VehicleTypeGroupId.IdSemi) : "",
            [`${VehicleTypeGroupId.Others}`]: data.planningVehiclesCountByGroup.get(VehicleTypeGroupId.Others) > 0 ? data.planningVehiclesCountByGroup.get(VehicleTypeGroupId.Others) : ""
        }] : [];

    const jobsiteVehicle4X2Count = data.jobsiteVehiclesCountByGroup ? data.jobsiteVehiclesCountByGroup.get(VehicleTypeGroupId.Id4x2) : 0;
    const jobsiteVehicle6X4Count = data.jobsiteVehiclesCountByGroup ? data.jobsiteVehiclesCountByGroup.get(VehicleTypeGroupId.Id6x4) : 0;
    const jobsiteVehicle8X4Count = data.jobsiteVehiclesCountByGroup ? data.jobsiteVehiclesCountByGroup.get(VehicleTypeGroupId.Id8x4) : 0;
    const jobsiteVehicleSemiCount = data.jobsiteVehiclesCountByGroup ? data.jobsiteVehiclesCountByGroup.get(VehicleTypeGroupId.IdSemi) : 0;
    const jobsiteVehicleOthersCount = data.jobsiteVehiclesCountByGroup ? data.jobsiteVehiclesCountByGroup.get(VehicleTypeGroupId.Others) : 0;

    return (
        <Drawer
            classes={{
                paper: positionCLickedId && isDawerDetailsOpened ? "details-drawer opened" : "details-drawer"
            }}
            variant="persistent"
            anchor="right"
            open={true}
        >
            <Box display="flex" flexDirection="column" className="details-drawer-content">
                <Box>
                    <Box display="flex" flexDirection="column" mb="10px">
                        <Box className="title">
                            Type de site
                        </Box>
                        <Box className="label">
                            {data.siteKindLabel}
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" mb="10px">
                        <Box className="title">
                            Site
                        </Box>
                        <Box className="label">
                            {data.siteLabel}
                        </Box>
                        {data.siteKind === SiteKind.jobSite &&
                            <Box className="label">
                                {data.siteAgencyLabel}
                            </Box>
                        }
                    </Box>
                    <Box display="flex" flexDirection="column" mb="10px" mr="38px">
                        <Box className="title">
                            Adresse du site
                        </Box>
                        <Box className="label">
                            {getAdressPart1(data.line1, data.line2)}
                        </Box>
                        <Box className="label">
                            {getAdressPart2(data.city, data.zipCode)}
                        </Box>
                    </Box>
                    {data.siteKind === SiteKind.transporter && (
                        <Box display="flex" flexDirection="column" mb="10px">
                            <Box className="title">
                                Contact :
                            </Box>
                            {data.contacts?.length > 0 && 
                                <>
                                    <div className="label">
                                        {data.contacts[0].email?.split(",").map((e, index) => 
                                            <div key={`email-contact-${index}`} className="label">
                                                {e}
                                            </div>
                                        )}
                                    </div>
                                    <div className="label">
                                        {data.contacts[0].phoneNumber && <a href={`callTo:${data.contacts[0].phoneNumber}`} data-rel="external">
                                            {Utilities.formatPhoneNumber(data.contacts[0].phoneNumber)}
                                        </a>}
                                    </div>
                                </>
                            }
                        </Box>
                    )}
                    {data.siteKind === SiteKind.agency && (
                        <Box display="flex" flexDirection="column" mb="10px">
                            <Box className="title">
                                Contacts :
                            </Box>
                            {data.contacts?.map((contact, index) => 
                                <Box pb={1} key={`agency-contact-${index}`}>
                                    <Box className="label">
                                        {contact.fullName}
                                    </Box>
                                    <div className="label">
                                        {contact.email}
                                    </div>
                                    <div className="label">
                                        {contact.phoneNumber && <a href={`callTo:${contact.phoneNumber}`} data-rel="external">
                                            {Utilities.formatPhoneNumber(contact.phoneNumber)}
                                        </a>}
                                    </div>
                                </Box>
                            )}
                        </Box>
                    )}
                    <Box display="flex" flexDirection="column" mb="10px">
                        <Box className="title">
                            Bénéficiaire :
                        </Box>
                        {data.beneficiaries?.map((b, index) => {
                            return (<Box className="label" key={`beneficiary-${index}`} display="flex" flexDirection="row" justifyContent="space-between">
                                <div>{b.beneficiaryName}</div>
                                {b.phoneNumber && <a href={`callTo:${b.phoneNumber}`} data-rel="external">
                                    {Utilities.formatPhoneNumber(b.phoneNumber)}
                                </a>}
                            </Box>)
                        })}
                    </Box>
                    {data.siteKind !== SiteKind.transporter && data.siteKind !== SiteKind.agency ?
                        (<>
                            <Box display="flex" flexDirection="column" mb="10px">
                                <Box className="title">
                                    Livraison(s)
                                </Box>
                                <Box className="label">
                                    {`Fixe : ${(highDeliveryQuantity && highDeliveryQuantity > 0 ? highDeliveryQuantity + `T` : '')}`}
                                </Box>
                                <Box className="label">
                                    {`Journée : ${(mediumDeliveryQuantity && mediumDeliveryQuantity > 0 ? mediumDeliveryQuantity + `T` : '')}`}
                                </Box>
                                <Box className="label">
                                    {`Modulable : ${(lowDeliveryQuantity && lowDeliveryQuantity > 0 ? lowDeliveryQuantity + `T` : '')}`}
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" mb="10px">
                                <Box className="title">
                                    Chargements(s)
                                </Box>
                                <Box className="label">
                                    {`Fixe : ${(highLoadingQuantity && highLoadingQuantity > 0 ? highLoadingQuantity + `T` : '')}`}
                                </Box>
                                <Box className="label">
                                    {`Journée : ${(mediumLoadingQuantity && mediumLoadingQuantity > 0 ? mediumLoadingQuantity + `T` : '')}`}
                                </Box>
                                <Box className="label">
                                    {`Modulable : ${(lowLoadingQuantity && lowLoadingQuantity > 0 ? lowLoadingQuantity + `T` : '')}`}
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" mb="10px">
                                <Box className="title">
                                    Camions(s) de chantier
                                </Box>
                                {jobsiteVehicle4X2Count > 0 &&
                                    <Box className="label">
                                        {`4X2 : ${jobsiteVehicle4X2Count}`}
                                    </Box>
                                }
                                {jobsiteVehicle6X4Count > 0 &&
                                    <Box className="label">
                                        {`6X4 : ${jobsiteVehicle6X4Count}`}
                                    </Box>
                                }
                                {jobsiteVehicle8X4Count > 0 &&
                                    <Box className="label">
                                        {`8X4 : ${jobsiteVehicle8X4Count}`}
                                    </Box>
                                }
                                {jobsiteVehicleSemiCount > 0 &&
                                    <Box className="label">
                                        {`SEMI : ${jobsiteVehicleSemiCount}`}
                                    </Box>
                                }
                                {jobsiteVehicleOthersCount > 0 &&
                                    <Box className="label">
                                        {`Autres : ${jobsiteVehicleOthersCount}`}
                                    </Box>
                                }
                            </Box>
                            <Box display="flex" flexDirection="column" mb="10px">
                                <Box className="title">
                                    Camion(s) prévu(s)
                                </Box>
                                <Box>
                                    <LocalizationProvider language="fr-FR">
                                        <IntlProvider locale="fr" >
                                            <Grid
                                                className="planning-vehicles-count-by-group-grid"
                                                rowHeight={40}
                                                data={planningVehiclesCountByGroupGridData}
                                                scrollable="none"
                                            >
                                                <GridColumn headerClassName="grid-header" field={`${VehicleTypeGroupId.Id4x2}`} title="4X2" width="83" />
                                                <GridColumn headerClassName="grid-header" field={`${VehicleTypeGroupId.Id6x4}`} title="6X4" width="83" />
                                                <GridColumn headerClassName="grid-header" field={`${VehicleTypeGroupId.Id8x4}`} title="8X4" width="83" />
                                                <GridColumn headerClassName="grid-header" field={`${VehicleTypeGroupId.IdSemi}`} title="SEMI" width="83" />
                                                <GridColumn headerClassName="grid-header" field={`${VehicleTypeGroupId.Others}`} title="Autres" width="83" />
                                            </Grid>
                                        </IntlProvider>
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                        </>)
                        : (
                            <Box display="flex" flexDirection="column" mb="10px">
                                <Box className="title">
                                    Flotte du transporteur
                                </Box>
                                <Box>
                                    <LocalizationProvider language="fr-FR">
                                        <IntlProvider locale="fr" >
                                            <Grid
                                                className="planning-vehicles-count-by-group-grid"
                                                rowHeight={40}
                                                data={planningVehiclesCountByGroupGridData}
                                                scrollable="none"
                                            >
                                                <GridColumn headerClassName="grid-header" field={`${VehicleTypeGroupId.Id4x2}`} title="4X2" width="83" />
                                                <GridColumn headerClassName="grid-header" field={`${VehicleTypeGroupId.Id6x4}`} title="6X4" width="83" />
                                                <GridColumn headerClassName="grid-header" field={`${VehicleTypeGroupId.Id8x4}`} title="8X4" width="83" />
                                                <GridColumn headerClassName="grid-header" field={`${VehicleTypeGroupId.IdSemi}`} title="SEMI" width="83" />
                                                <GridColumn headerClassName="grid-header" field={`${VehicleTypeGroupId.Others}`} title="Autres" width="83" />
                                            </Grid>
                                        </IntlProvider>
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                        )
                    }
                    {data.siteKind !== SiteKind.unreferencedCustomer &&
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Box width="100%">
                                <TextField
                                    label="Commentaire"
                                    margin="normal"
                                    multiline
                                    variant="outlined"
                                    rows="4"
                                    className="remarks"
                                    disabled={!isTxtRemarksEditable}
                                    onBlur={() => handleEditRemarks(false)}
                                    inputRef={txtRemarksRef}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="flex-end">
                                <Tooltip title="Saisir dans le champs Commentaire">
                                    <IconButton size="small" className="edit-button" onClick={() => handleEditRemarks(true)}>
                                        <FontAwesomeIcon size="1x" className="pen-icon" icon={faPen} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
        </Drawer>
    );
}
