import { Box } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { UploadOnBeforeUploadEvent, UploadResponse } from '@progress/kendo-react-upload';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ImportErrorsExtraResult, LineImportErrors } from 'src/shared/models/ImportErrorsExtraResult';
import { WebAppActionResultEx } from 'src/shared/models/WebAppActionResult';
import ToastService from 'src/ToastService';
import { ScaleLoaderComponent } from '../../../shared/components/Common/ScaleLoaderComponent';
import BusinessErrors from '../../../utils/BusinessErrors';
import '../ReferentialsStyles.scss';
import { HeaderContentComponent } from './components/HeaderContentComponent';
import { Level3SegmentationsComponent } from './components/Level3SegmentationsComponent';
import './Level3SegmentationsReferentialStyles.scss';
import { Level3SegmentationLightModel } from './services/dataContracts/queryStack/Level3SegmentationLightModel';
import { LogisticsUnitChoiceOfLevel3SegmentationsLightModel } from './services/dataContracts/queryStack/LogisticsUnitChoiceOfLevel3SegmentationsLightModel';
import { Level3SegmentationsReferentialApiClient } from './services/Level3SegmentationsReferentialApiClient';

export const Level3SegmentationsReferentialView = () => {

    const inputSearchLevel3SegmentationsRef: React.RefObject<HTMLInputElement> = React.useRef(null);
    const initialSort: SortDescriptor[] = [{ field: 'id', dir: 'asc' }];

    const [level3SegmentationsList, setLevel3SegmentationsList] = useState<Array<Level3SegmentationLightModel>>([]);
    const [level3SegmentationChoicesLogisticsUnit, setLevel3SegmentationChoicesLogisticsUnit] = useState<Array<LogisticsUnitChoiceOfLevel3SegmentationsLightModel>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [sort, setSort] = useState<Array<SortDescriptor>>(initialSort);

    useEffect(() => {
        getLevel3Segmentations("");
    }, []);

    const clearSearchText = (): void => {
        inputSearchLevel3SegmentationsRef.current.value = "";
        level3SegmentationsKeyPressed("");
    }

    const handleLevel3SegmentationsKeyPressed = debounce((text: string): void => {
        if (text.length >= 3 || text.length === 0) {
            level3SegmentationsKeyPressed(text);
        }
    }, 500);

    const level3SegmentationsKeyPressed = (text: string): void => {
        getLevel3Segmentations(text);
    }

    const getLevel3Segmentations = (searchText: string, errorMessage?: string, listErrorMessage?: Array<string>, successMessage?: string, endMessage?: string): void => {
        setLoading(true);
        Level3SegmentationsReferentialApiClient.GetLevel3SegmentationsWithLevel3SegmentationChoicesLogisticsUnit(searchText)
            .then(response => {
                setLevel3SegmentationsList(response[0].data);
                setLevel3SegmentationChoicesLogisticsUnit(response[1].data);

                if (errorMessage)
                    ToastService.showErrorToast(errorMessage, listErrorMessage, endMessage);
                else if (successMessage)
                    ToastService.showSuccessToast(successMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const handleSortColumnChange = (sortItems: SortDescriptor[]): void => {
        setSort(sortItems);
    }

    const inputSearchLevel3SegmentationsValue = inputSearchLevel3SegmentationsRef.current === null || inputSearchLevel3SegmentationsRef.current === undefined ? '' : inputSearchLevel3SegmentationsRef.current.value;

    const handlerAfterUpload = (response: UploadResponse): void => {
        const responseResult: WebAppActionResultEx<ImportErrorsExtraResult> = response ? response.response : null;
        const listErrorMessage: string[] = [];
        let errorMessage = '';
        let successMessage = '';
        let endMessage = '';

        if (response.status === 200 && responseResult) {
            const importLines: Array<LineImportErrors> = responseResult.extraResult.linesWithErrors;
            if (importLines.length > 0) {
                importLines.forEach(e => {
                    e.errors.forEach(eError => {
                        let libMsg = BusinessErrors.GetError(eError);
                        if (!libMsg && eError)
                            libMsg = eError;
                        listErrorMessage.push(`- Ligne ${e.rowIndex} - ${libMsg}`);
                    });
                });

                errorMessage = 'Erreur lors de l\'import de la visibilité des segmentations niveau 3:';
                endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des segmentations niveau 3 sur vos zones logistiques';

            } 
            else{
                successMessage = 'La visibilité des segmentations niveau 3 pour vos zones logistiques a bien été intégrée';
            }
        } 
        else {
            errorMessage = 'Erreur lors de l\'import de la visibilité des segmentations niveau 3, veuillez vérifier votre fichier';
            endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des segmentations niveau 3 sur vos zones logistiques';
            successMessage = '';
        }
        getLevel3Segmentations(inputSearchLevel3SegmentationsValue, errorMessage, listErrorMessage, successMessage, endMessage);
    }

    const displayErrorResponse = (): void => {
        const errorMessage = 'Erreur lors de l\'import de la visibilité des segmentations niveau 3, veuillez vérifier votre fichier';
        const endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des segmentations niveau 3 sur vos zones logistiques';
        const successMessage = '';
        getLevel3Segmentations(inputSearchLevel3SegmentationsValue, errorMessage, [], successMessage, endMessage);
    }

    const handlerBeforeUpload = (e: UploadOnBeforeUploadEvent): void => {
        setLoading(true);
    }

    const headerContentComponent: JSX.Element = useMemo(() =>
        <HeaderContentComponent
            inputSearchLevel3SegmentationsValue={inputSearchLevel3SegmentationsValue}
            inputSearchLevel3SegmentationsRef={inputSearchLevel3SegmentationsRef}
            sort={sort}
            handleLevel3SegmentationsKeyPress={handleLevel3SegmentationsKeyPressed}
            handleClearSearchText={clearSearchText}
            handlerAfterUpload={handlerAfterUpload}
            handlerBeforeUpload={handlerBeforeUpload}
            displayErrorResponse={displayErrorResponse}
        />, [inputSearchLevel3SegmentationsValue, inputSearchLevel3SegmentationsRef, sort]);

    const level3SegmentationsComponent: JSX.Element = useMemo(() =>
        <Level3SegmentationsComponent
            level3Segmentations={level3SegmentationsList}
            level3SegmentationChoicesLogisticsUnit={level3SegmentationChoicesLogisticsUnit}
            sort={sort}
            handleSortChange={handleSortChange}
            handleSortColumnChange={handleSortColumnChange}
            />, [level3SegmentationsList, level3SegmentationChoicesLogisticsUnit, sort]);

    return (
        <Box className="level3segmentations referencial">
            <Box display="flex" flexDirection="row" flex="wrap" className="view-title">
                Gestion des segmentations niveau 3
            </Box>
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box display="flex" flexDirection="column" flex="wrap">
                        {headerContentComponent}
                        {(loading ?
                            <ScaleLoaderComponent loading={loading} />
                            :
                            level3SegmentationsComponent
                        )}
                    </Box>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}
