import axios, { AxiosResponse } from 'axios';
import { PagedResult } from '../../../shared/models/PagedResult';
import { WebAppActionResult } from '../../../shared/models/WebAppActionResult';
import '../../../utils/Date';
import { ReservationDetailsToSendRequestArgs } from './dataContracts/controller/ReservationDetailsToSendRequestArgs';
import { TransporterReservationRequestArgs } from './dataContracts/controller/TransporterReservationRequestArgs';
import { NeededVehicleDetailLightModel } from './dataContracts/queryStack/NeededVehicleDetailLightModel';
import { NeededVehiclesWeeklySummaryLightModel } from './dataContracts/queryStack/NeededVehiclesWeeklySummaryLightModel';
import { TransporterLightModel } from './dataContracts/queryStack/TransporterLightModel';
import { VehicleReservationDetailLightModel } from './dataContracts/queryStack/VehicleReservationDetailLightModel';
import { VehicleTypeLightModel } from './dataContracts/queryStack/VehicleTypeLightModel';

const controllerUrl = 'api/Reservation/';

export class ReservationApiClient {

    public static LoadData(fromDate: Date, logisticsUnits: Array<string>)
        : Promise<[AxiosResponse<PagedResult<NeededVehiclesWeeklySummaryLightModel>>,
            AxiosResponse<Array<TransporterLightModel>>,
            AxiosResponse<PagedResult<VehicleReservationDetailLightModel>>,
            AxiosResponse<PagedResult<NeededVehicleDetailLightModel>>,
            AxiosResponse<Array<VehicleTypeLightModel>>]> {
        return Promise.all(
            [
                this.GetNeededVehiclesWeeklySummaries(fromDate, logisticsUnits),
                this.GetTransportersList(logisticsUnits),
                this.GetVehiclesReservationsDetails(fromDate, logisticsUnits),
                this.GetNeededVehiclesWeekDetails(fromDate, logisticsUnits),
                this.GetVehicleTypesList()
            ]);
    }

    public static GetNeededVehiclesWeeklySummaries = (fromDate: Date, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<PagedResult<NeededVehiclesWeeklySummaryLightModel>>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        return axios.post(`${controllerUrl}GetNeededVehiclesWeeklySummaries?fromDate=${encodeURIComponent(fromDate.toJSON())}`, JSON.stringify(logisticsUnits), { headers: _headers });
    }

    public static GetNeededVehiclesWeekDetails = (fromDate: Date, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<PagedResult<NeededVehicleDetailLightModel>>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        return axios.post(`${controllerUrl}GetNeededVehiclesWeekDetails?fromDate=${encodeURIComponent(fromDate.toJSON())}`, JSON.stringify(logisticsUnits), { headers: _headers });
    }

    public static GetTransportersList = (logisticsUnits: Array<string>)
        : Promise<AxiosResponse<Array<TransporterLightModel>>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        return axios.post(`${controllerUrl}GetTransportersList`, JSON.stringify(logisticsUnits), { headers: _headers });
    }

    public static SetReservedVehicles = (requestArgs: TransporterReservationRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'SetReservedVehicles',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(
                {
                    fromDate: requestArgs.fromDate.toJsonTimezoned(),
                    transporterId: requestArgs.transporterId,
                    vehicleTypeId: requestArgs.vehicleTypeId,
                    vehicleNumbers: requestArgs.vehicleNumbers,
                    isNightWork: requestArgs.isNightWork
                })
        });
    }

    public static UnsetReservedVehicles = (requestArgs: TransporterReservationRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'UnsetReservedVehicles',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(
                {
                    fromDate: requestArgs.fromDate.toJsonTimezoned(),
                    transporterId: requestArgs.transporterId,
                    vehicleTypeId: requestArgs.vehicleTypeId,
                    vehicleNumbers: requestArgs.vehicleNumbers,
                    isNightWork: requestArgs.isNightWork
                })
        });
    }

    public static GetVehiclesReservationsDetails = (fromDate: Date, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<PagedResult<VehicleReservationDetailLightModel>>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        return axios.post(`${controllerUrl}GetVehiclesReservationsDetails?fromDate=${encodeURIComponent(fromDate.toJSON())}`, JSON.stringify(logisticsUnits), { headers: _headers });
    }

    public static ConfirmTransporterReservation = (reservationDetailsToSend: ReservationDetailsToSendRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'ConfirmTransporterReservation',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(reservationDetailsToSend)
        });
    }

    public static GetVehicleTypesList = ()
        : Promise<AxiosResponse<Array<VehicleTypeLightModel>>> => {
        return axios.get(`${controllerUrl}GetVehicleTypesList`);
    }

}
