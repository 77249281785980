import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import Utilities from '../../../utils/Utilities';
import { AddedVehicleTypeToReservationModel } from '../models/AddedVehicleTypeToReservationModel';
import { NeededVehicleModel } from '../models/NeededVehicleModel';
import { ReservedVehicleTypeValueModel } from '../models/ReservedVehicleTypeValueModel';
import { TotalNeededModel } from '../models/TotalNeededModel';
import { TransporterReservationsModel } from '../models/TransporterReservationsModel';
import { VehicleNeededByDayModel } from '../models/VehicleNeededByDayModel';
import { WeeklyRequestedByVehicleTypeIdModel } from '../models/WeeklyRequestedByVehicleTypeIdModel';
import { NeededVehicleDetailLightModel } from '../services/dataContracts/queryStack/NeededVehicleDetailLightModel';
import { VehicleReservationDetailLightModel } from '../services/dataContracts/queryStack/VehicleReservationDetailLightModel';
import { VehicleTypeLightModel } from '../services/dataContracts/queryStack/VehicleTypeLightModel';

interface ReservationListComponentProps {
    weeklyRequestedByVehicleTypeIdArray: Array<WeeklyRequestedByVehicleTypeIdModel>,
    firstDayInSelectedWeek: Date,
    selectedTransporter: string,
    onReservationBlur: (from: Date, transporterId: string, vehicleTypeId: string, event: React.FocusEvent<HTMLInputElement>) => void,
    weeklyTransporterVehicleReservationsDetailsArray: Array<VehicleReservationDetailLightModel>,
    isNightFilterSelected: boolean,
    allVehicleReservations: Array<VehicleReservationDetailLightModel>,
    neededVehicleDetailByWeekArray: Array<NeededVehicleDetailLightModel>,
    isOpenVehicleTypeIdlist: boolean,
    vehicleTypesList: Array<VehicleTypeLightModel>,
    handleOpenCloseVehicleTypeIdlist: () => void,
    onChangeReservationValue: (event: React.ChangeEvent<HTMLInputElement>, vehicleTypeId: string, date: Date) => void,
    getReservedTransporterVehiclesNumber: (date: Date, vehicleTypeId: string) => number,
    getTotalNeededByDate: (date: Date) => TotalNeededModel,
    getAllReservedVehiclesNumber: (date: Date, vehicleTypeId: string) => number,
    getTotalReservedByDate: (date: Date) => number,
    getTotalReservedByDateWithoutSelectedTransporter: (date: Date) => number,
    addedVehicleTypesToReservation: Array<AddedVehicleTypeToReservationModel>,
    onChangeAddedReservationValue: (event: React.ChangeEvent<HTMLInputElement>, vehicleTypeId: string, date: Date) => void
}

export const ReservationListComponent = (props: ReservationListComponentProps): JSX.Element => {
    const [currentInitialInputValue, setCurrentInitialInputValue] = useState<number>(0);

    const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        const characterCode: string = e.key;
        if (characterCode === 'Backspace' || characterCode === 'ArrowUp' || characterCode === 'ArrowDown' || characterCode === 'ArrowLeft' || characterCode === 'ArrowRight')
            return;
        const characterNumber = Number(characterCode);
        if (characterNumber >= 0 && characterNumber <= 9) {
            if (e.currentTarget.value && e.currentTarget.value.length) {
                return;
            } else if (characterNumber === 0) {
                e.preventDefault();
            }
        } else {
            e.preventDefault();
        }
    }

    const getVehiclesNeededNumberByDayAndByVehicleType = (date: Date, vehiclesNeededByDay: Array<VehicleNeededByDayModel>): NeededVehicleModel => {
        let allNeededVehiclesNumber = 0;
        let allNeededVehiclesNoIdentifiedNumber = 0;
        vehiclesNeededByDay.forEach((e: VehicleNeededByDayModel) => {
            if (Date.equals(e.deliveryDate, date)) {
                if (!props.isNightFilterSelected) {
                    allNeededVehiclesNumber += e.dayTimePlannedNumberOfVehicles;
                    allNeededVehiclesNoIdentifiedNumber += e.dayTimePlannedNumberOfNoIdentifiedVehicles;
                } else {
                    allNeededVehiclesNumber += e.nightTimePlannedNumberOfVehicles;
                    allNeededVehiclesNoIdentifiedNumber += e.nightTimePlannedNumberOfNoIdentifiedVehicles;
                }
            }
        });

        const neededVehicle: NeededVehicleModel = {
            allNeededVehiclesNoIdentifiedNumber: allNeededVehiclesNoIdentifiedNumber,
            allNeededVehiclesNumber: allNeededVehiclesNumber
        };

        return neededVehicle;
    }

    const handleSelectVehicleTypeIdChange = (value: string): void => {
        const addedVehicleTypesToReservationArray: AddedVehicleTypeToReservationModel[] = props.addedVehicleTypesToReservation;
        if (!addedVehicleTypesToReservationArray.find(x => x.vehicleTypeId === value) && !props.weeklyRequestedByVehicleTypeIdArray.find(x => x.vehicleTypeId === value)) {
            const vehicleType = vehicleTypesList.find(x => x.vehicleTypeId === value);
            addedVehicleTypesToReservationArray.push({
                vehicleTypeId: vehicleType.vehicleTypeId,
                vehicleTypeLabel: vehicleType.label,
                transporterReservationsArray: []
            });
        }
    }

    const getAddedVehicleTypesToReservationValue = (date: Date, vehicleTypeId: string): number => {
        let value = 0;
        const addedVehicleTypesToReservationArray: AddedVehicleTypeToReservationModel[] = props.addedVehicleTypesToReservation;
        const transporterReservationsArray: TransporterReservationsModel[] = addedVehicleTypesToReservationArray.find(x => x.vehicleTypeId === vehicleTypeId).transporterReservationsArray;
        if (props.selectedTransporter !== '') {
            transporterReservationsArray.forEach((e: TransporterReservationsModel) => {
                if (e.transporter === props.selectedTransporter) {
                    e.reservationsData.forEach((elem) => {
                        if (Date.equals(elem.date, date) && elem.isNightWork === props.isNightFilterSelected) {
                            value += elem.value;
                        }
                    });
                }
            });
        } else {
            transporterReservationsArray.forEach((e: TransporterReservationsModel) => {
                e.reservationsData.forEach((elem: ReservedVehicleTypeValueModel) => {
                    if (Date.equals(elem.date, date) && elem.isNightWork === props.isNightFilterSelected) {
                        value += elem.value;
                    }
                });
            });
        }
        return Number(value);
    }

    const { weeklyRequestedByVehicleTypeIdArray, firstDayInSelectedWeek, selectedTransporter, onReservationBlur, isOpenVehicleTypeIdlist, vehicleTypesList, handleOpenCloseVehicleTypeIdlist, onChangeReservationValue, getReservedTransporterVehiclesNumber,
        getTotalNeededByDate, getAllReservedVehiclesNumber, getTotalReservedByDate, getTotalReservedByDateWithoutSelectedTransporter, addedVehicleTypesToReservation, onChangeAddedReservationValue, isNightFilterSelected } = props;

    const weekDays: string[] = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
    for (let i = 0; i < 7; i++) {
        const date: Date = moment(firstDayInSelectedWeek).add(i, 'days').toDate();
        weekDays[i] += " " + date.getDate();
    }

    return (
        <Box className="reservation-table" height={"85%"}>
            <Table>
                <TableHead>
                    <TableRow className="table-header-cell">
                        <TableCell align="center" className="text-nowrap table-cell blue-label bold fixed-head">
                            Camions réservés
                            <Select IconComponent={() => (
                                <IconButton size="small" onClick={() => handleOpenCloseVehicleTypeIdlist()} title='Ajouter un type de véhicule'>
                                    <FontAwesomeIcon className={"fa-card"} size="lg" icon={faPlusCircle} />
                                </IconButton>
                            )}
                                open={isOpenVehicleTypeIdlist}
                                onClose={() => handleOpenCloseVehicleTypeIdlist()}
                                onOpen={() => handleOpenCloseVehicleTypeIdlist()}
                                onChange={(e) => handleSelectVehicleTypeIdChange(e.target.value)}
                                value={''}
                            >
                                <MenuItem value={''}>
                                    <em></em>
                                </MenuItem>
                                {vehicleTypesList.map((vehicleType, index) => {
                                    return (
                                        <MenuItem key={index} value={vehicleType.vehicleTypeId}>{vehicleType.label}</MenuItem>
                                    );
                                })}
                            </Select>
                        </TableCell>
                        {weekDays.map((day, i) => {
                            return (
                                <TableCell key={i} align="center" className="table-cell fixed-head">{day}</TableCell>
                            );
                        })}
                        <TableCell align="center" className="table-cell fixed-head">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {weeklyRequestedByVehicleTypeIdArray.map((weeklyRequestedByVehicleTypeId: WeeklyRequestedByVehicleTypeIdModel, index: number) => {
                        let totalNoIdentifiedVehicleWeeklyMissingByVehicleType = 0;
                        let totalWeeklyReservedByVehicleType = 0;
                        let totalWeeklyMissingByVehicleType = 0;

                        return (
                            weeklyRequestedByVehicleTypeId.vehicleTypeId !== null && weeklyRequestedByVehicleTypeId.vehiclesNeededByDay.some(x => isNightFilterSelected ? x.nightTimePlannedNumberOfVehicles > 0 : x.dayTimePlannedNumberOfVehicles > 0) &&
                            <TableRow key={index}>
                                <TableCell component="th" scope="row" className="table-cell text-nowrap">{weeklyRequestedByVehicleTypeId.vehicleTypeLabel}</TableCell>
                                {Utilities.getDateRangeOfWeek(firstDayInSelectedWeek).map((date, i) => {
                                    const allNeededVehiclesNumberByDayAndByVehicleType = getVehiclesNeededNumberByDayAndByVehicleType(date, weeklyRequestedByVehicleTypeId.vehiclesNeededByDay);
                                    const allReservedVehiclesNumber = getAllReservedVehiclesNumber(date, weeklyRequestedByVehicleTypeId.vehicleTypeId);
                                    const value = getReservedTransporterVehiclesNumber(date, weeklyRequestedByVehicleTypeId.vehicleTypeId);

                                    totalNoIdentifiedVehicleWeeklyMissingByVehicleType += allNeededVehiclesNumberByDayAndByVehicleType.allNeededVehiclesNumber === 0 ? 0 : (allNeededVehiclesNumberByDayAndByVehicleType.allNeededVehiclesNoIdentifiedNumber - allReservedVehiclesNumber);
                                    totalWeeklyReservedByVehicleType += allNeededVehiclesNumberByDayAndByVehicleType.allNeededVehiclesNumber === 0 ? 0 : value;
                                    totalWeeklyMissingByVehicleType += allNeededVehiclesNumberByDayAndByVehicleType.allNeededVehiclesNumber === 0 ? 0 : (allNeededVehiclesNumberByDayAndByVehicleType.allNeededVehiclesNumber - allReservedVehiclesNumber);

                                    return (
                                        <TableCell key={i} align="center" className="table-cell">
                                            {allNeededVehiclesNumberByDayAndByVehicleType.allNeededVehiclesNumber !== 0 &&
                                                <>
                                                    <span>{allNeededVehiclesNumberByDayAndByVehicleType.allNeededVehiclesNoIdentifiedNumber - allReservedVehiclesNumber}</span>
                                                    <span className="separator"></span>
                                                    <input className="reserved-number-text" disabled={selectedTransporter === ''} type="number" min="0"
                                                        value={value}
                                                        onChange={(e) => onChangeReservationValue(e, weeklyRequestedByVehicleTypeId.vehicleTypeId, date)}
                                                        onKeyDown={(e) => handleKeypress(e)}
                                                        onBlur={(event) => {
                                                            if (currentInitialInputValue == Number(event.target.value))
                                                                return;
                                                            onReservationBlur(date, selectedTransporter, weeklyRequestedByVehicleTypeId.vehicleTypeId, event)
                                                        }}
                                                        onFocus={() => setCurrentInitialInputValue(value)}>
                                                    </input>
                                                </>
                                            }
                                        </TableCell>
                                    );
                                })}
                                <TableCell align="center" className="table-cell gray-cell">
                                    {!(totalWeeklyMissingByVehicleType === 0 && totalWeeklyReservedByVehicleType === 0) &&
                                        <>
                                            <span>{totalNoIdentifiedVehicleWeeklyMissingByVehicleType}</span>
                                            <span className="separator"></span>
                                            <span>{totalWeeklyReservedByVehicleType}</span>
                                        </>
                                    }
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    {addedVehicleTypesToReservation.map((VehicleType, index) => {
                        let totalWeeklyReservedByVehicleType = 0;
                        return (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row" className="table-cell text-nowrap">{VehicleType.vehicleTypeLabel}</TableCell>
                                {Utilities.getDateRangeOfWeek(firstDayInSelectedWeek).map((date, i) => {
                                    const value = getAddedVehicleTypesToReservationValue(date, VehicleType.vehicleTypeId);
                                    totalWeeklyReservedByVehicleType += value;
                                    return (
                                        <TableCell key={i} align="center" className="table-cell">
                                            <>
                                                <span>{" - "}</span>
                                                <span className="separator"></span>
                                                <input className="reserved-number-text" disabled={selectedTransporter === ''} type="number" min="0"
                                                    value={value}
                                                    onKeyDown={(e) => handleKeypress(e)}
                                                    onChange={(e) => onChangeAddedReservationValue(e, VehicleType.vehicleTypeId, date)}
                                                    onBlur={(event) => onReservationBlur(date, selectedTransporter, VehicleType.vehicleTypeId, event)}>
                                                </input>
                                            </>
                                        </TableCell>
                                    );
                                })}
                                <TableCell align="center" className="table-cell gray-cell">
                                    <>
                                        <span>{' - '}</span>
                                        <span className="separator"></span>
                                        <span>{totalWeeklyReservedByVehicleType}</span>
                                    </>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                    <TableRow>
                        <TableCell className="table-cell gray-cell">Total</TableCell>
                        {Utilities.getDateRangeOfWeek(firstDayInSelectedWeek).map((date, i) => {
                            const totalNeededByDay = getTotalNeededByDate(date);
                            const totalReservedByDay = getTotalReservedByDate(date);
                            const totalReservedByDayWithoutSelectedTransporter = getTotalReservedByDateWithoutSelectedTransporter(date);
                            return (
                                <TableCell key={i} align="center" className="table-cell gray-cell">
                                    {totalNeededByDay.totalNeededByDateNumber === 0 && totalReservedByDayWithoutSelectedTransporter !== 0 &&
                                        <>
                                            <span>{' - '}</span>
                                            <span className="separator"></span>
                                            <span>{totalReservedByDay}</span>
                                        </>
                                    }
                                    {totalNeededByDay.totalNeededByDateNumber !== 0 &&
                                        <>
                                            <span>{totalNeededByDay.totalNoIdentifiedNeededByDateNumber - totalReservedByDayWithoutSelectedTransporter}</span>
                                            <span className="separator"></span>
                                            <span>{totalReservedByDay}</span>
                                        </>
                                    }
                                </TableCell>
                            );
                        })}
                        <TableCell className="table-cell gray-cell"></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
}