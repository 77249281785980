import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn as Column, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import { PhoneNumberCell } from '../../../../shared/components/Common/PhoneNumberCell';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { ProductionForemanPlanningVehicleLightModel } from '../services/queryStack/ProductionForemanPlanningVehicleLightModel';
import { SizingUtilities } from '../SizingUtilities';

interface VehicleTripsComponentProps {
    planningVehiclesList: Array<ProductionForemanPlanningVehicleLightModel>,
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const ProductionForemanGridName = 'productionForeman';
const ModuleKey = AppModule.ThirdPartyProduction;

export const VehicleTripsComponent = (props: VehicleTripsComponentProps) => {

    const initialSort: SortDescriptor[] = [{ field: 'licensePlate', dir: 'asc' }];

    const [sort, setSort] = useState<Array<SortDescriptor>>(initialSort);
    const [skip, setSkip] = useState<number>(0);

    const forceUpdate = useForceUpdate();

    const getVehicleGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, ProductionForemanGridName, fieldName, defaultIndex);
    }

    const getVehicleGridWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, ProductionForemanGridName, fieldName, defaultWidth);
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            ProductionForemanGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, ProductionForemanGridName, event.columns);
        forceUpdate();
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const { planningVehiclesList } = props;

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, SizingUtilities.rowHeight);
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / SizingUtilities.rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    return (
        <Box id="vehicle-grid-id" className="vehicles-trip-grid">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Grid
                        className="vehicles-grid"
                        data={orderBy(planningVehiclesList, sort).slice(skip, skip + gridPageSize)}
                        sortable
                        reorderable
                        resizable
                        sort={sort}
                        selectedField="selected"
                        onColumnResize={onResizeHandler}
                        onColumnReorder={(e) => onReorderHandler(e)}
                        onSortChange={handleSortChange}
                        editField="inEdit"
                        scrollable="virtual"
                        skip={skip}
                        pageSize={gridPageSize}
                        total={planningVehiclesList.length}
                        onPageChange={pageChange}
                        rowHeight={40}
                        style={{ height: gridHeight }}
                    >
                        <Column field="empty" orderIndex={0} title=" " width={10} resizable={false} reorderable={false} />
                        <Column field="licencePlate" orderIndex={getVehicleGridOrderIndexColumn("licencePlate", 1)} width={getVehicleGridWidth("licencePlate", 200)} title="Immatriculation" editable={false} />
                        <Column field="realVehicleType" orderIndex={getVehicleGridOrderIndexColumn("realVehicleType", 2)} width={getVehicleGridWidth("realVehicleType", 150)} title="Type de camion " editable={false} />
                        <Column field="businessId" orderIndex={getVehicleGridOrderIndexColumn("businessId", 3)} title="Flux" editable={false} width={getVehicleGridWidth("businessId", 150)} />
                        <Column field="zephyrOrderNumber" orderIndex={getVehicleGridOrderIndexColumn("zephyrOrderNumber", 4)} width={getVehicleGridWidth("zephyrOrderNumber", 200)} title="N° commande" editable={false} />
                        <Column field="firstLoadingTime" orderIndex={getVehicleGridOrderIndexColumn("firstLoadingTime", 5)} width={getVehicleGridWidth("firstLoadingTime", 150)} title="1er chargement" editable={false} />
                        <Column field="product" orderIndex={getVehicleGridOrderIndexColumn("product", 6)} width={getVehicleGridWidth("product", 150)} title="Produit" editable={false} />
                        <Column field="senderSiteLabel" orderIndex={getVehicleGridOrderIndexColumn("senderSiteLabel", 7)} width={getVehicleGridWidth("senderSiteLabel", 150)} title="Départ " editable={false} />
                        <Column field="receiverSiteLabel" orderIndex={getVehicleGridOrderIndexColumn("receiverSiteLabel", 8)} width={getVehicleGridWidth("receiverSiteLabel", 150)} title="Destination " editable={false} />
                        <Column field="finishedTripQuantity" orderIndex={getVehicleGridOrderIndexColumn("finishedTripQuantity", 9)} width={getVehicleGridWidth("finishedTripQuantity", 200)} title="Qté livrée/planifiée" editable={false} />
                        <Column field="finishedTripCount" orderIndex={getVehicleGridOrderIndexColumn("finishedTripCount", 10)} width={getVehicleGridWidth("finishedTripCount", 200)} title="Tour(s) Planifiés/Effectués" editable={false} />
                        <Column field="planningDate" orderIndex={getVehicleGridOrderIndexColumn("planningDate", 11)} width={getVehicleGridWidth("planningDate", 200)} title="Date" format="{0:dd-MM-yyyy}" editable={false} />
                        <Column field="transporter" orderIndex={getVehicleGridOrderIndexColumn("transporter", 12)} width={getVehicleGridWidth("transporter", 200)} title="Transporteur" editable={false} />
                        <Column field="driverName" orderIndex={getVehicleGridOrderIndexColumn("driverName", 13)} width={getVehicleGridWidth("driverName", 200)} title="Chauffeur" editable={false} />
                        <Column field="driverPhone" orderIndex={getVehicleGridOrderIndexColumn("driverPhone", 14)} width={getVehicleGridWidth("driverPhone", 200)} title="Tel. chauffeur" editable={false}
                            cell={(props: GridCellProps) => <PhoneNumberCell {...props} />} />
                        <Column field="beneficiaryName" orderIndex={getVehicleGridOrderIndexColumn("beneficiaryName", 15)} width={getVehicleGridWidth("beneficiaryName", 200)} title="Bénéficiaire" editable={false} />
                        <Column field="deliveryFreeFormAddress" orderIndex={getVehicleGridOrderIndexColumn("deliveryFreeFormAddress", 16)} width={getVehicleGridWidth("deliveryFreeFormAddress", 250)} title="Adresse destination" editable={false} />
                        <Column field="receiverSiteAgency" orderIndex={getVehicleGridOrderIndexColumn("receiverSiteAgency", 17)} width={getVehicleGridWidth("receiverSiteAgency", 200)} title="Agence arrivée" editable={false} />
                        <Column field="transportersInstructions" orderIndex={getVehicleGridOrderIndexColumn("transportersInstructions", 18)} width={getVehicleGridWidth("transportersInstructions", 200)} title="Instructions transporteurs" editable={false} />
                        <Column field="logisticsUnitLabel" orderIndex={getVehicleGridOrderIndexColumn("logisticsUnitLabel", 19)} title="Zone logistique" width={getVehicleGridWidth("logisticsUnitLabel", 100)} />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}
