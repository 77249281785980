import { faCamera, faEye, faEyeSlash, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, FormControlLabel, IconButton, Input, InputAdornment, Radio, RadioGroup, Tooltip } from '@mui/material';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { AuthenticationType, data, HtmlMarker, HtmlMarkerManager, layer, Map as AzureMap, Shape, source } from 'azure-maps-control';
import 'azure-maps-control/dist/atlas.min.css';
import { debounce, orderBy } from 'lodash';
import React, { ChangeEvent } from 'react';
import Select, { components, InputActionMeta } from 'react-select';
import AsyncSelect from 'react-select/async';
import colasAgencyIcon from 'src/assets/icons/colas agency logo 64x32.png';
import colasFactoryEndpointIcon from 'src/assets/icons/colas factory endpoint 24x24.png';
import colasFactoryIcon from 'src/assets/icons/colas factory logo 64x32.png';
import colasJobsiteIcon from 'src/assets/icons/colas jobsite logo 26x32.png';
import externalJobsiteIcon from 'src/assets/icons/external jobsite logo 26x32.png';
import externalTransporterIcon from 'src/assets/icons/transporter external logo 40x32.png';
import { SettingsProvider } from '../../SettingsProvider';
import { ScaleLoaderComponent } from '../../shared/components/Common/ScaleLoaderComponent';
import SimpleDialog from '../../shared/components/Common/SimpleDialog';
import { FieldDescriptor } from '../../shared/components/TextSearchFieldsSelector/models/FieldDescriptor';
import { TextSearchFieldsSelectorComponent } from '../../shared/components/TextSearchFieldsSelector/TextSearchFieldsSelectorComponent';
import { MapsService } from '../../shared/MapsService/MapsService';
import { AppModule, LocalStorage, SessionStorage } from '../../utils/Storage';
import Utilities from '../../utils/Utilities';
import './CartographyStyles.scss';
import { DetailsDrawerComponent } from './components/DetailsDrawerComponent';
import { FavoriteDialogComponent } from './components/FavoriteDialogComponent';
import { AddressSelectItem } from './models/AddressSelectItem';
import { DisplayedPropertyTypeChoice } from './models/DisplayedPropertyTypeChoice';
import { DisplayedQuantityChoice } from './models/DisplayedQuantityChoice';
import { FavoriteAddress } from './models/FavoriteAddress';
import { FavoriteAddressSelectItem } from './models/FavoriteAddressSelectItem';
import { FeatureEntityDataModel } from './models/FeatureEntityDataModel';
import { FeatureTypeData } from './models/FeatureTypeData';
import { HtmlMarkerExtended } from './models/HtmlMarkerExtended';
import { TransportFlowExtended } from './models/TransportFlowExtended';
import { VehicleTypeGroupId } from './models/VehicleTypeGroupId';
import { CartographyApiClient } from './services/CartographyApiClient';
import { Address } from './services/dataContracts/queryStack/Address';
import { GpsCoordinates } from './services/dataContracts/queryStack/GpsCoordinates';
import { ProductionSiteLightModel } from './services/dataContracts/queryStack/ProductionSiteLightModel';
import { Site } from './services/dataContracts/queryStack/Site';
import { SiteBulletinBoardLightModel } from './services/dataContracts/queryStack/SiteBulletinBoardLightModel';
import { SiteKind } from './services/dataContracts/queryStack/SiteKind';
import { TransporterLightModel } from './services/dataContracts/queryStack/TransporterLightModel';
import { TransportPriority } from './services/dataContracts/queryStack/TransportPriority';
import { TransportServiceKind } from './services/dataContracts/queryStack/TransportServiceKind';
import { WorkAgencyLightModel } from './services/dataContracts/queryStack/WorkAgencyLightModel';

interface CartographyViewStateProperties {
    logisticsUnitIds: string[]
}

interface CartographyViewState {
    isExternalTransportersButtonActive: boolean,
    isColasAgenciesButtonActive: boolean,
    loading: boolean,
    date: Date,
    externalTransportersData: TransporterLightModel[],
    colasAgenciesData: WorkAgencyLightModel[],
    transportFlowsData: TransportFlowExtended[],
    map: AzureMap,
    positionCLickedId: string,
    isDawerDetailsOpened: boolean,
    drawerData: FeatureEntityDataModel,
    isMarkerSelected: boolean,
    shapePropertiesCache: Map<string, FeatureEntityDataModel>,
    isPerishableSelected: boolean,
    isNotPerishableSelected: boolean,
    isRemovalSelected: boolean,
    isJobsiteSelected: boolean,
    isLowPrioritySelected: boolean,
    isMediumPrioritySelected: boolean,
    isHighPrioritySelected: boolean,
    isDeliverySelected: boolean,
    isLoadingSelected: boolean,
    remainingQuantityChoice: DisplayedQuantityChoice
    displayedNumberChoice: DisplayedPropertyTypeChoice,
    addressInputValue: string,
    addressValue: any,
    isAddressInputLoading: boolean,
    isFavoriteDialogOpened: boolean,
    favoriteAddresses: FavoriteAddressSelectItem[],
    searchText: string,
    relatedEntitiesIdsMap: Map<string, string>,
    vehicleTypeGroupFilterOptions: Array<FieldDescriptor<string>>,
    filtersSelected: Array<string>
}

const ModuleKey = AppModule.Cartography;
const ExternalTransportersButtonStorageKey = 'Cartography_ExternalTransportersButton';
const ColasAgenciesButtonStorageKey = 'Cartography_ColasAgenciesButton';
const FavoriteAddressesStorageKey = 'Cartography_FavoriteAddresses';

const DataSourceId = 'dataSourceId';
const ColasFactoryEntityType = 'colasFactory';
const AgencyEntityType = 'agency';
const ExternalTransporterEntityType = 'externalTransporters';
const ColasJobsiteEntityType = 'colasJobsite';
const ExternalJobsiteEntityType = 'externalJobsite';
const ColasFactoryEndpointEntityType = 'colasFactoryEndpoint';
const PinLayerId = 'pin-layerId';

const SiteKindLabels: Map<SiteKind | string, string> = new Map<SiteKind | string, string>([
    [SiteKind.productionSite, "Site de production"],
    [SiteKind.agency, "Agence Colas"],
    [SiteKind.transporter, "Transporteur externe"],
    [SiteKind.jobSite, "Chantier Colas"],
    [SiteKind.customer, "Site référencé"],
    [SiteKind.unreferencedCustomer, "Site non référencé"]
]);

export class CartographyView extends React.Component<CartographyViewStateProperties, CartographyViewState> {
    _isMounted: boolean;
    datePickerRef: React.RefObject<DatePicker>;
    inputSearchTextRef: React.RefObject<HTMLInputElement>;
    favoriteAddressesStorage = JSON.parse(LocalStorage.GetItem(ModuleKey, FavoriteAddressesStorageKey));
    favoriteList = this.favoriteAddressesStorage ? this.favoriteAddressesStorage.map(x => { return { label: x.name, value: x } }) : [];

    constructor(props: CartographyViewStateProperties) {
        super(props);
        const externalTransportersButton = LocalStorage.GetItem(ModuleKey, ExternalTransportersButtonStorageKey);
        const colasAgenciesButton = LocalStorage.GetItem(ModuleKey, ColasAgenciesButtonStorageKey);
        this.datePickerRef = React.createRef();
        this.inputSearchTextRef = React.createRef();

        this.state = {
            isExternalTransportersButtonActive: externalTransportersButton === null ? false : (externalTransportersButton === "false" ? false : true),
            isColasAgenciesButtonActive: colasAgenciesButton === null ? false : (colasAgenciesButton === "false" ? false : true),
            loading: false,
            date: SessionStorage.ActiveStartDate,
            externalTransportersData: [],
            colasAgenciesData: [],
            transportFlowsData: [],
            map: null,
            positionCLickedId: null,
            isDawerDetailsOpened: false,
            drawerData: null,
            isMarkerSelected: false,
            shapePropertiesCache: new Map<string, FeatureEntityDataModel>(),
            isPerishableSelected: false,
            isNotPerishableSelected: false,
            isRemovalSelected: false,
            isJobsiteSelected: false,
            isLowPrioritySelected: false,
            isMediumPrioritySelected: false,
            isHighPrioritySelected: false,
            isDeliverySelected: false,
            isLoadingSelected: false,
            remainingQuantityChoice: DisplayedQuantityChoice.RemainingQuantityToBeDelivered,
            displayedNumberChoice: DisplayedPropertyTypeChoice.Quantities,
            addressInputValue: "",
            addressValue: { freeFormAddress: "" },
            isAddressInputLoading: false,
            isFavoriteDialogOpened: false,
            favoriteAddresses: this.favoriteList ? [{ label: "", value: "" }].concat(this.favoriteList) : [{ label: "", value: "" }],
            searchText: '',
            relatedEntitiesIdsMap: new Map<string, string>(),
            vehicleTypeGroupFilterOptions: [],
            filtersSelected: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.initMap(this.state.date);
    }

    initMap = (date: Date, zoom?: number, center?: data.Position, searchText?: string): void => {
        const map = new AzureMap('map', {
            zoom: zoom ?? 6,
            center: center ?? [2.3945, 47.0853],
            view: 'Auto',
            authOptions: {
                authType: AuthenticationType.subscriptionKey,
                subscriptionKey: SettingsProvider.Get().azureMapsApiKey
            }
        });

        map.events.add('ready', () => {
            const datasource = this.createDataSource(map, DataSourceId);
            const pinLayer = new layer.SymbolLayer(datasource, PinLayerId, {
                //On ajoute cette expression dans le filter pour ne rien afficher (ni pin ni cluster) et laisser gérer l'affichage des pins et clusters avec les HtmlMarkers
                filter: ['!', true]
                //filter: ['all', ['!', ['has', 'point_count']], ['has', 'point_count']]
            });

            map.layers.add(pinLayer);

            map.events.add('click', () => this.handleMapClicked());

            const shouldUpdateMapCamera = !zoom && !center
            this.displayPointsAndGetVehicleTypes(date, map, datasource, shouldUpdateMapCamera, searchText);
        });
    }

    displayPointsAndGetVehicleTypes = (date: Date, map: AzureMap, datasource: source.DataSource, shouldUpdateMapCamera: boolean, searchText: string): void => {
        this.setState({ loading: true });

        Promise.all([
            CartographyApiClient.GetProductionSites(this.props.logisticsUnitIds),
            CartographyApiClient.GetWorkAgencies(this.props.logisticsUnitIds),
            CartographyApiClient.GetExternalTransporters(this.props.logisticsUnitIds),
            CartographyApiClient.GetTransportFlows(date, this.props.logisticsUnitIds)
        ])
            .then((res) => {
                if (this._isMounted && res) {
                    const productionSitesData = res[0].data;
                    const agenciesData = res[1].data;
                    const externalTransportersData = res[2].data;
                    const transportFlowsData = res[3].data;

                    this.setMapPinsAndFillVehicleTypesList(date, map, datasource, productionSitesData, agenciesData, externalTransportersData, transportFlowsData as TransportFlowExtended[], shouldUpdateMapCamera, searchText);
                }
            });
    }

    createDataSource = (map: AzureMap, datasourceId: string): source.DataSource => {
        const datasource = new source.DataSource(datasourceId);
        map.sources.add(datasource);
        return datasource;
    }

    setMapPinsAndFillVehicleTypesList = (date: Date, map: AzureMap, datasource: source.DataSource, productionSitesData: ProductionSiteLightModel[], agenciesData: WorkAgencyLightModel[]
        , externalTransportersData: TransporterLightModel[], transportFlowsData: TransportFlowExtended[], shouldUpdateMapCamera: boolean, searchText: string) => {
        const allPositions: data.Position[] = [];
        const productionSitesPositionsMap = new Map<string, Map<string, FeatureEntityDataModel>>();
        const colasJobsitesPositionsMap = new Map<string, Map<string, FeatureEntityDataModel>>();
        const productionSitesEndpointsPositionsMap = new Map<string, Map<string, FeatureEntityDataModel>>();

        const productionSitesMap = new Map<string, FeatureEntityDataModel>();
        this.createProductionSitesMapPins(map, datasource, productionSitesData, productionSitesPositionsMap, productionSitesMap, allPositions);

        if (this.state.isExternalTransportersButtonActive) {
            this.createExternalTransportersMapPins(map, datasource, externalTransportersData, allPositions);
        }

        if (this.state.isColasAgenciesButtonActive) {
            this.createAgenciesMapPins(map, datasource, agenciesData, allPositions);
        }

        const colasJobsitesMap = new Map<string, FeatureEntityDataModel>();
        const externalJobsitesMap = new Map<string, FeatureEntityDataModel>();
        const vehicleTypeGroupOptions: Array<FieldDescriptor<string>> = [];

        transportFlowsData.forEach((transportFlow: TransportFlowExtended) => {
            let senderSiteEntityData: FeatureEntityDataModel = null;
            let receiverSiteEntityData: FeatureEntityDataModel = null;

            if (transportFlow.senderSite.kind === SiteKind.jobSite) {
                this.createJobsiteMapPin(map, datasource, transportFlow.senderSite, transportFlow.pickupCoordinates, transportFlow.pickupAddress, colasJobsitesPositionsMap, colasJobsitesMap, allPositions, ColasJobsiteEntityType, colasJobsiteIcon);
                senderSiteEntityData = this.updateEntityData(colasJobsitesMap, transportFlow.senderSite.identifiers, transportFlow, false);
            }

            if (transportFlow.receiverSite.kind === SiteKind.jobSite) {
                this.createJobsiteMapPin(map, datasource, transportFlow.receiverSite, transportFlow.deliveryCoordinates, transportFlow.deliveryAddress, colasJobsitesPositionsMap, colasJobsitesMap, allPositions, ColasJobsiteEntityType, colasJobsiteIcon);
                receiverSiteEntityData = this.updateEntityData(colasJobsitesMap, transportFlow.receiverSite.identifiers, transportFlow, true);
            }

            if (transportFlow.senderSite.kind === SiteKind.customer || transportFlow.senderSite.kind === SiteKind.unreferencedCustomer) {
                this.createJobsiteMapPin(map, datasource, transportFlow.senderSite, transportFlow.pickupCoordinates, transportFlow.pickupAddress, colasJobsitesPositionsMap, externalJobsitesMap, allPositions, ExternalJobsiteEntityType, externalJobsiteIcon);
                senderSiteEntityData = this.updateEntityData(externalJobsitesMap, transportFlow.senderSite.identifiers, transportFlow, false);
            }

            if (transportFlow.receiverSite.kind === SiteKind.customer || transportFlow.receiverSite.kind === SiteKind.unreferencedCustomer) {
                this.createJobsiteMapPin(map, datasource, transportFlow.receiverSite, transportFlow.deliveryCoordinates, transportFlow.deliveryAddress, colasJobsitesPositionsMap, externalJobsitesMap, allPositions, ExternalJobsiteEntityType, externalJobsiteIcon);
                receiverSiteEntityData = this.updateEntityData(externalJobsitesMap, transportFlow.receiverSite.identifiers, transportFlow, true);
            }

            if (transportFlow.senderSite.kind === SiteKind.productionSite) {
                this.createColasFactoryEndpointMapPin(map, datasource, transportFlow.senderSite, transportFlow.pickupCoordinates, transportFlow.pickupAddress, productionSitesPositionsMap, productionSitesEndpointsPositionsMap, allPositions, ColasFactoryEndpointEntityType, colasFactoryEndpointIcon);
                senderSiteEntityData = this.updateEntityData(productionSitesMap, transportFlow.senderSite.identifiers, transportFlow, false);
            }

            if (transportFlow.receiverSite.kind === SiteKind.productionSite) {
                this.createColasFactoryEndpointMapPin(map, datasource, transportFlow.receiverSite, transportFlow.deliveryCoordinates, transportFlow.deliveryAddress, productionSitesPositionsMap, productionSitesEndpointsPositionsMap, allPositions, ColasFactoryEndpointEntityType, colasFactoryEndpointIcon);
                receiverSiteEntityData = this.updateEntityData(productionSitesMap, transportFlow.receiverSite.identifiers, transportFlow, true);
            }

            transportFlow.receiverFeature = receiverSiteEntityData;
            transportFlow.senderFeature = senderSiteEntityData;

            transportFlow.planningVehicles.map(x => x.vehicleTypeGroupId).forEach((vehicleTypeGroupId: string) => {
                const item: FieldDescriptor<string> = { label: vehicleTypeGroupId, value: vehicleTypeGroupId };
                if (!vehicleTypeGroupOptions.some(x => x.value === vehicleTypeGroupId)) {
                    vehicleTypeGroupOptions.push(item);
                }
            });
        });

        // mise à jour des shapes avec les nouvelles entityData
        this.updateShapes(productionSitesMap, datasource, ColasFactoryEntityType);
        this.updateShapes(colasJobsitesMap, datasource, ColasJobsiteEntityType);
        this.updateShapes(externalJobsitesMap, datasource, ExternalJobsiteEntityType);
        this.updateColasFactoryEndPointsShapes(datasource);

        if (shouldUpdateMapCamera) {
            map.setCamera({
                bounds: data.BoundingBox.fromPositions(allPositions),
                type: "ease",
                duration: 1000,
                padding: 70
            });
        }

        this.setState({
            loading: false,
            externalTransportersData: externalTransportersData,
            colasAgenciesData: agenciesData,
            transportFlowsData: transportFlowsData,
            map: map,
            date: date,
            positionCLickedId: null,
            isDawerDetailsOpened: false,
            drawerData: null,
            vehicleTypeGroupFilterOptions: orderBy(vehicleTypeGroupOptions, o => o.label, ['asc']),
            filtersSelected: vehicleTypeGroupOptions.map(x => x.value)
        });

        if (searchText) {
            this.filterEntities(searchText);
        }
        const { isDeliverySelected, isLoadingSelected, displayedNumberChoice, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected } = this.state;
        this.handleFlowFilterChange(map, null, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
    }

    private createColasFactoryEndpointMapPin = (map: AzureMap, datasource: source.DataSource, site: Site, coordinates: GpsCoordinates, address: Address, productionSitesPositionsMap: Map<string, Map<string, FeatureEntityDataModel>>, productionSitesEndpointsPositionsMap: Map<string, Map<string, FeatureEntityDataModel>>, allPositions: data.Position[], pinEntityType: string, icon: string): void => {
        const position = new data.Position(coordinates.longitude, coordinates.latitude);
        const entityData: FeatureEntityDataModel = this.createSiteFeatureData(site, address, icon, pinEntityType, position);
        const positionKey = position.toString();
        const existingProductionSite = productionSitesPositionsMap.has(positionKey);
        if (existingProductionSite) {
            //Si la position existe déjà dans les points fixes UPs
            const productionSiteIds = productionSitesPositionsMap.get(positionKey);
            const existingId = productionSiteIds.get(site.identifiers);
            if (existingId)
                return;
        }

        const existingEndpointPosition = productionSitesEndpointsPositionsMap.has(positionKey);
        let identifiers: Map<string, FeatureEntityDataModel> = null;
        if (existingEndpointPosition) {
            identifiers = productionSitesEndpointsPositionsMap.get(positionKey);
            const exisitingIdentifiers = identifiers.has(site.identifiers);
            if (exisitingIdentifiers)
                return;

            identifiers.set(site.identifiers, entityData);
        } else {
            identifiers = new Map<string, FeatureEntityDataModel>();
            identifiers.set(site.identifiers, entityData);
        }
        productionSitesEndpointsPositionsMap.set(positionKey, identifiers);

        this.createPinFeature(map, datasource, position, pinEntityType, entityData);
        allPositions.push(position);
    }

    private createJobsiteMapPin = (map: AzureMap, datasource: source.DataSource, site: Site, coordinates: GpsCoordinates, address: Address, positionsMap: Map<string, Map<string, FeatureEntityDataModel>>, jobsitesMap: Map<string, FeatureEntityDataModel>, allPositions: data.Position[], pinEntityType: string, icon: string): void => {
        const position = new data.Position(coordinates.longitude, coordinates.latitude);
        const entityData: FeatureEntityDataModel = this.createSiteFeatureData(site, address, icon, pinEntityType, position);
        const positionKey = position.toString();
        const existingPosition = positionsMap.has(positionKey);
        let identifiers: Map<string, FeatureEntityDataModel> = null;
        if (existingPosition) {
            //Si la position existe déjà
            identifiers = positionsMap.get(positionKey);
            const exisitingIdentifiers = identifiers.has(site.identifiers);
            if (exisitingIdentifiers)
                return;

            identifiers.set(site.identifiers, entityData);
        }
        else {
            identifiers = new Map<string, FeatureEntityDataModel>();
            identifiers.set(site.identifiers, entityData);
        }
        positionsMap.set(positionKey, identifiers);

        this.createPinFeature(map, datasource, position, pinEntityType, entityData);
        jobsitesMap.set(site.identifiers, entityData);
        allPositions.push(position);
    }

    private createExternalTransportersMapPins = (map: AzureMap, datasource: source.DataSource, externalTransportersData: TransporterLightModel[], allPositions: data.Position[]): void => {
        externalTransportersData.forEach((externalTransporter: TransporterLightModel) => {
            if (externalTransporter.coordinates.longitude && externalTransporter.coordinates.latitude) {
                const position = new data.Position(externalTransporter.coordinates.longitude, externalTransporter.coordinates.latitude);
                const entityData: FeatureEntityDataModel = this.createTransporterFeatureData(externalTransporter, position);
                this.createPinFeature(map, datasource, position, ExternalTransporterEntityType, entityData);
                allPositions.push(position);
            }
        });
    }

    private createAgenciesMapPins = (map: AzureMap, datasource: source.DataSource, agenciesData: WorkAgencyLightModel[], allPositions: data.Position[]): void => {
        agenciesData.forEach((agency: WorkAgencyLightModel) => {
            if (agency.coordinates.longitude && agency.coordinates.latitude) {
                const position = new data.Position(agency.coordinates.longitude, agency.coordinates.latitude);
                const entityData: FeatureEntityDataModel = this.createAgencyFeatureData(agency, position);
                this.createPinFeature(map, datasource, position, AgencyEntityType, entityData);
                allPositions.push(position);
            }
        });
    }

    private createProductionSitesMapPins = (map: AzureMap, datasource: source.DataSource, productionSitesData: ProductionSiteLightModel[], productionSitesPositionsMap: Map<string, Map<string, FeatureEntityDataModel>>, productionSitesMap: Map<string, FeatureEntityDataModel>, allPositions: data.Position[]): void => {
        productionSitesData.forEach((productionSite: ProductionSiteLightModel) => {
            if (productionSite.coordinates.longitude && productionSite.coordinates.latitude) {
                const position = new data.Position(productionSite.coordinates.longitude, productionSite.coordinates.latitude);
                const entityData: FeatureEntityDataModel = this.createProductionSiteFeatureEntityData(productionSite, position);

                //check meme position et up different
                const positionKey = position.toString();
                const existingPosition = productionSitesPositionsMap.has(positionKey);
                let identifiers: Map<string, FeatureEntityDataModel> = null;
                if (existingPosition) {
                    //Si la position existe déjà
                    identifiers = productionSitesPositionsMap.get(positionKey);
                    const exisitingIdentifiers = identifiers.has(productionSite.productionSiteId);
                    if (exisitingIdentifiers)
                        return;

                    identifiers.set(productionSite.productionSiteId, entityData);
                } else {
                    identifiers = new Map<string, FeatureEntityDataModel>();
                    identifiers.set(productionSite.productionSiteId, entityData);
                }
                productionSitesPositionsMap.set(positionKey, identifiers);

                this.createPinFeature(map, datasource, position, ColasFactoryEntityType, entityData);
                productionSitesMap.set(productionSite.productionSiteId, entityData);
                allPositions.push(position);
            }
        });
    }

    private createSiteFeatureData = (site: Site, address: Address, icon: string, pinEntityType: string, position: data.Position): FeatureEntityDataModel => {
        const siteKindLabel = SiteKindLabels.get(site.kind) ?? ("?" + site.kind.toString());

        const deliveryQuantityByPriority: Map<TransportPriority, number> = new Map<TransportPriority, number>();
        this.initialPriorityMap(deliveryQuantityByPriority);

        const loadingQuantityByPriority: Map<TransportPriority, number> = new Map<TransportPriority, number>();
        this.initialPriorityMap(loadingQuantityByPriority);

        const planningVehiclesCountByGroup: Map<VehicleTypeGroupId, number> = new Map<VehicleTypeGroupId, number>();
        this.initialVehiclesCountByGroup(planningVehiclesCountByGroup);

        const jobsiteVehiclesCountByGroup: Map<VehicleTypeGroupId, number> = new Map<VehicleTypeGroupId, number>();
        this.initialVehiclesCountByGroup(jobsiteVehiclesCountByGroup);

        const entityData: FeatureEntityDataModel = {
            siteId: site.identifiers,
            siteBulletinBoardId: site.siteBulletinBoardId,
            siteBulletinBoardRemarks: null,
            isSiteBulletinBoardRemarksLoaded: false,
            siteKindLabel: siteKindLabel,
            siteKind: site.kind,
            siteLabel: site.label,
            siteAgencyLabel: site.kind === SiteKind.jobSite ? site.siteAgencyLabel : "",
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            zipCode: address.zipCode,
            deliveryQuantityByPriority: deliveryQuantityByPriority,
            loadingQuantityByPriority: loadingQuantityByPriority,
            planningVehiclesCountByGroup: planningVehiclesCountByGroup,
            jobsiteVehiclesCountByGroup: jobsiteVehiclesCountByGroup,
            senderOnFlows: new Map<string, TransportFlowExtended>(),
            receiverOnFlows: new Map<string, TransportFlowExtended>(),
            icon: icon,
            cssClass: "pin-marker",
            htmlContent: this.getHtmlContent(icon, "pin-marker"),
            entityType: pinEntityType,
            position: position,
            remainingDeliveryQuantity: null,
            requestedDeliveryQuantity: null,
            deliveredDeliveryQuantity: null,
            remainingLoadingQuantity: null,
            requestedLoadingQuantity: null,
            deliveredLoadingQuantity: null,
            deliveryVehicleNumber: null,
            loadingVehicleNumber: null
        };

        return entityData;
    }

    private createProductionSiteFeatureEntityData = (productionSite: ProductionSiteLightModel, position: data.Position): FeatureEntityDataModel => {
        const deliveryQuantityByPriority: Map<TransportPriority, number> = new Map<TransportPriority, number>();
        this.initialPriorityMap(deliveryQuantityByPriority);

        const loadingQuantityByPriority: Map<TransportPriority, number> = new Map<TransportPriority, number>();
        this.initialPriorityMap(loadingQuantityByPriority);

        const planningVehiclesCountByGroup: Map<VehicleTypeGroupId, number> = new Map<VehicleTypeGroupId, number>();
        this.initialVehiclesCountByGroup(planningVehiclesCountByGroup);

        const jobsiteVehiclesCountByGroup: Map<VehicleTypeGroupId, number> = new Map<VehicleTypeGroupId, number>();
        this.initialVehiclesCountByGroup(jobsiteVehiclesCountByGroup);

        const entityData: FeatureEntityDataModel = {
            siteId: productionSite.productionSiteId,
            siteBulletinBoardId: productionSite.siteBulletinBoardId,
            siteBulletinBoardRemarks: null,
            isSiteBulletinBoardRemarksLoaded: false,
            siteKindLabel: SiteKindLabels.get(SiteKind.productionSite),
            siteKind: SiteKind.productionSite,
            siteLabel: productionSite.label,
            line1: productionSite.address.line1,
            line2: productionSite.address.line2,
            city: productionSite.address.city,
            zipCode: productionSite.address.zipCode,
            deliveryQuantityByPriority: deliveryQuantityByPriority,
            loadingQuantityByPriority: loadingQuantityByPriority,
            planningVehiclesCountByGroup: planningVehiclesCountByGroup,
            jobsiteVehiclesCountByGroup: jobsiteVehiclesCountByGroup,
            senderOnFlows: new Map<string, TransportFlowExtended>(),
            receiverOnFlows: new Map<string, TransportFlowExtended>(),
            icon: colasFactoryIcon,
            cssClass: "pin-marker",
            htmlContent: this.getHtmlContent(colasFactoryIcon, "pin-marker"),
            entityType: ColasFactoryEntityType,
            position: position,
            remainingDeliveryQuantity: null,
            requestedDeliveryQuantity: null,
            deliveredDeliveryQuantity: null,
            remainingLoadingQuantity: null,
            requestedLoadingQuantity: null,
            deliveredLoadingQuantity: null,
            deliveryVehicleNumber: null,
            loadingVehicleNumber: null
        };

        return entityData;
    }

    private createAgencyFeatureData = (agency: WorkAgencyLightModel, position: data.Position): FeatureEntityDataModel => {
        const planningVehiclesCountByGroup: Map<VehicleTypeGroupId, number> = new Map<VehicleTypeGroupId, number>();

        const _4X2Count = agency.vehicleTypeGroups?.find(x => x.vehicleTypeGroupId === VehicleTypeGroupId.Id4x2);
        planningVehiclesCountByGroup.set(VehicleTypeGroupId.Id4x2, _4X2Count?.count ?? 0);

        const _6X4Count = agency.vehicleTypeGroups?.find(x => x.vehicleTypeGroupId === VehicleTypeGroupId.Id6x4);
        planningVehiclesCountByGroup.set(VehicleTypeGroupId.Id6x4, _6X4Count?.count ?? 0);

        const _8X4Count = agency.vehicleTypeGroups?.find(x => x.vehicleTypeGroupId === VehicleTypeGroupId.Id8x4);
        planningVehiclesCountByGroup.set(VehicleTypeGroupId.Id8x4, _8X4Count?.count ?? 0);

        const _semiCount = agency.vehicleTypeGroups?.find(x => x.vehicleTypeGroupId === VehicleTypeGroupId.IdSemi);
        planningVehiclesCountByGroup.set(VehicleTypeGroupId.IdSemi, _semiCount?.count ?? 0);

        const _othersCount = agency.vehicleTypeGroups?.find(x => x.vehicleTypeGroupId === VehicleTypeGroupId.Others);
        planningVehiclesCountByGroup.set(VehicleTypeGroupId.Others, _othersCount?.count ?? 0);

        const entityData: FeatureEntityDataModel = {
            siteId: agency.agencyId,
            siteBulletinBoardId: agency.siteBulletinBoardId,
            siteBulletinBoardRemarks: null,
            isSiteBulletinBoardRemarksLoaded: false,
            siteKindLabel: SiteKindLabels.get(SiteKind.agency),
            siteKind: SiteKind.agency,
            siteLabel: agency.label,
            contacts: agency.contacts?.map(x => ({
                fullName: x.fullName,
                email: x.email,
                phoneNumber: x.phoneNumber,
            })) ?? [],
            line1: agency.address.line1,
            line2: agency.address.line2,
            city: agency.address.city,
            zipCode: agency.address.zipCode,
            icon: colasAgencyIcon,
            cssClass: "pin-marker",
            htmlContent: this.getHtmlContent(colasAgencyIcon, "pin-marker"),
            entityType: AgencyEntityType,
            planningVehiclesCountByGroup: planningVehiclesCountByGroup,
            position: position,
            remainingDeliveryQuantity: null,
            requestedDeliveryQuantity: null,
            deliveredDeliveryQuantity: null,
            remainingLoadingQuantity: null,
            requestedLoadingQuantity: null,
            deliveredLoadingQuantity: null,
            deliveryVehicleNumber: null,
            loadingVehicleNumber: null
        };

        return entityData;
    }

    private createTransporterFeatureData = (transporter: TransporterLightModel, position: data.Position): FeatureEntityDataModel => {
        const planningVehiclesCountByGroup: Map<VehicleTypeGroupId, number> = new Map<VehicleTypeGroupId, number>();

        const _4X2Count = transporter.vehicleTypeGroups?.find(x => x.vehicleTypeGroupId === VehicleTypeGroupId.Id4x2);
        planningVehiclesCountByGroup.set(VehicleTypeGroupId.Id4x2, _4X2Count?.count ?? 0);

        const _6X4Count = transporter.vehicleTypeGroups?.find(x => x.vehicleTypeGroupId === VehicleTypeGroupId.Id6x4);
        planningVehiclesCountByGroup.set(VehicleTypeGroupId.Id6x4, _6X4Count?.count ?? 0);

        const _8X4Count = transporter.vehicleTypeGroups?.find(x => x.vehicleTypeGroupId === VehicleTypeGroupId.Id8x4);
        planningVehiclesCountByGroup.set(VehicleTypeGroupId.Id8x4, _8X4Count?.count ?? 0);

        const _semiCount = transporter.vehicleTypeGroups?.find(x => x.vehicleTypeGroupId === VehicleTypeGroupId.IdSemi);
        planningVehiclesCountByGroup.set(VehicleTypeGroupId.IdSemi, _semiCount?.count ?? 0);

        const _othersCount = transporter.vehicleTypeGroups?.find(x => x.vehicleTypeGroupId === VehicleTypeGroupId.Others);
        planningVehiclesCountByGroup.set(VehicleTypeGroupId.Others, _othersCount?.count ?? 0);

        const entityData: FeatureEntityDataModel = {
            siteId: transporter.transporterId,
            siteBulletinBoardId: transporter.siteBulletinBoardId,
            siteBulletinBoardRemarks: null,
            isSiteBulletinBoardRemarksLoaded: false,
            siteKindLabel: SiteKindLabels.get(SiteKind.transporter),
            siteKind: SiteKind.transporter,
            siteLabel: transporter.label,
            contacts: transporter.contact ? [
                { email: transporter.contact.email, phoneNumber: transporter.contact.phoneNumber }
            ] : [],
            line1: transporter.address.line1,
            line2: transporter.address.line2,
            city: transporter.address.city,
            zipCode: transporter.address.zipCode,
            icon: externalTransporterIcon,
            cssClass: this.state.positionCLickedId ? "pin-marker stump" : "pin-marker",
            htmlContent: this.state.positionCLickedId ? this.getHtmlContent(externalTransporterIcon, "pin-marker stump") : this.getHtmlContent(externalTransporterIcon, "pin-marker"),
            entityType: ExternalTransporterEntityType,
            planningVehiclesCountByGroup: planningVehiclesCountByGroup,
            position: position,
            remainingDeliveryQuantity: null,
            requestedDeliveryQuantity: null,
            deliveredDeliveryQuantity: null,
            remainingLoadingQuantity: null,
            requestedLoadingQuantity: null,
            deliveredLoadingQuantity: null,
            deliveryVehicleNumber: null,
            loadingVehicleNumber: null
        };

        return entityData;
    }

    private updateEntityData = (map: Map<string, FeatureEntityDataModel>, id: string, transportFlow: TransportFlowExtended, isDeliverySite: boolean): FeatureEntityDataModel => {
        const existingElement = map.has(id);
        if (existingElement) {
            const entityData = map.get(id);

            const quantityByPriorityMap = isDeliverySite ? entityData.deliveryQuantityByPriority : entityData.loadingQuantityByPriority;
            const currentQuantity = quantityByPriorityMap.get(transportFlow.priority);
            quantityByPriorityMap.set(transportFlow.priority, currentQuantity + transportFlow.plannedQuantity);

            const flowsMap = isDeliverySite ? entityData.receiverOnFlows : entityData.senderOnFlows;
            flowsMap.set(transportFlow.transportFlowId, transportFlow);

            const _4X2Count = Utilities.count(transportFlow.planningVehicles, x => x.vehicleTypeGroupId === VehicleTypeGroupId.Id4x2);
            const _6X4Count = Utilities.count(transportFlow.planningVehicles, x => x.vehicleTypeGroupId === VehicleTypeGroupId.Id6x4);
            const _8X4Count = Utilities.count(transportFlow.planningVehicles, x => x.vehicleTypeGroupId === VehicleTypeGroupId.Id8x4);
            const _semiCount = Utilities.count(transportFlow.planningVehicles, x => x.vehicleTypeGroupId === VehicleTypeGroupId.IdSemi);
            const _others = Utilities.count(transportFlow.planningVehicles, x => x.vehicleTypeGroupId !== VehicleTypeGroupId.Id4x2 && x.vehicleTypeGroupId !== VehicleTypeGroupId.Id6x4 && x.vehicleTypeGroupId !== VehicleTypeGroupId.Id8x4 && x.vehicleTypeGroupId !== VehicleTypeGroupId.IdSemi);

            const planningVehiclesCountByGroup = new Map<VehicleTypeGroupId, number>();

            const old4X2Count = entityData.planningVehiclesCountByGroup.get(VehicleTypeGroupId.Id4x2);
            planningVehiclesCountByGroup.set(VehicleTypeGroupId.Id4x2, old4X2Count + _4X2Count);

            const old6X2Count = entityData.planningVehiclesCountByGroup.get(VehicleTypeGroupId.Id6x4);
            planningVehiclesCountByGroup.set(VehicleTypeGroupId.Id6x4, old6X2Count + _6X4Count);

            const old8X2Count = entityData.planningVehiclesCountByGroup.get(VehicleTypeGroupId.Id8x4);
            planningVehiclesCountByGroup.set(VehicleTypeGroupId.Id8x4, old8X2Count + _8X4Count);

            const oldSemiCount = entityData.planningVehiclesCountByGroup.get(VehicleTypeGroupId.IdSemi);
            planningVehiclesCountByGroup.set(VehicleTypeGroupId.IdSemi, oldSemiCount + _semiCount);

            const oldOthersCount = entityData.planningVehiclesCountByGroup.get(VehicleTypeGroupId.Others);
            planningVehiclesCountByGroup.set(VehicleTypeGroupId.Others, oldOthersCount + _others)

            entityData.planningVehiclesCountByGroup = planningVehiclesCountByGroup;

            if (transportFlow.serviceKind === TransportServiceKind.jobsiteVehicle) {
                const jobsiteVehiclesCountByGroup = new Map<VehicleTypeGroupId, number>();

                const old4X2Count = entityData.jobsiteVehiclesCountByGroup.get(VehicleTypeGroupId.Id4x2);
                jobsiteVehiclesCountByGroup.set(VehicleTypeGroupId.Id4x2, old4X2Count + _4X2Count);

                const old6X2Count = entityData.jobsiteVehiclesCountByGroup.get(VehicleTypeGroupId.Id6x4);
                jobsiteVehiclesCountByGroup.set(VehicleTypeGroupId.Id6x4, old6X2Count + _6X4Count);

                const old8X2Count = entityData.jobsiteVehiclesCountByGroup.get(VehicleTypeGroupId.Id8x4);
                jobsiteVehiclesCountByGroup.set(VehicleTypeGroupId.Id8x4, old8X2Count + _8X4Count);

                const oldSemiCount = entityData.jobsiteVehiclesCountByGroup.get(VehicleTypeGroupId.IdSemi);
                jobsiteVehiclesCountByGroup.set(VehicleTypeGroupId.IdSemi, oldSemiCount + _semiCount);

                const oldOthersCount = entityData.jobsiteVehiclesCountByGroup.get(VehicleTypeGroupId.Others);
                jobsiteVehiclesCountByGroup.set(VehicleTypeGroupId.Others, oldOthersCount + _others)

                entityData.jobsiteVehiclesCountByGroup = jobsiteVehiclesCountByGroup;
            }

            if (transportFlow.isPerishableProduct && transportFlow.serviceKind === TransportServiceKind.delivery) {
                entityData.hasPerishableProductFlows = true;
            }

            if (!transportFlow.isPerishableProduct && transportFlow.serviceKind === TransportServiceKind.delivery) {
                entityData.hasNotPerishableProductFlows = true;
            }

            const hasFlowsWithServiceKind = entityData.hasFlowsWithServiceKind ?? new Map<TransportServiceKind, boolean>();
            hasFlowsWithServiceKind.set(transportFlow.serviceKind, true);
            entityData.hasFlowsWithServiceKind = hasFlowsWithServiceKind;

            const hasFlowsWithPriority = entityData.hasFlowsWithPriority ?? new Map<TransportPriority, boolean>();
            hasFlowsWithPriority.set(transportFlow.priority, true);
            entityData.hasFlowsWithPriority = hasFlowsWithPriority;

            entityData.beneficiaries = entityData.beneficiaries ?? [];

            if ((transportFlow.beneficiaryName || transportFlow.beneficiaryPhoneNumber)
                && entityData.beneficiaries.findIndex(x => x.beneficiaryName === transportFlow.beneficiaryName && x.phoneNumber === transportFlow.beneficiaryPhoneNumber) === -1) {
                entityData.beneficiaries.push({
                    beneficiaryName: transportFlow.beneficiaryName,
                    phoneNumber: transportFlow.beneficiaryPhoneNumber
                });
            }

            map.set(id, entityData);

            return entityData;
        }

        return null;
    }

    private updateShapes = (map: Map<string, FeatureEntityDataModel>, datasource: source.DataSource, entityType: string): void => {
        map.forEach((entityData: FeatureEntityDataModel, id: string) => {
            const shapeId = this.createFeaturePinId(id, entityType, entityData.position);
            const shape = datasource.getShapeById(shapeId);

            if (shape) {
                this.setPropertiesCached(entityData, shapeId);
            }
        });
    }

    setPropertiesCached = (entityData: FeatureEntityDataModel, shapeId: string): void => {
        this.state.shapePropertiesCache.set(shapeId, entityData);
    }

    private updateColasFactoryEndPointsShapes = (datasource: source.DataSource): void => {
        const shapes = datasource.getShapes();
        shapes.forEach((shape) => {
            const shapeId = shape.getId() as string;
            const endpointData: FeatureEntityDataModel = this.state.shapePropertiesCache.get(shapeId);
            if (endpointData.entityType === ColasFactoryEndpointEntityType) {
                const colasFactoryShapeId = this.createFeaturePinId(endpointData.siteId, ColasFactoryEntityType, null);
                const colasFactoryShape = datasource.getShapeById(colasFactoryShapeId);
                if (colasFactoryShape) {
                    const colasFactoryData: FeatureEntityDataModel = this.state.shapePropertiesCache.get(colasFactoryShapeId);
                    endpointData.deliveryQuantityByPriority = colasFactoryData.deliveryQuantityByPriority;
                    endpointData.loadingQuantityByPriority = colasFactoryData.loadingQuantityByPriority;
                    endpointData.planningVehiclesCountByGroup = colasFactoryData.planningVehiclesCountByGroup;
                    endpointData.jobsiteVehiclesCountByGroup = colasFactoryData.jobsiteVehiclesCountByGroup;
                    endpointData.senderOnFlows = colasFactoryData.senderOnFlows;
                    endpointData.receiverOnFlows = colasFactoryData.receiverOnFlows;
                    //NOTE CMA HGA on a vu que les properties sont sérialiser (JSON) au niveau du shape, d'où la nécessité de la mettre à jours
                    this.setPropertiesCached(endpointData, shapeId);
                }
            }
        });
    }

    private initialPriorityMap = (map: Map<TransportPriority, number>): void => {
        map.set(TransportPriority.high, 0);
        map.set(TransportPriority.medium, 0);
        map.set(TransportPriority.low, 0);
    }

    private initialVehiclesCountByGroup = (map: Map<VehicleTypeGroupId, number>): void => {
        map.set(VehicleTypeGroupId.Id4x2, 0);
        map.set(VehicleTypeGroupId.Id6x4, 0);
        map.set(VehicleTypeGroupId.Id8x4, 0);
        map.set(VehicleTypeGroupId.IdSemi, 0);
        map.set(VehicleTypeGroupId.Others, 0);
    }

    getHtmlContent = (icon: string, cssClass: string, deliveryValue?: string, loadingValue?: string): string => {
        let htmlContent = `<div class="${cssClass}">`;

        if (deliveryValue) {
            htmlContent += `<div class="delivery-section">${deliveryValue}</div>`
        }

        if (loadingValue) {
            htmlContent += `<div class="loading-section">${loadingValue}</div>`
        }

        htmlContent += `<img src="${icon}" /></div>`;
        return htmlContent;
    }

    createPinFeature = (map: AzureMap, datasource: source.DataSource, position: data.Position, entityType: string, entityData: FeatureEntityDataModel): void => {
        const shapeId = this.createFeaturePinId(entityData.siteId, entityType, position);
        const entityTypeData: FeatureTypeData = { entityType: entityType };
        const pin = new data.Feature(new data.Point(position), entityTypeData, shapeId);
        datasource.add(pin);
        this.state.shapePropertiesCache.set(shapeId, entityData);

        const pinMarker = new HtmlMarker({
            htmlContent: entityData.htmlContent,
            position: position
        }) as HtmlMarkerExtended;

        pinMarker.entityData = entityData;
        pinMarker.entityType = entityType;

        map.events.add('click', pinMarker, () => this.handleClickMarker(datasource, entityData.siteId, entityData.entityType, entityData.position));
        map.markers.add(pinMarker);
    }

    createFeaturePinId = (siteId: string, entityType: string, position: data.Position): string => {
        if (entityType === ColasFactoryEndpointEntityType)
            return `${entityType}#${siteId}(${position})`;

        return `${entityType}#${siteId}`;
    }

    handleClickMarker = (datasource: source.DataSource, id: string, entityType: string, position: data.Position): void => {
        if (this.state.positionCLickedId === id) {
            this.setState({ positionCLickedId: null, isDawerDetailsOpened: false, drawerData: null, isMarkerSelected: false, relatedEntitiesIdsMap: new Map<string, string>() });
            const { isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice } = this.state;

            this.updateEntitiesWhenNoSelection(this.state.map, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
            this.updateMarkersHtmlContents(this.state.map.markers);
            return;
        }

        const shapes = datasource.getShapes();
        const shapeId = this.createFeaturePinId(id, entityType, position);
        const clickedShape = datasource.getShapeById(shapeId)

        if (clickedShape) {
            const entityData: FeatureEntityDataModel = this.state.shapePropertiesCache.get(shapeId);
            entityData.cssClass = entityData.cssClass.contains(' matches-search-text') ? "pin-marker selected matches-search-text" : "pin-marker selected";

            const { isDeliverySelected, isLoadingSelected, displayedNumberChoice, remainingQuantityChoice, transportFlowsData, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, filtersSelected, vehicleTypeGroupFilterOptions } = this.state;
            if (isDeliverySelected || isLoadingSelected) {
                if (displayedNumberChoice === DisplayedPropertyTypeChoice.Quantities) {
                    this.updateShapeQuantities(entityData, shapeId, transportFlowsData, isDeliverySelected, isLoadingSelected, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
                }
                else if (displayedNumberChoice === DisplayedPropertyTypeChoice.NumberOfVehicles) {
                    this.updateShapeVehiclesNumber(entityData, shapeId, transportFlowsData, isDeliverySelected, isLoadingSelected, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, filtersSelected.length !== vehicleTypeGroupFilterOptions.length ? filtersSelected : null);
                }
            }
            else {
                entityData.htmlContent = this.getHtmlContent(entityData.icon, entityData.cssClass);
            }

            const relatedEntitiesIdsMap = new Map<string, string>();
            if (entityType !== AgencyEntityType && entityType !== ExternalTransporterEntityType) {
                entityData.receiverOnFlows.forEach((flow) => {
                    if (flow.senderFeature !== null && flow.senderFeature.siteId !== entityData.siteId) {
                        if (!relatedEntitiesIdsMap.has(flow.senderFeature.siteId))
                            relatedEntitiesIdsMap.set(flow.senderFeature.siteId, flow.senderFeature.siteId);
                    }

                    if (flow.receiverFeature !== null && flow.receiverFeature.siteId !== entityData.siteId) {
                        if (!relatedEntitiesIdsMap.has(flow.receiverFeature.siteId))
                            relatedEntitiesIdsMap.set(flow.receiverFeature.siteId, flow.receiverFeature.siteId);
                    }
                });

                entityData.senderOnFlows.forEach((flow) => {
                    if (flow.senderFeature !== null && flow.senderFeature.siteId !== entityData.siteId) {
                        if (!relatedEntitiesIdsMap.has(flow.senderFeature.siteId))
                            relatedEntitiesIdsMap.set(flow.senderFeature.siteId, flow.senderFeature.siteId);
                    }

                    if (flow.receiverFeature !== null && flow.receiverFeature.siteId !== entityData.siteId) {
                        if (!relatedEntitiesIdsMap.has(flow.receiverFeature.siteId))
                            relatedEntitiesIdsMap.set(flow.receiverFeature.siteId, flow.receiverFeature.siteId);
                    }
                });

                shapes.forEach((shape: Shape) => {
                    const shapeId = shape.getId() as string;
                    const unrelatedEntityData: FeatureEntityDataModel = this.state.shapePropertiesCache.get(shapeId);
                    const isRelatedEntity = relatedEntitiesIdsMap.has(unrelatedEntityData.siteId);
                    if (entityData.siteId !== unrelatedEntityData.siteId) {
                        let cssClass = "pin-marker";
                        if (unrelatedEntityData.entityType && !isRelatedEntity) {
                            cssClass += " stump";
                        }
                        unrelatedEntityData.cssClass = unrelatedEntityData.cssClass.contains(' matches-search-text') ? cssClass + " matches-search-text" : cssClass;

                        if ((isDeliverySelected || isLoadingSelected) && isRelatedEntity) {
                            if (displayedNumberChoice === DisplayedPropertyTypeChoice.Quantities) {
                                this.updateShapeQuantities(unrelatedEntityData, shapeId, transportFlowsData, isDeliverySelected, isLoadingSelected, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
                            }
                            else if (displayedNumberChoice === DisplayedPropertyTypeChoice.NumberOfVehicles) {
                                this.updateShapeVehiclesNumber(unrelatedEntityData, shapeId, transportFlowsData, isDeliverySelected, isLoadingSelected, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, filtersSelected.length !== vehicleTypeGroupFilterOptions.length ? filtersSelected : null);
                            }
                        }
                        else {
                            unrelatedEntityData.htmlContent = this.getHtmlContent(unrelatedEntityData.icon, unrelatedEntityData.cssClass);
                            //TODO HGA CMA on devrais mettre shapeId dans entityData pour éviter de le récalculer parfois et de faire l'appel de getId() qui risque d'etre couteuse
                            this.setPropertiesCached(unrelatedEntityData, shapeId);
                        }
                    }
                });
            }
            else {
                shapes.forEach((shape: Shape) => {
                    if (shape !== clickedShape) {
                        const shapeId = shape.getId() as string;
                        const otherEntityData: FeatureEntityDataModel = this.state.shapePropertiesCache.get(shapeId);
                        otherEntityData.cssClass = otherEntityData.cssClass.contains(' matches-search-text') ? "pin-marker" + " matches-search-text" : "pin-marker";

                        if (isDeliverySelected || isLoadingSelected) {
                            if (displayedNumberChoice === DisplayedPropertyTypeChoice.Quantities) {
                                this.updateShapeQuantities(otherEntityData, shapeId, transportFlowsData, isDeliverySelected, isLoadingSelected, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
                            }
                            else if (displayedNumberChoice === DisplayedPropertyTypeChoice.NumberOfVehicles) {
                                this.updateShapeVehiclesNumber(otherEntityData, shapeId, transportFlowsData, isDeliverySelected, isLoadingSelected, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, filtersSelected.length !== vehicleTypeGroupFilterOptions.length ? filtersSelected : null);
                            }
                        }
                        else {
                            otherEntityData.htmlContent = this.getHtmlContent(otherEntityData.icon, otherEntityData.cssClass);
                            //TODO HGA CMA on devrais mettre shapeId dans entityData pour éviter de le récalculer parfois et de faire l'appel de getId() qui risque d'etre couteuse
                            this.setPropertiesCached(otherEntityData, shapeId);
                        }
                    }
                });
            }

            this.setPropertiesCached(entityData, shapeId);
            this.updateMarkersHtmlContents(this.state.map.markers);

            this.setState({
                positionCLickedId: id,
                isDawerDetailsOpened: true,
                drawerData: entityData,
                isMarkerSelected: true,
                relatedEntitiesIdsMap: relatedEntitiesIdsMap
            });

            if (!entityData.isSiteBulletinBoardRemarksLoaded) {
                CartographyApiClient.GetSiteBulletinBoard(entityData.siteBulletinBoardId)
                    .then((res) => {
                        let siteBulletinBoard: SiteBulletinBoardLightModel = null;
                        let remarks = "";

                        if (res.data) {
                            siteBulletinBoard = res.data;
                        }

                        if (siteBulletinBoard) {
                            remarks = siteBulletinBoard.remarks;
                        }

                        entityData.siteBulletinBoardRemarks = remarks;
                        entityData.isSiteBulletinBoardRemarksLoaded = true;

                        this.setPropertiesCached(entityData, shapeId);

                        this.setState({ drawerData: entityData });
                        return;
                    });
            }
        }
    }

    //TODO HGA CMA Chercher à optimiser ces deux methodes pour ne pa recalculer le html si pas necessaire
    updateMarkersHtmlContents = (markers: HtmlMarkerManager): void => {
        markers.getMarkers().forEach((marker) => {
            const htmlMarker = marker as HtmlMarkerExtended;
            if (htmlMarker.entityType) {
                const shapeId = this.createFeaturePinId(htmlMarker.entityData.siteId, htmlMarker.entityType, htmlMarker.entityData.position);
                const props: FeatureEntityDataModel = this.state.shapePropertiesCache.get(shapeId);
                htmlMarker.setOptions({
                    htmlContent: props.htmlContent
                });
            }
        });
    }

    handleMapClicked = (): void => {
        if (!this.state.isMarkerSelected && this.state.isDawerDetailsOpened) {
            this.setState({ positionCLickedId: null, isDawerDetailsOpened: false, drawerData: null, relatedEntitiesIdsMap: new Map<string, string>() });
            const { isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice } = this.state;

            this.updateEntitiesWhenNoSelection(this.state.map, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
            this.updateMarkersHtmlContents(this.state.map.markers);
        }

        if (this.state.isMarkerSelected) {
            this.setState({ isMarkerSelected: false });
        }
    }

    handleDatepickerChange = (event: DatePickerChangeEvent): void => {
        let date: Date = event.value;
        //When the popup is visible
        if (event.target.state.show) {
            (this.datePickerRef.current as any).shouldFocusDateInput = false;
            this.handleDatePickerBlur(date);
        }
        else {
            if (!date)
                date = new Date(null);

            (this.datePickerRef.current as any).handleBlur = () => {
                this.handleDatePickerBlur(date);
            }
        }
    }

    handleDatePickerBlur = (date: Date): void => {
        if (!Date.equals(date, this.state.date)) {
            const { map, searchText } = this.state;
            map.clear();
            map.dispose();

            this.setState({
                positionCLickedId: null,
                isDawerDetailsOpened: false,
                drawerData: null,
                isMarkerSelected: false,
                relatedEntitiesIdsMap: new Map<string, string>()
            });

            const mapCamera = map.getCamera();
            this.initMap(date, mapCamera.zoom, mapCamera.center, searchText);
        }
    }

    handleExternalTransportersChanged = (): void => {
        LocalStorage.SetItem(ModuleKey, ExternalTransportersButtonStorageKey, '' + !this.state.isExternalTransportersButtonActive);
        const isExternalTransportersButtonActive = !this.state.isExternalTransportersButtonActive;
        this.setState({ isExternalTransportersButtonActive: isExternalTransportersButtonActive });

        const datasource = this.state.map.sources.getById(DataSourceId) as source.DataSource;
        if (isExternalTransportersButtonActive) {
            this.state.externalTransportersData.forEach((externalTransporter: TransporterLightModel) => {
                if (externalTransporter.coordinates.longitude && externalTransporter.coordinates.latitude) {
                    const position = new data.Position(externalTransporter.coordinates.longitude, externalTransporter.coordinates.latitude);
                    const entityData: FeatureEntityDataModel = this.createTransporterFeatureData(externalTransporter, position);
                    this.createPinFeature(this.state.map, datasource, position, ExternalTransporterEntityType, entityData);
                }
            });

            return;
        }

        //Supprimer les Pins + markers qui correspondent aux transporteurs
        datasource.getShapes().forEach((shape) => {
            const entityData = this.state.shapePropertiesCache.get(shape.getId() as string);
            if (entityData.entityType === ExternalTransporterEntityType)
                datasource.remove(shape);
        });

        const markers = this.state.map.markers;
        markers.getMarkers().forEach((marker) => {
            const htmlMarker = marker as HtmlMarkerExtended;
            if (htmlMarker.entityType === ExternalTransporterEntityType) {
                markers.remove(htmlMarker);
            }
        });
    }

    handleColasAgenciesChanged = (): void => {
        LocalStorage.SetItem(ModuleKey, ColasAgenciesButtonStorageKey, '' + !this.state.isColasAgenciesButtonActive);
        const isColasAgenciesButtonActive = !this.state.isColasAgenciesButtonActive;
        this.setState({ isColasAgenciesButtonActive: isColasAgenciesButtonActive });

        const datasource = this.state.map.sources.getById(DataSourceId) as source.DataSource;
        if (isColasAgenciesButtonActive) {
            this.state.colasAgenciesData.forEach((agency: WorkAgencyLightModel) => {
                if (agency.coordinates.longitude && agency.coordinates.latitude) {
                    const position = new data.Position(agency.coordinates.longitude, agency.coordinates.latitude);
                    const entityData: FeatureEntityDataModel = this.createAgencyFeatureData(agency, position);
                    this.createPinFeature(this.state.map, datasource, position, AgencyEntityType, entityData);
                }
            });

            return;
        }

        //Supprimer les Pins + markers qui correspondent aux agences Colas
        datasource.getShapes().forEach((shape) => {
            const entityData = this.state.shapePropertiesCache.get(shape.getId() as string);
            if (entityData.entityType === AgencyEntityType)
                datasource.remove(shape);
        });

        const markers = this.state.map.markers;
        markers.getMarkers().forEach((marker) => {
            const htmlMarker = marker as HtmlMarkerExtended;
            if (htmlMarker.entityType === AgencyEntityType) {
                markers.remove(htmlMarker);
            }
        });
    }

    updateSiteBulletinBoardRemarks = (id: string, remarks: string): void => {
        const datasource = this.state.map.sources.getById(DataSourceId) as source.DataSource;
        const shapes = datasource.getShapes();
        shapes.forEach((shape: Shape) => {
            const shapeId = shape.getId() as string;
            const entityData = this.state.shapePropertiesCache.get(shapeId);
            if (entityData?.siteId === id) {
                entityData.siteBulletinBoardRemarks = remarks;
                this.setPropertiesCached(entityData, shapeId);
            }
        });
    }

    handlePerishableChange = (): void => {
        const isPerishableSelected = !this.state.isPerishableSelected;
        this.setState({ isPerishableSelected: isPerishableSelected });

        const { positionCLickedId, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice } = this.state;

        this.handleFlowFilterChange(this.state.map, positionCLickedId, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
    }

    handleNotPerishableChange = (): void => {
        const isNotPerishableSelected = !this.state.isNotPerishableSelected;
        this.setState({ isNotPerishableSelected: isNotPerishableSelected });

        const { positionCLickedId, isPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice } = this.state;

        this.handleFlowFilterChange(this.state.map, positionCLickedId, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
    }

    handleRemovalChange = (): void => {
        const isRemovalSelected = !this.state.isRemovalSelected;
        this.setState({ isRemovalSelected: isRemovalSelected });

        const { positionCLickedId, isPerishableSelected, isNotPerishableSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice } = this.state;

        this.handleFlowFilterChange(this.state.map, positionCLickedId, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
    }

    handleJobsiteChange = (): void => {
        const isJobsiteSelected = !this.state.isJobsiteSelected;
        this.setState({ isJobsiteSelected: isJobsiteSelected });

        const { positionCLickedId, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice } = this.state;

        this.handleFlowFilterChange(this.state.map, positionCLickedId, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
    }

    handelSelectAllServiceKind = (): void => {
        this.setState({ isPerishableSelected: true, isNotPerishableSelected: true, isRemovalSelected: true, isJobsiteSelected: true });

        const { positionCLickedId, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice } = this.state;

        this.handleFlowFilterChange(this.state.map, positionCLickedId, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, true, true, true, true, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
    }

    handleLowPriorityChanged = (): void => {
        const isLowPrioritySelected = !this.state.isLowPrioritySelected;
        this.setState({ isLowPrioritySelected: isLowPrioritySelected });

        const { positionCLickedId, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isMediumPrioritySelected, isHighPrioritySelected, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice } = this.state;

        this.handleFlowFilterChange(this.state.map, positionCLickedId, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
    }

    handleMediumPriorityChanged = (): void => {
        const isMediumPrioritySelected = !this.state.isMediumPrioritySelected;
        this.setState({ isMediumPrioritySelected: isMediumPrioritySelected });

        const { positionCLickedId, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isHighPrioritySelected, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice } = this.state;

        this.handleFlowFilterChange(this.state.map, positionCLickedId, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
    }

    handleHighPriorityChanged = (): void => {
        const isHighPrioritySelected = !this.state.isHighPrioritySelected;
        this.setState({ isHighPrioritySelected: isHighPrioritySelected });

        const { positionCLickedId, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice } = this.state;

        this.handleFlowFilterChange(this.state.map, positionCLickedId, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
    }

    handleSelectAllPriority = (): void => {
        this.setState({ isLowPrioritySelected: true, isMediumPrioritySelected: true, isHighPrioritySelected: true });

        const { positionCLickedId, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice } = this.state;

        this.handleFlowFilterChange(this.state.map, positionCLickedId, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, true, true, true);
    }

    handleDeliveryChanged = (): void => {
        const isDeliverySelected = !this.state.isDeliverySelected;
        this.setState({ isDeliverySelected: isDeliverySelected });

        const { positionCLickedId, transportFlowsData, displayedNumberChoice, isLoadingSelected, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected } = this.state;

        this.handleFlowFilterChange(this.state.map, positionCLickedId, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
    }

    handleLoadingChanged = (): void => {
        const isLoadingSelected = !this.state.isLoadingSelected;
        this.setState({ isLoadingSelected: isLoadingSelected });

        const { positionCLickedId, transportFlowsData, isDeliverySelected, displayedNumberChoice, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected } = this.state;

        this.handleFlowFilterChange(this.state.map, positionCLickedId, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
    }

    handleRemainingQuantityChoiceChanged = (e: ChangeEvent<HTMLInputElement>): void => {
        const remainingQuantityChoice = e.target.value as DisplayedQuantityChoice;
        this.setState({ remainingQuantityChoice: remainingQuantityChoice });

        const { positionCLickedId, transportFlowsData, displayedNumberChoice, isDeliverySelected, isLoadingSelected, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected } = this.state;

        this.handleFlowFilterChange(this.state.map, positionCLickedId, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
    }

    handleDisplayedNumberChoiceChanged = (e: ChangeEvent<HTMLInputElement>): void => {
        const displayedNumberChoice = e.target.value as DisplayedPropertyTypeChoice;

        if (displayedNumberChoice === DisplayedPropertyTypeChoice.Quantities) {
            //si on clique sur "Tonnage", on forcer la mise à jour de vehicleTypeGroupFilterOptions pour reinitialiser la dropdownliste des types de famille des camions
            //la mise à jour des options force la réinialisation de la dropdownliste avec tous les options cochés
            this.setState({
                displayedNumberChoice: displayedNumberChoice,
                filtersSelected: this.state.vehicleTypeGroupFilterOptions.map(x => x.value),
                vehicleTypeGroupFilterOptions: [...this.state.vehicleTypeGroupFilterOptions]
            });
        } else {
            this.setState({ displayedNumberChoice: displayedNumberChoice });
        }

        const { positionCLickedId, transportFlowsData, isDeliverySelected, isLoadingSelected, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected } = this.state;

        this.handleFlowFilterChange(this.state.map, positionCLickedId, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
    }

    private handleFlowFilterChange(map: AzureMap, positionCLickedId: string, isDeliverySelected: boolean, isLoadingSelected: boolean, displayedNumberChoice: DisplayedPropertyTypeChoice, transportFlowsData: TransportFlowExtended[], remainingQuantityChoice: DisplayedQuantityChoice, isPerishableSelected: boolean, isNotPerishableSelected: boolean, isRemovalSelected: boolean, isJobsiteSelected: boolean, isLowPrioritySelected: boolean, isMediumPrioritySelected: boolean, isHighPrioritySelected: boolean) {
        if (positionCLickedId) {
            this.updateEntitiesWhenSelected(map, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
        }
        else {
            this.updateEntitiesWhenNoSelection(map, isDeliverySelected, isLoadingSelected, displayedNumberChoice, transportFlowsData, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
        }

        this.updateMarkersHtmlContents(map.markers);
    }

    updateEntitiesWhenSelected = (map: AzureMap,
        isDeliverySelected: boolean,
        isLoadingSelected: boolean,
        displayedNumberChoice: DisplayedPropertyTypeChoice,
        transportFlowsData: TransportFlowExtended[],
        remainingQuantityChoice: DisplayedQuantityChoice,
        isPerishableSelected: boolean,
        isNotPerishableSelected: boolean,
        isRemovalSelected: boolean,
        isJobsiteSelected: boolean,
        isLowPrioritySelected: boolean,
        isMediumPrioritySelected: boolean,
        isHighPrioritySelected: boolean): void => {
        const datasource = map.sources.getById(DataSourceId) as source.DataSource;
        const shapes = datasource.getShapes();
        shapes.forEach((shape: Shape) => {
            const shapeId = shape.getId() as string;
            const entityData: FeatureEntityDataModel = this.state.shapePropertiesCache.get(shapeId);

            let cssClass = "pin-marker";
            if (entityData.cssClass.contains(' matches-search-text')) {
                cssClass += ' matches-search-text';
            }
            if (entityData.cssClass.contains(' selected')) {
                cssClass += ' selected';
            }
            entityData.cssClass = cssClass;

            if (this.state.relatedEntitiesIdsMap.has(entityData.siteId) || entityData.siteId === this.state.positionCLickedId) {
                if (isDeliverySelected || isLoadingSelected) {
                    if (displayedNumberChoice === DisplayedPropertyTypeChoice.Quantities) {
                        this.updateShapeQuantities(entityData, shapeId, transportFlowsData, isDeliverySelected, isLoadingSelected, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
                    }
                    else if (displayedNumberChoice === DisplayedPropertyTypeChoice.NumberOfVehicles) {
                        this.updateShapeVehiclesNumber(entityData, shapeId, transportFlowsData, isDeliverySelected, isLoadingSelected, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, this.state.filtersSelected.length !== this.state.vehicleTypeGroupFilterOptions.length ? this.state.filtersSelected : null);
                    }
                }
                else {
                    entityData.htmlContent = this.getHtmlContent(entityData.icon, cssClass);
                    //TODO HGA CMA on devrais mettre shapeId dans entityData pour éviter de le récalculer parfois et de faire l'appel de getId() qui risque d'etre couteuse
                    this.setPropertiesCached(entityData, shapeId);
                }
            }
            else {
                cssClass += " stump";

                if (entityData.cssClass.contains(' matches-search-text')) {
                    cssClass += ' matches-search-text';
                }
                entityData.cssClass = cssClass;
                entityData.htmlContent = this.getHtmlContent(entityData.icon, cssClass);
                //TODO HGA CMA on devrais mettre shapeId dans entityData pour éviter de le récalculer parfois et de faire l'appel de getId() qui risque d'etre couteuse
                this.setPropertiesCached(entityData, shapeId);
            }
        });
    }

    updateEntitiesWhenNoSelection = (map: AzureMap,
        isDeliverySelected: boolean,
        isLoadingSelected: boolean,
        displayedNumberChoice: DisplayedPropertyTypeChoice,
        transportFlowsData: TransportFlowExtended[],
        remainingQuantityChoice: DisplayedQuantityChoice,
        isPerishableSelected: boolean,
        isNotPerishableSelected: boolean,
        isRemovalSelected: boolean,
        isJobsiteSelected: boolean,
        isLowPrioritySelected: boolean,
        isMediumPrioritySelected: boolean,
        isHighPrioritySelected: boolean): void => {
        const datasource = map.sources.getById(DataSourceId) as source.DataSource;
        const shapes = datasource.getShapes();
        shapes.forEach((shape: Shape) => {
            const shapeId = shape.getId() as string;
            const entityData: FeatureEntityDataModel = this.state.shapePropertiesCache.get(shapeId);
            let cssClass = "";

            const isEntityHasPerishableProductFlows: boolean = entityData.hasFlowsWithServiceKind?.has(TransportServiceKind.delivery) && entityData.hasPerishableProductFlows;
            const isEntityHasNotPerishableProductFlows: boolean = entityData.hasFlowsWithServiceKind?.has(TransportServiceKind.delivery) && entityData.hasNotPerishableProductFlows;
            const isEntityHasRemovalFlows: boolean = entityData.hasFlowsWithServiceKind?.has(TransportServiceKind.removal);
            const isEntityHasJobsiteFlows: boolean = entityData.hasFlowsWithServiceKind?.has(TransportServiceKind.jobsiteVehicle);
            const isEntityHasLowFlows: boolean = entityData.hasFlowsWithPriority?.has(TransportPriority.low);
            const isEntityHasMediumFlows: boolean = entityData.hasFlowsWithPriority?.has(TransportPriority.medium);
            const isEntityHasHighFlows: boolean = entityData.hasFlowsWithPriority?.has(TransportPriority.high);

            let filters: boolean = null;

            if (isPerishableSelected) {
                filters = filters || isEntityHasPerishableProductFlows;
            }

            if (isNotPerishableSelected) {
                filters = filters || isEntityHasNotPerishableProductFlows;
            }

            if (isRemovalSelected) {
                filters = filters || isEntityHasRemovalFlows;
            }

            if (isJobsiteSelected) {
                filters = filters || isEntityHasJobsiteFlows;
            }

            if (isLowPrioritySelected) {
                filters = filters || isEntityHasLowFlows;
            }

            if (isMediumPrioritySelected) {
                filters = filters || isEntityHasMediumFlows;
            }

            if (isHighPrioritySelected) {
                filters = filters || isEntityHasHighFlows;
            }

            if (filters === true || filters === null) {
                cssClass = entityData.cssClass.contains(' matches-search-text') ? "pin-marker matches-search-text" : "pin-marker";
            }
            else {
                cssClass = entityData.cssClass.contains(' matches-search-text') ? "pin-marker stump matches-search-text" : "pin-marker stump";
            }

            entityData.cssClass = cssClass;

            if (isDeliverySelected || isLoadingSelected) {
                if (displayedNumberChoice === DisplayedPropertyTypeChoice.Quantities) {
                    this.updateShapeQuantities(entityData, shapeId, transportFlowsData, isDeliverySelected, isLoadingSelected, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
                }
                else if (displayedNumberChoice === DisplayedPropertyTypeChoice.NumberOfVehicles) {
                    this.updateShapeVehiclesNumber(entityData, shapeId, transportFlowsData, isDeliverySelected, isLoadingSelected, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, this.state.filtersSelected.length !== this.state.vehicleTypeGroupFilterOptions.length ? this.state.filtersSelected : null);
                }
            }
            else {
                entityData.htmlContent = this.getHtmlContent(entityData.icon, entityData.cssClass);
                this.setPropertiesCached(entityData, shapeId);
            }
        });
    }

    updateShapeQuantities(entityData: FeatureEntityDataModel,
        shapeId: string,
        transportFlowsData: TransportFlowExtended[],
        isDeliverySelected: boolean,
        isLoadingSelected: boolean,
        remainingQuantityChoice: DisplayedQuantityChoice,
        isPerishableSelected: boolean,
        isNotPerishableSelected: boolean,
        isRemovalSelected: boolean,
        isJobsiteSelected: boolean,
        isLowPrioritySelected: boolean,
        isMediumPrioritySelected: boolean,
        isHighPrioritySelected: boolean) {
        let deliveryValue = null;
        let loadingValue = null;

        if (isDeliverySelected) {
            entityData.remainingDeliveryQuantity = null;
            entityData.requestedDeliveryQuantity = null;
            entityData.deliveredDeliveryQuantity = null;

            transportFlowsData.forEach((tf) => {
                const doesMatch = this.doesEntityMatchFlow(entityData, tf, false, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected)
                if (doesMatch) {
                    entityData.remainingDeliveryQuantity += tf.remainingQuantity;
                    entityData.requestedDeliveryQuantity += tf.requestedQuantity;
                    entityData.deliveredDeliveryQuantity += tf.deliveredQuantity;
                }
            });

            deliveryValue = remainingQuantityChoice === DisplayedQuantityChoice.RemainingQuantityToBeDelivered ? (entityData.requestedDeliveryQuantity != null && entityData.deliveredDeliveryQuantity != null ? `${(entityData.requestedDeliveryQuantity - entityData.deliveredDeliveryQuantity).toStringFixed("FR-fr", 2)} T` : null)
                : (DisplayedQuantityChoice.RemainingQuantityToBePlanned ? (entityData.remainingDeliveryQuantity != null ? `${entityData.remainingDeliveryQuantity.toStringFixed("FR-fr", 2)} T` : null) : null);
        }

        if (isLoadingSelected) {
            entityData.remainingLoadingQuantity = null;
            entityData.requestedLoadingQuantity = null;
            entityData.deliveredLoadingQuantity = null;

            transportFlowsData.forEach((tf) => {
                const doesMatch = this.doesEntityMatchFlow(entityData, tf, true, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected)
                if (doesMatch) {
                    entityData.remainingLoadingQuantity += tf.remainingQuantity;
                    entityData.requestedLoadingQuantity += tf.requestedQuantity;
                    entityData.deliveredLoadingQuantity += tf.deliveredQuantity;
                }
            });

            loadingValue = remainingQuantityChoice === DisplayedQuantityChoice.RemainingQuantityToBeDelivered ? (entityData.requestedLoadingQuantity != null && entityData.deliveredLoadingQuantity != null ? `${(entityData.requestedLoadingQuantity - entityData.deliveredLoadingQuantity).toStringFixed("FR-fr", 2)} T` : null)
                : (DisplayedQuantityChoice.RemainingQuantityToBePlanned ? (entityData.remainingLoadingQuantity != null ? `${entityData.remainingLoadingQuantity.toStringFixed("FR-fr", 2)} T` : null) : null);
        }

        entityData.htmlContent = this.getHtmlContent(entityData.icon, entityData.cssClass, deliveryValue ?? null, loadingValue ?? null);
        this.setPropertiesCached(entityData, shapeId);
    }

    updateShapeVehiclesNumber = (entityData: FeatureEntityDataModel,
        shapeId: string,
        transportFlowsData: TransportFlowExtended[],
        isDeliverySelected: boolean,
        isLoadingSelected: boolean,
        isPerishableSelected: boolean,
        isNotPerishableSelected: boolean,
        isRemovalSelected: boolean,
        isJobsiteSelected: boolean,
        isLowPrioritySelected: boolean,
        isMediumPrioritySelected: boolean,
        isHighPrioritySelected: boolean,
        vehicleTypeGroupIds: Array<string>): void => {
        let deliveryValue = null;
        let loadingValue = null;

        if (isDeliverySelected) {
            entityData.deliveryVehicleNumber = null;
            transportFlowsData.forEach((tf) => {
                const doesMatch = this.doesEntityMatchFlow(entityData, tf, false, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected)
                if (doesMatch) {
                    if (vehicleTypeGroupIds) {
                        entityData.deliveryVehicleNumber += Utilities.count(tf.planningVehicles, x => vehicleTypeGroupIds.includes(x.vehicleTypeGroupId));
                    }
                    else {
                        entityData.deliveryVehicleNumber += tf.planningVehicles.length;
                    }

                    deliveryValue = entityData.deliveryVehicleNumber;
                }
            });
        }

        if (isLoadingSelected) {
            entityData.loadingVehicleNumber = null;
            transportFlowsData.forEach((tf) => {
                const doesMatch = this.doesEntityMatchFlow(entityData, tf, true, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected)
                if (doesMatch) {
                    if (vehicleTypeGroupIds) {
                        entityData.loadingVehicleNumber += Utilities.count(tf.planningVehicles, x => vehicleTypeGroupIds.includes(x.vehicleTypeGroupId));
                    }
                    else {
                        entityData.loadingVehicleNumber += tf.planningVehicles.length;
                    }

                    loadingValue = entityData.loadingVehicleNumber;
                }
            });
        }

        entityData.htmlContent = this.getHtmlContent(entityData.icon, entityData.cssClass, deliveryValue ?? null, loadingValue ?? null);
        this.setPropertiesCached(entityData, shapeId);
    }

    doesEntityMatchFlow = (entityData: FeatureEntityDataModel,
        tf: TransportFlowExtended,
        isSenderSite: boolean,
        isPerishableSelected: boolean,
        isNotPerishableSelected: boolean,
        isRemovalSelected: boolean,
        isJobsiteSelected: boolean,
        isLowPrioritySelected: boolean,
        isMediumPrioritySelected: boolean,
        isHighPrioritySelected: boolean
    ): boolean => {
        const isIdsMatched = isSenderSite ? tf.senderSite.identifiers === entityData.siteId : tf.receiverSite.identifiers === entityData.siteId;
        if (isIdsMatched) {
            const isFlowHasPerishableProductFlows: boolean = tf.serviceKind === TransportServiceKind.delivery && tf.isPerishableProduct;
            const isFlowHasNotPerishableProductFlows: boolean = tf.serviceKind === TransportServiceKind.delivery && !tf.isPerishableProduct;
            const isFlowHasRemovalFlows: boolean = tf.serviceKind === TransportServiceKind.removal;
            const isFlowHasJobsiteFlows: boolean = tf.serviceKind === TransportServiceKind.jobsiteVehicle;
            const isFlowHasLowFlows: boolean = tf.priority === TransportPriority.low;
            const isFlowHasMediumFlows: boolean = tf.priority === TransportPriority.medium;
            const isFlowHasHighFlows: boolean = tf.priority === TransportPriority.high;

            let filters: boolean = null;

            if (isPerishableSelected) {
                filters = filters || isFlowHasPerishableProductFlows;
            }

            if (isNotPerishableSelected) {
                filters = filters || isFlowHasNotPerishableProductFlows;
            }

            if (isRemovalSelected) {
                filters = filters || isFlowHasRemovalFlows;
            }

            if (isJobsiteSelected) {
                filters = filters || isFlowHasJobsiteFlows;
            }

            if (isLowPrioritySelected) {
                filters = filters || isFlowHasLowFlows;
            }

            if (isMediumPrioritySelected) {
                filters = filters || isFlowHasMediumFlows;
            }

            if (isHighPrioritySelected) {
                filters = filters || isFlowHasHighFlows;
            }

            return isIdsMatched && (filters ?? true);
        }

        return false;
    }

    handleChangeAddress = (e: AddressSelectItem): void => {
        if (e) {
            this.setState({ addressInputValue: e.label, addressValue: { freeFormAddress: e.label } });
            this.state.map.setCamera({ zoom: 14, center: [e.position.longitude, e.position.latitude], type: "ease", duration: 1000 });
        } else {
            this.setState({ addressInputValue: "", addressValue: { freeFormAddress: "" } });
        }
    }

    handleInputChangeAddress = (value: string, inputActionMeta: InputActionMeta): void => {
        if (inputActionMeta.action === "input-change") {
            this.setState({ addressInputValue: value, addressValue: { freeFormAddress: value } });
        }
    }

    getListAddress = (listAddress: AddressSelectItem[]): AddressSelectItem[] => {
        return listAddress;
    }

    loadAddressesOptions = debounce((inputValue: string, callback): void => {
        this.loadOptions(inputValue, callback);
    }, 500);

    loadOptions = (inputValue: string, callback): void => {
        if (inputValue.trim().length > 2) {
            this.setState({ isAddressInputLoading: true });

            MapsService.SearchAddress(inputValue)
                .then((res) => {
                    const listAddress = [{ label: "", value: "", position: null }];
                    res.forEach(x => {
                        const address: AddressSelectItem = {
                            value: x.id,
                            label: x.freeformAddress,
                            position: x.position
                        };

                        listAddress.push(address);
                    });

                    callback(this.getListAddress(listAddress));
                    this.setState({ isAddressInputLoading: false });
                });
        }
    }

    handleFavoriteDialogClosed = (): void => this.setState({ isFavoriteDialogOpened: false });

    handleFavoriteDialogOpened = (): void => this.setState({ isFavoriteDialogOpened: true });

    handleAddFavorite = (name: string): void => {
        const address: FavoriteAddress = { name: name, position: this.state.map.getCamera().center, zoom: this.state.map.getCamera().zoom };
        const favoriteAddressesStorage = this.favoriteAddressesStorage ?? [];
        favoriteAddressesStorage.push(address);
        this.favoriteAddressesStorage = favoriteAddressesStorage;
        LocalStorage.SetItem(ModuleKey, FavoriteAddressesStorageKey, JSON.stringify(favoriteAddressesStorage));

        const favoriteAddresses = this.state.favoriteAddresses;
        favoriteAddresses.push({ label: name, value: address });
        this.setState({ favoriteAddresses: favoriteAddresses, isFavoriteDialogOpened: false });
    }

    handleFavoriteAddressChange = (e: FavoriteAddressSelectItem): void => {
        if (e.value !== "") {
            const value = e.value as FavoriteAddress;
            this.state.map.setCamera({ zoom: value.zoom, center: value.position, type: "ease", duration: 1000 });
        }
    }

    handelDeleteFavoriteAddress = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, name: string): void => {
        event.preventDefault();
        event.stopPropagation();

        const favoriteAddressesStorage = this.favoriteAddressesStorage;
        const index = favoriteAddressesStorage.findIndex(x => x.name === name);
        favoriteAddressesStorage.splice(index, 1);
        this.favoriteAddressesStorage = favoriteAddressesStorage;
        LocalStorage.SetItem(ModuleKey, FavoriteAddressesStorageKey, JSON.stringify(favoriteAddressesStorage));

        const favoriteList = favoriteAddressesStorage ? favoriteAddressesStorage.map(x => { return { label: x.name, value: x } }) : [];
        const favoriteAddresses: FavoriteAddressSelectItem[] = favoriteList ? [{ label: "", value: "" }].concat(favoriteList) : [{ label: "", value: "" }];
        this.setState({ favoriteAddresses: favoriteAddresses });
    }

    handleSearchInputChanged = debounce((searchText: string): void => {
        this.searchInputChanged(searchText);
    }, 500);

    searchInputChanged = (searchText: string): void => {
        this.setState({ searchText: searchText });
        this.filterEntities(searchText);
        this.updateMarkersHtmlContents(this.state.map.markers);
    }

    private filterEntities = (searchText: string) => {
        const text = searchText.toLowerCase().removeDiacritics();

        const datasource = this.state.map.sources.getById(DataSourceId) as source.DataSource;
        const shapes = datasource.getShapes();
        shapes.forEach((shape: Shape) => {
            const shapeId = shape.getId() as string;
            const entityData: FeatureEntityDataModel = this.state.shapePropertiesCache.get(shapeId);
            let cssClass = entityData.cssClass;

            if (text) {
                let hasAnyBeneficiaryContainsText = false;

                entityData.beneficiaries?.every(b => {
                    const beneficiaryName = b.beneficiaryName ? b.beneficiaryName.toLowerCase().removeDiacritics() : '';
                    hasAnyBeneficiaryContainsText = beneficiaryName.contains(text);
                    if (hasAnyBeneficiaryContainsText)
                        return false;

                    return true;
                })

                const siteLabel = entityData.siteLabel ? entityData.siteLabel.toLowerCase().removeDiacritics() : '';
                const siteId = entityData.siteId ? entityData.siteId.toLowerCase().removeDiacritics() : '';

                if (hasAnyBeneficiaryContainsText || siteLabel.contains(text) || siteId.contains(text)) {
                    cssClass += ' matches-search-text';
                }
                else {
                    if (cssClass.contains(' matches-search-text')) {
                        cssClass = cssClass.replace(' matches-search-text', '');
                    }
                }
            }
            else {
                if (cssClass.contains(' matches-search-text')) {
                    cssClass = cssClass.replace(' matches-search-text', '');
                }
            }

            entityData.cssClass = cssClass;

            const { displayedNumberChoice, transportFlowsData, isDeliverySelected, isLoadingSelected, isPerishableSelected, remainingQuantityChoice, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, filtersSelected, vehicleTypeGroupFilterOptions } = this.state;
            if (displayedNumberChoice === DisplayedPropertyTypeChoice.Quantities) {
                this.updateShapeQuantities(entityData, shapeId, transportFlowsData, isDeliverySelected, isLoadingSelected, remainingQuantityChoice, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected);
            }
            else if (displayedNumberChoice === DisplayedPropertyTypeChoice.NumberOfVehicles) {
                this.updateShapeVehiclesNumber(entityData, shapeId, transportFlowsData, isDeliverySelected, isLoadingSelected, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, filtersSelected.length !== vehicleTypeGroupFilterOptions.length ? filtersSelected : null);
            }
        });
    }

    clearFilter = (): void => {
        this.inputSearchTextRef.current.value = '';
        this.searchInputChanged('');
    }

    handleChangeVehicleTypeSelected = (filtersSelected: Array<string>): void => {
        const { transportFlowsData, isDeliverySelected, isLoadingSelected, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, vehicleTypeGroupFilterOptions } = this.state;

        const datasource = this.state.map.sources.getById(DataSourceId) as source.DataSource;
        const shapes = datasource.getShapes();
        shapes.forEach((shape: Shape) => {
            const shapeId = shape.getId() as string;
            const entityData: FeatureEntityDataModel = this.state.shapePropertiesCache.get(shapeId);
            if (entityData?.entityType !== AgencyEntityType && entityData?.entityType !== ExternalTransporterEntityType) {
                this.updateShapeVehiclesNumber(entityData, shapeId, transportFlowsData, isDeliverySelected, isLoadingSelected, isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, filtersSelected.length !== vehicleTypeGroupFilterOptions.length ? filtersSelected : null);
            }
        });

        this.updateMarkersHtmlContents(this.state.map.markers);

        this.setState({ filtersSelected: filtersSelected });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { date, loading, isExternalTransportersButtonActive, isColasAgenciesButtonActive, positionCLickedId, isDawerDetailsOpened, drawerData,
            isPerishableSelected, isNotPerishableSelected, isRemovalSelected, isJobsiteSelected, isLowPrioritySelected, isMediumPrioritySelected, isHighPrioritySelected, isDeliverySelected, isLoadingSelected,
            remainingQuantityChoice, displayedNumberChoice, addressInputValue, addressValue, isAddressInputLoading, isFavoriteDialogOpened, vehicleTypeGroupFilterOptions } = this.state;

        const favoriteAddresses = orderBy(this.state.favoriteAddresses, o => o.label.toLowerCase(), ['asc'])

        return (
            <Box className="cartography" minHeight="600px">
                <Box display="flex" flexDirection="column" className="header">
                    <Box display="flex" flexDirection="row" mb="10px">
                        <Box>
                            <LocalizationProvider language="fr-FR">
                                <IntlProvider locale="fr" >
                                    <DatePicker defaultValue={date} ref={this.datePickerRef} onChange={this.handleDatepickerChange} />
                                </IntlProvider>
                            </LocalizationProvider>
                        </Box>
                        <Box ml="10px">
                            <Input disableUnderline className={`input-search-Point ${this.inputSearchTextRef?.current?.value?.length > 2 ? ' search-text-active' : ''}`} inputRef={this.inputSearchTextRef}
                                endAdornment={<>
                                    <InputAdornment position="end" classes={
                                        {
                                            root: 'input-icon-close'
                                        }
                                    }>
                                        <FontAwesomeIcon icon={faTimes} onClick={this.clearFilter} />
                                    </InputAdornment>
                                    <InputAdornment position="end" classes={
                                        {
                                            root: 'input-icon-search'
                                        }
                                    }>
                                        <FontAwesomeIcon icon={faSearch} className="icon-search" />
                                    </InputAdornment>
                                </>}
                                placeholder="Rechercher un IDMDM, un bénéficiaire, une société référencée, une société non-référencée." onChange={(e) => this.handleSearchInputChanged(e.target.value)} />
                        </Box>
                        <Box ml="10px">
                            <AsyncSelect
                                value={{ label: addressValue.freeFormAddress, value: addressValue.freeFormAddress }}
                                inputValue={addressInputValue}
                                className="async-select"
                                loadOptions={this.loadAddressesOptions}
                                onChange={(e: AddressSelectItem) => this.handleChangeAddress(e)}
                                onInputChange={(value, inputActionMeta) => this.handleInputChangeAddress(value, inputActionMeta)}
                                blurInputOnSelect={true}
                                openMenuOnClick={false}
                                isClearable={true}
                                isLoading={isAddressInputLoading}
                            />
                        </Box>
                        <Box ml="10px">
                            <IconButton className="camera-icon" onClick={this.handleFavoriteDialogOpened}>
                                <FontAwesomeIcon icon={faCamera} />
                            </IconButton>
                        </Box>
                        <Box ml="10px">
                            <Select
                                className="favorite-addresse"
                                placeholder="Lieux favoris"
                                components={{
                                    Option: (props) =>
                                        <components.Option {...props}>
                                            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                                <label>{props.data.label}</label>
                                                {props.data.value &&
                                                    <IconButton onClick={(event) => this.handelDeleteFavoriteAddress(event, props.data.label)}>
                                                        <FontAwesomeIcon size="sm" icon={faTimes} />
                                                    </IconButton>
                                                }
                                            </Box>
                                        </components.Option>
                                }}
                                options={favoriteAddresses}
                                onChange={this.handleFavoriteAddressChange}
                                menuPosition="fixed"
                                menuShouldBlockScroll={true}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="row" alignItems="end">
                            <Tooltip title="Transporteurs externes" placement="bottom">
                                <Button
                                    className={isExternalTransportersButtonActive ? "external-transporters-btn active" : "external-transporters-btn"}
                                    onClick={this.handleExternalTransportersChanged}
                                >
                                    <img src={externalTransporterIcon} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Agences" placement="bottom">
                                <Button
                                    className={isColasAgenciesButtonActive ? "colas-agencies-btn active" : "colas-agencies-btn"}
                                    onClick={this.handleColasAgenciesChanged}
                                >
                                    <img src={colasAgencyIcon} />
                                </Button>
                            </Tooltip>
                        </Box>
                        <ScaleLoaderComponent loading={loading} />
                        <Box display="flex" flexDirection="column" className="filter-section" justifyContent="end">
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box>Prestation</Box>
                                <Box onClick={this.handelSelectAllServiceKind} mr="10px" className="select-all">Tout sélectionner</Box>
                            </Box>
                            <Box display="flex" flexDirection="row">
                                <Button onClick={this.handlePerishableChange} className={isPerishableSelected ? "perishable-btn active" : "perishable-btn"}>Périssable</Button>
                                <Button onClick={this.handleNotPerishableChange} className={isNotPerishableSelected ? "not-perishable-btn active" : "not-perishable-btn"}>Non Périssable</Button>
                                <Button onClick={this.handleRemovalChange} className={isRemovalSelected ? "removal-btn active" : "removal-btn"}>Enlèvement</Button>
                                <Button onClick={this.handleJobsiteChange} className={isJobsiteSelected ? "jobsite-btn active" : "jobsite-btn"}>Camion chantier</Button>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="end">
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box>Criticité Flux</Box>
                                <Box onClick={this.handleSelectAllPriority} mr="10px" className="select-all">Tout sélectionner</Box>
                            </Box>
                            <Box display="flex" flexDirection="row">
                                <Button onClick={this.handleLowPriorityChanged} className={isLowPrioritySelected ? "low-btn active" : "low-btn"}>Modulable</Button>
                                <Button onClick={this.handleMediumPriorityChanged} className={isMediumPrioritySelected ? "day-btn active" : "day-btn"}>Journée</Button>
                                <Button onClick={this.handleHighPriorityChanged} className={isHighPrioritySelected ? "fix-btn active" : "fix-btn"}>Fixe</Button>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" className="filter-section" justifyContent="end">
                            <Box display="flex" flexDirection="column" mr="10px">
                                <Button onClick={this.handleDeliveryChanged} className={isDeliverySelected ? "delivery-btn active" : "delivery-btn"}>Livraison</Button>
                                <Button onClick={this.handleLoadingChanged} className={isLoadingSelected ? "loading-btn active" : "loading-btn"}>Chargement</Button>
                            </Box>
                            <Box alignSelf="flex-end">
                                <RadioGroup value={displayedNumberChoice} onChange={(e) => this.handleDisplayedNumberChoiceChanged(e)}>
                                    <FormControlLabel
                                        value={DisplayedPropertyTypeChoice.Quantities}
                                        control={<Radio />}
                                        label="Tonnages"
                                        disabled={!isDeliverySelected && !isLoadingSelected}
                                        className={!isDeliverySelected && !isLoadingSelected ? "radio-group-item disabled" : "radio-group-item"} />
                                    <FormControlLabel
                                        value={DisplayedPropertyTypeChoice.NumberOfVehicles}
                                        control={<Radio />}
                                        label="Nombre camions"
                                        disabled={!isDeliverySelected && !isLoadingSelected}
                                        className={!isDeliverySelected && !isLoadingSelected ? "radio-group-item disabled" : "radio-group-item"} />
                                </RadioGroup>
                            </Box>
                            <Box alignSelf="flex-end" className="filter-section" ml="10px">
                                <RadioGroup value={remainingQuantityChoice} onChange={(e) => this.handleRemainingQuantityChoiceChanged(e)}>
                                    <FormControlLabel
                                        value={DisplayedQuantityChoice.RemainingQuantityToBeDelivered}
                                        control={<Radio />}
                                        label="RAL"
                                        disabled={displayedNumberChoice === DisplayedPropertyTypeChoice.NumberOfVehicles || (!isDeliverySelected && !isLoadingSelected)}
                                        className={(displayedNumberChoice === DisplayedPropertyTypeChoice.NumberOfVehicles || (!isDeliverySelected && !isLoadingSelected)) ? "radio-group-item disabled" : "radio-group-item"}
                                    />
                                    <FormControlLabel
                                        value={DisplayedQuantityChoice.RemainingQuantityToBePlanned}
                                        control={<Radio />}
                                        label="RAP"
                                        disabled={displayedNumberChoice === DisplayedPropertyTypeChoice.NumberOfVehicles || (!isDeliverySelected && !isLoadingSelected)}
                                        className={(displayedNumberChoice === DisplayedPropertyTypeChoice.NumberOfVehicles || (!isDeliverySelected && !isLoadingSelected)) ? "radio-group-item disabled" : "radio-group-item"}
                                    />
                                </RadioGroup>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" className="filter-section vehicle-type-filter" justifyContent="end">
                            <TextSearchFieldsSelectorComponent<string>
                                selectedAll={true}
                                options={vehicleTypeGroupFilterOptions}
                                onChange={this.handleChangeVehicleTypeSelected}
                                placeHolder='Rechercher dans:'
                                noOptionsMessage='Pas de filtres disponibles.'
                                isDisabled={!((isDeliverySelected || isLoadingSelected) && displayedNumberChoice === DisplayedPropertyTypeChoice.NumberOfVehicles)}
                                selectAllLabel="Toutes familles véhicules" />
                        </Box>
                        {positionCLickedId &&
                            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className="details-header">
                                <Box className="title">Complément d'information</Box>
                                <Box className="eyeIcon"
                                    onClick={() => this.setState({ isDawerDetailsOpened: !isDawerDetailsOpened })}>
                                    <FontAwesomeIcon icon={isDawerDetailsOpened ? faEye : faEyeSlash} />
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box className="map-content">
                    <Box id="map" className="content"></Box>
                    {positionCLickedId && isDawerDetailsOpened &&
                        <DetailsDrawerComponent
                            positionCLickedId={positionCLickedId}
                            isDawerDetailsOpened={isDawerDetailsOpened}
                            data={drawerData}
                            updateSiteBulletinBoardRemarks={this.updateSiteBulletinBoardRemarks}
                        />
                    }
                </Box>
                <SimpleDialog isOpen={isFavoriteDialogOpened}
                    onClose={this.handleFavoriteDialogClosed}
                    closeIcon={true}
                    dialogTitle="Centrage Carte"
                    classNameVal="favorite-dialog"
                    component={
                        <FavoriteDialogComponent
                            favoriteAddresses={favoriteAddresses}
                            handleFavoriteDialogClosed={this.handleFavoriteDialogClosed}
                            handleAddFavorite={this.handleAddFavorite} />
                    }
                />
            </Box >
        );
    }
}