import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface CostsRemarksOfTransporterHeaderComponentProps {
    title: string
}

export const CostsRemarksOfTransporterHeaderComponent = (props: CostsRemarksOfTransporterHeaderComponentProps): JSX.Element => {
    return (
        <span className="editable-header">
            <span className="editable-header-title">{props.title}</span>
            <FontAwesomeIcon size="1x" className='icon-pen-blue' icon={faPen} />
        </span>
    );
}
