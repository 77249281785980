export const propertyLabels: Map<string, string> = new Map([
    ['TransportRequest.Version', 'Version'],
    ['TransportRequest.SourceLocalId', 'N° AL'],
    ['TransportRequest.SourceApplication', 'Source d\'application'],
    ['TransportRequest.CallStatus', 'Statut'],
    ['TransportRequest.CreationUser', 'Créateur'],
    ['TransportRequest.CreationTime', 'Date Création'],
    ['TransportRequest.LastWriteUser', 'Modificateur'],
    ['TransportRequest.LastWriteTime', 'Date modification'],
    ['TransportRequest.CustomerAnalyticalReference', 'Id MDM client'],
    ['TransportRequest.OrderNumber', 'Numéro commande'],
    ['TransportRequest.DeliveryStartDate', 'Date début'],
    ['TransportRequest.DeliveryEndDate', 'Date fin'],
    ['TransportRequest.IsTransportRequired', 'Transport obligatoire'],
    ['TransportRequest.DeliveryRemarks', 'Info livraisons'],
    ['TransportRequest.EstimatedDistance', 'Distance estimée'],
    ['TransportRequest.EstimatedDuration', 'Durée du trajet'],
    ['TransportRequest.RejectionMotive', 'Motif de rejet'],
    ['TransportRequest.RequestedLogisticsUnitId', 'Zone logistique demandée'],
    ['TransportRequest.FlowNumberSequence', 'Numéro de séquence du Flux'],
    ['TransportRequest.Priority', 'Priorité'],
    ['TransportRequest.ServiceKind', 'Prestation'],
    ['TransportRequest.IsPerishableProduct', 'Est périssable'],
    ['TransportRequest.BusinessId', 'Id métier'],
    ['TransportRequest.CoatingApplicationMode', 'Type de chantier'],
    ['TransportRequest.IsNightWork', 'Chantier de nuit'],

    //RequestedTransportation
    ['RequestedTransportation.LineId', 'Line Id'],
    ['RequestedTransportation.VehicleTypeId', 'Type'],
    ['RequestedTransportation.VehicleType', 'Type de véhicule'],
    ['RequestedTransportation.NumberOfVehicles', 'Nombre de camions'],
    ['RequestedTransportation.NumberOfTurns', 'Nombre de rotations'],
    ['RequestedTransportation.DayPeriod', 'Période'],
    ['RequestedTransportation.PreferredArrivalTime', 'Heure arrivée souhaitée'],
    ['RequestedTransportation.MissionStartTime', 'Heure début mission'],
    ['RequestedTransportation.UnloadingDuration', 'Durée déchargement'],
    ['RequestedTransportation.LoadingDuration', 'Durée chargement'],
    ['RequestedTransportation.NumberOfFinishers', 'Nombre de finishers'],
    ['RequestedTransportation.IsUnloadingContinuityRequired', 'Besoin de continuité'],
    ['RequestedTransportation.EstimatedCost', 'Coût transport'],
    ['RequestedTransportation.Quantity', 'Quantité'],
    ['RequestedTransportation.UnitOfMeasure', 'Unité de mesure'],

    //SiteInfo
    ['SiteInfo.SupplierMdmId', 'Id MDM'],
    ['SiteInfo.CustomerMdmId', 'Id MDM chantier'],
    ['SiteInfo.CompanyId', 'Société juridique'],
    ['SiteInfo.AgencyId', 'Agence'],
    ['SiteInfo.ProductionSiteId', 'Site de production'],
    ['SiteInfo.JobSiteMdmId', 'Id MDM chantier'],
    ['SiteInfo.BusinessUnit', 'BU'],
    ['SiteInfo.BusinessUnitSubPhase', 'BU sous-phase'],
    ['SiteInfo.UnreferencedCustomer', 'Nom externe'],

    //ProductInfo
    ['ProductInfo.Code', 'Code'],
    ['ProductInfo.Level3SegmentationId', 'Seg. niveau 3'],
    ['ProductInfo.Level4SegmentationId', 'Seg. niveau 4'],
    ['ProductInfo.Quantity', 'Quantité'],
    ['ProductInfo.UnitOfMeasure', 'Unité d\'oeuvre'],

    ['Contact.JobForeman', 'Bénéficiaire'],
    ['Contact.RequestedBy', 'Demandeur'],
    ['Product.Product', 'Chargement'],

    ['AddressInfo.Line1', 'Ligne 1'],
    ['AddressInfo.Line2', 'Ligne 2'],
    ['AddressInfo.ZipCode', 'Code postal'],
    ['AddressInfo.StatisticZipCode', 'Code INSEE'],
    ['AddressInfo.City', 'Ville'],
    ['AddressInfo.Country', 'Pays'],

    ['GpsCoordinates.Latitude', 'Latitude'],
    ['GpsCoordinates.Longitude', 'Longitude'],

    //Contact Info
    ['ContactInfo.User', 'Utilisateur'],
    ['ContactInfo.Name', 'Nom'],
    ['ContactInfo.PhoneNumber', 'Téléphone'],
    ['ContactInfo.Email', 'Email'],
    ['ContactInfo.FaxNumber', 'Fax'],
    ['ContactInfo.WebSite', 'Website'],
    ['ContactInfo.IsExternal', 'Est externe'],

    ['TransportSellPriceKind.PerTon', 'Tonne'],
    ['TransportSellPriceKind.PerTimePeriod', 'Forfait'],
    ['TransportSellPriceKind.PerTur', 'Tour'],
]);

export const enumValues = new Map([
    ['TransportRequestStatus.0', 'En attente'],
    ['TransportRequestStatus.1', 'Validé'],
    ['TransportRequestStatus.2', 'Planifié'],
    ['TransportRequestStatus.3', 'Publié'],
    ['TransportRequestStatus.4', 'Rejeté'],
    ['SourceApplication.0', 'Planeo'],
    ['SourceApplication.1', 'EPlanning'],
    ['SourceApplication.2', 'Lorie'],
    ['CoatingApplicationMode.0', 'Manuel'],
    ['CoatingApplicationMode.1', 'Mécanique'],
    ['CoatingApplicationMode.2', 'Mixte'],
    ['CoatingApplicationMode.3', 'Non applicable'],
    ['DayPeriod.0', 'Journée'],
    ['DayPeriod.1', 'Demi-journée'],
    ['DayPeriod.2', 'Nuit'],
    ['DayPeriod.3', 'Matin'],
    ['DayPeriod.4', 'Après-midi'],
    ['DayPeriod.5', 'Soir'],
    ['TransportPriority.0', 'Fixe'],
    ['TransportPriority.1', 'Journée'],
    ['TransportPriority.2', 'Modulable'],
    ['TransportServiceKind.0', 'Livraison'],
    ['TransportServiceKind.1', 'Enlèvement'],
    ['TransportServiceKind.2', 'Camion de chantier'],

]);

export const HiddenProperties = [
    'Version',
    'AggregateLastWriteTime',
    'DeliveryStartDate_AsNumeric',
    'DeliveryEndDate_AsNumeric'
];

export const sortKeyAddress = [
    { 'sortKey': 1, 'val': 'Line1' },
    { 'sort4ey': 2, 'val': 'Line2' },
    { 'sortKey': 3, 'val': 'ZipCode' },
    { 'sortKey': 4, 'val': 'StatisticZipCode' },
    { 'sortKey': 5, 'val': 'City' },
    { 'sortKey': 6, 'val': 'Country' }
];

export const sortKeyTransportation = [
    { 'sortKey': 1, 'val': 'VehicleTypeId' },
    { 'sortKey': 2, 'val': 'VehicleType' },
    { 'sortKey': 3, 'val': 'NumberOfVehicles' },
    { 'sortKey': 4, 'val': 'NumberOfTurns' },
    { 'sortKey': 5, 'val': 'DayPeriod' },
    { 'sortKey': 6, 'val': 'MissionStartTime' },
    { 'sortKey': 7, 'val': 'PreferredArrivalTime' },
    { 'sortKey': 8, 'val': 'LoadingDuration' },
    { 'sortKey': 9, 'val': 'UnloadingDuration' },
    { 'sortKey': 10, 'val': 'NumberOfFinishers' },
    { 'sortKey': 11, 'val': 'IsUnloadingContinuityRequired' },
    { 'sortKey': 12, 'val': 'Quantity' },
    { 'sortKey': 13, 'val': 'UnitOfMeasure' },
    { 'sortKey': 14, 'val': 'EstimatedCost' },
    { 'sortKey': 15, 'val': 'LineId' }
];