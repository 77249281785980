import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { FavoriteAddressSelectItem } from '../models/FavoriteAddressSelectItem';

interface FavoriteDialogComponentProps {
    favoriteAddresses: FavoriteAddressSelectItem[],
    handleFavoriteDialogClosed: () => void,
    handleAddFavorite: (name: string) => void
}

export const FavoriteDialogComponent = (props: FavoriteDialogComponentProps): JSX.Element => {

    const [favoriteName, setFavoriteName] = useState<string>('');

    const existFavorite = props.favoriteAddresses.some(x => x.label.trim().toLowerCase().removeDiacritics() === favoriteName.trim().toLowerCase().removeDiacritics());
    const isValidateButtonDisabled = favoriteName.trim().length === 0 || existFavorite;

    const handleClickAddFavorite = (): void => {
        props.handleAddFavorite(favoriteName.trim());
        setFavoriteName('');
    }

    return (
        <>
            <Box>
                <TextField label="Nom" variant="outlined" value={favoriteName} onChange={(e) => setFavoriteName(e.target.value)} required className="favorite-name" />
            </Box>
            {existFavorite && favoriteName.trim().length > 0 &&
                <Box className="exist-favorite">Ce nom de centrage exite déjà.</Box>
            }
            <Box display="flex" flexDirection="row" justifyContent="end" mt="10px">
                <Button onClick={props.handleFavoriteDialogClosed} className="favorite-dialog-btn">Annuler</Button>
                <Button
                    onClick={handleClickAddFavorite}
                    className={isValidateButtonDisabled ? "favorite-dialog-btn disabled" : "favorite-dialog-btn"}
                    disabled={isValidateButtonDisabled}>Valider</Button>
            </Box>
        </>
    );
}
