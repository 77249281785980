import axios, { AxiosResponse } from "axios";
import { WebAppActionResult } from "../../../shared/models/WebAppActionResult";
import { ProductionSiteLightModel } from "./dataContracts/queryStack/ProductionSiteLightModel";
import { SiteBulletinBoardLightModel } from "./dataContracts/queryStack/SiteBulletinBoardLightModel";
import { TransporterLightModel } from "./dataContracts/queryStack/TransporterLightModel";
import { TransportFlowLightModel } from "./dataContracts/queryStack/TransportFlowLightModel";
import { WorkAgencyLightModel } from "./dataContracts/queryStack/WorkAgencyLightModel";

const controllerPath = "api/Cartography/";

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

export class CartographyApiClient {

    public static GetProductionSites = (logisticsUnits: string[])
        : Promise<AxiosResponse<ProductionSiteLightModel[]>> => {
        return axios({
            method: 'POST',
            url: controllerPath + 'GetProductionSites',
            headers: headers,
            data: JSON.stringify(logisticsUnits)
        });
    }

    public static GetWorkAgencies = (logisticsUnits: string[])
        : Promise<AxiosResponse<WorkAgencyLightModel[]>> => {
        return axios({
            method: 'POST',
            url: controllerPath + 'GetWorkAgencies',
            headers: headers,
            data: JSON.stringify(logisticsUnits)
        });
    }

    public static GetExternalTransporters = (logisticsUnits: string[])
        : Promise<AxiosResponse<TransporterLightModel[]>> => {
        return axios({
            method: 'POST',
            url: controllerPath + 'GetExternalTransporters',
            headers: headers,
            data: JSON.stringify(logisticsUnits)
        });
    }

    public static GetTransportFlows = (date: Date, logisticsUnits: string[])
        : Promise<AxiosResponse<TransportFlowLightModel[]>> => {
        return axios({
            method: 'POST',
            url: controllerPath + 'GetTransportFlows?date=' + encodeURIComponent(date.toJSON()),
            headers: headers,
            data: JSON.stringify(logisticsUnits)
        });
    }

    public static GetSiteBulletinBoard = (siteBulletinBoardId: string)
        : Promise<AxiosResponse<SiteBulletinBoardLightModel>> => {
        return axios({
            method: 'GET',
            url: controllerPath + 'GetSiteBulletinBoard?siteBulletinBoardId=' + encodeURIComponent(siteBulletinBoardId),
            headers: headers
        });
    }

    public static AddOrUpdateSiteBulletinBoard = (siteBulletinBoardId: string, remarks: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerPath + 'AddOrUpdateSiteBulletinBoard?siteBulletinBoardId=' + encodeURIComponent(siteBulletinBoardId) + '&remarks=' + encodeURIComponent(remarks),
            headers: headers
        });
    }

}