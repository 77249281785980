import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Checkbox, debounce, FormControlLabel, Input, InputAdornment } from '@mui/material';
import { GridCellProps, GridRowProps } from '@progress/kendo-react-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import ToastService from 'src/ToastService';
import { fromHours } from 'src/utils/TimeSpanString';
import { CalendarComponent } from '../../../shared/components/Common/CalendarComponent';
import { DateRange } from '../../../shared/models/DateRange';
import { WebAppActionResult } from '../../../shared/models/WebAppActionResult';
import BusinessErrors from '../../../utils/BusinessErrors';
import '../../../utils/Date';
import { downloadFileFromBlob, getFileName } from '../../../utils/DownloadFile';
import { RouteComponentProps, withRouter } from '../../../withRouter';
import { ThirdPartyTransporterApiClient } from '../ThirdPartyTransporter/services/ThirdPartyTransporterApiClient';
import { PublishedCostsGridComponent } from './components/PublishedCostsGridComponent';
import { EditedFieldModel } from './models/EditedFieldModel';
import { PlanningVehiclesPublishedCostsLineModel } from './models/PlanningVehiclesPublishedCostsLineModel';
import { TotalsModel } from './models/TotalsModel';
import { TransportPurchasePriceKindEnum } from './models/TransportPurchasePriceKindEnum';
import { PublishedCostsExportRequestArgs } from './services/dataContracts/controller/PublishedCostsExportRequestArgs';
import { NegotiatedDeliveryTripsGroupLightModel } from './services/dataContracts/queryStack/NegotiatedDeliveryTripsGroupLightModel';
import { PlanningVehiclesPublishedCostsLightModel } from './services/dataContracts/queryStack/PlanningVehiclesPublishedCostsLightModel';
import { SpecificDeliveryTripsGroupLightModel } from './services/dataContracts/queryStack/SpecificDeliveryTripsGroupLightModel';
import { TransportFlowsDetailsLightModel } from './services/dataContracts/queryStack/TransportFlowsDetailsLightModel';
import { TransportRequestFeesLightModel } from './services/dataContracts/queryStack/TransportRequestFeesLightModel';
import { ThirdPartyTransporterPublishedCostsApiClient } from './services/ThirdPartyTransporterPublishedCostsApiClient';
import './ThirdPartyTransporterPublishedCostsStyles.scss';

interface ThirdPartyTransporterPublishedCostsViewProps {
    role: string,
    isForInternalTransporters: boolean
}

const ThirdPartyTransporterPublishedCostsView = (props: ThirdPartyTransporterPublishedCostsViewProps & RouteComponentProps): JSX.Element => {
    let preventExit: boolean;
    let preventExitTimeout: ReturnType<typeof setTimeout>;
    let blurTimeout: ReturnType<typeof setTimeout>;
    const today: Date = new Date();
    const minDate: Date = new Date().addDays(-60);

    const searchParams: URLSearchParams = new URLSearchParams(props.location.search);

    const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);
    const [date, setDate] = useState<DateRange>({ start: Date.getFirstOfMonth(today).getDayStart(), end: Date.getLastOfMonth(today).getDayEnd() });
    const [data, setData] = useState<PlanningVehiclesPublishedCostsLineModel[]>([]);
    const [searchText, setSearchText] = useState<string>(searchParams?.get('searchText') ?? '');
    const [totals, setTotals] = useState<TotalsModel>({
        totalFees: 0,
        totalCosts: 0
    });
    const [editField, setEditField] = useState<EditedFieldModel>(null);
    const [loadingPage, setLoadingPage] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [transporterName, setTransporterName] = useState<string>(searchParams?.get('transporterName'));
    const [transporterId, setTransporterId] = useState<string>(searchParams?.get('transporterId'));

    useEffect(() => {
        if (transporterId
            && (!searchText || searchText.length >= 3 || searchText.length === 0)) {
            getPlanningVehiclesPublishedCostsBounced(date, transporterId, searchText, showDetails);
        }
    }, [date, searchText, transporterId]);

    useEffect(() => {
        const linkToken = searchParams?.get('K');
        if (linkToken) {
            setLoadingPage(true);
            ThirdPartyTransporterApiClient.GetTransporterToken(linkToken)
                .then((res) => {
                    if (res.data) {
                        const searchDateRange = {
                            start: res.data.planningStartDate.getDayStart(),
                            end: res.data.planningEndDate?.getDayEnd() ?? date.end
                        };
                        setDate(searchDateRange);
                        setTransporterId(res.data.transporterId);
                        setTransporterName(res.data.transporterName);
                    }
                })
                .finally(() => {
                    setLoadingPage(false);
                });
        }
    }, []);

    const handleChangeDateRange = (range: DateRange): void => {
        if (range.end != null && range.start != null) {
            const fromTime: Date = range.start.getDayStart();
            const toTime: Date = range.end.getDayEnd();
            const date: DateRange = { start: fromTime, end: toTime }
            setDate(date);
        }
    }

    const getPlanningVehiclesPublishedCosts = (date: DateRange, transporterId: string, searchText: string, showDetails: boolean): void => {
        setLoading(true);
        ThirdPartyTransporterPublishedCostsApiClient.GetPlanningVehiclesPublisedCosts(date, transporterId, searchText)
            .then((res) => {
                const arrayData: PlanningVehiclesPublishedCostsLineModel[] = [];
                let totalFees = 0;
                let totalCosts = 0;

                res.data.forEach((pv: PlanningVehiclesPublishedCostsLightModel) => {
                    const vehicleline: PlanningVehiclesPublishedCostsLineModel = {
                        isHidden: false,
                        vehicleLine: true,
                        rowClassName: "default-line vehicle-line",
                        buyerLogisticsUnitLabel: pv.buyerLogisticsUnitLabel,
                        planningId: pv.planningId,
                        planningVehicleId: pv.planningVehicleId,
                        transporterId: pv.transporterId,
                        costsAreAgreed: pv.costsAreAgreed,
                        costsAreAgreedBase: pv.costsAreAgreed,
                        costsRemarksOfTransporterBase: pv.costsRemarksOfTransporter,
                        costsRemarksOfTransporter: pv.costsRemarksOfTransporter,
                        costsRemarksOfLogistician: pv.costsRemarksOfLogistician,
                        costsPublishedDate: Date.getDateFromIsoString(pv.costsPublishedDate),
                        licencePlate: pv.licencePlate,
                        equipmentId: pv.equipmentId,
                        vehicleTypeLabel: pv.vehicleTypeLabel,
                        planningDate: Date.getDateFromIsoString(pv.planningDate),
                        confirmationNumber: pv.confirmationNumber,
                        totalFees: pv.totalFees?.toCurrencyString(),
                        totalCosts: pv.totalCosts?.toCurrencyString(),
                        costPurchaseProvisioningBusinessId: pv.costPurchaseProvisioningBusinessId,
                        feesPurchaseProvisioningBusinessId: pv.feesPurchaseProvisioningBusinessId,
                        contractualPurchasePriceContractNumber: pv.contractualPurchasePriceContractNumber,
                        isCanceled: pv.isCanceled,
                        transporterConfirmationDate: pv.transporterConfirmationDate,
                        transporterOrderId: pv.transporterOrderId,
                        transporterIsInternal: pv.transporterIsInternal,
                        cancellationReason: pv.cancellationReason,
                        cancellationRemarks: pv.cancellationRemarks,
                        purchaseIsCanceled: pv.purchaseIsCanceled,
                        saleIsCanceled: pv.saleIsCanceled,
                        cancellationOrderSentDate: pv.cancellationOrderSentDate,
                        cancellationOrderConfirmationDate: pv.cancellationOrderConfirmationDate
                    };
                    arrayData.push(vehicleline);

                    totalFees = pv.totalFees ? totalFees + pv.totalFees : totalFees;
                    totalCosts = pv.totalCosts ? totalCosts + pv.totalCosts : totalCosts;

                    switch (pv.priceKind) {
                        case TransportPurchasePriceKindEnum.Day:
                        case TransportPurchasePriceKindEnum.HalfDay:
                        case TransportPurchasePriceKindEnum.Night: {
                            if (pv.quantityMainPrice) {
                                const perTimePeriodLine: PlanningVehiclesPublishedCostsLineModel = {
                                    isHidden: !showDetails,
                                    rowClassName: "default-line principal-price-line",
                                    totalCosts: pv.price?.toCurrencyString(),
                                    priceKindBase: pv.priceKind,
                                    priceKind: translatePriceKind(pv.priceKind)
                                };
                                arrayData.push(perTimePeriodLine);
                            }
                            break;
                        }
                        case TransportPurchasePriceKindEnum.PerTurn:
                        case TransportPurchasePriceKindEnum.PerHour:
                        case TransportPurchasePriceKindEnum.PerTon:
                            {
                                if (pv.quantityMainPrice) {
                                    const line: PlanningVehiclesPublishedCostsLineModel = {
                                        isHidden: !showDetails,
                                        rowClassName: "default-line principal-price-line",
                                        totalCosts: pv.totalMainPrice?.toCurrencyString(),
                                        priceKindBase: pv.priceKind,
                                        priceKind: translatePriceKind(pv.priceKind),
                                        deliveredQuantity: pv.priceKind === TransportPurchasePriceKindEnum.PerTon ? pv.deliveredQuantity?.toString() : null,
                                        expectedTimeBillableOnMainPrice: pv.priceKind === TransportPurchasePriceKindEnum.PerHour ? fromHours(pv.expectedHoursBillableOnMainPrice) : null,
                                        deliveredNumberOfTurns: pv.priceKind === TransportPurchasePriceKindEnum.PerTurn ? pv.deliveredNumberOfTurns?.toString() : null,
                                        unitPrice: pv.price?.toCurrencyString()
                                    };
                                    arrayData.push(line);
                                }
                                break;
                            }
                        default:
                    }

                    if (pv.transportFlows) {
                        pv.transportFlows.forEach((f: TransportFlowsDetailsLightModel) => {
                            const rowClassName = f.serviceKind != 'Delivery' ? f.serviceKind : (f.isPerishableProduct ? 'Delivery_Perishable' : 'Delivery_Not_Perishable');

                            f.specificPrices?.forEach((sp: SpecificDeliveryTripsGroupLightModel) => {
                                const specificLine: PlanningVehiclesPublishedCostsLineModel = {
                                    isHidden: !showDetails,
                                    totalCosts: sp.price?.toCurrencyString(),
                                    rowClassName: `default-line ${rowClassName}`,
                                    priceKindBase: sp.priceKind,
                                    priceKind: translatePriceKind(sp.priceKind),
                                    deliveredQuantity: sp.priceKind === TransportPurchasePriceKindEnum.PerTon ? sp.quantity?.toString() : "",
                                    deliveredNumberOfTurns: sp.priceKind === TransportPurchasePriceKindEnum.PerTurn ? sp.numberOfTurns?.toString() : "",
                                    expectedTimeBillableOnMainPrice: sp.priceKind === TransportPurchasePriceKindEnum.PerHour ? sp.expectedTime : null,
                                    beneficiaryName: f.beneficiaryName,
                                    senderSite: f.senderSiteLabel,
                                    senderSiteCity: f.pickupAddressCity,
                                    receiverSite: f.receiverSiteLabel,
                                    receiverSiteCity: f.deliveryAddressCity,
                                    tansportFlowBusinessId: f.businessId,
                                    unitPrice: sp.unitPrice?.toCurrencyString(),
                                    isSpecificLine: true
                                };
                                arrayData.push(specificLine);
                            });

                            f.negotiatedPrices?.forEach((sp: NegotiatedDeliveryTripsGroupLightModel) => {
                                const negotiatedLine: PlanningVehiclesPublishedCostsLineModel = {
                                    isHidden: !showDetails,
                                    rowClassName: `default-line ${rowClassName}`,
                                    totalCosts: sp.price?.toCurrencyString(),
                                    priceKindBase: sp.priceKind,
                                    priceKind: translatePriceKind(sp.priceKind),
                                    deliveredQuantity: sp.priceKind === TransportPurchasePriceKindEnum.PerTon ? sp.quantity?.toString() : "",
                                    deliveredNumberOfTurns: sp.priceKind === TransportPurchasePriceKindEnum.PerTurn ? sp.numberOfTurns?.toString() : "",
                                    expectedTimeBillableOnMainPrice: sp.priceKind === TransportPurchasePriceKindEnum.PerHour ? sp.expectedTime : null,
                                    beneficiaryName: f.beneficiaryName,
                                    senderSite: f.senderSiteLabel,
                                    senderSiteCity: f.pickupAddressCity,
                                    receiverSite: f.receiverSiteLabel,
                                    receiverSiteCity: f.deliveryAddressCity,
                                    tansportFlowBusinessId: f.businessId,
                                    unitPrice: sp.unitPrice?.toCurrencyString(),
                                    isNegotiatedLine: true
                                };
                                arrayData.push(negotiatedLine);
                            });
                        });
                    }

                    if (pv.fees) {
                        let othersFees = 0;
                        let waitFees = 0;
                        let tollFees = 0;

                        pv.fees.forEach((f: TransportRequestFeesLightModel) => {
                            othersFees += f.othersFees ?? 0;
                            waitFees += f.waitFees ?? 0;
                            tollFees += f.tollFees ?? 0;
                        });

                        if (othersFees) {
                            const feesLine: PlanningVehiclesPublishedCostsLineModel = {
                                isHidden: showDetails ? false : true,
                                rowClassName: "fees-line",
                                priceKind: "Autres",
                                totalFees: othersFees.toCurrencyString() ?? ""
                            };
                            arrayData.push(feesLine);
                        }
                        if (waitFees) {
                            const feesLine: PlanningVehiclesPublishedCostsLineModel = {
                                isHidden: showDetails ? false : true,
                                rowClassName: "fees-line",
                                priceKind: "Attente",
                                totalFees: waitFees.toCurrencyString() ?? ""
                            };
                            arrayData.push(feesLine);
                        }
                        if (tollFees) {
                            const feesLine: PlanningVehiclesPublishedCostsLineModel = {
                                isHidden: showDetails ? false : true,
                                rowClassName: "fees-line",
                                priceKind: "Péage",
                                totalFees: tollFees.toCurrencyString() ?? ""
                            };
                            arrayData.push(feesLine);
                        }
                    }
                });

                setTotals({
                    totalFees: totalFees,
                    totalCosts: totalCosts
                });

                setData(arrayData);
                setLoading(false);
            });
    }

    const getPlanningVehiclesPublishedCostsBounced = useCallback(debounce(getPlanningVehiclesPublishedCosts, 500), []);

    const translatePriceKind = (priceKind: number): string => {
        switch (priceKind) {
            case TransportPurchasePriceKindEnum.Day:
                return "Journée";
            case TransportPurchasePriceKindEnum.HalfDay:
                return "1/2 journée";
            case TransportPurchasePriceKindEnum.Night:
                return "Nuit";
            case TransportPurchasePriceKindEnum.PerHour:
                return "Heure";
            case TransportPurchasePriceKindEnum.PerTon:
                return "Tonne";
            case TransportPurchasePriceKindEnum.PerTurn:
                return "Tour";
            default:
                return "";
        }
    }

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps): React.ReactElement<HTMLTableRowElement> => {
        const trProps = {
            ...trElement.props,

            onMouseDown: () => {
                preventExit = true;
                clearTimeout(preventExitTimeout);
                preventExitTimeout = setTimeout(() => { preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(blurTimeout);
                if (!preventExit) {
                    blurTimeout = setTimeout(() => { exitEdit(); });
                }
            },
            onFocus: () => { clearTimeout(blurTimeout); }
        };
        return React.cloneElement(trElement, { ...trProps, className: rowProps.dataItem.rowClassName }, trElement.props.children as any);
    }

    const cellRender = (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps): React.ReactElement => {
        const dataItem: PlanningVehiclesPublishedCostsLineModel = cellProps.dataItem;
        const field = cellProps.field;
        const additionalProps = (dataItem.inEdit && (cellProps.field === dataItem.inEdit)) ?
            {
                ref: (td) => {
                    const input = td && td.querySelector('input');
                    if (!input || (input === document.activeElement)) { return; }
                    if (input.type === 'checkbox') {
                        input.focus();
                    } else {
                        input.select();
                    }
                }
            } : {
                onClick: () => { enterEdit(dataItem, field); }
            };
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children as any);
    }

    const selectionChange = (item: PlanningVehiclesPublishedCostsLineModel): void => {
        //NEUTRE --> OK --> KO --> NEUTRE
        const costsAreAgreed = item.costsAreAgreed === null
            ? true
            : item.costsAreAgreed === true
                ? false
                : null;

        item.costsAreAgreed = costsAreAgreed;

        if (costsAreAgreed === null) {
            const oldCostsAreAgreedBase = item.costsAreAgreedBase;
            item.costsAreAgreedBase = costsAreAgreed;
            ThirdPartyTransporterPublishedCostsApiClient.ChangePublishedCostsAgreedAndRemarksOfTransporter(item.planningVehicleId, transporterId, item.costsRemarksOfTransporter, costsAreAgreed)
                .then((res) => {
                    const data: WebAppActionResult = res?.data;
                    const errors: string[] = BusinessErrors.Get(data);
                    if (errors.length > 0) {
                        ToastService.showErrorToast("L'action n'est pas prise en compte: ", errors);
                        item.costsAreAgreedBase = oldCostsAreAgreedBase;
                        return;
                    }
                    item.costsAreAgreedBase = costsAreAgreed;
                });
        } else {
            item.inEdit = "costsRemarksOfTransporter";
            setEditField({
                field: "costsRemarksOfTransporter",
                planningId: item.planningId,
                costsAreAgreed: item.costsAreAgreed
            });
        }
    }

    const enterEdit = (dataItem: PlanningVehiclesPublishedCostsLineModel, field: string): void => {
        if (dataItem.inEdit && field === editField.field && editField.planningId === dataItem.planningId)
            return;

        exitEdit();
        dataItem.inEdit = field;
        setEditField({
            field: field,
            planningId: dataItem.planningId
        });
    }

    const exitEdit = (): void => {
        let wasChanged = false;
        let propertyName: string = null;
        let costsAreAgreed: boolean = null;
        let planningId: number = null;
        let planningVehicleId: string = null;
        let propertyValue: string = null;

        data.forEach((dataItem: PlanningVehiclesPublishedCostsLineModel) => {
            if (dataItem.inEdit !== undefined) {
                propertyName = dataItem.inEdit;
                propertyValue = dataItem[propertyName]?.trim() ?? '';
                costsAreAgreed = dataItem.costsAreAgreed;
                planningVehicleId = dataItem.planningVehicleId;
                planningId = dataItem.planningId;
                wasChanged = true;
            }
            dataItem.inEdit = undefined;
        });

        setEditField(null);

        if (!wasChanged)
            return;

        if (propertyName === "costsRemarksOfTransporter" && planningVehicleId && planningId) {
            ThirdPartyTransporterPublishedCostsApiClient.ChangePublishedCostsAgreedAndRemarksOfTransporter(planningVehicleId, transporterId, propertyValue, costsAreAgreed)
                .then((res) => {
                    const resData: WebAppActionResult = res?.data;
                    const errors: string[] = BusinessErrors.Get(resData);
                    if (errors.length > 0) {
                        ToastService.showErrorToast("L'action n'est pas prise en compte: ", errors);

                        //On remet le commentaire et le statut d'avant
                        const newData = [...data];
                        const dataItem = newData.find(d => d.planningVehicleId === planningVehicleId && d.planningId === planningId && d.transporterId === transporterId);
                        dataItem.costsRemarksOfTransporter = dataItem.costsRemarksOfTransporterBase;
                        dataItem.costsAreAgreed = dataItem.costsAreAgreedBase;
                        setData(newData);
                        return;
                    }

                    //On mets à jour les valeurs de base
                    const newData = [...data];
                    const dataItem = newData.find(d => d.planningVehicleId === planningVehicleId && d.planningId === planningId && d.transporterId === transporterId);
                    dataItem.costsRemarksOfTransporterBase = dataItem.costsRemarksOfTransporter;
                    dataItem.costsAreAgreedBase = costsAreAgreed;
                    setData(newData);
                });
        }
    }

    const handleDetailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setShowDetails(e.target.checked);

        const dataArray = data;
        dataArray.forEach((item: PlanningVehiclesPublishedCostsLineModel) => {
            item.isHidden = item.vehicleLine ? false : !e.target.checked;
        });

        setData(dataArray);
    }

    const exportToExcel = (): void => {
        const requestArgs: PublishedCostsExportRequestArgs = {
            startDate: date.start,
            endDate: date.end,
            isForInternalTransporters: props.isForInternalTransporters,
            transporterId: transporterId
        };

        ThirdPartyTransporterPublishedCostsApiClient.ExportPublishedCosts(requestArgs)
            .then(response => {
                const fileName: string = getFileName(response);
                const blob: Blob = new Blob([response.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const handleIsDateRangeValidChanged = (isValid: boolean): void => {
        setIsDateRangeValid(isValid);
    }

    const checkboxInputProps: React.InputHTMLAttributes<HTMLInputElement> = { 'aria-label': 'primary checkbox' };

    const clearSearchText = (): void => {
        setSearchText("");
    }

    const handleSearchTextKeyPressed = (text: string): void => {
        setSearchText(text);
    }

    return (
        loadingPage ?
            <ScaleLoader
                width={5}
                height={20}
                radius={50}
                color={'#000000'}
                loading={loadingPage}
            />
            :
            <Box className="transporterPublishedCosts" display="flex" flexDirection="column" justifyContent="flex-start">
                <Box display="flex" flexDirection="row" width="100%">
                    <Box display="flex" flexDirection="column" width="50%">
                        <Box className="transporter" display="flex" alignItems="center">
                            {transporterName}
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Box className="calendar">
                                <CalendarComponent calendarId="transporter-published-costs-daterange-picker"
                                    startDate={date.start}
                                    endDate={date.end}
                                    minStartDate={minDate.getDayStart()}
                                    maximumNumberOfMonths={2}
                                    isValid={isDateRangeValid}
                                    handleIsValidChanged={handleIsDateRangeValidChanged}
                                    handlerFromChildToParent={handleChangeDateRange}
                                />
                            </Box>
                            <FormControlLabel
                                className="checkbox-detail"
                                control={
                                    <Checkbox
                                        checked={showDetails}
                                        onChange={(e) => handleDetailChange(e)}
                                        color="primary"
                                        inputProps={checkboxInputProps}
                                    />
                                }
                                label="Afficher le détail"
                                labelPlacement="start"
                            />
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Input disableUnderline className={searchText.length > 2 ? 'input-search-vehicles search-text-active' : 'input-search-vehicles'}
                                    endAdornment={<>
                                        <InputAdornment position="end" classes={
                                            {
                                                root: 'input-icon-close-root'
                                            }
                                        }>
                                            <FontAwesomeIcon icon={faTimes} onClick={() => clearSearchText()} />
                                        </InputAdornment>
                                        <InputAdornment position="end" classes={
                                            {
                                                root: 'input-icon-search-root'
                                            }
                                        }>
                                            <FontAwesomeIcon icon={faSearch} className="icon-search" />
                                        </InputAdornment>
                                    </>}
                                    id="input-search-text" value={searchText} placeholder="Rechercher..." onChange={(event) => handleSearchTextKeyPressed(event.target.value)} />
                            </Box>
                            <Box alignSelf="center" ml={'10px'}>
                                <Button variant="contained" color="primary" title="Exporter sous format Excel" onClick={exportToExcel}>
                                    Exporter
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" alignSelf="center" width="50%" p="10px">
                        <Box display="flex" flexDirection="row">
                            <Box display="flex" flexDirection="column" className="total-sum-content">
                                <Box className="totals-title">Total</Box>
                                <Box className="totals-value">
                                    {(totals.totalCosts + totals.totalFees).toStringFixed("fr", 2) + " €"}
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" className="total-content">
                                <Box className="total-title">Coût</Box>
                                <Box className="total-value">
                                    {totals.totalCosts.toStringFixed("fr", 2) + " €"}
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" className="total-content border-radius">
                                <Box className="total-title">Frais</Box>
                                <Box className="total-value">
                                    {totals.totalFees.toStringFixed("fr", 2) + " €"}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    {(loading ?
                        <ScaleLoader
                            width={5}
                            height={20}
                            radius={50}
                            color={'#000000'}
                            loading={loading}
                        />
                        :
                        <Box className="published-costs-list">
                            <PublishedCostsGridComponent
                                role={props.role}
                                data={data}
                                isForInternalTransporters={props.isForInternalTransporters}
                                cellRender={cellRender}
                                rowRender={rowRender}
                                selectionChange={selectionChange}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
    );
}

export default React.forwardRef(withRouter(ThirdPartyTransporterPublishedCostsView));