import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult, WebAppActionResultEx } from '../../../shared/models/WebAppActionResult';
import { IntegratePreInvoicesRequestArgs } from '../services/dataContracts/controller/IntegratePreInvoicesRequestArgs';
import { PreInvoiceDetailChangeInvoicePriceRequestArgs } from '../services/dataContracts/controller/PreInvoiceDetailChangeInvoicePriceRequestArgs';
import { PreInvoiceDetailChangeRemarksRequestArgs } from '../services/dataContracts/controller/PreInvoiceDetailChangeRemarksRequestArgs';
import { PreInvoiceGenerateRequestArgs } from '../services/dataContracts/controller/PreInvoiceGenerateRequestArgs';
import { TransportSaleLightModel } from '../services/dataContracts/queryStack/TransportSaleLightModel';
import { CopyFlowPriceIntoInvoicePriceRequestArgs } from './dataContracts/controller/CopyFlowPriceIntoInvoicePriceRequestArgs';
import { CreateRegularizationPreInvoiceRequestArg } from './dataContracts/controller/CreateRegularizationPreInvoiceRequestArg';
import { PreInvoicesSearchRequestArgs } from './dataContracts/controller/PreInvoicesSearchRequestArgs';
import { RemovePreInvoiceRequestArgs } from './dataContracts/controller/RemovePreInvoiceRequestArgs';
import { TextSearchField } from './dataContracts/controller/TextSearchField';
import { VehiclesCostsWithMarginSearchRequestArgs } from './dataContracts/controller/VehiclesCostsWithMarginSearchRequestArgs';
import { NewPreInvoiceRegularizationInitModel } from './dataContracts/queryStack/NewPreInvoiceRegularizationInitModel';
import { TransportSaleCostWithMarginLightModel } from './dataContracts/queryStack/TransportSaleCostWithMarginLightModel';

const controllerPath = "api/TransportSales";

export class TransportSalesApiClient {
    public static SearchPreInvoices = (pageSize: number, pageIndex: number, searchText: string, searchTextFields: Array<TextSearchField>, fromTime: Date, toTime: Date, toPreInvoiceOnly: boolean, toIntegrateOnly: boolean, hasSendingError: boolean, logisticsUnitIds: string[])
        : Promise<AxiosResponse<Array<TransportSaleLightModel>>> => {

        const requestArgs: PreInvoicesSearchRequestArgs = {
            pageSize: pageSize,
            pageIndex: pageIndex,
            fromTime: fromTime,
            toTime: toTime,
            logisticsUnitIds: logisticsUnitIds,
            searchText: searchText,
            searchTextFields: searchTextFields,
            toPreInvoiceOnly: toPreInvoiceOnly,
            toIntegrateOnly: toIntegrateOnly,
            hasSendingError: hasSendingError
        }

        return axios.post(`${controllerPath}/SearchPreInvoices`,
            JSON.stringify(requestArgs), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
    }

    public static GetVehiclesCostsWithMargin = (pageSize: number, pageIndex: number, fromTime: Date, toTime: Date, logisticsUnitIds: string[])
        : Promise<AxiosResponse<Array<TransportSaleCostWithMarginLightModel>>> => {

        const requestArgs: VehiclesCostsWithMarginSearchRequestArgs = {
            pageSize: pageSize,
            pageIndex: pageIndex,
            fromTime: fromTime,
            toTime: toTime,
            logisticsUnitIds: logisticsUnitIds
        }

        return axios.post(`${controllerPath}/GetVehiclesCostsWithMargin`,
            JSON.stringify(requestArgs), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
    }

    public static GetNewPreInvoiceRegularizationInitData = (preInvoiceId: string)
        : Promise<AxiosResponse<NewPreInvoiceRegularizationInitModel>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const url = controllerPath + "/GetNewPreInvoiceRegularizationInitData?preInvoiceId=" + encodeURIComponent(preInvoiceId);
        return axios.post(url, {}, { headers: _headers });
    }

    public static GeneratePreInvoicesFromTransportFlows = (preInvoiceGenerateRequest: PreInvoiceGenerateRequestArgs)
        : Promise<AxiosResponse<WebAppActionResultEx<Map<number, string>>>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const url = controllerPath + "/GeneratePreInvoicesFromTransportFlows";
        return axios.post(url, preInvoiceGenerateRequest, { headers: _headers });
    }

    public static UpdatePreInvoiceDetailRemarks(preInvoiceId: string, planningId: number, transportFlowId: string, versionOfAggregate: number, planningVehicleId: string, preInvoiceDetailRemarks: string)
        : Promise<AxiosResponse<WebAppActionResult>> {
        const preInvoiceDetailChange: PreInvoiceDetailChangeRemarksRequestArgs = {
            preInvoiceId: preInvoiceId,
            planningId: planningId,
            transportFlowId: transportFlowId,
            planningVehicleId: planningVehicleId,
            preInvoiceDetailRemarks: preInvoiceDetailRemarks,
            versionOfAggregate: versionOfAggregate
        }
        return axios({
            method: 'POST',
            url: controllerPath + '/UpdatePreInvoiceDetailRemarks',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(preInvoiceDetailChange)
        });
    }

    public static UpdatePreInvoiceDetailInvoicePrice(preInvoiceId: string, planningId: number, transportFlowId: string, versionOfAggregate: number, planningVehicleId: string, invoicePrice: number)
        : Promise<AxiosResponse<WebAppActionResult>> {
        const preInvoiceDetailChange: PreInvoiceDetailChangeInvoicePriceRequestArgs = {
            preInvoiceId: preInvoiceId,
            planningId: planningId,
            transportFlowId: transportFlowId,
            planningVehicleId: planningVehicleId,
            preInvoiceDetailInvoicePrice: invoicePrice,
            versionOfAggregate: versionOfAggregate
        }
        return axios({
            method: 'POST',
            url: controllerPath + '/UpdatePreInvoiceBillingPrice',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(preInvoiceDetailChange)
        });
    }

    public static CopyFlowPriceIntoInvoicePrice(requestArgs: CopyFlowPriceIntoInvoicePriceRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> {
        return axios({
            method: 'POST',
            url: controllerPath + '/CopyFlowPriceIntoInvoicePrice',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static SendIntegrationPreInvoices = (requestArgs: IntegratePreInvoicesRequestArgs)
        : Promise<AxiosResponse<WebAppActionResultEx<Map<number, string>>>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const url = controllerPath + "/IntegratePreInvoices";
        return axios.post(url, requestArgs, { headers: _headers });
    }

    public static CreateRegularizationPreInvoice = (requestArgs: CreateRegularizationPreInvoiceRequestArg)
        : Promise<AxiosResponse<WebAppActionResultEx<Map<number, string>>>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const url = controllerPath + "/CreateRegularizationPreInvoice";
        return axios.post(url, requestArgs, { headers: _headers });
    }

    public static RemovePreInvoice(requestArgs: RemovePreInvoiceRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const url = controllerPath + "/RemovePreInvoice";
        return axios.post(url, requestArgs, { headers: headers });
    }
}