import React from 'react';
import { toast } from 'react-toastify';

export default class ToastService {
    public static showSuccessToast = (successMessage: string, endMessage?: string): void => {
        toast.success(
            <>
                <div>
                    {successMessage}
                </div>
                {endMessage && <div>
                    {endMessage}
                </div>}
            </>
            , {
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                autoClose: 5000
            });
    }

    public static showErrorToast = (mainMessage: string, errors?: string[], endMessage?: string): void => {
        toast.error(
            <>
                {mainMessage && <div>
                    {mainMessage}
                </div>}
                {errors && <div className="lst-msg-error">
                    {
                        errors.map((elem, index) => {
                            return (
                                <div key={`err-${index}`}>
                                    {elem}
                                </div>
                            )
                        })
                    }
                </div>}
                {endMessage && <div>
                    {endMessage}
                </div>
                }
            </>
            , {
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                autoClose: 5000
            });
    }

    public static showWarningToast = (mainMessage: string, warnings?: string[], endMessage?: string): void => {
        toast.warning(
            <>
                {mainMessage && <div>
                    {mainMessage}
                </div>}
                {warnings && <div className="lst-msg-warning">
                    {
                        warnings.map((elem, index) => {
                            return (
                                <div key={`err-${index}`}>
                                    {elem}
                                </div>
                            )
                        })
                    }
                </div>}
                {endMessage && <div>
                    {endMessage}
                </div>
                }
            </>
            , {
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                autoClose: 5000
            });
    }
}