/*eslint-disable*/
//Erreur no-extra-semi
import './Number';
import moment from 'moment';

export { }

declare global {
    interface DateConstructor {
        _doesNativeDateParserAssumesLocalTimeWhenZoneNotUnspecified: boolean;
        _doesNativeDateParserAssumesLocalZeroWhenOnlyDate: boolean;

        isValid(date: Date): boolean;
        parseJSON(s: string): Date;
        parseJSONwithDefault(s: string, defaultResult: Date): Date;
        getToday(): Date;
        getNow(): Date;
        getFirstOfCurrentMonth(): Date;
        getLastOfCurrentMonth(): Date;
        getFirstOfMonth(date: Date): Date;
        getLastOfMonth(date: Date): Date;
        getLastOfCurrentWeek(): Date;
        getLastOfBeforeTwoMonths(): Date;
        getMondayOfCurrentWeek(): Date;
        getSundayOfCurrentWeek(): Date;
        getLastDayOfCurrentYear(): Date;
        getFirstDayOfCurrentYear(): Date;
        equals(d1: Date, d2: Date): boolean;
        min(...dates: Date[]): Date;
        max(...dates: Date[]): Date;

        setTimezoneCookie();
        getTimeFromIsoString(isoString: Date): Date;
        getDateFromIsoString(isoString: Date): Date;
        getDateFormatterFromIsoString(isoString: Date, format: string): string;
        getDateFromNumericString(numericString: string): Date;
        getDateTimeFromIsoString(isoString: Date): Date;
        getDateMonthLettersFromIsoString(isoString: Date): Date;
        sortableString(isoString: Date): Date;
    }

    interface Date {
        hasEmptyTime(): boolean;
        hasSameDateAs(other: Date): boolean;
        toJsonUnspecifiedZone(): string;
        toJsonTimezoned(): string;
        toJSON(): string;
        stripTime(): Date;
        equals(other: Date): boolean;
        toShortTimeString(): string;
        toCustomTimeString(): string;
        toShortDateDayMonthString(): string;
        toShortDateString(): string;
        toShortDateTimeString(): string;
        toCustomDateTimeString(): string;
        getWeek(): number;
        computeBilledHours(): number;
        toNumericDate(): number;
        getDayStart(): Date;
        getDayEnd(): Date;
        addDays(daysToAdd: number): Date;
        addMonths(monthsToAdd: number): Date;
        addMinutes(minutesToAdd: number): Date;
    }
}

Date.isValid = function (date: Date) {
    return Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.getTime());
};

Date.parseJSON = function (s) {

    if (s == null)
        return new Date(s);

    if (Date._doesNativeDateParserAssumesLocalTimeWhenZoneNotUnspecified === undefined) {
        var d = new Date("2016-01-01T00:00:00");
        if (d.getHours() == 0) {
            //ex: Edge, IE11
            Date._doesNativeDateParserAssumesLocalTimeWhenZoneNotUnspecified = true;
        }
        else {
            //ex: CHROME
            Date._doesNativeDateParserAssumesLocalTimeWhenZoneNotUnspecified = false;
        }
    };

    if (Date._doesNativeDateParserAssumesLocalZeroWhenOnlyDate === undefined) {
        var d = new Date("2016-01-01");
        if (d.getHours() == 0) {
            //ex: ?
            Date._doesNativeDateParserAssumesLocalZeroWhenOnlyDate = true;
        }
        else {
            //ex: CHROME, Edge, IE11
            Date._doesNativeDateParserAssumesLocalZeroWhenOnlyDate = false;
        }
    };

    //ex: 2020-06-10T09:26:18.7425323+00:00
    if (s.indexOf("-") === 4 && s.lastIndexOf("-") === 7 && s.indexOf("T") === 10 && s.indexOf(":") === 13) {

        let withoutOffset = s;
        let offsetSeparatorIndex = s.lastIndexOf("+");
        if (offsetSeparatorIndex > 0) {
            // c'est temporaire en attendant de revenir sur le parsing des dates et la gestion des fuseaux.
            // on s'assure que les dates aux fuseaux 0/utc s'affiche à l'heure locale.
            if (s.endsWith("00:00"))
                return new Date(withoutOffset);

            withoutOffset = s.substring(0, offsetSeparatorIndex);

            if (Date._doesNativeDateParserAssumesLocalTimeWhenZoneNotUnspecified == true)
                return new Date(withoutOffset);

            //when there is no timezone offset nor Z indication in the string
            //the native implementation will consider the date as being UTC and adjust/convert the time
            //to browser's local time zone.
            //the trick will be to rollback this adjustement
            var result = new Date(s);
            var hoursOffset = result.getTimezoneOffset() / 60;
            result.setHours(result.getHours() + hoursOffset);
            return result;
        }

        offsetSeparatorIndex = s.lastIndexOf("-");
        if (offsetSeparatorIndex > 10) {
            withoutOffset = s.substring(0, offsetSeparatorIndex);

            if (Date._doesNativeDateParserAssumesLocalTimeWhenZoneNotUnspecified == true)
                return new Date(withoutOffset);

            //when there is no timezone offset nor Z indication in the string
            //the native implementation will consider the date as being UTC and adjust/convert the time
            //to browser's local time zone.
            //the trick will be to rollback this adjustement
            var result = new Date(s);
            var hoursOffset = result.getTimezoneOffset() / 60;
            result.setHours(result.getHours() + hoursOffset);
            return result;
        }

        if (s.endsWith('Z')) {
            //use the faster native implem for UTC date strings
            return new Date(s);
        }
    }
    return new Date('');
};

Date.parseJSONwithDefault = function (s: string, defaultResult: Date) {
    var result = Date.parseJSON(s);
    if (Date.isValid(result))
        return result;

    return defaultResult;
};

Date.getToday = function () {
    var result = new Date().stripTime();
    return result;
};

Date.getNow = function () {
    var result = new Date();
    return result;
};

Date.getFirstOfCurrentMonth = function (): Date {
    var date = new Date();
    return this.getFirstOfMonth(date);
};

Date.getLastOfCurrentMonth = function (): Date {
    var date = new Date();
    return this.getLastOfMonth(date);
};

Date.getFirstOfMonth = function (date: Date): Date {
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDay;
};

Date.getLastOfMonth = function (date: Date): Date {
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDay;
};

Date.getLastDayOfCurrentYear = function (): Date {
    var date = new Date();
    var lastDayYear = new Date(date.getFullYear(), 11, 31);
    return lastDayYear;
};

Date.getFirstDayOfCurrentYear = function (): Date {
    var date = new Date();
    var firstDayYear = new Date(date.getFullYear(), 0, 1);
    return firstDayYear;
};

Date.getMondayOfCurrentWeek = function (): Date {
    var firstDay = Date.getToday();
    firstDay.setDate(firstDay.getDate() - (firstDay.getDay() + 6) % 7);
    return firstDay;
};

Date.getSundayOfCurrentWeek = function (): Date {
    var lastDay = Date.getToday();
    lastDay.setDate(lastDay.getDate() + (7 - lastDay.getDay()) % 7);
    return lastDay;
};

Date.getLastOfCurrentWeek = function (): Date {
    var lastDay = Date.getToday();
    lastDay.setDate(lastDay.getDate() - 7);
    return lastDay;
}

Date.getLastOfBeforeTwoMonths = function (): Date {
    var lastDay = Date.getToday();
    lastDay.setMonth(lastDay.getMonth() - 2);
    return lastDay;
}

Date.equals = function (d1: Date, d2: Date): boolean {

    if (d1 === null && d2 === null)
        return true;

    // Toujours créer des dates à partir des valeurs passés en paramètres puisque on n'est pas toujours sur du typage des données.
    var date1 = new Date(d1);
    if (!Date.isValid(date1))
        return false;

    var date2 = new Date(d2);
    if (!Date.isValid(date2))
        return false;

    var result = (date1.getTime() == date2.getTime());
    return result;
};

Date.min = function (...dates: Date[]): Date {
    if (dates.length == 0)
        return null;

    var minTime = null;
    for (var i = 0; i < dates.length; i++) {
        var date = dates[i];
        if (date != null && Date.isValid(date)) {
            minTime = (minTime == null) ? date.getTime() : Math.min(minTime, dates[i].getTime());
        }
    }
    return minTime == null ? null : new Date(minTime);
};

Date.max = function (...dates: Date[]): Date {
    if (dates.length == 0)
        return null;

    var maxTime = null;
    for (var i = 0; i < dates.length; i++) {
        var date = dates[i];
        if (date != null && Date.isValid(date)) {
            maxTime = (maxTime == null) ? date.getTime() : Math.max(maxTime, dates[i].getTime());
        }
    }
    return maxTime == null ? null : new Date(maxTime);
};

Date.setTimezoneCookie = function () {
    //Date.getTimezoneOffset() returns the difference between UTC time and local time, in minutes. (ex: for a GMT+2 timezone, it returns -120).
    //The cookie will be valorised with the difference between local time and UTC time.  
    //Ex: for a GMT+2 timezone, the cookie value will be 120 (minutes).
    var tzOffset = (-1) * new Date().getTimezoneOffset();

    //SYNC cookie name with Global.asax.cs
    document.cookie = "tz=" + tzOffset.toString() + "; path=/";
};

Date.prototype.hasEmptyTime = function () {

    if (this.getHours() > 0)
        return false;

    if (this.getMinutes() > 0)
        return false;

    if (this.getSeconds() > 0)
        return false;

    if (this.getMilliseconds() > 0)
        return false;

    return true;
};

Date.prototype.hasSameDateAs = function (other: Date) {
    return (
        this.getFullYear() == other.getFullYear() &&
        this.getMonth() == other.getMonth() &&
        this.getDate() == other.getDate()
    );
};

Date.prototype.toJsonUnspecifiedZone = function () {
    return this.getFullYear() + '-' +
        (this.getMonth() + 1).padZero() + '-' +
        this.getDate().padZero() +
        'T' +
        this.getHours().padZero() + ':' +
        this.getMinutes().padZero() + ':' +
        this.getSeconds().padZero() +
        '.' +
        this.getMilliseconds().padZero(7);
};

Date.prototype.toJsonTimezoned = function () {
    var tzOffset = this.getTimezoneOffset();
    var tzString;
    if (tzOffset == 0) {
        tzString = '+00:00';
    }
    else {
        var absOffset = Math.abs(tzOffset);
        var hOffset = ~~(absOffset / 60);
        var mOffset = ~~(absOffset % 60);

        tzString = (tzOffset < 0 ? '+' : '-') + // not a bug, showing '+' when negative offset ;)
            hOffset.padZero() + ':' +
            mOffset.padZero();
    }

    return this.toJsonUnspecifiedZone() + tzString;
};

Date.prototype.toJSON = function () {
    return this.toJsonTimezoned();
};

Date.prototype.stripTime = function () {
    var result = new Date(this.getFullYear(), this.getMonth(), this.getDate());
    return result;
};

Date.prototype.equals = function (other: Date): boolean {
    return Date.equals(this, other);
};

Date.prototype.toShortTimeString = function (): string {
    return this.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

Date.prototype.toCustomTimeString = function (): string {
    return this.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
};

Date.prototype.toShortDateDayMonthString = function (): string {
    return this.getDate().toString().padStart(2, "0") + '/' + (this.getMonth() + 1).toString().padStart(2, "0");
};

Date.prototype.toShortDateString = function (): string {
    return this.toShortDateDayMonthString() + '/' + this.getFullYear();
};

Date.prototype.toShortDateTimeString = function (): string {
    return this.toShortDateString() + ' - ' + this.toShortTimeString();
};

Date.prototype.toCustomDateTimeString = function (): string {
    return this.toShortDateString() + ' - ' + this.toCustomTimeString();
};
Date.prototype.computeBilledHours = function (): number {
    return this.getHours() + ((this.getMinutes() - this.getMinutes() % 15) / 60)
};

Date.prototype.toNumericDate = function (): number {
    return this.getFullYear() * 10000 + ((this.getMonth() + 1) * 100) + this.getDate();
};

Date.prototype.getWeek = function () {
    var target: any = new Date(this.valueOf());
    // ISO week date weeks start on monday  
    var dayNr = (this.getDay() + 6) % 7;
    // Set the target to the thursday of this week so the  
    // target date is in the right year  
    target.setDate(target.getDate() - dayNr + 3);
    // ISO 8601 states that week 1 is the week  
    // with january 4th in it  
    var jan4: any = new Date(target.getFullYear(), 0, 4);
    // Number of days between target date and january 4th  
    var dayDiff = (target - jan4) / 86400000;
    // Calculate week number: Week 1 (january 4th) plus the    
    // number of weeks between target date and january 4th    
    var weekNr = 1 + Math.ceil(dayDiff / 7);

    return weekNr;
}

Date.prototype.getDayStart = function () {
    var result = new Date(this.getFullYear(), this.getMonth(), this.getDate(), 0, 0, 0, 0);
    return result;
};

Date.prototype.getDayEnd = function () {
    var result = new Date(this.getFullYear(), this.getMonth(), this.getDate(), 23, 59, 59, 0);
    return result;
};

Date.prototype.addDays = function (daysToAdd: number) {
    var result = this;
    result.setDate(result.getDate() + daysToAdd);
    return result;
};

Date.prototype.addMonths = function (monthsToAdd: number) {
    var result = this;
    result.setMonth(result.getMonth() + monthsToAdd);
    return result;
};

Date.prototype.addMinutes = function (minutesToAdd: number) {
    var result = this;
    result.setMinutes(result.getMinutes() + minutesToAdd);
    return result;
};

Date.getDateFromIsoString = function (isoString: Date): Date {
    var m = moment.parseZone(isoString);
    var result: any = m.format("DD/MM/YYYY");
    return result;
};

Date.getDateFromNumericString = function (numericString: string): Date {
    var m = moment(numericString, "YYYYMMDD");
    return m.toDate();
};

Date.getTimeFromIsoString = function (isoString: Date): Date {
    var m = moment.parseZone(isoString);
    var result: any = m.format('HH:mm');
    return result;
};

Date.getDateTimeFromIsoString = function (isoString: Date): Date {
    var m = moment.parseZone(isoString);
    var result: any = m.format('DD/MM/YYYY HH:mm');
    return result;
};

Date.sortableString = function (isoString: Date): Date {
    var m = moment.parseZone(isoString);
    var result: any = m.format("YYYYMMDD");
    return result;
}

Date.getDateMonthLettersFromIsoString = function (isoString: Date): Date {
    var m = moment.parseZone(isoString);
    var result: any = m.format('DD MMM');
    return result;
};

Date.getDateFormatterFromIsoString = function (isoString: Date, formatString: string): string {
    var m = moment.parseZone(isoString);
    var result: any = m.format(formatString);
    return result;
};