import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';

export const CustomIsNightCell = (props: GridCellProps) => {
    const dataValue: number = props.dataItem[props.field] ? props.dataItem[props.field] : null;
    let defaultRendering: JSX.Element = <></>;

    if (dataValue) {
        defaultRendering = (
            <td>Oui</td>
        );
    } else {
        defaultRendering = (
            <td></td>
        );
    }

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}
