import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import { NumericTextBox, NumericTextBoxBlurEvent, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LogisticsUnitChoice } from '../../../shared/models/LogisticsUnitChoice';
import { VehicleTypeLightModel } from '../services/dataContracts/queryStack/VehicleTypeLightModel';

interface AddVehicleProperties {
    vehicleTypes: VehicleTypeLightModel[],
    chosenLogisticsUnits: LogisticsUnitChoice[],
    onClose: () => void,
    handleCreatePlanningVehicles: (logisticsUnitId: string, vehicleTypeId: string, isNightWork: boolean, numberOfVehicles: number) => void,
    disableCreatePlanningVehicles: boolean
}

export const AddVehicleComponent = (props: AddVehicleProperties) => {
    const [selectedLogisticsUnitId, setSelectedLogisticsUnitId] = useState<string>('none');
    const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState<string>('none');
    const [isNightWork, setIsNightWork] = useState<boolean>(false);
    const [numberOfVehicles, setNumberOfVehicles] = useState<number>(1);
    const [isInvalidNumberOfVehicles, setIsInvalidNumberOfVehicles] = useState<boolean>(false);

    useEffect(() => {
        //Séléctionner directement la Zone Logistique dans le cas où la liste des Zones Logistiques égal à 1
        if (props.chosenLogisticsUnits.length == 1) {
            const logisticsUnitId = props.chosenLogisticsUnits[0].logisticsUnitId;
            setSelectedLogisticsUnitId(logisticsUnitId);
        }
    }, []);

    const handleLogisticsUnitChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        const logisticsUnitId = e.target.value;
        setSelectedLogisticsUnitId(logisticsUnitId);
        if (logisticsUnitId == 'none') {
            setSelectedVehicleTypeId('none');
        }
    }
    const handleVehicleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        const VehicleTypeId = e.target.value;
        setSelectedVehicleTypeId(VehicleTypeId);
    }

    const handleNightWorkCheckChange = (): void => {
        setIsNightWork(!isNightWork);
    }

    const createPlanningVehicles = debounce((): void => {
        props.handleCreatePlanningVehicles(selectedLogisticsUnitId, selectedVehicleTypeId, isNightWork, numberOfVehicles);
    }, 500);

    const onNumberOfVehiclesChange = (event: NumericTextBoxChangeEvent): void => {
        const value: number = event.target.value;
        setNumberOfVehicles(value);
        if (value < 1 || value > 10)
            setIsInvalidNumberOfVehicles(true);
        else
            setIsInvalidNumberOfVehicles(false);
    }

    const onNumberOfVehiclesBlur = (event: NumericTextBoxBlurEvent): void => {
        const value: number = event.target.value;
        const numberOfVehicles: number = value < 1 ? 1 : (value > 10 ? 10 : value);
        setNumberOfVehicles(numberOfVehicles);
        setIsInvalidNumberOfVehicles(false);
    }

    return (
        <Box display='flex' flexDirection='column' p={1}>
            <Box pb={2}>
                <select className="logisticsUnit-select" aria-placeholder="Zone logistique" disabled={props.chosenLogisticsUnits.length < 2} onChange={e => handleLogisticsUnitChange(e)} value={selectedLogisticsUnitId}>
                    <option value="none" className='title-select'>Zone logistique</option>
                    {props.chosenLogisticsUnits.map((logisticsUnit, logisticsUnitIndex) =>
                        <option key={`logisticsUnit-${logisticsUnitIndex}`} value={logisticsUnit.logisticsUnitId}>{logisticsUnit.label}</option>
                    )}
                </select>
            </Box>
            <Box pb={2}>
                <select className="vehicle-type-select" aria-placeholder="Type véhicule" disabled={selectedLogisticsUnitId == "none"} onChange={e => handleVehicleTypeChange(e)} value={selectedVehicleTypeId}>
                    <option value="none" className='title-select'>Type véhicule</option>
                    {props.vehicleTypes.map((v, vIndex) =>
                        <option key={`vehicle-type-${vIndex}`} value={v.vehicleTypeId}>{v.label}</option>
                    )}
                </select>
            </Box>
            <Box pb={2}>
                Nombre de camions:
                <LocalizationProvider language="fr-FR">
                    <IntlProvider locale="fr" >
                        <NumericTextBox className="number-of-vehicles-input"
                            spinners={true}
                            onChange={(event) => onNumberOfVehiclesChange(event)}
                            onBlur={(event) => onNumberOfVehiclesBlur(event)}
                            value={numberOfVehicles}
                        />
                    </IntlProvider>
                </LocalizationProvider>
            </Box>
            {isInvalidNumberOfVehicles &&
                <Box>
                    <span className="error-msg">Le nombre de camions doit être entre 1 et 10.</span>
                </Box>
            }
            <Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isNightWork}
                            onChange={() => handleNightWorkCheckChange()}
                            color="default"
                        />
                    }
                    label="Chantier de nuit"
                />
            </Box>
            <Box display='flex' flexDirection='row' pt={2}>
                <Box pr={2} width="100%">
                    <Button variant="contained" color="secondary" onClick={props.onClose}>
                        Annuler
                    </Button>
                </Box>
                <Box width="100%">
                    <Button variant="contained" color="primary" disabled={selectedLogisticsUnitId == 'none' || selectedVehicleTypeId == 'none' || props.disableCreatePlanningVehicles || isInvalidNumberOfVehicles}
                        onClick={() => createPlanningVehicles()}>
                        Sauvegarder
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}