import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import React, { useState } from 'react';
import Select from 'react-select';
import { CalendarComponent } from 'src/shared/components/Common/CalendarComponent';
import { DateRange } from 'src/shared/models/DateRange';
import { LogisticsUnitChoice } from 'src/shared/models/LogisticsUnitChoice';
import BusinessErrors from 'src/utils/BusinessErrors';
import { LogisticsUnitSelectModel } from '../../../../shared/models/LogisticsUnitSelectModel';
import { AddOverheadExpenseRequestArgs } from '../services/dataContracts/controller/AddOverheadExpenseRequestArgs';
import { OverheadExpensesReferentialApiClient } from '../services/OverheadExpensesReferentialApiClient';

interface AddOverheadExpenseProps {
    logisticsUnits: Array<LogisticsUnitChoice>,
    HandleCloseDialog: () => void,
    HandleResult: (errors: Array<string>) => void,
    DisplayError: (error) => void
}

export const AddOverheadExpenseComponent = (props: AddOverheadExpenseProps): JSX.Element => {
    const [selectedLogisticsUnit, setSelectedLogisticsUnit] = useState<LogisticsUnitSelectModel>(props.logisticsUnits.length == 1 ? { value: props.logisticsUnits[0].logisticsUnitId, label: props.logisticsUnits[0].label } : null);
    const [dailyValue, setDailyValue] = useState<number>(0);
    const [requestPending, setRequestPending] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<DateRange>({
        start: Date.getFirstDayOfCurrentYear(),
        end: Date.getLastDayOfCurrentYear()
    });

    const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);
    const [enabledValidButton, setEnabledValidButton] = useState<boolean>(false);

    const HandleLogisticsUnitChange = (event: LogisticsUnitSelectModel): void => {
        setSelectedLogisticsUnit(event);

        setEnabledValidButton(isDateRangeValid && event && dailyValue > 0);
    }

    const HandleChangeDateRange = (event: DateRange): void => {
        const startDate = event.start?.getDayStart();
        const endDate = event.end?.getDayStart();

        setDateRange({ start: startDate, end: endDate });
    }

    const HandleChangeDailyValue = (event: NumericTextBoxChangeEvent): void => {
        const value = event.target.value != null ? event.target.value : 0;
        //specifier "min=0" sur le composant bloque uniquement la saisi des negatifs avec des fleches
        //si on ecrit directement il n'ya pas de blocage
        if (value >= 0) {
            setDailyValue(value);
            setEnabledValidButton(isDateRangeValid && selectedLogisticsUnit && value > 0);
        }
        else {
            event.nativeEvent.preventDefault();
        }
    }

    const handleIsDateRangeValidChanged = (isValid: boolean): void => {
        setIsDateRangeValid(isValid);
        setEnabledValidButton(isValid && selectedLogisticsUnit && dailyValue > 0);
    }

    const AddOverheadExpense = (): void => {
        const requestArgs: AddOverheadExpenseRequestArgs = {
            logisticsUnitId: selectedLogisticsUnit?.value,
            startDate: dateRange.start,
            endDate: dateRange.end,
            dailyValue: dailyValue
        };

        setRequestPending(true);
        OverheadExpensesReferentialApiClient.AddOverheadExpense(requestArgs)
            .then(res => {
                const data = res?.data;

                const errors = BusinessErrors.Get(data);
                props.HandleResult(errors);
            })
            .finally(() => {
                setRequestPending(false);
            });
    }

    const handleClose = (_event, reason: string) =>{
        if (reason !== "backdropClick") {
            props.HandleCloseDialog();
        }
    }

    return (
        <Dialog
            open={true}
            disableEscapeKeyDown
            aria-modal="false"
            onClose={handleClose}
            scroll="paper"
            className="simple-dialog-new-add-overhead-expense"
        >
            <DialogTitle id="scroll-dialog-title">
                Ajouter des nouveaux frais de structure
            </DialogTitle>
            <DialogContent dividers>
                <Box display="flex" flexDirection="column" className="dialog-add">
                    <Select
                        className="logistics-unit-select"
                        id="vehicle-type"
                        isClearable
                        placeholder="Zone logistique"
                        menuPosition="fixed"
                        menuShouldBlockScroll={true}
                        isDisabled={props.logisticsUnits.length < 2}
                        options={props.logisticsUnits.map(x => { return { label: x.label, value: x.logisticsUnitId } })}
                        value={selectedLogisticsUnit}
                        onChange={(e) => HandleLogisticsUnitChange(e)}
                    />
                    <Box pt={2}>
                        <div className="title">
                            Période
                        </div>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box pr={2}>
                                <CalendarComponent handlerFromChildToParent={HandleChangeDateRange} startDate={dateRange.start} endDate={dateRange.end}
                                    calendarId='referential-overhead-expense-daterange-picker' isValid={isDateRangeValid}
                                    handleIsValidChanged={handleIsDateRangeValidChanged} />
                            </Box>
                            <Tooltip title="La période ne doit pas chevaucher une période de validité existante pour la Zone logistique.">
                                <div>
                                    <FontAwesomeIcon className="fa-info-circle-icon" size="lg" icon={faInfoCircle} />
                                </div>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box pt={2}>
                        <div className="title">
                            Montant des frais de structure journaliers :
                        </div>
                        <NumericTextBox
                            min={0}
                            format={{
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'EUR',
                                currencyDisplay: 'symbol'
                            }}
                            onChange={(event) => HandleChangeDailyValue(event)}
                            value={dailyValue}
                            spinners={false} />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.HandleCloseDialog} color="primary">
                    Annuler
                </Button>
                <Button color="primary" disabled={!enabledValidButton || requestPending} onClick={AddOverheadExpense}>
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    );
}