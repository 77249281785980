import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useState } from 'react';
import { DeliveryTripCancellationReason } from "../../models/DeliveryTripCancellationReason";

interface CancelDeliveryTripDialogComponentProps {
    handleCloseCancelDeliveryTripDialog: () => void,
    handleSaveCancelDeliveryTripDialog: (cancellationReason: DeliveryTripCancellationReason, cancellationRemarks: string) => void;
}

export const CancelDeliveryTripDialogComponent = (props: CancelDeliveryTripDialogComponentProps): JSX.Element => {

    const [cancellationReason, setCancellationReason] = useState<DeliveryTripCancellationReason>(null);
    
    const txtCancellationRemarks: React.RefObject<HTMLTextAreaElement> = React.useRef(null);

    const handleSaveCancelPlanningVehicleDialog = (): void => {
        props.handleSaveCancelDeliveryTripDialog(cancellationReason, txtCancellationRemarks?.current?.value ?? null);
    };

    const handleChangeCancellationReason = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value as DeliveryTripCancellationReason;
        setCancellationReason(value);
    };

    return (
        <Box display="flex" flexDirection="column" className="cancel-planning-vehicle-content">
            <FormControl>
                <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(e) => handleChangeCancellationReason(e)}>
                    <FormControlLabel value={DeliveryTripCancellationReason.badWeather} control={<Radio />} label="Intempéries" />
                    <FormControlLabel value={DeliveryTripCancellationReason.customerCancellation} control={<Radio />} label="Annulation client" />
                    <FormControlLabel value={DeliveryTripCancellationReason.supplierCancellation} control={<Radio />} label="Annulation fournisseur du client" />
                    <FormControlLabel value={DeliveryTripCancellationReason.transporterCancellation} control={<Radio />} label="Annulation transporteur" />
                    <FormControlLabel value={DeliveryTripCancellationReason.other} control={<Radio />} label="Autre" />
                </RadioGroup>
            </FormControl>
            <Box display="flex" flexDirection="row" mt="15px">
                <Box>Commentaire :</Box>
                <TextField
                    className="cancel-remarks"
                    margin="normal"
                    multiline
                    variant="outlined"
                    rows="4"
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputRef={txtCancellationRemarks}
                />
            </Box>
            <Box mt="15px" display="flex" flexDirection="row" justifyContent="flex-end">
                <Button variant="contained" color="secondary" title="Annuler" onClick={() => props.handleCloseCancelDeliveryTripDialog()}>
                    Annuler
                </Button>
                <Button variant="contained" color="primary" title="Sauvegarder" className="btn-save" disabled={cancellationReason === null} onClick={() => handleSaveCancelPlanningVehicleDialog()}>
                    Sauvegarder
                </Button>
            </Box>
        </Box>
    );
};