import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult } from 'src/shared/models/WebAppActionResult';
import { Level3SegmentationChoiceLogisticsUnitRequestArgs } from './dataContracts/controller/Level3SegmentationChoiceLogisticsUnitRequestArgs';
import { Level3SegmentationChoiceLogisticsUnitsRequestArgs } from './dataContracts/controller/Level3SegmentationChoiceLogisticsUnitsRequestArgs';
import { Level3SegmentationChoicesExportRequestArgs } from './dataContracts/controller/Level3SegmentationChoicesExportRequestArgs';
import { Level3SegmentationLightModel } from './dataContracts/queryStack/Level3SegmentationLightModel';
import { LogisticsUnitChoiceOfLevel3SegmentationsLightModel } from './dataContracts/queryStack/LogisticsUnitChoiceOfLevel3SegmentationsLightModel';

const controllerUrl = 'api/Level3SegmentationsReferential/';

export class Level3SegmentationsReferentialApiClient {
    public static GetLevel3Segmentations = (searchText: string)
        : Promise<AxiosResponse<Array<Level3SegmentationLightModel>>> => {
        return axios.get(`${controllerUrl}GetLevel3Segmentations?searchText=${encodeURIComponent(searchText)}`);
    }

    public static GetLevel3SegmentationChoicesLogisticsUnit = ()
        : Promise<AxiosResponse<Array<LogisticsUnitChoiceOfLevel3SegmentationsLightModel>>> => {
        return axios.get(`${controllerUrl}GetLevel3SegmentationChoicesLogisticsUnit`);
    }

    public static GetLevel3SegmentationsWithLevel3SegmentationChoicesLogisticsUnit = (searchText: string)
        : Promise<[AxiosResponse<Array<Level3SegmentationLightModel>>, AxiosResponse<Array<LogisticsUnitChoiceOfLevel3SegmentationsLightModel>>]> => {
        return Promise.all(
            [
                Level3SegmentationsReferentialApiClient.GetLevel3Segmentations(searchText),
                Level3SegmentationsReferentialApiClient.GetLevel3SegmentationChoicesLogisticsUnit()
            ]);
    }

    public static AddOrRemoveLevel3SegmentationChoice = (level3SegmentationChoiceLogisticsUnit: Level3SegmentationChoiceLogisticsUnitRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddOrRemoveLevel3SegmentationChoice',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(level3SegmentationChoiceLogisticsUnit)
        });
    }

    public static AddOrRemoveLevel3SegmentationChoices = (level3SegmentationChoiceLogisticsUnits: Level3SegmentationChoiceLogisticsUnitsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddOrRemoveLevel3SegmentationChoices',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(level3SegmentationChoiceLogisticsUnits)
        });
    }

    public static ExportLevel3SegmentationChoices = (level3SegmentationChoicesExportRequestArgs: Level3SegmentationChoicesExportRequestArgs)
        : Promise<AxiosResponse<Blob>> => {
        const _headers = {
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return axios.post(controllerUrl + 'ExportLevel3SegmentationChoices', level3SegmentationChoicesExportRequestArgs, { headers: _headers, responseType: 'blob' });
    }

    public static GetImportUrl = (): string => {
        return controllerUrl + 'ImportFile';
    }
}
