import { Box, Card, CardContent, MenuItem, Select } from '@mui/material';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import React, { useState } from 'react';
import ToastService from 'src/ToastService';
import BusinessErrors from 'src/utils/BusinessErrors';
import { fromHours } from 'src/utils/TimeSpanString';
import { AvailablePurchasePriceLightModelExtended } from '../models/AvailablePurchasePriceLightModelExtended';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';
import { VehiclePriceKind } from '../models/VehiclePriceKind';
import { OverridenDeliveredNumberOfTurnsRequestArgs } from '../services/dataContracts/controller/OverridenDeliveredNumberOfTurnsRequestArgs';
import { OverridenDeliveredQuantityRequestArgs } from '../services/dataContracts/controller/OverridenDeliveredQuantityRequestArgs';
import { PlanningVehicleChangeRequestArgs } from '../services/dataContracts/controller/PlanningVehicleChangeRequestArgs';
import { PlanningVehicleDeliveryOverrideKind } from '../services/dataContracts/controller/PlanningVehicleDeliveryOverrideKind';
import { AvailablePurchasePriceLightModel } from '../services/dataContracts/queryStack/AvailablePurchasePriceLightModel';
import { NegotiatedDeliveryTripsGroupLightModel } from '../services/dataContracts/queryStack/NegotiatedDeliveryTripsGroupLightModel';
import { SpecificDeliveryTripsGroupLightModel } from '../services/dataContracts/queryStack/SpecificDeliveryTripsGroupLightModel';
import { TransportPurchasesApiClient } from '../services/TransportPurchasesApiClient';
import { DetailNegotiatedPriceCardComponent } from './DetailNegotiatedPriceCardComponent';
import { DetailSpecificPriceCardComponent } from './DetailSpecificPriceCardComponent';

interface DetailComponentProps {
    dataItem: PlanningVehicleLightModelExtended,
    inEditSelect: boolean,
    inEditInput: boolean,
    getPriceKind: (priceKind: string) => string,
    handleChange: (dataItem: PlanningVehicleLightModelExtended) => void,
    displayErrorsMessages: (errors: string[]) => void,
    refreshPlanningVehicles: () => void,
    refreshPlanningVehicleWithDetails: (planningVehicleId: string) => void
    handleUpdatingPlanningVehicle: (planningVehicleId: string) => void
}

export const DetailComponent = (props: DetailComponentProps) => {
    const dataItem: PlanningVehicleLightModelExtended = props.dataItem;
    let unitPrice: number = dataItem.mainUnitPrice;

    const [unitPriceData, setMainUnitPriceData] = useState<number>(unitPrice);
    const [editSelectData, setEditSelectData] = useState<boolean>(props.inEditSelect);
    const [editInputData, setEditInputData] = useState<boolean>(props.inEditInput);
    const [selectedValueData, setSelectedValueData] = useState<string>(props.dataItem.mainPriceKind + ':' + props.dataItem.mainUnitPrice);

    const handleChange = (dataItem: PlanningVehicleLightModelExtended, event: NumericTextBoxChangeEvent): void => {
        if (event.target.value == null || Number(event.target.value) == 0) {
            unitPrice = null;
        } else {
            unitPrice = Number(event.target.value);
        }
        const unitFixedPrice = unitPrice ? Number(unitPrice.toFixed(2)) : 0;
        dataItem.mainUnitPrice = unitFixedPrice;
        setMainUnitPriceData(unitFixedPrice);
    }

    const handleTransporterVehicleSelectChange = (dataItem: PlanningVehicleLightModelExtended, value: string): void => {
        const eventTargetValue = value;
        const values = eventTargetValue.split(":");
        setSelectedValueData(value);
        dataItem.mainPriceKind = values[0];
        dataItem.mainUnitPrice = Number(values[1]);
        props.handleChange(dataItem);
        handleBlurCost();
    }

    const handleInternalVehicleSelectChange = (dataItem: PlanningVehicleLightModelExtended, value: string): void => {
        setSelectedValueData(value)
        dataItem.mainPriceKind = value;
        props.handleChange(dataItem);
        handleBlurCost();
    }

    const availablePurchasePrices: Array<AvailablePurchasePriceLightModelExtended> = dataItem.availablePurchasePrices;

    const handleBlurCost = (): void => {
        setEditInputData(false);
        const planningVehicleChange: PlanningVehicleChangeRequestArgs = {
            planningId: props.dataItem.planningId,
            planningVehicleId: props.dataItem.planningVehicleId,
            vehicleId: null,
            vehicleTypeId: null,
            propertyName: "unitPrice",
            driverId: null,
            driverName: null,
            driverPhoneNumber: null,
            unitPrice: props.dataItem.mainUnitPrice,
            priceKind: props.dataItem.mainPriceKind,
            date: props.dataItem.planningDate,
            isNightWork: props.dataItem.isNightWork,
            billedTime: null,
            billedHours: null,
            remarks: null,
            costsRemarksOfLogistician: null
        };

        props.handleUpdatingPlanningVehicle(planningVehicleChange.planningVehicleId);
        TransportPurchasesApiClient.UpdatePlanningVehicleCell(planningVehicleChange)
            .then(json => {
                const errors = BusinessErrors.Get(json.data);
                if (errors.length > 0) {
                    props.refreshPlanningVehicles();
                    ToastService.showErrorToast("", errors);
                    return;
                }
                props.handleChange(props.dataItem);
                props.refreshPlanningVehicleWithDetails(planningVehicleChange.planningVehicleId);
            });
    }

    const handlePriceType = (): void => {
        setEditSelectData(true);
    }

    const handleInputPrice = (): void => {
        setEditInputData(true);
    }

    const handleDeliveredQuantitySpecificPriceChanged = (index: number, value: number) => {
        const currentPrice = dataItem.specificPrices[index];
        
        currentPrice.overridenDeliveredQuantity = value;
        props.handleUpdatingPlanningVehicle(props.dataItem.planningVehicleId);

        const requestArgs: OverridenDeliveredQuantityRequestArgs = {
            planningId: props.dataItem.planningId,
            planningVehicleId: props.dataItem.planningVehicleId,
            transportFlowId: currentPrice.transportFlowId,
            overridenDeliveredQuantity: value == null ? 0 : value,
            specificOrNegotiatedPriceKind: currentPrice.priceKind,
            specificPrice: currentPrice.unitPrice,
            deliveryOverrideKind: PlanningVehicleDeliveryOverrideKind.specificPriceTrips
        }

        OverridePlanningVehicleDeliveredQuantity(requestArgs, currentPrice.transportRequestId, currentPrice.transportFlowId, value);
    }

    const handleUndoOverridenDeliveredQuantitySpecificPrice = (index: number) => {
        props.handleUpdatingPlanningVehicle(props.dataItem.planningVehicleId);
        const currentPrice = dataItem.specificPrices[index];
        currentPrice.overridenDeliveredQuantity = null;

        const requestArgs: OverridenDeliveredQuantityRequestArgs = {
            planningId: props.dataItem.planningId,
            planningVehicleId: props.dataItem.planningVehicleId,
            transportFlowId: currentPrice.transportFlowId,
            overridenDeliveredQuantity: null,
            specificOrNegotiatedPriceKind: currentPrice.priceKind,
            specificPrice: currentPrice.unitPrice,
            deliveryOverrideKind: PlanningVehicleDeliveryOverrideKind.specificPriceTrips
        }

        OverridePlanningVehicleDeliveredQuantity(requestArgs, currentPrice.transportRequestId, currentPrice.transportFlowId, null);
    }

    const handleDeliveredQuantityNegotiatedPriceChanged = (index: number, value: number) => {
        const currentPrice = dataItem.negotiatedPurchasePrices[index];
        currentPrice.overridenDeliveredQuantity = value;
        props.handleUpdatingPlanningVehicle(props.dataItem.planningVehicleId);

        const requestArgs: OverridenDeliveredQuantityRequestArgs = {
            planningId: props.dataItem.planningId,
            planningVehicleId: props.dataItem.planningVehicleId,
            transportFlowId: currentPrice.transportFlowId,
            overridenDeliveredQuantity: value == null ? 0 : value,
            specificOrNegotiatedPriceKind: currentPrice.priceKind,
            specificPrice: currentPrice.unitPrice,
            deliveryOverrideKind: PlanningVehicleDeliveryOverrideKind.negotiatedPriceTrips
        }

        OverridePlanningVehicleDeliveredQuantity(requestArgs, currentPrice.transportRequestId, currentPrice.transportFlowId, value);
    }

    const handleDeliveredNumberOfTurnsNegotiatedPriceChanged = (index: number, value: number) => {
        const currentPrice = dataItem.negotiatedPurchasePrices[index];
        currentPrice.overridenDeliveredNumberOfTurns = value;
        props.handleUpdatingPlanningVehicle(props.dataItem.planningVehicleId);

        const requestArgs: OverridenDeliveredNumberOfTurnsRequestArgs = {
            planningId: props.dataItem.planningId,
            planningVehicleId: props.dataItem.planningVehicleId,
            transportFlowId: currentPrice.transportFlowId,
            overridenDeliveredNumberOfTurns: value == null ? 0 : value,
            specificOrNegotiatedPriceKind: currentPrice.priceKind,
            specificPrice: currentPrice.unitPrice,
            deliveryOverrideKind: PlanningVehicleDeliveryOverrideKind.negotiatedPriceTrips
        }

        OverridePlanningVehicleDeliveredNumberOfTurns(requestArgs, currentPrice.transportRequestId, currentPrice.transportFlowId, value);
    }

    const handleUndoOverridenDeliveredQuantityNegotiatedPrice = (index: number) => {
        const currentPrice = dataItem.negotiatedPurchasePrices[index];
        currentPrice.overridenDeliveredQuantity = null;
        props.handleUpdatingPlanningVehicle(props.dataItem.planningVehicleId);

        const requestArgs: OverridenDeliveredQuantityRequestArgs = {
            planningId: props.dataItem.planningId,
            planningVehicleId: props.dataItem.planningVehicleId,
            transportFlowId: currentPrice.transportFlowId,
            overridenDeliveredQuantity: null,
            specificOrNegotiatedPriceKind: currentPrice.priceKind,
            specificPrice: currentPrice.unitPrice,
            deliveryOverrideKind: PlanningVehicleDeliveryOverrideKind.negotiatedPriceTrips
        }

        OverridePlanningVehicleDeliveredQuantity(requestArgs, currentPrice.transportRequestId, currentPrice.transportFlowId, null);
    }

    const handleUndoOverridenDeliveredNumberOfTurnsNegotiatedPrice = (index: number) => {
        const currentPrice = dataItem.negotiatedPurchasePrices[index];
        currentPrice.overridenDeliveredNumberOfTurns = null;
        props.handleUpdatingPlanningVehicle(props.dataItem.planningVehicleId);

        const requestArgs: OverridenDeliveredNumberOfTurnsRequestArgs = {
            planningId: props.dataItem.planningId,
            planningVehicleId: props.dataItem.planningVehicleId,
            transportFlowId: currentPrice.transportFlowId,
            overridenDeliveredNumberOfTurns: null,
            specificOrNegotiatedPriceKind: currentPrice.priceKind,
            specificPrice: currentPrice.unitPrice,
            deliveryOverrideKind: PlanningVehicleDeliveryOverrideKind.negotiatedPriceTrips
        }

        OverridePlanningVehicleDeliveredNumberOfTurns(requestArgs, currentPrice.transportRequestId, currentPrice.transportFlowId, null);
    }

    const OverridePlanningVehicleDeliveredQuantity = (requestArgs: OverridenDeliveredQuantityRequestArgs, transportRequestId: string, transportFlowId: string, value: number) => {
        TransportPurchasesApiClient.OverridePlanningVehicleDeliveredQuantity(requestArgs)
            .then(res => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    props.displayErrorsMessages(errors);
                    props.refreshPlanningVehicles();
                    return;
                }
                props.refreshPlanningVehicleWithDetails(dataItem.planningVehicleId);
            });
    }

    const OverridePlanningVehicleDeliveredNumberOfTurns = (requestArgs: OverridenDeliveredNumberOfTurnsRequestArgs, transportRequestId: string, transportFlowId: string, value: number) => {
        TransportPurchasesApiClient.OverridePlanningVehicleDeliveredNumberOfTurns(requestArgs)
            .then(res => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    props.displayErrorsMessages(errors);
                    props.refreshPlanningVehicles();
                    return;
                }
                props.refreshPlanningVehicleWithDetails(dataItem.planningVehicleId);
            });
    }
    
    return (
        <section>
            <Box display='flex' flexDirection='row' flexWrap="wrap">
                <Box width='9%' pb={0.5} pr={2}>
                    <Card className="card-section card-forfait-bg">
                        <CardContent className="card-body">
                            <Box display='flex' flexDirection='row'>
                                <Box width='80%' className="line-border" onClick={handlePriceType}>
                                    <Box display='flex' flexDirection='column'>
                                        <Box className='tarif-forfait'>
                                            Tarif Principal
                                        </Box>
                                        <Box display='flex' flexDirection='row'>
                                            <Box className="type-forfait">
                                                {(!editSelectData || dataItem.updating) ? (<span>
                                                    {dataItem.mainPriceKind === VehiclePriceKind.PerTon ? "Tonne"
                                                        : (dataItem.mainPriceKind === VehiclePriceKind.PerHour ? "Heure"
                                                            : (dataItem.mainPriceKind === VehiclePriceKind.Day ? "Jour"
                                                                : (dataItem.mainPriceKind === VehiclePriceKind.HalfDay ? "Demi-Journée" 
                                                                    : (dataItem.mainPriceKind === VehiclePriceKind.PerTurn ? "Tour" : "Nuit"))))}
                                                </span>)
                                                : ((dataItem.transporterId != null && !dataItem.relatedDataIsLoaded) ?(<Select style={{ width: '100%', fontSize: '12px' }}
                                                            value=''>
                                                        </Select>)
                                                    :   (!availablePurchasePrices || availablePurchasePrices.length === 0)
                                                        ? <Select style={{ width: "110px" }}
                                                            onChange={(event) => handleInternalVehicleSelectChange(props.dataItem, event.target.value)}
                                                            value={selectedValueData}
                                                            className="type-forfait">
                                                            <MenuItem style={{ fontSize: '12px' }} value={0}>Jour</MenuItem>
                                                            <MenuItem style={{ fontSize: '12px' }} value={2}>Nuit</MenuItem>
                                                            <MenuItem style={{ fontSize: '12px' }} value={1}>Demi-journée</MenuItem>
                                                            <MenuItem style={{ fontSize: '12px' }} value={3}>Tonne</MenuItem>
                                                            <MenuItem style={{ fontSize: '12px' }} value={4}>Heure</MenuItem>
                                                        </Select>
                                                        : <Select style={{ width: "110px" }}
                                                            onChange={(event) => handleTransporterVehicleSelectChange(props.dataItem, event.target.value)}
                                                            value={selectedValueData}
                                                            className="type-forfait"
                                                        >
                                                            {availablePurchasePrices.map((elem: AvailablePurchasePriceLightModel, index) => <MenuItem key={index} value={elem.priceKind + ':' + elem.price} title={elem.description}>{props.getPriceKind(elem.priceKind)} : {elem.price}€</MenuItem>)}
                                                        </Select>)
                                                }

                                            </Box>
                                            {(!editSelectData || dataItem.updating) && <Box className="type-forfait" pl={1}>
                                                {unitPriceData + ' €'}
                                            </Box>}
                                        </Box>
                                    </Box>
                                    {
                                        dataItem.mainPriceKind === VehiclePriceKind.PerTon &&
                                        <Box className="cost-quantity-value" width="100%">
                                            {dataItem.quantityMainPrice}
                                        </Box>
                                    }
                                    {
                                        dataItem.mainPriceKind === VehiclePriceKind.PerHour &&
                                        <Box className="cost-quantity-value" width="100%">
                                            {fromHours(dataItem.quantityMainPrice)}
                                        </Box>
                                    }
                                    {
                                        dataItem.mainPriceKind === VehiclePriceKind.PerTurn &&
                                        <Box className="cost-quantity-value" width="100%">
                                            {dataItem.quantityMainPrice}
                                        </Box>
                                    }
                                </Box>
                                <Box width='30%' pl={2}>
                                    <Box display='flex' flexDirection='column'>
                                        <Box className='cost'>
                                            Coût
                                        </Box>
                                        <Box>
                                            {(!editInputData || dataItem.updating) ? 
                                                (<Box className="cost-value" onClick={handleInputPrice}>
                                                    {(props.dataItem.totalMainPrice ? props.dataItem.totalMainPrice : 0) + ' €'}
                                                </Box>)
                                            : (<Box display='flex' flexDirection='column'>
                                            <NumericTextBox
                                                defaultValue={unitPriceData}
                                                min={0}
                                                width={110}
                                                format={{
                                                    maximumFractionDigits: 2,
                                                    currencyDisplay: 'symbol',
                                                    currency: 'EUR',
                                                    style: 'currency'
                                                }}
                                                onChange={(event) => handleChange(props.dataItem, event)}
                                                onBlur={handleBlurCost}
                                                 />
                                            </Box>)}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                {
                    dataItem.specificPrices.map((elem: SpecificDeliveryTripsGroupLightModel, index: number) => 
                        <Box pb={0.5} pr={2} key={`specific-price-${index}`}>
                            <DetailSpecificPriceCardComponent item={elem}
                                updating={dataItem.updating}
                                handleDeliveredQuantityChanged={(value: number) => handleDeliveredQuantitySpecificPriceChanged(index, value) }
                                handleUndoOverridenDeliveredQuantity={() => handleUndoOverridenDeliveredQuantitySpecificPrice(index)} />
                        </Box>
                    )
                }
                {
                    dataItem.negotiatedPurchasePrices.map((elem: NegotiatedDeliveryTripsGroupLightModel, index: number) => 
                        <Box pb={0.5} pr={2} key={`negotiated-price-${index}`}>
                            <DetailNegotiatedPriceCardComponent 
                                item={elem}
                                updating={dataItem.updating}
                                handleDeliveredQuantityChanged={(value: number) => handleDeliveredQuantityNegotiatedPriceChanged(index, value) }
                                handleUndoOverridenDeliveredQuantity={() => handleUndoOverridenDeliveredQuantityNegotiatedPrice(index)}
                                handleDeliveredNumberOfTurnsChanged={(value: number) => handleDeliveredNumberOfTurnsNegotiatedPriceChanged(index, value)}
                                handleUndoOverridenDeliveredNumberOfTurns={() => handleUndoOverridenDeliveredNumberOfTurnsNegotiatedPrice(index)} />
                        </Box>
                    )
                }
            </Box>
        </section >
    );
}