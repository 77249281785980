import axios, { AxiosResponse } from 'axios';
import { PagedResult } from 'src/shared/models/PagedResult';
import { WebAppActionResult } from 'src/shared/models/WebAppActionResult';
import { TransportFlowInfoRequestArgs } from './dataContracts/controller/TransportFlowInfoRequestArgs';
import { BeneficiaryLightModel } from './dataContracts/queryStack/BeneficiaryLightModel';
import { JobSiteDeliveriesLightModel } from './dataContracts/queryStack/JobSiteDeliveriesLightModel';

const controllerUrl = 'api/ThirdPartyBeneficiary/';

export class ThirdPartyBeneficiaryApiClient {

    public static SearchBeneficiariesWithOrders = (pageLength: number, pageNumber: number, beneficiaryId: string, date: Date, logisticsUnits: Array<string>)
        : Promise<[AxiosResponse<Array<BeneficiaryLightModel>>, AxiosResponse<PagedResult<JobSiteDeliveriesLightModel>>]> => {
        return Promise.all([
            ThirdPartyBeneficiaryApiClient.GetBeneficiaries(date, logisticsUnits),
            ThirdPartyBeneficiaryApiClient.Search(pageLength, pageNumber, beneficiaryId, date, null)
        ]);
    }

    public static Search = (pageLength: number, pageNumber: number, beneficiary: string, planningDate: Date, token: string)
        : Promise<AxiosResponse<PagedResult<JobSiteDeliveriesLightModel>>> => {
        let url = "";
        if (token === null) {
            url = `${controllerUrl}Search?beneficiary=` + encodeURIComponent(beneficiary) + "&pageIndex=" + pageNumber + "&pageSize=" + pageLength + "&planningDate=" + encodeURIComponent(planningDate.toJSON());
        } else {
            url = `${controllerUrl}SearchByToken?token=` + token + "&pageIndex=" + pageNumber + "&pageSize=" + pageLength;
        }
        return axios.get(url);
    }

    public static GetBeneficiaries = (date: Date, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<Array<BeneficiaryLightModel>>> => {
        const data = {
            fromTime: date,
            logisticsUnits: logisticsUnits
        };

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Pragma: 'no-cache'
        };

        return axios.post(`${controllerUrl}GetBeneficiaries`, data, { headers: headers });
    }

    public static UpdateTransportRequestStatus = (requestArgs: TransportFlowInfoRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const headers = {
            'Accept': 'application / json',
            'Content-Type': 'application/json',
            Pragma: 'no-cache'
        };
        return axios.post(`${controllerUrl}ChangeFinishedStatus`, requestArgs, { headers: headers });
    }
}