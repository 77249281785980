import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { NeededVehiclesWeeklySummaryLightModel } from '../services/dataContracts/queryStack/NeededVehiclesWeeklySummaryLightModel';
import { WeekItemComponent } from './WeekItemComponent';

interface ProjectedNeedTabsComponentProps {
    firstDayInSelectedWeek: Date,
    onWeekChange: (event: React.ChangeEvent<{}>, newSelectedValue: Date) => void,
    neededVehiclesWeeklySummaryArray: Array<NeededVehiclesWeeklySummaryLightModel>
}

export const ProjectedNeedTabsComponent = (props: ProjectedNeedTabsComponentProps): JSX.Element => {

    const { neededVehiclesWeeklySummaryArray, firstDayInSelectedWeek, onWeekChange } = props;

    let tabsValues = new Set<string>();

    const tabs: JSX.Element[] = neededVehiclesWeeklySummaryArray.map((neededVehiclesWeeklySummary: NeededVehiclesWeeklySummaryLightModel, index: number) => {
        const checkSelectedWeek: boolean = Date.equals(neededVehiclesWeeklySummary.firstDayInWeek, firstDayInSelectedWeek);

        let tabValue = new Date(neededVehiclesWeeklySummary.firstDayInWeek).toLocaleDateString();
        tabsValues.add(tabValue);

        return (
            <Tab key={index} value={tabValue}
                className={checkSelectedWeek ? 'projected-need-element selected' : 'projected-need-element'}
                icon={<WeekItemComponent data={neededVehiclesWeeklySummary} />} />
        )
    });

    let firstDayInSelectedWeekTabValue = new Date(firstDayInSelectedWeek).toLocaleDateString();
    let defaultValue = tabsValues.has(firstDayInSelectedWeekTabValue) ? firstDayInSelectedWeekTabValue : null;

    return (
        <Tabs
            className="projected-need-tabs"
            value={defaultValue}
            onChange={onWeekChange}
            variant="scrollable"
            scrollButtons="auto"
        >
            {tabs}
        </Tabs>
    );
}