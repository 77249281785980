import { Button } from '@mui/material';
import { load, loadMessages } from '@progress/kendo-react-intl';
import React from 'react';
import { ScaleLoader } from 'react-spinners';
import { CalendarComponent } from '../../../shared/components/Common/CalendarComponent';
import { DateRange } from '../../../shared/models/DateRange';
import '../../../utils/Date';
import { downloadFileFromBlob, getFileName } from '../../../utils/DownloadFile';
import { SessionStorage } from '../../../utils/Storage';
import { TransportPlanExportRequestArgsExtended } from './models/TransportPlanExportRequestArgsExtended';
import { TransportPlanExportApiClient } from './services/TransportPlanExportApiClient';

const weekData = require('cldr-core/supplemental/weekData.json');
const caGregorian = require('cldr-dates-full/main/fr/ca-gregorian.json');
const dateFields = require('cldr-dates-full/main/fr/dateFields.json');
const timeZoneNames = require('cldr-dates-full/main/fr/timeZoneNames.json');
const esMessages = require('../../../config/fr.json');

loadMessages(esMessages, 'fr-FR');
load(
    weekData,
    caGregorian,
    dateFields,
    timeZoneNames
);

interface IChildComponentProps {
    logisticsUnitIds: Array<string>,
    onClose: () => void
}

interface TransportPlanExportState {
    date: DateRange,
    isLoading: boolean,
    isValid: boolean
}

class TransportPlanExportForm extends React.Component<IChildComponentProps, TransportPlanExportState> {
    today: Date = new Date();
    _isMounted: boolean;
    constructor(props: IChildComponentProps) {
        super(props);
        const start: Date = SessionStorage.ActiveStartDate;
        const end: Date = SessionStorage.ActiveEndDate;
        this.state = {
            date: {
                start: start,
                end: end
            },
            isLoading: false,
            isValid: true
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChangeDateRange = (event: DateRange): void => {
        if (event.end != null && event.start != null) {
            const fromTime: Date = event.start.getDayStart();
            const toTime: Date = event.end.getDayEnd();
            this.setState({ date: { start: fromTime, end: toTime } });
        }
    }

    export = (): void => {
        this.setState({
            isLoading: true
        });
        const { date } = this.state;
        const { onClose } = this.props;

        const transportPlanExportRequestArgs: TransportPlanExportRequestArgsExtended = {
            fromTime: new Date(date.start).toJsonTimezoned(),
            untilTime: new Date(date.end).toJsonTimezoned(),
            logisticsUnitIds: this.props.logisticsUnitIds
        };

        TransportPlanExportApiClient.Export(transportPlanExportRequestArgs)
            .then(response => {
                const fileName: string = getFileName(response);
                const blob: Blob = new Blob([response.data]);
                downloadFileFromBlob(blob, fileName);
                onClose();
                if (this._isMounted) {
                    this.setState({
                        isLoading: false
                    });
                }
            });
    }

    handleIsValidChanged = (isValid: boolean): void => {
        this.setState({isValid: isValid});
    }

    render() {
        const { date, isLoading } = this.state;
        const { onClose } = this.props;
        return (
            <div>
                <div className='sweet-loading main-spinner'>
                    <ScaleLoader
                        width={5}
                        height={20}
                        radius={50}
                        color={'#000000'}
                        loading={isLoading}
                    />
                </div>
                <div className="row">
                    <div className="col-12" style={{ 'marginBottom': '20px' }}>
                        <div className="example-wrapper" >
                            <CalendarComponent handlerFromChildToParent={this.handleChangeDateRange}
                                startDate={date.start}
                                endDate={date.end}
                                maximumNumberOfMonths={2}
                                calendarId="transportPlan_export-daterange-picker"
                                isValid={this.state.isValid}
                                handleIsValidChanged={this.handleIsValidChanged}
                            />
                        </div>
                    </div>
                    <div className="col-12 flex-content flex-row flex-wrap justify-content-end">
                        <Button variant="contained" color="secondary" style={{ 'margin': '5px' }} onClick={onClose}>
                            Annuler
                        </Button>
                        <Button variant="contained" color="primary" style={{ 'margin': '5px' }} onClick={() => this.export()}>
                            Exporter
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default TransportPlanExportForm;
