import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult } from 'src/shared/models/WebAppActionResult';
import { AddCustomerRequestArgs } from './dataContracts/controller/AddCustomerRequestArgs';
import { BeneficiaryRequesterChoicesExportRequestArgs } from './dataContracts/controller/BeneficiaryRequesterChoicesExportRequestArgs';
import { CustomerCandidateToAdd } from './dataContracts/controller/CustomerCandidateToAdd';
import { CustomerChoiceLogisticsUnitRequestArgs } from './dataContracts/controller/CustomerChoiceLogisticsUnitRequestArgs';
import { CustomerChoiceLogisticsUnitsRequestArgs } from './dataContracts/controller/CustomerChoiceLogisticsUnitsRequestArgs';
import { CustomerChoicesExportRequestArgs } from './dataContracts/controller/CustomerChoicesExportRequestArgs';
import { CustomerContactChoiceLogisticsUnitRequestArgs } from './dataContracts/controller/CustomerContactChoiceLogisticsUnitRequestArgs';
import { CustomerContactChoiceLogisticsUnitsRequestArgs } from './dataContracts/controller/CustomerContactChoiceLogisticsUnitsRequestArgs';
import { UpdateCustomerRequestArgs } from './dataContracts/controller/UpdateCustomerRequestArgs';
import { ContactCustomerLightModel } from './dataContracts/queryStack/ContactCustomerLightModel';
import { CustomerLightModel } from './dataContracts/queryStack/CustomerLightModel';
import { LogisticsUnitChoiceOfContactsLightModel } from './dataContracts/queryStack/LogisticsUnitChoiceOfContactsLightModel';
import { LogisticsUnitChoiceOfCustomersLightModel } from './dataContracts/queryStack/LogisticsUnitChoiceOfCustomersLightModel';

const controllerUrl = 'api/CustomerContactsReferential/';

export class CustomerContactsReferentialApiClient {
    public static GetCustomers = (searchText: string, isGlobalSearch: boolean)
        : Promise<AxiosResponse<Array<CustomerLightModel>>> => {
        return axios.get(`${controllerUrl}GetCustomers?searchText=${encodeURIComponent(searchText)}&isGlobalSearch=${isGlobalSearch}`);
    }

    public static GetCustomerChoicesLogisticsUnit = ()
        : Promise<AxiosResponse<Array<LogisticsUnitChoiceOfCustomersLightModel>>> => {
        return axios.get(`${controllerUrl}GetCustomerChoicesLogisticsUnit`);
    }

    public static GetCustomersWithCustomerChoicesLogisticsUnit = (searchText: string, isGlobalSearch: boolean)
        : Promise<[AxiosResponse<Array<CustomerLightModel>>, AxiosResponse<Array<LogisticsUnitChoiceOfCustomersLightModel>>]> => {
        return Promise.all(
            [
                CustomerContactsReferentialApiClient.GetCustomers(searchText, isGlobalSearch),
                CustomerContactsReferentialApiClient.GetCustomerChoicesLogisticsUnit()
            ]);
    }

    public static AddOrRemoveCustomerChoice = (customerChoiceLogisticsUnit: CustomerChoiceLogisticsUnitRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddOrRemoveCustomerChoice',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(customerChoiceLogisticsUnit)
        });
    }

    public static SetOrUnsetCustomerChoiceToAllTransportLogisticsUnits = (customerChoiceLogisticsUnits: CustomerChoiceLogisticsUnitsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'SetOrUnsetCustomerChoiceToAllLogisticsUnits',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(customerChoiceLogisticsUnits)
        });
    }

    public static GetContactsByCustomerWithContactChoicesLogisticsUnit = (customerMdmId: string)
        : Promise<[AxiosResponse<Array<ContactCustomerLightModel>>, AxiosResponse<Array<LogisticsUnitChoiceOfContactsLightModel>>]> => {
        return Promise.all(
            [
                CustomerContactsReferentialApiClient.GetContactsByCustomer(customerMdmId),
                CustomerContactsReferentialApiClient.GetContactsChoicesLogisticsUnits()
            ]);
    }

    public static GetContactsByCustomer = (customerMdmId: string)
        : Promise<AxiosResponse<Array<ContactCustomerLightModel>>> => {
        return axios.get(`${controllerUrl}GetContactsByCustomer?customerMdmId=${encodeURIComponent(customerMdmId)}`);
    }

    public static GetContactsChoicesLogisticsUnits = ()
        : Promise<AxiosResponse<Array<LogisticsUnitChoiceOfContactsLightModel>>> => {
        return axios.get(`${controllerUrl}GetContactsChoicesLogisticsUnits`);
    }

    public static AddOrRemoveContactChoice = (contactChoiceLogisticsUnit: CustomerContactChoiceLogisticsUnitRequestArgs, isBeneficiary: boolean)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const _action = isBeneficiary ? 'AddOrRemoveBeneficiaryChoice' : 'AddOrRemoveRequesterChoice'
        return axios({
            method: 'POST',
            url: controllerUrl + _action,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(contactChoiceLogisticsUnit)
        });
    }

    public static SetOrUnsetContactChoiceToAllLogisticsUnits = (contactChoiceTransportLogisticsUnits: CustomerContactChoiceLogisticsUnitsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'SetOrUnsetContactChoiceToAllLogisticsUnits',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(contactChoiceTransportLogisticsUnits)
        });
    }

    public static ExportCustomerBeneficiaryRequesterChoices = (customerChoicesExportRequestArgs: CustomerChoicesExportRequestArgs, beneficiaryRequesterChoicesExportRequestArgs: BeneficiaryRequesterChoicesExportRequestArgs)
        : Promise<[AxiosResponse<Blob>, AxiosResponse<Blob>]> => {
        return Promise.all(
            [
                CustomerContactsReferentialApiClient.ExportCustomersChoices(customerChoicesExportRequestArgs),
                CustomerContactsReferentialApiClient.ExportBeneficiaryRequesterByCustomerChoices(beneficiaryRequesterChoicesExportRequestArgs)
            ]);
    }

    public static ExportCustomersChoices = (customerChoicesExportRequestArgs: CustomerChoicesExportRequestArgs)
        : Promise<AxiosResponse<Blob>> => {
        const _headers ={
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return axios.post(controllerUrl + 'ExportCustomersChoices', customerChoicesExportRequestArgs, { headers: _headers, responseType: 'blob' });
    }

    public static ExportBeneficiaryRequesterByCustomerChoices = (beneficiaryRequesterChoicesExportRequestArgs: BeneficiaryRequesterChoicesExportRequestArgs)
        : Promise<AxiosResponse<Blob>> => {
        const _headers ={
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return axios.post(controllerUrl + 'ExportBeneficiariesRequestersByCustomerChoices', beneficiaryRequesterChoicesExportRequestArgs, { headers: _headers, responseType: 'blob' });
    }

    public static GetImportUrl = (): string => {
        return controllerUrl + 'ImportFile';
    }

    public static SearchCustomerByMdmId = (mdmId: string)
        : Promise<AxiosResponse<CustomerCandidateToAdd>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SearchCustomerByMdmId?mdmId=${mdmId}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static SearchCustomerByName = (name: string)
        : Promise<AxiosResponse<CustomerCandidateToAdd[]>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SearchCustomerByName?name=${name}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static CustomerExists = (mdmId: string)
        : Promise<AxiosResponse<boolean>> => {
        return axios.get(`${controllerUrl}CustomerExists?mdmId=${mdmId}`);
    }

    public static AddCustomer = (requestArgs: AddCustomerRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddCustomer',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateCustomer = (requestArgs: UpdateCustomerRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateCustomer`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }
}
