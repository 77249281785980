import { Box } from '@mui/material';
import React from 'react';
import { VehicleItemModel } from '../models/VehicleItemModel';
import { ReservedTransporterVehicleTypeLightModel } from '../services/dataContracts/queryStack/ReservedTransporterVehicleTypeLightModel';
import { TransportPurchasePriceDatesLightModel } from '../services/dataContracts/queryStack/TransportPurchasePriceDatesLightModel';

interface TransporterVehicleItemComponentProps {
    style: React.CSSProperties,
    dataItem: VehicleItemModel,
    handleAssignTransporterVehicleType?: (vehicleTypeId: string, transporterId: string, transportPurchasePriceDatesLightModel: TransportPurchasePriceDatesLightModel[], vehicleTypeGroupId: string) => void,
    confirmedReservations: Map<string, Map<string, ReservedTransporterVehicleTypeLightModel>>
}

export const TransporterVehicleItemComponent = (props: TransporterVehicleItemComponentProps): JSX.Element => {

    const { dataItem, style } = props;

    const transporterConfirmedReservations: ReservedTransporterVehicleTypeLightModel = props.confirmedReservations.get(dataItem.transporterId)?.get(dataItem.vehicleTypeId);
    const hasReservedVehicles = transporterConfirmedReservations !== undefined && Number(transporterConfirmedReservations.reservedNumberOfVehicles) > 0;

    return (
        <Box style={style} display="flex" flexDirection="row" className="vehicle-card" onDoubleClick={() => props.handleAssignTransporterVehicleType(dataItem.vehicleTypeId, dataItem.transporterId, dataItem.transportPurchasePriceDatesLightModel, dataItem.vehicleTypeGroupId)}>
            <Box className={`header-vehicle ${props.dataItem.isInternalTransporter ? 'header-internal' : 'header-transporter'}`}>
            </Box>
            <Box className={hasReservedVehicles ? "hover-vehicle with-reserved-number" : "hover-vehicle"}>
                <span className={`vehicle-title${!dataItem.vehicleTypeIsEnabled ? " vehicle-disabled" : ""}`}>
                    {dataItem.vehicleTypeLabel}
                </span>
                <div className="vehicle-libelle">
                    {dataItem.transporterLabel}
                </div>
            </Box>
            {hasReservedVehicles &&
                <Box className="hover-vehicle reserved-number">
                    {transporterConfirmedReservations.reservedNumberOfVehicles}
                </Box>
            }
        </Box>
    )
}
