import CloseIcon from '@mui/icons-material/Close';
import { Paper, PaperProps } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import Draggable from 'react-draggable';

interface IChildComponentProps {
    isOpen: boolean;
    closeOnClickOutside?: boolean;
    draggable?: boolean;
    onClose: () => void;
    classNameVal: string;
    dialogTitle?: string;
    component: JSX.Element;
    closeIcon?: boolean;
    dialogTitleComponent?: JSX.Element;
    headerBorder?: boolean;
}

const PaperComponent = (props: PaperProps) => {
    return (<Draggable handle="#simple-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} bounds="parent">
            <Paper {...props} />
        </Draggable>);
}

class SimpleDialog extends React.Component<IChildComponentProps, {}> {

    handleClose = (_event, reason: string) =>{
        if (this.props.closeOnClickOutside || reason !== "backdropClick") {
            this.props.onClose();
        }
    }

    render() {
        const { onClose, isOpen, dialogTitle, component, classNameVal, closeIcon, dialogTitleComponent, headerBorder } = this.props;
        return (
            <Dialog
                disableEscapeKeyDown
                onClose={this.handleClose}
                aria-labelledby="simple-dialog-title"
                open={isOpen}
                PaperComponent={this.props.draggable ? PaperComponent : undefined}
                disableEnforceFocus={true}
                className={classNameVal}>
                <DialogTitle id="simple-dialog-title" className={headerBorder ? 'dialog-header-border' : ''}>
                    {dialogTitle ? <Typography className="simple-dialog-title">{dialogTitle}</Typography> : null}
                    {dialogTitleComponent ? dialogTitleComponent : null}
                    {closeIcon ? (
                        <IconButton aria-label="close" onClick={onClose} className="dialog-close-button">
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </DialogTitle>
                <div className="simple-dialog-content">
                    {component}
                </div>
            </Dialog>
        );
    }
}

export default SimpleDialog;
