import { Box, Button, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useState } from 'react';
import { CancellationReason } from '../services/dataContracts/queryStack/CancellationReason';

interface CancelPlanningVehicleDialogComponentProps {
    handleCloseCancelPlanningVehicleDialog: () => void,
    handleSaveCancelPlanningVehicleDialog: (cancellationReason: CancellationReason, cancellationRemarks: string, purchaseIsCanceled: boolean, saleIsCanceled: boolean) => void
}

export const CancelPlanningVehicleDialogComponent = (props: CancelPlanningVehicleDialogComponentProps): JSX.Element => {

    const [cancellationReason, setCancellationReason] = useState<CancellationReason>(null);
    const [purchaseIsCanceled, setPurchaseIsCanceled] = useState<boolean>(false);
    const [saleIsCanceled, setSaleIsCanceled] = useState<boolean>(false);

    const txtCancellationRemarks: React.RefObject<HTMLTextAreaElement> = React.useRef(null);

    const handleSaveCancelPlanningVehicleDialog = (): void => {
        props.handleSaveCancelPlanningVehicleDialog(cancellationReason, txtCancellationRemarks?.current?.value ?? null, purchaseIsCanceled, saleIsCanceled)
    }

    const handleChangeCancellationReason = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value as CancellationReason;
        setCancellationReason(value);
        if (value === CancellationReason.badWeather) {
            setPurchaseIsCanceled(true);
            setSaleIsCanceled(true);
        }
    }

    return (
        <Box display="flex" flexDirection="column" className="cancel-planning-vehicle-content">
            <FormControl>
                <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(e) => handleChangeCancellationReason(e)}
                >
                    <FormControlLabel value={CancellationReason.badWeather} control={<Radio />} label="Intempéries" />
                    <FormControlLabel value={CancellationReason.customerCancellation} control={<Radio />} label="Annulation client" />
                    <FormControlLabel value={CancellationReason.supplierCancellation} control={<Radio />} label="Annulation fournisseur du client" />
                    <FormControlLabel value={CancellationReason.transporterCancellation} control={<Radio />} label="Annulation transporteur" />
                    <FormControlLabel value={CancellationReason.other} control={<Radio />} label="Autre" />
                </RadioGroup>
            </FormControl>
            <Box display="flex" flexDirection="row" mt="15px">
                <Box>Commentaire :</Box>
                <TextField
                    className="cancel-remarks"
                    margin="normal"
                    multiline
                    variant="outlined"
                    rows="4"
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputRef={txtCancellationRemarks}
                />
            </Box>
            <Box mt="15px">Annuler (pour ce camion) :</Box>
            <Box display="flex" flexDirection="row">
                <FormControlLabel control={<Checkbox checked={purchaseIsCanceled} onChange={(e) => setPurchaseIsCanceled(e.target.checked)} />} label="Les Achats" />
                <FormControlLabel control={<Checkbox checked={saleIsCanceled} onChange={(e) => setSaleIsCanceled(e.target.checked)} />} label="Les Ventes" />
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Button variant="contained" color="secondary" title="Annuler" onClick={() => props.handleCloseCancelPlanningVehicleDialog()}>
                    Annuler
                </Button>
                <Button variant="contained" color="primary" title="Sauvegarder" className="btn-save" disabled={cancellationReason === null} onClick={() => handleSaveCancelPlanningVehicleDialog()}>
                    Sauvegarder
                </Button>
            </Box>
        </Box>
    );
}