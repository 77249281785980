import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { UploadOnAddEvent, UploadOnBeforeUploadEvent, UploadOnStatusChangeEvent, UploadResponse } from '@progress/kendo-react-upload';
import React from 'react';
import { ScaleLoader } from 'react-spinners';
import { UploadComponent } from 'src/shared/components/Common/UploadComponent';
import { SettingsProvider } from '../../../SettingsProvider';
import { AttachmentsApiClient } from '../../../shared/Attachments/services/AttachmentsApiClient';
import { WebAppActionResult } from '../../../shared/models/WebAppActionResult';
import BusinessErrors from '../../../utils/BusinessErrors';
import { downloadFileFromBlob, getFileName } from '../../../utils/DownloadFile';
import { TransportFlowAttachmentFileLightModel } from '../services/dataContracts/queryStack/TransportFlowAttachmentFileLightModel';
import { TransportPlanApiClient } from '../services/TransportPlanApiClient';

interface FlowAttachmentComponentProps {
    transportRequestId: string,
    transportFlowId: string,
    attachmentFiles: Array<TransportFlowAttachmentFileLightModel>,
    handleDeleteAttachment: (attachmentId: string) => void,
    handlerAfterUpload: () => void,
    handlerBeforeUpload: (event: UploadOnBeforeUploadEvent) => void,
    isLoadingAttachments: boolean,
    handleAdd: (event: UploadOnAddEvent) => void,
    handlerDisplayErrorsAfterUpload: (errors: string[]) => void
}

export const FlowAttachmentComponent = (props: FlowAttachmentComponentProps): JSX.Element => {

    const onUploadStatusChange = (event: UploadOnStatusChangeEvent): void => {
        const res: UploadResponse = event.response;

        if (res?.status == 200) {
            const data: WebAppActionResult = res?.response;
            const errors: string[] = BusinessErrors.Get(data);
            if (errors.length > 0) {
                props.handlerDisplayErrorsAfterUpload(errors);
                return;
            }
            props.handlerAfterUpload();
        }
    }

    const getAttachment = (attachmentId: string): void => {
        AttachmentsApiClient.GetAttachment(attachmentId)
            .then(response => {
                const fileName: string = getFileName(response);
                const blob: Blob = response.data;
                downloadFileFromBlob(blob, fileName);
            });
    }

    const config = SettingsProvider.Get();
    return (
        <Box display="flex" flexDirection="column" className="upload-attachment">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr">
                    <UploadComponent
                        batch={true}
                        multiple={true}
                        showFileList={false}
                        onStatusChange={onUploadStatusChange}
                        onBeforeUpload={props.handlerBeforeUpload}
                        onAdd={props.handleAdd}
                        defaultFiles={[]}
                        withCredentials={false}
                        saveUrl={TransportPlanApiClient.GetFlowAttachmentsSaveUrl(props.transportRequestId, props.transportFlowId)}
                        restrictions={{
                            maxFileSize: config.maxAttachmentSize
                        }}
                    />
                </IntlProvider>
            </LocalizationProvider>
            {props.isLoadingAttachments &&
                <div className='sweet-loading spinnerClass'>
                    <ScaleLoader
                        width={5}
                        height={20}
                        radius={50}
                        color={'#000000'}
                        loading={props.isLoadingAttachments}
                    />
                </div>
            }
            {!props.isLoadingAttachments && props.attachmentFiles.map((file: TransportFlowAttachmentFileLightModel, index: number) => {
                return (
                    <Box key={index} display="flex" flexDirection="row" justifyContent="space-between">
                        <Box className="attachment-name" onClick={() => getAttachment(file.attachmentId)}>{file.name}</Box>
                        <Tooltip title="Supprimer" placement="bottom">
                            <Box style={{ cursor: 'pointer' }} onClick={() => props.handleDeleteAttachment(file.attachmentId)}>
                                <FontAwesomeIcon className="fa-delete-attachment-icon" size="sm" icon={faTimes} />
                            </Box>
                        </Tooltip>
                    </Box>
                )
            })}
        </Box>
    );
}