import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, IconButton } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { DispatcherAgencyLightModelExtended } from '../models/DispatcherAgencyLightModelExtended';
import { SizingUtilities } from './SizingUtilities';

interface DispatcherAgenciesComponentProps {
    selectedDispatcher: string,
    dispatcherAgenciesList: DispatcherAgencyLightModelExtended[],
    handleAgencySelectionChange: (checked: boolean, dataItem: DispatcherAgencyLightModelExtended) => void,
    handleAddDispatcherAgenciesDialogClick: () => void,
    handleRemoveDispatcherAgencies: () => void,
    handleDispatcherAgencyRowClick: (dataItem: DispatcherAgencyLightModelExtended) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const DispatcherAgenciesReferentialGridName = "dispatcherAgenciesReferential";
const ModuleKey = AppModule.DispatcherAgenciesReferential;

export const DispatcherAgenciesComponent = (props: DispatcherAgenciesComponentProps): JSX.Element => {

    const initialSort: SortDescriptor[] = [{ field: "agencyLabel", dir: "asc" }];

    const [skip, setSkip] = useState<number>(0);
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);

    const forceUpdate = useForceUpdate();

    const getDispatchersWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, DispatcherAgenciesReferentialGridName, fieldName, columnWidth);
    }

    const getDispatchersGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, DispatcherAgenciesReferentialGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            DispatcherAgenciesReferentialGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, DispatcherAgenciesReferentialGridName, event.columns);
        forceUpdate();
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, dataItem: DispatcherAgencyLightModelExtended): void => {
        props.handleAgencySelectionChange(e.target.checked, dataItem);
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const rowClick = (e: GridRowClickEvent): void => {
        props.handleDispatcherAgencyRowClick(e.dataItem);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.dispatcherAgenciesList.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: DispatcherAgencyLightModelExtended[] = orderBy(props.dispatcherAgenciesList, sort).slice(skip, skip + gridPageSize);

    const disabledBtnAdd: boolean = props.selectedDispatcher == null;
    const disableBtnTrash: boolean = props.dispatcherAgenciesList.every(x => x.selected == false);

    return (
        <Box display="flex" flexDirection="column" flex="wrap" width="100%" className="dispatcher-agencies-content">
            <Box display="flex" flexDirection="row" alignItems="center" className="dispatcher-agencies-header">
                <Box mr="10px"><b>Agence(s) associée(s) au dispatcheur</b></Box>
                <Box pr={3}>
                    <Box>
                        <IconButton size="small" aria-label="Add"
                            disabled={disabledBtnAdd} onClick={() => props.handleAddDispatcherAgenciesDialogClick()}>
                            <FontAwesomeIcon className={disabledBtnAdd ? "fa-plus-disabled" : "fa-plus"} icon={faPlus} />
                        </IconButton>
                    </Box>
                </Box>
                <Box pr={3}>
                    <Box>
                        <IconButton size="small" aria-label="Add"
                            disabled={disableBtnTrash} onClick={() => props.handleRemoveDispatcherAgencies()}>
                            <FontAwesomeIcon className={disableBtnTrash ? "fa-trash-disabled" : "fa-trash"} icon={faTrash} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" flex="wrap" className="dispatcher-agencies-referential-grid">
                <Grid
                    className="dispatcher-agencies-grid"
                    data={gridData}
                    selectedField="selected"
                    sortable
                    reorderable
                    resizable
                    sort={sort}
                    onColumnResize={onResizeHandler}
                    onColumnReorder={(e) => onReorderHandler(e)}
                    onSortChange={handleSortChange}
                    onRowClick={rowClick}
                    rowHeight={rowHeight}
                    scrollable="virtual"
                    skip={skip}
                    total={totalGrid}
                    pageSize={gridPageSize}
                    onPageChange={pageChange}
                    style={gridStyle}
                >
                    <GridColumn field="selected" orderIndex={getDispatchersGridOrderIndexColumn("selected", 0)} width={getDispatchersWidth("selected", 50)}
                        cell={(props: GridCellProps) => <td>
                            <Checkbox
                                checked={(props.dataItem as DispatcherAgencyLightModelExtended).selected}
                                onChange={(e) => handleChange(e, props.dataItem)}
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </td>}
                        headerCell={() => <></>}
                    />
                    <GridColumn field="agencyLabel" orderIndex={getDispatchersGridOrderIndexColumn("agencyLabel", 1)} width={getDispatchersWidth("agencyLabel", 250)} title="Nom agence Colas" />
                    <GridColumn field="agencyId" orderIndex={getDispatchersGridOrderIndexColumn("agencyId", 2)} width={getDispatchersWidth("agencyId", 120)} title="Id" />
                </Grid>
            </Box>
        </Box>
    );
}
