import { faPen, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton, Input, InputAdornment, Tooltip } from '@mui/material';
import React from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import { WebAppActionResult } from '../../../../shared/models/WebAppActionResult';
import BusinessErrors from '../../../../utils/BusinessErrors';
import { UpdatedTransporterVehiclesRequestArgs } from '../services/dataContracts/controller/UpdatedTransporterVehiclesRequestArgs';
import { VehicleLightModel } from '../services/dataContracts/queryStack/VehicleLightModel';
import { ThirdPartyTransporterApiClient } from '../services/ThirdPartyTransporterApiClient';

interface VehiclesManagementComponentState {
    licencePlatesList: Array<VehicleLightModel>,
    searchText: string
}

interface IChildComponentProps {
    TransporterId: string,
    updateListAciveLicencePlate: (transporterId: string) => void,
    displayError: (message: string) => void,
    updateVehicleDrawerList: boolean,
    setUpdateVehicleDrawerListToFalse: () => void,
    handleClickOpen: (dialogType: string, item?: VehicleLightModel) => void,
    _handleDrawerVehiclesClose: () => void
}

class VehiclesManagementComponent extends React.Component<IChildComponentProps, VehiclesManagementComponentState> {
    _isMounted: boolean;
    inputSearchVehiclesRef: React.RefObject<HTMLInputElement>;

    constructor(props: IChildComponentProps) {
        super(props);
        this.inputSearchVehiclesRef = React.createRef();
        this.state = {
            licencePlatesList: [],
            searchText: ''
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillReceiveProps(nextProps: IChildComponentProps) {
        if ((nextProps.TransporterId !== this.props.TransporterId && nextProps.TransporterId !== "-1") || nextProps.updateVehicleDrawerList) {
            this.props.setUpdateVehicleDrawerListToFalse();
            this.SearchLicencePlate(nextProps.TransporterId, this.state.searchText);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChangeVehicleEnabled = (vehicleId: number, isEnabled: boolean): void => {
        const updatedTransporterVehicles: UpdatedTransporterVehiclesRequestArgs = {
            transporterId: this.props.TransporterId,
            isEnabled: !isEnabled,
            vehicleIdsList: [vehicleId]
        }

        ThirdPartyTransporterApiClient.checkTransporterVehicle(updatedTransporterVehicles)
            .then(json => {
                if (this._isMounted) {
                    const data: WebAppActionResult = json?.data;
                    const errors: string[] = BusinessErrors.Get(data);
                    if (errors.length > 0) {
                        this.props.displayError("Les modifications ne sont pas pris en compte");
                        return;
                    }

                    const licencePlatesListArray: VehicleLightModel[] = this.state.licencePlatesList;
                    const vehicle: VehicleLightModel = licencePlatesListArray.find(d => d.id === vehicleId);
                    vehicle.isEnabled = !isEnabled;
                    this.setState({
                        licencePlatesList: licencePlatesListArray
                    });
                    this.props.updateListAciveLicencePlate(this.props.TransporterId);
                }
            });
    }

    clearSearchText = (): void => {
        this.inputSearchVehiclesRef.current.value = '';
        this.setState({
            searchText: ''
        });
        this.SearchLicencePlate(this.props.TransporterId, '');
    }

    SearchLicencePlate = (transporterId: string, searchText: string): void => {
        ThirdPartyTransporterApiClient.SearchLicencePlate(transporterId, false, true, searchText)
            .then(res => {
                if (this._isMounted) {
                    this.setState({ licencePlatesList: res.data });
                }
            });
    }

    handleSearchVehicleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        this.SearchLicencePlate(this.props.TransporterId, e.target.value);
        this.setState({
            searchText: e.target.value
        });
    }

    render() {
        const { licencePlatesList } = this.state;

        const searchTextVehiclesValue: string = this.inputSearchVehiclesRef.current === null || this.inputSearchVehiclesRef.current === undefined ? '' : this.inputSearchVehiclesRef.current.value;

        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{ 'padding': '15px' }}
            >
                <Box display="flex" pb={2} flexDirection="row" width="100%">
                    <Box className="drawer-title" width="80%">
                        Gestion des camions
                    </Box>
                    <Box display="flex" width="20%" flexDirection="row" justifyContent="flex-end">
                        <Box style={{ cursor: 'pointer' }} onClick={this.props._handleDrawerVehiclesClose}>
                            <FontAwesomeIcon size="lg" icon={faTimes} />
                        </Box>
                    </Box>
                </Box>
                <Box className="TitleSectionSelectVehiclesToShow">
                    <p>Sélectionner les camions à afficher dans la/les liste(s) déroulante(s) <b>Immatriculation</b> :</p>
                </Box>
                <Box pb={1}>
                    <Button variant="contained" color="primary" onClick={() => this.props.handleClickOpen('vehicles')}>
                        Ajouter un camion
                    </Button>
                </Box>
                <Box width="100%">
                    <Input disableUnderline className={searchTextVehiclesValue.length > 0 ? 'search-text-active' : ''} inputRef={this.inputSearchVehiclesRef}
                        startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => this.clearSearchText()} /></InputAdornment>}
                        id="input-search-vehicles" placeholder="Rechercher ..." onChange={(event) => this.handleSearchVehicleChange(event)} />
                </Box>
                <Box>
                    {licencePlatesList.map((vehicle, index) =>
                        <div key={index}>
                            <div className="switch-section">
                                <label className="switch">
                                    <input type="checkbox" checked={vehicle.isEnabled}
                                        onChange={() => this.handleChangeVehicleEnabled(vehicle.id, vehicle.isEnabled)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                                <Tooltip title="Modifier le camion" placement="bottom">
                                    <IconButton className="switch-label" size="small" onClick={() => this.props.handleClickOpen('vehicles', vehicle)}>
                                        <FontAwesomeIcon size="1x" icon={faPen} />
                                    </IconButton>
                                </Tooltip>
                                <span><b>{vehicle.licencePlate}</b></span><span style={{ whiteSpace: 'pre-wrap' }}>{' - ' + (vehicle.vehicleTypeLabel ? vehicle.vehicleTypeLabel : '') + ' - ' + (vehicle.loadCapacity ? vehicle.loadCapacity + ' Kg' : '')}</span>
                            </div>
                        </div>
                    )}
                </Box>
            </Box>
        );
    }
}

export default VehiclesManagementComponent;
