import axios, { AxiosResponse } from 'axios';
import { CommandResultLight, WebAppActionResult } from '../../../../shared/models/WebAppActionResult';
import { BeneficiaryRequesterChoiceLogisticsUnitsRequestArgs } from './dataContracts/controller/BeneficiaryRequesterChoiceLogisticsUnitsRequestArgs';
import { ContactChoiceLogisticsUnitRequestArgs } from './dataContracts/controller/ContactChoiceLogisticsUnitRequestArgs';
import { InternalContactChoicesExportRequestArgs } from './dataContracts/controller/InternalContactChoicesExportRequestArgs';
import { UpdateUserRequestArgs } from './dataContracts/controller/UpdateUserRequestArgs';
import { InternalContactLightModel } from './dataContracts/queryStack/InternalContactLightModel';
import { LogisticsUnitChoiceOfContactsLightModel } from './dataContracts/queryStack/LogisticsUnitChoiceOfContactsLightModel';
import { ProductionSiteLightModel } from "./dataContracts/queryStack/ProductionSiteLightModel";
import { UserLight } from "./dataContracts/queryStack/UserLight";

const controllerUrl = "api/InternalContactsReferential/";

export class InternalContactsReferentialApiClient {
    public static GetInternalContacts = (searchText: string, isGlobalSearch: boolean)
        : Promise<AxiosResponse<Array<InternalContactLightModel>>> => {
        return axios.get(`${controllerUrl}GetInternalContacts?searchText=${encodeURIComponent(searchText)}&isGlobalSearch=${isGlobalSearch}`);
    }

    public static GetInternalContactChoicesLogisticsUnit = ()
        : Promise<AxiosResponse<Array<LogisticsUnitChoiceOfContactsLightModel>>> => {
        return axios.get(`${controllerUrl}GetInternalContactChoicesLogisticsUnit`);
    }

    public static GetInternalBeneficiariesRequestersWithContactChoicesLogisticsUnit(searchText: string, isGlobalSearch: boolean)
        : Promise<[AxiosResponse<Array<InternalContactLightModel>>, AxiosResponse<Array<LogisticsUnitChoiceOfContactsLightModel>>]> {
        return Promise.all(
            [
                this.GetInternalContacts(searchText, isGlobalSearch),
                this.GetInternalContactChoicesLogisticsUnit()
            ]);
    }

    public static GetUser = (userName: string): Promise<AxiosResponse<UserLight>> => {
        return axios.get(`${controllerUrl}GetUser?userName=${encodeURIComponent(userName)}`);
    }

    public static GetManagedProductionSitesChoices = (userName: string)
        : Promise<AxiosResponse<Array<ProductionSiteLightModel>>> => {
        return axios.get(`${controllerUrl}GetManagedProductionSitesChoices?userName=${encodeURIComponent(userName)}`);
        }
    
    public static AddOrRemoveInternalContactChoice(requestArgs: ContactChoiceLogisticsUnitRequestArgs, isBeneficiary: boolean)
        : Promise<AxiosResponse<WebAppActionResult>> {
        const _action = isBeneficiary ? 'AddOrRemoveInternalBeneficiaryChoice' : 'AddOrRemoveInternalRequesterChoice'
        return axios({
            method: 'POST',
            url: controllerUrl + _action,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static SetOrUnsetInternalContactChoiceToAllLogisticsUnits(requestArgs: BeneficiaryRequesterChoiceLogisticsUnitsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> {
        return axios({
            method: 'POST',
            url: controllerUrl + 'SetOrUnsetInternalContactChoiceToAllLogisticsUnits',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static ExportInternalContactChoices(requestArgs: InternalContactChoicesExportRequestArgs)
        : Promise<AxiosResponse<Blob>> {
        const _headers ={
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return axios.post(controllerUrl + 'ExportInternalContactChoices', requestArgs, { headers: _headers, responseType: 'blob' });
    }

    public static GetImportUrl = (): string => {
        return controllerUrl + 'ImportFile';
    }

    public static EnsureUser = (userName: string, userRole: string)
        : Promise<AxiosResponse<string | WebAppActionResult | CommandResultLight>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}EnsureUser?userName=${encodeURIComponent(userName)}&userRole=${encodeURIComponent(userRole)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static UpdateUser = (requestArgs: UpdateUserRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateUser`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }
}
