import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';

interface ConfirmValidationDialogProps {
    open: boolean,
    handleClose: () => void,
    validate: () => void
}

export const ConfirmValidationDialogComponent = (props: ConfirmValidationDialogProps): JSX.Element => {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-describedby="alert-dialog-description"
            className="confirm-validation-dialog"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Le demandeur ou le bénéficiaire n'est pas connu de LORIE, être-vous sûr de vouloir valider ce Flux? Si non, veuillez le remplacer par un contact connu (ou contacter votre administrateur pour insérer un nouveau contact).
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    NON
                </Button>
                <Button onClick={props.validate} color="primary">
                    OUI
                </Button>
            </DialogActions>
        </Dialog>
    );
}
