import { faPen, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Card, CardContent, Tooltip } from '@mui/material';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { DieselIndexTemplateLightModelExtended } from '../models/DieselIndexTemplateLightModelExtended';
import { UpdateDieselIndexTemplateRequestArgs } from '../services/dataContracts/controller/UpdateDieselIndexTemplateRequestArgs';
import { DieselIndexTemplateLightModel } from '../services/dataContracts/queryStack/DieselIndexTemplateLightModel';

interface DieselIndexTemplateProperties {
    minStartDate: Date,
    maxStartDate: Date,
    dieselIndexTemplate: DieselIndexTemplateLightModelExtended,
    templateSelected: boolean,
    HandleRemoveDieselIndexTemplate: (templateId: string) => void,
    HandleUpdateDieselIndexTemplate: (template: UpdateDieselIndexTemplateRequestArgs) => void,
    HandleSelectedTemplate: (template: DieselIndexTemplateLightModel, selected: boolean) => void,
    HandleEditTemplate: (dieselIndexTemplate: DieselIndexTemplateLightModelExtended, editable: boolean) => void
}

export const DieselIndexTemplateComponent = (props: DieselIndexTemplateProperties) => {
    const [templateEditableData, setTemplateEditableData] = useState<UpdateDieselIndexTemplateRequestArgs>({
        dieselIndexTemplateId: props.dieselIndexTemplate.dieselIndexTemplateId,
        label: props.dieselIndexTemplate.label,
        referenceValue: props.dieselIndexTemplate.referenceValue,
        weightings: cloneDeep(props.dieselIndexTemplate.weightings)
    });
    const [showConfirmationRemoveTemplate, setShowConfirmationRemoveTemplate] = useState<boolean>(false);

    const GetTemplateWeighting = (): Array<JSX.Element> => {
        const content = [];
        const vehicleTypeGroupsWeightings = props.dieselIndexTemplate.isEditing ? templateEditableData.weightings : props.dieselIndexTemplate.weightings;
        const vehicleTypeGroupsWeightingsLength = vehicleTypeGroupsWeightings.length;

        let currentIndex = 0;
        let lineIndex = 0;

        while (currentIndex < vehicleTypeGroupsWeightingsLength) {
            const elemFirst = GetLabelElement(vehicleTypeGroupsWeightings[currentIndex].vehicleTypeGroupId, vehicleTypeGroupsWeightings[currentIndex].weightingValue, currentIndex);
            currentIndex++;
            const elemSecond = currentIndex == vehicleTypeGroupsWeightingsLength ? <></> : GetLabelElement(vehicleTypeGroupsWeightings[currentIndex].vehicleTypeGroupId, vehicleTypeGroupsWeightings[currentIndex].weightingValue, currentIndex);
            currentIndex++;
            lineIndex++;
            content.push(<Box display="flex" flexDirection="row" className="weighting-line" key={`line-card-${lineIndex}`} pb={1}>{elemFirst}{elemSecond}</Box>);
        }

        return content;
    }

    const GetLabelElement = (label: string, value: number, index: number): JSX.Element => {
        return (<Box width="50%" display="flex" flexDirection="row" alignItems="center" paddingLeft={`${index % 2 == 0 ? '0em' : '1em'}`}>
            <Box className="weighting-title">
                {label} : &nbsp;
            </Box>
            <Box className="template-weighting-label-value">
                {
                    props.dieselIndexTemplate.isEditing ?
                        <div onClick={StopPropagationEvent}>
                            <NumericTextBox
                                min={0}
                                max={1}
                                step={0.1}
                                format="p2"
                                value={value}
                                spinners={false}
                                onChange={(event) => HandleChangeTemplateWeighting(event, index)} />
                        </div>
                        :
                        `${(value * 100).toStringFixed("FR-fr")} %`}
            </Box>
        </Box>);
    }

    const HandleEditTemplate = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, editable: boolean): void => {
        if (props.dieselIndexTemplate.isEditable) {
            props.HandleEditTemplate(props.dieselIndexTemplate, editable);
            if (editable) {
                setTemplateEditableData({
                    ...templateEditableData,
                    referenceValue: props.dieselIndexTemplate.referenceValue,
                    weightings: cloneDeep(props.dieselIndexTemplate.weightings)
                });
            }
        }

        StopPropagationEvent(event);
    }

    const HandleChangeReferenceValue = (e: NumericTextBoxChangeEvent): void => {
        const value = e.target.value != null ? e.target.value : 0;
        if (value >= 0) {
            setTemplateEditableData({
                ...templateEditableData,
                referenceValue: value
            });
        }
        else {
            e.nativeEvent.preventDefault();
        }

    }

    const HandleChangeTemplateWeighting = (e: NumericTextBoxChangeEvent, index: number): void => {
        if (e.target.value >= 0 && e.target.value <= 1) {
            const weightings = templateEditableData.weightings;

            const templateWeightingsData = [...weightings];
            templateWeightingsData[index].weightingValue = e.target.value;

            setTemplateEditableData({
                ...templateEditableData,
                weightings: templateWeightingsData
            });
        }
        else {
            e.nativeEvent.preventDefault();
        }
    }

    const HandleHideModal = (): void => {
        setShowConfirmationRemoveTemplate(false);
    }

    const HandleClickTemplate = (): void => {
        props.HandleSelectedTemplate(props.dieselIndexTemplate, !props.templateSelected);
    }

    const HandleShowConfirmationRemoveTemplate = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (props.dieselIndexTemplate.isEditable)
            setShowConfirmationRemoveTemplate(true);

        StopPropagationEvent(event);
    }

    const StopPropagationEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <>
            <Card className={`template-card ${props.templateSelected ? ' template-card-selected' : ''}`} onClick={HandleClickTemplate}>
                <CardContent className="template-content">
                    <div className="template-header">
                        <div className="template-label template-title">
                            {props.dieselIndexTemplate.label}
                        </div>
                        {props.dieselIndexTemplate.isEditing ?
                            <Tooltip title="Sauvegarder le modèle" placement="bottom">
                                <div className="template-modify-save-icon" onClick={() => props.HandleUpdateDieselIndexTemplate(templateEditableData)}>
                                    <FontAwesomeIcon size="1x" className='icon-blue' icon={faSave} />
                                </div>
                            </Tooltip>
                            :
                            <Tooltip title="Modifier le modèle" placement="bottom">
                                <div className="template-modify-save-icon" onClick={(e) => HandleEditTemplate(e, true)}>
                                    <FontAwesomeIcon size="1x" className={props.dieselIndexTemplate.isEditable ? 'icon-blue' : 'icon-disabled'} icon={faPen} />
                                </div>
                            </Tooltip>
                        }
                        <Tooltip title={props.dieselIndexTemplate.isEditing ? "Annuler la modification" : "Supprimer le modèle"} placement="bottom">
                            <div className="template-remove-icon" onClick={(e) => props.dieselIndexTemplate.isEditing ? HandleEditTemplate(e, false) : HandleShowConfirmationRemoveTemplate(e)}>
                                <FontAwesomeIcon icon={faTimes} className={props.dieselIndexTemplate.isEditable ? 'close-icon' : 'icon-disabled'} />
                            </div>
                        </Tooltip>
                    </div>
                    <div>
                        {`${Date.getDateFromIsoString(props.dieselIndexTemplate.startDate)}} au ${Date.getDateFromIsoString(props.dieselIndexTemplate.endDate)}`}
                    </div>
                    <div className="template-reference">
                        <span className="template-title template-reference-title">C0 : </span>
                        {
                            props.dieselIndexTemplate.isEditing ?
                                <div onClick={StopPropagationEvent}>
                                    <NumericTextBox
                                        className="template-reference-value"
                                        min={0}
                                        onChange={(event) => HandleChangeReferenceValue(event)}
                                        format={{
                                            maximumFractionDigits: 4
                                        }}
                                        value={templateEditableData.referenceValue}
                                        spinners={false} />
                                </div>
                                :
                                `${props.dieselIndexTemplate.referenceValue.toStringFixed("FR-fr", 4)}`
                        }
                    </div>
                    <div className="template-title">
                        Pondération / K :
                    </div>
                    {
                        GetTemplateWeighting().map(elemLine => (
                            elemLine
                        ))
                    }
                </CardContent>
            </Card>
            <Modal show={showConfirmationRemoveTemplate} onHide={HandleHideModal} className='mt-5'>
                <Modal.Header closeButton>
                    <Modal.Title>ATTENTION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Souhaitez-vous vraiment supprimer ce modèle?
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary" onClick={HandleHideModal}>
                        ANNULER
                    </Button>
                    <Button className="primary" onClick={() => props.HandleRemoveDieselIndexTemplate(props.dieselIndexTemplate.dieselIndexTemplateId)}>
                        CONFIRMER
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}