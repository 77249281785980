import React from 'react';
import './PlanningVehicleStatusStyles.scss';

interface PlanningVehicleStatusComponentProps {
    isCanceled: boolean,
    transporterConfirmationDate: Date,
    transporterOrderId: string,
    transporterIsInternal: boolean,
    cancellationReason: string,
    cancellationRemarks: string,
    purchaseIsCanceled: boolean,
    saleIsCanceled: boolean,
    cancellationOrderSentDate: Date,
    cancellationOrderConfirmationDate: Date
}

interface PlanningVehicleStatusComponentState {
    title: string,
    className: string,
    letter: string
}


const dicoCancellationReasonTextes: Map<string, string> = new Map<string, string>([
    ["badWeather", "Intempéries"],
    ["customerCancellation", "Annulation client"],
    ["supplierCancellation", "Annulation fournisseur du client"],
    ["transporterCancellation", "Annulation transporteur"],
    ["other", "Autre"]
]);
const dicoCancellationReasonLetters: Map<string, string> = new Map<string, string>([
    ["badWeather", 'I'],
    ["customerCancellation", 'C'],
    ["supplierCancellation", 'F'],
    ["transporterCancellation", 'T'],
    ["other", 'A']
]);
const dicoConfirmationStatusTitles: Map<string, string> = new Map<string, string>([
    ["orderSent", 'Demande envoyée et non confirmée'],
    ["orderConfirmed", 'Camion confirmé par le transporteur']
]);

export class PlanningVehicleStatusComponent extends React.Component<PlanningVehicleStatusComponentProps, PlanningVehicleStatusComponentState>{

    constructor(props: PlanningVehicleStatusComponentProps) {
        super(props);

        this.state = {
            title: "",
            className: "",
            letter: ""
        };
    }

    componentDidMount() {
        if (this.props.isCanceled) {
            const cancellationReason = dicoCancellationReasonTextes.get(this.props.cancellationReason);
            const cancellationReasonAndRemarks = this.props.cancellationRemarks ? cancellationReason + ' - ' + this.props.cancellationRemarks : cancellationReason;
            const canceledPurchaseAndSaleText = this.props.purchaseIsCanceled && this.props.saleIsCanceled ? " (AV)" :
                (this.props.purchaseIsCanceled && !this.props.saleIsCanceled ? " (A)" :
                    (!this.props.purchaseIsCanceled && this.props.saleIsCanceled ? " (V)" : ""));

            const cancellationReasonTitle = cancellationReasonAndRemarks + canceledPurchaseAndSaleText;
            const cancellationReasonClassName = this.props.cancellationOrderConfirmationDate ? "vehicle-canceled order-sent confirmed" :
                (this.props.cancellationOrderSentDate ? "vehicle-canceled order-sent" : "vehicle-canceled");
            const cancellationReasonLetter = dicoCancellationReasonLetters.get(this.props.cancellationReason);

            this.setState({
                title: cancellationReasonTitle,
                className: cancellationReasonClassName,
                letter: cancellationReasonLetter
            });
        } else {
            const confirmationStatus = this.props.transporterConfirmationDate ? "orderConfirmed" : (this.props.transporterOrderId ? "orderSent" : "");
            const confirmationStatusTitle = dicoConfirmationStatusTitles.get(confirmationStatus);
            const confirmationStatusClassName = `confirmed-vehicle confirmed-vehicle-${this.props.transporterIsInternal ? 'internal-' : 'external-'}${confirmationStatus}`;

            this.setState({
                title: confirmationStatusTitle,
                className: confirmationStatusClassName,
                letter: ""
            });
        }
    }

    public static getOrderValue = (
        isCanceled: boolean,
        cancellationOrderSentDate: Date,
        cancellationOrderConfirmationDate: Date,
        transporterConfirmationDate: Date,
        transporterOrderId: string,
        transporterIsInternal: boolean
    ): number => {
        if (isCanceled && cancellationOrderConfirmationDate)
            return 0;

        if (!isCanceled && transporterConfirmationDate && transporterOrderId)
            return 1;

        if (!isCanceled && transporterIsInternal && transporterConfirmationDate && transporterOrderId)
            return 2;

        if (isCanceled && cancellationOrderSentDate && !cancellationOrderConfirmationDate)
            return 3;

        if (!isCanceled && !transporterIsInternal && !transporterConfirmationDate && transporterOrderId)
            return 4;

        if (!isCanceled && transporterIsInternal && !transporterConfirmationDate && transporterOrderId)
            return 5;

        if (isCanceled && !cancellationOrderSentDate && !cancellationOrderConfirmationDate)
            return 6;

        return 7;
    }

    render() {
        return (
            <div className="planning-vehicle-status">
                <div title={this.state.title} className={this.state.className}>{this.state.letter}</div>
            </div>
        );
    }
}