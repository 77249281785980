import { Box, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { ScaleLoaderComponent } from '../../../../../shared/components/Common/ScaleLoaderComponent';
import { TransporterCandidateToAddExtended } from '../../models/TransporterCandidateToAddExtended';
import { TransporterCandidateToAdd } from '../../services/dataContracts/controller/TransporterCandidateToAdd';
import { ConfirmAddTransporterStepComponent } from './ConfirmAddTransporterStepComponent';
import { SearchTransporterStepComponent } from './SearchTransporterStepComponent';
import { SelectTransporterStepComponent } from './SelectTransporterStepComponent';

interface AddTransporterDialogComponentProperties {
    activeStep: number,
    transporterIdMdm: string,
    transporterName: string,
    handleTransporterIdMdmChange: (value: string) => void,
    handleTransporterNameChange: (value: string) => void,
    transporterSearchLoading: boolean,
    transporterChoices: TransporterCandidateToAdd[],
    searchTransporterByMdmId: (transporterIdMdm: string) => void,
    searchTransporterByName: (transporterName: string) => void,
    handleSearchInReferentialGrid: (transporterMdmId: string) => void,
    handleConfirmStep: (transporter: TransporterCandidateToAddExtended) => void,
    confirmAddTransporter: (transporterMainEmail: string, secondsEmails: string, transporterPhoneNumber: string) => void,
    handlePreviousStep: () => void
}

export const AddTransporterDialogComponent = (props: AddTransporterDialogComponentProperties): JSX.Element => {

    const steps: string[] = ["Chercher", "Ajouter", "Confirmer"];

    const getStepContent = (stepIndex: number): JSX.Element => {
        switch (stepIndex) {
            case 0:
                return <SearchTransporterStepComponent
                    transporterIdMdm={props.transporterIdMdm}
                    transporterName={props.transporterName}
                    handleTransporterIdMdmChange={props.handleTransporterIdMdmChange}
                    handleTransporterNameChange={props.handleTransporterNameChange}
                    transporterSearchLoading={props.transporterSearchLoading}
                    searchTransporterByMdmId={props.searchTransporterByMdmId}
                    searchTransporterByName={props.searchTransporterByName}
                />;
            case 1:
                return <SelectTransporterStepComponent
                    transporterChoices={props.transporterChoices}
                    handleSearchInReferentialGrid={props.handleSearchInReferentialGrid}
                    handleConfirmStep={props.handleConfirmStep}
                    handlePreviousStep={props.handlePreviousStep}
                />;
            case 2:
                return <ConfirmAddTransporterStepComponent
                    confirmAddTransporter={props.confirmAddTransporter}
                    handlePreviousStep={props.handlePreviousStep}
                />;
            default:
                return <></>;
        }
    }

    return (
        <Box className="add-transporter-component">
            <Box display="flex" flexDirection="row" justifyContent="center" className="loading-content">
                <ScaleLoaderComponent loading={props.transporterSearchLoading} />
            </Box>
            <Stepper activeStep={props.activeStep} alternativeLabel>
                {steps.map((label: string) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box>{getStepContent(props.activeStep)}</Box>
        </Box>
    );
}