import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Checkbox, FormControlLabel, Input, InputAdornment } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { UploadOnBeforeUploadEvent, UploadOnStatusChangeEvent, UploadResponse } from '@progress/kendo-react-upload';
import React from 'react';
import { UploadComponent } from 'src/shared/components/Common/UploadComponent';
import { downloadFileFromBlob, getFileName } from 'src/utils/DownloadFile';
import { LogisticsUnitChoice } from '../../../../shared/models/LogisticsUnitChoice';
import { SelectedTransporterModel } from '../models/SelectedTransporterModel';
import { PurchasePriceChoicesExportRequestArgs } from '../services/dataContracts/controller/PurchasePriceChoicesExportRequestArgs';
import { TransporterChoicesExportRequestArgs } from '../services/dataContracts/controller/TransporterChoicesExportRequestArgs';
import { TransportPurchasePricesReferentialApiClient } from '../services/TransportPurchasePricesReferentialApiClient';

interface HeaderContentComponentProps {
    isForInternalTransporters: boolean,
    inputSearchPurchasePricesValue: string,
    inputSearchPurchasePricesRef: React.RefObject<HTMLInputElement>,
    sortTransporter: SortDescriptor[],
    sortPurchasePrice: SortDescriptor[],
    selectedTransporter: SelectedTransporterModel,
    logisticsUnits: Array<LogisticsUnitChoice>,
    showAllTransportLogisticsUnitsPurchasePrices: boolean,
    showOnlyContractedPrices: boolean,
    hasTransporters: boolean,
    isGlobalSearch: boolean,
    handlePurchasePricesKeyPress: (text: string) => void,
    handleClearSearchText: () => void,
    handlerAfterUpload: (response: UploadResponse) => void,
    handlerBeforeUpload: (event: UploadOnBeforeUploadEvent) => void,
    handleShowAllTransportLogisticsUnitsPurchasePrices: () => void,
    handleShowOnlyContractedPrices: () => void,
    handleClickOpenExportExistingPrices: () => void,
    handleClickOpenExportNewPrices: () => void,
    handleIsGlobalSearchChanged: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleAddTransporterDialogClick: () => void,
    handleEditTransporterDialogClick: () => void
}

export const HeaderContentComponent = (props: HeaderContentComponentProps): JSX.Element => {
    const exportToExcel = (): void => {
        const logisticsUnits: Array<string> = TransportPurchasePricesReferentialApiClient.GetChosenLogisticsUnits(props.logisticsUnits);

        const TransporterChoicesExportRequestArgs: TransporterChoicesExportRequestArgs = {
            isForInternalTransporters: props.isForInternalTransporters,
            searchText: props.inputSearchPurchasePricesValue,
            sortField: props.sortTransporter.length > 0 ? props.sortTransporter[0].field : null,
            sortDirection: props.sortTransporter.length > 0 ? props.sortTransporter[0].dir : null,
            logisticsUnits: logisticsUnits,
            isGlobalSearch: props.isGlobalSearch
        };


        if (!props.selectedTransporter.transporterId) {
            TransportPurchasePricesReferentialApiClient.ExportTransporterChoices(TransporterChoicesExportRequestArgs)
                .then(response => {
                    const fileName = getFileName(response);
                    const blob = new Blob([response.data]);
                    downloadFileFromBlob(blob, fileName);
                });
        }
        else {
            const logisticsUnits: Array<string> = TransportPurchasePricesReferentialApiClient.GetChosenLogisticsUnits(props.logisticsUnits);

            const PurchasePriceChoicesExportRequestArgs: PurchasePriceChoicesExportRequestArgs = {
                isForInternalTransporters: props.isForInternalTransporters,
                transporterId: props.selectedTransporter.transporterId,
                transporterName: props.selectedTransporter.transporterName,
                sortField: props.sortPurchasePrice.length > 0 ? props.sortPurchasePrice[0].field : null,
                sortDirection: props.sortPurchasePrice.length > 0 ? props.sortPurchasePrice[0].dir : null,
                onlyChosenPurchasePrices: !props.showAllTransportLogisticsUnitsPurchasePrices,
                onlyContractedPurchasePrices: props.showOnlyContractedPrices,
                logisticsUnits: logisticsUnits
            };

            TransportPurchasePricesReferentialApiClient.ExportTransporterPurchasePriceChoices(TransporterChoicesExportRequestArgs, PurchasePriceChoicesExportRequestArgs)
                .then(response => {
                    const fileNameTransporters = getFileName(response[0]);
                    const fileNamePurchasePrices = getFileName(response[1]);
                    const blobTransporters = new Blob([response[0].data]);
                    const blobPurchasePrices = new Blob([response[1].data]);
                    downloadFileFromBlob(blobTransporters, fileNameTransporters);
                    downloadFileFromBlob(blobPurchasePrices, fileNamePurchasePrices);
                });
        }
    }

    const onUploadStatusChange = (event: UploadOnStatusChangeEvent): void => {
        const res = event.response;
        if (res?.status == 200) {
            props.handlerAfterUpload(event.response);
        }
    }

    const checkboxInputProps: React.InputHTMLAttributes<HTMLInputElement> = { 'aria-label': 'primary checkbox' };

    return (
        <Box display="flex" flexDirection="row" flex="wrap" justifyContent="start" alignItems="center" className="header-content">
            <Box>
                <Input disableUnderline className={props.inputSearchPurchasePricesValue.length > 2 ? 'search-text-active' : ''} inputRef={props.inputSearchPurchasePricesRef}
                    startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                    endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => props.handleClearSearchText()} /></InputAdornment>}
                    placeholder="Rechercher ..." onChange={(event) => props.handlePurchasePricesKeyPress(event.target.value)} />
            </Box>
            <Box>
                <FormControlLabel
                    id="global-search-transporters-referential"
                    control={
                        <Checkbox
                            checked={props.isGlobalSearch}
                            disabled={props.inputSearchPurchasePricesValue.length < 3}
                            onChange={(e) => props.handleIsGlobalSearchChanged(e)}
                            color="primary"
                            inputProps={checkboxInputProps}
                        />
                    }
                    label="Recherche globale"
                    labelPlacement="start"
                />
            </Box>
            {!props.isForInternalTransporters &&
                <>
                    <Box ml={'10px'}>
                        <Button variant="contained" color="primary" title="Ajouter" onClick={props.handleAddTransporterDialogClick}>
                            Ajouter
                        </Button>
                    </Box>
                    <Box ml={'10px'}>
                        <Button variant="contained" disabled={!props.selectedTransporter.transporterId} color="primary" title="Modifier" onClick={props.handleEditTransporterDialogClick}>
                            Modifier
                        </Button>
                    </Box>
                </>}
            <Box ml={'10px'}>
                <Button variant="contained" color="primary" title="Exporter sous format Excel" onClick={exportToExcel}>
                    Exporter visibilité
                </Button>
            </Box>
            <Box ml={'10px'}>
                <Button variant="contained" color="primary" title="Exporter sous format Excel" disabled={!props.hasTransporters} onClick={props.handleClickOpenExportExistingPrices}>
                    Exporter tarifs existants
                </Button>
            </Box>
            <Box ml={'10px'}>
                <Button variant="contained" color="primary" title="Exporter sous format Excel" disabled={!props.hasTransporters} onClick={props.handleClickOpenExportNewPrices}>
                    Exporter nouveaux tarifs
                </Button>
            </Box>
            <Box ml={'10px'}>
                <UploadComponent
                    multiple={false}
                    showFileList={false}
                    restrictions={{
                        allowedExtensions: ['.xls', '.xlsx']
                    }}
                    defaultFiles={[]}
                    withCredentials={false}
                    saveUrl={TransportPurchasePricesReferentialApiClient.GetImportUrl(props.isForInternalTransporters)}
                    onBeforeUpload={props.handlerBeforeUpload}
                    onStatusChange={onUploadStatusChange}
                />
            </Box>
            <Box ml={'10px'}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.showAllTransportLogisticsUnitsPurchasePrices}
                            onChange={() => props.handleShowAllTransportLogisticsUnitsPurchasePrices()}
                            color="default"
                        />
                    }
                    label="Tous les tarifs"
                />
            </Box>
            {!props.isForInternalTransporters &&
                <Box ml={'10px'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.showOnlyContractedPrices}
                                onChange={() => props.handleShowOnlyContractedPrices()}
                                color="default"
                            />
                        }
                        label="Tarifs PAC"
                    />
                </Box>
            }
        </Box>
    );
}
