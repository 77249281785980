import axios, { AxiosResponse } from 'axios';
import { TransportQuotationRequest } from './dataContracts/TransportQuotationRequest';
import { TransportQuotationResult } from './dataContracts/TransportQuotationResult';
import { PriceGridsListEntry } from './dataContracts/admin/PriceGridsListEntry';
import { PriceGrid } from './dataContracts/admin/PriceGrid';
import { PriceGridSelectionRule } from './dataContracts/admin/PriceGridSelectionRule';
import { PriceGridUpdateResult } from './dataContracts/admin/PriceGridUpdateResult';
import { PriceGridUpdateRequestData } from './dataContracts/admin/PriceGridUpdateRequestData';

const apiUrl = 'api/QuotationProxy/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

const disabledGenericErrorHandlerConfig = {
    headers: headers,
    __colas_lorie_disableGenericErrorHandler: true
};

export class TransportQuotationApiClient {

    public static GetQuotation(transportQuotationRequest: TransportQuotationRequest): Promise<AxiosResponse<TransportQuotationResult>> {

        return axios({
            method: 'POST',
            url: `${apiUrl}GetQuotation`,
            headers: headers,
            data: JSON.stringify(transportQuotationRequest)
        });
    }

    public static GetPriceGridsList(logisticsUnitId: string): Promise<AxiosResponse<Array<PriceGridsListEntry>>> {
        return axios.get(`${apiUrl}admin/GetPriceGridsList?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`, disabledGenericErrorHandlerConfig);
    }

    public static GetPriceGridData(logisticsUnitId: string, priceGridId: string): Promise<AxiosResponse<PriceGrid>> {
        return axios.get(`${apiUrl}admin/GetPriceGridData?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}&priceGridId=${encodeURIComponent(priceGridId)}`, disabledGenericErrorHandlerConfig);
    }

    public static GetPricingSelectionRulesData(logisticsUnitId: string): Promise<AxiosResponse<Array<PriceGridSelectionRule>>> {
        return axios.get(`${apiUrl}admin/GetPricingSelectionRulesData?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`, disabledGenericErrorHandlerConfig);
    }

    public static HasMaintenanceLock(): Promise<AxiosResponse<boolean>> {
        return axios.get(`${apiUrl}admin/HasMaintenanceLock`, disabledGenericErrorHandlerConfig);
    }
    
    public static UpdatePriceGridData(logisticsUnitId: string, priceGridId: string, priceGridData: PriceGridUpdateRequestData): Promise<AxiosResponse<PriceGridUpdateResult>> {

        return axios({
            method: 'POST',
            url: `${apiUrl}admin/UpdatePriceGridData?logisticsUnitId=${logisticsUnitId}&priceGridId=${encodeURIComponent(priceGridId)}`,
            headers: headers,
            data: JSON.stringify(priceGridData)
        });
    }

}