import axios, { AxiosResponse } from 'axios';
import { ContractualPricedTransporterVehicleTypeCountedResultLightModel } from './dataContracts/queryStack/ContractualPricedTransporterVehicleTypeCountedResultLightModel';
import { DailyVehicleCountedResultLightModel } from './dataContracts/queryStack/DailyVehicleCountedResultLightModel';
import { HiddenPlanningVehicleLightModel } from './dataContracts/queryStack/HiddenPlanningVehicleLightModel';
import { ReservedTransporterVehicleTypeLightModel } from './dataContracts/queryStack/ReservedTransporterVehicleTypeLightModel';
import { VehicleCountedResultLightModel } from './dataContracts/queryStack/VehicleCountedResultLightModel';
import { VehicleTypeCountedResultLightModel } from './dataContracts/queryStack/VehicleTypeCountedResultLightModel';
import { VehicleTypeTransporterCountedResultLightModel } from './dataContracts/queryStack/VehicleTypeTransporterCountedResultLightModel';

const controllerUrl = 'api/VehiclesSelector/';

export class VehiclesSelectorApiClient {

    public static FindTransportersVehiclesAll = (pageLength: number, pageNumber: number, searchText: string, targetGenericChecked: boolean, targetTransporterChecked: boolean
        , targetInternalChecked: boolean, targetDailyVehiclesChecked: boolean, targetContractedChecked: boolean, includingAlreadyUsed: boolean, startDate: Date, endDate: Date, dailyVehicleDate: Date, logisticsUnits: Array<string>)
        : Promise<[AxiosResponse<VehicleTypeCountedResultLightModel>, AxiosResponse<VehicleTypeTransporterCountedResultLightModel>
            , AxiosResponse<VehicleCountedResultLightModel>, AxiosResponse<DailyVehicleCountedResultLightModel>, AxiosResponse<ContractualPricedTransporterVehicleTypeCountedResultLightModel>]> => {

        return Promise.all([
            VehiclesSelectorApiClient.FindVehicleTypeTrip(pageLength, pageNumber, searchText, targetGenericChecked),
            VehiclesSelectorApiClient.FindTransportersVehicleTypeTrip(pageLength, pageNumber, searchText, startDate, endDate, logisticsUnits, targetTransporterChecked),
            VehiclesSelectorApiClient.FindInternalVehicles(pageLength, pageNumber, startDate, searchText, logisticsUnits, targetInternalChecked, includingAlreadyUsed),
            VehiclesSelectorApiClient.FindAssignedDailyVehicles(pageLength, pageNumber, startDate, dailyVehicleDate, searchText, logisticsUnits, targetDailyVehiclesChecked, includingAlreadyUsed),
            VehiclesSelectorApiClient.FindContractedVehicles(pageLength, pageNumber, startDate, searchText, logisticsUnits, targetContractedChecked)
        ]);
    }

    public static FindAssignedDailyVehiclesWithInternalVehicles = (pageLength: number, pageNumber: number, searchText: string
        , targetInternalChecked: boolean, targetDailyVehiclesChecked: boolean, includingAlreadyUsed: boolean, startDate: Date, dailyVehicleDate: Date, logisticsUnits: Array<string>)
        : Promise<[AxiosResponse<DailyVehicleCountedResultLightModel>, AxiosResponse<VehicleCountedResultLightModel>]> => {

        return Promise.all([
            VehiclesSelectorApiClient.FindAssignedDailyVehicles(pageLength, pageNumber, startDate, dailyVehicleDate, searchText, logisticsUnits, targetDailyVehiclesChecked, includingAlreadyUsed),
            VehiclesSelectorApiClient.FindInternalVehicles(pageLength, pageNumber, startDate, searchText, logisticsUnits, targetInternalChecked, includingAlreadyUsed)
        ]);
    }

    public static FindAssignedDailyVehiclesWithInternalVehiclesAndContractedVehicles = (pageLength: number, pageNumber: number, searchText: string, targetInternalChecked: boolean, targetDailyVehiclesChecked: boolean, targetContractedChecked: boolean, includingAlreadyUsed: boolean, startDate: Date, endDate: Date, dailyVehicleDate: Date, logisticsUnits: Array<string>)
        : Promise<[AxiosResponse<DailyVehicleCountedResultLightModel>, AxiosResponse<VehicleCountedResultLightModel>, AxiosResponse<ContractualPricedTransporterVehicleTypeCountedResultLightModel>]> => {

        return Promise.all([
            VehiclesSelectorApiClient.FindAssignedDailyVehicles(pageLength, pageNumber, startDate, dailyVehicleDate, searchText, logisticsUnits, targetDailyVehiclesChecked, includingAlreadyUsed),
            VehiclesSelectorApiClient.FindInternalVehicles(pageLength, pageNumber, startDate, searchText, logisticsUnits, targetInternalChecked, includingAlreadyUsed),
            VehiclesSelectorApiClient.FindContractedVehicles(pageLength, pageNumber, startDate, searchText, logisticsUnits, targetContractedChecked)
        ]);
    }

    public static GetUnusedReservationsWithHiddenVehicles = (dailyVehicleDate: Date, planningDate: Date)
        : Promise<[AxiosResponse<Array<ReservedTransporterVehicleTypeLightModel>>, AxiosResponse<Array<HiddenPlanningVehicleLightModel>>]> => {

        return Promise.all([
            VehiclesSelectorApiClient.GetUnusedReservations(planningDate),
            VehiclesSelectorApiClient.GetHiddenVehicles(dailyVehicleDate, planningDate)
        ]);
    }

    public static FindVehicleTypeTrip = (pageLength: number, pageNumber: number, searchText: string, checked: boolean)
        : Promise<AxiosResponse<VehicleTypeCountedResultLightModel>> => {
        return axios.get(`${controllerUrl}SearchGenericVehicle?searchText=${encodeURIComponent(searchText)}&pageIndex=${pageNumber}&pageSize=${pageLength}&includingElements=${checked}`);
    }

    public static FindTransportersVehicleTypeTrip = (pageLength: number, pageNumber: number, searchText: string, startDate: Date, endDate: Date, logisticsUnits: Array<string>, checked: boolean)
        : Promise<AxiosResponse<VehicleTypeTransporterCountedResultLightModel>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        let url = `${controllerUrl}SearchTransporterVehicle?startDate=${encodeURIComponent(startDate.toJSON())}&searchText=${encodeURIComponent(searchText)}&pageIndex=${pageNumber}&pageSize=${pageLength}&includingElements=${checked}`;
        if (endDate !== null)
            url = `${url}&endDate=${encodeURIComponent(endDate.toJSON())}&logisticsUnits=${logisticsUnits}`;
        return axios.post(url, logisticsUnits, { headers: _headers });
    }

    public static FindInternalVehicles = (pageLength: number, pageNumber: number, fromTime: Date, searchText: string, logisticsUnits: Array<string>, checked: boolean, includingAlreadyUsed: boolean)
        : Promise<AxiosResponse<VehicleCountedResultLightModel>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        const url = `${controllerUrl}SearchInternalVehicles?searchText=${encodeURIComponent(searchText)}&pageIndex=${pageNumber}&pageSize=${pageLength}&includingElements=${checked}&includingAlreadyUsed=${includingAlreadyUsed}&planningDate=${encodeURIComponent(fromTime.toJSON())}`;

        return axios.post(url, logisticsUnits, { headers: _headers });
    }

    public static FindAssignedDailyVehicles = (pageLength: number, pageNumber: number, planningDate: Date, dailyVehicleDate: Date, searchText: string, logisticsUnits: Array<string>, checked: boolean, includingAlreadyUsed: boolean)
        : Promise<AxiosResponse<DailyVehicleCountedResultLightModel>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const url = `${controllerUrl}SearchAssignedDailyVehicles?searchText=${encodeURIComponent(searchText)}&pageIndex=${pageNumber}&pageSize=${pageLength}&includingElements=${checked}&includingAlreadyUsed=${includingAlreadyUsed}&planningDate=${encodeURIComponent(planningDate.toJSON())}&dailyVehicleDate=${encodeURIComponent(dailyVehicleDate.toJSON())}&currentDate=${encodeURIComponent((new Date()).toJSON())}`;

        return axios.post(url, logisticsUnits, { headers: _headers });
    }

    public static FindContractedVehicles = (pageLength: number, pageNumber: number, planningDate: Date, searchText: string, logisticsUnits: Array<string>, checked: boolean)
        : Promise<AxiosResponse<ContractualPricedTransporterVehicleTypeCountedResultLightModel>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const url = `${controllerUrl}SearchContractedVehicles?searchText=${encodeURIComponent(searchText)}&pageIndex=${pageNumber}&pageSize=${pageLength}&planningDate=${encodeURIComponent(planningDate.toJSON())}&currentDate=${encodeURIComponent((new Date()).toJSON())}&includingElements=${checked}`;

        return axios.post(url, logisticsUnits, { headers: _headers });
    }

    public static GetUnusedReservations = (selectedDate: Date)
        : Promise<AxiosResponse<Array<ReservedTransporterVehicleTypeLightModel>>> => {
        return axios.get(`${controllerUrl}GetUnusedReservations?selectedDate=${encodeURIComponent(selectedDate.toJSON())}`);
    }

    public static GetHiddenVehicles = (dailyVehicleDate: Date, planningDate: Date)
        : Promise<AxiosResponse<Array<HiddenPlanningVehicleLightModel>>> => {
        return axios.get(`${controllerUrl}GetHiddenVehicles?dailyVehicleDate=${encodeURIComponent(dailyVehicleDate.toJSON())}&planningDate=${encodeURIComponent(planningDate.toJSON())}`);
    }

    public static UpdateIsHiddenForVehiclesSelector = (planningVehicleId: string, vehicleId: number, selectedDate: Date, isHidden: boolean, userName: string)
        : Promise<AxiosResponse> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateIsHiddenForVehiclesSelector?planningVehicleId=${planningVehicleId}&vehicleId=${vehicleId}&selectedDate=${encodeURIComponent(selectedDate.toJSON())}&isHidden=${isHidden}&userName=${userName}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }
}
