import { GridCellProps } from '@progress/kendo-react-grid';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import React from 'react';

interface DeliveryTripInstructionsCustomCellProperties extends GridCellProps{
    width: string
}

interface DeliveryTripInstructionsCustomCellState {
    dataValue: string|string[]|number
}

export default class DeliveryTripInstructionsCustomCellComponent extends React.Component<DeliveryTripInstructionsCustomCellProperties, DeliveryTripInstructionsCustomCellState> {
    constructor(props: DeliveryTripInstructionsCustomCellProperties) {
        super(props);
        this.state = {
            dataValue: this.props.dataItem[this.props.field],
        }
    }

    handleChange = (props: DeliveryTripInstructionsCustomCellProperties, event: InputChangeEvent): void => {
        this.setState({
            dataValue: event.target.value
        });

        props.dataItem.inEdit = props.field;
        props.dataItem[props.field] = event.target.value;
    }

    render() {
        let dataValue: string = this.props.dataItem[this.props.field];
        dataValue = dataValue ? dataValue : '';
        let defaultRendering: JSX.Element = null;
        if (!this.props.dataItem.inEdit || this.props.dataItem.inEdit !== this.props.field) {
            defaultRendering = (
                <td>{dataValue}</td>
            );
        } else {
            defaultRendering = (
                <td>
                    <Input
                        value={dataValue}
                        onChange={(event) => this.handleChange(this.props, event)}
                        width={this.props.width} />
                </td>
            );
        }
        return this.props.render ?
            this.props.render.call(undefined, defaultRendering, this.props) :
            defaultRendering;
    }
}