export { }

declare global {
    interface String {
        padZero(len?: number, c?: string): string;
        isNullOrEmpty(value): boolean;
        format(format, args): any;
        startsWith(prefix, ignoreCase): boolean;
        endsWith(suffix): boolean;
        trim(): string;
        contains(text): boolean;
        removeDiacritics(): string;
    }
}

String.prototype.padZero = function (len, c) {
    var s = '', c = c || '0', len = (len || 2) - this.length;
    while (s.length < len) s += c;
    return s + this;
};
String.prototype.isNullOrEmpty = function (value) {
    if (value == undefined || value == 'undefined')
        return true;

    if (value) {
        if (typeof (value) == 'string') {
            if (value.length > 0)
                return value.replace(/\s/g, '').length < 1;
        }

        if (value != null)
            return false;
    }
    return true;
};

String.prototype.format = function (format, args) {
    let result = format;
    for (let i = 1; i < arguments.length; i++) {
        result = result.replace(new RegExp('\\{' + (i - 1) + '\\}', 'g'), arguments[i]);
    }
    return result;
};

String.prototype.startsWith = function (prefix, ignoreCase) {
    if (!prefix) return false;
    if (prefix.length > this.length) return false;
    if (ignoreCase) {
        if (ignoreCase == true) {
            return (this.substr(0, prefix.length).toUpperCase() == prefix.toUpperCase());
        }
    }
    return (this.substr(0, prefix.length) === prefix);
}

String.prototype.endsWith = function (suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
};

String.prototype.trim = function () {
    return this.replace(/^\s+|\s+$/g, '');
};

String.prototype.contains = function (text) {
    return this.indexOf(text) !== -1;
};

String.prototype.removeDiacritics = function(){
    //see: from https://stackoverflow.com/a/37511463
    return this.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}