import { Button } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import ToastService from 'src/ToastService';
import { AppModule, LocalStorage } from '../../../utils/Storage';
import { DeliveryTripInstructionLightModel } from '../services/dataContracts/queryStack/DeliveryTripInstructionLightModel';
import { PlanningVehicleVisualisationLightModel } from '../services/dataContracts/queryStack/PlanningVehicleVisualisationLightModel';
import { TransportFlowsDetailsLightModel } from '../services/dataContracts/queryStack/TransportFlowsDetailsLightModel';
import { SizingUtilities } from '../SizingUtilities';
import { CustomDriverInstructionsCell } from './CustomDriverInstructionsCell';
import { CustomIsNightCell } from './CustomIsNightCell';

interface VisualisationComponentProps {
    visualisationData: Array<PlanningVehicleVisualisationLightModel>,
    _getLabelPrice: (priceKind: string) => string
}

interface VehicleData {
    date: string,
    senderAgency: string,
    transporter: string,
    vehicleNumber: string,
    vehicleType: string,
    businessId: string,
    transportFlowId: string,
    loadingTime: Date,
    expectedEndTime: Date,
    expectedTimeBillableOnMainPrice: string,
    plannedQuantity: string,
    transportersInstructions: string,
    driverInstructions: Array<string>,
    beneficiaryName: string,
    beneficiaryPhoneNumber: string,
    product: string,
    isNight: string,
    receiverSiteLabel: string,
    deliveryAdress1: string,
    deliveryAdress2: string,
    deliveryCity: string,
    licencePlate: string,
    confirmationNumber: string,
    senderSiteLabel: string,
    mainPriceKind: string,
    totalMainPrice?: number,
    deliveredQuantity?: number,
    total?: number,
    receiverAgency: string,
    remarks: string,
    transportFlowCount: string,
    isInternal: string,
    buyerLogisticsUnitLabel: string
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

interface VisualisationState{
    data: Array<VehicleData>,
    sort: Array<SortDescriptor>,
    skip: number
}

const TransportPurchasesVisualisationGridName = 'transportPurchasesVisualistaion';
const ModuleKey = AppModule.TransportPurchases;

export const VisualisationComponent = (props: VisualisationComponentProps) => {
    const sort: SortDescriptor[] = [{ field: 'date', dir: 'asc' }];

    const [state, setState] = useState<VisualisationState>({
        data: [],
        sort: sort,
        skip: 0
    });
    const [loading, setLoading] = useState<boolean>(false);

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        setState({
            ...state,
            data: setData()
        });
    }, [props.visualisationData]);

    const setData = (): Array<VehicleData> => {
        const vehicleDataArray = new Array<VehicleData>();
        props.visualisationData.forEach((element: PlanningVehicleVisualisationLightModel) => {
            element.transportFlows.forEach((tf: TransportFlowsDetailsLightModel) => {
                const item = {} as VehicleData;

                item.date = element.date !== null ? Date.getDateFromIsoString(element.date) as unknown as string : "";
                item.transporter = element.transporter !== null ? element.transporter : "";
                item.vehicleNumber = element.vehicleNumber !== null ? element.vehicleNumber.toString() : "";
                item.plannedQuantity = tf.plannedQuantity !== null ? tf.plannedQuantity.toString() : "";
                item.vehicleType = element.plannedVehicleType !== null ? element.plannedVehicleType : "";
                item.businessId = tf.businessId !== null ? tf.businessId : "";
                item.loadingTime = tf.firstStartTime !== null ? Date.getTimeFromIsoString(tf.firstStartTime) : null;
                item.expectedEndTime = tf.lastEndTime !== null ? Date.getTimeFromIsoString(tf.lastEndTime) : null;
                item.expectedTimeBillableOnMainPrice = element.expectedTimeBillableOnMainPrice;
                item.transportersInstructions = tf.transportersInstructions !== null ? tf.transportersInstructions : "";
                item.driverInstructions = getDriverInstructions(tf.instructions);
                item.beneficiaryName = tf.beneficiaryName !== null ? tf.beneficiaryName : "";
                item.beneficiaryPhoneNumber = tf.beneficiaryPhoneNumber !== null ? tf.beneficiaryPhoneNumber : "";
                item.product = tf.productCode !== null ? tf.productCode : "";
                item.isInternal = element.isInternal === null 
                    ? ""
                    : element.isInternal === true ? "Interne" : "Externe";
                item.isNight = element.isNightWork ? "OUI" : "";
                item.senderAgency = tf.senderAgency !== null ? tf.senderAgency : "";
                item.receiverSiteLabel = tf.receiverSiteLabel !== null ? tf.receiverSiteLabel : "";
                item.deliveryAdress1 = tf.deliveryAddressLine1 !== null ? tf.deliveryAddressLine1 : "";
                item.deliveryAdress2 = tf.deliveryAddressLine2 !== null ? tf.deliveryAddressLine2 : "";
                item.deliveryCity = tf.deliveryAddressCity !== null ? tf.deliveryAddressCity : "";
                item.transportFlowId = tf.transportFlowId !== null ? tf.transportFlowId : "";
                item.licencePlate = element.licencePlate !== null ? element.licencePlate :  "";
                item.senderSiteLabel = tf.senderSiteLabel !== null ? tf.senderSiteLabel :  "";
                item.confirmationNumber = element.confirmationNumber !== null ? element.confirmationNumber.toString() : "";
                item.receiverAgency = tf.receiverAgency;
                item.remarks = element.remarks;
                item.transportFlowCount = element.transportFlowCount !== null ?element.transportFlowCount.toString() : "";
                item.mainPriceKind = (element.hasSpecificPrice || element.hasNegotiatedPrice) ? 'Multiple' : props._getLabelPrice(element.mainPriceKind);
                item.deliveredQuantity = element.deliveredQuantity;
                item.total = Number((element.total ?? 0).toFixed(2));
                item.buyerLogisticsUnitLabel = element.buyerLogisticsUnitLabel;

                vehicleDataArray.push(item);
            });
        });
        return vehicleDataArray;
    }

    const getDriverInstructions = (instructions: DeliveryTripInstructionLightModel[]): Array<string> => {
        const driverInstructionsTable: Array<string> = [];

        if (instructions.length === 1) {
            instructions[0].instructionText !== '' ? driverInstructionsTable.push(instructions[0].instructionText) : driverInstructionsTable.push('');
        } else if (instructions.length > 1) {
            instructions.forEach((e) => {
                e.instructionText !== '' ? driverInstructionsTable.push('Tour n° ' + e.rotationNumber + ' : ' + e.instructionText) : driverInstructionsTable.push('');
            });
        }
        return driverInstructionsTable;
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            TransportPurchasesVisualisationGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, TransportPurchasesVisualisationGridName, event.columns);
        forceUpdate();
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setState({
            ...state,
            sort: e.sort
        });
    }

    const getVisualisationGridWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, TransportPurchasesVisualisationGridName, fieldName, columnWidth);
    }

    const getVisualisationGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, TransportPurchasesVisualisationGridName, fieldName, defaultIndex);
  }

    const toClipboardCell = (value: string) : string => {

        if (value === null || value === '' || value === undefined)
            return '""';

        let result = value;

        if (value.indexOf('"') >= 0) {
          result = result.replace(new RegExp('"', 'g'), '""'); //replaceAll
        }

        return '"' + result + '"';
    }

    const copyToClipBoard = (): void => {
        setLoading(true);
        const dataToCopy: Array<VehicleData> = orderBy(state.data, state.sort);
        let textToCopy = `Date\tImmatriculation\tSite départ\tTransporteur\tN°\tType\tN° Besoin\tN° Flux\tQté planifiée\tType tarif\tCoût total\tCommentaire\tNb. missions\tBon Confirmation\tHeure début\t Heure Fin prévue\tTps à facturer\tQté livrée\tInstructions aux transporteurs\tInstructions chauffeurs\tBénéficiaire\tTéléphone\tProduit\tInt/Ext\tNuit\tAgence départ\tAgence arrivée\tSite arrivée\tAdresse arrivée 1\tAdresse arrivée 2\tVille arrivée\n`;

    dataToCopy.forEach((item: VehicleData) => {
        let driverInstructions = "";
        item.driverInstructions.forEach((instruction: string) => {
            driverInstructions += instruction + "\n"
        });
        if (driverInstructions !== "") {
            driverInstructions = driverInstructions.substring(0, driverInstructions.length - 1);
        }
        textToCopy += `${toClipboardCell(item.date?.toString())}\t${toClipboardCell(item.licencePlate)}\t${toClipboardCell(item.senderSiteLabel)}\t${toClipboardCell(item.transporter)}\t${toClipboardCell(item.vehicleNumber)}\t${toClipboardCell(item.vehicleType)}\t${toClipboardCell(item.businessId)}\t${toClipboardCell(item.transportFlowId)}\t${toClipboardCell(item.plannedQuantity)}\t${toClipboardCell(item.mainPriceKind)}\t${toClipboardCell(item.total?.toString())}\t${toClipboardCell(item.remarks)}\t${toClipboardCell(item.transportFlowCount)}\t${toClipboardCell(item.confirmationNumber)}\t${toClipboardCell(item.loadingTime?.toString())}\t${toClipboardCell(item.expectedEndTime?.toString())}\t${toClipboardCell(item.expectedTimeBillableOnMainPrice?.toString())}\t${item.deliveredQuantity}\t${toClipboardCell(item.transportersInstructions)}\t${toClipboardCell(driverInstructions)}\t${toClipboardCell(item.beneficiaryName)}\t${toClipboardCell(item.beneficiaryPhoneNumber)}\t${toClipboardCell(item.product)}\t${toClipboardCell(item.isInternal)}\t${toClipboardCell(item.isNight)}\t${toClipboardCell(item.senderAgency)}\t${toClipboardCell(item.receiverAgency)}\t${toClipboardCell(item.receiverSiteLabel)}\t${toClipboardCell(item.deliveryAdress1)}\t${toClipboardCell(item.deliveryAdress2)}\t${toClipboardCell(item.deliveryCity)}\n`;
        });
        navigator.clipboard.writeText(textToCopy).then(() => {
            ToastService.showSuccessToast("Les données sont copiées dans le presse-papier");
            setLoading(false);
        });
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setState({
            ...state,
            skip: event.page.skip
        });
    }

    const _gridOffsetFromWindowTop = SizingUtilities.gridOffsetFromWindowTop(true);
    let gridHeight = SizingUtilities.computeGridHeight(_gridOffsetFromWindowTop, true);
    let gridPageSize = SizingUtilities.computeGridPageSize(gridHeight, SizingUtilities.rowHeight);
    const resize = (): void => {
        gridHeight = ((window.innerHeight * 80) / 100) - _gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / SizingUtilities.rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    return (
        <>
            <Button variant="contained" color="primary" className="btn-copy" onClick={copyToClipBoard}>
                Copier dans le presse-papier
            </Button>

            <ScaleLoader
                width={5}
                height={20}
                radius={50}
                color={'#000000'}
                loading={loading}
            />

            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Grid
                        data={orderBy(state.data, state.sort).slice(state.skip, state.skip + gridPageSize)}
                        sort={state.sort}
                        className="data-to-copy-grid"
                        sortable
                        reorderable
                        resizable
                        onColumnResize={onResizeHandler}
                        onColumnReorder={(e) => onReorderHandler(e)}
                        onSortChange={handleSortChange}
                        scrollable="virtual"
                        skip={state.skip}
                        total={state.data.length}
                        pageSize={gridPageSize}
                        onPageChange={pageChange}
                        rowHeight={40}
                        style={{ height: gridHeight }}
                    >
                        <GridColumn field="date" title="Date" format="{0:dd-MM-yyyy}" width={getVisualisationGridWidth("date", 100)} orderIndex={getVisualisationGridOrderIndexColumn("date", 0)} />
                        <GridColumn field="licencePlate" width={getVisualisationGridWidth("licencePlate", 120)} orderIndex={getVisualisationGridOrderIndexColumn("licencePlate", 1)} title="Immatriculation" editable={false} />
                        <GridColumn field="senderSiteLabel" title="Site départ" width={getVisualisationGridWidth("senderSiteLabel", 210)} orderIndex={getVisualisationGridOrderIndexColumn("senderSiteLabel", 2)} />
                        <GridColumn field="transporter" title="Transporteur" width={getVisualisationGridWidth("transporter", 250)} orderIndex={getVisualisationGridOrderIndexColumn("transporter", 3)} />
                        <GridColumn field="vehicleNumber" title="N°" width={getVisualisationGridWidth("vehicleNumber", 120)} orderIndex={getVisualisationGridOrderIndexColumn("vehicleNumber", 4)} />
                        <GridColumn field="vehicleType" title="Type" width={getVisualisationGridWidth("vehicleType", 130)} orderIndex={getVisualisationGridOrderIndexColumn("vehicleType", 5)} />
                        <GridColumn field="businessId" title="N° Besoin" width={getVisualisationGridWidth("businessId", 143)} orderIndex={getVisualisationGridOrderIndexColumn("businessId", 6)} />
                        <GridColumn field="transportFlowId" title="N° Flux" width={getVisualisationGridWidth("transportFlowId", 143)} orderIndex={getVisualisationGridOrderIndexColumn("transportFlowId", 7)} />
                        <GridColumn field="plannedQuantity" title="Qté planifiée" width={getVisualisationGridWidth("plannedQuantity", 143)} orderIndex={getVisualisationGridOrderIndexColumn("plannedQuantity", 8)} />
                        <GridColumn field="mainPriceKind" title="Type tarif" width={getVisualisationGridWidth("mainPriceKind", 143)} orderIndex={getVisualisationGridOrderIndexColumn("mainPriceKind", 9)} />
                        <GridColumn field="total" title="Coût total" width={getVisualisationGridWidth("total", 143)} orderIndex={getVisualisationGridOrderIndexColumn("total", 10)} 
                            cell={(props: GridCellProps) => <td>
                                {props.dataItem.total ? props.dataItem.total + ' €' : ''}
                            </td>}
                        />
                        <GridColumn field="remarks" title="Commentaire" width={getVisualisationGridWidth("remarks", 143)} orderIndex={getVisualisationGridOrderIndexColumn("remarks", 11)} />
                        <GridColumn field="transportFlowCount" title="Nb. missions" width={getVisualisationGridWidth("transportFlowCount", 143)} orderIndex={getVisualisationGridOrderIndexColumn("transportFlowCount", 12)} />
                        <GridColumn field="confirmationNumber" title="Bon confirmation" width={getVisualisationGridWidth("confirmationNumber", 143)} orderIndex={getVisualisationGridOrderIndexColumn("confirmationNumber", 13)} />
                        <GridColumn field="loadingTime" title="Heure début" width={getVisualisationGridWidth("loadingTime", 120)} orderIndex={getVisualisationGridOrderIndexColumn("loadingTime", 14)} format="{0:HH:mm}" editable={false} />
                        <GridColumn field="expectedEndTime" orderIndex={getVisualisationGridOrderIndexColumn("expectedEndTime", 15)} width={getVisualisationGridWidth("expectedEndTime", 100)} title="Heure fin prévue" format="{0:HH:mm}" editable={false} />
                        <GridColumn field="expectedTimeBillableOnMainPrice" title="Tps à facturer" width={getVisualisationGridWidth("expectedTimeBillableOnMainPrice", 120)} orderIndex={getVisualisationGridOrderIndexColumn("expectedTimeBillableOnMainPrice", 16)} format="{0:HH:mm}"  editable={false} />
                        <GridColumn field="deliveredQuantity" title="Qté livrée" width={getVisualisationGridWidth("deliveredQuantity", 120)} orderIndex={getVisualisationGridOrderIndexColumn("deliveredQuantity", 17)} editable={false} />
                        <GridColumn field="transportersInstructions" title="Instructions aux transporteurs" width={getVisualisationGridWidth("transportersInstructions", 150)} orderIndex={getVisualisationGridOrderIndexColumn("transportersInstructions", 18)} />
                        <GridColumn field="driverInstructions" title="Instructions chauffeurs" width={getVisualisationGridWidth("driverInstructions", 150)} orderIndex={getVisualisationGridOrderIndexColumn("driverInstructions", 19)}
                            cell={(props: GridCellProps) => <CustomDriverInstructionsCell {...props} />}
                        />
                        <GridColumn field="beneficiaryName" title="Bénéficiaire" width={getVisualisationGridWidth("beneficiaryName", 140)} orderIndex={getVisualisationGridOrderIndexColumn("beneficiaryName", 21)} />
                        <GridColumn field="beneficiaryPhoneNumber" title="Téléphone" width={getVisualisationGridWidth("beneficiaryPhoneNumber", 120)} orderIndex={getVisualisationGridOrderIndexColumn("beneficiaryPhoneNumber", 22)} />
                        <GridColumn field="product" title="Produit" width={getVisualisationGridWidth("product", 120)} orderIndex={getVisualisationGridOrderIndexColumn("product", 23)} />
                        <GridColumn field="isInternal" width={getVisualisationGridWidth("isInternal", 100)} orderIndex={getVisualisationGridOrderIndexColumn("isInternal", 24)} title="Int/Ext" editable={false} />
                        <GridColumn field="isNight" title="Nuit" width={getVisualisationGridWidth("isNight", 120)} orderIndex={getVisualisationGridOrderIndexColumn("isNight", 25)}
                            cell={(props: GridCellProps) => <CustomIsNightCell {...props} />}
                        />
                        <GridColumn field="receiverSiteLabel" title="Site arrivée" width={getVisualisationGridWidth("receiverSiteLabel", 210)} orderIndex={getVisualisationGridOrderIndexColumn("receiverSiteLabel", 26)} />
                        <GridColumn field="senderAgency" title="Agence départ" width={getVisualisationGridWidth("senderAgency", 200)} orderIndex={getVisualisationGridOrderIndexColumn("senderAgency", 27)} />
                        <GridColumn field="receiverAgency" title="Agence arrivée" width={getVisualisationGridWidth("receiverAgency", 200)} orderIndex={getVisualisationGridOrderIndexColumn("receiverAgency", 28)} />
                        <GridColumn field="deliveryAdress1" title="Adresse arrivée 1" width={getVisualisationGridWidth("deliveryAdress1", 130)} orderIndex={getVisualisationGridOrderIndexColumn("deliveryAdress1", 29)} />
                        <GridColumn field="deliveryAdress2" title="Adresse arrivée 2" width={getVisualisationGridWidth("deliveryAdress2", 130)} orderIndex={getVisualisationGridOrderIndexColumn("deliveryAdress2", 30)} />
                        <GridColumn field="deliveryCity" title="Ville arrivée" width={getVisualisationGridWidth("deliveryCity", 120)} orderIndex={getVisualisationGridOrderIndexColumn("deliveryCity", 31)} />
                        <GridColumn field="buyerLogisticsUnitLabel" width={getVisualisationGridWidth("buyerLogisticsUnitLabel", 100)} orderIndex={getVisualisationGridOrderIndexColumn("buyerLogisticsUnitLabel", 32)} title="Zone logistique" editable={false} />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </>
    );
}
