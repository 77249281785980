import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { UploadOnAddEvent, UploadOnBeforeUploadEvent, UploadOnStatusChangeEvent, UploadResponse } from '@progress/kendo-react-upload';
import React from 'react';
import { ScaleLoader } from 'react-spinners';
import { SettingsProvider } from 'src/SettingsProvider';
import { AttachmentsApiClient } from 'src/shared/Attachments/services/AttachmentsApiClient';
import { UploadComponent } from 'src/shared/components/Common/UploadComponent';
import { CommandResultLight, WebAppActionResultEx } from 'src/shared/models/WebAppActionResult';
import { downloadFileFromBlob, getFileName } from 'src/utils/DownloadFile';
import { AddedAttachmentResult } from '../services/dataContracts/controller/AddedAttachmentResult';
import { AttachmentArgs } from '../services/dataContracts/controller/AttachmentArgs';
import { AttachmentStatus } from '../services/dataContracts/controller/AttachmentStatus';
import { TransportPurchasesApiClient } from '../services/TransportPurchasesApiClient';

interface AttachmentsComponentProps {
    attachmentFiles: Array<AttachmentArgs>,
    isLoadingAttachments: boolean,
    handleAdd: (event: UploadOnAddEvent) => void,
    handleDeleteAttachment: (attachmentId: string, state: AttachmentStatus) => void,
    handlerAfterUploadAttachment: (attachments: AddedAttachmentResult[]) => void,
    handlerBeforeUpload: (event: UploadOnBeforeUploadEvent) => void,
    handlerDisplayErrorsAfterUpload: (commandResults: CommandResultLight[]) => void
}


export const AttachmentsComponent = (props: AttachmentsComponentProps): JSX.Element => {
    const onUploadStatusChange = (event: UploadOnStatusChangeEvent): void => {
        const res: UploadResponse = event.response;
        if (res?.status == 200) {
            const data: WebAppActionResultEx<AddedAttachmentResult[]> = res?.response;
            const commandResultsWithErrors = data.commandResults.filter(x => x.hasBusinessErrors === true);
            if (commandResultsWithErrors.length > 0) {
                props.handlerDisplayErrorsAfterUpload(commandResultsWithErrors);
            }
            props.handlerAfterUploadAttachment(data.extraResult);
        }
    }

    const getAttachment = (attachmentId: string): void => {
        AttachmentsApiClient.GetAttachment(attachmentId)
            .then(response => {
                const fileName: string = getFileName(response);
                const blob: Blob = response.data;
                downloadFileFromBlob(blob, fileName);
            });
    }

    const config = SettingsProvider.Get();
    return (
        <Box display="flex" flexDirection="column" className="upload-attachment">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr">
                    <UploadComponent
                        disabled={props.isLoadingAttachments}
                        batch={true}
                        multiple={true}
                        showFileList={false}
                        onStatusChange={onUploadStatusChange}
                        onBeforeUpload={props.handlerBeforeUpload}
                        onAdd={props.handleAdd}
                        defaultFiles={[]}
                        withCredentials={false}
                        saveUrl={TransportPurchasesApiClient.GetAttachmentsSaveUrl()}
                        restrictions={{
                            maxFileSize: config.maxAttachmentEmailSize
                        }}
                    />
                </IntlProvider>
            </LocalizationProvider>
            {props.isLoadingAttachments &&
                <div className='sweet-loading spinnerClass'>
                    <ScaleLoader
                        width={5}
                        height={20}
                        radius={50}
                        color={'#000000'}
                        loading={props.isLoadingAttachments}
                    />
                </div>
            }
            <Box className="attachment-files">
                {!props.isLoadingAttachments && props.attachmentFiles != null && props.attachmentFiles.length > 0 && props.attachmentFiles.filter(x => x.status !== AttachmentStatus.deleted).map((file: AttachmentArgs, index: number) => {
                    return (
                        <Box key={index} display="flex" flexDirection="row" justifyContent="space-between" pr="5px">
                            <Box className="attachment-name" onClick={() => getAttachment(file.attachmentId)}>{file.name}</Box>
                            <Tooltip title="Supprimer" placement="bottom">
                                <Box style={{ cursor: 'pointer' }} onClick={() => props.handleDeleteAttachment(file.attachmentId, file.status)}>
                                    <FontAwesomeIcon className="fa-delete-attachment-icon" size="sm" icon={faTimes} />
                                </Box>
                            </Tooltip>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}