import { faSearch, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Checkbox, FormControlLabel, IconButton, Input, InputAdornment, Tooltip } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridColumnProps, GridColumnResizeEvent, GridHeaderSelectionChangeEvent, GridPageChangeEvent, GridSelectionChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ScaleLoader } from 'react-spinners';
import BusinessErrors from 'src/utils/BusinessErrors';
import { AppModule, LocalStorage } from 'src/utils/Storage';
import { DieselIndexLightModelExtended } from '../models/DieselIndexLightModelExtended';
import { DieselIndexLightModel } from '../services/dataContracts/queryStack/DieselIndexLightModel';
import { DieselIndexesReferentialApiClient } from '../services/DieselIndexesReferentialApiClient';

interface DieselIndexProperties {
    data: Array<DieselIndexLightModelExtended>,
    showAllLogisticsUnitsDieselIndexes: boolean,
    loading: boolean,
    inputSearchRef: React.MutableRefObject<HTMLInputElement>,
    inputSearchValue: string,
    gridHeight: number,
    gridPageSize: number,
    vehicleTypeGroups: Array<string>,
    ClearSearchText: () => void,
    HandleShowAllLogisticsUnitsDieselIndexes: () => void,
    HandleDieselIndexKeyPress: (searchText: string) => void,
    SelectionChange: (event: GridSelectionChangeEvent) => void,
    HeaderSelectionChange: (event: GridHeaderSelectionChangeEvent) => void,
    HandleResult: (errors: Array<string>) => void
}

interface IOrderIndexRef {
    index: number
}

const DieselIndexesGridName = 'dieselIndexes';
const ModuleKey = AppModule.DieselIndexesReferential;

export const DieselIndexComponent = (props: DieselIndexProperties) => {
    const [sort, setSort] = useState<SortDescriptor[]>([
        { field: 'transporterName', dir: 'asc' }
    ]);

    useEffect(() => {
        if (props.loading == true && props.data?.length > 0) {
            setSkip(0);
        }
    }, [props.loading]);

    const [skip, setSkip] = useState<number>(0);

    const [requestPending, setRequestPending] = useState<boolean>(false);

    const [showConfirmationRemoveDieselIndexes, setShowConfirmationRemoveDieselIndexes] = useState<boolean>(false);

    const dieselIndexesSelected = props.data.filter(x => x.selected === true);

    const HandleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const GetDieselIndexesColumnWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, DieselIndexesGridName, fieldName, defaultWidth);
    }

    const PageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip ? event.page.skip : 0);
    }

    const RemoveDieselIndexes = (): void => {
        setShowConfirmationRemoveDieselIndexes(true);
    }

    const HandleHideModal = (): void => {
        setShowConfirmationRemoveDieselIndexes(false);
    }

    const OnResizeHandler = (event: GridColumnResizeEvent): void => {
        let column: GridColumnProps = null;
        const parentColumns = (event.columns as Array<GridColumnProps>);

        for (const c of parentColumns) {
            const columnData = (c.children as Array<GridColumnProps>).find(x => x.id === event.targetColumnId);
            if (columnData !== undefined) {
                column = columnData;
                break;
            }
        }

        if (column !== null) {
            LocalStorage.SetGridColumnWidth(
                ModuleKey,
                DieselIndexesGridName,
                column.field,
                column.width);
        }
    }

    const GetDieselIndexOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, DieselIndexesGridName, fieldName, defaultIndex);
    }

    const HandleRemoveDieselIndexes = (): void => {
        const dieselIndexIdsSelected = dieselIndexesSelected ? dieselIndexesSelected.map(x => x.dieselIndexId) : [];
        if (dieselIndexIdsSelected.length > 0) {
            setRequestPending(true);
        }
        DieselIndexesReferentialApiClient.RemoveDieselIndexes(dieselIndexIdsSelected)
            .then(res => {
                const errors = BusinessErrors.Get(res.data);

                props.HandleResult(errors);
            })
            .finally(() => {
                HandleHideModal();
                setRequestPending(false);
            });
    }

    const gridData: DieselIndexLightModelExtended[] = orderBy(props.data, sort).slice(skip, skip + props.gridPageSize);

    const GetWeightingGridColumns = (defaultOrder: any): JSX.Element[] => {
        const weightings: Array<JSX.Element> = [];

        props.vehicleTypeGroups.map((vehicleTypeGroupId: string, index: number) => {
            weightings.push(<Column key={`grid-column-weighting-${index}`} field={`vehicleTypeGroup${vehicleTypeGroupId}`} orderIndex={GetDieselIndexOrderIndexColumn(vehicleTypeGroupId, defaultOrder.index++)}
                className='cell-center' headerClassName='cell-center' title={vehicleTypeGroupId} width={GetDieselIndexesColumnWidth(`${vehicleTypeGroupId}`, 100)} format="{0:p2}"
                cell={p => {
                    const dataItem: DieselIndexLightModel = p.dataItem;
                    const weighting = dataItem.weightings.find(x => x.vehicleTypeGroupId === vehicleTypeGroupId);
                    return (
                        <td className="cell-weighting">
                            {((weighting === undefined ? 0 : weighting.weightingValue) * 100).toPercentString()}
                        </td>);
                }
                } />);

        });

        return weightings;
    }

    const disabledBtnTrash = requestPending === true || dieselIndexesSelected == null || dieselIndexesSelected.length === 0;

    const defaultOrder: IOrderIndexRef = {
        index: 0
    };

    return (
        <>
            <Box className="diesel-index-component" pt={1} width="95%" display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row" className="diesel-index-component-header" width="100%" pb={1}>
                    <Box width="40%">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.showAllLogisticsUnitsDieselIndexes}
                                    onChange={() => props.HandleShowAllLogisticsUnitsDieselIndexes()}
                                    color="default"
                                />
                            }
                            label="Afficher toutes les associations"
                        />
                    </Box>
                    <Box width="60%" justifyContent="flex-end" display="flex" flexDirection="row" alignItems="center">
                        <Box pr={3}>
                            <Tooltip title="Supprimer le(s) indice(s) gazole sélectionné(s)" placement="bottom">
                                <Box>
                                    <IconButton size="small" className="btn-icon" aria-label="Add"
                                        disabled={disabledBtnTrash} onClick={RemoveDieselIndexes}>
                                        <FontAwesomeIcon className={disabledBtnTrash ? 'fa-trash-disabled' : 'fa-trash'} icon={faTrash} />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                        </Box>
                        <Input disableUnderline className={`input-search-diesel-indexes${props.inputSearchValue.length > 2 ? ' search-text-active' : ''}`} inputRef={props.inputSearchRef}
                            endAdornment={<>
                                <InputAdornment position="end" classes={
                                    {
                                        root: 'input-icon-close-root'
                                    }
                                }>
                                    <FontAwesomeIcon icon={faTimes} onClick={() => props.ClearSearchText()} />
                                </InputAdornment>
                                <InputAdornment position="end" classes={
                                    {
                                        root: 'input-icon-search-root'
                                    }
                                }>
                                    <FontAwesomeIcon icon={faSearch} className="icon-search" />
                                </InputAdornment>
                            </>}
                            id="input-search-text" placeholder="Rechercher..." onChange={(event) => props.HandleDieselIndexKeyPress(event.target.value)} />
                    </Box>
                </Box>
                <Box className="diesel-index-grid">
                    {
                        props.loading ? <div className='sweet-loading'>
                            <ScaleLoader
                                width={5}
                                height={20}
                                radius={50}
                                color={'#000000'}
                                loading={props.loading}
                            />
                        </div> :
                            <Grid
                                style={{ height: props.gridHeight }}
                                rowHeight={40}
                                data={gridData}
                                resizable
                                sortable
                                selectedField="selected"
                                onSelectionChange={props.SelectionChange}
                                onHeaderSelectionChange={props.HeaderSelectionChange}
                                sort={sort}
                                onSortChange={HandleSortChange}
                                onColumnResize={OnResizeHandler}
                                scrollable="virtual"
                                skip={skip}
                                pageSize={props.gridPageSize}
                                total={props.data.length}
                                onPageChange={PageChange}
                            >
                                <Column title=" " field="parentSelected" headerClassName="cell-disabled-sort" resizable={false} sortable={false} width={15} children={[
                                    <Column field="selected" className='cell-center' headerClassName='cell-center' resizable={true} width={25} orderIndex={GetDieselIndexOrderIndexColumn("selected", defaultOrder.index++)}
                                        headerSelectionValue={props.data.findIndex((dataItem: DieselIndexLightModelExtended) => dataItem.selected === false) === -1} />
                                ]} />
                                <Column title=" " field="parentTransporterName" headerClassName="cell-disabled-sort" resizable={false} sortable={false} children={[
                                    <Column field="transporterName" headerClassName='cell-center' title="Transporteur" orderIndex={GetDieselIndexOrderIndexColumn("transporterName", defaultOrder.index++)} width={GetDieselIndexesColumnWidth("transporterName", 250)} />
                                ]} />
                                <Column title="Indice" field="parentReferenceValue" headerClassName='cell-center cell-disabled-sort' sortable={false} resizable={false} children={[
                                    <Column field="referenceValueString" className='cell-center' headerClassName='cell-center' orderIndex={GetDieselIndexOrderIndexColumn("referenceValueString", defaultOrder.index++)} width={GetDieselIndexesColumnWidth("referenceValueString", 100)} title="C0" />
                                ]} />
                                <Column title="Pondération K" field="parentWeigthings" headerClassName='cell-center cell-disabled-sort' sortable={false} resizable={false} children={GetWeightingGridColumns(defaultOrder)} />
                                <Column title="Validité" field="parentDates" headerClassName='cell-center cell-disabled-sort' sortable={false} resizable={false} children={[
                                    <Column field="startDate" className='cell-center' headerClassName='cell-center' orderIndex={GetDieselIndexOrderIndexColumn("startDate", defaultOrder.index++)} width={GetDieselIndexesColumnWidth("startDate", 100)} title="Début" format="{0:dd-MM-yyyy}" />,
                                    <Column field="endDate" className='cell-center' headerClassName='cell-center' orderIndex={GetDieselIndexOrderIndexColumn("endDate", defaultOrder.index)} width={GetDieselIndexesColumnWidth("endDate", 100)} title="Fin" format="{0:dd-MM-yyyy}" />
                                ]} />
                            </Grid>
                    }
                </Box>
            </Box>
            <Modal show={showConfirmationRemoveDieselIndexes} onHide={HandleHideModal} className='mt-5'>
                <Modal.Header closeButton>
                    <Modal.Title>ATTENTION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Souhaitez-vous vraiment supprimer ce(s) modèle(s) d'indice Gazole pour ce(s) transporteur(s)?
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary" onClick={HandleHideModal}>
                        ANNULER
                    </Button>
                    <Button className="primary" onClick={HandleRemoveDieselIndexes}>
                        CONFIRMER
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}