import { Box, Tooltip } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderCellProps, GridPageChangeEvent, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import Utilities from '../../../../utils/Utilities';
import { CustomCheckboxCell } from '../../Common/CustomCheckboxCell';
import { CustomHeaderCellComponent } from '../../Common/CustomHeaderCellComponent';
import { SizingUtilities } from '../../SizingUtilities';
import { InternalContactLightModelExtended } from '../models/InternalContactLightModelExtended';
import { InternalContactLightModel } from '../services/dataContracts/queryStack/InternalContactLightModel';
import { LogisticsUnitChoiceOfContactsLightModel } from '../services/dataContracts/queryStack/LogisticsUnitChoiceOfContactsLightModel';
import { InternalContactsReferentialApiClient } from '../services/InternalContactsReferentialApiClient';
import { BeneficiaryAndRequesterChoiceCellComponent } from './BeneficiaryAndRequesterChoiceCellComponent';

interface InternalContactsComponentProps {
    userSelected: InternalContactLightModel,
    internalContacts: Array<InternalContactLightModelExtended>,
    internalContactChoicesLogisticsUnit: Array<LogisticsUnitChoiceOfContactsLightModel>,
    sort: SortDescriptor[],
    handleSortChange: (e: GridSortChangeEvent) => void,
    handleSortColumnChange: (sortItems: SortDescriptor[]) => void,
    handleUserSelected: (userItem: InternalContactLightModel) => void,
    handleUpdateInternalContacts: (internalContacts: Array<InternalContactLightModel>, internalContactChoicesLogisticsUnit: Array<LogisticsUnitChoiceOfContactsLightModel>) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const InternalContactsReferentialGridName = 'internalContactsReferential';
const ModuleKey = AppModule.InternalContactsReferential;

export const InternalContactsComponent = (props: InternalContactsComponentProps): JSX.Element => {

    const [skip, setSkip] = useState<number>(0);
    const [internalContactsChoicesDataArray, setInternalContactsChoicesDataArray] = useState<Array<InternalContactLightModelExtended>>([]);

    useEffect(() => {
        getInternalContactsChoicesData();
    }, []);

    useEffect(() => {
        getInternalContactsChoicesData();
    }, [props.internalContacts]);

    const forceUpdate = useForceUpdate();

    const getInternalContactsChoicesData = (): void => {
        const dataArray: Array<InternalContactLightModelExtended> = [];

        props.internalContacts.forEach((beneficiaryRequester: InternalContactLightModelExtended) => {
            const element: InternalContactLightModelExtended = { ...beneficiaryRequester };

            let checked_beneficiary = true;
            let checked_requester = true;
            let indeterminateAll = true;
            let numberOfChecked = 0;
            let numberOfUnchecked = 0;

            props.internalContactChoicesLogisticsUnit.forEach((logisticsUnit: LogisticsUnitChoiceOfContactsLightModel) => {
                element[`${logisticsUnit.logisticsUnitId}_beneficiary`] = logisticsUnit.chosenBeneficiaries.includes(element.contactId);
                element[`${logisticsUnit.logisticsUnitId}_requester`] = logisticsUnit.chosenRequesters.includes(element.contactId);
                if (!logisticsUnit.chosenBeneficiaries.includes(element.contactId)) {
                    checked_beneficiary = false;
                    numberOfUnchecked += 1;
                } else {
                    numberOfChecked += 1;
                }
                if (!logisticsUnit.chosenRequesters.includes(element.contactId)) {
                    checked_requester = false;
                    numberOfUnchecked += 1;
                } else {
                    numberOfChecked += 1;
                }
            });

            //*2 car il y'a bénéficiaire et demandeur
            if ((props.internalContactChoicesLogisticsUnit.length * 2) === numberOfUnchecked
                || (props.internalContactChoicesLogisticsUnit.length * 2) === numberOfChecked) {
                indeterminateAll = false;
            }

            element.isIndeterminateAll = indeterminateAll;
            element.isSelectedAll = checked_requester && checked_beneficiary ? true : (indeterminateAll ? null : false);
            element.selected = props.userSelected?.contactId == element.contactId ? true : false;
            dataArray.push(element);
        });

        setInternalContactsChoicesDataArray(dataArray);
    }

    const getInternalContactsWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, InternalContactsReferentialGridName, fieldName, columnWidth);
    }

    const getBeneficiaryRequesterGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, InternalContactsReferentialGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            InternalContactsReferentialGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, InternalContactsReferentialGridName, event.columns);
        forceUpdate();
    }

    const updateChoicesInternalContactsLogisticsUnit = (checked: boolean, logisticsUnitId: string, isBeneficiary: boolean, contactId: string): void => {
        InternalContactsReferentialApiClient.AddOrRemoveInternalContactChoice({
            contactId: contactId,
            logisticsUnitId: logisticsUnitId,
            shouldAdd: checked
        }, isBeneficiary)
            .then(() => {
                const itemFromArray: InternalContactLightModelExtended = internalContactsChoicesDataArray.find(x => x.contactId === contactId);
                const newInternalContactChoicesLogisticsUnit = [...props.internalContactChoicesLogisticsUnit];
                const choice = newInternalContactChoicesLogisticsUnit.find(x => x.logisticsUnitId === logisticsUnitId);

                if (isBeneficiary) {
                    itemFromArray[`${logisticsUnitId}_beneficiary`] = checked;
                    if(checked && choice.chosenBeneficiaries.findIndex(x => x === itemFromArray.contactId) === -1) {                        
                        choice.chosenBeneficiaries.push(itemFromArray.contactId);
                    }
                    else if(!checked && choice.chosenBeneficiaries.findIndex(x => x === itemFromArray.contactId) !== -1) {
                        choice.chosenBeneficiaries = choice.chosenBeneficiaries.filter(x => x !== itemFromArray.contactId);
                    }
                }
                else {
                    itemFromArray[`${logisticsUnitId}_requester`] = checked;

                    if(checked && choice.chosenRequesters.findIndex(x => x === itemFromArray.contactId) === -1) {                        
                        choice.chosenRequesters.push(itemFromArray.contactId);
                    }
                    else if(!checked && choice.chosenRequesters.findIndex(x => x === itemFromArray.contactId) !== -1) {
                        choice.chosenRequesters = choice.chosenRequesters.filter(x => x !== itemFromArray.contactId);
                    }
                }
                let checked_beneficiary = true;
                let checked_requester = true;
                let indeterminateAll = true;
                let numberOfChecked = 0;
                let numberOfUnchecked = 0;
                props.internalContactChoicesLogisticsUnit.forEach((logisticsUnit: LogisticsUnitChoiceOfContactsLightModel) => {
                    if (!itemFromArray[`${logisticsUnit.logisticsUnitId}_beneficiary`]) {
                        checked_beneficiary = false;
                        numberOfUnchecked += 1;
                    } else {
                        numberOfChecked += 1;
                    }

                    if (!itemFromArray[`${logisticsUnit.logisticsUnitId}_requester`]) {
                        checked_requester = false;
                        numberOfUnchecked += 1;
                    } else {
                        numberOfChecked += 1;
                    }
                });

                //*2 car il y'a bénéficiaire et demandeur
                if ((props.internalContactChoicesLogisticsUnit.length * 2) === numberOfUnchecked
                    || (props.internalContactChoicesLogisticsUnit.length * 2) === numberOfChecked) {
                    indeterminateAll = false;
                }

                itemFromArray.isSelectedAll = checked_requester && checked_beneficiary ? true : (indeterminateAll ? null : false);
                itemFromArray.isIndeterminateAll = indeterminateAll;
                
                props.handleUpdateInternalContacts(internalContactsChoicesDataArray, newInternalContactChoicesLogisticsUnit);
            });
    }

    const updateChoicesInternalContactsAllLogisticsUnits = (checked: boolean, contactId: string): void => {
        const logisticsUnitIds: Array<string> = [];
        props.internalContactChoicesLogisticsUnit.forEach((element: LogisticsUnitChoiceOfContactsLightModel) => {
            logisticsUnitIds.push(element.logisticsUnitId);
        });

        InternalContactsReferentialApiClient.SetOrUnsetInternalContactChoiceToAllLogisticsUnits({
            contactId: contactId,
            shouldAdd: checked,
            logisticsUnitIds: logisticsUnitIds
        })
            .then(() => {
                const itemFromArray: InternalContactLightModelExtended = internalContactsChoicesDataArray.find(x => x.contactId === contactId);
                const newInternalContactChoicesLogisticsUnit = [...props.internalContactChoicesLogisticsUnit];
                
                logisticsUnitIds.forEach((logisticsUnitId: string) => {
                    itemFromArray[`${logisticsUnitId}_beneficiary`] = checked;
                    itemFromArray[`${logisticsUnitId}_requester`] = checked;

                    const choice = newInternalContactChoicesLogisticsUnit.find(x => x.logisticsUnitId === logisticsUnitId);
                    if(checked) {
                        if(choice.chosenBeneficiaries.findIndex(x => x === itemFromArray.contactId) === -1)                        
                            choice.chosenBeneficiaries.push(itemFromArray.contactId);

                        if(choice.chosenRequesters.findIndex(x => x === itemFromArray.contactId) === -1)                        
                            choice.chosenRequesters.push(itemFromArray.contactId);
                        
                    }
                    else if(!checked) {
                        if(choice.chosenBeneficiaries.findIndex(x => x === itemFromArray.contactId) !== -1)
                            choice.chosenBeneficiaries = choice.chosenBeneficiaries.filter(x => x !== itemFromArray.contactId);
                        
                        if(choice.chosenRequesters.findIndex(x => x === itemFromArray.contactId) !== -1)
                            choice.chosenRequesters = choice.chosenRequesters.filter(x => x !== itemFromArray.contactId);
                    }

                });

                itemFromArray.isSelectedAll = checked;
                itemFromArray.isIndeterminateAll = false;

                props.handleUpdateInternalContacts(internalContactsChoicesDataArray, newInternalContactChoicesLogisticsUnit);
            });
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const handleRowClick = (e: GridRowClickEvent): void => {
        if(e.dataItem.contactId === props.userSelected?.contactId){
            internalContactsChoicesDataArray.find(x => x.contactId == e.dataItem.contactId).selected = false;
        }
        else{
            internalContactsChoicesDataArray.forEach(x => x.selected = false);
            internalContactsChoicesDataArray.find(x => x.contactId == e.dataItem.contactId).selected = true;
        }
        props.handleUserSelected(e.dataItem);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.internalContacts.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: InternalContactLightModelExtended[] = orderBy(internalContactsChoicesDataArray, props.sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="colas_customers-contact-grid">
            <Grid
                className="internal-contact-grid"
                data={gridData}
                sortable
                reorderable
                resizable
                selectedField="selected"
                sort={props.sort}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={props.handleSortChange}
                onRowClick={handleRowClick}
                editField="inEdit"
                rowHeight={rowHeight}
                scrollable="virtual"
                skip={skip}
                total={totalGrid}
                pageSize={gridPageSize}
                onPageChange={pageChange}
                style={gridStyle}
            >
                <GridColumn field="isSelectedAll" orderIndex={getBeneficiaryRequesterGridOrderIndexColumn("isSelectedAll", 0)} width={getInternalContactsWidth("isSelectedAll", 100)} title="Sélection"
                    cell={(props: GridCellProps) =>
                        <CustomCheckboxCell
                            {...props}
                            keyName="contactId"
                            updateChoicesAllLogisticsUnits={updateChoicesInternalContactsAllLogisticsUnits}
                        />}
                    headerCell={(_props: GridHeaderCellProps) =>
                        <CustomHeaderCellComponent tooltip="Activer/Désactiver un bénéficiaire/demandeur interne pour toutes les zones logistiques" title={_props.title} field="isSelectedAll" sort={props.sort}
                            handleSortColumnChange={props.handleSortColumnChange} />
                    }
                />
                <GridColumn field="lastName" orderIndex={getBeneficiaryRequesterGridOrderIndexColumn("lastName", 1)} width={getInternalContactsWidth("lastName", 120)} title="Nom" />
                <GridColumn field="firstName" orderIndex={getBeneficiaryRequesterGridOrderIndexColumn("firstName", 2)} width={getInternalContactsWidth("firstName", 120)} title="Prénom" />
                <GridColumn field="roleId" orderIndex={getBeneficiaryRequesterGridOrderIndexColumn("roleId", 3)} width={getInternalContactsWidth("roleId", 120)} title="Rôle" />
                <GridColumn field="phoneNumber" orderIndex={getBeneficiaryRequesterGridOrderIndexColumn("phoneNumber", 4)} width={getInternalContactsWidth("phoneNumber", 120)} title="Téléphone"
                    cell={(props: GridCellProps) => <td className="phone-number-ref">{props.dataItem?.phoneNumber ? <a href={`callTo:${Utilities.formatPhoneNumber(props.dataItem.phoneNumber)}`}>{Utilities.formatPhoneNumber(props.dataItem.phoneNumber)}</a> : ''}</td>}
                />
                <GridColumn field="contactId" orderIndex={getBeneficiaryRequesterGridOrderIndexColumn("contactId", 5)} width={getInternalContactsWidth("contactId", 120)} title="Id du contact" />
                {props.internalContactChoicesLogisticsUnit.map((logisticsUnit: LogisticsUnitChoiceOfContactsLightModel, i: number) =>
                    <GridColumn headerClassName="logistics-unit" key={i} field={logisticsUnit.logisticsUnitId} orderIndex={getBeneficiaryRequesterGridOrderIndexColumn(logisticsUnit.logisticsUnitId, 6 + i)} minResizableWidth={240} width={getInternalContactsWidth(logisticsUnit.logisticsUnitId, 240)} title={logisticsUnit.label}
                        cell={(props: GridCellProps) =>
                            <BeneficiaryAndRequesterChoiceCellComponent
                                {...props}
                                updateChoicesLogisticsUnit={updateChoicesInternalContactsLogisticsUnit}
                            />}
                        headerCell={(_props: GridHeaderCellProps) =>
                            <Tooltip title="Activer/Désactiver ue bénéficiaire/demandeur interne pour la zone logistique" placement="top">
                                <span>{_props.title}</span>
                            </Tooltip>
                        }
                    />
                )}
            </Grid>
        </Box>
    );
}
