import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import React from 'react';
import { VehicleItemModel } from '../models/VehicleItemModel';
import { VehicleInternalExternalDetailComponent } from './VehicleInternalExternalDetailComponent';

interface DailyVehicleItemComponentProps {
    style: React.CSSProperties,
    dataItem: VehicleItemModel,
    handleAssignDailyPlanningVehicle?: (dailyPlanningVehicleId: string, vehicleId: number, licencePlate: string, transporterId: string, vehicleTypeId: string, vehicleTypeGroupId: string) => void,
    handleVehicleHiddenStateChange?: (planningVehicleId: string, vehicleId: number) => void
}

export const DailyVehicleItemComponent = (props: DailyVehicleItemComponentProps): JSX.Element => {

    const { dataItem, style } = props;

    const isContractualPurchasePriceVehicle: boolean = dataItem.contractualPurchasePriceId !== null;

    return (
        <Box style={style} display="flex" flexDirection="row" className="vehicle-card" onDoubleClick={() => props.handleAssignDailyPlanningVehicle(dataItem.planningVehicleId, dataItem.vehicleId, dataItem.licencePlate, dataItem.transporterId, dataItem.expectedVehicleTypeId, dataItem.vehicleTypeGroupId)}>
            <Box className="header-vehicle header-daily"></Box>
            {isContractualPurchasePriceVehicle ? <div className="header-vehicle spacing-left header-contracted"></div> : ''}
            <Box className="hover-vehicle">
                <VehicleInternalExternalDetailComponent isDailyVehicle={true} transporterId={dataItem.transporterId} transporterLabel={dataItem.transporterLabel}
                    firstBeneficiaryName={dataItem.firstBeneficiaryName} vehicleTypeIsEnabled={dataItem.vehicleTypeIsEnabled} vehicleTypeLabel={dataItem.vehicleTypeLabel}
                    licencePlate={dataItem.licencePlate} driverId={dataItem.driverId}
                    equipmentId={dataItem.equipmentId} driverFullName={dataItem.driverFullName}
                    firstDeliveryAddressCity={dataItem.firstDeliveryAddressCity} receiverSiteLabel={dataItem.receiverSiteLabel}
                    isContractualPurchasePriceVehicle={isContractualPurchasePriceVehicle} contractNumber={dataItem.contractNumber} priceKind={dataItem.priceKind}
                    remainingNumberOfDays={dataItem.remainingNumberOfDays} remainingNumberOfWorkDays={dataItem.remainingNumberOfWorkDays}/>
            </Box>
            <Box className="remove-daily-vehicle" onClick={() => props.handleVehicleHiddenStateChange(dataItem.planningVehicleId, dataItem.vehicleId)}>
                <FontAwesomeIcon icon={faTimes} className="close-icon" />
            </Box>
        </Box>
    )
}
