import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Checkbox, FormControlLabel, Input, InputAdornment } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { UploadOnBeforeUploadEvent, UploadOnStatusChangeEvent, UploadResponse } from '@progress/kendo-react-upload';
import React from 'react';
import { UploadComponent } from 'src/shared/components/Common/UploadComponent';
import { downloadFileFromBlob, getFileName } from 'src/utils/DownloadFile';
import { SelectedCustomerModel } from '../models/SelectedCustomerModel';
import { CustomerContactsReferentialApiClient } from '../services/CustomerContactsReferentialApiClient';
import { BeneficiaryRequesterChoicesExportRequestArgs } from '../services/dataContracts/controller/BeneficiaryRequesterChoicesExportRequestArgs';
import { CustomerChoicesExportRequestArgs } from '../services/dataContracts/controller/CustomerChoicesExportRequestArgs';

interface HeaderContentComponentProps {
    inputSearchExternalContactsValue: string,
    inputSearchExternalContactsRef: React.RefObject<HTMLInputElement>,
    selectedCustomer: SelectedCustomerModel,
    isGlobalSearch: boolean,
    sort: SortDescriptor[],
    loadingContactsOfCustomer: boolean,
    handleExternalContactsKeyPress: (text: string) => void,
    handleClearSearchText: () => void,
    handlerAfterUpload: (uploadResponse: UploadResponse) => void,
    handlerBeforeUpload: (event: UploadOnBeforeUploadEvent) => void,
    handleIsGlobalSearchChanged: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleAddContactDialogClick: () => void,
    handleEditContactDialogClick: () => void
}

export const HeaderContentComponent = (props: HeaderContentComponentProps): JSX.Element => {
    const exportToExcel = (): void => {
        const customerChoicesExportRequestArgs: CustomerChoicesExportRequestArgs = {
            searchText: props.inputSearchExternalContactsValue,
            sortField: props.sort.length > 0 ? props.sort[0].field : null,
            sortDirection: props.sort.length > 0 ? props.sort[0].dir : null,
            isGlobalSearch: props.isGlobalSearch
        };

        if (!props.selectedCustomer.customerMdmId) {
            CustomerContactsReferentialApiClient.ExportCustomersChoices(customerChoicesExportRequestArgs)
                .then(response => {
                    const fileName: string = getFileName(response);
                    const blob: Blob = new Blob([response.data]);
                    downloadFileFromBlob(blob, fileName);
                });
        }
        else {
            const beneficiaryRequesterChoicesExportRequestArgs: BeneficiaryRequesterChoicesExportRequestArgs = {
                customerMdmId: props.selectedCustomer.customerMdmId,
                name: props.selectedCustomer.name,
                sortField: props.sort.length > 0 ? props.sort[0].field : null,
                sortDirection: props.sort.length > 0 ? props.sort[0].dir : null
            };

            CustomerContactsReferentialApiClient.ExportCustomerBeneficiaryRequesterChoices(customerChoicesExportRequestArgs, beneficiaryRequesterChoicesExportRequestArgs)
                .then(response => {
                    const fileNameTransporters: string = getFileName(response[0]);
                    const fileNamePurchasePrices: string = getFileName(response[1]);
                    const blobTransporters: Blob = new Blob([response[0].data]);
                    const blobPurchasePrices: Blob = new Blob([response[1].data]);
                    downloadFileFromBlob(blobTransporters, fileNameTransporters);
                    downloadFileFromBlob(blobPurchasePrices, fileNamePurchasePrices);
                });
        }
    }

    const onUploadStatusChange = (event: UploadOnStatusChangeEvent): void => {
        const res = event.response;
        if (res?.status == 200) {
            props.handlerAfterUpload(event.response);
        }
    }

    const inputSearchExternalContactsClassName: string = props.inputSearchExternalContactsValue.length > 2 ? 'search-text-active' : '';
    const uploadSaveUrl: string = CustomerContactsReferentialApiClient.GetImportUrl();
    const checkboxInputProps: React.InputHTMLAttributes<HTMLInputElement> = { 'aria-label': 'primary checkbox' };

    return (
        <Box display="flex" flexDirection="row" flex="wrap" justifyContent="start" alignItems="center" className="header-content">
            <Box>
                <Input disableUnderline className={inputSearchExternalContactsClassName} inputRef={props.inputSearchExternalContactsRef}
                    startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                    endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => props.handleClearSearchText()} /></InputAdornment>}
                    placeholder="Rechercher ..." onChange={(event) => props.handleExternalContactsKeyPress(event.target.value)} />
            </Box>
            <Box>
                <FormControlLabel
                    id="global-search-customers-referential"
                    control={
                        <Checkbox
                            checked={props.isGlobalSearch}
                            disabled={props.inputSearchExternalContactsValue.length < 3}
                            onChange={(e) => props.handleIsGlobalSearchChanged(e)}
                            color="primary"
                            inputProps={checkboxInputProps}
                        />
                    }
                    label="Recherche globale"
                    labelPlacement="start"
                />
            </Box>
            <Box ml={'10px'}>
                <Button variant="contained" color="primary" title="Ajouter" onClick={props.handleAddContactDialogClick}>
                    Ajouter
                </Button>
            </Box>
            <Box ml={'10px'}>
                <Button variant="contained" disabled={!props.selectedCustomer.customerMdmId && !props.loadingContactsOfCustomer} color="primary" title="Modifier" onClick={props.handleEditContactDialogClick}>
                    Modifier
                </Button>
            </Box>
            <Box ml={'10px'}>
                <Button variant="contained" color="primary" title="Exporter sous format Excel" onClick={exportToExcel}>
                    Exporter
                </Button>
            </Box>
            <Box ml={'10px'}>
                <UploadComponent
                    multiple={false}
                    showFileList={false}
                    restrictions={{
                        allowedExtensions: ['.xls', '.xlsx']
                    }}
                    defaultFiles={[]}
                    withCredentials={false}
                    saveUrl={uploadSaveUrl}
                    onBeforeUpload={props.handlerBeforeUpload}
                    onStatusChange={onUploadStatusChange}
                />
            </Box>
        </Box>
    );
}
