import { Box, Button, Radio } from '@mui/material';
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React from 'react';
import { WorkAgencyCandidateToAdd } from '../../services/dataContracts/controller/WorkAgencyCandidateToAdd';
import { WorkAgenciesReferentialApiClient } from '../../services/WorkAgenciesReferentialApiClient';

interface SelectWorkAgencyStepComponentProperties {
    workAgencyChoices: WorkAgencyCandidateToAdd,
    handleSearchInReferentialGrid: (agencyId: string) => void,
    confirmAddWorkAgency: (agency: WorkAgencyCandidateToAdd) => void,
    handlePreviousStep: () => void
}

export const SelectWorkAgencyStepComponent = (props: SelectWorkAgencyStepComponentProperties): JSX.Element => {

    const handleClikAddWorkAgency = (): void => {
        WorkAgenciesReferentialApiClient.WorkAgencyExists(props.workAgencyChoices.agencyId)
            .then((res) => {
                if (res.data === true)
                    props.handleSearchInReferentialGrid(props.workAgencyChoices.agencyId);
                else
                    props.confirmAddWorkAgency(props.workAgencyChoices);
            });
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box className="work-agency-choices">
                <LocalizationProvider language="fr-FR">
                    <IntlProvider locale="fr" >
                        <Grid
                            data={props.workAgencyChoices ? [props.workAgencyChoices] : []}
                            className="work-agency-choices-grid"
                            rowHeight={40}
                        >
                            <GridColumn field="selected" title="Sélection" width={60}
                                cell={(props: GridCellProps) => <td>
                                    <Radio
                                        checked={true}
                                        color="default"
                                    />
                                </td>}
                            />
                            <GridColumn field="agencyId" title="Id agence" width={150} />
                            <GridColumn field="label" title="Libellé agence" width={350} />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                <Button variant="contained" title="Retour" className="previous-button" onClick={props.handlePreviousStep}>
                    Retour
                </Button>
                <Button variant="contained" color="primary" title="Ajouter" onClick={handleClikAddWorkAgency} >
                    Confirmer
                </Button>
            </Box>
        </Box>
    );
}
