import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridRowProps, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { PriceCell } from 'src/shared/Quotation/services/dataContracts/admin/PriceCell';
import { PriceGrid } from 'src/shared/Quotation/services/dataContracts/admin/PriceGrid';
import { TripDurationLimit } from 'src/shared/Quotation/services/dataContracts/admin/TripDurationLimit';
import { TripDurationPricesRow } from 'src/shared/Quotation/services/dataContracts/admin/TripDurationPricesRow';
import { PriceGridKind } from 'src/shared/Quotation/services/dataContracts/PriceGridKind';
import { AppModule, LocalStorage } from 'src/utils/Storage';
import { EditedFieldModel } from '../models/EditedFieldModel';
import { PerTurnOrTonPeriodRowModel } from '../models/PerTurnOrTonPeriodRowModel';
import { SizingUtilities } from '../SizingUtilities';
import { CustomPriceCellComponent } from './CustomPriceCellComponent';

interface PerTurnOrTonGridProps {
    priceGridData: PriceGrid,
    hasMaintenanceLock: boolean,
    handlePriceGridParsed: (data: Array<PerTurnOrTonPeriodRowModel>) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const ModuleKey = AppModule.QuotationPricesReferential;

export const PerTurnOrTonGridComponent = (props: PerTurnOrTonGridProps) => {
    let preventExit: boolean;
    let preventExitTimeout: ReturnType<typeof setTimeout>;
    let blurTimeout: ReturnType<typeof setTimeout>;

    const [gridDataArray, setGridDataArray] = useState<Array<PerTurnOrTonPeriodRowModel>>([]);
    const [vehicleTypeGroups, setVehicleTypeGroups] = useState<Array<string>>([]);

    const [sort, setSort] = useState<Array<SortDescriptor>>([]);
    const [editField, setEditField] = useState<EditedFieldModel>(null);

    const { priceGridData, hasMaintenanceLock } = props;

    const priceGridKind = priceGridData?.priceGridKind ?? "" as PriceGridKind;

    useEffect(() => {
        if (priceGridData?.id) {
            prepareData();
        }
    }, [priceGridData]);

    const prepareData = (): void => {
        const dataArray: Array<PerTurnOrTonPeriodRowModel> = [];

        if ([PriceGridKind.perTurn, PriceGridKind.perTon].indexOf(props.priceGridData?.priceGridKind) !== -1) {
            const perTurnOrTonGrid = priceGridKind == PriceGridKind.perTurn
                ? props.priceGridData.perTurnGrid
                : props.priceGridData.perTonGrid;

            perTurnOrTonGrid.tripDurationLimits?.map((t: TripDurationLimit, index: number) => {

                //Dans le cas d'une ligne sans temps de trajet, on n'affiche pas la ligne
                if (t.durationLimit) {
                    let element: PerTurnOrTonPeriodRowModel = {
                        id: t.id,
                        durationLimit: t.durationLimit,
                        priceGridKind: priceGridKind,
                        changes: new Map<string, number>()
                    };

                    const tripDurationPricesRow: TripDurationPricesRow = perTurnOrTonGrid.tripDurationPricesRows.find(td => td.tripDurationLimitId === t.id);

                    //Génération des colonnes dynamiques
                    if (tripDurationPricesRow) {
                        if (index === 0) {
                            const firstPricesRow = perTurnOrTonGrid.tripDurationPricesRows[0];

                            const vehicleTypeGroups = firstPricesRow.pricesRow?.map(x => x.vehicleTypeGroup);
                            const uniqVehicleTypeGroups = vehicleTypeGroups?.length > 0 ? uniq(vehicleTypeGroups) : [];

                            setVehicleTypeGroups(uniqVehicleTypeGroups);
                        }

                        (tripDurationPricesRow.pricesRow ?? []).map(priceRow => {
                            element[priceRow.vehicleTypeGroup] = priceRow.price;
                            element.changes.set(priceRow.vehicleTypeGroup, priceRow.price);
                        });
                    }

                    dataArray.push(element);
                }
            });

            if (perTurnOrTonGrid.minPricesRow?.length > 0) {
                const id = "Prix minimum";

                let element: PerTurnOrTonPeriodRowModel = {
                    id: id,
                    priceGridKind,
                    changes: new Map<string, number>()
                };

                perTurnOrTonGrid.minPricesRow.map((row: PriceCell) => {
                    element[row.vehicleTypeGroup] = row.price;
                    element.changes.set(row.vehicleTypeGroup, row.price);
                });

                dataArray.push(element);
            }

            if (perTurnOrTonGrid.maxPricesRow?.length > 0) {
                const id = "Prix maximum";

                let element: PerTurnOrTonPeriodRowModel = {
                    id: id,
                    priceGridKind,
                    changes: new Map<string, number>()
                };

                perTurnOrTonGrid.maxPricesRow.map((row: PriceCell) => {
                    element[row.vehicleTypeGroup] = row.price;
                    element.changes.set(row.vehicleTypeGroup, row.price);
                });

                dataArray.push(element);
            }
        }

        setGridDataArray(dataArray);
        props.handlePriceGridParsed(dataArray);
    }

    const [skip, setSkip] = useState<number>(0);

    const forceUpdate = useForceUpdate();

    const getTurnOrTonWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, priceGridKind, fieldName, columnWidth);
    }

    const getTurnOrTonGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, priceGridKind, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            priceGridKind,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, priceGridKind, event.columns);
        forceUpdate();
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = gridDataArray.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    //ODA Voir mutualisation avec l'autre composant 
    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, dataItem: GridRowProps): React.ReactElement<HTMLTableRowElement> => {
        const trProps = {
            ...trElement.props,
            onMouseDown: () => {
                preventExit = true;
                clearTimeout(preventExitTimeout);
                preventExitTimeout = setTimeout(() => { preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(blurTimeout);
                if (!preventExit) {
                    blurTimeout = setTimeout(() => { exitEdit(); });
                }
            },
            onFocus: () => { clearTimeout(blurTimeout); }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as any);
    }

    const cellRender = (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps): React.ReactElement<HTMLTableCellElement> => {
        const dataItem: PerTurnOrTonPeriodRowModel = cellProps.dataItem;
        const field: string = cellProps.field;
        const additionalProps = (dataItem.inEdit && (cellProps.field === dataItem.inEdit)) ?
            {
                ref: (td: any) => {
                    const input = td && td.querySelector('input');
                    if (!input || (input === document.activeElement)) { return; }
                    if (input.type === 'checkbox') {
                        input.focus();
                    } else {
                        input.select();
                    }
                }
            } : {
                onClick: () => { enterEdit(dataItem, field); }
            };
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children as any);
    }

    const enterEdit = (dataItem: PerTurnOrTonPeriodRowModel, field: string) => {
        if (dataItem.inEdit && field === editField.field && editField.id === dataItem.id)
            return;

        exitEdit();
        dataItem.inEdit = field;
        setEditField({
            field: field,
            id: dataItem.id
        });
    };

    const exitEdit = () => {
        let wasChanged = false;

        gridDataArray.forEach(dataItem => {
            if (dataItem.inEdit !== undefined) {
                wasChanged = true;
            }
            dataItem.inEdit = undefined;
        });

        setEditField(null);

        if (!wasChanged)
            return;

        props.handlePriceGridParsed(gridDataArray);
    };

    const gridData = orderBy(gridDataArray, sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="grid-content">
            <Grid
                className="grid"
                data={gridData}
                sortable
                reorderable
                resizable
                sort={sort}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={handleSortChange}
                editField="inEdit"
                rowHeight={rowHeight}
                total={totalGrid}
                pageSize={gridPageSize}
                onPageChange={pageChange}
                rowRender={rowRender}
                cellRender={cellRender}
                style={gridStyle}
            >
                <GridColumn field="empty" width={50} title=" " sortable={false} reorderable={false} editable={false} />
                <GridColumn field="id" orderIndex={getTurnOrTonGridOrderIndexColumn("id", 1)} width={getTurnOrTonWidth("id", 120)} title="Id" editable={false} />
                <GridColumn field="durationLimit" orderIndex={getTurnOrTonGridOrderIndexColumn("durationLimit", 2)} width={getTurnOrTonWidth("durationLimit", 120)} title="Durée de trajet" editable={false} />
                {vehicleTypeGroups.map((vehicleTypeGroup: string, i: number) =>
                    <GridColumn key={`vehicleTypeGroup-${i}`} field={vehicleTypeGroup}
                        orderIndex={getTurnOrTonGridOrderIndexColumn(vehicleTypeGroup, 3 + i)} width={getTurnOrTonWidth(vehicleTypeGroup, 150)} title={vehicleTypeGroup}
                        cell={(props: GridCellProps) => <CustomPriceCellComponent isReadOnly={hasMaintenanceLock} {...props} />} />
                )}
            </Grid>
        </Box>
    )
}