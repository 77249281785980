import { Box, Button } from '@mui/material';
import React from 'react';
import { CoatingApplicationModeEnum } from '../models/CoatingApplicationModeEnum';
import { Level3SegmentationModel } from '../models/Level3SegmentationModel';
import { SelectInputItemModel } from '../models/SelectInputItemModel';
import { NumericTextInput, SelectInput } from './CommonComponents';

interface JobSiteAndProductContentProps {
    coatingApplicationMode: CoatingApplicationModeEnum,
    isJobSiteVehicleServiceKind: boolean,
    level3Segmentation: Level3SegmentationModel,
    productLabel: string,
    quantity: number,
    isNightWork: boolean,
    setProductLabel: (value: string) => void,
    handleQuantityChanged: (value: number) => void,
    setNightWork: (value: boolean) => void
    handleCoatingApplicationModeChanged: (coatingApplicationMode: CoatingApplicationModeEnum) => void,
    handleLevel3SegmentationChanged: (e: SelectInputItemModel) => void
}

export const JobSiteAndProductContent = (props: JobSiteAndProductContentProps): JSX.Element => {

    const { coatingApplicationMode, isJobSiteVehicleServiceKind, level3Segmentation, productLabel, quantity, isNightWork } = props;

    return (
        <Box display="flex" flexDirection="column" className="section">
            <Box display="flex" flexDirection="row" width="100%" justifyContent="flex-start" flexWrap="wrap">
                <Box display="flex" flexDirection="column" mr={"20px"}>
                    <Box className={(coatingApplicationMode === null && !isJobSiteVehicleServiceKind) ? "required-label" : "label"}>Type de chantier</Box>
                    <Box display="flex" flexDirection="row">
                        <Button className={coatingApplicationMode === CoatingApplicationModeEnum.Mixed ? "btn-selected" : "btn-enabled"}
                            onClick={() => props.handleCoatingApplicationModeChanged(CoatingApplicationModeEnum.Mixed)}>Mixte</Button>
                        <Button className={coatingApplicationMode === CoatingApplicationModeEnum.Manual ? "btn-selected" : "btn-enabled"}
                            onClick={() => props.handleCoatingApplicationModeChanged(CoatingApplicationModeEnum.Manual)}>Manuel</Button>
                        <Button className={coatingApplicationMode === CoatingApplicationModeEnum.Mechanical ? "btn-selected" : "btn-enabled"}
                            onClick={() => props.handleCoatingApplicationModeChanged(CoatingApplicationModeEnum.Mechanical)}>Mécanique</Button>
                        <Button className={coatingApplicationMode === CoatingApplicationModeEnum.NotApplicable ? "btn-selected" : "btn-enabled"}
                            onClick={() => props.handleCoatingApplicationModeChanged(CoatingApplicationModeEnum.NotApplicable)}>Blanc</Button>
                    </Box>
                </Box>
                <SelectInput title="Type de produit"
                    titleClassName={(!level3Segmentation.level3Seg && !isJobSiteVehicleServiceKind) ? "required-label" : "label"}
                    options={level3Segmentation.level3SegList}
                    value={level3Segmentation.level3Seg}
                    handleChange={(e) => props.handleLevel3SegmentationChanged(e)}
                    required={(!level3Segmentation.level3Seg && !isJobSiteVehicleServiceKind)}
                />
                <Box display="flex" flexDirection="column" mr={"20px"}>
                    <Box className="label">Produit à transporter</Box>
                    <Box display="flex" flexDirection="row">
                        <input name="Colas.Lorie.TransportFlow.Product.Code" type="text" className="input-text product"
                            value={productLabel === null ? "" : productLabel}
                            onChange={(e) => { props.setProductLabel(e.target.value) }}
                            disabled={isJobSiteVehicleServiceKind}
                        />
                    </Box>
                </Box>
                <NumericTextInput title="Quantité demandée"
                    titleClassName={(!isJobSiteVehicleServiceKind && quantity === null) ? "required-label" : "label"}
                    className={isJobSiteVehicleServiceKind ? "container-disabled ton" : (quantity === null ? "container-required ton" : "ton")}
                    handleChange={(e) => { props.handleQuantityChanged(e.value) }}
                    width="105px"
                    value={quantity}
                    disabled={isJobSiteVehicleServiceKind}
                    iconClassName="ton-icon"
                    iconText="t"
                />
                <Box display="flex" flexDirection="column" mr={"20px"}>
                    <Box className="label">Travail de nuit</Box>
                    <Box display="flex" flexDirection="row">
                        <Button className={isNightWork ? "btn-selected" : "btn-enabled"} onClick={() => { props.setNightWork(true) }}>Oui</Button>
                        <Button className={isNightWork ? "btn-enabled" : "btn-selected"} onClick={() => { props.setNightWork(false) }}>Non</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
