import React from "react";

const getViewBox = (name) => {
    switch (name) {
        case "logoLorie":
            return "0 0 1801.590626 703.800841";
        case "camion":
            return "0 0 240.000000 116.926655";
        case "semi":
            return "0 0 52.405 52.405";
        default:
            return "0 0 1801.590626 703.800841";
    }
};

const getSvgContent = (name, color) => {
    switch (name) {
        case "logoLorie":
            return (
                <g transform="translate(-370,-905) scale(2.83,2.83)"
                fill="#1D1D1B" stroke="none">
                    <path fill="#FFED00" d="M742.16,380.23L512.9,150.98c-35.38-35.38-92.74-35.38-128.13,0L155.52,380.23
                        c-35.38,35.38-35.38,92.74,0,128.13l229.26,229.26c35.38,35.38,92.74,35.38,128.13,0l229.26-229.26
                        C777.54,472.98,777.54,415.61,742.16,380.23z"/>
                    <polygon fill="#1D1D1B" points="637.8,414.24 615.06,390.12 615.06,547.68 637.8,571.55 "/>
                    <polygon fill="#1D1D1B" points="703.78,449.03 620.8,449.03 615.06,390.12 703.78,390.12 675.82,419.57 "/>
                    <g>
                        <g>
                            <path fill="#1D1D1B" d="M364.64,373.11c1.61,0,3.16,0.27,4.61,0.74v-8.64c0-1.13,0.92-2.05,2.05-2.05h15.89c0.84,0,1.6,0.52,1.91,1.3
                                l3.42,8.66h14.82l-7.12-18.42c-0.72-1.86-2.5-3.08-4.5-3.08h-47.86c-2.66,0-4.82,2.16-4.82,4.82v16.68H364.64z"/>
                            <path fill="#1D1D1B" d="M216.87,388.15c0-8.29,6.75-15.04,15.04-15.04h37.91h56.9h8.78v-16.68c0-2.66-2.16-4.82-4.82-4.82h-34.39
                                h-51.6h-31.55c-2.66,0-4.82,2.16-4.82,4.82v64.5c0,2.66,2.16,4.82,4.82,4.82h3.73V388.15z"/>
                        </g>
                        <g>
                            <g>
                                <circle fill="#1D1D1B" cx="375.28" cy="465.04" r="16.28"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path fill="#1D1D1B" d="M489.43,459.61l-12.71-32.9c-0.86-2.22-2.99-3.68-5.36-3.68h-57.1c-3.18,0-5.75,2.57-5.75,5.75v41.59
                                    c0,2.49-2.02,4.51-4.51,4.51s-4.51-2.02-4.51-4.51v-41.59c0-3.18-2.57-5.75-5.75-5.75h-41.03h-61.56H253.5
                                    c-3.18,0-5.75,2.57-5.75,5.75v76.95c0,3.18,2.57,5.75,5.75,5.75l13.68,0c1.56,0,2.76-1.21,2.97-2.75
                                    c1.58-11.76,11.65-20.87,23.84-20.87c12.19,0,22.25,9.11,23.84,20.87c0.21,1.54,1.38,2.75,2.94,2.75c13.29,0,71.24,0,84.46,0
                                    c1.54,0,2.69-1.19,2.9-2.72c1.57-11.78,11.64-20.9,23.84-20.9c12.19,0,22.26,9.11,23.84,20.88c0.21,1.54,1.4,2.74,2.96,2.74
                                    l24.98,0c5.34,0,7.9-2.57,7.9-5.75v-34.23C491.65,467.44,490.9,463.41,489.43,459.61z M470.18,461.68h-27.87
                                    c-1.35,0-2.45-1.1-2.45-2.45v-19.97c0-1.35,1.1-2.45,2.45-2.45h18.96c1.01,0,1.91,0.61,2.28,1.55l8.26,20.92
                                    C472.27,460.43,471.42,461.68,470.18,461.68z"/>
                                <g>
                                    <path fill="#1D1D1B" d="M234.78,428.78c0-10.32,8.4-18.72,18.72-18.72h37.64h61.56h17.25v-21.91c0-2.94-2.38-5.32-5.32-5.32h-37.92
                                        h-56.9h-37.91c-2.94,0-5.32,2.38-5.32,5.32v71.13c0,2.94,2.38,5.32,5.32,5.32h2.87V428.78z"/>
                                    <path fill="#1D1D1B" d="M393.73,410.06c3.79,0,7.31,1.13,10.26,3.07c1-0.66,2.07-1.21,3.19-1.67v-13.62c0-0.31,0.06-0.61,0.18-0.88
                                        c0.34-0.81,1.15-1.38,2.09-1.38h3.3h14.23c0.93,0,1.76,0.57,2.11,1.43l5.16,13.06h16.31l-9.21-23.83
                                        c-0.79-2.05-2.76-3.4-4.96-3.4h-52.78c-1.64,0-3.08,0.75-4.06,1.92c-0.04,0.04-0.08,0.09-0.11,0.14
                                        c-0.14,0.18-0.27,0.36-0.38,0.56c-0.09,0.15-0.18,0.31-0.25,0.47c-0.05,0.1-0.1,0.21-0.14,0.32c-0.1,0.25-0.18,0.51-0.24,0.78
                                        c-0.01,0.06-0.02,0.13-0.03,0.19c-0.06,0.31-0.1,0.63-0.1,0.95v0v0v21.91H393.73z"/>
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <circle fill="#1D1D1B" cx="293.99" cy="511.97" r="17.62"/>
                            </g>
                            <g>
                                <circle fill="#1D1D1B" cx="431.96" cy="511.97" r="17.62"/>
                            </g>
                        </g>
                    </g>
                    <path fill="#1D1D1B" d="M602.36,575.23c-6.28,0.04-12.41-0.1-18.41-0.39c-5.18-0.25-10.27-0.63-15.25-1.12l3.55-20.6
                        c7.77,0.64,15.85,0.93,24.24,0.82L602.36,575.23z M537.16,568.93c-9.72-2.03-19.28-4.6-28.45-7.62l15.18-17.47
                        c7.53,2.35,15.33,4.32,23.2,5.84L537.16,568.93z M483.86,551.65c-7.32-3.31-14.52-7.01-21.43-10.99l21.48-13.99
                        c6.14,3.42,12.5,6.56,18.9,9.34L483.86,551.65z"/>
                </g>
            );
        case "camion":
            return (
                <g transform="translate(-13.500000,192.027329) scale(0.100000,-0.100000)"
                    fill={color} stroke="none">
                    <path d="M400 1870 l0 -50 222 -2 222 -3 12 -35 c7 -19 40 -150 74 -290 34
                    -140 66 -272 71 -292 l10 -38 653 0 654 0 62 254 62 255 47 3 46 3 0 50 0 50
                    -722 3 -722 2 -11 43 c-6 23 -15 53 -19 67 l-8 25 -326 3 -327 2 0 -50z"/>
                    <path d="M375 1695 c-22 -8 -49 -26 -60 -40 -10 -14 -44 -89 -74 -168 l-55
                    -142 -6 -140 c-5 -132 -6 -140 -25 -140 -18 0 -20 -7 -20 -85 l0 -85 113 -3
                    112 -3 0 33 c1 72 59 152 132 182 132 55 288 -49 288 -191 0 -23 2 -23 123
                    -23 l123 0 56 50 56 50 126 0 c79 0 126 4 126 10 0 6 19 31 41 55 l41 45 -348
                    2 -349 3 -3 303 -2 302 -178 0 c-125 -1 -189 -5 -217 -15z m295 -149 l0 -84
                    -64 -56 -63 -56 -132 0 c-72 0 -131 3 -131 8 0 18 83 219 97 235 25 27 68 35
                    186 36 l107 1 0 -84z"/>
                    <path d="M1729 1055 c22 -24 41 -49 41 -55 0 -5 23 -10 50 -10 28 0 50 5 50
                    10 0 6 19 31 41 55 l42 45 -133 0 -133 0 42 -45z"/>
                    <path d="M2170 1094 c0 -3 13 -16 28 -29 15 -13 34 -35 41 -49 14 -25 17 -26
                    108 -26 l93 0 0 55 0 55 -135 0 c-74 0 -135 -3 -135 -6z"/>
                    <path d="M489 1047 c-104 -70 -106 -197 -6 -271 40 -29 134 -29 173 0 53 39
                    69 71 69 134 0 66 -18 99 -75 138 -45 30 -115 30 -161 -1z m128 -89 c30 -28
                    29 -63 -2 -93 -32 -33 -68 -32 -94 1 -27 34 -27 62 1 91 27 29 65 29 95 1z"/>
                    <path d="M1500 1048 c-57 -39 -75 -72 -75 -138 0 -97 58 -155 155 -155 66 0
                    99 18 138 75 88 131 -87 306 -218 218z m127 -90 c29 -27 29 -57 2 -92 -26 -33
                    -62 -34 -94 -1 -30 30 -31 54 -4 89 25 32 64 34 96 4z"/>
                    <path d="M1979 1047 c-58 -39 -81 -84 -77 -145 13 -152 193 -206 286 -84 35
                    47 38 135 6 179 -55 74 -147 96 -215 50z m130 -93 c27 -35 26 -59 -4 -89 -30
                    -30 -54 -31 -89 -4 -33 26 -35 70 -4 99 31 28 72 26 97 -6z"/>
                </g>
            );        case "semi":
            return (
                <g transform="translate(-13.500000,192.027329) scale(0.100000,-0.100000)"
                    fill={color}>
		            <path d="M45.463,29.636c-1.484,0-2.689,1.201-2.689,2.686c0,1.486,1.205,2.689,2.689,2.689
		            c1.479,0,2.688-1.203,2.688-2.689C48.151,30.839,46.946,29.636,45.463,29.636z M45.461,33.499c-0.65,0-1.182-0.527-1.182-1.18
		            c0-0.646,0.527-1.178,1.182-1.178c0.646,0,1.18,0.527,1.18,1.178C46.641,32.972,46.112,33.499,45.461,33.499z M39.188,29.636
		            c-1.48,0-2.686,1.201-2.686,2.686c0,1.486,1.203,2.689,2.686,2.689c1.484,0,2.689-1.203,2.689-2.689
		            C41.875,30.839,40.672,29.636,39.188,29.636z M39.188,33.499c-0.646,0-1.178-0.527-1.178-1.18c0-0.646,0.529-1.178,1.178-1.178
		            c0.652,0,1.178,0.527,1.178,1.178C40.366,32.972,39.838,33.499,39.188,33.499z M32.83,29.636c-1.484,0-2.689,1.201-2.689,2.686
		            c0,1.486,1.203,2.689,2.689,2.689c1.479,0,2.686-1.203,2.686-2.689C35.516,30.839,34.313,29.636,32.83,29.636z M32.83,33.499
		            c-0.65,0-1.186-0.527-1.186-1.18c0-0.646,0.529-1.178,1.186-1.178c0.646,0,1.178,0.527,1.178,1.178
		            C34.008,32.972,33.481,33.499,32.83,33.499z M5.57,20.14c0,0,1.601-1.452,4.501-2.759v2.759H5.57z M10.39,17.202v3.546
		            c-0.078,0-0.15,0-0.225,0v10.224v0.729h19.417c0.33-1.51,1.676-2.645,3.277-2.645c1.48,0,2.73,0.955,3.186,2.279
		            c0.451-1.322,1.703-2.279,3.178-2.279c1.432,0,2.652,0.898,3.139,2.164c0.484-1.266,1.703-2.164,3.139-2.164
		            c1.604,0,2.955,1.135,3.281,2.645h2.764v-3.305h0.859V17.202H10.39z M50.784,30.07h-0.418v-1.672h0.418V30.07z M1.472,31.345
		            c-0.041,0.188-0.067,0.385-0.067,0.584c0,0.168,0.021,0.33,0.055,0.49H0v-1.074H1.472z M8.74,31.345h1.124v1.072H8.757
		            c0.021-0.158,0.039-0.322,0.039-0.488C8.795,31.728,8.771,31.532,8.74,31.345z M29.541,31.929c-0.021,0.16-0.037,0.324-0.037,0.49
		            c0,0.102,0.008,0.197,0.014,0.299H10.167v-0.789H29.541L29.541,31.929z M48.821,31.929h3.021v0.787h-2.998
		            c0.008-0.1,0.016-0.197,0.016-0.297C48.86,32.253,48.844,32.089,48.821,31.929z M0.097,25.594v1.18l-0.001,4.273h1.636
		            c0.396-1.553,1.8-2.705,3.477-2.705c1.673,0,3.074,1.148,3.473,2.705h1.205V20.732h-7.57L0.097,25.594z M9.058,21.516v2.555H6.137
		            l-2.161,1.817H1.088l1.606-4.372H9.058z M5.219,29.441c-1.592-0.002-2.883,1.289-2.883,2.881s1.291,2.881,2.883,2.881
		            c1.59,0,2.881-1.287,2.881-2.881C8.098,30.73,6.807,29.441,5.219,29.441z M5.219,33.585c-0.699,0-1.266-0.566-1.266-1.266
		            c0-0.695,0.562-1.264,1.266-1.264c0.697,0,1.263,0.564,1.263,1.264S5.916,33.585,5.219,33.585z"/>
                </g>
            );
        default:
            return <g></g>;
    }
};

const LorieIcon = (props) => (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet"
            width={props.width}
            height={props.height}
            viewBox={getViewBox(props.name)}
        >
        {getSvgContent(props.name, props.color)}
        </svg>
    );

export default LorieIcon;
