import { faPen, faUndo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Card, CardContent } from "@mui/material"
import { NumericTextBox, NumericTextBoxHandle } from "@progress/kendo-react-inputs"
import React, { useEffect, useState } from "react"
import { PurchaseCostUsedQuantitiesKindEnum } from "../models/PurchaseCostUsedQuantitiesKindEnum"
import { VehiclePriceKind } from "../models/VehiclePriceKind"
import { NegotiatedDeliveryTripsGroupLightModel } from "../services/dataContracts/queryStack/NegotiatedDeliveryTripsGroupLightModel"

interface DetailNegotiatedPriceCardProps {
    updating: boolean,
    item: NegotiatedDeliveryTripsGroupLightModel,
    handleDeliveredQuantityChanged: (overridenValue: number) => void,
    handleDeliveredNumberOfTurnsChanged: (overridenValue: number) => void,
    handleUndoOverridenDeliveredQuantity: () => void
    handleUndoOverridenDeliveredNumberOfTurns: () => void
}

export const DetailNegotiatedPriceCardComponent = (props: DetailNegotiatedPriceCardProps) => {
    const { item, updating } = props;
    const quantityRef: React.RefObject<NumericTextBoxHandle> = React.useRef(null);
    const numberOfTurnsRef: React.RefObject<NumericTextBoxHandle> = React.useRef(null);
    
    const [deliveredQuantityEditing, setDeliveredQuantityEditing] = useState<boolean>(false);
    const [overridenDeliveredQuantity, setOverridenDeliveredQuantity] = useState<number>(item.overridenDeliveredQuantity);
    const [deliveredNumberOfTurnsEditing, setDeliveredNumberOfTurnsEditing] = useState<boolean>(false);
    const [overridenDeliveredNumberOfTurns, setOverridenDeliveredNumberOfTurns] = useState<number>(item.overridenDeliveredNumberOfTurns);

    useEffect(() => {
        if(deliveredQuantityEditing) {
            quantityRef.current?.focus();
        }
    }, [deliveredQuantityEditing]);

    useEffect(() => {
        if(deliveredNumberOfTurnsEditing) {
            numberOfTurnsRef.current?.focus();
        }
    }, [deliveredNumberOfTurnsEditing]);

    const priceKindLabel = item.priceKind === VehiclePriceKind.PerTon 
        ? "T" 
        : item.priceKind === VehiclePriceKind.PerTurn 
            ? "Tr" : "H";

    const undoOverridenDeliveredQuantity = () => {
        setOverridenDeliveredQuantity(null);
        props.handleUndoOverridenDeliveredQuantity();
    }

    const undoOverridenDeliveredNumberOfTurns = () => {
        setOverridenDeliveredNumberOfTurns(null);
        props.handleUndoOverridenDeliveredNumberOfTurns();
    }
    
    const usedQuantity = overridenDeliveredQuantity !== null
        ? overridenDeliveredQuantity
        : item.computedQuantity;

    const usedNumberOfTurns = overridenDeliveredNumberOfTurns !== null
        ? overridenDeliveredNumberOfTurns
        : item.computedNumberOfTurns;

    const cardStatusClass = item.transportFlowServiceKind != 'Delivery' ? item.transportFlowServiceKind : (item.transportFlowIsPerishableProduct ? 'Delivery_Perishable' : 'Delivery_Not_Perishable');

    return(
        <Card className={`card-section-specific-negotiated-price ${cardStatusClass}`}>
            <CardContent className="card-body">
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" paddingBottom="4px">
                        <div className="label">
                            Tarif négocié: {Number(item.unitPrice).toCurrencyString()}/{priceKindLabel}
                        </div>
                        <Box paddingRight="8px">
                            {item.transportFlowBusinessId}
                        </Box>
                    </Box>
                    <div>
                        {`${item.transportFlowSenderSiteCity} > ${item.transportFlowReceiverSiteCity}${item.transportFlowProduct ? " - " + item.transportFlowProduct : ""}`}
                    </div>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" paddingTop="4px" paddingBottom="4px">
                        {item.priceKind === VehiclePriceKind.PerHour 
                            ? <Box display="flex" flexDirection="column" width="75%">
                                <div>
                                    Temps à facturer
                                </div>
                                <div>
                                    {item.plannedTime}
                                </div>
                            </Box>
                            : <Box display="flex" flexDirection="row" justifyContent="space-between" width="75%">
                                {item.priceKind === VehiclePriceKind.PerTon ?
                                <>
                                    <Box display="flex" flexDirection="column">
                                        <div>
                                            Quantité
                                        </div>
                                        <div>
                                        </div>
                                    </Box>
                                    <Box display="flex" flexDirection="column" pl={1}>
                                        <div>
                                            Planifiée
                                        </div>
                                        <div>
                                            {item.plannedQuantity}
                                        </div>
                                    </Box>
                                    <Box display="flex" flexDirection="column" pl={1}>
                                        <div>
                                            Zéphyr
                                        </div>
                                        <div>
                                            {item.deliveredQuantity ? item.deliveredQuantity : ""}
                                        </div>
                                    </Box>

                                    <Box display="flex" flexDirection="column" pl={1}>
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            <div>Comptabilisée</div>&nbsp;
                                            <FontAwesomeIcon size="1x" className='icon-pen-blue' icon={faPen} />&nbsp;
                                            <Box onClick={() => (!updating && overridenDeliveredQuantity !== null) ? undoOverridenDeliveredQuantity() : null}>
                                            <FontAwesomeIcon size="1x" icon={faUndo} className={overridenDeliveredQuantity !== null ? "undo-icon" : "undo-icon disabled"} />
                                        </Box>
                                        </Box>
                                        {(updating || !deliveredQuantityEditing) ? (<div className={overridenDeliveredQuantity !== null
                                            ? "text-blue" 
                                            : item.kindOfValuesAccounted === PurchaseCostUsedQuantitiesKindEnum.Delivered 
                                                ? "" 
                                                : "text-mauve"} onClick={() => setDeliveredQuantityEditing(true)}>
                                            {usedQuantity}
                                        </div>)
                                        : (
                                            <NumericTextBox className="input-cell-override"
                                                defaultValue={usedQuantity}
                                                ref={quantityRef}
                                                min={0}
                                                format={{
                                                    maximumFractionDigits: 2
                                                }}
                                                spinners={false}
                                                onChange={(event) => setOverridenDeliveredQuantity(event.target.value)}
                                                onBlur={(event) => {
                                                    setDeliveredQuantityEditing(false);
                                                    const value = !event.target.value ? 0 : event.target.value;
                                                    if(value !== item.overridenDeliveredQuantity)
                                                        props.handleDeliveredQuantityChanged(value);
                                                }} />
                                        )}
                                    </Box>
                                </>
                                : <>
                                <Box display="flex" flexDirection="column">
                                    <div>
                                        Tour(s)
                                    </div>
                                    <div>
                                    </div>
                                </Box>
                                <Box display="flex" flexDirection="column" pl={1}>
                                    <div>
                                        Prévu(s)
                                    </div>
                                    <div>
                                        {item.plannedNumberOfTurns}
                                    </div>
                                </Box>
                                <Box display="flex" flexDirection="column" pl={1}>
                                    <div>
                                        Zéphyr
                                    </div>
                                    <div>
                                        {item.deliveredNumberOfTurns ? item.deliveredNumberOfTurns : ""}
                                    </div>
                                </Box>
                                <Box display="flex" flexDirection="column" pl={1}>
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <div>Comptabilisé(s)</div>&nbsp;
                                        <FontAwesomeIcon size="1x" className='icon-pen-blue' icon={faPen} />&nbsp;
                                        <Box onClick={() => (!updating && overridenDeliveredNumberOfTurns !== null) ? undoOverridenDeliveredNumberOfTurns() : null}>
                                            <FontAwesomeIcon size="1x" icon={faUndo} className={overridenDeliveredNumberOfTurns !== null ? "undo-icon" : "undo-icon disabled"} />
                                        </Box>
                                    </Box>
                                    {(updating || !deliveredNumberOfTurnsEditing) ? (<div className={overridenDeliveredNumberOfTurns !== null
                                        ? "text-blue" 
                                        : item.kindOfValuesAccounted === PurchaseCostUsedQuantitiesKindEnum.Delivered 
                                            ? "" 
                                            : "text-mauve"} onClick={() => setDeliveredNumberOfTurnsEditing(true)}>
                                            {usedNumberOfTurns}
                                        </div>)
                                        : (
                                            <NumericTextBox className="input-cell-override"
                                                defaultValue={usedNumberOfTurns}
                                                ref={numberOfTurnsRef}
                                                min={0}
                                                format={{
                                                    maximumFractionDigits: 0
                                                }}
                                                spinners={false}
                                                onChange={(event) => setOverridenDeliveredNumberOfTurns(event.target.value)}
                                                onBlur={(event) => {
                                                    setDeliveredNumberOfTurnsEditing(false);
                                                    const value = !event.target.value ? 0 : event.target.value;
                                                    if(value !== item.overridenDeliveredNumberOfTurns)
                                                        props.handleDeliveredNumberOfTurnsChanged(value);
                                                }} />
                                    )}
                                </Box>
                            </>}
                            </Box>
                        }
                        <Box display="flex" flexDirection="column" width="25%" alignItems="flex-end" paddingRight="8px">
                            <div className="label">
                                Coût
                            </div>
                            <div>
                                {Number(item.totalPrice).toCurrencyString()}
                            </div>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}