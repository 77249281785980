import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult, WebAppActionResultEx } from '../../../../shared/models/WebAppActionResult';
import { AddedDriverRequestArgs } from './dataContracts/controller/AddedDriverRequestArgs';
import { AddedVehicleRequestArgs } from './dataContracts/controller/AddedVehicleRequestArgs';
import { GdprAcceptancyResult } from './dataContracts/controller/GdprAcceptancyResult';
import { OrderRequestArgs } from './dataContracts/controller/OrderRequestArgs';
import { SearchTransportersRequestArgs } from './dataContracts/controller/SearchTransportersRequestArgs';
import { UpdatedDriverRequestArgs } from './dataContracts/controller/UpdatedDriverRequestArgs';
import { UpdatedGdprUserRequestArgs } from './dataContracts/controller/UpdatedGdprUserRequestArgs';
import { UpdatedTransporterDriversRequestArgs } from './dataContracts/controller/UpdatedTransporterDriversRequestArgs';
import { UpdatedTransporterVehiclesRequestArgs } from './dataContracts/controller/UpdatedTransporterVehiclesRequestArgs';
import { UpdatedVehicleRequestArgs } from './dataContracts/controller/UpdatedVehicleRequestArgs';
import { DriverLightModel } from './dataContracts/queryStack/DriverLightModel';
import { OtherTransporterWithUsedDriversChoiceLightModel } from './dataContracts/queryStack/OtherTransporterWithUsedDriversChoiceLightModel';
import { TransporterLightModel } from './dataContracts/queryStack/TransporterLightModel';
import { VehicleLightModel } from './dataContracts/queryStack/VehicleLightModel';
import { VehicleTypeEstimatedLoadCapacityLightModel } from './dataContracts/queryStack/VehicleTypeEstimatedLoadCapacityLightModel';
import { TransporterLinkTokenLightModel } from "./dataContracts/queryStack/TransporterLinkTokenLightModel";
import { TransporterOrdersSeenByTransporterLightModel } from './dataContracts/queryStack/TransporterOrdersSeenByTransporterLightModel';
import { TransporterOrderPlanningVehiclesLightModel } from './dataContracts/queryStack/TransporterOrderPlanningVehiclesLightModel';

const controllerUrl = 'api/ThirdPartyTransporter/';

export class ThirdPartyTransporterApiClient {

    public static Search = (transporterId: string, date: Date, token: string)
        : Promise<AxiosResponse<TransporterOrdersSeenByTransporterLightModel>> => {
        let url = "";
        if (token === null) {
            url = controllerUrl + "FindOrders?transporterId=" + encodeURIComponent(transporterId) + "&selectedDate=" + encodeURIComponent(date.toJSON());
        } else {
            url = controllerUrl + "FindOrdersByToken?token=" + encodeURIComponent(token);
        }

        return axios.get(url);
    }

    public static ConfirmOrders = (orders: OrderRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        return axios.post(controllerUrl + 'ConfirmOrders', orders, { headers: _headers })
    }

    public static UpdateGdpr = (userName: string, hasAcceptedGdpr: boolean)
        : Promise<AxiosResponse<WebAppActionResultEx<GdprAcceptancyResult>>> => {
        const data: UpdatedGdprUserRequestArgs = {
            userName: userName,
            hasAcceptedGdpr: hasAcceptedGdpr
        };

        return axios({
            method: 'POST',
            url: controllerUrl + 'UpdateGdpr',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: data
        });
    }

    public static SearchExternalTransporters = (date: Date, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<Array<TransporterLightModel>>> => {
        const data: SearchTransportersRequestArgs = {
            logisticsUnits: logisticsUnits,
            selectedDate: date,
            dispatcherUserName: null,
            isForInternalTransporters: false
        }

        return axios({
            method: 'POST',
            url: controllerUrl + 'SearchTransporters',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: data
        });
    }

    public static SearchInternalTransporters = (date: Date, logisticsUnits: Array<string>, dispatcherUserName: string)
        : Promise<AxiosResponse<Array<TransporterLightModel>>> => {
        const data: SearchTransportersRequestArgs = {
            logisticsUnits: logisticsUnits,
            selectedDate: date,
            dispatcherUserName: dispatcherUserName,
            isForInternalTransporters: true
        }

        return axios({
            method: 'POST',
            url: controllerUrl + 'SearchTransporters',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: data
        });
    }

    public static SearchExternalTransportersWithOrders = (transporterId: string, date: Date, logisticsUnits: Array<string>)
        : Promise<[AxiosResponse<Array<TransporterLightModel>>, AxiosResponse<TransporterOrdersSeenByTransporterLightModel>]> => {
        return Promise.all([
            ThirdPartyTransporterApiClient.SearchExternalTransporters(date, logisticsUnits),
            ThirdPartyTransporterApiClient.FindOrders(transporterId, date)
        ]);
    }

    public static SearchInternalTransportersWithOrders = (transporterId: string, date: Date, logisticsUnits: Array<string>, dispatcherUserName: string)
        : Promise<[AxiosResponse<Array<TransporterLightModel>>, AxiosResponse<TransporterOrdersSeenByTransporterLightModel>]> => {
        return Promise.all([
            ThirdPartyTransporterApiClient.SearchInternalTransporters(date, logisticsUnits, dispatcherUserName),
            ThirdPartyTransporterApiClient.FindOrders(transporterId, date)
        ]);
    }

    public static FindOrders = (transporterId: string, date: Date)
        : Promise<AxiosResponse<TransporterOrdersSeenByTransporterLightModel>> => {
        return axios.get(controllerUrl + "FindOrders?transporterId=" + encodeURIComponent(transporterId) + "&selectedDate=" + encodeURIComponent(date.toJSON()))
    }

    public static GetOrderedPlanningVehiclesIds = (orders: Array<string>)
        : Promise<AxiosResponse<Array<TransporterOrderPlanningVehiclesLightModel>>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + "GetOrderedPlanningVehiclesIds",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(orders)
        });
    }

    public static GetVehicleTypes = ()
        : Promise<AxiosResponse<Array<VehicleTypeEstimatedLoadCapacityLightModel>>> => {
        return axios.get(controllerUrl + "GetVehicleTypes");
    }

    public static SearchDrivers = (transporterId: string, restrictToEnabledOnly: boolean, searchText = "")
        : Promise<AxiosResponse<Array<DriverLightModel>>> => {
        const url = controllerUrl + "SearchDrivers?transporterId=" + encodeURIComponent(transporterId) + "&searchText=" + encodeURIComponent(searchText) + "&restrictToEnabledOnly=" + restrictToEnabledOnly;
        return axios({
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static SearchLicencePlate = (transporterId: string, restrictToEnabledOnly: boolean, shouldOrderByVehicleTypeId: boolean, searchText = "")
        : Promise<AxiosResponse<Array<VehicleLightModel>>> => {
        return axios.get(controllerUrl + "SearchLicencePlate?transporterId=" + encodeURIComponent(transporterId) + "&searchText=" + encodeURIComponent(searchText) + "&restrictToEnabledOnly=" + restrictToEnabledOnly + "&shouldOrderByVehicleTypeId=" + shouldOrderByVehicleTypeId);
    }

    public static SearchDriversWithLicencePlate = (transporterId: string)
        : Promise<[AxiosResponse<Array<DriverLightModel>>, AxiosResponse<Array<VehicleLightModel>>]> => {
        return Promise.all([
            ThirdPartyTransporterApiClient.SearchDrivers(transporterId, true),
            ThirdPartyTransporterApiClient.SearchLicencePlate(transporterId, true, true)
        ]);
    }

    public static AddVehicle = (vehicle: AddedVehicleRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddVehicle',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(vehicle)
        });
    }

    public static UpdateVehicle = (vehicle: UpdatedVehicleRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'UpdateVehicle',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(vehicle)
        });
    }

    public static AddDriver = (driver: AddedDriverRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddDriver',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(driver)
        });
    }

    public static UpdateDriver = (driver: UpdatedDriverRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'UpdateDriver',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(driver)
        });
    }

    public static checkTransporterVehicle = (updatedTransporterVehicles: UpdatedTransporterVehiclesRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'CheckTransporterVehicle',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(updatedTransporterVehicles)
        });
    }

    public static checkTransporterDriver = (updatedTransporterDrivers: UpdatedTransporterDriversRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'CheckTransporterDriver',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(updatedTransporterDrivers)
        });
    }

    public static SearchOtherTransporters = (transporterId: string, onlyChosen: boolean, searchText = "")
        : Promise<AxiosResponse<Array<OtherTransporterWithUsedDriversChoiceLightModel>>> => {
        const url = controllerUrl + "SearchOtherTransporters?transporterId=" + encodeURIComponent(transporterId) + "&searchText=" + encodeURIComponent(searchText) + "&onlyChosen=" + encodeURIComponent(onlyChosen);
        return axios({
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static AddOrRemoveTransporterUsingDriversOfOtherChoice = (transporterId: string, otherTransporterId: string, shouldAdd: boolean)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddOrRemoveTransporterUsingDriversOfOtherChoice?transporterId=' + encodeURIComponent(transporterId) + '&otherTransporterId=' + encodeURIComponent(otherTransporterId) + '&shouldAdd=' + encodeURIComponent(shouldAdd),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetTransporterToken = (tokenId: string): Promise<AxiosResponse<TransporterLinkTokenLightModel>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'GetTransporterToken?tokenId=' + encodeURIComponent(tokenId),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }
}