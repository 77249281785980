import { Tooltip } from '@mui/material';
import React, { PureComponent } from 'react';
import { TransportFlowLightModelExtended } from '../../models/TransportFlowLightModelExtended';

interface CustomDiffCellProps {
    //Avoir des paramètres dynamiques
    [key: string]: any,
    rawValue: any,
    tooltipValue: string,
    hasDiffFromRequest: boolean,
    shouldRenderWrapped?: boolean,
    dataItem: TransportFlowLightModelExtended
    onRowClick: (dataItem: TransportFlowLightModelExtended) => void
}

const diffClassName = "field-different-value-transportRequest";

export const CustomCellWrapperHOC = (WrappedComponent)=>{
    return class extends PureComponent<CustomDiffCellProps, {}>{
        constructor(props: CustomDiffCellProps) {
            super(props);
        }
    
        render() {
            const className: string = this.props.tooltipValue !== null ? diffClassName : "";
            
            return (
                <td onClick={()=> this.props.onRowClick(this.props.dataItem)}>
                    <Tooltip title={`${this.props.hasDiffFromRequest ? 
                            (this.props.tooltipValue !== null && this.props.tooltipValue !== '' ? this.props.tooltipValue : ' ') : ''}`} placement="bottom">
                        <div className={className}>
                            {this.props.shouldRenderWrapped? (
                                <WrappedComponent isWrapped={true} {...this.props}/>
                            ): this.props.rawValue}
                        </div>
                    </Tooltip>
                </td>
            );
        }
    }
}