import { Box } from '@mui/material';
import { Grid, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridRowProps } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { BeneficiaryVehiclesInvoicesDetailsLightModel } from '../services/dataContracts/queryStack/BeneficiaryVehiclesInvoicesDetailsLightModel';
import { SizingUtilities } from '../SizingUtilities';

interface InvoicesDetailsGridProps {
    data: Array<BeneficiaryVehiclesInvoicesDetailsLightModel>
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const BeneficiaryInvoicesGridName = 'beneficiaryInvoices';
const ModuleKey = AppModule.ThirdPartyBeneficiaryInvoices;

export const InvoicesDetailsGrid = (props: InvoicesDetailsGridProps): JSX.Element => {
    const forceUpdate = useForceUpdate();
    const [skip, setSkip] = useState<number>(0);

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            BeneficiaryInvoicesGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, BeneficiaryInvoicesGridName, event.columns);
        forceUpdate();
    }

    const getPublishedCostsWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, BeneficiaryInvoicesGridName, fieldName, defaultWidth);
    }

    const getPublishedCostsGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, BeneficiaryInvoicesGridName, fieldName, defaultIndex);
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const data: BeneficiaryVehiclesInvoicesDetailsLightModel[] = props.data;
    const gridData: BeneficiaryVehiclesInvoicesDetailsLightModel[] = data.slice(skip, skip + gridPageSize);
    const totalGrid: number = data.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps): React.ReactElement<HTMLTableRowElement> => {
        const isGroupHeader: boolean = props.dataItem.isGroupHeader;

        let className: string = trElement.props.className;
        if (!isGroupHeader) {
            className = className.replace('k-selected', '');
            className = className + ' vehicle';
        }
        else {
            className = className + ' header';
        }

        return React.cloneElement(trElement, { ...trElement.props, className: className }, trElement.props.children as any);
    }

    return (
        <Box className="invoices-details-list">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Grid
                        className="invoices-details-grid"
                        data={gridData}
                        reorderable
                        resizable
                        onColumnResize={onResizeHandler}
                        onColumnReorder={onReorderHandler}
                        rowHeight={rowHeight}
                        scrollable="virtual"
                        skip={skip}
                        total={totalGrid}
                        pageSize={gridPageSize}
                        onPageChange={pageChange}
                        rowRender={rowRender}
                        style={gridStyle}
                    >
                        <GridColumn field="empty" orderIndex={0} title=" " width={10} resizable={false} reorderable={false} />
                        <GridColumn field="planningDate" title="Date d'arrivée" format="{0:dd-MM-yyyy}" orderIndex={getPublishedCostsGridOrderIndexColumn("planningDate", 1)} width={getPublishedCostsWidth("planningDate", 120)} />
                        <GridColumn field="invoiceDate" title="Date de facturation" format="{0:dd-MM-yyyy}" orderIndex={getPublishedCostsGridOrderIndexColumn("invoiceDate", 2)} width={getPublishedCostsWidth("invoiceDate", 120)} />
                        <GridColumn field="senderSite" title="Site départ" orderIndex={getPublishedCostsGridOrderIndexColumn("senderSite", 3)} width={getPublishedCostsWidth("senderSite", 120)} />
                        <GridColumn field="receiverSite" title="Site arrivée" orderIndex={getPublishedCostsGridOrderIndexColumn("receiverSite", 4)} width={getPublishedCostsWidth("receiverSite", 120)} />
                        <GridColumn field="address" title="Adresse" orderIndex={getPublishedCostsGridOrderIndexColumn("address", 5)} width={getPublishedCostsWidth("address", 120)} />
                        <GridColumn field="flowBusinessId" title="Flux" orderIndex={getPublishedCostsGridOrderIndexColumn("flowBusinessId", 6)} width={getPublishedCostsWidth("flowBusinessId", 120)} />
                        <GridColumn field="transportSellPriceKind" title="Type tarification" orderIndex={getPublishedCostsGridOrderIndexColumn("transportSellPriceKind", 7)} width={getPublishedCostsWidth("transportSellPriceKind", 120)} />
                        <GridColumn field="licencePlate" title="Immatriculation" orderIndex={getPublishedCostsGridOrderIndexColumn("licencePlate", 8)} width={getPublishedCostsWidth("licencePlate", 120)} />
                        <GridColumn field="vehicleTypeLabel" title="Type camion" orderIndex={getPublishedCostsGridOrderIndexColumn("vehicleTypeLabel", 9)} width={getPublishedCostsWidth("vehicleTypeLabel", 120)} />
                        <GridColumn field="plannedQuantity" title="Qté planifiée" orderIndex={getPublishedCostsGridOrderIndexColumn("plannedQuantity", 10)} width={getPublishedCostsWidth("plannedQuantity", 120)} />
                        <GridColumn field="plannedNumberOfTurns" title="Tours planifiés" orderIndex={getPublishedCostsGridOrderIndexColumn("plannedNumberOfTurns", 11)} width={getPublishedCostsWidth("plannedNumberOfTurns", 120)} />
                        <GridColumn field="deliveredQuantity" title="Qté livrée" orderIndex={getPublishedCostsGridOrderIndexColumn("deliveredQuantity", 12)} width={getPublishedCostsWidth("deliveredQuantity", 120)} />
                        <GridColumn field="deliveredNumberOfTurns" title="Tours effectués" orderIndex={getPublishedCostsGridOrderIndexColumn("deliveredNumberOfTurns", 13)} width={getPublishedCostsWidth("deliveredNumberOfTurns", 120)} />
                        <GridColumn field="invoicePriceText" title="Prix facturés" orderIndex={getPublishedCostsGridOrderIndexColumn("invoicePriceText", 14)} width={getPublishedCostsWidth("invoicePriceText", 120)} />
                        <GridColumn field="preInvoiceComments" title="Commentaire" orderIndex={getPublishedCostsGridOrderIndexColumn("preInvoiceComments", 15)} width={getPublishedCostsWidth("preInvoiceComments", 120)} />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}
