import { faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardContent, Divider, Tooltip, Typography } from "@mui/material";
import { filter, intersection, map } from 'lodash';
import React from "react";
import AddedDeletedPropertyComponent from "./components/AddedDeletedPropertyComponent";
import ModifiedPropertyComponent from "./components/ModifiedPropertyComponent";
import OwnedEntityChangesComponent from "./components/OwnedEntityChangesComponent";
import TransportationsChangesComponent from "./components/TransportationsChangesComponent";
import { EntityChangeAction } from './models/EntityChangeAction';
import { OwnedEntity } from './models/OwnedEntity';
import { EntityChangeLightModel } from './services/dataContracts/queryStack/EntityChangeLightModel';
import { TransportRequestChangeLightModel } from './services/dataContracts/queryStack/TransportRequestChangeLightModel';
import { TransportRequestChangesApiClient } from './services/TransportRequestChangesApiClient';
import { enumValues, HiddenProperties, propertyLabels } from './TransportRequestChangesResources';

interface TransportRequestChangesViewProperties {
    transportRequestId: string,
    vehicleTypesLabels: Map<string, string>,
    closeDrawer: () => void,
    businessId: string,
    dateAl: string,
    lastRootChangeId: number,
    lastObservedRootChangeId: number,
    updateAlertHandler: (boolean) => void
}

interface TransportRequestChangesViewState {
    trackingChanges: TransportRequestChangeLightModel[],
    unobservedDc: boolean

}

enum KnownFields {
    ProductionSite = 'ProductionSite',
    Agency = 'Agency'
}

enum KnownTypes{
    DateTimeOffsetNullable = 'DateTimeOffset?',
    DateTimeOffset = 'DateTimeOffset',
    TimeStampNullable = 'TimeStamp?',
    TimeStamp = 'TimeStamp',
    BooleanNullable = 'Boolean?',
    Boolean = 'Boolean',
    TransportRequestStatus = 'TransportRequestStatus',
    SourceApplication = 'SourceApplication',
    CoatingApplicationModeNullable = 'CoatingApplicationMode?',
    CoatingApplicationMode = 'CoatingApplicationMode',
    TransportPriority = 'TransportPriority',
    TransportPriorityNullable = 'TransportPriority?',
    TransportServiceKind = 'TransportServiceKind'
}

export class TransportRequestChangesView extends React.PureComponent<TransportRequestChangesViewProperties, TransportRequestChangesViewState> {
    _isMounted: boolean;
    ///v1 = p1

    ///v1 typer stp le code

    ///v1 -> Renommer le dico en PropertyLabels

    //v1.5 -> prefixer les Noms des proprietes les noms des entités, ex: 'TransportRequest.SourceLocalId'
    ///v2 mettre ce dico dans dans un fichier apart (TransportRequestChanges.Resources.ts) PropertyLabels comme dico
    //(remarque, si on a besoin d'aoutrer des descriptions, par ex, il aura aussi un deuxieme dico a l'interieur "PropertyDescriptions")
    propertyLabels: Map<string, string>;

    //v1 -> renommer en EnumsValues
    //v2 -> plus propret, un dico par enum avec les values

    enumValues: Map<string, string>;

    //SYNC with VehicleTripQuerieshandler.SearchVehiclesTrip and clientApp/src/shared/component/TransportRequestChanges/TransportRequestChanges.DICT_CODE_UE_UP
    DICT_CODE_UE: Map<string, string> = new Map([
        ['UE745', 'Agence travaux Blaye'],
        ['UE761', 'Agence travaux Floirac'],
        ['UE764', 'Agence travaux Libourne'],
        ['UE770', 'Agence travaux Merignac'],
        ['UE803', 'Agence travaux Novello'],
        ['UE849', 'Agence travaux Van Cuyck'],
        ['UE758', 'Agence travaux Pépin'],
        ['UE779', 'Agence travaux Sarrazy'],
        ['UE762', 'Agence travaux Biscarosse'],
        ['UE737', 'Agence travaux Saint-Astier'],
        ['UE793', 'FABRIMACO MERIGNAC'],
        ['UE829', 'BME'],
        ['UE835', '33000'],
        ['UE838', 'AME']
    ]);
    DICT_CODE_UP: Map<string, string> = new Map([
        ['UP1295', 'FABRIMACO MERIGNAC'],
        ['UP1387', 'BME'],
        ['UP1395', '33000'],
        ['UP1400', 'AME']
    ]);
    //v1 RENOMMER en HiddenProperties
    //avec 'TransportRequest.Version' 
    HiddenProperties: string[];

    constructor(props: TransportRequestChangesViewProperties) {
        super(props);
        this.HiddenProperties = HiddenProperties;

        this.propertyLabels = propertyLabels;

        this.enumValues = enumValues;

        this.state = {
            trackingChanges: [],
            unobservedDc: false
        };

    }

    componentDidMount() {
        this._isMounted = true;
        this.showNotifications(this.props.transportRequestId);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTrackingChanges = (elems: EntityChangeLightModel[], entityType: string): EntityChangeLightModel[] => {
        return elems.filter((e: EntityChangeLightModel) => {
            return e.entityType === entityType
        });
    }

    getTrackingEntityChanges = (elems: EntityChangeLightModel[], entityType: string): EntityChangeLightModel => {
        return elems.find((e: EntityChangeLightModel) => {
            return e.entityType === entityType
        });
    }

    getTrackingChangesByNavigationName = (elems: EntityChangeLightModel[], entityType: string, navigationName: string): EntityChangeLightModel => {
        return elems.find((e: EntityChangeLightModel) => {
            return e.entityType === entityType && e.navigationName === navigationName;
        });
    }

    getTrackingTransportationAction = (elems: EntityChangeLightModel[], changeAction: string): EntityChangeLightModel[] => {
        return elems.filter((e: EntityChangeLightModel) => {
            return e.entityChangeAction === changeAction
        });
    }

    showNotifications = (deliveryId: string, isAlertNeedUpdated = false): void => {
        const d: Date = new Date((new Date().stripTime()));
        TransportRequestChangesApiClient.GetTransportRequestChanges(deliveryId, d)
            .then(res => {
                const elems = res.data;
                if (elems && this._isMounted) {
                    this.setState({
                        trackingChanges: elems.changeHistory,
                        unobservedDc: elems.lastObservedRootChangeId !== elems.lastRootChangeId
                    });
                    if (isAlertNeedUpdated)
                        this.props.updateAlertHandler((elems.lastObservedRootChangeId !== elems.lastRootChangeId));
                }

            })
            .catch(error => console.log(error));
    }

    setNotificationViewed = (deliveryId: string, lastObservedRootChangeId: number): void => {
        TransportRequestChangesApiClient.UpdateLastObservedIndicator(deliveryId, lastObservedRootChangeId)
            .then(res => {
                this.showNotifications(this.props.transportRequestId, true);
                this.forceUpdate();
                //this.props.closeDrawer();
            })
            .catch(error => console.log(error));
    }

    getNameValue = (enumElem: any, objectName: string): string => {
        return this.enumValues.get(`${objectName}.${enumElem}`);
    }

    //Récuperer le libelle du code agency
    getUeLabelByCode = (code: string): string => {
        return (this.DICT_CODE_UE.get(code)) ? this.DICT_CODE_UE.get(code) : code;
    }

    //Récuperer le libelle du code site
    getUpLabelByCode = (code: string): string => {
        return (this.DICT_CODE_UP.get(code)) ? this.DICT_CODE_UP.get(code) : code;
    }

    getValueOfParsedJson = (objectParsedValues: any, nameField: string): any => {
        let res;
        try {
            //v1 Parser en amont et utiliser l'objet JSON directement
            //renvoie la valeur avec son type reel
            const o = objectParsedValues[nameField];

            if (objectParsedValues && o) {
                switch (o.ClrTypeName) {
                    case KnownTypes.DateTimeOffsetNullable:
                    case KnownTypes.DateTimeOffset:
                        res = o.Value ? new Date(o.Value).toLocaleString() : ''; //ex: renvoie la valeur avec son type reel, new Date(o.Value)
                        break;
                    case KnownTypes.TimeStampNullable:
                    case KnownTypes.TimeStamp:
                        res = o.Value;
                        break;
                    case KnownTypes.BooleanNullable:
                    case KnownTypes.Boolean:
                        res = 'Non';
                        if (o.Value === true)
                            res = 'Oui';
                        break;
                    case KnownTypes.TransportRequestStatus:
                    case KnownTypes.SourceApplication:
                    case KnownTypes.CoatingApplicationModeNullable:
                    case KnownTypes.CoatingApplicationMode:
                    case KnownTypes.TransportPriorityNullable:
                    case KnownTypes.TransportPriority:
                    case KnownTypes.TransportServiceKind:
                        res = this.getNameValue(o.Value, o.ClrTypeName);
                        break;
                    default:
                        //Afficher le libelle au lieu des code UE et UP pour les Customer/Supplier owner
                        switch (nameField) {
                            case KnownFields.ProductionSite:
                                res = this.getUpLabelByCode(o.Value);
                                break;
                            case KnownFields.Agency:
                                res = this.getUeLabelByCode(o.Value);
                                break;
                            default:
                                res = o.Value;
                                break;
                        }
                }
            }
        } catch (e) { }
        return res;
    }

    reOrderFields = (objectElem: any, sortKeyObj): string[] => {
        const arrWithKeys: string[] = Object.keys(objectElem);
        const s = filter(sortKeyObj, (e) => {
            return arrWithKeys.indexOf(e.val) !== -1;
        });
    
        return map(s, 'val');
    }

    render() {
        const { vehicleTypesLabels } = this.props;

        let classColor = '';
        let classBackgroundColor = '';
        if (this.state.unobservedDc) {
            classColor = "color-modified";
            classBackgroundColor = "card-header-dc";
        }

        return (
            <div style={{ width: '100%' }}>
                <Card>
                    <CardContent className="drawer-group-notif-dc">
                        <Typography variant="h5">
                            <Box display="flex" flexWrap="nowrap" flexDirection="row">
                                <Box width="80%">
                                    Historique modifications
                                </Box>
                                <Box display="flex" width="20%" flexDirection="row" justifyContent="flex-end">
                                    <Box style={{ cursor: 'pointer' }} onClick={this.props.closeDrawer}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider style={{ height: '2px' }} />
                        </Typography>
                        <Typography variant="h6">
                            <Box display="flex" flexWrap="nowrap" flexDirection="row" className={classBackgroundColor}>
                                <Box pl={1} width="50%">
                                    Flux {this.props.businessId}
                                </Box>
                                <Box display="flex" width="40%" flexDirection="row" justifyContent="flex-end">
                                    {this.props.dateAl}
                                </Box>
                                <Box pr={1} display="flex" width="10%" flexDirection="row" justifyContent="flex-end">
                                    {this.state.unobservedDc ? (
                                        <Tooltip title="Vu/Pas vu" placement="bottom">
                                            <Box style={{ cursor: 'pointer' }} onClick={() => this.setNotificationViewed(this.props.transportRequestId, this.props.lastRootChangeId)}>
                                                <FontAwesomeIcon className={classColor} icon={faEye} />
                                            </Box>
                                        </Tooltip>
                                    )
                                        : (
                                            <Tooltip title="Vu/Pas vu" placement="top">
                                                <Box style={{ cursor: 'pointer' }} onClick={() => this.setNotificationViewed(this.props.transportRequestId, null)}>
                                                    <FontAwesomeIcon className={classColor} icon={faEyeSlash} />
                                                </Box>
                                            </Tooltip>
                                        )
                                    }
                                </Box>
                            </Box>
                            <Divider style={{ height: '2px' }} />
                        </Typography>
                        <Box display="flex" flexWrap="nowrap" flexDirection="column" className="drawerNotifAL">
                            <Box>
                                {this.state.trackingChanges.map((elem, index) => {
                                    //let trackingGlobals=this.getTrackingChanges(elem.entityChanges, OWNED_ENTITY_TransportRequest);
                                    const trackingTransportation = this.getTrackingChanges(elem.childChanges, OwnedEntity.RequestedTransportation);

                                    const trackingProduct = this.getTrackingEntityChanges(elem.childChanges, OwnedEntity.ProductInfo);
                                    const trackingCustomer = this.getTrackingChangesByNavigationName(elem.childChanges, OwnedEntity.SiteInfo, OwnedEntity.ReceiverSite);
                                    const trackingSupplier = this.getTrackingChangesByNavigationName(elem.childChanges, OwnedEntity.SiteInfo, OwnedEntity.SenderSite);
                                    const trackingJobForman = this.getTrackingChangesByNavigationName(elem.childChanges, OwnedEntity.ContactInfo, OwnedEntity.JobForeman);
                                    const trackingRequestedBy = this.getTrackingChangesByNavigationName(elem.childChanges, OwnedEntity.ContactInfo, OwnedEntity.RequestedBy);

                                    const trackingDeliveryAddress = this.getTrackingChangesByNavigationName(elem.childChanges, OwnedEntity.AddressInfo, OwnedEntity.DeliveryAddress);
                                    const trackingPickupAddress = this.getTrackingChangesByNavigationName(elem.childChanges, OwnedEntity.AddressInfo, OwnedEntity.PickupAddress);
                                    const trackingDeliveryCoordinates = this.getTrackingChangesByNavigationName(elem.childChanges, OwnedEntity.GpsCoordinates, OwnedEntity.DeliveryCoordinates);
                                    const trackingPickupCoordinates = this.getTrackingChangesByNavigationName(elem.childChanges, OwnedEntity.GpsCoordinates, OwnedEntity.PickupCoordinates);

                                    const colorPanelModif = (elem.hasBeenObserved) ? 'white' : '#ecf8fd';
                                    let changeActionAL: EntityChangeAction = EntityChangeAction.Modified;

                                    //On ne doit avoir qu'1 seul global tracking pour un AL
                                    const trackingGlobal: EntityChangeLightModel = elem.rootChange;
                                    changeActionAL = (elem.rootChange) ? (elem.rootChange.entityChangeAction as EntityChangeAction) : null;
                                    const oldValuesParsedTrackingGlobal = (trackingGlobal && trackingGlobal.oldValuesJson) ? JSON.parse(trackingGlobal.oldValuesJson) : null;
                                    const newValuesParsedTrackingGlobal = (trackingGlobal && trackingGlobal.newValuesJson) ? JSON.parse(trackingGlobal.newValuesJson) : null;
                                    const additionalValuesJsonTrackingGlobal = (trackingGlobal && trackingGlobal.additionalValuesJson) ? JSON.parse(trackingGlobal.additionalValuesJson) : null;
                                    if (elem.childChanges.length > 0 ||
                                        intersection(HiddenProperties, Object.keys(oldValuesParsedTrackingGlobal)).length !== Object.keys(oldValuesParsedTrackingGlobal).length) {
                                        if (changeActionAL === EntityChangeAction.Modified) {
                                            return (
                                                <Box key={`${elem.rootChange.changeTime}${index}`} bgcolor={colorPanelModif} p={1}>
                                                    <Card>
                                                        <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center" p={1}>
                                                            <Box width="60%">
                                                                <span className="card-title-text">{this.getValueOfParsedJson(additionalValuesJsonTrackingGlobal, 'LastWriteUser') ? this.getValueOfParsedJson(additionalValuesJsonTrackingGlobal, 'LastWriteUser') : 'Jean Marc'}</span> <span className="field-title-dc">Modificateur</span>
                                                            </Box>
                                                            <Box display="flex" width="40%" flexDirection="row" justifyContent="flex-end">
                                                                <Box className="field-title-dc">
                                                                    {elem.rootChange.changeTime ? ((new Date(elem.rootChange.changeTime)).toShortDateTimeString()) : ''}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Divider style={{ height: '2px' }} />
                                                        <CardContent className="my-card-content">
                                                            {
                                                                <Box pb={1} pr={1} key={`dc${index}f`} flexDirection="column" justifyContent="flex-start">
                                                                    {oldValuesParsedTrackingGlobal ? Object.keys(oldValuesParsedTrackingGlobal)
                                                                        .filter(x => x !== "LastWriteUser" && x !== "LastWriteTime" && x !== "FlowNumberSequence")
                                                                        .map((o, index4) => (
                                                                            <ModifiedPropertyComponent hiddenProperties={this.HiddenProperties} key={`dcfield:${index4}`} propertyLabels={this.propertyLabels} getValueOfParsedJson={this.getValueOfParsedJson}
                                                                                elem={o} entityType={OwnedEntity.TransportRequest} oldValuesParsedTracking={oldValuesParsedTrackingGlobal} newValuesParsedTracking={newValuesParsedTrackingGlobal} />
                                                                        )) : ''}
                                                                </Box>
                                                            }
                                                            {(trackingPickupAddress) ?
                                                                <Box pb={1} pr={1}>
                                                                    <OwnedEntityChangesComponent reOrderFields={this.reOrderFields} HiddenProperties={this.HiddenProperties} trackingEntity={trackingPickupAddress} propertyLabels={this.propertyLabels} getValueOfParsedJson={this.getValueOfParsedJson} vehicleTypesLabels={vehicleTypesLabels} />
                                                                </Box> : ''
                                                            }
                                                            {(trackingPickupCoordinates) ?
                                                                <Box pb={1} pr={1}>
                                                                    <OwnedEntityChangesComponent reOrderFields={this.reOrderFields} HiddenProperties={this.HiddenProperties} trackingEntity={trackingPickupCoordinates} propertyLabels={this.propertyLabels} getValueOfParsedJson={this.getValueOfParsedJson} vehicleTypesLabels={vehicleTypesLabels} />
                                                                </Box> : ''
                                                            }
                                                            {(trackingDeliveryAddress) ?
                                                                <Box pb={1} pr={1}>
                                                                    <OwnedEntityChangesComponent reOrderFields={this.reOrderFields} HiddenProperties={this.HiddenProperties} trackingEntity={trackingDeliveryAddress} propertyLabels={this.propertyLabels} getValueOfParsedJson={this.getValueOfParsedJson} vehicleTypesLabels={vehicleTypesLabels} />
                                                                </Box> : ''
                                                            }
                                                            {(trackingDeliveryCoordinates) ?
                                                                <Box pb={1} pr={1}>
                                                                    <OwnedEntityChangesComponent reOrderFields={this.reOrderFields} HiddenProperties={this.HiddenProperties} trackingEntity={trackingDeliveryCoordinates} propertyLabels={this.propertyLabels} getValueOfParsedJson={this.getValueOfParsedJson} vehicleTypesLabels={vehicleTypesLabels} />
                                                                </Box> : ''
                                                            }
                                                            {(trackingProduct) ?
                                                                <Box pb={1} pr={1}>
                                                                    <OwnedEntityChangesComponent reOrderFields={this.reOrderFields} HiddenProperties={this.HiddenProperties} trackingEntity={trackingProduct} propertyLabels={this.propertyLabels} getValueOfParsedJson={this.getValueOfParsedJson} vehicleTypesLabels={vehicleTypesLabels} />
                                                                </Box> : ''
                                                            }
                                                            {(trackingCustomer) ?
                                                                <Box pb={1} pr={1}>
                                                                    <OwnedEntityChangesComponent reOrderFields={this.reOrderFields} HiddenProperties={this.HiddenProperties} trackingEntity={trackingCustomer} propertyLabels={this.propertyLabels} getValueOfParsedJson={this.getValueOfParsedJson} vehicleTypesLabels={vehicleTypesLabels} />
                                                                </Box> : ''
                                                            }
                                                            {(trackingSupplier) ?
                                                                <Box pb={1} pr={1}>
                                                                    <OwnedEntityChangesComponent reOrderFields={this.reOrderFields} HiddenProperties={this.HiddenProperties} trackingEntity={trackingSupplier} propertyLabels={this.propertyLabels} getValueOfParsedJson={this.getValueOfParsedJson} vehicleTypesLabels={vehicleTypesLabels} />
                                                                </Box> : ''
                                                            }
                                                            {(trackingJobForman) ?
                                                                <Box pb={1} pr={1}>
                                                                    <OwnedEntityChangesComponent reOrderFields={this.reOrderFields} HiddenProperties={this.HiddenProperties} trackingEntity={trackingJobForman} propertyLabels={this.propertyLabels} getValueOfParsedJson={this.getValueOfParsedJson} vehicleTypesLabels={vehicleTypesLabels} />
                                                                </Box> : ''
                                                            }
                                                            {(trackingRequestedBy) ?
                                                                <Box pb={1} pr={1}>
                                                                    <OwnedEntityChangesComponent reOrderFields={this.reOrderFields} HiddenProperties={this.HiddenProperties} trackingEntity={trackingRequestedBy} propertyLabels={this.propertyLabels} getValueOfParsedJson={this.getValueOfParsedJson} vehicleTypesLabels={vehicleTypesLabels} />
                                                                </Box> : ''
                                                            }
                                                            {
                                                                (trackingTransportation && trackingTransportation.length > 0) ?
                                                                    (
                                                                        <Box pb={1} pr={1}>
                                                                            <TransportationsChangesComponent reOrderFields={this.reOrderFields} HiddenProperties={this.HiddenProperties} actionType={EntityChangeAction.Added}
                                                                                trackingTransportations={this.getTrackingTransportationAction(trackingTransportation, EntityChangeAction.Added)}
                                                                                propertyLabels={this.propertyLabels} vehicleTypesLabels={vehicleTypesLabels} getValueOfParsedJson={this.getValueOfParsedJson}
                                                                            />
                                                                            {this.getTrackingTransportationAction(trackingTransportation, EntityChangeAction.Added).length > 0 ? <div className="card-separator" /> : ''}
                                                                            <TransportationsChangesComponent reOrderFields={this.reOrderFields} HiddenProperties={this.HiddenProperties} actionType={EntityChangeAction.Modified}
                                                                                trackingTransportations={this.getTrackingTransportationAction(trackingTransportation, EntityChangeAction.Modified)}
                                                                                propertyLabels={this.propertyLabels} vehicleTypesLabels={vehicleTypesLabels} getValueOfParsedJson={this.getValueOfParsedJson}
                                                                            />
                                                                            {this.getTrackingTransportationAction(trackingTransportation, EntityChangeAction.Modified).length > 0 ? <div className="card-separator" /> : ''}
                                                                            <TransportationsChangesComponent reOrderFields={this.reOrderFields} HiddenProperties={this.HiddenProperties} actionType={EntityChangeAction.Deleted}
                                                                                trackingTransportations={this.getTrackingTransportationAction(trackingTransportation, EntityChangeAction.Deleted)}
                                                                                propertyLabels={this.propertyLabels} vehicleTypesLabels={vehicleTypesLabels} getValueOfParsedJson={this.getValueOfParsedJson}
                                                                            />
                                                                        </Box>
                                                                    ) : ''
                                                            }
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                            )
                                        }
                                        else if (changeActionAL === EntityChangeAction.Added) {
                                            return (
                                                <Box p={1} key={`${elem.rootChange.changeTime}${index}`} bgcolor={colorPanelModif}>
                                                    <Card>
                                                        <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center" p={1}>
                                                            <Box width="60%">
                                                                <span className="card-title-text">{this.getValueOfParsedJson(newValuesParsedTrackingGlobal, "LastWriteUser")}</span> <span className="field-title-dc">Créateur</span>
                                                            </Box>
                                                            <Box display="flex" width="40%" flexDirection="row" justifyContent="flex-end">
                                                                <Box className="field-title-dc">
                                                                    {elem.rootChange.changeTime ? ((new Date(elem.rootChange.changeTime)).toShortDateTimeString()) : ''}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Divider style={{ height: '2px' }} />
                                                        <CardContent>
                                                            <Box p={1} key={`dcAdded${index}`} flexDirection="column" justifyContent="flex-start">
                                                                <AddedDeletedPropertyComponent entityType={OwnedEntity.TransportRequest} HiddenProperties={this.HiddenProperties} key={`dcAddedF${index}`} elem='CreationUser' getValueOfParsedJson={this.getValueOfParsedJson}
                                                                    valuesParsedTracking={newValuesParsedTrackingGlobal} propertyLabels={this.propertyLabels} />
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                            )
                                        }
                                        else {
                                            return (
                                                <Box p={1} key={`${elem.rootChange.changeTime}${index}`} bgcolor={colorPanelModif}>
                                                    <Card>
                                                        <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center" p={1}>
                                                            <Box width="60%">
                                                                <span className="card-title-text">{this.getValueOfParsedJson(newValuesParsedTrackingGlobal, "LastWriteUser")}</span><span className="field-title-dc">Rejetteur</span>
                                                            </Box>
                                                            <Box display="flex" width="40%" flexDirection="row" justifyContent="flex-end">
                                                                <Box className="field-title-dc">
                                                                    {elem.rootChange.changeTime ? ((new Date(elem.rootChange.changeTime)).toShortDateTimeString()) : ''}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Divider style={{ height: '2px' }} />
                                                        <CardContent>
                                                            <Box p={1} key={`dcAdded${index}`} flexDirection="column" justifyContent="flex-start">
                                                                <AddedDeletedPropertyComponent entityType={OwnedEntity.TransportRequest} HiddenProperties={this.HiddenProperties} key={`dcAddedF${index}`} elem='LastWriteUser' getValueOfParsedJson={this.getValueOfParsedJson}
                                                                    valuesParsedTracking={newValuesParsedTrackingGlobal} propertyLabels={this.propertyLabels} />
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                            )
                                        }
                                    }
                                    else return <></>
                                })}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </div>
        );
    }
}