import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { ProductionSiteLightModelExtended } from '../../models/ProductionSiteLightModelExtended';
import { EditProductionSiteLogisticUnitRequestArgs } from '../../services/dataContracts/controller/EditProductionSiteLogisticUnitRequestArgs';

interface EditProductionSiteLogisticalZoneDialogComponentProperties {
    productionSiteToEdit: ProductionSiteLightModelExtended,
    editLogisticUnit: (productionSiteChoiceLogisticsUnits: EditProductionSiteLogisticUnitRequestArgs) => void
}

export const EditProductionSiteLogisticalZoneDialogComponent = (props: EditProductionSiteLogisticalZoneDialogComponentProperties): JSX.Element => {
    const [productionSite, setProductionSite] = useState<ProductionSiteLightModelExtended>({ ...props.productionSiteToEdit });
    const [isProductionSiteChanged, setIsProductionSiteChanged] = useState<boolean>(false);

    const handleChangeProductionSiteField = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, nameField: string): void => {
        const newProductionSite = { ...productionSite };
        newProductionSite[nameField] = event.target.value;

        setProductionSite(newProductionSite);
        setIsProductionSiteChanged(hasProductionSiteChanged(newProductionSite));
    }

    const hasProductionSiteChanged = (newProductionSite: ProductionSiteLightModelExtended): boolean => {
        return (
            isStringValid(newProductionSite.label) &&
            isStringValid(newProductionSite.billingAddress) &&
            isStringValid(newProductionSite.europeanVatNumber) &&
            (
                props.productionSiteToEdit.label != newProductionSite.label ||
                props.productionSiteToEdit.billingAddress != newProductionSite.billingAddress ||
                props.productionSiteToEdit.europeanVatNumber != newProductionSite.europeanVatNumber
            )
        );
    }

    function isStringValid(input: string): boolean {
        return input?.trim().length > 0;
    }
    const updateProductionSiteIsLogistic = (): void => {

        const requestArgs: EditProductionSiteLogisticUnitRequestArgs = {
            productionSiteId: productionSite.productionSiteId,
            label: productionSite.label,
            billingAddress: productionSite.billingAddress,
            europeanVatNumber: productionSite.europeanVatNumber,
        }

        props.editLogisticUnit(requestArgs);
    }

    return (
        <Box display="flex" flexDirection="column" className="edit-content">
            <Box className="customer-title">
                Unité de production : &nbsp;&nbsp; <span className="customer-name">{props.productionSiteToEdit.productionSiteId} </span>
            </Box>

            <Box pl={"6px"} pt={2}>
                <TextField error={!productionSite.label} label="Nom de la zone logistique : " value={productionSite.label ?? ""} className="text-field"
                    helperText={!productionSite.label ? "Il faut obligatoirement mettre un nom de zone logistique." : ''} variant="outlined" onChange={(event) => handleChangeProductionSiteField(event, "label")} />
            </Box>
            <Box pl={"6px"} pt={2}>
                <TextField error={!productionSite.billingAddress} label="Adresse de facturation : " value={productionSite.billingAddress ?? ""} className="text-field"
                    helperText={!productionSite.billingAddress ? "Il faut obligatoirement mettre une adresse de facturation." : ''} variant="outlined" onChange={(event) => handleChangeProductionSiteField(event, "billingAddress")} />
            </Box>
            <Box pl={"6px"} pt={2}>
                <TextField error={!productionSite.europeanVatNumber} label="N° de TVA : intercommunataire" value={productionSite.europeanVatNumber ?? ""} className="text-field"
                    helperText={!productionSite.europeanVatNumber ? "Il faut obligatoirement mettre un N° de TVA : intercommunataire." : ''} variant="outlined" onChange={(event) => handleChangeProductionSiteField(event, "europeanVatNumber")} />
            </Box>

            <Box display="flex" flexDirection="row" justifyContent="flex-end" pt={2} pl={2}>
                <Button variant="contained" color="primary" title="Modifier la zone logistique" onClick={updateProductionSiteIsLogistic}
                    disabled={(!isProductionSiteChanged)} >
                    Modifier
                </Button>
            </Box>
        </Box>
    )
}