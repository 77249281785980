import styles from '../../_variables.scss';

let _gridOffsetFromWindowTop: number = null;

export class SizingUtilities {

    //40 is the row height defined in prop of the kendo grid
    public static rowHeight = 40;

    // gridOffsetFromWindowTop is the height of elements above grid.
    public static gridOffsetFromWindowTop = (isVisualisationPopup?: boolean): number => {
        if (!_gridOffsetFromWindowTop) {
            _gridOffsetFromWindowTop = parseInt(isVisualisationPopup ? styles.visualisationGridOffsetFromWindowTop : styles.vehicleGridOffsetFromWindowTop);
        }

        return _gridOffsetFromWindowTop;
    }

    // window.innerHeight get the hight of page from navigator size
    // 80% is the height of popup relative navigator size
    public static computeGridHeight = (gridOffsetFromWindowTop: number, isVisualisationPopup?: boolean): number => {
        const windowHeight = isVisualisationPopup ? (window.innerHeight * 80) / 100 : window.innerHeight;
        return windowHeight - gridOffsetFromWindowTop;
    }

    // rowHeight is the row height defined in prop of the kendo grid
    public static computeGridPageSize = (gridHeight: number, rowHeight: number): number => {
        return Number((gridHeight / rowHeight).toFixed(0));
    }

}

