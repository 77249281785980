import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import React from "react";

interface MapMarkerButtonProps {
    text: string,
    handleClickButton: () => void,
    isDisable: boolean
}

export const MapMarkerButton = (props: MapMarkerButtonProps): JSX.Element => {
    return (
        <IconButton size="medium" onClick={props.handleClickButton} className="map-marker-button" disabled={props.isDisable}>
            <FontAwesomeIcon className={props.isDisable ? "map-marker-disabled" : "map-marker"} size="lg" icon={faMapMarker} />
            <span className="map-marker-text">{props.text}</span>
        </IconButton>
    );
}