import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { updateMode } from '../TransportFlowForm';
import '../TransportFlowStyles.scss';

interface TransportFlowHeaderComponentProps {
    mode: string,
    flowBusinessId: string
}

export const TransportFlowHeaderComponent = (props: TransportFlowHeaderComponentProps): JSX.Element => {
    return (
        <Box display="flex" flexWrap="nowrap" flexDirection="row" className="transport-flow-form-header" alignItems="center">
            <Box display="flex" alignItems="center">
                <IconButton size="medium">
                    <FontAwesomeIcon className="fa-pen-icon" size="lg" icon={faPen} />
                </IconButton>
            </Box>
            <Box display="flex" flexDirection="column" className="title" alignItems="start" width="25%">
                <span>{(props.mode === updateMode ? "Modification d'un Flux" : "Création d'un nouveau Flux")}</span>
                <span className="title-flow-businessid">{props.mode === updateMode ? props.flowBusinessId : ""}</span>
            </Box>
            <Box display="flex" className="alert-required-fields" alignItems="center" justifyContent="center" width="50%">
                Veuillez renseigner les champs obligatoires
            </Box>
        </Box>
    );
}
