import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderCellProps, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { CustomCheckboxCell } from '../../Common/CustomCheckboxCell';
import { CustomHeaderCellComponent } from '../../Common/CustomHeaderCellComponent';
import { SizingUtilities } from '../../SizingUtilities';
import { Level2ActivityLightModelExtended } from '../models/Level2ActivityLightModelExtended';
import { Level2ActivityLightModel } from '../services/dataContracts/queryStack/Level2ActivityLightModel';
import { LogisticsUnitChoiceOfLevel2ActivitiesLightModel } from '../services/dataContracts/queryStack/LogisticsUnitChoiceOfLevel2ActivitiesLightModel';
import { Level2ActivitiesReferentialApiClient } from '../services/Level2ActivitiesReferentialApiClient';

interface Level2ActivitiesComponentProps {
    level2Activities: Array<Level2ActivityLightModel>,
    level2ActivityChoicesLogisticsUnit: Array<LogisticsUnitChoiceOfLevel2ActivitiesLightModel>,
    sort: SortDescriptor[],
    handleSortChange: (e: GridSortChangeEvent) => void,
    handleSortColumnChange: (sortItems: SortDescriptor[]) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const Level2ActivitiesReferentialGridName = 'level2ActivitiesReferential';
const ModuleKey = AppModule.Level2ActivitiesReferential;

export const Level2ActivitiesComponent = (props: Level2ActivitiesComponentProps) => {

    const [skip, setSkip] = useState<number>(0);
    const [level2ActivityChoicesDataMap, setLevel2ActivityChoicesDataMap] = useState<Map<string, Level2ActivityLightModelExtended>>(new Map<string, Level2ActivityLightModelExtended>());
    const [level2ActivityChoicesDataArray, setLevel2ActivityChoicesDataArray] = useState<Array<Level2ActivityLightModelExtended>>([]);

    useEffect(() => {
        getLevel2ActivityChoicesData();
    }, []);

    const forceUpdate = useForceUpdate();

    const getLevel2ActivityChoicesData = (): void => {
        const dataMap: Map<string, Level2ActivityLightModelExtended> = new Map<string, Level2ActivityLightModelExtended>();
        const dataArray: Array<Level2ActivityLightModelExtended> = [];

        props.level2Activities.forEach((level2Activity: Level2ActivityLightModel) => {
            const element: Level2ActivityLightModelExtended = { ...level2Activity };

            let checked = true;
            let indeterminateAll = true;
            let numberOfChecked = 0;
            let numberOfUnchecked = 0;

            props.level2ActivityChoicesLogisticsUnit.forEach((logisticsUnit: LogisticsUnitChoiceOfLevel2ActivitiesLightModel) => {
                element[logisticsUnit.logisticsUnitId] = logisticsUnit.chosenLevel2Activities.includes(element.id);
                if (!logisticsUnit.chosenLevel2Activities.includes(element.id)) {
                    checked = false;
                    numberOfUnchecked += 1;
                } else {
                    numberOfChecked += 1;
                }
            });

            if (props.level2ActivityChoicesLogisticsUnit.length === numberOfUnchecked || props.level2ActivityChoicesLogisticsUnit.length === numberOfChecked) {
                indeterminateAll = false;
            }
            element.isIndeterminateAll = indeterminateAll;
            element.isSelectedAll = checked ? true : (!indeterminateAll ? false : null);

            dataMap.set(element.id, element);
            dataArray.push(element);
        });

        setLevel2ActivityChoicesDataMap(dataMap);
        setLevel2ActivityChoicesDataArray(dataArray);
    }

    const getLevel2ActivitiesWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, Level2ActivitiesReferentialGridName, fieldName, columnWidth);
    }

    const getLevel2ActivityGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, Level2ActivitiesReferentialGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            Level2ActivitiesReferentialGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, Level2ActivitiesReferentialGridName, event.columns);
        forceUpdate();
    }

    const updateChoicesLevel2ActivitiesLogisticsUnit = (checked: boolean, LogisticsUnitId: string, level2ActivityId: string): void => {
        Level2ActivitiesReferentialApiClient.AddOrRemoveLevel2ActivityChoice({
            level2ActivityId: level2ActivityId,
            logisticsUnitId: LogisticsUnitId,
            shouldAdd: checked
        })
            .then(() => {
                const item = level2ActivityChoicesDataMap.get(level2ActivityId);
                item[LogisticsUnitId] = checked;

                let checkedSelect = true;
                let indeterminateAll = true;
                let numberOfChecked = 0;
                let numberOfUnchecked = 0;
                props.level2ActivityChoicesLogisticsUnit.forEach((logisticsUnit: LogisticsUnitChoiceOfLevel2ActivitiesLightModel) => {
                    if (!item[logisticsUnit.logisticsUnitId]) {
                        checkedSelect = false;
                        numberOfUnchecked += 1;
                    } else {
                        numberOfChecked += 1;
                    }
                });
                if (props.level2ActivityChoicesLogisticsUnit.length === numberOfUnchecked || props.level2ActivityChoicesLogisticsUnit.length === numberOfChecked) {
                    indeterminateAll = false;
                }

                item.isSelectedAll = checkedSelect ? true : (indeterminateAll ? null : false);
                item.isIndeterminateAll = indeterminateAll;

                forceUpdate();
            });
    }

    const updateChoicesLevel2ActivitiesAllLogisticsUnits = (checked: boolean, level2ActivityId: string): void => {
        const logisticsUnitIds: Array<string> = [];
        props.level2ActivityChoicesLogisticsUnit.forEach((element: LogisticsUnitChoiceOfLevel2ActivitiesLightModel) => {
            logisticsUnitIds.push(element.logisticsUnitId);
        });

        Level2ActivitiesReferentialApiClient.AddOrRemoveLevel2ActivityChoices({
            level2ActivityId: level2ActivityId,
            shouldAdd: checked,
            logisticsUnitIds: logisticsUnitIds
        })
            .then(() => {
                const item = level2ActivityChoicesDataMap.get(level2ActivityId);
                logisticsUnitIds.forEach((logisticsUnitId: string) => {
                    item[logisticsUnitId] = checked;
                });
                item.isSelectedAll = checked ? true : false;
                item.isIndeterminateAll = false;

                forceUpdate();
            });
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.level2Activities.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData = orderBy(level2ActivityChoicesDataArray, props.sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="level2Activities-grid-content">
            <Grid
                className="level2Activities-grid"
                data={gridData}
                sortable
                reorderable
                resizable
                sort={props.sort}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={props.handleSortChange}
                editField="inEdit"
                rowHeight={rowHeight}
                scrollable="virtual"
                skip={skip}
                total={totalGrid}
                pageSize={gridPageSize}
                onPageChange={pageChange}
                style={gridStyle}
            >
                <GridColumn field="isSelectedAll" orderIndex={getLevel2ActivityGridOrderIndexColumn("isSelectedAll", 0)} width={getLevel2ActivitiesWidth("isSelectedAll", 100)} title="Sélection"
                    cell={(props: GridCellProps) =>
                        <CustomCheckboxCell
                            {...props}
                            keyName="id"
                            updateChoicesAllLogisticsUnits={updateChoicesLevel2ActivitiesAllLogisticsUnits}
                        />}
                    headerCell={(headerProps: GridHeaderCellProps) =>
                        <CustomHeaderCellComponent tooltip="Activer/Désactiver une activité pour toutes les zones logistiques" title={headerProps.title} field="isSelectedAll" sort={props.sort}
                            handleSortColumnChange={props.handleSortColumnChange} />
                    }
                />
                <GridColumn field="id" orderIndex={getLevel2ActivityGridOrderIndexColumn("id", 1)} width={getLevel2ActivitiesWidth("id", 120)} title="Id" />
                <GridColumn field="label" orderIndex={getLevel2ActivityGridOrderIndexColumn("label", 2)} width={getLevel2ActivitiesWidth("label", 120)} title="Libellé" />
                {props.level2ActivityChoicesLogisticsUnit.map((logisticsUnit: LogisticsUnitChoiceOfLevel2ActivitiesLightModel, i: number) =>
                    <GridColumn key={i} field={logisticsUnit.logisticsUnitId} orderIndex={getLevel2ActivityGridOrderIndexColumn(logisticsUnit.logisticsUnitId, 4 + i)} width={getLevel2ActivitiesWidth(logisticsUnit.logisticsUnitId, 150)} title={logisticsUnit.label}
                        cell={(props: GridCellProps) =>
                            <CustomCheckboxCell
                                keyName="id"
                                {...props}
                                updateChoicesLogisticsUnit={updateChoicesLevel2ActivitiesLogisticsUnit}
                            />}
                        headerCell={(headerProps: GridHeaderCellProps) =>
                            <CustomHeaderCellComponent tooltip="Activer/Désactiver une activité pour la zone logistique" title={headerProps.title} field={logisticsUnit.logisticsUnitId} sort={props.sort}
                                handleSortColumnChange={props.handleSortColumnChange} />
                        }
                    />
                )}
            </Grid>
        </Box>
    );
}
