import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Input, InputAdornment } from '@mui/material';
import React from 'react';
import { SelectedInternalDriverModel } from '../models/SelectedInternalDriverModel';

interface HeaderContentComponentProps {
    inputSearchInternalDriversValue: string,
    inputSearchInternalDriversRef: React.RefObject<HTMLInputElement>,
    selectedInternalDriver: SelectedInternalDriverModel,
    loadingInternalDrivers: boolean,
    isForTemporaryDrivers: boolean,
    handleInternalDriversKeyPress: (text: string) => void,
    handleClearSearchText: () => void,
    handleAddInternalDriverDialogClick: () => void,
    handleEditInternalDriverDialogClick: () => void
    handleAddTemporaryDriverDialogClick: () => void,
    handleEditTemporaryDriverDialogClick: () => void
}

export const HeaderContentComponent = (props: HeaderContentComponentProps): JSX.Element => {

    const { isForTemporaryDrivers } = props;

    const inputSearchInternalDriversClassName: string = props.inputSearchInternalDriversValue.length > 2 ? 'search-text-active' : '';

    return (
        <Box display="flex" flexDirection="row" flex="wrap" justifyContent="start" alignItems="center" className="header-content">
            <Box>
                <Input disableUnderline className={inputSearchInternalDriversClassName} inputRef={props.inputSearchInternalDriversRef}
                    startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                    endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => props.handleClearSearchText()} /></InputAdornment>}
                    placeholder="Rechercher ..." onChange={(event) => props.handleInternalDriversKeyPress(event.target.value)} />
            </Box>
            <Box ml={'10px'}>
                <Button variant="contained" color="primary" title="Ajouter"
                    onClick={isForTemporaryDrivers ? props.handleAddTemporaryDriverDialogClick : props.handleAddInternalDriverDialogClick}>
                    Ajouter
                </Button>
            </Box>
            <Box ml={'10px'}>
                <Button variant="contained" color="primary" title="Modifier"
                    disabled={!props.selectedInternalDriver.employeeId || props.loadingInternalDrivers}
                    onClick={isForTemporaryDrivers ? props.handleEditTemporaryDriverDialogClick : props.handleEditInternalDriverDialogClick}>
                    Modifier
                </Button>
            </Box>
        </Box>
    );
}
