import { faClock, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { DateInput, DateInputChangeEvent, DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React from 'react';
import Select, { createFilter, GroupBase } from 'react-select';
import { SelectComponentsConfig } from 'react-select/dist/declarations/src/components';
import { SelectInputItemModel } from '../models/SelectInputItemModel';

interface SelectInputProps {
    options: Array<SelectInputItemModel>,
    value: SelectInputItemModel,
    handleInputChange?: (text: string) => void,
    handleChange: (e: SelectInputItemModel) => void,
    required?: boolean,
    isDisabled?: boolean,
    title: string,
    className?: string,
    titleClassName: string,
    components?: SelectComponentsConfig<SelectInputItemModel, false, GroupBase<SelectInputItemModel>>
}

interface DatePickerInputProps {
    handleChange: (event: DatePickerChangeEvent) => void,
    disabled?: boolean,
    min?: Date,
    value?: Date,
    refDate?: React.MutableRefObject<DatePicker>,
    title: string,
    titleClassName: string
}

interface NumericTextInputProps {
    className: string,
    handleChange: (event: NumericTextBoxChangeEvent) => void,
    handleChangeBilledUnitPriceIsLocked?: () => void,
    width?: number | string,
    value: number,
    disabled?: boolean,
    max?: number,
    maximumFractionDigits?: number,
    title?: string,
    titleClassName?: string,
    iconClassName?: string,
    boxClassName?: string,
    iconText?: string,
    isCurrency?: boolean,
    isNegotiatedSellPrice?: boolean,
    sellPriceIsLocked?: boolean,
    isSalePriceFieldLock?: boolean
}

interface DateInputTextProps {
    value: Date,
    handleChange: (event: DateInputChangeEvent<DateInput>) => void,
    required?: boolean,
    disabled?: boolean
}

export const SelectInput = (props: SelectInputProps): JSX.Element => {
    let className = props.required ? "input-select required " : "input-select ";
    className = props.className ? className + props.className : className;
    return (
        <Box display="flex" flexDirection="column" mr={"20px"}>
            <Box className={props.titleClassName}>{props.title}</Box>
            <Box display="flex" flexDirection="row">
                {props.components ?
                    <Select
                        className={className}
                        components={props.components}
                        options={props.options}
                        value={props.value}
                        onInputChange={props.handleInputChange}
                        onChange={props.handleChange}
                        filterOption={createFilter({
                            matchFrom: 'any',
                            stringify: option => `${option.label}`,
                        })}
                        isClearable
                        placeholder=""
                        isOptionDisabled={(option) => option.disabled === true}
                        isDisabled={props.isDisabled}
                        menuPosition="fixed"
                        menuShouldBlockScroll={true}
                    />
                    : <Select
                        className={className}
                        options={props.options}
                        value={props.value}
                        components={props.components}
                        onInputChange={props.handleInputChange}
                        onChange={props.handleChange}
                        filterOption={createFilter({
                            matchFrom: 'any',
                            stringify: option => `${option.label}`,
                        })}
                        isClearable
                        placeholder=""
                        isOptionDisabled={(option) => option.disabled === true}
                        isDisabled={props.isDisabled}
                        menuPosition="fixed"
                        menuShouldBlockScroll={true}
                    />
                }
            </Box>
        </Box>
    );
}

export const DatePickerInput = (props: DatePickerInputProps): JSX.Element => {
    return (
        <LocalizationProvider language="fr-FR">
            <IntlProvider locale="fr" >
                <Box display="flex" flexDirection="column" mr={"20px"}>
                    <Box className={props.titleClassName}>{props.title}</Box>
                    <Box display="flex" flexDirection="row">
                        <DatePicker onChange={props.handleChange} disabled={props.disabled} min={props.min} value={props.value}
                            required ref={props.refDate} />
                    </Box>
                </Box>
            </IntlProvider>
        </LocalizationProvider>
    );
}

export const NumericTextInput = (props: NumericTextInputProps): JSX.Element => {
    return (
        <LocalizationProvider language="fr-FR">
            <IntlProvider locale="fr" >
                <Box display="flex" flexDirection="column" mr={"20px"} className={props.boxClassName ? props.boxClassName : ''}>
                    {props.title && <Box display="flex" flexDirection="row" className={props.titleClassName}>
                        <Box>
                            {props.title} {props.isNegotiatedSellPrice ? <sup className="negotiated-info" title="Tarif de vente Négocié">N</sup> : ''}
                        </Box>
                        {props.isSalePriceFieldLock &&
                            <Box display="flex" flexDirection="column" alignItems="center" pl={0.5} className={props.sellPriceIsLocked ? "billed-unit-price-locked" : "billed-unit-price-not-locked"} onClick={props.handleChangeBilledUnitPriceIsLocked}>
                                {props.sellPriceIsLocked
                                    ? <FontAwesomeIcon size="1x" icon={faLock} />
                                    : <FontAwesomeIcon size="1x" icon={faUnlock} />}
                            </Box>
                        }
                    </Box>}
                    <Box display="flex" flexDirection="row" width={props.width} className={props.className}>
                        <NumericTextBox className="input-text"
                            min={0}
                            format={{
                                maximumFractionDigits: props.maximumFractionDigits !== undefined ? props.maximumFractionDigits : 2
                            }}
                            spinners={false}
                            onChange={props.handleChange}
                            value={props.value}
                            disabled={props.disabled}
                            max={props.max}
                        />
                        {props.isCurrency &&
                            <i className="euro-symbole">€</i>
                        }
                        {props.iconClassName && props.iconText &&
                            <Box display="flex" flexDirection="row" className={props.iconClassName}>{props.iconText}</Box>
                        }
                    </Box>
                </Box>
            </IntlProvider>
        </LocalizationProvider>
    );
}

export const DateInputText = (props: DateInputTextProps): JSX.Element => {
    return (
        <>
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <DateInput
                        disabled={props.disabled}
                        spinners={true}
                        format={"HH:mm"}
                        value={props.value}
                        onChange={props.handleChange}
                        className={props.required ? "date-input required" : "date-input"}
                    />
                </IntlProvider>
            </LocalizationProvider>
            <FontAwesomeIcon className="fa-clock-icon" size="lg" icon={faClock} />
        </>
    );
}