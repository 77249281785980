import { Tooltip } from "@mui/material"
import React, { useEffect, useState } from "react"

interface OverflowTextProps {
    text: string
    customTooltip?: string
    customTooltipWhenOverflow?: string
}

export const OverflowTextComponent = (props: OverflowTextProps) => {
    const textElementRef = React.useRef<HTMLDivElement>()
    const [isOverflow, setIsOverflow] = useState(false)

    const updateIsOverflow = () => {
        const textIsOverflow = textElementRef.current.scrollWidth > textElementRef.current.clientWidth
        setIsOverflow(textIsOverflow)
    }

    // compare once and add resize listener on "componentDidMount"
    useEffect(() => {
        updateIsOverflow();
        window.addEventListener('resize', updateIsOverflow);
    }, []);

    // remove resize listener again on "componentWillUnmount"
    useEffect(() => () => {
        window.removeEventListener('resize', updateIsOverflow);
    }, []);

    let tooltipTitle;

    if (isOverflow) {
        tooltipTitle = props.customTooltipWhenOverflow ?? props.customTooltip ?? props.text;
    }
    else {
        tooltipTitle = props.customTooltip ?? "";
    }

    return (
        <Tooltip
            title={tooltipTitle}
            disableHoverListener={!isOverflow && !props.customTooltip}
            placement="bottom"
        >
            <div ref={textElementRef} style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
            }}>
                {props.text}
            </div>
        </Tooltip>
    )
}