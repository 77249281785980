import { Box } from '@mui/material';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ToastService from 'src/ToastService';
import { ScaleLoaderComponent } from '../../../shared/components/Common/ScaleLoaderComponent';
import SimpleDialog from '../../../shared/components/Common/SimpleDialog';
import { LogisticsUnitChoice } from '../../../shared/models/LogisticsUnitChoice';
import BusinessErrors from '../../../utils/BusinessErrors';
import '../ReferentialsStyles.scss';
import { AddDispatcherAgenciesDialogComponent } from './components/AddDispatcherAgenciesDialogComponent';
import { AddDispatcherDialogComponent } from './components/AddDispatcherDialogComponent';
import { DispatcherAgenciesComponent } from './components/DispatcherAgenciesComponent';
import { DispatchersComponent } from './components/DispatchersComponent';
import { HeaderContentComponent } from './components/HeaderContentComponent';
import './DispatchersReferentialStyles.scss';
import { DispatcherAgencyLightModelExtended } from './models/DispatcherAgencyLightModelExtended';
import { DispatcherLightModelExtended } from './models/DispatcherLightModelExtended';
import { ReactSelectModel } from './models/ReactSelectModel';
import { AddDispatcherManagedAgenciesRequestArgs } from './services/dataContracts/controller/AddDispatcherManagedAgenciesRequestArgs';
import { AddDispatcherRequestArgs } from './services/dataContracts/controller/AddDispatcherRequestArgs';
import { DispatcherCandidateToAdd } from './services/dataContracts/controller/DispatcherCandidateToAdd';
import { RemoveDispatcherManagedAgenciesRequestArgs } from './services/dataContracts/controller/RemoveDispatcherManagedAgenciesRequestArgs';
import { AgencyLightModel } from './services/dataContracts/queryStack/AgencyLightModel';
import { DispatchersReferentialApiClient } from './services/DispatchersReferentialApiClient';

interface DispatchersReferentialViewProps {
    logisticsUnits: Array<LogisticsUnitChoice>
}

export const DispatchersReferentialView = (props: DispatchersReferentialViewProps): JSX.Element => {

    const inputSearchDispatchersRef: React.RefObject<HTMLInputElement> = React.useRef(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingAgenciesOfDispatcher, setLoadingAgenciesOfDispatcher] = useState<boolean>(false);
    const [isAddDispatcherDialogOpened, setIsAddDispatcherDialogOpened] = useState<boolean>(false);
    const [isAddDispatcherAgenciesDialogOpened, setIsAddDispatcherAgenciesDialogOpened] = useState<boolean>(false);
    const [dispatchersList, setDispatchersList] = useState<DispatcherLightModelExtended[]>([]);
    const [selectedDispatcher, setSelectedDispatcher] = useState<string>(null);
    const [userName, setUserName] = useState<string>("");
    const [dispatcherAgenciesList, setDispatcherAgenciesList] = useState<DispatcherAgencyLightModelExtended[]>([]);
    const [agencyList, setAgencyList] = useState<AgencyLightModel[]>([]);
    const [selectedAgencies, setSelectedAgencies] = useState<ReactSelectModel[]>([]);

    useEffect(() => {
        getDispatchers("");
    }, []);

    const clearSearchText = (): void => {
        inputSearchDispatchersRef.current.value = "";
        dispatchersKeyPressed("");
    }

    const dispatchersKeyPress = debounce((text: string): void => {
        if (text.length >= 3) {
            dispatchersKeyPressed(text);
        } else if (text.length === 0) {
            dispatchersKeyPressed(text);
        }
    }, 500);

    const dispatchersKeyPressed = (text: string): void => {
        getDispatchers(text);
    }

    const inputSearchDispatchersValue: string = inputSearchDispatchersRef.current === null || inputSearchDispatchersRef.current === undefined ? "" : inputSearchDispatchersRef.current.value;

    const getDispatchers = (searchText: string): void => {
        setLoading(true);
        DispatchersReferentialApiClient.GetDispatchers(searchText)
            .then(response => {
                const dispatchersList: DispatcherLightModelExtended[] = response.data;
                dispatchersList.map((item: DispatcherLightModelExtended) => item.selected = false);
                setDispatchersList(dispatchersList);
            })
            .finally(() => {
                setLoading(false);
                setSelectedDispatcher(null);
            });
    }

    const handleAddDispatcherDialogClick = (): void => {
        setIsAddDispatcherDialogOpened(true);
    }

    const handleCloseAddDispatcherDialog = (): void => {
        setIsAddDispatcherDialogOpened(false);
        setUserName("");
    }

    const handleAddDispatcherAgenciesDialogClick = (): void => {
        setIsAddDispatcherAgenciesDialogOpened(true);

        DispatchersReferentialApiClient.GetAgencies()
            .then((res) => {
                setAgencyList(res.data);
            });
    }

    const handleRemoveDispatcherAgencies = (): void => {
        const requestArgs: RemoveDispatcherManagedAgenciesRequestArgs = {
            userName: selectedDispatcher,
            agencyIds: dispatcherAgenciesList.filter(x => x.selected === true).map(x => x.agencyId)
        };

        DispatchersReferentialApiClient.RemoveDispatcherManagedAgencies(requestArgs)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast('', errors);

                    return;
                }

                getDispatcherAgencies(selectedDispatcher);
            });
    }

    const handleCloseAddDispatcherAgenciesDialog = (): void => {
        setIsAddDispatcherAgenciesDialogOpened(false);
        setSelectedAgencies([]);
    }

    const handleSelectionChange = (checked: boolean, dataItem: DispatcherLightModelExtended): void => {
        const dispatchers = [...dispatchersList];
        dispatchers.map(x => x.selected = false);
        const element = dispatchers.find(d => d.userName === dataItem.userName);
        element.selected = checked;
        setDispatchersList(dispatchers);

        if (checked)
            getDispatcherAgencies(dataItem.userName);
        else
            setSelectedDispatcher(null);
    }

    const handleDispatcherRowClick = (dataItem: DispatcherLightModelExtended): void => {
        const dispatchers = [...dispatchersList];
        const element = dispatchers.find(d => d.userName === dataItem.userName);
        element.selected = !element.selected;
        dispatchers.filter(x => x.userName !== element.userName).map(x => x.selected = false);
        setDispatchersList(dispatchers);

        if (element.selected)
            getDispatcherAgencies(dataItem.userName);
        else
            setSelectedDispatcher(null);
    }

    const getDispatcherAgencies = (userName: string): void => {
        setLoadingAgenciesOfDispatcher(true);
        setSelectedDispatcher(userName);

        DispatchersReferentialApiClient.GetDispatcherAgencies(userName)
            .then((res) => {
                const dispatcherAgenciesList: DispatcherAgencyLightModelExtended[] = res.data;
                dispatcherAgenciesList.map((item: DispatcherAgencyLightModelExtended) => item.selected = false);
                setDispatcherAgenciesList(dispatcherAgenciesList);
                setLoadingAgenciesOfDispatcher(false);
            });
    }

    const handleChangeUserName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setUserName(event.target.value);
    }

    const handleAddContactClick = (): void => {
        const username = userName.trim().toLocaleLowerCase();
        DispatchersReferentialApiClient.SearchDispatcher(username)
            .then((res) => {
                if (res.data) {
                    const dispatcherCandidateToAdd: DispatcherCandidateToAdd = res.data;
                    if (dispatcherCandidateToAdd) {
                        switch (dispatcherCandidateToAdd.existInLorieDb) {
                            case true:
                                if (dispatcherCandidateToAdd.roleId === "DIS")
                                    handleSearchInReferentialGrid(username);
                                else
                                    ToastService.showErrorToast("Cet utilisateur est déjà présent dans LORIE avec le profil ( " + dispatcherCandidateToAdd.roleId + " ). Contacter l'Admin.");
                                return;

                            case false:
                                var requestArgs: AddDispatcherRequestArgs = {
                                    userName: username,
                                    firstName: dispatcherCandidateToAdd.firstName,
                                    lastName: dispatcherCandidateToAdd.lastName,
                                    email: dispatcherCandidateToAdd.email,
                                    phoneNumber: dispatcherCandidateToAdd.phoneNumber,
                                    isEnabled: dispatcherCandidateToAdd.isEnabled
                                };

                                DispatchersReferentialApiClient.AddDispatcher(requestArgs)
                                    .then((res) => {
                                        const data = res?.data;
                                        const errors = BusinessErrors.Get(data);
                                        if (errors.length > 0) {
                                            ToastService.showErrorToast("", errors);
                                            return;
                                        }

                                        inputSearchDispatchersRef.current.value = username;
                                        handleCloseAddDispatcherDialog();
                                        getDispatchers(username);
                                    });
                                return;

                            default:
                                return;
                        }
                    }
                }

                ToastService.showErrorToast("Aucun dispatcheur trouvé dans le référentiel");
            });
    }

    const handleSearchInReferentialGrid = (userName: string): void => {
        inputSearchDispatchersRef.current.value = userName;
        handleCloseAddDispatcherDialog();
        getDispatchers(userName);

        ToastService.showSuccessToast("Ce dispatcheur est déjà présent dans LORIE.");
    }

    const handleAgencySelectionChange = (checked: boolean, dataItem: DispatcherAgencyLightModelExtended): void => {
        const dispatcherAgencies = [...dispatcherAgenciesList];
        const element = dispatcherAgencies.find(d => d.agencyId === dataItem.agencyId);
        element.selected = checked;
        setDispatcherAgenciesList(dispatcherAgencies);
    }

    const handleDispatcherAgencyRowClick = (dataItem: DispatcherAgencyLightModelExtended): void => {
        const dispatcherAgencies = [...dispatcherAgenciesList];
        const element = dispatcherAgencies.find(d => d.agencyId === dataItem.agencyId);
        element.selected = !element.selected;
        setDispatcherAgenciesList(dispatcherAgencies);
    }

    const handleChangeAgenciesSelected = (opts: Array<ReactSelectModel>): void => {
        setSelectedAgencies(opts);
    }

    const handleClickValidateDispatcherAgencies = (): void => {
        const requestArgs: AddDispatcherManagedAgenciesRequestArgs = {
            userName: selectedDispatcher,
            agencyIds: selectedAgencies.map(x => x.value)
        };

        DispatchersReferentialApiClient.AddDispatcherManagedAgencies(requestArgs)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                getDispatcherAgencies(selectedDispatcher);
                handleCloseAddDispatcherAgenciesDialog();
            });
    }

    const headerContentComponent: JSX.Element = useMemo(() =>
        <HeaderContentComponent
            inputSearchDispatchersValue={inputSearchDispatchersValue}
            inputSearchDispatchersRef={inputSearchDispatchersRef}
            handleDispatchersKeyPress={dispatchersKeyPress}
            handleClearSearchText={clearSearchText}
            handleAddDispatchersDialogClick={handleAddDispatcherDialogClick}
        />, [inputSearchDispatchersValue, inputSearchDispatchersRef]);

    const dispatchersComponent: JSX.Element = useMemo(() =>
        <DispatchersComponent
            dispatchersList={dispatchersList}
            handleSelectionChange={handleSelectionChange}
            handleDispatcherRowClick={handleDispatcherRowClick}
        />, [dispatchersList]);

    const dispatcherAgenciesComponent: JSX.Element = useMemo(() =>
        <DispatcherAgenciesComponent
            selectedDispatcher={selectedDispatcher}
            dispatcherAgenciesList={dispatcherAgenciesList}
            handleAgencySelectionChange={handleAgencySelectionChange}
            handleAddDispatcherAgenciesDialogClick={handleAddDispatcherAgenciesDialogClick}
            handleRemoveDispatcherAgencies={handleRemoveDispatcherAgencies}
            handleDispatcherAgencyRowClick={handleDispatcherAgencyRowClick}
        />, [selectedDispatcher, dispatcherAgenciesList]);

    return (
        <Box className="dispatchers referencial">
            <Box display="flex" flexDirection="row" flex="wrap" className="view-title">
                Dispatcheurs Colas
            </Box>
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box display="flex" flexDirection="column" flex="wrap">
                        {headerContentComponent}
                        {(loading ?
                            <ScaleLoaderComponent loading={loading} />
                            :
                            (<Box display="flex" flexDirection="row" width="100%">
                                <Box width="40%">
                                    {dispatchersComponent}
                                </Box>
                                <Box width="50%">
                                    {loadingAgenciesOfDispatcher ?
                                        <ScaleLoaderComponent loading={loadingAgenciesOfDispatcher} />
                                        :
                                        selectedDispatcher &&
                                        dispatcherAgenciesComponent
                                    }
                                </Box>
                            </Box>)
                        )}
                    </Box>
                </IntlProvider>
            </LocalizationProvider>
            <SimpleDialog isOpen={isAddDispatcherDialogOpened}
                onClose={handleCloseAddDispatcherDialog}
                closeIcon={true}
                dialogTitle="Ajouter un dispatcheur"
                classNameVal="add-dispatcher-dialog"
                component={
                    <AddDispatcherDialogComponent
                        userName={userName}
                        handleChangeUserName={handleChangeUserName}
                        handleAddContactClick={handleAddContactClick}
                    />
                }
            />
            <SimpleDialog isOpen={isAddDispatcherAgenciesDialogOpened}
                onClose={handleCloseAddDispatcherAgenciesDialog}
                closeIcon={true}
                dialogTitle="Associer des agences à un dispatcheur"
                classNameVal="add-dispatcher-agencies-dialog"
                component={
                    <AddDispatcherAgenciesDialogComponent
                        agencyList={agencyList}
                        dispatcherAgenciesList={dispatcherAgenciesList}
                        selectedAgencies={selectedAgencies}
                        handleChangeAgenciesSelected={handleChangeAgenciesSelected}
                        handleClickValidateDispatcherAgencies={handleClickValidateDispatcherAgencies}
                    />
                }
            />
        </Box>
    );
}
