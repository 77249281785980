import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';

export const CustomDriverInstructionsCell = (props: GridCellProps) => {

    const defaultRendering: JSX.Element = (
        <td>
            {props.dataItem.driverInstructions.map((instruction: string, i: number) => {
                return (
                    <p key={i}>{instruction}</p>
                )
            })}
        </td>
    );

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}
