import { Box } from '@mui/material';
import React from 'react';

//RENAME v1 NotificationFieldAdded devient AddedDeletedProperty
//v2 consider  NotificationFieldAdded devient AddedProperty, DeletedProperty

interface AddedDeletedPropertyComponentProps {
    HiddenProperties: string[],
    propertyLabels: Map<string, string>,
    vehicleTypesLabels?: Map<string, string>,
    elem: string,
    entityType: string,
    getValueOfParsedJson: (any, string) => any,
    valuesParsedTracking: any
}

export default class AddedDeletedPropertyComponent extends React.PureComponent<AddedDeletedPropertyComponentProps, {}>{
    render() {
        const propertyLabel = this.props.propertyLabels.get(`${this.props.entityType}.${this.props.elem}`);
        let value = this.props.getValueOfParsedJson(this.props.valuesParsedTracking, this.props.elem);

        if (this.props.elem === "VehicleTypeId") {
            value = this.props.vehicleTypesLabels.get(value) ?? value;
        }

        return (
            this.props.HiddenProperties.indexOf(this.props.elem) === -1 ? (
                <Box display="flex" flexWrap="nowrap" flexDirection="column">
                    <Box>
                        <div className="field-title">
                            {propertyLabel ? propertyLabel : this.props.elem}
                        </div>
                    </Box>
                    <Box>
                        <div className="field-value">
                            {value}
                        </div>
                    </Box>
                </Box>) : ''
        );
    }
}