import { faSearch, faSms, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Checkbox, FormControlLabel, Input, InputAdornment, TextareaAutosize } from '@mui/material';
import { debounce, groupBy, map, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import Utilities from '../../../utils/Utilities';
import { DriverNotificationModel } from '../models/DriverNotificationModel';
import { PlanningVehicleTripsLightModelExtended } from '../models/PlanningVehicleTripsLightModelExtended';
import { TripLightModelExtended } from '../models/TripLightModelExtended';
import { TripLightModel } from '../services/dataContracts/queryStack/TripLightModel';

interface SmsNotificationsDialogComponentProps {
    resources: PlanningVehicleTripsLightModelExtended[],
    planningDate: Date,
    userDisplayName: string,
    userPhoneNumber: string,
    smsNotificationMaxContentLength: number,
    sendSmsNotifications: (selectedNotifications: DriverNotificationModel[]) => void
}

export const SmsNotificationsDialogComponent = (props: SmsNotificationsDialogComponentProps): JSX.Element => {

    const [searchText, setSearchText] = useState<string>('');
    const [checkAllNotifications, setCheckAllNotifications] = useState<boolean>(false);
    const [driversNotifications, setDriversNotifications] = useState<DriverNotificationModel[]>([]);
    const [filteredDriversNotifications, setFilteredDriversNotifications] = useState<DriverNotificationModel[]>([]);
    const [showInternalDrivers, setShowInternalDrivers] = useState<boolean>(true);
    const [showExternalDrivers, setShowExternalDrivers] = useState<boolean>(true);

    useEffect(() => {
        initialiseNotifications();
    },
        []);

    useEffect(() => {
        const validNotifications = driversNotifications.filter(x => !x.smsMaxContentReached);
        const allValidNotificationSelected = validNotifications.length > 0 && validNotifications.every(x => x.selected);
        setCheckAllNotifications(allValidNotificationSelected);
    },
        [driversNotifications]);

    const initialiseNotifications = (): void => {
        
        const notificationsByDrivers = new Map<number, DriverNotificationModel[]>();

        props.resources.forEach((resource: PlanningVehicleTripsLightModelExtended) => {
            const trips = resource.trips.filter(x => x.status !== "Canceled");
            //order trips by startTime, to define chronological order of trips
            const orderedTrips: TripLightModelExtended[] = map(orderBy(trips, r => [r.startTime], ['asc']), (value: TripLightModel, index: number) => ({
                ...value,
                tripOrderNumber: index + 1
            }));

            //group trips by TransportFlowId, to create sms text for each specific flow
            const group: _.Dictionary<TripLightModelExtended[]> = groupBy(orderedTrips, x => x.transportFlowId);
            const groupedTripsByTransportFLowId: TripLightModelExtended[][] = map(group, (value: TripLightModelExtended[]) => (value));

            groupedTripsByTransportFLowId.forEach((trips: TripLightModelExtended[]) => {

                const driverNotification = {} as DriverNotificationModel;
                const firstTrip = trips[0];
                driverNotification.firstTripStartTime = firstTrip.startTime;
                driverNotification.driverId = resource.driverId;
                driverNotification.driverEmployeeId = resource.driverEmployeeId;
                driverNotification.driverEmployeeIsTemporary = resource.driverEmployeeIsTemporary;
                driverNotification.driverName = resource.driverFullName;
                driverNotification.driverPhoneNumber = resource.driverPhoneNumber;
                driverNotification.planningDateAsNumeric = resource.planningId;
                driverNotification.planningVehicleId = resource.planningVehicleId;
                driverNotification.transporterName = resource.transporter;
                driverNotification.transportFlowId = firstTrip.transportFlowId;
                driverNotification.numberOfSendingSms = resource.smsNotifiedFlows ? resource.smsNotifiedFlows.find(x => x.transportFlowId === firstTrip.transportFlowId)?.count : 0;
                driverNotification.selected = false;

                let smsContent = "Bonjour, pour le " + props.planningDate.toShortDateDayMonthString() + " :\n";

                const isJobsiteVehicle = firstTrip.serviceKind === "JobsiteVehicle";
                if (!isJobsiteVehicle) {
                    trips.forEach((trip: TripLightModelExtended, index: number) => {
                        smsContent += formatTripOrderNumber(trip.tripOrderNumber);
                        smsContent += index === trips.length - 2 ? " et " : (index === trips.length - 1 ? "" : ", ");
                    });

                    smsContent += " - " + trips.length;
                    smsContent += trips.length === 1 ? " tour" : " tours";
                }
                smsContent += "\n";

                if (resource.equipmentId || resource.label)
                    smsContent += (resource.equipmentId ?? resource.label) + "\n";

                smsContent += firstTrip.senderSiteLabel
                    ? `Départ : ${isJobsiteVehicle
                        ? firstTrip.senderSiteLabel
                        : firstTrip.startTime.toShortTimeString() + " - " + firstTrip.senderSiteLabel}`
                    : "";

                // On affiche l'adresse que si le site de départ n'est pas un site de production référencé
                smsContent += firstTrip.senderSiteKind && firstTrip.senderSiteKind !== "ProductionSite" ? (firstTrip.senderSiteJobsiteLabel ? " - " + firstTrip.senderSiteJobsiteLabel : "") + "\n" + Utilities.formatAddress(firstTrip.pickupLine1, firstTrip.pickupLine2, firstTrip.pickupZipCode, firstTrip.pickupCity) : "";
                smsContent += firstTrip.senderSiteKind ? "\n" : "";

                smsContent += firstTrip.receiverSiteLabel
                    ? `Arrivée : ${isJobsiteVehicle ? firstTrip.startTime.toShortTimeString() + " - " : ""}${firstTrip.receiverSiteLabel}`
                    : '';

                // On affiche l'adresse que si le site de départ n'est pas un site de production référencé
                smsContent += firstTrip.receiverSiteKind && firstTrip.receiverSiteKind !== "ProductionSite" ? (firstTrip.receiverSiteJobsiteLabel ? " - " + firstTrip.receiverSiteJobsiteLabel : "") + "\n" + Utilities.formatAddress(firstTrip.deliveryLine1, firstTrip.deliveryLine2, firstTrip.deliveryZipCode, firstTrip.deliveryCity) + "\n" : "\n";

                smsContent += "CCH : " + (firstTrip.beneficiaryPhoneNumber ? firstTrip.beneficiaryName + " - " + Utilities.formatPhoneNumber(firstTrip.beneficiaryPhoneNumber) + "\n" : firstTrip.beneficiaryName + "\n");

                const sumQuantityPlanified = Utilities.sum(trips.filter(t => t.status !== "Canceled"), "quantity");

                if (firstTrip.product || sumQuantityPlanified) {
                    smsContent += firstTrip.product ? "Produit : " + firstTrip.product : "";

                    smsContent += sumQuantityPlanified
                        ? (firstTrip.product ? " - " : "") + "Qté : " + sumQuantityPlanified + (firstTrip.unitOfMeasure ?? "T")
                        : "";
                    smsContent += "\n";
                }
                smsContent += "\n";


                smsContent += firstTrip.transportersInstructions ? firstTrip.transportersInstructions + "\n" : '';

                const tripsWithDriverInstructions: TripLightModelExtended[] = trips.filter(x => x.driverInstructions);
                tripsWithDriverInstructions.forEach((trip: TripLightModelExtended, index: number) => {
                    smsContent += "Tour " + trip.tripOrderNumber + ": " + trip.driverInstructions + "\n";
                    smsContent += index === tripsWithDriverInstructions.length - 1 ? "\n" : ''
                });

                smsContent += props.userDisplayName;
                if (props.userPhoneNumber) {
                    smsContent += "\n" + props.userPhoneNumber;
                }
                driverNotification.smsContent = smsContent;
                driverNotification.smsMaxContentReached = smsContent?.length > props.smsNotificationMaxContentLength;

                if (!notificationsByDrivers.has(driverNotification.driverId)) {
                    notificationsByDrivers.set(driverNotification.driverId, []);
                }

                const driverNotifications = notificationsByDrivers.get(driverNotification.driverId);
                driverNotifications.push(driverNotification);
            });

        });

        const driversNotifications: DriverNotificationModel[] = [];
        for (const driverNotifications of notificationsByDrivers.values()) {
            const notificationsCount = driverNotifications.length;
            orderBy(driverNotifications, x => x.firstTripStartTime).forEach((notification, index) => {
                const notificationNumber = `${index + 1}/${notificationsCount}`;
                const newSmsContent = `${notificationNumber} - ${notification.smsContent}`;

                notification.smsContent = newSmsContent;
                notification.smsMaxContentReached = newSmsContent.length > props.smsNotificationMaxContentLength;

                driversNotifications.push(notification);
            });
        }
        
        setDriversNotifications(driversNotifications);
        setFilteredDriversNotifications(driversNotifications);
    }

    const formatTripOrderNumber = (tripOrderNumber: number): string => {
        return tripOrderNumber === 1 ? tripOrderNumber + "er" : tripOrderNumber + "e";
    }

    const handleClearSearchText = (): void => {
        setSearchText('');
        setFilteredDriversNotifications(driversNotifications);
    }

    const handleSearchKeyPress = (value: string): void => {
        setSearchText(value);

        const filteredNotifications: DriverNotificationModel[] = driversNotifications.filter(buildFilter(value, showInternalDrivers, showExternalDrivers));
        setFilteredDriversNotifications(filteredNotifications);
    }

    const handleSelectNotification = (driverId: number, transportFlowId: string): void => {
        const notifications: DriverNotificationModel[] = [...driversNotifications];
        const index: number = notifications.findIndex(x => x.driverId === driverId && x.transportFlowId === transportFlowId);
        notifications[index].selected = !notifications[index].selected;
        setDriversNotifications(notifications);
    }

    const handleNotificationContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>, driverId: number, transportFlowId: string): void => {
        const smsContent = e.target.value;
        const notifications: DriverNotificationModel[] = [...driversNotifications];
        const index: number = notifications.findIndex(x => x.driverId === driverId && x.transportFlowId === transportFlowId);
        notifications[index].smsContent = smsContent;
        notifications[index].smsMaxContentReached = smsContent?.length > props.smsNotificationMaxContentLength;

        if (notifications[index].smsMaxContentReached && notifications[index].selected) {
            notifications[index].selected = false;
        }
        setDriversNotifications(notifications);
    }

    const handleChangeCheckAll = (): void => {
        const checked = !checkAllNotifications;
        const notifications: DriverNotificationModel[] = [...driversNotifications];
        notifications
            .filter(notification => !notification.smsMaxContentReached)
            .forEach(notification => {
                notification.selected = checked;
            });

        setDriversNotifications(notifications);
    }

    const handleClickSendSms = debounce((): void => {
        props.sendSmsNotifications([...filteredDriversNotifications.filter(x => x.selected === true)]);
    }, 500);

    const handleShowInternalDriversChange = (): void => {
        const newShowInternalDrivers = !showInternalDrivers;
        setShowInternalDrivers(newShowInternalDrivers);

        const filteredNotifications: Array<DriverNotificationModel> = driversNotifications.filter(buildFilter(searchText, newShowInternalDrivers, showExternalDrivers));
        setFilteredDriversNotifications(filteredNotifications);
    }

    const handleShowExternalDriversChange = (): void => {
        const newShowExternalDrivers = !showExternalDrivers;
        setShowExternalDrivers(newShowExternalDrivers);

        const filteredNotifications: Array<DriverNotificationModel> = driversNotifications.filter(buildFilter(searchText, showInternalDrivers, newShowExternalDrivers));
        setFilteredDriversNotifications(filteredNotifications);
    }

    const buildFilter = (text: string, internalDriversActive: boolean, externalDriversActive: boolean) => {
        return (d: DriverNotificationModel) => (
            (internalDriversActive || (!internalDriversActive && d.driverEmployeeId === null))
            && (externalDriversActive || (!externalDriversActive && d.driverEmployeeId !== null)))
            && (!text || (text &&
                (d.driverName.toLowerCase().includes(text.toLowerCase()) || d.transporterName.toLowerCase().includes(text.toLowerCase()) || d.smsContent.toLowerCase().includes(text.toLowerCase()))));
    }

    const isSendButtonDisable: boolean = filteredDriversNotifications.every(x => x.selected === false);

    return (
        <Box display="flex" flexDirection="column" className="sms-notifications-content">
            <Box display="flex" flexDirection="row" alignItems="center">
                <Box display="flex" flexDirection="row" maxHeight="40px" className="search-text" width="58%">
                    <Checkbox
                        checked={checkAllNotifications}
                        onChange={() => handleChangeCheckAll()}
                        color="primary"
                    />
                    <Input disableUnderline value={searchText}
                        className="search-text-area"
                        multiline
                        startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => handleClearSearchText()} /></InputAdornment>}
                        placeholder="Rechercher : chauffeur, transporteur, chef de chantier, site de départ et d’arrivée" onChange={(event) => handleSearchKeyPress(event.target.value)} />
                </Box>
                <Box display="flex" flexDirection="column" ml="20px">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showInternalDrivers}
                                onChange={handleShowInternalDriversChange}
                                color="default"
                            />
                        }
                        className="drivers-label-filter"
                        label="Chauffeurs internes"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showExternalDrivers}
                                onChange={handleShowExternalDriversChange}
                                color="default"
                            />
                        }
                        className="drivers-label-filter"
                        label="Chauffeurs externes"
                    />
                </Box>
                <Box display="flex" flexDirection="row" maxHeight="40px">
                    <Button variant="contained" color="primary" title="Envoyer SMS" disabled={isSendButtonDisable} onClick={handleClickSendSms}>
                        Envoyer SMS
                    </Button>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" className="notifications-list">
                {filteredDriversNotifications.map((notification: DriverNotificationModel, index: number) => {
                    return (
                        <Box key={`notification-${index}`} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className="driver-sms-notification">
                            <Box display="flex" flexDirection="column" justifyContent="flex-start" width="30%">
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <FormControlLabel
                                        id="driver-to-send-sms"
                                        className="driver-name"
                                        control={
                                            <Checkbox
                                                checked={notification.selected}
                                                onChange={() => handleSelectNotification(notification.driverId, notification.transportFlowId)}
                                                color="primary"
                                                disabled={notification.smsMaxContentReached}
                                            />
                                        }
                                        label={notification.driverName}
                                        labelPlacement="end"
                                    />
                                    {notification.driverEmployeeId !== null && <FontAwesomeIcon icon={faUser} className={`fa-user-driver-${!notification.driverEmployeeIsTemporary ? "internal" : "temporary"}`} />}
                                </Box>
                                {notification.numberOfSendingSms > 0 &&
                                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" className="sms-counter">
                                        <Box pr={"10px"}>{notification.numberOfSendingSms}</Box>
                                        <FontAwesomeIcon size="2x" icon={faSms} />
                                    </Box>
                                }
                                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center"
                                    className={`sms-length-counter${notification.smsMaxContentReached ? " error" : ""}`}>
                                    {notification.smsContent.length + "/" + props.smsNotificationMaxContentLength + " Caractères"}
                                </Box>
                                <Box textAlign="center" mt="15px">
                                    {notification.transporterName}
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="flex-end" width="70%" pl={1}>
                                <TextareaAutosize
                                    className={`sms-text-input${notification.smsMaxContentReached ? " error" : ""}`}
                                    maxRows={10}
                                    minRows={10}
                                    maxLength={props.smsNotificationMaxContentLength}
                                    aria-label="sms notification"
                                    value={notification.smsContent}
                                    onChange={(e) => handleNotificationContentChange(e, notification.driverId, notification.transportFlowId)} />
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    );
}