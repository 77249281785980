import { faPen, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton, Input, InputAdornment, Tooltip } from '@mui/material';
import React from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import { WebAppActionResult } from '../../../../shared/models/WebAppActionResult';
import BusinessErrors from '../../../../utils/BusinessErrors';
import Utilities from '../../../../utils/Utilities';
import { UpdatedTransporterDriversRequestArgs } from '../services/dataContracts/controller/UpdatedTransporterDriversRequestArgs';
import { DriverLightModel } from '../services/dataContracts/queryStack/DriverLightModel';
import { ThirdPartyTransporterApiClient } from '../services/ThirdPartyTransporterApiClient';

interface DriversManagementComponentState {
    searchText: string,
    driversList: Array<DriverLightModel>
}

interface IChildComponentProps {
    TransporterId: string,
    updateListAciveDriver: (transporterId: string) => void,
    displayError: (message: string) => void,
    updateDriverDrawerList: boolean,
    setUpdateDriverDrawerListToFalse: () => void,
    handleClickOpen: (dialogType: string, item?: DriverLightModel) => void,
    _handleDrawerDriversClose: () => void
}

class DriversManagementComponent extends React.Component<IChildComponentProps, DriversManagementComponentState> {
    _isMounted: boolean;
    inputSearchDriversRef: React.RefObject<HTMLInputElement>;

    constructor(props: IChildComponentProps) {
        super(props);
        this.inputSearchDriversRef = React.createRef();
        this.state = {
            driversList: [],
            searchText: ''
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillReceiveProps(nextProps: IChildComponentProps) {
        if ((nextProps.TransporterId !== this.props.TransporterId && nextProps.TransporterId !== "-1") || nextProps.updateDriverDrawerList) {
            this.props.setUpdateDriverDrawerListToFalse();
            this.SearchDrivers(nextProps.TransporterId, this.state.searchText);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSearchDriversChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        this.SearchDrivers(this.props.TransporterId, e.target.value);
        this.setState({
            searchText: e.target.value
        });
    }

    clearSearchText = (): void => {
        this.inputSearchDriversRef.current.value = '';
        this.setState({
            searchText: ''
        });
        this.SearchDrivers(this.props.TransporterId, '');
    }

    SearchDrivers = (transporterId: string, searchText: string): void => {
        ThirdPartyTransporterApiClient.SearchDrivers(transporterId, false, searchText)
            .then(res => {
                if (this._isMounted) {
                    this.setState({ driversList: res.data });
                }
            });
    }

    handleChangeDriverEnabled = (driverId: number, isEnabled: boolean): void => {
        const updatedTransporterDrivers: UpdatedTransporterDriversRequestArgs = {
            transporterId: this.props.TransporterId,
            isEnabled: !isEnabled,
            driverIdsList: [driverId]
        }

        ThirdPartyTransporterApiClient.checkTransporterDriver(updatedTransporterDrivers)
            .then(json => {
                if (this._isMounted) {
                    const data: WebAppActionResult = json?.data;
                    const errors: string[] = BusinessErrors.Get(data);
                    if (errors.length > 0) {
                        this.props.displayError("Les modifications ne sont pas pris en compte");
                        return;
                    }

                    const driversListArray: DriverLightModel[] = this.state.driversList;
                    const driver: DriverLightModel = driversListArray.find(d => d.driverId === driverId);
                    driver.isEnabled = !isEnabled;
                    this.setState({
                        driversList: driversListArray
                    });
                    this.props.updateListAciveDriver(this.props.TransporterId);
                }
            });
    }

    render() {
        const { driversList } = this.state;

        const searchTextDriversValue: string = this.inputSearchDriversRef.current === null || this.inputSearchDriversRef.current === undefined ? '' : this.inputSearchDriversRef.current.value;

        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{ 'padding': '15px' }}
            >
                <Box display="flex" pb={2} flexDirection="row" width="100%">
                    <Box className="drawer-title" width="80%">
                        Gestion des chauffeurs
                    </Box>
                    <Box display="flex" width="20%" flexDirection="row" justifyContent="flex-end">
                        <Box style={{ cursor: 'pointer' }} onClick={this.props._handleDrawerDriversClose}>
                            <FontAwesomeIcon size="lg" icon={faTimes} />
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <p>Sélectionner les chauffeurs à afficher dans la/les liste(s) déroulante(s) <b>Chauffeur</b> :</p>
                </Box>
                <Box pb={1}>
                    <Button variant="contained" color="primary" onClick={() => this.props.handleClickOpen('drivers')}>
                        Ajouter un chauffeur
                    </Button>
                </Box>
                <Box width="100%">
                    <Input disableUnderline className={searchTextDriversValue.length > 0 ? 'search-text-active' : ''} inputRef={this.inputSearchDriversRef}
                        startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => this.clearSearchText()} /></InputAdornment>}
                        id="input-search-drivers" placeholder="Rechercher ..." onChange={(event) => this.handleSearchDriversChange(event)} />
                </Box>
                <Box>
                    {driversList.map((driver, index) =>
                        <div key={index}>
                            <div className="switch-section">
                                <label className="switch">
                                    <input type="checkbox" checked={driver.isEnabled}
                                        onChange={() => this.handleChangeDriverEnabled(driver.driverId, driver.isEnabled)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                                <Tooltip title="Modifier le chauffeur" placement="bottom">
                                    <IconButton className="switch-label" size="small" onClick={() => this.props.handleClickOpen('drivers', driver)} >
                                        <FontAwesomeIcon size="1x" icon={faPen} />
                                    </IconButton>
                                </Tooltip>
                                <span><b>{driver.fullName}</b></span><span style={{ whiteSpace: 'pre-wrap' }}>{' - ' + (driver.phoneNumber ? Utilities.formatPhoneNumber(driver.phoneNumber) : '')} </span>
                            </div>
                        </div>
                    )}
                </Box>
            </Box>
        );
    }
}

export default DriversManagementComponent;
