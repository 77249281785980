import { Box, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { ScaleLoaderComponent } from '../../../../../shared/components/Common/ScaleLoaderComponent';
import { WorkAgencyCandidateToAdd } from '../../services/dataContracts/controller/WorkAgencyCandidateToAdd';
import { SearchWorkAgencyStepComponent } from './SearchWorkAgencyStepComponent';
import { SelectWorkAgencyStepComponent } from './SelectWorkAgencyStepComponent';

interface AddWorkAgencyDialogComponentProperties {
    activeStep: number,
    workAgencySearchLoading: boolean,
    agencyId: string,
    workAgencyChoices: WorkAgencyCandidateToAdd,
    handleWorkAgencyIdentifierChange: (value: string) => void,
    searchWorkAgencyByIdentifier: (agencyId: string) => void,
    handleSearchInReferentialGrid: (agencyId: string) => void,
    handlePreviousStep: () => void,
    confirmAddWorkAgency: () => void
}

export const AddWorkAgencyDialogComponent = (props: AddWorkAgencyDialogComponentProperties): JSX.Element => {

    const steps: string[] = ["Chercher", "Confirmer"];

    const getStepContent = (stepIndex: number): JSX.Element => {
        switch (stepIndex) {
            case 0:
                return <SearchWorkAgencyStepComponent
                    workAgencySearchLoading={props.workAgencySearchLoading}
                    agencyId={props.agencyId}
                    handleWorkAgencyIdentifierChange={props.handleWorkAgencyIdentifierChange}
                    searchWorkAgencyByIdentifier={props.searchWorkAgencyByIdentifier}
                />;
            case 1:
                return <SelectWorkAgencyStepComponent
                    workAgencyChoices={props.workAgencyChoices}
                    handleSearchInReferentialGrid={props.handleSearchInReferentialGrid}
                    confirmAddWorkAgency={props.confirmAddWorkAgency}
                    handlePreviousStep={props.handlePreviousStep}
                />;
            default:
                return <></>;
        }
    }

    return (
        <Box className="add-work-agency-component">
            <Box display="flex" flexDirection="row" justifyContent="center" className="loading-content">
                <ScaleLoaderComponent loading={props.workAgencySearchLoading} />
            </Box>
            <Stepper activeStep={props.activeStep} alternativeLabel>
                {steps.map((label: string) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box>{getStepContent(props.activeStep)}</Box>
        </Box>
    );
}