import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { UploadOnAddEvent, UploadOnBeforeUploadEvent, UploadOnStatusChangeEvent, UploadResponse } from '@progress/kendo-react-upload';
import React from 'react';
import { ScaleLoader } from 'react-spinners';
import { SettingsProvider } from '../../../SettingsProvider';
import { AttachmentsApiClient } from '../../../shared/Attachments/services/AttachmentsApiClient';
import { UploadComponent } from '../../../shared/components/Common/UploadComponent';
import { CommandResultLight, WebAppActionResultEx } from '../../../shared/models/WebAppActionResult';
import { downloadFileFromBlob, getFileName } from '../../../utils/DownloadFile';
import { AddedAttachmentResult } from '../services/dataContracts/controller/AddedAttachmentResult';
import { AttachmentArgs } from '../services/dataContracts/controller/AttachmentArgs';
import { AttachmentStatus } from '../services/dataContracts/controller/AttachmentStatus';
import { TransportFlowApiClient } from '../services/TransportFlowApiClient';

interface AttachmentsComponentProps {
    attachmentFiles: Array<AttachmentArgs>,
    isLoadingAttachments: boolean,
    handleAdd: (event: UploadOnAddEvent) => void,
    handleDeleteAttachment: (attachmentId: string, state: AttachmentStatus) => void,
    handlerAfterUploadAttachment: (attachments: AddedAttachmentResult[]) => void,
    handlerBeforeUpload: (event: UploadOnBeforeUploadEvent) => void,
    handlerDisplayErrorsAfterUpload: (commandResults: CommandResultLight[]) => void
}

export const AttachmentsComponent = (props: AttachmentsComponentProps): JSX.Element => {

    const onUploadStatusChange = (event: UploadOnStatusChangeEvent): void => {
        const res: UploadResponse = event.response;
        if (res?.status == 200) {
            const data: WebAppActionResultEx<AddedAttachmentResult[]> = res?.response;
            const commandResultsWithErrors = data.commandResults.filter(x => x.hasBusinessErrors === true);
            if (commandResultsWithErrors.length > 0) {
                props.handlerDisplayErrorsAfterUpload(commandResultsWithErrors);
            }
            props.handlerAfterUploadAttachment(data.extraResult);
        }
    }

    const getAttachment = (attachmentId: string): void => {
        AttachmentsApiClient.GetAttachment(attachmentId)
            .then(response => {
                const fileName: string = getFileName(response);
                const blob: Blob = response.data;
                downloadFileFromBlob(blob, fileName);
            });
    }

    const config = SettingsProvider.Get();

    return (
        <Box display="flex" flexDirection="column" className="upload-attachment">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr">
                    <UploadComponent
                        batch={true}
                        multiple={true}
                        showFileList={false}
                        onStatusChange={onUploadStatusChange}
                        onBeforeUpload={props.handlerBeforeUpload}
                        onAdd={props.handleAdd}
                        defaultFiles={[]}
                        withCredentials={false}
                        saveUrl={TransportFlowApiClient.GetAttachmentsSaveUrl()}
                        restrictions={{
                            maxFileSize: config.maxAttachmentSize
                        }}
                    />
                </IntlProvider>
            </LocalizationProvider>
            {props.isLoadingAttachments &&
                <div className='sweet-loading spinnerClass'>
                    <ScaleLoader
                        width={5}
                        height={20}
                        radius={50}
                        color={'#000000'}
                        loading={props.isLoadingAttachments}
                    />
                </div>
            }
            <Box className="attachment-files">
                {!props.isLoadingAttachments && props.attachmentFiles.filter(x => x.status !== AttachmentStatus.deleted).map((file: AttachmentArgs, index: number) => {
                    return (
                        <Box key={index} display="flex" flexDirection="row" justifyContent="space-between" pr="5px">
                            <Box className="attachment-name" onClick={() => getAttachment(file.attachmentId)}>{file.name}</Box>
                            <Tooltip title="Supprimer" placement="bottom">
                                <Box style={{ cursor: 'pointer' }} onClick={() => props.handleDeleteAttachment(file.attachmentId, file.status)}>
                                    <FontAwesomeIcon className="fa-delete-attachment-icon" size="sm" icon={faTimes} />
                                </Box>
                            </Tooltip>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    );
}