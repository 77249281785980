import * as React from 'react';

export interface ListProps {
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

export const List = React.forwardRef<HTMLUListElement, ListProps>((props, ref) => {
    return (
        <ul
            ref={ref}
            {...props}
            className="ul-list"
            style={{ ...props.style }} />
    )
});
