import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import { GridCellProps } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { DriverLightModelExtended } from '../models/DriverLightModelExtended';

interface CustomDriverCellProps extends GridCellProps{
    driversList: Array<DriverLightModelExtended>
}

const DriverItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const index = itemProps.index;
    const dataItem: DriverLightModelExtended = itemProps.dataItem;
    
    const itemChildren = (
      <Box display="flex" flexDirection="row" alignItems="center" className="driver-item">
        {dataItem.driverId !== -1 && 
            <FontAwesomeIcon icon={faUser} className={`fa-user-driver-${(dataItem.isInternal && !dataItem.isTemporary) ? "internal" : "temporary"}`} />}
        <Box pl={1} key={`driver-${index}`} className="driver-item-name">
            {dataItem.fullName}
        </Box>
      </Box>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

export const CustomDriverCell = (props: CustomDriverCellProps) => {
    let timeout: NodeJS.Timeout;
    const purchaseIsCanceled = props.dataItem.purchaseIsCanceled;
    const dataItem = props.dataItem as DriverLightModelExtended;

    const [state, setState] = useState({
        dataValue: dataItem[props.field],
        selectedValue: dataItem.driverId
    });
    const [allDriversList] = useState<Array<DriverLightModelExtended>>([{
            driverId: -1,
            fullName: '',
            phoneNumber: null,
            isInternal: true,
            isEnabled: true
        },
        ...props.driversList
    ]);
    const [driversList, setDriversList] = useState<Array<DriverLightModelExtended>>([...allDriversList]);
    const [loading, setLoading] = useState<boolean>(false);
    
    const handleSelectChange = (dataItem: DriverLightModelExtended, field: string, event: ComboBoxChangeEvent): void => {
        const targetValue = event.target.value;
        if (targetValue?.driverId && targetValue.driverId !== -1) {
            setState({
                ...state,
                selectedValue: event.target.value.driverId
            });
            dataItem.inEdit = field;
            dataItem[field] = targetValue.fullName,
            dataItem.driverId = targetValue.driverId;
            const index = driversList.findIndex(d => d.driverId === Number(targetValue.driverId));
            dataItem.driverPhone = driversList[index].phoneNumber;
        } else {
            setState({
                ...state,
                selectedValue: -1
            });
            dataItem.inEdit = field;
            dataItem[field] = "";
            dataItem.driverId = null;
            dataItem.driverPhone = "";
        }
    }

    const filterChange = (event: ComboBoxFilterChangeEvent): void => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            setLoading(false);
            setDriversList(filterData(event.filter))
        }, 500);

        setLoading(true);
    }

    const filterData = (filter: FilterDescriptor): Array<DriverLightModelExtended> => {
        const searchText = filter.value.toLowerCase().removeDiacritics();

        return allDriversList.filter(d => d.fullName?.toLowerCase().removeDiacritics().contains(searchText));
    }

    let defaultRendering: JSX.Element = <></>;

    if (!props.dataItem.inEdit || props.dataItem.inEdit !== props.field || !props.dataItem.selected) {
        const dataValue: number = props.dataItem[props.field] ? props.dataItem[props.field] : '';
        defaultRendering = (
            <td className={purchaseIsCanceled ? "purchase-is-canceled" : ""}>{!dataValue || dataValue === null ? '' : dataValue}</td>
        );
    } else {
        defaultRendering = (
            <td>
                <ComboBox 
                    style={{ width: "100%" }}
                    onChange={(event) => handleSelectChange(props.dataItem, props.field, event)}
                    value={driversList.find(c => c.driverId === state.selectedValue)}
                    data={driversList}
                    filterable={true}
                    textField="fullName"
                    itemRender={DriverItemRender}
                    onFilterChange={filterChange}
                    loading={loading}
                    allowCustom={true}
                    dataItemKey='driverId'
                />
            </td>
        );
    }

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}
