import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, Input, InputAdornment } from "@mui/material";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import React from "react";
import { ScaleLoader } from "react-spinners";
import { PurchaseProvisioningLightModelExtended } from "../models/PurchaseProvisioningLightModelExtended";
import { TotalCostModel } from "../models/TotalCostModel";
import { ProvisioningCardComponent } from "./ProvisioningCardComponent";

interface ProvisioningsComponentProps {
    loadingPurchaseProvisioning: boolean,
    toIntegrate: boolean,
    sendingPurchaseProvisioningId?: string,
    purchaseProvisionings: Array<PurchaseProvisioningLightModelExtended>,
    inputSearchProvisioningRef: React.MutableRefObject<HTMLInputElement>,
    purchaseProvisioningMaxAllowedCostLimit?: number,
    handleProvisioningKeyPress: (integrated: boolean, text: string) => void,
    clearSearchText: (integrated: boolean) => void,
    handleSelectedPurchaseProvisioning: (purchaseProvisioningId: string, selected: boolean, toIntegrate: boolean) => void,
    updatePurchaseProvisioningVisibilityInBI: (purchaseProvisioningId: string, toIntegrate: boolean) => void,
    removePurchaseProvisioning: (purchaseProvisioningId: string) => void,
    sendPurchaseProvisioning?: (purchaseProvisioning: PurchaseProvisioningLightModelExtended, emailSendRequested: boolean) => void,
    handleEditRemarks: (purchaseProvisioningId: string, toIntegrate: boolean) => void,
    handleEditGasoilIndex?: (purchaseProvisioningId: string) => void,
    handleUpdateOverridenGasoilIndex?: (purchaseProvisioningId: string, isInternal: boolean, overridenGasoilIndexCost?: number, overridenGasoilIndexToUpdate?: number) => void,
    handleChangeGasoilIndex?: (purchaseProvisioningId: string, value: number) => void,
    reInitializeGasoilIndex?: (purchaseProvisioningId: string) => void,
    handleUpdateRemarks: (purchaseProvisioningId: string, toIntegrate: boolean, remarks: string, remarksToUpdate?: string) => void,
    handleChangeRemarks: (purchaseProvisioningId: string, value: string, toIntegrate: boolean) => void
}

export const ProvisioningsComponent = (props: ProvisioningsComponentProps) => {
    const { loadingPurchaseProvisioning, purchaseProvisionings, sendingPurchaseProvisioningId, inputSearchProvisioningRef, toIntegrate, sendPurchaseProvisioning, removePurchaseProvisioning, handleEditRemarks
        , handleEditGasoilIndex, handleChangeGasoilIndex, handleUpdateOverridenGasoilIndex, reInitializeGasoilIndex, handleChangeRemarks, handleUpdateRemarks, 
        handleSelectedPurchaseProvisioning, updatePurchaseProvisioningVisibilityInBI, purchaseProvisioningMaxAllowedCostLimit } = props;

    const inputSearchProvisioningValue = inputSearchProvisioningRef.current === null || inputSearchProvisioningRef.current === undefined ? '' : inputSearchProvisioningRef.current.value;

    const sumTotalCostsAndGasoilIndexes = (): TotalCostModel => {
        let sumTotalCosts = 0;
        let sumGasoilIndexes = 0;
        for (let i = 0; i < purchaseProvisionings.length; i++) {
            sumTotalCosts += purchaseProvisionings[i].totalCosts;
            sumGasoilIndexes += purchaseProvisionings[i].currentGasoilIndex;
        }

        return {
            sumTotalCosts: sumTotalCosts,
            sumGasoilIndexes: sumGasoilIndexes
        };
    }


    const totalCosts = sumTotalCostsAndGasoilIndexes();

    return <Box display="flex" flexDirection="column" className="purchase-to-send-drawer" pt={1}>
        <LocalizationProvider language="fr-FR">
            <IntlProvider locale="fr">
                <Box width="100%">
                    <>
                        <Box pb={1} width="100%" display="flex" flexDirection="row" justifyContent="center">
                            <Box display="flex" flexDirection="row" alignItems="center" width="80%" className="header-costs">
                                <Box pr={1} width="50%">
                                    <div className="title">
                                        Total transport
                                    </div>
                                    <div className="value">
                                        {totalCosts.sumTotalCosts.toCurrencyString()}
                                    </div>
                                </Box>
                                <Divider orientation="vertical" className="vertical-separator" />
                                <Box width="50%">
                                    <div className="title">
                                        Total gazole
                                    </div>
                                    <div className="value">
                                        {totalCosts.sumGasoilIndexes?.toCurrencyString()}
                                    </div>
                                </Box>
                            </Box>
                        </Box>
                        <Box pb={1}>
                            <Input disableUnderline className={inputSearchProvisioningValue.length > 2 ? 'input-search-purchase-provisioning search-text-active' : 'input-search-purchase-provisioning'} inputRef={props.inputSearchProvisioningRef}
                                endAdornment={<>
                                    <InputAdornment position="end" classes={
                                        {
                                            root: 'input-icon-close-root'
                                        }
                                    }>
                                        <FontAwesomeIcon icon={faTimes} onClick={() => props.clearSearchText(!toIntegrate)} />
                                    </InputAdornment>
                                    <InputAdornment position="end" classes={
                                        {
                                            root: 'input-icon-search-root'
                                        }
                                    }>
                                        <FontAwesomeIcon icon={faSearch} className="icon-search" />
                                    </InputAdornment>
                                </>}
                                id="input-search-text-provisioning" placeholder="Rechercher une référence, un camion, un transporteur..." onChange={(event) => props.handleProvisioningKeyPress(!toIntegrate, event.target.value)} />
                        </Box>
                        {loadingPurchaseProvisioning ?
                            <div className='sweet-loading spinnerClass'>
                                <ScaleLoader
                                    width={5}
                                    height={20}
                                    radius={50}
                                    color={'#000000'}
                                    loading={loadingPurchaseProvisioning}
                                />
                            </div>
                            :
                            purchaseProvisionings.length == 0 && inputSearchProvisioningValue === "" ?
                                <Box textAlign="center">
                                    Veuillez sélectionner les camions à facturer afin de générer un lot
                                </Box>
                                : purchaseProvisionings.map((purchase: PurchaseProvisioningLightModelExtended, index: number) => {
                                    return (
                                        <div key={`purchase-to-send-card-${index}`}>
                                            <ProvisioningCardComponent purchase={purchase} sendPurchaseProvisioning={sendPurchaseProvisioning}
                                                sendingPurchaseProvisioning={sendingPurchaseProvisioningId == purchase.purchaseProvisioningId} purchaseProvisioningMaxAllowedCostLimit={purchaseProvisioningMaxAllowedCostLimit} removePurchaseProvisioning={removePurchaseProvisioning}
                                                handleEditRemarks={handleEditRemarks} handleEditGasoilIndex={handleEditGasoilIndex} handleChangeGasoilIndex={handleChangeGasoilIndex} handleSelectedPurchaseProvisioning={handleSelectedPurchaseProvisioning} updatePurchaseProvisioningVisibilityInBI={updatePurchaseProvisioningVisibilityInBI}
                                                handleUpdateOverridenGasoilIndex={handleUpdateOverridenGasoilIndex} reInitializeGasoilIndex={reInitializeGasoilIndex}
                                                handleChangeRemarks={handleChangeRemarks} handleUpdateRemarks={handleUpdateRemarks} toIntegrate={toIntegrate} />
                                        </div>
                                    )
                                })
                        }
                    </>
                </Box>
            </IntlProvider>
        </LocalizationProvider>
    </Box>;
}