import { TimePicker, TimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';
import { VehiclePriceKind } from '../models/VehiclePriceKind';

interface CustomBilledTimeCellProps extends GridCellProps{
    handleBilledTimeChange: (dataItem: PlanningVehicleLightModelExtended, field: string, event: TimePickerChangeEvent) => void,
    handleChange: (dataItem: PlanningVehicleLightModelExtended) => void
}

export const CustomBilledTimeCell = (props: CustomBilledTimeCellProps) => {
    const dataItem: PlanningVehicleLightModelExtended = props.dataItem;
    const dataValue: string = dataItem.overridenBilledTime != null ? dataItem.overridenBilledTime : dataItem[props.field];
    let className: string = dataItem.overridenBilledTime != null ? "overridden" : "";
    dataItem.mainPriceKind != VehiclePriceKind.PerHour ? className += " read-only" : className += "";
    className+= dataItem.purchaseIsCanceled ? " purchase-is-canceled" : "";
    const times: Array<string> = dataValue ? dataValue.split(':') : null;
    const defaultValue: Date = times ? new Date(1, 1, 1, Number(times[0]), Number(times[1])) : null;
    let defaultRendering: JSX.Element = <></>;

    const handleBilledTimeChange = (dataItem: PlanningVehicleLightModelExtended, field: string, event: TimePickerChangeEvent): void => {
        props.handleBilledTimeChange(dataItem, field, event);
        props.handleChange(dataItem);
    }

    if (!dataItem.inEdit || dataItem.inEdit !== props.field || dataItem.mainPriceKind != VehiclePriceKind.PerHour) {
        defaultRendering = (
            <td className={className}>{!dataValue || dataValue === null ? '' : dataValue}</td>
        );
    } else {
        defaultRendering = (
            <td>
                <TimePicker
                    format="HH:mm"
                    defaultValue={defaultValue}
                    onChange={(event) => handleBilledTimeChange(props.dataItem, props.field, event)}
                />
            </td>
        );
    }
    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}