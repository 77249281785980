import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import React from 'react';
import { VehicleItemModel } from '../models/VehicleItemModel';
import { InternalVehiclesChoiceLightModel } from '../services/dataContracts/queryStack/InternalVehiclesChoiceLightModel';
import { VehicleInternalExternalDetailComponent } from './VehicleInternalExternalDetailComponent';

interface InternalVehicleItemComponentProps {
    style: React.CSSProperties,
    dataItem: VehicleItemModel,
    handleAssignInternalVehicle?: (transporterId: string, vehicleId: number, vehicleTypeId: string, equipmentId: string, driverId: number, licencePlate: string, internalVehiclesChoicesLightModel: InternalVehiclesChoiceLightModel[], vehicleTypeGroupId: string) => void,
    handleAssignDailyPlanningVehicle?: (dailyPlanningVehicleId: string, vehicleId: number, licencePlate: string, transporterId: string, vehicleTypeId: string, vehicleTypeGroupId: string) => void,
    handleVehicleHiddenStateChange?: (planningVehicleId: string, vehicleId: number) => void
}

export const InternalVehicleItemComponent = (props: InternalVehicleItemComponentProps): JSX.Element => {

    const { dataItem, style } = props;

    return (
        <Box style={style} display="flex" flexDirection="row" className="vehicle-card" onDoubleClick={() => {
            if (dataItem.isDailyVehicle && !dataItem.isHiddenDaily) {
                props.handleAssignDailyPlanningVehicle(dataItem.planningVehicleId, dataItem.vehicleId, dataItem.licencePlate, dataItem.transporterId, dataItem.expectedVehicleTypeId, dataItem.vehicleTypeGroupId);
            } else {
                props.handleAssignInternalVehicle(dataItem.transporterId, dataItem.vehicleId, dataItem.vehicleTypeId, dataItem.equipmentId, dataItem.driverId, dataItem.licencePlate, dataItem.internalVehiclesChoicesLightModel, dataItem.vehicleTypeGroupId);
            }
        }}>
            <Box className="header-vehicle header-internal"></Box>
            {dataItem.isDailyVehicle && !dataItem.isHiddenDaily ? <div className="header-vehicle spacing-left header-daily"></div> : ''}
            <Box className="hover-vehicle">
                <VehicleInternalExternalDetailComponent isDailyVehicle={dataItem.isDailyVehicle && !dataItem.isHiddenDaily ? true : false} transporterId={dataItem.transporterId}
                    transporterLabel={dataItem.transporterLabel} firstBeneficiaryName={dataItem.firstBeneficiaryName} vehicleTypeLabel={dataItem.vehicleTypeLabel} vehicleTypeIsEnabled={dataItem.vehicleTypeIsEnabled}
                    licencePlate={dataItem.licencePlate}
                    driverId={dataItem.driverId} equipmentId={dataItem.equipmentId} driverFullName={dataItem.driverFullName}
                    firstDeliveryAddressCity={dataItem.firstDeliveryAddressCity} receiverSiteLabel={dataItem.receiverSiteLabel} />
                {!dataItem.isHidden ? <div className="remove-daily-vehicle"></div> : ''}
            </Box>
            {dataItem.isDailyVehicle && !dataItem.isHiddenDaily ?
                (<Box className="remove-daily-vehicle" onClick={() => props.handleVehicleHiddenStateChange(dataItem.planningVehicleId, dataItem.vehicleId)}>
                    <FontAwesomeIcon icon={faTimes} className="close-icon" />
                </Box>) : ''}
        </Box>
    )
}
