import { Box, Button } from '@mui/material';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { uniq, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { EventModelData } from '../models/EventModelData';
import { PriceKind } from '../models/PriceKind';
import { PurchasePriceLightModel } from '../services/dataContracts/queryStack/PurchasePriceLightModel';

interface UpdateSpecificPriceOfDeliveryTripsProperties {
    deliveryTrips: Array<EventModelData>,
    availablePurchasePrices: Array<PurchasePriceLightModel>,
    actionPending: boolean,
    getPriceKind: (priceKind: string) => void,
    onClose: () => void,
    handleUpdateSpecificPriceOfDeliveryTrips: (specificPrice: number, specificPriceKind: string) => void
    handleRemoveSpecificPriceFromDeliveryTrips: () => void
}

export const UpdateSpecificPriceOfDeliveryTripsComponent = (props: UpdateSpecificPriceOfDeliveryTripsProperties) => {
    const { actionPending, deliveryTrips, availablePurchasePrices, getPriceKind } = props;

    const getAvailablePurchasePricesWithExistingSpecificPriceForTrips = (priceKind: string): Array<PurchasePriceLightModel> => {
        let availablePurchasePricesForPriceKind = availablePurchasePrices ? availablePurchasePrices.filter(x => x.priceKind == priceKind) : []

        availablePurchasePricesForPriceKind = [
            ...availablePurchasePricesForPriceKind,
            ...deliveryTrips.filter(x => x.priceKind === priceKind
                && !availablePurchasePricesForPriceKind.some(y => y.price === x.price && y.priceKind === x.priceKind)).map(x => ({
                    priceKind: x.priceKind,
                    price: x.price,
                    description: ""
                }))];

        return uniqBy(availablePurchasePricesForPriceKind, x => [x.priceKind, x.price, x.description].join());
    }

    const perTonPrices: Array<PurchasePriceLightModel> = getAvailablePurchasePricesWithExistingSpecificPriceForTrips(PriceKind.PerTon.toString());
    const perHourPrices: Array<PurchasePriceLightModel> = getAvailablePurchasePricesWithExistingSpecificPriceForTrips(PriceKind.PerHour.toString());
    const perTurnPrices: Array<PurchasePriceLightModel> = getAvailablePurchasePricesWithExistingSpecificPriceForTrips(PriceKind.PerTurn.toString());

    const [specificPrice, setSpecificPrice] = useState<number>();
    const [specificPriceKind, setSpecificPriceKind] = useState<string>();
    const [deliveryTripsHasMultiPriceOrPriceKinds, setDeliveryTripsHasMultiPriceOrPriceKinds] = useState<boolean>(false);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const eventTargetValue = event.target.value;
        if (eventTargetValue === PriceKind.PerTon.toString() || eventTargetValue === PriceKind.PerHour.toString() || eventTargetValue === PriceKind.PerTurn.toString()) {
            setSpecificPriceKind(eventTargetValue);
        } else {
            const values = eventTargetValue.split(":");
            setSpecificPrice(values.length > 1 ? Number(values[1]) : null);
            setSpecificPriceKind(values[0]);
        }
    }

    const handleChange = (event: NumericTextBoxChangeEvent): void => {
        setSpecificPrice(event.target.value);
    }

    useEffect(() => {
        const prices = uniq(deliveryTrips.map(x => x.price));
        const priceKinds = uniq(deliveryTrips.map(x => x.priceKind));
        let dtsHasMultiPriceOrPriceKinds = false;

        if (prices?.length === 1) {
            setSpecificPrice(prices[0]);
        }
        else {
            dtsHasMultiPriceOrPriceKinds = true;
        }

        if (priceKinds?.length === 1) {
            setSpecificPriceKind(priceKinds[0]);
        }
        else {
            dtsHasMultiPriceOrPriceKinds = true;
        }

        setDeliveryTripsHasMultiPriceOrPriceKinds(dtsHasMultiPriceOrPriceKinds)

    }, []);

    const deliveryTripsHasSpecificPrices = deliveryTrips.some(x => x.price !== null || x.priceKind);

    const valueSpecificPrice = (specificPriceKind === PriceKind.PerTon.toString()
        ? perTonPrices
        : specificPriceKind === PriceKind.PerTurn.toString()
            ? perTurnPrices
            : specificPriceKind === PriceKind.PerHour.toString()
                ? perHourPrices
                : [])
        .findIndex(x => x.price === specificPrice && x.priceKind === specificPriceKind) !== -1 ?
        `${specificPriceKind}:${specificPrice}`
        : specificPriceKind;

    return (
        <Box display='flex' flexDirection='column' p={1}>
            <Box display="flex" flexDirection="row">
                <Box display="flex" flexDirection="column" pr={1} width="50%">
                    <div className="title">
                        Tarification spécifique d'achat
                    </div>
                    <div className="value">
                        <select className="select-trip-details" value={valueSpecificPrice} onChange={(event) => handleSelectChange(event)} disabled={false}>
                            <option value=""></option>
                            <option value={PriceKind.PerHour.toString()}>Heure</option>
                            {
                                perHourPrices ? perHourPrices.map((elem: PurchasePriceLightModel, index) =>
                                    <option key={index} value={elem.priceKind + ':' + elem.price}>{`${getPriceKind(elem.priceKind)} : ${elem.price} €`}</option>)
                                    : ''}
                            <option value={PriceKind.PerTon.toString()}>Tonne</option>
                            {
                                perTonPrices ? perTonPrices.map((elem: PurchasePriceLightModel, index) =>
                                    <option key={index} value={elem.priceKind + ':' + elem.price}>{`${getPriceKind(elem.priceKind)} : ${elem.price} €`}</option>)
                                    : ''}
                            <option value={PriceKind.PerTurn.toString()}>Tour</option>
                            {
                                perTurnPrices ? perTurnPrices.map((elem: PurchasePriceLightModel, index) =>
                                    <option key={index} value={elem.priceKind + ':' + elem.price}>{`${getPriceKind(elem.priceKind)} : ${elem.price} €`}</option>)
                                    : ''}
                        </select>
                    </div>
                </Box>
                <Box display="flex" flexDirection="column" pr={1} width="50%">
                    <div className="title">
                        Prix à l'unité
                    </div>
                    <Box>
                        <LocalizationProvider language="fr-FR">
                            <IntlProvider locale="fr" >
                                <NumericTextBox className="input-specific-price"
                                    min={0}
                                    format={{
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'EUR',
                                        currencyDisplay: 'symbol'
                                    }}
                                    spinners={false}
                                    onChange={(event) => handleChange(event)}
                                    width={'125px'}
                                    value={specificPrice}
                                    disabled={false}
                                />
                            </IntlProvider>
                        </LocalizationProvider>
                    </Box>
                </Box>
            </Box>
            {deliveryTripsHasMultiPriceOrPriceKinds && <Box className="text-orange" pt={2}>
                Vous avez sélectionné des tours actuellement associés à des tarifs spécifiques différents.
            </Box>}
            <Box display="flex" flexDirection="row" pt={2} justifyContent="flex-end">
                <Box pr={5}>
                    <Button variant="contained" color="secondary" onClick={props.onClose}>
                        Annuler
                    </Button>
                </Box>
                <Box pr={5}>
                    <Button variant="contained" color="secondary" disabled={!deliveryTripsHasSpecificPrices || actionPending}
                        onClick={() => {
                            props.handleRemoveSpecificPriceFromDeliveryTrips();
                        }}>
                        Supprimer
                    </Button>
                </Box>
                <Box>
                    <Button variant="contained" color="primary" disabled={specificPrice === null || !specificPriceKind || actionPending}
                        onClick={() => {
                            props.handleUpdateSpecificPriceOfDeliveryTrips(specificPrice, specificPriceKind);
                        }}>
                        Sauvegarder
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}