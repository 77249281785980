import { faClock, faHardHat, faIndustry, faLongArrowAltDown, faLongArrowAltUp, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, IconButton } from '@mui/material';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { components, OptionProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import { MapMarkerButton } from 'src/shared/components/Common/MapMarkerButton';
import { Address, GpsCoordinates, MapsService } from 'src/shared/MapsService/MapsService';
import { fromSeconds } from '../../../utils/TimeSpanString';
import { FixedLists } from '../FixedLists';
import { AddressModel } from '../models/AddressModel';
import { InputActionMeta } from '../models/InputActionMeta';
import { PayerSiteModel } from '../models/PayerSiteModel';
import { SelectInputItemModel } from '../models/SelectInputItemModel';
import { SiteEntityTypeEnum } from '../models/SiteEntityTypeEnum';
import { SiteModel } from '../models/SiteModel';
import { AgencyLightModel } from '../services/dataContracts/queryStack/AgencyLightModel';
import { ProductionSiteLightModel } from '../services/dataContracts/queryStack/ProductionSiteLightModel';
import { creationMode, jobSite, productionSite, referencedCustomer, unreferencedCustomer } from '../TransportFlowForm';
import { SelectInput } from './CommonComponents';

interface AddressesContentProps {
    pickupCoordinates: GpsCoordinates,
    isDeliveryMapMarkerActive: boolean,
    isJobSiteVehicleServiceKind: boolean,
    senderSite: SiteModel,
    failedPickupAddress: boolean,
    pickupAddress: AddressModel,
    deliveryCoordinates: GpsCoordinates,
    isPickupMapMarkerActive: boolean,
    receiverSite: SiteModel,
    deliveryAddress: AddressModel,
    failedDeliveryAddress: boolean,
    estimateDuration: number,
    estimateDistance: number,
    payerSite: PayerSiteModel,
    payerLevel2Activity: SelectInputItemModel,
    logisticsUnitId: string,
    payerSiteList: PayerSiteModel[],
    payerLevel2ActivityList: SelectInputItemModel[],
    transportBillingMode: SelectInputItemModel,
    productionSitesList: ProductionSiteLightModel[],
    agenciesList: AgencyLightModel[],
    payerOverridenjobSiteIdmdm: string,
    payerOverridenBeneficiary: SelectInputItemModel,
    payerOverridenBeneficiaryList: SelectInputItemModel[],
    mode: string,
    handlePickupAddressChanged: (value: AddressModel) => void,
    handleDeliveryAddressChanged: (value: AddressModel) => void,
    setIsPickupMapMarkerActive: (value: boolean) => void,
    setIsDeliveryMapMarkerActive: (value: boolean) => void,
    setPayerSiteList: (list: PayerSiteModel[]) => void,
    setPickupPin: (position: GpsCoordinates) => void
    setDeliveryPin: (position: GpsCoordinates) => void
    setFailedPickupAddress: (failed: boolean) => void
    setFailedDeliveryAddress: (failed: boolean) => void
    handleSenderSiteKindChanged: (e: SelectInputItemModel) => void,
    handleUnReferencedSenderSiteCustomerChange: (value: string) => void,
    handleUnReferencedSenderSiteCustomerSiteChange: (value: string) => void,
    handleSenderSiteAgencyChanged: (e: SelectInputItemModel) => void,
    handleSenderJobSiteIdmdmChanged: (value: string) => void,
    handleSenderjobSiteLabelChanged: (value: string) => void,
    handleSenderProductionSiteChanged: (e: SelectInputItemModel) => void,
    handleSenderProductionSiteActivityChanged: (e: SelectInputItemModel) => void,
    handleReferencedSenderSiteCustomerChanged: (e: SelectInputItemModel) => void,
    handleReceiverSiteKindChanged: (e: SelectInputItemModel) => void,
    handleUnReferencedReceiverSiteCustomerChange: (value: string) => void,
    handleUnReferencedReceiverSiteCustomerSiteChange: (value: string) => void,
    handleReceiverSiteAgencyChanged: (e: SelectInputItemModel) => void,
    handleReceiverJobSiteIdmdmChanged: (value: string) => void,
    handleReceiverjobSiteLabelChanged: (value: string) => void,
    handleReceiverProductionSiteChanged: (e: SelectInputItemModel) => void,
    handleReceiverProductionSiteActivityChanged: (e: SelectInputItemModel) => void,
    handleReferencedReceiverSiteCustomerChanged: (e: SelectInputItemModel) => void,
    handlePayerSiteChanged: (e: PayerSiteModel) => void,
    handlePayerLevel2ActivityChanged: (e: SelectInputItemModel) => void,
    handlePayerOverridenBeneficiaryChanged: (e: SelectInputItemModel) => void,
    handlePayerOverridenjobSiteIdmdmChanged: (e: React.ChangeEvent<HTMLInputElement>) => void,
    handleTransportBillingModeChanged: (e: SelectInputItemModel) => void,
    reInitialisePayerContent: () => void,
    handleReverseSites: () => void,
    handleVerifyJobMdmId: (isSenderSite: boolean) => void
}

const pickupInputRefName = "pickupInputRefName";
const deliveryInputRefName = "deliveryInputRefName";

const OptionItem = (props: OptionProps<PayerSiteModel>): JSX.Element => {
    const data: PayerSiteModel = props.data;

    return (
        <components.Option {...props}>
            <Box display="flex" flexDirection="row">
                {data.payerType && <Box pr={1}>
                    {data.payerType && <FontAwesomeIcon icon={data.payerType == SiteEntityTypeEnum.Agency ? faHardHat : faIndustry} />}
                </Box>}
                <Box className="option-item">
                    {data.label}
                </Box>
            </Box>
        </components.Option>
    );
}

export const AddressesContent = (props: AddressesContentProps): JSX.Element => {

    const { logisticsUnitId, pickupCoordinates, isDeliveryMapMarkerActive, isJobSiteVehicleServiceKind, senderSite, pickupAddress, failedPickupAddress,
        deliveryCoordinates, isPickupMapMarkerActive, receiverSite, deliveryAddress, failedDeliveryAddress, estimateDuration, estimateDistance, productionSitesList, agenciesList } = props;

    const HasInternalSite: boolean = senderSite.siteKind?.value === productionSite || senderSite.siteKind?.value === jobSite || receiverSite.siteKind?.value === productionSite || receiverSite.siteKind?.value === jobSite;

    const [pickupAddressInputValue, setPickupAddressInputValue] = useState<string>("");
    const [deliveryAddressInputValue, setDeliveryAddressInputValue] = useState<string>("");
    const [isPickupAddressInputLoading, setIsPickupAddressInputLoading] = useState<boolean>(false);
    const [isDeliveryAddressInputLoading, setIsDeliveryAddressInputLoading] = useState<boolean>(false);

    useEffect(() => {
        if (logisticsUnitId && (props.mode === creationMode)) {
            const payerSiteArray: PayerSiteModel[] = [];
            productionSitesList.forEach((element: ProductionSiteLightModel) => {
                payerSiteArray.push({
                    label: element.label,
                    value: element.productionSiteId,
                    payerType: SiteEntityTypeEnum.ProductionSite
                });
            });

            agenciesList.forEach((element: AgencyLightModel) => {
                payerSiteArray.push({
                    label: element.label,
                    value: element.agencyId,
                    payerType: SiteEntityTypeEnum.Agency
                });
            });

            const payerSiteArraySorted = payerSiteArray.sort((a, b) => a.label.localeCompare(b.label));

            if (HasInternalSite) {
                payerSiteArraySorted.unshift({ label: "Automatique", value: "Automatic" });
            }

            props.setPayerSiteList(payerSiteArraySorted);
            props.reInitialisePayerContent();
        }
    }, [logisticsUnitId, productionSitesList, agenciesList]);

    useEffect(() => {
        if (pickupAddress && pickupAddress.freeFormAddress)
            setPickupAddressInputValue(pickupAddress.freeFormAddress);
        else
            setPickupAddressInputValue("");
    }, [pickupAddress]);

    useEffect(() => {
        if (deliveryAddress && deliveryAddress.freeFormAddress)
            setDeliveryAddressInputValue(deliveryAddress.freeFormAddress);
        else
            setDeliveryAddressInputValue("");
    }, [deliveryAddress]);

    const handleChangePickupAddress = (e: SelectInputItemModel): void => {
        if (e) {
            setPickupAddressInputValue(e.label);
            props.handlePickupAddressChanged({ freeFormAddress: e.label, line1: e.line1, line2: e.line2, zipCode: e.zipCode, city: e.city });

            if (e.value) {
                props.setFailedPickupAddress(false);
                props.setPickupPin({ latitude: e.latitude, longitude: e.longitude });
            } else {
                props.setFailedPickupAddress(true);
                props.setPickupPin(null);
            }
        } else {
            setPickupAddressInputValue("");
            props.handlePickupAddressChanged({ freeFormAddress: "", line1: "", line2: "", zipCode: "", city: "" });
            props.setPickupPin(null);
        }
    }

    const handleInputChangePickupAddress = (value: string, inputActionMeta: InputActionMeta): void => {
        if (inputActionMeta.action === "input-change") {
            setPickupAddressInputValue(value);
            props.handlePickupAddressChanged({ freeFormAddress: value, line1: "", line2: "", zipCode: "", city: "" });
        }
    }

    const handleChangeDeliveryAddress = (e: SelectInputItemModel): void => {
        if (e) {
            setDeliveryAddressInputValue(e.label);
            props.handleDeliveryAddressChanged({ freeFormAddress: e.label, line1: e.line1, line2: e.line2, zipCode: e.zipCode, city: e.city });

            if (e.value) {
                props.setFailedDeliveryAddress(false);
                props.setDeliveryPin({ latitude: e.latitude, longitude: e.longitude });
            } else {
                props.setFailedDeliveryAddress(true);
                props.setDeliveryPin(null);
            }
        } else {
            setDeliveryAddressInputValue("");
            props.handleDeliveryAddressChanged({ freeFormAddress: "", line1: "", line2: "", zipCode: "", city: "" });
            props.setDeliveryPin(null);
        }
    }

    const handleInputChangeDeliveryAddress = (value: string, inputActionMeta: InputActionMeta): void => {
        if (inputActionMeta.action === "input-change") {
            setDeliveryAddressInputValue(value);
            props.handleDeliveryAddressChanged({ freeFormAddress: value, line1: "", line2: "", zipCode: "", city: "" });
        }
    }

    const getListAddress = (listAddress: SelectInputItemModel[]): SelectInputItemModel[] => {
        return listAddress;
    }

    const loadPickupAddressesOptions = debounce((inputValue: string, callback): void => {
        loadOptions(inputValue, callback, pickupInputRefName);
    }, 500);

    const loadDeliveryAddressesOptions = debounce((inputValue: string, callback): void => {
        loadOptions(inputValue, callback, deliveryInputRefName);
    }, 500);

    const loadOptions = (inputValue: string, callback, refName: string): void => {
        if (inputValue.trim().length > 2) {
            refName === pickupInputRefName ? setIsPickupAddressInputLoading(true) : (refName === deliveryInputRefName ? setIsDeliveryAddressInputLoading(true) : null);
            MapsService.SearchAddress(inputValue)
                .then((res: Address[]) => {
                    const listAddress: SelectInputItemModel[] = [{ label: "", value: "" }];
                    res.forEach(x => {
                        const address: SelectInputItemModel = {
                            value: x.id,
                            label: x.freeformAddress,
                            line1: x.line1,
                            zipCode: x.zipCode,
                            city: x.city,
                            latitude: x.position.latitude,
                            longitude: x.position.longitude
                        };

                        listAddress.push(address);
                    });

                    callback(getListAddress(listAddress));
                    refName === pickupInputRefName ? setIsPickupAddressInputLoading(false) : (refName === deliveryInputRefName ? setIsDeliveryAddressInputLoading(false) : null);
                });
        }
    }

    let pickupAddressLabelClassName = "";
    let pickupAddressSelectClassName = "";
    if (isJobSiteVehicleServiceKind) {
        if (senderSite.siteKind) {
            if ((pickupAddress.line1?.trim() || pickupAddress.line2?.trim()) && pickupAddress.freeFormAddress?.trim() && pickupAddress.zipCode?.trim() && pickupAddress.city?.trim()) {
                pickupAddressLabelClassName = "label";
                pickupAddressSelectClassName = "async-select"
            } else if (!pickupAddress.line1?.trim() && !pickupAddress.line2?.trim() && pickupAddress.freeFormAddress?.trim() && pickupAddress.zipCode?.trim() && pickupAddress.city?.trim()) {
                pickupAddressLabelClassName = "warning-label";
                pickupAddressSelectClassName = "async-select warning"
            } else {
                pickupAddressLabelClassName = "required-label";
                pickupAddressSelectClassName = "async-select required"
            }
        } else {
            pickupAddressLabelClassName = "label";
            pickupAddressSelectClassName = "async-select"
        }
    } else {
        if ((pickupAddress.line1?.trim() || pickupAddress.line2?.trim()) && pickupAddress.freeFormAddress?.trim() && pickupAddress.zipCode?.trim() && pickupAddress.city?.trim()) {
            pickupAddressLabelClassName = "label";
            pickupAddressSelectClassName = "async-select"
        } else if (!pickupAddress.line1?.trim() && !pickupAddress.line2?.trim() && pickupAddress.freeFormAddress?.trim() && pickupAddress.zipCode?.trim() && pickupAddress.city?.trim()) {
            pickupAddressLabelClassName = "warning-label";
            pickupAddressSelectClassName = "async-select warning"
        } else {
            pickupAddressLabelClassName = "required-label";
            pickupAddressSelectClassName = "async-select required"
        }
    }

    let deliveryAddressLabelClassName = "";
    let deliveryAddressSelectClassName = "";
    if ((deliveryAddress.line1?.trim() || deliveryAddress.line2?.trim()) && deliveryAddress.freeFormAddress?.trim() && deliveryAddress.zipCode?.trim() && deliveryAddress.city?.trim()) {
        deliveryAddressLabelClassName = "label";
        deliveryAddressSelectClassName = "async-select";
    } else if (!deliveryAddress.line1?.trim() && !deliveryAddress.line2?.trim() && deliveryAddress.freeFormAddress?.trim() && deliveryAddress.zipCode?.trim() && deliveryAddress.city?.trim()) {
        deliveryAddressLabelClassName = "warning-label";
        deliveryAddressSelectClassName = "async-select warning";
    } else {
        deliveryAddressLabelClassName = "required-label";
        deliveryAddressSelectClassName = "async-select required";
    }

    const onFreeFormAddressFocus = (ref: string): void => {
        if (ref === pickupInputRefName) {
            setPickupAddressInputValue(pickupAddress.freeFormAddress ?? '');
        }
        else if (ref === deliveryInputRefName) {
            setDeliveryAddressInputValue(deliveryAddress.freeFormAddress ?? '');
        }
    }

    const enabledSenderRefreshBtnAPIMJob = senderSite.jobSiteIdmdm?.length >= 4;
    const enabledReceiverRefreshBtnAPIMJob = receiverSite.jobSiteIdmdm?.length >= 4;

    return (
        <Box display="flex" flexDirection="column" className="section">
            <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="flex-start">
                <Box display="flex" flexDirection="column" justifyContent="flex-start" className="pickup-delivery-site">
                    <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="flex-start" alignItems="center" pb={'15px'}>
                        <Box display="flex" flexDirection="column" className="map-marker-icon" justifyContent="flex-end">
                            <MapMarkerButton text="D" handleClickButton={() => props.setIsPickupMapMarkerActive(true)}
                                isDisable={pickupCoordinates || isDeliveryMapMarkerActive ? true : false}
                            />
                        </Box>
                        <SelectInput title="Type du lieu de départ"
                            titleClassName={senderSite.siteKind || isJobSiteVehicleServiceKind ? "label" : "required-label"}
                            options={senderSite.siteKindList}
                            value={senderSite.siteKind}
                            handleChange={(e) => props.handleSenderSiteKindChanged(e)}
                            required={!senderSite.siteKind && !isJobSiteVehicleServiceKind}
                        />
                        {senderSite.siteKind?.value === unreferencedCustomer &&
                            <>
                                <Box display="flex" flexDirection="column" mr={"20px"}>
                                    <Box className={senderSite.unReferencedSiteCustomer?.trim() ? "label" : "required-label"}>Nom du site de départ</Box>
                                    <Box display="flex" flexDirection="row">
                                        <input type="text"
                                            className={senderSite.unReferencedSiteCustomer?.trim() ? "input-text site-name" : "input-text site-name required"}
                                            value={senderSite.unReferencedSiteCustomer === null ? "" : senderSite.unReferencedSiteCustomer}
                                            onChange={(e) => props.handleUnReferencedSenderSiteCustomerChange(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="column" mr={"20px"}>
                                    <Box className="label">Identifiant chantier</Box>
                                    <Box display="flex" flexDirection="row">
                                        <input type="text"
                                            className="input-text site-name"
                                            value={senderSite.unReferencedSiteCustomerSite === null ? "" : senderSite.unReferencedSiteCustomerSite}
                                            onChange={(e) => props.handleUnReferencedSenderSiteCustomerSiteChange(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </>
                        }
                        {senderSite.siteKind?.value === jobSite &&
                            <>
                                <Box display="flex" flexDirection="column" mr="20px">
                                    <Box className={senderSite.jobSiteIdmdm?.trim() ? "label" : "required-label"}>IDMDM chantier</Box>
                                    <Box display="flex" flexDirection="row">
                                        <input type="text"
                                            className={senderSite.jobSiteIdmdm?.trim() ? "input-text idmdm" : "input-text idmdm required"}
                                            value={senderSite.jobSiteIdmdm === null ? "" : senderSite.jobSiteIdmdm}
                                            onChange={(e) => props.handleSenderJobSiteIdmdmChanged(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                                <Avatar className="avatar-verify-jobmdmid">
                                    <IconButton size="small"
                                        title="Vérification du chantier" onClick={(e) => props.handleVerifyJobMdmId(true)}
                                        disabled={!enabledSenderRefreshBtnAPIMJob}
                                        className={!enabledSenderRefreshBtnAPIMJob ? "verify-jobmdmid-button disabled" : "verify-jobmdmid-button"}>
                                        <FontAwesomeIcon icon={faSync} />
                                    </IconButton>
                                </Avatar>
                                <SelectInput title="Agence"
                                    titleClassName={senderSite.selectedSiteAgency ? "label" : "required-label"}
                                    options={senderSite.siteAgencyList}
                                    value={senderSite.selectedSiteAgency}
                                    handleChange={(e) => props.handleSenderSiteAgencyChanged(e)}
                                    required={!senderSite.selectedSiteAgency}
                                />
                                <Box display="flex" flexDirection="column" mr="20px">
                                    <Box className="label">Label/Alias chantier</Box>
                                    <Box display="flex" flexDirection="row">
                                        <input type="text"
                                            className="input-text jobsite-alias"
                                            value={senderSite.jobSiteLabel === null ? "" : senderSite.jobSiteLabel}
                                            onChange={(e) => props.handleSenderjobSiteLabelChanged(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </>
                        }
                        {senderSite.siteKind?.value === productionSite &&
                            <>
                                <SelectInput title="Nom du site de départ"
                                    titleClassName={senderSite.productionSite ? "label" : "required-label"}
                                    options={senderSite.siteList}
                                    value={senderSite.productionSite}
                                    handleChange={(e) => props.handleSenderProductionSiteChanged(e)}
                                    required={!senderSite.productionSite}
                                />
                                <SelectInput title="Activité"
                                    titleClassName={senderSite.productionSiteActivity?.value ? "label" : "required-label"}
                                    options={senderSite.productionSiteActivities}
                                    value={senderSite.productionSiteActivity}
                                    handleChange={(e) => props.handleSenderProductionSiteActivityChanged(e)}
                                    required={!senderSite.productionSiteActivity?.value}
                                />
                            </>
                        }
                        {senderSite.siteKind?.value === referencedCustomer &&
                            <SelectInput title="Nom du site de départ"
                                titleClassName={senderSite.referencedSiteCustomer ? "label" : "required-label"}
                                options={senderSite.referencedSiteCustomerList}
                                value={senderSite.referencedSiteCustomer}
                                handleChange={(e) => props.handleReferencedSenderSiteCustomerChanged(e)}
                                required={!senderSite.referencedSiteCustomer}
                            />
                        }
                    </Box>
                    <Box className={failedPickupAddress ? "address-failed" : ''} display="flex" flexDirection="column" justifyContent="flex-start" pb={'15px'}>
                        <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="flex-start">
                            <IconButton size="medium" onClick={props.handleReverseSites} className="icons-reverse-button">
                                <FontAwesomeIcon size="2x" icon={faLongArrowAltDown} />
                                <FontAwesomeIcon size="2x" icon={faLongArrowAltUp} />
                            </IconButton>
                            <Box display="flex" flexDirection="column" mr={"20px"}>
                                <Box className={pickupAddressLabelClassName}>Adresse site de départ</Box>
                                <Box display="flex" flexDirection="row">
                                    <AsyncSelect
                                        value={{ label: pickupAddress.freeFormAddress, value: pickupAddress.freeFormAddress }}
                                        inputValue={pickupAddressInputValue}
                                        className={pickupAddressSelectClassName}
                                        loadOptions={loadPickupAddressesOptions}
                                        onFocus={() => onFreeFormAddressFocus(pickupInputRefName)}
                                        onChange={(e) => handleChangePickupAddress(e)}
                                        onInputChange={(value, inputActionMeta) => handleInputChangePickupAddress(value, inputActionMeta)}
                                        blurInputOnSelect={true}
                                        openMenuOnClick={false}
                                        isClearable={true}
                                        isLoading={isPickupAddressInputLoading}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {failedPickupAddress &&
                            <Box className="alert-address-failed">Un des éléments de l’adresse est incorrect.</Box>
                        }
                    </Box>
                    <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="flex-start" alignItems="center" pb={'15px'}>
                        <Box display="flex" flexDirection="column" className="map-marker-icon" justifyContent="flex-end">
                            <MapMarkerButton text="A" handleClickButton={() => props.setIsDeliveryMapMarkerActive(true)}
                                isDisable={deliveryCoordinates || isPickupMapMarkerActive ? true : false}
                            />
                        </Box>
                        <SelectInput title="Type du lieu d'arrivée"
                            titleClassName={receiverSite.siteKind ? "label" : "required-label"}
                            options={receiverSite.siteKindList}
                            value={receiverSite.siteKind}
                            handleChange={(e) => props.handleReceiverSiteKindChanged(e)}
                            required={!receiverSite.siteKind}
                        />
                        {receiverSite.siteKind?.value === unreferencedCustomer &&
                            <>
                                <Box display="flex" flexDirection="column" mr={"20px"}>
                                    <Box className={receiverSite.unReferencedSiteCustomer?.trim() ? "label" : "required-label"}>Nom du site d'arrivée</Box>
                                    <Box display="flex" flexDirection="row">
                                        <input type="text"
                                            className={receiverSite.unReferencedSiteCustomer?.trim() ? "input-text site-name" : "input-text site-name required"}
                                            value={receiverSite.unReferencedSiteCustomer === null ? "" : receiverSite.unReferencedSiteCustomer}
                                            onChange={(e) => props.handleUnReferencedReceiverSiteCustomerChange(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="column" mr={"20px"}>
                                    <Box className="label">Identifiant chantier</Box>
                                    <Box display="flex" flexDirection="row">
                                        <input type="text"
                                            className="input-text site-name"
                                            value={receiverSite.unReferencedSiteCustomerSite === null ? "" : receiverSite.unReferencedSiteCustomerSite}
                                            onChange={(e) => props.handleUnReferencedReceiverSiteCustomerSiteChange(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </>
                        }
                        {receiverSite.siteKind?.value === jobSite &&
                            <>
                                <Box display="flex" flexDirection="column" mr={"20px"}>
                                    <Box className={receiverSite.jobSiteIdmdm?.trim() ? "label" : "required-label"}>IDMDM chantier</Box>
                                    <Box display="flex" flexDirection="row">
                                        <input type="text"
                                            className={receiverSite.jobSiteIdmdm?.trim() ? "input-text idmdm" : "input-text idmdm required"}
                                            value={receiverSite.jobSiteIdmdm === null ? "" : receiverSite.jobSiteIdmdm}
                                            onChange={(e) => props.handleReceiverJobSiteIdmdmChanged(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                                <Avatar className="avatar-verify-jobmdmid">
                                    <IconButton size="small"
                                        title="Vérification du chantier" onClick={(e) => props.handleVerifyJobMdmId(false)}
                                        disabled={!enabledReceiverRefreshBtnAPIMJob}
                                        className={!enabledReceiverRefreshBtnAPIMJob ? "verify-jobmdmid-button disabled" : "verify-jobmdmid-button"}>
                                        <FontAwesomeIcon icon={faSync} />
                                    </IconButton>
                                </Avatar>
                                <SelectInput title="Agence"
                                    titleClassName={receiverSite.selectedSiteAgency ? "label" : "required-label"}
                                    options={receiverSite.siteAgencyList}
                                    value={receiverSite.selectedSiteAgency}
                                    handleChange={(e) => props.handleReceiverSiteAgencyChanged(e)}
                                    required={!receiverSite.selectedSiteAgency}
                                />
                                <Box display="flex" flexDirection="column" mr="20px">
                                    <Box className="label">Label/Alias chantier</Box>
                                    <Box display="flex" flexDirection="row">
                                        <input type="text"
                                            className="input-text jobsite-alias"
                                            value={receiverSite.jobSiteLabel === null ? "" : receiverSite.jobSiteLabel}
                                            onChange={(e) => props.handleReceiverjobSiteLabelChanged(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </>
                        }
                        {receiverSite.siteKind?.value === productionSite &&
                            <>
                                <SelectInput title="Nom du site d'arrivée"
                                    titleClassName={receiverSite.productionSite ? "label" : "required-label"}
                                    options={receiverSite.siteList}
                                    value={receiverSite.productionSite}
                                    handleChange={(e) => props.handleReceiverProductionSiteChanged(e)}
                                    required={!receiverSite.productionSite}
                                />
                                <SelectInput title="Activité"
                                    titleClassName={receiverSite.productionSiteActivity?.value ? "label" : "required-label"}
                                    options={receiverSite.productionSiteActivities}
                                    value={receiverSite.productionSiteActivity}
                                    handleChange={(e) => props.handleReceiverProductionSiteActivityChanged(e)}
                                    required={!receiverSite.productionSiteActivity?.value}
                                />
                            </>
                        }
                        {receiverSite.siteKind?.value === referencedCustomer &&
                            <SelectInput title="Nom du site d'arrivée"
                                titleClassName={receiverSite.referencedSiteCustomer ? "label" : "required-label"}
                                options={receiverSite.referencedSiteCustomerList}
                                value={receiverSite.referencedSiteCustomer}
                                handleChange={(e) => props.handleReferencedReceiverSiteCustomerChanged(e)}
                                required={!receiverSite.referencedSiteCustomer}
                            />
                        }
                    </Box>
                    <Box className={failedDeliveryAddress ? "address-failed" : ''} display="flex" flexDirection="column" justifyContent="flex-start" pb={'15px'} pl={'38px'}>
                        <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="flex-start">
                            <Box display="flex" flexDirection="column" mr={"20px"}>
                                <Box className={deliveryAddressLabelClassName}>Adresse site d'arrivée</Box>
                                <Box display="flex" flexDirection="row">
                                    <AsyncSelect
                                        value={{ label: deliveryAddress.freeFormAddress, value: deliveryAddress.freeFormAddress }}
                                        inputValue={deliveryAddressInputValue}
                                        className={deliveryAddressSelectClassName}
                                        loadOptions={loadDeliveryAddressesOptions}
                                        onFocus={() => onFreeFormAddressFocus(deliveryInputRefName)}
                                        onChange={(e) => handleChangeDeliveryAddress(e)}
                                        onInputChange={(value, inputActionMeta) => handleInputChangeDeliveryAddress(value, inputActionMeta)}
                                        blurInputOnSelect={true}
                                        openMenuOnClick={false}
                                        isClearable={true}
                                        isLoading={isDeliveryAddressInputLoading}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {failedDeliveryAddress &&
                            <Box className="alert-address-failed">Un des éléments de l’adresse est incorrect.</Box>
                        }
                    </Box>
                </Box>
                <Box className="map-content">
                    <Box id="myMap" className="content"></Box>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end" className="estimate-content">
                        <Box display="flex" flexDirection="column" mr={"20px"}>
                            <Box className="label">Temps estimé</Box>
                            <Box display="flex" flexDirection="row">
                                <span>{estimateDuration ? fromSeconds(estimateDuration) : ''}</span>
                                <FontAwesomeIcon className="fa-clock-icon" size="lg" icon={faClock} />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" mr={"20px"}>
                            <Box className="label">kilométrage estimé</Box>
                            <Box display="flex" flexDirection="row">
                                <span>{estimateDistance ? (estimateDistance / 1000).toStringFixed("FR-fr") : ''}</span>
                                <span className="km-unit">Km</span>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {!isJobSiteVehicleServiceKind &&
                <Box className="payer-section" display="flex" flexDirection="column" justifyContent="flex-start">
                    <fieldset className="payer-fieldset">
                        <legend>Payeur</legend>
                        <Box display="flex" flexDirection="row" width="100%" justifyContent="flex-start" p={"10px"}>
                            <SelectInput title="Site"
                                titleClassName={!props.payerSite ? "required-label" : "label"}
                                options={props.payerSiteList}
                                value={props.payerSite}
                                components={{
                                    Option: (props) => <OptionItem {...props} />
                                }}
                                handleChange={(e) => props.handlePayerSiteChanged(e)}
                                required={!props.payerSite}
                            />
                            {props.payerSite?.value !== "Automatic" &&
                                <>
                                    {props.payerSite?.payerType === SiteEntityTypeEnum.ProductionSite &&
                                        <SelectInput title="Activité"
                                            titleClassName={!props.payerLevel2Activity ? "required-label" : "label"}
                                            options={props.payerLevel2ActivityList}
                                            value={props.payerLevel2Activity}
                                            handleChange={(e) => props.handlePayerLevel2ActivityChanged(e)}
                                            required={!props.payerLevel2Activity}
                                        />}
                                    {props.payerSite?.payerType === SiteEntityTypeEnum.Agency &&
                                        <>
                                            <Box display="flex" flexDirection="column" mr={"20px"}>
                                                <Box className={props.payerOverridenjobSiteIdmdm?.trim() ? "label" : "required-label"}>IDMDM chantier</Box>
                                                <Box display="flex" flexDirection="row">
                                                    <input type="text"
                                                        className={props.payerOverridenjobSiteIdmdm?.trim() ? "input-text" : "input-text required"}
                                                        value={props.payerOverridenjobSiteIdmdm ?? ""}
                                                        onChange={(e) => props.handlePayerOverridenjobSiteIdmdmChanged(e)}
                                                    />
                                                </Box>
                                            </Box>
                                            <SelectInput title="Chef de chantier"
                                                titleClassName={!props.payerOverridenBeneficiary ? "required-label" : "label"}
                                                options={props.payerOverridenBeneficiaryList}
                                                value={props.payerOverridenBeneficiary}
                                                handleChange={(e) => props.handlePayerOverridenBeneficiaryChanged(e)}
                                                required={!props.payerOverridenBeneficiary}
                                            />
                                        </>
                                    }

                                    <SelectInput title="Mode de livraison"
                                        titleClassName={!props.transportBillingMode ? "required-label" : "label"}
                                        options={FixedLists.transportBillingModeList}
                                        value={props.transportBillingMode}
                                        handleChange={(e) => props.handleTransportBillingModeChanged(e)}
                                        required={!props.transportBillingMode}
                                    />
                                </>
                            }
                        </Box>
                    </fieldset>
                </Box>
            }
        </Box>
    );
}
