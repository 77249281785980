! function (e, t) {
    "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define("frFR", [], t) : "object" == typeof exports ? exports.frFR = t() : (e.bryntum = e.bryntum || {}, e.bryntum.locales = e.bryntum.locales || {}, e.bryntum.locales.frFR = t())
}(window, function () {
    return function (e) {
        var t = {};

        function r(n) {
            if (t[n]) return t[n].exports;
            var a = t[n] = {
                i: n,
                l: !1,
                exports: {}
            };
            return e[n].call(a.exports, a, a.exports, r), a.l = !0, a.exports
        }
        return r.m = e, r.c = t, r.d = function (e, t, n) {
            r.o(e, t) || Object.defineProperty(e, t, {
                enumerable: !0,
                get: n
            })
        }, r.r = function (e) {
            "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
                value: "Module"
            }), Object.defineProperty(e, "__esModule", {
                value: !0
            })
        }, r.t = function (e, t) {
            if (1 & t && (e = r(e)), 8 & t) return e;
            if (4 & t && "object" == typeof e && e && e.__esModule) return e;
            var n = Object.create(null);
            if (r.r(n), Object.defineProperty(n, "default", {
                enumerable: !0,
                value: e
            }), 2 & t && "string" != typeof e)
                for (var a in e) r.d(n, a, function (t) {
                    return e[t]
                }.bind(null, a));
            return n
        }, r.n = function (e) {
            var t = e && e.__esModule ? function () {
                return e.default
            } : function () {
                return e
            };
            return r.d(t, "a", t), t
        }, r.o = function (e, t) {
            return Object.prototype.hasOwnProperty.call(e, t)
        }, r.p = "", r(r.s = 0)
    }([function (e, t, r) {
        "use strict";
        r.r(t);
        var n = {
            localeName: "frFR",
            localeDesc: "French",
            TemplateColumn: {
                noTemplate: "TemplateColumn nécessite un template",
                noFunction: "TemplateColumn.template doit être une fonction"
            },
            ColumnStore: {
                columnTypeNotFound: function (e) {
                    return "Le type de colonne '".concat(e.type, "' n'est pas enregistré")
                }
            },
            InstancePlugin: {
                fnMissing: function (e) {
                    return "Essayer de lier fn ".concat(e.plugIntoName, "#").concat(e.fnName, ", mais le plugin fn").concat(e.pluginName, "#").concat(e.fnName, " n'est pas disponible")
                },
                overrideFnMissing: function (e) {
                    return "Essayer d'écraser fn ".concat(e.plugIntoName, "#").concat(e.fnName, ", mais le plugin fn ").concat(e.pluginName, "#").concat(e.fnName, " n'est pas disponible")
                }
            },
            CellEdit: {
                editCell: "Modifier la cellule"
            },
            ColumnPicker: {

                columnsMenu: "Colonnes",
                hideColumn: "Masquer la colonne",
                hideColumnShort: "Cacher",
                Column: "Colonne"
            },
            Filter: {
                applyFilter: "Appliquer le filtre",
                editFilter: "Modifier le filtre",
                filter: "Filtre",
                on: "Sur",
                before: "Avant",
                after: "Aprés",
                equals: "Est égal à",
                lessThan: "Moins que",
                moreThan: "Plus grand que",
                removeFilter: "Supprimer le filtre"
            },
            FilterBar: {
                enableFilterBar: "Afficher la ligne de filtrage",
                disableFilterBar: "Masquer la ligne du filtre"
            },
            Group: {
                groupAscending: "Groupe en hausse",
                groupDescending: "Groupe descendant",
                groupAscendingShort: "Ascending",
                groupDescendingShort: "Tomber",
                stopGrouping: "Arrêter le groupement",
                stopGroupingShort: "Stop",
                Group: "Groupe"
            },
            Search: {
                searchForValue: "Recherche de valeur"
            },
            Sort: {
                sortAscending: "Trier par ordre croissant",
                sortDescending: "Trier par ordre décroissant",
                multiSort: "Multi-tri",
                addSortAscending: "Ajouter ascendant",
                addSortDescending: "Ajouter décroissant",
                toggleSortAscending: "Changer en croissant",
                toggleSortDescending: "Changer en chute",
                removeSorter: "Supprimer les tris",
                sortAscendingShort: "Rising",
                sortDescendingShort: "Tomber",
                removeSorterShort: "Supprimer",
                addSortAscendingShort: "+ Rising",
                addSortDescendingShort: "+ Falling",
                Sort: "Trier",
                Multisort: "Multisort"
            },
            Tree: {
                noTreeColumn: "Pour utiliser l'arborescence des fonctionnalités, une colonne doit être configurée avec l'arborescence: true"
            },
            Grid: {
                featureNotFound: function (e) {
                    return "La fonctionnalité  '".concat(e, "' n'est pas disponible, veuillez vérifier qu'elle est importée")
                },
                removeRow: "Supprimer la ligne",
                removeRows: "Supprimer les lignes",
                loadMask: "Chargement ...",
                loadFailedMessage: "Une erreur s'est produite, veuillez réessayer.",
                moveColumnLeft: "Move to the left section",
                moveColumnRight: "Déplacer vers la section droite"
            },
            GridBase: {
                loadMask: "Chargement...",
                noRows: "Aucun camion à afficher"
            },
            Field: {
                invalidValue: "Valeur invalide",
                minimumValueViolation: "Valeur trop basse",
                maximumValueViolation: "Valeur trop élevée",
                fieldRequired: "Ce champ est obligatoire"
            },
            DateField: {
                datePickerFormat: "d-m-y",
                invalidDate: "Date invalide",
                minimumValueViolation: "Date trop tôt",
                maximumValueViolation: "Date trop tardive"
            },
            TimeField: {
                timePickerFormat: "H:i",
                timePicker24Hour: !1,
                invalidTime: "Temps invalide"
            },
            Tooltip: {
                "Loading...": "Chargement..."
            },
            DateHelper: {
                locale: "fr-FR",
                shortWeek: "V",
                shortQuarter: "q",
                week: "semaine",
                weekStartDay: 1,
                unitNames: [{
                    single: "milliseconde",
                    plural: "millisecondes",
                    abbrev: "ms"
                }, {
                    single: "seconde",
                    plural: "secondes",
                    abbrev: "s"
                }, {
                    single: "minute",
                    plural: "minutes",
                    abbrev: "min"
                }, {
                    single: "heure",
                    plural: "heures",
                    abbrev: "h"
                }, {
                    single: "jour",
                    plural: "jours",
                    abbrev: "j"
                }, {
                    single: "semaine",
                    plural: "semaines",
                    abbrev: "sem"
                }, {
                    single: "mois",
                    plural: "mois",
                    abbrev: "m"
                }, {
                    single: "trimestre",
                    plural: "trimestres",
                    abbrev: "tr"
                }, {
                    single: "année",
                    plural: "années",
                    abbrev: "an"
                }],
                unitAbbreviations: [
                    ["ms", "mil"],
                    ["s", "sek"],
                    ["min", "min"],
                    ["h", "h"],
                    ["j"],
                    ["sem", "sem"],
                    ["m", "m"],
                    ["tr", "tr"],
                    []
                ],
                ordinalSuffix: function (e) {
                    var t = e[e.length - 1];
                    return e + ("11" === e || "12" === e || "1" !== t && "2" !== t ? "e" : "a")
                },
                parsers: {
                    L: "DD-MM-YYYY",
                    LT: "HH:mm"
                }
            }
        },
            a = {
                ExcelExporter: {
                    "No resource assigned": "Aucune ressource assignée"
                },
                Dependencies: {
                    from: "De",
                    to: "A",
                    valid: "Valide",
                    invalid: "Invalide"
                },
                EventEdit: {
                    nameText: "Nom",
                    resourceText: "Ressource",
                    startText: "Démarrer",
                    endText: "End",
                    saveText: "Enregistrer",
                    deleteText: "Delete",
                    cancelText: "Annuler",
                    editEvent: "Modifier la réservation"
                },
                Scheduler: {
                    addEvent: "Ajouter un événement",
                    deleteEvent: "Supprimer l'événement",
                    unassignEvent: "Supprimer le lien de la ressource"
                },
                TimeAxisHeaderMenu: {
                    pickZoomLevel: "Sélectionnez le niveau de zoom",
                    activeDateRange: "Plage de dates actives",
                    startText: "Date de début",
                    endText: "Date de fin",
                    todayText: "aujourd'hui"
                },
                EventFilter: {
                    filterEvents: "Filtrer les événements",
                    byName: "Par nom"
                },
                TimeRanges: {
                    showCurrentTimeLine: "Afficher la chronologie actuelle"
                }
            };
        for (var i in n) a[i] = n[i];
        t.default = a
    }]).default
});