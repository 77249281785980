import { Box, Tooltip } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderCellProps, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import Utilities from 'src/utils/Utilities';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { CustomHeaderCellComponent } from '../../Common/CustomHeaderCellComponent';
import { SizingUtilities } from '../../SizingUtilities';
import { ContactCustomerLightModelExtended } from '../models/ContactCustomerLightModelExtended';
import { CustomerContactsReferentialApiClient } from '../services/CustomerContactsReferentialApiClient';
import { LogisticsUnitChoiceOfContactsLightModel } from '../services/dataContracts/queryStack/LogisticsUnitChoiceOfContactsLightModel';
import { BeneficiaryAndRequesterChoiceAllCell } from './BeneficiaryAndRequesterChoiceAllCell';
import { BeneficiaryAndRequesterChoiceCell } from './BeneficiaryAndRequesterChoiceCell';

interface CustomerContactsComponentProps {
    selectedCustomerMdmId: string,
    contacts: Map<string, ContactCustomerLightModelExtended>,
    contactsArray: Array<ContactCustomerLightModelExtended>,
    contactChoicesLogisticsUnit: Array<LogisticsUnitChoiceOfContactsLightModel>,
    sort: SortDescriptor[],
    handleSortChange: (e: GridSortChangeEvent) => void,
    handleSortColumnChange: (sortItems: SortDescriptor[]) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const ExternalContactsGridName = 'externalContactsReferential';
const ModuleKey = AppModule.CustomerContactsReferential;

export const CustomerContactsComponent = (props: CustomerContactsComponentProps): JSX.Element => {

    const [skip, setSkip] = useState<number>(0);
    const [contactsChoicesMap] = useState<Map<string, ContactCustomerLightModelExtended>>(props.contacts);

    const forceUpdate = useForceUpdate();

    const getContactsWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, ExternalContactsGridName, fieldName, columnWidth);
    }

    const getBeneficiaryRequesterGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, ExternalContactsGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            ExternalContactsGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, ExternalContactsGridName, event.columns);
        forceUpdate();
    }

    const updateChoicesContactsLogisticsUnit = (checked: boolean, logisticsUnit: string, isBeneficiary: boolean, contactId: string, customerId: string): void => {
        CustomerContactsReferentialApiClient.AddOrRemoveContactChoice({
            customerId: customerId,
            contactId: contactId,
            logisticsUnitId: logisticsUnit,
            shouldAdd: checked
        }, isBeneficiary)
            .then(() => {
                const item: ContactCustomerLightModelExtended = contactsChoicesMap.get(contactId);

                if (isBeneficiary)
                    item[`${logisticsUnit}_beneficiary`] = checked;
                else
                    item[`${logisticsUnit}_requester`] = checked;

                let checked_beneficiary = true;
                let checked_requester = true;
                let indeterminateAll = true;
                let numberOfChecked = 0;
                let numberOfUnchecked = 0;
                props.contactChoicesLogisticsUnit.forEach((logisticsUnit: LogisticsUnitChoiceOfContactsLightModel) => {
                    if (!item[`${logisticsUnit.logisticsUnitId}_beneficiary`]) {
                        checked_beneficiary = false;
                        numberOfUnchecked += 1;
                    } else {
                        numberOfChecked += 1;
                    }

                    if (!item[`${logisticsUnit.logisticsUnitId}_requester`]) {
                        checked_requester = false;
                        numberOfUnchecked += 1;
                    } else {
                        numberOfChecked += 1;
                    }
                });

                //*2 car il y'a bénéficiaire et demandeur
                if ((props.contactChoicesLogisticsUnit.length * 2) === numberOfUnchecked
                    || (props.contactChoicesLogisticsUnit.length * 2) === numberOfChecked) {
                    indeterminateAll = false;
                }

                item.isSelectedAll = checked_requester && checked_beneficiary ? true : (indeterminateAll ? null : false);
                item.isIndeterminateAll = indeterminateAll;

                forceUpdate();
            });
    }

    const updateChoicesContactsAllLogisticsUnits = (checked: boolean, contactId: string, customerId: string): void => {
        const logisticsUnitIds: Array<string> = [];
        props.contactChoicesLogisticsUnit.forEach((element: LogisticsUnitChoiceOfContactsLightModel) => {
            logisticsUnitIds.push(element.logisticsUnitId);
        });

        CustomerContactsReferentialApiClient.SetOrUnsetContactChoiceToAllLogisticsUnits({
            customerId: customerId,
            contactId: contactId,
            shouldAdd: checked,
            logisticsUnitIds: logisticsUnitIds
        })
            .then(() => {
                const item: ContactCustomerLightModelExtended = contactsChoicesMap.get(contactId);

                logisticsUnitIds.forEach((logisticsUnitId: string) => {
                    item[`${logisticsUnitId}_beneficiary`] = checked;
                    item[`${logisticsUnitId}_requester`] = checked;
                });

                item.isSelectedAll = checked ? true : false;
                item.isIndeterminateAll = false;

                forceUpdate();
            });
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.contacts.size;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: ContactCustomerLightModelExtended[] = orderBy(props.contactsArray, props.sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="colas_customers-contact-grid">
            <Grid
                className="customers-contacts-grid"
                data={gridData}
                sortable
                reorderable
                resizable
                sort={props.sort}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={props.handleSortChange}
                editField="inEdit"
                rowHeight={rowHeight}
                scrollable="virtual"
                skip={skip}
                total={totalGrid}
                pageSize={gridPageSize}
                onPageChange={pageChange}
                style={gridStyle}
            >
                <GridColumn field="isSelectedAll" orderIndex={getBeneficiaryRequesterGridOrderIndexColumn("isSelectedAll", 0)} width={getContactsWidth("isSelectedAll", 100)} title="Sélection"
                    cell={(props: GridCellProps) =>
                        <BeneficiaryAndRequesterChoiceAllCell
                            {...props}
                            updateChoicesAllLogisticsUnits={updateChoicesContactsAllLogisticsUnits}
                        />}
                    headerCell={(_props: GridHeaderCellProps) =>
                        <CustomHeaderCellComponent tooltip="Activer/Désactiver un contact pour toutes les zones logistiques" title={_props.title} field="isSelectedAll" sort={props.sort}
                            handleSortColumnChange={props.handleSortColumnChange} />
                    }
                />
                <GridColumn field="lastName" orderIndex={getBeneficiaryRequesterGridOrderIndexColumn("lastName", 1)} width={getContactsWidth("lastName", 120)} title="Nom" />
                <GridColumn field="firstName" orderIndex={getBeneficiaryRequesterGridOrderIndexColumn("firstName", 2)} width={getContactsWidth("firstName", 120)} title="Prénom" />
                <GridColumn field="phoneNumber" orderIndex={getBeneficiaryRequesterGridOrderIndexColumn("phoneNumber", 3)} width={getContactsWidth("phoneNumber", 120)} title="Téléphone"
                    cell={(properties: GridCellProps) => <td>{properties.dataItem?.phoneNumber.startsWith('+32') || properties.dataItem?.phoneNumber.startsWith('0032') ? Utilities.formatPhoneNumber(properties.dataItem.phoneNumber) : properties.dataItem.phoneNumber}</td>}
                />
                {props.contactChoicesLogisticsUnit.map((logisticsUnit: LogisticsUnitChoiceOfContactsLightModel, i: number) =>
                    <GridColumn headerClassName="logistics-unit" key={i} field={logisticsUnit.logisticsUnitId} orderIndex={getBeneficiaryRequesterGridOrderIndexColumn(logisticsUnit.logisticsUnitId, 4 + i)} minResizableWidth={240} width={getContactsWidth(logisticsUnit.logisticsUnitId, 240)} title={logisticsUnit.label}
                        cell={(props: GridCellProps) =>
                            <BeneficiaryAndRequesterChoiceCell
                                {...props}
                                updateChoicesLogisticsUnit={updateChoicesContactsLogisticsUnit}
                            />}
                        headerCell={(_props: GridHeaderCellProps) =>
                            <Tooltip title="Activer/Désactiver un contact pour la zone logistique" placement="top">
                                <span>{_props.title}</span>
                            </Tooltip>
                        }
                    />
                )}
            </Grid>
        </Box>
    );
}
