import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderCellProps, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { CustomCheckboxCell } from '../../Common/CustomCheckboxCell';
import { CustomHeaderCellComponent } from '../../Common/CustomHeaderCellComponent';
import { SizingUtilities } from '../../SizingUtilities';
import { WorkAgencyLightModelExtended } from '../models/WorkAgencyLightModelExtended';
import { LogisticsUnitChoiceOfWorkAgenciesLightModel } from '../services/dataContracts/queryStack/LogisticsUnitChoiceOfWorkAgenciesLightModel';
import { WorkAgenciesReferentialApiClient } from '../services/WorkAgenciesReferentialApiClient';

interface WorkAgenciesReferentialComponentProps {
    workAgencies: Array<WorkAgencyLightModelExtended>,
    workAgencyChoicesLogisticsUnit: Array<LogisticsUnitChoiceOfWorkAgenciesLightModel>,
    sort: SortDescriptor[],
    handleSortChange: (e: GridSortChangeEvent) => void,
    handleSortColumnChange: (sortItems: SortDescriptor[]) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const WorkAgenciesReferentialGridName = 'workAgenciesReferential';
const ModuleKey = AppModule.WorkAgenciesReferential;

export const WorkAgenciesReferentialComponent = (props: WorkAgenciesReferentialComponentProps) => {

    const [skip, setSkip] = useState<number>(0);
    const [workAgencyChoicesDataMap, setWorkAgencyChoicesDataMap] = useState<Map<string, WorkAgencyLightModelExtended>>(new Map<string, WorkAgencyLightModelExtended>());
    const [workAgencyChoicesDataArray, setWorkAgencyChoicesDataArray] = useState<Array<WorkAgencyLightModelExtended>>([]);

    useEffect(() => {
        setWorkAgencyChoicesData();
    }, []);

    const forceUpdate = useForceUpdate();

    const setWorkAgencyChoicesData = (): void => {
        const dataMap: Map<string, WorkAgencyLightModelExtended> = new Map<string, WorkAgencyLightModelExtended>();
        const dataArray: Array<WorkAgencyLightModelExtended> = [];

        props.workAgencies.forEach((agency: WorkAgencyLightModelExtended) => {
            const element: WorkAgencyLightModelExtended = { ...agency };

            let checked = true;
            let _indeterminateAll = true;
            let numberOfChecked = 0;
            let numberOfUnchecked = 0;

            props.workAgencyChoicesLogisticsUnit.forEach((logisticsUnit: LogisticsUnitChoiceOfWorkAgenciesLightModel) => {
                element[logisticsUnit.logisticsUnitId] = logisticsUnit.chosenWorkAgencies.includes(element.agencyId);
                if (!logisticsUnit.chosenWorkAgencies.includes(element.agencyId)) {
                    checked = false;
                    numberOfUnchecked += 1;
                } else {
                    numberOfChecked += 1;
                }
            });

            if (props.workAgencyChoicesLogisticsUnit.length === numberOfUnchecked || props.workAgencyChoicesLogisticsUnit.length === numberOfChecked) {
                _indeterminateAll = false;
            }
            element.isIndeterminateAll = _indeterminateAll;
            element.isSelectedAll = checked ? true : (_indeterminateAll ? null : false);

            dataMap.set(element.agencyId, element);
            dataArray.push(element);
        });

        setWorkAgencyChoicesDataMap(dataMap);
        setWorkAgencyChoicesDataArray(dataArray);
    }

    const getWorkAgenciesWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, WorkAgenciesReferentialGridName, fieldName, defaultWidth);
    }

    const getAgencyGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, WorkAgenciesReferentialGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            WorkAgenciesReferentialGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, WorkAgenciesReferentialGridName, event.columns);
        forceUpdate();
    }

    const updateChoicesAgenciesLogisticsUnit = (checked: boolean, logisticsUnitId: string, agencyId: string): void => {
        WorkAgenciesReferentialApiClient.AddOrRemoveWorkAgencyChoice({
            agencyId: agencyId,
            logisticsUnitId: logisticsUnitId,
            shouldAdd: checked
        })
            .then(() => {
                const item = workAgencyChoicesDataMap.get(agencyId);
                item[logisticsUnitId] = checked;

                let checkedSelect = true;
                let _indeterminateAll = true;
                let numberOfChecked = 0;
                let numberOfUnchecked = 0;
                props.workAgencyChoicesLogisticsUnit.forEach((logisticsUnit: LogisticsUnitChoiceOfWorkAgenciesLightModel) => {
                    if (!item[logisticsUnit.logisticsUnitId]) {
                        checkedSelect = false;
                        numberOfUnchecked += 1;
                    } else {
                        numberOfChecked += 1;
                    }
                });
                if (props.workAgencyChoicesLogisticsUnit.length === numberOfUnchecked || props.workAgencyChoicesLogisticsUnit.length === numberOfChecked) {
                    _indeterminateAll = false;
                }

                item.isSelectedAll = checkedSelect ? true : (_indeterminateAll ? null : false);
                item.isIndeterminateAll = _indeterminateAll;

                forceUpdate();
            });
    }

    const updateChoicesAgenciesAllLogisticsUnits = (checked: boolean, logisticsUnitId: string): void => {
        const logisticsUnitIds: Array<string> = [];
        props.workAgencyChoicesLogisticsUnit.forEach((element: LogisticsUnitChoiceOfWorkAgenciesLightModel) => {
            logisticsUnitIds.push(element.logisticsUnitId);
        });

        WorkAgenciesReferentialApiClient.AddOrRemoveWorkAgencyChoices({
            agencyId: logisticsUnitId,
            shouldAdd: checked,
            logisticsUnitIds: logisticsUnitIds
        })
            .then(() => {
                const item = workAgencyChoicesDataMap.get(logisticsUnitId);
                logisticsUnitIds.forEach((agencyId: string) => {
                    item[agencyId] = checked;
                });
                item.isSelectedAll = checked ? true : false;
                item.isIndeterminateAll = false;

                forceUpdate();
            });
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const _gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(_gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.workAgencies.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - _gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: Array<WorkAgencyLightModelExtended> = orderBy(workAgencyChoicesDataArray, props.sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="internal-work-agencies-grid">
            <Grid className='agencies-grid'
                data={gridData}
                sortable
                reorderable
                resizable
                sort={props.sort}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={props.handleSortChange}
                editField="inEdit"
                rowHeight={rowHeight}
                scrollable="virtual"
                skip={skip}
                total={totalGrid}
                pageSize={gridPageSize}
                onPageChange={pageChange}
                style={gridStyle}
            >
                <GridColumn field="isSelectedAll" orderIndex={getAgencyGridOrderIndexColumn("isSelectedAll", 0)} width={getWorkAgenciesWidth("isSelectedAll", 100)} title="Sélection"
                    cell={(props: GridCellProps) =>
                        <CustomCheckboxCell
                            {...props}
                            keyName="agencyId"
                            updateChoicesAllLogisticsUnits={updateChoicesAgenciesAllLogisticsUnits}
                        />}
                    headerCell={(_props: GridHeaderCellProps) =>
                        <CustomHeaderCellComponent tooltip="Activer/Désactiver un UE travaux pour toutes les zones logistiques" title={_props.title} field="isSelectedAll" sort={props.sort}
                            handleSortColumnChange={props.handleSortColumnChange} />
                    }
                />
                <GridColumn field="agencyLabel" orderIndex={getAgencyGridOrderIndexColumn("agencyLabel", 1)} width={getWorkAgenciesWidth("agencyLabel", 120)} title="Nom" />
                <GridColumn field="agencyId" orderIndex={getAgencyGridOrderIndexColumn("agencyId", 2)} width={getWorkAgenciesWidth("agencyId", 120)} title="Id" />
                {props.workAgencyChoicesLogisticsUnit.map((logisticsUnit: LogisticsUnitChoiceOfWorkAgenciesLightModel, i: number) =>
                    <GridColumn key={i} field={logisticsUnit.logisticsUnitId} orderIndex={getAgencyGridOrderIndexColumn(logisticsUnit.logisticsUnitId, 4 + i)} width={getWorkAgenciesWidth(logisticsUnit.logisticsUnitId, 150)} title={logisticsUnit.label}
                        cell={(props) =>
                            <CustomCheckboxCell
                                {...props}
                                keyName="agencyId"
                                updateChoicesLogisticsUnit={updateChoicesAgenciesLogisticsUnit}
                            />}
                        headerCell={(_props) =>
                            <CustomHeaderCellComponent tooltip="Activer/Désactiver un UE travaux pour la zone logistique" title={_props.title} field={logisticsUnit.logisticsUnitId} 
                                sort={props.sort} handleSortColumnChange={props.handleSortColumnChange} />
                        }
                    />
                )}
            </Grid>
        </Box>
    );
}
