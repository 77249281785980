import axios, { AxiosResponse } from 'axios';
import { DateRange } from '../../../../shared/models/DateRange';
import { BeneficiaryInvoicesDetailsLightModel } from './dataContracts/queryStack/BeneficiaryInvoicesDetailsLightModel';

const controllerUrl = "api/ThirdPartyBeneficiaryInvoices/";
const axiosConfigHeaders = { 'Accept': 'application/json', 'Content-Type': 'application/json' };

export class ThirdPartyBeneficiaryInvoicesApiClient {
    public static Search(token: string, beneficiary: string, date: DateRange, searchText: string)
        : Promise<AxiosResponse<BeneficiaryInvoicesDetailsLightModel>> {
        let url = `${controllerUrl}`;

        if (token)
            url += `SearchByToken?token=${encodeURIComponent(token)}`;
        else
            url += `Search?beneficiary=${encodeURIComponent(beneficiary)}&fromDate=${encodeURIComponent(date.start.toJSON())}&untilDate=${encodeURIComponent(date.end.toJSON())}&searchText=${encodeURIComponent(searchText)}`;

        return axios({
            method: 'POST',
            url: url,
            headers: axiosConfigHeaders,
        });
    }
}
