import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import React from 'react';
import { components, OptionProps } from 'react-select';
import Utilities from 'src/utils/Utilities';
import { FixedLists } from '../FixedLists';
import { ContactModel } from '../models/ContactModel';
import { SelectInputItemModel } from '../models/SelectInputItemModel';
import { externalNotReferenced, externalReferenced, internal } from '../TransportFlowForm';
import { SelectInput } from './CommonComponents';

interface ContactsContentProps {
    requestedBy: ContactModel,
    beneficiary: ContactModel,
    handleRequestedByTypeChange: (e: SelectInputItemModel) => void,
    handleRequestedByContactChanged: (e: SelectInputItemModel) => void,
    handleRequestedByContactLabel: (label: string) => void,
    handleBeneficiairyTypeChange: (e: SelectInputItemModel) => void,
    handleBeneficiairyContactChanged: (e: SelectInputItemModel) => void,
    handleBeneficiairyContactLabel: (label: string) => void,
    handleChangeBeneficiairyPhoneNumber: (phoneNumber: string) => void,
    handleBlurBeneficiairyPhoneNumber: (event: React.FocusEvent<HTMLInputElement>) => void
}

const OptionRequesterBeneficiaryItem = (props: OptionProps<SelectInputItemModel>): JSX.Element => {
    const data: SelectInputItemModel = props.data;

    return (
        <components.Option {...props}>
            <Box display="flex" flexDirection="row">
                {(data.isPrimary || data.isSecondary) && <Box pr={1}>
                    <FontAwesomeIcon icon={faHardHat} className={`fa-person-${data.isPrimary ? "primary" : "secondary"}`} />
                </Box>}
                <Box className="option-item">
                    {data.label}
                </Box>
            </Box>
        </components.Option>
    );
}

export const ContactsContent = (props: ContactsContentProps): JSX.Element => {

    const { requestedBy, beneficiary } = props;

    return (
        <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%" pb={'15px'} justifyContent="flex-start">
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start" width="50%">
                <SelectInput title="Type de demandeur"
                    titleClassName="label"
                    options={FixedLists.clientTypeList}
                    value={requestedBy.contactType}
                    handleChange={(e) => props.handleRequestedByTypeChange(e)}
                />
                {requestedBy.contactType?.value === internal &&
                    <SelectInput title="Demandeur"
                        titleClassName={requestedBy.undefinedContact ? "required-label" : "label"}
                        options={requestedBy.contactList}
                        value={requestedBy.contact}
                        components={{
                            Option: (props) => <OptionRequesterBeneficiaryItem {...props} />
                        }}
                        required={requestedBy.undefinedContact}
                        handleChange={(e) => props.handleRequestedByContactChanged(e)}
                    />
                }
                {requestedBy.contactType?.value === externalReferenced &&
                    <SelectInput title="Demandeur"
                        titleClassName={requestedBy.undefinedContact ? "required-label" : "label"}
                        options={requestedBy.contactList}
                        value={requestedBy.contact}
                        required={requestedBy.undefinedContact}
                        handleChange={(e) => props.handleRequestedByContactChanged(e)}
                    />
                }
                {requestedBy.contactType?.value === externalNotReferenced &&
                    <Box display="flex" flexDirection="column" mr={"20px"}>
                        <Box className="label">Demandeur</Box>
                        <Box display="flex" flexDirection="row">
                            <input type="text" className="input-text name"
                                value={requestedBy.contact?.label ?? ''}
                                onChange={(e) => props.handleRequestedByContactLabel(e.target.value)}
                            />
                        </Box>
                    </Box>
                }
            </Box>
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start" width="50%">
                <SelectInput title="Type de bénéficiaire"
                    titleClassName={!beneficiary.contactType ? "required-label" : "label"}
                    options={FixedLists.clientTypeList}
                    value={beneficiary.contactType}
                    handleChange={(e) => props.handleBeneficiairyTypeChange(e)}
                    required={!beneficiary.contactType}
                />
                {beneficiary.contactType?.value === internal &&
                    <SelectInput title="Bénéficiaire"
                        titleClassName={!beneficiary.contact || beneficiary.undefinedContact ? "required-label" : "label"}
                        options={beneficiary.contactList}
                        value={beneficiary.contact}
                        components={{
                            Option: (props) => <OptionRequesterBeneficiaryItem {...props} />
                        }}
                        handleChange={(e) => props.handleBeneficiairyContactChanged(e)}
                        required={!beneficiary.contact || beneficiary.undefinedContact}
                    />
                }
                {beneficiary.contactType?.value === externalReferenced &&
                    <SelectInput title="Bénéficiaire"
                        titleClassName={!beneficiary.contact || beneficiary.undefinedContact ? "required-label" : "label"}
                        options={beneficiary.contactList}
                        value={beneficiary.contact}
                        handleChange={(e) => props.handleBeneficiairyContactChanged(e)}
                        required={!beneficiary.contact || beneficiary.undefinedContact}
                    />
                }
                {beneficiary.contactType?.value === externalNotReferenced &&
                    <Box display="flex" flexDirection="column" mr={"20px"}>
                        <Box className={!beneficiary.contact || beneficiary.contact?.label?.trim() === "" ? "required-label" : "label"}>Bénéficiaire</Box>
                        <Box display="flex" flexDirection="row">
                            <input type="text" className={!beneficiary.contact || beneficiary.contact?.label?.trim() === "" ? "input-text name required" : "input-text name"}
                                value={beneficiary.contact?.label ?? ''}
                                onChange={(e) => props.handleBeneficiairyContactLabel(e.target.value)}
                            />
                        </Box>
                    </Box>
                }
                {beneficiary.contactType &&
                    <Box display="flex" flexDirection="column" mr={"20px"}>
                        <Box className={beneficiary.invalidPhoneNumber ? "required-label" : "label"}>Tel bénéficiaire</Box>
                        <Box display="flex" flexDirection="row">
                            <input type="text" className={beneficiary.invalidPhoneNumber ? "input-text tel required" : "input-text tel"}
                                value={beneficiary.contactPhoneNumber === null || beneficiary.contactPhoneNumber == null || beneficiary.contactPhoneNumber == undefined ? '' :
                                    beneficiary.contactPhoneNumber.startsWith('+32') || beneficiary.contactPhoneNumber.startsWith('0032') ?
                                        Utilities.formatPhoneNumber(beneficiary.contactPhoneNumber) : beneficiary.contactPhoneNumber}
                                onChange={(e) => props.handleChangeBeneficiairyPhoneNumber(e.target.value)}
                                onBlur={(e) => props.handleBlurBeneficiairyPhoneNumber(e)}
                            />
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    );
}
