import { Drawer } from "@mui/material";
import React from 'react';
import { TransportRequestComplementaryDetailsLightModel } from '../services/dataContracts/queryStack/TransportRequestComplementaryDetailsLightModel';
import { TransportRequestDetailsComponent } from './TransportRequestDetailsComponent';

interface TransportRequestDetailsDrawerProperties {
    handleDrawerRightClose: () => void,
    areRequestDetailsOpened: boolean,
    detailsData: TransportRequestComplementaryDetailsLightModel
}

export class TransportRequestDetailsDrawerComponent extends React.PureComponent<TransportRequestDetailsDrawerProperties, {}>{

    render() {
        return (
            <Drawer
                className='drawer-dc-details'
                variant="persistent"
                anchor="right"
                open={this.props.areRequestDetailsOpened}
                classes={{
                    paper: 'drawer-dc-details-paper'
                }}
            >
                <div className='toolbar-styles' />
                <TransportRequestDetailsComponent handleDrawerRightClose={this.props.handleDrawerRightClose} detailsData={this.props.detailsData} />
            </Drawer>
        );
    }
}