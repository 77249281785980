import { Box } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { UploadOnBeforeUploadEvent, UploadResponse } from '@progress/kendo-react-upload';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ToastService from 'src/ToastService';
import { ScaleLoaderComponent } from '../../../shared/components/Common/ScaleLoaderComponent';
import SimpleDialog from '../../../shared/components/Common/SimpleDialog';
import { ImportErrorsExtraResult, LineImportErrors } from '../../../shared/models/ImportErrorsExtraResult';
import { CommandResultLight, WebAppActionResult, WebAppActionResultEx } from '../../../shared/models/WebAppActionResult';
import BusinessErrors from '../../../utils/BusinessErrors';
import '../ReferentialsStyles.scss';
import { AddContactComponent } from './components/AddContactComponent';
import { EditContactComponent } from './components/EditContactComponent';
import { HeaderContentComponent } from './components/HeaderContentComponent';
import { InternalContactsComponent } from './components/InternalContactsComponent';
import './InternalContactsReferentialStyles.scss';
import { UpdateUserRequestArgs } from './services/dataContracts/controller/UpdateUserRequestArgs';
import { InternalContactLightModel } from './services/dataContracts/queryStack/InternalContactLightModel';
import { LogisticsUnitChoiceOfContactsLightModel } from './services/dataContracts/queryStack/LogisticsUnitChoiceOfContactsLightModel';
import { InternalContactsReferentialApiClient } from './services/InternalContactsReferentialApiClient';

interface InternalContacts {
    internalBeneficiariesRequestersList: Array<InternalContactLightModel>
    internalBeneficiariesRequestersChoicesLogisticsUnit: Array<LogisticsUnitChoiceOfContactsLightModel>
}

export const InternalContactsReferentialView = (): JSX.Element => {
    const inputSearchInternalBeneficiariesRequestersRef = React.useRef(null);
    const initialSort: SortDescriptor[] = [{ field: 'lastName', dir: 'asc' }];

    const [internalContacts, setInternalContacts] = useState<InternalContacts>({
        internalBeneficiariesRequestersChoicesLogisticsUnit: [],
        internalBeneficiariesRequestersList: []
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [isGlobalSearch, setIsGlobalSearch] = useState<boolean>(false);
    const [isAddContactDialogOpened, setIsAddContactDialogOpened] = useState<boolean>(false);
    const [isEditContactDialogOpened, setIsEditContactDialogOpened] = useState<boolean>(false);
    const [userSelected, setUserSelected] = useState<InternalContactLightModel>(null);

    const [userName, setUserName] = useState<string>('');
    const [userRole, setUserRole] = useState<string>('');
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);

    useEffect(() => {
        getInternalBeneficiariesRequesters("", false);
    }, []);

    const clearSearchText = (): void => {
        setIsGlobalSearch(false);
        inputSearchInternalBeneficiariesRequestersRef.current.value = "";
        BeneficiariesRequestersKeyPressed("", false);
    }

    const InternalBeneficiariesRequestersKeyPressed = debounce((text: string): void => {
        if (text.length >= 3) {
            BeneficiariesRequestersKeyPressed(text, isGlobalSearch);
        } else if (text.length === 0) {
            setIsGlobalSearch(false);
            BeneficiariesRequestersKeyPressed(text, false);
        }
    }, 500);

    const BeneficiariesRequestersKeyPressed = (text: string, isGlobalSearch: boolean): void => {
        getInternalBeneficiariesRequesters(text, isGlobalSearch);
    }

    const getInternalBeneficiariesRequesters = (searchText: string, isGlobalSearch: boolean, errorMessage?: string, listErrorMessage?: Array<string>, successMessage?: string, endMessage?: string): void => {
        setLoading(true);
        InternalContactsReferentialApiClient.GetInternalBeneficiariesRequestersWithContactChoicesLogisticsUnit(searchText, isGlobalSearch)
            .then(response => {
                setInternalContacts({
                    internalBeneficiariesRequestersList: response[0].data,
                    internalBeneficiariesRequestersChoicesLogisticsUnit: response[1].data
                });
                setUserSelected(null);

                if (errorMessage)
                    ToastService.showErrorToast(errorMessage, listErrorMessage, endMessage);
                else if (successMessage)
                    ToastService.showSuccessToast(successMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const handleSortColumnChange = (sortItems: SortDescriptor[]): void => {
        setSort(sortItems);
    }

    const inputSearchInternalBeneficiariesRequestersValue: string = inputSearchInternalBeneficiariesRequestersRef.current === null || inputSearchInternalBeneficiariesRequestersRef.current === undefined ? '' : inputSearchInternalBeneficiariesRequestersRef.current.value;

    const handlerAfterUpload = (res: UploadResponse): void => {
        const response: WebAppActionResultEx<ImportErrorsExtraResult> = res ? res.response : null;
        const listErrorMessage: string[] = [];
        let errorMessage = '';
        let successMessage = '';
        let endMessage = '';

        if (res.status === 200 && response) {
            const importLines: Array<LineImportErrors> = response.extraResult.linesWithErrors;
            if (importLines.length > 0) {
                importLines.forEach(e => {
                    e.errors.forEach(eError => {
                        let libMsg: string = BusinessErrors.GetError(eError);
                        if (!libMsg && eError)
                            libMsg = eError;
                        listErrorMessage.push(`- Ligne ${e.rowIndex} - ${libMsg}`);
                    });
                });

                errorMessage = 'Erreur lors de l\'import de la visibilité des demandeurs et bénéficiaires Colas:';
                endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des contacts sur vos zones logistiques';
            } else {
                successMessage = 'La visibilité des demandeurs et bénéficiaires Colas pour vos zones logistiques a bien été intégrée';
            }
        } else {
            errorMessage = 'Erreur lors de l\'import de la visibilité des demandeurs et bénéficiaires Colas, veuillez vérifier votre fichier';
            endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des demandeurs et bénéficiaires sur vos zones logistiques';
            successMessage = '';
        }

        getInternalBeneficiariesRequesters(inputSearchInternalBeneficiariesRequestersValue, isGlobalSearch, errorMessage, listErrorMessage, successMessage, endMessage);
    }

    const displayErrorResponse = (): void => {
        const errorMessage = 'Erreur lors de l\'import de la visibilité des demandeurs et bénéficiaires Colas, veuillez vérifier votre fichier';
        const endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des demandeurs et bénéficiaires sur vos zones logistiques';
        const successMessage = '';
        getInternalBeneficiariesRequesters(inputSearchInternalBeneficiariesRequestersValue, isGlobalSearch, errorMessage, [], successMessage, endMessage);
    }

    const handlerBeforeUpload = (e: UploadOnBeforeUploadEvent): void => {
        setLoading(true);
    }

    const handleIsGlobalSearchChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const checked: boolean = event.target.checked;
        setIsGlobalSearch(checked);
        getInternalBeneficiariesRequesters(inputSearchInternalBeneficiariesRequestersValue, checked);
    }

    const handleAddContactDialogClick = (): void => {
        setUserName('');
        setUserRole('');
        setIsAddContactDialogOpened(true);
    }

    const handleEditContactDialogClick = (): void => {
        setIsEditContactDialogOpened(true);
    }

    const handleChangeUserName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setUserName(event.target.value);
    }

    const handleChangeUserRole = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
        setUserRole(value);
    }

    const handleUpdateInternalContacts = (internalBeneficiariesRequesters: Array<InternalContactLightModel>, internalContactChoicesLogisticsUnit: Array<LogisticsUnitChoiceOfContactsLightModel>): void => {
        setInternalContacts({
            internalBeneficiariesRequestersList: internalBeneficiariesRequesters,
            internalBeneficiariesRequestersChoicesLogisticsUnit: internalContactChoicesLogisticsUnit
        });
    }

    const handleAddContactClick = (): void => {
        const username = userName.trim().toLocaleLowerCase();
        InternalContactsReferentialApiClient.EnsureUser(username, userRole)
            .then((res) => {
                const resultType = typeof res.data;

                if (resultType === "string") {
                    inputSearchInternalBeneficiariesRequestersRef.current.value = username;
                    setIsGlobalSearch(true);
                    setIsAddContactDialogOpened(false);
                    getInternalBeneficiariesRequesters(username, true);

                } else {
                    if ((res.data as WebAppActionResult).commandResults) {
                        const errors: string[] = BusinessErrors.Get(res.data as WebAppActionResult);
                        if (errors.length > 0) {
                            ToastService.showErrorToast("", errors);
                        }
                    } else {
                        const error: string = BusinessErrors.GetError((res.data as CommandResultLight).businessErrors[0]);
                        ToastService.showErrorToast(error);
                    }
                }
            });
    }

    const handleEditContactClick = (requestArgs: UpdateUserRequestArgs): void => {
        setIsEditContactDialogOpened(false);
        InternalContactsReferentialApiClient.UpdateUser(requestArgs)
            .then((res) => {
                const errors: string[] = BusinessErrors.Get(res.data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                }
                else {
                    //On ne raffraichit que la ligne car le composant avec la virtualisation ne gère pas correctement le scroll
                    const newList = [...internalContacts.internalBeneficiariesRequestersList];
                    const currentUser = newList.find(x => x.contactId == requestArgs.userName);
                    currentUser.phoneNumber = requestArgs.phoneNumber;
                    currentUser.roleId = requestArgs.userRole;

                    setUserSelected({
                        ...userSelected,
                        phoneNumber: requestArgs.phoneNumber,
                        roleId: requestArgs.userRole
                    })

                    setInternalContacts({
                        ...internalContacts,
                        internalBeneficiariesRequestersList: newList
                    });
                }
            });
    }

    const handleUserSelected = (userItem: InternalContactLightModel): void => {
        if (userItem.contactId === userSelected?.contactId) {
            setUserSelected(null);
        }
        else {
            setUserSelected(userItem);
        }
    }

    const isEditUserEnabled = userSelected != null && userSelected.roleId !== "DIS" && userSelected.roleId !== "LOG";

    const headerContentComponent = useMemo((): JSX.Element =>
        <HeaderContentComponent
            inputSearchInternalBeneficariesRequestersValue={inputSearchInternalBeneficiariesRequestersValue}
            inputSearchInternalBeneficariesRequestersRef={inputSearchInternalBeneficiariesRequestersRef}
            isEditUserEnabled={isEditUserEnabled}
            isUserSelected={userSelected != null}
            sort={sort}
            isGlobalSearch={isGlobalSearch}
            handleInternalBeneficariesRequestersKeyPress={InternalBeneficiariesRequestersKeyPressed}
            handleClearSearchText={clearSearchText}
            handlerAfterUpload={handlerAfterUpload}
            handlerBeforeUpload={handlerBeforeUpload}
            displayErrorResponse={displayErrorResponse}
            handleIsGlobalSearchChanged={handleIsGlobalSearchChanged}
            handleAddContactDialogClick={handleAddContactDialogClick}
            handleEditContactDialogClick={handleEditContactDialogClick}
        />, [inputSearchInternalBeneficiariesRequestersValue, inputSearchInternalBeneficiariesRequestersRef, sort, isGlobalSearch, isEditUserEnabled, userSelected != null]);

    const internalContactsComponent = useMemo((): JSX.Element =>
        <InternalContactsComponent
            userSelected={userSelected}
            handleUserSelected={handleUserSelected}
            internalContacts={internalContacts.internalBeneficiariesRequestersList}
            internalContactChoicesLogisticsUnit={internalContacts.internalBeneficiariesRequestersChoicesLogisticsUnit}
            sort={sort}
            handleSortChange={handleSortChange}
            handleSortColumnChange={handleSortColumnChange}
            handleUpdateInternalContacts={handleUpdateInternalContacts}
        />, [internalContacts, sort, userSelected]);

    return (
        <Box className="internal-contacts referencial">
            <Box display="flex" flexDirection="row" flex="wrap" className="view-title">
                Gestion des bénéficiaires et demandeurs internes
            </Box>
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box display="flex" flexDirection="column" flex="wrap">
                        {headerContentComponent}
                        {(loading ?
                            <ScaleLoaderComponent loading={loading} />
                            :
                            internalContactsComponent
                        )}
                    </Box>
                </IntlProvider>
            </LocalizationProvider>
            <SimpleDialog isOpen={isAddContactDialogOpened}
                onClose={() => setIsAddContactDialogOpened(false)}
                closeIcon={true}
                dialogTitle="Ajouter un utilisateur"
                classNameVal="add-contact-dialog"
                component={
                    <AddContactComponent
                        userName={userName}
                        userRole={userRole}
                        handleChangeUserName={handleChangeUserName}
                        handleChangeUserRole={handleChangeUserRole}
                        handleAddContactClick={handleAddContactClick}
                    />
                }
            />
            <SimpleDialog isOpen={isEditContactDialogOpened && (userSelected != null)}
                onClose={() => setIsEditContactDialogOpened(false)}
                closeIcon={true}
                dialogTitle="Modifier un utilisateur"
                classNameVal="edit-contact-dialog"
                component={
                    <EditContactComponent
                        userSelected={userSelected}
                        handleEditContactClick={handleEditContactClick}
                    />
                }
            />
        </Box>
    );
}
