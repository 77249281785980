import axios, { AxiosResponse } from 'axios';
import { RequestedTransportationsExportRequestArgs } from './dataContracts/controller/RequestedTransportationsExportRequestArgs';

const controllerUrl = "api/RequestedTransportationsExport/";
const axiosConfigHeaders = { 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/json' };

export class RequestedTransportationsExportApiClient {

    public static Export = (requestArgs: RequestedTransportationsExportRequestArgs)
        : Promise<AxiosResponse<Blob>> => {
            return axios.post(`${controllerUrl}Export`, requestArgs, { headers: axiosConfigHeaders, responseType: 'blob' });
    }
}
