import { Tooltip } from "@mui/material";
import { SortDescriptor } from "@progress/kendo-data-query";
import React from 'react';

interface CustomHeaderCellProps {
    title: string,
    tooltip: string,
    field: string,
    sort?: SortDescriptor[],
    handleSortColumnChange?: (sortItems: SortDescriptor[]) => void,
}


export const CustomHeaderCellComponent = (props: CustomHeaderCellProps) => {
    let sortIconClass = "";
    let sortIndex = -1;
    if (props.sort?.length > 0) {
        const currentSortIndex: number = props.sort.findIndex(x => x.field == props.field);
        if(currentSortIndex !== -1){
            sortIconClass = props.sort[currentSortIndex].dir === "asc" ?  "k-icon k-i-sort-asc-sm" : "k-icon k-i-sort-desc-sm";
            sortIndex = currentSortIndex;
        }
    }

    const handleSortChange = () =>{
        let sortItems: SortDescriptor[] = null;
        if (props.sort.length > 0 && props.sort[0].dir === 'asc' && props.sort[0].field === props.field) {
            sortItems = [{ field: props.field, dir: 'desc' }];
        } else if (props.sort.length > 0 && props.sort[0].dir === 'desc' && props.sort[0].field === props.field) {
            sortItems = [];
        } else {
            sortItems = [{ field: props.field, dir: 'asc' }];
        }
        props.handleSortColumnChange(sortItems);
    }

    return (
        <Tooltip title={props.tooltip} placement="top" onClick={handleSortChange} className="referential-tooltip">
            <div>
                <span>{props.title}</span>
                {sortIconClass !== "" &&
                    <span className={sortIconClass}></span>
                }
                {props.sort.length > 1 && sortIndex !== -1 &&
                    <span className="k-sort-order">{(sortIndex + 1)}</span>
                }
            </div>
        </Tooltip>
    );
}