import { faMapMarkerAlt, faTag, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardContent, Divider } from '@mui/material';
import React from 'react';
import { OwnedEntity } from '../models/OwnedEntity';
import { EntityChangeLightModel } from '../services/dataContracts/queryStack/EntityChangeLightModel';
import { sortKeyAddress } from '../TransportRequestChangesResources';
import ModifiedPropertyComponent from './ModifiedPropertyComponent';

//v1 RENAME TO OwnedEntityChanges

//Consider v2 -> OwnedEntityChanges devintela base OwnedPropertyCanges qui gere quel'afficgahe des proprietes new od

//en suite des AddressEntityChanges, AddressEntityChanges, ContactEntityChages qui l'utiles

interface OwnedEntityChangesComponentProps {
    trackingEntity: EntityChangeLightModel,
    HiddenProperties: string[],
    propertyLabels: Map<string, string>,
    vehicleTypesLabels: Map<string, string>,
    getValueOfParsedJson: (objectValues: any, nameField: string) => any,
    reOrderFields: (objectElem: any, sortKeyObj) => string[]
}

enum Title {
    DeliveryAddress = "Adresse d'arrivée",
    PickupAddress = "Adresse de départ",
    Beneficiary = "Chef de chantier",
    RequestedBy = "Demandeur",
    DeliveryCoordinates = "Coordonnées d'arrivée",
    PickupCoordinates = "Coordonnées de départ",
    Product = "Produit",
    Customer = "Client",
    Supplier = "Fournisseur"
}

export default class OwnedEntityChangesComponent extends React.PureComponent<OwnedEntityChangesComponentProps, {}>{
    
    render() {
        let iconName;
        let entityText = "";
        const oldValuesObject = JSON.parse(this.props.trackingEntity.oldValuesJson);
        const newValuesObject = JSON.parse(this.props.trackingEntity.newValuesJson) ?? {};
        let myKeys = Object.keys(newValuesObject);

        switch (this.props.trackingEntity.entityType) {
            case OwnedEntity.AddressInfo:
                iconName = faMapMarkerAlt;
                myKeys = this.props.reOrderFields(newValuesObject, sortKeyAddress);
                if (this.props.trackingEntity.navigationName === OwnedEntity.DeliveryAddress)
                    entityText = Title.DeliveryAddress;
                else
                    entityText = Title.PickupAddress;
                break;
            case OwnedEntity.GpsCoordinates:
                iconName = faMapMarkerAlt;
                if (this.props.trackingEntity.navigationName === OwnedEntity.DeliveryCoordinates)
                    entityText = Title.DeliveryCoordinates;
                else
                    entityText = Title.PickupCoordinates;
                break;
            case OwnedEntity.ContactInfo:
                iconName = faUser;
                if (this.props.trackingEntity.navigationName === OwnedEntity.JobForeman)
                    entityText = Title.Beneficiary;
                else
                    entityText = Title.RequestedBy;
                break;
            case OwnedEntity.ProductInfo:
                iconName = faTag;
                entityText = Title.Product;
                break;
            case OwnedEntity.SiteInfo:
                iconName = faUser;
                if (this.props.trackingEntity.navigationName === OwnedEntity.SenderSite)
                    entityText = Title.Supplier;
                else
                    entityText = Title.Customer;
                break;
        }
        return (
            <Card>
                <CardContent className="my-card-content">
                    <Box display="flex" flexDirection="row" alignItems="center" flexWrap="nowrap" pb={1}>
                        <Box>
                            <FontAwesomeIcon icon={iconName} />
                        </Box>
                        <Box className="card-title-text" pl={1}>
                            {entityText}
                        </Box>
                    </Box>
                    <Divider style={{ height: '2px' }} />
                    <Box pt={1} key={`dcte:${this.props.trackingEntity.entityType}:${this.props.trackingEntity.navigationName}`} flexDirection="column" justifyContent="flex-start">
                        {newValuesObject ? myKeys.map((o: any, index4) => (
                            <ModifiedPropertyComponent entityType={this.props.trackingEntity.entityType} hiddenProperties={this.props.HiddenProperties} key={`dctef:${this.props.trackingEntity.entityType}:${index4}`} propertyLabels={this.props.propertyLabels}
                                getValueOfParsedJson={this.props.getValueOfParsedJson} elem={o} oldValuesParsedTracking={oldValuesObject} newValuesParsedTracking={newValuesObject} vehicleTypesLabels={this.props.vehicleTypesLabels} />
                        )) : ''}
                    </Box>
                </CardContent>
            </Card>
        )
    }
}