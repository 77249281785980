import { Tooltip } from '@mui/material';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridItemChangeEvent, GridPageChangeEvent, GridRowProps } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import { PlanningVehicleStatusComponent } from '../../../../shared/components/PlanningVehicleStatus/PlanningVehicleStatusComponent';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { PlanningVehiclesPublishedCostsLineModel } from '../models/PlanningVehiclesPublishedCostsLineModel';
import { TransportPurchasePriceKindEnum } from '../models/TransportPurchasePriceKindEnum';
import { SizingUtilities } from '../SizingUtilities';
import { CostsRemarksOfTransporterHeaderComponent } from './CostsRemarksOfTransporterHeaderComponent';
import { CustomCostsAreAgreedCellComponent } from './CustomCostsAreAgreedCellComponent';
import { CustomCostsRemarksOfTransporterCellComponent } from './CustomCostsRemarksOfTransporterCellComponent';

interface PublishedCostsGridComponentProps {
    isForInternalTransporters: boolean,
    role: string,
    data: PlanningVehiclesPublishedCostsLineModel[],
    cellRender: (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps) => React.ReactElement,
    selectionChange: (dataItem: PlanningVehiclesPublishedCostsLineModel) => void,
    rowRender: (trElement: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps) => React.ReactElement<HTMLTableRowElement>
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const TransporterPublishedCostsGridName = 'transporterPublishedCosts';

export const PublishedCostsGridComponent = (props: PublishedCostsGridComponentProps): JSX.Element => {
    const ModuleKey = props.isForInternalTransporters
        ? props.role === "DIS" ? AppModule.InternalTransporterPublishedCosts : AppModule.ThirdPartyInternalTransporterPublishedCosts
        : AppModule.ThirdPartyExternalTransporterPublishedCosts;

    const forceUpdate = useForceUpdate();

    const [skip, setSkip] = useState<number>(0);

    const itemChange = (event: GridItemChangeEvent): void => {
        event.dataItem[event.field] = event.value;
        forceUpdate();
    }

    const getPublishedCostsWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, TransporterPublishedCostsGridName, fieldName, defaultWidth);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            TransporterPublishedCostsGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, TransporterPublishedCostsGridName, event.columns);
        forceUpdate();
    }

    const getPublishedCostsGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, TransporterPublishedCostsGridName, fieldName, defaultIndex);
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const data: PlanningVehiclesPublishedCostsLineModel[] = props.data.filter(x => x.isHidden === false);
    const gridData: PlanningVehiclesPublishedCostsLineModel[] = data.slice(skip, skip + gridPageSize);
    const totalGrid: number = data.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };

    return (
        <LocalizationProvider language="fr-FR">
            <IntlProvider locale="fr">
                <Grid
                    className="published-costs-grid"
                    data={gridData}
                    editField="inEdit"
                    cellRender={props.cellRender}
                    rowRender={props.rowRender}
                    onItemChange={itemChange}
                    onColumnResize={onResizeHandler}
                    onColumnReorder={(e) => onReorderHandler(e)}
                    reorderable
                    resizable
                    rowHeight={rowHeight}
                    scrollable="virtual"
                    skip={skip}
                    total={totalGrid}
                    pageSize={gridPageSize}
                    onPageChange={pageChange}
                    style={gridStyle}
                >
                    <GridColumn field="costsAreAgreed" title="Validation" orderIndex={getPublishedCostsGridOrderIndexColumn("costsAreAgreed", 0)} width={getPublishedCostsWidth("costsAreAgreed", 100)} editable={true}
                        cell={(properties: GridCellProps) => <CustomCostsAreAgreedCellComponent {...properties} changeHandler={props.selectionChange} />} />
                    <GridColumn field="costsRemarksOfTransporter" title="Commentaire transporteur" orderIndex={getPublishedCostsGridOrderIndexColumn("costsRemarksOfTransporter", 1)} width={getPublishedCostsWidth("costsRemarksOfTransporter", 300)} editable={true}
                        headerCell={() => <CostsRemarksOfTransporterHeaderComponent title="Commentaire transporteur" />}
                        cell={(properties: GridCellProps) => <CustomCostsRemarksOfTransporterCellComponent {...properties} width={getPublishedCostsWidth("costsRemarksOfTransporter", 300)} />} />
                    <GridColumn field="statusValue" title="Statut" orderIndex={getPublishedCostsGridOrderIndexColumn("statusValue", 2)} width={getPublishedCostsWidth("statusValue", 50)} editable={false}
                        cell={(properties: GridCellProps) =>
                            <td>
                                <PlanningVehicleStatusComponent
                                    isCanceled={properties.dataItem.isCanceled}
                                    transporterConfirmationDate={properties.dataItem.transporterConfirmationDate}
                                    transporterOrderId={properties.dataItem.transporterOrderId}
                                    transporterIsInternal={properties.dataItem.transporterIsInternal}
                                    cancellationReason={properties.dataItem.cancellationReason}
                                    cancellationRemarks={properties.dataItem.cancellationRemarks}
                                    purchaseIsCanceled={properties.dataItem.purchaseIsCanceled}
                                    saleIsCanceled={properties.dataItem.saleIsCanceled}
                                    cancellationOrderSentDate={properties.dataItem.cancellationOrderSentDate}
                                    cancellationOrderConfirmationDate={properties.dataItem.cancellationOrderConfirmationDate}
                                />
                            </td>
                        }
                    />
                    <GridColumn field="specificOrNegotiatedPrice" title="Tarif spécifique/négocié" orderIndex={getPublishedCostsGridOrderIndexColumn("specificOrNegotiatedPrice", 3)} width={getPublishedCostsWidth("specificOrNegotiatedPrice", 120)} editable={false}
                        cell={(properties: GridCellProps) => {
                            const dataItem: PlanningVehiclesPublishedCostsLineModel = properties.dataItem;
                            const className = dataItem.isSpecificLine 
                                ? "specific-price-kind"
                                : dataItem.isNegotiatedLine
                                    ? "negotiated-price-kind"
                                    : "";
                            
                            const priceKind = dataItem.priceKindBase === TransportPurchasePriceKindEnum.PerHour
                                ? "H"
                                : dataItem.priceKindBase === TransportPurchasePriceKindEnum.PerTon
                                    ? "T"
                                    : dataItem.priceKindBase === TransportPurchasePriceKindEnum.PerTurn
                                        ? "Tr"
                                        : "";

                            let tooltipTitle = priceKind ? `Tarif ${dataItem.isSpecificLine 
                                ? "spécifique"
                                : dataItem.isNegotiatedLine
                                    ? "achat négocié"
                                    : ""}` : "";

                            if(tooltipTitle) {
                                tooltipTitle += `${dataItem.priceKindBase === TransportPurchasePriceKindEnum.PerHour
                                    ? " à l'heure"
                                    : dataItem.priceKindBase === TransportPurchasePriceKindEnum.PerTon
                                        ? " à la tonne"
                                        : dataItem.priceKindBase === TransportPurchasePriceKindEnum.PerTurn
                                            ? " au tour"
                                            : ""
                                }`;
                            }
                            
                            return (
                                <td>
                                    {priceKind && className && 
                                        <Tooltip title={tooltipTitle}>
                                            <div className={className}>
                                                <div className={`${className}-font`}>{priceKind}</div>
                                            </div>
                                        </Tooltip>
                                    }
                                </td>
                            )
                        }} />
                    <GridColumn field="costsRemarksOfLogistician" title="Commentaire préfac" orderIndex={getPublishedCostsGridOrderIndexColumn("costsRemarksOfLogistician", 4)} width={getPublishedCostsWidth("costsRemarksOfLogistician", 120)} editable={false} />
                    <GridColumn field="planningDate" title="Date" orderIndex={getPublishedCostsGridOrderIndexColumn("planningDate", 5)} width={getPublishedCostsWidth("planningDate", 120)} format="{0:dd-MM-yyyy}" editable={false} />
                    <GridColumn field="licencePlate" title="Immatriculation" orderIndex={getPublishedCostsGridOrderIndexColumn("licencePlate", 6)} width={getPublishedCostsWidth("licencePlate", 120)} editable={false} />
                    {props.isForInternalTransporters && <GridColumn field="equipmentId" title="Id du véhicule" orderIndex={getPublishedCostsGridOrderIndexColumn("equipmentId", 7)} width={getPublishedCostsWidth("equipmentId", 120)} editable={false} />}
                    <GridColumn field="vehicleTypeLabel" title="Type de camion" orderIndex={getPublishedCostsGridOrderIndexColumn("vehicleTypeLabel", 8)} width={getPublishedCostsWidth("vehicleTypeLabel", 120)} editable={false} />
                    <GridColumn field="confirmationNumber" title="N°BC" orderIndex={getPublishedCostsGridOrderIndexColumn("confirmationNumber", 9)} width={getPublishedCostsWidth("confirmationNumber", 120)} editable={false} />
                    <GridColumn field="tansportFlowBusinessId" title="Flux" orderIndex={getPublishedCostsGridOrderIndexColumn("tansportFlowBusinessId", 10)} width={getPublishedCostsWidth("tansportFlowBusinessId", 120)} editable={false} />
                    <GridColumn field="senderSite" title="Site départ" orderIndex={getPublishedCostsGridOrderIndexColumn("senderSite", 11)} width={getPublishedCostsWidth("senderSite", 120)} editable={false} />
                    <GridColumn field="senderSiteCity" title="Ville départ" orderIndex={getPublishedCostsGridOrderIndexColumn("senderSiteCity", 12)} width={getPublishedCostsWidth("senderSiteCity", 120)} editable={false} />
                    <GridColumn field="receiverSite" title="Site arrivée" orderIndex={getPublishedCostsGridOrderIndexColumn("receiverSite", 13)} width={getPublishedCostsWidth("receiverSite", 120)} editable={false} />
                    <GridColumn field="receiverSiteCity" title="Ville arrivée" orderIndex={getPublishedCostsGridOrderIndexColumn("receiverSiteCity", 14)} width={getPublishedCostsWidth("receiverSiteCity", 120)} editable={false} />
                    <GridColumn field="beneficiaryName" title="Bénéficiaire" orderIndex={getPublishedCostsGridOrderIndexColumn("beneficiaryName", 15)} width={getPublishedCostsWidth("beneficiaryName", 120)} editable={false} />
                    <GridColumn field="priceKind" title="Tarif achat" orderIndex={getPublishedCostsGridOrderIndexColumn("priceKind", 16)} width={getPublishedCostsWidth("priceKind", 120)} editable={false} />
                    <GridColumn field="unitPrice" title="Prix unitaire" orderIndex={getPublishedCostsGridOrderIndexColumn("unitPrice", 17)} width={getPublishedCostsWidth("unitPrice", 120)} editable={false} />
                    <GridColumn field="deliveredQuantity" title="Qté comptabilisée" orderIndex={getPublishedCostsGridOrderIndexColumn("deliveredQuantity", 18)} width={getPublishedCostsWidth("deliveredQuantity", 120)} editable={false} />
                    <GridColumn field="deliveredNumberOfTurns" title="Tour(s) comptabilisé(s)" orderIndex={getPublishedCostsGridOrderIndexColumn("deliveredNumberOfTurns", 19)} width={getPublishedCostsWidth("deliveredNumberOfTurns", 120)} editable={false} />
                    <GridColumn field="expectedTimeBillableOnMainPrice" title="Temps à facturer" orderIndex={getPublishedCostsGridOrderIndexColumn("expectedTimeBillableOnMainPrice", 20)} width={getPublishedCostsWidth("expectedTimeBillableOnMainPrice", 120)} editable={false} />
                    {!props.isForInternalTransporters && <GridColumn field="contractualPurchasePriceContractNumber" title="PAC" orderIndex={getPublishedCostsGridOrderIndexColumn("contractualPurchasePriceContractNumber", 21)} width={getPublishedCostsWidth("contractualPurchasePriceContractNumber", 80)} editable={false} />}
                    <GridColumn field="totalCosts" title="Total camions" orderIndex={getPublishedCostsGridOrderIndexColumn("totalCosts", 22)} width={getPublishedCostsWidth("totalCosts", 120)} editable={false} />
                    <GridColumn field="totalFees" title="Total frais" orderIndex={getPublishedCostsGridOrderIndexColumn("totalFees", 23)} width={getPublishedCostsWidth("totalFees", 120)} editable={false} />
                    <GridColumn field="costsPublishedDate" title="Date préfac" orderIndex={getPublishedCostsGridOrderIndexColumn("costsPublishedDate", 24)} width={getPublishedCostsWidth("costsPublishedDate", 120)} format="{0:dd-MM-yyyy}" editable={false} />
                    <GridColumn field="costPurchaseProvisioningBusinessId" orderIndex={getPublishedCostsGridOrderIndexColumn("costPurchaseProvisioningBusinessId", 25)} width={getPublishedCostsWidth("costPurchaseProvisioningBusinessId", 70)} title="Lot coût" editable={false} />
                    <GridColumn field="feesPurchaseProvisioningBusinessId" orderIndex={getPublishedCostsGridOrderIndexColumn("feesPurchaseProvisioningBusinessId", 26)} width={getPublishedCostsWidth("feesPurchaseProvisioningBusinessId", 70)} title="Lot frais" editable={false} />
                    <GridColumn field="buyerLogisticsUnitLabel" orderIndex={getPublishedCostsGridOrderIndexColumn("buyerLogisticsUnitLabel", 27)} width={getPublishedCostsWidth("buyerLogisticsUnitLabel", 70)} title="Zone logistique" editable={false} />
                </Grid>
            </IntlProvider>
        </LocalizationProvider>
    );
}
