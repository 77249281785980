import { faInfoCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, IconButton } from '@mui/material';
import React from 'react';
import { TimePeriodRelatedPriceCellExplanation } from '../../../shared/Quotation/services/dataContracts/TimePeriodRelatedPriceCellExplanation';
import { TransportQuotationResult } from '../../../shared/Quotation/services/dataContracts/TransportQuotationResult';
import { QuotationCallModel } from '../models/QuotationCallModel';
import '../TransportFlowStyles.scss';

interface QuotationSettingsComponentProps {
    quotationResult: TransportQuotationResult,
    quotationCall: QuotationCallModel,
    priceUnavailabilityReason: string,
    payerLabel: string,
    isZephyrJobCodeDisabled: boolean,
    payerZephyrJobCode: string,
    isPayerAutomatic: boolean,
    handleQuotationDetailsExpandedChange: () => void,
    refreshZephyrJobCode: () => void
}

export const QuotationSettingsComponent = (props: QuotationSettingsComponentProps): JSX.Element => {
    const { quotationResult, quotationCall, handleQuotationDetailsExpandedChange, priceUnavailabilityReason, payerLabel } = props;

    return (
        <Box display="flex" flexDirection="row" width="100%" justifyContent="flex-start">
            <Accordion className="quotation-settings"
                expanded={quotationCall.isQuotationDetailsExpanded}
                onChange={() => handleQuotationDetailsExpandedChange()}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="quotation-settings"
                    id="quotation-settings"
                >
                    <Box>
                        <FontAwesomeIcon className="fa-info-circle-icon" size="lg" icon={faInfoCircle} />
                        Paramètres de cotation
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box display="flex" flexDirection="column" width="100%" pb={'15px'}>
                        <Box display="flex" flexWrap="wrap" flexDirection="row" width="100%" pb={'15px'} justifyContent="flex-start">
                            <Box display="flex" flexDirection="column" className="quotation-setting-section">
                                <Box className="label" textAlign="center">Tarif appliqué (de base)</Box>
                                {quotationResult?.priceExplanation?.basePrice?.priceCellKind.toString() === "TripDurationRelated" &&
                                    <Box display="flex" flexDirection="row" justifyContent="center">
                                        <Box ml={"5px"}><b>{quotationResult?.priceExplanation?.basePrice?.tripDurationLimit ? quotationResult?.priceExplanation?.basePrice?.tripDurationLimit : ''}</b></Box>
                                        <Box ml={"5px"}>{quotationResult?.priceExplanation?.basePrice?.unitPrice ? quotationResult?.priceExplanation?.basePrice?.unitPrice + " €" : ''}</Box>
                                        <Box ml={"5px"}><b>{quotationResult?.priceExplanation?.basePrice?.totalPrice ? " | " + quotationResult?.priceExplanation?.basePrice?.totalPrice.toCurrencyString() : ''}</b></Box>
                                    </Box>
                                }
                                {quotationResult?.priceExplanation?.basePrice?.priceCellKind.toString() === "TimePeriodRelated" &&
                                    <Box display="flex" flexDirection="column" justifyContent="center">
                                        <Box ml={"5px"} textAlign="center"><b>{quotationResult?.priceExplanation?.basePrice?.totalPrice ? quotationResult?.priceExplanation?.basePrice?.totalPrice.toCurrencyString() : ''}</b></Box>
                                        <Box display="flex" flexDirection="row" justifyContent="center">
                                            {
                                                quotationResult?.priceExplanation?.basePrice?.timePeriodRelatedDetail?.map((element: TimePeriodRelatedPriceCellExplanation, index: number) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <Box ml={"5px"}>{element.quantity + " X " + element.unitPrice + " € ( " + element.timePeriodId + " )"}</Box>
                                                            {
                                                                index < quotationResult?.priceExplanation?.basePrice?.timePeriodRelatedDetail.length - 1 ? ', ' : ''
                                                            }
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Box>
                                }
                                {quotationResult?.priceExplanation?.basePrice?.priceCellKind.toString() === "MaxPrice" &&
                                    <Box display="flex" flexDirection="row" justifyContent="center">
                                        <Box ml={"5px"}><b>TMax</b></Box>
                                        <Box ml={"5px"}>{quotationResult?.priceExplanation?.basePrice?.unitPrice ? quotationResult?.priceExplanation?.basePrice?.unitPrice + " €" : ''}</Box>
                                        <Box ml={"5px"}><b>{quotationResult?.priceExplanation?.basePrice?.totalPrice ? " | " + quotationResult?.priceExplanation?.basePrice?.totalPrice.toCurrencyString() : ''}</b></Box>
                                    </Box>
                                }
                                {quotationResult?.priceExplanation?.basePrice?.priceCellKind.toString() === "MinPrice" &&
                                    <Box display="flex" flexDirection="row" justifyContent="center">
                                        <Box ml={"5px"}><b>TMin</b></Box>
                                        <Box ml={"5px"}>{quotationResult?.priceExplanation?.basePrice?.unitPrice ? quotationResult?.priceExplanation?.basePrice?.unitPrice + " €" : ''}</Box>
                                        <Box ml={"5px"}><b>{quotationResult?.priceExplanation?.basePrice?.totalPrice ? " | " + quotationResult?.priceExplanation?.basePrice?.totalPrice.toCurrencyString() : ''}</b></Box>
                                    </Box>
                                }

                            </Box>
                            <Box display="flex" flexDirection="column" className="quotation-setting-section">
                                <Box className="label" textAlign="center">Forfait chantier</Box>
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    <>
                                        <Box ml={"5px"}>
                                            <b>
                                                {quotationResult?.priceExplanation?.priceChanges?.coatingApplicationMode?.mode ?
                                                    quotationResult?.priceExplanation?.priceChanges?.coatingApplicationMode?.mode.toString().toLowerCase() === "manual" ? "Manuel" :
                                                        (quotationResult?.priceExplanation?.priceChanges?.coatingApplicationMode?.mode.toString().toLowerCase() === "mechanical" ? "Mécanique" :
                                                            (quotationResult?.priceExplanation?.priceChanges?.coatingApplicationMode?.mode.toString().toLowerCase() === "mixed" ? "Mixte" :
                                                                (quotationResult?.priceExplanation?.priceChanges?.coatingApplicationMode?.mode.toString().toLowerCase() === "notApplicable" ? "Blanc" : ""))) : ''}
                                            </b>
                                        </Box>
                                        <Box ml={"5px"}>{quotationResult?.priceExplanation?.priceChanges?.coatingApplicationMode?.unitPrice ? quotationResult?.priceExplanation?.priceChanges?.coatingApplicationMode?.unitPrice + " €" : ''}</Box>
                                        <Box ml={"5px"}><b>{quotationResult?.priceExplanation?.priceChanges?.coatingApplicationMode?.totalPriceChange ? " | " + quotationResult?.priceExplanation?.priceChanges?.coatingApplicationMode?.totalPriceChange.toCurrencyString() : ''}</b></Box>
                                    </>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" className="quotation-setting-section">
                                <Box className="label" textAlign="center">Camion(s) ajouté(s)</Box>
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    <>
                                        <Box ml={"5px"}>{quotationResult?.priceExplanation?.priceChanges?.additionalVehicles?.quantity ? quotationResult?.priceExplanation?.priceChanges?.additionalVehicles?.quantity : ''}</Box>
                                        <Box ml={"5px"}><b>{quotationResult?.priceExplanation?.priceChanges?.additionalVehicles?.totalPriceChange ? " | " + quotationResult?.priceExplanation?.priceChanges?.additionalVehicles?.totalPriceChange.toCurrencyString() : ''}</b></Box>
                                    </>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" className="quotation-setting-section">
                                <Box className="label" textAlign="center">Tranche horaire</Box>
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    {(quotationResult?.priceExplanation?.priceChanges?.timeSlot?.slotType === "WorkingDaySlot") &&
                                        <>
                                            <Box ml={"5px"}>
                                                <b>
                                                    {quotationResult?.priceExplanation?.priceChanges?.timeSlot?.slotId ?
                                                        quotationResult?.priceExplanation?.priceChanges?.timeSlot?.slotId === "Full" ? "Pleine" :
                                                            (quotationResult?.priceExplanation?.priceChanges?.timeSlot?.slotId === "Staggered" ? "Décalée" :
                                                                (quotationResult?.priceExplanation?.priceChanges?.timeSlot?.slotId === "OffPeak" ? "Creuse" :
                                                                    (quotationResult?.priceExplanation?.priceChanges?.timeSlot?.slotId === "Late" ? "Tardive" :
                                                                        (quotationResult?.priceExplanation?.priceChanges?.timeSlot?.slotId === "Night" ? "Nuit" : '')))) : ''}
                                                </b>
                                            </Box>
                                            <Box ml={"5px"}>{quotationResult?.priceExplanation?.priceChanges?.timeSlot?.coefficient ? quotationResult?.priceExplanation?.priceChanges?.timeSlot?.coefficient : ''}</Box>
                                            <Box ml={"5px"}><b>{quotationResult?.priceExplanation?.priceChanges?.timeSlot?.totalPriceChange ? " | " + quotationResult?.priceExplanation?.priceChanges?.timeSlot?.totalPriceChange.toCurrencyString() : ''}</b></Box>
                                        </>
                                    }
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" className="quotation-setting-section">
                                <Box className="label" textAlign="center">WE / Férié</Box>
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    {(quotationResult?.priceExplanation?.priceChanges?.timeSlot?.slotType === "WeekendSlot" || quotationResult?.priceExplanation?.priceChanges?.timeSlot?.slotType === "HolidaySlot") &&
                                        <>
                                            <Box ml={"5px"}>{quotationResult?.priceExplanation?.priceChanges?.timeSlot?.coefficient ? quotationResult?.priceExplanation?.priceChanges?.timeSlot?.coefficient : ''}</Box>
                                            <Box ml={"5px"}><b>{quotationResult?.priceExplanation?.priceChanges?.timeSlot?.totalPriceChange ? " | " + quotationResult?.priceExplanation?.priceChanges?.timeSlot?.totalPriceChange.toCurrencyString() : ''}</b></Box>
                                        </>
                                    }
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" className="quotation-setting-section">
                                <Box className="label" textAlign="center">Prestation</Box>
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    <>
                                        <Box ml={"5px"}>
                                            <b>
                                                {quotationResult?.priceExplanation?.priceChanges?.transportPriority?.priority ?
                                                    quotationResult?.priceExplanation?.priceChanges?.transportPriority?.priority.toString() === "Low" ? "Modulable" :
                                                        (quotationResult?.priceExplanation?.priceChanges?.transportPriority?.priority.toString() === "Medium" ? "Journée" :
                                                            (quotationResult?.priceExplanation?.priceChanges?.transportPriority?.priority.toString() === "High" ? "Fixe" : "")) : ''}
                                            </b>
                                        </Box>
                                        <Box ml={"5px"}>{quotationResult?.priceExplanation?.priceChanges?.transportPriority?.percent ? (quotationResult?.priceExplanation?.priceChanges?.transportPriority?.percent * 100).toStringFixed("FR-fr") + "%" : ''}</Box>
                                        <Box ml={"5px"}><b>{quotationResult?.priceExplanation?.priceChanges?.transportPriority?.totalPriceChange ? " | " + quotationResult?.priceExplanation?.priceChanges?.transportPriority?.totalPriceChange.toCurrencyString() : ''}</b></Box>
                                    </>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" className="quotation-setting-section">
                                <Box className="label" textAlign="center">Fin tardive</Box>
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    {quotationResult?.priceExplanation?.workingDaySlotBonusCanceled ? (quotationResult?.priceExplanation?.workingDaySlotBonusCanceled ? "Oui" : "Non") : ""}
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" className="quotation-setting-section">
                                <Box className="label" textAlign="center">Payeur calculé</Box>
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    {payerLabel && props.isPayerAutomatic ? payerLabel : ''}
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" className="quotation-setting-section">
                                <Box className="label" textAlign="center">Mode livraison calculé</Box>
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    {props.isPayerAutomatic ? (quotationResult?.transportBillingMode?.toString().toLowerCase() === "departure" ? "Départ" : (quotationResult?.transportBillingMode?.toString().toLowerCase() === "inclusive" ? "Rendu" : '')) : ''}
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" className="quotation-setting-section" alignItems="flex-end">
                                <Box display="flex" flexDirection="column">
                                    <Box className="label">Id chantier zephyr</Box>
                                    <Box display="flex" flexDirection="row" justifyContent="center">
                                        <input value={props.payerZephyrJobCode === null ? "" : props.payerZephyrJobCode} type="text" className="input-text" disabled={true} />
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="column">
                                    <Box className="label"></Box>
                                    <Avatar className="avatar-refresh-zephyr-job-code">
                                        <IconButton size="small" onClick={props.refreshZephyrJobCode} id="btn-refresh-zephyr-job-code"
                                            title="Rafraîchir Id chantier zephyr"
                                            disabled={props.isZephyrJobCodeDisabled}
                                            className={props.isZephyrJobCodeDisabled ? "job-code-zephyr-button disabled" : "job-code-zephyr-button"}>
                                            <FontAwesomeIcon icon={faSync} />
                                        </IconButton>
                                    </Avatar>
                                </Box>
                            </Box>
                        </Box>
                        {quotationResult?.priceUnavailabilityReason &&
                            <Box display="flex" flexDirection="column" width="100%" justifyContent="flex-start" className="quotation-setting-section">
                                {priceUnavailabilityReason}
                            </Box>
                        }
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
