import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult } from '../../../../shared/models/WebAppActionResult';
import { AddInternalVehicleRequestArgs } from './dataContracts/controller/AddInternalVehicleRequestArgs';
import { InternalVehicleCandidateToAdd } from './dataContracts/controller/InternalVehicleCandidateToAdd';
import { InternalVehicleChoicesExportRequestArgs } from './dataContracts/controller/InternalVehicleChoicesExportRequestArgs';
import { UpdateInternalVehicleRequestArgs } from './dataContracts/controller/UpdateInternalVehicleRequestArgs';
import { VehicleChoiceLogisticsUnitRequestArgs } from './dataContracts/controller/VehicleChoiceLogisticsUnitRequestArgs';
import { VehicleChoiceLogisticsUnitsRequestArgs } from './dataContracts/controller/VehicleChoiceLogisticsUnitsRequestArgs';
import { InternalDriverLightModel } from "./dataContracts/queryStack/InternalDriverLightModel";
import { InternalVehicleLightModel } from './dataContracts/queryStack/InternalVehicleLightModel';
import { LogisticsUnitChoiceOfInternalVehiclesLightModel } from './dataContracts/queryStack/LogisticsUnitChoiceOfInternalVehiclesLightModel';
import { VehicleTypeLightModel } from './dataContracts/queryStack/VehicleTypeLightModel';

const controllerUrl = "api/InternalVehiclesReferential/";

export class InternalVehiclesReferentialApiClient {
    public static GetInternalVehicles = (searchText: string, isGlobalSearch: boolean)
        : Promise<AxiosResponse<Array<InternalVehicleLightModel>>> => {
        return axios.get(`${controllerUrl}GetInternalVehicles?searchText=${encodeURIComponent(searchText)}&isGlobalSearch=${isGlobalSearch}`);
    }

    public static GetInternalVehicleChoicesLogisticsUnit = ()
        : Promise<AxiosResponse<Array<LogisticsUnitChoiceOfInternalVehiclesLightModel>>> => {
        return axios.get(`${controllerUrl}GetInternalVehicleChoicesLogisticsUnit`);
    }

    public static GetVehicleTypes = ()
        : Promise<AxiosResponse<Array<VehicleTypeLightModel>>> => {
        return axios.get(`${controllerUrl}GetVehicleTypes`);
    }

    public static GetInternalVehiclesWithVehicleChoicesLogisticsUnit(searchText: string, isGlobalSearch: boolean)
        : Promise<[AxiosResponse<Array<InternalVehicleLightModel>>, AxiosResponse<Array<LogisticsUnitChoiceOfInternalVehiclesLightModel>>]> {
        return Promise.all(
            [
                this.GetInternalVehicles(searchText, isGlobalSearch),
                this.GetInternalVehicleChoicesLogisticsUnit()
            ]);
    }

    public static GetInternalVehiclesWithVehicleChoicesLogisticsUnitAndVehicleTypes(searchText: string, isGlobalSearch: boolean)
        : Promise<[AxiosResponse<Array<InternalVehicleLightModel>>, AxiosResponse<Array<LogisticsUnitChoiceOfInternalVehiclesLightModel>>
            , AxiosResponse<Array<VehicleTypeLightModel>>]> {
        return Promise.all(
            [
                this.GetInternalVehicles(searchText, isGlobalSearch),
                this.GetInternalVehicleChoicesLogisticsUnit(),
                this.GetVehicleTypes()
            ]);
    }

    public static GetInternalDrivers(transporterId: string, restrictToEnabledOnly: boolean):
        Promise<AxiosResponse<Array<InternalDriverLightModel>>> {
        return axios.get(`${controllerUrl}GetInternalDrivers?transporterId=${transporterId}&restrictToEnabledOnly=${restrictToEnabledOnly}`);
    }

    public static AddOrRemoveInternalVehicleChoice(requestArgs: VehicleChoiceLogisticsUnitRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddOrRemoveInternalVehicleChoice',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static AddOrRemoveInternalVehicleChoices(requestArgs: VehicleChoiceLogisticsUnitsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddOrRemoveInternalVehicleChoices',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static ExportInternalVehicleChoices(requestArgs: InternalVehicleChoicesExportRequestArgs)
        : Promise<AxiosResponse<Blob>> {
        const _headers = {
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return axios.post(controllerUrl + 'ExportInternalVehicleChoices', requestArgs, { headers: _headers, responseType: 'blob' });
    }

    public static GetImportUrl = (): string => {
        return controllerUrl + 'ImportFile';
    }

    public static SearchVehicle = (licencePlate: string)
        : Promise<AxiosResponse<InternalVehicleCandidateToAdd>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SearchVehicle?licencePlate=${licencePlate}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static AddInternalVehicle = (requestArgs: AddInternalVehicleRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddInternalVehicle',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateInternalVehicle = (requestArgs: UpdateInternalVehicleRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateInternalVehicle`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateInternalVehicleFromReferential(equipmentId: string):
        Promise<AxiosResponse> {
        return axios.get(`${controllerUrl}UpdateInternalVehicleFromReferential?equipmentId=${equipmentId}`);
    }
}
