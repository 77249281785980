import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { appInsightsReactPlugin } from './appInsightsReactPlugin';
import { SettingsProvider } from './SettingsProvider';
import { UserLightModel } from './shared/User/services/dataContracts/queryStack/UserLightModel';
import { UserApiClient } from './shared/User/services/UserApiClient';
import { ExternalLayout } from './views/ExternalLayout';
import ThirdPartyBeneficiaryView from './views/ThirdParty/ThirdPartyBeneficiary/ThirdPartyBeneficiaryView';
import ThirdPartyBeneficiaryInvoicesView from './views/ThirdParty/ThirdPartyBeneficiaryInvoices/ThirdPartyBeneficiaryInvoicesView';
import ThirdPartyTransporterView from './views/ThirdParty/ThirdPartyTransporter/ThirdPartyTransporterView';
import { ThirdPartyTransporterPrintView } from './views/ThirdParty/ThirdPartyTransporterPrint/ThirdPartyTransporterPrintView';
import ThirdPartyTransporterPublishedCostsView from './views/ThirdParty/ThirdPartyTransporterPublishedCosts/ThirdPartyTransporterPublishedCostsView';

interface AppState {
    role: string,
    userName: string,
    loadingLayout: boolean,
    firstName: string,
    lastName: string,
    userHash: string
}

class ExternalAppHOC extends React.Component<{}, AppState> {
    _isMounted: boolean;
    static displayName: string = ExternalAppHOC.name;

    constructor(props: any) {
        super(props);
        this.state = {
            role: '',
            userName: '',
            loadingLayout: true,
            firstName: '',
            lastName: '',
            userHash: ''
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getUserRole();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getMatomoInstance = (userName: string, userHash: string) => {
        if (SettingsProvider.IsReady() && userName) {
            const setting = SettingsProvider.Get();
            if (setting.matomo && setting.matomo.urlBase && setting.matomo.siteId != 0) {
                return createInstance({
                    urlBase: setting.matomo.urlBase,
                    siteId: setting.matomo.siteId,
                    userId: userHash
                });
            }
            return null;
        }
        return null;
    }

    getUserRole() {
        const url_string: string = window.location.href;
        const url: URL = new URL(url_string);
        const param: string = url.searchParams.get("K");
        UserApiClient.FindByToken(param)
            .then(res => {
                if (this._isMounted) {
                    const json: UserLightModel = res.data;
                    json.userName == null ? this.setState({ role: '', loadingLayout: false, firstName: '', lastName: '', userName: '', userHash: '' }) : this.setState({ role: json.role, userName: json.userName, firstName: json.firstName ? json.firstName : '', lastName: json.lastName ? json.lastName : '', loadingLayout: false, userHash: json.userHash });
                }
            })
            .catch(err => {
                this.setState({ role: '', loadingLayout: false, firstName: '', lastName: '' });
            });
    }

    render() {
        const { userName, userHash } = this.state;

        let MatomoProviderTest = MatomoProvider as any;

        return (
            <MatomoProviderTest value={this.getMatomoInstance(userName, userHash)}>
                <ToastContainer className="generic-error-toast" />
                {
                    //rendu vide tant qu'on n'a pas encore le retour sur le token de l'user
                    userName !== '' &&
                        window.location.pathname.endsWith('-Print') ?
                        <Routes>
                            <Route path='External-Transporter-Print' Component={() => <ThirdPartyTransporterPrintView />} />
                        </Routes>
                        :
                        <ExternalLayout role={this.state.role} loadingLayout={this.state.loadingLayout} firstName={this.state.firstName} lastName={this.state.lastName} userName={userName}>
                            <Routes>
                                <Route path='External-Transporter' Component={() => <ThirdPartyTransporterView userName={userName} role={this.state.role} isForInternalTransporters={false} />} />
                                <Route path='External-JobForeman' Component={() => <ThirdPartyBeneficiaryView role={this.state.role} />} />
                                <Route path='External-TransporterPublishedCosts' Component={() => <ThirdPartyTransporterPublishedCostsView role={this.state.role} isForInternalTransporters={false} />} />
                                <Route path='External-BeneficiaryInvoicesDetails' Component={() => <ThirdPartyBeneficiaryInvoicesView role={this.state.role} />} />
                            </Routes>
                        </ExternalLayout>
                }
            </MatomoProviderTest>
        );
    }
}

const ExternalApp = withAITracking(appInsightsReactPlugin, ExternalAppHOC);
export default ExternalApp;
