import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult } from '../../models/WebAppActionResult';
import { TransportRequestLastObservedRootChangeIndicatorRequestArgs } from './dataContracts/controller/TransportRequestLastObservedRootChangeIndicatorRequestArgs';
import { TransportRequestChangeSummaryDateLightModel } from './dataContracts/queryStack/TransportRequestChangeSummaryDateLightModel';
import { TransportRequestChangeTrackingLightModel } from './dataContracts/queryStack/TransportRequestChangeTrackingLightModel';

const controllerUrl = "api/TransportRequestChanges/";

export class TransportRequestChangesApiClient {
    static GetTransportRequestChanges = (transportRequestId, fromDate)
        : Promise<AxiosResponse<TransportRequestChangeTrackingLightModel>> => {
        return axios.get(`${controllerUrl}getTransportRequestchanges?transportRequestId=${encodeURIComponent(transportRequestId)}&fromDateTimeOffset=${encodeURIComponent(fromDate.toJSON())}`);
    }

    static GetTransportRequestChangesAll = (fromDate, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<Array<TransportRequestChangeSummaryDateLightModel>>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        return axios.post(`${controllerUrl}GetTransportRequestChangesAll?fromDateTimeOffset=${encodeURIComponent(fromDate.toJSON())}`, logisticsUnits, { headers: _headers });
    }

    static GetNotificationsCount = (fromDate, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<number>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        return axios.post(`${controllerUrl}getnotificationscount?fromDateTimeOffset=${encodeURIComponent(fromDate.toJSON())}`, logisticsUnits, { headers: _headers });
    }

    static UpdateLastObservedIndicator = (transportRequestId: string, lastObservedRootChangeId: number)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const _headers = {
            'Content-Type': 'application/json'
        }
        const _bodyPost: TransportRequestLastObservedRootChangeIndicatorRequestArgs = {
            transportRequestId: transportRequestId,
            lastObservedRootChangeId: lastObservedRootChangeId
        }
        return axios.post(controllerUrl + "updateLastObservedIndicator", _bodyPost, { headers: _headers });
    }
}