import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult, WebAppActionResultEx } from 'src/shared/models/WebAppActionResult';
import { AddDriverRequestArgs } from './dataContracts/controller/AddDriverRequestArgs';
import { AddTemporaryDriverRequestArgs } from './dataContracts/controller/AddTemporaryDriverRequestArgs';
import { ChangeInternalDriverEnabledStatusRequestArgs } from './dataContracts/controller/ChangeInternalDriverEnabledStatusRequestArgs';
import { DriverCandidateToAdd } from './dataContracts/controller/DriverCandidateToAdd';
import { UpdateDriverRequestArgs } from './dataContracts/controller/UpdateDriverRequestArgs';
import { UpdateTemporaryDriverRequestArgs } from './dataContracts/controller/UpdateTemporaryDriverRequestArgs';
import { InternalDriverLightModel } from './dataContracts/queryStack/InternalDriverLightModel';
import { InternalTransporterLightModel } from './dataContracts/queryStack/InternalTransporterLightModel';

const controllerUrl = 'api/InternalDriversReferential/';

export class InternalDriversReferentialApiClient {
    public static GetInternalDrivers = (searchText: string, isForTemporaryDrivers: boolean)
        : Promise<AxiosResponse<Array<InternalDriverLightModel>>> => {
        return axios.get(`${controllerUrl}GetInternalDrivers?searchText=${encodeURIComponent(searchText)}&isForTemporaryDrivers=${isForTemporaryDrivers}`);
    }

    public static SearchDriverByMgrh = (mgrh: string)
        : Promise<AxiosResponse<DriverCandidateToAdd>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SearchDriverByMgrh?mgrh=${mgrh}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static SearchDriverByLastName = (lastName: string)
        : Promise<AxiosResponse<DriverCandidateToAdd[]>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SearchDriverByLastName?lastName=${lastName}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static DriverExists = (mgrh: string)
        : Promise<AxiosResponse<boolean>> => {
        return axios.get(`${controllerUrl}DriverExists?mgrh=${mgrh}`);
    }

    public static AddDriver = (requestArgs: AddDriverRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddDriver',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateDriver = (requestArgs: UpdateDriverRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateDriver`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static GetInternalTransporters = ()
        : Promise<AxiosResponse<Array<InternalTransporterLightModel>>> => {
        return axios.get(`${controllerUrl}GetInternalTransporters`);
    }

    public static AddTemporaryDriver = (requestArgs: AddTemporaryDriverRequestArgs)
        : Promise<AxiosResponse<WebAppActionResultEx<string>>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddTemporaryDriver',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateTemporaryDriver = (requestArgs: UpdateTemporaryDriverRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateTemporaryDriver`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static TemporaryDriverExists = (lastName: string, firstName: string, phoneNumber: string)
        : Promise<AxiosResponse<boolean>> => {
        return axios.get(`${controllerUrl}TemporaryDriverExists?lastName=${lastName}&firstName=${firstName}&phoneNumber=${phoneNumber}`);
    }

    public static ChangeInternalDriverEnabledStatus = (requestArgs: ChangeInternalDriverEnabledStatusRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}ChangeInternalDriverEnabledStatus`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateInternalDriverFromReferential(employeeId: string):
        Promise<AxiosResponse> {
        return axios.get(`${controllerUrl}UpdateInternalDriverFromReferential?employeeId=${employeeId}`);
    }
}
