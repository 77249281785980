import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import { TransportFlowLightModelExtended } from '../models/TransportFlowLightModelExtended';

interface StatusCellProps extends GridCellProps {
    selectRowHandler: (dataItem: TransportFlowLightModelExtended) => void
}

export const CustomFlowStatusCellComponent = (props: StatusCellProps): JSX.Element => {

    const labelValue: string = props.dataItem.flowStatus;
    const fieldValue: string = props.dataItem.statusField;
    let defaultRendering: JSX.Element = null;

    defaultRendering = (
        <td>
            <div onClick={() => props.selectRowHandler(props.dataItem)} className={fieldValue + '-flow'}>{labelValue}</div>
        </td>
    );

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}