import { Upload, UploadHttpHeaders, UploadOnStatusChangeEvent, UploadProps } from '@progress/kendo-react-upload';
import React from 'react';
import { MsalConfig } from '../../../MsalConfig';
import { SessionStorage } from '../../../utils/Storage';

export const UploadComponent = (props: UploadProps) => {
    const baseOnStatusChange = props.onStatusChange;

    const onStatusChange = (event: UploadOnStatusChangeEvent): void => {
        const res = event.response;
        if (res?.response?.status == 401) {
            const msalInstance = MsalConfig.GetPublicClientApplication();
            msalInstance.logoutRedirect();
        }
        else if(baseOnStatusChange){
            baseOnStatusChange(event);
        }
    }

    const getHttpHeaders = (): UploadHttpHeaders => {
        const headers: UploadHttpHeaders = { 'Authorization': `Bearer ${SessionStorage.GetLastKnownApiAccessToken()}` };
        return headers;
    }
    
    return (
        <Upload
            {...props}
            saveHeaders={getHttpHeaders()}
            onStatusChange={onStatusChange}
        />
    );
}