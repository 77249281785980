import { GridCellProps } from '@progress/kendo-react-grid';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';

interface CustomCheckboxCellProperties extends GridCellProps{
    dataItem: PlanningVehicleLightModelExtended,
    onSelectionChange?: (checked: boolean, dataItem: PlanningVehicleLightModelExtended) => void
}

export const CustomCheckboxCell = (props: CustomCheckboxCellProperties) => {

    const checkboxRef: MutableRefObject<HTMLInputElement> = useRef(null);

    useEffect(() => {
        checkboxRef.current.indeterminate = props.dataItem.isIndeterminate;
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onSelectionChange(event.target.checked, props.dataItem);
    };

    const { dataItem } = props;

    return (
        <td className="cell-checkbox">
            <input type="checkbox" ref={checkboxRef} checked={dataItem.selected} 
                disabled={!dataItem.isEnabled} onChange={(e) => handleChange(e)} />
        </td>
    );
}
