import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import Utilities from '../../../../../utils/Utilities';
import { SecondEmailModel } from '../../models/SecondEmailModel';

interface ConfirmAddTransporterStepComponentProperties {
    confirmAddTransporter: (transporterMainEmail: string, secondsEmails: string, transporterPhoneNumber: string) => void,
    handlePreviousStep: () => void
}

export const ConfirmAddTransporterStepComponent = (props: ConfirmAddTransporterStepComponentProperties): JSX.Element => {

    const [transporterMainEmail, setTransporterMainEmail] = useState<string>('');
    const [transporterPhoneNumber, setTransporterPhoneNumber] = useState<string>('');
    const [transporterSecondsEmails, setTransporterSecondsEmails] = useState<SecondEmailModel[]>([
        {
            index: 0,
            emailAddress: ''
        }
    ]);
    const [isMainMailValid, setIsMainMailValid] = useState<boolean>(false);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(false);
    const [isPhoneNumberTouched, setIsPhoneNumberTouched] = useState<boolean>(false);
    const [isTransporterMainEmailTouched, setIsTransporterMainEmailTouched] = useState<boolean>(false);

    const handleChangeSecondEmail = (value: string, index: number): void => {
        const transporterSecondEmailsArray = [...transporterSecondsEmails];
        var index = transporterSecondEmailsArray.findIndex(x => x.index === index);
        transporterSecondEmailsArray[index].emailAddress = value;
        setTransporterSecondsEmails(transporterSecondEmailsArray);
    }

    const addSecondEmailLine = (): void => {
        const transporterSecondEmailsArray = [...transporterSecondsEmails];
        const lastIndex = transporterSecondEmailsArray.reduce((max, b) => Math.max(max, b.index), transporterSecondEmailsArray[0].index);
        transporterSecondEmailsArray.push({
            index: lastIndex + 1,
            emailAddress: ''
        });
        setTransporterSecondsEmails(transporterSecondEmailsArray);
    }

    const removeSecondEmailLine = (index: number): void => {
        const transporterSecondEmailsArray = [...transporterSecondsEmails];
        var index = transporterSecondEmailsArray.findIndex(x => x.index === index);
        transporterSecondEmailsArray.splice(index, 1);
        verifyOthersMail(transporterSecondEmailsArray, index);
        setTransporterSecondsEmails(transporterSecondEmailsArray);
    }

    const confirmAddTransporter = (): void => {
        let secondsEmails: string = transporterMainEmail.trim() + ',';

        transporterSecondsEmails.forEach((element: SecondEmailModel) => {
            element.emailAddress.trim() !== "" ? secondsEmails = secondsEmails.concat(element.emailAddress.trim() + ',') : secondsEmails += '';
        });

        secondsEmails = secondsEmails.slice(0, -1);

        props.confirmAddTransporter(transporterMainEmail.trim(), secondsEmails, transporterPhoneNumber.trim());
    }

    const handleBlurTransporterMainMail = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const value = e.target.value.trim();
        const isValidEmail = Utilities.validateEmailAddress(value);
        const alreadyExists = transporterSecondsEmails.some(e => e.emailAddress.trim() === value);
        setIsMainMailValid(isValidEmail && !alreadyExists);
        verifySecondsMail(value);
    }

    const handleChangeTransporterMainMail = (email: string) => {
        setTransporterMainEmail(email);
        setIsTransporterMainEmailTouched(true);
    }

    const handleBlurTransporterSecondMail = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, index: number): void => {
        const transporterSecondEmailsArray = [...transporterSecondsEmails];
        const elementIndex = transporterSecondEmailsArray.findIndex(x => x.index === index);

        if (e.target.value.trim() === '') {
            transporterSecondEmailsArray[elementIndex].isValidEmail = null;
            transporterSecondEmailsArray[elementIndex].alreadyExists = null;
        } else {
            const value = e.target.value.trim();
            const alreadyExists = transporterSecondEmailsArray.some(e => e.index !== index && e.emailAddress.trim() === value) || transporterMainEmail.trim() === value;
            const isValidEmail = Utilities.validateEmailAddress(value);
            transporterSecondEmailsArray[elementIndex].isValidEmail = isValidEmail && !alreadyExists;
            transporterSecondEmailsArray[elementIndex].alreadyExists = alreadyExists ? alreadyExists : null;
        }
        verifyOthersMail(transporterSecondEmailsArray, index);

        setTransporterSecondsEmails(transporterSecondEmailsArray);
    }

    const verifySecondsMail = (mainMail: string): void => {
        const transporterSecondEmailsArray = [...transporterSecondsEmails];
        transporterSecondEmailsArray.filter(x => x.alreadyExists === true).forEach(element => {
            const alreadyExists = transporterSecondEmailsArray.some(e => e.index !== element.index && e.emailAddress.trim() === element.emailAddress.trim()) || mainMail === element.emailAddress.trim();
            const isValidEmail = Utilities.validateEmailAddress(element.emailAddress.trim());
            element.isValidEmail = isValidEmail && !alreadyExists;
            element.alreadyExists = alreadyExists ? alreadyExists : null;
        });

        setTransporterSecondsEmails(transporterSecondEmailsArray);
    }

    const verifyOthersMail = (transporterSecondEmailsArray: SecondEmailModel[], index: number): void => {
        transporterSecondEmailsArray.filter(x => x.alreadyExists === true && x.index !== index).forEach(element => {
            const alreadyExists = transporterSecondEmailsArray.some(e => e.index !== element.index && e.emailAddress.trim() === element.emailAddress.trim()) || transporterMainEmail === element.emailAddress.trim();
            const isValidEmail = Utilities.validateEmailAddress(element.emailAddress.trim());
            element.isValidEmail = isValidEmail && !alreadyExists;
            element.alreadyExists = alreadyExists ? alreadyExists : null;
        });

        if (!isMainMailValid) {
            const exists = transporterSecondEmailsArray.some(e => e.emailAddress.trim() === transporterMainEmail.trim());
            const isValid = Utilities.validateEmailAddress(transporterMainEmail.trim());
            setIsMainMailValid(isValid && !exists);
        }
    }

    const handleBlurPhoneNumber = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const isPhoneNumberValid = Utilities.isValidPhoneNumber(e.target.value);
        setIsPhoneNumberValid(isPhoneNumberValid);
    }

    const handleChangePhoneNumber = (phoneNumber: string): void => {
        setTransporterPhoneNumber(phoneNumber);
        setIsPhoneNumberTouched(true);
    }
    
    const isSecondMailsValid: boolean = transporterSecondsEmails.every((item) => item.isValidEmail !== false);

    return (
        <Box className="confirm-add-transporter">
            <Box display="flex" flexDirection="column" className="input-texts-content">
                <Box display="flex" flexDirection="row" mb={"10px"}>
                    <TextField error={isTransporterMainEmailTouched && !isMainMailValid} label="Email principal" value={transporterMainEmail} className="text-field"
                        helperText={isTransporterMainEmailTouched && !isMainMailValid ? "L'adresse mail est invalide ou vide." : ''} variant="outlined"
                        onChange={(e) => handleChangeTransporterMainMail(e.target.value)} onBlur={(e) => handleBlurTransporterMainMail(e)} />
                </Box>
                {transporterSecondsEmails.map((element: SecondEmailModel, index: number) => {
                    return (
                        <Box key={`second-email-${index}`} display="flex" flexDirection="row" mb={"10px"}>
                            <Box>
                            <TextField error={element.emailAddress?.trim() && !element.isValidEmail} label="Email secondaire" value={element.emailAddress} className="text-field"
                                helperText={element.emailAddress?.trim() && !element.isValidEmail? "L'adresse mail est invalide ou vide." : ''} variant="outlined" 
                                onChange={(e) => handleChangeSecondEmail(e.target.value, element.index)} onBlur={(e) => handleBlurTransporterSecondMail(e, element.index)} />
                            </Box>
                            <Box style={{ cursor: 'pointer' }} alignSelf="center" pl={1} pr={1} onClick={addSecondEmailLine}>
                                <FontAwesomeIcon size="lg" icon={faPlusCircle} />
                            </Box>
                            {(transporterSecondsEmails.length > 1) &&
                                <Box style={{ cursor: 'pointer' }} alignSelf="center" onClick={() => removeSecondEmailLine(element.index)}>
                                    <FontAwesomeIcon size="lg" icon={faTimes} />
                                </Box>
                            }
                        </Box>
                    );
                })}
                <Box display="flex" flexDirection="row" mb={"10px"}>
                    <TextField error={isPhoneNumberTouched && (!isPhoneNumberValid || !transporterPhoneNumber)} label="Téléphone" value={transporterPhoneNumber ? Utilities.formatPhoneNumber(transporterPhoneNumber) : ''} className="text-field"
                        helperText={isPhoneNumberTouched && (!isPhoneNumberValid || !transporterPhoneNumber) ? "Le numéro de téléphone est invalide ou vide." : ''} variant="outlined"
                        onChange={(e) => handleChangePhoneNumber(e.target.value)} onBlur={(e) => handleBlurPhoneNumber(e)} />
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                <Button variant="contained" title="Retour" className="previous-button" onClick={props.handlePreviousStep}>
                    Retour
                </Button>
                <Button variant="contained" color="primary" title="Confirmer" disabled={!isMainMailValid || !isPhoneNumberValid || !isSecondMailsValid} onClick={confirmAddTransporter}>
                    Confirmer
                </Button>
            </Box>
        </Box>
    );
}