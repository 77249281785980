import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, TextField, Tooltip } from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import Utilities from 'src/utils/Utilities';
import { CustomerContactRequestArgs } from '../services/dataContracts/controller/CustomerContactRequestArgs';
import { UpdateCustomerRequestArgs } from '../services/dataContracts/controller/UpdateCustomerRequestArgs';
import { ContactCustomerLightModel } from '../services/dataContracts/queryStack/ContactCustomerLightModel';
import { CustomerLightModel } from '../services/dataContracts/queryStack/CustomerLightModel';

interface EditCustomerDialogComponentProps {
    customerSelected: CustomerLightModel,
    customerContactsSelectedArray: Array<ContactCustomerLightModel>,
    handleEditCustomerClick: (requestArgs: UpdateCustomerRequestArgs) => void
}

interface ContactCustomerValid extends ContactCustomerLightModel {
    isPhoneNumberValid?: boolean
}

export const EditCustomerDialogComponent = (props: EditCustomerDialogComponentProps): JSX.Element => {
    const [customer, setCustomer] = useState<CustomerLightModel>({ ...props.customerSelected });
    const [customerContacts, setCustomerContacts] = useState<Array<ContactCustomerValid>>([]);
    const [isCustomerChanged, setIsCustomerChanged] = useState<boolean>(false);
    const [isContactsChanged, setIsContactsChanged] = useState<boolean>(false);

    useEffect(() => {
        const newCustomerContacts: Array<ContactCustomerValid> = [];

        props.customerContactsSelectedArray.forEach(c => {
            newCustomerContacts.push({
                ...c,
                isPhoneNumberValid: !c.phoneNumber || Utilities.isValidPhoneNumber(c.phoneNumber)
            });
        });

        setCustomerContacts(newCustomerContacts);
    }, []);

    const handleChangeAddressField = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, nameField: string): void => {
        const newCustomer = { ...customer };
        newCustomer[nameField] = event.target.value;

        setCustomer(newCustomer);
        setIsCustomerChanged(hasCustomerChanged(newCustomer));
    }

    const handleChangeContact = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, nameField: string, index: number): void => {
        const customerContactsClone: ContactCustomerValid[] = cloneDeep(customerContacts);
        customerContactsClone[index][nameField] = event.target.value;

        setCustomerContacts(customerContactsClone);

        setIsContactsChanged(hasContactsChanged(customerContactsClone));
    }

    const handleChangePhoneNumber = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number): void => {
        const value = event.target.value;
        let isPhoneNumberValid = false;

        if (value && !Utilities.isValidPhoneNumber(value))
            isPhoneNumberValid = false;
        else
            isPhoneNumberValid = true;

        const customerContactsClone: ContactCustomerValid[] = cloneDeep(customerContacts);

        customerContactsClone[index].phoneNumber = value;
        customerContactsClone[index].isPhoneNumberValid = isPhoneNumberValid;

        setCustomerContacts(customerContactsClone);

        setIsContactsChanged(hasContactsChanged(customerContactsClone));
    }

    const removeContact = (index: number): void => {
        const customerContactsClone: ContactCustomerLightModel[] = cloneDeep(customerContacts);

        customerContactsClone.splice(index, 1);

        setCustomerContacts(customerContactsClone);

        setIsContactsChanged(hasContactsChanged(customerContactsClone));
    }

    const addContact = (): void => {
        const customerContactsClone: ContactCustomerValid[] = cloneDeep(customerContacts);

        customerContactsClone.push(
            {
                firstName: '',
                lastName: '',
                phoneNumber: '',
                customerMdmId: customer.customerMdmId,
                isPhoneNumberValid: true,
                contactId: null
            }
        );

        setCustomerContacts(customerContactsClone);
    }

    const updateContact = (): void => {
        const contacts: Array<CustomerContactRequestArgs> = [];
        customerContacts.forEach(c => {
            contacts.push({
                contactId: c.contactId,
                firstName: c.firstName,
                lastName: c.lastName,
                phoneNumber: c.phoneNumber
            });
        });


        const requestArgs: UpdateCustomerRequestArgs = {
            customerMdmId: customer.customerMdmId,
            addressLine1: customer.addressLine1,
            addressLine2: customer.addressLine2,
            addressCity: customer.addressCity,
            addressZipCode: customer.addressZipCode,
            customerContacts: contacts
        }

        props.handleEditCustomerClick(requestArgs);
    }

    const hasCustomerChanged = (newCustomer: CustomerLightModel): boolean => {
        return (
            props.customerSelected.addressLine1 != newCustomer.addressLine1 ||
            props.customerSelected.addressLine2 != newCustomer.addressLine2 ||
            props.customerSelected.addressCity != newCustomer.addressCity ||
            props.customerSelected.addressZipCode != newCustomer.addressZipCode
        );
    }

    const hasContactsChanged = (newContacts: Array<ContactCustomerLightModel>): boolean => {
        if (props.customerContactsSelectedArray.length != newContacts.length)
            return true;

        for (let i = 0; i < props.customerContactsSelectedArray.length; i++) {
            const currentContact = props.customerContactsSelectedArray[i];
            if (currentContact.firstName != newContacts[i].firstName ||
                currentContact.lastName != newContacts[i].lastName ||
                currentContact.phoneNumber != newContacts[i].phoneNumber)
                return true;
        }

        return false;
    }


    return (
        <Box display="flex" flexDirection="column" className="edit-content">
            <Box className="customer-title">
                <span className="customer-name">{props.customerSelected.name}</span>&nbsp;-&nbsp;{props.customerSelected.customerMdmId}
            </Box>
            <Box pl={"6px"} pt={2}>
                <TextField error={!customer.addressLine1 && !customer.addressLine2} label="Adresse 1" value={customer.addressLine1 ?? ""} className="text-field"
                    helperText={!customer.addressLine1 && !customer.addressLine2 ? "Il faut obligatoirement mettre une adresse." : ''} variant="outlined" onChange={(event) => handleChangeAddressField(event, "addressLine1")} />
            </Box>
            <Box pl={"6px"} pt={1}>
                <TextField error={!customer.addressLine1 && !customer.addressLine2} label="Adresse 2" value={customer.addressLine2 ?? ""} className="text-field"
                    helperText={!customer.addressLine1 && !customer.addressLine2 ? "Il faut obligatoirement mettre une adresse." : ''} variant="outlined" onChange={(event) => handleChangeAddressField(event, "addressLine2")} />
            </Box>
            <Box pl={"6px"} pt={1}>
                <TextField error={!customer.addressZipCode || !Utilities.validateZipCodeAddress(customer.addressZipCode)} label="Code postal" value={customer.addressZipCode} className="text-field"
                    helperText={(!customer.addressZipCode || !Utilities.validateZipCodeAddress(customer.addressZipCode)) ? "Le code postal est invalide." : ''} variant="outlined" onChange={(event) => handleChangeAddressField(event, "addressZipCode")} />
            </Box>
            <Box pl={"6px"} pt={1}>
                <TextField error={!customer.addressCity} label="Ville" value={customer.addressCity} className="text-field"
                    helperText={!customer.addressCity ? "La ville est obligatoire." : ''} variant="outlined" onChange={(event) => handleChangeAddressField(event, "addressCity")} />
            </Box>
            <Box pl={"6px"} pt={1} className="contacts-title">
                Listes des contacts du client externe:
            </Box>
            {customerContacts.map((currentContact, index) => {
                return (<Box key={`edit-email-address-${index}`} display="flex" flexDirection="row" pl={"6px"} pt={1}>
                    <Box pr={1} width="30%">
                        <TextField error={!currentContact.lastName && currentContact.lastName?.trim() === '' && !currentContact.firstName && currentContact.firstName?.trim() === ''} label="Nom" value={currentContact.lastName ?? ""} className="text-field"
                            helperText={!currentContact.lastName && currentContact.lastName?.trim() === '' && !currentContact.firstName && currentContact.firstName?.trim() === '' ? "Il faut obligatoirement mettre un nom ou un prénom." : ''} variant="outlined" onChange={(event) => handleChangeContact(event, "lastName", index)} />
                    </Box>
                    <Box pr={1} width="30%">
                        <TextField error={!currentContact.lastName && currentContact.lastName?.trim() === '' && !currentContact.firstName && currentContact.firstName?.trim() === ''} label="Prénom" value={currentContact.firstName ?? ""} className="text-field"
                            helperText={!currentContact.lastName && currentContact.lastName?.trim() === '' && !currentContact.firstName && currentContact.firstName?.trim() === '' ? "Il faut obligatoirement mettre un nom ou un prénom." : ''} variant="outlined" onChange={(event) => handleChangeContact(event, "firstName", index)} />
                    </Box>
                    <Box pr={1} width="30%">
                        <TextField error={!currentContact.isPhoneNumberValid} label="Téléphone portable" value={currentContact.phoneNumber ?? ""} className="phone-number"
                            helperText={!currentContact.isPhoneNumberValid ? "Le numéro n'est pas valide." : ''} variant="outlined" onChange={(event) => handleChangePhoneNumber(event, index)} />
                    </Box>
                    <Box display="flex" width="10%" flexDirection="row" justifyContent="flex-end" alignItems="center">
                        <Tooltip title="Ajout un contact" placement="top">
                            <Box style={{ cursor: 'pointer' }} pl={1} pr={1} onClick={addContact}>
                                <FontAwesomeIcon size="lg" icon={faPlusCircle} />
                            </Box>
                        </Tooltip>
                        <Tooltip title="Supprimer un contact" placement="top">
                            <Box style={{ cursor: 'pointer' }} onClick={(e) => removeContact(index)}>
                                <FontAwesomeIcon size="lg" icon={faTimes} />
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>)
            })}
            {customerContacts.length == 0 && <Box style={{ cursor: 'pointer' }} pl={"6px"} pt={0.5} onClick={addContact}>
                <FontAwesomeIcon size="lg" icon={faPlusCircle} title="Ajouter un nouveau contact" />
            </Box>}
            <Box display="flex" flexDirection="row" justifyContent="flex-end" pt={2} pl={2}>
                <Button variant="contained" color="primary" title="Modifier l'utilisateur" onClick={updateContact}
                    disabled={(!isCustomerChanged && !isContactsChanged)
                        || (!customer.addressLine1 && !customer.addressLine2) || !customer.addressZipCode
                        || !Utilities.validateZipCodeAddress(customer.addressZipCode) || !customer.addressCity
                        || (customerContacts.some(c => !c.isPhoneNumberValid || (c.firstName?.trim() === '' && c.lastName?.trim() === '')))}>
                    Modifier
                </Button>
            </Box>
        </Box>
    );
}
