import { Box, Button } from '@mui/material';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import React, { MutableRefObject } from 'react';
import { DateConfigModel } from '../models/DateConfigModel';
import { LogisticsUnitsModel } from '../models/LogisticsUnitsModel';
import { SelectInputItemModel } from '../models/SelectInputItemModel';
import { ServiceKindModel } from '../models/ServiceKindModel';
import { TransportPriorityEnum } from '../models/TransportPriorityEnum';
import { TransportRequestModel } from '../models/TransportRequestModel';
import { updateMode } from '../TransportFlowForm';
import { DatePickerInput, SelectInput } from './CommonComponents';

interface FlowDatesAndPrestationContentProps {
    logisticsUnit: LogisticsUnitsModel,
    serviceKind: ServiceKindModel,
    mode: string,
    dateConfig: DateConfigModel,
    priority: TransportPriorityEnum,
    sourceApplicationIsPlaneo: boolean,
    initiallyRequestedTransportationLineId: number,
    transportRequest: TransportRequestModel,
    isJobSiteVehicleServiceKind: boolean,
    startDateValid: boolean,
    endDateValid: boolean,
    startDateRef: MutableRefObject<DatePicker>,
    endDateRef: MutableRefObject<DatePicker>,
    zephyrOrderNumber: string,
    handleLogisticsUnitChange: (e: SelectInputItemModel) => void,
    handleServiceKindChange: (e: SelectInputItemModel) => void,
    handleCLickPriority: (priority: TransportPriorityEnum) => void,
    handleStartDateChange: (event: DatePickerChangeEvent) => void,
    handleEndDateChange: (event: DatePickerChangeEvent) => void,
    handleTransportRequestChange: (e: SelectInputItemModel) => void,
    setZephyrOrderNumber: (value: string) => void
}

export const FlowDatesAndPrestationContent = (props: FlowDatesAndPrestationContentProps): JSX.Element => {

    const { logisticsUnit, zephyrOrderNumber, serviceKind, dateConfig, priority, isJobSiteVehicleServiceKind, startDateValid, endDateValid
        , startDateRef, endDateRef, transportRequest, sourceApplicationIsPlaneo, initiallyRequestedTransportationLineId, mode } = props;

    return (
        <Box display="flex" flexDirection="column" className="section">
            <Box display="flex" flexDirection="row" width="100%" pb={'15px'} justifyContent="flex-start">
                <SelectInput title="Nom de la zone logistique"
                    titleClassName={!logisticsUnit.selectedLogisticsUnit ? "required-label" : "label"}
                    options={logisticsUnit.userLogisticsUnitList}
                    value={logisticsUnit.selectedLogisticsUnit}
                    handleChange={(e) => props.handleLogisticsUnitChange(e)}
                    required={!logisticsUnit.selectedLogisticsUnit}
                    isDisabled={mode === updateMode}
                />
                <SelectInput title="Prestation"
                    titleClassName={!serviceKind.selectedServiceKind ? "required-label" : "label"}
                    options={serviceKind.serviceKindList}
                    value={serviceKind.selectedServiceKind}
                    handleChange={(e) => props.handleServiceKindChange(e)}
                    required={!serviceKind.selectedServiceKind}
                    isDisabled={mode === updateMode}
                />
                <Box display="flex" flexDirection="column" mr={"20px"}>
                    <Box className="label">N° commande</Box>
                    <Box display="flex" flexDirection="row">
                        <input type="text" className="input-text"
                            value={zephyrOrderNumber ?? ""}
                            onChange={(e) => { props.setZephyrOrderNumber(e.target.value) }}
                            disabled={(mode === updateMode && sourceApplicationIsPlaneo && initiallyRequestedTransportationLineId != null)}
                        />
                    </Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start" flexWrap="wrap">
                <Box display="flex" flexDirection="column" mr={"20px"}>
                    <Box className={priority === null ? "required-label" : "label"}>Criticité</Box>
                    <Box display="flex" flexDirection="row">
                        <Button className={priority === TransportPriorityEnum.high ? "btn-selected" : "btn-enabled"}
                            onClick={() => { props.handleCLickPriority(TransportPriorityEnum.high) }}
                            disabled={(!dateConfig.equalDates && dateConfig.equalDates !== null) || mode === updateMode}>Fixe</Button>
                        <Button className={priority === TransportPriorityEnum.medium ? "btn-selected" : "btn-enabled"}
                            onClick={() => { props.handleCLickPriority(TransportPriorityEnum.medium) }}
                            disabled={(!dateConfig.equalDates && dateConfig.equalDates !== null) || mode === updateMode || isJobSiteVehicleServiceKind}>Journée</Button>
                        <Button className={priority === TransportPriorityEnum.low ? "btn-selected" : "btn-enabled"}
                            onClick={() => { props.handleCLickPriority(TransportPriorityEnum.low) }}
                            disabled={(dateConfig.equalDates && dateConfig.equalDates !== null) || mode === updateMode || isJobSiteVehicleServiceKind}>Modulable</Button>
                    </Box>
                </Box>
                <DatePickerInput title="Date de début"
                    titleClassName={!startDateValid ? "required-label" : "label"}
                    value={dateConfig.startDateValue}
                    handleChange={props.handleStartDateChange}
                    disabled={mode === updateMode}
                    refDate={startDateRef}
                />
                <DatePickerInput title="Date de fin"
                    titleClassName={dateConfig.endDateValue === null ? "required-label" : "label"}
                    value={dateConfig.endDateValue}
                    handleChange={props.handleEndDateChange}
                    disabled={(priority === TransportPriorityEnum.high || priority === TransportPriorityEnum.medium || dateConfig.startDateValue === null || (mode === "update" && priority !== TransportPriorityEnum.low))}
                    min={dateConfig.minEndDateValue}
                    refDate={endDateRef}
                />
                <SelectInput title="Besoin"
                    titleClassName="label"
                    options={transportRequest.transportRequestList}
                    value={transportRequest.selectedTransportRequest}
                    handleChange={(e) => props.handleTransportRequestChange(e)}
                    isDisabled={mode === updateMode || dateConfig.startDateValue === null || dateConfig.endDateValue === null || !startDateValid || !endDateValid}
                />
            </Box>
        </Box>
    );
}
