import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { cloneDeep, groupBy, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import BusinessErrors from 'src/utils/BusinessErrors';
import { DieselIndexTemplateLightModel } from '../services/dataContracts/queryStack/DieselIndexTemplateLightModel';
import { DieselIndexesReferentialApiClient } from '../services/DieselIndexesReferentialApiClient';
import { ForeignDieselIndexTemplateComponent } from './ForeignDieselIndexTemplateComponent';

interface AddForeignDieselIndexTemplatesProperties {
    selectedLogisticsUnitId: string,
    startDate: Date,
    endDate: Date,
    HandleCloseDialog: () => void,
    HandleResult: (errors: Array<string>) => void
}

export const AddForeignDieselIndexTemplatesComponent = (props: AddForeignDieselIndexTemplatesProperties) => {
    const [dieselIndexTemplates, setDieselIndexTemplates] = useState<Array<DieselIndexTemplateLightModel>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [requestPending, setRequestPending] = useState<boolean>(false);
    const [duplicateTemplatesSelected, setDuplicateTemplatesSelected] = useState<Array<DieselIndexTemplateLightModel>>([]);

    useEffect(() => {
        setLoading(true);

        DieselIndexesReferentialApiClient.GetForeignDieselIndexTemplates(props.selectedLogisticsUnitId, props.startDate, props.endDate)
            .then(res => {
                setDieselIndexTemplates(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const HandleRefreshDuplicateTemplatesSelected = (template: DieselIndexTemplateLightModel, toAdd: boolean): void => {
        let duplicateTemplatesData = [...duplicateTemplatesSelected];
        const indexElem = duplicateTemplatesData.findIndex(x => x.dieselIndexTemplateId === template.dieselIndexTemplateId);
        if (indexElem != -1) {
            if (!toAdd) {
                duplicateTemplatesData = duplicateTemplatesData.filter(x => x.dieselIndexTemplateId !== template.dieselIndexTemplateId);
            }
        }
        else if (toAdd) {
            duplicateTemplatesData.push(template);
        }

        setDuplicateTemplatesSelected([...duplicateTemplatesData]);
    }

    const AddDieselIndexTemplates = (): void => {
        setRequestPending(true);
        const duplicateTemplatesSelectedData = cloneDeep(duplicateTemplatesSelected);

        duplicateTemplatesSelectedData.forEach(template => {
            template.logisticsUnitId = props.selectedLogisticsUnitId
        });

        DieselIndexesReferentialApiClient.AddDieselIndexTemplates(duplicateTemplatesSelectedData)
            .then(res => {
                const data = res?.data;

                const errors = BusinessErrors.Get(data);
                props.HandleResult(errors);
            })
            .finally(() => {
                setRequestPending(false);
            });
    }

    const handleClose = (_event, reason: string) => {
        if (reason !== "backdropClick") {
            props.HandleCloseDialog();
        }
    }

    const dieselIndexTemplatesGroupedByLogisticsUnit = groupBy(dieselIndexTemplates, x => x.logisticsUnitId);

    return (
        <Dialog
            disableEscapeKeyDown
            aria-modal="false"
            open={true}
            onClose={handleClose}
            scroll="paper"
            className="simple-dialog-existing-template"
        >
            <DialogTitle id="scroll-dialog-title">
                Choix d'un modèle
            </DialogTitle>
            <DialogContent dividers className="dialog-duplicate-content">
                <Box className="dialog-duplicate-templates">
                    {loading
                        ?
                        <div className='sweet-loading'>
                            <ScaleLoader
                                width={5}
                                height={20}
                                radius={50}
                                color={'#000000'}
                                loading={loading}
                            />
                        </div> :
                        <Box display="flex" flexDirection="column" p={1}>
                            {map(dieselIndexTemplatesGroupedByLogisticsUnit, (templates, logisticsUnitId) => {
                                return (
                                    <Box key={`template-card-logisticsUnit-${logisticsUnitId}`} pb={1}>
                                        <Card className="template-card-logisticsUnit">
                                            <CardContent className="template-content">
                                                <div className="template-header-logisticsUnit">
                                                    {templates[0].logisticsUnitLabel}
                                                </div>
                                                <Box display="flex" flexDirection="column">
                                                    {templates.map((template, index) => {
                                                        return <ForeignDieselIndexTemplateComponent dieselIndexTemplate={template} HandleRefreshDuplicateTemplateIds={HandleRefreshDuplicateTemplatesSelected} key={`template-card-logisticsUnit-${logisticsUnitId}-${index}`} />
                                                    })}
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                )
                            })}
                        </Box>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.HandleCloseDialog} color="primary">
                    Annuler
                </Button>
                <Button color="primary" disabled={duplicateTemplatesSelected.length == 0 || requestPending} onClick={AddDieselIndexTemplates}>
                    Récupérer
                </Button>
            </DialogActions>
        </Dialog>
    );
}