import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Input, InputAdornment } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { UploadOnBeforeUploadEvent, UploadOnStatusChangeEvent, UploadResponse } from '@progress/kendo-react-upload';
import React from 'react';
import { UploadComponent } from 'src/shared/components/Common/UploadComponent';
import { downloadFileFromBlob, getFileName } from '../../../../utils/DownloadFile';
import { WorkAgencyChoicesExportRequestArgs } from '../services/dataContracts/controller/WorkAgencyChoicesExportRequestArgs';
import { WorkAgenciesReferentialApiClient } from '../services/WorkAgenciesReferentialApiClient';

interface HeaderContentComponentProps {
    inputSearchWorkAgenciesValue: string,
    inputSearchWorkAgenciesRef: React.RefObject<HTMLInputElement>,
    sort: SortDescriptor[],
    handleWorkAgenciesKeyPress: (text: string) => void,
    handleClearSearchText: () => void,
    handleAddWorkAgencyDialogClick: () => void,
    handlerAfterUpload: (response: UploadResponse) => void,
    handlerBeforeUpload: (event: UploadOnBeforeUploadEvent) => void,
    displayErrorResponse: () => void
}

export const HeaderContentComponent = (props: HeaderContentComponentProps) => {

    const exportToExcel = (): void => {
        const workAgencyChoicesExportRequestArgs: WorkAgencyChoicesExportRequestArgs = {
            searchText: props.inputSearchWorkAgenciesValue,
            sortField: props.sort.length > 0 ? props.sort[0].field : null,
            sortDirection: props.sort.length > 0 ? props.sort[0].dir : null
        };

        WorkAgenciesReferentialApiClient.ExportWorkAgencyChoices(workAgencyChoicesExportRequestArgs)
            .then(response => {
                const fileName = getFileName(response);
                const blob = new Blob([response.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const onUploadStatusChange = (event: UploadOnStatusChangeEvent): void => {
        const res = event.response;
        if (res?.status == 200) {
            props.handlerAfterUpload(event.response);
        }
        else if(props.displayErrorResponse) {
            props.displayErrorResponse();
        }
    }
    
    const inputSearchWorkAgenciesClassName: string = props.inputSearchWorkAgenciesValue.length > 2 ? 'search-text-active' : '';
    const uploadSaveUrl: string = WorkAgenciesReferentialApiClient.GetImportUrl();

    return (
        <Box display="flex" flexDirection="row" flex="wrap" justifyContent="start" alignItems="center" className="header-content">
            <Box>
                <Input disableUnderline className={inputSearchWorkAgenciesClassName} inputRef={props.inputSearchWorkAgenciesRef}
                    startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                    endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => props.handleClearSearchText()} /></InputAdornment>}
                    placeholder="Rechercher ..." onChange={(event) => props.handleWorkAgenciesKeyPress(event.target.value)} />
            </Box>
            <Box ml={'10px'}>
                <Button variant="contained" color="primary" title="Ajouter"
                    onClick={props.handleAddWorkAgencyDialogClick}>
                    Ajouter
                </Button>
            </Box>
            <Box ml={'10px'}>
                <Button variant="contained" color="primary" title="Exporter sous format Excel" onClick={exportToExcel}>
                    Exporter
                </Button>
            </Box>
            <Box ml={'10px'}>
                <UploadComponent
                    multiple={false}
                    showFileList={false}
                    restrictions={{
                        allowedExtensions: ['.xls', '.xlsx']
                    }}
                    defaultFiles={[]}
                    withCredentials={false}
                    saveUrl={uploadSaveUrl}
                    onBeforeUpload={props.handlerBeforeUpload}
                    onStatusChange={onUploadStatusChange}
                />
            </Box>
        </Box>
    );
}
