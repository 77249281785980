import { Box, Tooltip } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import React, { MutableRefObject, useEffect, useRef } from 'react';

interface CustomHeaderCheckBoxCellProperties {
    label: string,
    field: string,
    showCheckbox: boolean,
    checkedAll: boolean,
    indeterminateAll: boolean,
    tooltipMessage: string,
    sort?: SortDescriptor[],
    handleSortColumnChange?: (sortItems: SortDescriptor[]) => void,
    onSelectionChange: (checked: boolean) => void
}

export const CustomHeaderCheckBoxCellComponent = (props: CustomHeaderCheckBoxCellProperties): JSX.Element => {

    const checkboxRef: MutableRefObject<HTMLInputElement> = useRef(null);

    let sortIconClass = "";
    let sortIndex = -1;
    if (props.sort?.length > 0) {
        const currentSortIndex: number = props.sort.findIndex(x => x.field == props.field);
        if(currentSortIndex !== -1){
            sortIconClass = props.sort[currentSortIndex].dir === "asc" ?  "k-icon k-i-sort-asc-sm" : "k-icon k-i-sort-desc-sm";
            sortIndex = currentSortIndex;
        }
    }

    useEffect(() => {
        if(props.showCheckbox)
            checkboxRef.current.indeterminate = props.indeterminateAll;
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        props.onSelectionChange(e.target.checked);
    };

    const handleSortChange = () =>{
        let sortItems: SortDescriptor[] = null;
        if (props.sort.length > 0 && props.sort[0].dir === 'asc' && props.sort[0].field === props.field) {
            sortItems = [{ field: props.field, dir: 'desc' }];
        } else if (props.sort.length > 0 && props.sort[0].dir === 'desc' && props.sort[0].field === props.field) {
            sortItems = [];
        } else {
            sortItems = [{ field: props.field, dir: 'asc' }];
        }
        props.handleSortColumnChange(sortItems);
    }

    const checked: boolean = props.checkedAll;
    return (
        <>
            {props.label && <Tooltip title={props.tooltipMessage} placement="top" className="referential-tooltip">
                <Box className="cell-price" minWidth="40px" onClick={handleSortChange}>
                    {props.label}
                    <div>
                        {sortIconClass !== "" &&
                        <span className={sortIconClass}></span>
                        }
                        {props.sort.length > 1 && sortIndex !== -1 &&
                            <span className="k-sort-order">{(sortIndex + 1)}</span>
                        }
                    </div>    
                </Box>
            </Tooltip>}
            {props.showCheckbox && <Box className="cell-checkbox">
                <input type="checkbox" ref={checkboxRef} checked={checked} onChange={(e) => handleChange(e)} className="custom-checkbox" />
            </Box>}
        </>
    );
}