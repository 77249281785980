import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Input, Tooltip } from '@mui/material';
import React from 'react';

interface AddDispatcherDialogComponentProps {
    userName: string,
    handleChangeUserName: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
    handleAddContactClick: () => void
}

export const AddDispatcherDialogComponent = (props: AddDispatcherDialogComponentProps): JSX.Element => {

    const AddDispatcherTooltipImage = "/static/Referentials/DispatchersReferential/AddDispatcherTooltipImage.png";

    const validateUserName = (userName: string): boolean => {
        const regExp = /^[a-zA-Z0-9](\.?[a-zA-Z0-9]){1,}@colas\.com$/;
        return regExp.test(userName.trim());
    }

    return (
        <Box className="add-dispatcher-content">
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box display="flex" flexDirection="column" width="80%">
                    <Box className="username-input-text-label">Utilisateur « @colas.com »</Box>
                    <Box display="flex" flexDirection="row">
                        <Input
                            id="username"
                            value={props.userName}
                            className="username-input-text"
                            onChange={props.handleChangeUserName}
                        />
                    </Box>
                </Box>
                <Box>
                    <Tooltip id="add-dispatcher-tooltip-image" placement="right" title={
                        <Box>
                            <p className="info-user-text">Pour récupérer l'utilisateur "@colas.com", vous pouvez double cliquer sur l'adresse mail de la personne à insérer sur Outlook et récupérer la valeur dans la valeur du champs "MI": </p>
                            <img src={AddDispatcherTooltipImage} />
                        </Box>
                    }>
                        <Avatar>
                            <FontAwesomeIcon icon={faQuestion} />
                        </Avatar>
                    </Tooltip>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                <Button variant="contained" color="primary" title="Ajouter" onClick={props.handleAddContactClick} disabled={!props.userName || !validateUserName(props.userName)}>
                    Ajouter
                </Button>
            </Box>
        </Box>
    );
}
