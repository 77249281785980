import { Drawer } from "@mui/material";
import { UploadOnAddEvent, UploadOnBeforeUploadEvent } from '@progress/kendo-react-upload';
import React from 'react';
import { FlowDiff } from "src/shared/Differences/dataContracts/FlowDiff";
import { FlowDiffProperty } from "src/shared/Differences/dataContracts/FlowDiffProperty";
import { FormatTypeEnum } from '../models/FormatTypeEnum';
import { TitleValueDiffModel } from '../models/TitleValueDiffModel';
import { TransportFlowAttachmentFileLightModel } from '../services/dataContracts/queryStack/TransportFlowAttachmentFileLightModel';
import { TransportFlowComplementaryDetailsLightModel } from '../services/dataContracts/queryStack/TransportFlowComplementaryDetailsLightModel';
import { TransportFlowDetailsComponent } from './TransportFlowDetailsComponent';

interface TransportFlowDetailsDrawerProperties {
    handleDrawerRightClose: () => void,
    areFlowDetailsOpened: boolean,
    detailsData: TransportFlowComplementaryDetailsLightModel,
    getLabelFromDict: (objectName: string, fieldName: string) => string,
    attachmentFiles: Array<TransportFlowAttachmentFileLightModel>,
    handleDeleteAttachment: (attachmentId: string) => void,
    handlerAfterUpload: () => void,
    handlerBeforeUpload: (event: UploadOnBeforeUploadEvent) => void,
    handleAdd: (event: UploadOnAddEvent) => void,
    isLoadingAttachments: boolean,
    handlerDisplayErrorsAfterUpload: (errors: string[]) => void,
    getValueTitle: (diffsFromRequest: Array<FlowDiff>, valueField: any, field: FlowDiffProperty, formatter?) => TitleValueDiffModel,
    formatter: (fieldValue: any, format: FormatTypeEnum) => string
}

export const TransportFlowDetailsDrawerComponent = (props: TransportFlowDetailsDrawerProperties): JSX.Element => {

    return (
        <Drawer
            className='drawer-dc-details'
            variant="persistent"
            anchor="right"
            open={props.areFlowDetailsOpened}
            classes={{
                paper: 'drawer-dc-details-paper'
            }}
        >
            <div className='toolbar-styles' />
            <TransportFlowDetailsComponent getValueTitle={props.getValueTitle} formatter={props.formatter}
                getLabelFromDict={props.getLabelFromDict}
                handleDrawerRightClose={props.handleDrawerRightClose}
                detailsData={props.detailsData}
                attachmentFiles={props.attachmentFiles}
                handleDeleteAttachment={props.handleDeleteAttachment}
                handlerAfterUpload={props.handlerAfterUpload}
                handlerBeforeUpload={props.handlerBeforeUpload}
                isLoadingAttachments={props.isLoadingAttachments}
                handleAdd={props.handleAdd}
                handlerDisplayErrorsAfterUpload={props.handlerDisplayErrorsAfterUpload}
            />
        </Drawer>
    );
}