import { Box, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { ScaleLoaderComponent } from '../../../../../shared/components/Common/ScaleLoaderComponent';
import { DriverCandidateToAddExtended } from '../../models/DriverCandidateToAddExtended';
import { DriverCandidateToAdd } from '../../services/dataContracts/controller/DriverCandidateToAdd';
import { ConfirmAddDriverStepComponent } from './ConfirmAddDriverStepComponent';
import { SearchDriverStepComponent } from './SearchDriverStepComponent';
import { SelectDriverStepComponent } from './SelectDriverStepComponent';

interface AddDriverDialogComponentProperties {
    activeStep: number,
    driverSearchLoading: boolean,
    mgrh: string,
    driverLastName: string,
    driverChoices: DriverCandidateToAdd[],
    handleDriverMgrhChange: (value: string) => void,
    handleDriverLastNameChange: (value: string) => void,
    searchDriverByMgrh: (mgrh: string) => void,
    searchDriverByLastName: (driverLastName: string) => void,
    handleSearchInReferentialGrid: (mgrh: string) => void,
    handleConfirmStep: (transporter: DriverCandidateToAddExtended) => void,
    handlePreviousStep: () => void,
    confirmAddDriver: (phoneNumber: string) => void
}

export const AddDriverDialogComponent = (props: AddDriverDialogComponentProperties): JSX.Element => {

    const steps: string[] = ["Chercher", "Ajouter", "Confirmer"];

    const getStepContent = (stepIndex: number): JSX.Element => {
        switch (stepIndex) {
            case 0:
                return <SearchDriverStepComponent
                    driverSearchLoading={props.driverSearchLoading}
                    mgrh={props.mgrh}
                    driverLastName={props.driverLastName}
                    handleDriverMgrhChange={props.handleDriverMgrhChange}
                    handleDriverLastNameChange={props.handleDriverLastNameChange}
                    searchDriverByMgrh={props.searchDriverByMgrh}
                    searchDriverByLastName={props.searchDriverByLastName}
                />;
            case 1:
                return <SelectDriverStepComponent
                    driverChoices={props.driverChoices}
                    handleSearchInReferentialGrid={props.handleSearchInReferentialGrid}
                    handleConfirmStep={props.handleConfirmStep}
                    handlePreviousStep={props.handlePreviousStep}
                />;
            case 2:
                return <ConfirmAddDriverStepComponent
                    confirmAddDriver={props.confirmAddDriver}
                    handlePreviousStep={props.handlePreviousStep}
                />;
            default:
                return <></>;
        }
    }

    return (
        <Box className="add-driver-component">
            <Box display="flex" flexDirection="row" justifyContent="center" className="loading-content">
                <ScaleLoaderComponent loading={props.driverSearchLoading} />
            </Box>
            <Stepper activeStep={props.activeStep} alternativeLabel>
                {steps.map((label: string) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box>{getStepContent(props.activeStep)}</Box>
        </Box>
    );
}