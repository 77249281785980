import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, IconButton, TextField, Tooltip } from '@mui/material';
import { UploadOnAddEvent, UploadOnBeforeUploadEvent } from '@progress/kendo-react-upload';
import React, { useEffect, useState } from 'react';
import { FlowDiff } from 'src/shared/Differences/dataContracts/FlowDiff';
import { FlowDiffProperty } from 'src/shared/Differences/dataContracts/FlowDiffProperty';
import { toMinutes } from 'src/utils/TimeSpanString';
import Utilities from '../../../utils/Utilities';
import { FormatTypeEnum } from '../models/FormatTypeEnum';
import { TitleClassDiffModel } from '../models/TitleClassDiffModel';
import { TitleValueDiffModel } from '../models/TitleValueDiffModel';
import { TransportFlowComplementaryDetailsLightModelExtended } from '../models/TransportFlowComplementaryDetailsLightModelExtended';
import { TransportFlowAttachmentFileLightModel } from '../services/dataContracts/queryStack/TransportFlowAttachmentFileLightModel';
import { TransportPlanApiClient } from '../services/TransportPlanApiClient';
import './DetailsComponentStyles.scss';
import { FlowAttachmentComponent } from './FlowAttachmentComponent';

interface TransportFlowDetailsProperties {
    detailsData: TransportFlowComplementaryDetailsLightModelExtended,
    getLabelFromDict: (objectName: string, fieldName: string) => string,
    handleDrawerRightClose: () => void,
    attachmentFiles: Array<TransportFlowAttachmentFileLightModel>,
    handleDeleteAttachment: (attachmentId: string) => void,
    handlerAfterUpload: () => void,
    handlerBeforeUpload: (event: UploadOnBeforeUploadEvent) => void,
    handleAdd: (event: UploadOnAddEvent) => void,
    isLoadingAttachments: boolean,
    handlerDisplayErrorsAfterUpload: (errors: string[]) => void,
    getValueTitle: (diffsFromRequest: Array<FlowDiff>, valueField: any, field: FlowDiffProperty, formatter?, formatType?: FormatTypeEnum) => TitleValueDiffModel,
    formatter: (fieldValue: any, format: FormatTypeEnum) => string
}

export const TransportFlowDetailsComponent = (props: TransportFlowDetailsProperties): JSX.Element => {
    const details: TransportFlowComplementaryDetailsLightModelExtended = props.detailsData;

    const transportFlowId: string = details ? details.transportFlowId : '';
    const transportRequestId: string = details ? details.transportRequestId : '';
    const transportersInstructions: string = details ? details.transportersInstructions : '';
    const deliveryRemarks: string = details ? details.transportRequestDeliveryRemarks : '';

    const txtDeliveryRemarks: React.RefObject<HTMLTextAreaElement> = React.useRef(null);
    const txtTransportersInstructions: React.RefObject<HTMLTextAreaElement> = React.useRef(null);
    const [isTxtTransportersInstructionsEditable, setIsTxtTransportersInstructionsEditable] = useState<boolean>(false);

    useEffect(() => {
        if (isTxtTransportersInstructionsEditable)
            txtTransportersInstructions.current.focus();
    }, [isTxtTransportersInstructionsEditable]);

    useEffect(() => {
        if (txtTransportersInstructions && txtTransportersInstructions.current) {
            if (transportersInstructions && transportersInstructions !== '')
                txtTransportersInstructions.current.value = transportersInstructions;
            else
                txtTransportersInstructions.current.value = '';
        }
        if (txtDeliveryRemarks && txtDeliveryRemarks.current) {
            if (deliveryRemarks && deliveryRemarks !== '')
                txtDeliveryRemarks.current.value = deliveryRemarks;
            else
                txtDeliveryRemarks.current.value = '';
        }
    }, [transportFlowId]);

    const handleEditTransportersInstructions = (value: boolean): void => {
        if (!value && transportersInstructions !== txtTransportersInstructions.current.value) {
            TransportPlanApiClient.UpdateTransportFlowTransportersInstructions(transportRequestId, transportFlowId, txtTransportersInstructions.current.value)
                .then((res) => {
                });
        }
        setIsTxtTransportersInstructionsEditable(value);
    }

    const getValueTitleFromField = (flowDiffProperty: FlowDiffProperty, formatType?: FormatTypeEnum): TitleClassDiffModel => {
        const titleValue = props.getValueTitle(details.diffsFromRequest, details.productCode, flowDiffProperty, props.formatter, formatType);
        const className = titleValue.hasDiffFromRequest ? "field-different-value-transportRequest" : "";
        return {
            class: className,
            title: titleValue.title,
            hasDiffFromRequest: titleValue.hasDiffFromRequest
        }
    }

    const productCodeDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.product_Code) : null;
    const productLevel3Diff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.product_Level3SegmentationId) : null;
    const coatingApplicationModeDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.coatingApplicationMode) : null;
    const pickupAddressDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.pickupAddress_Line1) : null;
    const deliveryAddressDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.deliveryAddress_Line1) : null;
    const pickupZipCodeDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.pickupAddress_ZipCode) : null;
    const deliveryZipCodeDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.deliveryAddress_ZipCode) : null;
    const pickupCityDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.pickupAddress_City) : null;
    const deliveryCityDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.deliveryAddress_City) : null;
    const benefPhoneNumberDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.beneficiary_PhoneNumber) : null;
    const requestedByPhoneNumberDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.requestedBy_PhoneNumber) : null;
    const unloadingDurationDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.unloadingDuration) : null;
    const isUnloadingContinuityRequiredDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.isUnloadingContinuityRequired) : null;
    const estimatedTripDurationDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.estimatedTripDuration) : null;
    const estimatedTransportCostDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.estimatedTransportPrice) : null;
    const arrivalTimeAtReceiverSiteDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.arrivalTimeAtReceiverSite, FormatTypeEnum.Time) : null;
    const arrivalSlotEndTimeAtReceiverSiteDiff = transportFlowId ? getValueTitleFromField(FlowDiffProperty.arrivalSlotEndTimeAtReceiverSite, FormatTypeEnum.Time) : null;

    let arrivalEndTime = null;

    if (transportFlowId && details.arrivalTimeAtReceiverSite && details.arrivalSlotDuration) {
        const arrivalSlotDurationMinutes = toMinutes(details.arrivalSlotDuration);
        arrivalEndTime = new Date(details.arrivalTimeAtReceiverSite).addMinutes(arrivalSlotDurationMinutes);
    }

    return (
        <Box display="flex" flexDirection="column" flex='nowrap' className='content-drawer-details'>
            <Box display="flex" pt={2} flexDirection="column" flex='nowrap' className='content-header-drawer-details'>
                <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems='center'>
                    <Box width="80%" pl={1} className='title-details'>
                        Complément d'information
                    </Box>
                    <Box pr={1} display="flex" width="20%" flexDirection="row" justifyContent="flex-end">
                        <Box style={{ cursor: 'pointer' }} onClick={props.handleDrawerRightClose}>
                            <FontAwesomeIcon size="lg" icon={faTimes} />
                        </Box>
                    </Box>
                </Box>
                <Divider style={{ height: '2px' }} />
                {transportFlowId ?
                    (
                        <>
                            <Box display="flex" p={2} flexDirection="column" flex='nowrap' className='container-details'>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Produit à transporter
                                            </Box>
                                            <Tooltip title={`${productCodeDiff.hasDiffFromRequest ?
                                                (productCodeDiff.title !== null && productCodeDiff.title !== '' ? productCodeDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${productCodeDiff.class}`}>
                                                    {details.productCode}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Type produit
                                            </Box>
                                            <Tooltip title={`${productLevel3Diff.hasDiffFromRequest ?
                                                (productLevel3Diff.title !== null && productLevel3Diff.title !== '' ? productLevel3Diff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${productLevel3Diff.class}`}>
                                                    {details.productType}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Type de chantier
                                            </Box>
                                            <Tooltip title={`${coatingApplicationModeDiff.hasDiffFromRequest ?
                                                (coatingApplicationModeDiff.title !== null && coatingApplicationModeDiff.title !== '' ? coatingApplicationModeDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${coatingApplicationModeDiff.class}`}>
                                                    {props.getLabelFromDict('CoatingApplicationMode', details.coatingApplicationMode)}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Travail de nuit
                                            </Box>
                                            <Box className='column-value'>
                                                {details.isNightWork === null ? 'Non défini' : details.isNightWork ? 'Oui' : 'Non'}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Id chantier départ
                                            </Box>
                                            <Box className='column-value'>
                                                {details.senderJobSiteMdmId}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Id chantier arrivée
                                            </Box>
                                            <Box className='column-value'>
                                                {details.receiverJobSiteMdmId}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Adresse départ
                                            </Box>
                                            <Tooltip title={`${pickupAddressDiff.hasDiffFromRequest ?
                                                (pickupAddressDiff.title !== null && pickupAddressDiff.title !== '' ? pickupAddressDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${pickupAddressDiff.class}`}>
                                                    {details.senderAddress}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Adresse arrivée
                                            </Box>
                                            <Tooltip title={`${deliveryAddressDiff.hasDiffFromRequest ?
                                                (deliveryAddressDiff.title !== null && deliveryAddressDiff.title !== '' ? deliveryAddressDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${deliveryAddressDiff.class}`}>
                                                    {details.receiverAddress}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                CP départ
                                            </Box>
                                            <Tooltip title={`${pickupZipCodeDiff.hasDiffFromRequest ?
                                                (pickupZipCodeDiff.title !== null && pickupZipCodeDiff.title !== '' ? pickupZipCodeDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${pickupZipCodeDiff.class}`}>
                                                    {details.senderAddressZipCode}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                CP arrivé
                                            </Box>
                                            <Tooltip title={`${deliveryZipCodeDiff.hasDiffFromRequest ?
                                                (deliveryZipCodeDiff.title !== null && deliveryZipCodeDiff.title !== '' ? deliveryZipCodeDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${deliveryZipCodeDiff.class}`}>
                                                    {details.receiverAddressZipCode}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Ville départ
                                            </Box>
                                            <Tooltip title={`${pickupCityDiff.hasDiffFromRequest ?
                                                (pickupCityDiff.title !== null && pickupCityDiff.title !== '' ? pickupCityDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${pickupCityDiff.class}`}>
                                                    {details.senderAddressCity}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Ville arrivée
                                            </Box>
                                            <Tooltip title={`${deliveryCityDiff.hasDiffFromRequest ?
                                                (deliveryCityDiff.title !== null && deliveryCityDiff.title !== '' ? deliveryCityDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${deliveryCityDiff.class}`}>
                                                    {details.receiverAddressCity}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Tel demandeur
                                            </Box>
                                            <Tooltip title={`${requestedByPhoneNumberDiff.hasDiffFromRequest ?
                                                (requestedByPhoneNumberDiff.title !== null && requestedByPhoneNumberDiff.title !== '' ? requestedByPhoneNumberDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value phone-number-ref ${requestedByPhoneNumberDiff.class}`}>
                                                    <a href={`callTo:${Utilities.formatPhoneNumber(details.requestedByPhoneNumber)}`} data-rel="external">
                                                        {Utilities.formatPhoneNumber(details.requestedByPhoneNumber)}
                                                    </a>
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Tel bénéficiaire
                                            </Box>
                                            <Tooltip title={`${benefPhoneNumberDiff.hasDiffFromRequest ?
                                                (benefPhoneNumberDiff.title !== null && benefPhoneNumberDiff.title !== '' ? benefPhoneNumberDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value phone-number-ref ${benefPhoneNumberDiff.class}`}>
                                                    <a href={`callTo:${Utilities.formatPhoneNumber(details.beneficiaryPhoneNumber)}`} data-rel="external">
                                                        {Utilities.formatPhoneNumber(details.beneficiaryPhoneNumber)}
                                                    </a>
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Heure début
                                            </Box>
                                            <Tooltip title={`${arrivalTimeAtReceiverSiteDiff.hasDiffFromRequest ?
                                                (arrivalTimeAtReceiverSiteDiff.title !== null && arrivalTimeAtReceiverSiteDiff.title !== '' ? arrivalTimeAtReceiverSiteDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${arrivalTimeAtReceiverSiteDiff.class}`}>
                                                    {`${details.arrivalTimeAtReceiverSite ? Date.getTimeFromIsoString(details.arrivalTimeAtReceiverSite) : ''}`}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Heure fin
                                            </Box>
                                            <Tooltip title={`${arrivalSlotEndTimeAtReceiverSiteDiff.hasDiffFromRequest ?
                                                (arrivalSlotEndTimeAtReceiverSiteDiff.title !== null && arrivalSlotEndTimeAtReceiverSiteDiff.title !== '' ? arrivalSlotEndTimeAtReceiverSiteDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${arrivalSlotEndTimeAtReceiverSiteDiff.class}`}>
                                                    {`${arrivalEndTime ? Date.getTimeFromIsoString(arrivalEndTime) : ''}`}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Durée sur chantier
                                            </Box>
                                            <Tooltip title={`${unloadingDurationDiff.hasDiffFromRequest ?
                                                (unloadingDurationDiff.title !== null && unloadingDurationDiff.title !== '' ? unloadingDurationDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${unloadingDurationDiff.class}`}>
                                                    {details.unloadingDuration}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Continuité
                                            </Box>
                                            <Tooltip title={`${isUnloadingContinuityRequiredDiff.hasDiffFromRequest ?
                                                (isUnloadingContinuityRequiredDiff.title !== null && isUnloadingContinuityRequiredDiff.title !== '' ? isUnloadingContinuityRequiredDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${isUnloadingContinuityRequiredDiff.class}`}>
                                                    {details.isUnloadingContinuityRequired === null ? 'Non défini' : details.isUnloadingContinuityRequired ? 'Oui' : 'Non'}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Temps trajet
                                            </Box>
                                            <Tooltip title={`${estimatedTripDurationDiff.hasDiffFromRequest ?
                                                (estimatedTripDurationDiff.title !== null && estimatedTripDurationDiff.title !== '' ? estimatedTripDurationDiff.title : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${estimatedTripDurationDiff.class}`}>
                                                    {details.estimatedTripDuration}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Distance
                                            </Box>
                                            <Box className='column-value'>
                                                {details.estimatedTripDistance}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Payeur
                                            </Box>
                                            <Box className='column-value'>
                                                {details.payerLabel ? details.payerLabel : ''}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box width="50%">
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Mode de livraison
                                            </Box>
                                            <Box className='column-value'>
                                                {details.transportBillingMode === "Departure" ? "Départ" : (details.transportBillingMode === "Inclusive" ? "Rendu" : '')}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} flexDirection="row">
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Nb finishers
                                            </Box>
                                            <Box className='column-value'>
                                                {details.numberOfFinishers}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box width="50%" pr={1}>
                                        <Box display="flex" flexDirection="column" flex='nowrap'>
                                            <Box className="column-title">
                                                Prix vente à facturer
                                            </Box>
                                            <Tooltip title={`${estimatedTransportCostDiff.hasDiffFromRequest ?
                                                (estimatedTransportCostDiff.title !== null && estimatedTransportCostDiff.title !== '' ? `${estimatedTransportCostDiff.title}€` : ' ') : ''}`} placement="bottom">
                                                <Box className={`column-value ${estimatedTransportCostDiff.class}`}>
                                                    {details.billedTransportPrice ? `${details.billedTransportPrice} ` : ''}{details.billedTransportPrice ? (<span>&euro;</span>) : ''}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                                {!details.isTransportRequestHidden &&
                                    <Box display="flex" pt={1} pb={1} flexDirection="row">
                                        <Box width="90%" pr={1}>
                                            <TextField
                                                label="Info. livraisons (client)"
                                                margin="normal"
                                                multiline
                                                variant="outlined"
                                                rows="4"
                                                disabled={true}
                                                className='txt-field-flow-complementary-detail'
                                                inputRef={txtDeliveryRemarks}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                }
                                <Box display="flex" pt={1} pb={1} flexDirection="row">
                                    <Box width="90%" pr={1}>
                                        <TextField
                                            id="outlined-dense-multiline"
                                            label="Instructions aux transporteurs"
                                            margin="normal"
                                            multiline
                                            variant="outlined"
                                            rows="4"
                                            disabled={!isTxtTransportersInstructionsEditable}
                                            className="txt-field-flow-complementary-detail"
                                            onBlur={() => handleEditTransportersInstructions(false)}
                                            inputRef={txtTransportersInstructions}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Box>
                                    <Box display="flex" alignItems="flex-end" width="10%" pl={1} pb={1}>
                                        <Tooltip title="Saisir dans le champs Instructions aux transporteurs">
                                            <IconButton size="small" onClick={() => handleEditTransportersInstructions(true)} disabled={isTxtTransportersInstructionsEditable}>
                                                <FontAwesomeIcon size="1x" className='icon-pen-blue' icon={faPen} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                                <Box display="flex" pt={1} pb={1} flexDirection="row">
                                    <FlowAttachmentComponent transportRequestId={transportRequestId}
                                        transportFlowId={transportFlowId}
                                        attachmentFiles={props.attachmentFiles}
                                        handleDeleteAttachment={props.handleDeleteAttachment}
                                        handlerAfterUpload={props.handlerAfterUpload}
                                        handlerBeforeUpload={props.handlerBeforeUpload}
                                        isLoadingAttachments={props.isLoadingAttachments}
                                        handleAdd={props.handleAdd}
                                        handlerDisplayErrorsAfterUpload={props.handlerDisplayErrorsAfterUpload}
                                    />
                                </Box>
                            </Box>
                        </>
                    ) : ''
                }
            </Box>
        </Box>
    );
}