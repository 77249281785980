import { Box } from '@mui/material';
import { debounce } from 'lodash';
import React from 'react';
import { ScaleLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.min.css';
import { downloadFileFromBlob, getFileName } from 'src/utils/DownloadFile';
import { DateRange } from '../../../shared/models/DateRange';
import '../../../utils/Date';
import { SessionStorage } from '../../../utils/Storage';
import Utilities from '../../../utils/Utilities';
import { ContentHeaderComponent } from './components/ContentHeaderComponent';
import { VehicleTripsComponent } from './components/VehicleTripsComponent';
import { ProductionForemanSelectModel } from './models/ProductionForemanSelectModel';
import { PlanningVehiclesExportRequestArgs } from './services/dataContracts/controller/PlanningVehiclesExportRequestArgs';
import { ProductionForemanPlanningVehicleLightModel } from './services/queryStack/ProductionForemanPlanningVehicleLightModel';
import { ThirdPartyProductionApiClient } from './services/ThirdPartyProductionApiClient';
import './ThirdPartyProductionStyles.scss';

interface ThirdPartyProductionProps {
    role: string,
    logisticsUnitIds: Array<string>
}

interface ThirdPartyProductionState {
    lastTimeStampSearchProductionForemen: number,
    lastTimeStampGetVehicleTypeCount: number,
    lastTimeStampSearchVehiclesTrip: number,
    selectedProductionForeman: ProductionForemanSelectModel,
    date: DateRange,
    loading: boolean,
    searchText: string,
    listProductionForeman: ProductionForemanSelectModel[],
    planningVehiclesList: Array<ProductionForemanPlanningVehicleLightModel>,
}

export class ThirdPartyProductionView extends React.Component<ThirdPartyProductionProps, ThirdPartyProductionState> {

    today: Date = new Date();
    inputSearchVehiclesRef: React.RefObject<HTMLInputElement>;
    path: string = window.location.pathname.substring(1);
    lastSelectedIndex = 0;
    agencies: Array<string> = new Array<string>();

    constructor(props: ThirdPartyProductionProps) {
        super(props);

        this.inputSearchVehiclesRef = React.createRef();

        this.state = {
            lastTimeStampSearchProductionForemen: Date.now(),
            lastTimeStampGetVehicleTypeCount: Date.now(),
            lastTimeStampSearchVehiclesTrip: Date.now(),
            selectedProductionForeman: null,
            date: { start: SessionStorage.ActiveStartDate, end: SessionStorage.ActiveEndDate },
            loading: true,
            searchText: "",
            listProductionForeman: [],
            planningVehiclesList: [],
        };
    }

    componentDidMount() {
        if (this.path === "ThirdPartyProduction") {
            this.SearchProductionForemen();
        } else {
            if (this.props.role === "PRD") {
                this.searchPlanningVehiclesTrip(this.state.date, this.props.logisticsUnitIds, "", "");
            }
        }
    }

    SearchProductionForemen = (): void => {
        const lastTimeStamp = this.state.lastTimeStampSearchProductionForemen;
        const currentTimeStamp = Date.now();

        ThirdPartyProductionApiClient.SearchProductionForemen(this.props.logisticsUnitIds)
            .then(res => {
                if (lastTimeStamp != this.state.lastTimeStampSearchProductionForemen)
                    return;

                this.setState({
                    lastTimeStampSearchProductionForemen: currentTimeStamp,
                    loading: false,
                    listProductionForeman: res.data.map(x => { return { label: x.productionForemanFullName, value: x.productionForemanUserName } })
                });
            });
    }

    clearSearchText = (): void => {
        this.inputSearchVehiclesRef.current.value = '';
        this.vehiclesTripsKeyPressed('');
    }

    handleVehiclesTripsKeyPress = debounce((text: string): void => {
        if (text.length >= 3 || text.length === 0) {
            this.vehiclesTripsKeyPressed(text);
        }
    }, 500);

    vehiclesTripsKeyPressed = (text: string): void => {
        this.setState({
            searchText: text
        });

        if (this.path === "ThirdPartyProduction") {
            if (this.state.selectedProductionForeman)
                this.searchPlanningVehiclesTrip(this.state.date, this.props.logisticsUnitIds, text, this.state.selectedProductionForeman.value);
        } else {
            if (this.props.role === "PRD") {
                this.searchPlanningVehiclesTrip(this.state.date, this.props.logisticsUnitIds, text, "");
            }
        }
    }

    searchPlanningVehiclesTrip = (date: DateRange, logisticsUnitIds: Array<string>, searchText: string, selectedProductionForemanUserName?: string): void => {
        const fromTime = date.start;
        const untilTime = date.end;

        const lastTimeStamp = this.state.lastTimeStampSearchVehiclesTrip;
        const currentTimeStamp = Date.now();
        this.setState({
            loading: true
        });

        ThirdPartyProductionApiClient.SearchPlanningVehicles(fromTime, untilTime, searchText, selectedProductionForemanUserName, logisticsUnitIds)
            .then(res => {
                if (lastTimeStamp != this.state.lastTimeStampSearchVehiclesTrip)
                    return;

                const planningVehiclesArray: Array<ProductionForemanPlanningVehicleLightModel> = res.data;
                planningVehiclesArray.forEach((Vehicle: ProductionForemanPlanningVehicleLightModel) => {
                    this.translatePlanningVehicles(Vehicle);
                });

                if (lastTimeStamp != this.state.lastTimeStampSearchVehiclesTrip)
                    return;

                this.setState({
                    planningVehiclesList: planningVehiclesArray.map((dataItem: ProductionForemanPlanningVehicleLightModel) => Object.assign({
                        selected: false,
                        finishedTripCount: `${dataItem.deliveredTripCount}/${dataItem.expectedTripCount}`,
                        finishedTripQuantity: `${dataItem.deliveredQuantity.toStringFixed("FR-fr", 2)}/${dataItem.expectedQuantity.toStringFixed("FR-fr", 2)}`
                    }, dataItem)),
                    lastTimeStampSearchVehiclesTrip: currentTimeStamp,
                    loading: false,
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    translatePlanningVehicles = (productionForemanPlanningVehicle: ProductionForemanPlanningVehicleLightModel): void => {
        productionForemanPlanningVehicle.planningDate = new Date(productionForemanPlanningVehicle.planningDate);
        productionForemanPlanningVehicle.firstLoadingTime = productionForemanPlanningVehicle.firstLoadingTime == null ? null : Date.getTimeFromIsoString(productionForemanPlanningVehicle.firstLoadingTime);
        productionForemanPlanningVehicle.driverPhone = Utilities.formatPhoneNumber(productionForemanPlanningVehicle.driverPhone);
    }

    handleProductionForemanChange = (e: ProductionForemanSelectModel): void => {
        this.setState({
            selectedProductionForeman: e
        });
        if (e)
            this.searchPlanningVehiclesTrip(this.state.date, this.props.logisticsUnitIds, this.state.searchText, e.value);
        else {
            this.setState({
                planningVehiclesList: [],
                loading: false
            });
        }

    }

    handleChangeDateRange = (range: DateRange): void => {
        if (range.end != null && range.start != null) {
            const fromTime = range.start.getDayStart();
            const toTime = range.end.getDayEnd();

            this.setState({
                date: { start: fromTime, end: toTime }
            });

            if (this.path === "ThirdPartyProduction") {
                if (this.state.selectedProductionForeman)
                    this.searchPlanningVehiclesTrip({ start: fromTime, end: toTime }, this.props.logisticsUnitIds, this.state.searchText, this.state.selectedProductionForeman.value);
            } else if (this.props.role === "PRD") {
                this.searchPlanningVehiclesTrip({ start: fromTime, end: toTime }, this.props.logisticsUnitIds, this.state.searchText, "");
            }
        }
    }

    exportToExcel = (): void => {
        const { date, selectedProductionForeman } = this.state;

        const today = new Date().stripTime();

        const requestArgs: PlanningVehiclesExportRequestArgs = {
            fromTime: date.start,
            untilTime: date.end,
            currentDate: today,
            logisticsUnits: this.props.logisticsUnitIds,
            selectedProductionForemanUserName: selectedProductionForeman?.value
        };

        ThirdPartyProductionApiClient.ExportPlanningVehicles(requestArgs)
            .then(response => {
                const fileName: string = getFileName(response);
                const blob: Blob = new Blob([response.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    render() {
        const { planningVehiclesList, loading, listProductionForeman, selectedProductionForeman } = this.state;

        const inputSearchVehiclesValue = this.inputSearchVehiclesRef.current === null || this.inputSearchVehiclesRef.current === undefined ? '' : this.inputSearchVehiclesRef.current.value;

        const contents = loading
            ? <div className='sweet-loading spinnerClass'>
                <ScaleLoader
                    width={5}
                    height={20}
                    radius={50}
                    color={'#000000'}
                    loading={loading}
                />
            </div>
            :
            <Box display="flex">
                <div className="vehicles-content">
                    <VehicleTripsComponent
                        planningVehiclesList={planningVehiclesList}
                    />
                </div>
            </Box>;

        const btnExportEnabled = selectedProductionForeman !== null || this.props.role === "PRD";

        const communContent = <div className="component-content">
            <ContentHeaderComponent
                inputSearchVehiclesValue={inputSearchVehiclesValue}
                inputSearchVehiclesRef={this.inputSearchVehiclesRef}
                path={this.path}
                date={this.state.date}
                listProductionForeman={listProductionForeman}
                btnExportEnabled={btnExportEnabled}
                selectedProductionForeman={selectedProductionForeman}
                handleChangeDateRange={this.handleChangeDateRange}
                handleVehiclesTripsKeyPress={this.handleVehiclesTripsKeyPress}
                clearSearchText={this.clearSearchText}
                handleProductionForemanChange={this.handleProductionForemanChange}
                exportToExcel={this.exportToExcel}
            />
            {contents}
        </div>

        const ret = <div className="production-foreman-management-content is-prd">
            {communContent}
        </div>;

        return ret;
    }
}
