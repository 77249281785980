import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select, { ActionMeta, components, InputActionMeta, OptionProps } from 'react-select';
import { ScaleLoader } from 'react-spinners';
import { TransporterLightModelExtended } from '../models/TransporterLightModelExtended';
import { DieselIndexTemplateLightModel } from '../services/dataContracts/queryStack/DieselIndexTemplateLightModel';
import { TransporterLightModel } from '../services/dataContracts/queryStack/TransporterLightModel';

interface TransportersProperties{
    selectedLogisticsUnitId: string,
    heightSeparator: number,
    loadingTransporters: boolean,
    showAllTransportLogisticsUnitsTransporters: boolean,
    transporters: Array<TransporterLightModelExtended>,
    selectedTemplate: DieselIndexTemplateLightModel,
    HandleChange: (selected: Array<TransporterLightModelExtended>) => void,
    HandleShowAllTransportLogisticsUnitsTransporters: () => void
}

const optionAll: TransporterLightModel = {
    transporterId: '*',
    transporterName: 'Sélectionner tout'
}

const Option = (props: OptionProps<TransporterLightModelExtended>) => {
    return (
      <div>
        <components.Option {...props} isDisabled={props.data.isDisabled}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.data.transporterName}</label>
        </components.Option>
      </div>
    );
};

export const TransportersComponent = (props: TransportersProperties) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [transportersSelected, setTransportersSelected] = useState<Array<TransporterLightModelExtended>>([]);

    useEffect(()=>{
        if(!props.loadingTransporters){
            setTransportersSelected([]);
        }
    }, [props.loadingTransporters]);

    const HandleInputChange = (query: string, inputActionMeta: InputActionMeta) : void => {
        if (inputActionMeta.action !== "set-value") {
            setInputValue(query);
        }
    }

    const HandleChange = (selected: any, event: ActionMeta<TransporterLightModelExtended>): void => {
        let result = [];

        if (selected !== null && selected.length > 0) {
            const transportersData = props.transporters.filter(t=>t.isDisabled == false);
            if (selected[selected.length - 1].transporterId === optionAll.transporterId) {
                if(event.action === "select-option"){
                    result = [optionAll, ...transportersData];
                }
            }
            else if (selected.length === transportersData.length) {
                const indexElem=selected.findIndex(x => x.transporterId === optionAll.transporterId);

                if (indexElem !== -1) {
                    result = selected.filter(
                        option => option.transporterId !== optionAll.transporterId
                    );
                } else if (event.action === "select-option") {
                    result = [optionAll, ...transportersData];
                }
            }
            else{
                result = selected;
            }
        }

        setTransportersSelected(result);
        props.HandleChange(result.filter(x => x.transporterId !== optionAll.transporterId));
    };

    return(
    <>
        <Box>
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={props.selectedLogisticsUnitId == ''}
                        checked={props.showAllTransportLogisticsUnitsTransporters}
                        onChange={() => props.HandleShowAllTransportLogisticsUnitsTransporters()}
                        color="default"
                    />
                }
                label="Afficher tous les transporteurs"
            />
        </Box>
        <Box width="80%">
        {props.loadingTransporters ? <div className='sweet-loading'>
            <ScaleLoader
                width={5}
                height={20}
                radius={50}
                color={'#000000'}
                loading={props.loadingTransporters}
            />
        </div>
        : <Select
            isMulti
            hideSelectedOptions={false}
            placeholder={<span>Rechercher...</span>}
            getOptionLabel={option => option.transporterName}
            getOptionValue={option => option.transporterId}
            value={transportersSelected}
            isClearable={false}
            controlShouldRenderValue={false}
            inputValue={inputValue}
            onInputChange={HandleInputChange}
            options={[{...optionAll, isDisabled: props.selectedTemplate === null ? true : false}, ...props.transporters]}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            noOptionsMessage={() => {
                return transportersSelected?.length > 0
                    ? ""
                    : "Pas de transporteurs disponibles.";
            }}
            styles={{
                menu: () => ({
                    height: (props.heightSeparator - 120)
                })
            }}
            components={{ Option }}
            onChange={(selected, event) => HandleChange(selected, event)}
            menuIsOpen={true}
            menuPlacement="auto"
            classNamePrefix="transporter"
            />}
        </Box>
    </>
    );
}