import { faCheck, faClock, faMoon, faSun, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn as Column, GridColumnReorderEvent, GridColumnResizeEvent, GridItemChangeEvent, GridRowProps, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import { EditableHeaderComponent } from 'src/shared/components/Common/EditableHeaderComponent';
import { AppModule, LocalStorage, SessionStorage } from '../../../utils/Storage';
import { PublishedCostsStatus } from '../models/PublishedCostsStatus';
import { PurchaseProvisioningDetailLightModelExtended } from '../models/PurchaseProvisioningDetailLightModelExtended';
import { BaseOfPurchaseCostIndexableCell } from './BaseOfPurchaseCostIndexableCell';

interface PurchaseProvisioningVehiclesSelectorProps {
    isRegularization: boolean,
    planningVehiclesList: Array<PurchaseProvisioningDetailLightModelExtended>,
    purchaseProvisioningIsFinished: boolean,
    rowRender: (trElement: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps) => React.ReactElement,
    cellRender: (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps) => React.ReactElement
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const PurchaseProvisioningVehiclesGridName = 'planningVehicles_byPurchaseProvisioning';
const ModuleKey = AppModule.PurchaseProvisionings;

export const PurchaseProvisioningVehiclesSelectorComponent = (props: PurchaseProvisioningVehiclesSelectorProps) => {

    const purchaseProvisioningsGridSortDescriptorsStorageKey = "purchase_provisionings_sortItem";
    const storedSortDescriptors = SessionStorage.GetItem(ModuleKey, purchaseProvisioningsGridSortDescriptorsStorageKey);
    const initialSort: SortDescriptor[] = storedSortDescriptors ? JSON.parse(storedSortDescriptors) : [{ field: 'planningDate', dir: 'desc' }];

    const [sort, setSort] = useState<Array<SortDescriptor>>(initialSort);

    const forceUpdate = useForceUpdate();

    const getPlanningVehiclesGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, PurchaseProvisioningVehiclesGridName, fieldName, defaultIndex);
    }

    const getPlanningVehiclesGridWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, PurchaseProvisioningVehiclesGridName, fieldName, defaultWidth);
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);
        
        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            PurchaseProvisioningVehiclesGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, PurchaseProvisioningVehiclesGridName, event.columns);
        forceUpdate();
    }

    const itemChange = (event: GridItemChangeEvent): void => {
        event.dataItem[event.field] = event.value;
        forceUpdate();
    }

    const { planningVehiclesList, purchaseProvisioningIsFinished, cellRender, rowRender } = props;

    return (
        <Box className="vehicles-by-provisioning">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Grid
                        className="grid"
                        data={orderBy(planningVehiclesList, sort)}
                        sortable={true}
                        cellRender={cellRender}
                        rowRender={rowRender}
                        onItemChange={itemChange}
                        reorderable
                        resizable
                        editField="inEdit"
                        sort={sort}
                        onColumnResize={onResizeHandler}
                        onColumnReorder={(e) => onReorderHandler(e)}
                        onSortChange={handleSortChange}
                        rowHeight={40}
                    >
                        <Column field="empty" orderIndex={0} title=" " width={10} resizable={false} reorderable={false} />
                        <Column field="isNightWork"
                            cell={(p: GridCellProps) => {
                                return <td>
                                    {p.dataItem.isNightWork == true ? (
                                        <FontAwesomeIcon size="lg" icon={faMoon} className="icon-work" />
                                    ) :
                                        <FontAwesomeIcon size="lg" icon={faSun} className="icon-work" />
                                    }
                                </td>
                            }}
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("isNightWork", 1)} title=" " editable={false} width={getPlanningVehiclesGridWidth("isNightWork", 35)} />
                        <Column field="vehicleLicencePlate" orderIndex={getPlanningVehiclesGridOrderIndexColumn("vehicleLicencePlate", 2)} width={getPlanningVehiclesGridWidth("vehicleLicencePlate", 100)} title="Camion" editable={false} />
                        <Column field="vehicleNumber" orderIndex={getPlanningVehiclesGridOrderIndexColumn("vehicleNumber", 3)} width={getPlanningVehiclesGridWidth("vehicleNumber", 50)} title="N°" editable={false} />
                        <Column field="vehicleEquipmentId" orderIndex={getPlanningVehiclesGridOrderIndexColumn("vehicleEquipmentId", 4)} width={getPlanningVehiclesGridWidth("vehicleEquipmentId", 80)} title="IDGM" editable={false} />
                        <Column field="expectedVehicleTypeLabel" orderIndex={getPlanningVehiclesGridOrderIndexColumn("expectedVehicleTypeLabel", 5)} width={getPlanningVehiclesGridWidth("expectedVehicleTypeLabel", 120)} title="Type véhicule" editable={false} />
                        <Column field="planningDate" orderIndex={getPlanningVehiclesGridOrderIndexColumn("planningDate", 6)} width={getPlanningVehiclesGridWidth("planningDate", 120)} title="Date de livraison" format="{0:dd/MM/yyyy}" editable={false} />
                        <Column field="costsRemarksOfLogistician"
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("costsRemarksOfLogistician", 7)} width={getPlanningVehiclesGridWidth("costsRemarksOfLogistician", 160)} title="Commentaire préfac" editable={true} />
                        <Column field="publishedCostsStatus" orderIndex={getPlanningVehiclesGridOrderIndexColumn("publishedCostsStatus", 8)}
                            width={getPlanningVehiclesGridWidth("publishedCostsStatus", 100)} title="Préfac" editable={false} headerClassName="preInvoice-header"
                            cell={(p: GridCellProps) => {
                                const dataItem: PurchaseProvisioningDetailLightModelExtended = p.dataItem;
                                return (
                                    <td className="preInvoice-icon">
                                        {dataItem.publishedCostsStatus === PublishedCostsStatus.None ? (
                                            <hr className="line-hr" />)
                                            : (dataItem.publishedCostsStatus === PublishedCostsStatus.Disagreed ?
                                                <Tooltip title={dataItem.costsDisagreementReason ? dataItem.costsDisagreementReason : ''}>
                                                    <div>
                                                        <FontAwesomeIcon size="lg" icon={faTimes} className="icon-error" />
                                                    </div>
                                                </Tooltip>
                                                : dataItem.publishedCostsStatus === PublishedCostsStatus.Agreed ?
                                                    <div><FontAwesomeIcon size="lg" icon={faCheck} className="icon-success" /> </div>
                                                    : <div><FontAwesomeIcon size="lg" icon={faClock} className="icon-pending" /></div>)
                                        }
                                    </td>)
                            }} />
                        <Column field="provisionedPurchaseCost"
                            cell={(p: GridCellProps) => {
                                const dataItem: PurchaseProvisioningDetailLightModelExtended = p.dataItem;
                                const hasDiffFromPurchaseProvisioningCostAndVehicleExistedAndNotRegularization = !props.isRegularization && dataItem.existingPlanningVehicle && dataItem.hasDiffFromPurchaseProvisioningCost;

                                return <td>
                                    <div className={`${hasDiffFromPurchaseProvisioningCostAndVehicleExistedAndNotRegularization ? 'field-different-value-purchaseProvisioning' : ''}`}>
                                        <Tooltip title={`${hasDiffFromPurchaseProvisioningCostAndVehicleExistedAndNotRegularization ? (dataItem.actualPurchaseCost?.toCurrencyString() ?? '') : ''}`}>
                                            <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" className={!dataItem.existingPlanningVehicle ? "vehicle-removed" : ""}>
                                                {dataItem.provisionedPurchaseCost?.toCurrencyString() ?? ''}
                                            </Box>
                                        </Tooltip>
                                    </div>
                                </td>
                            }}
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("provisionedPurchaseCost", 9)} width={getPlanningVehiclesGridWidth("provisionedPurchaseCost", 120)} title="Coût" editable={false} />
                        <Column 
                            field="baseOfPurchaseCostIndexableWithGasoilIndex"
                            headerCell={() => purchaseProvisioningIsFinished 
                                ? <span>Coût à indexer</span> 
                                : <EditableHeaderComponent field="baseOfPurchaseCostIndexableWithGasoilIndex" title="Coût à indexer" />}
                            editable={!purchaseProvisioningIsFinished}
                            cell={(p: GridCellProps) => <BaseOfPurchaseCostIndexableCell {...p} purchaseProvisioningIsFinished={purchaseProvisioningIsFinished} 
                                width={getPlanningVehiclesGridWidth("baseOfPurchaseCostIndexableWithGasoilIndex", 200)} />}
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("baseOfPurchaseCostIndexableWithGasoilIndex", 10)} />
                        <Column field="provisionedFees"
                            cell={(p: GridCellProps) => {
                                const dataItem: PurchaseProvisioningDetailLightModelExtended = p.dataItem;
                                const hasDiffFromPurchaseProvisioningFeesAndVehicleExistedAndNotRegularization = !props.isRegularization && dataItem.existingPlanningVehicle && dataItem.hasDiffFromPurchaseProvisioningFees;

                                return <td>
                                    <div className={`${hasDiffFromPurchaseProvisioningFeesAndVehicleExistedAndNotRegularization ? 'field-different-value-purchaseProvisioning' : ''}`}>
                                        <Tooltip title={`${hasDiffFromPurchaseProvisioningFeesAndVehicleExistedAndNotRegularization ? (dataItem.actualFees?.toCurrencyString() ?? '') : ''}`}>
                                            <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" className={!dataItem.existingPlanningVehicle ? "vehicle-removed" : ""}>
                                                {dataItem.provisionedFees?.toCurrencyString() ?? ''}
                                            </Box>
                                        </Tooltip>
                                    </div>
                                </td>
                            }}
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("fees", 11)} width={getPlanningVehiclesGridWidth("provisionedFees", 120)} title="Frais" editable={false} />
                        {
                            props.isRegularization &&
                            <Column field="regularizationRemarks" orderIndex={getPlanningVehiclesGridOrderIndexColumn("regularizationRemarks", 12)}
                                width={getPlanningVehiclesGridWidth("regularizationRemarks", 200)} title="Commentaire regul." editable={false} />
                        }
                        <Column field="contractualPurchasePriceContractNumber" orderIndex={getPlanningVehiclesGridOrderIndexColumn("contractualPurchasePriceContractNumber", props.isRegularization ? 13 : 12)} width={getPlanningVehiclesGridWidth("contractualPurchasePriceContractNumber", 60)} title="PAC" editable={false} />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}
