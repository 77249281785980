import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { IntlProvider, loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { UploadFileInfo, UploadOnAddEvent, UploadOnBeforeUploadEvent, UploadOnStatusChangeEvent } from "@progress/kendo-react-upload";
import React from "react";
import { SettingsProvider } from "../../../SettingsProvider";
import ToastService from "../../../ToastService";
import { UploadComponent } from "../../components/Common/UploadComponent";
import { UserSignatureAttachment } from "../models/UserSignatureAttachment";
import { UserSignatureApiClient } from "../services/UserSignatureApiClient";

loadMessages(
    {
        upload: {
            select: "Déposer une image"
        },
    },
    "uploadMessage"
);

interface UserSignatureLogoChoiceComponentProps {
    attachmentIdChoice: string,
    selectedUserAttachment: UserSignatureAttachment,
    defaultImages: { id: string, html: string }[],
    handleSelectedAttachmentChoiceChanged: (e: React.ChangeEvent<HTMLInputElement>) => void,
    handlerBeforeUpload: (event: UploadOnBeforeUploadEvent) => void,
    onUploadStatusChange: (event: UploadOnStatusChangeEvent) => void,
    handleDeleteAttachment: (attachmentId: string) => Promise<void>
}

export const UserSignatureLogoChoiceComponent = (props: UserSignatureLogoChoiceComponentProps): JSX.Element => {

    const config = SettingsProvider.Get();

    const { attachmentIdChoice, selectedUserAttachment, defaultImages } = props;

    const handleAddAttachments = (e: UploadOnAddEvent): void => {
        e.affectedFiles.forEach((file: UploadFileInfo) => {
            if (file.size > 10000000) {
                ToastService.showErrorToast("Les fichiers déposés ne doivent pas dépasser la taille de 10 Mo");
                return;
            }
        });
    }

    return (
        <RadioGroup
            name="logos-radio-buttons"
            value={attachmentIdChoice}
            row
            className="radio-group"
            onChange={(e) => props.handleSelectedAttachmentChoiceChanged(e)}
        >
            <FormControlLabel className="logo-radio-button" value="-1" control={<Radio />} label="Aucun" labelPlacement="bottom" />
            <FormControlLabel className="logo-radio-button" value={selectedUserAttachment?.attachmentId ?? "0"} control={<Radio />}
                label={
                    <LocalizationProvider language="uploadMessage">
                        <IntlProvider locale="fr">
                            <div className="upload-attachment">
                                <UploadComponent
                                    batch={true}
                                    multiple={false}
                                    showFileList={false}
                                    onBeforeUpload={props.handlerBeforeUpload}
                                    onStatusChange={props.onUploadStatusChange}
                                    onAdd={handleAddAttachments}
                                    defaultFiles={[]}
                                    withCredentials={false}
                                    saveUrl={UserSignatureApiClient.GetAttachmentsSaveUrl()}
                                    restrictions={{
                                        maxFileSize: config.maxAttachmentSize,
                                        allowedExtensions: ['JPG', 'BMP','PNG','svg']
                                    }}
                                />
                                {selectedUserAttachment &&
                                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                        <div className="attachment-label">{selectedUserAttachment.label}</div>
                                        <FontAwesomeIcon icon={faTimes} color="red" cursor="pointer" onClick={() => props.handleDeleteAttachment(selectedUserAttachment.attachmentId)} />
                                    </Box>
                                }
                            </div>
                        </IntlProvider>
                    </LocalizationProvider>
                }
                labelPlacement="bottom"
            />
            <Box display="flex" flexDirection="row" className="default-images">
                {defaultImages.map((image, index) => {
                    return (
                        <FormControlLabel className="logo-radio-button"
                            key={`logo-${index}`}
                            value={image.id}
                            control={<Radio />}
                            label={<div dangerouslySetInnerHTML={{ __html: image.html }}></div>}
                            labelPlacement="bottom"
                        />
                    )
                })}
            </Box>
        </RadioGroup>
    );
}