import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Input, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import { CalendarComponent } from '../../../../shared/components/Common/CalendarComponent';
import { DateRange } from '../../../../shared/models/DateRange';

interface HeaderComponentProps {
    beneficiaryName: string,
    date: DateRange,
    handleChangeDateRange: (date: DateRange) => void,
    inputSearchVehiclesValue: string,
    inputSearchVehiclesRef: React.RefObject<HTMLInputElement>,
    handleVehicleSearchKeyPress: (text: string) => void,
    handleClearSearchText: () => void,
}

export const HeaderComponent = (props: HeaderComponentProps): JSX.Element => {
    const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);

    const handleIsDateRangeValidChanged = (isValid: boolean): void => {
        setIsDateRangeValid(isValid);
    }
    
    return (
        <>
            <Box className="beneficiary" display="flex" alignItems="center">
                {props.beneficiaryName}
            </Box>
            <Box display="flex" flexDirection="row">
                <Box className="calendar">
                    {props.date &&
                        <CalendarComponent calendarId="beneficiary-invoices-details-daterange-picker"
                            startDate={props.date.start}
                            endDate={props.date.end}
                            maximumNumberOfMonths={2}
                            isValid={isDateRangeValid}
                            handleIsValidChanged={handleIsDateRangeValidChanged}
                            handlerFromChildToParent={props.handleChangeDateRange}
                        />
                    }
                </Box>
                <Box display="flex" alignItems="center" className="input-search">
                    <Input disableUnderline className={props.inputSearchVehiclesValue.length > 2 ? 'search-text-active' : ''} inputRef={props.inputSearchVehiclesRef}
                        startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => props.handleClearSearchText()} /></InputAdornment>}
                        placeholder="Rechercher sur l'immatriculation, le site, l'adresse..." onChange={(event) => props.handleVehicleSearchKeyPress(event.target.value)} />
                </Box>
            </Box>
        </>
    );

}
