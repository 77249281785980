import { Box, Button, Checkbox, Radio } from '@mui/material';
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import { ProductionSiteCandidateToAdd } from '../../services/dataContracts/controller/ProductionSiteCandidateToAdd';
import { ProductionSitesReferentialApiClient } from '../../services/ProductionSitesReferentialApiClient';

interface SelectProductionSiteStepComponentProperties {
    productionSiteChoices: ProductionSiteCandidateToAdd,
    handleSearchInReferentialGrid: (productionSiteId: string) => void,
    confirmAddProductionSite: (isLogisticZone: boolean, productionSite: ProductionSiteCandidateToAdd) => void,
    handlePreviousStep: () => void,
    role: string
}

export const SelectProductionSiteStepComponent = (props: SelectProductionSiteStepComponentProperties): JSX.Element => {

    const [productionSiteIsLogisticZone, setProductionSiteIsLogisticZone] = useState<boolean>(false);
    const handleClikAddProductionSite = (): void => {
        ProductionSitesReferentialApiClient.ProductionSiteExists(props.productionSiteChoices.productionSiteId)
            .then((res) => {
                if (res.data === true)
                    props.handleSearchInReferentialGrid(props.productionSiteChoices.productionSiteId);
                else
                    props.confirmAddProductionSite(productionSiteIsLogisticZone, props.productionSiteChoices);
            });
    }

    useEffect(() => {
        props.productionSiteChoices.productionSiteIsLogisticZone = productionSiteIsLogisticZone;
    }, [productionSiteIsLogisticZone]);

    const handleCheckBoxProductionSiteIsLogisticZoneClick = (): void => {
        setProductionSiteIsLogisticZone(!productionSiteIsLogisticZone);
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box className="production-site-choices">
                <LocalizationProvider language="fr-FR">
                    <IntlProvider locale="fr" >
                        <Grid
                            data={props.productionSiteChoices ? [props.productionSiteChoices] : []}
                            className="production-site-choices-grid"
                            rowHeight={40}
                        >
                            <GridColumn field="selected" title="Sélection" width={60}
                                cell={(props: GridCellProps) => <td>
                                    <Radio
                                        checked={true}
                                        color="default"
                                    />
                                </td>}
                            />
                            <GridColumn field="productionSiteId" title="Id Unité de Production" width={150} />
                            <GridColumn field="label" title="Libellé Unité de Production" width={350} />
                        </Grid>
                    </IntlProvider>
                    {props.productionSiteChoices.haveOneActivityST && props.role == 'ADM' &&
                        <div className='production-site-activity-st'>
                            <Checkbox checked={productionSiteIsLogisticZone} onClick={handleCheckBoxProductionSiteIsLogisticZoneClick} /> De type &laquo; Zone Logistique &raquo;
                        </div>
                    }

                </LocalizationProvider>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                <Button variant="contained" title="Retour" className="previous-button" onClick={props.handlePreviousStep}>
                    Retour
                </Button>
                <Button variant="contained" color="primary" title="Ajouter" onClick={handleClikAddProductionSite} >
                    Confirmer
                </Button>
            </Box>
        </Box>
    );
}
