import { Box, Button, Input } from '@mui/material';
import React, { useState } from 'react';
import Select from 'react-select';
import { VehicleTypeModel } from '../models/VehicleTypeModel';
import { InternalVehicleCandidateToAdd } from '../services/dataContracts/controller/InternalVehicleCandidateToAdd';
import { VehicleTypeLightModel } from '../services/dataContracts/queryStack/VehicleTypeLightModel';
import { InternalVehiclesReferentialApiClient } from '../services/InternalVehiclesReferentialApiClient';

interface AddInternalVehicleComponentProps {
    vehicleTypes: Array<VehicleTypeLightModel>,
    handleSearchInReferentialGrid: (liencePlate: string) => void,
    handleAddInternalVehicle: (internalVehicleCandidateToAdd: InternalVehicleCandidateToAdd, vehicleType: VehicleTypeModel) => void,
    dislayNotFoundMessage: () => void
}

export const AddInternalVehicleComponent = (props: AddInternalVehicleComponentProps): JSX.Element => {

    const [licencePlate, setLicencePlate] = useState<string>('');
    const [isLicencePlateValid, setIsLicencePlateValid] = useState<boolean>(false);
    const [vehicleType, setVehicleType] = useState<VehicleTypeModel>(null);
    const vehicleTypes = props.vehicleTypes.map((t: VehicleTypeLightModel) => ({
        value: t.vehicleTypeId,
        label: t.label,
        loadCapacity: t.loadCapacity
    }));

    const handleVehicleTypeChange = (event: VehicleTypeModel): void => {
        setVehicleType(event);
    }

    const handleChangeLicencePlate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const value = e.target.value;
        setLicencePlate(value);
        isValidLicencePlate(value) ? setIsLicencePlateValid(true) : setIsLicencePlateValid(false);
    }

    const isValidLicencePlate = (value: string): boolean => {
        const reg_licence_plate = '^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$';
        const second_reg_licence_plate = '^([0-9]{1}|[0-9]{2}|[0-9]{3}|[0-9]{4})([a-zA-Z]{1}|[a-zA-Z]{2}|[a-zA-Z]{3})([0-9]{1}|[0-9]{2}|[0-9]{3}|2A|2B)$';

        if (value.match(reg_licence_plate) || value.match(second_reg_licence_plate))
            return true;

        return false;
    }

    const handleClickConfirmAddInternalVehicle = (): void => {
        const licencePlateValue = licencePlate.trim();
        InternalVehiclesReferentialApiClient.SearchVehicle(licencePlateValue)
            .then((res) => {
                const internalVehicleCandidateToAdd: InternalVehicleCandidateToAdd = res.data;
                if (internalVehicleCandidateToAdd) {
                    switch (internalVehicleCandidateToAdd.existInLorieDb) {
                        case true:
                            props.handleSearchInReferentialGrid(internalVehicleCandidateToAdd.licencePlate);
                            return;

                        case false:
                            props.handleAddInternalVehicle(internalVehicleCandidateToAdd, vehicleType);
                            return;
                        default:
                            return;
                    }
                } else {
                    props.dislayNotFoundMessage();
                }
            });
    }

    const isConfirmButtonEnabled = isLicencePlateValid && vehicleType;

    return (
        <Box className="add-internal-vehicle-content">
            <Box className="vehicle-text-info">Information du camion Colas à ajouter:</Box>
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column" width="80%" mb={"10px"}>
                    <Box className="input-label">Plaque d'immatriculation</Box>
                    <Box display="flex" flexDirection="row">
                        <Input
                            id="licence-plate"
                            value={licencePlate}
                            className="input-text"
                            onChange={(e) => handleChangeLicencePlate(e)}
                        />
                    </Box>
                </Box>
                <Box className="info-licence-plate">La plaque d'immatriculation ne doit pas contenir des espaces et des tirets</Box>
                <Box display="flex" flexDirection="column" width="80%" mb={"10px"}>
                    <Box className="input-label">Type de véhicule</Box>
                    <Box display="flex" flexDirection="row">
                        <Select
                            className="vehicle-type-list"
                            id="vehicle-type"
                            placeholder=""
                            menuPosition="fixed"
                            menuShouldBlockScroll={true}
                            isClearable
                            options={vehicleTypes}
                            value={vehicleType}
                            onChange={(e) => handleVehicleTypeChange(e)}
                        />
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" mt="20px">
                    <Button variant="contained" color="primary" title="Confirmer" disabled={!isConfirmButtonEnabled} onClick={() => handleClickConfirmAddInternalVehicle()}>
                        Confirmer
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
