import { Box, Button, Tooltip } from "@mui/material";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { uniq } from 'lodash';
import React from "react";
import { NewProvisioningModel } from "../models/NewProvisioningModel";
import { PlanningVehicleLightModelExtended } from "../models/PlanningVehicleLightModelExtended";
import { ProvisioningGenerationDetailRequestArgs } from "../services/dataContracts/controller/ProvisioningGenerationDetailRequestArgs";

interface NewProvisioningProps {
    planningVehiclesSelected: Array<PlanningVehicleLightModelExtended>,
    vehicleTypeGroups: Array<string>,
    purchaseProvisioningMaxAllowedCostLimit: number,
    handleGenerateRegularizations: () => void,
    handleGenerateProvisioning: (transporterId: string, transporterName: string, buyerLogisticsAgencyId: string, buyerLogisticsBusinessUnitId: string,
        supplierMdmIdentifier: string, details: Array<ProvisioningGenerationDetailRequestArgs>, purchaseCost?: number, fees?: number, gasoilIndexCost?: number) => void
}

export const NewProvisioningComponent = (props: NewProvisioningProps) => {
    const { planningVehiclesSelected, vehicleTypeGroups, purchaseProvisioningMaxAllowedCostLimit } = props;

    const calculateNewProvisioning = (): Array<NewProvisioningModel> => {
        const res: Array<NewProvisioningModel> = [];
        vehicleTypeGroups.forEach(vehicleTypeGroupId => {
            const planningVehicleByCurrentVehicleTypeGroup = planningVehiclesSelected.filter(x => x.expectedVehicleTypeGroupId == vehicleTypeGroupId);
            const provisioningVehicleTypeGroup = calculateProvisioningByVehicleTypeGroup(planningVehicleByCurrentVehicleTypeGroup, vehicleTypeGroupId);

            res.push(provisioningVehicleTypeGroup);
        });

        return res;
    }

    const calculateProvisioningByVehicleTypeGroup = (planningVehicles: Array<PlanningVehicleLightModelExtended>, vehicleTypeGroupId: string): NewProvisioningModel => {
        const sumPurchaseCost = sumProvisioning(planningVehicles.filter(x => x.purchaseCostEnabled), "purchaseCost");
        const sumFees = sumProvisioning(planningVehicles.filter(x => x.feesEnabled), "fees");
        const sumGasoilIndexCost = sumProvisioning(planningVehicles.filter(x => x.purchaseCostEnabled && x.gasoilIndexCost), "gasoilIndexCost");

        return {
            vehicleTypeGroupId: vehicleTypeGroupId,
            purchaseCost: sumPurchaseCost,
            fees: sumFees,
            total: (sumFees ?? 0) + (sumPurchaseCost ?? 0),
            gasoilIndexCost: sumGasoilIndexCost
        };
    }

    const sumProvisioning = (items: Array<PlanningVehicleLightModelExtended | NewProvisioningModel>, prop: string) => {
        return items.reduce(function (a, b) {
            return a + b[prop];
        }, 0);
    }

    const data = calculateNewProvisioning();
    const totalPurchaseCosts = sumProvisioning(data, "purchaseCost");
    const totalFees = sumProvisioning(data, "fees");
    const totalGasoilIndexCost = sumProvisioning(data, "gasoilIndexCost");
    const sumTotal = sumProvisioning(data, "total");

    const distinctTransporters: Array<string> = uniq(planningVehiclesSelected.map(x => x.transporterId));
    const isTotalLessThanPurchaseProvisioningMaxAllowedCostLimit = (sumTotal + totalGasoilIndexCost) < purchaseProvisioningMaxAllowedCostLimit;
    const isInternal = distinctTransporters?.length == 1 ? (planningVehiclesSelected[0].supplierMdmIdentifier === null ? true : false) : false;

    const hasAnyPlanningVehiclesNotLotisables = planningVehiclesSelected.some(p => (!p.fees || (p.fees && !p.feesEnabled)) && (!p.purchaseCost || (p.purchaseCost && !p.purchaseCostEnabled)));

    const enabledBtnNewProvisioning = (isInternal ? (sumTotal && distinctTransporters?.length == 1) : (sumTotal && isTotalLessThanPurchaseProvisioningMaxAllowedCostLimit && distinctTransporters?.length == 1)) && !hasAnyPlanningVehiclesNotLotisables;

    const enabledBtnRegularizationProvisioning = planningVehiclesSelected.some(p => ((!p.isInternal && p.costPurchaseProvisioningSentSuccessfully) || (p.isInternal && p.costPurchaseProvisioningBusinessId))
        || ((!p.isInternal && p.feesPurchaseProvisioningSentSuccessfully) || (p.isInternal && p.feesPurchaseProvisioningBusinessId)));

    const handlePurchaseProvisioningGeneration = () => {
        const details: Array<ProvisioningGenerationDetailRequestArgs> = planningVehiclesSelected.map(x => {
            return {
                planningVehicleId: x.planningVehicleId,
                planningVehicleNumber: x.planningVehicleNumber,
                planningDate: x.planningDate,
                planningDate_AsNumeric: x.planningDateAsNumeric,
                expectedVehicleTypeId: x.expectedVehicleTypeId,
                expectedVehicleTypeLabel: x.expectedVehicleTypeLabel,
                expectedVehicleTypeGroupId: x.expectedVehicleTypeGroupId,
                vehicleId: x.vehicleId,
                vehicleLicencePlate: x.vehicleLicencePlate,
                vehicleEquipmentId: x.vehicleEquipmentId,
                isNightWork: x.isNightWork,
                transporterOrderId: x.transporterOrderId,
                transporterOrderReference: x.transporterOrderReference,
                orderSentDate: x.orderSentDate,
                orderConfirmationDate: x.orderConfirmationDate,
                costsArePublished: x.costsArePublished,
                costsAreAgreed: x.costsAreAgreed,
                costsDisagreementReason: x.costsDisagreementReason,
                costsRemarksOfLogistician: x.costsRemarksOfLogistician,
                purchaseCost: (x.purchaseCost && x.purchaseCostEnabled) ? x.purchaseCost : null,
                fees: (x.fees && x.feesEnabled) ? x.fees : null,
                gasoilIndexCost: x.gasoilIndexCost,
                applicableGasoilIndexValue: x.applicableGasoilIndexValue,
                planningVehicleRemarks: x.planningVehicleRemarks,
                contractualPurchasePriceId: x.contractualPurchasePriceId,
                contractualPurchasePriceContractNumber: x.contractualPurchasePriceContractNumber
            };
        });

        props.handleGenerateProvisioning(distinctTransporters[0], planningVehiclesSelected[0].transporterName, planningVehiclesSelected[0].buyerLogisticsAgencyId,
            planningVehiclesSelected[0].buyerLogisticsBusinessUnitId, planningVehiclesSelected[0].supplierMdmIdentifier, details, totalPurchaseCosts, totalFees, totalGasoilIndexCost);
    }

    return (<Box display="flex" flexDirection="column" className="component">
        <Box display="flex" pt={2} flexDirection="column" flex="nowrap" className="container-grid" height="85%" minHeight="375px" id="container">
            <Box display="flex" flexDirection="column">
                <LocalizationProvider language="fr-FR">
                    <IntlProvider locale="fr">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="title center-content">Coûts €</th>
                                    <th className="title center-content">Frais €</th>
                                    <th className="title center-content">Total €</th>
                                    <th className="title center-content">Gazole €</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((x: NewProvisioningModel, index: number) => {
                                    return (
                                        <tr key={`vehicle-type-group-${index}`}>
                                            <td className="title left-content">{x.vehicleTypeGroupId ? `${x.vehicleTypeGroupId}` : ''}</td>
                                            <td className="value center-content">{x.purchaseCost ? `${x.purchaseCost.toCurrencyString()}` : ''}</td>
                                            <td className="value center-content">{x.fees ? `${x.fees.toCurrencyString()}` : ''}</td>
                                            <td className="value center-content">{x.total ? `${x.total.toCurrencyString()}` : ''}</td>
                                            <td className="value center-content">{x.gasoilIndexCost ? `${x.gasoilIndexCost.toCurrencyString()}` : ''}</td>
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td className="title left-content">Total</td>
                                    <td className="value center-content">{`${totalPurchaseCosts ? Number(totalPurchaseCosts.toFixed(2)).toCurrencyString() : ''}`}</td>
                                    <td className="value center-content">{`${totalFees ? Number(totalFees.toFixed(2)).toCurrencyString() : ''}`}</td>
                                    <td className="value center-content">{`${sumTotal ? Number(sumTotal.toFixed(2)).toCurrencyString() : ''}`}</td>
                                    <td className="value center-content">{`${totalGasoilIndexCost ? Number(totalGasoilIndexCost.toFixed(2)).toCurrencyString() : ''}`}</td>
                                </tr>
                            </tbody>
                        </table>
                    </IntlProvider>
                </LocalizationProvider>
                {distinctTransporters.length > 1 && <Box pt={2} className="error-message">
                    Les lignes du TRANSPORT ou GAZOLE doivent appartenir à un seul transporteur
                </Box>
                }

                {!sumTotal && <Box pt={1} className="error-message">
                    Le lot à générer a un TOTAL nul
                </Box>
                }
                {((sumTotal + totalGasoilIndexCost) >= purchaseProvisioningMaxAllowedCostLimit) && !isInternal && <Box pt={1} className="error-message">
                    La somme des lignes "Transport" et "Gazole" ne doit pas être supérieure ou égale à {purchaseProvisioningMaxAllowedCostLimit.toCurrencyString()}
                </Box>
                }
                {hasAnyPlanningVehiclesNotLotisables && <Box pt={1} className="error-message">
                    Pour un même coût/frais, un camion ne peut pas être associé à plusieurs lots
                </Box>
                }
            </Box>
        </Box>
        <Box pt={1} height="15%" width="100%" minHeight="40px" display="flex" flexDirection="column" justifyContent="flex-end">
            <Button variant="contained" className="new-provisioning-btn" onClick={handlePurchaseProvisioningGeneration} disabled={!enabledBtnNewProvisioning}>
                Générer un lot de provision
            </Button>
            {
                enabledBtnRegularizationProvisioning ? (
                    <Button variant="contained" className="regularization-provisioning-btn" onClick={props.handleGenerateRegularizations}>
                        Générer un lot de régularisation
                    </Button>
                ) : (
                    <Tooltip title={<>
                        <div>Camion externe : "Pour une régularisation, sélectionnez une ligne associée à un lot intégré dans Zéphyr".</div>
                        <div>Camion interne : "Pour une régularisation, sélectionnez une ligne associée à un lot"</div></>}>
                        <div className="regularization-provisioning-btn-disabled">
                            <Button variant="contained" disabled={true}>
                                Générer un lot de régularisation
                            </Button>
                        </div>
                    </Tooltip>
                )
            }
        </Box>
    </Box>);
}