import { Box, ListItem, ListItemText } from '@mui/material';
import React from 'react';
import { TransporterLightModel } from '../services/dataContracts/queryStack/TransporterLightModel';
import { VehicleReservationDetailLightModel } from '../services/dataContracts/queryStack/VehicleReservationDetailLightModel';

interface TransporterListComponentProps {
    transportersList: Array<TransporterLightModel>,
    selectedTransporter: string,
    onSelectTransporterChange: (transporterId: string) => void,
    weeklyTransporterVehicleReservationsDetailsArray: Array<VehicleReservationDetailLightModel>,
    firstDayInSelectedWeek: Date,
    isNightFilterSelected: boolean
}

export const TransporterListComponent = (props: TransporterListComponentProps): JSX.Element => {

    const { transportersList, selectedTransporter, onSelectTransporterChange, weeklyTransporterVehicleReservationsDetailsArray } = props;

    return (
        <Box className="transporter-list-table">
            <ListItem divider className="table-header-cell fixed-head">
                <ListItemText primary="Tous les transporteurs" />
            </ListItem>
            {transportersList.map((transporter: TransporterLightModel, index: number) => {
                let reservedNumberOfVehicles = 0;
                let transporterConfirmMailDone = true;
                weeklyTransporterVehicleReservationsDetailsArray.filter(x => x.transporterId === transporter.transporterId).forEach(function (element) {
                    reservedNumberOfVehicles += element.reservedNumberOfVehicles;
                    if (element.confirmedDate === null) {
                        transporterConfirmMailDone = false;
                    }
                });
                return (
                    <ListItem key={index} divider
                        onClick={() => onSelectTransporterChange(transporter.transporterId)}
                        className={selectedTransporter === transporter.transporterId ? 'transporter-selected-item' : ''}>
                        <ListItemText primary={
                            <Box display="flex" flexWrap="nowrap" flexDirection="row">
                                <Box className={transporterConfirmMailDone ? '' : 'differentiated-transporter'} flexGrow={1}>{transporter.transporterName}</Box>
                                <Box className="bold blue-label">{Number(reservedNumberOfVehicles)}</Box>
                            </Box>
                        } />
                    </ListItem>
                );
            })}
        </Box>
    );
}