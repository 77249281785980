import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Drawer, FormControlLabel, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { load, loadMessages } from '@progress/kendo-react-intl';
import '@progress/kendo-theme-material/dist/all.css';
import clsx from 'clsx';
import { each, groupBy, isEqual, map, sortBy, uniq } from 'lodash';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ScaleLoader } from 'react-spinners';
import ToastService from 'src/ToastService';
import { SettingsProvider } from '../../../SettingsProvider';
import SimpleDialog from '../../../shared/components/Common/SimpleDialog';
import { WebAppActionResult, WebAppActionResultEx } from '../../../shared/models/WebAppActionResult';
import BusinessErrors from '../../../utils/BusinessErrors';
import '../../../utils/Date';
import { AppModule, SessionStorage } from '../../../utils/Storage';
import '../../../utils/String';
import Utilities from '../../../utils/Utilities';
import { RouteComponentProps, withRouter } from '../../../withRouter';
import { ThirdPartyTransporterPrintData, ThirdPartyTransporterPrintDataProperty } from '../ThirdPartyTransporterPrint/models/TransporterOrderPrintData';
import AddOrUpdateDriverComponent from './components/AddOrUpdateDriverComponent';
import AddOrUpdateVehicleComponent from './components/AddOrUpdateVehicleComponent';
import DriversManagement from './components/DriversManagementComponent';
import { HeaderComponent } from './components/HeaderComponent';
import { OtherTransportersUsingDriversChoicesManagementComponent } from './components/OtherTransportersUsingDriversChoicesManagementComponent';
import { TransporterCardVehicleComponent } from './components/TransporterCardVehicleComponent';
import VehiclesManagementComponent from './components/VehiclesManagementComponent';
import { MapsPanelModel } from './models/MapsPanelModel';
import { SelectOptionModel } from './models/SelectOptionModel';
import { TransporterOrderLineSeenByTransporterGroupedByLogisticsUnitModel } from './models/TransporterOrderLineSeenByTransporterGroupedByLogisticsUnitModel';
import { LabeledTripNumber, TransporterOrderLineSeenByTransporterLightModelExtended } from './models/TransporterOrderLineSeenByTransporterLightModelExtended';
import { TransporterSelectModel } from './models/TransporterSelectModel';
import { GdprAcceptancyResult } from './services/dataContracts/controller/GdprAcceptancyResult';
import { OrderLineRequestArgs } from './services/dataContracts/controller/OrderLineRequestArgs';
import { OrderRequestArgs } from './services/dataContracts/controller/OrderRequestArgs';
import { DriverLightModel } from './services/dataContracts/queryStack/DriverLightModel';
import { TransporterLightModel } from './services/dataContracts/queryStack/TransporterLightModel';
import { TransporterOrderLineDetailSeenByTransporterLightModel } from './services/dataContracts/queryStack/TransporterOrderLineDetailSeenByTransporterLightModel';
import { TransporterOrderLineSeenByTransporterLightModel } from './services/dataContracts/queryStack/TransporterOrderLineSeenByTransporterLightModel';
import { TransporterOrdersSeenByTransporterLightModel } from './services/dataContracts/queryStack/TransporterOrdersSeenByTransporterLightModel';
import { VehicleLightModel } from './services/dataContracts/queryStack/VehicleLightModel';
import { ThirdPartyTransporterApiClient } from './services/ThirdPartyTransporterApiClient';
import './ThirdPartyTransporterStyles.scss';

const weekData = require('cldr-core/supplemental/weekData.json');
const caGregorian = require('cldr-dates-full/main/fr/ca-gregorian.json');
const dateFields = require('cldr-dates-full/main/fr/dateFields.json');
const timeZoneNames = require('cldr-dates-full/main/fr/timeZoneNames.json');
const numbers = require('cldr-numbers-full/main/fr/numbers.json');
const esMessages = require('../../../config/fr.json');
loadMessages(esMessages, 'fr-FR');

load(
    weekData,
    caGregorian,
    dateFields,
    timeZoneNames,
    numbers
);

interface ThirdPartyTransporterState {
    listTransporters: TransporterSelectModel[],
    selectedTransporter: TransporterSelectModel,
    selectedDate: Date,
    transporterOrdersSeenByTransporter: TransporterOrdersSeenByTransporterLightModel,
    numberOfUnconfirmedCanceledVehicles: number,
    lines: Array<TransporterOrderLineSeenByTransporterLightModelExtended>,
    linesGroupByLogisticsUnitId: Array<TransporterOrderLineSeenByTransporterGroupedByLogisticsUnitModel>,
    driversList: Array<DriverLightModel>,
    licencePlatesList: Array<VehicleLightModel>,
    disableChanges: boolean,
    vehicleTypeGroupDoesNotMatch: boolean,
    identicalDrivers: boolean,
    token: string,
    tokenAcces: boolean,
    tokenBcDate: Date,
    tokenTransporterName: string,
    tokenTransporterId: string,
    isDialogOpened: boolean,
    popupContentComponent: JSX.Element,
    dialogTitle: string,
    className: string,
    isVehiclesManagementDrawerOpened: boolean,
    isDriversManagementDrawerOpened: boolean,
    updateVehicleDrawerList: boolean,
    updateDriverDrawerList: boolean,
    isDriverListOpened: boolean,
    isVehicleListOpened: boolean,
    lastTimeStampSearchTransporters: number,
    lastTimeStampFindOrders: number,
    lastTimeStampSearchDrivers: number,
    lastTimeStampSearchLicensePlate: number,
    loading: boolean,
    expandedPanelsTransportRequests: Map<string, boolean>,
    expandedPanelsLUs: Map<string, boolean>,
    expandedPanelConfirmation: boolean,
    hasAcceptedGdpr: boolean,
    gdprAcceptancyDate: Date,
    managerUserName: string,
    toastLstMessageError: string[],
    isOnlyTransportersUsingDriversChosenChecked: boolean,
    showMsgConfirmOrderWhenEmptyField: boolean
}

interface ThirdPartyTransporterProps {
    userName: string,
    isForInternalTransporters: boolean,
    logisticsUnitIds: Array<string>,
    role: string
}

const GDPR_FILE_NAME = 'RGPD 005_Transparence_Trame_Politique_DP_Privacy Policy_FR-LORIE-V0.1.pdf';

const dicoPriceKindLabels: Map<string, string> = new Map<string, string>([
    ['Day', 'Jour'],
    ['HalfDay', 'Demi-journée'],
    ['Night', 'Nuit'],
    ['PerTon', 'Tonne'],
    ['PerHour', 'Heure']
]);

class ThirdPartyTransporterView extends React.Component<ThirdPartyTransporterProps & RouteComponentProps, ThirdPartyTransporterState> {
    today: Date = new Date();
    _isMounted: boolean;
    transporterCardsRef: React.RefObject<HTMLDivElement>;
    searchParams: URLSearchParams = new URLSearchParams(this.props.location.search);
    dispatcherUserName: string = this.props.role === "DIS" ? this.props.userName : null;
    isLogisticianOrAdminUser = (this.props.role === "LOG" || this.props.role === 'ADM');

    constructor(props: ThirdPartyTransporterProps & RouteComponentProps) {
        super(props);
        let planningDate: Date;
        let token = null;

        if (this.props.isForInternalTransporters) {
            const planningDateParam = this.searchParams.get("planningDate");

            if (planningDateParam) {
                planningDate = Date.getDateFromNumericString(planningDateParam);

                if (!Date.isValid(planningDate))
                    planningDate = null;
            }

            else {
                planningDate = SessionStorage.ActiveStartDate;
            }
        }
        else {
            planningDate = SessionStorage.ActiveStartDate;
            token = this.searchParams.get("K");
        }

        this.transporterCardsRef = React.createRef();
        this.state = {
            listTransporters: [],
            selectedTransporter: null,
            selectedDate: planningDate,
            transporterOrdersSeenByTransporter: null,
            numberOfUnconfirmedCanceledVehicles: null,
            lines: [],
            linesGroupByLogisticsUnitId: [],
            driversList: [],
            licencePlatesList: [],
            disableChanges: false,
            vehicleTypeGroupDoesNotMatch: false,
            identicalDrivers: false,
            token: token,
            tokenAcces: false,
            tokenBcDate: null,
            tokenTransporterName: '',
            tokenTransporterId: '',
            isDialogOpened: false,
            popupContentComponent: null,
            dialogTitle: '',
            className: '',
            isVehiclesManagementDrawerOpened: false,
            isDriversManagementDrawerOpened: false,
            updateVehicleDrawerList: false,
            updateDriverDrawerList: false,
            isDriverListOpened: false,
            isVehicleListOpened: false,
            lastTimeStampSearchTransporters: Date.now(),
            lastTimeStampFindOrders: Date.now(),
            lastTimeStampSearchDrivers: Date.now(),
            lastTimeStampSearchLicensePlate: Date.now(),
            loading: false,
            expandedPanelsTransportRequests: new Map<string, boolean>(),
            expandedPanelsLUs: new Map<string, boolean>(),
            expandedPanelConfirmation: this.getWidthOfWindow() >= 800 ? true : false,
            hasAcceptedGdpr: false,
            gdprAcceptancyDate: null,
            managerUserName: '',
            toastLstMessageError: [],
            isOnlyTransportersUsingDriversChosenChecked: false,
            showMsgConfirmOrderWhenEmptyField: false
        };
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.isForInternalTransporters) {
            const agencyIdParam = this.searchParams.get("agencyId");
            if (this.state.selectedDate) {
                if (agencyIdParam) {
                    const buyerLogisticsUnitId = this.searchParams.get("buyerLogisticsUnitId");
                    this.SearchInternalTransportersWithOrders(agencyIdParam, this.state.selectedDate, buyerLogisticsUnitId);
                    this.SearchDriversWithLicensePlate(agencyIdParam);
                }
                else {
                    this.SearchInternalTransporters(this.state.selectedDate);
                }
            }
        }
        else {
            if (this.state.token !== null) {
                this.setState({
                    tokenAcces: true
                });
                this.search(null, null, this.state.token);
            } else {
                this.SearchExternalTransporters(this.state.selectedDate);
            }
        }
    }

    componentDidUpdate() {
        if (this.state.token !== null && this.state.selectedTransporter !== null) {
            this.SearchDriversWithLicensePlate(this.state.selectedTransporter?.value);
        }
    }

    shouldComponentUpdate(nextProps: ThirdPartyTransporterProps & RouteComponentProps, nextState: ThirdPartyTransporterState) {
        if (this.state.lastTimeStampSearchTransporters > nextState.lastTimeStampSearchTransporters
            || this.state.lastTimeStampFindOrders > nextState.lastTimeStampFindOrders
            || this.state.lastTimeStampSearchDrivers > nextState.lastTimeStampSearchDrivers
            || this.state.lastTimeStampSearchLicensePlate > nextState.lastTimeStampSearchLicensePlate
        )
            return false;
        return true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getWidthOfWindow = (): number => {
        return window.innerWidth;
    }

    handleExpansionPanelConfimationChange = (): void => {
        this.setState({
            expandedPanelConfirmation: !this.state.expandedPanelConfirmation
        });
    }

    handleExpansionPanelLUChange = (panel: string): void => {
        const expandedPanelsLUs: Map<string, boolean> = this.state.expandedPanelsLUs;

        const isExpanded: boolean = expandedPanelsLUs.get(panel);
        expandedPanelsLUs.set(panel, !isExpanded);

        this.setState({
            expandedPanelsLUs: expandedPanelsLUs
        });
    }

    handleExpansionTransportRequestChange = (panel: string): void => {
        const expandedPanelsTransportRequests: Map<string, boolean> = this.state.expandedPanelsTransportRequests;

        const isExpanded: boolean = expandedPanelsTransportRequests.get(panel);
        expandedPanelsTransportRequests.set(panel, !isExpanded);

        this.setState({
            expandedPanelsTransportRequests: expandedPanelsTransportRequests
        });
    }

    handleDriverListOpen = (isOpen: boolean, readOnly: boolean): void => {
        if (!readOnly) {
            this.setState({
                isDriverListOpened: isOpen
            });
        }
    }

    handleVehicleListOpen = (isOpen: boolean, readOnly: boolean): void => {
        if (!readOnly) {
            this.setState({
                isVehicleListOpened: isOpen
            });
        }
    }

    handleSearchDatePickerChange = (date: Date): void => {
        if (!date) {
            this.setState({
                selectedDate: null
            });
        }
        else if (!Date.equals(date, this.state.selectedDate)) {
            this.setState({
                selectedDate: date,
                isDriverListOpened: false,
                isVehicleListOpened: false,
                loading: true
            });
            if (this.props.isForInternalTransporters) {
                this.SearchInternalTransporters(date);
            }
            else {
                if (this.state.selectedTransporter) {
                    if (this.state.token)
                        this.search(this.state.selectedTransporter?.value, date, this.state.token);
                    else
                        this.SearchExternalTransportersWithOrders(this.state.selectedTransporter?.value, date);
                }
                else
                    this.SearchExternalTransporters(date);
            }
        }
    }

    handleTransporterChange = (e: TransporterSelectModel): void => {
        const { selectedDate } = this.state;
        if (selectedDate) {
            const selectedTransporter: TransporterSelectModel = e ? { label: e.label, value: e.label === 'Colas' ? null : e.value } : null;
            if (e) {
                this.setState({
                    selectedTransporter: selectedTransporter,
                    disableChanges: false,
                    vehicleTypeGroupDoesNotMatch: false,
                    isDriverListOpened: false,
                    isVehicleListOpened: false
                });
                this.search(e.value, this.state.selectedDate, this.state.token);
                this.SearchDriversWithLicensePlate(e.value);
                return;
            }

            this.setState({
                selectedTransporter: selectedTransporter,
                disableChanges: false,
                vehicleTypeGroupDoesNotMatch: false,
                isVehiclesManagementDrawerOpened: false,
                isDriversManagementDrawerOpened: false,
                isDriverListOpened: false,
                isVehicleListOpened: false,
                transporterOrdersSeenByTransporter: null,
                numberOfUnconfirmedCanceledVehicles: null,
                lines: null,
                tokenBcDate: null,
                tokenTransporterName: null,
                tokenTransporterId: '',
                loading: false,
                driversList: [],
                licencePlatesList: []
            });
        }
    }

    printOrGenerateFileSpecifiedSection = (lines: TransporterOrderLineSeenByTransporterLightModelExtended[] = null, requestToGenerateFile: boolean) => {
        let w: any = window;

        const printData: ThirdPartyTransporterPrintData = {
            isForInternalTransportes: this.props.isForInternalTransporters,
            date: this.state.tokenAcces ? this.state.tokenBcDate : this.state.selectedDate,
            transporter: this.state.tokenAcces ? this.state.tokenTransporterName : this.state.selectedTransporter?.label,
            requestToGenerateFile: requestToGenerateFile,
            lines: lines == null ? this.state.lines : lines
        }
        w[ThirdPartyTransporterPrintDataProperty] = printData;
        const url: URL = new URL(window.location.href);
        const urlRedirect: string = url.pathname + "-Print" + url.search;

        w = window.open(urlRedirect, 'blank_', 'rel="noopener"');
    }

    areOrderLinesIdsEqual = (firstList: Array<string>, secondList: Array<string>): boolean => {
        this.sortArrayAsc(firstList);
        this.sortArrayAsc(secondList);

        if (isEqual(firstList, secondList)) {
            return true;
        }
        return false;
    }

    sortArrayAsc = (array: Array<string>): string[] => {
        return array.sort();
    }

    handleConfirmVehicleTripsStatus = (): void => {
        if (!this.props.isForInternalTransporters && !this.state.hasAcceptedGdpr)
            ToastService.showErrorToast("Merci de bien vouloir lire et accepter la politique de données personnelles avant de confirmer les camions");
        else if (this.state.lines.some(l => (!l.driverId || !l.driverPhone || !l.loadCapacity || !l.licencePlate) && !l.isCanceled)) {
            this.setState({
                showMsgConfirmOrderWhenEmptyField: true
            });
        }
        else {
            this.confirmVehicleTripsStatus();
        }
    }

    confirmVehicleTripsStatus = (): void => {
        const previousLineIds: Array<string> = [];
        this.state.transporterOrdersSeenByTransporter.lines.forEach((line: TransporterOrderLineSeenByTransporterLightModel) => {
            previousLineIds.push(line.planningVehicleId);
        });

        const orderIds = uniq(this.state.transporterOrdersSeenByTransporter.lines?.map(x => x.orderId)) ?? [];
        ThirdPartyTransporterApiClient.GetOrderedPlanningVehiclesIds(orderIds)
            .then(res => {
                const data = res.data;

                const lineIds = [];

                data.forEach(d => {
                    d.planningVehicleIds?.forEach(p => {
                        if (lineIds.indexOf(p) === -1) {
                            lineIds.push(p);
                        }
                    })
                });

                if (this.areOrderLinesIdsEqual(previousLineIds, lineIds)) {
                    const linesList: OrderLineRequestArgs[] = new Array<OrderLineRequestArgs>();
                    this.state.lines.forEach((line: TransporterOrderLineSeenByTransporterLightModelExtended) => {

                        if ((!line.driverId || !line.driverPhone || !line.licencePlate || !line.loadCapacity) && !line.isCanceled)
                            return;
                        const dcIds: string[] = new Array<string>();
                        line.transportRequests.forEach((transportRequest: TransporterOrderLineDetailSeenByTransporterLightModel) => {
                            dcIds.push(transportRequest.transportRequestId);
                        });

                        const orderLine: OrderLineRequestArgs = {
                            transporterOrderId: line.orderId,
                            planningVehicleId: line.planningVehicleId,
                            planningId: line.planningId,
                            vehicleId: line.vehicleId,
                            loadCapacity: line.loadCapacity,
                            licencePlate: line.licencePlate,
                            driverId: line.driverId,
                            driverName: line.driverName,
                            driverPhoneNumber: line.driverPhone,
                            transportRequestIds: dcIds,
                            isCanceled: line.isCanceled
                        }

                        linesList.push(orderLine);
                    });

                    const order: OrderRequestArgs = {
                        transporterId: this.state.transporterOrdersSeenByTransporter.transporterId,
                        purchaseDate: this.state.transporterOrdersSeenByTransporter.date,
                        lines: linesList,
                        clientDate: Date.getToday().stripTime()
                    }

                    ThirdPartyTransporterApiClient.ConfirmOrders(order)
                        .then(json => {
                            if (this._isMounted) {
                                const data: WebAppActionResult = json?.data;
                                if (data.commandResults &&
                                    data.commandResults.some(x => x.hasBusinessErrors &&
                                        x.businessErrors.some(e => e.name === 'PlanningVehicleHasBeenCanceledInTheMeantime' || e.name === 'PlanningVehicleHasBeenReinstatedInTheMeantime'))) {
                                    ToastService.showErrorToast("Votre confirmation de commande a été modifiée par votre logisticien, merci d'actualiser votre page (touche F5) et de saisir les informations nécessaires");
                                    return;
                                }

                                const errors: string[] = BusinessErrors.Get(data);
                                if (errors.length > 0) {
                                    ToastService.showErrorToast("", errors);
                                    return;
                                }

                                ToastService.showSuccessToast("Confirmation effectuée avec succès");

                                this.setState({ showMsgConfirmOrderWhenEmptyField: false });
                                if (this.state.tokenAcces) {
                                    this.search(this.state.tokenTransporterId, this.state.tokenBcDate, this.state.token);
                                } else {
                                    this.search(this.state.selectedTransporter?.value, this.state.selectedDate, this.state.token);
                                }
                            }
                        });
                } else {
                    ToastService.showErrorToast("Votre confirmation de commande a été modifiée par votre logisticien, merci d'actualiser votre page (touche F5) et de saisir les informations nécessaires");

                    this.setState({
                        showMsgConfirmOrderWhenEmptyField: false
                    });
                }

            });
    }

    sortArrayByKey = (array: Array<TransporterLightModel>, key: string) => {
        return array.sort((a, b) => a[key] < b[key] ? -1 : (a[key] > b[key] ? 1 : 0));
    }

    SearchExternalTransporters = (date: Date): void => {
        const lastTimeStamp: number = this.state.lastTimeStampSearchTransporters;
        const currentTimeStamp: number = Date.now();

        ThirdPartyTransporterApiClient.SearchExternalTransporters(date, this.props.logisticsUnitIds)
            .then(json => {
                if (this._isMounted) {
                    if (lastTimeStamp != this.state.lastTimeStampSearchTransporters)
                        return;

                    const transporters = this.sortArrayByKey(json.data, "transporterName");
                    this.setState({
                        listTransporters: transporters.map(x => { return { label: x.transporterName, value: x.transporterId } }),
                        lastTimeStampSearchTransporters: currentTimeStamp,
                        selectedTransporter: null,
                        loading: false
                    });
                }
            });
    }

    SearchInternalTransporters = (date: Date): void => {
        const lastTimeStamp: number = this.state.lastTimeStampSearchTransporters;
        const currentTimeStamp: number = Date.now();

        ThirdPartyTransporterApiClient.SearchInternalTransporters(date, this.props.logisticsUnitIds, this.dispatcherUserName)
            .then(json => {
                if (this._isMounted) {
                    if (lastTimeStamp != this.state.lastTimeStampSearchTransporters)
                        return;

                    const transporters: TransporterSelectModel[] = this.sortArrayByKey(json.data, "transporterName")?.map(x => { return { label: x.transporterName, value: x.transporterId } });
                    let selectedTransporter: TransporterSelectModel = null;

                    if (transporters?.length === 1) {
                        selectedTransporter = transporters[0];
                        const transporterId = selectedTransporter.value;
                        this.search(transporterId, date, null);
                        this.SearchDriversWithLicensePlate(transporterId);
                        this.setState({
                            listTransporters: transporters,
                            lastTimeStampSearchTransporters: currentTimeStamp,
                            selectedTransporter: selectedTransporter,
                            loading: false
                        });
                    }
                    else {
                        this.setState({
                            listTransporters: transporters,
                            lastTimeStampSearchTransporters: currentTimeStamp,
                            selectedTransporter: null,
                            disableChanges: false,
                            vehicleTypeGroupDoesNotMatch: false,
                            isDriversManagementDrawerOpened: false,
                            isDriverListOpened: false,
                            transporterOrdersSeenByTransporter: null,
                            numberOfUnconfirmedCanceledVehicles: null,
                            lines: null,
                            loading: false,
                            driversList: [],
                            licencePlatesList: []
                        });
                    }
                }
            });
    }

    SearchExternalTransportersWithOrders = (transporterId: string, date: Date): void => {
        const lastTimeStamp: number = this.state.lastTimeStampSearchTransporters;
        const currentTimeStamp: number = Date.now();

        ThirdPartyTransporterApiClient.SearchExternalTransportersWithOrders(transporterId, date, this.props.logisticsUnitIds)
            .then(res => {
                if (this._isMounted && res && res.length > 0) {
                    if (lastTimeStamp != this.state.lastTimeStampSearchTransporters)
                        return;
                    const transporters: TransporterSelectModel[] = this.sortArrayByKey(res[0].data, "transporterName")?.map(x => { return { label: x.transporterName, value: x.transporterId } });

                    let lines: TransporterOrderLineSeenByTransporterLightModelExtended[] = [];
                    let linesGroupByLogisticsUnitLabel: TransporterOrderLineSeenByTransporterGroupedByLogisticsUnitModel[] = [];
                    let numberOfUnconfirmedCanceledVehicles: number = null;
                    const transporterOrdersSeenByTransporter: TransporterOrdersSeenByTransporterLightModel = res[1].data;
                    let mapTransportRequestsPanels: Map<string, boolean> = new Map<string, boolean>();
                    let mapLUsPanels: Map<string, boolean> = new Map<string, boolean>();
                    let hasAcceptedGdpr = false;
                    let gdprAcceptancyDate: Date = null;
                    let managerUserName: string = null;

                    const selectedTransporter: TransporterSelectModel = transporters.find(o => o.value === transporterId);

                    if (selectedTransporter != null) {
                        lines = transporterOrdersSeenByTransporter.lines as TransporterOrderLineSeenByTransporterLightModelExtended[];
                        this.transporterOrderLineEnrichment(lines);

                        linesGroupByLogisticsUnitLabel = this.GetLinesGroupByLogisticsUnitLabel(lines);
                        gdprAcceptancyDate = transporterOrdersSeenByTransporter.gdprAcceptancyDate;
                        hasAcceptedGdpr = transporterOrdersSeenByTransporter.hasAcceptedGdpr;
                        managerUserName = transporterOrdersSeenByTransporter.managerUserName;

                        const maps: MapsPanelModel = this.getMapsPanelLU_DC(lines, linesGroupByLogisticsUnitLabel);
                        mapTransportRequestsPanels = maps.mapTransportRequestsPanels;
                        mapLUsPanels = maps.mapLUsPanels;
                        numberOfUnconfirmedCanceledVehicles = lines.reduce(
                            (p, c) => p + (c.isCanceled && c.cancellationOrderConfirmationDate === null ? 1 : 0),
                            0
                        );
                    }

                    const state = {
                        listTransporters: transporters,
                        lastTimeStampSearchTransporters: currentTimeStamp,
                        transporterOrdersSeenByTransporter: transporterOrdersSeenByTransporter,
                        numberOfUnconfirmedCanceledVehicles: numberOfUnconfirmedCanceledVehicles > 0 ? numberOfUnconfirmedCanceledVehicles : null,
                        lines: lines,
                        linesGroupByLogisticsUnitId: linesGroupByLogisticsUnitLabel,
                        lastTimeStampFindOrders: currentTimeStamp,
                        expandedPanelsTransportRequests: mapTransportRequestsPanels,
                        expandedPanelsLUs: mapLUsPanels,
                        hasAcceptedGdpr: hasAcceptedGdpr,
                        gdprAcceptancyDate: gdprAcceptancyDate,
                        managerUserName: managerUserName,
                        selectedTransporter: selectedTransporter,
                        loading: false
                    };

                    this.setState(state);
                }
            });
    }

    SearchInternalTransportersWithOrders = (transporterId: string, date: Date, buyerLogisticsUnitIdToOpen: string): void => {
        const lastTimeStamp: number = this.state.lastTimeStampSearchTransporters;
        const currentTimeStamp: number = Date.now();

        ThirdPartyTransporterApiClient.SearchInternalTransportersWithOrders(transporterId, date, this.props.logisticsUnitIds, this.dispatcherUserName)
            .then(res => {
                if (this._isMounted && res && res.length > 0) {
                    if (lastTimeStamp != this.state.lastTimeStampSearchTransporters)
                        return;
                    const transporters: TransporterSelectModel[] = this.sortArrayByKey(res[0].data, "transporterName")?.map(x => { return { label: x.transporterName, value: x.transporterId } });
                    const orders = res[1];

                    let lines: TransporterOrderLineSeenByTransporterLightModelExtended[] = [];
                    let linesGroupByLogisticsUnitLabel: TransporterOrderLineSeenByTransporterGroupedByLogisticsUnitModel[] = [];
                    let numberOfUnconfirmedCanceledVehicles: number = null;
                    let transporterOrdersSeenByTransporter: TransporterOrdersSeenByTransporterLightModel;
                    let mapTransportRequestsPanels: Map<string, boolean> = new Map<string, boolean>();
                    let mapLUsPanels: Map<string, boolean> = new Map<string, boolean>();
                    let hasAcceptedGdpr = false;
                    let gdprAcceptancyDate: Date = null;
                    let managerUserName: string = null;

                    const selectedTransporter: TransporterSelectModel = transporters.find(o => o.value === transporterId);

                    if (selectedTransporter) {
                        lines = orders.data.lines as TransporterOrderLineSeenByTransporterLightModelExtended[];
                        this.transporterOrderLineEnrichment(lines);

                        linesGroupByLogisticsUnitLabel = this.GetLinesGroupByLogisticsUnitLabel(lines);
                        transporterOrdersSeenByTransporter = orders.data;
                        gdprAcceptancyDate = orders.data.gdprAcceptancyDate;
                        hasAcceptedGdpr = orders.data.hasAcceptedGdpr;
                        managerUserName = orders.data.managerUserName;

                        const maps: MapsPanelModel = this.getMapsPanelLU_DC(lines, linesGroupByLogisticsUnitLabel, buyerLogisticsUnitIdToOpen);
                        mapTransportRequestsPanels = maps.mapTransportRequestsPanels;
                        mapLUsPanels = maps.mapLUsPanels;
                        numberOfUnconfirmedCanceledVehicles = lines.reduce(
                            (p, c) => p + (c.isCanceled && c.cancellationOrderConfirmationDate === null ? 1 : 0),
                            0
                        );
                    }

                    const state = {
                        listTransporters: transporters,
                        lastTimeStampSearchTransporters: currentTimeStamp,
                        transporterOrdersSeenByTransporter: transporterOrdersSeenByTransporter,
                        numberOfUnconfirmedCanceledVehicles: numberOfUnconfirmedCanceledVehicles > 0 ? numberOfUnconfirmedCanceledVehicles : null,
                        lines: lines,
                        linesGroupByLogisticsUnitId: linesGroupByLogisticsUnitLabel,
                        lastTimeStampFindOrders: currentTimeStamp,
                        expandedPanelsTransportRequests: mapTransportRequestsPanels,
                        expandedPanelsLUs: mapLUsPanels,
                        hasAcceptedGdpr: hasAcceptedGdpr,
                        gdprAcceptancyDate: gdprAcceptancyDate,
                        managerUserName: managerUserName,
                        selectedTransporter: selectedTransporter,
                        loading: false
                    };

                    this.setState(state);
                }
            });
    }

    getMapsPanelLU_DC = (lines: TransporterOrderLineSeenByTransporterLightModelExtended[], linesGroupByLogisticsUnitLabel, buyerLogisticsUnitIdToOpen?: string): MapsPanelModel => {
        const mapTransportRequestsPanels: Map<string, boolean> = new Map<string, boolean>();
        const mapLUsPanels: Map<string, boolean> = new Map<string, boolean>();
        let cpDC = 0;
        let cpLU = 0;

        each(linesGroupByLogisticsUnitLabel, i => {
            cpDC = 0;

            if (buyerLogisticsUnitIdToOpen) {
                mapLUsPanels.set(`panel-logistics-unit-${cpLU}`, i.lines[0].logisticsUnit.logisticsUnitId === buyerLogisticsUnitIdToOpen);
            }
            else {
                mapLUsPanels.set(`panel-logistics-unit-${cpLU}`, true);
            }
            lines.forEach(i => {
                mapTransportRequestsPanels.set(`panel-dc-${cpLU}-${cpDC}`, true);
                cpDC++;
            });

            cpLU++;
        });
        return {
            mapTransportRequestsPanels: mapTransportRequestsPanels,
            mapLUsPanels: mapLUsPanels
        } as MapsPanelModel
    }

    search = (transporterId: string, date: Date, token: string): void => {
        if (this.state.selectedDate) {
            const currentTimeStamp: number = Date.now();
            this.setState({ loading: true });
            ThirdPartyTransporterApiClient.Search(transporterId, date, token)
                .then(res => {
                    const json: TransporterOrdersSeenByTransporterLightModel = res.data;

                    if (token) {
                        this.SearchDriversWithLicensePlate(json.transporterId);
                    }
                    if (this._isMounted) {
                        const lines = json.lines as TransporterOrderLineSeenByTransporterLightModelExtended[];
                        const linesGroupByLogisticsUnitLabel = this.GetLinesGroupByLogisticsUnitLabel(lines);

                        this.transporterOrderLineEnrichment(lines);

                        const maps: MapsPanelModel = this.getMapsPanelLU_DC(lines, linesGroupByLogisticsUnitLabel, json.linkTokenBuyerLogisticsUnitId);
                        const mapTransportRequestsPanels: Map<string, boolean> = maps.mapTransportRequestsPanels;
                        const mapLUsPanels: Map<string, boolean> = maps.mapLUsPanels;
                        const numberOfUnconfirmedCanceledVehicles = lines.reduce(
                            (p, c) => p + (c.isCanceled && c.cancellationOrderConfirmationDate === null ? 1 : 0),
                            0
                        );

                        this.setState({
                            transporterOrdersSeenByTransporter: json,
                            numberOfUnconfirmedCanceledVehicles: numberOfUnconfirmedCanceledVehicles > 0 ? numberOfUnconfirmedCanceledVehicles : null,
                            lines: lines,
                            linesGroupByLogisticsUnitId: linesGroupByLogisticsUnitLabel,
                            tokenBcDate: token ? json.date : null,
                            tokenTransporterName: token ? json.transporterName : '',
                            tokenTransporterId: token ? json.transporterId : '',
                            lastTimeStampFindOrders: currentTimeStamp,
                            loading: false,
                            expandedPanelsTransportRequests: mapTransportRequestsPanels,
                            expandedPanelsLUs: mapLUsPanels,
                            hasAcceptedGdpr: json.hasAcceptedGdpr,
                            gdprAcceptancyDate: json.gdprAcceptancyDate,
                            managerUserName: json.managerUserName
                        });
                    }
                })
                .catch(e => {
                    this.setState({
                        transporterOrdersSeenByTransporter: null,
                        numberOfUnconfirmedCanceledVehicles: null,
                        lines: null,
                        tokenBcDate: null,
                        tokenTransporterName: null,
                        tokenTransporterId: '',
                        lastTimeStampFindOrders: currentTimeStamp,
                        loading: false
                    });
                });
        }
    }

    transporterOrderLineEnrichment = (lines: TransporterOrderLineSeenByTransporterLightModelExtended[]): void => {
        lines.forEach(l => {
            const trips = new Array<LabeledTripNumber>();
            l.transportRequests.forEach(t => {
                t.tripsOrderNumbers.forEach(o => {
                    trips.push({ number: o, label: `${t.senderSiteLabel ?? ''} - ${t.receiverSiteLabel ?? ''}${t.formule ? ' / ' + t.formule : ''}` })
                });
            });
            l.labeledTripsOrdered = trips.sort((a, b) => a.number - b.number);
            l.mainPriceKindLabel = dicoPriceKindLabels.get(l.mainPriceKind);
        });
    }

    GetLinesGroupByLogisticsUnitLabel = (lines: TransporterOrderLineSeenByTransporterLightModelExtended[]): Array<TransporterOrderLineSeenByTransporterGroupedByLogisticsUnitModel> => {
        const group: _.Dictionary<TransporterOrderLineSeenByTransporterLightModelExtended[]> = groupBy(lines, x => x.logisticsUnit?.logisticsUnitId);
        const TransporterOrderLineArray: Array<TransporterOrderLineSeenByTransporterGroupedByLogisticsUnitModel> = map(group, (value, key) => ({
            logisticsUnitLabel: value[0].logisticsUnit?.label,
            billingAddress: value[0].logisticsUnit?.billingAddress,
            europeanVatNumber: value[0].logisticsUnit?.europeanVatNumber,
            lines: value
        }));
        return TransporterOrderLineArray;
    }

    SearchDrivers = (transporterId: string): void => {
        const lastTimeStamp: number = this.state.lastTimeStampSearchDrivers;
        const currentTimeStamp: number = Date.now();

        ThirdPartyTransporterApiClient.SearchDrivers(transporterId, true)
            .then(res => {
                if (this._isMounted) {
                    if (lastTimeStamp != this.state.lastTimeStampSearchDrivers)
                        return;

                    this.setState({ driversList: res.data, lastTimeStampSearchDrivers: currentTimeStamp });
                }
            });
    }

    SearchLicencePlate = (transporterId: string): void => {
        const lastTimeStamp: number = this.state.lastTimeStampSearchLicensePlate
        const currentTimeStamp: number = Date.now();

        ThirdPartyTransporterApiClient.SearchLicencePlate(transporterId, true, true)
            .then(res => {
                if (this._isMounted) {
                    if (lastTimeStamp != this.state.lastTimeStampSearchLicensePlate)
                        return;

                    this.setState({ licencePlatesList: res.data, lastTimeStampSearchLicensePlate: currentTimeStamp });
                }
            });
    }

    SearchDriversWithLicensePlate = (transporterId: string): void => {
        const lastTimeStamp: number = this.state.lastTimeStampSearchDrivers;
        const currentTimeStamp: number = Date.now();

        ThirdPartyTransporterApiClient.SearchDriversWithLicencePlate(transporterId)
            .then(json => {
                if (this._isMounted && json && json.length === 2) {
                    if (lastTimeStamp != this.state.lastTimeStampSearchDrivers)
                        return;

                    const driversList: DriverLightModel[] = json[0].data;
                    const licencePlatesList: VehicleLightModel[] = json[1].data;

                    this.setState({ driversList: driversList, licencePlatesList: licencePlatesList, lastTimeStampSearchDrivers: currentTimeStamp });
                }
            });
    }

    handleLicencePlateChange = (licenceRef: React.MutableRefObject<HTMLInputElement>, selectedOption: SelectOptionModel, planningVehicleId: string, isNightWork: boolean): void => {
        const linesList: TransporterOrderLineSeenByTransporterLightModelExtended[] = this.state.lines;
        const index: number = linesList.findIndex(d => d.planningVehicleId === planningVehicleId);

        if (!selectedOption) {
            linesList[index].vehicleId = null;
            linesList[index].licencePlate = null;

            if (this.state.vehicleTypeGroupDoesNotMatch) {
                this.setState({
                    vehicleTypeGroupDoesNotMatch: false,
                    lines: linesList
                });
            } else {
                this.setState({ lines: linesList });
            }
            return;
        }

        const licencePlate: VehicleLightModel = this.state.licencePlatesList.find(d => d.id === selectedOption.value);
        linesList[index].vehicleId = selectedOption.value as number;
        linesList[index].licencePlate = licencePlate.licencePlate;
        linesList[index].loadCapacity = licencePlate.loadCapacity;

        this.setState({ lines: linesList });

        //controle de saisie sur le dopdownlist Immatriculation
        if (licencePlate.vehicleTypeGroupId !== linesList[index].orderedVehicleTypeGroupeId) {
            ToastService.showErrorToast("Le type de véhicule ne correspond pas à la demande");

            if (!this.state.vehicleTypeGroupDoesNotMatch) {
                this.setState({
                    vehicleTypeGroupDoesNotMatch: true,
                });
            }
            licenceRef.current.focus();
            return;
        } else {
            if (this.state.vehicleTypeGroupDoesNotMatch) {
                this.setState({
                    vehicleTypeGroupDoesNotMatch: false,
                });
            }
        }

        this.licencePlateInputControle(licenceRef, selectedOption.value as number, isNightWork, planningVehicleId);
    }

    handleLicencePlateBlur = (licenceRef: React.MutableRefObject<HTMLInputElement>, selectedVehicleId: number, planningVehicleId: string, isNightWork: boolean, vehicleId: number): void => {
        if (vehicleId !== null && selectedVehicleId === -1) {
            this.setState({
                isVehicleListOpened: false
            });
            return;
        }

        if (selectedVehicleId !== -1) {
            const linesList: TransporterOrderLineSeenByTransporterLightModelExtended[] = this.state.lines;
            const item: TransporterOrderLineSeenByTransporterLightModelExtended = linesList.find(d => d.planningVehicleId === planningVehicleId);
            const licencePlate: VehicleLightModel = this.state.licencePlatesList.find(d => d.id === selectedVehicleId);
            if (licencePlate.vehicleTypeGroupId !== item.orderedVehicleTypeGroupeId) {
                ToastService.showErrorToast("Le type de véhicule ne correspond pas à la demande");

                if (!this.state.vehicleTypeGroupDoesNotMatch) {
                    this.setState({
                        vehicleTypeGroupDoesNotMatch: true,
                    });
                }

                licenceRef.current.focus();
                return;
            } else {
                if (this.state.vehicleTypeGroupDoesNotMatch) {
                    this.setState({
                        vehicleTypeGroupDoesNotMatch: false,
                    });
                }
            }
            this.licencePlateInputControle(licenceRef, selectedVehicleId, isNightWork, planningVehicleId);
        }
    }

    licencePlateInputControle = (licenceRef: React.MutableRefObject<HTMLInputElement>, selectedVehicleId: number, isNightWork: boolean, planningVehicleId: string): void => {
        let vehicleTypeGroupDoesNotMatch = false;
        for (let i = 0; i < this.state.lines.length; i++) {
            if (this.state.lines[i].vehicleId === selectedVehicleId && this.state.lines[i].isNightWork === isNightWork && this.state.lines[i].planningVehicleId !== planningVehicleId) {
                vehicleTypeGroupDoesNotMatch = true;
                break;
            }
        }

        if (vehicleTypeGroupDoesNotMatch) {
            ToastService.showErrorToast("Ce véhicule est déjà utilisé, veuillez en choisir un autre");

            this.setState({
                vehicleTypeGroupDoesNotMatch: true
            });
            licenceRef.current.focus();
        } else {
            this.setState({
                vehicleTypeGroupDoesNotMatch: false,
            });
        }
    }

    handleDriverChange = (driverRef: React.MutableRefObject<HTMLInputElement>, selectedOption: SelectOptionModel, planningVehicleId: string, isNightWork: boolean): void => {
        const linesList: TransporterOrderLineSeenByTransporterLightModelExtended[] = this.state.lines;
        const index: number = linesList.findIndex(d => d.planningVehicleId === planningVehicleId);

        if (!selectedOption) {
            linesList[index].driverId = null;
            linesList[index].driverName = null;
            linesList[index].driverPhone = null;

            if (this.state.identicalDrivers) {
                this.setState({
                    identicalDrivers: false,
                    lines: linesList
                });
            } else {
                this.setState({ lines: linesList });
            }
            return;
        }

        const driver: DriverLightModel = this.state.driversList.find(d => d.driverId === (selectedOption.value as DriverLightModel)?.driverId);
        linesList[index].driverId = driver.driverId;
        linesList[index].driverName = driver.fullName;
        linesList[index].driverPhone = driver.phoneNumber;

        this.setState({ lines: linesList });

        //controle de saisie sur le dopdownlist Chauffeur
        this.driverInputControle(driverRef, (selectedOption.value as DriverLightModel).driverId, isNightWork, planningVehicleId);
    }

    handleDriverBlur = (driverRef: React.MutableRefObject<HTMLInputElement>, selectedDriver: DriverLightModel, planningVehicleId: string, isNightWork: boolean, driverId: number): void => {
        if (driverId !== null && !selectedDriver) {
            this.setState({
                isDriverListOpened: false
            });
            return;
        }

        if (selectedDriver) {
            this.driverInputControle(driverRef, selectedDriver.driverId, isNightWork, planningVehicleId);
        }
    }

    driverInputControle = (driverRef: React.MutableRefObject<HTMLInputElement>, selectedDriverId: number, isNightWork: boolean, planningVehicleId: string): void => {
        let identicalDrivers = false;
        for (let i = 0; i < this.state.lines.length; i++) {
            if (this.state.lines[i].driverId === selectedDriverId && this.state.lines[i].isNightWork === isNightWork && this.state.lines[i].planningVehicleId !== planningVehicleId) {
                identicalDrivers = true;
                break;
            }
        }

        if (identicalDrivers) {
            ToastService.showErrorToast("Ce chauffeur est déjà utilisé, veuillez en choisir un autre");

            this.setState({
                identicalDrivers: true
            });
            driverRef.current.focus();
        } else {
            this.setState({
                identicalDrivers: false,
            });
        }
    }

    handleDriverPhoneChange = (event: React.ChangeEvent<HTMLInputElement>, planningVehicleId: string): void => {
        const linesList: TransporterOrderLineSeenByTransporterLightModelExtended[] = this.state.lines;
        const index: number = linesList.findIndex(d => d.planningVehicleId === planningVehicleId);
        linesList[index].driverPhone = event.target.value;

        this.setState({ lines: linesList });
    }

    handleLoadCapacityChange = (event: React.ChangeEvent<HTMLInputElement>, planningVehicleId: string): void => {
        const linesList: TransporterOrderLineSeenByTransporterLightModelExtended[] = this.state.lines;
        const index: number = linesList.findIndex(d => d.planningVehicleId === planningVehicleId);
        linesList[index].loadCapacity = Number(event.target.value.replace(/\D{1,}/, ''));

        this.setState({ lines: linesList });
    }

    handleLoadCapacityBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const config = SettingsProvider.Get();
        if (Number(event.target.value) < config.minimumLoadCapacity) {
            ToastService.showErrorToast('La charge utile doit être supérieure à ' + config.minimumLoadCapacity + ' kg');

            if (!this.state.disableChanges) {
                this.setState({
                    disableChanges: true,
                });
            }
            event.target.focus();
        } else {
            if (this.state.disableChanges) {
                this.setState({
                    disableChanges: false,
                });
            }
        }
    }

    handleDriverPhoneBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const isPhoneNumberValid = this.props.isForInternalTransporters
            ? Utilities.isValidPhoneNumber(event.target.value, true)
            : Utilities.isValidPhoneNumber(event.target.value);
        if (!isPhoneNumberValid) {
            ToastService.showErrorToast('Numéro de téléphone invalide');

            if (!this.state.disableChanges) {
                this.setState({
                    disableChanges: true,
                });
            }
            event.target.focus();
        } else {
            if (this.state.disableChanges) {
                this.setState({
                    disableChanges: false,
                });
            }
        }
    }

    handleClickOpen = (dialogType: string, item?: VehicleLightModel | DriverLightModel): void => {
        const { tokenTransporterId, tokenAcces, selectedTransporter } = this.state;
        const transporterId: string = tokenTransporterId ? tokenTransporterId : !tokenAcces ? (selectedTransporter ? selectedTransporter.value : null) : null;
        if (dialogType === 'vehicles') {
            if (item)
                this.setState({
                    isDialogOpened: true, dialogTitle: 'Modifier un camion', popupContentComponent: <AddOrUpdateVehicleComponent onClose={() => this.handleClickClose()} onAddOrUpdate={this.addingUpdatingVehicleFinished} vehicle={item as VehicleLightModel} transporterId={transporterId} />, className: "simple-dialog"
                });
            else
                this.setState({
                    isDialogOpened: true, dialogTitle: 'Ajouter un camion', popupContentComponent: <AddOrUpdateVehicleComponent onClose={() => this.handleClickClose()} onAddOrUpdate={this.addingUpdatingVehicleFinished} transporterId={transporterId} />, className: "simple-dialog"
                });
        } else if (dialogType === 'drivers') {
            if (item)
                this.setState({
                    isDialogOpened: true, dialogTitle: 'Modifier un chauffeur', popupContentComponent: <AddOrUpdateDriverComponent onClose={() => this.handleClickClose()} onAddOrUpdate={this.addingUpdatingDriverFinished} driver={item as DriverLightModel} transporterId={transporterId} />, className: ''
                });
            else
                this.setState({
                    isDialogOpened: true, dialogTitle: 'Ajouter un chauffeur', popupContentComponent: <AddOrUpdateDriverComponent onClose={() => this.handleClickClose()} onAddOrUpdate={this.addingUpdatingDriverFinished} transporterId={transporterId} />, className: ''
                });
        }
    }

    handleClickClose = (): void => {
        this.setState({ isDialogOpened: false });
    }

    addingUpdatingVehicleFinished = (transporterId: string, message: string): void => {
        ToastService.showSuccessToast(message);

        this.setState({ isDialogOpened: false });
        this.SearchLicencePlate(transporterId);
        this.setState({ updateVehicleDrawerList: true });
    }

    addingUpdatingDriverFinished = (transporterId: string): void => {
        ToastService.showSuccessToast("Le chauffeur a été ajouté avec succès");

        this.setState({ isDialogOpened: false });
        this.SearchDrivers(transporterId);
        this.setState({ updateDriverDrawerList: true });
    }

    handleClickOpenVehiclesDrawer = (): void => {
        this.setState({
            isVehiclesManagementDrawerOpened: true,
            isDriversManagementDrawerOpened: false
        });
    }
    handleCloseVehiclesDrawer = (): void => {
        this.setState({
            isVehiclesManagementDrawerOpened: false
        });
    }
    handleCloseDriversDrawer = (): void => {
        this.setState({
            isDriversManagementDrawerOpened: false
        });
    }

    handleClickOpenDriversDrawer = (): void => {
        this.setState({
            isDriversManagementDrawerOpened: true,
            isVehiclesManagementDrawerOpened: false
        });
    }

    updateListAciveLicencePlate = (transporterId: string): void => {
        this.SearchLicencePlate(transporterId);
    }

    updateListAciveDriver = (transporterId: string): void => {
        this.SearchDrivers(transporterId);
    }

    displayError = (message: string): void => {
        ToastService.showErrorToast(message);
    }

    setUpdateVehicleDrawerListToFalse = (): void => {
        this.setState({ updateVehicleDrawerList: false });
    }

    setUpdateDriverDrawerListToFalse = (): void => {
        this.setState({ updateDriverDrawerList: false });
    }

    setUpdateOnlyTransportersUsingDriversChosenChecked = (checked: boolean): void => {
        this.setState({
            isOnlyTransportersUsingDriversChosenChecked: checked
        });
    }

    handleClickGdpr = (): void => {
        window.open(`/${GDPR_FILE_NAME}`);
    }

    handleChangeGdpr = (): void => {
        const oldHasAcceptedGdpr: boolean = this.state.hasAcceptedGdpr;
        const managerUserName: string = this.state.managerUserName;
        ThirdPartyTransporterApiClient.UpdateGdpr(managerUserName, !oldHasAcceptedGdpr)
            .then(json => {
                const data: WebAppActionResultEx<GdprAcceptancyResult> = json?.data;
                const errors: string[] = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }
                if (data?.extraResult) {
                    this.setState({
                        hasAcceptedGdpr: data.extraResult.hasAcceptedGdpr,
                        gdprAcceptancyDate: data.extraResult.gdprAcceptancyDate
                    });
                }
            });
    }

    getValueField = (fieldValue: string): string => {
        return fieldValue ? fieldValue : '';
    }

    handleClickOpenVehiclesPublishedCosts = (): void => {
        const url: URL = new URL(window.location.href);
        let urlRedirect = '/';
        if (url.pathname.startsWith('/External')) {
            const param: string = url.searchParams.get("K");
            urlRedirect = `/External-TransporterPublishedCosts?K=${param}&transporterId=${this.state.tokenTransporterId}&transporterName=${this.state.tokenTransporterName}`;
        } else {
            urlRedirect += `${this.props.isForInternalTransporters
                ? (this.props.role === "DIS" ? AppModule.InternalTransporterPublishedCosts : AppModule.ThirdPartyInternalTransporterPublishedCosts)
                : AppModule.ThirdPartyExternalTransporterPublishedCosts}?transporterId=${this.state.selectedTransporter?.value}&transporterName=${this.state.selectedTransporter?.label}`;
        }

        this.props.navigate(urlRedirect);
    }

    displayBusinessErrors = (errors: string[]): void => {
        ToastService.showErrorToast("", errors);
    }

    handleHideMsgConfirmOrderWhenEmptyField = (): void => {
        this.setState({
            showMsgConfirmOrderWhenEmptyField: false
        });
    }

    render() {
        const { listTransporters, selectedDate, driversList, licencePlatesList, numberOfUnconfirmedCanceledVehicles, lines, disableChanges, vehicleTypeGroupDoesNotMatch, identicalDrivers, tokenAcces, tokenBcDate,
            tokenTransporterName, dialogTitle, popupContentComponent, className, tokenTransporterId, selectedTransporter, isVehiclesManagementDrawerOpened, isDriversManagementDrawerOpened,
            updateVehicleDrawerList, updateDriverDrawerList, managerUserName, isOnlyTransportersUsingDriversChosenChecked, showMsgConfirmOrderWhenEmptyField } = this.state;
        let readOnly = false;
        let allFieldsIsValid = false;
        const licencePlatesListSortedByVehicleTypeId: VehicleLightModel[] = licencePlatesList != null ? sortBy(licencePlatesList, o => [o.vehicleTypeId]) : licencePlatesList;

        if (lines !== null && lines !== undefined && lines.length > 0) {
            const today: Date = new Date();
            const bcDate: Date = !tokenAcces ? selectedDate : new Date(tokenBcDate);
            if (bcDate !== null && (today > bcDate.getDayEnd().addDays(1)) && !this.isLogisticianOrAdminUser) {
                readOnly = true;
            }
            const linesCompleted: TransporterOrderLineSeenByTransporterLightModelExtended[] = lines.filter(e => e.isCanceled || (e.driverId && e.licencePlate && e.driverPhone && e.loadCapacity));
            if (linesCompleted.length >= 1) {
                allFieldsIsValid = true;
            }
        } else {
            readOnly = true;
        }

        const transporterId: string = tokenTransporterId
            ? tokenTransporterId
            : !tokenAcces
                ? selectedTransporter?.value ?? null
                : null;

        return (
            <>
                <Box display="flex" minHeight="300px" flexWrap="nowrap" pl={2} pr={2} flexDirection="column" className={clsx('container-transporter', isVehiclesManagementDrawerOpened || isDriversManagementDrawerOpened ? 'transporter transporter-main-content' : 'transporter'
                )}>
                    <Box>
                        <HeaderComponent isForInternalTransporters={this.props.isForInternalTransporters} tokenBcDate={tokenBcDate} tokenAcces={tokenAcces} selectedDate={selectedDate}
                            numberOfUnconfirmedCanceledVehicles={numberOfUnconfirmedCanceledVehicles} listTransporters={listTransporters} tokenTransporterName={tokenTransporterName} selectedTransporter={selectedTransporter}
                            disabledButtonMngt={disableChanges || !transporterId}
                            handleSearchDatePickerChange={this.handleSearchDatePickerChange} handleTransporterChange={this.handleTransporterChange}
                            handleClickOpenVehiclesDrawer={this.handleClickOpenVehiclesDrawer} handleClickOpenDriversDrawer={this.handleClickOpenDriversDrawer}
                            handleClickOpenVehiclesPublishedCosts={this.handleClickOpenVehiclesPublishedCosts}
                        />
                    </Box>
                    {this.state.loading &&
                        <Box className='sweet-loading main-spinner'>
                            <ScaleLoader
                                width={5}
                                height={20}
                                radius={50}
                                color={'#000000'}
                                loading={this.state.loading}
                            />
                        </Box>
                    }

                    {!this.state.loading && lines && lines.length !== 0 &&
                        <Box className="confirmation-panel screenOnly" display="flex" flexDirection="column" flexWrap="wrap" pt={1} pb={this.props.isForInternalTransporters ? 1 : 0}>
                            <Box display="flex" pl={2} pr={2} flexWrap="wrap" flexDirection="row" alignItems="center">
                                <Box pr={1} display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" className="txt-paragraph">
                                    {!readOnly ? "Veuillez compléter les champs marqués d'un astérisque et cliquez ensuite sur le bouton « Confirmer les camions »" : ""}
                                </Box>
                                <Box display="flex" className="container-buttons-confirmation" flexDirection="row" flexWrap="wrap" justifyContent="flex-end" alignItems="center">
                                    <Button variant="contained" className="transporter-button" onClick={() => this.handleConfirmVehicleTripsStatus()} disabled={readOnly || !allFieldsIsValid || disableChanges || vehicleTypeGroupDoesNotMatch || identicalDrivers}>
                                        Confirmer les camions
                                    </Button>

                                    <Box className="txt-imprimer">
                                        Tout imprimer
                                        <Tooltip title="Tout imprimer" placement="bottom">
                                            <IconButton color="primary" size="small" onClick={() => this.printOrGenerateFileSpecifiedSection(null, false)} disabled={disableChanges || vehicleTypeGroupDoesNotMatch || identicalDrivers} >
                                                <FontAwesomeIcon size="1x" className="icon-print" icon={faPrint} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Box>
                            <Box pl={2} pr={2} display="flex" flexWrap="wrap" width='100%' flexDirection="row" alignItems="center" className={this.props.isForInternalTransporters ? "cancelation-msg-div" : "gdpr-cancelation-msg-div"}>
                                {!this.props.isForInternalTransporters && <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.hasAcceptedGdpr}
                                            disabled={this.state.hasAcceptedGdpr || this.props.userName !== managerUserName}
                                            onChange={() => this.handleChangeGdpr()}
                                            color="default"
                                        />
                                    }
                                    className='gdpr-control'
                                    label={<Typography className='gdpr-text'>J’ai lu <Link component='button' onClick={this.handleClickGdpr} className='gdpr-link'>la politique de données personnelles</Link> et j’accepte le traitement de mes données personnelles dans les conditions détaillées dans cette politique</Typography>}
                                />}
                                {numberOfUnconfirmedCanceledVehicles &&
                                    <Box className={`${this.props.isForInternalTransporters ? "mt-8 " : ""}cancelation-note`}>La confirmation des camions indiquera la prise de connaissance du statut "Annulé" des camions.</Box>
                                }
                            </Box>
                            {this.state.hasAcceptedGdpr &&
                                <Box pl={7} className='gdpr-text-approuved' alignSelf="center">
                                    Approuvée le {new Date(this.state.gdprAcceptancyDate).toShortDateTimeString()}
                                </Box>
                            }
                        </Box>
                    }
                    {!this.state.loading && lines !== null && lines !== undefined &&
                        <Box display='flex' flexDirection='column' className={`div-transporter-cards${this.props.isForInternalTransporters ? ' internal-cards' : ' external-cards'}`}>
                            {!this.state.loading && tokenAcces &&
                                <Box pt={1} pb={1} className="text-confirmation-command">
                                    <Accordion expanded={this.state.expandedPanelConfirmation} onChange={() => this.handleExpansionPanelConfimationChange()}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Box><span className="font-weight-bold">CONFIRMATION de COMMANDE – PRESTATION de TRANSPORT</span> (Article L.3222-4 du Code des Transports)</Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box display='flex' flexDirection='column'>
                                                <Box>Nous entendons que vous exécutez personnellement en tant que voiturier, les transports que nous vous confions. Il ne pourra être recouru à la sous-traitance, à titre exceptionnel, qu’en cas de nécessité absolue et après approbation par nos soins du nom de votre affrété.</Box>
                                                <Box>Le transport s’effectue dans le respect du contrat cadre et la prise en compte des informations ci-dessous. Le conducteur doit être en règle avec la réglementation en vigueur.</Box>
                                                <Box>A compter de la réception du mail, le transporteur doit nous informer dans les plus brefs délais de la non-exécution de la commande. Dans le cas contraire, la tacite acceptation prévaut.</Box>
                                                <Box> En votre qualité de transporteur, nous vous demandons de fournir l’exemplaire « expéditeur » de la lettre de voiture (prévue à l’article du 9 Novembre 1999) par mail à votre donneur d’ordre.</Box>
                                                <Box> Nous vous rappelons que : <span className="important-information">« Le port des EPI, le respect des consignes de sécurité et le bâchage des bennes sont OBLIGATOIRES »</span>.</Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                            }
                            <div ref={this.transporterCardsRef} className="transporter-cards">
                                {
                                    this.state.linesGroupByLogisticsUnitId.map((itemGroup, indexGroup) =>
                                        <Accordion className={`expansion-panel-logistics-unit expansion-panel-logistics-unit-${indexGroup}`} key={`expansion-Panel-summary-groupby-logistics-unit-${indexGroup}`} expanded={this.state.expandedPanelsLUs.get(`panel-logistics-unit-${indexGroup}`)}
                                            onChange={() => this.handleExpansionPanelLUChange(`panel-logistics-unit-${indexGroup}`)}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Box display="flex" flexDirection="column">
                                                    <div className='agency-nb-camion-title'>{itemGroup.logisticsUnitLabel} - {itemGroup.lines.length} {itemGroup.lines.length === 1 ? "CAMION" : "CAMIONS"}{` / Confirmation de commande : ${itemGroup.lines[0].orderReference}`}</div>
                                                    {!this.props.isForInternalTransporters && <div className='agency-nb-camion-title'>{`Adresse de facturation: ${itemGroup.billingAddress ?? ''}`}{itemGroup.europeanVatNumber ? ` / TVA intracommunautaire : ${itemGroup.europeanVatNumber}` : ''}
                                                    </div>}
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="transporter-vehicles-cards">
                                                    {itemGroup.lines.map((item, index) =>
                                                        <Box key={`tr-${index}`} mb={1} className={`section-line-print-${indexGroup}-${index}`}>
                                                            <TransporterCardVehicleComponent orderId={item.orderId} isForInternalTransporters={this.props.isForInternalTransporters} indexGroup={indexGroup} index={index} orderReference={item.orderReference} item={item} licencePlatesListSortedByVehicleTypeId={licencePlatesListSortedByVehicleTypeId}
                                                                driversList={driversList} expandedPanelsTransportRequests={this.state.expandedPanelsTransportRequests} isDriverListOpened={this.state.isDriverListOpened} isVehicleListOpened={this.state.isVehicleListOpened}
                                                                readOnly={readOnly} disableChanges={disableChanges} identicalDrivers={identicalDrivers} vehicleTypeGroupDoesNotMatch={vehicleTypeGroupDoesNotMatch}
                                                                handleDriverListOpen={this.handleDriverListOpen} handleVehicleListOpen={this.handleVehicleListOpen} handleChange={this.handleExpansionTransportRequestChange} handleLicencePlateChange={this.handleLicencePlateChange}
                                                                handleLicencePlateBlur={this.handleLicencePlateBlur} handleDriverChange={this.handleDriverChange} handleDriverBlur={this.handleDriverBlur}
                                                                handleDriverPhoneChange={this.handleDriverPhoneChange} handleDriverPhoneBlur={this.handleDriverPhoneBlur} handleLoadCapacityChange={this.handleLoadCapacityChange}
                                                                handleLoadCapacityBlur={this.handleLoadCapacityBlur} printOrGenerateFileSpecifiedSection={this.printOrGenerateFileSpecifiedSection} getValueField={this.getValueField}
                                                                role={this.props.role} selectedVehicleId={item.vehicleId ?? -1} selectedDriverId={item.driverId ?? -1}
                                                            />
                                                        </Box>
                                                    )}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                }
                            </div>
                        </Box>
                    }
                </Box>
                {!this.props.isForInternalTransporters &&
                    <Drawer
                        className={'transporter ' + clsx("screenOnly", 'transporter-drawer-vehicles-drivers')}
                        classes={{
                            paper: 'transporter-drawer-paper'
                        }}
                        variant="persistent"
                        anchor="right"
                        open={isVehiclesManagementDrawerOpened}
                    >
                        <div className='toolbar-styles' />
                        <VehiclesManagementComponent TransporterId={transporterId} updateListAciveLicencePlate={() => this.updateListAciveLicencePlate(transporterId)}
                            displayError={() => this.displayError} updateVehicleDrawerList={updateVehicleDrawerList}
                            setUpdateVehicleDrawerListToFalse={() => this.setUpdateVehicleDrawerListToFalse()} handleClickOpen={this.handleClickOpen}
                            _handleDrawerVehiclesClose={this.handleCloseVehiclesDrawer} />
                    </Drawer>
                }
                <Drawer
                    className={'transporter ' + clsx("screenOnly", 'transporter-drawer-vehicles-drivers')}
                    classes={{
                        paper: 'transporter-drawer-paper'
                    }}
                    variant="persistent"
                    anchor="right"
                    open={isDriversManagementDrawerOpened}
                >
                    <div className='toolbar-styles' />
                    {!this.props.isForInternalTransporters &&
                        <DriversManagement TransporterId={transporterId} updateListAciveDriver={() => this.updateListAciveDriver(transporterId)}
                            displayError={() => this.displayError} updateDriverDrawerList={updateDriverDrawerList}
                            setUpdateDriverDrawerListToFalse={() => this.setUpdateDriverDrawerListToFalse()} handleClickOpen={this.handleClickOpen}
                            _handleDrawerDriversClose={this.handleCloseDriversDrawer} />
                    }
                    {this.props.isForInternalTransporters &&
                        <OtherTransportersUsingDriversChoicesManagementComponent
                            transporterId={transporterId}
                            isOnlyChosenChecked={isOnlyTransportersUsingDriversChosenChecked}
                            handleDrawerOtherTransportersClose={this.handleCloseDriversDrawer}
                            setIsOnlyChosenChecked={this.setUpdateOnlyTransportersUsingDriversChosenChecked}
                            displayBusinessErrors={this.displayBusinessErrors}
                            updateListAciveDriver={this.updateListAciveDriver}
                        />
                    }
                </Drawer>
                <Modal show={showMsgConfirmOrderWhenEmptyField} onHide={this.handleHideMsgConfirmOrderWhenEmptyField} className='mt-5'>
                    <Modal.Header closeButton>
                        <Modal.Title>ATTENTION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Tous les camions n'ont pas été renseignés.<br />
                        Confirmez-vous l'action ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="secondary" onClick={this.handleHideMsgConfirmOrderWhenEmptyField}>
                            ANNULER
                        </Button>
                        <Button className="primary" onClick={() => this.confirmVehicleTripsStatus()}>
                            CONFIRMER
                        </Button>
                    </Modal.Footer>
                </Modal>
                <SimpleDialog isOpen={this.state.isDialogOpened} onClose={() => this.handleClickClose()} component={popupContentComponent} dialogTitle={dialogTitle} classNameVal={className} />
            </>
        );
    }
}

export default React.forwardRef(withRouter(ThirdPartyTransporterView));