import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Input, Tooltip } from '@mui/material';
import React from 'react';

interface SearchProductionSiteStepComponentProperties {
    productionSiteSearchLoading: boolean,
    productionSiteId: string,
    handleProductionSiteIdentifierChange: (value: string) => void,
    searchProductionSiteByIdentifier: (agencyId: string) => void
}

export const SearchProductionSiteStepComponent = (props: SearchProductionSiteStepComponentProperties): JSX.Element => {

    const addProductionSiteTooltipImage = "/static/Referentials/ProductionSitesReferential/AddProductionSiteTooltipImage.png";

    const searchProductionSite = (): void => {
        if (props.productionSiteId !== '')
            props.searchProductionSiteByIdentifier(props.productionSiteId);
    }

    const isProductionSiteIdValid = (): boolean => {
        const regExp = /^UP[1-9](\d{1,7})?$/i;
        return regExp.test(props.productionSiteId);
    }

    const productionSiteIdIsValid = isProductionSiteIdValid();
    const isSearchButtonDisabled: boolean = props.productionSiteSearchLoading || !productionSiteIdIsValid;

    return (
        <Box className="search-production-site-component">
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column" mb={"20px"}>
                    Information de l'Unité de Production à ajouter:
                </Box>
                <Box display="flex" flexDirection="column" className="production-site-infos">
                    <Box display="flex" flexDirection="row" justifyContent="flex-start">
                        <Box display="flex" flexDirection="column" className="input-text-content">
                            <Box className="production-site-id-input-text-label">Identifiant</Box>
                            <Box display="flex" flexDirection="row">
                                <Input
                                    id="production-site-id"
                                    value={props.productionSiteId}
                                    className="production-site-id-input-text"
                                    error={!productionSiteIdIsValid}
                                    onChange={(e) => props.handleProductionSiteIdentifierChange(e.target.value)}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center" ml={"10px"}>
                            <Tooltip id="add-production-site-tooltip-image" placement="right-start" title={
                                <Box>
                                    <p className="info-user-text">Pour récupérer l'id de l'Unité de Production, vous pouvez accéder à Zephyr sur cet écran. L'identifiant doit obligatoirement commencer par « UP »: </p>
                                    <img src={addProductionSiteTooltipImage} />
                                </Box>
                            }>
                                <Avatar>
                                    <FontAwesomeIcon icon={faQuestion} />
                                </Avatar>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                    <Button variant="contained" color="primary" title="Chercher" onClick={() => searchProductionSite()} disabled={isSearchButtonDisabled}>
                        Chercher
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
