import { GridCellProps } from '@progress/kendo-react-grid';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import '@progress/kendo-react-intl';
import React from 'react';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';
import { PurchaseCostUsedQuantitiesKindEnum } from '../models/PurchaseCostUsedQuantitiesKindEnum';

interface CustomPricesCellProps extends GridCellProps{
    vehicle: PlanningVehicleLightModelExtended
}

export const CustomPricesCell = (props: CustomPricesCellProps) => {
    const dataItem: PlanningVehicleLightModelExtended = props.dataItem;

    const handleChange = (dataItem: PlanningVehicleLightModelExtended, field: string, event: NumericTextBoxChangeEvent): void => {
        dataItem.inEdit = field;
        dataItem.mainUnitPrice = event.target.value;
        if (field == "totalPurchasePrice") {
            dataItem.updating = true;
        }
    }

    const dataValue: number = dataItem[props.field] ? dataItem[props.field] : null;
    const hasSpecificPriceOrNegotiatedPrice: boolean = dataItem.hasSpecificPrice === true || dataItem.hasNegotiatedPrice === true;
    let defaultRendering: JSX.Element = <></>;

    if (!dataItem.inEdit || dataItem.inEdit !== props.field || hasSpecificPriceOrNegotiatedPrice
        || (dataItem.vehicleId == null && props.field == "tollFees") || (dataItem.vehicleId == null && props.field == "othersFees")
        || (dataItem.vehicleId == null && props.field == "waitFees")) {

        const dataItem = props.dataItem as PlanningVehicleLightModelExtended;
        const className = `quantities-used-${!dataItem.kindOfValuesUsedForPrice
            ? "old"
            : dataItem.kindOfValuesUsedForPrice === PurchaseCostUsedQuantitiesKindEnum.MixOfPlannedAndDelivered
            ? "mixed"
                : dataItem.kindOfValuesUsedForPrice === PurchaseCostUsedQuantitiesKindEnum.Planned
                    ? "planned"
                    : "delivered"}`;
        defaultRendering = (
            <td className={dataItem.purchaseIsCanceled ? "purchase-is-canceled" : className}>{!dataValue || dataValue === null ? '' : dataValue + ' €'}</td>
        );
    } else {
        defaultRendering = (
            <td>
                <NumericTextBox
                    defaultValue={dataValue}
                    min={0}
                    width={110}
                    format={{
                        maximumFractionDigits: 2,
                        currencyDisplay: 'symbol',
                        currency: 'EUR',
                        style: 'currency'
                    }}
                    onChange={(event) => handleChange(dataItem, props.field, event)} />
            </td>
        );
    }

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}
