import { Box, Button, debounce } from '@mui/material';
import { GridHeaderSelectionChangeEvent, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import { DateRange } from 'src/shared/models/DateRange';
import { LogisticsUnitChoice } from 'src/shared/models/LogisticsUnitChoice';
import ToastService from 'src/ToastService';
import BusinessErrors from 'src/utils/BusinessErrors';
import { LogisticsUnitSelectModel } from '../../../shared/models/LogisticsUnitSelectModel';
import { AddDieselIndexTemplateComponent } from './components/AddDieselIndexTemplateComponent';
import { AddForeignDieselIndexTemplatesComponent } from './components/AddForeignDieselIndexTemplatesComponent';
import { CnrGasoilIndexesDrawerComponent } from './components/CnrGasoilIndexesDrawerComponent';
import { ContentHeaderComponent } from './components/ContentHeaderComponent';
import { DieselIndexComponent } from './components/DieselIndexComponent';
import { DieselIndexTemplateComponent } from './components/DieselIndexTemplateComponent';
import { TransportersComponent } from './components/TransportersComponent';
import './DieselIndexesReferentialStyles.scss';
import { DieselIndexLightModelExtended } from './models/DieselIndexLightModelExtended';
import { DieselIndexTemplateLightModelExtended } from './models/DieselIndexTemplateLightModelExtended';
import { TransporterLightModelExtended } from './models/TransporterLightModelExtended';
import { AddDieselIndexRequestArgs } from './services/dataContracts/controller/AddDieselIndexRequestArgs';
import { UpdateDieselIndexTemplateRequestArgs } from './services/dataContracts/controller/UpdateDieselIndexTemplateRequestArgs';
import { DieselIndexesReferentialApiClient } from './services/DieselIndexesReferentialApiClient';
import { SizingUtilities } from './SizingUtilities';

interface DieselIndexesReferentialProps {
    logisticsUnits: Array<LogisticsUnitChoice>,
    role: string
}

export const DieselIndexesReferentialView = (props: DieselIndexesReferentialProps): JSX.Element => {
    const inputSearchDieselIndexesRef: React.RefObject<HTMLInputElement> = React.useRef(null);

    const [selectedLogisticsUnit, setSelectedLogisticsUnit] = useState<LogisticsUnitSelectModel>(
        props.logisticsUnits.length == 1 ? { value: props.logisticsUnits[0].logisticsUnitId, label: props.logisticsUnits[0].label } : null
    );

    const minDate: Date = Date.getFirstOfCurrentMonth();
    const maxDate: Date = Date.getLastDayOfCurrentYear();
    const today: Date = new Date();

    const [openDialogNewTemplate, setOpenDialogNewTemplate] = useState<boolean>(false);
    const [openDialogExistingTemplate, setOpenDialogExistingTemplate] = useState<boolean>(false);
    const [heightSeparator, setHeightSeparator] = useState<number>((window.innerHeight - 200));
    const [gridHeight, setGridHeight] = useState<number>();
    const [gridPageSize, setGridPageSize] = useState<number>();
    const [dateRange, setDateRange] = useState<DateRange>({ start: minDate, end: maxDate });
    const [vehicleTypeGroups, setVehicleTypeGroups] = useState<Array<string>>([]);
    const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
    const [loadingDieselIndexes, setLoadingDieselIndexes] = useState<boolean>(false);
    const [loadingTransporters, setLoadingTransporters] = useState<boolean>(false);
    const [lastTimeStampGetDieselIndexTemplates, setLastTimeStampGetDieselIndexTemplates] = useState<number>(Date.now());
    const [lastTimeStampGetDieselIndexes, setLastTimeStampGetDieselIndexes] = useState<number>(Date.now());
    const [lastTimeStampGetDieselIndexesWithTransporters, setLastTimeStampGetDieselIndexesWithTransporters] = useState<number>(Date.now());
    const [lastTimeStampGetTransporters, setLastTimeStampGetTransporters] = useState<number>(Date.now());
    const [dieselIndexTemplates, setDieselIndexTemplates] = useState<Array<DieselIndexTemplateLightModelExtended>>([]);
    const [dieselIndexes, setDieselIndexes] = useState<Array<DieselIndexLightModelExtended>>([]);
    const [transporters, setTransporters] = useState<Array<TransporterLightModelExtended>>([]);
    const [transportersSelected, setTransportersSelected] = useState<Array<TransporterLightModelExtended>>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<DieselIndexTemplateLightModelExtended>(null);
    const [transportersIdsHasAlreadyIndexes, setTransportersIdHasAlreadyIndexes] = useState<Array<string>>([]);
    const [showAllTransportLogisticsUnitsTransporters, setShowAllTransportLogisticsUnitsTransporters] = useState<boolean>(false);
    const [showAllLogisticsUnitsDieselIndexes, setShowAllLogisticsUnitsDieselIndexes] = useState<boolean>(false);
    const [inputSearchDieselIndexValue, setInputSearchDieselIndexValue] = useState<string>('');
    const [isCnrGasoilIndexesDrawerOpened, setIsCnrGasoilIndexesDrawerOpened] = useState<boolean>(false);

    useEffect(() => {
        const gridOffsetFromWindowTop = SizingUtilities.gridOffsetFromWindowTop();
        const gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        const gridPageSize = Number((gridHeight / SizingUtilities.rowHeight).toFixed(0));
        setGridHeight(gridHeight);
        setGridPageSize(gridPageSize);

        if (selectedLogisticsUnit) {
            setLoadingTemplates(true);
            setLoadingTransporters(true);
            setLoadingDieselIndexes(true);
            DieselIndexesReferentialApiClient.GetDieselIndexTemplatesWithDieselIndexesAndVehicleTypeGroupsAndTransporters(selectedLogisticsUnit.value, dateRange.start, dateRange.end, "", true, true)
                .then(res => {
                    const dieselIndexTemplatesData = res[0].data as Array<DieselIndexTemplateLightModelExtended>;
                    dieselIndexTemplatesData.forEach(x => {
                        x.isEditing = false;
                        x.isEditable = true;
                    })

                    setDieselIndexTemplates(dieselIndexTemplatesData);
                    const dieselIndexesData: Array<DieselIndexLightModelExtended> = res[1].data as Array<DieselIndexLightModelExtended>;

                    ExtendDieselIndexes(dieselIndexesData);

                    setDieselIndexes(dieselIndexesData);
                    setVehicleTypeGroups(res[2].data);
                    InitTransporters(res[3].data as Array<TransporterLightModelExtended>);
                })
                .finally(() => {
                    setLoadingTemplates(false);
                    setLoadingTransporters(false);
                    setLoadingDieselIndexes(false);
                });
        }
        else {
            DieselIndexesReferentialApiClient.GetVehicleTypeGroups()
                .then(res => setVehicleTypeGroups(res.data));
        }
    }, []);

    const HandleLogisticsUnitChange = (event: LogisticsUnitSelectModel): void => {
        setSelectedLogisticsUnit(event);
        if (event) {
            SearchDieselIndexTemplatesWithDieselIndexesAndTransporters(event.value, dateRange.start, dateRange.end);
            return;
        }
        setDieselIndexTemplates([]);
        InitTransporters([]);
    }

    const InitTransporters = (transportersData: Array<TransporterLightModelExtended>): void => {
        setTransportersSelected([]);
        setTransportersIdHasAlreadyIndexes([]);
        ForceUpdateSelectedTransporters();

        transportersData.forEach(t => {
            t.isDisabled = true;
        });

        setTransporters(transportersData);
    }

    const SearchDieselIndexTemplatesWithDieselIndexesAndTransporters = (selectedLogisticsUnitId: string, startDate: Date, endDate: Date): void => {
        if (selectedLogisticsUnitId != '') {
            setLoadingTemplates(true);
            setLoadingTransporters(true);
            setLoadingDieselIndexes(true);

            const currentTimeStamp = Date.now();

            DieselIndexesReferentialApiClient.GetDieselIndexTemplatesWithDieselIndexesAndTransporters(selectedLogisticsUnitId, startDate, endDate, inputSearchDieselIndexValue, !showAllTransportLogisticsUnitsTransporters, !showAllLogisticsUnitsDieselIndexes)
                .then(res => {
                    if (currentTimeStamp < lastTimeStampGetDieselIndexTemplates)
                        return;

                    const dieselIndexesData: Array<DieselIndexLightModelExtended> = res[0].data as Array<DieselIndexLightModelExtended>;

                    ExtendDieselIndexes(dieselIndexesData);

                    setDieselIndexes(dieselIndexesData);

                    const dieselIndexTemplatesData = res[1].data as Array<DieselIndexTemplateLightModelExtended>;
                    dieselIndexTemplatesData.forEach(x => {
                        x.isEditing = false;
                        x.isEditable = true;
                    })

                    setDieselIndexTemplates(dieselIndexTemplatesData);
                    InitTransporters(res[2].data as Array<TransporterLightModelExtended>);
                })
                .finally(() => {
                    setLoadingTemplates(false);
                    setLoadingTransporters(false);
                    setLoadingDieselIndexes(false);
                    setSelectedTemplate(null);
                    setLastTimeStampGetDieselIndexTemplates(currentTimeStamp);
                });
        }
    }

    const SearchTransporters = (selectedLogisticsUnitId: string, showAllTransportLogisticsUnitsTransporters: boolean): void => {
        if (selectedLogisticsUnitId != '') {
            setLoadingTransporters(true);

            const currentTimeStamp = Date.now();

            DieselIndexesReferentialApiClient.GetTransporters(selectedLogisticsUnitId, !showAllTransportLogisticsUnitsTransporters)
                .then(res => {
                    if (currentTimeStamp < lastTimeStampGetTransporters)
                        return;

                    const transportersData = res.data as Array<TransporterLightModelExtended>;

                    if (selectedTemplate !== null) {
                        transportersData.forEach(t => {
                            t.isDisabled = false;
                        });

                        if (transportersIdsHasAlreadyIndexes.length > 0) {
                            const transportersHasAlreadyIndexes = transportersData.filter(x => transportersIdsHasAlreadyIndexes.indexOf(x.transporterId) !== -1);

                            if (transportersHasAlreadyIndexes?.length > 0) {
                                transportersHasAlreadyIndexes.forEach(t => {
                                    t.isDisabled = true;
                                });
                            }
                        }
                        setTransporters(transportersData);
                    }
                    else {
                        InitTransporters(transportersData);
                    }
                })
                .finally(() => {
                    setLoadingTransporters(false);
                    setLastTimeStampGetTransporters(currentTimeStamp);
                });
        }
    }

    const SearchDieselIndexTemplates = (selectedLogisticsUnitId: string, startDate: Date, endDate: Date): void => {
        if (selectedLogisticsUnitId != '') {
            setLoadingTemplates(true);
            setSelectedTemplate(null);

            const currentTimeStamp = Date.now();

            DieselIndexesReferentialApiClient.GetDieselIndexTemplates(selectedLogisticsUnitId, startDate, endDate)
                .then(res => {
                    if (currentTimeStamp < lastTimeStampGetDieselIndexTemplates)
                        return;

                    const dieselIndexTemplatesData = res.data as Array<DieselIndexTemplateLightModelExtended>;
                    dieselIndexTemplatesData.forEach(x => {
                        x.isEditing = false;
                        x.isEditable = true;
                    })

                    setDieselIndexTemplates(dieselIndexTemplatesData);
                    InitTransporters([...transporters]);
                })
                .finally(() => {
                    setLoadingTemplates(false);
                    setLastTimeStampGetDieselIndexTemplates(currentTimeStamp);
                });
        }
    }

    const SearchDieselIndexTemplatesWithDieselIndexes = (selectedLogisticsUnitId: string, startDate: Date, endDate: Date, onlyChosenLogisticsUnit: boolean): void => {
        if (selectedLogisticsUnitId != '') {
            setLoadingTemplates(true);
            setSelectedTemplate(null);
            setLoadingDieselIndexes(true);

            const currentTimeStamp = Date.now();

            DieselIndexesReferentialApiClient.GetDieselIndexTemplatesWithDieselIndexes(selectedLogisticsUnitId, startDate, endDate, inputSearchDieselIndexValue, onlyChosenLogisticsUnit)
                .then(res => {
                    const dieselIndexTemplatesData = res[0].data as Array<DieselIndexTemplateLightModelExtended>;
                    dieselIndexTemplatesData.forEach(x => {
                        x.isEditing = false;
                        x.isEditable = true;
                    })

                    setDieselIndexTemplates(dieselIndexTemplatesData);

                    const dieselIndexesData: Array<DieselIndexLightModelExtended> = res[1].data as Array<DieselIndexLightModelExtended>;

                    ExtendDieselIndexes(dieselIndexesData);

                    setDieselIndexes(dieselIndexesData);
                    InitTransporters([...transporters]);
                })
                .finally(() => {
                    setLoadingTemplates(false);
                    setLoadingDieselIndexes(false);
                    setLastTimeStampGetDieselIndexTemplates(currentTimeStamp);
                });
        }
    }

    const SearchDieselIndexesWithTransporters = (selectedLogisticsUnitId: string, startDate: Date, endDate: Date): void => {
        if (selectedLogisticsUnitId != '') {
            setLoadingTransporters(true);
            setLoadingDieselIndexes(true);

            const currentTimeStamp = Date.now();

            DieselIndexesReferentialApiClient.GetDieselIndexesWithTransporters(selectedLogisticsUnitId, startDate, endDate, inputSearchDieselIndexValue, !showAllTransportLogisticsUnitsTransporters, !showAllLogisticsUnitsDieselIndexes)
                .then(res => {
                    if (currentTimeStamp < lastTimeStampGetDieselIndexesWithTransporters)
                        return;

                    const dieselIndexesData: Array<DieselIndexLightModelExtended> = res[0].data as Array<DieselIndexLightModelExtended>;

                    ExtendDieselIndexes(dieselIndexesData);

                    setDieselIndexes(dieselIndexesData);

                    if (selectedTemplate != null) {
                        GetTransportersHavingDieselIndexes(selectedTemplate);
                    }
                })
                .finally(() => {
                    setLoadingTransporters(false);
                    setLoadingDieselIndexes(false);
                    setLastTimeStampGetDieselIndexesWithTransporters(currentTimeStamp);
                });
        }
    }

    const HandleChangeDateRange = (event: DateRange): void => {
        if (event.end != null && event.start != null) {
            const fromTime = event.start.getDayStart();
            const toTime = event.end.getDayStart();

            if (!fromTime.equals(dateRange.start) || !toTime.equals(dateRange.end)) {
                setDateRange({ start: fromTime, end: toTime });
                SearchDieselIndexTemplatesWithDieselIndexes(selectedLogisticsUnit?.value, fromTime, toTime, !showAllLogisticsUnitsDieselIndexes);
                setSelectedTemplate(null);
            }
        }
    }

    const HandleRemoveDieselIndexTemplate = (templateId: string): void => {
        DieselIndexesReferentialApiClient.RemoveDieselIndexTemplate(templateId)
            .then(res => {
                const errors = BusinessErrors.Get(res.data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("Erreur lors de la suppression du modèle d'indice Gazole", errors);
                }
                else {
                    ToastService.showSuccessToast("Suppression du modèle d'indice Gazole réalisée avec succès");
                }
            })
            .finally(() => {
                SearchDieselIndexTemplates(selectedLogisticsUnit?.value, dateRange.start, dateRange.end);
            });
    }

    const HandleClickOpenNewTemplate = (opened: boolean): void => {
        setOpenDialogNewTemplate(opened);
    }

    const HandleClickOpenExistingTemplate = (opened: boolean): void => {
        setOpenDialogExistingTemplate(opened);
    }

    const HandleUpdateDieselIndexTemplate = (requestArgs: UpdateDieselIndexTemplateRequestArgs): void => {
        setLoadingTemplates(true);

        let hasWeightingValue = false;
        requestArgs.weightings.forEach((element) => {
            if (element.weightingValue === null) {
                hasWeightingValue = true;
            }
        });

        if (hasWeightingValue) {
            DisplayError("Les champs de pondération K et le champ C0 doivent être complétés pour ajouter le modèle.");
            setLoadingTemplates(false);
            return;
        }

        DieselIndexesReferentialApiClient.UpdateDieselIndexTemplate(requestArgs)
            .then(res => {
                const data = res?.data;

                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("Erreur lors de la mise à jour du modèle d'indice Gazole", errors);
                }
                else {
                    ToastService.showSuccessToast("Mise à jour du modèle d'indice Gazole réalisée avec succès");
                }
            })
            .finally(() => {
                setLoadingTemplates(false);
                SearchDieselIndexTemplates(selectedLogisticsUnit?.value, dateRange.start, dateRange.end);
            });
    }

    const HandleAddDieselIndexTemplateResult = (errors: Array<string>): void => {
        if (errors.length > 0) {
            ToastService.showErrorToast("Erreur lors de la création du modèle d'indice Gazole", errors);
        }
        else {
            ToastService.showSuccessToast("Création du modèle d'indice Gazole réalisée avec succès");

            SearchDieselIndexTemplates(selectedLogisticsUnit?.value, dateRange.start, dateRange.end);
        }

        HandleClickOpenNewTemplate(false);
    }

    const HandleRemoveDieselIndexesResult = (errors: Array<string>): void => {
        if (errors.length > 0) {
            ToastService.showErrorToast("Erreur lors de la suppression de(s) modèle(s) d'indice(s) Gazole pour le transporteur", errors);
        }
        else {
            ToastService.showSuccessToast("Suppression de(s) modèle(s) d'indice Gazole pour le(s) transporteur(s) réalisée avec succès");
        }
        SearchDieselIndexesWithTransporters(selectedLogisticsUnit?.value, dateRange.start, dateRange.end);
    }

    const HandleForeignIndexTemplateResult = (errors: Array<string>): void => {
        if (errors.length > 0) {
            ToastService.showErrorToast("Erreur lors de la récupération du (des) modèle(s) d'indice Gazole", errors);
        }
        else {
            ToastService.showSuccessToast("Récupération du (des) modèle(s) d'indice Gazole réalisée avec succès");

            SearchDieselIndexTemplates(selectedLogisticsUnit?.value, dateRange.start, dateRange.end);
        }

        HandleClickOpenExistingTemplate(false);
    }

    const HandleSelectedTemplate = (template: DieselIndexTemplateLightModelExtended, selected: boolean): void => {
        const selectedTemplateData = selected ? template : null;
        setSelectedTemplate(selectedTemplateData);

        if (selectedTemplateData != null) {
            GetTransportersHavingDieselIndexes(template);
        }
        else {
            InitTransporters([...transporters]);
        }
    }

    const ForceUpdateSelectedTransporters = () => {
        setLoadingTransporters(true);
        setTimeout(() => {
            setLoadingTransporters(false)
        }
            , 50);
    }

    const GetTransportersHavingDieselIndexes = (selectedTemplateData: DieselIndexTemplateLightModelExtended): void => {
        setLoadingTransporters(true);
        setTransportersSelected([]);
        DieselIndexesReferentialApiClient.GetTransportersHavingDieselIndexes(selectedTemplateData.logisticsUnitId, selectedTemplateData.startDate, selectedTemplateData.endDate)
            .then(res => {
                const transporterIds: Array<string> = res.data;
                const transportersData = [...transporters];

                transportersData.forEach(t => {
                    t.isDisabled = false;
                });

                if (transporterIds.length > 0) {
                    const transportersHasAlreadyIndexes = transportersData.filter(x => transporterIds.indexOf(x.transporterId) !== -1);

                    if (transportersHasAlreadyIndexes?.length > 0) {
                        transportersHasAlreadyIndexes.forEach(t => {
                            t.isDisabled = true;
                        });
                    }
                }
                setTransportersIdHasAlreadyIndexes(transporterIds);
                setTransporters(transportersData);
            })
            .finally(() => {
                setLoadingTransporters(false);
            });
    }

    const HandleChangeTransportersSelected = (selected: Array<TransporterLightModelExtended>): void => {
        setTransportersSelected(selected);
    };

    const HandleClickApplyTemplate = (): void => {
        const requestArgs: AddDieselIndexRequestArgs = {
            logisticsUnitId: selectedLogisticsUnit?.value,
            startDate: selectedTemplate.startDate,
            endDate: selectedTemplate.endDate,
            referenceValue: selectedTemplate.referenceValue,
            transporterIds: transportersSelected.map(x => x.transporterId),
            weightings: selectedTemplate.weightings
        };

        DieselIndexesReferentialApiClient.AddDieselIndexes(requestArgs)
            .then(res => {
                const data = res?.data;

                const errors = BusinessErrors.Get(data);

                if (errors.length > 0) {
                    ToastService.showErrorToast("Erreur lors de la création du (des) indice(s) Gazole", errors);
                }
                else {
                    ToastService.showSuccessToast("Création du (des) indice(s) Gazole réalisée avec succès");

                    SearchDieselIndexTemplatesWithDieselIndexes(selectedLogisticsUnit?.value, dateRange.start, dateRange.end, !showAllLogisticsUnitsDieselIndexes);
                }
            })
            .finally(() => {
                InitTransporters([...transporters]);
            });
    }

    const HandleShowAllTransportLogisticsUnitsTransporters = (): void => {
        const selected = !showAllTransportLogisticsUnitsTransporters;
        setShowAllTransportLogisticsUnitsTransporters(selected);
        SearchTransporters(selectedLogisticsUnit?.value, selected);
    }

    const HandleShowAllLogisticsUnitsDieselIndexes = (): void => {
        const selected = !showAllLogisticsUnitsDieselIndexes;
        setShowAllLogisticsUnitsDieselIndexes(selected);
        SearchDieselIndexes(selectedLogisticsUnit?.value, dateRange.start, dateRange.end, inputSearchDieselIndexValue, !selected);
    }

    const SearchDieselIndexes = (selectedLogisticsUnitId: string, startDate: Date, endDate: Date, inputSearchText: string, onlyChosenLogisticsUnit: boolean): void => {
        if (selectedLogisticsUnitId != '') {
            setLoadingDieselIndexes(true);

            const currentTimeStamp = Date.now();

            DieselIndexesReferentialApiClient.GetDieselIndexes(selectedLogisticsUnitId, inputSearchText, startDate, endDate, onlyChosenLogisticsUnit)
                .then(res => {
                    if (currentTimeStamp < lastTimeStampGetDieselIndexes)
                        return;

                    const dieselIndexesData: Array<DieselIndexLightModelExtended> = res.data as Array<DieselIndexLightModelExtended>;

                    ExtendDieselIndexes(dieselIndexesData);

                    setDieselIndexes(dieselIndexesData);
                })
                .finally(() => {
                    setLoadingDieselIndexes(false);
                    setLastTimeStampGetDieselIndexes(currentTimeStamp);
                });
        }
    }

    const ExtendDieselIndexes = (dieselIndexesData: Array<DieselIndexLightModelExtended>): void => {
        dieselIndexesData.forEach((x) => {
            x.selected = false;
            x.weightings.forEach(y => {
                x[`vehicleTypeGroup${y.vehicleTypeGroupId}`] = y.weightingValue;
            });
            x.referenceValueString = x.referenceValue.toStringFixed("FR-fr", 4);
        })
    }

    const ClearSearchInputDieselIndexText = (): void => {
        inputSearchDieselIndexesRef.current.value = '';
        setInputSearchDieselIndexValue('');
        SearchDieselIndexes(selectedLogisticsUnit?.value, dateRange.start, dateRange.end, '', !showAllLogisticsUnitsDieselIndexes);
    }

    const HandleDieselIndexKeyPress = debounce((searchText: string): void => {
        if (searchText.length >= 3 || searchText.length === 0) {
            inputSearchDieselIndexesRef.current.value = searchText;
            setInputSearchDieselIndexValue(searchText);
            SearchDieselIndexes(selectedLogisticsUnit?.value, dateRange.start, dateRange.end, searchText, !showAllLogisticsUnitsDieselIndexes);
        }
    }, 500);

    const SelectionChange = (event: GridSelectionChangeEvent): void => {
        const dieselIndexesData = [...dieselIndexes];

        const dataItem = event.dataItem as DieselIndexLightModelExtended;

        const data = dieselIndexesData.map((item: DieselIndexLightModelExtended) => {
            if (item.dieselIndexId === dataItem.dieselIndexId) {
                item.selected = !dataItem.selected;
            }
            return item;
        });

        setDieselIndexes(data);
    }

    const HeaderSelectionChange = (event: GridHeaderSelectionChangeEvent): void => {
        const checked = event.nativeEvent.target.checked;
        const dieselIndexesData = [...dieselIndexes];

        const data = dieselIndexesData.map((item: DieselIndexLightModelExtended) => {
            item.selected = checked;
            return item;
        });

        setDieselIndexes(data);
    }

    const HandleEditTemplate = (dieselTemplate: DieselIndexTemplateLightModelExtended, editable: boolean): void => {
        const dieselIndexTemplatesData = [...dieselIndexTemplates];
        if (editable) {
            const elem = dieselIndexTemplatesData.find(x => x.dieselIndexTemplateId !== dieselTemplate.dieselIndexTemplateId && x.isEditing === true);
            if (elem !== undefined)
                return;

            dieselIndexTemplatesData.forEach(x => {
                if (x.dieselIndexTemplateId === dieselTemplate.dieselIndexTemplateId) {
                    x.isEditing = editable;
                }

                else {
                    x.isEditable = false;
                    x.isEditing = false;
                }
            });
        }
        else {
            dieselIndexTemplatesData.forEach(x => {
                x.isEditing = false;
                x.isEditable = true;
            });
        }

        setDieselIndexTemplates(dieselIndexTemplatesData);
    }

    const HandleOpenCloseCnrGasoilIndexesDrawer = (): void => {
        setIsCnrGasoilIndexesDrawerOpened(!isCnrGasoilIndexesDrawerOpened);
    }

    const DisplayError = (error: string): void => {
        ToastService.showErrorToast(error);
    }

    window.onresize = (event: UIEvent) => {
        setHeightSeparator(window.innerHeight - 200);
        const gridOffsetFromWindowTop = SizingUtilities.gridOffsetFromWindowTop();
        const gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        const gridPageSize = Number((gridHeight / SizingUtilities.rowHeight).toFixed(0));
        setGridHeight(gridHeight);
        setGridPageSize(gridPageSize);
    }

    return (
        <div className="diesel-indexes-referential-view">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box display="flex" flexDirection="column" className="diesel-indexes-referential">
                        <ContentHeaderComponent
                            logisticsUnits={props.logisticsUnits}
                            selectedLogisticsUnit={selectedLogisticsUnit}
                            date={dateRange}
                            handleLogisticsUnitChange={HandleLogisticsUnitChange}
                            handleChangeDateRange={HandleChangeDateRange}
                            handleOpenCloseCnrGasoilIndexesDrawer={HandleOpenCloseCnrGasoilIndexesDrawer}
                        />
                        <Box pl={2} pr={2}>
                            <hr className='gasole-separator' />
                        </Box>
                        <Box display="flex">
                            <Box display="flex" flexDirection="row" className={isCnrGasoilIndexesDrawerOpened ? "indexes-content open" : "indexes-content"} pt={1} pl={2}>
                                <Box width="15%" className="template-content" minWidth="350px" display="flex" flexDirection="column" alignItems="center" style={{ height: heightSeparator }}>
                                    <Box pb={2} className="title-column">
                                        Modèles
                                    </Box>
                                    <Box pb={1}>
                                        <Button onClick={() => HandleClickOpenNewTemplate(true)} className={`btn-actions${selectedLogisticsUnit ? '' : ' btn-actions-disabled'} txt-integration`} variant="contained" disabled={!selectedLogisticsUnit}>
                                            Créer un modèle
                                        </Button>
                                    </Box>
                                    <Box pb={1}>
                                        <Button onClick={() => HandleClickOpenExistingTemplate(true)} className={`btn-actions${selectedLogisticsUnit ? '' : ' btn-actions-disabled'} txt-integration`} variant="contained" disabled={!selectedLogisticsUnit}>
                                            Récupérer un modèle
                                        </Button>
                                    </Box>
                                    <Box width="100%" className="list-template">
                                        {loadingTemplates ? <div className='sweet-loading'>
                                            <ScaleLoader
                                                width={5}
                                                height={20}
                                                radius={50}
                                                color={'#000000'}
                                                loading={loadingTemplates}
                                            />
                                        </div> : <Box display="flex" flexDirection="column" pt={1} pb={1} pr={1}>
                                            {dieselIndexTemplates.map((template, index) => {
                                                return <DieselIndexTemplateComponent key={`template-${index}`} minStartDate={dateRange.start} maxStartDate={dateRange.end} templateSelected={selectedTemplate?.dieselIndexTemplateId == template.dieselIndexTemplateId}
                                                    dieselIndexTemplate={template} HandleRemoveDieselIndexTemplate={HandleRemoveDieselIndexTemplate}
                                                    HandleUpdateDieselIndexTemplate={HandleUpdateDieselIndexTemplate} HandleSelectedTemplate={HandleSelectedTemplate} HandleEditTemplate={HandleEditTemplate} />
                                            })}
                                        </Box>}
                                    </Box>
                                </Box>
                                <Box className="vertical-separator" style={{ height: heightSeparator }}></Box>
                                <Box width="25%" minWidth="250px" display="flex" flexDirection="column" alignItems="center" className="transporter-content" style={{ height: heightSeparator }}>
                                    <Box className="title-column">
                                        Transporteurs
                                    </Box>
                                    <TransportersComponent selectedLogisticsUnitId={selectedLogisticsUnit?.value} selectedTemplate={selectedTemplate} loadingTransporters={loadingTransporters}
                                        heightSeparator={heightSeparator} transporters={transporters} showAllTransportLogisticsUnitsTransporters={showAllTransportLogisticsUnitsTransporters}
                                        HandleChange={HandleChangeTransportersSelected} HandleShowAllTransportLogisticsUnitsTransporters={HandleShowAllTransportLogisticsUnitsTransporters}
                                    />
                                </Box>
                                <Box className="vertical-separator" style={{ height: heightSeparator }}></Box>
                                <Box width="60%" className="transporter-template-content" display="flex" flexDirection="column" alignItems="center">
                                    <Box pb={2} className="title-column">
                                        Transporteurs & modèles
                                    </Box>
                                    <Box pb={1} width="100%" display="flex" flexDirection="column" alignItems="center">
                                        <Button onClick={() => HandleClickApplyTemplate()} className={`btn-actions${selectedTemplate === null || transportersSelected == null
                                            || transportersSelected.length === 0 ? ' btn-actions-disabled' : ''} txt-integration`} variant="contained" disabled={selectedTemplate === null || transportersSelected == null || transportersSelected.length === 0}>
                                            Appliquer un modèle
                                        </Button>
                                        <DieselIndexComponent data={dieselIndexes} showAllLogisticsUnitsDieselIndexes={showAllLogisticsUnitsDieselIndexes}
                                            gridHeight={gridHeight} gridPageSize={gridPageSize} vehicleTypeGroups={vehicleTypeGroups}
                                            inputSearchValue={inputSearchDieselIndexValue} inputSearchRef={inputSearchDieselIndexesRef} loading={loadingDieselIndexes}
                                            HandleDieselIndexKeyPress={HandleDieselIndexKeyPress} ClearSearchText={ClearSearchInputDieselIndexText}
                                            HandleShowAllLogisticsUnitsDieselIndexes={HandleShowAllLogisticsUnitsDieselIndexes}
                                            HeaderSelectionChange={HeaderSelectionChange} SelectionChange={SelectionChange} HandleResult={HandleRemoveDieselIndexesResult} />
                                    </Box>
                                </Box>
                            </Box>
                            <CnrGasoilIndexesDrawerComponent
                                isCnrGasoilIndexesDrawerOpened={isCnrGasoilIndexesDrawerOpened}
                                startDate={today}
                                role={props.role}
                            />
                        </Box>
                    </Box>
                    {openDialogNewTemplate &&
                        <AddDieselIndexTemplateComponent selectedLogisticsUnitId={selectedLogisticsUnit?.value} vehicleTypeGroups={vehicleTypeGroups} minStartDate={dateRange.start} maxStartDate={dateRange.end}
                            HandleResult={HandleAddDieselIndexTemplateResult} HandleCloseDialog={() => HandleClickOpenNewTemplate(false)} DisplayError={DisplayError} />
                    }
                    {openDialogExistingTemplate &&
                        <AddForeignDieselIndexTemplatesComponent selectedLogisticsUnitId={selectedLogisticsUnit?.value} startDate={dateRange.start} endDate={dateRange.end}
                            HandleCloseDialog={() => HandleClickOpenExistingTemplate(false)} HandleResult={HandleForeignIndexTemplateResult} />
                    }
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}