import { Box } from '@mui/material';
import React from 'react';
import { VehicleItemModel } from '../models/VehicleItemModel';

interface GenericVehicleItemComponentProps {
    style: React.CSSProperties,
    dataItem: VehicleItemModel,
    handleAssignGenericVehicleType?: (vehicleTypeId: string, vehicleTypeGroupId: string) => void
}

export const GenericVehicleItemComponent = (props: GenericVehicleItemComponentProps): JSX.Element => {
    const { dataItem, style } = props;
    return (
        <Box style={style} display="flex" flexDirection="row" className="vehicle-card" onDoubleClick={() => props.handleAssignGenericVehicleType(dataItem.vehicleTypeId, dataItem.vehicleTypeGroupId)}>
            <Box className="header-vehicle header-generic">
            </Box>
            <Box className="hover-vehicle" alignSelf="center">
                <div className="vehicle-title">
                    {dataItem.vehicleTypeLabel}
                </div>
            </Box>
        </Box>
    )
}
