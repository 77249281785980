import { Box } from '@mui/material';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ToastService from 'src/ToastService';
import SimpleDialog from '../../../shared/components/Common/SimpleDialog';
import { LogisticsUnitChoice } from '../../../shared/models/LogisticsUnitChoice';
import { LogisticsUnitSelectModel } from '../../../shared/models/LogisticsUnitSelectModel';
import BusinessErrors from '../../../utils/BusinessErrors';
import { AddNegotiatedSellPricesDialog } from './components/AddNegotiatedSellPricesDialog';
import { ContentHeaderComponent } from './components/ContentHeaderComponent';
import { NegotiatedSellPricesComponent } from './components/NegotiatedSellPricesComponent';
import { NegotiatedSellPriceLightModelExtended } from './models/NegotiatedSellPriceLightModelExtended';
import './NegotiatedSellPricesReferentialStyles.scss';
import { AddNegotiatedSellPricesArgs } from './services/dataContracts/controller/AddNegotiatedSellPricesArgs';
import { RemoveNegotiatedSellPriceArgs } from './services/dataContracts/controller/RemoveNegotiatedSellPriceArgs';
import { NegotiatedSellPricesReferentialApiClient } from './services/NegotiatedSellPricesReferentialApiClient';

interface NegotiatedSellPricesReferentialProperties {
    logisticsUnits: Array<LogisticsUnitChoice>,
}

export const NegotiatedSellPricesReferentialView = (props: NegotiatedSellPricesReferentialProperties): JSX.Element => {

    const [inputSearchSellPriceValue, setInputSearchSellPriceValue] = useState<string>("");
    const [selectedLogisticsUnit, setSelectedLogisticsUnit] = useState<LogisticsUnitSelectModel>(props.logisticsUnits.length == 1 ? { value: props.logisticsUnits[0].logisticsUnitId, label: props.logisticsUnits[0].label } : null);
    const [isAddSellPriceDialogOpened, setIsAddSellPriceDialogOpened] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isCreateNegotiatedSellPriceInfoModalOpened, setCreateNegotiatedSellPriceInfoModalOpened] = useState<boolean>(false);
    const [negotiatedSellPrices, setNegotiatedSellPrices] = useState<NegotiatedSellPriceLightModelExtended[]>([])

    const inputSearchSellPriceRef: React.RefObject<HTMLInputElement> = React.useRef(null);

    useEffect(() => {
        if (selectedLogisticsUnit)
            getNegotiatedSellPrices(selectedLogisticsUnit.value, "");
    }, []);

    const handleLogisticsUnitChange = (event: LogisticsUnitSelectModel): void => {
        setSelectedLogisticsUnit(event);
        getNegotiatedSellPrices(event ? event.value : "", "");
    }

    const handleOpenAddSellPriceDialog = (): void => {
        setIsAddSellPriceDialogOpened(true);
    }

    const handleCloseAddSellPriceDialog = (): void => {
        setIsAddSellPriceDialogOpened(false);
    }

    const handleRemoveSellPrices = debounce((): void => {
        const negotiatedSellPricesToRemove: RemoveNegotiatedSellPriceArgs[] = negotiatedSellPrices.filter(x => x.selected)
            .map(x => {
                const args: RemoveNegotiatedSellPriceArgs = {
                    negotiatedSellPriceId: x.negotiatedSellPriceId,
                    logisticsUnitId: x.logisticsUnitId
                };
                return args;
            });

        NegotiatedSellPricesReferentialApiClient.RemoveNegotiatedSellPrices(negotiatedSellPricesToRemove)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }
                getNegotiatedSellPrices(selectedLogisticsUnit?.value, inputSearchSellPriceRef.current.value);
            });
    }, 500);

    const clearSearchText = (): void => {
        inputSearchSellPriceRef.current.value = "";
        setInputSearchSellPriceValue("");
        getNegotiatedSellPrices(selectedLogisticsUnit?.value, "");
    }

    const handleSellPriceKeyPress = debounce((searchText: string): void => {
        if (searchText.length >= 3 || searchText.length === 0) {
            inputSearchSellPriceRef.current.value = searchText;
            setInputSearchSellPriceValue(searchText);
            getNegotiatedSellPrices(selectedLogisticsUnit?.value, searchText);
        }
    }, 500);

    const getNegotiatedSellPrices = (logisticsUnitId: string, searchText: string): void => {
        setLoading(true);
        NegotiatedSellPricesReferentialApiClient.SearchNegotiatedSellPrices(logisticsUnitId, searchText)
            .then((res) => {
                const negotiatedSellPricesArray: Array<NegotiatedSellPriceLightModelExtended> = res.data;
                negotiatedSellPricesArray.forEach((element: NegotiatedSellPriceLightModelExtended) => {
                    translatePlanningVehicles(element);
                });
                setNegotiatedSellPrices(negotiatedSellPricesArray);
                setLoading(false);
            });
    }

    const translatePlanningVehicles = (element: NegotiatedSellPriceLightModelExtended): void => {
        element.selected = false;
        element.price = Number(element.price.toFixed(2));
        element.priceKindLabel = element.priceKind === "PerTurn" ? "Tour" : (element.priceKind === "PerTon" ? "Tonne" : "");
    }

    const handleSelectionItemChange = (checked: boolean, dataItem: NegotiatedSellPriceLightModelExtended): void => {
        const negotiatedSellPricesArray = [...negotiatedSellPrices];
        const element = negotiatedSellPricesArray.find(p => p.negotiatedSellPriceId === dataItem.negotiatedSellPriceId);
        element.selected = checked;
        setNegotiatedSellPrices(negotiatedSellPricesArray);
    }

    const addNegotiatedSellPrices = debounce((requestArgs: AddNegotiatedSellPricesArgs): void => {
        NegotiatedSellPricesReferentialApiClient.AddNegotiatedSellPrices(requestArgs)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }
                setIsAddSellPriceDialogOpened(false);

                ToastService.showSuccessToast("Création du tarif de vente négocié réalisée avec succès");
                getNegotiatedSellPrices(selectedLogisticsUnit?.value, inputSearchSellPriceRef.current.value);

                // Pour l'instant la prise en compte des nouveaux tarifs est immédiat, on garde l'info invisible.
                setCreateNegotiatedSellPriceInfoModalOpened(false);
            });
    }, 500);

    const handleCreateNegotiatedSellPriceInfoModal = (): void => {
        setCreateNegotiatedSellPriceInfoModalOpened(false);
    }

    const enableBtnTrash: boolean = negotiatedSellPrices.some(x => x.selected === true);
    const disabledBtnAdd: boolean = selectedLogisticsUnit ? false : true;

    const contentHeaderComponent: JSX.Element = useMemo(() =>
        <ContentHeaderComponent
            logisticsUnits={props.logisticsUnits}
            selectedLogisticsUnit={selectedLogisticsUnit}
            enableBtnTrash={enableBtnTrash}
            disabledBtnAdd={disabledBtnAdd}
            inputSearchSellPriceValue={inputSearchSellPriceValue}
            inputSearchSellPriceRef={inputSearchSellPriceRef}
            handleLogisticsUnitChange={handleLogisticsUnitChange}
            handleOpenAddSellPriceDialog={handleOpenAddSellPriceDialog}
            handleRemoveSellPrices={handleRemoveSellPrices}
            handleSellPriceKeyPress={handleSellPriceKeyPress}
            clearSearchText={clearSearchText}
        />, [selectedLogisticsUnit, enableBtnTrash, inputSearchSellPriceValue]);

    const negotiatedSellPricesComponent: JSX.Element = useMemo(() =>
        <NegotiatedSellPricesComponent
            loading={loading}
            negotiatedSellPrices={negotiatedSellPrices}
            handleSelectionItemChange={handleSelectionItemChange}
        />, [loading, selectedLogisticsUnit, negotiatedSellPrices]);

    return (
        <div className="negotiated-sell-prices-referential-view">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box className="title-section">
                        <h5>
                            Tarifs de vente négociés
                        </h5>
                        <hr className="separator" />
                    </Box>
                    <Box display="flex" flexDirection="column" className="content-section">
                        {contentHeaderComponent}
                        {negotiatedSellPricesComponent}
                    </Box>
                    <SimpleDialog isOpen={isAddSellPriceDialogOpened}
                        onClose={handleCloseAddSellPriceDialog}
                        dialogTitle="Ajouter un nouveau tarif négocié"
                        closeIcon={true}
                        classNameVal="add-sell-price-dialog"
                        headerBorder={true}
                        component={
                            <AddNegotiatedSellPricesDialog
                                selectedLogisticsUnit={selectedLogisticsUnit}
                                negotiatedSellPrices={negotiatedSellPrices}
                                addNegotiatedSellPrices={addNegotiatedSellPrices}
                            />
                        }
                    />
                </IntlProvider>
            </LocalizationProvider>
            <Modal show={isCreateNegotiatedSellPriceInfoModalOpened} onHide={handleCreateNegotiatedSellPriceInfoModal} className='mt-5'
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    La prise en compte des nouveaux tarifs ajoutés peut prendre 3 minutes.
                </Modal.Body>
            </Modal>
        </div>
    );

}