import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import React from 'react';
import TransportRequestChangeSummaryComponent from './components/TransportRequestChangeSummaryComponent';
import { TransportRequestChangeSummaryDateLightModel } from './services/dataContracts/queryStack/TransportRequestChangeSummaryDateLightModel';
import { TransportRequestChangesApiClient } from './services/TransportRequestChangesApiClient';

interface TransportRequestChangesAllViewProperties {
    logisticsUnits: Array<string>,
    closeDrawer: () => void
}

interface TransportRequestChangesAllViewState {
    trackingChanges: TransportRequestChangeSummaryDateLightModel[];
}

export default class TransportRequestChangesAllView extends React.PureComponent<TransportRequestChangesAllViewProperties, TransportRequestChangesAllViewState>{
    _isMounted: boolean;
    constructor(props) {
        super(props);
        this.state = {
            trackingChanges: []
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.showNotifications();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    showNotifications = (): void => {
        const d: Date = new Date((new Date().stripTime()));
        TransportRequestChangesApiClient.GetTransportRequestChangesAll(d, this.props.logisticsUnits)
            .then(res => {
                const elems = res.data;
                if (elems && this._isMounted) {
                    this.setState({
                        trackingChanges: elems
                    });
                }
            })
            .catch(error => console.log(error));
    }

    render() {
        return (
            <div style={{ width: '100%' }}>
                <Card>
                    <CardContent className="drawer-group-notif-dc">
                        <Typography variant="h5">
                            <Box display="flex" flexWrap="nowrap" flexDirection="row">
                                <Box width="80%">
                                    Alertes
                                </Box>
                                <Box display="flex" width="20%" flexDirection="row" justifyContent="flex-end">
                                    <Box style={{ cursor: 'pointer' }} onClick={this.props.closeDrawer}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider style={{ height: '2px' }} />
                        </Typography>
                        <Box display="flex" flexWrap="nowrap" flexDirection="column" p={1}>
                            {
                                this.state.trackingChanges.map((elem: TransportRequestChangeSummaryDateLightModel, index) => {
                                    return (
                                        <div key={`dccs:${index}`}>
                                            <TransportRequestChangeSummaryComponent deliveryDate={elem.deliveryDate} trackingCount={elem.trackingCount} tracking={elem.transportRequestChangesHistory} closeDrawer={this.props.closeDrawer} />
                                        </div>
                                    )
                                })
                            }
                        </Box>
                    </CardContent>
                </Card>
            </div>
        );
    }
}