import { BusinessWarningLight, CommandResultLight, WebAppActionResult } from '../shared/models/WebAppActionResult';

export default class BusinessWarnings {

    private static readonly _emptyWarnings: string[] = [];

    private static readonly _emptyMessages: string[] = [];

    private static readonly WarningsMessages = {

        Generic: new Map<string, string>(
            []),

        PerBusinessarnningType: new Map<string, Map<string, string>>(
            [
                ['TransportRequestWarning', new Map<string, string>([
                    ['TheRemainingQuantityHasBeenMadeNegativeOnAtLeastOneTransportFlow', 'Vous avez généré au moins un flux avec un reste à livrer négatif.'],
                    ['AtLeastOnePlanningVehicleHasPlannedFlowDeliveryTripsChanged', 'Au moins un camion a subi un changement de planification pour ce Flux.'],
                    ['SomeTurnsWereNotCreatedBecauseTheRemainingQuantityIsInsufficient', 'La quantité restante à livrer pour ce Flux ne permet pas la création de tous les tours demandés.'],
                    ['RequestQuantityMoreThanTransportFlowRemainingQuantity', 'Le reste à livrer d\'au moins un Flux ne permet pas le déplacement de tous les tours sélectionnés.'],
                    ['CanMoveOnlyTripsWithStatusInTransportNeededOrTransportPlannedOrCanceled', 'Seuls les camions associés à des tours au statut GÉNÉRIQUE, PLANIFIÉ ou ANNULÉ peuvent être modifiés.'],
                    ['ActionCannotBePerformedOnACanceledPlanningVehicle', 'Impossible d\'effectuer l\'action sur un camion au statut "Annulé".']
                ])],
                ['PlanningVehicleWarning', new Map<string, string>([
                    ['PlanningVehicleHasProvisionings', 'Attention, au moins un camion n\'a pas été supprimé car appartenant à un lot dans les provisions d\'achats.'],
                    ['PlanningVehicleHasTransportFlowsWithPreInvoices', 'Attention, au moins un camion n\'a pas été supprimé car associé à un BL de ventes.'],
                    ['PlanningVehicleIsAlreadyCanceled', 'Le camion est déjà annulé.'],
                    ['PlanningVehicleIsAlreadyReinstated', 'Le camion est déjà rétabli.']
                ])],
                ['ProductionSiteWarning', new Map<string, string>([
                    ['AtLeastOneProductionSiteWasNotFoundAndSkipped', 'Attention, au moins un site de production n\'a pas été associé car n\'existe plus.']
                ])],
                ['PurchaseProvisioningWarning', new Map<string, string>([
                    ['SumOfPurchaseCostAndGasoilExceedsTheMaximumAllowedLimit', 'La somme des lignes "Transport" et "Gazole" dépasse la limite autorisée.']
                ])],
            ]),
    };

    private static getMessages = (commandResult: CommandResultLight): string[] => {

        if (commandResult && commandResult.businessWarnings && commandResult.businessWarnings.length > 0) {
            const warningMessages: string[] = [];
            commandResult.businessWarnings.forEach((warning: BusinessWarningLight) => {
                const warningType = warning.type.substring(warning.type.lastIndexOf('.') + 1, warning.type.length);
                const perTypeBusinessWarnings = BusinessWarnings.WarningsMessages.PerBusinessarnningType.get(warningType) ? BusinessWarnings.WarningsMessages.PerBusinessarnningType.get(warningType) : BusinessWarnings.WarningsMessages.Generic;

                let warningText = perTypeBusinessWarnings.get(warning.name);
                warningText = warningText ? warningText : warning.name;
                warningMessages.push(warningText);
            });

            return warningMessages;
        }

        return BusinessWarnings._emptyMessages;
    }

    public static Get = (data: WebAppActionResult): string[] => {

        if (data && data.commandResults && data.commandResults.length > 0) {
            let businessWarnings: string[] = null;
            data.commandResults.forEach(item => {
                if (item.hasBusinessWarnings) {
                    businessWarnings = (businessWarnings ?? []).concat(BusinessWarnings.getMessages(item));
                }
            });
            return businessWarnings ?? BusinessWarnings._emptyWarnings;
        }

        return BusinessWarnings._emptyWarnings;
    }

}