import { Box } from '@mui/material';
import React from 'react';
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { VehicleItemModel } from '../models/VehicleItemModel';
import { VehicleSelectorType } from '../models/VehicleSelectorType';
import { InternalVehiclesChoiceLightModel } from '../services/dataContracts/queryStack/InternalVehiclesChoiceLightModel';
import { ReservedTransporterVehicleTypeLightModel } from '../services/dataContracts/queryStack/ReservedTransporterVehicleTypeLightModel';
import { TransportPurchasePriceDatesLightModel } from '../services/dataContracts/queryStack/TransportPurchasePriceDatesLightModel';
import { SizingUtilities } from '../SizingUtilities';
import { ContractedVehicleItemComponent } from './ContractedVehicleItemComponent';
import { DailyVehicleItemComponent } from './DailyVehicleItemComponent';
import { GenericVehicleItemComponent } from './GenericVehicleItemComponent';
import { InternalVehicleItemComponent } from './InternalVehicleItemComponent';
import { TransporterVehicleItemComponent } from './TransporterVehicleItemComponent';

interface VehicleGridComponentProperties {
    vehicles: VehicleItemModel[],
    handleAssignGenericVehicleType?: (vehicleTypeId: string, vehicleTypeGroupId: string) => void,
    handleAssignTransporterVehicleType?: (vehicleTypeId: string, transporterId: string, transportPurchasePriceDatesLightModel: TransportPurchasePriceDatesLightModel[], vehicleTypeGroupId: string) => void,
    handleAssignInternalVehicle?: (transporterId: string, vehicleId: number, vehicleTypeId: string, equipmentId: string, driverId: number, licencePlate: string, internalVehiclesChoicesLightModel: InternalVehiclesChoiceLightModel[], vehicleTypeGroupId: string) => void,
    handleAssignDailyPlanningVehicle?: (dailyPlanningVehicleId: string, vehicleId: number, licencePlate: string, transporterId: string, vehicleTypeId: string, vehicleTypeGroupId: string) => void,
    handleVehicleHiddenStateChange?: (planningVehicleId: string, vehicleId: number) => void,
    handleAssignContractedVehicle: (contractualPurchasePriceId: number, transporterId: string, vehicleTypeGroupId: string) => void,
    confirmedReservations: Map<string, Map<string, ReservedTransporterVehicleTypeLightModel>>
}

export const VehicleGridComponent = (props: VehicleGridComponentProperties): JSX.Element => {

    const renderRow = (properties: ListChildComponentProps) => {
        const { index, style } = properties;
        const itemStyle: React.CSSProperties = { ...style, width: "99%" };
        const dataItem = props.vehicles[index];

        switch (dataItem.vehicleItemType) {
            case VehicleSelectorType.generic:
                return (
                    <GenericVehicleItemComponent style={itemStyle} dataItem={dataItem}
                        handleAssignGenericVehicleType={props.handleAssignGenericVehicleType}
                    />
                );
            case VehicleSelectorType.transporter:
                return (
                    <TransporterVehicleItemComponent style={itemStyle} dataItem={dataItem}
                        handleAssignTransporterVehicleType={props.handleAssignTransporterVehicleType}
                        confirmedReservations={props.confirmedReservations}
                    />
                );
            case VehicleSelectorType.internal:
                return (
                    <InternalVehicleItemComponent style={itemStyle} dataItem={dataItem}
                        handleAssignDailyPlanningVehicle={props.handleAssignDailyPlanningVehicle}
                        handleAssignInternalVehicle={props.handleAssignInternalVehicle}
                        handleVehicleHiddenStateChange={props.handleVehicleHiddenStateChange}
                    />
                );
            case VehicleSelectorType.daily:
                return (
                    <DailyVehicleItemComponent style={itemStyle} dataItem={dataItem}
                        handleVehicleHiddenStateChange={props.handleVehicleHiddenStateChange}
                        handleAssignDailyPlanningVehicle={props.handleAssignDailyPlanningVehicle}
                    />
                );
            case VehicleSelectorType.contracted:
                return (
                    <ContractedVehicleItemComponent style={itemStyle} dataItem={dataItem}
                        handleAssignContractedVehicle={props.handleAssignContractedVehicle}
                        confirmedReservations={props.confirmedReservations}
                    />
                );
            default:
                return (
                    <div style={style}>{`/!\\ VehicleType ${dataItem.vehicleItemType} n'a pas de composant implémenté /!\\`}</div>
                );
        }
    }

    const itemSize = SizingUtilities.itemSize;
    const listWidth = SizingUtilities.listWidth;

    return (
        props.vehicles.length > 0 ?
            <Box height="100%">
                <AutoSizer>
                    {({ height }) => (
                        <FixedSizeList height={height} width={listWidth} itemSize={itemSize} itemCount={props.vehicles.length}>
                            {renderRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </Box>
            :
            <></>
    )
}
