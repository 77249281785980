import axios, { AxiosResponse } from 'axios';
import { PagedResult } from 'src/shared/models/PagedResult';
import { SearchPlanningVehiclesRequestArgs } from './controller/SearchPlanningVehiclesRequestArgs';
import { PlanningVehicleLightModel } from './queryStack/PlanningVehicleLightModel';
import { TransporterLightModel } from './queryStack/TransporterLightModel';
import { PublishedCostsSearchOption } from './controller/PublishedCostsSearchOption';
import { WebAppActionResult } from 'src/shared/models/WebAppActionResult';
import { PlanningVehicleChangeRequestArgs } from './controller/PlanningVehicleChangeRequestArgs';
import { ProvisioningGenerationRequestArgs } from './controller/ProvisioningGenerationRequestArgs';
import { SearchPurchaseProvisioningToSendRequestArgs } from './controller/SearchPurchaseProvisioningToSendRequestArgs';
import { PurchaseProvisioningLightModel } from './queryStack/PurchaseProvisioningLightModel';
import { PurchaseProvisioningRemarksRequestArgs } from './controller/PurchaseProvisioningRemarksRequestArgs';
import { PurchaseProvisioningOverridenGasoilIndexRequestArgs } from './controller/PurchaseProvisioningOverridenGasoilIndexRequestArgs';
import { PurchaseProvisioningDetailLightModel } from './queryStack/PurchaseProvisioningDetailLightModel';
import { SendPurchaseProvisioningRequestArgs } from './controller/SendPurchaseProvisioningRequestArgs';
import { PurchaseProvisioningVisibilityInBIRequestArgs } from './controller/PurchaseProvisioningVisibilityInBIRequestArgs';
import { RegularizationPurchaseProvisioningRequestArgs } from './controller/RegularizationPurchaseProvisioningRequestArgs';
import { BaseOfPurchaseCostIndexableRequestArgs } from './controller/BaseOfPurchaseCostIndexableRequestArgs';

const controllerPath = "api/PurchaseProvisionings/";

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

export class PurchaseProvisioningsApiClient {
    public static SearchTransporters = (fromTime: Date, untilTime: Date, searchText: string, logisticsUnitId: string)
        : Promise<AxiosResponse<PagedResult<TransporterLightModel>>> => {
        const url = `${controllerPath}SearchTransporters?fromTime=${encodeURIComponent(fromTime.toJSON())}&logisticsUnitId=${logisticsUnitId}&untilTime=${encodeURIComponent(untilTime.toJSON())}&searchText=${encodeURIComponent(searchText)}&pageIndex=${null}&pageSize=${null}`
        return axios({
            method: 'POST',
            url: url,
            headers: headers
        });
    }

    public static SearchPlanningVehicles = (fromTime: Date, untilTime: Date, searchText: string, publishedCostsSearchOptions: Array<PublishedCostsSearchOption>, selectedTransporters: Array<string>, logisticsUnitId: string)
        : Promise<AxiosResponse<Array<PlanningVehicleLightModel>>> => {
        const body: SearchPlanningVehiclesRequestArgs = {
            selectedTransporters: selectedTransporters,
            logisticsUnitId: logisticsUnitId,
            fromTime: fromTime,
            untilTime: untilTime,
            searchText: searchText,
            publishedCostsSearchOption: publishedCostsSearchOptions
        };

        const url =`${controllerPath}SearchPlanningVehicles`;

        return axios({
            method: 'POST',
            url: url,
            headers: headers,
            data: JSON.stringify(body)
        });
    }

    public static SearchPurchaseProvisioningDetails = (purchaseProvisioningId: string)
        : Promise<AxiosResponse<Array<PurchaseProvisioningDetailLightModel>>> => {

        const url =`${controllerPath}SearchPurchaseProvisioningDetails?purchaseProvisioningId=${encodeURIComponent(purchaseProvisioningId)}`;

        return axios.get(url);
    }

    public static SearchPurchaseProvisioningsToIntegrate = (fromTime: Date, untilTime: Date, searchText: string, logisticsUnitId: string)
        : Promise<AxiosResponse<Array<PurchaseProvisioningLightModel>>> => {
        const body: SearchPurchaseProvisioningToSendRequestArgs = {
            logisticsUnitId: logisticsUnitId,
            fromTime: fromTime,
            untilTime: untilTime,
            searchText: searchText
        };

        const url =`${controllerPath}SearchPurchaseProvisioningsToIntegrate`;

        return axios({
            method: 'POST',
            url: url,
            headers: headers,
            data: JSON.stringify(body)
        });
    }

    public static SearchIntegratedProvisionings = (fromTime: Date, untilTime: Date, searchText: string, logisticsUnitId: string)
        : Promise<AxiosResponse<Array<PurchaseProvisioningLightModel>>> => {
        const body: SearchPurchaseProvisioningToSendRequestArgs = {
            logisticsUnitId: logisticsUnitId,
            fromTime: fromTime,
            untilTime: untilTime,
            searchText: searchText
        };

        const url =`${controllerPath}SearchIntegratedProvisionings`;

        return axios({
            method: 'POST',
            url: url,
            headers: headers,
            data: JSON.stringify(body)
        });
    }

    public static SearchPurchaseProvisioningById = (purchaseProvisioningId: string)
        : Promise<AxiosResponse<PurchaseProvisioningLightModel>> => {

        const url =`${controllerPath}SearchPurchaseProvisioningById?purchaseProvisioningId=${encodeURIComponent(purchaseProvisioningId)}`;

        return axios.get(url);
    }

    public static GetVehicleTypeGroups = ()
        : Promise<AxiosResponse<Array<string>>> => {
        return axios.get(`${controllerPath}GetVehicleTypeGroups`);
    }

    public static SearchPlanningVehiclesWithTransporters = (fromTime: Date, untilTime: Date, searchText: string, publishedCostsSearchOptions: Array<PublishedCostsSearchOption>, selectedTransporters: Array<string>, logisticsUnitId: string)
        : Promise<[AxiosResponse<PagedResult<TransporterLightModel>>, AxiosResponse<PlanningVehicleLightModel[]>]> => {
        return Promise.all(
            [
                PurchaseProvisioningsApiClient.SearchTransporters(fromTime, untilTime, searchText, logisticsUnitId),
                PurchaseProvisioningsApiClient.SearchPlanningVehicles(fromTime, untilTime, searchText, publishedCostsSearchOptions, selectedTransporters, logisticsUnitId)
            ]);
    }

    public static SearchPlanningVehiclesWithProvisionings = (fromTime: Date, untilTime: Date, searchText: string, searchTextIntegratedProvisionings: string, searchTextProvisioningsToIntegrate: string, publishedCostsSearchOptions: Array<PublishedCostsSearchOption>, selectedTransporters: Array<string>, logisticsUnitId: string)
        : Promise<[AxiosResponse<PlanningVehicleLightModel[]>
        , AxiosResponse<PurchaseProvisioningLightModel[]>, AxiosResponse<Array<PurchaseProvisioningLightModel>>]> => {
        return Promise.all(
            [
                PurchaseProvisioningsApiClient.SearchPlanningVehicles(fromTime, untilTime, searchText, publishedCostsSearchOptions, selectedTransporters, logisticsUnitId),
                PurchaseProvisioningsApiClient.SearchPurchaseProvisioningsToIntegrate(fromTime, untilTime, searchTextProvisioningsToIntegrate, logisticsUnitId),
                PurchaseProvisioningsApiClient.SearchIntegratedProvisionings(fromTime, untilTime, searchTextIntegratedProvisionings, logisticsUnitId)
            ]);
    }

    public static SearchPlanningVehiclesWithTransportersAndProvisionings = (fromTime: Date, untilTime: Date, searchText: string, searchTextIntegratedProvisionings: string, searchTextProvisioningsToIntegrate: string, publishedCostsSearchOptions: Array<PublishedCostsSearchOption>, selectedTransporters: Array<string>, logisticsUnitId: string)
        : Promise<[AxiosResponse<PagedResult<TransporterLightModel>>, AxiosResponse<PlanningVehicleLightModel[]>
        , AxiosResponse<Array<PurchaseProvisioningLightModel>>, AxiosResponse<Array<PurchaseProvisioningLightModel>>]> => {
        return Promise.all(
            [
                PurchaseProvisioningsApiClient.SearchTransporters(fromTime, untilTime, searchText, logisticsUnitId),
                PurchaseProvisioningsApiClient.SearchPlanningVehicles(fromTime, untilTime, searchText, publishedCostsSearchOptions, selectedTransporters, logisticsUnitId),
                PurchaseProvisioningsApiClient.SearchPurchaseProvisioningsToIntegrate(fromTime, untilTime, searchTextProvisioningsToIntegrate, logisticsUnitId),
                PurchaseProvisioningsApiClient.SearchIntegratedProvisionings(fromTime, untilTime, searchTextIntegratedProvisionings, logisticsUnitId)
            ]);
    }

    public static SearchPlanningVehiclesWithTransportersAndVehicleTypeGroupsAndProvisionings = (fromTime: Date, untilTime: Date, searchText: string, searchTextIntegratedProvisionings: string, searchTextProvisioningsToIntegrate: string, publishedCostsSearchOptions: Array<PublishedCostsSearchOption>, selectedTransporters: Array<string>, logisticsUnitId: string)
        : Promise<[AxiosResponse<PagedResult<TransporterLightModel>>, AxiosResponse<PlanningVehicleLightModel[]>, AxiosResponse<Array<string>>
        , AxiosResponse<Array<PurchaseProvisioningLightModel>>, AxiosResponse<Array<PurchaseProvisioningLightModel>>]> => {
        return Promise.all(
            [
                PurchaseProvisioningsApiClient.SearchTransporters(fromTime, untilTime, searchText, logisticsUnitId),
                PurchaseProvisioningsApiClient.SearchPlanningVehicles(fromTime, untilTime, searchText, publishedCostsSearchOptions, selectedTransporters, logisticsUnitId),
                PurchaseProvisioningsApiClient.GetVehicleTypeGroups(),
                PurchaseProvisioningsApiClient.SearchPurchaseProvisioningsToIntegrate(fromTime, untilTime, searchTextProvisioningsToIntegrate, logisticsUnitId),
                PurchaseProvisioningsApiClient.SearchIntegratedProvisionings(fromTime, untilTime, searchTextIntegratedProvisionings, logisticsUnitId)
            ]);
    }

    public static UpdatePlanningVehicleRemarks = (planningVehicleId: string, planningVehicleRemarks: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const planningVehicleChange: PlanningVehicleChangeRequestArgs = {
            planningVehicleId: planningVehicleId,
            planningVehicleRemarks: planningVehicleRemarks,
            costsRemarksOfLogistician: null
        }
        return axios({
            method: 'POST',
            url: `${controllerPath}UpdatePlanningVehicleRemarks`,
            headers: headers,
            data: JSON.stringify(planningVehicleChange)
        });
    }

    public static UpdateBaseOfPurchaseCostIndexableWithGasoilIndex = (requestArgs: BaseOfPurchaseCostIndexableRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerPath}UpdateBaseOfPurchaseCostIndexableWithGasoilIndex`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdatePlanningVehicleCostsRemarks = (planningVehicleId: string, costsRemarks: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const planningVehicleChange: PlanningVehicleChangeRequestArgs = {
            planningVehicleId: planningVehicleId,
            planningVehicleRemarks: null,
            costsRemarksOfLogistician: costsRemarks
        }
        return axios({
            method: 'POST',
            url: `${controllerPath}UpdatePlanningVehicleCostsRemarksOfLogistician`,
            headers: headers,
            data: JSON.stringify(planningVehicleChange)
        });
    }

    public static ProvisioningGeneration = (requestArgs: ProvisioningGenerationRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const url = controllerPath + "ProvisioningGeneration";
        return axios.post(url, requestArgs, { headers: headers });
    }

    public static UpdatePurchaseProvisioningRemarks(purchaseProvisioningId: string, remarks: string)
        : Promise<AxiosResponse<WebAppActionResult>> {
        const purchaseProvisioningChange: PurchaseProvisioningRemarksRequestArgs = {
            purchaseProvisioningId: purchaseProvisioningId,
            remarks: remarks
        }
        return axios({
            method: 'POST',
            url: controllerPath + 'UpdatePurchaseProvisioningRemarks',
            headers: headers,
            data: JSON.stringify(purchaseProvisioningChange)
        });
    }

    public static UpdatePurchaseProvisioningOverridenGasoilIndex(purchaseProvisioningId: string, overridenGasoilIndex?: number)
        : Promise<AxiosResponse<WebAppActionResult>> {
        const purchaseProvisioningChange: PurchaseProvisioningOverridenGasoilIndexRequestArgs = {
            purchaseProvisioningId: purchaseProvisioningId,
            overridenGasoilIndex: overridenGasoilIndex
        }
        return axios({
            method: 'POST',
            url: controllerPath + 'UpdatePurchaseProvisioningOverridenGasoilIndex',
            headers: headers,
            data: JSON.stringify(purchaseProvisioningChange)
        });
    }

    public static UpdatePurchaseProvisioningVisibilityInBI(purchaseProvisioningId: string, visibleInBI: boolean)
        : Promise<AxiosResponse<WebAppActionResult>> {
        const purchaseProvisioningChange: PurchaseProvisioningVisibilityInBIRequestArgs = {
            purchaseProvisioningId: purchaseProvisioningId,
            visibleInBI: visibleInBI
        }
        return axios({
            method: 'POST',
            url: controllerPath + 'UpdatePurchaseProvisioningVisibilityInBI',
            headers: headers,
            data: JSON.stringify(purchaseProvisioningChange)
        });
    }

    public static SendPurshaseProvisioning = (requestData: SendPurchaseProvisioningRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {

        return axios.post(`${controllerPath}SendPurchaseProvisioning`, requestData, { headers: headers });
    }

    public static RemovePurchaseProvisioning = (purchaseProvisioningId: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerPath + `RemovePurchaseProvisioning?purchaseProvisioningId=${purchaseProvisioningId}`,
            headers: headers
        });
    }

    public static GenerateRegularizationPurchaseProvisionings = (regularizations: Array<RegularizationPurchaseProvisioningRequestArgs>)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerPath + `GenerateRegularizationPurchaseProvisionings`,
            headers: headers,
            data: JSON.stringify(regularizations)
        });
    }
}