import { GridCellProps } from '@progress/kendo-react-grid';
import { NumericTextBox, NumericTextBoxBlurEvent } from '@progress/kendo-react-inputs';
import React, { useState } from 'react';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';

interface BaseOfPurchaseCostIndexableCellProperties extends GridCellProps{
    purchaseProvisioningIsFinished: boolean
    width: number
}

export const BaseOfPurchaseCostIndexableCell = (props: BaseOfPurchaseCostIndexableCellProperties) => {
    const [dataValue, setDataValue] = useState<number>(props.dataItem[props.field]);

    const handleChange = (dataItem: PlanningVehicleLightModelExtended, field: string, event: NumericTextBoxBlurEvent): void => {
        setDataValue(event.target.value as number);

        dataItem.inEdit = field;
        dataItem[field] = event.target.value;
    }

    let defaultRendering: JSX.Element = <></>;

    if (!props.dataItem.inEdit || props.dataItem.inEdit !== props.field || props.purchaseProvisioningIsFinished) {
        let className = "";
        if (props.dataItem.baseOfPurchaseCostIndexableWithGasoilIndex !== props.dataItem.provisionedPurchaseCost) {
            className += "purchase-cost-overriden";
        }
        if (props.dataItem.isNightWork) {
            className += " purchase-cost-is-nightwork";
        }
        
        defaultRendering = (
            <td className={className}>
                {dataValue?.toCurrencyString() ?? ''}
            </td>
        );
    } else {
        defaultRendering = (
            <td>
                <NumericTextBox className="input-cell-override"
                    defaultValue={dataValue}
                    min={0}
                    format={{
                        maximumFractionDigits: 2
                    }}
                    spinners={false}
                    onChange={(event) => handleChange(props.dataItem, props.field, event)}
                    width={props.width} />
            </td>
        );
    }

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}