import { faCamera, faEuroSign, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Divider, IconButton, Input, InputAdornment, Tooltip } from '@mui/material';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import Select, { ActionMeta, components, OptionProps } from 'react-select';
import imgSendZephyr from 'src/assets/images/logoSendZephyr.png';
import imgSendZephyrDisabled from 'src/assets/images/logoSendZephyrGray.png';
import { FieldDescriptor } from 'src/shared/components/TextSearchFieldsSelector/models/FieldDescriptor';
import { TextSearchFieldsSelectorComponent } from 'src/shared/components/TextSearchFieldsSelector/TextSearchFieldsSelectorComponent';
import { CalendarComponent } from '../../../shared/components/Common/CalendarComponent';
import { DateRange } from '../../../shared/models/DateRange';
import { LogisticsUnitChoice } from '../../../shared/models/LogisticsUnitChoice';
import '../../../utils/Number';
import { AppModule, LocalStorage } from '../../../utils/Storage';
import { GroupLabelFilter } from '../models/GroupLabelFilter';
import { OptionSelectFilter } from '../models/OptionSelectFilter';
import { PreInvoiceCosts } from '../models/PreInvoiceCosts';
import { TextSearchField } from '../services/dataContracts/controller/TextSearchField';

const Option = (props: OptionProps<LogisticsUnitChoice>) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.data.label}</label>
            </components.Option>
        </div>
    );
};

interface ContentHeaderComponentProps {
    logisticsUnits: Array<LogisticsUnitChoice>,
    inputSearchTransportSellValue: string,
    inputSearchTransportSellRef: React.MutableRefObject<HTMLInputElement>,
    path: string,
    hasAnyPreInvoiceSelected: boolean,
    hasAnyFlowToPreInvoicesSelected: boolean,
    hasAnyVehicles: boolean,
    nbTodoPreInvoices: number,
    date: DateRange,
    openMargin: boolean,
    filterOptions: Array<FieldDescriptor<TextSearchField>>,
    filterSelectOptions: Array<FieldDescriptor<OptionSelectFilter>>,
    groupLabelFilter: GroupLabelFilter,
    preInvoiceCosts: PreInvoiceCosts,
    ModuleKey: AppModule,
    LogisticsUnitsSelectedOptionsStorageKey: string
    handleChangeDateRange: (data: DateRange) => void,
    handleLogisticsUnitsChange: (selectedLogisticsUnitIds: string[]) => void,
    handleChangeFilterSelect: (optionValue: OptionSelectFilter) => void,
    generatePreInvoicesFromTransportFlows: (shouldForceFreshDataFromFlow: boolean) => void,
    sendIntegrationPreInvoices: () => void,
    clearSearchText: () => void,
    handleTransportSellKeyPress: (searchText: string) => void,
    handleMarginVehiclesOpen: (open: boolean) => void,
    onChangeTextSearchFields: (filtersSelected: Array<TextSearchField>) => void,
    handleChangeGroup: (groupFilter: GroupLabelFilter) => void
}

export const ContentHeaderComponent = (props: ContentHeaderComponentProps): JSX.Element => {
    const { nbTodoPreInvoices, openMargin, logisticsUnits, ModuleKey, LogisticsUnitsSelectedOptionsStorageKey } = props;

    const [filterSelected, setFilterSelected] = useState<FieldDescriptor<OptionSelectFilter>>(null);
    const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);
    const [selectedOptions, setSelectedOptions] = useState<LogisticsUnitChoice[]>([]);
    const [openMenu, setOpenMenu] = useState<boolean>(false);

    useEffect(() => {
        if (logisticsUnits.length === 1) {
            LocalStorage.SetItem(ModuleKey, LogisticsUnitsSelectedOptionsStorageKey, JSON.stringify([logisticsUnits[0]]));
            setSelectedOptions([logisticsUnits[0]]);
        }
        else {
            const logisticsUnitsSelectedOptionsStorage = LocalStorage.GetItem(ModuleKey, LogisticsUnitsSelectedOptionsStorageKey);
            const options: LogisticsUnitChoice[] = logisticsUnitsSelectedOptionsStorage ? JSON.parse(logisticsUnitsSelectedOptionsStorage) : [];

            setSelectedOptions(options.length === logisticsUnits.length ? [{ label: "Tout", logisticsUnitId: "-1", checked: false, isQuotationAdministrator: false }, ...options] : options);
        }
    }, []);

    const stopPropagationEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const handleIsDateRangeValidChanged = (isValid: boolean): void => {
        setIsDateRangeValid(isValid);
    }

    const handleChangeFilterSelected = (opt: FieldDescriptor<OptionSelectFilter>) => {
        setFilterSelected(opt);

        props.handleChangeFilterSelect(opt?.value);
    }

    const handleChange = (selectedOptions: LogisticsUnitChoice[], event: ActionMeta<LogisticsUnitChoice>): void => {
        switch (event.action) {
            case "select-option":
                if (event.option.logisticsUnitId === "-1" || selectedOptions.length === logisticsUnits.length) {
                    setSelectedOptions([{ label: "Tout", logisticsUnitId: "-1", checked: false, isQuotationAdministrator: false }, ...logisticsUnits]);
                    LocalStorage.SetItem(ModuleKey, LogisticsUnitsSelectedOptionsStorageKey, JSON.stringify([...logisticsUnits]));
                    break;
                }

                setSelectedOptions(selectedOptions);
                LocalStorage.SetItem(ModuleKey, LogisticsUnitsSelectedOptionsStorageKey, JSON.stringify(selectedOptions));
                break;
            case "deselect-option":
                if (event.option.logisticsUnitId === "-1") {
                    setSelectedOptions([]);
                    LocalStorage.SetItem(ModuleKey, LogisticsUnitsSelectedOptionsStorageKey, JSON.stringify([]));
                    break;
                }

                if (selectedOptions.find(x => x.logisticsUnitId === "-1")) {
                    const options = selectedOptions.filter(x => x.logisticsUnitId !== "-1");
                    setSelectedOptions(options);
                    LocalStorage.SetItem(ModuleKey, LogisticsUnitsSelectedOptionsStorageKey, JSON.stringify(options));
                    break;
                }

                setSelectedOptions(selectedOptions);
                LocalStorage.SetItem(ModuleKey, LogisticsUnitsSelectedOptionsStorageKey, JSON.stringify(selectedOptions));
                break;
            default:
                setSelectedOptions(selectedOptions);
                LocalStorage.SetItem(ModuleKey, LogisticsUnitsSelectedOptionsStorageKey, JSON.stringify(selectedOptions));
                break;
        }
    };

    const handleSearch = () => {
        const selectedLogisticsUnitIds = selectedOptions.filter(x => x.logisticsUnitId !== "-1").map(x => x.logisticsUnitId);
        props.handleLogisticsUnitsChange(selectedLogisticsUnitIds);
        setOpenMenu(false);
    }

    const btnZephyrDisabled = !props.hasAnyPreInvoiceSelected;

    return (
        <Box display="flex" flexDirection="column" className="content-header">
            <Box display="flex" flexDirection="row" flexWrap='nowrap' alignContent="center" alignItems="center" className="top-filter">
                <Box width="100%" display="flex" flexDirection="row" alignItems="center" flexWrap='wrap' justifyContent="space-between" alignContent="end">
                    <Box width="25%" minWidth="450px">
                        <CalendarComponent maximumNumberOfMonths={2} handlerFromChildToParent={props.handleChangeDateRange} startDate={props.date.start} endDate={props.date.end}
                            calendarId='transport-sell-daterange-picker' isValid={isDateRangeValid} handleIsValidChanged={handleIsDateRangeValidChanged} />
                    </Box>
                    <Box width="22%" maxWidth="340px">
                        <Select
                            className="logistics-unit-options"
                            classNamePrefix="options-filter"
                            isDisabled={logisticsUnits.length === 1}
                            isMulti
                            hideSelectedOptions={false}
                            placeholder={<span>{selectedOptions.length === 1 ? selectedOptions[0].label : (selectedOptions.length > 1 ? `Zones logistiques (${selectedOptions.filter(x => x.logisticsUnitId !== "-1").length})` : "Zones logistiques")}</span>}
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.logisticsUnitId}
                            options={logisticsUnits.length === 1 ? logisticsUnits : [{ label: "Tout", logisticsUnitId: "-1", checked: false, isQuotationAdministrator: false }, ...logisticsUnits]}
                            components={{ Option }}
                            value={selectedOptions}
                            isClearable={false}
                            controlShouldRenderValue={false}
                            blurInputOnSelect={false}
                            closeMenuOnSelect={false}
                            menuPlacement="auto"
                            onMenuOpen={() => setOpenMenu(true)}
                            menuIsOpen={openMenu}
                            onChange={(selectedOptions: LogisticsUnitChoice[], event) => handleChange(selectedOptions, event)}
                            onBlur={handleSearch}
                        />
                    </Box>
                    <Box width={openMargin ? '9%' : '12%'} minWidth="200px" display="flex" pl={2} flexDirection="column">
                        <Select
                            className={`option-filter-container ${filterSelected != null ? 'option-filter-container-filtered' : ''}`}
                            placeholder="Tout"
                            isMulti={false}
                            isClearable
                            menuPlacement="auto"
                            classNamePrefix="option-filter"
                            options={props.filterSelectOptions}
                            value={filterSelected}
                            onChange={(e) => handleChangeFilterSelected(e)}
                        />
                        <Box>
                            {nbTodoPreInvoices &&
                                (<span className="nb-transportFlow-todo-invoice">
                                    {`${nbTodoPreInvoices} Flux non synchronisés`}
                                </span>)
                            }
                        </Box>
                    </Box>
                    <Box width="15%" minWidth="250px">
                        <Input disableUnderline className={props.inputSearchTransportSellValue.length > 2 ? 'input-search-vehicles search-text-active' : 'input-search-vehicles'} inputRef={props.inputSearchTransportSellRef}
                            endAdornment={<>
                                <InputAdornment position="end" classes={
                                    {
                                        root: 'input-icon-close-root'
                                    }
                                }>
                                    <FontAwesomeIcon icon={faTimes} onClick={() => props.clearSearchText()} />
                                </InputAdornment>
                                <InputAdornment position="end" classes={
                                    {
                                        root: 'input-icon-search-root'
                                    }
                                }>
                                    <FontAwesomeIcon icon={faSearch} className="icon-search" />
                                </InputAdornment>
                            </>}
                            id="input-search-text" placeholder="Rechercher..." onChange={(event) => props.handleTransportSellKeyPress(event.target.value)} />
                    </Box>
                    <Box width="250px">
                        <TextSearchFieldsSelectorComponent<TextSearchField> selectedAll={true} options={props.filterOptions} onChange={props.onChangeTextSearchFields} placeHolder='Rechercher dans:' noOptionsMessage='Pas de filtres disponibles.' />
                    </Box>
                    <Box display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="flex-end" textAlign="end" alignItems="center">
                        <Box>
                            <Tooltip title="Synchronisation : cliquer pour afficher les données à facturer des Flux sélectionnés." placement="bottom">
                                <Avatar className={`btn-actions${!props.hasAnyFlowToPreInvoicesSelected ? ' btn-actions-disabled' : ''}`}>
                                    <IconButton className="icon-camera" onClick={() => props.generatePreInvoicesFromTransportFlows(false)}
                                        disabled={!props.hasAnyFlowToPreInvoicesSelected} size="small">
                                        <FontAwesomeIcon size="1x" icon={faCamera} />
                                    </IconButton>
                                </Avatar>
                            </Tooltip>
                        </Box>
                        <Box className="btn-actions-container">
                            <div className={`send-zephyr-btn${btnZephyrDisabled ? ' btn-disabled' : ''}`}>
                                <Tooltip title={btnZephyrDisabled ? "Les flux non synchronisés ne peuvent pas être envoyés à Zephyr Transport" : "Envoi des BL de vente de transport à Zephyr Transport"}>
                                    <img height="40px" src={btnZephyrDisabled ? imgSendZephyrDisabled : imgSendZephyr}
                                        onClick={btnZephyrDisabled ? stopPropagationEvent : props.sendIntegrationPreInvoices} />
                                </Tooltip>
                            </div>
                        </Box>
                        {!openMargin &&
                            <Box className="btn-actions-container">
                                <Tooltip title="Afficher le tableau des marges." placement="bottom">
                                    <Avatar className="btn-margin">
                                        <IconButton size="small" className="btn-icon"
                                            disabled={!props.hasAnyVehicles} onClick={() => props.handleMarginVehiclesOpen(true)}>
                                            <FontAwesomeIcon icon={faEuroSign} />
                                        </IconButton>
                                    </Avatar>
                                </Tooltip>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
            <Divider className="divider-filter" />
            <Box display="flex" flexDirection="row" pt={2} pb={2} className="filter-group-buttons">
                <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" width="70%">
                    <Box display="flex" flexDirection="column" pl={2}>
                        <div className="filter-title">
                            Regrouper
                        </div>
                        <Box display="flex" flexDirection="row">
                            <Button className={props.groupLabelFilter === GroupLabelFilter.TransportRequestBusinessId ? "mr-2 selected" : "mr-2"} id="transportRequestId" onClick={() => props.handleChangeGroup(GroupLabelFilter.TransportRequestBusinessId)}>
                                Besoin
                            </Button>
                            <Button className={props.groupLabelFilter === GroupLabelFilter.ReceiverSiteResolutionLabel ? "mr-2 selected" : "mr-2"} id="receiverSite" onClick={() => props.handleChangeGroup(GroupLabelFilter.ReceiverSiteResolutionLabel)}>
                                Site d'arrivée
                            </Button>
                            <Button className={props.groupLabelFilter === GroupLabelFilter.PayerLabel ? "mr-2 selected" : "mr-2"} id="payer" onClick={() => props.handleChangeGroup(GroupLabelFilter.PayerLabel)}>
                                Payeur
                            </Button>
                            <Button className={props.groupLabelFilter === GroupLabelFilter.PayerJobSiteMdmId ? "mr-2 selected" : "mr-2"} id="payerJobSiteMdmId" onClick={() => props.handleChangeGroup(GroupLabelFilter.PayerJobSiteMdmId)}>
                                Chantier Payeur
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box width="30%" min-width="250px" display="flex" flexDirection="column" alignItems="flex-end">
                    <LocalizationProvider language="fr-FR">
                        <IntlProvider locale="fr">
                            <table className="preinvoice-costs-table">
                                <thead>
                                    <tr>
                                        <th className="title center-content">Montants intégrés</th>
                                        <th className="title center-content">Montants non intégrés</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="value center-content">{`${props.preInvoiceCosts.integratedCosts ? Number(props.preInvoiceCosts.integratedCosts.toFixed(2)).toCurrencyString() : ''}`}</td>
                                        <td className="value center-content">{`${props.preInvoiceCosts.costsToIntegrate ? Number(props.preInvoiceCosts.costsToIntegrate.toFixed(2)).toCurrencyString() : ''}`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </IntlProvider>
                    </LocalizationProvider>
                </Box>
            </Box>
        </Box>
    );
}
