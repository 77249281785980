import { Box } from '@mui/material';
import { debounce, each, groupBy, mapValues, sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import { DateRange } from '../../../shared/models/DateRange';
import { RouteComponentProps, withRouter } from '../../../withRouter';
import { HeaderComponent } from './components/HeaderComponent';
import { InvoicesDetailsGrid } from './components/InvoicesDetailsGrid';
import { BeneficiaryVehiclesInvoicesDetailsLightModelExtended } from './models/BeneficiaryVehiclesInvoicesDetailsLightModelExtended';
import { BeneficiaryInvoicesDetailsLightModel } from './services/dataContracts/queryStack/BeneficiaryInvoicesDetailsLightModel';
import { ThirdPartyBeneficiaryInvoicesApiClient } from './services/ThirdPartyBeneficiaryInvoicesApiClient';
import './ThirdPartyBeneficiaryInvoicesStyles.scss';

interface ThirdPartyBeneficiaryInvoicesViewProps {
    role: string
}

const ThirdPartyBeneficiaryInvoicesView = (props: ThirdPartyBeneficiaryInvoicesViewProps & RouteComponentProps) => {

    let beneficiary = "";
    let fromTime: Date = null;
    let token: string = null;

    const searchParams: URLSearchParams = new URLSearchParams(props.location.search);
    if (searchParams) {
        beneficiary = searchParams.get('beneficiary');
        fromTime = searchParams.get('fromTime') ? new Date(searchParams.get('fromTime')) : null;
        token = searchParams.get('K');
    }

    const inputSearchVehiclesRef: React.RefObject<HTMLInputElement> = React.useRef(null);

    const [date, setDate] = useState<DateRange>(fromTime ? { start: fromTime.getDayStart().addDays(-7), end: fromTime.getDayEnd() } : null);
    const [beneficiaryName, setBeneficiaryName] = useState<string>(beneficiary);
    const [tokenAcces] = useState<boolean>(!fromTime && !beneficiary);
    const [invoicesDetailsData, setInvoicesDetailsData] = useState<Array<BeneficiaryVehiclesInvoicesDetailsLightModelExtended>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (tokenAcces)
            searchByToken(token);
        else
            search(beneficiary, date, "");
    }, []);

    const searchByToken = (token: string): void => {
        setLoading(true);
        ThirdPartyBeneficiaryInvoicesApiClient.Search(token, '', null, '')
            .then((res) => {
                const data: BeneficiaryInvoicesDetailsLightModel = res?.data;
                if (data) {
                    const fromDate: Date = new Date(data.fromDate);
                    setBeneficiaryName(data.beneficiaryName);
                    setDate({
                        start: fromDate.getDayStart(),
                        end: fromDate.getDayEnd().addDays(7)
                    });

                    const dataArray: BeneficiaryVehiclesInvoicesDetailsLightModelExtended[] = translateData(data.vehiclesInvoicesDetails as BeneficiaryVehiclesInvoicesDetailsLightModelExtended[]);
                    setInvoicesDetailsData(dataArray);
                    setLoading(false);
                }
            });
    }

    const search = (beneficiary: string, date: DateRange, searchText: string): void => {
        setLoading(true);
        ThirdPartyBeneficiaryInvoicesApiClient.Search(null, beneficiary, date, searchText)
            .then((res) => {
                const data: BeneficiaryInvoicesDetailsLightModel = res?.data;
                if (data) {
                    const dataArray: BeneficiaryVehiclesInvoicesDetailsLightModelExtended[] = translateData(data.vehiclesInvoicesDetails as BeneficiaryVehiclesInvoicesDetailsLightModelExtended[]);
                    setInvoicesDetailsData(dataArray);
                }
                setLoading(false);
            });
    }

    const translateData = (data: Array<BeneficiaryVehiclesInvoicesDetailsLightModelExtended>): Array<BeneficiaryVehiclesInvoicesDetailsLightModelExtended> => {
        const result: Array<BeneficiaryVehiclesInvoicesDetailsLightModelExtended> = [];
        const groupsByTransportFlow = mapValues(groupBy(data, x => x.flowBusinessId), v => sortBy(v, x => [x.planningDate, x.receiverSite, x.flowBusinessId]));

        each(groupsByTransportFlow, (flowsPlanningVehicles: BeneficiaryVehiclesInvoicesDetailsLightModelExtended[], transportFlowId: string) => {
            if (flowsPlanningVehicles.length > 0) {
                const sellPriceKind = flowsPlanningVehicles[0].transportSellPriceKind;
                let sellPriceKindText = sellPriceKind === "PerTon" ? "Tonne"
                    : (sellPriceKind === "PerTurn" ? "Tour"
                        : (sellPriceKind === "PerTimePeriod" ? "Forfait" : ''));

                if (sellPriceKind === "PerTurn" || sellPriceKind === "PerTon") {
                    const flowUnitPrice = flowsPlanningVehicles[0].flowUnitPrice ? flowsPlanningVehicles[0].flowUnitPrice : 0;
                    sellPriceKindText = sellPriceKindText + " / " + flowUnitPrice.toCurrencyString();
                }

                const transportFlow: BeneficiaryVehiclesInvoicesDetailsLightModelExtended = {
                    flowBusinessId: transportFlowId,
                    planningDate: Date.getDateFromIsoString(flowsPlanningVehicles[0].planningDate),
                    invoiceDate: Date.getDateFromIsoString(flowsPlanningVehicles[0].invoiceDate),
                    senderSite: flowsPlanningVehicles[0].senderSite,
                    receiverSite: flowsPlanningVehicles[0].receiverSite,
                    address: flowsPlanningVehicles[0].address,
                    deliveredQuantity: sum(flowsPlanningVehicles, 'deliveredQuantity'),
                    deliveredNumberOfTurns: sum(flowsPlanningVehicles, 'deliveredNumberOfTurns'),
                    plannedQuantity: sum(flowsPlanningVehicles, 'plannedQuantity'),
                    plannedNumberOfTurns: sum(flowsPlanningVehicles, 'plannedNumberOfTurns'),
                    invoicePrice: sum(flowsPlanningVehicles, 'invoicePrice'),
                    isGroupHeader: true,
                    invoicePriceText: sum(flowsPlanningVehicles, 'invoicePrice').toCurrencyString(),
                    transportSellPriceKind: sellPriceKindText,
                    licencePlate: null,
                    vehicleTypeLabel: null,
                    preInvoiceComments: null,
                    flowUnitPrice: flowsPlanningVehicles[0].flowUnitPrice
                };
                result.push(transportFlow);
            }

            const sortedByPlanningDate: BeneficiaryVehiclesInvoicesDetailsLightModelExtended[] = sortBy(flowsPlanningVehicles, f => f.licencePlate);

            each(sortedByPlanningDate, (pv: BeneficiaryVehiclesInvoicesDetailsLightModelExtended) => {
                const planningVehicle: BeneficiaryVehiclesInvoicesDetailsLightModelExtended = {
                    flowBusinessId: null,
                    planningDate: null,
                    invoiceDate: null,
                    senderSite: null,
                    receiverSite: null,
                    address: null,
                    deliveredQuantity: pv.deliveredQuantity,
                    deliveredNumberOfTurns: pv.deliveredNumberOfTurns,
                    plannedQuantity: pv.plannedQuantity,
                    plannedNumberOfTurns: pv.plannedNumberOfTurns,
                    invoicePrice: pv.invoicePrice,
                    isGroupHeader: false,
                    invoicePriceText: pv.invoicePrice.toCurrencyString(),
                    transportSellPriceKind: null,
                    licencePlate: pv.licencePlate,
                    vehicleTypeLabel: pv.vehicleTypeLabel,
                    preInvoiceComments: pv.preInvoiceComments,
                    flowUnitPrice: pv.flowUnitPrice
                };
                result.push(planningVehicle);
            });
        });

        return result;
    }

    const sum = (items: Array<BeneficiaryVehiclesInvoicesDetailsLightModelExtended>, propertyName: string): number => {
        return items.reduce(function (a, b) {
            return a + b[propertyName];
        }, 0);
    }

    const handleChangeDateRange = (range: DateRange): void => {
        if (range.end != null && range.start != null) {
            const fromTime: Date = range.start.getDayStart();
            const toTime: Date = range.end.getDayEnd();
            const date: DateRange = { start: fromTime, end: toTime }
            setDate(date);
            search(beneficiaryName, date, "");
        }
    }

    const clearSearchText = (): void => {
        inputSearchVehiclesRef.current.value = "";
        vehiclesKeyPressed("");
    }

    const handleVehiclesKeyPressed = debounce((text: string): void => {
        if (text.length >= 3 || text.length === 0) {
            vehiclesKeyPressed(text);
        }
    }, 500);

    const vehiclesKeyPressed = (text: string): void => {
        search(beneficiaryName, date, text);
    }

    const inputSearchVehiclesValue: string = inputSearchVehiclesRef.current === null || inputSearchVehiclesRef.current === undefined ? '' : inputSearchVehiclesRef.current.value;

    const headerComponent: JSX.Element = useMemo(() =>
        <HeaderComponent
            beneficiaryName={beneficiaryName}
            date={date}
            handleChangeDateRange={handleChangeDateRange}
            inputSearchVehiclesValue={inputSearchVehiclesValue}
            inputSearchVehiclesRef={inputSearchVehiclesRef}
            handleVehicleSearchKeyPress={handleVehiclesKeyPressed}
            handleClearSearchText={clearSearchText}
        />,
        [date, beneficiaryName, inputSearchVehiclesValue, inputSearchVehiclesRef]);

    const invoicesDetailsGrid: JSX.Element = useMemo(() => <InvoicesDetailsGrid data={invoicesDetailsData} />,
        [invoicesDetailsData]);

    return (
        <Box className="beneficiaryInvoicesDetails" display="flex" flexDirection="column" justifyContent="flex-start">
            {headerComponent}

            {(loading ?
                <ScaleLoader
                    width={5}
                    height={20}
                    radius={50}
                    color={'#000000'}
                    loading={loading}
                />
                :
                invoicesDetailsGrid
            )}
        </Box>
    );
}

export default React.forwardRef(withRouter(ThirdPartyBeneficiaryInvoicesView));