import { faAt, faGripVertical, faPhone, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Input, Tooltip } from '@mui/material';
import * as React from 'react';
import { UserSignatureField } from '../models/UserSignatureField';
import { UserSignatureFieldType } from '../services/dataContracts/controller/UserSignatureFieldType';

export interface ListItemProps {
    data?: UserSignatureField,
    children?: React.ReactNode,
    style?: React.CSSProperties,
    handleDeleteField?: (id: number) => void,
    handleIsBoldChanged?: (id: number) => void,
    handleIsItalicChanged?: (id: number) => void,
    handleTextChanged?: (id: number, value: string) => void,
    handleBlurText?: (id: number, value: string) => void
}

export const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>((props, ref) => {
    return (
        <li ref={ref} {...{ data: props.data, children: props.children, style: props.style }} className="k-item">
            <Box display="flex" flexDirection="row" alignItems="center">
                <FontAwesomeIcon icon={faTimes} color="red" style={{ cursor: "pointer" }} onClick={() => props.handleDeleteField(props.data?.id)} />
                <FontAwesomeIcon icon={faGripVertical} />
                {props.data?.fieldType === UserSignatureFieldType.text ? <span className="text-icon" style={{  }}>T</span> :
                    (props.data?.fieldType === UserSignatureFieldType.email ? <FontAwesomeIcon icon={faAt} /> :
                        (props.data?.fieldType === UserSignatureFieldType.phoneNumber ? <FontAwesomeIcon icon={faPhone} /> : ''))}
                <Input className={`input-text ${props.data?.isValid === false ? 'invalid' : ''}`}
                    value={props.data?.content}
                    onChange={(e) => props.handleTextChanged(props.data?.id, e.target.value)}
                    onBlur={(e) => props.handleBlurText(props.data?.id, e.target.value)}
                />
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Tooltip title="Gras" placement="top">
                        <Button className={`btn-bold ${props.data?.isBold ? 'checked' : ''}`} onClick={() => props.handleIsBoldChanged(props.data?.id)}>
                            <b>B</b>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Italique" placement="top">
                        <Button className={`btn-italic ${props.data?.isItalic ? 'checked' : ''}`} onClick={() => props.handleIsItalicChanged(props.data?.id)}>
                            <i>I</i>
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
        </li>
    )
})
