import axios, { AxiosResponse } from 'axios';
import { PagedResult } from 'src/shared/models/PagedResult';
import { WebAppActionResult } from 'src/shared/models/WebAppActionResult';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';
import { TransporterOrderRequestArgsExtended } from '../models/TransporterOrderRequestArgsExtended';
import { OverridenDeliveredNumberOfTurnsRequestArgs } from './dataContracts/controller/OverridenDeliveredNumberOfTurnsRequestArgs';
import { OverridenDeliveredQuantityRequestArgs } from './dataContracts/controller/OverridenDeliveredQuantityRequestArgs';
import { PlanningVehicleChangeRequestArgs } from './dataContracts/controller/PlanningVehicleChangeRequestArgs';
import { PlanningVehiclePublishedCostsRequestArgs } from './dataContracts/controller/PlanningVehiclePublishedCostsRequestArgs';
import { PlanningVehicleFeesChangeRequestArgs } from './dataContracts/controller/PlanningVehicleFeesChangeRequestArgs';
import { AvailablePurchasePriceLightModel } from './dataContracts/queryStack/AvailablePurchasePriceLightModel';
import { DriverLightModel } from './dataContracts/queryStack/DriverLightModel';
import { PlanningVehicleDetailsLightModel } from './dataContracts/queryStack/PlanningVehicleDetailsLightModel';
import { PlanningVehicleVisualisationLightModel } from './dataContracts/queryStack/PlanningVehicleVisualisationLightModel';
import { TransporterLightModel } from './dataContracts/queryStack/TransporterLightModel';
import { DeleteAttachmentArgs } from './dataContracts/controller/DeleteAttachmentArgs';
import { SendEmailArgs } from './dataContracts/controller/SendEmailArgs';

const controllerUrl = 'api/TransportPurchases/';

interface VehicleTripTransportersLogisticsUnits {
    selectedTransporters: Array<string>,
    logisticsUnits: Array<string>
}

export class TransportPurchasesApiClient {

    public static SearchTransporters = (fromTime: Date, untilTime: Date, searchText: string, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<PagedResult<TransporterLightModel>>> => {
        const url = `${controllerUrl}SearchTransporters?fromTime=` + encodeURIComponent(fromTime.toJSON()) + `&untilTime=` + encodeURIComponent(untilTime.toJSON()) + `&searchText=` + encodeURIComponent(searchText) + `&pageIndex=` + null + `&pageSize=` + null
        return axios({
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(logisticsUnits)
        });
    }

    public static SendConfirmation = (transporterOrderList: Array<TransporterOrderRequestArgsExtended>)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'SendConfirmation',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(transporterOrderList)
        });
    }

    public static SearchPlanningVehicles = (fromTime: Date, untilTime: Date, searchText: string, planningVehicleId: string, selectedTransporters: Array<string>, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<Array<PlanningVehicleLightModelExtended>>> => {
        const tr: VehicleTripTransportersLogisticsUnits = {
            selectedTransporters: selectedTransporters,
            logisticsUnits: logisticsUnits,
        };

        let url = `${controllerUrl}SearchPlanningVehicles?fromTime=${encodeURIComponent(fromTime.toJSON())}&untilTime=${encodeURIComponent(untilTime.toJSON())}&searchText=${encodeURIComponent(searchText)}`;

        if (planningVehicleId)
            url += `&planningVehicleId=${encodeURIComponent(planningVehicleId)}`;

        return axios({
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(tr)
        });
    }

    public static SearchPlanningVehicleDetails = (planningVehicleId: string)
        : Promise<AxiosResponse<Array<PlanningVehicleDetailsLightModel>>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SearchPlanningVehicleDetails?planningVehicleId=${encodeURIComponent(planningVehicleId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
    }

    public static SearchPlanningVehiclesWithDetails = (fromTime: Date, untilTime: Date, searchText: string, planningVehicleId: string, selectedTransporters: Array<string>, logisticsUnits: Array<string>)
        : Promise<[AxiosResponse<Array<PlanningVehicleLightModelExtended>>, AxiosResponse<Array<PlanningVehicleDetailsLightModel>>]> => {

        return Promise.all(
            [
                TransportPurchasesApiClient.SearchPlanningVehicles(fromTime, untilTime, searchText, planningVehicleId, selectedTransporters, logisticsUnits),
                TransportPurchasesApiClient.SearchPlanningVehicleDetails(planningVehicleId)
            ]);
    }

    public static SearchPlanningVehicleDetailsWithDrivers = (planningVehicleId: string, transporterId: string)
        : Promise<[AxiosResponse<Array<PlanningVehicleDetailsLightModel>>, AxiosResponse<Array<DriverLightModel>>]> => {
        return Promise.all(
            [
                TransportPurchasesApiClient.SearchPlanningVehicleDetails(planningVehicleId),
                TransportPurchasesApiClient.SearchDrivers(transporterId)
            ]);
    }

    public static SearchPlanningVehicleDetailsWithDriversAndPurchasePrices = (planningId: number, vehicleTypeId: string, planningVehicleId: string, transporterId: string, logisticsUnits: Array<string>)
        : Promise<[AxiosResponse<Array<PlanningVehicleDetailsLightModel>>, AxiosResponse<Array<DriverLightModel>>, AxiosResponse<Array<AvailablePurchasePriceLightModel>>]> => {
        return Promise.all(
            [
                TransportPurchasesApiClient.SearchPlanningVehicleDetails(planningVehicleId),
                TransportPurchasesApiClient.SearchDrivers(transporterId),
                TransportPurchasesApiClient.GetAvailablePurchasePrices(transporterId, vehicleTypeId, planningId, logisticsUnits)
            ]);
    }

    public static GetPlanningVehiclesVisualisation = (fromTime: Date, untilTime: Date, searchText: string, selectedTransporters: Array<string>, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<Array<PlanningVehicleVisualisationLightModel>>> => {
        const tr: VehicleTripTransportersLogisticsUnits = {
            selectedTransporters: selectedTransporters,
            logisticsUnits: logisticsUnits,
        };

        return axios({
            method: 'POST',
            url: `${controllerUrl}GetPlanningVehiclesVisualisation?fromTime=${encodeURIComponent(fromTime.toJSON())}&untilTime=${encodeURIComponent(untilTime.toJSON())}&searchText=${encodeURIComponent(searchText)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(tr)
        });
    }

    public static UpdatePlanningVehicleCell = (planningVehicleChange: PlanningVehicleChangeRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'UpdatePlanningVehicleCell',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(planningVehicleChange)
        });
    }

    public static SearchPlanningVehicleWithDetails = (planningVehicleId: string, fromTime: Date, untilTime: Date, searchText: string
        , selectedTransporters: Array<string>, logisticsUnits: Array<string>)
        : Promise<[AxiosResponse<PlanningVehicleLightModelExtended[]>, AxiosResponse<PlanningVehicleDetailsLightModel[]>]> => {
        return Promise.all(
            [
                TransportPurchasesApiClient.SearchPlanningVehicles(fromTime, untilTime, searchText, planningVehicleId, selectedTransporters, logisticsUnits),
                TransportPurchasesApiClient.SearchPlanningVehicleDetails(planningVehicleId)
            ]);
    }

    public static UpdatePlanningVehicleFees = (vehicleFeeChange: PlanningVehicleFeesChangeRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'UpdatePlanningVehicleFees',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(vehicleFeeChange)
        });
    }

    public static SearchDrivers = (transporterId: string)
        : Promise<AxiosResponse<Array<DriverLightModel>>> => {
        const url = `${controllerUrl}SearchDrivers?transporterId=` + (transporterId ? encodeURIComponent(transporterId) : '') + `&restrictToEnabledOnly=` + true;
        return axios({
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetAvailablePurchasePrices = (transporterId: string, vehicleTypeId: string, planningId: number, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<Array<AvailablePurchasePriceLightModel>>> => {
        const url = `${controllerUrl}GetAvailablePurchasePrices?transporterId=${encodeURIComponent(transporterId)}&vehicleTypeId=${vehicleTypeId}&planningId=${planningId}`;
        return axios({
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(logisticsUnits)
        });
    }

    public static SearchPlanningVehiclesWithTransporters = (fromTime: Date, untilTime: Date, searchText: string, selectedTransporters: Array<string>, logisticsUnits: Array<string>)
        : Promise<[AxiosResponse<PagedResult<TransporterLightModel>>, AxiosResponse<PlanningVehicleLightModelExtended[]>]> => {
        return Promise.all(
            [
                TransportPurchasesApiClient.SearchTransporters(fromTime, untilTime, searchText, logisticsUnits),
                TransportPurchasesApiClient.SearchPlanningVehicles(fromTime, untilTime, searchText, null, selectedTransporters, logisticsUnits)
            ]);
    }

    public static PublishVehiclesCosts = (planningVehiclesPublishedCosts: Array<PlanningVehiclePublishedCostsRequestArgs>)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'PublishVehiclesCosts',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(planningVehiclesPublishedCosts)
        });
    }

    public static OverridePlanningVehicleDeliveredQuantity = (requestArgs: OverridenDeliveredQuantityRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'OverridePlanningVehicleDeliveredQuantity',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static OverridePlanningVehicleDeliveredNumberOfTurns = (requestArgs: OverridenDeliveredNumberOfTurnsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'OverridePlanningVehicleDeliveredNumberOfTurns',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }


    public static GetAttachmentsSaveUrl = (): string => {
        return `${controllerUrl}AddAttachments`;
    }

    public static DeleteAttachments = (requestArgs: DeleteAttachmentArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}DeleteAttachments`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static SendEmail = (requestArgs: SendEmailArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SendEmail`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }
}
