import { AccordionDetails, Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridItemChangeEvent, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React from 'react';
import { ScaleLoader } from 'react-spinners';
import { AppModule, LocalStorage } from '../../../utils/Storage';
import { TransportRequestLightModelExtended } from '../models/TransportRequestLightModelExtended';
import { CustomAlertCellComponent } from './CustomAlertCellComponent';
import { CustomTransportRequestStatusCellComponent } from './CustomTransportRequestStatusCellComponent';

interface TransportRequestState {
    sort: Array<SortDescriptor>,
    orders: [],
    total: number,
    skip: number,
    take: number
}

interface TransportRequestProperties {
    data: Array<TransportRequestLightModelExtended>,
    vehicleTypesLabels: Map<string, string>,
    loading: boolean,
    sortByAlert: boolean,
    getTransportRequestTripWidth: (propName: string, width: number) => number,
    onReorderHandler: (event: GridColumnReorderEvent) => void,
    onResizeHandler: (event: GridColumnResizeEvent) => void,
    searchTransportFlow: (dataItem: TransportRequestLightModelExtended) => void
}

export const TransportRequestsGridName = 'transportRequests';

export default class TransportRequestComponent extends React.Component<TransportRequestProperties, TransportRequestState>{
    pageSize = 20;

    constructor(props) {
        super(props);
        let sortComponent = [];
        if (props.sortByAlert)
            sortComponent = [
                { field: 'hasUnobservedChanged', dir: 'desc' },
                { field: 'businessId', dir: 'desc' }
            ];
        else
            sortComponent = [
                { field: 'businessId', dir: 'desc' }
            ];

        this.state = {
            sort: sortComponent,
            orders: [],
            total: 0,
            skip: 0,
            take: 20
        };
    }

    componentWillUpdate(props, state) {
        if (props.sortByAlert !== undefined && this.props.sortByAlert !== props.sortByAlert) {
            this.setState({
                sort: [
                    { field: 'hasUnobservedChanged', dir: 'desc' },
                    { field: 'businessId', dir: 'desc' }
                ]
            });
        }
    }

    getTransportRequestOrderIndexColumn =  (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(AppModule.TransportPlan, TransportRequestsGridName, fieldName, defaultIndex);
    }

    handleSortChange = (e: GridSortChangeEvent): void => {
        this.setState({
            sort: e.sort
        });
    }

    updateAlertHandler = (dataItem: TransportRequestLightModelExtended, value: boolean): void => {
        dataItem.hasUnobservedChanged = value;
    }

    itemChangeTransportRequest = (event: GridItemChangeEvent): void => {
        event.dataItem[event.field] = event.value;
        this.forceUpdate();
    }

    handleRowClick = (e: GridRowClickEvent): void => {
        this.props.searchTransportFlow(e.dataItem);
    }

    render() {
        const contents: JSX.Element = this.props.loading
            ? <div className='sweet-loading spinnerClass'>
                <ScaleLoader
                    width={5}
                    height={20}
                    radius={50}
                    color={'#000000'}
                    loading={this.props.loading}
                />
            </div>
            : <AccordionDetails className="container-expansionPanel-details">
                <Box className="transport-request-content-grid transport-request-dc">
                    <div id="TransportRequestGrid" className="del-cal-trp" style={{ height: '100%' }}>
                        <LocalizationProvider language="fr-FR">
                            <IntlProvider locale="fr" >
                                <Grid
                                    style={{ height: '98%' }}
                                    data={orderBy(this.props.data, this.state.sort)}
                                    sortable
                                    rowHeight={40}
                                    reorderable
                                    resizable
                                    selectedField="selected"
                                    sort={this.state.sort}
                                    onSortChange={this.handleSortChange}
                                    onRowClick={(e) => this.handleRowClick(e)}
                                    onItemChange={this.itemChangeTransportRequest}
                                    onColumnResize={this.props.onResizeHandler}
                                    onColumnReorder={(e) => this.props.onReorderHandler(e)}>

                                    <GridColumn field="hasUnobservedChanged" orderIndex={this.getTransportRequestOrderIndexColumn("hasUnobservedChanged", 0)} title="Alerte" headerClassName='hide-title-column' width={this.props.getTransportRequestTripWidth("hasUnobservedChanged", 60)}
                                        cell={(props: GridCellProps) => <CustomAlertCellComponent {...props} vehicleTypesLabels={this.props.vehicleTypesLabels} unobservedDc={props.dataItem.hasUnobservedChanged} updateAlertHandler={this.updateAlertHandler} />} />
                                    <GridColumn field="status" orderIndex={this.getTransportRequestOrderIndexColumn("status", 1)} title="Statut" width={this.props.getTransportRequestTripWidth("status", 60)}
                                        cell={(properties: GridCellProps) => <CustomTransportRequestStatusCellComponent {...properties} selectRowHandler={() => this.props.searchTransportFlow(properties.dataItem)} />} />
                                    <GridColumn field="lblTransportRequired" className='transport-required-col' orderIndex={this.getTransportRequestOrderIndexColumn("isTransportRequired", 2)} headerClassName='hide-title-column' title="Livraison" width={this.props.getTransportRequestTripWidth("isTransportRequired", 30)} />
                                    <GridColumn field="businessId" className='al-number' orderIndex={this.getTransportRequestOrderIndexColumn("businessId", 3)} title="N° AL/Besoin" width={this.props.getTransportRequestTripWidth("businessId", 100)} />
                                    <GridColumn field="zephyrOrderNumber" orderIndex={this.getTransportRequestOrderIndexColumn("zephyrOrderNumber", 4)} title="N° commande" width={this.props.getTransportRequestTripWidth("zephyrOrderNumber", 100)} />
                                    <GridColumn field="deliveryDate" orderIndex={this.getTransportRequestOrderIndexColumn("deliveryDate", 5)} title="Date début" width={this.props.getTransportRequestTripWidth("deliveryDate", 130)} format="{0:dd-MM-yyyy}" />
                                    <GridColumn field="deliveryEndDate" orderIndex={this.getTransportRequestOrderIndexColumn("deliveryEndDate", 6)} title="Date fin" width={this.props.getTransportRequestTripWidth("deliveryEndDate", 130)} format="{0:dd-MM-yyyy}" />
                                    <GridColumn field="beneficiaryName" orderIndex={this.getTransportRequestOrderIndexColumn("beneficiaryName", 7)} title="Bénéficiaire" width={this.props.getTransportRequestTripWidth("beneficiaryName", 150)} />
                                    <GridColumn field="senderSite" orderIndex={this.getTransportRequestOrderIndexColumn("senderSite", 8)} title="Site départ" width={this.props.getTransportRequestTripWidth("senderSite", 150)} />
                                    <GridColumn field="productCode" orderIndex={this.getTransportRequestOrderIndexColumn("productCode", 9)} title="Chargement" width={this.props.getTransportRequestTripWidth("productCode", 130)} />
                                    <GridColumn field="firstLoadingTime" orderIndex={this.getTransportRequestOrderIndexColumn("firstLoadingTime", 10)} title="Heure chargement 1" width={this.props.getTransportRequestTripWidth("firstLoadingTime", 100)} format="{0:HH:mm}" />
                                    <GridColumn field="requestedQuantity" orderIndex={this.getTransportRequestOrderIndexColumn("requestedQuantity", 11)} title="Qté demandée" width={this.props.getTransportRequestTripWidth("requestedQuantity", 90)} />
                                    <GridColumn field="assignedQuantity" orderIndex={this.getTransportRequestOrderIndexColumn("assignedQuantity", 12)} title="Qté planifiée" format="{0:0.00}" width={this.props.getTransportRequestTripWidth("assignedQuantity", 100)} />
                                    <GridColumn field="deliveredQuantity" orderIndex={this.getTransportRequestOrderIndexColumn("deliveredQuantity", 13)} title="Qté livrée" format="{0:0.00}" width={this.props.getTransportRequestTripWidth("deliveredQuantity", 90)} />
                                    <GridColumn field="requestedVehicles" orderIndex={this.getTransportRequestOrderIndexColumn("requestedVehicles", 14)} title="Nb camions demandés" width={this.props.getTransportRequestTripWidth("requestedVehicles", 100)} />
                                    <GridColumn field="assignedVehicles" orderIndex={this.getTransportRequestOrderIndexColumn("assignedVehicles", 15)} title="Nb camions affectés" width={this.props.getTransportRequestTripWidth("assignedVehicles", 100)} />
                                    <GridColumn field="remainingVehiclesToAssign" orderIndex={this.getTransportRequestOrderIndexColumn("remainingVehiclesToAssign", 16)} title="Nb camions manquants" width={this.props.getTransportRequestTripWidth("remainingVehiclesToAssign", 100)} />
                                    <GridColumn field="estimatedDuration" orderIndex={this.getTransportRequestOrderIndexColumn("estimatedDuration", 17)} title="Temps trajet" width={this.props.getTransportRequestTripWidth("estimatedDuration", 100)} />
                                    <GridColumn field="requestedByName" orderIndex={this.getTransportRequestOrderIndexColumn("requestedByName", 18)} title="Demandeur" width={this.props.getTransportRequestTripWidth("requestedByName", 150)} />
                                    <GridColumn field="deliveryAddress" orderIndex={this.getTransportRequestOrderIndexColumn("deliveryAddress", 19)} title="Adresse arrivée" width={this.props.getTransportRequestTripWidth("deliveryAddress", 150)} />
                                    <GridColumn field="deliveryAddressZipCode" orderIndex={this.getTransportRequestOrderIndexColumn("deliveryAddressZipCode", 20)} title="CP arrivée" width={this.props.getTransportRequestTripWidth("deliveryAddressZipCode", 100)} />
                                    <GridColumn field="deliveryAddressCity" orderIndex={this.getTransportRequestOrderIndexColumn("deliveryAddressCity", 21)} title="Ville arrivée" width={this.props.getTransportRequestTripWidth("deliveryAddressCity", 100)} />
                                    <GridColumn field="coatingApplicationMode" orderIndex={this.getTransportRequestOrderIndexColumn("coatingApplicationMode", 22)} title="Mode" width={this.props.getTransportRequestTripWidth("coatingApplicationMode", 100)} />
                                    <GridColumn field="estimatedDistance" orderIndex={this.getTransportRequestOrderIndexColumn("estimatedDistance", 23)} title="Distance" width={this.props.getTransportRequestTripWidth("estimatedDistance", 100)} />
                                    <GridColumn field="deliveryRemarks" orderIndex={this.getTransportRequestOrderIndexColumn("deliveryRemarks", 24)} title="Info livraisons" width={this.props.getTransportRequestTripWidth("deliveryRemarks", 250)} />
                                    <GridColumn field="supplierAgency" orderIndex={this.getTransportRequestOrderIndexColumn("supplierAgency", 25)} title="Agence départ" width={this.props.getTransportRequestTripWidth("supplierAgency", 150)} />
                                    <GridColumn field="pickupAddress" orderIndex={this.getTransportRequestOrderIndexColumn("pickupAddress", 26)} title="Adresse départ" width={this.props.getTransportRequestTripWidth("pickupAddress", 150)} />
                                    <GridColumn field="pickupAddressCity" orderIndex={this.getTransportRequestOrderIndexColumn("pickupAddressCity", 27)} title="Ville départ" width={this.props.getTransportRequestTripWidth("pickupAddressCity", 100)} />
                                    <GridColumn field="pickupAddressZipCode" orderIndex={this.getTransportRequestOrderIndexColumn("pickupAddressZipCode", 28)} title="CP départ" width={this.props.getTransportRequestTripWidth("pickupAddressZipCode", 100)} />
                                    <GridColumn field="customerAgency" orderIndex={this.getTransportRequestOrderIndexColumn("customerAgency", 29)} title="  Agence arrivée" width={this.props.getTransportRequestTripWidth("customerAgency", 150)} />
                                    <GridColumn field="creationTime" orderIndex={this.getTransportRequestOrderIndexColumn("creationTime", 30)} title="Date création" width={this.props.getTransportRequestTripWidth("creationTime", 150)} format="{0:dd-MM-yyyy HH:mm}" />
                                    <GridColumn field="serviceKindLabel" orderIndex={this.getTransportRequestOrderIndexColumn("serviceKindLabel", 31)} title="Prestation" width={this.props.getTransportRequestTripWidth("serviceKindLabel", 90)} />
                                    <GridColumn field="priority" orderIndex={this.getTransportRequestOrderIndexColumn("priority", 32)} title="Criticité" width={this.props.getTransportRequestTripWidth("priority", 85)} />
                                    <GridColumn field="logisticsUnitLabel" orderIndex={this.getTransportRequestOrderIndexColumn("logisticsUnitLabel", 33)} title="Zone logistique" width={this.props.getTransportRequestTripWidth("logisticsUnitLabel", 100)} />
                                </Grid>
                            </IntlProvider>
                        </LocalizationProvider>
                    </div>
                </Box>
            </AccordionDetails>
        return (
            <>
                {contents}
            </>
        );
    }
}