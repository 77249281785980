import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Drawer } from "@mui/material";
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn as Column, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import { AppModule, LocalStorage, SessionStorage } from '../../../utils/Storage';
import { TransportSaleCostWithMarginLightModel } from '../services/dataContracts/queryStack/TransportSaleCostWithMarginLightModel';

interface MarginDrawerComponentProperties {
    isMarginDrawerOpened: boolean,
    gridHeight: number,
    gridPageSize: number,
    loading: boolean,
    vehiclesList: Array<TransportSaleCostWithMarginLightModel>,
    totalInvoicePrices: number,
    totalCosts: number,
    totalMargins: number,
    handleMarginDrawerClose: (open: boolean) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const TransportSalesMarginGridName = 'transportSalesMargin';
const ModuleKey = AppModule.TransportSales;

export const MarginDrawerComponent = (props: MarginDrawerComponentProperties) => {

    const marginGridSortDescriptorsStorageKey = "transport_margin_sortItem";
    const storedSortDescriptors = SessionStorage.GetItem(ModuleKey, marginGridSortDescriptorsStorageKey);
    const initialSort: SortDescriptor[] = storedSortDescriptors ? JSON.parse(storedSortDescriptors) : [{ field: 'businessId', dir: 'asc' }];

    const [sort, setSort] = useState(initialSort);
    const [skip, setSkip] = useState(0);

    useEffect(() => {
        if (props.loading == true && props.vehiclesList?.length > 0) {
            setSkip(0);
        }
    }, [props.loading]);

    const forceUpdate = useForceUpdate();

    const getVehicleGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, TransportSalesMarginGridName, fieldName, defaultIndex);
    }

    const getVehicleGridWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, TransportSalesMarginGridName, fieldName, defaultWidth);
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            TransportSalesMarginGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, TransportSalesMarginGridName, event.columns);
        forceUpdate();
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip ? event.page.skip : 0);
    }

    const { vehiclesList, gridPageSize, loading } = props;

    return (
        <Drawer
            className='drawer-margin'
            variant="persistent"
            anchor="right"
            open={props.isMarginDrawerOpened}
            classes={{
                paper: 'drawer-margin-paper'
            }}
        >
            <div className='toolbar-styles' />
            <Box display="flex" flexDirection="column" flex="nowrap" className="content-drawer-margin">
                <Box display="flex" pt={3} pb={2} flexDirection="column" flex="nowrap" className="content-header-drawer-margin">
                    <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center">
                        <Box width="80%" pl={1} className="title-margin">
                            ACHATS / VENTES Lorie (hors intégration) :
                        </Box>
                        <Box pr={1} display="flex" width="20%" flexDirection="row" justifyContent="flex-end">
                            <Box style={{ cursor: 'pointer' }} onClick={() => props.handleMarginDrawerClose(false)}>
                                <FontAwesomeIcon size="lg" icon={faTimes} />
                            </Box>
                        </Box>
                    </Box>
                </Box >
                {loading
                    ? <div className='sweet-loading spinnerClass'>
                        <ScaleLoader
                            width={5}
                            height={20}
                            radius={50}
                            color={'#000000'}
                            loading={loading}
                        />
                    </div>
                    : <Box display="flex" p={2} flexDirection="column" flex="nowrap" className="container-margin-grid" id="container-margin">
                        <LocalizationProvider language="fr-FR">
                            <IntlProvider locale="fr">
                                <Grid
                                    className="transport-margin"
                                    data={orderBy(vehiclesList, sort).slice(skip, skip + gridPageSize)}
                                    sortable={true}
                                    reorderable
                                    resizable
                                    sort={sort}
                                    onColumnResize={onResizeHandler}
                                    onColumnReorder={(e) => onReorderHandler(e)}
                                    onSortChange={handleSortChange}
                                    editField="inEdit"
                                    scrollable="virtual"
                                    skip={skip}
                                    pageSize={gridPageSize}
                                    total={vehiclesList.length}
                                    onPageChange={pageChange}
                                    rowHeight={40}
                                >
                                    <Column field="vehicleType" className="vehicle-type" headerClassName="vehicle-type-header"
                                        footerCell={() => {
                                            return <td className='title-footer'>
                                                Total
                                            </td>
                                        }}
                                        orderIndex={getVehicleGridOrderIndexColumn("vehicleType", 1)} title="Type camion" editable={false} width={getVehicleGridWidth("vehicleType", 90)} />
                                    <Column field="businessId" orderIndex={getVehicleGridOrderIndexColumn("businessId", 2)} title="Camion" editable={false} width={getVehicleGridWidth("businessId", 90)} />
                                    <Column field="invoicePrice"
                                        cell={(p: GridCellProps) => {
                                            return <td>
                                                {`${p.dataItem.invoicePrice?.toCurrencyString()}`}
                                            </td>
                                        }}
                                        footerCell={() => {
                                            return <td>
                                                {`${Number(props.totalInvoicePrices.toFixed(2)).toCurrencyString()}`}
                                            </td>
                                        }}
                                        orderIndex={getVehicleGridOrderIndexColumn("invoicePrice", 3)} width={getVehicleGridWidth("invoicePrice", 80)} title="Vente" editable={false} />
                                    <Column field="totalCosts"
                                        cell={(p: GridCellProps) => {
                                            return <td>
                                                {`${p.dataItem.totalCosts?.toCurrencyString()}`}
                                            </td>
                                        }}
                                        footerCell={() => {
                                            return <td>
                                                {`${Number(props.totalCosts.toFixed(2)).toCurrencyString()}`}
                                            </td>
                                        }}
                                        orderIndex={getVehicleGridOrderIndexColumn("totalCosts", 4)} width={getVehicleGridWidth("totalCosts", 80)} title="Achats" editable={false} />
                                    <Column field="margin"
                                        cell={(p: GridCellProps) => {
                                            return <td className={`${p.dataItem.margin < 0 ? 'negative-margin' : 'positive-margin'}`}>
                                                {`${p.dataItem.margin?.toCurrencyString()}`}
                                            </td>
                                        }}
                                        footerCell={() => {
                                            const _margin = Number(props.totalMargins.toFixed(2));
                                            return <td className={`${_margin < 0 ? 'negative-margin' : 'positive-margin'}`}>
                                                {`${_margin?.toCurrencyString()}`}
                                            </td>
                                        }}
                                        orderIndex={getVehicleGridOrderIndexColumn("margin", 5)} width={getVehicleGridWidth("margin", 80)} title="Marge" editable={false} />
                                </Grid>
                            </IntlProvider>
                        </LocalizationProvider>
                    </Box>
                }
            </Box >
        </Drawer >
    );
}
