import { Box } from '@mui/material';
import { DateRangePicker, DateRangePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import { SessionStorage } from '../../../utils/Storage';
import { DateRange } from '../../models/DateRange';
import EventIcon from '@mui/icons-material/Event';

interface CalendarComponentProperties {
    calendarId: string,
    handlerFromChildToParent: (data: DateRange) => void,
    isValid: boolean,
    handleIsValidChanged: (isValid: boolean) => void,
    startDate?: Date,
    endDate?: Date,
    minStartDate?: Date,
    maxStartDate?: Date,
    maximumNumberOfMonths?: number,
    render?: any
}

export const CalendarComponent = (props: CalendarComponentProperties) => {
    const { calendarId, minStartDate, maxStartDate, maximumNumberOfMonths, isValid, handleIsValidChanged } = props;

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [value, setValue] = useState<DateRange>(
        (props.startDate && props.endDate) ?
            {
                start: props.startDate, end: props.endDate
            } :
            {
                start: SessionStorage.ActiveStartDate, end: SessionStorage.ActiveEndDate
            });

    const [minDateValue, setMinDateValue] = useState<Date>(minStartDate);
    const [maxDateValue, setMaxDateValue] = useState<Date>(maxStartDate);

    const handleChangeDateRange = (event: DateRangePickerChangeEvent) => {
        const startDate: Date = event.target.value.start;
        const endDate: Date = event.target.value.end;
        const missingDate = startDate == null || endDate == null;

        //When the popup is visible
        if (event.show) {
            setValue(event.target.value);

            if (maximumNumberOfMonths && endDate == null && startDate != null) {
                setMinDateValue(startDate.getDayStart().addMonths(-maximumNumberOfMonths));
                setMaxDateValue(startDate.getDayStart().addMonths(maximumNumberOfMonths));
            }
            else if (endDate != null && startDate != null) {
                const data = {
                    start: startDate,
                    end: endDate
                }
                setIsVisible(false);
                props.handlerFromChildToParent(data);
                setMaxDateValue(undefined);
                setMinDateValue(undefined);
                if (!isValid && !missingDate)
                    handleIsValidChanged(true);
            }
        }
        //When change by keyboard
        else {
            let dateRange: DateRange = null;
            if (maximumNumberOfMonths) {
                if (startDate && !value.start.equals(startDate)) {
                    let maxDate: Date = startDate.getDayStart().addMonths(maximumNumberOfMonths);
                    if (value.end > maxDate) {
                        dateRange = { start: startDate, end: maxDate };
                        setValue(dateRange);
                    } else if (startDate > value.end) {
                        dateRange = { start: startDate, end: startDate };
                        setValue(dateRange);
                    } else {
                        dateRange = event.target.value;
                        setValue(dateRange);
                    }

                    if (!isValid && !missingDate)
                        handleIsValidChanged(true);
                }

                if (endDate && !value.end.equals(endDate)) {
                    let maxDate: Date = value.start.getDayStart().addMonths(maximumNumberOfMonths);
                    if (endDate > maxDate) {
                        dateRange = { start: value.start, end: maxDate };
                        setValue(dateRange);
                    } else if (endDate < value.start) {
                        dateRange = { start: value.start, end: value.start };
                        setValue(dateRange);
                    } else {
                        dateRange = event.target.value;
                        setValue(dateRange);
                    }

                    if (!isValid && !missingDate)
                        handleIsValidChanged(true);
                }

                if (dateRange == null && value.start && value.end && !isValid && !missingDate) {
                    dateRange = { start: value.start, end: value.end };
                    handleIsValidChanged(true);
                }

                (event.target as any).handleBlur = (e) => {
                    if (dateRange == null) {
                        handleIsValidChanged(false);
                    }
                    else if (!dateRange.start.equals(value.start) || !dateRange.end.equals(value.end)) {
                        handleCalendar(e, dateRange);
                    }
                }
            }
            else {
                setValue(event.target.value);
                (event.target as any).handleBlur = (e) => {
                    if (startDate > endDate) {
                        let date: DateRange = {
                            start: endDate,
                            end: startDate
                        };
                        setValue(date);
                        handleCalendar(e, date);
                    } else {
                        let date: DateRange = {
                            start: startDate,
                            end: endDate
                        };
                        handleCalendar(e, date);
                    }
                    handleIsValidChanged(!missingDate);
                }
            }
        }
    }

    const handleCalendar = (event: any, value: DateRange) => {
        //Check if the second field to focus is not dateinput
        const d = document.getElementById(props.calendarId);
        if (!event.relatedTarget || (d && !d.contains(event.relatedTarget))) {
            props.handlerFromChildToParent(value);
        }
    };

    const toggleCalendar = () => {
        setIsVisible(!isVisible);
    }
    const startEndDateInputSettings = { label: '', disableUnderline: true };

    return (
        <Box display="flex" flexDirection='row' p={1} alignItems='center' >
            <Box height='100%' onClick={toggleCalendar} className="k-icon k-i-calendar btn-calendar">
                <EventIcon />
            </Box>
            <Box pl={1}>
                <LocalizationProvider language="fr-FR">
                    <IntlProvider locale="fr" >
                        {(!minDateValue) ? (<DateRangePicker className="calendar-daterange-picker" id={calendarId} value={value} onChange={handleChangeDateRange}
                            startDateInputSettings={startEndDateInputSettings} endDateInputSettings={startEndDateInputSettings}
                            show={isVisible} valid={isValid} />) :
                            (minDateValue && !maxDateValue)
                                ? <DateRangePicker className="calendar-daterange-picker" id={calendarId} value={value} onChange={handleChangeDateRange}
                                    min={minDateValue}
                                    startDateInputSettings={startEndDateInputSettings} endDateInputSettings={startEndDateInputSettings}
                                    show={isVisible} valid={isValid} />
                                : <DateRangePicker className="calendar-daterange-picker" id={calendarId} value={value} onChange={handleChangeDateRange}
                                    min={minDateValue} max={maxDateValue}
                                    startDateInputSettings={startEndDateInputSettings} endDateInputSettings={startEndDateInputSettings}
                                    show={isVisible} valid={isValid} />
                        }

                    </IntlProvider>
                </LocalizationProvider>
            </Box>
        </Box>
    );
}