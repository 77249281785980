import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, FormControlLabel, Input, InputAdornment } from '@mui/material';
import { debounce } from 'lodash';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { WebAppActionResult } from '../../../../shared/models/WebAppActionResult';
import BusinessErrors from '../../../../utils/BusinessErrors';
import { OtherTransporterWithUsedDriversChoiceLightModel } from '../services/dataContracts/queryStack/OtherTransporterWithUsedDriversChoiceLightModel';
import { ThirdPartyTransporterApiClient } from '../services/ThirdPartyTransporterApiClient';

interface OtherTransportersUsingDriversChoicesManagementComponentProps {
    transporterId: string,
    isOnlyChosenChecked: boolean,
    handleDrawerOtherTransportersClose: () => void,
    displayBusinessErrors: (errors: string[]) => void,
    updateListAciveDriver: (transporterId: string) => void,
    setIsOnlyChosenChecked: (checked: boolean) => void
}

export const OtherTransportersUsingDriversChoicesManagementComponent = (props: OtherTransportersUsingDriversChoicesManagementComponentProps): JSX.Element => {

    const inputSearchOtherTransportersRef: MutableRefObject<HTMLInputElement> = useRef(null);
    const { isOnlyChosenChecked } = props;
    const [searchText, setSearchText] = useState<string>('');
    const [otherTransporters, setOtherTransporters] = useState<Array<OtherTransporterWithUsedDriversChoiceLightModel>>([]);

    useEffect(() => {
        if (props.transporterId && props.transporterId !== '-1')
            searchOtherTransporters('', isOnlyChosenChecked);
    }, [props.transporterId]);

    const clearSearchText = (): void => {
        inputSearchOtherTransportersRef.current.value = '';
        searchOtherTransporters('', isOnlyChosenChecked);
        setSearchText('');
    }

    const handleSearchTransportersChange = debounce((value: string): void => {
        searchOtherTransporters(value, isOnlyChosenChecked);
        setSearchText(value);
    }, 500);

    const searchOtherTransporters = (searchText: string, isOnlyChosenChecked: boolean): void => {
        ThirdPartyTransporterApiClient.SearchOtherTransporters(props.transporterId, isOnlyChosenChecked, searchText)
            .then((res) => {
                if (res) {
                    setOtherTransporters(res.data);
                }
            });
    }

    const handleOnlyActivatedAgencyChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        props.setIsOnlyChosenChecked(e.target.checked);
        searchOtherTransporters(searchText, e.target.checked);
    }

    const handleChangeActiveOtherTransporter = (transporterId: string, otherTransporterId: string, shouldAdd: boolean): void => {
        ThirdPartyTransporterApiClient.AddOrRemoveTransporterUsingDriversOfOtherChoice(transporterId, otherTransporterId, shouldAdd)
            .then((res) => {
                const data: WebAppActionResult = res?.data;
                const errors: string[] = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    props.displayBusinessErrors(errors);
                }

                searchOtherTransporters(searchText, isOnlyChosenChecked);
                props.updateListAciveDriver(transporterId);
            });
    }

    const searchTextOtherTransportersValue: string = inputSearchOtherTransportersRef.current === null || inputSearchOtherTransportersRef.current === undefined ? '' : inputSearchOtherTransportersRef.current.value;

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            p="15px"
        >
            <Box display="flex" pb={2} flexDirection="row" width="100%">
                <Box className="drawer-title" width="80%">
                    Gestion des chauffeurs
                </Box>
                <Box display="flex" width="20%" flexDirection="row" justifyContent="flex-end">
                    <Box style={{ cursor: "pointer" }} onClick={props.handleDrawerOtherTransportersClose}>
                        <FontAwesomeIcon size="lg" icon={faTimes} />
                    </Box>
                </Box>
            </Box>
            <Box>
                <p>Sélectionner les agences des chauffeurs à afficher dans la/les liste(s) déroulante(s) <b>Chauffeur</b> :</p>
            </Box>
            <Box width="100%">
                <Input disableUnderline className={searchTextOtherTransportersValue.length > 0 ? "search-text-active" : ''} inputRef={inputSearchOtherTransportersRef}
                    startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                    endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => clearSearchText()} /></InputAdornment>}
                    id="input-search-other-transporters" placeholder="Rechercher ..." onChange={(event) => handleSearchTransportersChange(event.target.value)} />
            </Box>
            <Box>
                <FormControlLabel control={<Checkbox color="primary" checked={isOnlyChosenChecked} onChange={(e) => handleOnlyActivatedAgencyChanged(e)} />} label="Agences activées" />
            </Box>
            <Box>
                {otherTransporters.map((transporter: OtherTransporterWithUsedDriversChoiceLightModel, index: number) =>
                    <div key={`transporter-${index}`}>
                        <div className="switch-section">
                            <label className="switch">
                                <input type="checkbox"
                                    checked={transporter.isChosen}
                                    onChange={(e) => handleChangeActiveOtherTransporter(props.transporterId, transporter.transporterId, e.target.checked)}
                                />
                                <span className="slider round"></span>
                            </label>
                            <span style={{ marginLeft: "5px" }}><b>{transporter.label}</b></span>
                        </div>
                    </div>
                )}
            </Box>
        </Box>
    );
}