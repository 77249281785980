import { Box } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { UploadOnBeforeUploadEvent, UploadResponse } from '@progress/kendo-react-upload';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import SimpleDialog from 'src/shared/components/Common/SimpleDialog';
import { ImportErrorsExtraResult, LineImportErrors } from 'src/shared/models/ImportErrorsExtraResult';
import { WebAppActionResultEx } from 'src/shared/models/WebAppActionResult';
import ToastService from 'src/ToastService';
import { ScaleLoaderComponent } from '../../../shared/components/Common/ScaleLoaderComponent';
import BusinessErrors from '../../../utils/BusinessErrors';
import '../ReferentialsStyles.scss';
import { AddWorkAgencyDialogComponent } from './components/AddWorkAgencyDialog/AddWorkAgencyDialogComponent';
import { HeaderContentComponent } from './components/HeaderContentComponent';
import { WorkAgenciesReferentialComponent } from './components/WorkAgenciesReferentialComponent';
import { WorkAgencyCandidateToAdd } from './services/dataContracts/controller/WorkAgencyCandidateToAdd';
import { LogisticsUnitChoiceOfWorkAgenciesLightModel } from './services/dataContracts/queryStack/LogisticsUnitChoiceOfWorkAgenciesLightModel';
import { WorkAgencyLightModel } from './services/dataContracts/queryStack/WorkAgencyLightModel';
import { WorkAgenciesReferentialApiClient } from './services/WorkAgenciesReferentialApiClient';
import './WorkAgenciesReferentialStyles.scss';

export const WorkAgenciesReferentialView = () => {

    const inputSearchAgenciesRef: React.RefObject<HTMLInputElement> = React.useRef(null);
    const initialSort: SortDescriptor[] = [{ field: 'agencyLabel', dir: 'asc' }];

    const [workAgenciesList, setWorkAgenciesList] = useState<Array<WorkAgencyLightModel>>([]);
    const [workAgencyChoicesLogisticsUnit, setWorkAgencyChoicesLogisticsUnit] = useState<Array<LogisticsUnitChoiceOfWorkAgenciesLightModel>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [sort, setSort] = useState<Array<SortDescriptor>>(initialSort);
    
    const [activeStep, setActiveStep] = useState<number>(0);
    const [workAgencyId, setWorkAgencyId] = useState<string>('');
    const [isAddWorkAgencyDialogOpened, setIsAddWorkAgencyDialogOpened] = useState<boolean>(false);
    const [workAgencySearchLoading, setWorkAgencySearchLoading] = useState<boolean>(false);
    const [workAgencyToAdd, setWorkAgencyToAdd] = useState<WorkAgencyCandidateToAdd>(null);

    useEffect(() => {
        getWorkAgencies("");
    }, []);

    const clearSearchText = (): void => {
        inputSearchAgenciesRef.current.value = "";
        agenciesKeyPressed("");
    }

    const WorkAgenciesKeyPressed = debounce((text: string): void => {
        if (text.length >= 3 || text.length === 0) {
            agenciesKeyPressed(text);
        }
    }, 500);

    const agenciesKeyPressed = (text: string): void => {
        getWorkAgencies(text);
    }

    const getWorkAgencies = (searchText: string, errorMessage?: string, listErrorMessage?: Array<string>, successMessage?: string, endMessage?: string): void => {
        setLoading(true);
        WorkAgenciesReferentialApiClient.GetWorkAgenciesWithAgencyChoicesLogisticsUnit(searchText)
            .then(response => {
                setWorkAgenciesList(response[0].data);
                setWorkAgencyChoicesLogisticsUnit(response[1].data);

                if (errorMessage)
                    ToastService.showErrorToast(errorMessage, listErrorMessage, endMessage);
                else if (successMessage)
                    ToastService.showSuccessToast(successMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const handleSortColumnChange = (sortItems: SortDescriptor[]): void => {
        setSort(sortItems);
    }

    const inputSearchWorkAgenciesValue = inputSearchAgenciesRef.current === null || inputSearchAgenciesRef.current === undefined ? '' : inputSearchAgenciesRef.current.value;

    const handlerAfterUpload = (response: UploadResponse): void => {
        const _response: WebAppActionResultEx<ImportErrorsExtraResult> = response ? response.response : null;
        let _successMessage = '';
        const _listErrorMessage = [];
        let _errorMessage = '';
        let _endMessage = '';

        if (response.status === 200  && _response) {
            const importLines: Array<LineImportErrors> = _response.extraResult.linesWithErrors;
            if (importLines.length > 0) {
                importLines.forEach(e => {
                    e.errors.forEach(eError => {
                        let _libMsg = BusinessErrors.GetError(eError);
                        if (!_libMsg && eError)
                            _libMsg = eError;
                        _listErrorMessage.push(`- Ligne ${e.rowIndex} - ${_libMsg}`);
                    });
                });
                
                _errorMessage = 'Erreur lors de l\'import de la visibilité des Unités d\'Exploitation travaux Colas:';
                _endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des Unités d\'Exploitation travaux Colas sur vos zones logistiques';    
            }
            else{
                _successMessage = 'La visibilité des Unités d\'Exploitation travaux Colas pour vos zones logistiques a bien été intégrée';
            }
        } else {
            _errorMessage = 'Erreur lors de l\'import de la visibilité des Unités d\'Exploitation travaux Colas, veuillez vérifier votre fichier';
            _endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des Unités d\'Exploitation travaux sur vos zones logistiques';
            _successMessage = '';

        }
        getWorkAgencies(inputSearchWorkAgenciesValue, _errorMessage, _listErrorMessage, _successMessage, _endMessage);
    }

    const displayErrorResponse = (): void => {
        const _errorMessage = 'Erreur lors de l\'import de la visibilité des Unités d\'Exploitation travaux Colas, veuillez vérifier votre fichier';
        const _endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des Unités d\'Exploitation travaux sur vos zones logistiques';
        const _successMessage = '';
        getWorkAgencies(inputSearchWorkAgenciesValue, _errorMessage, [], _successMessage, _endMessage);
    }

    const handlerBeforeUpload = (e: UploadOnBeforeUploadEvent): void => {
        setLoading(true);
    }

    const searchWorkAgencyByIdentifier = (agencyId: string): void => {
        setWorkAgencySearchLoading(true);

        WorkAgenciesReferentialApiClient.SearchWorkAgencyByIdentifier(agencyId.trim().toUpperCase())
            .then((res) => {

                setWorkAgencySearchLoading(false);

                const workAgencyCandidateToAdd: WorkAgencyCandidateToAdd = res.data;
                if (workAgencyCandidateToAdd) {
                    if (workAgencyCandidateToAdd.existInLorieDb) {
                        handleSearchInReferentialGrid(workAgencyCandidateToAdd.agencyId);
                    }
                    else if (workAgencyCandidateToAdd.existOnColasAPI) {
                        setWorkAgencyToAdd(workAgencyCandidateToAdd);
                        setActiveStep(1);
                    }
                    else {
                        ToastService.showErrorToast("Aucune Unité d'Exploitation trouvée dans le référentiel");
                    }
                }

            });
    }

    const handleSearchInReferentialGrid = (agencyId: string): void => {
        inputSearchAgenciesRef.current.value = agencyId;
        handleCloseAddWorkAgencyDialog();
        getWorkAgencies(agencyId);

        ToastService.showErrorToast("Cette Unité d'Exploitation est déjà présente dans LORIE");
    }

    const confirmAddWorkAgency = (): void => {
        WorkAgenciesReferentialApiClient.AddWorkAgency(workAgencyToAdd.agencyId)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                inputSearchAgenciesRef.current.value = workAgencyToAdd.agencyId;
                handleCloseAddWorkAgencyDialog();
                ToastService.showSuccessToast("L'UE est insérée dans LORIE.  Si vous souhaitez recevoir les camions de chantier EDispatch de cette UE, veuillez prévenir votre administrateur CDS préféré afin de l'associer à votre zone logistique");
                getWorkAgencies(workAgencyToAdd.agencyId);
            });
    }

    const handleAddWorkAgencyDialogClick = (): void => {
        setIsAddWorkAgencyDialogOpened(true);
    }

    const handleWorkAgencyIdentifierChange = (value: string) => {
        setWorkAgencyId(value);
    }

    const handleCloseAddWorkAgencyDialog = (): void => {
        setIsAddWorkAgencyDialogOpened(false);
        setActiveStep(0);
        setWorkAgencyId('');
        setWorkAgencyToAdd(null);
    }

    const handlePreviousStep = (): void => {
        setActiveStep(activeStep - 1);
    }

    const headerContentComponent: JSX.Element = useMemo(() =>
        <HeaderContentComponent
            inputSearchWorkAgenciesValue={inputSearchWorkAgenciesValue}
            inputSearchWorkAgenciesRef={inputSearchAgenciesRef}
            sort={sort}
            handleWorkAgenciesKeyPress={WorkAgenciesKeyPressed}
            handleClearSearchText={clearSearchText}
            handleAddWorkAgencyDialogClick={handleAddWorkAgencyDialogClick}
            handlerAfterUpload={handlerAfterUpload}
            handlerBeforeUpload={handlerBeforeUpload}
            displayErrorResponse={displayErrorResponse}
        />, [inputSearchWorkAgenciesValue, inputSearchAgenciesRef, sort]);

    const workAgenciesComponent: JSX.Element = useMemo(() =>
        <WorkAgenciesReferentialComponent
            workAgencies={workAgenciesList}
            workAgencyChoicesLogisticsUnit={workAgencyChoicesLogisticsUnit}
            sort={sort}
            handleSortChange={handleSortChange}
            handleSortColumnChange={handleSortColumnChange}
        />, [workAgenciesList, workAgencyChoicesLogisticsUnit, sort]);

    return (
        <Box className="internal-agencies referencial">
            <Box display="flex" flexDirection="row" flex="wrap" className="view-title">
                Gestion des unités d'exploitation travaux Colas
            </Box>
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box display="flex" flexDirection="column" flex="wrap">
                        {headerContentComponent}
                        {(loading ?
                            <ScaleLoaderComponent loading={loading} />
                            :
                            workAgenciesComponent
                        )}
                    </Box>
                </IntlProvider>
            </LocalizationProvider>
            <SimpleDialog isOpen={isAddWorkAgencyDialogOpened}
                onClose={handleCloseAddWorkAgencyDialog}
                closeIcon={true}
                dialogTitle="Ajouter une Unité d'Exploitation Travaux"
                classNameVal="add-work-agency-dialog"
                component={
                    <AddWorkAgencyDialogComponent
                        activeStep={activeStep}
                        workAgencySearchLoading={workAgencySearchLoading}
                        agencyId={workAgencyId}
                        workAgencyChoices={workAgencyToAdd}
                        handleWorkAgencyIdentifierChange={handleWorkAgencyIdentifierChange}
                        searchWorkAgencyByIdentifier={searchWorkAgencyByIdentifier}
                        handleSearchInReferentialGrid={handleSearchInReferentialGrid}
                        confirmAddWorkAgency={confirmAddWorkAgency}
                        handlePreviousStep={handlePreviousStep}
                    />
                }
            />
        </Box>
    );
}
