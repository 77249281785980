import { Box, Button, Checkbox, CircularProgress, debounce, FormControlLabel, TextareaAutosize, TextField, Tooltip } from '@mui/material';
import { UploadOnAddEvent, UploadOnBeforeUploadEvent } from '@progress/kendo-react-upload';
import React, { useState } from 'react';
import { CommandResultLight } from 'src/shared/models/WebAppActionResult';
import ToastService from 'src/ToastService';
import { AddedAttachmentResult } from '../services/dataContracts/controller/AddedAttachmentResult';
import { AttachmentArgs } from '../services/dataContracts/controller/AttachmentArgs';
import { AttachmentStatus } from '../services/dataContracts/controller/AttachmentStatus';
import { SendEmailArgs } from '../services/dataContracts/controller/SendEmailArgs';
import { TransporterLightModel } from '../services/dataContracts/queryStack/TransporterLightModel';
import { TransportPurchasesApiClient } from '../services/TransportPurchasesApiClient';
import { AttachmentsComponent } from './AttachmentsComponent';

interface TransporterListEmailNotificationsDialogComponentProps {
    attachmentFiles: Array<AttachmentArgs>,
    isLoadingAttachments: boolean,
    selectedTransporters: Array<string>,
    allTransporters: Array<TransporterLightModel>,
    handleAdd: (event: UploadOnAddEvent) => void,
    handleDeleteAttachment: (attachmentId: string, state: AttachmentStatus) => void,
    handlerAfterUploadAttachment: (attachments: AddedAttachmentResult[]) => void,
    handlerBeforeUpload: (event: UploadOnBeforeUploadEvent) => void,
    handlerDisplayErrorsAfterUpload: (commandResults: CommandResultLight[]) => void,
    handleCloseDialogAfterSendEmail: () => void,
}

export const TransporterListEmailNotificationsDialogComponent = (props: TransporterListEmailNotificationsDialogComponentProps): JSX.Element => {
    const [emailContent, setEmailContent] = useState<string>('');
    const [emailSubject, setEmailSubject] = useState<string>('');
    const [emailBccChecked, setEmailBccChecked] = useState<boolean>(false);
    const [loading, setLoading] = useState(false)

    const handleSendEmail = debounce((): void => {
        setLoading(true);
        var attachmentsList = [];
        props.attachmentFiles.forEach(
            attachment => attachmentsList.push(attachment.attachmentId)
        )

        var toTransportersList = [];
        props.allTransporters.forEach(
            transporter => {
                if (props.selectedTransporters.indexOf(transporter.transporterId) > -1) {
                    toTransportersList.push(transporter.transporterEmail)
                }
            }
        )
        const requestArgs: SendEmailArgs = {
            attachments: attachmentsList,
            isBcc: emailBccChecked,
            content: emailContent,
            subject: emailSubject,
            to: toTransportersList
        };

        TransportPurchasesApiClient.SendEmail(requestArgs)
            .then((res) => {
                if (res?.status != 200) {
                    ToastService.showErrorToast(`Erreur d'envoi, merci de créer un incident au 3003`);
                }
                else {
                    ToastService.showSuccessToast(`Message envoyé à ${props.selectedTransporters.length} transporteur(s).`);
                    setEmailContent('');
                    setEmailSubject('');
                    setEmailBccChecked(false);
                    props.handleCloseDialogAfterSendEmail();
                }
            })
            .finally(() => {
                setLoading(false); // Reset loading state regardless of success or failure
            });
    }, 500);

    const toggleBcc = () => {
        var isChecked = emailBccChecked;
        setEmailBccChecked(!isChecked);
    }

    const handleEmailContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setEmailContent(e.target.value);
    }

    return (
        <Box display="flex" flexDirection="column" className="transporters-notifications-content" height="100%">
            <Box p={1}>
                <TextField className="email-text-input" placeholder="Objet" value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)}></TextField>
            </Box>
            <Box p={1}>
                <TextareaAutosize
                    value={emailContent}
                    minRows={15}
                    className="email-text-input"
                    aria-label="email notification"
                    onChange={(e) => handleEmailContentChange(e)}
                />
            </Box>
            <Box p={1}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={emailBccChecked}
                            onClick={toggleBcc} />
                    }
                    label="Recevoir une copie de l'email"
                />
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box display="flex" flexDirection="column" justifyContent="flex-start" mt="10px">
                    <AttachmentsComponent
                        attachmentFiles={props.attachmentFiles}
                        isLoadingAttachments={props.isLoadingAttachments}
                        handleAdd={props.handleAdd}
                        handleDeleteAttachment={props.handleDeleteAttachment}
                        handlerAfterUploadAttachment={props.handlerAfterUploadAttachment}
                        handlerBeforeUpload={props.handlerBeforeUpload}
                        handlerDisplayErrorsAfterUpload={props.handlerDisplayErrorsAfterUpload} />
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="flex-end" mb="10px">
                    {loading ? (
                        <CircularProgress />
                    ) : (<Tooltip title="Envoyer email" placement="top">
                        <div>
                            <Button variant="contained" className="send-email" title="Envoyer Email" disabled={emailContent.length == 0 && props.attachmentFiles.length == 0} onClick={handleSendEmail}>
                                Envoyer email
                            </Button>
                        </div>
                    </Tooltip>)}
                </Box>
            </Box>
        </Box>
    );
}