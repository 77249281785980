import { GridCellProps } from '@progress/kendo-react-grid';
import React, { Fragment } from 'react';
import Utilities from '../../../../utils/Utilities';

interface PhoneNumberLinkProperties {
    phoneNumber: string
}

const PhoneNumberLink = (properties: PhoneNumberLinkProperties): JSX.Element => {
    return <a href={`callTo:${Utilities.formatPhoneNumber(properties.phoneNumber)}`}>{Utilities.formatPhoneNumber(properties.phoneNumber)}</a>;
}

export const CustomPhoneNumberCell = (props: GridCellProps): JSX.Element => {

    const phoneNumbers: string[] = props.dataItem?.phoneNumber && props.dataItem.phoneNumber.includes(',') ? props.dataItem.phoneNumber.split(',') : null;

    if (phoneNumbers) {
        return (
            <td className="phone-number-ref">
                {phoneNumbers.map((phoneNumber: string, index: number) => {
                    return (
                        <Fragment key={`phoneNumber-${index}`}>
                            <PhoneNumberLink phoneNumber={phoneNumber} key={`phoneNumber-${index}`} />
                            {index < phoneNumbers.length - 1 && ', '}
                        </Fragment>
                    );
                })}
            </td>
        );
    }

    return (
        <td className="phone-number-ref">
            {props.dataItem?.phoneNumber ? <PhoneNumberLink phoneNumber={props.dataItem?.phoneNumber} /> : ''}
        </td>
    );
}
