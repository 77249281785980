import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import Utilities from 'src/utils/Utilities';
import { UpdateDriverRequestArgs } from '../services/dataContracts/controller/UpdateDriverRequestArgs';
import { InternalDriverLightModel } from '../services/dataContracts/queryStack/InternalDriverLightModel';

interface EditInternalDriverDialogComponentProps {
    internalDriverSelected: InternalDriverLightModel,
    handleEditInternalDriverClick: (requestArgs: UpdateDriverRequestArgs) => void,
    handleEditAgencyForInternalDriverClick: () => void
}

export const EditInternalDriverDialogComponent = (props: EditInternalDriverDialogComponentProps): JSX.Element => {
    const [phoneNumber, setPhoneNumber] = useState<string>(props.internalDriverSelected.phoneNumber);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(props.internalDriverSelected.phoneNumber ? Utilities.isValidPhoneNumber(props.internalDriverSelected.phoneNumber) : false);
    const [enabledValidButton, setEnabledValidButton] = useState<boolean>(false);

    const handleChangePhoneNumber = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const value = event.target.value;
        setPhoneNumber(value);

        const isPhoneValid = value && Utilities.isValidPhoneNumber(value);
        if (isPhoneValid)
            setIsPhoneNumberValid(true);
        else
            setIsPhoneNumberValid(false);

        const validButton = (value !== props.internalDriverSelected.phoneNumber && isPhoneValid);
        setEnabledValidButton(validButton);
    }

    return (
        <Box display="flex" flexDirection="column" className="edit-content">
            <Box className="driver-title">
                <span className="driver-name">{`${props.internalDriverSelected.lastName} ${props.internalDriverSelected.firstName}`}</span>&nbsp;-&nbsp;{props.internalDriverSelected.employeeId}
            </Box>
            <Box pt={2}>
                <TextField error={!isPhoneNumberValid} label="Téléphone portable" value={phoneNumber ? phoneNumber : ''} className="phone-number"
                    helperText={!isPhoneNumberValid ? "Le numéro n'est pas valide." : ''} variant="outlined" onChange={handleChangePhoneNumber} />
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" pt={2} pl={2}><Button variant="contained" size='small' color="primary" title="METTRE A JOUR TRANSPORTEUR INTERNE" disabled={!props.internalDriverSelected.hasDifferentAgencyOnColasReferential}
                onClick={() => props.handleEditAgencyForInternalDriverClick()}>
                METTRE A JOUR TRANSPORTEUR INTERNE
            </Button>
                <Box pl={2}></Box>
                <Button variant="contained" color="primary" title="Modifier le chauffeur interne" disabled={!enabledValidButton} onClick={() => props.handleEditInternalDriverClick({
                    employeeId: props.internalDriverSelected.employeeId,
                    phoneNumber: phoneNumber
                })}>
                    Modifier
                </Button>
            </Box>
        </Box>
    );
}
