import { Box, Button, TextField } from '@mui/material';
import React from 'react';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import ToastService from 'src/ToastService';
import { SettingsProvider } from '../../../../SettingsProvider';
import BusinessErrors from '../../../../utils/BusinessErrors';
import { AddedVehicleRequestArgs } from '../services/dataContracts/controller/AddedVehicleRequestArgs';
import { UpdatedVehicleRequestArgs } from '../services/dataContracts/controller/UpdatedVehicleRequestArgs';
import { VehicleLightModel } from '../services/dataContracts/queryStack/VehicleLightModel';
import { VehicleTypeEstimatedLoadCapacityLightModel } from '../services/dataContracts/queryStack/VehicleTypeEstimatedLoadCapacityLightModel';
import { ThirdPartyTransporterApiClient } from '../services/ThirdPartyTransporterApiClient';

interface AddOrUpdateVehicleComponentState {
    licencePlate: string,
    loadCapacity: number,
    vehicleTypes: Array<VehicleTypeEstimatedLoadCapacityLightModel>,
    vehicleTypeId: string,
    vehicleType: VehicleTypeEstimatedLoadCapacityLightModel
}

interface IChildComponentProps {
    onClose: () => void,
    onAddOrUpdate: (transporterId: string, message: string) => void,
    transporterId: string;
    vehicle?: VehicleLightModel
}

class AddOrUpdateVehicleComponent extends React.Component<IChildComponentProps, AddOrUpdateVehicleComponentState> {
    immatriculationInputRef: React.RefObject<HTMLTextAreaElement>;
    chargeInputRef: React.RefObject<HTMLTextAreaElement>;

    constructor(props: IChildComponentProps) {
        super(props);
        this.immatriculationInputRef = React.createRef();
        this.chargeInputRef = React.createRef();

        this.state = {
            licencePlate: '',
            loadCapacity: null,
            vehicleTypes: [],
            vehicleTypeId: '',
            vehicleType: null
        }
    }

    handleChange = (event: any, property: string): void => {
        if (property === 'licencePlate') {
            this.setState({ licencePlate: event.target.value });
        }
        else if (property === 'vehicleTypeId') {
            this.setState({ vehicleTypeId: event.vehicleTypeId, vehicleType: event, loadCapacity: event.loadCapacity });
        }
        else if (property === 'loadCapacity') {
            if (event.target.value < 0)
                this.setState({ loadCapacity: 0 });
            else
                this.setState({ loadCapacity: event.target.value.replace(/\D{1,}/, '') });
        }
    }

    hasValidInformations = (): boolean => {
        let isValid = true;
        const _config = SettingsProvider.Get();
        const reg_licence_plate = '^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$';
        const reg_licence_plate1 = '^([0-9]{1}|[0-9]{2}|[0-9]{3}|[0-9]{4})([a-zA-Z]{1}|[a-zA-Z]{2}|[a-zA-Z]{3})([0-9]{1}|[0-9]{2}|[0-9]{3}|2A|2B)$';
        if (!this.state.licencePlate.match(reg_licence_plate) && !this.state.licencePlate.match(reg_licence_plate1)) {
            isValid = false;
            ToastService.showErrorToast("Plaque d'immatriculation Invalide");
        } else if (this.state.loadCapacity < _config.minimumLoadCapacity) {
            isValid = false;
            ToastService.showErrorToast("La charge utile doit être supérieure à " + _config.minimumLoadCapacity + " kg");
        }
        return isValid;
    }

    addVehicle = (): void => {
        if (this.hasValidInformations()) {
            let isNewVehicle = true;
            const vehicle: AddedVehicleRequestArgs = {
                transporterId: this.props.transporterId,
                vehicleTypeId: this.state.vehicleTypeId,
                licencePlate: this.state.licencePlate,
                loadCapacity: Number(this.state.loadCapacity)
            }

            ThirdPartyTransporterApiClient.SearchLicencePlate(this.props.transporterId, true, true)
                .then(res => {
                    res.data.forEach(function (element: VehicleLightModel) {
                        if (element.licencePlate.toUpperCase() === vehicle.licencePlate.toUpperCase())
                            isNewVehicle = false;
                    });

                    if (!isNewVehicle)
                        ToastService.showErrorToast("Plaque d'immatriculation existe déja.");
                    else {
                        ThirdPartyTransporterApiClient.AddVehicle(vehicle)
                            .then(json => {
                                const errors: string[] = BusinessErrors.Get(json.data);
                                if (errors.length > 0) {
                                    ToastService.showErrorToast("L'ajout n'est pas pris en compte.");
                                    return;
                                }
                                this.props.onAddOrUpdate(this.props.transporterId, "Le camion a été ajouté avec succès.");
                            });
                    }
                });
        }
    }

    updateVehicle = (): void => {
        if (this.hasValidInformations()) {
            const vehicle: UpdatedVehicleRequestArgs = {
                id: this.props.vehicle.id,
                transporterId: this.props.transporterId,
                vehicleTypeId: this.state.vehicleTypeId,
                licencePlate: this.state.licencePlate,
                loadCapacity: Number(this.state.loadCapacity)
            }
            ThirdPartyTransporterApiClient.UpdateVehicle(vehicle)
                .then(json => {
                    const errors: string[] = BusinessErrors.Get(json.data);
                    if (errors.length > 0) {
                        ToastService.showErrorToast("Les modifications ne sont pas pris en compte.");
                        return;
                    }
                    this.props.onAddOrUpdate(this.props.transporterId, "Le camion a été mis à jour avec succès.");
                });
        }
    }

    addOrUpdateVehicle = (): void => {
        if (!this.props.vehicle)
            this.addVehicle();
        else
            this.updateVehicle();
    }

    componentDidMount() {
        this.loadVehicles();
        //load Vehicle when edit
        this.loadVehicle();
    }

    loadVehicles = (): void => {
        ThirdPartyTransporterApiClient.GetVehicleTypes()
            .then(res => {
                const data: VehicleTypeEstimatedLoadCapacityLightModel[] = res.data;
                this.setState({
                    vehicleTypes: data.map((v: VehicleTypeEstimatedLoadCapacityLightModel) => ({
                        vehicleTypeId: v.vehicleTypeId,
                        label: v.label,
                        loadCapacity: v.loadCapacity
                    }))
                });
            });
    }

    loadVehicle = (): void => {
        if (this.props.vehicle) {
            this.immatriculationInputRef.current.value = this.props.vehicle.licencePlate;
            this.immatriculationInputRef.current.disabled = true;
            this.setState({
                licencePlate: this.props.vehicle.licencePlate,
                vehicleType: {
                    label: this.props.vehicle.vehicleTypeLabel,
                    vehicleTypeId: this.props.vehicle.vehicleTypeId,
                    loadCapacity: this.props.vehicle.loadCapacity
                },
                loadCapacity: this.props.vehicle.loadCapacity,
                vehicleTypeId: this.props.vehicle.vehicleTypeId
            });
        }
    }

    render() {
        const { onClose } = this.props;
        const isEnabledBtn: boolean = this.state.licencePlate !== "" && this.state.loadCapacity !== null && this.state.vehicleTypeId !== "";
        const loadCapacity = this.state.loadCapacity ? this.state.loadCapacity : '';
        const vehicleType = this.state.vehicleType;
        const vehicleTypes = this.state.vehicleTypes ? this.state.vehicleTypes : [];

        return (
            <>
                <Box display="flex" flexWrap="wrap" flexDirection="row" className="add-update-vehicle">
                    <Box mb={"20px"} width="100%">
                        <TextField
                            className="input-vehicle"
                            label="Plaque d'immatriculation"
                            variant="outlined"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => this.handleChange(e, 'licencePlate')}
                            inputRef={this.immatriculationInputRef}
                        />
                    </Box>
                    <span className="error-msg">La plaque d'immatriculation ne doit pas contenir des espaces et des tirets</span>
                    <Box mb={"20px"} width="100%" display="flex" flexDirection="column">
                        <Select
                            className="vt-select-search"
                            id="select-vehicle-type"
                            placeholder="Type de camion"
                            menuPosition="fixed"
                            menuShouldBlockScroll={true}
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.vehicleTypeId as string}
                            options={vehicleTypes}
                            value={vehicleType}
                            onChange={(e) => this.handleChange(e, 'vehicleTypeId')}
                        />
                    </Box>
                    <Box mb={"20px"} width="100%">
                        <TextField
                            className="input-vehicle"
                            label="Charge utile (kg)"
                            type="text"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={loadCapacity}
                            onChange={(e) => this.handleChange(e, 'loadCapacity')}
                            inputRef={this.chargeInputRef}
                        />
                    </Box>
                    <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="flex-end" width="100%">
                        <Button variant="contained" color="secondary" style={{ 'margin': '5px' }} onClick={onClose}>
                            Annuler
                        </Button>
                        <Button variant="contained" color="primary" style={{ 'margin': '5px' }} disabled={!isEnabledBtn} onClick={this.addOrUpdateVehicle}>
                            Sauvegarder
                        </Button>
                    </Box>
                </Box>
            </>
        );
    }
}

export default AddOrUpdateVehicleComponent;
