import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { IntlProvider, load, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import '@progress/kendo-theme-material/dist/all.css';
import { debounce } from 'lodash';
import React from 'react';
import Select from 'react-select';
import { ScaleLoader } from 'react-spinners';
import { UserApiClient } from 'src/shared/User/services/UserApiClient';
import ToastService from 'src/ToastService';
import BusinessWarnings from 'src/utils/BusinessWarnings';
import Utilities from 'src/utils/Utilities';
import { WebAppActionResult } from '../../../shared/models/WebAppActionResult';
import BusinessErrors from '../../../utils/BusinessErrors';
import '../../../utils/Date';
import { SessionStorage } from '../../../utils/Storage';
import { RouteComponentProps, withRouter } from '../../../withRouter';
import { LoadingsCardComponent } from './components/LoadingsCardComponent';
import { TransportFlowInfoDetailRequestArgs } from './services/dataContracts/controller/TransportFlowInfoDetailRequestArgs';
import { TransportFlowInfoRequestArgs } from './services/dataContracts/controller/TransportFlowInfoRequestArgs';
import { JobSiteDeliveriesLightModel } from './services/dataContracts/queryStack/JobSiteDeliveriesLightModel';
import { PlanningVehicleTripsLightModel } from './services/dataContracts/queryStack/PlanningVehicleTripsLightModel';
import { ThirdPartyBeneficiaryApiClient } from './services/ThirdPartyBeneficiaryApiClient';
import './ThirdPartyBeneficiaryStyles.scss';

const weekData = require('cldr-core/supplemental/weekData.json');
const caGregorian = require('cldr-dates-full/main/fr/ca-gregorian.json');
const dateFields = require('cldr-dates-full/main/fr/dateFields.json');
const timeZoneNames = require('cldr-dates-full/main/fr/timeZoneNames.json');
const numbers = require('cldr-numbers-full/main/fr/numbers.json');
const esMessages = require('../../../config/fr.json');
loadMessages(esMessages, 'fr-FR');

load(
    weekData,
    caGregorian,
    dateFields,
    timeZoneNames,
    numbers
);

/* eslint-disable */
interface ThirdPartyBeneficiaryState {
    expandedPanelJobSite: string,
    expandedPanelsJobSites: Map<string, boolean>,
    expandedPanelsTransportRequests: Map<string, boolean>,
    expandedPanelState: boolean,
    pageLength: number,
    pageNumber: number,
    DeliveryDate: Date,
    selectedBeneficiary: BeneficiarySelectModel,
    JobSiteList: Array<JobSiteDeliveriesLightModel>
    fromTime: Date,
    disableSwitchAction: boolean,
    role: string,
    beneficiaryList: BeneficiarySelectModel[],
    token: string,
    tokenAcces: boolean,
    tokenDeliveryDate: Date,
    loading: boolean
}

interface ThirdPartyBeneficiaryProps {
    role?: string,
    logisticsUnitIds?: Array<string>
}

interface BeneficiarySelectModel {
    value: string,
    label: string
}

class ThirdPartyBeneficiaryView extends React.Component<RouteComponentProps & ThirdPartyBeneficiaryProps, ThirdPartyBeneficiaryState> {
    _isMounted: boolean;
    today: Date = new Date();
    datePickerRef: React.RefObject<DatePicker>;

    constructor(props: RouteComponentProps & ThirdPartyBeneficiaryProps) {
        super(props);
        const searchParams: URLSearchParams = new URLSearchParams(this.props.location.search);
        this.datePickerRef = React.createRef();

        this.state = {
            expandedPanelJobSite: "",
            expandedPanelsJobSites: new Map<string, boolean>(),
            expandedPanelsTransportRequests: new Map<string, boolean>(),
            expandedPanelState: false,
            DeliveryDate: SessionStorage.ActiveStartDate,
            selectedBeneficiary: null,
            JobSiteList: [],
            pageLength: 100000,
            pageNumber: 0,
            fromTime: null,
            disableSwitchAction: false,
            role: '',
            beneficiaryList: [],
            token: searchParams.get("K"),
            tokenAcces: false,
            tokenDeliveryDate: null,
            loading: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.state.token !== null) {
            this.setState({
                tokenAcces: true
            });
            this.search(this.state.pageLength, this.state.pageNumber, null, null, this.state.token, true);
        } else {
            this.getBeneficiaries(this.state.DeliveryDate);
            var fromTime = this.state.DeliveryDate.stripTime();
            this.setState({
                fromTime: fromTime,
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    search = (pageLength: number, pageNumber: number, beneficiary: BeneficiarySelectModel, planningDate: Date, token: string, needInitPanels?: boolean): void => {
        this.setState({ loading: true });
        ThirdPartyBeneficiaryApiClient.Search(pageLength, pageNumber, beneficiary?.value, planningDate, token)
            .then(res => {
                let mapPanels: Map<string, boolean> = new Map<string, boolean>();
                let delCallsPanels: Map<string, boolean> = new Map<string, boolean>();
                let state: ThirdPartyBeneficiaryState = {
                    ...this.state,
                    JobSiteList: res.data.pageItems,
                    loading: false
                };
                if (needInitPanels) {
                    let cp: number = 0;
                    let cpCalls: number = 0;
                    if (res.data.pageItems.length === 1) {
                        mapPanels.set(`${res.data.pageItems[0].freeFormDeliveryAddress}-panel-${cp}`, true);
                        res.data.pageItems[0].transportRequests.forEach(j => {
                            delCallsPanels.set(`${j.transportRequestId}-panel-${cpCalls}`, true);
                            cpCalls++;
                        })
                    }
                    else if (res.data.pageItems.length > 0) {
                        res.data.pageItems.forEach(i => {
                            mapPanels.set(`${i.freeFormDeliveryAddress}-panel-${cp}`, false);
                            cp++;
                            i.transportRequests.forEach(j => {
                                delCallsPanels.set(`${j.transportRequestId}-panel-${cpCalls}`, true);
                                cpCalls++;
                            })
                        });
                    }

                    state.expandedPanelsJobSites = mapPanels;
                    state.expandedPanelsTransportRequests = delCallsPanels;
                }
                if (this._isMounted) {
                    this.setState(state);
                }

            })
            .catch(() => {
                this.setState({ loading: false });
            });
        if (token !== null) {
            UserApiClient.FindLinkToken(this.state.token)
                .then(res => {
                    var data = res.data;
                    if (data) {
                        var deliveryDate: Date = new Date(data.planningDate)
                        var fromTime: Date = deliveryDate.stripTime();
                        if (this._isMounted) {
                            this.setState({
                                tokenDeliveryDate: data.planningDate,
                                fromTime: fromTime,
                                selectedBeneficiary: { value: data.fullName, label: data.fullName }
                            });
                        }
                    }
                });
        }
    }

    getBeneficiaries = (date: Date): void => {
        ThirdPartyBeneficiaryApiClient.GetBeneficiaries(date, this.props.logisticsUnitIds)
            .then(res => {
                if (this._isMounted) {
                    this.setState({
                        beneficiaryList: res.data.map(x => { return { value: x.fullName, label: x.fullName } }),
                    });
                }
            });
    }

    handleChange = (panel: string): void => {
        let expandedPanelsJobSites: Map<string, boolean> = this.state.expandedPanelsJobSites;

        let isExpanded: boolean = expandedPanelsJobSites.get(panel);
        expandedPanelsJobSites.set(panel, !isExpanded);

        this.setState({
            expandedPanelsJobSites: expandedPanelsJobSites
        });
    }

    handleChangePanelTransportRequests = (panel: string): void => {
        let expandedPanelsTransportRequests: Map<string, boolean> = this.state.expandedPanelsTransportRequests;

        let isExpanded: boolean = expandedPanelsTransportRequests.get(panel);
        expandedPanelsTransportRequests.set(panel, !isExpanded);

        this.setState({
            expandedPanelsTransportRequests: expandedPanelsTransportRequests
        });
    }

    handleDatepickerChange = (event: DatePickerChangeEvent): void => {
        var fromTime: Date = event.value.stripTime();
        this.setState({
            DeliveryDate: event.value,
            fromTime: fromTime
        });

        if (event.target.state.show) {
            (this.datePickerRef.current as any).shouldFocusDateInput = false;
        }

        if (this.state.selectedBeneficiary) {
            if (this.state.token) {
                this.search(this.state.pageLength, this.state.pageNumber, this.state.selectedBeneficiary, fromTime, null, true);
            }
            else {
                this.searchBeneficiariesWithOrders(this.state.pageLength, this.state.pageNumber, this.state.selectedBeneficiary, fromTime, true);
            }
        }
        else
            this.getBeneficiaries(fromTime);
    }

    searchBeneficiariesWithOrders = (pageLength: number, pageNumber: number, selectedBeneficiary: BeneficiarySelectModel, date: Date, needInitPanels: boolean): void => {
        this.setState({ loading: true });
        ThirdPartyBeneficiaryApiClient.SearchBeneficiariesWithOrders(pageLength, pageNumber, selectedBeneficiary?.value, date, this.props.logisticsUnitIds)
            .then(res => {
                if (this._isMounted && res && res.length > 0) {
                    let beneficiaries = res[0];
                    let orders = res[1];

                    let mapPanels: Map<string, boolean> = new Map<string, boolean>();
                    let delCallsPanels: Map<string, boolean> = new Map<string, boolean>();
                    let jobSiteList: JobSiteDeliveriesLightModel[] = [];
                    let beneficiaryList: BeneficiarySelectModel[] = [];

                    if (beneficiaries.data && beneficiaries.data && beneficiaries.data.length > 0)
                        beneficiaryList = beneficiaries.data.map(x => { return { value: x.fullName, label: x.fullName } });

                    if (orders.data && orders.data.pageItems && orders.data.pageItems.length > 0)
                        jobSiteList = orders.data.pageItems;

                    let state: ThirdPartyBeneficiaryState = {
                        ...this.state,
                        JobSiteList: jobSiteList,
                        beneficiaryList: beneficiaryList,
                        loading: false
                    };
                    if (needInitPanels) {
                        let cp: number = 0;
                        let cpCalls: number = 0;
                        if (orders.data.pageItems && orders.data.pageItems.length === 1) {
                            mapPanels.set(`${orders.data.pageItems[0].freeFormDeliveryAddress}-panel-${cp}`, true);
                            orders.data.pageItems[0].transportRequests.forEach(j => {
                                delCallsPanels.set(`${j.transportRequestId}-panel-${cpCalls}`, true);
                                cpCalls++;
                            })
                        }
                        else if (orders.data.pageItems && orders.data.pageItems.length > 0) {
                            orders.data.pageItems.forEach(i => {
                                mapPanels.set(`${i.freeFormDeliveryAddress}-panel-${cp}`, false);
                                cp++;
                                i.transportRequests.forEach(j => {
                                    delCallsPanels.set(`${j.transportRequestId}-panel-${cpCalls}`, true);
                                    cpCalls++;
                                })
                            });
                        }
                        state.expandedPanelsJobSites = mapPanels;
                        state.expandedPanelsJobSites = delCallsPanels;
                    }


                    let indexBeneficiary: number = beneficiaryList.findIndex(o => {
                        return o.value === selectedBeneficiary.value;
                    });

                    if (indexBeneficiary != -1) {
                        selectedBeneficiary = selectedBeneficiary;
                    }

                    state.selectedBeneficiary = selectedBeneficiary;
                    if (this._isMounted) {
                        this.setState(state);
                    }
                }
            })
            .catch(e => {
                if (this._isMounted) {
                    this.setState({ loading: false });
                }
            });
    }

    changeTransportFlowPlanningVehiclesTripsFinishedStatus = debounce((transportRequestId: string, transportFlowId: string, shouldChangeToFinished: boolean, details: Array<TransportFlowInfoDetailRequestArgs>): void => {
        this.setState({
            disableSwitchAction: true,
        });

        var requestArgs: TransportFlowInfoRequestArgs = {
            transportRequestId: transportRequestId,
            transportFlowId: transportFlowId,
            shouldChangeToFinished: shouldChangeToFinished,
            details: details
        };

        ThirdPartyBeneficiaryApiClient.UpdateTransportRequestStatus(requestArgs)
            .then(json => {
                let data: WebAppActionResult = json?.data;
                var errors: string[] = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors.filter(Utilities.distinct));
                }
                var warnings: string[] = BusinessWarnings.Get(data);
                if (warnings.length > 0) {
                    ToastService.showWarningToast("", warnings.filter(Utilities.distinct));
                }

                if (this.state.token !== null) {
                    this.search(this.state.pageLength, this.state.pageNumber, null, null, this.state.token);
                } else {
                    this.search(this.state.pageLength, this.state.pageNumber, this.state.selectedBeneficiary, this.state.fromTime, null, false);
                }
                if (this._isMounted) {
                    this.setState({
                        disableSwitchAction: false,
                    });
                }
            });
    }, 500);

    handleBeneficiaryChange = (event: BeneficiarySelectModel): void => {
        this.setState({ selectedBeneficiary: event });
        this.search(this.state.pageLength, this.state.pageNumber, event, this.state.fromTime, null, true);
    }

    handleClickOpenInvoicesDetails = (beneficiaryName: string, fromTime: Date): void => {
        var url: URL = new URL(window.location.href);
        var urlRedirect: string = '/';
        if (url.pathname.startsWith('/External')) {
            var param: string = url.searchParams.get("K");
            urlRedirect = `/External-BeneficiaryInvoicesDetails?K=${param}&beneficiary=${beneficiaryName}&fromTime=${fromTime}`;
        } else {
            urlRedirect += `ThirdPartyBeneficiaryInvoices?beneficiary=${beneficiaryName}&fromTime=${fromTime}`;
        }
        this.props.navigate(urlRedirect);
    }

    render() {
        const { beneficiaryList, tokenAcces, DeliveryDate, tokenDeliveryDate, JobSiteList, loading, selectedBeneficiary, fromTime } = this.state;
        const { role } = this.props;
        let ret: JSX.Element;
        if (tokenAcces || (role === 'ADM' || role === 'LOG')) {
            ret = (
                <Box display="flex" flexWrap="wrap" flexDirection="row" pl={2} pr={2} pb={1}>
                    <Box width="100%">
                        <Box display="flex" flexWrap="nowrap" flexDirection="row" justifyContent="space-between" className="beneficiary-header">
                            <Box display="flex" flexWrap="wrap" flexDirection="row">
                                <Box width="100%" className="flex-content flex-row flex-wrap">
                                    {!tokenAcces &&
                                        <>
                                            <Box pt={1}>
                                                <span className="font-weight-bold date_title text-mr">Date</span>
                                            </Box>
                                            <Box pr={2}>
                                                <LocalizationProvider language="fr-FR">
                                                    <IntlProvider locale="fr" >
                                                        <DatePicker ref={this.datePickerRef} defaultValue={DeliveryDate} onChange={(e) => this.handleDatepickerChange(e)} disabled={tokenAcces} />
                                                    </IntlProvider>
                                                </LocalizationProvider>
                                            </Box>
                                        </>
                                    }

                                    {tokenAcces &&
                                        <>
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
                                                    <path fill="#748285" fillRule="evenodd" d="M19.984 20V8.984H6.016V20h13.968zm0-16.032c.544 
                                                    0 1.016.2 1.416.6.4.4.6.872.6 1.416V20c0 .544-.2 1.008-.6 1.392-.4.384-.872.576-1.416.576H6.016c-.56
                                                    0-1.036-.192-1.428-.576A1.877 1.877 0 0 1 4 20V5.984c0-.544.196-1.016.588-1.416.392-.4.868-.6 
                                                    1.428-.6H7V2h2.016v1.968h7.968V2H19v1.968h.984zM18.016 11v1.968H16V11h2.016zm-4.032 0v1.968h-1.968V11h1.968zM10 11v1.968H7.984V11H10z" />
                                                </svg>
                                                <span className="pl-3 texte-date-cta">{`${Date.getDateFromIsoString(tokenDeliveryDate)}`}</span>
                                            </span>
                                        </>
                                    }
                                </Box>
                                <Box width="100%" className="flex-content flex-row flex-wrap">
                                    {!tokenAcces &&
                                        <>
                                            <Box pt={1}>
                                                <label className="font-weight-bold" htmlFor="Transporter text-mr">Bénéficiaire</label>
                                            </Box>
                                            <Box pr={2}>
                                                <Select
                                                    className="transporter-select"
                                                    isDisabled={beneficiaryList.length === 0}
                                                    isClearable
                                                    placeholder=""
                                                    menuPosition="fixed"
                                                    menuShouldBlockScroll={true}
                                                    options={beneficiaryList}
                                                    value={this.state.selectedBeneficiary}
                                                    onChange={(e) => this.handleBeneficiaryChange(e)}
                                                />
                                            </Box>
                                        </>
                                    }
                                </Box>
                            </Box>
                            <Box>
                                <Button className={!selectedBeneficiary ? "invoices-details-button disabled" : "invoices-details-button"}
                                    onClick={() => this.handleClickOpenInvoicesDetails(selectedBeneficiary?.value, fromTime)}
                                    disabled={!selectedBeneficiary ? true : false}
                                >
                                    Détail facturation
                                </Button>
                            </Box>
                        </Box>

                        {!loading && (JobSiteList.map((jobSite, index) =>
                            <Box key={`jobsite-${index}`} display="flex" flexWrap="wrap" flexDirection="row">
                                <Box width="100%">
                                    <Accordion key={`exp-index-${index}`}
                                        expanded={this.state.expandedPanelsJobSites.get(`${jobSite.freeFormDeliveryAddress}-panel-${index}`)}
                                        onChange={() => this.handleChange(`${jobSite.freeFormDeliveryAddress}-panel-${index}`)}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} className="card-content-summary">
                                            <Box display="flex" flexDirection="column">
                                                <div className="loading-card-font">Site arrivée</div>
                                                <Typography className="jobsite-name">{jobSite.freeFormDeliveryAddress}</Typography>
                                            </Box>
                                        </AccordionSummary>
                                        {jobSite.transportRequests.map((transportRequest, transportRequestIndex) => {
                                            let shouldChangeToFinished: boolean = transportRequest.isFinished ? false : true;
                                            let vehicleTripsNotFinished = transportRequest.vehicleTrips.filter(vt => !vt.isFinished);
                                            let isIndeterminate = transportRequest.isFinished
                                                ? false
                                                : (vehicleTripsNotFinished.length > 0 && vehicleTripsNotFinished.length < transportRequest.vehicleTrips.length);

                                            return (
                                                <AccordionDetails className="expansionPanelDetails" key={`jobsite-dc-${transportRequestIndex}`}>
                                                    <Box display="flex" flexWrap="wrap" flexDirection="row">
                                                        <Box width="100%">
                                                            <Accordion className="job-foreman-dc-expansionPanel" expanded={this.state.expandedPanelsTransportRequests.get(`${transportRequest.transportRequestId}-panel-${transportRequestIndex}`)}
                                                                onChange={() => this.handleChangePanelTransportRequests(`${transportRequest.transportRequestId}-panel-${transportRequestIndex}`)}>
                                                                <AccordionSummary className="job-foreman-dc-expansionPanel-summary" expandIcon={<ExpandMoreIcon />}>
                                                                    <Box width="100%">
                                                                        <Box className="transportRequest" pt={1}>
                                                                            <span className="product-quantity">
                                                                                {`${transportRequest.productCode ? transportRequest.productCode + ' - ' : ''}${transportRequest.quantity ?? ''}${(transportRequest.unitOfMeasure ?? 'T') + ' - '}`}
                                                                            </span>
                                                                            {'Flux ' + transportRequest.businessId}
                                                                        </Box>
                                                                        <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="space-between" alignItems="center">
                                                                            <FormControlLabel
                                                                                onClick={(event) => event.stopPropagation()}
                                                                                className={transportRequest.isFinished ? 'finish-checkbox-control bg-button-finished' : 'finish-checkbox-control bg-button'}
                                                                                control={
                                                                                    <Checkbox
                                                                                        className={transportRequest.isFinished ? 'finished-checkbox' : 'not-finished-checkbox'}
                                                                                        checked={transportRequest.isFinished}
                                                                                        onChange={() => this.changeTransportFlowPlanningVehiclesTripsFinishedStatus(transportRequest.transportRequestId, transportRequest.transportFlowId, shouldChangeToFinished
                                                                                            , transportRequest.vehicleTrips.filter(vt => !vt.isLocked).map(vt => {
                                                                                                return {
                                                                                                    planningVehicleId: vt.planningVehicleId,
                                                                                                    deliveryTripIds: vt.deliveryTripIds
                                                                                                };
                                                                                            }))}
                                                                                        indeterminate={isIndeterminate}
                                                                                    />
                                                                                }
                                                                                disabled={!transportRequest.vehicleTrips.some(vt => !vt.isLocked)}
                                                                                label={<span className="finish-checkbox-label">Passer tous les camions à "Terminé"</span>}
                                                                                labelPlacement="start"
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </AccordionSummary>
                                                                <AccordionDetails className="job-foreman-dc-expansionPanel-details">
                                                                    <Box display="flex" flexWrap="wrap" flexDirection="row" className="bg-c-rectangle">
                                                                        {transportRequest.vehicleTrips.map((jobSiteVehicleTrip: PlanningVehicleTripsLightModel, loadingIndex) =>
                                                                            <Box key={`dc-vt-${loadingIndex}`} className="rectangle">
                                                                                <LoadingsCardComponent data={jobSiteVehicleTrip} transportRequestId={transportRequest.transportRequestId}
                                                                                    transportFlowId={transportRequest.transportFlowId} updateState={loading} role={role}
                                                                                    changeTransportFlowPlanningVehiclesTripsFinishedStatus={this.changeTransportFlowPlanningVehiclesTripsFinishedStatus} />
                                                                            </Box>
                                                                        )}
                                                                    </Box>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Box>

                                                    </Box>
                                                </AccordionDetails>
                                            );
                                        })}
                                    </Accordion>
                                </Box>
                            </Box>
                        ))}
                        {loading && <Box className='sweet-loading main-spinner'>
                            <ScaleLoader
                                width={5}
                                height={20}
                                radius={50}
                                color={'#000000'}
                                loading={this.state.loading}
                            />
                        </Box>}
                    </Box>
                </Box>);
        } else {
            ret = (
                <Box display="flex" flexWrap="wrap" flexDirection="row">
                    <Box width="100%">
                        <p>Vous n'avez pas les droits</p>
                    </Box>
                </Box>
            );
        }
        return ret;
    }
}

export default React.forwardRef(withRouter(ThirdPartyBeneficiaryView));