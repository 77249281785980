import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Checkbox, FormControlLabel, Input, InputAdornment } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { UploadOnBeforeUploadEvent, UploadOnStatusChangeEvent, UploadResponse } from '@progress/kendo-react-upload';
import React from 'react';
import { UploadComponent } from 'src/shared/components/Common/UploadComponent';
import { downloadFileFromBlob, getFileName } from '../../../../utils/DownloadFile';
import { ProductionSiteChoicesExportRequestArgs } from '../services/dataContracts/controller/ProductionSiteChoicesExportRequestArgs';
import { ProductionSitesReferentialApiClient } from '../services/ProductionSitesReferentialApiClient';

interface HeaderContentComponentProps {
    inputSearchProductionSitesValue: string,
    inputSearchProductionSitesRef: React.RefObject<HTMLInputElement>,
    sort: SortDescriptor[],
    handleProductionSitesKeyPress: (text: string) => void,
    handleClearSearchText: () => void,
    handleAddProductionSiteDialogClick: () => void,
    handlerAfterUpload: (uploadResponse: UploadResponse) => void,
    handlerBeforeUpload: (event: UploadOnBeforeUploadEvent) => void,
    displayErrorResponse: () => void,
    productionSiteIsLogisticZone: boolean,
    handleCheckBoxProductionSiteIsLogisticZoneClick: (event: React.ChangeEvent<HTMLInputElement>) => void,
    selectedProductionSiteId: string,
    handleEditLogisticalZoneDialogClick: () => void,
    role: string
}

export const HeaderContentComponent = (props: HeaderContentComponentProps) => {

    const exportToExcel = (): void => {
        const productionSiteChoicesExportRequestArgs: ProductionSiteChoicesExportRequestArgs = {
            searchText: props.inputSearchProductionSitesValue,
            sortField: props.sort.length > 0 ? props.sort[0].field : null,
            sortDirection: props.sort.length > 0 ? props.sort[0].dir : null
        };

        ProductionSitesReferentialApiClient.ExportProductionSiteChoices(productionSiteChoicesExportRequestArgs)
            .then(response => {
                const fileName = getFileName(response);
                const blob = new Blob([response.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const onUploadStatusChange = (event: UploadOnStatusChangeEvent): void => {
        const res = event.response;
        if (res?.status == 200) {
            props.handlerAfterUpload(event.response);
        }
        else if (props.displayErrorResponse) {
            props.displayErrorResponse();
        }
    }

    const inputSearchProductionSitesClassName: string = props.inputSearchProductionSitesValue.length > 2 ? 'search-text-active' : '';
    const uploadSaveUrl: string = ProductionSitesReferentialApiClient.GetImportUrl();
    const checkboxInputProps: React.InputHTMLAttributes<HTMLInputElement> = { 'aria-label': 'primary checkbox' };

    return (
        <Box display="flex" flexDirection="row" flex="wrap" justifyContent="start" alignItems="center" className="header-content">
            <Box>
                <Input disableUnderline className={inputSearchProductionSitesClassName} inputRef={props.inputSearchProductionSitesRef}
                    startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                    endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => props.handleClearSearchText()} /></InputAdornment>}
                    placeholder="Rechercher ..." onChange={(event) => props.handleProductionSitesKeyPress(event.target.value)} />
            </Box>
            <Box ml={'10px'}>
                <Button variant="contained" color="primary" title="Ajouter"
                    onClick={props.handleAddProductionSiteDialogClick}>
                    Ajouter
                </Button>
            </Box>
            <Box ml={'10px'}>
                <Button variant="contained" color="primary" title="Exporter sous format Excel" onClick={exportToExcel}>
                    Exporter
                </Button>
            </Box>
            <Box ml={'10px'}>
                <UploadComponent
                    multiple={false}
                    showFileList={false}
                    restrictions={{
                        allowedExtensions: ['.xls', '.xlsx']
                    }}
                    defaultFiles={[]}
                    withCredentials={false}
                    saveUrl={uploadSaveUrl}
                    onBeforeUpload={props.handlerBeforeUpload}
                    onStatusChange={onUploadStatusChange}
                />
            </Box>
            {props.role == 'ADM' &&
                <Box ml={'10px'}>
                    <FormControlLabel
                        id="global-search-vehicles-referential"
                        control={
                            <Checkbox
                                checked={props.productionSiteIsLogisticZone}
                                onChange={(e) => props.handleCheckBoxProductionSiteIsLogisticZoneClick(e)}
                                color="primary"
                                inputProps={checkboxInputProps}
                            />
                        }
                        label="N'afficher que les Zones Logistiques"
                        labelPlacement="end"
                    />
                </Box>}
            {props.role == 'ADM' &&
                <Box ml={'10px'}>
                    <Button variant="contained" color="primary" title="Modifier"
                        disabled={!props.selectedProductionSiteId}
                        onClick={props.handleEditLogisticalZoneDialogClick}
                    >
                        Modifier
                    </Button>
                </Box>
            }
        </Box>
    );
}
