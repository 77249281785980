import { GridCellProps } from '@progress/kendo-react-grid';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import '@progress/kendo-react-intl';
import React from 'react';
import { TransportSaleLightModelExtended } from '../models/TransportSaleLightModelExtended';
import { PreInvoiceSentStatusLightModel } from '../services/dataContracts/queryStack/PreInvoiceSentStatusLightModel';
import { TransportSaleEntityLightModel } from '../services/dataContracts/queryStack/TransportSaleEntityLightModel';

interface CustomPricesCellProps extends GridCellProps {
    refreshComputedCells: (preInvoice: TransportSaleLightModelExtended) => void
}

export const CustomPricesCellComponent = (props: CustomPricesCellProps) => {

    const handleChange = (dataItem: TransportSaleLightModelExtended, field: string, event: NumericTextBoxChangeEvent): void => {
        dataItem.inEdit = field;
        dataItem.invoicePrice = event.target.value;
        props.refreshComputedCells(dataItem);
    }

    const dataItem: TransportSaleLightModelExtended = props.dataItem;

    const dataValue: number = dataItem[props.field] ?? null;
    let defaultRendering: JSX.Element = <></>;

    if (dataItem.entity != TransportSaleEntityLightModel.planningVehicle ||
        !dataItem.inEdit ||
        dataItem.inEdit !== props.field ||
        dataItem.transportFlow?.sentStatus === PreInvoiceSentStatusLightModel.successfully) {
        defaultRendering = (
            <td>{dataValue?.toCurrencyString() ?? ""}</td>
        );
    } else {
        defaultRendering = (
            <td>
                <NumericTextBox
                    defaultValue={dataValue}
                    min={0}
                    width={110}
                    onChange={(event) => handleChange(dataItem, props.field, event)}
                />
            </td>
        );
    }

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}
