import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderCellProps, GridPageChangeEvent, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { CustomCheckboxCell } from '../../Common/CustomCheckboxCell';
import { CustomHeaderCellComponent } from '../../Common/CustomHeaderCellComponent';
import { SizingUtilities } from '../../SizingUtilities';
import { TransportPurchaseLightModelExtended } from '../models/TransportPurchaseLightModelExtended';
import { LogisticsUnitChoiceOfTransportersLightModel } from '../services/dataContracts/queryStack/LogisticsUnitChoiceOfTransportersLightModel';
import { TransportPurchasePricesReferentialApiClient } from '../services/TransportPurchasePricesReferentialApiClient';
import { CustomPhoneNumberCell } from './CustomPhoneNumberCell';


interface LogisticsUnitsProps {
    isForInternalTransporters: boolean,
    transportersArray: Array<TransportPurchaseLightModelExtended>,
    transportersMap: Map<string, TransportPurchaseLightModelExtended>,
    sort: SortDescriptor[],
    transporterLogisticsUnitsChoices: Array<LogisticsUnitChoiceOfTransportersLightModel>,
    handleSortChange: (e: GridSortChangeEvent) => void,
    handleSortColumnChange: (sortItems: SortDescriptor[]) => void,
    handleSelectedTransporter: (transporterId: string, transporterName) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const LogisticsUnitGridName = 'logisticsUnit';

export const LogisticsUnitsComponent = (props: LogisticsUnitsProps): JSX.Element => {
    const ModuleKey = props.isForInternalTransporters ? AppModule.InternalTransportPurchasePricesReferential : AppModule.ExternalTransportPurchasePricesReferential;
    
    const [transportersChoicesData] = useState<Map<string, TransportPurchaseLightModelExtended>>(props.transportersMap);
    const [skip, setSkip] = useState<number>(0);

    const forceUpdate = useForceUpdate();

    const getTransporterLogisticsUnitsWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, LogisticsUnitGridName, fieldName, columnWidth);
    }

    const getTransporterGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, LogisticsUnitGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            LogisticsUnitGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, LogisticsUnitGridName, event.columns);
        forceUpdate();
    }

    const handleRowClick = (event: GridRowClickEvent): void => {
        if (event.dataItem.selected)
            props.handleSelectedTransporter(null, null);
        else
            props.handleSelectedTransporter(event.dataItem.transporterId, event.dataItem.transporterName);
    }

    const updateTransportersChoicesLogisticsUnit = (checked: boolean, logisticsUnit: string, transporterId: string | number): void => {
        TransportPurchasePricesReferentialApiClient.UpdateTransporterChoiceLogisticsUnit({
            transporterId: transporterId as string,
            logisticsUnitId: logisticsUnit,
            isChecked: checked
        })
            .then(() => {
                const item: TransportPurchaseLightModelExtended = transportersChoicesData.get(transporterId as string);
                item[logisticsUnit] = checked;

                let checkedSelect = true;
                let indeterminateAll = true;
                let numberOfChecked = 0;
                let numberOfUnchecked = 0;
                props.transporterLogisticsUnitsChoices.forEach((transporter: LogisticsUnitChoiceOfTransportersLightModel) => {
                    if (!item[transporter.logisticsUnitId]) {
                        checkedSelect = false;
                        numberOfUnchecked += 1;
                    } else {
                        numberOfChecked += 1;
                    }
                });
                if (props.transporterLogisticsUnitsChoices.length === numberOfUnchecked || props.transporterLogisticsUnitsChoices.length === numberOfChecked) {
                    indeterminateAll = false;
                }

                item.isSelectedAll = checkedSelect ? true : (indeterminateAll ? null : false);
                item.isIndeterminateAll = indeterminateAll;

                forceUpdate();
            });
    }

    const updateTransportersChoicesAllLogisticsUnits = (checked: boolean, transporterId: string | number): void => {
        const logisticsUnitIds: Array<string> = [];
        props.transporterLogisticsUnitsChoices.forEach((element: LogisticsUnitChoiceOfTransportersLightModel) => {
            logisticsUnitIds.push(element.logisticsUnitId);
        });

        TransportPurchasePricesReferentialApiClient.SetOrUnsetTransporterChoiceToAllLogisticsUnits({
            transporterId: transporterId as string,
            isChecked: checked,
            logisticsUnitIds: logisticsUnitIds
        })
            .then(() => {
                const item = transportersChoicesData.get(transporterId as string);
                logisticsUnitIds.forEach((transporterId: string) => {
                    item[transporterId] = checked;
                });
                item.isSelectedAll = checked ? true : false;
                item.isIndeterminateAll = false;

                forceUpdate();
            });
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.transportersArray.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const dataGrid: TransportPurchaseLightModelExtended[] = orderBy(props.transportersArray, props.sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="colas_transporters-grid">
            <Grid
                className="transporters-grid"
                data={dataGrid}
                selectedField="selected"
                sortable
                reorderable
                resizable
                sort={props.sort}
                onRowClick={e => handleRowClick(e)}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={props.handleSortChange}
                editField="inEdit"
                rowHeight={rowHeight}
                scrollable="virtual"
                skip={skip}
                total={totalGrid}
                pageSize={Number(gridPageSize)}
                onPageChange={pageChange}
                style={gridStyle}
            >
                <GridColumn field="isSelectedAll" orderIndex={getTransporterGridOrderIndexColumn("isSelectedAll", 0)} width={getTransporterLogisticsUnitsWidth("isSelectedAll", 60)} title="Sélection"
                            cell={(props: GridCellProps) =>
                        <CustomCheckboxCell
                            {...props}
                            keyName="transporterId"
                            updateChoicesAllLogisticsUnits={updateTransportersChoicesAllLogisticsUnits}
                        />}
                            headerCell={(headerProps: GridHeaderCellProps) =>
                        <CustomHeaderCellComponent tooltip="Activer/Désactiver un transporteur pour toutes les zones logistiques" title={headerProps.title} field="isSelectedAll" sort={props.sort}
                            handleSortColumnChange={props.handleSortColumnChange} />
                    }
                />
                {!props.isForInternalTransporters &&
                    <GridColumn field="hasGeographicCoordinates"
                        cell={(props: GridCellProps) => {
                            return <td className="warning-icon-cell">
                                {!props.dataItem.hasGeographicCoordinates ?
                                    <Tooltip title="Le transporteur externe ne possède pas de coordonnées géographiques." placement="bottom">
                                        <Box>
                                            <FontAwesomeIcon size="lg" icon={faExclamationTriangle} />
                                        </Box>
                                    </Tooltip> : ''}
                            </td>
                        }}
                        orderIndex={getTransporterGridOrderIndexColumn("hasGeographicCoordinates", 1)}
                        title=" " width={40} resizable={false} reorderable={false} />
                }
                
                <GridColumn field="transporterName" orderIndex={getTransporterGridOrderIndexColumn("transporterName", 2)} width={getTransporterLogisticsUnitsWidth("transporterName", 140)}
                            title={props.isForInternalTransporters ? "Transporteur interne" :"Transporteur"}/>
                <GridColumn field="transporterId" orderIndex={getTransporterGridOrderIndexColumn("transporterId", 3)} width={getTransporterLogisticsUnitsWidth("transporterId", 75)} title="Id transporteur"/>
                <GridColumn field="address" orderIndex={getTransporterGridOrderIndexColumn("address", 4)} width={props.isForInternalTransporters ? 0 : getTransporterLogisticsUnitsWidth("address", 250)} title="Adresse"/>
                <GridColumn field="managerEmail" orderIndex={getTransporterGridOrderIndexColumn("managerEmail", 5)} width={getTransporterLogisticsUnitsWidth("managerEmail", 140)} title="Contact"/>
                <GridColumn field="phoneNumber" orderIndex={getTransporterGridOrderIndexColumn("phoneNumber", 6)} width={getTransporterLogisticsUnitsWidth("phoneNumber", 120)} title="Téléphone"
                    cell={(props: GridCellProps) => <CustomPhoneNumberCell {...props }/>}
                />
                {props.transporterLogisticsUnitsChoices.map((transporter: LogisticsUnitChoiceOfTransportersLightModel, i: number) =>
                    <GridColumn key={i} field={transporter.logisticsUnitId} orderIndex={getTransporterGridOrderIndexColumn(transporter.logisticsUnitId, 7 + i)} width={getTransporterLogisticsUnitsWidth(transporter.logisticsUnitId, 95)} title={transporter.label}
                        cell={(props: GridCellProps) =>
                            <CustomCheckboxCell
                                {...props}
                                keyName="transporterId"
                                updateChoicesLogisticsUnit={updateTransportersChoicesLogisticsUnit}
                            />}
                        headerCell={(headerProps: GridHeaderCellProps) =>
                            <CustomHeaderCellComponent tooltip="Activer/Désactiver un transporteur pour la zone logistique" title={headerProps.title} field={transporter.logisticsUnitId} sort={props.sort}
                                handleSortColumnChange={props.handleSortColumnChange} />
                        }
                    />
                )}
            </Grid>
        </Box>
    );
}