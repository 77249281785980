import axios, { AxiosResponse } from "axios";
import { WebAppActionResult } from "../../../../shared/models/WebAppActionResult";
import { AddNegotiatedSellPricesArgs } from "./dataContracts/controller/AddNegotiatedSellPricesArgs";
import { RemoveNegotiatedSellPriceArgs } from "./dataContracts/controller/RemoveNegotiatedSellPriceArgs";
import { AgencyLightModel } from "./dataContracts/queryStack/AgencyLightModel";
import { Level2ActivityLightModel } from "./dataContracts/queryStack/Level2ActivityLightModel";
import { NegotiatedSellPriceLightModel } from "./dataContracts/queryStack/NegotiatedSellPriceLightModel";
import { ProductionSiteLightModel } from "./dataContracts/queryStack/ProductionSiteLightModel";
import { ReferencedCustomerLightModel } from "./dataContracts/queryStack/ReferencedCustomerLightModel";
import { VehicleTypeLightModel } from "./dataContracts/queryStack/VehicleTypeLightModel";

const controllerUrl = "api/NegotiatedSellPricesReferential/";

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export class NegotiatedSellPricesReferentialApiClient {

    public static GetProductionSites = (logisticsUnitId: string)
        : Promise<AxiosResponse<Array<ProductionSiteLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetProductionSites?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: headers
        });
    }

    public static GetWorkAgencies = (logisticsUnitId: string)
        : Promise<AxiosResponse<Array<AgencyLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetWorkAgencies?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: headers
        });
    }

    public static GetLevel2Activities = (productionSiteId: string, logisticsUnitId: string)
        : Promise<AxiosResponse<Array<Level2ActivityLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetLevel2Activities?productionSiteId=${encodeURIComponent(productionSiteId)}&logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: headers
        });
    }

    public static GetReferencedCustomers = (logisticsUnitId: string)
        : Promise<AxiosResponse<Array<ReferencedCustomerLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetReferencedCustomers?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`,
            headers: headers
        });
    }

    public static GetVehicleTypes = ()
        : Promise<AxiosResponse<Array<VehicleTypeLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetVehicleTypes`,
            headers: headers
        });
    }

    public static AddNegotiatedSellPrices = (requestArgs: AddNegotiatedSellPricesArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AddNegotiatedSellPrices`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static SearchNegotiatedSellPrices = (logisticsUnitId: string, searchText: string)
        : Promise<AxiosResponse<Array<NegotiatedSellPriceLightModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}SearchNegotiatedSellPrices?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}&searchText=${encodeURIComponent(searchText)}`,
            headers: headers
        });
    }

    public static RemoveNegotiatedSellPrices = (negotiatedSellPricesToRemove: RemoveNegotiatedSellPriceArgs[])
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}RemoveNegotiatedSellPrices`,
            headers: headers,
            data: JSON.stringify(negotiatedSellPricesToRemove)
        });
    }
}
