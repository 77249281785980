export enum VehiclePriceKind {
    Day = "Day",

    HalfDay = "HalfDay",

    Night = "Night",

    PerTon = "PerTon",

    PerHour = "PerHour",

    PerTurn = "PerTurn"
}