import styles from '../../../_variables.scss';

let _gridOffsetFromWindowTop: number = null;

export class SizingUtilities {

    //40 is the row height defined in prop of the kendo grid
    public static rowHeight = 40;

    // gridOffsetFromWindowTop is the height of elements above grid.
    public static gridOffsetFromWindowTop = () => {
        if (!_gridOffsetFromWindowTop) {
            _gridOffsetFromWindowTop = parseInt(styles.referencialNegotiatedPurchasePricesGridOffsetFromWindowTop);
        }

        return _gridOffsetFromWindowTop;
    }

    // window.innerHeight get the hight of page from navigator size
    public static computeGridHeight = (gridOffsetFromWindowTop: number) => {
        return window.innerHeight - gridOffsetFromWindowTop;
    }

    // rowHeight is the row height defined in prop of the kendo grid
    public static computeGridPageSize = (gridHeight: number, rowHeight: number) => {
        return Number((gridHeight / rowHeight).toFixed(0));
    }

}