import { Tooltip } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import React, { MutableRefObject, useEffect, useRef } from 'react';

interface CustomHeaderCheckBoxCellProperties {
    logisticsUnitId: string,
    label: string,
    checkedAll: boolean,
    indeterminateAll: boolean,
    tooltipMessage: string,
    sort?: SortDescriptor[],
    handleSortColumnChange?: (sortItems: SortDescriptor[]) => void,
    updateAllElementsChoicesLogisticsUnit: (checked: boolean, logisticsUnitId: string) => void
}

export const CustomHeaderCheckBoxCellComponent = (props: CustomHeaderCheckBoxCellProperties): JSX.Element => {

    const checkboxRef: MutableRefObject<HTMLInputElement> = useRef(null);

    let sortIconClass = "";
    let sortIndex = -1;
    if (props.sort?.length > 0) {
        const currentSortIndex: number = props.sort.findIndex(x => x.field == props.logisticsUnitId);
        if(currentSortIndex !== -1){
            sortIconClass = props.sort[currentSortIndex].dir === "asc" ?  "k-icon k-i-sort-asc-sm" : "k-icon k-i-sort-desc-sm";
            sortIndex = currentSortIndex;
        }
    }

    useEffect(() => {
        checkboxRef.current.indeterminate = props.indeterminateAll;
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        props.updateAllElementsChoicesLogisticsUnit(e.target.checked, props.logisticsUnitId);
    };

    const handleSortChange = () =>{
        let sortItems: SortDescriptor[] = null;
        if (props.sort.length > 0 && props.sort[0].dir === 'asc' && props.sort[0].field === props.logisticsUnitId) {
            sortItems = [{ field: props.logisticsUnitId, dir: 'desc' }];
        } else if (props.sort.length > 0 && props.sort[0].dir === 'desc' && props.sort[0].field === props.logisticsUnitId) {
            sortItems = [];
        } else {
            sortItems = [{ field: props.logisticsUnitId, dir: 'asc' }];
        }
        props.handleSortColumnChange(sortItems);
    }

    const checked: boolean = props.checkedAll;
    return (
        <>
            <Tooltip title={props.tooltipMessage} placement="top" onClick={handleSortChange} className="referential-tooltip">
                <div>
                    <span>
                        {props.label}
                    </span>
                    {sortIconClass !== "" &&
                    <span className={sortIconClass}></span>
                    }
                    {props.sort.length > 1 && sortIndex !== -1 &&
                        <span className="k-sort-order">{(sortIndex + 1)}</span>
                    }
                </div>
            </Tooltip>
            <input type="checkbox" ref={checkboxRef} checked={checked} onChange={(e) => handleChange(e)} className="custom-checkbox" />
        </>
    );
}