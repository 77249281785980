import { GridCellProps } from '@progress/kendo-react-grid';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import React from 'react';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';

interface RemarksCellProperties extends GridCellProps{
    width: number
}

interface RemarksCellState {
    dataValue: string|string[]|number
}

export default class RemarksCell extends React.Component<RemarksCellProperties, RemarksCellState> {
    constructor(props: RemarksCellProperties) {
        super(props);
        this.state = {
            dataValue: this.props.dataItem[this.props.field],
        }
    }

    handleChange = (dataItem: PlanningVehicleLightModelExtended, field: string, event: InputChangeEvent): void => {
        this.setState({
            dataValue: event.target.value
        });

        dataItem.inEdit = field;
        dataItem[field] = event.target.value;
    }

    render() {
        let dataValue: string = this.props.dataItem[this.props.field];
        dataValue = dataValue ? dataValue : '';
        let defaultRendering: JSX.Element = <></>;
        if (!this.props.dataItem.inEdit || this.props.dataItem.inEdit !== this.props.field) {
            defaultRendering = (
                <td className="remarks-cell">{dataValue}</td>
            );
        } else {
            defaultRendering = (
                <td>
                    <Input
                        value={dataValue}
                        onChange={(event) => this.handleChange(this.props.dataItem, this.props.field, event)}
                        width={this.props.width} />
                </td>
            );
        }
        return this.props.render ?
            this.props.render.call(undefined, defaultRendering, this.props) :
            defaultRendering;
    }
}