import { Checkbox, Tooltip } from '@mui/material';
import React from 'react';
import { TransportSaleLightModelExtended } from '../models/TransportSaleLightModelExtended';
import { TransportSaleEntityLightModel } from '../services/dataContracts/queryStack/TransportSaleEntityLightModel';

interface CustomCheckBoxCellProperties {
    dataItem?: TransportSaleLightModelExtended,
    field: string,
    selectedAll?: boolean,
    tooltipMessage: string,
    updateElements: (targetChecked: boolean, elemId?: string, dataItem?: TransportSaleEntityLightModel, planningId?: number) => void
}

export const CustomCheckBoxCell = (props: CustomCheckBoxCellProperties) => {

    const handleChange = (dataItem: TransportSaleLightModelExtended, field: string, event: React.ChangeEvent<HTMLInputElement>): void => {
        if (field == "selectedAll") {
            props.updateElements(event.target.checked);
        }
        else {
            const elemId = dataItem.entity == TransportSaleEntityLightModel.transportRequest 
                ? dataItem.groupKey
                : dataItem.preInvoiceId 
                    ? dataItem.preInvoiceId
                    : dataItem.transportFlowId;
                    
            props.updateElements(event.target.checked, elemId, dataItem.entity, dataItem.planningId);
        }
    };

    const defaultRendering: JSX.Element = (
        <>
            {props.field == "selectedAll" ?
                (<Tooltip title={props.tooltipMessage} placement="bottom">
                    <Checkbox
                        classes={{
                            root: "checkbox"
                        }}
                        checked={props.selectedAll}
                        onChange={(e) => handleChange(props.dataItem, props.field, e)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Tooltip>
                ) :
                props.dataItem.entity == TransportSaleEntityLightModel.transportFlow || props.dataItem.entity == TransportSaleEntityLightModel.transportRequest ?
                    (<td>
                        <Tooltip title={props.tooltipMessage} placement="bottom">
                            <Checkbox
                                classes={{
                                    root: "checkbox"
                                }}
                                checked={props.dataItem[props.field]}
                                onChange={(e) => handleChange(props.dataItem, props.field, e)}
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Tooltip>
                    </td>) : <td></td>
            }
        </>
    );

    return defaultRendering;
}