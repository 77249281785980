import Axios, { AxiosResponse } from 'axios';
import { PlanningVehiclesExportRequestArgs } from './dataContracts/controller/PlanningVehiclesExportRequestArgs';
import { ProductionForemanLightModel } from './queryStack/ProductionForemanLightModel';
import { ProductionForemanPlanningVehicleLightModel } from './queryStack/ProductionForemanPlanningVehicleLightModel';

const controllerUrl = "api/ThirdPartyProduction/";

export class ThirdPartyProductionApiClient {
    public static SearchProductionForemen = (logisticsUnits: Array<string> = null)
        : Promise<AxiosResponse<Array<ProductionForemanLightModel>>> => {

        return Axios.post(`${controllerUrl}SearchProductionForemen`,
            logisticsUnits,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
    }

    public static SearchPlanningVehicles(fromTime: Date, untilTime: Date, searchText: string, selectedProductionForemanUserName: string, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<Array<ProductionForemanPlanningVehicleLightModel>>> {
        return Axios({
            method: 'POST',
            url: `${controllerUrl}SearchPlanningVehicles?fromTime=${encodeURIComponent(fromTime.toJSON())}&untilTime=${encodeURIComponent(untilTime.toJSON())}&searchText=${encodeURIComponent(searchText)}&selectedProductionForemanUserName=${encodeURIComponent(selectedProductionForemanUserName)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(logisticsUnits)
        });
    }

    public static ExportPlanningVehicles(requestArgs: PlanningVehiclesExportRequestArgs)
        : Promise<AxiosResponse<Blob>> {
        const _headers = {
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return Axios.post(controllerUrl + 'ExportPlanningVehicles', requestArgs, { headers: _headers, responseType: 'blob' });
    }
}
