import { Box, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { ScaleLoaderComponent } from '../../../../../shared/components/Common/ScaleLoaderComponent';
import { CustomerCandidateToAddExtended } from '../../models/CustomerCandidateToAddExtended';
import { CustomerContactRequestArgsExtended } from '../../models/CustomerContactRequestArgsExtended';
import { CustomerCandidateToAdd } from '../../services/dataContracts/controller/CustomerCandidateToAdd';
import { ConfirmAddCustomerStepComponent } from './ConfirmAddCustomerStepComponent';
import { SearchCustomerStepComponent } from './SearchCustomerStepComponent';
import { SelectCustomerStepComponent } from './SelectCustomerStepComponent';

interface AddCustomerDialogComponentProperties {
    activeStep: number,
    customerSearchLoading: boolean,
    customerIdMdm: string,
    customerName: string,
    customerChoices: CustomerCandidateToAdd[],
    handleCustomerIdMdmChange: (value: string) => void,
    handleCustomerNameChange: (value: string) => void,
    searchCustomerByMdmId: (customerIdMdm: string) => void,
    searchCustomerByName: (customerName: string) => void,
    handleSearchInReferentialGrid: (customerMdmId: string) => void,
    handleConfirmStep: (transporter: CustomerCandidateToAddExtended) => void,
    handlePreviousStep: () => void,
    confirmAddCustomer: (customerContacts: CustomerContactRequestArgsExtended[]) => void
}

export const AddCustomerDialogComponent = (props: AddCustomerDialogComponentProperties): JSX.Element => {

    const steps: string[] = ["Chercher", "Ajouter", "Confirmer"];

    const getStepContent = (stepIndex: number): JSX.Element => {
        switch (stepIndex) {
            case 0:
                return <SearchCustomerStepComponent
                    customerSearchLoading={props.customerSearchLoading}
                    customerIdMdm={props.customerIdMdm}
                    customerName={props.customerName}
                    handleCustomerIdMdmChange={props.handleCustomerIdMdmChange}
                    handleCustomerNameChange={props.handleCustomerNameChange}                    
                    searchCustomerByMdmId={props.searchCustomerByMdmId}
                    searchCustomerByName={props.searchCustomerByName}
                />;
            case 1:
                return <SelectCustomerStepComponent
                    customerChoices={props.customerChoices}
                    handleSearchInReferentialGrid={props.handleSearchInReferentialGrid}
                    handleConfirmStep={props.handleConfirmStep}
                    handlePreviousStep={props.handlePreviousStep}
                />;
            case 2:
                return <ConfirmAddCustomerStepComponent
                    confirmAddCustomer={props.confirmAddCustomer}
                    handlePreviousStep={props.handlePreviousStep}
                />;
            default:
                return <></>;
        }
    }

    return (
        <Box className="add-customer-component">
            <Box display="flex" flexDirection="row" justifyContent="center" className="loading-content">
                <ScaleLoaderComponent loading={props.customerSearchLoading} />
            </Box>
            <Stepper activeStep={props.activeStep} alternativeLabel>
                {steps.map((label: string) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box>{getStepContent(props.activeStep)}</Box>
        </Box>
    );
}