import styles from '../../_variables.scss';

export class SizingUtilities {

    //50 is the item size (row height) defined in prop of the kendo grid
    public static itemSize = 60;

    //width de la liste des véhicules fixé dans le css (_variables.scss)
    public static listWidth: string = styles.vehiclesSelectorListWidth;

}
