import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { SelectOptionModel } from '../models/SelectOptionModel';
import { PurchasePriceLineRequestArgs } from '../services/dataContracts/controller/PurchasePriceLineRequestArgs';
import { VehicleTypeLightModel } from '../services/dataContracts/queryStack/VehicleTypeLightModel';
import { TransportPurchasePricesReferentialApiClient } from '../services/TransportPurchasePricesReferentialApiClient';
import { MultiSelectVehicleTypesComponent } from './MultiSelectVehicleTypesComponent';

interface AddNewPurchasePricesTemplateProperties {
    transportersOptions: Array<SelectOptionModel>,
    vehicleTypesOptions: Array<SelectOptionModel>,
    purchasePriceLines: Array<PurchasePriceLineRequestArgs>,
    onClose: () => void,
    handleRefreshVehicleTypesList: (vehicleTypes: Array<SelectOptionModel>) => void,
    handleRefreshPurchasePriceLines: (purchasePriceLines: Array<PurchasePriceLineRequestArgs>) => void
}

const optionsPriceKind: Array<SelectOptionModel> = new Array<SelectOptionModel>(
    { value: "Day", label: "Jour" },
    { value: "HalfDay", label: "Demi-journée" },
    { value: "Night", label: "Nuit" },
    { value: "PerTon", label: "Tonne" },
    { value: "PerHour", label: "Heure" },
    { value: "PerTurn", label: "Tour" },
);

export const AddNewPurchasePricesTemplateComponent = (props: AddNewPurchasePricesTemplateProperties): JSX.Element => {
    const [vehicleTypes, setVehicleTypes] = useState<Array<SelectOptionModel>>([]);
    const [purchasePriceLines, setPurchasePriceLines] = useState<Array<PurchasePriceLineRequestArgs>>([
        {
            transporterId: null,
            transporterName: null,
            vehicleTypes: [],
            pricesLabelKind: [],
            numberOfLinesToCreate: 1
        }
    ]);

    const getVehicleTypesList = (): void => {
        TransportPurchasePricesReferentialApiClient.GetVehicleTypesList()
            .then(response => {
                const vehicleTypes: VehicleTypeLightModel[] = response.data;

                const vehicleTypeOptions: SelectOptionModel[] = vehicleTypes.map(v => {
                    return { value: v.vehicleTypeId, label: v.label }
                });

                setVehicleTypes(vehicleTypeOptions);
                props.handleRefreshVehicleTypesList(vehicleTypeOptions);
            });
    }

    useEffect(() => {
        if (props.vehicleTypesOptions.length == 0) {
            getVehicleTypesList();
        }
        else {
            setVehicleTypes(props.vehicleTypesOptions);
        }
    }, []);

    const handleChangeTransporterSelected = (opt: SelectOptionModel, index: number): void => {
        const newPurchasePriceLines: PurchasePriceLineRequestArgs[] = cloneDeep(purchasePriceLines);

        newPurchasePriceLines[index].transporterId = opt.value;
        newPurchasePriceLines[index].transporterName = opt.label;

        setPurchasePriceLines(newPurchasePriceLines);

        props.handleRefreshPurchasePriceLines(newPurchasePriceLines);
    }

    const changeVehicleTypesSelected = (opts: Array<SelectOptionModel>, index: number): void => {

        const newPurchasePriceLines: PurchasePriceLineRequestArgs[] = cloneDeep(purchasePriceLines);

        newPurchasePriceLines[index].vehicleTypes = opts.map(o => {
            return { vehicleTypeId: o.value, vehicleTypeLabel: o.label }
        });

        setPurchasePriceLines(newPurchasePriceLines);

        props.handleRefreshPurchasePriceLines(newPurchasePriceLines);
    }

    const handleChangePurchasePricesSelected = (opts: Array<SelectOptionModel>, index: number): void => {
        const newPurchasePriceLines: PurchasePriceLineRequestArgs[] = cloneDeep(purchasePriceLines);

        newPurchasePriceLines[index].pricesLabelKind = opts.map(o => o.value);

        setPurchasePriceLines(newPurchasePriceLines);

        props.handleRefreshPurchasePriceLines(newPurchasePriceLines);
    }

    const handleNumberOfLinesToCreateChanged = (value: number, index: number): void => {
        const newPurchasePriceLines: PurchasePriceLineRequestArgs[] = cloneDeep(purchasePriceLines);

        newPurchasePriceLines[index].numberOfLinesToCreate = value;

        setPurchasePriceLines(newPurchasePriceLines);

        props.handleRefreshPurchasePriceLines(newPurchasePriceLines);
    }

    const removePurchasePrice = (index: number): void => {
        const newPurchasePriceLines: PurchasePriceLineRequestArgs[] = cloneDeep(purchasePriceLines);

        newPurchasePriceLines.splice(index, 1);

        setPurchasePriceLines(newPurchasePriceLines);

        props.handleRefreshPurchasePriceLines(newPurchasePriceLines);
    }

    const addPurchasePrice = (): void => {
        const newPurchasePriceLines: PurchasePriceLineRequestArgs[] = cloneDeep(purchasePriceLines);

        newPurchasePriceLines.push(
            {
                transporterId: null,
                transporterName: null,
                vehicleTypes: [],
                pricesLabelKind: [],
                numberOfLinesToCreate: 1
            }
        );

        setPurchasePriceLines(newPurchasePriceLines);

        props.handleRefreshPurchasePriceLines(newPurchasePriceLines);
    }

    return (
        <Box display='flex' flexDirection='column' p={1} className="add-new-purchase-prices-template-dialog">
            <Box pb={2}>
                {purchasePriceLines.map((line, index) => {
                    const valueTransporter: SelectOptionModel = line.transporterId ? props.transportersOptions.find(e => e.value == line.transporterId) : null;
                    const vehicleTypesValues: SelectOptionModel[] = line.vehicleTypes.length > 0 ? vehicleTypes.filter(p => line.vehicleTypes.map(v => v.vehicleTypeId).includes(p.value)) : null;
                    const pricesKindValues: SelectOptionModel[] = line.pricesLabelKind.length > 0 ? optionsPriceKind.filter(p => line.pricesLabelKind.includes(p.value)) : null;

                    return (
                        <Box key={`purchase-price-line-${index}`} display="flex" flexDirection="row" justifyContent="space-between" width="100%" maxWidth="850px">
                            <Box width="240px" pr="5px">
                                <Select
                                    menuPosition="fixed"
                                    menuShouldBlockScroll={true}
                                    options={props.transportersOptions}
                                    placeholder="Choisir un transporteur"
                                    value={valueTransporter}
                                    onChange={(e) => handleChangeTransporterSelected(e, index)}
                                />
                            </Box>
                            <Box width="300px" pr="5px">
                                <MultiSelectVehicleTypesComponent
                                    vehicleTypes={vehicleTypes}
                                    vehicleTypesValues={vehicleTypesValues}
                                    index={index}
                                    changeVehicleTypesSelected={changeVehicleTypesSelected} />
                            </Box>
                            <Box width="240px" pr="5px">
                                <Select
                                    menuPosition="fixed"
                                    menuShouldBlockScroll={true}
                                    options={optionsPriceKind}
                                    value={pricesKindValues}
                                    placeholder="Choisir une tarification"
                                    onChange={(e: SelectOptionModel[]) => handleChangePurchasePricesSelected(e, index)}
                                    isMulti
                                />
                            </Box>
                            <Box width="50px" pr="5px">
                                <NumericTextBox
                                    className={line.numberOfLinesToCreate < 1 || line.numberOfLinesToCreate > 9 || line.numberOfLinesToCreate === null ? "invalid-number number-of-lines" : "number-of-lines"}
                                    width="100%"
                                    value={line.numberOfLinesToCreate}
                                    min={1}
                                    max={9}
                                    format="n0"
                                    onChange={(e) => handleNumberOfLinesToCreateChanged(e.target.value, index)}
                                />
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                                <Box style={{ cursor: 'pointer' }} onClick={addPurchasePrice}>
                                    <FontAwesomeIcon size="lg" icon={faPlusCircle} />
                                </Box>
                                {(purchasePriceLines.length > 1) &&
                                    <Box style={{ cursor: 'pointer' }} onClick={(e) => removePurchasePrice(index)}>
                                        <FontAwesomeIcon size="lg" icon={faTimes} />
                                    </Box>
                                }
                            </Box>
                        </Box>
                    );
                })
                }
            </Box>
        </Box>
    );
}