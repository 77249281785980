import { faExclamationTriangle, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import { Grid, GridCellProps, GridColumn, GridRowProps } from '@progress/kendo-react-grid';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import { EditableHeaderComponent } from 'src/shared/components/Common/EditableHeaderComponent';
import BusinessErrors from '../../../utils/BusinessErrors';
import { PurchaseCostUsedQuantitiesKindEnum } from '../models/PurchaseCostUsedQuantitiesKindEnum';
import { OverridenDeliveredNumberOfTurnsRequestArgs } from '../services/dataContracts/controller/OverridenDeliveredNumberOfTurnsRequestArgs';
import { PlanningVehicleDeliveryOverrideKind } from '../services/dataContracts/controller/PlanningVehicleDeliveryOverrideKind';
import { TransportPurchasesApiClient } from '../services/TransportPurchasesApiClient';

interface NumericCellProps extends GridCellProps {
    computedNumberOfTurns?: number,
    overridenDeliveredNumberOfTurns?: number,
    kindOfValues: string,
    purchaseIsCanceled: boolean,
    handleChange: (dataItem: OverridenDeliveredNumberOfTurnsItem, field: string, value: number) => void,
    width?: number
}

export const NumericCell = (props: NumericCellProps): JSX.Element => {
    const overridenDeliveredNumberOfTurns = props.dataItem[props.field];
    const dataValue: number = overridenDeliveredNumberOfTurns !== null
        ? overridenDeliveredNumberOfTurns
        : props.computedNumberOfTurns;

    let defaultRendering: JSX.Element = <></>;
    if (!props.dataItem.inEdit || props.dataItem.inEdit !== props.field || props.purchaseIsCanceled) {
        defaultRendering = (
            <td>
                <p className={`item-value${overridenDeliveredNumberOfTurns !== null
                    ? " touched"
                    : props.kindOfValues === PurchaseCostUsedQuantitiesKindEnum.Delivered
                        ? ""
                        : " text-mauve"} ${props.purchaseIsCanceled ? "purchase-is-canceled" : ""}`}>{dataValue}</p>
            </td>
        );
    } else {
        defaultRendering = (
            <td className="cell-override">
                <NumericTextBox className="input-cell-override"
                    defaultValue={dataValue}
                    min={0}
                    format={{
                        maximumFractionDigits: 0
                    }}
                    spinners={false}
                    onBlur={(event) => props.handleChange(props.dataItem, props.field, event.target.value)}
                    width={props.width} />
            </td>
        );
    }

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}

interface OverridenDeliveredNumberOfTurnsCellComponentProps {
    computedNumberOfTurns: number,
    kindOfValues: string,
    overridenDeliveredNumberOfTurns: number,
    planningId: number,
    planningVehicleId: string,
    transportFlowId: string,
    transportRequestId: string,
    purchaseIsCanceled: boolean,
    flowWithInconsistentOverrides: boolean,
    displayErrorsMessages: (errors: string[]) => void,
    refreshPlanningVehicles: () => void,
    refreshPlanningVehicleDeliveredNumberOfTurns: (planningVehicleId: string, transportRequestId: string, transportFlowId: string, overridenDeliveredNumberOfTurns: number) => void,
    handleUpdatingPlanningVehicle: (planningVehicleId: string) => void
}

interface OverridenDeliveredNumberOfTurnsItem {
    overridenDeliveredNumberOfTurns: number,
    oldValue?: number,
    inEdit?: string
}

interface OverridenDeliveredNumberOfTurnsCellState {
    editField: string,
    data: Array<OverridenDeliveredNumberOfTurnsItem>
}

export const OverridenDeliveredNumberOfTurnsCellComponent = (props: OverridenDeliveredNumberOfTurnsCellComponentProps): JSX.Element => {

    let preventExit: boolean;
    let preventExitTimeout: ReturnType<typeof setTimeout>;
    let blurTimeout: ReturnType<typeof setTimeout>;

    const [state, setState] = useState<OverridenDeliveredNumberOfTurnsCellState>({
        editField: null,
        data: [{
            overridenDeliveredNumberOfTurns: props.overridenDeliveredNumberOfTurns
        }]
    });

    useEffect(() => {
        setState({
            ...state,
            data: [{
                "overridenDeliveredNumberOfTurns": props.overridenDeliveredNumberOfTurns
            }]
        });
    }, [props.planningVehicleId, props.overridenDeliveredNumberOfTurns]);

    const cellRender = (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps): React.ReactElement<HTMLTableCellElement> => {
        const dataItem: OverridenDeliveredNumberOfTurnsItem = cellProps.dataItem;
        const field = cellProps.field;
        const additionalProps = (dataItem.inEdit && (cellProps.field === dataItem.inEdit)) ?
            {
                ref: (td) => {
                    const input = td && td.querySelector('input');
                    if (!input || (input === document.activeElement)) { return; }
                    if (input.type === 'checkbox') {
                        input.focus();
                    } else {
                        input.select();
                    }
                }
            } : {
                onClick: () => { enterEdit(dataItem, field); }
            };
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children as any);
    }

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, dataItem: GridRowProps): React.ReactElement<HTMLTableRowElement> => {
        const trProps = {
            ...trElement.props,
            onMouseDown: () => {
                preventExit = true;
                clearTimeout(preventExitTimeout);
                preventExitTimeout = setTimeout(() => { preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(blurTimeout);
                if (!preventExit) {
                    blurTimeout = setTimeout(() => { exitEdit(); });
                }
            },
            onFocus: () => { clearTimeout(blurTimeout); }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as any);
    }

    const enterEdit = (dataItem: OverridenDeliveredNumberOfTurnsItem, field: string): void => {
        if (dataItem.inEdit && field === state.editField) {
            return;
        }
        exitEdit();
        dataItem.oldValue = dataItem[field];
        dataItem.inEdit = field;

        setState({
            ...state,
            editField: field
        });
    }

    const exitEdit = (): void => {
        let wasChanged = false;
        let propertyName: string = null;
        let propertyValue: number = null;

        state.data.forEach((dataItem: any) => {
            if (dataItem.inEdit != undefined) {
                //check if value isn't changed
                if (dataItem[dataItem.inEdit] === dataItem.oldValue)
                    wasChanged = false;
                else {
                    wasChanged = true;
                    propertyName = dataItem.inEdit;
                    propertyValue = dataItem[propertyName];
                }
            }
            dataItem.inEdit = undefined;
        });

        setState({
            ...state,
            editField: null
        });

        if (!wasChanged)
            return;

        const requestArgs: OverridenDeliveredNumberOfTurnsRequestArgs = {
            planningId: props.planningId,
            planningVehicleId: props.planningVehicleId,
            transportFlowId: props.transportFlowId,
            overridenDeliveredNumberOfTurns: propertyValue == null ? 0 : propertyValue,
            specificOrNegotiatedPriceKind: null,
            deliveryOverrideKind: PlanningVehicleDeliveryOverrideKind.transportFlow
        }

        OverridePlanningVehicleDeliveredQuantity(requestArgs, propertyValue);
    }

    const itemChangeOverridenDeliverdNbrOfTurns = (dataItem: OverridenDeliveredNumberOfTurnsItem, field: string, value: number): void => {
        dataItem.inEdit = field;
        dataItem[field] = value == null ? 0 : value;
    }

    const undoOverridenDeliveredNumberOfTurns = (): void => {
        const requestArgs: OverridenDeliveredNumberOfTurnsRequestArgs = {
            planningId: props.planningId,
            planningVehicleId: props.planningVehicleId,
            transportFlowId: props.transportFlowId,
            overridenDeliveredNumberOfTurns: null,
            specificOrNegotiatedPriceKind: null,
            deliveryOverrideKind: PlanningVehicleDeliveryOverrideKind.transportFlow
        }

        OverridePlanningVehicleDeliveredQuantity(requestArgs, null);
    }

    const OverridePlanningVehicleDeliveredQuantity = (requestArgs: OverridenDeliveredNumberOfTurnsRequestArgs, value: number) => {
        props.handleUpdatingPlanningVehicle(props.planningVehicleId);
        TransportPurchasesApiClient.OverridePlanningVehicleDeliveredNumberOfTurns(requestArgs)
            .then(res => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    props.displayErrorsMessages(errors);
                    props.refreshPlanningVehicles();
                    return;
                }

                setState({
                    ...state,
                    data: [{
                        "overridenDeliveredNumberOfTurns": value
                    }]
                });

                props.refreshPlanningVehicleDeliveredNumberOfTurns(props.planningVehicleId, props.transportRequestId, props.transportFlowId, value)
            });
    }

    return (
        <Box display="flex" flexDirection="row">
            <Box width="22px">
                {props.flowWithInconsistentOverrides && (<Tooltip title="Incohérence sur la surcharge du Flux par rapport aux valeurs comptabilisées du/des tarif(s) spécifique(s)/négocié(s)." placement="bottom">
                    <Box style={{ cursor: 'pointer' }} className="inconsistent-flow">
                        <FontAwesomeIcon size="1x" icon={faExclamationTriangle} />&nbsp;
                    </Box>
                </Tooltip>)}
            </Box>
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Grid
                        className="override-grid"
                        data={state.data}
                        cellRender={cellRender}
                        rowRender={rowRender}
                        editField="inEdit"
                        rowHeight={15}
                        scrollable="none"
                    >
                        <GridColumn field="overridenDeliveredNumberOfTurns" title="Comptabilisé(s)"
                            headerCell={() => !props.purchaseIsCanceled
                                ? <EditableHeaderComponent field="overridenDeliveredNumberOfTurns" title="Comptabilisé(s)" />
                                : <div className="title">Comptabilisé(s)</div>}
                            editable={!props.purchaseIsCanceled}
                            cell={(pro) =>
                                <NumericCell {...pro}
                                    field={pro.field}
                                    dataItem={pro.dataItem}
                                    kindOfValues={props.kindOfValues}
                                    computedNumberOfTurns={props.computedNumberOfTurns}
                                    purchaseIsCanceled={props.purchaseIsCanceled}
                                    handleChange={itemChangeOverridenDeliverdNbrOfTurns}
                                />
                            }
                        />
                    </Grid >
                </IntlProvider>
            </LocalizationProvider>

            {!props.purchaseIsCanceled && <Box onClick={() => state.data[0]?.overridenDeliveredNumberOfTurns !== null ? undoOverridenDeliveredNumberOfTurns() : null}>
                <FontAwesomeIcon size="1x" icon={faUndo} className={state.data[0]?.overridenDeliveredNumberOfTurns !== null ? "undo-icon" : "undo-icon disabled"} />
            </Box>}
        </Box>
    )
}
