//Navigator interface no longer extends MSFileSaver #45612
//see https://github.com/microsoft/TypeScript/issues/45612
declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void
  }
}

export function downloadFileFromBlob(blob: any, fileName: string) {
    if (navigator.appVersion.toString().indexOf('.NET') > 0) //For IE
    {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    }
    else {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }
}

export function getFileName(response){
    let filename = null;
    if (response && response.headers && response.headers) {
        const disposition = response.headers['content-disposition'];
        if(disposition && disposition.indexOf('attachment') !== -1){
            //On tente de recupèrer la valeur du filename* si elle existe
            let filenameRegex = /filename\*=(UTF-8['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/;
            let matches = filenameRegex.exec(disposition);
            if (matches != null && matches[2]) { 
                filename = decodeURIComponent(matches[2]);
            }
            else{
                //Sinon on tente de récuperer la valeur du filename
                filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                matches = filenameRegex.exec(disposition);
                if(matches != null && matches[1]){
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
        }
    }
    return filename;
}

export function b64toBlob(b64Data: any, contentType: string, sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}
