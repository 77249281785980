import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Checkbox, FormControlLabel, Input, InputAdornment, Tooltip } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { UploadOnBeforeUploadEvent, UploadOnStatusChangeEvent, UploadResponse } from '@progress/kendo-react-upload';
import React from 'react';
import { UploadComponent } from 'src/shared/components/Common/UploadComponent';
import { downloadFileFromBlob, getFileName } from '../../../../utils/DownloadFile';
import { InternalContactChoicesExportRequestArgs } from '../services/dataContracts/controller/InternalContactChoicesExportRequestArgs';
import { InternalContactsReferentialApiClient } from '../services/InternalContactsReferentialApiClient';

interface HeaderContentComponentProps {
    inputSearchInternalBeneficariesRequestersValue: string,
    inputSearchInternalBeneficariesRequestersRef: React.RefObject<HTMLInputElement>,
    isEditUserEnabled: boolean,
    isUserSelected: boolean,
    sort: SortDescriptor[],
    isGlobalSearch: boolean,
    handleInternalBeneficariesRequestersKeyPress: (text: string) => void,
    handleClearSearchText: () => void,
    handlerAfterUpload: (response: UploadResponse) => void,
    handlerBeforeUpload: (event: UploadOnBeforeUploadEvent) => void,
    displayErrorResponse: () => void,
    handleIsGlobalSearchChanged: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleAddContactDialogClick: () => void,
    handleEditContactDialogClick: () => void
}

export const HeaderContentComponent = (props: HeaderContentComponentProps): JSX.Element => {
    const exportToExcel = (): void => {
        const requestArgs: InternalContactChoicesExportRequestArgs = {
            searchText: props.inputSearchInternalBeneficariesRequestersValue,
            sortField: props.sort.length > 0 ? props.sort[0].field : null,
            sortDirection: props.sort.length > 0 ? props.sort[0].dir : null,
            isGlobalSearch: props.isGlobalSearch
        };

        InternalContactsReferentialApiClient.ExportInternalContactChoices(requestArgs)
            .then(response => {
                const fileName: string = getFileName(response);
                const blob: Blob = new Blob([response.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const onUploadStatusChange = (event: UploadOnStatusChangeEvent): void => {
        const res = event.response;
        if (res?.status == 200) {
            props.handlerAfterUpload(event.response);
        }
        else if (props.displayErrorResponse) {
            props.displayErrorResponse();
        }
    }

    const inputSearchInternalBeneficariesRequestersClassName: string = props.inputSearchInternalBeneficariesRequestersValue.length > 2 ? 'search-text-active' : '';
    const uploadSaveUrl: string = InternalContactsReferentialApiClient.GetImportUrl();
    const checkboxInputProps: React.InputHTMLAttributes<HTMLInputElement> = { 'aria-label': 'primary checkbox' };

    return (
        <Box display="flex" flexDirection="row" flex="wrap" justifyContent="start" alignItems="center" className="header-content">
            <Box>
                <Input disableUnderline className={inputSearchInternalBeneficariesRequestersClassName} inputRef={props.inputSearchInternalBeneficariesRequestersRef}
                    startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                    endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => props.handleClearSearchText()} /></InputAdornment>}
                    placeholder="Rechercher ..." onChange={(event) => props.handleInternalBeneficariesRequestersKeyPress(event.target.value)} />
            </Box>
            <Box>
                <FormControlLabel
                    id="global-search-contacts-referential"
                    control={
                        <Checkbox
                            checked={props.isGlobalSearch}
                            disabled={props.inputSearchInternalBeneficariesRequestersValue.length < 3}
                            onChange={(e) => props.handleIsGlobalSearchChanged(e)}
                            color="primary"
                            inputProps={checkboxInputProps}
                        />
                    }
                    label="Recherche globale"
                    labelPlacement="start"
                />
            </Box>
            <Box ml={'10px'}>
                <Tooltip title="Ajouter un utilisateur" placement="bottom">
                    <Button variant="contained" color="primary" onClick={props.handleAddContactDialogClick}>
                        Ajouter
                </Button>
                </Tooltip>
            </Box>
            <Box ml={'10px'}>
                <Tooltip title={props.isUserSelected && !props.isEditUserEnabled ? "Les profils dispatcheur (DIS) et logisticien (LOG) ne sont pas modifiables." : "Modifier un utilisateur."} placement="bottom">
                    <span className={props.isEditUserEnabled ? '' : 'span-disabled'}>
                        <Button variant="contained" color="primary" disabled={!props.isEditUserEnabled} onClick={props.handleEditContactDialogClick}>
                            Modifier
                        </Button>
                    </span>
                </Tooltip>
            </Box>
            <Box ml={'10px'}>
                <Tooltip title="Exporter sous format Excel" placement="bottom">
                    <Button variant="contained" color="primary" onClick={exportToExcel}>
                        Exporter
                </Button>
                </Tooltip>
            </Box>
            <Box ml={'10px'}>
                <UploadComponent
                    multiple={false}
                    showFileList={false}
                    restrictions={{
                        allowedExtensions: ['.xls', '.xlsx']
                    }}
                    defaultFiles={[]}
                    withCredentials={false}
                    saveUrl={uploadSaveUrl}
                    onBeforeUpload={props.handlerBeforeUpload}
                    onStatusChange={onUploadStatusChange}
                />
            </Box>
        </Box>
    );
}
