


// $Classes/Enums/Interfaces(filter)[template][separator]
// filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
// template: The template to repeat for each matched item
// separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

// More info: http://frhagn.github.io/Typewriter/



    export enum FlowDiffProperty {
  
    deliveryStartDate = "DeliveryStartDate",
    deliveryEndDate = "DeliveryEndDate",
    coatingApplicationMode = "CoatingApplicationMode",
    product = "Product",
    product_Code = "Product_Code",
    product_Quantity = "Product_Quantity",
    product_UnitOfMeasure = "Product_UnitOfMeasure",
    product_Level3SegmentationId = "Product_Level3SegmentationId",
    senderSiteResolution = "SenderSiteResolution",
    senderSiteResolution_Kind = "SenderSiteResolution_Kind",
    senderSiteResolution_Identifiers = "SenderSiteResolution_Identifiers",
    senderSiteResolution_Label = "SenderSiteResolution_Label",
    pickupAddress = "PickupAddress",
    pickupAddress_Line1 = "PickupAddress_Line1",
    pickupAddress_ZipCode = "PickupAddress_ZipCode",
    pickupAddress_City = "PickupAddress_City",
    receiverSiteResolution = "ReceiverSiteResolution",
    receiverSiteResolution_Kind = "ReceiverSiteResolution_Kind",
    receiverSiteResolution_Identifiers = "ReceiverSiteResolution_Identifiers",
    receiverSiteResolution_Label = "ReceiverSiteResolution_Label",
    deliveryAddress = "DeliveryAddress",
    deliveryAddress_Line1 = "DeliveryAddress_Line1",
    deliveryAddress_ZipCode = "DeliveryAddress_ZipCode",
    deliveryAddress_City = "DeliveryAddress_City",
    requestedBy = "RequestedBy",
    requestedBy_Name = "RequestedBy_Name",
    requestedBy_PhoneNumber = "RequestedBy_PhoneNumber",
    beneficiary = "Beneficiary",
    beneficiary_Name = "Beneficiary_Name",
    beneficiary_PhoneNumber = "Beneficiary_PhoneNumber",
    vehicleTypeId = "VehicleTypeId",
    numberOfVehicles = "NumberOfVehicles",
    arrivalTimeAtSenderSite = "ArrivalTimeAtSenderSite",
    arrivalTimeAtReceiverSite = "ArrivalTimeAtReceiverSite",
    arrivalSlotEndTimeAtReceiverSite = "ArrivalSlotEndTimeAtReceiverSite",
    unloadingDuration = "UnloadingDuration",
    estimatedTripDuration = "EstimatedTripDuration",
    isUnloadingContinuityRequired = "IsUnloadingContinuityRequired",
    estimatedTransportPrice = "EstimatedTransportPrice",
}
