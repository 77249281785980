import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Drawer } from '@mui/material';
import React from 'react';
import '../../../utils/Number';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';
import { VehiclePriceKind } from '../models/VehiclePriceKind';
import { PlanningVehicleDetailsLightModel } from '../services/dataContracts/queryStack/PlanningVehicleDetailsLightModel';
import { TransportFlowsDetailsLightModel } from '../services/dataContracts/queryStack/TransportFlowsDetailsLightModel';
import { FeesComponent } from './FeesComponent';
import { OverridenDeliveredNumberOfTurnsCellComponent } from './OverridenDeliveredNumberOfTurnsCellComponent';
import { OverridenDeliveredQuantityCellComponent } from './OverridenDeliveredQuantityCellComponent';

interface TransportRequestsDetailsComponentProps {
    isDcDetailsDrawerOpened: boolean,
    planningVehicle: PlanningVehicleLightModelExtended,
    planningVehicleDetails: Array<PlanningVehicleDetailsLightModel>,
    displayErrorsMessages: (errors: string[]) => void,
    handleDeliveredQuantityChanged: (planningVehicleId: string, transportRequestId: string, transportFlowId: string, overridenValue: number) => void,
    handleDeliveredNumberOfTurnsChanged: (planningVehicleId: string, transportRequestId: string, transportFlowId: string, overridenValue: number) => void,
    refreshPlanningVehicles: () => void
    refreshPlanningVehicleWithDetails: (planningVehicleId: string) => void
    handleUpdatingPlanningVehicle: (planningVehicleId: string) => void
}

interface AllFeesData{
    allTollFeesValue: number,
    allWaitFeesValue: number,
    allOthersFeesValue: number
}

export const TransportRequestsDetailsComponent = (props: TransportRequestsDetailsComponentProps) => {

    const getAllFeesValues = (): AllFeesData => {
        let allTollFeesValue = 0;
        let allWaitFeesValue = 0;
        let allOthersFeesValue = 0;

        if (props.planningVehicleDetails) {
            props.planningVehicleDetails.forEach((detail: PlanningVehicleDetailsLightModel) => {
                const tollFees = detail.fees.tollFees === null ? 0 : Number(detail.fees.tollFees);
                const waitFees = detail.fees.waitFees === null ? 0 : Number(detail.fees.waitFees);
                const othersFees = detail.fees.othersFees === null ? 0 : Number(detail.fees.othersFees);

                allTollFeesValue = Number(allTollFeesValue + tollFees);
                allWaitFeesValue = Number(allWaitFeesValue + waitFees);
                allOthersFeesValue = Number(allOthersFeesValue + othersFees);
            });

            return {
                allTollFeesValue: allTollFeesValue,
                allWaitFeesValue: allWaitFeesValue,
                allOthersFeesValue: allOthersFeesValue
            };
        }

        return {
            allTollFeesValue: 0,
            allWaitFeesValue: 0,
            allOthersFeesValue: 0
        };
    }

    const getDcStatusClassName = (status: string): string => {
        switch (status) {
            case 'En attente':
                return "pendingTransportFlow";
            case 'Initial':
                return "initializedTransportFlow";
            case 'Optimisé':
                return "optimizedTransportFlow";
            case 'En cours':
                return "inProressTransportFlow";
            case 'Planifié':
                return "plannedTransportFlow";
            case 'Confirmé':
                return "confirmedTransportFlow";
            case 'Terminé':
                return "finishedTransportFlow";
            case 'Annulé':
                return "canceledTransportFlow";
            default:
                return "";
        }
    }

    const allFeesValues: AllFeesData = getAllFeesValues();

    const planningVehiclePriceKind = props.planningVehicle?.mainPriceKind;

    return (
        <Drawer
            className={props.isDcDetailsDrawerOpened ? 'dc-details-drawer-content open' : 'dc-details-drawer-content'}
            variant="persistent"
            anchor="right"
            open={props.isDcDetailsDrawerOpened}
        >
            <Box className="dc-details-drawer-header">
                <Box display="flex" flexDirection="row" flex="nowrap" className="total-fees-content">
                    <Box justifyContent="center" className="fees-label">
                        Frais
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" width="25%">
                        <Box justifyContent="center" className="fees-value-label">
                            {allFeesValues.allTollFeesValue !== null && Number(allFeesValues.allTollFeesValue) !== 0 ? allFeesValues.allTollFeesValue.toCurrencyString() : 'NR'}
                        </Box>
                        <Box justifyContent="center" className="fees-type-label">
                            Péage
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" width="25%">
                        <Box justifyContent="center" className="fees-value-label">
                            {allFeesValues.allWaitFeesValue !== null && Number(allFeesValues.allWaitFeesValue) !== 0 ? allFeesValues.allWaitFeesValue.toCurrencyString() : 'NR'}
                        </Box>
                        <Box justifyContent="center" className="fees-type-label">
                            Attente
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" width="25%">
                        <Box justifyContent="center" className="fees-value-label">
                            {allFeesValues.allOthersFeesValue !== null && Number(allFeesValues.allOthersFeesValue) !== 0 ? allFeesValues.allOthersFeesValue.toCurrencyString() : 'NR'}
                        </Box>
                        <Box justifyContent="center" className="fees-type-label">
                            Autres
                        </Box>
                    </Box>
                </Box>
            </Box>
            {props.planningVehicleDetails &&
                <div className="dc-details-content">
                    {props.planningVehicleDetails.map((item: PlanningVehicleDetailsLightModel, index: number) => {
                        return (
                            <Accordion key={index} defaultExpanded className="dc-panel">
                                <AccordionSummary className="dc-panel-header" expandIcon={<ExpandMoreIcon />}>
                                    <Box display="flex" flexDirection="row" flex="nowrap" justifyContent="space-between" className="panel-header">
                                        <Box className="businessId-label">{item.businessId}</Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails className="dc-panel-details">
                                    <Box display="flex" flexDirection="column" flex="wrap" className="panel-details">
                                        <Box display="flex" flexDirection="column" flex="wrap" className="details-section">
                                            <Box className="detail-title">Frais</Box>
                                            <Box display="flex" flexDirection="row" flex="wrap" pb={'5px'}>
                                                <Box width={'100%'}>
                                                    <FeesComponent
                                                        item={item.fees}
                                                        purchaseIsCanceled={item.purchaseIsCanceled}
                                                        planningVehicleId={item.planningVehicleId}
                                                        vehicleId={item.vehicleId}
                                                        planningDate={item.planningDate}
                                                        transportRequestId={item.transportRequestId}
                                                        displayErrorsMessages={props.displayErrorsMessages}
                                                        refreshPlanningVehicles={props.refreshPlanningVehicles}
                                                        refreshPlanningVehicleWithDetails={props.refreshPlanningVehicleWithDetails}
                                                        handleUpdatingPlanningVehicle={props.handleUpdatingPlanningVehicle}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                        {item.transportFlows.map((transportFlow: TransportFlowsDetailsLightModel, i: number) => {
                                            const dcStatusClassName = getDcStatusClassName(transportFlow.status);
                                            return (
                                                <Accordion key={i} defaultExpanded className="dc-panel">
                                                    <AccordionSummary className="dc-panel-header" expandIcon={<ExpandMoreIcon />}>
                                                        <Box display="flex" flexDirection="row" flex="nowrap" justifyContent="space-between" className="panel-header">
                                                            <Box className={'status-label ' + dcStatusClassName}>{transportFlow.status}</Box>
                                                            <Box className="businessId-label">{transportFlow.businessId}</Box>
                                                            <Box className="beneficiaryName-label">{transportFlow.beneficiaryName}</Box>
                                                        </Box>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="dc-panel-details">
                                                        <Box display="flex" flexDirection="column" flex="wrap" className="panel-details">
                                                            <Box display="flex" flexDirection="column" flex="wrap" className="details-section">
                                                                <Box className="detail-title">Départ</Box>
                                                                <Box display="flex" flexDirection="row" flex="nowrap" pb={'10px'}>
                                                                    <Box>
                                                                        <p className="item-value">{transportFlow.senderSiteLabel}</p>
                                                                    </Box>
                                                                    <Box pl={'5px'}>
                                                                        <p className="item-value">{(transportFlow.pickupAddress?.trim() ? `${transportFlow.pickupAddress}, ` : '')
                                                                            + (transportFlow.pickupAddressZipCode ? `${transportFlow.pickupAddressZipCode} ` : ' ')
                                                                            + (transportFlow.pickupAddressCity ? `${transportFlow.pickupAddressCity} ` : '')}
                                                                        </p>
                                                                    </Box>
                                                                </Box>
                                                                <Box className="detail-title">Arrivée</Box>
                                                                <Box display="flex" flexDirection="row" flex="wrap" pb={'10px'}>
                                                                    <Box>
                                                                        <p className="item-value">{transportFlow.receiverSiteLabel}</p>
                                                                    </Box>
                                                                    <Box pl={'5px'}>
                                                                        <p className="item-value">{(transportFlow.deliveryAddress?.trim() ? `${transportFlow.deliveryAddress}, ` : '')
                                                                            + (transportFlow.deliveryAddressZipCode ? `${transportFlow.deliveryAddressZipCode} ` : ' ')
                                                                            + (transportFlow.deliveryAddressCity ? `${transportFlow.deliveryAddressCity} ` : '')}
                                                                        </p>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box display="flex" flexDirection="column" flex="wrap" className="details-section">
                                                                <Box display="flex" flexDirection="row" flex="wrap" pb={'10px'}>
                                                                    <Box width={'100px'}>
                                                                        <Box className="detail-title">Produit</Box>
                                                                        <p className="item-value">{transportFlow.productCode}</p>
                                                                    </Box>
                                                                    <Box width={'100px'}>
                                                                        <Box className="detail-title">Heure début</Box>
                                                                        <p className="item-value">{`${transportFlow.firstStartTime}`}</p>
                                                                    </Box>
                                                                    <Box width={'100px'}>
                                                                        <Box className="detail-title">Heure fin</Box>
                                                                        <p className="item-value">{`${transportFlow.lastEndTime}`}</p>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box display="flex" flexDirection="column" flex="wrap" className="details-section">
                                                                <Box display="flex" flexDirection="row" flex="wrap" pb={'10px'}>
                                                                    <Box width={'85px'}>
                                                                        <Box className="detail-title">Tour(s)</Box>
                                                                    </Box>
                                                                    <Box width={'90px'}>
                                                                        <Box className="detail-title">Prévu(s)</Box>
                                                                        <p className="item-value">{transportFlow.plannedNumberOfTurns}</p>
                                                                    </Box>
                                                                    <Box width={'80px'}>
                                                                        <Box className="detail-title">Zéphyr</Box>
                                                                        <p className="item-value">{transportFlow.deliveredNumberOfTurns > 0 ? transportFlow.deliveredNumberOfTurns : ''}</p>
                                                                    </Box>
                                                                    <Box width={'145px'} mt={"-5px"}>
                                                                        <OverridenDeliveredNumberOfTurnsCellComponent
                                                                            computedNumberOfTurns={transportFlow.computedNumberOfTurns}
                                                                            kindOfValues={transportFlow.kindOfNumberOfTurnsValues}
                                                                            overridenDeliveredNumberOfTurns={transportFlow.overridenDeliveredNumberOfTurns}
                                                                            planningId={item.planningId}
                                                                            planningVehicleId={item.planningVehicleId}
                                                                            transportRequestId={item.transportRequestId}
                                                                            transportFlowId={transportFlow.transportFlowId}
                                                                            purchaseIsCanceled={item.purchaseIsCanceled}
                                                                            flowWithInconsistentOverrides={planningVehiclePriceKind === VehiclePriceKind.PerTurn && transportFlow.hasInconsistencies}
                                                                            displayErrorsMessages={props.displayErrorsMessages}
                                                                            refreshPlanningVehicles={props.refreshPlanningVehicles}
                                                                            refreshPlanningVehicleDeliveredNumberOfTurns={props.handleDeliveredNumberOfTurnsChanged}
                                                                            handleUpdatingPlanningVehicle={props.handleUpdatingPlanningVehicle}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                                <Box display="flex" flexDirection="row" flex="wrap" pb={'10px'}>
                                                                    <Box width={'85px'}>
                                                                        <Box className="detail-title">Quantité</Box>
                                                                    </Box>
                                                                    <Box width={'90px'}>
                                                                        <Box className="detail-title">Planifiée</Box>
                                                                        <p className="item-value">{transportFlow.plannedQuantity + ' T'}</p>
                                                                    </Box>
                                                                    <Box width={'80px'}>
                                                                        <Box className="detail-title">Zéphyr</Box>
                                                                        <p className="item-value">{transportFlow.deliveredQuantity > 0 ? transportFlow.deliveredQuantity + ' T' : ''}</p>
                                                                    </Box>
                                                                    <Box width={'145px'} mt={"-5px"}>
                                                                        <OverridenDeliveredQuantityCellComponent
                                                                            computedQuantity={transportFlow.computedQuantity}
                                                                            kindOfValues={transportFlow.kindOfQuantityValues}
                                                                            overridenDeliveredQuantity={transportFlow.overridenDeliveredQuantity}
                                                                            planningId={item.planningId}
                                                                            planningVehicleId={item.planningVehicleId}
                                                                            transportRequestId={item.transportRequestId}
                                                                            transportFlowId={transportFlow.transportFlowId}
                                                                            purchaseIsCanceled={item.purchaseIsCanceled}
                                                                            flowWithInconsistentOverrides={planningVehiclePriceKind === VehiclePriceKind.PerTon && transportFlow.hasInconsistencies}
                                                                            displayErrorsMessages={props.displayErrorsMessages}
                                                                            refreshPlanningVehicleDeliveredQuantity={props.handleDeliveredQuantityChanged}
                                                                            refreshPlanningVehicles={props.refreshPlanningVehicles}
                                                                            handleUpdatingPlanningVehicle={props.handleUpdatingPlanningVehicle}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </AccordionDetails>
                                                </Accordion>
                                            );
                                        })}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </div>
            }
        </Drawer>
    );
}