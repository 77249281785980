import { Tooltip } from '@mui/material';
import { GridCellProps } from '@progress/kendo-react-grid';
import '@progress/kendo-react-intl';
import React from 'react';
import { TransportSaleLightModelExtended } from '../models/TransportSaleLightModelExtended';
import { PreInvoiceSentStatusLightModel } from '../services/dataContracts/queryStack/PreInvoiceSentStatusLightModel';
import { TransportSaleEntityLightModel } from '../services/dataContracts/queryStack/TransportSaleEntityLightModel';

interface InvoiceCellProps extends GridCellProps {
    field: string
}

export const InvoiceCellComponent = (props: InvoiceCellProps) => {
    const dataItem: TransportSaleLightModelExtended = props.dataItem;

    const dataValue: string = props.field == "sentDate"
        ? (dataItem[props.field]
            ? (new Date(dataItem[props.field])).toCustomDateTimeString()
            : null)
        : dataItem[props.field];

    let defaultRendering: JSX.Element = <></>;

    const classNameOfBusinessIdField = props.field === "businessId" && dataItem.isSearchTextMatch ? "search-text-match" : "";

    if (dataItem.entity != TransportSaleEntityLightModel.transportFlow) {
        defaultRendering = (
            <td className={classNameOfBusinessIdField}>
                {dataValue}
            </td>
        );
    } else {
        defaultRendering = dataItem.sentStatus == PreInvoiceSentStatusLightModel.successfully
            ? (<td className={`invoice-successfully ${classNameOfBusinessIdField}`}>
                {dataItem.zephyrReceiptId ? <Tooltip title={dataItem.zephyrReceiptId} placement="bottom">
                    <div>
                        {dataValue}
                    </div>
                </Tooltip>
                    : <div className={classNameOfBusinessIdField}>{dataValue}</div>
                }
            </td>)
            : dataItem.sentStatus == PreInvoiceSentStatusLightModel.error
                ? (
                    <td className={`invoice-error ${classNameOfBusinessIdField}`}>
                        <Tooltip title={dataItem.sendingError} placement="bottom">
                            <div>
                                {dataValue}
                            </div>
                        </Tooltip>
                    </td>)
                : <td className={classNameOfBusinessIdField}>
                    {dataValue}
                </td>;
    }

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}
