import { Box, Button, TextareaAutosize, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import ToastService from 'src/ToastService';
import BusinessErrors from 'src/utils/BusinessErrors';
import { SendDriverFreeSmsRequestArgs } from '../services/dataContracts/controller/SendDriverFreeSmsRequestArgs';
import { DriverSmsNotification } from '../services/dataContracts/queryStack/DriverSmsNotification';
import { OperationalMonitoringApiClient } from '../services/OperationalMonitoringApiClient';

interface DriverSmsNotificationsDialogComponentProps {
    driverPhoneNumberIsMobile: boolean,
    driverSmsNotifications: DriverSmsNotification[],
    sendDriverFreeSmsRequestArgs: SendDriverFreeSmsRequestArgs,
    smsNotificationMaxContentLength: number,
    getDriverSmsNotifications: (planningVehicleId: string, driverId: number, driverName: string, planningDateAsNumeric: number, driverPhoneNumber: string, driverPhoneNumberIsMobile: boolean) => void
}

export const DriverSmsNotificationsDialogComponent = (props: DriverSmsNotificationsDialogComponentProps): JSX.Element => {

    const [smsContent, setSmsContent] = useState<string>('');
    const [smsMaxContentReached, setSmsMaxContentReached] = useState<boolean>(false);

    useEffect(() => {
        setSmsMaxContentReached(smsContent?.length > props.smsNotificationMaxContentLength);
    }, [smsContent]);

    const handleSmsContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setSmsContent(e.target.value);
    }

    const handleSendSms = debounce((): void => {
        const requestArgs: SendDriverFreeSmsRequestArgs = { ...props.sendDriverFreeSmsRequestArgs };
        requestArgs.smsContent = smsContent;

        OperationalMonitoringApiClient.SendFreeSmsToDriver(requestArgs)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                }
                else {
                    setSmsContent('');
                    props.getDriverSmsNotifications(requestArgs.planningVehicleId, requestArgs.driverId, requestArgs.driverName, requestArgs.planningDateAsNumeric, requestArgs.driverPhoneNumber, props.driverPhoneNumberIsMobile);
                }
            });
    }, 500);

    return (
        <Box display="flex" flexDirection="column" className="driver-notifications-content" height="100%">
            <Box display="flex" flexDirection="column-reverse" className="sms-notifications-content">
                {props.driverSmsNotifications.map((sms: DriverSmsNotification, index: number) => {
                    return (
                        <Box key={`driver-sms-notification-${index}`} display="flex" flexDirection="column" className="sms-content">
                            <Box display="flex" flexDirection="row" justifyContent="flex-end" mb="10px">
                                {sms.sendingDate.toCustomDateTimeString()}
                            </Box>
                            <Box display="flex" flexDirection="column" justifyContent="flex-start">
                                {sms.content.split('\n').map((line: string, i: number) => {
                                    return (
                                        <Box key={`line-${i}`} minHeight="18px">
                                            {line}
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="column" className="send-section">
                <TextareaAutosize
                    className="sms-text-input"
                    maxRows={3}
                    minRows={3}
                    maxLength={props.smsNotificationMaxContentLength}
                    aria-label="sms notification"
                    value={smsContent}
                    onChange={(e) => handleSmsContentChange(e)}
                />
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" mt="10px"
                        className={`sms-length-counter${smsMaxContentReached ? " error" : ""}`}>
                        {smsContent.length + "/" + props.smsNotificationMaxContentLength + " Caractères"}
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="flex-end" mt="10px">
                        <Tooltip title={!props.driverPhoneNumberIsMobile ? "Impossible d'envoyer un SMS à un numéro de téléphone fixe" : ""} placement="top">
                            <div>
                                <Button variant="contained" color="primary" title="Envoyer SMS" disabled={smsContent === '' || smsMaxContentReached || !props.driverPhoneNumberIsMobile} onClick={handleSendSms}>
                                    Envoyer SMS
                                </Button>
                            </div>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
