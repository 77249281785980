import { AccordionDetails, Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderSelectionChangeEvent, GridItemChangeEvent, GridRowClickEvent, GridRowProps, GridSelectionChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React from 'react';
import { ScaleLoader } from 'react-spinners';
import { EditableHeaderComponent } from 'src/shared/components/Common/EditableHeaderComponent';
import { PhoneNumberCell } from '../../../shared/components/Common/PhoneNumberCell';
import { AppModule, LocalStorage } from '../../../utils/Storage';
import { DeliveryTripLightModelExtended } from '../models/DeliveryTripLightModelExtended';
import CustomDeliveryNoteCellComponent from './CustomDeliveryNoteCellComponent';
import { CustomExpectedQuantityCellComponent } from './CustomExpectedQuantityCellComponent';
import { CustomTripStatusCellComponent } from './CustomTripStatusCellComponent';
import DeliveryTripInstructionsCustomCellComponent from './DeliveryTripInstructionsCustomCellComponent';
import TimeEditorComponent from './TimeEditorComponent';

interface DeliveryTripState {
    sort: Array<SortDescriptor>,
    total: number,
    skip: number,
    take: number
}

interface DeliveryTripProperties {
    data: Array<DeliveryTripLightModelExtended>,
    getTransportRequestTripWidth: (propName: string, width: number) => any,
    onReorderHandler: (event: GridColumnReorderEvent) => void,
    onResizeHandler: (event: GridColumnResizeEvent) => void,
    headerSelectionChange: (event: GridHeaderSelectionChangeEvent) => void,
    selectionChange: (event: GridSelectionChangeEvent) => void,
    rowClickDeliveryTrip: (DeliveryTripLightModelExtended: DeliveryTripLightModelExtended, event?: GridRowClickEvent) => void,
    rowRenderDeliveryTrip: (trElement: React.ReactElement<HTMLTableRowElement>, dataItem: GridRowProps) => React.ReactElement<HTMLTableRowElement>,
    cellRenderDeliveryTrip: (trElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps) => any,
    loading: boolean
}

const TRANSPORTREQUEST_ID_FIELD = "transportRequestId";
const TRIP_STATUS_FIELD = "tripStatus";
const STATUS_TERMINE = 'Terminé';

export const DeliveryTripsGridName = 'deliveryTrips';

export default class DeliveryTripComponent extends React.Component<DeliveryTripProperties, DeliveryTripState>{
    constructor(props: DeliveryTripProperties) {
        super(props);
        this.state = {
            sort: [
                { field: 'vehicleNumber', dir: 'asc' },
                { field: 'loadingTime', dir: 'asc' }
            ],
            total: 0,
            skip: 0,
            take: 50
        };
    }

    getDeliveryTripOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(AppModule.TransportPlan, DeliveryTripsGridName, fieldName, defaultIndex);
    }

    handleSortChange = (e: GridSortChangeEvent): void => {
        this.setState({
            sort: e.sort
        });
    }

    handleSortEditableColumnChange = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, field: string): void => {
        const { sort } = this.state;
        const sortItems: SortDescriptor[] = [...sort];
        const currentSortIndex = sortItems.findIndex(x => x.field === field);
        if(currentSortIndex !== -1){
            if(sortItems[currentSortIndex].dir === 'asc'){
                sortItems[currentSortIndex].dir = 'desc';
            }
            else{
                sortItems.splice(currentSortIndex, 1);
            }
        }
        else{
            sortItems.push({ field: field, dir: 'asc' });
        }

        this.setState({
            sort: sortItems
        });
    }

    itemChange = (event: GridItemChangeEvent): void => {
        this.props.data.find(t => t.transportRequestId === event.dataItem[TRANSPORTREQUEST_ID_FIELD]).assignedQuantity = this.props.data.find(t => t.transportRequestId === event.dataItem[TRANSPORTREQUEST_ID_FIELD]).assignedQuantity - (Number(event.dataItem[event.field])) + Number(event.value);
        if (event.dataItem[TRIP_STATUS_FIELD] === STATUS_TERMINE)
            this.props.data.find(t => t.transportRequestId === event.dataItem[TRANSPORTREQUEST_ID_FIELD]).deliveredQuantity = this.props.data.find(t => t.transportRequestId === event.dataItem[TRANSPORTREQUEST_ID_FIELD]).deliveredQuantity - (Number(event.dataItem[event.field])) + Number(event.value);
        event.dataItem[event.field] = event.value;
        this.forceUpdate();
    }

    rowClickHandler = (event: GridRowClickEvent): void => {
        this.props.rowClickDeliveryTrip(event.dataItem, event)
    }

    render() {
        const tripComponent = this.props.loading
            ? <div className='sweet-loading spinnerClass'>
                <ScaleLoader
                    width={5}
                    height={20}
                    radius={50}
                    color={'#000000'}
                    loading={this.props.loading}
                />
            </div>
            :
            <AccordionDetails className="container-expansionPanel-details">
                <Box className="delivery-trip-content-grid transport-request-dc" width="100%">
                    <div id="deliveryTrips" style={{ height: '100%' }} className="del-cal-trp delivery-trip-grid">
                        <LocalizationProvider language="fr-FR">
                            <IntlProvider locale="fr" >
                                <Grid
                                    style={{ height: '98%' }}
                                    rowHeight={40}
                                    data={orderBy(this.props.data, this.state.sort)}
                                    reorderable
                                    resizable
                                    selectedField="selected"
                                    sort={this.state.sort}
                                    sortable={{ mode: 'multiple' }}
                                    onSortChange={this.handleSortChange}
                                    onColumnResize={this.props.onResizeHandler}
                                    onHeaderSelectionChange={this.props.headerSelectionChange}
                                    onSelectionChange={this.props.selectionChange}
                                    onRowClick={(e) => this.rowClickHandler(e)}
                                    cellRender={this.props.cellRenderDeliveryTrip}
                                    rowRender={this.props.rowRenderDeliveryTrip}
                                    onItemChange={this.itemChange}
                                    editField="inEdit"
                                    onColumnReorder={(e) => this.props.onReorderHandler(e)}>
                                    <GridColumn field="selected" className='cell-padding-left' headerClassName='cell-padding-left' orderIndex={this.getDeliveryTripOrderIndexColumn("selected", 0)} width="25" headerSelectionValue={this.props.data.findIndex((dataItem: DeliveryTripLightModelExtended) => dataItem.selected === false) === -1} />
                                    <GridColumn field="tripStatus" orderIndex={this.getDeliveryTripOrderIndexColumn("tripStatus", 1)} title="Statut" editable={false} width={this.props.getTransportRequestTripWidth("tripStatus", 63)}
                                        cell={(properties: GridCellProps) => <CustomTripStatusCellComponent {...properties} selectRowHandler={() => this.props.rowClickDeliveryTrip(properties.dataItem)} />} />
                                    <GridColumn field="businessId" orderIndex={this.getDeliveryTripOrderIndexColumn("businessId", 2)} title="N° tour" editable={false} width={this.props.getTransportRequestTripWidth("businessId", 106)} />
                                    <GridColumn field="vehicleNumber" orderIndex={this.getDeliveryTripOrderIndexColumn("vehicleNumber", 3)} title="N°" editable={false} width={this.props.getTransportRequestTripWidth("vehicleNumber", 60)} />
                                    <GridColumn field="tripVehicle" orderIndex={this.getDeliveryTripOrderIndexColumn("tripVehicle", 4)} title="Camion" editable={false} width={this.props.getTransportRequestTripWidth("tripVehicle", 104)} />
                                    <GridColumn field="vehicleType" orderIndex={this.getDeliveryTripOrderIndexColumn("vehicleType", 5)} title="Type de camion" editable={false} width={this.props.getTransportRequestTripWidth("vehicleType", 226)} />
                                    <GridColumn field="beneficiaryName" orderIndex={this.getDeliveryTripOrderIndexColumn("beneficiaryName", 6)} title="Bénéficiaire" editable={false} width={this.props.getTransportRequestTripWidth("beneficiaryName", 246)} />
                                    <GridColumn field="loadingTime" orderIndex={this.getDeliveryTripOrderIndexColumn("loadingTime", 7)} title="Heure chargement"
                                        headerCell={() => <EditableHeaderComponent field="loadingTime" sort={this.state.sort} _handleSortEditableColumnChange={this.handleSortEditableColumnChange} title="Heure chargement" />} 
                                        cell={(props: GridCellProps) => <TimeEditorComponent {...props} format={"HH:mm dd-MM-yyyy"} />}
                                        width={this.props.getTransportRequestTripWidth("loadingTime", 84)} />
                                    <GridColumn field="deliveryTime" orderIndex={this.getDeliveryTripOrderIndexColumn("deliveryTime", 8)} title="Heure arrivée"
                                        headerCell={() => <EditableHeaderComponent field="deliveryTime" sort={this.state.sort} _handleSortEditableColumnChange={this.handleSortEditableColumnChange} title="Heure arrivée" />} 
                                        cell={(props: GridCellProps) => <TimeEditorComponent {...props} format={"HH:mm dd-MM-yyyy"} />}
                                        width={this.props.getTransportRequestTripWidth("deliveryTime", 79)} />
                                    <GridColumn field="tripTransporter" orderIndex={this.getDeliveryTripOrderIndexColumn("tripTransporter", 9)} title="Transporteur" editable={false} width={this.props.getTransportRequestTripWidth("tripTransporter", 246)} />
                                    <GridColumn field="tripDriver" orderIndex={this.getDeliveryTripOrderIndexColumn("tripDriver", 10)} title="Chauffeur" editable={false} width={this.props.getTransportRequestTripWidth("tripDriver", 140)} />
                                    <GridColumn field="tripDriverPhoneNumber" orderIndex={this.getDeliveryTripOrderIndexColumn("tripDriverPhoneNumber", 11)} title="Tél. Chauffeur" editable={false} width={this.props.getTransportRequestTripWidth("tripDriverPhoneNumber", 97)}
                                        cell={(props: GridCellProps) => <PhoneNumberCell {...props} />} />
                                    <GridColumn field="distance" orderIndex={this.getDeliveryTripOrderIndexColumn("distance", 12)} title="Distance" editable={false} width={this.props.getTransportRequestTripWidth("distance", 81)} />
                                    <GridColumn field="expectedQuantity" orderIndex={this.getDeliveryTripOrderIndexColumn("expectedQuantity", 13)} title="Qté planifiée" format="{0:0.00}" width={this.props.getTransportRequestTripWidth("expectedQuantity", 77)}
                                        headerCell={() => <EditableHeaderComponent field="expectedQuantity" sort={this.state.sort} _handleSortEditableColumnChange={this.handleSortEditableColumnChange} title="Qté planifiée" />} 
                                        cell={(props: GridCellProps) => <CustomExpectedQuantityCellComponent {...props} />} />
                                    <GridColumn field="deliveredQuantity" orderIndex={this.getDeliveryTripOrderIndexColumn("deliveredQuantity", 14)} title="Qté livrée" format="{0:0.00}" width={this.props.getTransportRequestTripWidth("deliveredQuantity", 77)} editable={false} />
                                    <GridColumn field="instructions" orderIndex={this.getDeliveryTripOrderIndexColumn("instructions", 15)} title="Instructions chauffeurs" width={this.props.getTransportRequestTripWidth("instructions", 310)}
                                        headerCell={() => <EditableHeaderComponent field="instructions" sort={this.state.sort} _handleSortEditableColumnChange={this.handleSortEditableColumnChange} title="Instructions chauffeurs" />} 
                                        cell={(props: GridCellProps) => <DeliveryTripInstructionsCustomCellComponent {...props} width={this.props.getTransportRequestTripWidth("instructions", 310)} />} />
                                    <GridColumn field="transportRequestDate" orderIndex={this.getDeliveryTripOrderIndexColumn("transportRequestDate", 16)} title="Date" format="{0:dd-MM-yyyy}" editable={false} width={this.props.getTransportRequestTripWidth("transportRequestDate", 87)} />
                                    <GridColumn field="idGM" orderIndex={this.getDeliveryTripOrderIndexColumn("idGM", 17)} title="IdGM" editable={false} width={this.props.getTransportRequestTripWidth("idGM", 72)} />
                                    <GridColumn field="deliveryNoteId" orderIndex={this.getDeliveryTripOrderIndexColumn("deliveryNoteId", 18)} title="BL" editable={false} width={this.props.getTransportRequestTripWidth("deliveryNoteId", 60)}
                                        cell={(props: GridCellProps) => <CustomDeliveryNoteCellComponent {...props} />} />
                                </Grid>
                            </IntlProvider>
                        </LocalizationProvider>
                    </div>;
                </Box>
            </AccordionDetails>
        return (
            <>
                {tripComponent}
            </>
        );
    }
}