import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { NumericTextBox, NumericTextBoxBlurEvent, NumericTextBoxChangeEvent, NumericTextBoxHandle } from '@progress/kendo-react-inputs';
import React from 'react';
import { FeesProperty } from '../services/dataContracts/controller/FeesProperty';

interface PlanningVehicleFeesCellProps {
    id: string,
    inEdit: string,
    feesProperty: FeesProperty,
    feesRef: React.RefObject<NumericTextBoxHandle>,
    feesValue: number,
    label: string,
    purchaseIsCanceled: boolean,
    handleChange: (event: NumericTextBoxChangeEvent, feesProperty: string) => void,
    handleBlur: (event: NumericTextBoxBlurEvent, feesProperty: string) => void,
    clickFeesValue: (feesProperty: FeesProperty) => void
}

export const PlanningVehicleFeesCell = (props: PlanningVehicleFeesCellProps) => {

    return (
        <Box display="flex" flexDirection="column" width="137px">
            <Box className="header-title">
                {props.label}{!props.purchaseIsCanceled && <FontAwesomeIcon size="1x" className='icon-pen-blue' icon={faPen} />}
            </Box>
            <Box>
                {
                    props.inEdit === props.feesProperty ?
                        (<NumericTextBox
                            id={props.id}
                            className="input-cell-fees"
                            ref={props.feesRef}
                            value={props.feesValue}
                            min={0}
                            format={{
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'EUR',
                                currencyDisplay: 'symbol'
                            }}
                            spinners={false}
                            width={100}
                            onChange={(event) => props.handleChange(event, props.feesProperty)}
                            onBlur={(event) => props.handleBlur(event, props.feesProperty)}
                        />)
                        :
                        (<p className={`item-value ${props.purchaseIsCanceled ? "purchase-is-canceled" : ""}`} onClick={() => !props.purchaseIsCanceled ? props.clickFeesValue(props.feesProperty) : undefined}>{props.feesValue ? props.feesValue.toCurrencyString() : 'NR'}</p>)
                }
            </Box>
        </Box>
    )
}
