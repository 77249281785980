import { Checkbox } from '@mui/material';
import React from 'react';
import { NegotiatedSellPriceLightModelExtended } from '../models/NegotiatedSellPriceLightModelExtended';

interface CustomCheckBoxCellProperties {
    dataItem?: any,
    field: string,
    handleSelectionItemChange: (checked: boolean, dataItem: NegotiatedSellPriceLightModelExtended) => void
}

export const CustomCheckBoxCell = (props: CustomCheckBoxCellProperties) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.handleSelectionItemChange(event.target.checked, props.dataItem);
    };

    const defaultRendering: JSX.Element = (
        <td>
            <Checkbox
                checked={props.dataItem[props.field]}
                onChange={(e) => handleChange(e)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </td>
    );

    return defaultRendering;
}