export interface ItemModel {
    label: string,
    value: string
}

export class FixedLists {
    public static serviceKindList: ItemModel[] = [
        { label: "Livraisons", value: "Delivery" },
        { label: "Enlèvements", value: "Removal" },
        { label: "Camions chantier", value: "JobsiteVehicle" }
    ];

    public static priceKindList: ItemModel[] = [
        { label: "(Par défaut)", value: null },
        { label: "Forfait", value: "PerTimePeriod" },
        { label: "Tonne", value: "PerTon" },
        { label: "Tour", value: "PerTurn" },
    ];
}