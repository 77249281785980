import React, { useEffect, useState } from 'react';
import Select, { ActionMeta, components, InputActionMeta, OptionProps } from 'react-select';
import './FilterSearchStyles.scss';
import { FieldDescriptor } from './models/FieldDescriptor';

interface TextSearchFieldsSelectorProperties<T> {
    options: Array<FieldDescriptor<T>>,
    placeHolder: string,
    noOptionsMessage: string,
    selectedAll?: boolean,
    isDisabled?: boolean,
    selectAllLabel?: string,
    onChange: (selected: Array<T>) => void
}

export const TextSearchFieldsSelectorComponent = <T extends {}>(props: TextSearchFieldsSelectorProperties<T>) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [optionsSelected, setOptionsSelected] = useState<Array<FieldDescriptor<T>>>([]);
    const [openMenu, setOpenMenu] = useState<boolean>(false);

    const filterOptionAll: FieldDescriptor<any> = {
        label: props.selectAllLabel ?? 'Tout',
        value: null
    };

    const Option = (props: OptionProps<FieldDescriptor<T>>) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label className={props.data.label === filterOptionAll.label ? 'filter-option-all-label' : ''}>{props.data.label}</label>
                </components.Option>
            </div>
        );
    };

    useEffect(() => {
        setOptionsSelected(props.selectedAll === true ? [filterOptionAll, ...props.options] : []);
    }, [props.options]);

    const HandleInputChange = (query: string, inputActionMeta: InputActionMeta): void => {
        if (inputActionMeta.action !== "set-value") {
            setInputValue(query);
        }
    }

    const HandleSearch = () => {
        let result = [];
        if (optionsSelected.length > 0) {
            result = optionsSelected.filter(x => x.value !== filterOptionAll.value).map(x => x.value);
            setOpenMenu(false);
        }

        props.onChange(result);
    }

    const HandleChange = (selected: any, event: ActionMeta<FieldDescriptor<T>>): void => {
        let result = [];

        if (selected !== null && selected.length > 0) {
            const optionsData = props.options;
            if (selected[selected.length - 1].value === filterOptionAll.value) {
                if (event.action === "select-option") {
                    result = [filterOptionAll, ...optionsData];
                }
            }
            else if (selected.length === optionsData.length) {
                const indexElem = selected.findIndex(x => x.value === filterOptionAll.value);

                if (indexElem !== -1) {
                    result = selected.filter(
                        option => option.value !== filterOptionAll.value
                    );
                } else if (event.action === "select-option") {
                    result = [filterOptionAll, ...optionsData];
                }
            }
            else {
                result = selected;
            }
        }

        setOptionsSelected(result);
    };

    const optionAllSelected = optionsSelected.find(x => x.value == filterOptionAll.value);
    return (
        <Select
            isMulti
            hideSelectedOptions={false}
            placeholder={<span>{`${optionAllSelected !== undefined ? `${props.placeHolder} ${filterOptionAll.label}`
                : `${optionsSelected.length == 1 ? optionsSelected[0].label
                    : `${optionsSelected.length} filtres`}`} `}</span>}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value as unknown as string}
            value={optionsSelected}
            isClearable={false}
            controlShouldRenderValue={false}
            inputValue={inputValue}
            onInputChange={HandleInputChange}
            options={[filterOptionAll, ...props.options]}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            noOptionsMessage={() => {
                return optionsSelected?.length > 0
                    ? ""
                    : props.noOptionsMessage;
            }}
            className={`option-filter-container${optionAllSelected === undefined && optionsSelected.length > 0 ? ' option-filter-container-filtered' : ''}`}
            components={{ Option }}
            onBlur={HandleSearch}
            onMenuOpen={() => setOpenMenu(true)}
            menuIsOpen={openMenu}
            onChange={(selected, event) => HandleChange(selected, event)}
            menuPlacement="auto"
            classNamePrefix="option-filter"
            isDisabled={props.isDisabled}
        />
    );
}