import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, Divider, Drawer, FormControlLabel } from '@mui/material';
import React from 'react';
import { ScaleLoader } from 'react-spinners';
import { TransporterLightModel } from '../services/dataContracts/queryStack/TransporterLightModel';

interface TransportersProps {
    isTransporterDrawerOpened: boolean,
    loading: boolean,
    transporters: Array<TransporterLightModel>,
    selectedTransporters: Array<string>,
    handleOpenCloseTransporterDrawer: () => void,
    toggleCheckbox: (transporter: TransporterLightModel) => void
}

export const TransportersComponent = (props: TransportersProps) => {
    return (
        <Drawer
            className='drawer-tr'
            variant="persistent"
            anchor="left"
            open={props.isTransporterDrawerOpened}
            classes={{
                paper: 'drawer-paper'
            }}>
            <div className='toolbar-styles' />
            <Box display="flex" flexDirection="column" flex='nowrap' className='content-drawer'>
                <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems='center' className="header">
                    <Box width="80%" className='title'>
                        Transporteurs
                        </Box>
                    <Box display="flex" width="20%" flexDirection="row" justifyContent="flex-end">
                        <Box style={{ cursor: 'pointer' }} onClick={() => props.handleOpenCloseTransporterDrawer()} >
                            <FontAwesomeIcon size="lg" icon={faTimes} />
                        </Box>
                    </Box>
                </Box>
                <Divider className='divider-title' />
                {props.loading
                ? <div className='sweet-loading spinnerClass'>
                    <ScaleLoader
                        width={5}
                        height={20}
                        radius={50}
                        color={'#000000'}
                        loading={props.loading}
                    />
                </div>
                :
                <Box display="flex" flexDirection="column" className="body">
                    {props.transporters.map((transporter: TransporterLightModel) => (
                        <Box key={transporter.transporterId}>
                            <FormControlLabel className={props.selectedTransporters.indexOf(transporter.transporterId) > -1 ? 'transporter-item selected-transporter' : 'transporter-item'}
                                control={
                                    <Checkbox color="primary" checked={props.selectedTransporters.indexOf(transporter.transporterId) > -1} onChange={() => props.toggleCheckbox(transporter)} />
                                }
                                label={
                                    <Box display="flex" flexWrap="nowrap" flexDirection="row" justifyContent="space-between" className='black-cls'>
                                        <Box className="lbl">{transporter.transporterName}</Box>
                                    </Box>
                                }
                            />
                            <Divider className='divider-title' />
                        </Box>
                    ))}
                </Box>}
            </Box>
        </Drawer>
    );
}
