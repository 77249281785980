import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { Container } from 'reactstrap';
import { LogisticsUnitChoice } from '../shared/models/LogisticsUnitChoice';
import { RouteComponentProps, withRouter } from '../withRouter';
import { NavMenu } from './NavMenu';

//Nécessaire pour que l'application soit supporté par IE11
//import 'babel-polyfill';
export interface LayoutProps {
    children?: React.ReactNode,
    role: string,
    userName: string,
    userInitials: string,
    userDisplayName: string,
    userPhoneNumber: string,
    userEmail: string,
    loadingLayout: boolean,
    logisticsUnits: Array<LogisticsUnitChoice>,
    logisticsUnitIds: Array<string>,
    handleLogisticsUnitsChanges: (logisticsUnits: Array<LogisticsUnitChoice>) => void,
}

const Layout = (props: RouteComponentProps & LayoutProps) => {
    const location = useLocation();
    const isAuthorizedUser = !!props.role;

    const { trackPageView } = useMatomo();
    useEffect(() => {
        trackPageView({ href: location.pathname });
    }, [location.pathname, props.userName]);

    const authorizedUser = props.loadingLayout
        ? <div className='sweet-loading spinnerClass'>
            <ScaleLoader
                width={5}
                height={20}
                radius={50}
                color={'#000000'}
                loading={props.loadingLayout}
            />
        </div>
        : isAuthorizedUser
            ?
            <div className="internal-layout">
                <NavMenu
                    role={props.role}
                    userName={props.userName}
                    userDisplayName={props.userDisplayName}
                    userPhoneNumber={props.userPhoneNumber}
                    userEmail={props.userEmail}
                    userInitials={props.userInitials}
                    handleRefreshLogisticsUnitsChanges={props.handleLogisticsUnitsChanges}
                    logisticsUnitIds={props.logisticsUnitIds}
                    logisticsUnits={props.logisticsUnits} />
                <Container fluid>
                    {props.children}
                </Container>
            </div>
            :
            <div className="jumbotron">
                <h1>Accès Refusé</h1>
                <p>Votre compte n'a pas les permissions requises ou est inconnu de l'application.</p>
            </div>;
    return (
        <div>
            {authorizedUser}
        </div>
    );
}

export default React.forwardRef(withRouter(Layout));