import React from 'react';
import { NeededVehiclesWeeklySummaryLightModel } from '../services/dataContracts/queryStack/NeededVehiclesWeeklySummaryLightModel';

interface WeekItemComponentProps {
    data: NeededVehiclesWeeklySummaryLightModel
}
export const WeekItemComponent = (props: WeekItemComponentProps): JSX.Element => {

    const { data } = props;

    return (
        <div>
            <p className="banner-vehicle-number-font-style">{data.dayTimePlannedNumberOfVehicles + ' / ' + data.nightTimePlannedNumberOfVehicles}</p>
            <p className="banner-date-font-style">{new Date(data.firstDayInWeek).toShortDateString()}</p>
            <p className="banner-date-font-style">{new Date(data.lastDayInWeek).toShortDateString()}</p>
        </div>
    );
}
