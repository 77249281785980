import { Box, Button, Radio } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import { TransporterCandidateToAddExtended } from '../../models/TransporterCandidateToAddExtended';
import { TransportPurchasePricesReferentialApiClient } from '../../services/TransportPurchasePricesReferentialApiClient';

interface SelectTransporterStepComponentProperties {
    transporterChoices: TransporterCandidateToAddExtended[],
    handleSearchInReferentialGrid: (transporterMdmId: string) => void,
    handleConfirmStep: (transporter: TransporterCandidateToAddExtended) => void,
    handlePreviousStep: () => void
}

export const SelectTransporterStepComponent = (props: SelectTransporterStepComponentProperties): JSX.Element => {

    const initialSort: SortDescriptor[] = [{ field: 'date', dir: 'asc' }];

    const [transporterChoices, setTransporterChoices] = useState<TransporterCandidateToAddExtended[]>([...props.transporterChoices]);
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);

    const handleTransporterChoiceChanged = (checked: boolean, dataItem: TransporterCandidateToAddExtended): void => {
        const transporterChoicesArray: TransporterCandidateToAddExtended[] = [...transporterChoices];
        transporterChoicesArray.forEach((item: TransporterCandidateToAddExtended) => {
            item.mdmId === dataItem.mdmId ? item.selected = true : item.selected = false;
        });

        setTransporterChoices(transporterChoicesArray);
    }

    const handleClikAddTransporter = (): void => {
        const transporterToAdd: TransporterCandidateToAddExtended = transporterChoices.find(x => x.selected === true);

        TransportPurchasePricesReferentialApiClient.TransporterExists(transporterToAdd.mdmId)
            .then((res) => {
                if (res.data === true)
                    props.handleSearchInReferentialGrid(transporterToAdd.mdmId);
                else
                    props.handleConfirmStep(transporterToAdd);
            });
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const isAddButtonDisabled: boolean = transporterChoices.every((item) => item.selected === false);

    return (
        <Box display="flex" flexDirection="column">
            <Box className="transporter-choices">
                <LocalizationProvider language="fr-FR">
                    <IntlProvider locale="fr" >
                        <Grid
                            data={orderBy(transporterChoices, sort)}
                            sort={sort}
                            className="transporter-choices-grid"
                            sortable
                            onSortChange={handleSortChange}
                            rowHeight={40}
                        >
                            <GridColumn field="selected" title="Sélection" width={60}
                                cell={(props: GridCellProps) => <td>
                                    <Radio
                                        checked={props.dataItem.selected}
                                        onChange={(e, checked) => handleTransporterChoiceChanged(checked, props.dataItem)}
                                        color="default"
                                    />
                                </td>}
                            />
                            <GridColumn field="mdmId" title="IdMdM" width={60} />
                            <GridColumn field="longName" title="Nom du transporteur" width={150} />
                            <GridColumn field="customLongName" title="Nom court du transporteur" width={150} />
                            <GridColumn field="businessIdentifier" title="Numéro SIRET" width={100} />
                            <GridColumn field="addressLine1" title="Adresse 1" width={170} />
                            <GridColumn field="addressLine2" title="Adresse 2" width={170} />
                            <GridColumn field="addressZipCode" title="Code postal" width={75} />
                            <GridColumn field="addressCity" title="Ville" width={100} />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                <Button variant="contained" title="Retour" className="previous-button" onClick={props.handlePreviousStep}>
                    Retour
                </Button>
                <Button variant="contained" color="primary" title="Ajouter" onClick={handleClikAddTransporter} disabled={isAddButtonDisabled} >
                    Ajouter
                </Button>
            </Box>
        </Box>
    );
}