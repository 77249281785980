import { GridCellProps } from '@progress/kendo-react-grid';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import React from 'react';
import { GenericPurchasePricesModel } from '../models/GenericPurchasePricesModel';

interface CustomPriceCellComponentProps extends GridCellProps {
    isReadOnly: boolean
}

export const CustomPriceCellComponent = (props: CustomPriceCellComponentProps): JSX.Element => {
    const dataItem = props.dataItem as GenericPurchasePricesModel;
    const newValue = !dataItem.change ? dataItem.price : dataItem.change;
    let defaultRendering: JSX.Element = null;

    const handleChange = (event: NumericTextBoxChangeEvent, gridPprops: GridCellProps): void => {
        dataItem.inEdit = gridPprops.field;
        const newValue = event.value != null && event.value >= 0 ? event.value : dataItem[gridPprops.field];
        dataItem.change = newValue;
    }

    if (props.isReadOnly || !dataItem.inEdit || dataItem.inEdit !== props.field) {
        const oldValue = dataItem[props.field];
        defaultRendering = (
            <td className={newValue !== oldValue ? "field-changed" : ""}>
                {!newValue ? '' : newValue}
            </td>
        );
    } else {
        defaultRendering = (
            <td>
                <NumericTextBox
                    value={newValue}
                    min={0}
                    width={110}
                    onChange={(event) => handleChange(event, props)} />
            </td>
        );
    }
    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}