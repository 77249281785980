import { ColumnConfig, ColumnStore, DomHelper, DragHelper, LocaleManager, Scheduler, ViewPreset } from '@bryntum/scheduler';
import { BryntumScheduler, BryntumSchedulerProps } from '@bryntum/scheduler-react';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { DeliveryTripStatus } from '../../../shared/models/DeliveryTripStatus';
import BusinessErrors from '../../../utils/BusinessErrors';
import { EventModelData } from '../models/EventModelData';
import { PlanningVehicleTripsLightModelExtended } from '../models/PlanningVehicleTripsLightModelExtended';
import { TransportFlowLightModelExtended } from '../models/TransportFlowLightModelExtended';

interface ExpeditionSchedulerProps {
    schedulerRef: React.RefObject<BryntumScheduler>,
    columns: any[],
    rowHeight: number,
    barMargin: number,
    role: string,
    resources: Array<PlanningVehicleTripsLightModelExtended>,
    events: Array<EventModelData>,
    eventsVersion: number,
    subGridConfigs: any,
    eventBodyTemplate: (eventRecord: any) => string,
    handleRemovePlanningVehicle: (planningVehicleId: string, planningId: number, hasOrderLine: boolean, hasAtLeastOneDeliveryTrip: boolean) => void,
    handleMoveTripsToNewPlanningVehicle: (planningVehicleId: string) => void,
    handleClickOpenDuplicatePlanningVehicle: (planningVehicleId: string) => void,
    handleRemovePlanningVehicleTransporter: (planningVehicleId: string) => void,
    handleRemoveContractualPurchasePriceFromPlanningVehicle: (planningVehicleId: string) => void,
    handleCancelReinstatePlanningVehicle: (data: PlanningVehicleTripsLightModelExtended) => void,
    editFlow: (transportFlowId: string, flowBusinessId: string) => void,
    duplicateFlow: (transportFlowId: string) => void,
    handleUpdateSpecificPriceOfDeliveryTrips: () => void,
    handleAssignDeliveryTripStatus: (newStatusSelected: DeliveryTripStatus) => void,
    removeSelectedTrips: (hideFlow: boolean) => void,
    attachZephyrReturnManually: (transportFlowId: string) => void,
    refreshMenuItemEditFlow: (item: any, data: EventModelData) => any,
    refreshMenuItemDuplicateFlow: (item: any) => any,
    refreshMenuItemUpdateSpecificPriceFromDeliveryTrips: (item: any) => any,
    refreshMenuItemRemoveTrips: (item: any, hideFlow: boolean) => any,
    refreshMenuItemEditTripStatus: (item: any) => any,
    refreshMenuItemRemovePlanningVehicle: (item: any, data: PlanningVehicleTripsLightModelExtended) => any,
    refreshMenuItemDuplicatePlanningVehicle: (item: any, data: PlanningVehicleTripsLightModelExtended) => any,
    refreshMenuItemRemovePlanningVehicleTransporter: (item: any, data: PlanningVehicleTripsLightModelExtended) => any,
    refreshMenuItemRemoveContractualPurchasePriceFromPlanningVehicle: (item: any, data: PlanningVehicleTripsLightModelExtended) => any,
    refreshMenuItemCancelPlanningVehicle: (item: any) => any,
    refreshMenuItemReinstatePlanningVehicle: (item: any) => any,
    refreshMenuItemMoveSelectedTrips: (item: any, data: PlanningVehicleTripsLightModelExtended) => any,
    refreshAttachZephyrReturnManually: (item: any) => any,
    eventSelectionHandler: (dataSelected: Array<EventModelData>) => void,
    rowGridSelectionHandler: (selectedVehicleEvents: any) => void,
    showEventDetail: (event: any, eventRecord: any) => void,
    handleTripsDropToNewLoadingTime: (event: any) => void,
    onResizeTripToNewDeliveryTime: (transportRequestId: string, deliveryTripId: number, newDeliveryTime: Date) => void,
    handleCellClick: (record: any) => void,
    onReorderPlanningVehiclesGridColumnHandler: (columns: Array<ColumnConfig>) => void,
    onResizePlanningVehiclesGridColumnHandler: (newWidth: number) => void,
    handleEventsVersionChanged: () => void,
    createTripFromFlow: (vehiclePlanningId: string, transportFlowId: string, transportRequestId: string, deliveryTime: Date, tripsNumber: number, tripsNumberIsForced: boolean) => void,
    displayError: (message: string) => void,
    handleDeliveryTripsCancellation: () => void
}

const ExpeditionScheduler = (props: ExpeditionSchedulerProps): JSX.Element => {

    const schedulerRef = props.schedulerRef;
    const frFR = require('../../../config/custom-locale-fr');
    let schedulerConfig: Partial<BryntumSchedulerProps> = {};

    useEffect(() => {
        const scheduler: Scheduler = schedulerRef.current.instance;
        LocaleManager.locale = frFR;

        switch (props.role) {
            case 'ADM':
            case 'LOG':
                configureDragHelper(scheduler);
                manageGridColumns(scheduler);
                manageGridSize(scheduler);
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        const scheduler: Scheduler = schedulerRef.current.instance;
        //On mets tout à plat avant de faire le setState car le scheduler prend en compte dans les nouvelles données, les camions qui étaient collapsés 
        scheduler.features.group?.expandAll();
        scheduler.resourceStore.data = props.resources;
        scheduler.eventStore.data = props.events;
    }, [props.events, props.resources]);

    useEffect(() => {
        props.handleEventsVersionChanged();
    }, [props.eventsVersion]);

    const configureDragHelper = (scheduler: Scheduler): void => {
        const dragHelper = new DragHelper({
            mode: 'translateXY',
            targetSelector: '.drag',
            cloneTarget: true,
            dropTargetSelector: '.b-timeline-subgrid'
        });

        dragHelper.on({
            dragstart: ({ context, event }) => {
                const proxy = context.element;
                proxy.classList.add('b-sch-event');
                proxy.innerText = context.grabbed.id;
                proxy.style.width = "300px";
                proxy.style.backgroundColor = "#FFF";
                proxy.style.color = "#000";
                proxy.style.border = "1px solid #000";
            },
            drop: ({ context, event }) => {
                if (scheduler.resolveResourceRecord) {
                    const ressourceModel = scheduler.resolveResourceRecord(context.target, [event.offsetX, event.offsetY]);
                    if (ressourceModel !== null) {
                        if (ressourceModel.getData('isCanceled')) {
                            const message = BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'ActionCannotBePerformedOnACanceledPlanningVehicle' });
                            props.displayError(message);
                            return;
                        }

                        const vehiclePlanningId = ressourceModel.id.toString();
                        const deliveryTime = scheduler.getDateFromCoordinate(DomHelper.getTranslateX(context.element), 'round', false);
                        const transportflow: TransportFlowLightModelExtended = JSON.parse(context.grabbed.dataset.transportflow);
                        const transportRequestId = transportflow.transportRequestId;
                        const transportFlowId = transportflow.transportFlowId;
                        const tripsNumber = transportflow.tripsNumber;
                        const tripsNumberIsForced = transportflow.tripsNumberIsForced;

                        props.createTripFromFlow(vehiclePlanningId, transportFlowId, transportRequestId, deliveryTime, tripsNumber, tripsNumberIsForced);
                    }
                }
            }
        });
    }

    const manageGridColumns = (scheduler: Scheduler): void => {
        (scheduler.columns as ColumnStore).on({
            change: ({ action, parent }) => {
                if (action == "add") {
                    const columns: Array<ColumnConfig> = parent.children.filter(x => x.field != '_timeAxis2');
                    props.onReorderPlanningVehiclesGridColumnHandler(columns);
                }
            }
        });
    }

    const manageGridSize = (scheduler: Scheduler): void => {
        scheduler.getSubGrid('locked').on({
            resize: ({ width }) => {
                //On met 40 pour garder un minimun pour afficher le splitter
                const newWidth = width > 40 ? width : 40;
                props.onResizePlanningVehiclesGridColumnHandler(newWidth);
            }
        });
    }

    const customPreset = new ViewPreset({
        id: 'customPreset',
        tickWidth: 100,
        shiftIncrement: 1,
        shiftUnit: 'day',
        defaultSpan: 23,
        timeResolution: {
            unit: 'minute',
            increment: 15
        },
        headers: [
            {
                unit: 'day',
                dateFormat: 'DD/MM/YYYY'
            },
            {
                unit: 'hour',
                dateFormat: 'HH:mm'
            }
        ]
    });

    const buildLogisticianScheduler = (): void => {
        schedulerConfig = {
            viewPreset: customPreset,
            rowHeight: props.rowHeight,
            autoHeight: false,
            barMargin: props.barMargin,
            columns: props.columns,
            createEventOnDblClick: false,
            multiEventSelect: true,
            enableDeleteKey: false,
            enableEventAnimations: false,
            useInitialAnimation: false,
            subGridConfigs: props.subGridConfigs,
            eventBodyTemplate: props.eventBodyTemplate,
            selectionMode: { row: true, cell: false, multiSelect: false, checkbox: false },
            features: {
                timeRanges: { showCurrentTimeLine: true },
                stripe: false,
                filter: false,
                eventTooltip: false,
                scheduleTooltip: false,
                eventDragSelect: true,
                eventDragCreate: false,
                eventCopyPaste: false,
                eventEdit: false,
                rowCopyPaste: false,
                cellMenu: {
                    items: {
                        removeRow: false,
                        removeVehicle: {
                            icon: 'b-fa b-fa-trash',
                            weight: 200,
                            disabled: false,
                            onItem: (eventRecord) => {
                                const planningParsed: PlanningVehicleTripsLightModelExtended = JSON.parse(eventRecord.record?.json);
                                props.handleRemovePlanningVehicle(planningParsed.planningVehicleId, planningParsed.planningId, planningParsed.hasOrderLine,
                                    planningParsed.hasAtLeastOneDeliveryTrip);
                            }
                        },
                        moveTrips: {
                            icon: 'b-fa b-fa-arrows-alt-v',
                            weight: 200,
                            disabled: false,
                            onItem: (eventRecord) => {
                                const planningParsed: PlanningVehicleTripsLightModelExtended = JSON.parse(eventRecord.record?.json);
                                props.handleMoveTripsToNewPlanningVehicle(planningParsed.id);
                            }
                        },
                        duplicatePlanningVehicle: {
                            html: `<div id="duplicate-flow">
                                Dupliquer ce type de véhicule et tour(s) associé(s)
                            </div>`,
                            icon: 'b-fa b-fa-copy',
                            weight: 200,
                            disabled: false,
                            onItem: (eventRecord) => {
                                props.handleClickOpenDuplicatePlanningVehicle('Dupliquer ce type de véhicule et tour(s) associé(s)');
                            }
                        },
                        removePlanningVehicleTransporter: {
                            weight: 200,
                            disabled: false,
                            icon: 'b-fa b-fa-truck-moving',
                            onItem: (eventRecord) => {
                                const planningParsed: PlanningVehicleTripsLightModelExtended = JSON.parse(eventRecord.record?.json);
                                props.handleRemovePlanningVehicleTransporter(planningParsed.id);
                            }
                        },
                        removeContractedTransporterVehicleType: {
                            weight: 200,
                            disabled: false,
                            icon: 'b-fa b-fa-file-signature',
                            onItem: (eventRecord) => {
                                const planningParsed: PlanningVehicleTripsLightModelExtended = JSON.parse(eventRecord.record?.json);
                                props.handleRemoveContractualPurchasePriceFromPlanningVehicle(planningParsed.id);
                            }
                        },
                        cancelReinstatePlanningVehicle: {
                            weight: 200,
                            disabled: false,
                            icon: 'b-fa b-fa-times',
                            onItem: (eventRecord) => {
                                const planningParsed: PlanningVehicleTripsLightModelExtended = JSON.parse(eventRecord.record?.json);
                                props.handleCancelReinstatePlanningVehicle(planningParsed);
                            }
                        }
                    },
                    processItems({ record, items }) {
                        //Si on click sur le bouton droit par exemple sur l'entête des groupes
                        const planningParsed: PlanningVehicleTripsLightModelExtended = JSON.parse(record?.json);
                        if (!planningParsed.planningVehicleId)
                            return false;

                        return true;
                    }
                },
                scheduleMenu: {
                    items: {
                        addEvent: false
                    }
                },
                timeAxisHeaderMenu: {
                    processItems({ items }) {
                        //ne pas afficher active dateRange dans HeaderMenu
                        const item = "dateRange";
                        delete items[item];
                    }
                },
                eventMenu: {
                    items: {
                        editEvent: false,
                        deleteEvent: false,
                        unassignEvent: false,
                        updateFlow: {
                            html:
                                `<div id="edit-flow">
                                Modifier le flux du trajet
                            </div>`,
                            icon: 'b-fa b-fa-pen',
                            cls: 'b-separator',
                            onItem: ({ eventRecord }) => {
                                const eventModel: EventModelData = JSON.parse(eventRecord?.json);
                                props.editFlow(eventModel.transportFlowId, eventModel.transportFlowBusinessId);
                            }
                        },
                        duplicateFlow: {
                            html:
                                `<div id="duplicate-flow">
                                Dupliquer le flux du trajet
                            </div>`,
                            icon: 'b-fa b-fa-clone',
                            onItem: ({ eventRecord }) => {
                                const eventModel: EventModelData = JSON.parse(eventRecord?.json);
                                props.duplicateFlow(eventModel.transportFlowId);
                            }
                        },
                        updateSpecificPriceOfDeliveryTrips: {
                            html:
                                `<div id="update-specific-price">
                                Modifier tarification spécifique d'achat du/des tour(s) sélectionné(s)
                            </div>`,
                            icon: 'b-fa b-fa-pen',
                            cls: 'b-separator',
                            onItem: ({ eventRecord }) => {
                                props.handleUpdateSpecificPriceOfDeliveryTrips();
                            }
                        },
                        updateTripStatus: {
                            html:
                                `<div id="edit-trip">
                                Changer statut tour(s) sélectionné(s)
                            </div>`,
                            icon: 'b-fa b-fa-pen',
                            menu:
                                [
                                    {
                                        weight: 200,
                                        flex: 1,
                                        margin: 0,
                                        text: 'Générique'
                                        , onItem: () => {
                                            props.handleAssignDeliveryTripStatus(DeliveryTripStatus.transportNeeded);
                                        }
                                    },
                                    {
                                        weight: 200,
                                        flex: 1,
                                        margin: 0,
                                        text: 'Planifié'
                                        , onItem: () => {
                                            props.handleAssignDeliveryTripStatus(DeliveryTripStatus.transportPlanned);
                                        }
                                    },
                                    {
                                        weight: 200,
                                        flex: 1,
                                        margin: 0,
                                        text: 'Confirmé'
                                        , onItem: () => {
                                            props.handleAssignDeliveryTripStatus(DeliveryTripStatus.transportConfirmed);
                                        }
                                    },
                                    {
                                        weight: 200,
                                        flex: 1,
                                        margin: 0,
                                        text: 'En cours'
                                        , onItem: () => {
                                            props.handleAssignDeliveryTripStatus(DeliveryTripStatus.inProgress);
                                        }
                                    },
                                    {
                                        weight: 200,
                                        flex: 1,
                                        margin: 0,
                                        text: 'Terminé'
                                        , onItem: () => {
                                            props.handleAssignDeliveryTripStatus(DeliveryTripStatus.finished);
                                        }
                                    },
                                    {
                                        weight: 200,
                                        text: 'Annulé',
                                        flex: 1,
                                        margin: 0
                                        , onItem: () => {
                                            props.handleDeliveryTripsCancellation();
                                        }
                                    }]
                        },
                        attachZephyrReturnManually: {
                            icon: 'b-fa b-fa-pen'
                            , html:
                                `<div id="attach-zephyr-return-manually">
                                Rattacher retour Zephyr manuellement
                            </div>`,
                            onItem: ({ eventRecord }) => {
                                const eventModel: EventModelData = JSON.parse(eventRecord?.json);
                                props.attachZephyrReturnManually(eventModel.transportFlowId);
                            }
                        },
                        deleteTrips: {
                            icon: 'b-fa b-fa-trash', weight: 200
                            , html:
                                `<div id="remove-trips">
                                Supprimer tour(s) sélectionné(s)
                            </div>`
                            , onItem: () => {
                                props.removeSelectedTrips(false);
                            }
                        },
                        deleteTripsAndHideFlow: {
                            icon: 'b-fa b-fa-trash', weight: 200
                            , html:
                                renderToString(<div id="remove-trips-and-hide-flow">
                                    <FontAwesomeIcon icon={faEyeSlash} style={{ marginRight: '10px', marginTop: '2px' }} />
                                    Supprimer tour(s) sélectionné(s) et masquer flux
                                </div>)
                            , onItem: () => {
                                props.removeSelectedTrips(true);
                            }
                        }
                    }
                },
                eventResize: {
                    validatorFn: (context) => {
                        const originalDuration = 15;
                        const diffInMinutes = getMinuteDifference(context.startDate, context.endDate)
                        if (originalDuration > diffInMinutes) {
                            return false;
                        }
                        return true;
                    }
                }
            },
            listeners: {
                eventMenuBeforeShow: (event: any, e: any) => {
                    const scheduler: Scheduler = schedulerRef.current.instance;
                    //Permettre de séléctionner lors d'un click droit le tour 
                    if (!event.event.ctrlKey) {
                        scheduler.selectEvent(event.eventRecord, false);
                    } else {
                        //préserver la persistence de sélection
                        scheduler.selectEvent(event.eventRecord, true);
                    }

                    let itemFlow = event.items['updateFlow'];
                    itemFlow = props.refreshMenuItemEditFlow(itemFlow, event.eventRecord?.data);

                    let itemFlowToDuplicate = event.items['duplicateFlow'];
                    itemFlowToDuplicate = props.refreshMenuItemDuplicateFlow(itemFlowToDuplicate);

                    const itemUpdateSpecificPrice = event.items['updateSpecificPriceOfDeliveryTrips'];
                    itemFlow = props.refreshMenuItemUpdateSpecificPriceFromDeliveryTrips(itemUpdateSpecificPrice);

                    let itemDeleteTrips = event.items['deleteTrips'];
                    itemDeleteTrips = props.refreshMenuItemRemoveTrips(itemDeleteTrips, false);

                    let itemDeleteTripsAndHideFlow = event.items['deleteTripsAndHideFlow'];
                    itemDeleteTripsAndHideFlow = props.refreshMenuItemRemoveTrips(itemDeleteTripsAndHideFlow, true);

                    let itemTripStatus = event.items['updateTripStatus'];
                    itemTripStatus = props.refreshMenuItemEditTripStatus(itemTripStatus);

                    let itemAttachZephyrReturnManually = event.items['attachZephyrReturnManually'];
                    itemAttachZephyrReturnManually = props.refreshAttachZephyrReturnManually(itemAttachZephyrReturnManually);
                },
                cellMenuBeforeShow: (event: any) => {
                    event.items.removeVehicle = props.refreshMenuItemRemovePlanningVehicle(event.items?.removeVehicle, event.record?.data);
                    event.items.moveTrips = props.refreshMenuItemMoveSelectedTrips(event.items?.moveTrips, event.record?.data);
                    event.items.duplicatePlanningVehicle = props.refreshMenuItemDuplicatePlanningVehicle(event.items?.duplicatePlanningVehicle, event.record?.data);
                    event.items.removePlanningVehicleTransporter = props.refreshMenuItemRemovePlanningVehicleTransporter(event.items?.removePlanningVehicleTransporter, event.record?.data);
                    event.items.removeContractedTransporterVehicleType = props.refreshMenuItemRemoveContractualPurchasePriceFromPlanningVehicle(event.items?.removeContractedTransporterVehicleType, event.record?.data);
                    event.items.cancelReinstatePlanningVehicle = event.record?.data?.isCanceled ? props.refreshMenuItemReinstatePlanningVehicle(event.items?.cancelReinstatePlanningVehicle) : props.refreshMenuItemCancelPlanningVehicle(event.items?.cancelReinstatePlanningVehicle);
                },
                eventSelectionChange: (event: any) => {
                    const resources = map(event.selection, "data");
                    props.eventSelectionHandler(resources);
                },
                selectionchange: (event: any) => {
                    //Dans le cas d'une sélection manuel avec action === "select" ou via le composant avec selected et deselected à [] mais selection remplie
                    if (event.mode === "row" && (event.action === "select"
                        || (event.selected.length == 0 && event.deselected.length == 0 && event.selection.length > 0))) {
                        props.rowGridSelectionHandler(event.selection[0].data);
                    }
                    //Dans le cas d'une désélection manuel avec action === "deselect" et que ce n'est pas via le composant avec selected et deselected à [] et selection remplie
                    if (event.mode === "row" && event.action === "deselect" &&
                        !(event.selected.length == 0 && event.deselected.length == 0 && event.selection.length > 0)) {
                        props.rowGridSelectionHandler(null);
                    }
                },
                eventClick: ({ event, eventRecord }) => {
                    if (event && !event.ctrlKey) {
                        props.showEventDetail(event, eventRecord);
                    }
                },
                eventDrop: (event: any) => {
                    if (props.handleTripsDropToNewLoadingTime != null) {
                        const scheduler: Scheduler = schedulerRef.current.instance;
                        try {
                            scheduler.readOnly = true;
                            props.handleTripsDropToNewLoadingTime(event);
                        }
                        catch {
                            scheduler.readOnly = false;
                        }
                    }
                },
                eventResizeEnd: (event: any) => {
                    if (event.eventRecord !== undefined && event.eventRecord !== null && event.changed === true) {
                        const scheduler: Scheduler = schedulerRef.current.instance;
                        try {
                            scheduler.readOnly = true;

                            const transportRequestId: string = event.eventRecord.data.transportRequestId;
                            const deliveryTripId: number = event.eventRecord.data.deliveryTripId;
                            const newDeliveryTime = event.eventRecord.data.endDate as Date;

                            if (newDeliveryTime) {
                                props.onResizeTripToNewDeliveryTime(transportRequestId, deliveryTripId, newDeliveryTime);
                            }
                            else {
                                scheduler.readOnly = false;
                            }
                        }
                        catch {
                            scheduler.readOnly = false;
                        }
                    }
                },
                cellClick: ({ record }) => {
                    props.handleCellClick(record);
                }
            }
        };
    }

    function getMinuteDifference(startDate: Date, endDate: Date): number {
        const millisecondsPerMinute = 1000 * 60;
        const startTimestamp = startDate.getTime();
        const endTimestamp = endDate.getTime();
        const differenceInMilliseconds = endTimestamp - startTimestamp;
        const differenceInMinutes = differenceInMilliseconds / millisecondsPerMinute;
        return differenceInMinutes;
    }

    const buildProductionForemanScheduler = (): void => {
        configureReadOnlyScheduler();
    }

    const buildDispatcherScheduler = (): void => {
        configureReadOnlyScheduler();
    }

    const configureReadOnlyScheduler = (): void => {
        schedulerConfig = {
            viewPreset: customPreset,
            rowHeight: props.rowHeight,
            autoHeight: false,
            barMargin: props.barMargin,
            columns: props.columns,
            createEventOnDblClick: false,
            multiEventSelect: true,
            enableDeleteKey: false,
            subGridConfigs: props.subGridConfigs,
            eventBodyTemplate: props.eventBodyTemplate,
            selectionMode: { row: true, cell: false, multiSelect: false, checkbox: false },
            features: {
                timeRanges: { showCurrentTimeLine: true },
                stripe: false,
                filter: false,
                eventTooltip: false,
                scheduleTooltip: false,
                eventDragSelect: true,
                eventDragCreate: false,
                eventCopyPaste: false,
                eventEdit: false,
                rowCopyPaste: false,
                scheduleMenu: {
                    items: {
                        addEvent: false
                    }
                },
                timeAxisHeaderMenu: {
                    processItems({ items }) {
                        //ne pas afficher active dateRange dans HeaderMenu
                        const item = "dateRange";
                        delete items[item];
                    }
                },
                // adjust deleteTask menu item
                eventMenu: {
                    items: {
                        deleteEvent: false,
                        unassignEvent: false,
                        editEvent: false
                    }
                }
            },
            listeners: {
                eventClick: ({ event, eventRecord }) => {
                    if (event && !event.ctrlKey) {
                        props.showEventDetail(event, eventRecord);
                    }
                }
            }
        };
    }

    switch (props.role) {
        case 'PRD':
            buildProductionForemanScheduler();
            break;
        case 'DIS':
            buildDispatcherScheduler();
            break;
        case 'ADM':
        case 'LOG':
            buildLogisticianScheduler();
            break;
        default:
            console.error('The scheduler was not intended for the "' + props.role + '" profile.');
            break;
    }

    return <BryntumScheduler {...schedulerConfig} ref={schedulerRef} />;
};

export default ExpeditionScheduler;
