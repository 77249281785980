import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import Select from 'react-select';
import { CalendarComponent } from 'src/shared/components/Common/CalendarComponent';
import { DateRange } from 'src/shared/models/DateRange';
import { LogisticsUnitChoice } from 'src/shared/models/LogisticsUnitChoice';
import { LogisticsUnitSelectModel } from '../../../../shared/models/LogisticsUnitSelectModel';

interface ContentHeaderComponentProps {
    logisticsUnits: Array<LogisticsUnitChoice>,
    date: DateRange,
    selectedLogisticsUnit: LogisticsUnitSelectModel,
    handleChangeDateRange: (data: DateRange) => void,
    handleLogisticsUnitChange: (e: LogisticsUnitSelectModel) => void,
    handleOpenCloseCnrGasoilIndexesDrawer: () => void
}

export const ContentHeaderComponent = (props: ContentHeaderComponentProps) => {
    const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);

    const handleIsDateRangeValidChanged = (isValid: boolean): void => {
        setIsDateRangeValid(isValid);
    }

    return (
        <Box display="flex" flexDirection="row" flex="wrap" justifyContent="space-between" className="content-header-gasole" alignItems="center">
            <Box display="flex" flexDirection="row" flex="wrap" alignItems="center">
                <Box>
                    <Select
                        className="logistics-unit-select"
                        id="vehicle-type"
                        isClearable
                        placeholder="Zone logistique"
                        menuPosition="fixed"
                        menuShouldBlockScroll={true}
                        isDisabled={props.logisticsUnits.length < 2}
                        options={props.logisticsUnits.map(x => { return { label: x.label, value: x.logisticsUnitId } })}
                        value={props.selectedLogisticsUnit}
                        onChange={(e) => props.handleLogisticsUnitChange(e)}
                    />
                </Box>
                <Box minWidth="450px" pl={6}>
                    <CalendarComponent startDate={props.date.start} endDate={props.date.end} calendarId='referential-gazole-daterange-picker' isValid={isDateRangeValid}
                        handleIsValidChanged={handleIsDateRangeValidChanged} handlerFromChildToParent={props.handleChangeDateRange} />
                </Box>
            </Box>
            <Box>
                <Button onClick={props.handleOpenCloseCnrGasoilIndexesDrawer} className="cm-button">Cm</Button>
            </Box>
        </Box>
    );
}
