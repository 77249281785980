import { Box } from "@mui/material";
import { DragAndDrop } from "@progress/kendo-react-common";
import React from "react";
import { UserSignatureField } from "../models/UserSignatureField";
import { DraggableListItem } from "./DraggableListItem";
import { DroppableList } from "./DroppableList";

export const DragContext = React.createContext<
    [null | { element: React.RefObject<HTMLElement | null>; data: UserSignatureField }, Function]
>([null, () => { }]);

export const DropContext = React.createContext<
    [null | { element: React.RefObject<HTMLElement | null>; data: UserSignatureField }, Function]
>([null, () => { }]);

export const DirectionContext = React.createContext<
    ["before" | "after", Function]
>(["before", () => { }]);

interface UserSignatureFieldsComponentProps {
    signatureFieldsData: UserSignatureField[],
    updateSignatureFieldsAndHtml: (fields: UserSignatureField[]) => void,
    handleDeleteField: (id: number) => void,
    handleIsBoldChanged: (id: number) => void,
    handleIsItalicChanged: (id: number) => void,
    handleTextChanged: (id: number, value: string) => void,
    handleBlurText: (id: number, value: string) => void
}

export const UserSignatureFieldsComponent = (props: UserSignatureFieldsComponentProps): JSX.Element => {

    const dropTimeout = React.useRef<number>();

    const [drag, setDrag] = React.useState<{
        element: React.RefObject<HTMLElement | null>;
        data: UserSignatureField;
    } | null>(null);

    const [drop, setDrop] = React.useState<{
        element: React.RefObject<HTMLElement | null>;
        data: UserSignatureField;
    } | null>(null);

    const [direction, setDirection] = React.useState<"before" | "after">("before");

    const handleDropChange = React.useCallback(
        (
            newDrop: {
                element: React.RefObject<HTMLElement | null>;
                data: UserSignatureField;
            } | null
        ) => {
            if (!newDrop) {
                dropTimeout.current = window.setTimeout(() => {
                    setDrop(newDrop);
                }, 50);
            } else {
                clearTimeout(dropTimeout.current);
                setDrop(newDrop);
            }
        },
        []
    )

    const handleItemDrop = React.useCallback(() => {
        if (!drag || !drop) {
            return;
        }
        const newData = props.signatureFieldsData.slice();
        const oldIndex = newData.findIndex((d) => d.id === drag.data.id);
        const dataItem = newData[oldIndex];

        if (dataItem) {
            newData.splice(oldIndex, 1);

            const newIndex = inRange(
                0,
                newData.length,
                newData.findIndex((d) => d.id === drop.data.id) +
                (direction === "after" ? 1 : 0)
            );

            newData.splice(newIndex, 0, dataItem);

            props.updateSignatureFieldsAndHtml(newData);
        }
    }, [props.signatureFieldsData, direction, drag, drop])

    const inRange = (min, max, index) => {
        return Math.max(min, Math.min(max, index));
    }

    return (
        <DragContext.Provider value={[drag, setDrag]}>
            <DropContext.Provider value={[drop, handleDropChange]}>
                <DirectionContext.Provider value={[direction, setDirection]}>
                    <DragAndDrop>
                        <Box className="fields">
                            {props.signatureFieldsData.length > 0 &&
                                <DroppableList>
                                    {props.signatureFieldsData.map((item) => (
                                        <DraggableListItem
                                            key={item.id}
                                            onDrop={handleItemDrop}
                                            data={item}
                                            handleDeleteField={props.handleDeleteField}
                                            handleIsBoldChanged={props.handleIsBoldChanged}
                                            handleIsItalicChanged={props.handleIsItalicChanged}
                                            handleTextChanged={props.handleTextChanged}
                                            handleBlurText={props.handleBlurText}
                                        >
                                        </DraggableListItem>
                                    ))}
                                </DroppableList>
                            }
                        </Box>
                    </DragAndDrop>
                </DirectionContext.Provider>
            </DropContext.Provider>
        </DragContext.Provider>
    );
}