import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortDescriptor } from '@progress/kendo-data-query';
import React from 'react';

interface EditableHeaderProps {
    title: string,
    field: string,
    sort?: SortDescriptor[],
    _handleSortEditableColumnChange?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, field: string) => void,
}

export const EditableHeaderComponent = (props: EditableHeaderProps) => {

    let sortIconClass = "";
    let sortIndex = -1;
    if (props.sort?.length > 0) {
        const currentSortIndex: number = props.sort.findIndex(x => x.field == props.field);
        if(currentSortIndex !== -1){
            sortIconClass = props.sort[currentSortIndex].dir === "asc" ?  "k-icon k-i-sort-asc-sm" : "k-icon k-i-sort-desc-sm";
            sortIndex = currentSortIndex;
        }
    }

    return (
        <span className="editable-header" onClick={(e) => props.sort ? props._handleSortEditableColumnChange(e, props.field) : null}>
            <span className="editable-header-title">{props.title}</span>
            {sortIconClass !== "" &&
                <span className={sortIconClass}></span>
            }
            {sortIndex !== -1 &&
                <span className="k-sort-order">{(sortIndex + 1)}</span>
            }
            <FontAwesomeIcon size="1x" className='icon-pen-blue' icon={faPen} />
        </span>
    );
}
