import { GridCellProps } from '@progress/kendo-react-grid';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import React from 'react';

export const CustomExpectedQuantityCellComponent = (props: GridCellProps): JSX.Element => {
    const dataValue: number = props.dataItem.expectedQuantity != null ? props.dataItem.expectedQuantity : null;
    let defaultRendering: JSX.Element = null;

    const handleChange = (props: GridCellProps, event: NumericTextBoxChangeEvent): void => {
        props.dataItem.inEdit = props.field;
        props.dataItem.expectedQuantity = event.target.value != null && event.target.value >= 0 ? event.target.value : props.dataItem.expectedQuantity;
    }

    if (!props.dataItem.inEdit || props.dataItem.inEdit !== props.field) {
        defaultRendering = (
            <td>{!dataValue ? '' : dataValue}</td>
        );
    } else {
        defaultRendering = (
            <td>
                <NumericTextBox
                    defaultValue={dataValue}
                    min={0}
                    width={110}
                    disabled={props.dataItem.planningVehicleIsCanceled || props.dataItem.isManuallyAttachedToZephyr}
                    onChange={(event) => handleChange(props, event)} />
            </td>
        );
    }
    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}