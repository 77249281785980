import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select, { ActionMeta, components, OnChangeValue, OptionProps } from 'react-select';
import Utilities from 'src/utils/Utilities';
import { UpdateUserRequestArgs } from '../services/dataContracts/controller/UpdateUserRequestArgs';
import { InternalContactLightModel } from '../services/dataContracts/queryStack/InternalContactLightModel';
import { ProductionSiteLightModel } from "../services/dataContracts/queryStack/ProductionSiteLightModel";
import { InternalContactsReferentialApiClient } from "../services/InternalContactsReferentialApiClient";

interface EditContactComponentProps {
    userSelected: InternalContactLightModel,
    handleEditContactClick: (requestArgs: UpdateUserRequestArgs) => void
}

const Option = (props: OptionProps<ProductionSiteLightModel>) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.data.label}</label>
            </components.Option>
        </div>
    );
};

export const EditContactComponent = (props: EditContactComponentProps): JSX.Element => {
    const [userRole, setUserRole] = useState<string>(props.userSelected.roleId);
    const [phoneNumber, setPhoneNumber] = useState<string>(props.userSelected.phoneNumber);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(props.userSelected.phoneNumber ? Utilities.isValidPhoneNumber(props.userSelected.phoneNumber) : false);
    const [productionSitesChoices, setProductionSitesChoices] = useState<ProductionSiteLightModel[]>([]);
    const [selectedProductionSitesChoices, setSelectedProductionSitesChoices] = useState<readonly ProductionSiteLightModel[]>([]);
    const [canSave, setCanSave] = useState<boolean>();
    const [userProductionSitesChoices, setUserProductionSitesChoices] = useState<ProductionSiteLightModel[]>([]);
    const [productionSitesChoiceEnabled, setProductionSitesChoiceEnabled] = useState<boolean>();

    const handleChangePhoneNumber = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const value = event.target.value;
        setPhoneNumber(value);

        if (!value || !Utilities.isValidPhoneNumber(value))
            setIsPhoneNumberValid(false);
        else
            setIsPhoneNumberValid(true);
    }

    const handleChangeUserRole = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
        setUserRole(value);
    }

    useEffect(() => {
        setProductionSitesChoiceEnabled(userRole === "PRD");
    }, [userRole]);

    useEffect(() => {
        Promise.all([
            InternalContactsReferentialApiClient.GetUser(props.userSelected.contactId),
            InternalContactsReferentialApiClient.GetManagedProductionSitesChoices(props.userSelected.contactId)
        ]
        ).then(response => {
            const userProductionSitesChoices = response[0].data.managedProductionSites;
            const productionSitesChoices = response[1].data;

            userProductionSitesChoices
                .filter(ups => !productionSitesChoices.some(x => x.productionSiteId === ups.productionSiteId))
                .forEach(ups => { productionSitesChoices.push(ups); });

            const sortedProductionSitesChoices = productionSitesChoices.sort((a, b) => a.label > b.label ? 1 : -1);

            setUserProductionSitesChoices(userProductionSitesChoices);
            setSelectedProductionSitesChoices(userProductionSitesChoices);
            setProductionSitesChoices(sortedProductionSitesChoices);
        });
    }, []);

    useEffect(() => {
        const productionSitesChanged = productionSitesChoiceEnabled
            && (userProductionSitesChoices.length !== selectedProductionSitesChoices.length
                || userProductionSitesChoices.some(x => !selectedProductionSitesChoices.some(y => y.productionSiteId === x.productionSiteId)));

        const canSave = isPhoneNumberValid &&
            (userRole !== props.userSelected.roleId
                || phoneNumber !== props.userSelected.phoneNumber
                || productionSitesChanged);

        setCanSave(canSave);
    }, [userRole, phoneNumber, isPhoneNumberValid, selectedProductionSitesChoices, productionSitesChoiceEnabled]);

    const onChange = (value: OnChangeValue<ProductionSiteLightModel, true>,
        actionMeta: ActionMeta<ProductionSiteLightModel>): void => {
        setSelectedProductionSitesChoices(value);
    }

    return (
        <Box display="flex" flexDirection="column" className="edit-content">
            <Box className="user-name">
                {`${props.userSelected.lastName} ${props.userSelected.firstName}`}
            </Box>
            <Box display="flex" flexDirection="row" pl={0.5} pt={0.5} pb={1}>
                <FormControl component="fieldset">
                    <RadioGroup value={userRole} onChange={handleChangeUserRole}>
                        <FormControlLabel className="user-role-label" value="CCH" control={<Radio color="primary" />} label="Chef de chantier"/>
                        <FormControlLabel className="user-role-label" value="CTX" control={<Radio color="primary" />} label="Conducteur de travaux"/>
                        <FormControlLabel className="user-role-label" value="PRD" control={<Radio color="primary" />} label="Chef de production"/>
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box pl={"6px"}>
                <TextField error={!isPhoneNumberValid} label="Téléphone portable" value={phoneNumber ? phoneNumber : ''} className="phone-number"
                           helperText={!isPhoneNumberValid ? "Le numéro n'est pas valide." : ''} variant="outlined" onChange={handleChangePhoneNumber}/>
            </Box>

            {productionSitesChoiceEnabled ? 
            <Box pl={0.5} pt={0.5} pb={1} >
                <Select
                    isMulti
                    placeholder={<span>{selectedProductionSitesChoices.length === 1
                        ? selectedProductionSitesChoices[0].label
                        : (selectedProductionSitesChoices.length > 1
                            ? `Unités de Production (${selectedProductionSitesChoices.filter(x => x.productionSiteId !== "-1").length})`
                                : "Unités de Production")}</span>}
                    noOptionsMessage={_ => <span>Aucune donnée</span>}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.productionSiteId}
                    value={selectedProductionSitesChoices}
                    options={productionSitesChoices}
                    isClearable={true}
                    menuPosition="fixed"
                    menuShouldBlockScroll={true}
                    closeMenuOnSelect={false}
                    onChange={onChange}
                    controlShouldRenderValue={false}
                    components={{ Option }}
                    hideSelectedOptions={false}
                    blurInputOnSelect={false}
                    menuPlacement="auto"
                    styles={{
                            menu: (css) => ({
                                ...css,
                                width: "max-content"
                            }),
                            placeholder: (provided, state) => ({
                                ...provided,
                                textOverflow: "ellipsis",
                                maxWidth: "95%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                display: "initial"
                            })
                        }}
                />
                </Box> : null}
            <Box display="flex" flexDirection="row" justifyContent="flex-end" pt={2} pl={2}>
                <Button variant="contained" color="primary" title="Modifier l'utilisateur" onClick={() => props.handleEditContactClick({
                    userName: props.userSelected.contactId,
                    userRole: userRole,
                    phoneNumber: phoneNumber,
                    managedProductionSites: selectedProductionSitesChoices.map(x => x.productionSiteId)
                })}
                        disabled={!canSave}>
                    Modifier
                </Button>
            </Box>
        </Box>
    );
}
