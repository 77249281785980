import { Box, Button, Drawer } from '@mui/material';
import { NumericTextBox, NumericTextBoxBlurEvent, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ToastService from 'src/ToastService';
import { WebAppActionResult } from '../../../../shared/models/WebAppActionResult';
import BusinessErrors from '../../../../utils/BusinessErrors';
import { CnrGasoilIndexModel } from '../models/CnrGasoilIndexModel';
import { AddCnrGasoilIndexRequestArgs } from '../services/dataContracts/controller/AddCnrGasoilIndexRequestArgs';
import { UpdateCnrGasoilIndexRequestArgs } from '../services/dataContracts/controller/UpdateCnrGasoilIndexRequestArgs';
import { CnrGasoilIndexLightModel } from '../services/dataContracts/queryStack/CnrGasoilIndexLightModel';
import { DieselIndexesReferentialApiClient } from '../services/DieselIndexesReferentialApiClient';

interface CnrGasoilIndexesDrawerComponentProperties {
    isCnrGasoilIndexesDrawerOpened: boolean,
    startDate: Date,
    role: string
}

export const CnrGasoilIndexesDrawerComponent = (props: CnrGasoilIndexesDrawerComponentProperties): JSX.Element => {
    const [cnrGasoilIndexesCalendar, setCnrGasoilIndexesCalendar] = useState<CnrGasoilIndexModel[]>([]);
    const [cnrGasoilIndexes, setCnrGasoilIndexes] = useState<CnrGasoilIndexLightModel[]>([]);
    const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(false);
    const [cnrIndexToUpdate, setCnrIndexToUpdate] = useState<UpdateCnrGasoilIndexRequestArgs>(null);

    useEffect(() => {
        const calendar: CnrGasoilIndexModel[] = initialiseCalendar();
        getCnrGasoilIndexes(calendar);
    }, []);

    const initialiseCalendar = (): CnrGasoilIndexModel[] => {
        const date: Date = new Date(props.startDate); //initialiser la date depuis la date du jour (start Date)
        date.setMonth(date.getMonth() - 1); // décaler d'un mois en arrière (m - 1)

        const calendar: CnrGasoilIndexModel[] = [];
        //boucler sur 12 mois 
        for (let i = 0; i <= 11; i++) {
            const fullYear: number = date.getFullYear();
            const month: number = date.getMonth();
            const monthLabel = date.toLocaleString('default', { month: 'long' });
            const item: CnrGasoilIndexModel = {
                id: (fullYear * 100) + month + 1, // identifiant index ((année * 100) + mois + 1)
                label: monthLabel + ' ' + fullYear,
                startDate: Date.getFirstOfMonth(date),
                endDate: Date.getLastOfMonth(date),
                value: null
            };
            calendar.push(item);
            date.setMonth(date.getMonth() - 1); // décaler d'un mois en arrière (m - 1)
        }

        return calendar;
    }

    const mapCalendar = (calendar: CnrGasoilIndexModel[], cnrGasoilIndexes: CnrGasoilIndexLightModel[]): void => {
        cnrGasoilIndexes.forEach((element: CnrGasoilIndexLightModel) => {
            const item: CnrGasoilIndexModel = calendar.find(x => x.id === element.cnrGasoilIndexId);
            item.value = element.indexValue;
        });

        setCnrGasoilIndexesCalendar(calendar);
        setCnrGasoilIndexes(cnrGasoilIndexes);
    }

    const handleChangeIndex = (event: NumericTextBoxChangeEvent, id: number): void => {
        const index: number = cnrGasoilIndexesCalendar.findIndex(x => x.id === id);
        cnrGasoilIndexesCalendar[index].value = event.target.value;
        setCnrGasoilIndexesCalendar([...cnrGasoilIndexesCalendar]);
    }

    const handleBlurIndex = (event: NumericTextBoxBlurEvent, id: number, startDate: Date): void => {
        let value: number = event.target.value;
        const item: CnrGasoilIndexLightModel = cnrGasoilIndexes.find(x => x.cnrGasoilIndexId === id);

        if (value < 0) {
            const index = cnrGasoilIndexesCalendar.findIndex(x => x.id === id);
            cnrGasoilIndexesCalendar[index].value = item.indexValue;
            value = item.indexValue;
            setCnrGasoilIndexesCalendar([...cnrGasoilIndexesCalendar]);
        }

        if (item) {
            if (item.indexValue !== value) {
                const requestArgs: UpdateCnrGasoilIndexRequestArgs = {
                    cnrGasoilIndexId: id,
                    indexValue: value
                }

                setCnrIndexToUpdate(requestArgs);
                setIsConfirmModalOpened(true);
            }
        } else {
            if (value !== null) {
                const requestArgs: AddCnrGasoilIndexRequestArgs = {
                    cnrGasoilIndexId: id,
                    firstDateOfMonth: startDate,
                    indexValue: value
                }

                DieselIndexesReferentialApiClient.AddCnrGasoilIndex(requestArgs)
                    .then((res) => {
                        handleAfterDataUpdated(res);
                    });
            }
        }
    }

    const handleConfirmUpdateCnrGasoilIndex = (): void => {
        if (cnrIndexToUpdate.indexValue === null) {
            DieselIndexesReferentialApiClient.RemoveCnrGasoilIndex(cnrIndexToUpdate.cnrGasoilIndexId)
                .then((res) => {
                    handleAfterDataUpdated(res);
                    setCnrIndexToUpdate(null);
                    setIsConfirmModalOpened(false);
                });
        } else {
            DieselIndexesReferentialApiClient.UpdateCnrGasoilIndex(cnrIndexToUpdate)
                .then((res) => {
                    handleAfterDataUpdated(res);
                    setCnrIndexToUpdate(null);
                    setIsConfirmModalOpened(false);
                });
        }
    }

    const handleAfterDataUpdated = (res: AxiosResponse<WebAppActionResult>): void => {
        const errors: string[] = BusinessErrors.Get(res.data);
        if (errors.length > 0) {
            ToastService.showErrorToast('', errors);
        }

        getCnrGasoilIndexes(cnrGasoilIndexesCalendar);
    }

    const getCnrGasoilIndexes = (calendar: CnrGasoilIndexModel[]): void => {
        DieselIndexesReferentialApiClient.GetCnrGasoilIndexes(calendar[11].startDate, calendar[0].endDate)
            .then((res) => {
                calendar.forEach((item: CnrGasoilIndexModel) => {
                    item.value = null;
                });
                mapCalendar(calendar, res.data)
            });
    }

    const handleCloseConfirmModal = (): void => {
        getCnrGasoilIndexes(cnrGasoilIndexesCalendar);
        setIsConfirmModalOpened(false);
    }

    return (
        <>
            {props.isCnrGasoilIndexesDrawerOpened ? (
                <Drawer
                    className={props.isCnrGasoilIndexesDrawerOpened ? "gasoil-indexes-drawer open" : "gasoil-indexes-drawer"}
                    variant="persistent"
                    anchor="right"
                    open={props.isCnrGasoilIndexesDrawerOpened}
                >
                    <Box>
                        {cnrGasoilIndexesCalendar.map((item: CnrGasoilIndexModel, index: number) => {
                            return (
                                <Box key={index} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className="gasoil-indexes-item">
                                    <Box>{item.label}</Box>
                                    <Box>
                                        <NumericTextBox
                                            id={`${item.id}`}
                                            value={item.value}
                                            min={0}
                                            format={{
                                                maximumFractionDigits: 4
                                            }}
                                            spinners={false}
                                            width={100}
                                            disabled={props.role !== "ADM"}
                                            onChange={(event) => handleChangeIndex(event, item.id)}
                                            onBlur={(event) => handleBlurIndex(event, item.id, item.startDate)}
                                        />
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Drawer>
            ) : (<></>)}

            <Modal show={isConfirmModalOpened} onHide={() => handleCloseConfirmModal()} className='mt-5'>
                <Modal.Header closeButton>
                    <Modal.Title>ATTENTION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Vous allez modifier un indice existant, confirmer-vous l'action ?
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary" onClick={() => handleCloseConfirmModal()}>
                        Annuler
                    </Button>
                    <Button className="primary" onClick={() => handleConfirmUpdateCnrGasoilIndex()}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}