export const propertyLabels: Map<string, string> = new Map([
    ['TransportPriority.High', 'Fixe'],
    ['TransportPriority.Medium', 'Journée'],
    ['TransportPriority.Low', 'Modulable'],

    ['TransportSellPriceKind.PerTon', 'Tonne'],
    ['TransportSellPriceKind.PerTimePeriod', 'Forfait'],
    ['TransportSellPriceKind.PerTurn', 'Tour'],

    ['PriceKind.Day', 'Journée'],
    ['PriceKind.HalfDay', '1/2 journée'],
    ['PriceKind.Night', 'Nuit'],
    ['PriceKind.PerTon', ''],
    ['PriceKind.PerHour', ''],
]);