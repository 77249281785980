import { Box, Button } from '@mui/material';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { AxiosResponse } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Select, { ActionMeta, components, OptionProps } from 'react-select';
import { ScaleLoader } from 'react-spinners';
import { LogisticsUnitSelectModel } from '../../../../shared/models/LogisticsUnitSelectModel';
import { ActionType } from '../models/ActionType';
import { DateConfigModel } from '../models/DateConfigModel';
import { NegotiatedPurchasePriceLightModelExtended } from '../models/NegotiatedPurchasePriceLightModelExtended';
import { PriceKind } from '../models/PriceKind';
import { PriceKindModel } from '../models/PriceKindModel';
import { ProductionSiteSelectModel } from '../models/ProductionSiteSelectModel';
import { ReactSelectModel } from '../models/ReactSelectModel';
import { SiteKind } from '../models/SiteKind';
import { SiteModel } from '../models/SiteModel';
import { AddNegotiatedPurchasePricesArgs } from '../services/dataContracts/controller/AddNegotiatedPurchasePricesArgs';
import { UpdateNegotiatedPurchasePriceArgs } from '../services/dataContracts/controller/UpdateNegotiatedPurchasePriceArgs';
import { AgencyLightModel } from '../services/dataContracts/queryStack/AgencyLightModel';
import { Level2ActivityLightModel } from '../services/dataContracts/queryStack/Level2ActivityLightModel';
import { ProductionSiteLightModel } from '../services/dataContracts/queryStack/ProductionSiteLightModel';
import { ReferencedCustomerLightModel } from '../services/dataContracts/queryStack/ReferencedCustomerLightModel';
import { NegotiatedPurchasePricesReferentialApiClient } from '../services/NegotiatedPurchasePricesReferentialApiClient';

interface NegotiatedPurchasePricesFormProps {
    selectedLogisticsUnit: LogisticsUnitSelectModel,
    negotiatedPurchasePrices: NegotiatedPurchasePriceLightModelExtended[],
    actionType: ActionType,
    addNegotiatedPurchasePrices: (requestArgs: AddNegotiatedPurchasePricesArgs) => void
    updateNegotiatedPurchasePrice: (requestArgs: UpdateNegotiatedPurchasePriceArgs) => void
}


export interface OptionPropsModelExtended extends OptionProps<ReactSelectModel> {
    isCheckAllSelected?: boolean
}

const OptionItem = (props: OptionPropsModelExtended): JSX.Element => {

    const isSelectAllItemChecked: boolean = props.data.value === "selectAllVehicles" && props.isCheckAllSelected;

    return (
        <div>
            <components.Option {...props} className={isSelectAllItemChecked ? "selected-item" : ''}>
                <input
                    type="checkbox"
                    checked={props.data.value === "selectAllVehicles" ? props.isCheckAllSelected : props.isSelected}
                    onChange={() => null}
                />{" "}
                <label className={isSelectAllItemChecked ? "selected-item-label" : ''}>{props.data.label}</label>
            </components.Option>
        </div>
    );
}


export const NegotiatedPurchasePricesForm = (props: NegotiatedPurchasePricesFormProps): JSX.Element => {

    const selectAllOption: ReactSelectModel = { label: "Sélectionner tout", value: "selectAllVehicles" };

    const siteKindList = [
        { label: "Site de production", value: SiteKind.ProductionSite },
        { label: "Chantier", value: SiteKind.JobSite },
        { label: "Sté externe référencée", value: SiteKind.Customer }
    ];

    const [senderSite, setSenderSite] = useState<SiteModel>({
        siteKind: null,
        productionSiteList: [],
        selectedProductionSite: null,
        productionSiteActivities: [],
        selectedProductionSiteActivity: null,
        siteAgencyList: [],
        selectedSiteAgency: null,
        jobSiteIdmdm: null,
        referencedSiteCustomerList: [],
        selectedReferencedSiteCustomer: null
    });
    const [receiverSite, setReceiverSite] = useState<SiteModel>({
        siteKind: null,
        productionSiteList: [],
        selectedProductionSite: null,
        productionSiteActivities: [],
        selectedProductionSiteActivity: null,
        siteAgencyList: [],
        selectedSiteAgency: null,
        jobSiteIdmdm: null,
        referencedSiteCustomerList: [],
        selectedReferencedSiteCustomer: null
    });
    const [priceKind, setPriceKind] = useState<PriceKindModel>(null);
    const [price, setPrice] = useState<number>(null);
    const [vehicleTypeGroups, setVehicleTypeGroups] = useState<ReactSelectModel[]>([]);
    const [selectedVehicleTypeGroups, setSelectedVehicleTypeGroups] = useState<ReactSelectModel[]>([]);
    const [dates, setDates] = useState<DateConfigModel>({
        start: null,
        end: null,
        minEnd: null,
        skipToValidate: false
    });
    const [startDateValid, setStartDateValid] = useState<boolean>(false);
    const [endDateValid, setEndDateValid] = useState<boolean>(false);
    const [isCheckAllSelected, setIsCheckAllSelected] = useState<boolean>(false);
    const [label, setLabel] = useState<string>("");
    const [zephyrOrderNumber, setZephyrOrderNumber] = useState<string>(null);
    const [loadingPopup, setLoadingPopup] = useState<boolean>(false);

    const startDateRef = useRef<DatePicker>();
    const endDateRef = useRef<DatePicker>();
    const { actionType, negotiatedPurchasePrices } = props;

    useEffect(() => {
        getVehicleTypeGroups();
        // Dans le cas d'une duplication ou modification
        if (actionType !== ActionType.Add) {
            const negotiatedPurchasePriceSelected = negotiatedPurchasePrices.find(x => x.selected === true);
            setPriceKind({ label: negotiatedPurchasePriceSelected.priceKindLabel, value: negotiatedPurchasePriceSelected.priceKind });
            setPrice(negotiatedPurchasePriceSelected.price);
            setLabel(negotiatedPurchasePriceSelected.label);
            setSelectedVehicleTypeGroups([{ label: negotiatedPurchasePriceSelected.vehicleTypeGroupId, value: negotiatedPurchasePriceSelected.vehicleTypeGroupId }]);
            setZephyrOrderNumber(negotiatedPurchasePriceSelected.zephyrOrderNumber);

            setDates({
                start: negotiatedPurchasePriceSelected.startDate,
                end: negotiatedPurchasePriceSelected.endDate,
                minEnd: negotiatedPurchasePriceSelected.endDate,
                skipToValidate: true
            });

            setSiteData(negotiatedPurchasePriceSelected.senderSiteProductionSiteId, negotiatedPurchasePriceSelected.senderSiteResolutionLabel
                , negotiatedPurchasePriceSelected.senderSiteLevel2ActivityId, negotiatedPurchasePriceSelected.senderSiteLevel2ActivityLabel, negotiatedPurchasePriceSelected.senderSiteAgencyId
                , negotiatedPurchasePriceSelected.senderSiteAgencyLabel, negotiatedPurchasePriceSelected.senderSiteCustomerMdmId, negotiatedPurchasePriceSelected.senderSiteJobSiteMdmId
                , negotiatedPurchasePriceSelected.senderSiteResolutionSiteKind, negotiatedPurchasePriceSelected.receiverSiteProductionSiteId
                , negotiatedPurchasePriceSelected.receiverSiteCustomerMdmId, true);

            setSiteData(negotiatedPurchasePriceSelected.receiverSiteProductionSiteId, negotiatedPurchasePriceSelected.receiverSiteResolutionLabel
                , negotiatedPurchasePriceSelected.receiverSiteLevel2ActivityId, negotiatedPurchasePriceSelected.receiverSiteLevel2ActivityLabel, negotiatedPurchasePriceSelected.receiverSiteAgencyId
                , negotiatedPurchasePriceSelected.receiverSiteAgencyLabel, negotiatedPurchasePriceSelected.receiverSiteCustomerMdmId, negotiatedPurchasePriceSelected.receiverSiteJobSiteMdmId
                , negotiatedPurchasePriceSelected.receiverSiteResolutionSiteKind, negotiatedPurchasePriceSelected.senderSiteProductionSiteId
                , negotiatedPurchasePriceSelected.senderSiteCustomerMdmId, false);
        }
    }, []);

    useEffect(() => {
        setStartDateValid(dates.skipToValidate ? true : isValidDate(startDateRef));
        setEndDateValid(dates.skipToValidate ? true : isValidDate(endDateRef));
        if (dates.skipToValidate)
            setDates({
                ...dates,
                skipToValidate: false
            });
    }, [dates.start, dates.end]);

    const setSiteData = (productionSiteId: string, resolutionLabel: string, level2ActivityId: string, level2ActivityLabel: string, agencyId: string, agencyLabel: string, customerMdmId: string, jobSiteIdmdm: string, siteKind: string, otherSiteProductionSiteId: string, otherCustomerMdmId: string, isSenderSite: boolean): void => {
        const site: SiteModel = {
            siteKind: null,
            productionSiteList: [],
            selectedProductionSite: null,
            selectedProductionSiteActivity: null,
            productionSiteActivities: [],
            selectedSiteAgency: null,
            jobSiteIdmdm: null,
            referencedSiteCustomerList: [],
            selectedReferencedSiteCustomer: null
        }
        setLoadingPopup(true);
        switch (siteKind) {
            case SiteKind.ProductionSite:
                GetProductionSitesWithLevel2Activities(productionSiteId)
                    .then((res) => {
                        const productionSitesData = res[0].data;
                        const level2ActivitiesData = res[1].data;
                        const productionSiteArray: ProductionSiteSelectModel[] = [];
                        productionSitesData.forEach((element: ProductionSiteLightModel) => productionSiteArray.push({
                            label: element.label,
                            value: element.productionSiteId,
                            agencyId: element.agencyId,
                            disabled: otherSiteProductionSiteId && otherSiteProductionSiteId === element.productionSiteId
                        }));

                        const productionSiteActivityList: ReactSelectModel[] = [];
                        level2ActivitiesData.forEach((element: Level2ActivityLightModel) => productionSiteActivityList.push({ label: element.label, value: element.id }));

                        site.siteKind = { label: "Site de production", value: SiteKind.ProductionSite };
                        site.productionSiteList = productionSiteArray;
                        site.productionSiteActivities = productionSiteActivityList;
                        site.selectedProductionSiteActivity = { label: level2ActivityLabel, value: level2ActivityId };
                        site.selectedProductionSite = {
                            label: resolutionLabel,
                            value: productionSiteId,
                            agencyId: agencyId
                        };

                        if (isSenderSite)
                            setSenderSite(site);
                        else
                            setReceiverSite(site);

                        setLoadingPopup(false);
                    });
                break;

            case SiteKind.JobSite:
                NegotiatedPurchasePricesReferentialApiClient.GetWorkAgencies(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const siteAgencyArray: ReactSelectModel[] = [];
                        res.data.forEach((element: AgencyLightModel) => siteAgencyArray.push({ label: element.label, value: element.agencyId }));

                        site.siteKind = { label: "Chantier", value: SiteKind.JobSite };
                        site.siteAgencyList = siteAgencyArray;
                        site.selectedSiteAgency = { label: agencyLabel, value: agencyId };
                        site.jobSiteIdmdm = jobSiteIdmdm;

                        if (isSenderSite)
                            setSenderSite(site);
                        else
                            setReceiverSite(site);

                        setLoadingPopup(false);
                    });
                break;

            case SiteKind.Customer:
                NegotiatedPurchasePricesReferentialApiClient.GetReferencedCustomers(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const referencedSiteCustomerArray: ReactSelectModel[] = [];
                        res.data.forEach((element: ReferencedCustomerLightModel) => referencedSiteCustomerArray.push({
                            label: element.name,
                            value: element.customerMdmId,
                            disabled: otherCustomerMdmId && otherCustomerMdmId === element.customerMdmId ? true : false
                        }));

                        site.siteKind = { label: "Sté externe référencée", value: SiteKind.Customer };
                        site.referencedSiteCustomerList = referencedSiteCustomerArray;
                        site.selectedReferencedSiteCustomer = { label: resolutionLabel, value: customerMdmId };

                        if (isSenderSite)
                            setSenderSite(site);
                        else
                            setReceiverSite(site);

                        setLoadingPopup(false);
                    })
                break;

            default:
                if (isSenderSite)
                    setSenderSite(site);
                else
                    setReceiverSite(site);
                break;
        }
    }

    const GetProductionSitesWithLevel2Activities = (productionSiteId: string): Promise<[AxiosResponse<ProductionSiteLightModel[]>, AxiosResponse<Level2ActivityLightModel[]>]> => {
        return Promise.all([NegotiatedPurchasePricesReferentialApiClient.GetProductionSites(props.selectedLogisticsUnit?.value), NegotiatedPurchasePricesReferentialApiClient.GetLevel2Activities(productionSiteId, props.selectedLogisticsUnit?.value)]);
    }

    const isValidDate = (refDate: React.MutableRefObject<DatePicker>): boolean => {
        return refDate.current?.validity.valid;
    }

    const getVehicleTypeGroups = (): void => {
        NegotiatedPurchasePricesReferentialApiClient.GetVehicleTypeGroups()
            .then((res) => {
                const vehicleTypeGroupList: ReactSelectModel[] = [];
                res.data.forEach((element: string) => vehicleTypeGroupList.push({ label: element, value: element }));
                setVehicleTypeGroups(vehicleTypeGroupList);
            });
    }

    const handleSenderSiteKindChanged = (e: ReactSelectModel): void => {
        const value = e?.value;
        switch (value) {
            case SiteKind.ProductionSite:

                NegotiatedPurchasePricesReferentialApiClient.GetProductionSites(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const productionSiteArray: ProductionSiteSelectModel[] = [];
                        res.data.forEach((element: ProductionSiteLightModel) => productionSiteArray.push({
                            label: element.label,
                            value: element.productionSiteId,
                            agencyId: element.agencyId,
                            disabled: receiverSite.siteKind?.value === SiteKind.ProductionSite && receiverSite.selectedProductionSite?.value === element.productionSiteId ? true : false
                        }));
                        setSenderSite({
                            ...senderSite,
                            siteKind: { label: e.label, value: e.value },
                            productionSiteList: productionSiteArray,
                            selectedProductionSite: null,
                            selectedProductionSiteActivity: null,
                            productionSiteActivities: [],
                            selectedSiteAgency: null,
                            jobSiteIdmdm: null,
                            referencedSiteCustomerList: [],
                            selectedReferencedSiteCustomer: null
                        });
                    });
                break;

            case SiteKind.JobSite:

                NegotiatedPurchasePricesReferentialApiClient.GetWorkAgencies(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const siteAgencyArray: ReactSelectModel[] = [];
                        res.data.forEach((element: AgencyLightModel) => siteAgencyArray.push({ label: element.label, value: element.agencyId }));
                        setSenderSite({
                            ...senderSite,
                            siteKind: { label: e.label, value: e.value },
                            siteAgencyList: siteAgencyArray,
                            selectedProductionSite: null,
                            selectedProductionSiteActivity: null,
                            productionSiteActivities: [],
                            selectedSiteAgency: null,
                            jobSiteIdmdm: null,
                            referencedSiteCustomerList: [],
                            selectedReferencedSiteCustomer: null
                        });
                    });
                break;

            case SiteKind.Customer:

                NegotiatedPurchasePricesReferentialApiClient.GetReferencedCustomers(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const referencedSiteCustomerArray: ReactSelectModel[] = [];
                        res.data.forEach((element: ReferencedCustomerLightModel) => referencedSiteCustomerArray.push({
                            label: element.name,
                            value: element.customerMdmId,
                            disabled: receiverSite.siteKind?.value === SiteKind.Customer && receiverSite.selectedReferencedSiteCustomer?.value === element.customerMdmId ? true : false
                        }));
                        setSenderSite({
                            ...senderSite,
                            siteKind: { label: e.label, value: e.value },
                            siteAgencyList: [],
                            selectedProductionSite: null,
                            selectedProductionSiteActivity: null,
                            productionSiteActivities: [],
                            selectedSiteAgency: null,
                            jobSiteIdmdm: null,
                            referencedSiteCustomerList: referencedSiteCustomerArray,
                            selectedReferencedSiteCustomer: null
                        });
                    })
                break;

            default:

                setSenderSite({
                    siteKind: null,
                    productionSiteList: [],
                    selectedProductionSite: null,
                    productionSiteActivities: [],
                    selectedProductionSiteActivity: null,
                    siteAgencyList: [],
                    selectedSiteAgency: null,
                    jobSiteIdmdm: null,
                    referencedSiteCustomerList: [],
                    selectedReferencedSiteCustomer: null
                });

        }
    }

    const handleReceiverSiteKindChanged = (e: ReactSelectModel): void => {
        const value = e?.value;
        switch (value) {
            case SiteKind.ProductionSite:

                NegotiatedPurchasePricesReferentialApiClient.GetProductionSites(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const productionSiteArray: ProductionSiteSelectModel[] = [];
                        res.data.forEach((element: ProductionSiteLightModel) => productionSiteArray.push({
                            label: element.label,
                            value: element.productionSiteId,
                            agencyId: element.agencyId,
                            disabled: senderSite.siteKind?.value === SiteKind.ProductionSite && senderSite.selectedProductionSite?.value === element.productionSiteId ? true : false
                        }));
                        setReceiverSite({
                            ...receiverSite,
                            siteKind: { label: e.label, value: e.value },
                            productionSiteList: productionSiteArray,
                            selectedProductionSite: null,
                            selectedProductionSiteActivity: null,
                            productionSiteActivities: [],
                            selectedSiteAgency: null,
                            jobSiteIdmdm: null,
                            referencedSiteCustomerList: [],
                            selectedReferencedSiteCustomer: null
                        });
                    });
                break;

            case SiteKind.JobSite:

                NegotiatedPurchasePricesReferentialApiClient.GetWorkAgencies(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const siteAgencyArray: ReactSelectModel[] = [];
                        res.data.forEach((element: AgencyLightModel) => siteAgencyArray.push({ label: element.label, value: element.agencyId }));
                        setReceiverSite({
                            ...receiverSite,
                            siteKind: { label: e.label, value: e.value },
                            siteAgencyList: siteAgencyArray,
                            selectedProductionSite: null,
                            selectedProductionSiteActivity: null,
                            productionSiteActivities: [],
                            selectedSiteAgency: null,
                            jobSiteIdmdm: null,
                            referencedSiteCustomerList: [],
                            selectedReferencedSiteCustomer: null
                        });
                    });
                break;

            case SiteKind.Customer:

                NegotiatedPurchasePricesReferentialApiClient.GetReferencedCustomers(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const referencedSiteCustomerArray: ReactSelectModel[] = [];
                        res.data.forEach((element: ReferencedCustomerLightModel) => referencedSiteCustomerArray.push({
                            label: element.name,
                            value: element.customerMdmId,
                            disabled: senderSite.siteKind?.value === SiteKind.Customer && senderSite.selectedReferencedSiteCustomer?.value === element.customerMdmId ? true : false
                        }));
                        setReceiverSite({
                            ...senderSite,
                            siteKind: { label: e.label, value: e.value },
                            siteAgencyList: [],
                            selectedProductionSite: null,
                            selectedProductionSiteActivity: null,
                            productionSiteActivities: [],
                            selectedSiteAgency: null,
                            jobSiteIdmdm: null,
                            referencedSiteCustomerList: referencedSiteCustomerArray,
                            selectedReferencedSiteCustomer: null
                        });
                    })
                break;

            default:

                setReceiverSite({
                    siteKind: null,
                    productionSiteList: [],
                    selectedProductionSite: null,
                    productionSiteActivities: [],
                    selectedProductionSiteActivity: null,
                    siteAgencyList: [],
                    selectedSiteAgency: null,
                    jobSiteIdmdm: null,
                    referencedSiteCustomerList: [],
                    selectedReferencedSiteCustomer: null
                });

        }
    }

    const handleSenderProductionSiteChanged = (e: ProductionSiteSelectModel): void => {
        if (e) {
            NegotiatedPurchasePricesReferentialApiClient.GetLevel2Activities(e.value as string, props.selectedLogisticsUnit?.value)
                .then((res) => {
                    const productionSiteActivityList: ReactSelectModel[] = [];
                    res.data.forEach((element: Level2ActivityLightModel) => productionSiteActivityList.push({ label: element.label, value: element.id }));
                    setSenderSite({
                        ...senderSite,
                        selectedProductionSite: { label: e.label, value: e.value, agencyId: e.agencyId },
                        productionSiteActivities: productionSiteActivityList,
                        selectedProductionSiteActivity: productionSiteActivityList.length == 1 ? { label: productionSiteActivityList[0].label, value: productionSiteActivityList[0].value } : null
                    });
                    if (receiverSite.siteKind?.value === SiteKind.ProductionSite)
                        receiverSite.productionSiteList.forEach((element) => {
                            if (element.value === e.value)
                                element.disabled = true;
                            else
                                element.disabled = false;
                        });
                });
        }
        else {
            setSenderSite({
                ...senderSite,
                selectedProductionSite: null,
                productionSiteActivities: [],
                selectedProductionSiteActivity: null
            });
        }
    }

    const handleReceiverProductionSiteChanged = (e: ProductionSiteSelectModel): void => {
        if (e) {
            NegotiatedPurchasePricesReferentialApiClient.GetLevel2Activities(e.value as string, props.selectedLogisticsUnit?.value)
                .then((res) => {
                    const productionSiteActivityList: ReactSelectModel[] = [];
                    res.data.forEach((element: Level2ActivityLightModel) => productionSiteActivityList.push({ label: element.label, value: element.id }));
                    setReceiverSite({
                        ...receiverSite,
                        selectedProductionSite: { label: e.label, value: e.value, agencyId: e.agencyId },
                        productionSiteActivities: productionSiteActivityList,
                        selectedProductionSiteActivity: productionSiteActivityList.length == 1 ? { label: productionSiteActivityList[0].label, value: productionSiteActivityList[0].value } : null
                    });
                    if (senderSite.siteKind?.value === SiteKind.ProductionSite)
                        senderSite.productionSiteList.forEach((element) => {
                            if (element.value === e.value)
                                element.disabled = true;
                            else
                                element.disabled = false;
                        });
                });
        }
        else {
            setReceiverSite({
                ...receiverSite,
                selectedProductionSite: null,
                productionSiteActivities: [],
                selectedProductionSiteActivity: null
            });
        }
    }

    const handleSenderSiteAgencyChanged = (e: ReactSelectModel): void => {
        setSenderSite({ ...senderSite, selectedSiteAgency: e ? { label: e.label, value: e.value } : null });
    }

    const handleReceiverSiteAgencyChanged = (e: ReactSelectModel): void => {
        setReceiverSite({ ...receiverSite, selectedSiteAgency: e ? { label: e.label, value: e.value } : null });
    }

    const handleSenderJobSiteIdmdmChanged = (value: string): void => {
        setSenderSite({ ...senderSite, jobSiteIdmdm: value });
    }

    const handleReceiverJobSiteIdmdmChanged = (value: string): void => {
        setReceiverSite({ ...receiverSite, jobSiteIdmdm: value });
    }

    const handleSenderProductionSiteActivityChanged = (e: ReactSelectModel): void => {
        setSenderSite({ ...senderSite, selectedProductionSiteActivity: e ? { label: e.label, value: e.value } : null });
    }

    const handleReceiverProductionSiteActivityChanged = (e: ReactSelectModel): void => {
        setReceiverSite({ ...receiverSite, selectedProductionSiteActivity: e ? { label: e.label, value: e.value } : null });
    }

    const handleSenderReferencedSiteCustomerChanged = (e: ReactSelectModel): void => {
        setSenderSite({ ...senderSite, selectedReferencedSiteCustomer: e ? { label: e.label, value: e.value } : null });
        if (receiverSite.siteKind?.value === SiteKind.Customer)
            receiverSite.referencedSiteCustomerList.forEach((element) => {
                if (e && element.value === e.value)
                    element.disabled = true;
                else
                    element.disabled = false;
            });
    }

    const handleReceiverReferencedSiteCustomerChanged = (e: ReactSelectModel): void => {
        setReceiverSite({ ...receiverSite, selectedReferencedSiteCustomer: e ? { label: e.label, value: e.value } : null });
        if (senderSite.siteKind?.value === SiteKind.Customer)
            senderSite.referencedSiteCustomerList.forEach((element) => {
                if (e && element.value === e.value)
                    element.disabled = true;
                else
                    element.disabled = false;
            });
    }

    const handleChangeVehicleTypeGroupsSelected = (opts: Array<ReactSelectModel>, event: ActionMeta<ReactSelectModel>): void => {
        if (event.action === "select-option" && event.option.value === "selectAllVehicles" && isCheckAllSelected) {
            setIsCheckAllSelected(false);
            setSelectedVehicleTypeGroups([]);
            return;
        }

        switch (event.action) {
            case "select-option":
                setOptions(event.option.value as string, opts);
                break;
            case "deselect-option":
                removeOptions(event.option.value as string, opts);
                break;
            case "remove-value":
                removeOptions(event.removedValue.value as string, opts);
                break;
            case "clear":
                if (isCheckAllSelected)
                    setIsCheckAllSelected(false);

                setSelectedVehicleTypeGroups([]);
                break;
            default:
                return;
        }
    }

    const handleChangeVehicleTypeGroupSelected = (event: ReactSelectModel): void => {
        if (event?.value) {
            setSelectedVehicleTypeGroups([{
                label: event.label,
                value: event.value
            }]);
        }
        else {
            setSelectedVehicleTypeGroups([]);
        }
    }

    const setOptions = (value: string, opts: Array<ReactSelectModel>): void => {
        if (value === "selectAllVehicles") {
            setSelectedVehicleTypeGroups(vehicleTypeGroups);
            setIsCheckAllSelected(true);
        } else {
            if (opts.length === vehicleTypeGroups.length)
                setIsCheckAllSelected(true);

            setSelectedVehicleTypeGroups(opts);
        }
    }

    const removeOptions = (value: string, opts: Array<ReactSelectModel>): void => {
        if (value === "selectAllVehicles") {
            setSelectedVehicleTypeGroups([]);
            setIsCheckAllSelected(false);
        } else {
            if (isCheckAllSelected)
                setIsCheckAllSelected(false);

            const options: Array<ReactSelectModel> = opts.filter(x => x.value !== value);
            setSelectedVehicleTypeGroups(options);
        }
    }

    const handleStartDateChange = (e: DatePickerChangeEvent): void => {
        const selectedDate: Date = e.target.value;
        if (e.target.state.show) {
            (startDateRef.current as any).shouldFocusDateInput = false;
        }
        setDates({ ...dates, start: selectedDate, minEnd: selectedDate });
    }

    const handleEndDateChange = (e: DatePickerChangeEvent): void => {
        const selectedDate: Date = e.target.value;
        if (e.target.state.show) {
            (endDateRef.current as any).shouldFocusDateInput = false;
        }
        setDates({ ...dates, end: selectedDate });
    }

    const handleClickValidatePurchasePrices = (): void => {
        if (props.actionType === ActionType.Edit) {
            updateNegotiatedPurchasePrice();
        }
        else {
            addNegotiatedPurchasePrices();
        }
    }

    const addNegotiatedPurchasePrices = (): void => {
        const vehicleTypeGroupIds = selectedVehicleTypeGroups.map(x => x.value as string);

        const requestArgs: AddNegotiatedPurchasePricesArgs = {
            logisticsUnitId: props.selectedLogisticsUnit?.value,
            senderSiteProductionSiteId: senderSite.selectedProductionSite ? senderSite.selectedProductionSite.value as string : null,
            senderSiteAgencyId: senderSite.selectedProductionSite ? senderSite.selectedProductionSite.agencyId as string :
                (senderSite.selectedSiteAgency ? senderSite.selectedSiteAgency.value as string : null),
            senderSiteJobSiteMdmId: senderSite.jobSiteIdmdm?.trim() ? senderSite.jobSiteIdmdm.trim() : null,
            senderSiteCustomerMdmId: senderSite.selectedReferencedSiteCustomer ? senderSite.selectedReferencedSiteCustomer.value as string : null,
            senderLevel2ActivityId: senderSite.selectedProductionSiteActivity ? senderSite.selectedProductionSiteActivity.value as string : null,
            receiverSiteProductionSiteId: receiverSite.selectedProductionSite ? receiverSite.selectedProductionSite.value as string : null,
            receiverSiteAgencyId: receiverSite.selectedProductionSite ? receiverSite.selectedProductionSite.agencyId as string :
                (receiverSite.selectedSiteAgency ? receiverSite.selectedSiteAgency.value as string : null),
            receiverSiteJobSiteMdmId: receiverSite.jobSiteIdmdm?.trim() ? receiverSite.jobSiteIdmdm.trim() : null,
            receiverSiteCustomerMdmId: receiverSite.selectedReferencedSiteCustomer ? receiverSite.selectedReferencedSiteCustomer.value as string : null,
            receiverLevel2ActivityId: receiverSite.selectedProductionSiteActivity ? receiverSite.selectedProductionSiteActivity.value as string : null,
            vehicleTypeGroupIds: vehicleTypeGroupIds,
            startDate: dates.start,
            endDate: dates.end,
            priceKind: priceKind.value,
            label: label?.trim() ? label.trim() : null,
            zephyrOrderNumber: zephyrOrderNumber?.trim() ? zephyrOrderNumber.trim() : null,
            price: price
        }
        props.addNegotiatedPurchasePrices(requestArgs);
    }

    const updateNegotiatedPurchasePrice = (): void => {
        const vehicleTypeGroupIds = selectedVehicleTypeGroups.map(x => x.value as string);
        const selectedNegotiatedPurchasePrices = props.negotiatedPurchasePrices.find(x => x.selected === true);

        const requestArgs: UpdateNegotiatedPurchasePriceArgs = {
            negotiatedPurchasePriceId: selectedNegotiatedPurchasePrices?.negotiatedPurchasePriceId,
            logisticsUnitId: props.selectedLogisticsUnit?.value,
            senderSiteProductionSiteId: senderSite.selectedProductionSite ? senderSite.selectedProductionSite.value as string : null,
            senderSiteAgencyId: senderSite.selectedProductionSite ? senderSite.selectedProductionSite.agencyId as string :
                (senderSite.selectedSiteAgency ? senderSite.selectedSiteAgency.value as string : null),
            senderSiteJobSiteMdmId: senderSite.jobSiteIdmdm?.trim() ? senderSite.jobSiteIdmdm.trim() : null,
            senderSiteCustomerMdmId: senderSite.selectedReferencedSiteCustomer ? senderSite.selectedReferencedSiteCustomer.value as string : null,
            senderLevel2ActivityId: senderSite.selectedProductionSiteActivity ? senderSite.selectedProductionSiteActivity.value as string : null,
            receiverSiteProductionSiteId: receiverSite.selectedProductionSite ? receiverSite.selectedProductionSite.value as string : null,
            receiverSiteAgencyId: receiverSite.selectedProductionSite ? receiverSite.selectedProductionSite.agencyId as string :
                (receiverSite.selectedSiteAgency ? receiverSite.selectedSiteAgency.value as string : null),
            receiverSiteJobSiteMdmId: receiverSite.jobSiteIdmdm?.trim() ? receiverSite.jobSiteIdmdm.trim() : null,
            receiverSiteCustomerMdmId: receiverSite.selectedReferencedSiteCustomer ? receiverSite.selectedReferencedSiteCustomer.value as string : null,
            receiverLevel2ActivityId: receiverSite.selectedProductionSiteActivity ? receiverSite.selectedProductionSiteActivity.value as string : null,
            vehicleTypeGroupId: vehicleTypeGroupIds.length > 0 ? vehicleTypeGroupIds[0] : "",
            startDate: dates.start,
            endDate: dates.end,
            priceKind: priceKind.value,
            label: label?.trim() ? label.trim() : null,
            zephyrOrderNumber: zephyrOrderNumber?.trim() ? zephyrOrderNumber.trim() : null,
            price: price
        }

        props.updateNegotiatedPurchasePrice(requestArgs);
    }

    const checkSite = (site: SiteModel): boolean => {
        if (site.siteKind?.value === SiteKind.JobSite) {
            if (site.selectedSiteAgency && site.jobSiteIdmdm?.trim()) {
                return true;
            } else {
                return false;
            }
        }
        if (site.siteKind?.value === SiteKind.ProductionSite) {
            if (site.selectedProductionSite && site.selectedProductionSiteActivity) {
                return true;
            } else {
                return false;
            }
        }
        if (site.siteKind?.value === SiteKind.Customer) {
            if (site.selectedReferencedSiteCustomer) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    const selectedVehicleTypeIds = selectedVehicleTypeGroups.map(x => x.value as string);

    const validForm: boolean =
        startDateValid &&
        endDateValid &&
        props.selectedLogisticsUnit &&
        selectedVehicleTypeIds.length > 0 &&
        senderSite.siteKind &&
        checkSite(senderSite) &&
        receiverSite.siteKind &&
        checkSite(receiverSite) &&
        price > 0 &&
        priceKind !== null;

    const isSenderJobSiteAndReceiverJobSiteAreEquals: boolean = senderSite.siteKind?.value === SiteKind.JobSite &&
        receiverSite.siteKind?.value === SiteKind.JobSite &&
        senderSite.selectedSiteAgency?.value === receiverSite.selectedSiteAgency?.value &&
        senderSite.jobSiteIdmdm?.trim() === receiverSite.jobSiteIdmdm?.trim();

    return (
        <Box display="flex" flexDirection="column" className="add-negotiated-purchase-prices-dialog">
            {loadingPopup ?
                <Box className="sweet-loading main-spinner">
                    <ScaleLoader
                        width={5}
                        height={20}
                        radius={50}
                        color={'#000000'}
                        loading={loadingPopup}
                    />
                </Box>
                : (<Box className="content">
                    <Box display="flex" flexDirection="column" pb={1}>
                        <Box className="label">Libellé</Box>
                        <Box display="flex" flexDirection="row">
                            <input type="text"
                                className="input-text"
                                value={label ?? ""}
                                onChange={(e) => setLabel(e.target.value)}
                            />
                        </Box>
                    </Box>
                    <fieldset className="site-fieldset">
                        <legend>Site départ</legend>
                        <Box display="flex" flexDirection="row">
                            <Box display="flex" flexDirection="column">
                                <Box className={!senderSite.siteKind ? "label required" : "label"}>Type de lieu de départ</Box>
                                <Box display="flex" flexDirection="row">
                                    <Select
                                        className={!senderSite.siteKind ? "input-select required" : "input-select"}
                                        isClearable
                                        placeholder=""
                                        options={siteKindList}
                                        value={senderSite.siteKind}
                                        onChange={(e) => handleSenderSiteKindChanged(e)}
                                        menuPosition="fixed"
                                        menuShouldBlockScroll={true}
                                    />
                                </Box>
                            </Box>
                            {senderSite.siteKind?.value === SiteKind.ProductionSite &&
                                <>
                                    <Box display="flex" flexDirection="column" ml="15px">
                                        <Box className={!senderSite.selectedProductionSite ? "label required" : "label"}>Site de production</Box>
                                        <Box display="flex" flexDirection="row">
                                            <Select
                                                className={!senderSite.selectedProductionSite ? "input-select required" : "input-select"}
                                                isClearable
                                                placeholder=""
                                                options={senderSite.productionSiteList}
                                                isOptionDisabled={(option) => option.disabled === true}
                                                value={senderSite.selectedProductionSite}
                                                onChange={(e) => handleSenderProductionSiteChanged(e)}
                                                menuPosition="fixed"
                                                menuShouldBlockScroll={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display="flex" flexDirection="column" ml="15px">
                                        <Box className={!senderSite.selectedProductionSiteActivity ? "label required" : "label"}>Activité</Box>
                                        <Box display="flex" flexDirection="row">
                                            <Select
                                                className={!senderSite.selectedProductionSiteActivity ? "input-select required" : "input-select"}
                                                isClearable
                                                placeholder=""
                                                options={senderSite.productionSiteActivities}
                                                value={senderSite.selectedProductionSiteActivity}
                                                onChange={(e) => handleSenderProductionSiteActivityChanged(e)}
                                                menuPosition="fixed"
                                                menuShouldBlockScroll={true}
                                            />
                                        </Box>
                                    </Box>
                                </>
                            }
                            {senderSite.siteKind?.value === SiteKind.JobSite &&
                                <>
                                    <Box display="flex" flexDirection="column" ml="15px">
                                        <Box className={!senderSite.selectedSiteAgency || isSenderJobSiteAndReceiverJobSiteAreEquals ? "label required" : "label"}>Agence</Box>
                                        <Box display="flex" flexDirection="row">
                                            <Select
                                                className={!senderSite.selectedSiteAgency || isSenderJobSiteAndReceiverJobSiteAreEquals ? "input-select required" : "input-select"}
                                                isClearable
                                                placeholder=""
                                                options={senderSite.siteAgencyList}
                                                isOptionDisabled={(option) => option.disabled === true}
                                                value={senderSite.selectedSiteAgency}
                                                onChange={(e) => handleSenderSiteAgencyChanged(e)}
                                                menuPosition="fixed"
                                                menuShouldBlockScroll={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display="flex" flexDirection="column" ml="15px">
                                        <Box className={senderSite.jobSiteIdmdm?.trim() && !isSenderJobSiteAndReceiverJobSiteAreEquals ? "label" : "label required"}>IDMDM chantier</Box>
                                        <Box display="flex" flexDirection="row">
                                            <input type="text"
                                                className={senderSite.jobSiteIdmdm?.trim() && !isSenderJobSiteAndReceiverJobSiteAreEquals ? "input-text" : "input-text required"}
                                                value={senderSite.jobSiteIdmdm === null ? "" : senderSite.jobSiteIdmdm}
                                                onChange={(e) => handleSenderJobSiteIdmdmChanged(e.target.value)}
                                            />
                                        </Box>
                                    </Box>
                                </>
                            }
                            {senderSite.siteKind?.value === SiteKind.Customer &&
                                <>
                                    <Box display="flex" flexDirection="column" ml="15px">
                                        <Box className={!senderSite.selectedReferencedSiteCustomer ? "label required" : "label"}>Nom du site de départ</Box>
                                        <Box display="flex" flexDirection="row">
                                            <Select
                                                className={!senderSite.selectedReferencedSiteCustomer ? "input-select required" : "input-select"}
                                                isClearable
                                                placeholder=""
                                                options={senderSite.referencedSiteCustomerList}
                                                isOptionDisabled={(option) => option.disabled === true}
                                                value={senderSite.selectedReferencedSiteCustomer}
                                                onChange={(e) => handleSenderReferencedSiteCustomerChanged(e)}
                                                menuPosition="fixed"
                                                menuShouldBlockScroll={true}
                                            />
                                        </Box>
                                    </Box>
                                </>
                            }
                        </Box>
                    </fieldset>
                    <fieldset className="site-fieldset">
                        <legend>Site arrivée</legend>
                        <Box display="flex" flexDirection="row">
                            <Box display="flex" flexDirection="column">
                                <Box className={!receiverSite.siteKind ? "label required" : "label"}>Type de lieu d'arrivée</Box>
                                <Box display="flex" flexDirection="row">
                                    <Select
                                        className={!receiverSite.siteKind ? "input-select required" : "input-select"}
                                        isClearable
                                        placeholder=""
                                        options={siteKindList}
                                        value={receiverSite.siteKind}
                                        onChange={(e) => handleReceiverSiteKindChanged(e)}
                                        menuPosition="fixed"
                                        menuShouldBlockScroll={true}
                                    />
                                </Box>
                            </Box>
                            {receiverSite.siteKind?.value === SiteKind.ProductionSite &&
                                <>
                                    <Box display="flex" flexDirection="column" ml="15px">
                                        <Box className={!receiverSite.selectedProductionSite ? "label required" : "label"}>Site de production</Box>
                                        <Box display="flex" flexDirection="row">
                                            <Select
                                                className={!receiverSite.selectedProductionSite ? "input-select required" : "input-select"}
                                                isClearable
                                                placeholder=""
                                                options={receiverSite.productionSiteList}
                                                isOptionDisabled={(option) => option.disabled === true}
                                                value={receiverSite.selectedProductionSite}
                                                onChange={(e) => handleReceiverProductionSiteChanged(e)}
                                                menuPosition="fixed"
                                                menuShouldBlockScroll={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display="flex" flexDirection="column" ml="15px">
                                        <Box className={!receiverSite.selectedProductionSiteActivity ? "label required" : "label"}>Activité</Box>
                                        <Box display="flex" flexDirection="row">
                                            <Select
                                                className={!receiverSite.selectedProductionSiteActivity ? "input-select required" : "input-select"}
                                                isClearable
                                                placeholder=""
                                                options={receiverSite.productionSiteActivities}
                                                value={receiverSite.selectedProductionSiteActivity}
                                                onChange={(e) => handleReceiverProductionSiteActivityChanged(e)}
                                                menuPosition="fixed"
                                                menuShouldBlockScroll={true}
                                            />
                                        </Box>
                                    </Box>
                                </>
                            }
                            {receiverSite.siteKind?.value === SiteKind.JobSite &&
                                <>
                                    <Box display="flex" flexDirection="column" ml="15px">
                                        <Box className={!receiverSite.selectedSiteAgency || isSenderJobSiteAndReceiverJobSiteAreEquals ? "label required" : "label"}>Agence</Box>
                                        <Box display="flex" flexDirection="row">
                                            <Select
                                                className={!receiverSite.selectedSiteAgency || isSenderJobSiteAndReceiverJobSiteAreEquals ? "input-select required" : "input-select"}
                                                isClearable
                                                placeholder=""
                                                options={receiverSite.siteAgencyList}
                                                isOptionDisabled={(option) => option.disabled === true}
                                                value={receiverSite.selectedSiteAgency}
                                                onChange={(e) => handleReceiverSiteAgencyChanged(e)}
                                                menuPosition="fixed"
                                                menuShouldBlockScroll={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display="flex" flexDirection="column" ml="15px">
                                        <Box className={receiverSite.jobSiteIdmdm?.trim() && !isSenderJobSiteAndReceiverJobSiteAreEquals ? "label" : "label required"}>IDMDM chantier</Box>
                                        <Box display="flex" flexDirection="row">
                                            <input type="text"
                                                className={receiverSite.jobSiteIdmdm?.trim() && !isSenderJobSiteAndReceiverJobSiteAreEquals ? "input-text" : "input-text required"}
                                                value={receiverSite.jobSiteIdmdm === null ? "" : receiverSite.jobSiteIdmdm}
                                                onChange={(e) => handleReceiverJobSiteIdmdmChanged(e.target.value)}
                                            />
                                        </Box>
                                    </Box>
                                </>
                            }
                            {receiverSite.siteKind?.value === SiteKind.Customer &&
                                <>
                                    <Box display="flex" flexDirection="column" ml="15px">
                                        <Box className={!receiverSite.selectedReferencedSiteCustomer ? "label required" : "label"}>Nom du site d'arrivée</Box>
                                        <Box display="flex" flexDirection="row">
                                            <Select
                                                className={!receiverSite.selectedReferencedSiteCustomer ? "input-select required" : "input-select"}
                                                isClearable
                                                placeholder=""
                                                options={receiverSite.referencedSiteCustomerList}
                                                isOptionDisabled={(option) => option.disabled === true}
                                                value={receiverSite.selectedReferencedSiteCustomer}
                                                onChange={(e) => handleReceiverReferencedSiteCustomerChanged(e)}
                                                menuPosition="fixed"
                                                menuShouldBlockScroll={true}
                                            />
                                        </Box>
                                    </Box>
                                </>
                            }
                        </Box>
                    </fieldset>
                    <Box display="flex" flexDirection="row" mb="10px">
                        <Box display="flex" flexDirection="column">
                            <Box className={!priceKind ? "label required" : "label"}>Type de tarif</Box>
                            <Box display="flex" flexDirection="row">
                                <Select
                                    className={!priceKind ? "input-select required" : "input-select"}
                                    options={[
                                        { label: "Heure", value: PriceKind.perHour },
                                        { label: "Tour", value: PriceKind.perTurn },
                                        { label: "Tonne", value: PriceKind.perTon }
                                    ]}
                                    isClearable
                                    placeholder=""
                                    value={priceKind}
                                    onChange={(e) => { e !== null ? setPriceKind({ label: e.label, value: e.value }) : setPriceKind(null) }}
                                    menuPosition="fixed"
                                    menuShouldBlockScroll={true}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" ml="15px">
                            <Box className={price ? "label" : "label required"}>Coût</Box>
                            <Box display="flex" flexDirection="row">
                                <NumericTextBox className={price ? "numeric-input" : "numeric-input required"}
                                    min={0}
                                    format={{
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'EUR',
                                        currencyDisplay: 'symbol'
                                    }}
                                    spinners={false}
                                    onChange={(e) => setPrice(e.target.value < 0 ? 0 : e.target.value)}
                                    value={price}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" ml="15px">
                            <Box className="label">N° commande</Box>
                            <Box display="flex" flexDirection="row">
                                <input type="text"
                                    className="input-text"
                                    value={zephyrOrderNumber ?? ""}
                                    onChange={(e) => setZephyrOrderNumber(e.target.value)}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" mb="10px">
                        <Box display="flex" flexDirection="column">
                            <Box className={dates.start && startDateValid ? "label" : "label required"}>Date de début</Box>
                            <Box display="flex" flexDirection="row">
                                <DatePicker onChange={(e) => handleStartDateChange(e)} value={dates.start}
                                    required ref={startDateRef} />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" ml="15px">
                            <Box className={dates.end && endDateValid ? "label" : "label required"}>Date de fin</Box>
                            <Box display="flex" flexDirection="row">
                                <DatePicker onChange={(e) => handleEndDateChange(e)} min={dates.minEnd} value={dates.end}
                                    required ref={endDateRef} />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box display="flex" flexDirection="column">
                            <Box className={selectedVehicleTypeGroups.length > 0 ? "label" : "label required"}>Famille véhicule</Box>
                            <Box display="flex" flexDirection="row">
                                {props.actionType === ActionType.Edit ? (
                                    <Select
                                        className={selectedVehicleTypeGroups.length > 0 ? "input-select" : "input-select required"}
                                        placeholder="Famille véhicule"
                                        menuPosition="fixed"
                                        options={vehicleTypeGroups}
                                        value={selectedVehicleTypeGroups?.length > 0 ? selectedVehicleTypeGroups[0] : null}
                                        onChange={(event) => handleChangeVehicleTypeGroupSelected(event)}
                                        menuShouldBlockScroll={true}
                                        closeMenuOnSelect={true}
                                    />)
                                    : (<Select
                                        isMulti
                                        className={selectedVehicleTypeGroups.length > 0 ? "input-select-vehicle-type-group" : "input-select-vehicle-type-group required"}
                                        placeholder="Famille véhicule"
                                        options={[selectAllOption, ...vehicleTypeGroups]}
                                        value={selectedVehicleTypeGroups}
                                        onChange={(opts: Array<ReactSelectModel>, event) => handleChangeVehicleTypeGroupsSelected(opts, event)}
                                        menuPosition="fixed"
                                        menuShouldBlockScroll={true}
                                        components={{
                                            Option: (props) => <OptionItem {...props} isCheckAllSelected={isCheckAllSelected} />
                                        }}
                                        hideSelectedOptions={false}
                                        blurInputOnSelect={false}
                                        closeMenuOnSelect={false}
                                    />)}
                            </Box>
                        </Box>
                    </Box>
                </Box>)}
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Button variant="contained" color="primary" title="Valider" onClick={handleClickValidatePurchasePrices} disabled={!validForm || isSenderJobSiteAndReceiverJobSiteAreEquals}>
                    Valider
                </Button>
            </Box>
        </Box>
    );

}
