import { GridCellProps } from '@progress/kendo-react-grid';
import React, { useEffect, useRef } from 'react';

interface BeneficiaryAndRequesterChoiceAllCellProperties extends GridCellProps{
    updateChoicesAllLogisticsUnits?: (checked: boolean, contactId: string, customerId: string) => void
}

export const BeneficiaryAndRequesterChoiceAllCell = (props: BeneficiaryAndRequesterChoiceAllCellProperties): JSX.Element => {

    const checkboxRef: React.MutableRefObject<HTMLInputElement> = useRef(null);

    useEffect(() => {
        checkboxRef.current.indeterminate = props.dataItem["isIndeterminateAll"];
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.updateChoicesAllLogisticsUnits(event.target.checked, props.dataItem.contactId, props.dataItem.customerMdmId);
    };

    const checked: boolean = props.dataItem["isSelectedAll"] === true ? true : (props.dataItem["isSelectedAll"] === false ? false : null);

    const defaultRendering: JSX.Element = (
        <td>
            <input type="checkbox" ref={checkboxRef} checked={checked} onChange={(e) => handleChange(e)} className="custom-checkbox" />
        </td>
    );

    return defaultRendering;
}
