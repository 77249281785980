import { ClientAppSettings } from "./shared/Settings/dataContracts/controller/ClientAppSettings";

export class SettingsProvider {
    private static _settings: ClientAppSettings | null = null;

    private constructor() {
    }

    static Get = () => {
        if (SettingsProvider._settings)
            return SettingsProvider._settings;

        throw console.error('SettingsProvider has not been initialized. Please use the "Setup" function to initialize before accessing.');
    }

    static Setup = (settings: ClientAppSettings) => {

        if (!settings)
            throw console.error('settings is null or undefined');

        if (!settings.azureMapsApiKey)
            throw console.error('azureMapsApiKey is missing');


        if (!settings.minimumLoadCapacity || settings.minimumLoadCapacity < 0)
            throw console.error('minimumLoadCapacity is missing or has an invalid value');

        if (!settings.maxAttachmentSize || settings.maxAttachmentSize < 0)
            throw console.error('maxAttachmentSize is missing or has an invalid value');

        if (!settings.maxAttachmentEmailSize || settings.maxAttachmentEmailSize < 0)
            throw console.error('maxAttachmentEmailSize is missing or has an invalid value');

        if (!settings.preInvoicesGenerationBatchSize || settings.preInvoicesGenerationBatchSize < 0)
            throw console.error('preInvoicesGenerationBatchSize is missing or has an invalid value');

        if (!settings.preInvoicesIntegrationBatchSize || settings.preInvoicesIntegrationBatchSize < 0)
            throw console.error('preInvoicesIntegrationBatchSize is missing or has an invalid value');

        if (!settings.purchaseProvisionsIntegrationBatchSize || settings.purchaseProvisionsIntegrationBatchSize < 0)
            throw console.error('purchaseProvisionsIntegrationBatchSize is missing or has an invalid value');

        if (!settings.daysToStoreColapsedSectionsSinceToday || settings.daysToStoreColapsedSectionsSinceToday < 0)
            throw console.error('daysToStoreColapsedSectionsSinceToday is missing or has an invalid value');

        if (!settings.purchaseProvisioningMaxAllowedCostLimit || settings.purchaseProvisioningMaxAllowedCostLimit < 0)
            throw console.error('purchaseProvisioningMaxAllowedCostLimit is missing or has an invalid value');

        if (!settings.smsNotificationSendingBatchSize || settings.smsNotificationSendingBatchSize < 0)
            throw console.error('smsNotificationSendingBatchSize is missing or has an invalid value');

        if (!settings.smsNotificationMaxContentLength || settings.smsNotificationMaxContentLength < 0)
            throw console.error('smsNotificationMaxContentLength is missing or has an invalid value');

        if (!settings.purchaseProvisioningRegularizationBatchSize || settings.purchaseProvisioningRegularizationBatchSize < 0)
            throw console.error('purchaseProvisioningRegularizationBatchSize is missing or has an invalid value');

        SettingsProvider._settings = settings;
    }

    static IsReady = () => {
        return SettingsProvider._settings !== null;
    }
}