import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult } from 'src/shared/models/WebAppActionResult';
import { WorkAgencyCandidateToAdd } from './dataContracts/controller/WorkAgencyCandidateToAdd';
import { WorkAgencyChoiceLogisticsUnitRequestArgs } from './dataContracts/controller/WorkAgencyChoiceLogisticsUnitRequestArgs';
import { WorkAgencyChoiceLogisticsUnitsRequestArgs } from './dataContracts/controller/WorkAgencyChoiceLogisticsUnitsRequestArgs';
import { WorkAgencyChoicesExportRequestArgs } from './dataContracts/controller/WorkAgencyChoicesExportRequestArgs';
import { LogisticsUnitChoiceOfWorkAgenciesLightModel } from './dataContracts/queryStack/LogisticsUnitChoiceOfWorkAgenciesLightModel';
import { WorkAgencyLightModel } from './dataContracts/queryStack/WorkAgencyLightModel';

const controllerUrl = 'api/WorkAgenciesReferential/';

export class WorkAgenciesReferentialApiClient {
    public static GetWorkAgencies = (searchText: string)
        : Promise<AxiosResponse<Array<WorkAgencyLightModel>>> => {
        return axios.get(`${controllerUrl}GetWorkAgencies?searchText=${encodeURIComponent(searchText)}`);
    }

    public static GetWorkAgencyChoicesLogisticsUnit = ()
        : Promise<AxiosResponse<Array<LogisticsUnitChoiceOfWorkAgenciesLightModel>>> => {
        return axios.get(`${controllerUrl}GetWorkAgencyChoicesLogisticsUnit`);
    }

    public static GetWorkAgenciesWithAgencyChoicesLogisticsUnit = (searchText: string)
        : Promise<[AxiosResponse<Array<WorkAgencyLightModel>>, AxiosResponse<Array<LogisticsUnitChoiceOfWorkAgenciesLightModel>>]> => {
        return Promise.all(
            [
                WorkAgenciesReferentialApiClient.GetWorkAgencies(searchText),
                WorkAgenciesReferentialApiClient.GetWorkAgencyChoicesLogisticsUnit()
            ]);
    }

    public static AddOrRemoveWorkAgencyChoice = (agencyChoiceLogisticsUnit: WorkAgencyChoiceLogisticsUnitRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddOrRemoveWorkAgencyChoice',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(agencyChoiceLogisticsUnit)
        });
    }

    public static AddOrRemoveWorkAgencyChoices = (agencyChoiceLogisticsUnits: WorkAgencyChoiceLogisticsUnitsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddOrRemoveWorkAgencyChoices',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(agencyChoiceLogisticsUnits)
        });
    }

    public static ExportWorkAgencyChoices = (workAgencyChoicesExportRequestArgs: WorkAgencyChoicesExportRequestArgs)
        : Promise<AxiosResponse<Blob>> => {
        const _headers = {
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return axios.post(controllerUrl + 'ExportWorkAgencyChoices', workAgencyChoicesExportRequestArgs, { headers: _headers, responseType: 'blob' });
    }

    public static GetImportUrl = (): string => {
        return controllerUrl + 'ImportFile';
    }

    public static WorkAgencyExists = (agencyId: string)
        : Promise<AxiosResponse<boolean>> => {
        return axios.get(`${controllerUrl}WorkAgencyExists?agencyId=${agencyId}`);
    }

    public static SearchWorkAgencyByIdentifier = (agencyId: string)
        : Promise<AxiosResponse<WorkAgencyCandidateToAdd>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SearchWorkAgencyByIdentifier?agencyId=${agencyId}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static AddWorkAgency = (agencyId: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AddWorkAgency?agencyId=${agencyId}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

}
