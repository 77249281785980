import { Button } from '@progress/kendo-react-buttons';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import axios from 'axios';
import React, { useState } from 'react';

export const SMSManagement = (): JSX.Element => {

    const [numberPhone, setNumberPhone] = useState<string>('');

    const handleNumberPhoneChanged = (event: InputChangeEvent): void => {
        setNumberPhone(event.target.value as string)
    }

    const handleSendSms = (): void => {
        axios.get("api/SendSMS/SendSMS?phoneNumber=" + encodeURIComponent(numberPhone))
            .then((res) => {
            });
    }

    return (
        <>
            <div>
                <Input
                    name="phonenumber"
                    type="phonenumber"
                    label="Numéro de Téléphone"
                    required={true}
                    value={numberPhone}
                    onChange={handleNumberPhoneChanged}
                />
            </div>
            <div className="pt-4">
                <Button onClick={handleSendSms}>ENVOYER</Button>
            </div>
        </>
    );
}