import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Input, InputAdornment } from '@mui/material';
import React from 'react';

interface HeaderContentComponentProps {
    inputSearchDispatchersValue: string,
    inputSearchDispatchersRef: React.RefObject<HTMLInputElement>,
    handleDispatchersKeyPress: (text: string) => void,
    handleClearSearchText: () => void,
    handleAddDispatchersDialogClick: () => void
}

export const HeaderContentComponent = (props: HeaderContentComponentProps): JSX.Element => {

    const inputSearchInternalDriversClassName: string = props.inputSearchDispatchersValue.length > 2 ? 'search-text-active' : '';

    return (
        <Box display="flex" flexDirection="row" flex="wrap" justifyContent="start" alignItems="center" className="header-content">
            <Box>
                <Input disableUnderline className={inputSearchInternalDriversClassName} inputRef={props.inputSearchDispatchersRef}
                    startAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faSearch} /></InputAdornment>}
                    endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon={faTimes} onClick={() => props.handleClearSearchText()} /></InputAdornment>}
                    placeholder="Rechercher ..." onChange={(event) => props.handleDispatchersKeyPress(event.target.value)} />
            </Box>
            <Box ml={'10px'}>
                <Button variant="contained" color="primary" title="Ajouter" onClick={props.handleAddDispatchersDialogClick}>
                    Ajouter
                </Button>
            </Box>
        </Box>
    );
}
