import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardContent, Divider } from '@mui/material';
import React from 'react';
import { EntityChangeAction } from '../models/EntityChangeAction';
import { OwnedEntity } from '../models/OwnedEntity';
import { EntityChangeLightModel } from '../services/dataContracts/queryStack/EntityChangeLightModel';
import { sortKeyTransportation } from '../TransportRequestChangesResources';
import AddedDeletedProperty from './AddedDeletedPropertyComponent';
import ModifiedPropertyComponent from './ModifiedPropertyComponent';

//RENAME v1 TransportationsChanges

//Mettre appart des "classes" avec des constates qui matchent avec les enum back
//Ex: EntityChangeActions avec Added = "Added";
//Ex: EntityTypes avec TransportRequest, TransportRequestCustomer, etc

//Typer les props
//v2 Faire une class associé TransportationsChangesResources qui est un dico avec les textes, libellées, etc
//ex: avec des entreéscomm AddedTitle = "'Ajout camion'"
//v2 ---> repercuter sur tous les composants

interface TransportationsChangesComponentProps {
    trackingTransportations: EntityChangeLightModel[],
    actionType: string,
    HiddenProperties: string[],
    propertyLabels: Map<string, string>,
    vehicleTypesLabels: Map<string, string>,
    getValueOfParsedJson: (jsonValues: any, nameField: string) => any,
    reOrderFields: (objectElem: any, sortKeyObj) => string[]
}

enum ActionTitle {
    Added = "Ajout camion",
    Modified = "Modification camion",
    Deleted = "Suppression camion"
}

export default class TransportationsChangesComponent extends React.PureComponent<TransportationsChangesComponentProps, {}>{
    cardHeaderTopClass: string;
    cardHeaderBottomClass: string;
    colorTitleClass: string;
    textTitle: string;

    constructor(props: TransportationsChangesComponentProps) {
        super(props);
        this.cardHeaderTopClass = "card-header-top";
        this.cardHeaderBottomClass = "card-header-bottom";

        switch (props.actionType) {
            case EntityChangeAction.Added:
                this.cardHeaderTopClass += " card-header-top-added";
                this.colorTitleClass = "color-added";
                this.textTitle = ActionTitle.Added;
                break;
            case EntityChangeAction.Modified:
                this.cardHeaderTopClass += " card-header-top-modified";
                this.colorTitleClass = "color-modified";
                this.textTitle = ActionTitle.Modified;
                break;
            default:
                this.cardHeaderTopClass += " card-header-top-deleted";
                this.colorTitleClass = "color-deleted";
                this.textTitle = ActionTitle.Deleted;
                break;
        }
    }

    render() {
        return (
            this.props.trackingTransportations && this.props.trackingTransportations.length > 0 ?
                <Box display="flex" flexDirection="column" flexWrap="nowrap">

                    {/* v2 Virer le colorCardHeaderLeft customiser avec un className="<Action>" ex: -> className="Added" */}
                    <Box height="5px" className={this.cardHeaderTopClass} />
                    <Box className={this.cardHeaderBottomClass}>
                        <Card>
                            <CardContent className="my-card-content">
                                <Box display="flex" flexDirection="row" flexWrap="nowrap" pb={1}>
                                    <Box>
                                        <FontAwesomeIcon icon={faTruck} />
                                    </Box>
                                    <Box className="card-title-text" pl={1}>
                                        {this.textTitle}
                                    </Box>
                                </Box>
                                <Box>
                                    <Divider style={{ height: '2px' }} />
                                </Box>
                                {
                                    this.props.actionType === EntityChangeAction.Modified ? (
                                        this.props.trackingTransportations.map((y, index) => {
                                            const oldObjectValuesY = JSON.parse(y.oldValuesJson);
                                            const newObjectValuesY = JSON.parse(y.newValuesJson);
                                            const additionalObjectValuesY = JSON.parse(y.additionalValuesJson);
                                            const myKeys = this.props.reOrderFields(newObjectValuesY, sortKeyTransportation);

                                            return (
                                                <Box pb={1} key={`dctf:${index}`}>
                                                    {
                                                        index > 0 ?
                                                            <Divider style={{ height: '2px' }} /> : ''
                                                    }
                                                    <Box pb={2} key={`dctf:${index}:fm:field-type`}>
                                                        <AddedDeletedProperty vehicleTypesLabels={this.props.vehicleTypesLabels} entityType={OwnedEntity.RequestedTransportation} HiddenProperties={this.props.HiddenProperties} key={`dctffMFirst:0`} elem="VehicleTypeId" getValueOfParsedJson={this.props.getValueOfParsedJson}
                                                            valuesParsedTracking={additionalObjectValuesY} propertyLabels={this.props.propertyLabels} />
                                                    </Box>
                                                    <Box flexDirection="column" justifyContent="flex-start">
                                                        {newObjectValuesY ? myKeys.map((o: any, index4) => (
                                                            <ModifiedPropertyComponent entityType={OwnedEntity.RequestedTransportation} hiddenProperties={this.props.HiddenProperties} key={`dctff:${index4}`} propertyLabels={this.props.propertyLabels} getValueOfParsedJson={this.props.getValueOfParsedJson}
                                                                elem={o} oldValuesParsedTracking={oldObjectValuesY} newValuesParsedTracking={newObjectValuesY} vehicleTypesLabels={this.props.vehicleTypesLabels} />
                                                        )) : ''}
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                    ) : this.props.actionType === EntityChangeAction.Added ? (
                                        this.props.trackingTransportations.map((y, index) => {
                                            const newObjectValuesY = JSON.parse(y.newValuesJson);
                                            const myKeys = this.props.reOrderFields(newObjectValuesY, sortKeyTransportation);
                                            return (
                                                <Box pb={1} key={`dctf:${index}`}>
                                                    {
                                                        index > 0 ?
                                                            <Divider style={{ height: '2px' }} /> : ''
                                                    }
                                                    <Box pb={2} flexDirection="column" justifyContent="flex-start">
                                                        {newObjectValuesY ? myKeys.map((o: any, index4) => (
                                                            <Box pt={1} key={`dctf:${index}:fa:field-others:${index4}`}>
                                                                <AddedDeletedProperty entityType={OwnedEntity.RequestedTransportation} HiddenProperties={this.props.HiddenProperties} key={`dctff:${index4}`} elem={o} getValueOfParsedJson={this.props.getValueOfParsedJson}
                                                                    valuesParsedTracking={newObjectValuesY} propertyLabels={this.props.propertyLabels} vehicleTypesLabels={this.props.vehicleTypesLabels} />
                                                            </Box>
                                                        )) : ''}
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                    ) : (
                                                this.props.trackingTransportations.map((y, index) => {
                                                    const oldObjectValuesY = JSON.parse(y.oldValuesJson);
                                                    const myKeys = this.props.reOrderFields(oldObjectValuesY, sortKeyTransportation);
                                                    return (
                                                        <Box pb={1} key={`dctf:${index}`}>
                                                            {
                                                                index > 0 ?
                                                                    <Divider style={{ height: '2px' }} /> : ''
                                                            }
                                                            <Box pb={2} flexDirection="column" justifyContent="flex-start">
                                                                {oldObjectValuesY ? myKeys.map((o: any, index4) => (
                                                                    <Box pt={1} key={`dctf:${index}:fd:field-others:${index4}`}>
                                                                        <AddedDeletedProperty entityType={OwnedEntity.RequestedTransportation} HiddenProperties={this.props.HiddenProperties} key={`dctff:${index4}`} elem={o} getValueOfParsedJson={this.props.getValueOfParsedJson}
                                                                            valuesParsedTracking={oldObjectValuesY} propertyLabels={this.props.propertyLabels} vehicleTypesLabels={this.props.vehicleTypesLabels} />
                                                                    </Box>
                                                                )) : ''}
                                                            </Box>
                                                        </Box>
                                                    )
                                                })
                                            )
                                }
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
                : ''
        );
    }
}