import { faCheck, faCheckDouble, faClock, faMinus, faMoon, faSun, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn as Column, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderCellProps, GridItemChangeEvent, GridPageChangeEvent, GridRowProps, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { Switch, SwitchChangeEvent } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import { EditableHeaderComponent } from 'src/shared/components/Common/EditableHeaderComponent';
import { PlanningVehicleStatusComponent } from '../../../shared/components/PlanningVehicleStatus/PlanningVehicleStatusComponent';
import { AppModule, LocalStorage, SessionStorage } from '../../../utils/Storage';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';
import { PublishedCostsStatus } from '../models/PublishedCostsStatus';
import { SizingUtilities } from '../SizingUtilities';
import { CustomCheckboxCell } from './CustomCheckboxCell';
import { CustomHeaderCheckBoxCellComponent } from './CustomHeaderCheckBoxCellComponent';
import RemarksCell from './RemarksCell';

interface PlanningVehiclesSelectorProps {
    percentHeight: number,
    planningVehiclesList: Array<PlanningVehicleLightModelExtended>,
    planningVehiclesSelected: Array<PlanningVehicleLightModelExtended>,
    rowRender: (trElement: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps) => React.ReactElement,
    cellRender: (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps) => React.ReactElement,
    selectionChange: (checked: boolean, dataItem: PlanningVehicleLightModelExtended) => void,
    headerSelectAllChanged: (planningVehiclesList: Array<PlanningVehicleLightModelExtended>, selectAllChecked: boolean) => void,
    handleChangeFeesEnabled: (event: SwitchChangeEvent, planningVehicleId: string) => void,
    handleChangePurchaseCostsEnabled: (event: SwitchChangeEvent, planningVehicleId: string) => void,
    handleChangeHeaderFeesEnabled: (checked: boolean) => void,
    handleChangeHeaderPurchaseCostsEnabled: (checked: boolean) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const PurchaseProvisioningsGridName = 'purchaseProvisionings';
const ModuleKey = AppModule.PurchaseProvisionings;

export const PlanningVehiclesSelectorComponent = (props: PlanningVehiclesSelectorProps) => {

    const purchaseProvisioningsGridSortDescriptorsStorageKey = "purchase_provisionings_sortItem";
    const storedSortDescriptors = SessionStorage.GetItem(ModuleKey, purchaseProvisioningsGridSortDescriptorsStorageKey);
    const initialSort: SortDescriptor[] = storedSortDescriptors ? JSON.parse(storedSortDescriptors) : [{ field: 'planningDate', dir: 'desc' }];
    const [skip, setSkip] = useState<number>(0);
    const [sort, setSort] = useState<Array<SortDescriptor>>(initialSort);

    const forceUpdate = useForceUpdate();

    const getPlanningVehiclesGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, PurchaseProvisioningsGridName, fieldName, defaultIndex);
    }

    const getPlanningVehiclesGridWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, PurchaseProvisioningsGridName, fieldName, defaultWidth);
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip ? event.page.skip : 0);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            PurchaseProvisioningsGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, PurchaseProvisioningsGridName, event.columns);
        forceUpdate();
    }

    const itemChange = (event: GridItemChangeEvent): void => {
        event.dataItem[event.field] = event.value;
        forceUpdate();
    }

    const handleSortEditableColumnChange = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, field: string): void => {
        let sortItem: SortDescriptor[] = null;
        if (sort.length > 0 && sort[0].dir === 'asc' && sort[0].field === field) {
            sortItem = [{ field: field, dir: 'desc' }];
        } else if (sort.length > 0 && sort[0].dir === 'desc' && sort[0].field === field) {
            sortItem = [];
        } else {
            sortItem = [{ field: field, dir: 'asc' }];
        }
        setSort(sortItem);
    }

    const handleSortColumnChange = (sortItems: SortDescriptor[]): void => {
        setSort(sortItems);
    }

    const { planningVehiclesList, planningVehiclesSelected, cellRender, rowRender, selectionChange, headerSelectAllChanged
        , handleChangeFeesEnabled, handleChangeHeaderFeesEnabled, handleChangePurchaseCostsEnabled, handleChangeHeaderPurchaseCostsEnabled } = props;

    const headerPurchaseCostsSelected = !planningVehiclesSelected.find(x => x.purchaseCostEnabled === false && !x.costPurchaseProvisioningBusinessId && x.purchaseCost);
    const headerFeesSelected = !planningVehiclesSelected.find(x => x.feesEnabled === false && !x.feesPurchaseProvisioningBusinessId && x.fees);

    const headerFeesShow = planningVehiclesSelected.find(x => x.fees && !x.feesPurchaseProvisioningBusinessId) !== undefined;
    const headerPurchaseCostsShow = planningVehiclesSelected.find(x => x.purchaseCost && !x.costPurchaseProvisioningBusinessId) !== undefined;

    const availablePlanningVehicles = planningVehiclesList.filter(x => x.isEnabled);

    const headerSelectedCheckedAll = availablePlanningVehicles.length == planningVehiclesSelected.length;
    const headerSelectedIndeterminate = planningVehiclesSelected.length > 0 && availablePlanningVehicles.length > planningVehiclesSelected.length;

    const availablePurchaseCosts = planningVehiclesSelected.filter(x => x.purchaseCost && !x.costPurchaseProvisioningBusinessId);
    const unavailablePurchaseCosts = planningVehiclesSelected.filter(x => x.purchaseCostEnabled === false && x.purchaseCost && !x.costPurchaseProvisioningBusinessId);

    const headerPurchaseCostsIndeterminate = headerPurchaseCostsSelected ? false : unavailablePurchaseCosts.length < availablePurchaseCosts.length;

    const availableFees = planningVehiclesSelected.filter(x => x.fees && !x.feesPurchaseProvisioningBusinessId);
    const unavailableFees = planningVehiclesSelected.filter(x => x.feesEnabled === false && x.fees && !x.feesPurchaseProvisioningBusinessId);

    const headerFeesIndeterminate = headerFeesSelected ? false : unavailableFees.length < availableFees.length;

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = (SizingUtilities.computeGridHeight(gridOffsetFromWindowTop) * props.percentHeight);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = planningVehiclesList.length;
    const resize = (): void => {
        gridHeight = (SizingUtilities.computeGridHeight(gridOffsetFromWindowTop) * props.percentHeight);
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridStyle: React.CSSProperties = { height: gridHeight };
    const gridData: Array<PlanningVehicleLightModelExtended> = orderBy(planningVehiclesList, sort).slice(skip, skip + gridPageSize);

    return (
        <Box id="vehicle-provisionings-grid-id" className="vehicles-provisionings">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Grid
                        className="grid"
                        data={gridData}
                        sortable={true}
                        reorderable
                        resizable
                        sort={sort}
                        selectedField="selected"
                        cellRender={cellRender}
                        rowRender={rowRender}
                        onItemChange={itemChange}
                        onColumnResize={onResizeHandler}
                        onColumnReorder={(e) => onReorderHandler(e)}
                        onSortChange={handleSortChange}
                        editField="inEdit"
                        scrollable="virtual"
                        skip={skip}
                        total={totalGrid}
                        pageSize={gridPageSize}
                        onPageChange={pageChange}
                        rowHeight={rowHeight}
                        style={gridStyle}
                    >
                        <Column field="selected" headerClassName="cell-checkbox" orderIndex={getPlanningVehiclesGridOrderIndexColumn("selected", 0)} width={40}
                            headerCell={(headerProps: GridHeaderCellProps) =>
                                <CustomHeaderCheckBoxCellComponent
                                    field={headerProps.field}
                                    label=""
                                    showCheckbox={true}
                                    checkedAll={headerSelectedCheckedAll}
                                    indeterminateAll={headerSelectedIndeterminate}
                                    sort={sort}
                                    handleSortColumnChange={handleSortColumnChange}
                                    tooltipMessage="Activer/Désactiver tous les camions"
                                    onSelectionChange={(checked: boolean) => headerSelectAllChanged(null, checked)}
                                />
                            }
                            cell={(props: GridCellProps) =>
                                <CustomCheckboxCell
                                    {...props}
                                    field={props.field}
                                    onSelectionChange={selectionChange}
                                />}
                        />
                        <Column field="isNightWork"
                            cell={(p: GridCellProps) => {
                                return <td>
                                    {p.dataItem.isNightWork == true ? (
                                        <FontAwesomeIcon size="lg" icon={faMoon} className="icon-work" />
                                    ) :
                                        <FontAwesomeIcon size="lg" icon={faSun} className="icon-work" />
                                    }
                                </td>
                            }}
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("isNightWork", 1)} title=" " editable={false} width={getPlanningVehiclesGridWidth("isNightWork", 35)} />
                        <Column field="statusOrderValue" orderIndex={getPlanningVehiclesGridOrderIndexColumn("statusOrderValue", 2)} width={getPlanningVehiclesGridWidth("statusOrderValue", 50)} title="Statut" editable={false}
                            cell={(properties: GridCellProps) => <td>
                                <PlanningVehicleStatusComponent
                                    isCanceled={properties.dataItem.isCanceled}
                                    transporterConfirmationDate={properties.dataItem.orderConfirmationDate}
                                    transporterOrderId={properties.dataItem.transporterOrderId}
                                    transporterIsInternal={properties.dataItem.isInternal}
                                    cancellationReason={properties.dataItem.cancellationReason}
                                    cancellationRemarks={properties.dataItem.cancellationRemarks}
                                    purchaseIsCanceled={properties.dataItem.purchaseIsCanceled}
                                    saleIsCanceled={properties.dataItem.saleIsCanceled}
                                    cancellationOrderSentDate={properties.dataItem.cancellationOrderSentDate}
                                    cancellationOrderConfirmationDate={properties.dataItem.cancellationOrderConfirmationDate}
                                />
                            </td>}
                        />
                        <Column field="vehicleLicencePlate" orderIndex={getPlanningVehiclesGridOrderIndexColumn("vehicleLicencePlate", 3)} width={getPlanningVehiclesGridWidth("vehicleLicencePlate", 100)} title="Camion" editable={false} />
                        <Column field="vehicleNumber" orderIndex={getPlanningVehiclesGridOrderIndexColumn("vehicleNumber", 4)} width={getPlanningVehiclesGridWidth("vehicleNumber", 50)} title="N°" editable={false} />
                        <Column field="vehicleEquipmentId" orderIndex={getPlanningVehiclesGridOrderIndexColumn("vehicleEquipmentId", 5)} width={getPlanningVehiclesGridWidth("vehicleEquipmentId", 80)} title="IDGM" editable={false} />
                        <Column field="planningDate" orderIndex={getPlanningVehiclesGridOrderIndexColumn("planningDate", 6)} width={getPlanningVehiclesGridWidth("planningDate", 120)} title="Date de livraison" format="{0:dd/MM/yyyy}" editable={false} />
                        <Column field="transporterName" orderIndex={getPlanningVehiclesGridOrderIndexColumn("transporterName", 7)} width={getPlanningVehiclesGridWidth("transporterName", 200)} title="Transporteur" editable={false} />
                        <Column field="expectedVehicleTypeLabel" orderIndex={getPlanningVehiclesGridOrderIndexColumn("expectedVehicleTypeLabel", 8)} width={getPlanningVehiclesGridWidth("expectedVehicleTypeLabel", 120)} title="Type véhicule" editable={false} />
                        <Column field="publishedCostsStatus"
                            cell={(p: GridCellProps) => {
                                const dataItem: PlanningVehicleLightModelExtended = p.dataItem;
                                    return dataItem.costsDisagreementReason 
                                        ? <Tooltip title={dataItem.costsDisagreementReason} placement="bottom-start">
                                            <td className="preInvoice-icon">
                                                {
                                                    dataItem.publishedCostsStatus === PublishedCostsStatus.Agreed
                                                        ? <FontAwesomeIcon className="icon-success" size="lg" icon={faCheckDouble} />
                                                        : dataItem.publishedCostsStatus === PublishedCostsStatus.Disagreed
                                                            ? <FontAwesomeIcon className="icon-error" size="lg" icon={faTimes} />
                                                            : <FontAwesomeIcon className="icon-minus" size="lg" icon={faMinus} />
                                                }
                                            </td>
                                        </Tooltip>
                                        : <td className="preInvoice-icon">
                                            {
                                                dataItem.publishedCostsStatus === PublishedCostsStatus.Agreed
                                                ? <FontAwesomeIcon className="icon-success" size="lg" icon={faCheck} />
                                                : dataItem.publishedCostsStatus === PublishedCostsStatus.Pending
                                                    ? <FontAwesomeIcon className="icon-pending" size="lg" icon={faClock} />
                                                    : dataItem.publishedCostsStatus === PublishedCostsStatus.Disagreed
                                                        ? <FontAwesomeIcon className="icon-error" size="lg" icon={faTimes} />
                                                        : <FontAwesomeIcon className="icon-minus" size="lg" icon={faMinus} />
                                            }
                                        </td>
                                    }}
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("publishedCostsStatus", 9)} width={getPlanningVehiclesGridWidth("publishedCostsStatus", 50)} headerClassName="preInvoice-header" title="Préfac" editable={false} />
                        <Column field="purchaseCost"
                            headerCell={(p: GridHeaderCellProps) => {
                                return (
                                    <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                        <CustomHeaderCheckBoxCellComponent
                                            field={p.field}
                                            label="Coût"
                                            showCheckbox={headerPurchaseCostsShow}
                                            checkedAll={headerPurchaseCostsSelected}
                                            indeterminateAll={headerPurchaseCostsIndeterminate}
                                            sort={sort}
                                            handleSortColumnChange={handleSortColumnChange}
                                            tooltipMessage="Activer/Désactiver tous les coûts des camions"
                                            onSelectionChange={handleChangeHeaderPurchaseCostsEnabled}
                                        />
                                    </Box>)
                            }}
                            cell={(p: GridCellProps) => {
                                const dataItem: PlanningVehicleLightModelExtended = p.dataItem;
                                return <td>
                                    {dataItem.purchaseCost ?
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            <Box className={(dataItem.selected && !dataItem.costPurchaseProvisioningBusinessId) ? "cell-price-half" : "cell-price-full"} minWidth="40px">
                                                {dataItem.purchaseCost.toCurrencyString()}
                                            </Box>
                                            {(dataItem.selected && !dataItem.costPurchaseProvisioningBusinessId) && <Box width="50%">
                                                <Switch
                                                    checked={dataItem.purchaseCostEnabled}
                                                    onChange={(e: SwitchChangeEvent) => handleChangePurchaseCostsEnabled(e, dataItem.planningVehicleId)}
                                                />
                                            </Box>}
                                        </Box>
                                        : <span></span>}
                                </td>
                            }}
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("purchaseCost", 10)} width={getPlanningVehiclesGridWidth("purchaseCost", 120)} title="Coût" editable={false} />
                        <Column field="fees"
                            headerCell={(p: GridHeaderCellProps) => {
                                return (
                                    <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                        <CustomHeaderCheckBoxCellComponent
                                            label="Frais"
                                            showCheckbox={headerFeesShow}
                                            field={p.field}
                                            checkedAll={headerFeesSelected}
                                            indeterminateAll={headerFeesIndeterminate}
                                            sort={sort}
                                            handleSortColumnChange={handleSortColumnChange}
                                            tooltipMessage="Activer/Désactiver tous les frais des camions"
                                            onSelectionChange={handleChangeHeaderFeesEnabled}
                                        />
                                    </Box>)
                            }}
                            cell={(p: GridCellProps) => {
                                const dataItem: PlanningVehicleLightModelExtended = p.dataItem;
                                return <td>
                                    {dataItem.fees ?
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            <Box className={(dataItem.selected && !dataItem.feesPurchaseProvisioningBusinessId) ? "cell-price-half" : "cell-price-full"} minWidth="40px">
                                                {dataItem.fees.toCurrencyString()}
                                            </Box>
                                            {(dataItem.selected && !dataItem.feesPurchaseProvisioningBusinessId) &&
                                                <Box width="50%">
                                                    <Switch
                                                        checked={dataItem.feesEnabled}
                                                        onChange={(e: SwitchChangeEvent) => handleChangeFeesEnabled(e, dataItem.planningVehicleId)}
                                                    />
                                                </Box>}
                                        </Box>
                                        : <span></span>}
                                </td>
                            }}
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("fees", 11)} width={getPlanningVehiclesGridWidth("fees", 120)} title="Frais" editable={false} />
                        <Column field="totalCosts"
                            cell={(p: GridCellProps) => {
                                return (<td>
                                    {p.dataItem.totalCosts ? p.dataItem.totalCosts.toCurrencyString() : ""}
                                </td>)
                            }}
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("totalCosts", 12)} width={getPlanningVehiclesGridWidth("totalCosts", 100)} title="Total" editable={false} />
                        <Column field="costDifference"
                            cell={(p: GridCellProps) => {
                                const dataItem: PlanningVehicleLightModelExtended = p.dataItem;
                                let className = dataItem.hasDiffFromPurchaseProvisioningCost ? 'field-different-value-purchaseProvisioning' : '';
                                if (dataItem.hasDiffFromPurchaseProvisioningCost && dataItem.costDifference < 0) {
                                    className += ' negative-price';
                                }

                                return <td>
                                    <div className={className}>
                                        {dataItem.costDifference?.toCurrencyString()}
                                    </div>
                                </td>
                            }}
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("costDifference", 13)} width={getPlanningVehiclesGridWidth("costDifference", 100)} title="Ecart coût" editable={false} />
                        <Column field="feesDifference"
                            cell={(p: GridCellProps) => {
                                const dataItem: PlanningVehicleLightModelExtended = p.dataItem;
                                let className = dataItem.hasDiffFromPurchaseProvisioningFees ? 'field-different-value-purchaseProvisioning' : '';
                                if (dataItem.hasDiffFromPurchaseProvisioningFees && dataItem.feesDifference < 0) {
                                    className += ' negative-price';
                                }

                                return <td>
                                    <div className={className}>
                                        {dataItem.feesDifference?.toCurrencyString()}
                                    </div>
                                </td>
                            }}
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("feesDifference", 14)} width={getPlanningVehiclesGridWidth("feesDifference", 100)} title="Ecart frais" editable={false} />
                        <Column field="planningVehicleRemarks"
                            headerCell={() => <EditableHeaderComponent field="planningVehicleRemarks" sort={sort} _handleSortEditableColumnChange={handleSortEditableColumnChange} title="Commentaire camion" />}
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("planningVehicleRemarks", 15)} width={getPlanningVehiclesGridWidth("planningVehicleRemarks", 160)} title="Commentaire camion" editable={true}
                            cell={(props: GridCellProps) => <RemarksCell {...props} width={getPlanningVehiclesGridWidth("planningVehicleRemarks", 200)} />} />
                        <Column field="costsRemarksOfLogistician"
                            headerCell={() => <EditableHeaderComponent field="costsRemarksOfLogistician" sort={sort} _handleSortEditableColumnChange={handleSortEditableColumnChange} title="Commentaire préfac" />} className="remarks-cell"
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("costsRemarksOfLogistician", 16)} width={getPlanningVehiclesGridWidth("costsRemarksOfLogistician", 160)} title="Commentaire préfac" editable={true}
                            cell={(props: GridCellProps) => <RemarksCell {...props} width={getPlanningVehiclesGridWidth("costsRemarksOfLogistician", 160)} />} />
                        <Column field="costPurchaseProvisioningBusinessId" orderIndex={getPlanningVehiclesGridOrderIndexColumn("costPurchaseProvisioningBusinessId", 17)} width={getPlanningVehiclesGridWidth("costPurchaseProvisioningBusinessId", 70)} title="Lot coût" editable={false}
                            cell={(p: GridCellProps) => {
                                let className = "";
                                if (p.dataItem.costPurchaseProvisioningBusinessId) {
                                    if (p.dataItem.costPurchaseProvisioningSentSuccessfully === true) {
                                        className = "purchase-provisioning-sent-successfully";
                                    }
                                    else if (p.dataItem.costPurchaseProvisioningSentSuccessfully === false) {
                                        className = "purchase-provisioning-sent-error";
                                    }
                                }
                                return (<td className={className}>
                                    {p.dataItem.costPurchaseProvisioningBusinessId}
                                </td>)
                            }} />
                        <Column field="feesPurchaseProvisioningBusinessId" orderIndex={getPlanningVehiclesGridOrderIndexColumn("feesPurchaseProvisioningBusinessId", 18)} width={getPlanningVehiclesGridWidth("feesPurchaseProvisioningBusinessId", 70)} title="Lot frais" editable={false}
                            cell={(p: GridCellProps) => {
                                let className = "";
                                if (p.dataItem.feesPurchaseProvisioningBusinessId) {
                                    if (p.dataItem.feesPurchaseProvisioningSentSuccessfully === true) {
                                        className = "purchase-provisioning-sent-successfully";
                                    }
                                    else if (p.dataItem.feesPurchaseProvisioningSentSuccessfully === false) {
                                        className = "purchase-provisioning-sent-error";
                                    }
                                }
                                return (<td className={className}>
                                    {p.dataItem.feesPurchaseProvisioningBusinessId}
                                </td>)
                            }} />
                        <Column field="regularizationReferences"
                            orderIndex={getPlanningVehiclesGridOrderIndexColumn("regularizationReferences", 19)} width={getPlanningVehiclesGridWidth("regularizationReferences", 160)} title="Reg." editable={false}
                            cell={(p: GridCellProps) => {
                                const dataItem: PlanningVehicleLightModelExtended = p.dataItem;

                                return (
                                    <td>
                                        {
                                            dataItem.regularizationReferences.map((refReg, index) => {
                                                const businessIdClassName = refReg.sentSuccessfully === true
                                                    ? "purchase-provisioning-sent-successfully"
                                                    : refReg.sentSuccessfully === false
                                                        ? "purchase-provisioning-sent-error"
                                                        : "";

                                                return (
                                                    <span key={`ref-reg-${index}`}>
                                                        {index > 0 ? ', ' : ''}
                                                        <span className={businessIdClassName}>
                                                            {refReg.businessId}
                                                        </span>
                                                    </span>
                                                )
                                            })
                                        }
                                    </td>
                                )
                            }} />
                        <Column field="contractualPurchasePriceContractNumber" orderIndex={getPlanningVehiclesGridOrderIndexColumn("contractualPurchasePriceContractNumber", 20)} width={getPlanningVehiclesGridWidth("contractualPurchasePriceContractNumber", 60)} title="PAC" editable={false} />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}
