import axios, { AxiosResponse } from 'axios';
import { PagedResult } from 'src/shared/models/PagedResult';
import { WebAppActionResult, WebAppActionResultEx } from 'src/shared/models/WebAppActionResult';
import { AssignContractualPricedTransporterVehicleTypeRequestArgs } from './dataContracts/controller/AssignContractualPricedTransporterVehicleTypeRequestArgs';
import { AssignDailyPlanningVehicleRequestArgs } from './dataContracts/controller/AssignDailyPlanningVehicleRequestArgs';
import { AssignGenericVehicleTypeRequestArgs } from './dataContracts/controller/AssignGenericVehicleTypeRequestArgs';
import { AssignInternalVehicleRequestArgs } from './dataContracts/controller/AssignInternalVehicleRequestArgs';
import { AssignTransporterVehicleTypeRequestArgs } from './dataContracts/controller/AssignTransporterVehicleTypeRequestArgs';
import { CancelPlanningVehicleRequestArgs } from './dataContracts/controller/CancelPlanningVehicleRequestArgs';
import { DeliveryTripChangeRequestArgs } from './dataContracts/controller/DeliveryTripChangeRequestArgs';
import { DuplicatePlanningVehicleRequestArgs } from './dataContracts/controller/DuplicatePlanningVehicleRequestArgs';
import { ExpeditionsSearchRequestArgs } from './dataContracts/controller/ExpeditionsSearchRequestArgs';
import { PlanningVehicleChangeRequestArgs } from "./dataContracts/controller/PlanningVehicleChangeRequestArgs";
import { ReinstatePlanningVehicleRequestArgs } from './dataContracts/controller/ReinstatePlanningVehicleRequestArgs';
import { SendDriverFreeSmsRequestArgs } from './dataContracts/controller/SendDriverFreeSmsRequestArgs';
import { SendSmsNotificationsRequestArgs } from './dataContracts/controller/SendSmsNotificationsRequestArgs';
import { TextSearchField } from './dataContracts/controller/TextSearchField';
import { TripDragToNewLoadingTimeRequestArgs } from "./dataContracts/controller/TripDragToNewLoadingTimeRequestArgs";
import { TripResizeToNewDeliveryTimeRequestArgs } from "./dataContracts/controller/TripResizeToNewDeliveryTimeRequestArgs";
import { TripsMoveToNewPlanningVehicleRequestArgs } from './dataContracts/controller/TripsMoveToNewPlanningVehicleRequestArgs';
import { DriverSmsNotification } from './dataContracts/queryStack/DriverSmsNotification';
import { PlanningVehicleTripsLightModel } from './dataContracts/queryStack/PlanningVehicleTripsLightModel';
import { PurchasePriceLightModel } from './dataContracts/queryStack/PurchasePriceLightModel';
import { TransportFlowLightModel } from './dataContracts/queryStack/TransportFlowLightModel';
import { VehicleTypeLightModel } from './dataContracts/queryStack/VehicleTypeLightModel';
import { AssignDeliveryTripsStatusArgs } from "./dataContracts/controller/AssignDeliveryTripsStatusArgs";
import { RemoveDeliveryTripsArgs } from "./dataContracts/controller/RemoveDeliveryTripsArgs";
import { UpdateSpecificPriceOfDeliveryTripsRequestArgs } from './dataContracts/controller/UpdateSpecificPriceOfDeliveryTripsRequestArgs';
import { RemoveSpecificPriceFromDeliveryTripsRequestArgs } from './dataContracts/controller/RemoveSpecificPriceFromDeliveryTripsRequestArgs';
import { OrphanDeliveryNotes } from './dataContracts/controller/OrphanDeliveryNotes';
import { AssociateOrphanDeliveryNoteRequestArgs } from './dataContracts/controller/AssociateOrphanDeliveryNoteRequestArgs';

const controllerUrl = 'api/OperationalMonitoring/';

export class OperationalMonitoringApiClient {

    public static GetExpeditions = (pageSize: number, pageIndex: number, searchText: string, searchTextFields: Array<TextSearchField>, fromTime: Date, logisticsUnits: Array<string>, productionForemanUserName: string, dispatcherUserName: string)
        : Promise<AxiosResponse<PagedResult<PlanningVehicleTripsLightModel>>> => {

        const requestArgs: ExpeditionsSearchRequestArgs = {
            pageSize: pageSize,
            pageIndex: pageIndex,
            searchText: searchText,
            fromTime: fromTime,
            logisticsUnits: logisticsUnits,
            productionForemanUserName: productionForemanUserName,
            dispatcherUserName: dispatcherUserName,
            searchTextFields: searchTextFields
        }

        return axios.post(`${controllerUrl}Search`,
            JSON.stringify(requestArgs), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    public static GetOverheadExpensesSum = (planningDate: Date, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<number>> => {
        return axios.post(`${controllerUrl}GetOverheadExpensesSum?planningDate=${encodeURIComponent(planningDate.toJSON())}`,
            JSON.stringify(logisticsUnits), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    public static GetExpeditionsWithOverheadExpensesSum(pageSize: number, pageIndex: number, searchText: string, searchTextFields: Array<TextSearchField>, fromTime: Date, logisticsUnits: Array<string>, productionForeman: string, dispatcherUserName: string)
        : Promise<[AxiosResponse<PagedResult<PlanningVehicleTripsLightModel>>, AxiosResponse<number>]> {
        return Promise.all([
            this.GetExpeditions(pageSize, pageIndex, searchText, searchTextFields, fromTime, logisticsUnits, productionForeman, dispatcherUserName),
            this.GetOverheadExpensesSum(fromTime, logisticsUnits)
        ]);
    }

    public static GetAvailablePurchasePrices = (pageSize: number, pageIndex: number, planningVehicleId: string, fromTime: Date, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<Array<PurchasePriceLightModel>>> => {
        return axios.post(`${controllerUrl}SearchAvailablePurchasePrices?fromTime=${encodeURIComponent(fromTime.toJSON())}&planningVehicleId=${encodeURIComponent(planningVehicleId)}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
            JSON.stringify(logisticsUnits), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    public static ExistsOverridenSpecificOrNegociatedPrices = (deliveryTripIds: Array<number>)
        : Promise<AxiosResponse<boolean>> => {
        return axios.post(`${controllerUrl}ExistsOverridenSpecificOrNegociatedPrices`,
            JSON.stringify(deliveryTripIds), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    public static SearchTransportFlow = (searchText: string, selectedDate: Date, addedDaysNumber: number, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<Array<TransportFlowLightModel>>> => {
        return axios.post(`${controllerUrl}SearchTransportFlow?selectedDate=${encodeURIComponent(selectedDate.toJSON())}&searchText=${encodeURIComponent(searchText)}&addedDaysNumber=${encodeURIComponent(addedDaysNumber)}`,
            JSON.stringify(logisticsUnits), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    public static CreateTripFromFlow = (planningVehicleId: string, transportFlowId: string, transportRequestId: string, deliveryTime: Date, tripsNumber: number, tripsNumberIsForced: boolean)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(`${controllerUrl}CreateTripFromFlow?planningVehicleId=${encodeURIComponent(planningVehicleId)}&transportFlowId=${encodeURIComponent(transportFlowId)}&transportRequestId=${encodeURIComponent(transportRequestId)}&deliveryTime=${encodeURIComponent(deliveryTime.toJSON())}&tripsNumber=${tripsNumber}&tripsNumberIsForced=${tripsNumberIsForced}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    public static CreatePlanningVehicles = (selectedDate: Date, buyerLogisticsUnitId: string, vehicleTypeId: string, isNightWork: boolean, numberOfVehicles: number)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(`${controllerUrl}CreatePlanningVehicles?selectedDate=${encodeURIComponent(selectedDate.toJSON())}&buyerLogisticsUnitId=${encodeURIComponent(buyerLogisticsUnitId)}&vehicleTypeId=${encodeURIComponent(vehicleTypeId)}&isNightWork=${isNightWork}&numberOfVehicles=${numberOfVehicles}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    public static RemovePlanningVehicle = (planningVehicleId: string, planningId: number, hasOrderLineConfirmed: boolean, hasAtLeastOneDeliveryTripConfirmed: boolean, logisticsUnits: Array<string>)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        return axios.post(`${controllerUrl}RemovePlanningVehicle?planningVehicleId=${encodeURIComponent(planningVehicleId)}&planningId=${encodeURIComponent(planningId)}&hasOrderLineConfirmed=${encodeURIComponent(hasOrderLineConfirmed)}&hasAtLeastOneDeliveryTripConfirmed=${encodeURIComponent(hasAtLeastOneDeliveryTripConfirmed)}`,
            JSON.stringify(logisticsUnits), config)
    }

    public static DuplicatePlanningVehicle = (requestArgs: DuplicatePlanningVehicleRequestArgs)
        : Promise<AxiosResponse<WebAppActionResultEx<Array<number>>>> => {

        return axios({
            method: 'POST',
            url: `${controllerUrl}DuplicatePlanningVehicle`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static RemoveEmptyPlanningVehicles = (selectedDate: Date, planningVehicleIds: Array<string>)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(`${controllerUrl}RemoveEmptyPlanningVehicles?selectedDate=${encodeURIComponent(selectedDate.toJSON())}`,
            JSON.stringify(planningVehicleIds), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

    }

    public static UpdatePlanningVehicleRemarks = (planningId: number, planningVehicleId: string, planningVehicleRemarks: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const planningVehicleChange: PlanningVehicleChangeRequestArgs = {
            planningId: planningId,
            planningVehicleId: planningVehicleId,
            planningVehicleRemarks: planningVehicleRemarks
        }
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdatePlanningVehicleRemarks`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(planningVehicleChange)
        });
    }

    public static DragTripsToNewLoadingTime = (tripsToDrag: Array<TripDragToNewLoadingTimeRequestArgs>, hasConfirmedSpecificPriceRemoval: boolean)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}DragTripsToNewLoadingTime?hasConfirmedSpecificPriceRemoval=${hasConfirmedSpecificPriceRemoval}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(tripsToDrag)
        });
    }

    public static MoveTripsToNewPlanningVehicle = (requestArgs: TripsMoveToNewPlanningVehicleRequestArgs, hasConfirmedSpecificPriceRemoval: boolean)
        : Promise<AxiosResponse<WebAppActionResult>> => {

        return axios({
            method: 'POST',
            url: `${controllerUrl}MoveTripsToNewPlanningVehicle?hasConfirmedSpecificPriceRemoval=${hasConfirmedSpecificPriceRemoval}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static ResizeTripToNewDeliveryTime = (tripDragResizeToNewLoadingDeliveryTime: TripResizeToNewDeliveryTimeRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const _headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        return axios.post(`${controllerUrl}ResizeTripToNewDeliveryTime`, tripDragResizeToNewLoadingDeliveryTime, { headers: _headers });
    }

    public static UpdateIsHiddenForCreateTripFromFlow = (transportFlowId: string, isHidden: boolean)
        : Promise<AxiosResponse> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateIsHiddenForCreateTripFromFlow?transportFlowId=${transportFlowId}&isHidden=${isHidden}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static AssignDeliveryTripStatus = (assignDeliveryTripsStatusArgs: AssignDeliveryTripsStatusArgs)
        : Promise<AxiosResponse<WebAppActionResultEx<Array<number>>>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AssignDeliveryStatus`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(assignDeliveryTripsStatusArgs)
        });
    }

    public static AssignGenericVehicleType = (requestArgs: AssignGenericVehicleTypeRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(`${controllerUrl}AssignGenericVehicleType`,
            JSON.stringify(requestArgs), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    public static AssignTransporterVehicleType = (requestArgs: AssignTransporterVehicleTypeRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(`${controllerUrl}AssignTransporterVehicleType`,
            JSON.stringify(requestArgs), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    public static AssignInternalVehicle = (requestArgs: AssignInternalVehicleRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(`${controllerUrl}AssignInternalVehicle`,
            JSON.stringify(requestArgs), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    public static AssignDailyPlanningVehicle = (planningVehicleReference: AssignDailyPlanningVehicleRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(`${controllerUrl}AssignDailyPlanningVehicle`,
            JSON.stringify(planningVehicleReference), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    public static AssignContractualPricedTransporterVehicleType = (requestArgs: AssignContractualPricedTransporterVehicleTypeRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(`${controllerUrl}AssignContractualPricedTransporterVehicleType`,
            JSON.stringify(requestArgs), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    public static RemoveContractualPurchasePriceFromPlanningVehicle = (planningVehicleId: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios.post(`${controllerUrl}RemoveContractualPurchasePriceFromPlanningVehicle?planningVehicleId=${planningVehicleId}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }

    public static RemoveDeliveryTrips = (requestArgs: RemoveDeliveryTripsArgs)
        : Promise<AxiosResponse<WebAppActionResultEx<{ removedDeliveryTrips: Array<number>, flowsToHide: Array<string> }>>> => {
        return axios.post(`${controllerUrl}RemoveDeliveryTrips`,
            JSON.stringify(requestArgs), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static GetVehicleTypes()
        : Promise<AxiosResponse<Array<VehicleTypeLightModel>>> {
        return axios.get(`${controllerUrl}GetVehicleTypes`);
    }

    public static UpdateDeliveryTrip = (deliveryTripChange: DeliveryTripChangeRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateDeliveryTrip`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(deliveryTripChange)
        });
    }

    public static UpdateTransporterInstructions = (transportRequestId: string, transportFlowId: string, transporterInstructions: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateTransporterInstructions?transportRequestId=${encodeURIComponent(transportRequestId)}&transportFlowId=${encodeURIComponent(transportFlowId)}&transporterInstructions= ${encodeURIComponent(transporterInstructions)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static SendPlanningBriefingsSmsToDrivers = (requestArgs: SendSmsNotificationsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const config = {
            headers: {
                'Accept': 'application / json',
                'Content-Type': 'application/json',
            },
            __colas_lorie_disableGenericErrorHandler: true
        }

        const url = controllerUrl + "SendPlanningBriefingsSmsToDrivers";
        return axios.post(url, requestArgs, config);
    }

    public static GetDriverSmsNotifications(planningVehicleId: string, driverId: number)
        : Promise<AxiosResponse<Array<DriverSmsNotification>>> {
        return axios.get(`${controllerUrl}GetDriverSmsNotifications?planningVehicleId=${encodeURIComponent(planningVehicleId)}&driverId=${encodeURIComponent(driverId)}`);
    }

    public static SendFreeSmsToDriver = (requestArgs: SendDriverFreeSmsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        const config = {
            headers: {
                'Accept': 'application / json',
                'Content-Type': 'application/json',
            },
            __colas_lorie_disableGenericErrorHandler: true
        }

        const url = controllerUrl + "SendFreeSmsToDriver";
        return axios.post(url, requestArgs, config);
    }

    public static CancelPlanningVehicle = (requestArgs: CancelPlanningVehicleRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}CancelPlanningVehicle`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static ReinstatePlanningVehicle = (requestArgs: ReinstatePlanningVehicleRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}ReinstatePlanningVehicle`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateSpecificPriceOfDeliveryTrips = (requestArgs: UpdateSpecificPriceOfDeliveryTripsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateSpecificPriceOfDeliveryTrips`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static RemoveSpecificPriceFromDeliveryTrips = (requestArgs: RemoveSpecificPriceFromDeliveryTripsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}RemoveSpecificPriceFromDeliveryTrips`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static GetOrphanDeliveryNotes(businessId: string)
        : Promise<AxiosResponse<Array<OrphanDeliveryNotes>>> {
        return axios.get(`${controllerUrl}GetOrphanDeliveryNotes?businessId=${encodeURIComponent(businessId)}`);
    }

    public static AssociateOrphanDeliveryNote = (requestArgs: AssociateOrphanDeliveryNoteRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AssociateOrphanDeliveryNote`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }
}