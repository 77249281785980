import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { VehicleTypeModel } from '../models/VehicleTypeModel';
import { UpdateInternalVehicleRequestArgs } from '../services/dataContracts/controller/UpdateInternalVehicleRequestArgs';
import { InternalDriverLightModel } from "../services/dataContracts/queryStack/InternalDriverLightModel";
import { InternalVehicleLightModel } from '../services/dataContracts/queryStack/InternalVehicleLightModel';
import { VehicleTypeLightModel } from '../services/dataContracts/queryStack/VehicleTypeLightModel';
import { InternalVehiclesReferentialApiClient } from "../services/InternalVehiclesReferentialApiClient";

interface EditInternalVehicleDialogComponentProps {
    internalVehicleSelected: InternalVehicleLightModel,
    vehicleTypes: VehicleTypeLightModel[],
    handleEditInternalVehicleClick: (requestArgs: UpdateInternalVehicleRequestArgs, driver: InternalDriverLightModel) => void,
    handleEditAgencyForInternalVehicleClick: () => void
}

export const EditInternalVehicleDialogComponent = (props: EditInternalVehicleDialogComponentProps): JSX.Element => {
    const vehicle = props.internalVehicleSelected;
    const [selectedVehicleType, setSelectedVehicleType] = useState<VehicleTypeModel>({ label: vehicle.vehicleTypeLabel, value: vehicle.vehicleTypeId });
    const [enabledValidButton, setEnabledValidButton] = useState<boolean>(false);
    const [drivers, setDrivers] = useState<InternalDriverLightModel[]>([]);
    const [selectedDriver, setSelectedDriver] = useState<InternalDriverLightModel>(vehicle.driver);

    useEffect(() => {
        InternalVehiclesReferentialApiClient.GetInternalDrivers(vehicle.transporterId, true)
            .then(response => {
                const data = response.data;
                if (vehicle.driver?.id && !data.find(d => d.id === vehicle.driver.id)) {
                    data.push(vehicle.driver);
                }

                setDrivers(data.sort((a, b) => a.fullName > b.fullName ? 1 : -1));
            });
    }, []);

    useEffect(() => {
        const vehicleTypeChanged = selectedVehicleType?.value !== vehicle.vehicleTypeId;
        const driverChanged = selectedDriver?.id !== vehicle.driver?.id;
        setEnabledValidButton((vehicleTypeChanged || driverChanged) && selectedVehicleType !== null);
    }, [selectedVehicleType, selectedDriver]);

    const getVehicleTypes = (): VehicleTypeModel[] => {
        const res = props.vehicleTypes.map((t: VehicleTypeLightModel) => ({
            value: t.vehicleTypeId,
            label: t.label,
            loadCapacity: t.loadCapacity
        }));

        const internalVehicleType = res.find(x => x.value === props.internalVehicleSelected.vehicleTypeId);
        if (!internalVehicleType) {
            res.push({
                value: props.internalVehicleSelected.vehicleTypeId,
                label: props.internalVehicleSelected.vehicleTypeLabel,
                loadCapacity: props.internalVehicleSelected.vehicleTypeEstimatedLoadCapacity
            });
        }

        return res;
    }

    const vehicleTypes: VehicleTypeModel[] = getVehicleTypes();

    const handleVehicleTypeChange = (event: VehicleTypeModel): void => {
        setSelectedVehicleType(event);
    }

    const handleDriverChange = (event: InternalDriverLightModel): void => {
        setSelectedDriver(event);
    }

    return (
        <Box display="flex" flexDirection="column" className="edit-content">
            <Box className="vehicle-title">
                Plaque d'immatriculation
            </Box>
            <Box className="licence-plate">
                {props.internalVehicleSelected.licencePlate}
            </Box>
            <Box className={selectedVehicleType !== null ? "title" : "title required"} pt={2}>
                Type de véhicule
            </Box>
            <Box display="flex" flexDirection="row">
                <Select
                    className={selectedVehicleType !== null ? "input-vehicle-type-select" : "input-vehicle-type-select required"}
                    id="vehicle-type"
                    placeholder=""
                    isClearable
                    menuPosition="fixed"
                    menuShouldBlockScroll={true}
                    options={vehicleTypes}
                    value={selectedVehicleType}
                    onChange={(e) => handleVehicleTypeChange(e)}
                />
            </Box>
            <Box className="title" pt={2}>
                Chauffeur Colas à associer
            </Box>
            <Box display="flex" flexDirection="row">
                <Select
                    id="driver"
                    className="input-vehicle-type-select"
                    isMulti={false}
                    isClearable
                    placeholder=""
                    menuPosition="fixed"
                    menuShouldBlockScroll={true}
                    options={drivers}
                    value={selectedDriver}
                    getOptionLabel={d => d.fullName}
                    getOptionValue={d => d.id.toString()}
                    onChange={(e) => handleDriverChange(e)}
                />
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" pt={2}>
                <Button variant="contained" size='small' color="primary" title="Mettre à jour l'agence travaux" disabled={!props.internalVehicleSelected.hasDifferentAgencyOnColasReferential}
                    onClick={() => props.handleEditAgencyForInternalVehicleClick()}>
                    Mettre à jour l'agence travaux
                </Button>
                <Box pl={2}></Box>
                <Button variant="contained" color="primary" title="Modifier le camion interne" disabled={!enabledValidButton}
                    onClick={() => props.handleEditInternalVehicleClick({
                        vehicleId: props.internalVehicleSelected.id,
                        vehicleTypeId: selectedVehicleType.value,
                        driverId: selectedDriver?.id
                    }, selectedDriver)}>
                    Modifier
                </Button>
            </Box>
        </Box>
    );
}
