import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult } from '../../models/WebAppActionResult';
import { UserSignatureRequestArgs } from './dataContracts/controller/UserSignatureRequestArgs';
import { UserSignatureDefaultImageLightModel } from './dataContracts/queryStack/UserSignatureDefaultImageLightModel';
import { UserSignatureLightModel } from './dataContracts/queryStack/UserSignatureLightModel';

const controllerUrl = 'api/UserSignature/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

export class UserSignatureApiClient {

    public static GetUserSignature = (userName: string)
        : Promise<AxiosResponse<UserSignatureLightModel>> => {
        return axios({
            method: 'GET',
            url: controllerUrl + 'GetUserSignature?userName=' + encodeURIComponent(userName),
            headers: headers
        });
    }

    public static GetUserSignatureDefaultImages = ()
        : Promise<AxiosResponse<UserSignatureDefaultImageLightModel[]>> => {
        return axios({
            method: 'GET',
            url: controllerUrl + 'GetUserSignatureDefaultImages',
            headers: headers
        });
    }

    public static GetAttachmentsSaveUrl = (): string => {
        return `${controllerUrl}AddAttachments`;
    }

    public static DeleteAttachment = (attachmentId: string)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}DeleteAttachment?attachmentId=${encodeURIComponent(attachmentId)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static AddOrUpdateUserSignature = (requestArgs: UserSignatureRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AddOrUpdateUserSignature`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: requestArgs
        });
    }
}
