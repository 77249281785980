import { Box, Button } from '@mui/material';
import React from 'react';
import Select from 'react-select';
import { LogisticsUnitChoice } from '../../../../shared/models/LogisticsUnitChoice';
import { LogisticsUnitSelectModel } from '../../../../shared/models/LogisticsUnitSelectModel';

interface ContentHeaderComponentProps {
    logisticsUnits: Array<LogisticsUnitChoice>,
    selectedLogisticsUnit: LogisticsUnitSelectModel,
    isPriceDataChanged: boolean,
    isLogisticUnitHavePurchasePrices: boolean,
    handleLogisticsUnitChange: (event: LogisticsUnitSelectModel) => void,
    handleConfirmSaveData: () => void,
    handleConfirmGeneratePurchasePrices: () => void
}

export const ContentHeaderComponent = (props: ContentHeaderComponentProps): JSX.Element => {
    return (
        <>
            <Box display="flex" flexDirection="row" className="header-content">
                <Box pr={3}>
                    <Select
                        className="logistics-unit-select"
                        id="vehicle-type"
                        isClearable
                        placeholder="Zone logistique"
                        menuPosition="fixed"
                        menuShouldBlockScroll={true}
                        isDisabled={props.logisticsUnits.length < 2}
                        options={props.logisticsUnits.map(x => { return { label: x.label, value: x.logisticsUnitId } })}
                        value={props.selectedLogisticsUnit}
                        onChange={(e) => props.handleLogisticsUnitChange(e)}
                    />
                </Box>
                <Box sx={{
                    paddingRight: "2em"
                }}></Box>
                <Box>
                    <Button variant="contained" color="primary" disabled={props.isLogisticUnitHavePurchasePrices} onClick={() => props.handleConfirmGeneratePurchasePrices()}>
                        Generer tarif
                    </Button>
                </Box>
                <Box sx={{
                    paddingRight: "2em"
                }}></Box>
                <Box>
                    <Button variant="contained" color="primary" disabled={!props.isPriceDataChanged} onClick={() => props.handleConfirmSaveData()}>
                        Sauvegarder
                    </Button>
                </Box>
            </Box>
        </>
    );
}