import { Box } from '@mui/material';
import { CompositeFilterDescriptor, FilterDescriptor, orderBy, process, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridColumnMenuCheckboxFilter, GridColumnMenuProps, GridColumnReorderEvent, GridColumnResizeEvent, GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { AppModule, LocalStorage } from 'src/utils/Storage';
import { OrphanDeliveryNotes } from '../services/dataContracts/controller/OrphanDeliveryNotes';
import { SizingUtilities } from '../SizingUtilities';

interface AttachZephyrFlowManuallyGridComponentProps {
    orphanDeliveryNotes: Array<OrphanDeliveryNotes>,
    handleSelectedOrphanDeliveryNote: (deliveryNoteId: string, lineId: string) => void,
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}


const orphanDeliveryNotesGridComponent = 'orphanDeliveryNotesGridComponent';
const ModuleKey = AppModule.OperationalMonitoring;

export const AttachZephyrFlowManuallyGridComponent = (props: AttachZephyrFlowManuallyGridComponentProps): JSX.Element => {
    const [skip, setSkip] = useState<number>(0);
    const initialSort: SortDescriptor[] = [{ field: 'businessId', dir: 'desc' }];
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const gridRef = useRef<any>();
    const [filter, setFilter] = useState<CompositeFilterDescriptor>({ logic: 'and', filters: [] });
    const [data, setData] = useState(props.orphanDeliveryNotes);
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        setData(process(props.orphanDeliveryNotes, { filter: filter }).data);
    }, [props.orphanDeliveryNotes]);

    const getDeliveryNotesWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, orphanDeliveryNotesGridComponent, fieldName, columnWidth);
    }

    const getDeliveryNotesGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, orphanDeliveryNotesGridComponent, fieldName, defaultIndex);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, orphanDeliveryNotesGridComponent, event.columns);
        forceUpdate();
    }

    const handleRowClick = (deliveryNote): void => {
        const dataItem = deliveryNote.dataItem as OrphanDeliveryNotes;

        if (dataItem.selected) {
            props.handleSelectedOrphanDeliveryNote(null, null);
        }

        else {
            props.handleSelectedOrphanDeliveryNote(dataItem.deliveryNoteId, dataItem.lineId);
        }
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            orphanDeliveryNotesGridComponent,
            currentColumn.field,
            currentColumn.width);
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const handleFilterChange = (event: GridFilterChangeEvent) => {
        setFilter(event.filter);

        let selectedLine = props.orphanDeliveryNotes.find(x => x.selected === true);
        let data = process(props.orphanDeliveryNotes, { filter: event.filter }).data;
        if (selectedLine && !data.find(x => x.deliveryNoteId === selectedLine.deliveryNoteId && x.lineId === selectedLine.lineId)) {
            data.push(selectedLine);
        }

        setData(data);
    };

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = data.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = ((window.innerHeight * 80) / 100) - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: OrphanDeliveryNotes[] = orderBy(data, sort).slice(skip, skip + gridPageSize);

    const checkboxFilter = (columnsprops: GridColumnMenuProps) => {
        let data = cloneDeep(props.orphanDeliveryNotes);

        let field = columnsprops.column.field;
        switch (field) {
            case "businessId":
            case "senderProductionSiteName":
            case "receiverProductionSiteName":
            case "deliveryNoteId":
            case "licencePlate":
            case "colasCompany":
            case "productCode":
            case "deliveryCity":
            case "deliveryAddressLine1":
            case "departureTime":
                data.sort((a, b) => {
                    if (a[field] < b[field]) return -1;
                    if (a[field] > b[field]) return 1;
                    return 0;
                });

                break;

            case "unitPrice":
            case "quantity":
            case "deliveryZipCode":
            case "customerMdmId":
                data.sort((a, b) => a[field] - b[field]);

                break;

            default:
                break;
        }

        return (
            <div className="kendo-checkbox">
                <GridColumnMenuCheckboxFilter
                    {...columnsprops}
                    data={data}
                    expanded={true}
                    searchBoxFilterOperator={"contains"}
                />
            </div>
        );
    }

    const isColumnActive = (field: string) => {
        let hasFilter = false;
        if (filter && filter.filters && filter.filters[0]) {
            hasFilter = (filter.filters as CompositeFilterDescriptor[]).some((rootFilter) => (rootFilter.filters as FilterDescriptor[])[0].field === field);
        }
        return hasFilter;
    };

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="orphan-delivery-notes-grid">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Grid
                        ref={gridRef}
                        className="delivery-notes-grid"
                        data={gridData}
                        selectedField="selected"
                        sortable
                        reorderable
                        resizable
                        sort={sort}
                        onColumnResize={onResizeHandler}
                        onColumnReorder={(e) => onReorderHandler(e)}
                        onSortChange={(e: GridSortChangeEvent) => {
                            setSort(e.sort);
                        }}
                        editField="inEdit"
                        scrollable="virtual"
                        rowHeight={rowHeight}
                        skip={skip}
                        total={totalGrid}
                        pageSize={gridPageSize}
                        onRowClick={handleRowClick}
                        onPageChange={pageChange}
                        style={gridStyle}
                        filterable
                        onFilterChange={handleFilterChange}
                        filter={filter}
                    >
                        <GridColumn field="businessId" columnMenu={checkboxFilter} headerClassName={isColumnActive("businessId") ? "k-filterable-active" : ""} orderIndex={getDeliveryNotesGridOrderIndexColumn("businessId", 1)} width={getDeliveryNotesWidth("businessId", 250)} title="ID du flux" />
                        <GridColumn field="supplierProductionSite" columnMenu={checkboxFilter} headerClassName={isColumnActive("supplierProductionSite") ? "k-filterable-active" : ""} orderIndex={getDeliveryNotesGridOrderIndexColumn("supplierProductionSite", 2)} width={getDeliveryNotesWidth("supplierProductionSite", 250)} title="Site de chargement" />
                        <GridColumn field="deliveryNoteId" columnMenu={checkboxFilter} headerClassName={isColumnActive("deliveryNoteId") ? "k-filterable-active" : ""} orderIndex={getDeliveryNotesGridOrderIndexColumn("deliveryNoteId", 3)} width={getDeliveryNotesWidth("deliveryNoteId", 250)} title="ID BL Zéphyr" />
                        <GridColumn field="licencePlate" columnMenu={checkboxFilter} headerClassName={isColumnActive("licencePlate") ? "k-filterable-active" : ""} orderIndex={getDeliveryNotesGridOrderIndexColumn("licencePlate", 4)} width={getDeliveryNotesWidth("licencePlate", 250)} title="Plaque d'immat" />
                        <GridColumn field="colasCompany" columnMenu={checkboxFilter} headerClassName={isColumnActive("colasCompany") ? "k-filterable-active" : ""} orderIndex={getDeliveryNotesGridOrderIndexColumn("colasCompany", 5)} width={getDeliveryNotesWidth("colasCompany", 250)} title="Transporteur" />
                        <GridColumn field="productCode" columnMenu={checkboxFilter} headerClassName={isColumnActive("productCode") ? "k-filterable-active" : ""} orderIndex={getDeliveryNotesGridOrderIndexColumn("productCode", 6)} width={getDeliveryNotesWidth("productCode", 250)} title="Code produit" />
                        <GridColumn field="unitPrice" columnMenu={checkboxFilter} headerClassName={isColumnActive("unitPrice") ? "k-filterable-active" : ""} orderIndex={getDeliveryNotesGridOrderIndexColumn("unitPrice", 7)} width={getDeliveryNotesWidth("unitPrice", 250)} title="Prix à l'unité" />
                        <GridColumn field="quantity" columnMenu={checkboxFilter} headerClassName={isColumnActive("quantity") ? "k-filterable-active" : ""} orderIndex={getDeliveryNotesGridOrderIndexColumn("quantity", 8)} width={getDeliveryNotesWidth("quantity", 250)} title="Quantité" />
                        <GridColumn field="deliveryCity" columnMenu={checkboxFilter} headerClassName={isColumnActive("deliveryCity") ? "k-filterable-active" : ""} orderIndex={getDeliveryNotesGridOrderIndexColumn("deliveryCity", 9)} width={getDeliveryNotesWidth("deliveryCity", 250)} title="Ville" />
                        <GridColumn field="deliveryZipCode" columnMenu={checkboxFilter} headerClassName={isColumnActive("deliveryZipCode") ? "k-filterable-active" : ""} orderIndex={getDeliveryNotesGridOrderIndexColumn("deliveryZipCode", 10)} width={getDeliveryNotesWidth("deliveryZipCode", 250)} title="Code postal " />
                        <GridColumn field="deliveryAddressLine1" columnMenu={checkboxFilter} headerClassName={isColumnActive("deliveryAddressLine1") ? "k-filterable-active" : ""} orderIndex={getDeliveryNotesGridOrderIndexColumn("deliveryAddressLine1", 11)} width={getDeliveryNotesWidth("deliveryAddressLine1", 250)} title="Adresse de livraison" />
                        <GridColumn field="departureTime" columnMenu={checkboxFilter} headerClassName={isColumnActive("departureTime") ? "k-filterable-active" : ""} orderIndex={getDeliveryNotesGridOrderIndexColumn("departureTime", 12)} width={getDeliveryNotesWidth("departureTime", 250)} title="Départ camion" />
                        <GridColumn field="customerMdmId" columnMenu={checkboxFilter} headerClassName={isColumnActive("customerMdmId") ? "k-filterable-active" : ""} orderIndex={getDeliveryNotesGridOrderIndexColumn("customerMdmId", 13)} width={getDeliveryNotesWidth("customerMdmId", 250)} title="IdMdm du chantier " />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}