import { Box } from "@mui/material";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import React, { useMemo } from 'react';
import { VehicleItemModel } from "../models/VehicleItemModel";
import { VehicleTypeModel } from '../models/VehicleTypeModel';
import { InternalVehiclesChoiceLightModel } from '../services/dataContracts/queryStack/InternalVehiclesChoiceLightModel';
import { ReservedTransporterVehicleTypeLightModel } from '../services/dataContracts/queryStack/ReservedTransporterVehicleTypeLightModel';
import { TransportPurchasePriceDatesLightModel } from '../services/dataContracts/queryStack/TransportPurchasePriceDatesLightModel';
import { VehicleGridComponent } from "./VehicleGridComponent";
import { VehiclesCountWithCheckedComponent } from './VehiclesCountWithCheckedComponent';

interface VehiclesProperties {
    genericVehicles: VehicleTypeModel,
    transporterVehicles: VehicleTypeModel,
    internalVehicles: VehicleTypeModel,
    dailyVehicles: VehicleTypeModel,
    contractedVehicles: VehicleTypeModel,
    genericVehiclesChecked: boolean,
    transporterVehiclesChecked: boolean,
    internalVehiclesChecked: boolean,
    dailyVehiclesChecked: boolean,
    contractedVehiclesChecked: boolean,
    byVehicleTypeGroupIdsFilterEnabled: boolean,
    includingAlreadyUsedFilterEnabled: boolean,
    confirmedReservations: Map<string, Map<string, ReservedTransporterVehicleTypeLightModel>>,
    dailyVehicleDateRef: React.Ref<any>,
    dailyVehicleDate: Date,
    handleDrawerLeftClose: () => void,
    handleAssignGenericVehicleType: (vehicleTypeId: string, vehicleTypeGroupId: string) => void,
    handleAssignTransporterVehicleType: (vehicleTypeId: string, transporterId: string, transportPurchasePriceDatesLightModel: TransportPurchasePriceDatesLightModel[], vehicleTypeGroupId: string) => void,
    handleAssignInternalVehicle: (transporterId: string, vehicleId: number, vehicleTypeId: string, equipmentId: string, driverId: number, licencePlate: string, internalVehiclesChoicesLightModel: InternalVehiclesChoiceLightModel[], vehicleTypeGroupId: string) => void,
    handleAssignContractedVehicle: (contractualPurchasePriceId: number, transporterId: string, vehicleTypeGroupId: string) => void,
    handleChangeVehicleInput: (searchText: string) => void,
    clearSearchText: () => void,
    handleGenericChange: (searchText: string, targetChecked: boolean) => void,
    handleTransporterChange: (searchText: string, targetChecked: boolean) => void,
    handleInternalChange: (searchText: string, targetChecked: boolean) => void,
    handleAssignDailyPlanningVehicle: (dailyPlanningVehicleId: string, vehicleId: number, licencePlate: string, transporterId: string, vehicleTypeId: string, vehicleTypeGroupId: string) => void
    handleDailyChange: (searchText: string, targetChecked: boolean) => void,
    handleContractedChange: (searchText: string, targetChecked: boolean) => void,
    handleVehicleTypeGroupIdsFilterChange: () => void,
    handleIncludingAlreadyUsedFilterChange: () => void,
    handleVehicleHiddenStateChange: (plannigVehicleId: string, vehicleId: number) => void,
    handleDailyVehicleDateChange: (event: DatePickerChangeEvent) => void
}

export const VehiclesComponent = (props: VehiclesProperties): JSX.Element => {

    let vehicles: VehicleItemModel[] = [];

    if (props.genericVehicles.checked)
        vehicles = vehicles.concat(props.genericVehicles.vehicles);

    if (props.transporterVehicles.checked)
        vehicles = vehicles.concat(props.transporterVehicles.vehicles);

    if (props.internalVehicles.checked)
        vehicles = vehicles.concat(props.internalVehicles.vehicles);

    if (props.dailyVehicles.checked)
        vehicles = vehicles.concat(props.dailyVehicles.vehicles.filter(x => !x.isHidden));

    if (props.contractedVehicles.checked)
        vehicles = vehicles.concat(props.contractedVehicles.vehicles);

    const vehicleGridComponent = useMemo((): JSX.Element =>
        <VehicleGridComponent vehicles={vehicles}
            handleAssignGenericVehicleType={props.handleAssignGenericVehicleType}
            handleAssignTransporterVehicleType={props.handleAssignTransporterVehicleType}
            handleAssignInternalVehicle={props.handleAssignInternalVehicle}
            handleAssignDailyPlanningVehicle={props.handleAssignDailyPlanningVehicle}
            handleVehicleHiddenStateChange={props.handleVehicleHiddenStateChange}
            handleAssignContractedVehicle={props.handleAssignContractedVehicle}
            confirmedReservations={props.confirmedReservations}
        />, [vehicles, props.confirmedReservations]);

    return (
        <Box display="flex" flexDirection="column" flex="nowrap" className="content-drawer-vehicle">
            <VehiclesCountWithCheckedComponent genericVehiclesChecked={props.genericVehiclesChecked} transporterVehiclesChecked={props.transporterVehiclesChecked}
                internalVehiclesChecked={props.internalVehiclesChecked} dailyVehiclesChecked={props.dailyVehiclesChecked} contractedVehiclesChecked={props.contractedVehiclesChecked}
                genericVehiclesCount={props.genericVehicles.countItems} transporterVehiclesCount={props.transporterVehicles.countItems}
                internalVehiclesCount={props.internalVehicles.countItems} dailyVehiclesCount={props.dailyVehicles.countItems} contractedVehiclesCount={props.contractedVehicles.countItems}
                handleGenericChange={props.handleGenericChange} handleTransporterChange={props.handleTransporterChange}
                handleInternalChange={props.handleInternalChange} handleDailyChange={props.handleDailyChange} handleContractedChange={props.handleContractedChange}
                handleDrawerLeftClose={props.handleDrawerLeftClose} handleChangeVehicleInput={props.handleChangeVehicleInput} clearSearchText={props.clearSearchText}
                byVehicleTypeGroupIdsFilterEnabled={props.byVehicleTypeGroupIdsFilterEnabled} includingAlreadyUsedFilterEnabled={props.includingAlreadyUsedFilterEnabled}
                handleVehicleTypeGroupIdsFilterChange={props.handleVehicleTypeGroupIdsFilterChange} handleIncludingAlreadyUsedFilterChange={props.handleIncludingAlreadyUsedFilterChange}
                dailyVehicleDateRef={props.dailyVehicleDateRef} dailyVehicleDate={props.dailyVehicleDate} handleDailyVehicleDateChange={props.handleDailyVehicleDateChange}
            />
            <div className="content-body-drawer-vehicle">
                {vehicleGridComponent}
            </div>
        </Box>
    )
}