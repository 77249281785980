import { faPlus, faSearch, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Input, InputAdornment, Tooltip } from '@mui/material';
import React from 'react';
import Select from 'react-select';
import { LogisticsUnitChoice } from '../../../../shared/models/LogisticsUnitChoice';
import { LogisticsUnitSelectModel } from '../../../../shared/models/LogisticsUnitSelectModel';

interface ContentHeaderComponentProps {
    logisticsUnits: Array<LogisticsUnitChoice>,
    selectedLogisticsUnit: LogisticsUnitSelectModel,
    enableBtnTrash: boolean,
    disabledBtnAdd: boolean,
    inputSearchSellPriceValue: string,
    inputSearchSellPriceRef: React.MutableRefObject<HTMLInputElement>,
    handleLogisticsUnitChange: (event: LogisticsUnitSelectModel) => void,
    handleOpenAddSellPriceDialog: () => void,
    handleRemoveSellPrices: () => void,
    handleSellPriceKeyPress: (searchText: string) => void,
    clearSearchText: () => void,
}

export const ContentHeaderComponent = (props: ContentHeaderComponentProps): JSX.Element => {

    return (
        <Box display="flex" flexDirection="row" className="header-content">
            <Box display="flex" flexDirection="row" width="50%">
                <Select
                    className="logistics-unit-select"
                    id="vehicle-type"
                    isClearable
                    placeholder="Zone logistique"
                    menuPosition="fixed"
                    menuShouldBlockScroll={true}
                    isDisabled={props.logisticsUnits.length < 2}
                    options={props.logisticsUnits.map(x => { return { label: x.label, value: x.logisticsUnitId } })}
                    value={props.selectedLogisticsUnit}
                    onChange={(e) => props.handleLogisticsUnitChange(e)}
                />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" width="50%">
                <Box pr={3}>
                    <Tooltip title="Ajouter un nouveau tarif" placement="bottom">
                        <Box>
                            <IconButton size="small" aria-label="Add"
                                disabled={props.disabledBtnAdd} onClick={() => props.handleOpenAddSellPriceDialog()}>
                                <FontAwesomeIcon className={props.disabledBtnAdd ? "fa-plus-disabled" : "fa-plus"} icon={faPlus} />
                            </IconButton>
                        </Box>
                    </Tooltip>
                </Box>
                <Box pr={3}>
                    <Tooltip title="Supprimer les tarifs sélectionnés" placement="bottom">
                        <Box>
                            <IconButton size="small" aria-label="Add"
                                disabled={!props.enableBtnTrash} onClick={() => props.handleRemoveSellPrices()}>
                                <FontAwesomeIcon className={!props.enableBtnTrash ? "fa-trash-disabled" : "fa-trash"} icon={faTrash} />
                            </IconButton>
                        </Box>
                    </Tooltip>
                </Box>
                <Input disableUnderline className={`input-search-negotiated-sell-price${props.inputSearchSellPriceValue.length > 2 ? ' search-text-active' : ''}`} inputRef={props.inputSearchSellPriceRef}
                    endAdornment={<>
                        <InputAdornment position="end" classes={
                            {
                                root: 'input-icon-close-root'
                            }
                        }>
                            <FontAwesomeIcon icon={faTimes} onClick={() => props.clearSearchText()} />
                        </InputAdornment>
                        <InputAdornment position="end" classes={
                            {
                                root: 'input-icon-search-root'
                            }
                        }>
                            <FontAwesomeIcon icon={faSearch} className="icon-search" />
                        </InputAdornment>
                    </>}
                    id="input-search-text" placeholder="Rechercher..." onChange={(event) => props.handleSellPriceKeyPress(event.target.value)} />
            </Box>
        </Box>
    );

}
