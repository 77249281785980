import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { debounce } from 'lodash';
import { NumericTextBox, NumericTextBoxBlurEvent, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { LocalizationProvider, IntlProvider } from '@progress/kendo-react-intl';

interface AddVehicleProperties {
    onClose: () => void,
    handleDuplicatePlanningVehicle: (numberOfVehicles: number) => void,
    disableDuplicatePlanningVehicles: boolean
}

export const DuplicateVehicleComponent = (props: AddVehicleProperties) => {
    const [numberOfVehicles, setNumberOfVehicles] = useState<number>(1);
    const [isInvalidNumberOfVehicles, setIsInvalidNumberOfVehicles] = useState<boolean>(false);

    const handleDuplicateButtonClick = debounce(async (): Promise<void> => {
        await props.handleDuplicatePlanningVehicle(numberOfVehicles);
    }, 500);

    const onNumberOfVehiclesChange = (event: NumericTextBoxChangeEvent): void => {
        const value: number = event.target.value;
        setNumberOfVehicles(value);
        if (value < 1 || value > 10)
            setIsInvalidNumberOfVehicles(true);
        else
            setIsInvalidNumberOfVehicles(false);
    }

    const onNumberOfVehiclesBlur = (event: NumericTextBoxBlurEvent): void => {
        const value: number = event.target.value;
        const numberOfVehicles: number = value < 1 ? 1 : (value > 10 ? 10 : value);
        setNumberOfVehicles(numberOfVehicles);
        setIsInvalidNumberOfVehicles(false);
    }

    return (
        <Box display='flex' flexDirection='column' p={1}>
            <Box pb={2}>
                Nombre de camions:
                <LocalizationProvider language="fr-FR">
                    <IntlProvider locale="fr" >
                        <NumericTextBox className="number-of-vehicles-input"
                            spinners={true}
                            onChange={(event) => onNumberOfVehiclesChange(event)}
                            onBlur={(event) => onNumberOfVehiclesBlur(event)}
                            value={numberOfVehicles}
                        />
                    </IntlProvider>
                </LocalizationProvider>
            </Box>
            {isInvalidNumberOfVehicles &&
                <Box>
                    <span className="error-msg">Le nombre de camions doit être entre 1 et 10.</span>
                </Box>
            }
            <Box display='flex' flexDirection='row' justifyContent="flex-end" pt={2}>
                <Box pr={2}>
                    <Button variant="contained" color="secondary" onClick={props.onClose}>
                        Annuler
                    </Button>
                </Box>
                <Box>
                    <Button variant="contained" color="primary"
                        disabled={isInvalidNumberOfVehicles || props.disableDuplicatePlanningVehicles}
                        onClick={() => handleDuplicateButtonClick()}>
                        Dupliquer
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}