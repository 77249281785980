import axios, { AxiosResponse } from 'axios';
import { flatMap } from 'lodash';
import { LogisticsUnitChoice } from '../../models/LogisticsUnitChoice';
import { LinkTokenLightModel } from './dataContracts/queryStack/LinkTokenLightModel';
import { UserLightModel } from './dataContracts/queryStack/UserLightModel';

const controllerUrl = 'api/User/';

export class UserApiClient {
    
    static FindLinkToken = (token: string)
    : Promise<AxiosResponse<LinkTokenLightModel>> => {
        return axios.get(`${controllerUrl}FindLinkToken?token=${token}`);
    }

    static GetUserProfile = ()
        : Promise<AxiosResponse<UserLightModel>> => {
        return axios.get(`${controllerUrl}GetUserProfile`);
    }

    static FindByToken = (token: string)
        : Promise<AxiosResponse<UserLightModel>> => {
        return axios.get(`${controllerUrl}FindByToken?token=${token}`);
    }

    static GetChosenLogisticsUnits = (logisticsUnits: Array<LogisticsUnitChoice>)
        : Array<string> => {
        const listLogisticsUnits = logisticsUnits.filter(e => { return e.checked === true });

        if (listLogisticsUnits)
            return UserApiClient.GetLogisticsUnitIds(listLogisticsUnits);

        return [];
    }

    static GetLogisticsUnitIds = (logisticsUnits: Array<LogisticsUnitChoice>)
        : Array<string> => {
        return flatMap(logisticsUnits, 'logisticsUnitId');
    }
    
}