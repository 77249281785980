export const priceUnavailabilityReasons: Map<string, string> = new Map<string, string>([
    ['priceUnavailabilityReason.TheVehicleTypeIsNotHandledByTheQuotationModel', 'Seuls les types de véhicules 4X2, 6X4, 8X4 et semi sont pris en compte. Veuillez choisir un autre type de véhicule ou saisir un nombre de véhicule.'],
    ['priceUnavailabilityReason.TheProductTypeIsNotHandledByTheQuotationModel', 'Le produit à transporter (Enrobés, Agrégats) ne permet pas le calcul du coût du transport.'],
    ['priceUnavailabilityReason.TheEstimatedLoadCapacityCannotBeDeterminedForTheVehicleType', 'Le coût du transport n’a pas pu être calculé (incident technique). Veuillez contacter le responsable du site.'],
    ['priceUnavailabilityReason.TheQuantityCannotBeLessThanOrEqualToZero', 'Le tonnage à livrer doit être supérieur à 0.'],
    ['priceUnavailabilityReason.ThereIsNoPricingGridDefinedInTheQuotationModelForTheGivenProductType', 'Le coût du transport n’a pas pu être calculé(incident technique).Veuillez contacter le responsable du site.'],
    ['priceUnavailabilityReason.ThereIsNoTimeSlotCoefficientTableDefinedInTheQuotationModelForTheGivenProductType', 'Le coût du transport n’a pas pu être calculé(incident technique).Veuillez contacter le responsable du site.'],
    ['priceUnavailabilityReason.TheDeliveryTripCannotBeEstimated', 'Le coût du transport n’a pas pu être calculé(incident technique).Veuillez essayer ultérieurement ou contacter le responsable du site.'],
    ['priceUnavailabilityReason.TheReturnTripCannotBeEstimated', 'Le coût du transport n’a pas pu être calculé(incident technique).Veuillez essayer ultérieurement ou contacter le responsable du site.'],
    ['priceUnavailabilityReason.TheLoadingDurationCannotBeDetermined', 'Le coût du transport n’a pas pu être calculé(incident technique).Veuillez contacter le responsable du site.'],
    ['priceUnavailabilityReason.TheUnloadingDurationCannotBeDetermined', 'Le coût du transport n’a pas pu être calculé(incident technique).Veuillez saisir une durée de déchargement et contacter le responsable du site.'],
    ['priceUnavailabilityReason.NoSolutionForUnloadingContinuityWithTheDesiredNumberOfVehicles', 'Il n’est pas possible d’assurer la continuité de déchargement avec le nombre de véhicules souhaité.'],
    ['priceUnavailabilityReason.NoSolutionWithTheDesiredNumberOfVehiclesWithinMaxWorkingTime', 'Il n’est pas possible de respecter la durée de travail maximale d’un chauffeur avec le nombre de véhicules souhaité.'],
    ['priceUnavailabilityReason.UnableToDetermineTheTimeSlotCoefficientFromTheQuotationModel', 'Le coût du transport n’a pas pu être calculé(incident technique).Veuillez essayer ultérieurement ou contacter le responsable du site.'],
    ['priceUnavailabilityReason.TheQuantityIsExcessive', 'Le tonnage à livrer est trop élevé.'],
    ['priceUnavailabilityReason.NoSolutionAsRequiredDeliveryDurationIsLargerThanMaxWorkingTime', 'Le coût du transport n’a pas pu être calculé (incident technique). Veuillez essayer ultérieurement ou contacter le responsable du site.'],
    ['priceUnavailabilityReason.NoSolutionForUnloadingContinuity', 'Il n’est pas possible d’assurer la continuité de déchargement.'],
    ['priceUnavailabilityReason.ThereIsNoPricingGridDefinedForTheGivenCriteriaOrModelDefinitionIsInconsistent', 'Il n\'existe pas de grille de tarification de vente pour cette prestation.']
]);
