import { Box, Button } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { UploadOnBeforeUploadEvent, UploadResponse } from '@progress/kendo-react-upload';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import ToastService from 'src/ToastService';
import { AppModule, LocalStorage } from 'src/utils/Storage';
import { ScaleLoaderComponent } from '../../../shared/components/Common/ScaleLoaderComponent';
import SimpleDialog from '../../../shared/components/Common/SimpleDialog';
import { ImportErrorsExtraResult, LineImportErrors } from '../../../shared/models/ImportErrorsExtraResult';
import { WebAppActionResultEx } from '../../../shared/models/WebAppActionResult';
import BusinessErrors from '../../../utils/BusinessErrors';
import '../ReferentialsStyles.scss';
import { AddInternalVehicleComponent } from './components/AddInternalVehicleComponent';
import { EditInternalVehicleDialogComponent } from './components/EditInternalVehicleDialogComponent';
import { HeaderContentComponent } from './components/HeaderContentComponent';
import { InternalVehiclesComponent } from './components/InternalVehiclesComponent';
import './InternalVehiclesReferentialStyles.scss';
import { InternalVehicleLightModelExtended } from './models/InternalVehicleLightModelExtended';
import { VehicleTypeModel } from './models/VehicleTypeModel';
import { AddInternalVehicleRequestArgs } from './services/dataContracts/controller/AddInternalVehicleRequestArgs';
import { InternalVehicleCandidateToAdd } from './services/dataContracts/controller/InternalVehicleCandidateToAdd';
import { UpdateInternalVehicleRequestArgs } from './services/dataContracts/controller/UpdateInternalVehicleRequestArgs';
import { InternalDriverLightModel } from "./services/dataContracts/queryStack/InternalDriverLightModel";
import { LogisticsUnitChoiceOfInternalVehiclesLightModel } from './services/dataContracts/queryStack/LogisticsUnitChoiceOfInternalVehiclesLightModel';
import { VehicleTypeLightModel } from './services/dataContracts/queryStack/VehicleTypeLightModel';
import { InternalVehiclesReferentialApiClient } from './services/InternalVehiclesReferentialApiClient';

interface InternalVehicles {
    internalVehiclesList: Array<InternalVehicleLightModelExtended>
    internalVehicleChoicesLogisticsUnit: Array<LogisticsUnitChoiceOfInternalVehiclesLightModel>
}

export const InternalVehiclesReferentialView = (): JSX.Element => {

    const inputSearchInternalVehiclesRef: React.RefObject<HTMLInputElement> = React.useRef(null);
    const initialSort: SortDescriptor[] = [{ field: 'licencePlate', dir: 'asc' }];

    const [internalVehicles, setInternalVehicles] = useState<InternalVehicles>({
        internalVehiclesList: [],
        internalVehicleChoicesLogisticsUnit: []
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [isGlobalSearch, setIsGlobalSearch] = useState<boolean>(false);
    const [isAddInternalVehicleDialogOpened, setIsAddInternalVehicleDialogOpened] = useState<boolean>(false);
    const [isEditInternalVehicleDialogOpened, setIsEditInternalVehicleDialogOpened] = useState<boolean>(false);
    const [selectedInternalVehicleId, setSelectedInternalVehicleId] = useState<number>(null);
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const [vehicleTypes, setVehicleTypes] = useState<VehicleTypeLightModel[]>([]);
    const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(false);
    const [reloading, setReloading] = useState<boolean>(false);

    useEffect(() => {
        getInternalVehiclesWithVehicleTypes("", false);
    }, []);

    const clearSearchText = (): void => {
        setIsGlobalSearch(false);
        inputSearchInternalVehiclesRef.current.value = "";
        vehiclesKeyPressed("", false);
    }

    const internalVehiclesKeyPressed = debounce((text: string): void => {
        if (text.length >= 3) {
            vehiclesKeyPressed(text, isGlobalSearch);
        } else if (text.length === 0) {
            setIsGlobalSearch(false);
            vehiclesKeyPressed(text, false);
        }
    }, 500);

    const vehiclesKeyPressed = (text: string, isGlobalSearch: boolean): void => {
        getInternalVehicles(text, isGlobalSearch);
    }

    const getInternalVehicles = (searchText: string, isGlobalSearch: boolean, errorMessage?: string, listErrorMessage?: Array<string>, successMessage?: string, endMessage?: string): void => {
        setLoading(true);
        setSelectedInternalVehicleId(null);
        InternalVehiclesReferentialApiClient.GetInternalVehiclesWithVehicleChoicesLogisticsUnit(searchText, isGlobalSearch)
            .then(response => {
                setInternalVehicles({
                    internalVehiclesList: response[0].data,
                    internalVehicleChoicesLogisticsUnit: response[1].data
                });

                if (errorMessage)
                    ToastService.showErrorToast(errorMessage, listErrorMessage, endMessage);
                else if (successMessage)
                    ToastService.showSuccessToast(successMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleUpdateInternalVehicles = (internalVehicles: Array<InternalVehicleLightModelExtended>, internalVehicleChoicesLogisticsUnit: Array<LogisticsUnitChoiceOfInternalVehiclesLightModel>): void => {
        setInternalVehicles({
            internalVehiclesList: internalVehicles,
            internalVehicleChoicesLogisticsUnit: internalVehicleChoicesLogisticsUnit
        });
    }

    const getInternalVehiclesWithVehicleTypes = (searchText: string, isGlobalSearch: boolean, errorMessage?: string, listErrorMessage?: Array<string>, successMessage?: string, endMessage?: string): void => {
        setLoading(true);
        setSelectedInternalVehicleId(null);
        InternalVehiclesReferentialApiClient.GetInternalVehiclesWithVehicleChoicesLogisticsUnitAndVehicleTypes(searchText, isGlobalSearch)
            .then(response => {
                setInternalVehicles({
                    internalVehiclesList: response[0].data,
                    internalVehicleChoicesLogisticsUnit: response[1].data
                });
                setVehicleTypes(response[2].data);

                if (errorMessage)
                    ToastService.showErrorToast(errorMessage, listErrorMessage, endMessage);
                else if (successMessage)
                    ToastService.showSuccessToast(successMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const handleSortColumnChange = (sortItems: SortDescriptor[]): void => {
        setSort(sortItems);
    }

    const inputSearchInternalVehiclesValue: string = inputSearchInternalVehiclesRef.current === null || inputSearchInternalVehiclesRef.current === undefined ? '' : inputSearchInternalVehiclesRef.current.value;

    const handlerAfterUpload = (res: UploadResponse): void => {
        const response: WebAppActionResultEx<ImportErrorsExtraResult> = res ? res.response : null;
        const listErrorMessage: string[] = [];
        let errorMessage = '';
        let successMessage = '';
        let endMessage = '';

        if (res.status === 200 && response) {
            const importLines: Array<LineImportErrors> = response.extraResult.linesWithErrors;
            if (importLines.length > 0) {
                importLines.forEach(e => {
                    e.errors.forEach(eError => {
                        let libMsg: string = BusinessErrors.GetError(eError);
                        if (!libMsg && eError)
                            libMsg = eError;
                        listErrorMessage.push(`- Ligne ${e.rowIndex} - ${libMsg}`);
                    });
                });

                errorMessage = 'Erreur lors de l\'import de la visibilité des camions Colas:';
                endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des camions sur vos zones logistiques';
            }
            else {
                successMessage = 'La visibilité des camions Colas pour vos zones logistiques a bien été intégrée';
            }
        } else {
            errorMessage = 'Erreur lors de l\'import de la visibilité des camions Colas, veuillez vérifier votre fichier';
            endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des camions sur vos zones logistiques';
            successMessage = '';
        }
        getInternalVehicles(inputSearchInternalVehiclesValue, isGlobalSearch, errorMessage, listErrorMessage, successMessage, endMessage);
    }

    const displayErrorResponse = (): void => {
        const errorMessage = 'Erreur lors de l\'import de la visibilité des camions Colas, veuillez vérifier votre fichier';
        const endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des camions sur vos zones logistiques';
        const successMessage = '';
        getInternalVehicles(inputSearchInternalVehiclesValue, isGlobalSearch, errorMessage, [], successMessage, endMessage);
    }

    const handlerBeforeUpload = (e: UploadOnBeforeUploadEvent): void => {
        setLoading(true);
    }

    const handleIsGlobalSearchChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const checked: boolean = event.target.checked;
        setIsGlobalSearch(checked);
        getInternalVehicles(inputSearchInternalVehiclesValue, checked);
    }

    const handleAddInternalVehicleDialogClick = (): void => {
        setIsAddInternalVehicleDialogOpened(true);
    }

    const handleCloseAddInternalVehicleDialog = (): void => {
        setIsAddInternalVehicleDialogOpened(false);
    }

    const handleSearchInReferentialGrid = (licencePlate: string): void => {
        inputSearchInternalVehiclesRef.current.value = licencePlate;
        setIsGlobalSearch(true);
        handleCloseAddInternalVehicleDialog();
        getInternalVehicles(licencePlate, true);
    }

    const handleAddInternalVehicle = (internalVehicleCandidateToAdd: InternalVehicleCandidateToAdd, vehicleType: VehicleTypeModel): void => {
        const requestArgs: AddInternalVehicleRequestArgs = {
            equipmentId: internalVehicleCandidateToAdd.equipmentId,
            label: internalVehicleCandidateToAdd.label,
            brand: internalVehicleCandidateToAdd.brand,
            equipmentType: internalVehicleCandidateToAdd.equipmentType,
            licencePlate: internalVehicleCandidateToAdd.licencePlate,
            licencePlateDate: internalVehicleCandidateToAdd.licencePlateDate,
            vehicleTypeId: vehicleType.value,
            loadCapacity: vehicleType.loadCapacity,
            vin: internalVehicleCandidateToAdd.vin,
            unladenWeight: internalVehicleCandidateToAdd.unladenWeight,
            loadedWeight: internalVehicleCandidateToAdd.loadedWeight,
            authorizedTotalRollingWeight: internalVehicleCandidateToAdd.authorizedTotalRollingWeight,
            level4Segmentation: internalVehicleCandidateToAdd.level4Segmentation,
            cost1: internalVehicleCandidateToAdd.cost1,
            cost1UnitOfWork: internalVehicleCandidateToAdd.cost1UnitOfWork,
            mainDriverIdentifier: internalVehicleCandidateToAdd.mainDriverIdentifier,
            ownerAgencyId: internalVehicleCandidateToAdd.ownerAgencyId,
            contentVersion: internalVehicleCandidateToAdd.contentVersion,
            isEnabled: internalVehicleCandidateToAdd.isEnabled
        }

        InternalVehiclesReferentialApiClient.AddInternalVehicle(requestArgs)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                inputSearchInternalVehiclesRef.current.value = internalVehicleCandidateToAdd.licencePlate;
                setIsGlobalSearch(true);
                handleCloseAddInternalVehicleDialog();
                getInternalVehicles(internalVehicleCandidateToAdd.licencePlate, true);
            });
    }

    const dislayNotFoundMessage = (): void => {
        ToastService.showErrorToast("Aucun camion Colas trouvé dans le référentiel");
    }

    const handleEditInternalVehicleDialogClick = (): void => {
        setIsEditInternalVehicleDialogOpened(true);
    }

    const handleCloseEditInternalVehicleDialog = (): void => {
        setIsEditInternalVehicleDialogOpened(false);
    }

    const handleSelectedInternalVehicle = (vehicleId: number): void => {
        const newInternalVehiclesList = [...internalVehicles.internalVehiclesList];
        if (selectedInternalVehicleId) {
            newInternalVehiclesList.find(d => d.id === selectedInternalVehicleId).selected = false;
        }

        if (vehicleId) {
            newInternalVehiclesList.find(d => d.id === vehicleId).selected = true;
        }

        setInternalVehicles({
            ...internalVehicles,
            internalVehiclesList: newInternalVehiclesList
        });

        setSelectedInternalVehicleId(vehicleId);
    }

    const handleCloseConfirmModal = (): void => {
        setIsConfirmModalOpened(false);
    }

    const handleConfirmUpdateAgencyForInternalVehicle = (): void => {
        const vehicle = internalVehicles.internalVehiclesList.find(d => d.id === selectedInternalVehicleId)
        InternalVehiclesReferentialApiClient.UpdateInternalVehicleFromReferential(vehicle.equipmentId)
            .then(res => {
                const data = res?.data;
                if (data.hasBusinessErrors && data.errorType == 'AgencyFromColasReferentialDoesntMatchAnExistingAgency') {
                    ToastService.showErrorToast(`La nouvelle agence ${data.ownerAgencyIdFromMdm} est absente de LORIE, vous devez la rajouter dans le référentiel \"Gestion des unités d\'exploitation travaux Colas\" et relancer la mise à jour de l\'agence`);
                    setIsConfirmModalOpened(false);
                    return;
                }
                setIsConfirmModalOpened(false);
                getInternalVehiclesWithVehicleTypes("", false);
                const ModuleKey = AppModule.InternalVehiclesReferential;
                var vehicleIdString = LocalStorage.GetItem(ModuleKey, 'lastVehicleIdSelected');
                var vehicleId = parseInt(vehicleIdString, 10);
                LocalStorage.SetItem(ModuleKey, 'lastVehicleIndexSelected', 'true');
                LocalStorage.SetItem(ModuleKey, 'selected', 'true');
                handleSelectedInternalVehicle(vehicleId);
                setReloading(true);
            })
            .catch(e => {
                ToastService.showErrorToast("L'action a échoué en raison d'une erreur technique. Si ce problème persiste, veuillez contacter le support.");
            });
    }

    const handleEditAgencyForInternalVehicleClick = (): void => {
        setIsEditInternalVehicleDialogOpened(false);
        setIsConfirmModalOpened(true);
    }

    const handleEditInternalVehicleClick = (requestArgs: UpdateInternalVehicleRequestArgs, driver: InternalDriverLightModel): void => {
        setIsEditInternalVehicleDialogOpened(false);
        InternalVehiclesReferentialApiClient.UpdateInternalVehicle(requestArgs)
            .then((res) => {
                const errors: string[] = BusinessErrors.Get(res.data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                }
                else {
                    //On ne raffraichit que la ligne car le composant avec la virtualisation ne gère pas correctement le scroll
                    const newList = [...internalVehicles.internalVehiclesList];
                    const currentVehicle = newList.find(x => x.id == requestArgs.vehicleId);
                    const vehicleType = vehicleTypes.find(a => a.vehicleTypeId === requestArgs.vehicleTypeId);
                    currentVehicle.vehicleTypeId = requestArgs.vehicleTypeId;
                    currentVehicle.vehicleTypeLabel = vehicleType?.label;
                    currentVehicle.driver = driver;

                    setInternalVehicles({
                        ...internalVehicles,
                        internalVehiclesList: newList
                    });
                }
            });
    }

    const headerContentComponent: JSX.Element = useMemo(() =>
        <HeaderContentComponent
            inputSearchInternalVehiclesValue={inputSearchInternalVehiclesValue}
            inputSearchInternalVehiclesRef={inputSearchInternalVehiclesRef}
            sort={sort}
            isGlobalSearch={isGlobalSearch}
            loadingInternalVehicles={loading}
            selectedInternalVehicleId={selectedInternalVehicleId}
            handleInternalVehiclesKeyPress={internalVehiclesKeyPressed}
            handleClearSearchText={clearSearchText}
            handlerAfterUpload={handlerAfterUpload}
            handlerBeforeUpload={handlerBeforeUpload}
            displayErrorResponse={displayErrorResponse}
            handleIsGlobalSearchChanged={handleIsGlobalSearchChanged}
            handleAddInternalVehicleDialogClick={handleAddInternalVehicleDialogClick}
            handleEditInternalVehicleDialogClick={handleEditInternalVehicleDialogClick}
        />, [inputSearchInternalVehiclesValue, inputSearchInternalVehiclesRef, selectedInternalVehicleId, loading, sort, isGlobalSearch, reloading]);

    const internalVehiclesComponent: JSX.Element = useMemo(() =>
        <InternalVehiclesComponent
            internalVehicles={internalVehicles.internalVehiclesList}
            internalVehicleChoicesLogisticUnit={internalVehicles.internalVehicleChoicesLogisticsUnit}
            sort={sort}
            handleSelectedInternalVehicle={handleSelectedInternalVehicle}
            handleSortChange={handleSortChange}
            handleSortColumnChange={handleSortColumnChange}
            handleUpdateInternalVehicles={handleUpdateInternalVehicles}
        />, [internalVehicles, selectedInternalVehicleId, sort, reloading]);

    return (
        <Box className="internal-Vehicles referencial">
            <Box display="flex" flexDirection="row" flex="wrap" className="view-title">
                Gestion des camions internes
            </Box>
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box display="flex" flexDirection="column" flex="wrap">
                        {headerContentComponent}
                        {(loading ?
                            <ScaleLoaderComponent loading={loading} />
                            :
                            internalVehiclesComponent
                        )}
                    </Box>
                </IntlProvider>
            </LocalizationProvider>
            <SimpleDialog isOpen={isAddInternalVehicleDialogOpened}
                onClose={handleCloseAddInternalVehicleDialog}
                closeIcon={true}
                dialogTitle="Ajouter un camion Colas"
                classNameVal="add-internal-vehicle-dialog"
                component={
                    <AddInternalVehicleComponent
                        vehicleTypes={vehicleTypes}
                        handleSearchInReferentialGrid={handleSearchInReferentialGrid}
                        handleAddInternalVehicle={handleAddInternalVehicle}
                        dislayNotFoundMessage={dislayNotFoundMessage}
                    />
                }
            />
            <SimpleDialog isOpen={isEditInternalVehicleDialogOpened}
                onClose={() => handleCloseEditInternalVehicleDialog()}
                closeIcon={true}
                dialogTitle="Modifier un camion Colas"
                classNameVal="edit-vehicle-dialog"
                component={
                    <EditInternalVehicleDialogComponent
                        internalVehicleSelected={internalVehicles.internalVehiclesList.find(d => d.id === selectedInternalVehicleId)}
                        vehicleTypes={vehicleTypes}
                        handleEditInternalVehicleClick={handleEditInternalVehicleClick}
                        handleEditAgencyForInternalVehicleClick={handleEditAgencyForInternalVehicleClick}
                    />
                }
            />
            <Modal show={isConfirmModalOpened} onHide={() => handleCloseConfirmModal()} className='mt-5'>
                <Modal.Header closeButton>
                    <Modal.Title>ATTENTION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    La mise à jour de l'agence sera effective dès confirmation et concernera toutes les nouvelles planifications à venir de ce camion. <br /><br />

                    Les planifications, demandes de confirmation de commande, provisions ou ventes dans une date future mais réalisées avant cette mise à jour resteront sur l'ancienne agence.<br /><br />

                    Libre à vous d'annuler ou modifier ces opérations si nécessaire.<br /><br />

                    Confirmez-vous la mise à jour ?
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary" onClick={() => handleCloseConfirmModal()}>
                        Annuler
                    </Button>
                    <Button className="primary" onClick={() => handleConfirmUpdateAgencyForInternalVehicle()}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>
        </Box>
    );
}
