import { Box, Button, Input } from '@mui/material';
import React, { useState } from 'react';
import Utilities from '../../../../../utils/Utilities';

interface ConfirmAddDriverStepComponentProperties {
    confirmAddDriver: (phoneNumber: string) => void,
    handlePreviousStep: () => void
}

export const ConfirmAddDriverStepComponent = (props: ConfirmAddDriverStepComponentProperties): JSX.Element => {

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(false);

    const handleChangePhoneNumber = (value: string): void => {
        setPhoneNumber(value);
        const isValidaPhoneNumber: boolean = Utilities.isValidPhoneNumber(value, true);
        setIsPhoneNumberValid(isValidaPhoneNumber);
    }

    return (
        <Box className="confirm-add-driver">
            <Box display="flex" flexDirection="column" className="input-text-content">
                <Box className={isPhoneNumberValid ? "driver-phone-number-input-text-label" : "driver-phone-number-input-text-label invalid"}>Téléphone portable du chauffeur Colas</Box>
                <Box display="flex" flexDirection="row">
                    <Input
                        id="driver-phone-number"
                        value={phoneNumber ? Utilities.formatPhoneNumber(phoneNumber) : ''}
                        className={isPhoneNumberValid ? "driver-phone-number-input-text" : "driver-phone-number-input-text invalid"}
                        onChange={(e) => handleChangePhoneNumber(e.target.value)}
                    />
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                <Button variant="contained" title="Retour" className="previous-button" onClick={props.handlePreviousStep}>
                    Retour
                </Button>
                <Button variant="contained" color="primary" title="Confirmer" disabled={!isPhoneNumberValid} onClick={() => props.confirmAddDriver(phoneNumber)}>
                    Confirmer
                </Button>
            </Box>
        </Box>
    );
}
