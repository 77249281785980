import { faSearch, faTimes, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Checkbox, Divider, FormControlLabel, IconButton, Input, InputAdornment, Tooltip } from '@mui/material';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import Select from 'react-select';
import { CalendarComponent } from 'src/shared/components/Common/CalendarComponent';
import { FieldDescriptor } from 'src/shared/components/TextSearchFieldsSelector/models/FieldDescriptor';
import { TextSearchFieldsSelectorComponent } from 'src/shared/components/TextSearchFieldsSelector/TextSearchFieldsSelectorComponent';
import { LogisticsUnitChoice } from 'src/shared/models/LogisticsUnitChoice';
import { DateRange } from '../../../shared/models/DateRange';
import { LogisticsUnitSelectModel } from '../../../shared/models/LogisticsUnitSelectModel';
import '../../../utils/Number';
import { PurchaseProvisionningCosts } from '../models/PurchaseProvisionningCosts';
import { PublishedCostsSearchOption } from '../services/dataContracts/controller/PublishedCostsSearchOption';

interface ContentHeaderComponentProps {
    date: DateRange,
    logisticsUnits: Array<LogisticsUnitChoice>,
    selectedLogisticsUnit: LogisticsUnitSelectModel,
    isTransporterDrawerOpened: boolean,
    inputSearchVehiclesValue: string,
    purchaseProvisionningCosts: PurchaseProvisionningCosts,
    inputSearchVehiclesRef: React.MutableRefObject<HTMLInputElement>,
    isHeaderBtnPreInvoicesEnabled: boolean,
    showOnlyCostAndFeesWithoutProvisioning: boolean,
    showOnlyProvisioningsWithDifferences: boolean,
    provisioningsWithDifferencesCount: number,
    filterOptionsPreinvoiceStatus: Array<FieldDescriptor<PublishedCostsSearchOption>>,
    handleOpenCloseTransporterDrawer: () => void,
    handleLogisticsUnitChange: (e: LogisticsUnitSelectModel) => void,
    handleChangeDateRange: (range: DateRange) => void,
    handleVehiclesTripsKeyPress: (text: string) => void,
    clearSearchText: () => void,
    onChangePublishedCostsSearchOption: (filtersSelected: Array<PublishedCostsSearchOption>) => void,
    handleShowOnlyCostAndFeesWithoutProvisioning: () => void,
    handleShowOnlyProvisioningsWithDifferences: () => void
}

export const ContentHeaderComponent = (props: ContentHeaderComponentProps) => {
    const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);

    const isBtnTransportersDisabled = !props.selectedLogisticsUnit;

    const handleIsDateRangeValidChanged = (isValid: boolean): void => {
        setIsDateRangeValid(isValid);
    }

    return (
        <>
            <Box display="flex" flexDirection="row" flex='wrap' alignItems="center" className="header-content" pt={2} pb={2}>
                {!props.isTransporterDrawerOpened &&
                    <Box width="4em">
                        <Tooltip title="Transporteurs" placement="bottom">
                            <Avatar className={`${isBtnTransportersDisabled ? '' : 'btn-actif '} btn-users`}>
                                <IconButton size="small" className="btn-icon" disabled={isBtnTransportersDisabled} onClick={() => props.handleOpenCloseTransporterDrawer()}>
                                    <FontAwesomeIcon icon={faUsers} />
                                </IconButton>
                            </Avatar>
                        </Tooltip>
                    </Box>
                }
                <Box pl={props.isTransporterDrawerOpened ? 2 : 0}>
                    <Select
                        className="logistics-unit-select"
                        id="vehicle-type"
                        isClearable
                        placeholder="Zone logistique"
                        menuPosition="fixed"
                        menuShouldBlockScroll={true}
                        isDisabled={props.logisticsUnits.length < 2}
                        options={props.logisticsUnits.map(x => { return { label: x.label, value: x.logisticsUnitId } })}
                        value={props.selectedLogisticsUnit}
                        onChange={(e) => props.handleLogisticsUnitChange(e)}
                    />
                </Box>
                <Box pl={2}>
                    <CalendarComponent startDate={props.date.start} endDate={props.date.end}
                        calendarId='vehicles-management-daterange-picker' maximumNumberOfMonths={2}
                        isValid={isDateRangeValid} handleIsValidChanged={handleIsDateRangeValidChanged} handlerFromChildToParent={props.handleChangeDateRange} />
                </Box>
                <Box pl={2}>
                    <Input disableUnderline className={props.inputSearchVehiclesValue.length > 2 ? 'input-search-vehicles search-text-active' : 'input-search-vehicles'} inputRef={props.inputSearchVehiclesRef}
                        endAdornment={<>
                            <InputAdornment position="end" classes={
                                {
                                    root: 'input-icon-close-root'
                                }
                            }>
                                <FontAwesomeIcon icon={faTimes} onClick={() => props.clearSearchText()} />
                            </InputAdornment>
                            <InputAdornment position="end" classes={
                                {
                                    root: 'input-icon-search-root'
                                }
                            }>
                                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                            </InputAdornment>
                        </>}
                        id="input-search-text" placeholder="Rechercher..." onChange={(event) => props.handleVehiclesTripsKeyPress(event.target.value)} />
                </Box>
            </Box>
            <Divider className='divider-title' />
            <Box display="flex" flexDirection="row" alignItems="center" p={2} width="100%">
                <Box minWidth="250px" width="20%">
                    <div className="filter-title">
                        Statut préfacturation
                    </div>
                    <TextSearchFieldsSelectorComponent<PublishedCostsSearchOption> selectedAll={true} options={props.filterOptionsPreinvoiceStatus} onChange={props.onChangePublishedCostsSearchOption} placeHolder='' noOptionsMessage='Pas de filtres disponibles.' />
                </Box>

                <Box minWidth="250px" width="20%" pl={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={!props.selectedLogisticsUnit}
                                checked={props.showOnlyCostAndFeesWithoutProvisioning}
                                onChange={() => props.handleShowOnlyCostAndFeesWithoutProvisioning()}
                                color="default"
                            />
                        }
                        className="provisioning-label-filter"
                        label="Coût OU frais sans lot"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={!props.selectedLogisticsUnit || (props.provisioningsWithDifferencesCount == 0 && !props.showOnlyProvisioningsWithDifferences)}
                                checked={props.showOnlyProvisioningsWithDifferences}
                                onChange={() => props.handleShowOnlyProvisioningsWithDifferences()}
                                color="default"
                            />
                        }
                        className="diff-provisioning-label-filter"
                        label={`Ecart Achats/ Provisions : ${props.provisioningsWithDifferencesCount}`}
                    />
                </Box>
                <Box width="60%" display="flex" flexDirection="row" justifyContent="flex-end" pl={3}>
                    <LocalizationProvider language="fr-FR">
                        <IntlProvider locale="fr">
                            <table className="provisionning-table">
                                <thead>
                                    <tr>
                                        <th rowSpan={2}></th>
                                        <th colSpan={2} className="title center-content">Coût</th>
                                        <th colSpan={2} className="title center-content">Frais</th>
                                    </tr>
                                    <tr>
                                        <th className="title center-content">À traiter</th>
                                        <th className="title center-content">Terminés</th>
                                        <th className="title center-content">À traiter</th>
                                        <th className="title center-content">Terminés</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="title left-content">Avec lot</td>
                                        <td className="value center-content">{`${props.purchaseProvisionningCosts.costsToIntegrateWithProvisioning ? Number(props.purchaseProvisionningCosts.costsToIntegrateWithProvisioning.toFixed(2)).toCurrencyString() : ''}`}</td>
                                        <td className="value center-content">{`${props.purchaseProvisionningCosts.integratedCostsWithProvisioning ? Number(props.purchaseProvisionningCosts.integratedCostsWithProvisioning.toFixed(2)).toCurrencyString() : ''}`}</td>
                                        <td className="value center-content">{`${props.purchaseProvisionningCosts.feesToIntegrateWithProvisioning ? Number(props.purchaseProvisionningCosts.feesToIntegrateWithProvisioning.toFixed(2)).toCurrencyString() : ''}`}</td>
                                        <td className="value center-content">{`${props.purchaseProvisionningCosts.integratedFeesWithProvisioning ? Number(props.purchaseProvisionningCosts.integratedFeesWithProvisioning.toFixed(2)).toCurrencyString() : ''}`}</td>
                                    </tr>
                                    <tr>
                                        <td className="title left-content">Sans lot</td>
                                        <td className="value center-content">{`${props.purchaseProvisionningCosts.costsToIntegrateWithoutProvisioning ? Number(props.purchaseProvisionningCosts.costsToIntegrateWithoutProvisioning.toFixed(2)).toCurrencyString() : ''}`}</td>
                                        <td className="value empty-content"></td>
                                        <td className="value center-content">{`${props.purchaseProvisionningCosts.feesToIntegrateWithoutProvisioning ? Number(props.purchaseProvisionningCosts.feesToIntegrateWithoutProvisioning.toFixed(2)).toCurrencyString() : ''}`}</td>
                                        <td className="value empty-content"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </IntlProvider>
                    </LocalizationProvider>
                </Box>
            </Box>
            <Divider className='divider-title' />
        </>
    );
}
