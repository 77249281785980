import { Box, Button } from '@mui/material';
import { TimePicker, TimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { sortBy } from 'lodash';
import React from 'react';
import Select from 'react-select';
import { TransportQuotationResult } from '../../../shared/Quotation/services/dataContracts/TransportQuotationResult';
import { FixedLists } from '../FixedLists';
import { BilledPriceModel } from '../models/BilledPriceModel';
import { DateConfigModel } from '../models/DateConfigModel';
import { PriceGridKindEnum } from '../models/PriceGridKindEnum';
import { SelectInputItemModel } from '../models/SelectInputItemModel';
import { TransportPriorityEnum } from '../models/TransportPriorityEnum';
import { VehicleTypeModel } from '../models/VehicleTypeModel';
import { NegotiatedPurchasePriceLightModel } from '../services/dataContracts/queryStack/NegotiatedPurchasePriceLightModel';
import { updateMode } from '../TransportFlowForm';
import { DateInputText, NumericTextInput, SelectInput } from './CommonComponents';

interface VehicleAndPriceContentProps {
    priority: TransportPriorityEnum,
    arrivalTimeAtSenderSite: Date,
    arrivalTimeAtReceiverSite: Date,
    arrivalEndTimeAtReceiverSite: Date,
    startDateValid: boolean,
    quotationResult: TransportQuotationResult,
    isJobSiteVehicleServiceKind: boolean,
    vehicleType: VehicleTypeModel,
    mode: string,
    unloadingDuration: number,
    tripDuration: number,
    jobSiteVehicleUnloadingDuration: Date,
    isUnloadingContinuityRequired: boolean,
    sellPriceKindData: SelectInputItemModel,
    negotiatedPurchasePriceKindData: SelectInputItemModel,
    billedSellPrice: BilledPriceModel,
    negotiatedPurchasePrice: number,
    dateConfig: DateConfigModel,
    quotationArrivalTimeAtSenderSiteIsFixed: boolean,
    isNegotiatedSellPrice: boolean,
    negotiatedPurchasePriceAppliesToInternalTransporters: boolean,
    negotiatedPurchasePriceAppliesToExternalTransporters: boolean,
    negotiatedPurchasePrices: Array<NegotiatedPurchasePriceLightModel>,
    setArrivalTimeAtSenderSiteIsFixed: (value: boolean) => void,
    setArrivalTimeAtSenderSite: (value: Date) => void,
    setArrivalTimeAtReceiverSite: (value: Date) => void,
    setArrivalEndTimeAtReceiverSite: (value: Date) => void,
    handleUnloadingDurationChanged: (value: number) => void,
    setJobSiteVehicleUnloadingDuration: (value: Date) => void,
    handleIsUnloadingContinuityRequiredChanged: (value: boolean) => void,
    handleTripDurationChanged: (value: number) => void,
    setArrivalSlotDuration: (value: number) => void,
    handleNumberOfVehiclesChange: (value: number) => void,
    handleVehicleTypeChanged: (e: SelectInputItemModel) => void,
    handlePriceKindSelectChange: (e: SelectInputItemModel) => void,
    handleNegotiatedPurchasePriceKindSelectChange: (e: SelectInputItemModel) => void,
    handleUnitBilledPriceChange: (value: number) => void,
    handleBilledPriceChange: (value: number) => void,
    handleNegotiatedUnitPurchasePriceChange: (value: number) => void,
    handleChangeBilledUnitPriceIsLocked: () => void,
    handleNegotiatedPurchasePriceAppliesToExternalTransportersChange: () => void,
    handleNegotiatedPurchasePriceAppliesToInternalTransportersChange: () => void
}

export const VehicleAndPriceContent = (props: VehicleAndPriceContentProps): JSX.Element => {
    const DefaultArrivalSlotDurationWhenArrivalTimeAtReceiverChanged = 120;
    const { arrivalTimeAtSenderSite, arrivalTimeAtReceiverSite, arrivalEndTimeAtReceiverSite, startDateValid, quotationResult, isJobSiteVehicleServiceKind, vehicleType, mode, unloadingDuration, tripDuration,
        jobSiteVehicleUnloadingDuration, isUnloadingContinuityRequired, sellPriceKindData, billedSellPrice, dateConfig, quotationArrivalTimeAtSenderSiteIsFixed, priority, negotiatedPurchasePriceKindData, negotiatedPurchasePrice,
        negotiatedPurchasePrices, negotiatedPurchasePriceAppliesToExternalTransporters, negotiatedPurchasePriceAppliesToInternalTransporters,
        handleNegotiatedPurchasePriceAppliesToExternalTransportersChange, handleNegotiatedPurchasePriceAppliesToInternalTransportersChange } = props;

    const vehicleTypeList = vehicleType.vehicleTypeList.map(x => {
        return { label: x.label, value: x.vehicleTypeId }
    });

    const getPurchasePricesOptions = (negotiatedPurchasePrices: NegotiatedPurchasePriceLightModel[]): SelectInputItemModel[] => {
        let res = [...FixedLists.negotiatedPurchasePriceKindsList];

        negotiatedPurchasePrices.forEach(n => {
            res.push({
                label: `${FixedLists.negotiatedPurchasePriceKindsList.find(x => x.value === n.priceKind).label} : ${n.price} € ${n.label ? '(' + n.label + ')' : ''}`,
                value: `${n.priceKind}:${n.price}:${n.label ?? ""}`
            });
        })

        res = sortBy(res, x => x.label);
        return res;
    }

    const purchasePriceKindOptions = getPurchasePricesOptions(negotiatedPurchasePrices);

    const handleArrivalTimeAtSenderSiteChange = (event: TimePickerChangeEvent): void => {
        const inputDate: Date = new Date(event.value);
        const arrivalTime: Date = initializeArrivalTimeFromDate(inputDate);
        props.setArrivalTimeAtSenderSite(arrivalTime);
        props.setArrivalTimeAtSenderSiteIsFixed(true);
        props.setArrivalTimeAtReceiverSite(null);
        props.setArrivalEndTimeAtReceiverSite(null);
        props.setArrivalSlotDuration(null);
    }

    const handleArrivalTimeAtSenderSiteFocus = (): void => {
        if (arrivalTimeAtSenderSite === null) {
            const arrivalTime: Date = initializeArrivalTime(dateConfig.startDateValue, 7, 0);
            props.setArrivalTimeAtSenderSite(arrivalTime);
            props.setArrivalTimeAtSenderSiteIsFixed(true);
            props.setArrivalTimeAtReceiverSite(null);
            props.setArrivalEndTimeAtReceiverSite(null);
            props.setArrivalSlotDuration(null);
        }
    }

    const handleArrivalTimeAtReceiverSiteChange = (event: TimePickerChangeEvent): void => {
        props.setArrivalTimeAtSenderSiteIsFixed(false);
        props.setArrivalTimeAtSenderSite(null);

        if (event.value) {
            const inputDate: Date = new Date(event.value);
            const arrivalTime: Date = initializeArrivalTimeFromDate(inputDate);
            props.setArrivalTimeAtReceiverSite(arrivalTime);
            if (props.priority !== TransportPriorityEnum.high && arrivalTime) {
                //Mettre 2 heures dans le créneau dès qu'on touche l'heure de début
                handleChangeSlotDuration(arrivalTime, DefaultArrivalSlotDurationWhenArrivalTimeAtReceiverChanged);
            }
        }
        else {

            props.setArrivalTimeAtReceiverSite(null);
            props.setArrivalEndTimeAtReceiverSite(null);
        }
    }

    const handleArrivalEndTimeAtReceiverSiteChange = (event: TimePickerChangeEvent): void => {
        const hour = new Date(event.value).getHours();
        const minute = new Date(event.value).getMinutes();
        const inputDate: Date = new Date(dateConfig.startDateValue.getFullYear(), dateConfig.startDateValue.getMonth(), dateConfig.startDateValue.getDate(), hour, minute);

        //On considère que c'est la journée du lendemain dans le cas où l'heure de fin est inférieure à l'heure de début
        if (inputDate < arrivalTimeAtReceiverSite) {
            inputDate.addDays(1);
        }

        const arrivalEndTime: Date = initializeArrivalTimeFromDate(inputDate);
        const diffDate = arrivalEndTime.valueOf() - arrivalTimeAtReceiverSite.valueOf();
        const newSlotDuration = Math.floor(diffDate) / 60000;
        props.setArrivalEndTimeAtReceiverSite(arrivalEndTime);
        props.setArrivalSlotDuration(newSlotDuration);
    }

    const handleArrivalTimeAtReceiverSiteFocus = (): void => {
        if (arrivalTimeAtReceiverSite === null) {
            const arrivalTime: Date = initializeArrivalTime(dateConfig.startDateValue, 7, 0);
            props.setArrivalTimeAtReceiverSite(arrivalTime);
            props.setArrivalTimeAtSenderSiteIsFixed(false);
            props.setArrivalTimeAtSenderSite(null);
            if (props.priority !== TransportPriorityEnum.high && arrivalEndTimeAtReceiverSite === null) {
                //Mettre 2 heures dans le créneau dès qu'on touche l'heure de début
                handleChangeSlotDuration(arrivalTime, DefaultArrivalSlotDurationWhenArrivalTimeAtReceiverChanged);
            }
        }
    }

    const handleChangeSlotDuration = (arrivalTime: Date, newSlotDuration: number): void => {
        const arrivalEndTime = new Date(arrivalTime).addMinutes(newSlotDuration);
        props.setArrivalSlotDuration(newSlotDuration);
        props.setArrivalEndTimeAtReceiverSite(arrivalEndTime);
    }

    const initializeArrivalTimeFromDate = (inputDate: Date): Date => {
        const h: number = inputDate.getHours();
        const m: number = inputDate.getMinutes();
        return initializeArrivalTime(inputDate, h, m);
    }

    const initializeArrivalTime = (date: Date, hour: number, minute: number): Date => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minute, 0, 0);
    }

    const negotiatedPurchasePriceValueSelected = (negotiatedPurchasePrice && negotiatedPurchasePriceKindData?.value)
        ? negotiatedPurchasePrices.find(x => x.price === negotiatedPurchasePrice && x.priceKind == negotiatedPurchasePriceKindData.value)
        : null;

    const isNegotiatedPurchasePrice = !!negotiatedPurchasePriceValueSelected;

    const negotiatedPurchasePriceKindDataValue: SelectInputItemModel = negotiatedPurchasePriceValueSelected
        ? {
            label: `${FixedLists.negotiatedPurchasePriceKindsList.find(x => x.value === negotiatedPurchasePriceValueSelected.priceKind).label} : ${negotiatedPurchasePrice} € ${negotiatedPurchasePriceValueSelected.label ? '(' + negotiatedPurchasePriceValueSelected.label + ')' : ''}`,
            value: `${negotiatedPurchasePriceValueSelected.priceKind}:${negotiatedPurchasePriceValueSelected.price}:${negotiatedPurchasePriceValueSelected.label ?? ""}`
        }
        : negotiatedPurchasePriceKindData;

    const negotiatedPurchasePriceAppliedToInternalExternalPriceRequired = negotiatedPurchasePriceKindDataValue?.value && negotiatedPurchasePrice && !negotiatedPurchasePriceAppliesToExternalTransporters && !negotiatedPurchasePriceAppliesToInternalTransporters;

    return (
        <Box display="flex" flexDirection="column" >
            <Box display="flex" flexWrap="wrap" flexDirection="row" width="100%" pb={'15px'} justifyContent="flex-start">
                {!isJobSiteVehicleServiceKind &&
                    <Box display="flex" flexDirection="column" mr={"20px"}>
                        <Box className={arrivalTimeAtSenderSite !== null ? "label" : "required-label"}>Heure chargement</Box>
                        <Box display="flex" flexDirection="row" width="100px">
                            <LocalizationProvider language="fr-FR">
                                <IntlProvider locale="fr" >
                                    <TimePicker value={arrivalTimeAtSenderSite}
                                        className={(quotationArrivalTimeAtSenderSiteIsFixed === true && arrivalTimeAtSenderSite !== null) ||
                                            (arrivalTimeAtSenderSite !== null && arrivalTimeAtReceiverSite === null) ? "fixed-arrival-time" : ""}
                                        disabled={!startDateValid}
                                        onChange={(event) => handleArrivalTimeAtSenderSiteChange(event)}
                                        required={arrivalTimeAtSenderSite === null}
                                        onFocus={() => handleArrivalTimeAtSenderSiteFocus()}
                                    />
                                </IntlProvider>
                            </LocalizationProvider>
                        </Box>
                    </Box>
                }
                {isJobSiteVehicleServiceKind &&
                    <Box display="flex" flexDirection="column" mr={"20px"}>
                        <Box className={isJobSiteVehicleServiceKind && jobSiteVehicleUnloadingDuration === null ? "required-label" : "label"}>Durée sur chantier</Box>
                        <Box display="flex" flexDirection="row" width="124px">
                            <DateInputText value={jobSiteVehicleUnloadingDuration}
                                handleChange={(event) => props.setJobSiteVehicleUnloadingDuration(event.value)}
                                required={isJobSiteVehicleServiceKind && jobSiteVehicleUnloadingDuration === null}
                            />
                        </Box>
                    </Box>
                }
                <Box display="flex" flexDirection="column" mr={"20px"}>
                    <Box className={arrivalTimeAtReceiverSite !== null ? "label" : "required-label"}>Heure début</Box>
                    <Box display="flex" flexDirection="row" width="100px">
                        <LocalizationProvider language="fr-FR">
                            <IntlProvider locale="fr" >
                                <TimePicker value={arrivalTimeAtReceiverSite}
                                    className={!isJobSiteVehicleServiceKind &&
                                        ((quotationArrivalTimeAtSenderSiteIsFixed === false && arrivalTimeAtReceiverSite !== null) ||
                                            (arrivalTimeAtReceiverSite !== null && arrivalTimeAtSenderSite === null)) ? "fixed-arrival-time" : ""}
                                    disabled={!startDateValid}
                                    onChange={(event) => handleArrivalTimeAtReceiverSiteChange(event)}
                                    required={arrivalTimeAtReceiverSite === null}
                                    onFocus={() => handleArrivalTimeAtReceiverSiteFocus()}
                                />
                            </IntlProvider>
                        </LocalizationProvider>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" mr={"20px"}>
                    <Box className={(priority === TransportPriorityEnum.high || arrivalEndTimeAtReceiverSite !== null) ? "label" : "required-label"}>Heure fin</Box>
                    <Box display="flex" flexDirection="row" width="100px">
                        <LocalizationProvider language="fr-FR">
                            <IntlProvider locale="fr" >
                                <TimePicker value={arrivalEndTimeAtReceiverSite}
                                    disabled={!startDateValid || priority === TransportPriorityEnum.high}
                                    onChange={(event) => handleArrivalEndTimeAtReceiverSiteChange(event)}
                                    required={priority === TransportPriorityEnum.low || priority === TransportPriorityEnum.medium}
                                />
                            </IntlProvider>
                        </LocalizationProvider>
                    </Box>
                </Box>
                <SelectInput title="Type de camion"
                    titleClassName={vehicleType.selectedVehicleType ? "label" : "required-label"}
                    options={vehicleTypeList}
                    value={vehicleType.selectedVehicleType}
                    handleChange={(e) => props.handleVehicleTypeChanged(e)}
                    required={!vehicleType.selectedVehicleType}
                    isDisabled={mode === updateMode}
                />
                {!isJobSiteVehicleServiceKind &&
                    <NumericTextInput title="Durée de déchargement"
                        titleClassName={(unloadingDuration === null) ? "required-label" : "label"}
                        className={(unloadingDuration === null) ? "container-required" : "container"}
                        handleChange={(e) => props.handleUnloadingDurationChanged(e.value)}
                        width="130px"
                        value={unloadingDuration}
                        maximumFractionDigits={0}
                        iconClassName="min-icon"
                        iconText="min"
                    />
                }
                <NumericTextInput title="Nb de camions"
                    titleClassName={quotationResult?.numberOfVehicles ? "label" : "required-label"}
                    className={quotationResult?.numberOfVehicles ? "container" : "container-required"}
                    handleChange={(e) => props.handleNumberOfVehiclesChange(e.value)}
                    width="80px"
                    value={quotationResult?.numberOfVehicles}
                    maximumFractionDigits={0}
                />
                <NumericTextInput title="Temps de trajet"
                    titleClassName={(tripDuration === null && !isJobSiteVehicleServiceKind) ? "required-label" : "label"}
                    className={(tripDuration === null && !isJobSiteVehicleServiceKind) ? "container-required" : "container"}
                    max={300}
                    handleChange={(e) => props.handleTripDurationChanged(e.value)}
                    width="90px"
                    value={tripDuration}
                    maximumFractionDigits={0}
                    iconClassName="min-icon"
                    iconText="min"
                />
                <Box display="flex" flexDirection="column" mr={"20px"}>
                    <Box className="label">Continuité</Box>
                    <Box display="flex" flexDirection="row">
                        <Button className={isJobSiteVehicleServiceKind ? "" : (isUnloadingContinuityRequired && isUnloadingContinuityRequired !== null ? "btn-selected" : "btn-enabled")}
                            disabled={isJobSiteVehicleServiceKind}
                            onClick={() => { props.handleIsUnloadingContinuityRequiredChanged(true) }}>Oui</Button>
                        <Button className={isJobSiteVehicleServiceKind ? "" : (!isUnloadingContinuityRequired && isUnloadingContinuityRequired !== null ? "btn-selected" : "btn-enabled")}
                            disabled={isJobSiteVehicleServiceKind}
                            onClick={() => { props.handleIsUnloadingContinuityRequiredChanged(false) }}>Non</Button>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" mr={"20px"}>
                    <Box className="label">Nb total de tours</Box>
                    <Box display="flex" flexDirection="row">
                        <p className="trips">{quotationResult?.totalNumberOfTurns}</p>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row">
                <fieldset className="negotiated-price-fieldset separator-fieldset">
                    <legend className="negotiated-billed-price-legend">Tarifs ventes</legend>
                    <Box display="flex" flexDirection="row">
                        <Box display="flex" flexDirection="column" mr={"20px"}>
                            <Box className={sellPriceKindData ? "label" : "required-label"}>Type tarification</Box>
                            <Select
                                className={sellPriceKindData ? "input-select priceKind" : "input-select required priceKind"}
                                isClearable
                                placeholder=""
                                menuPosition="fixed"
                                menuShouldBlockScroll={true}
                                options={isJobSiteVehicleServiceKind ? FixedLists.jobSiteVehiclePriceKindsList : FixedLists.priceKindList}
                                value={sellPriceKindData}
                                onChange={(e) => props.handlePriceKindSelectChange(e)}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column" mr={"20px"}>
                            <Box className="label">Prix vente calculé</Box>
                            <Box display="flex" flexDirection="row">
                                <p className="estimated-price">{quotationResult?.estimatedPrice ? Number(quotationResult?.estimatedPrice).toLocaleString() + " €" : ''}</p>
                            </Box>
                        </Box>
                        <NumericTextInput title="Prix de vente unitaire"
                            titleClassName="label"
                            className={sellPriceKindData && sellPriceKindData?.value !== PriceGridKindEnum.PerTimePeriod && !isJobSiteVehicleServiceKind && !billedSellPrice.billedUnitPriceIsLocked ? "transport-price" : "transport-price-disabled"}
                            handleChange={(e) => props.handleUnitBilledPriceChange(e.value)}
                            handleChangeBilledUnitPriceIsLocked={props.handleChangeBilledUnitPriceIsLocked}
                            value={sellPriceKindData && sellPriceKindData?.value !== PriceGridKindEnum.PerTimePeriod && !isJobSiteVehicleServiceKind ? billedSellPrice?.billedUnitPriceValue : null}
                            maximumFractionDigits={4}
                            max={999999.9999}
                            isCurrency={true}
                            disabled={!sellPriceKindData || sellPriceKindData?.value === PriceGridKindEnum.PerTimePeriod || isJobSiteVehicleServiceKind || billedSellPrice.billedUnitPriceIsLocked}
                            isNegotiatedSellPrice={props.isNegotiatedSellPrice}
                            sellPriceIsLocked={billedSellPrice?.billedUnitPriceIsLocked}
                            isSalePriceFieldLock={sellPriceKindData && sellPriceKindData?.value !== PriceGridKindEnum.PerTimePeriod && !isJobSiteVehicleServiceKind}
                        />
                        <NumericTextInput title="Prix vente Flux"
                            boxClassName="billed-price-item"
                            titleClassName={billedSellPrice?.billedPriceValue !== null && billedSellPrice?.billedPriceValue !== 0 ? "billed-price-label" : "required-billed-price-label"}
                            className={billedSellPrice?.billedPriceValue !== null && billedSellPrice?.billedPriceValue !== 0 ? "container transport-price" : "container-required transport-price"}
                            handleChange={(e) => props.handleBilledPriceChange(e.value)}
                            width="110px"
                            value={billedSellPrice?.billedPriceValue}
                            maximumFractionDigits={4}
                            max={999999.9999}
                            isCurrency={true}
                        />
                    </Box>
                </fieldset>

                {!isJobSiteVehicleServiceKind && <fieldset className="negotiated-price-fieldset">
                    <legend className="negotiated-purchase-price-legend">Tarifs achats négociés</legend>
                    <Box display="flex" flexDirection="row">
                        <Box display="flex" flexDirection="column" mr={"20px"}>
                            <Box className="label">Type tarification</Box>
                            <Select
                                className="purchasePriceKind"
                                isClearable
                                placeholder=""
                                menuPosition="fixed"
                                menuShouldBlockScroll={true}
                                options={purchasePriceKindOptions}
                                value={negotiatedPurchasePriceKindDataValue}
                                onChange={(e) => props.handleNegotiatedPurchasePriceKindSelectChange(e)}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <div className="label">Prix à l'unité</div>
                                {isNegotiatedPurchasePrice && <div className="negotiated-purchase-price-r">R</div>}
                            </Box>

                            <NumericTextInput
                                boxClassName="purchase-price-item"
                                className="container"
                                width="110px"
                                handleChange={(e) => props.handleNegotiatedUnitPurchasePriceChange(e.value)}
                                value={negotiatedPurchasePrice}
                                maximumFractionDigits={4}
                                max={999999.9999}
                                isCurrency={true}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Box>
                                <input
                                    type="checkbox"
                                    className={negotiatedPurchasePriceAppliedToInternalExternalPriceRequired ? "required-checkbox" : ""}
                                    checked={negotiatedPurchasePriceAppliesToInternalTransporters}
                                    onChange={handleNegotiatedPurchasePriceAppliesToInternalTransportersChange}
                                />&nbsp;
                                <label className={negotiatedPurchasePriceAppliedToInternalExternalPriceRequired ? "required-label-checkbox" : ""}>Transporteurs internes</label>
                            </Box>
                            <Box>
                                <input
                                    type="checkbox"
                                    className={negotiatedPurchasePriceAppliedToInternalExternalPriceRequired ? "required-checkbox" : ""}
                                    checked={negotiatedPurchasePriceAppliesToExternalTransporters}
                                    onChange={handleNegotiatedPurchasePriceAppliesToExternalTransportersChange}
                                />&nbsp;
                                <label className={negotiatedPurchasePriceAppliedToInternalExternalPriceRequired ? "required-label-checkbox" : ""}>Transporteurs externes</label>
                            </Box>
                        </Box>
                    </Box>
                </fieldset>}
            </Box>
        </Box>
    );
}
