import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult } from 'src/shared/models/WebAppActionResult';
import { LogisticsUnitLightModel } from '../../ProductionSitesReferential/services/dataContracts/queryStack/LogisticsUnitLightModel';
import { UpdateLogisticsUnitLogisticianRequestArgs } from './dataContracts/controller/UpdateLogisticsUnitLogisticianRequestArgs';
import { LogisticsUnitLogisticianLightModel } from '../../ProductionSitesReferential/services/dataContracts/queryStack/LogisticsUnitLogisticianLightModel';

const controllerUrl = 'api/LogisticsUnitLogisticiansReferential/';

export class LogisticsUnitLogisticiansReferentialApiClient {
    public static GetLogisticsUnits = (searchText: string)
        : Promise<AxiosResponse<Array<LogisticsUnitLightModel>>> => {
        return axios.get(`${controllerUrl}GetLogisticsUnits?searchText=${encodeURIComponent(searchText)}`);
    }

    public static GetLogisticiansByLogisticsUnit = (logisticsUnitId: string)
        : Promise<AxiosResponse<Array<LogisticsUnitLogisticianLightModel>>> => {
        return axios.get(`${controllerUrl}GetLogisticiansByLogisticsUnit?logisticsUnitId=${encodeURIComponent(logisticsUnitId)}`);
    }

    public static UpdateLogisticsUnitLogistician = (requestArgs: UpdateLogisticsUnitLogisticianRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateLogisticsUnitLogistician`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }
}
