import { faCheck, faCheckDouble, faClock, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';

export const CustomCostsPublishedCell = (props: GridCellProps) => {
    let costsArePublished = false;
    const dataItem: PlanningVehicleLightModelExtended = props.dataItem;
    if (dataItem[props.field] != null) {
        costsArePublished = dataItem[props.field];
    }

    let defaultRendering: JSX.Element = <></>;

    if (dataItem[props.field] === null || !costsArePublished) {
        defaultRendering =
            <td>
                <FontAwesomeIcon className="planning-vehicle_costs-are-not-published" size="lg" icon={faMinus} />
            </td>;
    } else {
        defaultRendering = 
            dataItem.costsDisagreementReason 
                ? <Tooltip title={dataItem.costsDisagreementReason} placement="bottom-start">
                    <td>
                        { dataItem.costsAreAgreed === null 
                            ? <FontAwesomeIcon className="planning-vehicle_costs-are-published" size="lg" icon={faClock} />
                            : dataItem.costsAreAgreed === true
                                ? dataItem.costsDisagreementReason
                                    ? <FontAwesomeIcon className="planning-vehicle_costs-are-agreed" size="lg" icon={faCheckDouble} />
                                    : <FontAwesomeIcon className="planning-vehicle_costs-are-agreed" size="lg" icon={faCheck} />
                                : <FontAwesomeIcon className="planning-vehicle_costs-are-not-agreed" size="lg" icon={faTimes} />
                        }
                    </td>
                </Tooltip>
                : <td>
                    { dataItem.costsAreAgreed === null 
                        ? <FontAwesomeIcon className="planning-vehicle_costs-are-published" size="lg" icon={faClock} />
                        : dataItem.costsAreAgreed === true
                            ? dataItem.costsDisagreementReason
                                ? <FontAwesomeIcon className="planning-vehicle_costs-are-agreed" size="lg" icon={faCheckDouble} />
                                : <FontAwesomeIcon className="planning-vehicle_costs-are-agreed" size="lg" icon={faCheck} />
                            : <FontAwesomeIcon className="planning-vehicle_costs-are-not-agreed" size="lg" icon={faTimes} />
                    }
                </td>
    }

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}
