import { Avatar, Box, Drawer } from '@mui/material';
import { GridCellProps } from '@progress/kendo-react-grid';
import clsx from 'clsx';
import React from 'react';
import { TransportRequestChangesView } from '../../../shared/TransportRequestChanges/TransportRequestChangesView';
import { TransportRequestLightModelExtended } from '../models/TransportRequestLightModelExtended';

type DrawerSide = 'top' | 'left' | 'bottom' | 'right';

interface AlertState {
    right: boolean,
    open: boolean
}

interface CustomAlertCellProps extends GridCellProps {
    vehicleTypesLabels: Map<string, string>,
    updateAlertHandler: (props: TransportRequestLightModelExtended, alertValue: boolean) => void,
    unobservedDc: boolean
}

export class CustomAlertCellComponent extends React.Component<CustomAlertCellProps, AlertState>{
    dateAl = '';

    constructor(props: CustomAlertCellProps) {
        super(props);

        this.state = {
            right: false,
            open: false
        };
        if (props.dataItem.deliveryDate) {
            this.dateAl = (new Date(props.dataItem.deliveryDate)).toShortDateString();
        }
    }

    updateTransportRequestUnobserved = (unobservedDc: boolean): void => {
        this.props.updateAlertHandler(this.props.dataItem, unobservedDc);
    }

    toggleDrawer = (side: DrawerSide, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event && event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        this.setState({ ...this.state, [side]: open });
    };

    closeDrawer = (): void => {
        this.setState({ 'right': false });
    }

    sideList = (side: DrawerSide): JSX.Element => (
        <div className='alert-drawer-content'
            role="presentation"
            onKeyDown={this.toggleDrawer(side, false)}
        >
            <TransportRequestChangesView updateAlertHandler={this.updateTransportRequestUnobserved}
                businessId={this.props.dataItem.businessId} transportRequestId={this.props.dataItem.transportRequestId}
                dateAl={this.dateAl} vehicleTypesLabels={this.props.vehicleTypesLabels} closeDrawer={this.closeDrawer} lastObservedRootChangeId={this.props.dataItem.lastObservedRootChangeId} lastRootChangeId={this.props.dataItem.lastRootChangeId} />
        </div>
    );

    render() {
        let classColor = 'circle-alert-dc-observed';
        if (this.props.unobservedDc)
            classColor = 'circle-alert-dc-unobserved';
        return (
            <td>
                <Box display="flex" flexDirection="row" alignItems="center" p={1}>
                    <Box style={{ cursor: 'pointer' }} onClick={this.toggleDrawer('right', true)}>
                        <Avatar className='container-alert-cell'>
                            <Avatar className={clsx(classColor, 'alert-cell')}></Avatar>
                        </Avatar>
                    </Box>
                </Box>
                <Drawer
                    onClose={() => this.closeDrawer()} classes={{ paper: 'alert-drawer-paper', root: 'alert-drawer-root' }} anchor="right" open={this.state.right}>
                    <div className='toolbar-styles' />
                    {this.sideList('right')}
                </Drawer>
            </td>
        );
    }
}