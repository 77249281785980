import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderCellProps, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useEffect, useRef, useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { CustomCheckboxCell } from '../../Common/CustomCheckboxCell';
import { CustomHeaderCellComponent } from '../../Common/CustomHeaderCellComponent';
import { SizingUtilities } from '../../SizingUtilities';
import { InternalVehicleLightModelExtended } from '../models/InternalVehicleLightModelExtended';
import { InternalVehicleLightModel } from '../services/dataContracts/queryStack/InternalVehicleLightModel';
import { LogisticsUnitChoiceOfInternalVehiclesLightModel } from '../services/dataContracts/queryStack/LogisticsUnitChoiceOfInternalVehiclesLightModel';
import { InternalVehiclesReferentialApiClient } from '../services/InternalVehiclesReferentialApiClient';

interface InternalVehiclesComponentProps {
    internalVehicles: Array<InternalVehicleLightModel>,
    internalVehicleChoicesLogisticUnit: Array<LogisticsUnitChoiceOfInternalVehiclesLightModel>,
    sort: SortDescriptor[],
    handleSortChange: (e: GridSortChangeEvent) => void,
    handleSortColumnChange: (sortItems: SortDescriptor[]) => void,
    handleSelectedInternalVehicle: (vehicleId: number) => void,
    handleUpdateInternalVehicles: (internalVehicles: Array<InternalVehicleLightModel>, internalVehicleChoicesLogisticsUnit: Array<LogisticsUnitChoiceOfInternalVehiclesLightModel>) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const InternalVehiclesReferentialGridName = 'internalVehiclesReferential';
const ModuleKey = AppModule.InternalVehiclesReferential;

export const InternalVehiclesComponent = (props: InternalVehiclesComponentProps) => {

    const [skip, setSkip] = useState<number>(0);
    const [internalVehicleChoicesDataArray, setInternalVehicleChoicesDataArray] = useState<Array<InternalVehicleLightModelExtended>>([]);
    const gridRef = useRef<any>();
    const [selectedRowId, setSelectedRowId] = useState(null);

    useEffect(() => {
        getInternalVehicleChoicesData();
    }, []);

    useEffect(() => {
        getInternalVehicleChoicesData();
    }, [props.internalVehicles]);

    useEffect(() => {
        var vehicleIndexString = LocalStorage.GetItem(ModuleKey, 'lastVehicleIndexSelected');
        LocalStorage.SetItem(ModuleKey, 'selected', 'false');
        if (vehicleIndexString !== null && gridRef.current) {
            var grid = orderBy(props.internalVehicles, props.sort);
            var vehicleIdString = LocalStorage.GetItem(ModuleKey, 'lastVehicleIdSelected');
            var vehicleId = parseInt(vehicleIdString, 10);
            var index = grid.findIndex(row => row.id === vehicleId);
            LocalStorage.SetItem(ModuleKey, 'selected', 'true');
            gridRef.current.scrollIntoView({ rowIndex: index - 2 });
            LocalStorage.RemoveItem(ModuleKey, 'lastVehicleIndexSelected');
        }
    }, [props.sort]);

    const forceUpdate = useForceUpdate();

    const getInternalVehicleChoicesData = (): void => {
        const dataArray: Array<InternalVehicleLightModelExtended> = [];

        props.internalVehicles.forEach((vehicle: InternalVehicleLightModel) => {
            const element: InternalVehicleLightModelExtended = { ...vehicle };

            let checked = true;
            let indeterminateAll = true;
            let numberOfChecked = 0;
            let numberOfUnchecked = 0;

            props.internalVehicleChoicesLogisticUnit.forEach((logisticsUnit: LogisticsUnitChoiceOfInternalVehiclesLightModel) => {
                element[logisticsUnit.logisticsUnitId] = logisticsUnit.chosenInternalVehicles.includes(element.id);
                if (!logisticsUnit.chosenInternalVehicles.includes(element.id)) {
                    checked = false;
                    numberOfUnchecked += 1;
                } else {
                    numberOfChecked += 1;
                }
            });

            if (props.internalVehicleChoicesLogisticUnit.length === numberOfUnchecked || props.internalVehicleChoicesLogisticUnit.length === numberOfChecked) {
                indeterminateAll = false;
            }
            element.isIndeterminateAll = indeterminateAll;
            element.isSelectedAll = checked ? true : (indeterminateAll ? null : false);

            element.driverFullName = element.driver?.fullName ?? "";
            dataArray.push(element);
        });

        setInternalVehicleChoicesDataArray(dataArray);
    }

    const getInternalVehiclesWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, InternalVehiclesReferentialGridName, fieldName, columnWidth);
    }

    const getVehicleGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, InternalVehiclesReferentialGridName, fieldName, defaultIndex);
    }

    const handleRowClick = (vehicle): void => {
        const dataItem = vehicle as InternalVehicleLightModelExtended;
        var vehicleIdString = LocalStorage.GetItem(ModuleKey, 'lastVehicleIdSelected');
        var vehicleId = parseInt(vehicleIdString, 10);
        var selected = LocalStorage.GetItem(ModuleKey, 'selected') === 'true';
        if (dataItem.selected || (selected && dataItem.id == vehicleId)) {
            LocalStorage.SetItem(ModuleKey, 'lastVehicleIdSelected', "0");
            LocalStorage.SetItem(ModuleKey, 'selected', 'false');
            setSelectedRowId(null)
            props.handleSelectedInternalVehicle(null);
        }
        else {
            LocalStorage.SetItem(ModuleKey, 'lastVehicleIdSelected', dataItem.id.toString());
            LocalStorage.SetItem(ModuleKey, 'selected', 'true');
            setSelectedRowId(dataItem.id)
            props.handleSelectedInternalVehicle(dataItem.id);
        }

    }

    const rowRender = (trElement, row) => {
        var vehicleIndexString = LocalStorage.GetItem(ModuleKey, 'lastVehicleIdSelected');
        var selected = LocalStorage.GetItem(ModuleKey, 'selected') === 'true';
        var selectedRowIdFromStorage = parseInt(vehicleIndexString, 10);
        const data = row.dataItem;
        const className = selected && (selectedRowId == row.dataItem.id || selectedRowIdFromStorage == row.dataItem.id) ? 'k-table-row k-master-row k-selected' : 'k-table-row k-master-row';
        const onClickHandler = () => handleRowClick(data);
        return React.cloneElement(trElement, { onClick: onClickHandler, className: className });
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            InternalVehiclesReferentialGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, InternalVehiclesReferentialGridName, event.columns);
        forceUpdate();
    }

    const updateChoicesVehicleslogisticsUnit = (checked: boolean, logisticsUnitId: string, vehicleId: number): void => {
        InternalVehiclesReferentialApiClient.AddOrRemoveInternalVehicleChoice({
            vehicleId: vehicleId,
            logisticsUnitId: logisticsUnitId,
            shouldAdd: checked
        })
            .then(() => {
                const item: InternalVehicleLightModelExtended = internalVehicleChoicesDataArray.find(x => x.id === vehicleId);
                const newInternalVehicleChoicesLogisticsUnit = [...props.internalVehicleChoicesLogisticUnit];
                const choice = newInternalVehicleChoicesLogisticsUnit.find(x => x.logisticsUnitId === logisticsUnitId);

                if (checked && choice.chosenInternalVehicles.findIndex(x => x === item.id) === -1) {
                    choice.chosenInternalVehicles.push(item.id);
                }
                else if (!checked && choice.chosenInternalVehicles.findIndex(x => x === item.id) !== -1) {
                    choice.chosenInternalVehicles = choice.chosenInternalVehicles.filter(x => x !== item.id);
                }

                item[logisticsUnitId] = checked;

                let checkedSelect = true;
                let indeterminateAll = true;
                let numberOfChecked = 0;
                let numberOfUnchecked = 0;
                props.internalVehicleChoicesLogisticUnit.forEach((logisticsUnit: LogisticsUnitChoiceOfInternalVehiclesLightModel) => {
                    if (!item[logisticsUnit.logisticsUnitId]) {
                        checkedSelect = false;
                        numberOfUnchecked += 1;
                    } else {
                        numberOfChecked += 1;
                    }
                });
                if (props.internalVehicleChoicesLogisticUnit.length === numberOfUnchecked || props.internalVehicleChoicesLogisticUnit.length === numberOfChecked) {
                    indeterminateAll = false;
                }

                item.isSelectedAll = checkedSelect ? true : (indeterminateAll ? null : false);
                item.isIndeterminateAll = indeterminateAll;

                props.handleUpdateInternalVehicles(internalVehicleChoicesDataArray, newInternalVehicleChoicesLogisticsUnit);
            });
    }

    const updateChoicesVehiclesAllLogisticsUnits = (checked: boolean, vehicleId: number): void => {
        const logisticsUnitIds: Array<string> = [];
        props.internalVehicleChoicesLogisticUnit.forEach((element: LogisticsUnitChoiceOfInternalVehiclesLightModel) => {
            logisticsUnitIds.push(element.logisticsUnitId);
        });

        InternalVehiclesReferentialApiClient.AddOrRemoveInternalVehicleChoices({
            vehicleId: vehicleId,
            shouldAdd: checked,
            logisticsUnitIds: logisticsUnitIds
        })
            .then(() => {
                const item: InternalVehicleLightModelExtended = internalVehicleChoicesDataArray.find(x => x.id === vehicleId);
                const newInternalContactChoicesLogisticsUnit = [...props.internalVehicleChoicesLogisticUnit];

                logisticsUnitIds.forEach((logisticsUnitId: string) => {
                    const choice = newInternalContactChoicesLogisticsUnit.find(x => x.logisticsUnitId === logisticsUnitId);
                    if (checked) {
                        choice.chosenInternalVehicles.push(item.id);
                    }
                    else {
                        choice.chosenInternalVehicles = choice.chosenInternalVehicles.filter(x => x !== item.id);
                    }

                    item[logisticsUnitId] = checked;
                });
                item.isSelectedAll = checked ? true : false;
                item.isIndeterminateAll = false;

                props.handleUpdateInternalVehicles(internalVehicleChoicesDataArray, newInternalContactChoicesLogisticsUnit);
            });
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.internalVehicles.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: InternalVehicleLightModelExtended[] = orderBy(internalVehicleChoicesDataArray, props.sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="internal-vehicles-grid">
            <Grid
                ref={gridRef}
                className="vehicles-grid"
                data={gridData}
                selectedField="selected"
                sortable
                reorderable
                resizable
                sort={props.sort}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={props.handleSortChange}
                onRowClick={e => handleRowClick(e)}
                editField="inEdit"
                scrollable="virtual"
                rowHeight={rowHeight}
                skip={skip}
                total={totalGrid}
                pageSize={gridPageSize}
                onPageChange={pageChange}
                style={gridStyle}
                rowRender={rowRender}
            >
                <GridColumn field="isSelectedAll" orderIndex={getVehicleGridOrderIndexColumn("isSelectedAll", 0)} width={getInternalVehiclesWidth("isSelectedAll", 100)} title="Sélection"
                    cell={(props: GridCellProps) =>
                        <CustomCheckboxCell
                            {...props}
                            keyName="id"
                            updateChoicesAllLogisticsUnits={updateChoicesVehiclesAllLogisticsUnits}
                        />}
                    headerCell={(_props: GridHeaderCellProps) =>
                        <CustomHeaderCellComponent tooltip="Activer/Désactiver un camion pour toutes les zones logistiques" title={_props.title} field="isSelectedAll" sort={props.sort}
                            handleSortColumnChange={props.handleSortColumnChange} />
                    }
                />
                <GridColumn field="hasDifference"
                    cell={(props: GridCellProps) => {
                        const dataItem = props.dataItem as InternalVehicleLightModelExtended;
                        return <td className="warning-icon-cell">
                            {dataItem.hasDifference ?
                                <Box>
                                    <FontAwesomeIcon size="lg" icon={faExclamationTriangle} />
                                </Box> : ''
                            }
                        </td>
                    }}
                    orderIndex={getVehicleGridOrderIndexColumn("hasDifference", 1)} width={getInternalVehiclesWidth("hasDifference", 120)} title="Différence" />
                <GridColumn field="licencePlate"
                    cell={(props: GridCellProps) => {
                        const dataItem = props.dataItem as InternalVehicleLightModelExtended;
                        return <td className={dataItem.hasDifferentLicensePlateOnColasReferential ? "field-changed" : ""}>
                            {dataItem.licencePlate}
                        </td>
                    }}
                    orderIndex={getVehicleGridOrderIndexColumn("licencePlate", 2)} width={getInternalVehiclesWidth("licencePlate", 120)} title="Plaque d'immat" />
                <GridColumn field="equipmentId" orderIndex={getVehicleGridOrderIndexColumn("equipmentId", 3)} width={getInternalVehiclesWidth("equipmentId", 120)} title="Id du véhicule" />
                <GridColumn field="vehicleTypeLabel" orderIndex={getVehicleGridOrderIndexColumn("vehicleTypeLabel", 4)} width={getInternalVehiclesWidth("vehicleTypeLabel", 120)} title="Type de vehicule" />
                <GridColumn field="ownerAgencyLabel"
                    cell={(props: GridCellProps) => {
                        const dataItem = props.dataItem as InternalVehicleLightModelExtended;
                        return <td className={dataItem.hasDifferentAgencyOnColasReferential ? "field-changed" : ""}>
                            {dataItem.ownerAgencyLabel}
                        </td>
                    }}
                    orderIndex={getVehicleGridOrderIndexColumn("ownerAgencyLabel", 5)} width={getInternalVehiclesWidth("ownerAgencyLabel", 200)} title="Agence travaux" />
                <GridColumn field="driverFullName" orderIndex={getVehicleGridOrderIndexColumn("driverFullName", 6)} width={getInternalVehiclesWidth("driverFullName", 200)} title="Chauffeur associé" />
                {props.internalVehicleChoicesLogisticUnit.map((logisticsUnit: LogisticsUnitChoiceOfInternalVehiclesLightModel, i: number) =>
                    <GridColumn key={i} field={logisticsUnit.logisticsUnitId} orderIndex={getVehicleGridOrderIndexColumn(logisticsUnit.logisticsUnitId, 6 + i)} width={getInternalVehiclesWidth(logisticsUnit.logisticsUnitId, 150)} title={logisticsUnit.label}
                        cell={(props: GridCellProps) =>
                            <CustomCheckboxCell
                                {...props}
                                keyName="id"
                                updateChoicesLogisticsUnit={updateChoicesVehicleslogisticsUnit}
                            />}
                        headerCell={(_props: GridHeaderCellProps) =>
                            <CustomHeaderCellComponent tooltip="Activer/Désactiver un camion pour la zone logistique" title={_props.title} field={logisticsUnit.logisticsUnitId} sort={props.sort}
                                handleSortColumnChange={props.handleSortColumnChange} />
                        }
                    />
                )}
            </Grid>
        </Box>
    );
}
