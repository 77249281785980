import { Box, Button } from '@mui/material';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { AxiosResponse } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { unstable_usePrompt } from 'react-router-dom';
import { LogisticsUnitChoice } from 'src/shared/models/LogisticsUnitChoice';
import { LogisticsUnitSelectModel } from 'src/shared/models/LogisticsUnitSelectModel';
import { PerTimePeriodPriceGridModelData } from 'src/shared/Quotation/services/dataContracts/admin/PerTimePeriodPriceGridModelData';
import { PerTonPriceGridModelData } from 'src/shared/Quotation/services/dataContracts/admin/PerTonPriceGridModelData';
import { PerTurnPriceGridModelData } from 'src/shared/Quotation/services/dataContracts/admin/PerTurnPriceGridModelData';
import { PriceCell } from 'src/shared/Quotation/services/dataContracts/admin/PriceCell';
import { PriceGrid } from 'src/shared/Quotation/services/dataContracts/admin/PriceGrid';
import { PriceGridKind } from 'src/shared/Quotation/services/dataContracts/admin/PriceGridKind';
import { PriceGridSelectionRule } from 'src/shared/Quotation/services/dataContracts/admin/PriceGridSelectionRule';
import { PriceGridsListEntry } from 'src/shared/Quotation/services/dataContracts/admin/PriceGridsListEntry';
import { PriceGridUpdateRequestData } from 'src/shared/Quotation/services/dataContracts/admin/PriceGridUpdateRequestData';
import { TransportQuotationApiClient } from 'src/shared/Quotation/services/TransportQuotationApiClient';
import ToastService from 'src/ToastService';
import BusinessErrors from 'src/utils/BusinessErrors';
import { ContentHeaderComponent } from './components/ContentHeaderComponent';
import { PerTimePeriodGridComponent } from './components/PerTimePeriodGridComponent';
import { PerTurnOrTonGridComponent } from './components/PerTurnOrTonGridComponent';
import { BaseRowModel } from './models/BaseRowModel';
import { PerTimePeriodRowModel } from './models/PerTimePeriodRowModel';
import { PerTurnOrTonPeriodRowModel } from './models/PerTurnOrTonPeriodRowModel';
import './QuotationPricesReferentialStyles.scss';

interface QuotationPricesReferentialProperties {
    logisticsUnits: Array<LogisticsUnitChoice>
}

enum Action {
    LogisticsUnitChange,
    PriceGridChange
}
interface ConfirmationActionModal {
    showModal: boolean,
    action?: Action,
    newValue?: LogisticsUnitSelectModel | PriceGridsListEntry
}

export const QuotationPricesReferentialView = (props: QuotationPricesReferentialProperties): JSX.Element => {
    const [selectedLogisticsUnit, setSelectedLogisticsUnit] = useState<LogisticsUnitSelectModel>(props.logisticsUnits.length == 1 ? { value: props.logisticsUnits[0].logisticsUnitId, label: props.logisticsUnits[0].label } : null);
    const [priceGrids, setPriceGrids] = useState<PriceGridsListEntry[]>([]);
    const [selectedPriceGrid, setSelectedPriceGrid] = useState<PriceGridsListEntry>(null);
    const [priceGridData, setPriceGridData] = useState<PriceGrid>(null);
    const [pricingSelectionRulesData, setPricingSelectionRulesData] = useState<PriceGridSelectionRule[]>([]);
    const [gridDataParsed, setGridDataParsed] = useState<Array<PerTurnOrTonPeriodRowModel | PerTimePeriodRowModel>>([]);
    const [isPriceDataChanged, setIsPriceDataChanged] = useState<boolean>(false);
    const [showConfirmationSaveDataModal, setShowConfirmationSaveDataModal] = useState<ConfirmationActionModal>({
        showModal: false
    });
    const [hasMaintenanceLock, setHasMaintenanceLock] = useState<boolean>(false);

    useEffect(() => {
        if (selectedLogisticsUnit?.value) {
            getPriceGridsListWithSelectionRules(selectedLogisticsUnit.value);
        }
        else {
            TransportQuotationApiClient.HasMaintenanceLock()
                .then(res => {
                    setHasMaintenanceLock(res.data);
                });
        }
    }, []);

    const getPriceGridsListWithSelectionRules = async (logisticsUnitId: string): Promise<void> => {
        setGridDataParsed([]);
        await Promise.all([
            TransportQuotationApiClient.GetPriceGridsList(logisticsUnitId)
                .then(res => {
                    setPriceGrids(res.data);
                })
                .catch(e => {
                    setPriceGrids([]);

                    if (e.response?.status === 404) {
                        ToastService.showWarningToast("Aucune grille de tarif paramétrée sur cette zone logistique");
                    }
                    else {
                        ToastService.showErrorToast("L'action a échoué en raison d'une erreur technique. Si ce problème persiste, veuillez contacter le support.");
                    }
                }),
            TransportQuotationApiClient.GetPricingSelectionRulesData(logisticsUnitId)
                .then(res => {
                    setPricingSelectionRulesData(res.data);
                })
                .catch(e => {
                    setPricingSelectionRulesData([]);
                }),
            TransportQuotationApiClient.HasMaintenanceLock()
                .then(res => {
                    setHasMaintenanceLock(res.data);
                })
        ]);
    }

    const handleLogisticsUnitChange = (event: LogisticsUnitSelectModel): void => {
        if (isPriceDataChanged) {
            setShowConfirmationSaveDataModal({
                showModal: true,
                action: Action.LogisticsUnitChange,
                newValue: event
            });
        }
        else {
            handleConfirmLogisticsUnitChange(event);
        }
    }

    const handleConfirmLogisticsUnitChange = (event: LogisticsUnitSelectModel): void => {
        setGridDataParsed([]);
        setIsPriceDataChanged(false);
        setSelectedLogisticsUnit(event);
        setSelectedPriceGrid(null);
        setPriceGridData(null);
        if (event?.value) {
            getPriceGridsListWithSelectionRules(event.value);
        }
    }

    const handlePriceGridChange = (event: PriceGridsListEntry): void => {
        if (event?.id !== selectedPriceGrid?.id) {
            if (isPriceDataChanged) {
                setShowConfirmationSaveDataModal({
                    showModal: true,
                    action: Action.PriceGridChange,
                    newValue: event
                });
            }
            else {
                handleConfirmPriceGridChange(event);
            }
        }
    }

    const getPriceGridData = (logisticsUnitId: string, priceGridId: string): Promise<[AxiosResponse<PriceGrid>, AxiosResponse<boolean>]> => {
        return Promise.all([
            TransportQuotationApiClient.GetPriceGridData(logisticsUnitId, priceGridId),
            TransportQuotationApiClient.HasMaintenanceLock()
        ]);
    }

    const refreshPriceGridData = (logisticsUnitId: string, priceGridId: string): void => {
        getPriceGridData(logisticsUnitId, priceGridId).then(res => {
            setPriceGridData(res[0].data);
            setHasMaintenanceLock(res[1].data);
            setIsPriceDataChanged(false);
        });
    }

    const handleConfirmPriceGridChange = (event: PriceGridsListEntry): void => {
        setSelectedPriceGrid(event);
        setIsPriceDataChanged(false);
        if (event?.id) {
            refreshPriceGridData(selectedLogisticsUnit.value, event.id);
        }
        else {
            setGridDataParsed([]);
        }
    }

    const isDataChanged = (gridData: Array<PerTurnOrTonPeriodRowModel | PerTimePeriodRowModel>) => {
        return gridData.some((row: BaseRowModel) => {
            for (const [key, value] of row.changes) {
                const oldValue = row[key];
                if (oldValue !== value) {
                    return true;
                }
            }
            return false;
        });
    }

    const handlePriceGridParsed = (gridData: Array<PerTurnOrTonPeriodRowModel | PerTimePeriodRowModel>): void => {
        setGridDataParsed(gridData);
        setIsPriceDataChanged(isDataChanged(gridData));
    }

    const handleHideModal = () => {
        setShowConfirmationSaveDataModal({
            showModal: false,
            action: undefined,
            newValue: undefined
        });
    }

    const handleConfirmSaveData = (): void => {
        const gridToUpdate = { ...priceGridData };

        let grid: PerTimePeriodPriceGridModelData | PerTonPriceGridModelData | PerTurnPriceGridModelData = selectedPriceGrid?.priceGridKind === PriceGridKind.perTimePeriod
            ? gridToUpdate.perTimePeriodGrid
            : selectedPriceGrid?.priceGridKind === PriceGridKind.perTon
                ? gridToUpdate.perTonGrid
                : gridToUpdate.perTurnGrid;

        gridDataParsed.forEach((item: PerTurnOrTonPeriodRowModel | PerTimePeriodRowModel) => {
            for (const [key, value] of item.changes) {
                let pricesRow: PriceCell[] = null;
                switch (selectedPriceGrid?.priceGridKind) {

                    case PriceGridKind.perTimePeriod:
                        pricesRow = (grid as PerTimePeriodPriceGridModelData).timePeriodPricesRows
                            .find(r => r.timePeriodId === item.id).pricesRow;
                        break;

                    case PriceGridKind.perTurn:
                    case PriceGridKind.perTon:
                    default:
                        switch (item.id) {

                            case "Prix minimum":
                                pricesRow = (grid as PerTonPriceGridModelData | PerTurnPriceGridModelData).minPricesRow;
                                break;

                            case "Prix maximum":
                                pricesRow = (grid as PerTonPriceGridModelData | PerTurnPriceGridModelData).maxPricesRow;
                                break;

                            default:
                                pricesRow = (grid as PerTonPriceGridModelData | PerTurnPriceGridModelData).tripDurationPricesRows
                                    .find(r => r.tripDurationLimitId === item.id).pricesRow;
                                break;
                        }
                        break;
                }

                pricesRow.find(r => r.vehicleTypeGroup === key).price = value;
            }
        });

        var updateGridRequestData: PriceGridUpdateRequestData = {
            perTimePeriodGrid: undefined,
            perTonGrid: undefined,
            perTurnGrid: undefined
        };

        if (selectedPriceGrid?.priceGridKind === PriceGridKind.perTimePeriod) {
            updateGridRequestData.perTimePeriodGrid = grid as PerTimePeriodPriceGridModelData
        }
        else if (selectedPriceGrid?.priceGridKind === PriceGridKind.perTon) {
            updateGridRequestData.perTonGrid = grid as PerTonPriceGridModelData
        }
        else {
            updateGridRequestData.perTurnGrid = grid as PerTurnPriceGridModelData;
        }

        TransportQuotationApiClient.UpdatePriceGridData(selectedLogisticsUnit.value, selectedPriceGrid.id, updateGridRequestData)
            .then(res => {
                const data = res.data;

                if (data.isSuccessfull) {
                    ToastService.showSuccessToast("La modification des tarifs de vente s'est déroulée avec succès", "La prise en compte des nouveaux tarifs se fera dans 5mn");
                }
                else {
                    ToastService.showErrorToast(BusinessErrors.GetError(data.businessError));
                }
            })
            .finally(() => {
                if (showConfirmationSaveDataModal.action === undefined) {
                    refreshPriceGridData(selectedLogisticsUnit.value, selectedPriceGrid.id);
                }
                else {
                    applyAction();
                }
            });
    }

    const applyAction = () => {
        if (showConfirmationSaveDataModal.action === Action.LogisticsUnitChange) {
            handleConfirmLogisticsUnitChange(showConfirmationSaveDataModal.newValue as LogisticsUnitSelectModel)
        }
        else if (showConfirmationSaveDataModal.action === Action.PriceGridChange) {
            handleConfirmPriceGridChange(showConfirmationSaveDataModal.newValue as PriceGridsListEntry);
        }
        handleHideModal();
    }

    const handleCancelSaveData = () => {
        applyAction();
    }

    unstable_usePrompt({
        message: "Une ou plusieurs modifications en cours. Voulez-vous quitter sans sauvegarder ?",
        when: ({ currentLocation, nextLocation }) =>
            isPriceDataChanged &&
            currentLocation.pathname !== nextLocation.pathname,
    });

    const contentHeaderComponent: JSX.Element = useMemo(() =>
        <ContentHeaderComponent
            logisticsUnits={props.logisticsUnits}
            selectedLogisticsUnit={selectedLogisticsUnit}
            priceGrids={priceGrids}
            selectedPriceGrid={selectedPriceGrid}
            pricingSelectionRulesData={pricingSelectionRulesData}
            isPriceDataChanged={isPriceDataChanged}
            handleLogisticsUnitChange={handleLogisticsUnitChange}
            handlePriceGridChange={handlePriceGridChange}
            handleConfirmSaveData={handleConfirmSaveData}
        />, [selectedLogisticsUnit, priceGrids, selectedPriceGrid, isPriceDataChanged]);

    return (
        <div className="quotation-prices-referential-view">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box className="title-section">
                        <h5>
                            Tarifs de vente
                        </h5>
                        <hr className="separator" />
                    </Box>
                    <Box display="flex" flexDirection="column" className="content-section">
                        {contentHeaderComponent}
                        {hasMaintenanceLock && <div className="error-message">
                            La mise à jour n'est pas possible en raison d'une opération de maintenance.
                        </div>}
                        {selectedPriceGrid?.id &&
                            <Box sx={{
                                paddingTop: "1em",
                            }}>
                                {selectedPriceGrid.priceGridKind == PriceGridKind.perTimePeriod
                                    ? <PerTimePeriodGridComponent hasMaintenanceLock={hasMaintenanceLock} priceGridData={priceGridData} handlePriceGridParsed={handlePriceGridParsed} />
                                    : <PerTurnOrTonGridComponent hasMaintenanceLock={hasMaintenanceLock} priceGridData={priceGridData} handlePriceGridParsed={handlePriceGridParsed} />
                                }
                            </Box>}
                    </Box>
                    <Modal show={showConfirmationSaveDataModal.showModal} onHide={handleHideModal} className='mt-5'>
                        <Modal.Header closeButton>
                            <Modal.Title>ATTENTION</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Vous allez quitter la fenêtre de saisie : Sauvegarder ?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="primary" onClick={handleConfirmSaveData}>
                                OUI
                            </Button>
                            <Button className="secondary" onClick={handleCancelSaveData}>
                                NON
                            </Button>
                            <Button className="secondary" onClick={handleHideModal}>
                                ANNULER
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}
