import { PriceGridKindEnum } from './models/PriceGridKindEnum';
import { PurchasePriceKindEnum } from './models/PurchasePriceGridEnum';
import { SelectInputItemModel } from './models/SelectInputItemModel';
import { TransportBillingModeEnum } from './models/TransportBillingModeEnum';

export class FixedLists {

    public static clientTypeList: SelectInputItemModel[] = [
        { label: "Interne", value: "internal" },
        { label: "Externe référencé", value: "externalReferenced" },
        { label: "Externe non référencé", value: "externalNotReferenced" }
    ];

    public static priceKindList: SelectInputItemModel[] = [
        { label: "Forfait", value: PriceGridKindEnum.PerTimePeriod, disabled: false },
        { label: "Tonne", value: PriceGridKindEnum.PerTon, disabled: false },
        { label: "Tour", value: PriceGridKindEnum.PerTurn, disabled: false }
    ];

    public static jobSiteVehiclePriceKindsList: SelectInputItemModel[] = [
        { label: "Forfait", value: PriceGridKindEnum.PerTimePeriod, disabled: false },
        { label: "Tonne", value: PriceGridKindEnum.PerTon, disabled: true },
        { label: "Tour", value: PriceGridKindEnum.PerTurn, disabled: true }
    ];

    public static siteKindList: SelectInputItemModel[] = [
        { label: "Site de production", value: "productionSite", disabled: false },
        { label: "Chantier", value: "jobSite", disabled: false },
        { label: "Sté externe référencée", value: "referencedCustomer", disabled: false },
        { label: "Non référencé", value: "unreferencedCustomer", disabled: false }
    ];

    public static serviceKindList: SelectInputItemModel[] = [
        { label: "Périssables", value: "perishable", disabled: false },
        { label: "Non périssables", value: "notPerishable", disabled: false },
        { label: "Enlèvements", value: "removal", disabled: false },
        { label: "Camions chantier", value: "jobsiteVehicle", disabled: false }
    ];

    public static transportBillingModeList: SelectInputItemModel[] = [
        { label: "Départ", value: TransportBillingModeEnum.Departure },
        { label: "Rendu", value: TransportBillingModeEnum.Inclusive }
    ]

    public static negotiatedPurchasePriceKindsList: SelectInputItemModel[] = [
        { label: "Heure", value: PurchasePriceKindEnum.PerHour},
        { label: "Tonne", value: PurchasePriceKindEnum.PerTon },
        { label: "Tour", value: PurchasePriceKindEnum.PerTurn }
    ];
}