import { Box, Button } from '@mui/material';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { unstable_usePrompt } from 'react-router-dom';
import { LogisticsUnitChoice } from 'src/shared/models/LogisticsUnitChoice';
import { LogisticsUnitSelectModel } from 'src/shared/models/LogisticsUnitSelectModel';
import ToastService from 'src/ToastService';
import BusinessErrors from 'src/utils/BusinessErrors';
import { RouteComponentProps, withRouter } from '../../../withRouter';
import { ContentHeaderComponent } from './components/ContentHeaderComponent';
import { GenericPurchasePricesComponent } from './components/GenericPurchasePricesComponent';
import './GenericPurchasePricesReferentialStyles.scss';
import { GenericPurchasePricesModel } from './models/GenericPurchasePricesModel';
import { UpdateLogisticsUnitGenericPurchasePricesArgs } from './services/dataContracts/controller/UpdateLogisticsUnitGenericPurchasePricesArgs';
import { UpdateLogisticsUnitGenericPurchasePricesArgsItem } from './services/dataContracts/controller/UpdateLogisticsUnitGenericPurchasePricesArgsItem';
import { VehicleTypeLightModel } from './services/dataContracts/queryStack/VehicleTypeLightModel';
import { GenericPurchasePricesReferentialApiClient } from './services/GenericPurchasePricesReferentialApiClient';

interface GenericPurchasePricesReferentialProps {
    logisticsUnits: Array<LogisticsUnitChoice>
}
enum Action {
    LogisticsUnitChange,
    PriceGridChange
}
interface ConfirmationActionModal {
    showModal: boolean,
    action?: Action,
    newValue?: LogisticsUnitSelectModel
}

const GenericPurchasePricesReferentialView = (props: RouteComponentProps & GenericPurchasePricesReferentialProps) => {
    const [selectedLogisticsUnit, setSelectedLogisticsUnit] = useState<LogisticsUnitSelectModel>(props.logisticsUnits.length == 1 ? { value: props.logisticsUnits[0].logisticsUnitId, label: props.logisticsUnits[0].label } : null);
    const [isPriceDataChanged, setIsPriceDataChanged] = useState<boolean>(false);
    const [showConfirmationSaveDataModal, setShowConfirmationSaveDataModal] = useState<ConfirmationActionModal>({
        showModal: false
    });
    const [gridDataParsed, setGridDataParsed] = useState<Array<GenericPurchasePricesModel>>([]);
    const [vehiculesType, setVehiculesType] = useState<VehicleTypeLightModel[]>([]);
    const [isLogisticUnitHavePurchasePrices, SetIsLogisticUnitHavePurchasePrices] = useState<boolean>(true);

    useEffect(() => {
        if (selectedLogisticsUnit?.value) {
            getVehiculeTypeAndPricWithSelectionRules(selectedLogisticsUnit.value);
        }
    }, []);

    const getVehiculeTypeAndPricWithSelectionRules = (logisticsUnitId: string): void => {
        setGridDataParsed(null);
        GenericPurchasePricesReferentialApiClient.GetVehicleTypes(logisticsUnitId)
            .then(res => {
                setVehiculesType(res.data);
                SetIsLogisticUnitHavePurchasePrices(res.data.length > 0 && logisticsUnitId != null)
            })
            .catch(e => {
                setVehiculesType([]);
                if (e.response?.status === 404) {
                    ToastService.showWarningToast("Aucune grille de tarif paramétrée sur cette zone logistique");
                }
                else {
                    ToastService.showErrorToast("L'action a échoué en raison d'une erreur technique. Si ce problème persiste, veuillez contacter le support.");
                }
            });
    }

    const handleLogisticsUnitChange = (event: LogisticsUnitSelectModel): void => {
        if (isPriceDataChanged) {
            setShowConfirmationSaveDataModal({
                showModal: true,
                action: Action.LogisticsUnitChange,
                newValue: event
            });
        }
        else {
            handleConfirmLogisticsUnitChange(event);
        }
    }

    const handleConfirmLogisticsUnitChange = (event: LogisticsUnitSelectModel): void => {
        setSelectedLogisticsUnit(event);
        setGridDataParsed(null);
        setIsPriceDataChanged(false);
        if (event?.value) {
            getVehiculeTypeAndPricWithSelectionRules(event.value);
        }
    }

    const isDataChanged = (gridData: Array<GenericPurchasePricesModel>): boolean => {
        return gridData.some((row: GenericPurchasePricesModel) => {
            const oldValue = row.change;
            return row.price !== oldValue;
        });
    };

    const handlePriceGridParsed = (gridData: Array<GenericPurchasePricesModel>): void => {
        setGridDataParsed(gridData);
        setIsPriceDataChanged(isDataChanged(gridData));
    }

    const handleHideModal = () => {
        setShowConfirmationSaveDataModal({
            showModal: false,
            action: undefined,
            newValue: undefined
        });
    }

    const handleConfirmGeneratePurchasePrices = (): void => {
        GenericPurchasePricesReferentialApiClient.GeneratePurchasePricesForNewLogiticUnit(selectedLogisticsUnit.value)
            .then(res => {
                console.log(res);
                getVehiculeTypeAndPricWithSelectionRules(selectedLogisticsUnit.value);
                SetIsLogisticUnitHavePurchasePrices(true)
            })
            .catch(e => {
                setVehiculesType([]);
                if (e.response?.status === 404) {
                    ToastService.showWarningToast("Aucune grille de tarif n'est généré sur cette zone logistique");
                }
                else {
                    ToastService.showErrorToast("L'action a échoué en raison d'une erreur technique. Si ce problème persiste, veuillez contacter le support.");
                }
            });
    }

    const handleConfirmSaveData = (): void => {
        let grid: UpdateLogisticsUnitGenericPurchasePricesArgs = {
            logisticUnitId: selectedLogisticsUnit.value,
            purchasePrices: []
        }
        gridDataParsed.forEach((item: GenericPurchasePricesModel) => {
            if (item.price != item.change) {
                let purchasePrice: UpdateLogisticsUnitGenericPurchasePricesArgsItem = {
                    vehicleTypeId: item.vehicleTypeId,
                    price: item.change
                };
                grid.purchasePrices.push(purchasePrice);
            }
        });

        GenericPurchasePricesReferentialApiClient.UpdateGenericPurchasePrices(grid)
            .then(res => {
                const errors = BusinessErrors.Get(res.data);
                if (res.status == 200 && errors.length == 0) {
                    setIsPriceDataChanged(false);
                    ToastService.showSuccessToast("La modification des tarifs génériques s'est déroulée avec succès");
                }
                else {
                    ToastService.showErrorToast("", errors);
                }
            })
            .finally(() => {
                if (showConfirmationSaveDataModal.action === undefined) {
                    getVehiculeTypeAndPricWithSelectionRules(selectedLogisticsUnit.value);
                }
                else {
                    applyAction();
                }
                setIsPriceDataChanged(false);
            });
    }

    const applyAction = () => {
        if (showConfirmationSaveDataModal.action === Action.LogisticsUnitChange) {
            handleConfirmLogisticsUnitChange(showConfirmationSaveDataModal.newValue as LogisticsUnitSelectModel)
        }
        handleHideModal();
    }

    const handleCancelSaveData = () => {
        applyAction();
    }

    unstable_usePrompt({
        message: "Une ou plusieurs modifications en cours. Voulez-vous quitter sans sauvegarder ?",
        when: ({ currentLocation, nextLocation }) =>
            isPriceDataChanged &&
            currentLocation.pathname !== nextLocation.pathname,
    });

    const contentHeaderComponent: JSX.Element = useMemo(() =>
        <ContentHeaderComponent
            logisticsUnits={props.logisticsUnits}
            selectedLogisticsUnit={selectedLogisticsUnit}
            isPriceDataChanged={isPriceDataChanged}
            isLogisticUnitHavePurchasePrices={isLogisticUnitHavePurchasePrices}
            handleLogisticsUnitChange={handleLogisticsUnitChange}
            handleConfirmSaveData={handleConfirmSaveData}
            handleConfirmGeneratePurchasePrices={handleConfirmGeneratePurchasePrices}
        />, [selectedLogisticsUnit, isPriceDataChanged, isLogisticUnitHavePurchasePrices]);

    return (
        <div className="generic-purchase-prices-referential-view">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box className="title-section">
                        <h5>
                            Tarifs Achat génériques
                        </h5>
                        <hr className="separator" />
                    </Box>
                    <Box display="flex" flexDirection="column" className="content-section">
                        {contentHeaderComponent}
                    </Box>
                    <Box p={1}>
                        <GenericPurchasePricesComponent
                            vehiculeTypes={vehiculesType}
                            selectedLogisticsUnit={selectedLogisticsUnit}
                            handleLogisticsUnitChange={handleLogisticsUnitChange}
                            handlePriceGridParsed={handlePriceGridParsed} />
                    </Box>
                    <Modal show={showConfirmationSaveDataModal.showModal} onHide={handleHideModal} className='mt-5'>
                        <Modal.Header closeButton>
                            <Modal.Title>ATTENTION</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Vous allez quitter la fenêtre de saisie : Sauvegarder ?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="primary" onClick={handleConfirmSaveData}>
                                OUI
                            </Button>
                            <Button className="secondary" onClick={handleCancelSaveData}>
                                NON
                            </Button>
                            <Button className="secondary" onClick={handleHideModal}>
                                ANNULER
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </IntlProvider>
            </LocalizationProvider>
        </div >
    );
}

export default React.forwardRef(withRouter(GenericPurchasePricesReferentialView));
