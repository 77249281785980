import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Input, Tooltip } from '@mui/material';
import React from 'react';

interface SearchDriverStepComponentProperties {
    driverSearchLoading: boolean,
    mgrh: string,
    driverLastName: string,
    handleDriverMgrhChange: (value: string) => void,
    handleDriverLastNameChange: (value: string) => void,
    searchDriverByMgrh: (mgrh: string) => void,
    searchDriverByLastName: (lastName: string) => void
}

export const SearchDriverStepComponent = (props: SearchDriverStepComponentProperties): JSX.Element => {

    const AddDriverTooltipImage = "/static/Referentials/InternalDriversReferential/AddDriverTooltipImage.png";

    const searchDriver = (): void => {
        if (props.mgrh !== '')
            props.searchDriverByMgrh(props.mgrh);
        else if (props.driverLastName !== '')
            props.searchDriverByLastName(props.driverLastName);
    }

    const isSearchButtonDisabled: boolean = props.driverSearchLoading || (props.mgrh.length == 0 && props.driverLastName.length < 3);

    return (
        <Box className="search-driver-component">
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column" mb={"20px"}>
                    Information du chauffeur Colas à ajouter:
                </Box>
                <Box display="flex" flexDirection="column" className="driver-infos">
                    <Box display="flex" flexDirection="row" justifyContent="flex-start">
                        <Box display="flex" flexDirection="column" className="input-text-content">
                            <Box className="mgrh-input-text-label">Matricule</Box>
                            <Box display="flex" flexDirection="row">
                                <Input
                                    id="driver-mgrh"
                                    value={props.mgrh}
                                    className="driver-mgrh-input-text"
                                    onChange={(e) => props.handleDriverMgrhChange(e.target.value)}
                                    disabled={props.driverLastName !== ''}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center" ml={"10px"}>
                            <Tooltip id="add-driver-tooltip-image" placement="right-start" title={
                                <Box>
                                    <p className="info-user-text">Pour récupérer le matricule du chauffeur, vous pouvez par exemple le récupérer sur LISA (gestion des référentiel / Personnel): </p>
                                    <img src={AddDriverTooltipImage} />
                                </Box>
                            }>
                                <Avatar>
                                    <FontAwesomeIcon icon={faQuestion} />
                                </Avatar>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box pt={"20px"} pb={"20px"}>OU</Box>
                    <Box display="flex" flexDirection="column" className="input-text-content">
                        <Box className="driver-last-name-input-text-label">Nom de famille <b>complet</b></Box>
                        <Box display="flex" flexDirection="row">
                            <Input
                                id="driver-last-name"
                                value={props.driverLastName}
                                className="driver-last-name-input-text"
                                onChange={(e) => props.handleDriverLastNameChange(e.target.value)}
                                disabled={props.mgrh !== ''}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                    <Button variant="contained" color="primary" title="Chercher" onClick={() => searchDriver()} disabled={isSearchButtonDisabled}>
                        Chercher
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
