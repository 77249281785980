import './String'

export { }

declare global {

    interface Number {
        padZero(len?: number, c?: string): string;
        toStringFixed(culture: string, fixedDigits?: number): string;
        toCurrencyString(): string;
        toPercentString(): string;
    }
}

Number.prototype.padZero = function (len, c) {
    return String(this).padZero(len, c);
};

Number.prototype.toStringFixed = function (culture: string, fixedDigits = 2) {
    return this.toLocaleString(culture, { 'minimumFractionDigits': 0, 'maximumFractionDigits': fixedDigits });
};

Number.prototype.toCurrencyString = function () {
    return this.toLocaleString('fr-FR', { 'minimumFractionDigits': 0, 'maximumFractionDigits': 2 }) + ' €';
};

Number.prototype.toPercentString = function () {
    return this.toLocaleString('fr-FR', { 'minimumFractionDigits': 0, 'maximumFractionDigits': 2 }) + ' %';
};