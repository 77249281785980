import { Box, Divider } from '@mui/material';
import React from 'react';
import { VehicleGroupCountersLightModel } from '../services/dataContracts/queryStack/VehicleGroupCountersLightModel';

interface VehicleGroupCountersProps {
    vehicleGroupCounters: VehicleGroupCountersLightModel,
    lastTimeStampGetVehicleGroupCounters?: Date,
    showDailyCounters: boolean
}

export default class VehicleGroupCountersComponent extends React.PureComponent<VehicleGroupCountersProps, {}> {
    render() {
        const defaultRendering = (
            <div className="vehicle-count-container">
                <Box display="flex" flexWrap="nowrap" flexDirection="row" className="vehicle-count-title-margin table-head" alignItems="center">
                    <Box width="30%" className="vt-count-title-master"></Box>
                    <Box width="15%" className="vt-count-title vt-center">4X2</Box>
                    <Box width="15%" className="vt-count-title vt-center">4X2 Grue</Box>
                    <Box width="15%" className="vt-count-title vt-center">6X4</Box>
                    <Box width="15%" className="vt-count-title vt-center">6X4 Grue</Box>
                    <Box width="15%" className="vt-count-title vt-center">8X4</Box>
                    <Box width="15%" className="vt-count-title vt-center">8X4 Grue</Box>
                    <Box width="15%" className="vt-count-title vt-center">SEMI</Box>
                    <Box width="28%" className="vt-count-title vt-center">TRACTEURS</Box>
                    <Box width="20%" className="vt-count-title vt-center">Autres</Box>
                </Box>
                <Divider className='divider-title' />
                <div className="vehicle-count-content">
                    <Box display="flex" flexWrap="nowrap" flexDirection="row" className="vehicle-count-title-margin" alignItems="center">
                        <Box width="30%" className="vt-count-title">Total</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.x4Count}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.x4GrueCount}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.x6Count}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.x6GrueCount}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.x8Count}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.x8GrueCount}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.semiCount}</Box>
                        <Box width="28%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.tracteursCount}</Box>
                        <Box width="20%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.othersTypeCount}</Box>
                    </Box>
                    <Box display="flex" flexWrap="nowrap" flexDirection="row" className="vehicle-count-title-margin" alignItems="center">
                        <Box width="30%" className="vt-count-title">Manquants</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.missingX4Count}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.missingX4GrueCount}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.missingX6Count}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.missingX6GrueCount}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.missingX8Count}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.missingX8GrueCount}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.missingSemiCount}</Box>
                        <Box width="28%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.missingTracteursCount}</Box>
                        <Box width="20%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.missingOthersTypeCount}</Box>
                    </Box>
                    <Box display="flex" flexWrap="nowrap" flexDirection="row" className="vehicle-count-title-margin" alignItems="center">
                        <Box width="30%" className="vt-count-title">{this.props.showDailyCounters
                            ? `Jour: ${this.props.lastTimeStampGetVehicleGroupCounters ? Date.getDateMonthLettersFromIsoString(this.props.lastTimeStampGetVehicleGroupCounters) : ''}` : ''}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.dailyX4Count}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.dailyX4GrueCount}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.dailyX6Count}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.dailyX6GrueCount}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.dailyX8Count}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.dailyX8GrueCount}</Box>
                        <Box width="15%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.dailySemiCount}</Box>
                        <Box width="28%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.dailyTracteursCount}</Box>
                        <Box width="20%" className="vt-count-value vt-center">{this.props.vehicleGroupCounters.dailyOthersTypeCount}</Box>
                    </Box>
                </div>
            </div>
        );
        return defaultRendering;
    }
}