import React, { useState } from 'react';
import Select, { ActionMeta, components, OptionProps } from 'react-select';
import { SelectOptionModel } from '../models/SelectOptionModel';

interface MultiSelectVehicleTypesComponentProperties {
    vehicleTypes: Array<SelectOptionModel>,
    vehicleTypesValues: Array<SelectOptionModel>,
    index: number,
    changeVehicleTypesSelected: (opts: Array<SelectOptionModel>, index: number) => void
}

export interface OptionPropsModelExtended extends OptionProps<SelectOptionModel> {
    isCheckAllSelected?: boolean
}

const OptionItem = (props: OptionPropsModelExtended): JSX.Element => {

    const isSelectAllItemChecked: boolean = props.data.value === "selectAllVehicles" && props.isCheckAllSelected;

    return (
        <div>
            <components.Option {...props} className={isSelectAllItemChecked ? "selected-item" : ''}>
                <input
                    type="checkbox"
                    checked={props.data.value === "selectAllVehicles" ? props.isCheckAllSelected : props.isSelected}
                    onChange={() => null}
                />{" "}
                <label className={isSelectAllItemChecked ? "selected-item-label" : ''}>{props.data.label}</label>
            </components.Option>
        </div>
    );
}

export const MultiSelectVehicleTypesComponent = (props: MultiSelectVehicleTypesComponentProperties): JSX.Element => {

    const selectAllOption: SelectOptionModel = { label: "Sélectionner tout", value: "selectAllVehicles" };
    const [isCheckAllSelected, setIsCheckAllSelected] = useState<boolean>(false);

    const handleChangeTransportersSelected = (opts: Array<SelectOptionModel>, event: ActionMeta<SelectOptionModel>): void => {
        if (event.action === "select-option" && event.option.value === "selectAllVehicles" && isCheckAllSelected)
        {
            setIsCheckAllSelected(false);
            props.changeVehicleTypesSelected([], props.index);
            return;
        }

        switch (event.action) {
            case "select-option":
                setOptions(event.option.value, opts);
                break;
            case "deselect-option":
                removeOptions(event.option.value, opts);
                break;
            case "remove-value":
                removeOptions(event.removedValue.value, opts);
                break;
            case "clear":
                if (isCheckAllSelected)
                    setIsCheckAllSelected(false);

                props.changeVehicleTypesSelected([], props.index);
                break;
            default:
                return;
        }
    }

    const setOptions = (value: string, opts: Array<SelectOptionModel>): void => {
        if (value === "selectAllVehicles") {
            setIsCheckAllSelected(true);
            props.changeVehicleTypesSelected(props.vehicleTypes, props.index);
        } else {
            if (opts.length === props.vehicleTypes.length)
                setIsCheckAllSelected(true);

            props.changeVehicleTypesSelected(opts, props.index);
        }
    }

    const removeOptions = (value: string, opts: Array<SelectOptionModel>): void => {
        if (value === "selectAllVehicles") {
            props.changeVehicleTypesSelected([], props.index);
            setIsCheckAllSelected(false);
        } else {
            if (isCheckAllSelected)
                setIsCheckAllSelected(false);

            const options: Array<SelectOptionModel> = opts.filter(x => x.value !== value);
            props.changeVehicleTypesSelected(options, props.index);
        }
    }

    return (
        <Select
            placeholder="Choisir un type de véhicule"
            options={[selectAllOption, ...props.vehicleTypes]}
            value={props.vehicleTypesValues}
            onChange={(opts:SelectOptionModel[], event) => handleChangeTransportersSelected(opts, event)}
            menuPosition="fixed"
            menuShouldBlockScroll={true}
            isMulti
            components={{
                Option: (props) => <OptionItem {...props} isCheckAllSelected={isCheckAllSelected} />
            }}
            hideSelectedOptions={false}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
        />
    );
}