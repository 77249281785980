import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Input, Tooltip } from '@mui/material';
import React from 'react';

interface SearchCustomerStepComponentProperties {
    customerSearchLoading: boolean,
    customerIdMdm: string,
    customerName: string,
    handleCustomerIdMdmChange: (value: string) => void,
    handleCustomerNameChange: (value: string) => void,
    searchCustomerByMdmId: (customerIdMdm: string) => void,
    searchCustomerByName: (customerName: string) => void
}

export const SearchCustomerStepComponent = (props: SearchCustomerStepComponentProperties): JSX.Element => {

    const AddCustomerTooltipImage = "/static/Referentials/CustomerContactsReferential/AddCustomerTooltipImage.jpg";

    const searchCustomer = (): void => {
        if (props.customerIdMdm !== '')
            props.searchCustomerByMdmId(props.customerIdMdm);
        else if (props.customerName !== '')
            props.searchCustomerByName(props.customerName);
    }

    const isSearchButtonDisabled: boolean = props.customerSearchLoading || (props.customerIdMdm.length == 0 && props.customerName.length < 3);

    return (
        <Box className="search-customer-component">
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column" className="customer-infos">
                    <Box display="flex" flexDirection="row" justifyContent="flex-start">
                        <Box display="flex" flexDirection="column" className="input-text-content">
                            <Box className="idmdm-input-text-label">IdMdm du client externe référencé</Box>
                            <Box display="flex" flexDirection="row">
                                <Input
                                    id="customer-idmdm"
                                    value={props.customerIdMdm}
                                    className="customer-idmdm-input-text"
                                    onChange={(e) => props.handleCustomerIdMdmChange(e.target.value)}
                                    disabled={props.customerName !== ''}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center" ml={"10px"}>
                            <Tooltip id="add-customer-tooltip-image" placement="right-start" title={
                                <Box>
                                    <p className="info-user-text">Pour récupérer l’idMdM du client, vous pouvez le récupérer sur l’application de référentiel client: </p>
                                    <img src={AddCustomerTooltipImage} />
                                </Box>
                            }>
                                <Avatar>
                                    <FontAwesomeIcon icon={faQuestion} />
                                </Avatar>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box pt={"20px"} pb={"20px"}>OU</Box>
                    <Box display="flex" flexDirection="column" className="input-text-content">
                        <Box className="customer-name-input-text-label">Nom du client externe référencé</Box>
                        <Box display="flex" flexDirection="row">
                            <Input
                                id="customer-name"
                                value={props.customerName}
                                className="customer-name-input-text"
                                onChange={(e) => props.handleCustomerNameChange(e.target.value)}
                                disabled={props.customerIdMdm !== ''}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                    <Button variant="contained" color="primary" title="Chercher" onClick={() => searchCustomer()} disabled={isSearchButtonDisabled}>
                        Chercher
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}