import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Divider, Typography } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { EntityChangeAction } from '../models/EntityChangeAction';
import { OwnedEntity } from '../models/OwnedEntity';
import { EntityChangeSummaryLightModel } from '../services/dataContracts/queryStack/EntityChangeSummaryLightModel';
import { TransportRequestSummaryChangeAllLightModel } from '../services/dataContracts/queryStack/TransportRequestSummaryChangeAllLightModel';

interface TransportRequestChangeSummaryComponentProperties {
    tracking: TransportRequestSummaryChangeAllLightModel[],
    deliveryDate: Date,
    trackingCount: number,
    closeDrawer: () => void
}

export default class TransportRequestChangeSummaryComponent extends React.PureComponent<TransportRequestChangeSummaryComponentProperties, {}>{

    getNbTrackingByEntityChangeAction = (elems: EntityChangeSummaryLightModel[], entityType: string, entityChangeAction: string): number => {
        return elems.filter((e) => {
            return e.entityType === entityType && e.entityChangeAction === entityChangeAction
        }).length;
    }

    render() {
        const objNavLink = {
            pathname: '/',
            //Eviter de faire state car il sera persistent sur la page même si la page sera actualisée
            aboutProps: {
                deliveryDate: new Date(this.props.deliveryDate),
                needLoaded: true
            }
        };
        return (
            <Accordion>
                <AccordionSummary className="drawer-group-notif-dc" expandIcon={<ExpandMoreIcon />}>
                    <div className="container-expansionPanel-title">
                        <div className="container-expansionPanel-title-date">
                            <Typography variant="h6">
                                <NavLink onClick={this.props.closeDrawer} to={objNavLink} >
                                    {this.props.deliveryDate ? ((new Date(this.props.deliveryDate)).toShortDateString()) : ''}
                                </NavLink>
                            </Typography>
                        </div>
                        <div className="container-expansionPanel-title-count">
                            <Avatar className="notifAl-cp">
                                {this.props.trackingCount}
                            </Avatar>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ width: '100%' }}>
                        {this.props.tracking.map((elem, index) => {
                            return (
                                <div key={`dccs:${index}`}>
                                    {
                                        (elem.changeHistory && elem.changeHistory.length > 0) ?
                                            (elem.changeHistory.map(((el, index1) => {
                                                const objNavLinkElem = {
                                                    pathname: '/',
                                                    //Eviter de mettre 'state' car ils seront persistents sur la page même si la page sera actualisée
                                                    aboutProps: {
                                                        deliveryDate: new Date(this.props.deliveryDate),
                                                        businessId: elem.businessId,
                                                        needLoaded: true
                                                    }
                                                };
                                                if (el.rootChange && el.rootChange.entityChangeAction === EntityChangeAction.Modified) {
                                                    const nbElemsTracksAdded = this.getNbTrackingByEntityChangeAction(el.childChanges, OwnedEntity.RequestedTransportation, EntityChangeAction.Added);
                                                    const nbElemsTracksModified = this.getNbTrackingByEntityChangeAction(el.childChanges, OwnedEntity.RequestedTransportation, EntityChangeAction.Modified);
                                                    const nbElemsTracksDeleted = this.getNbTrackingByEntityChangeAction(el.childChanges, OwnedEntity.RequestedTransportation, EntityChangeAction.Deleted);
                                                    return (
                                                        <div key={`dccs:h:${index}:${index1}`}>
                                                            <div className="container-group-dc">
                                                                <div className="field-group-dc-detail">

                                                                    <div className="field-group-dc-title">
                                                                        <NavLink onClick={this.props.closeDrawer} to={objNavLinkElem} >
                                                                            {elem.businessId}
                                                                        </NavLink>
                                                                    </div>
                                                                    {
                                                                        el.childChanges && el.childChanges.length > 0 ? (
                                                                            <div>
                                                                                {el.childChanges.length} modification(s)
                                                                            </div>) : ''
                                                                    }
                                                                    {
                                                                        nbElemsTracksAdded > 0 ? (
                                                                            <div>
                                                                                {nbElemsTracksAdded} ajout(s) camion
                                                                            </div>) : ''
                                                                    }
                                                                    {
                                                                        nbElemsTracksModified > 0 ? (
                                                                            <div>
                                                                                {nbElemsTracksModified} modifications(s) camion
                                                                            </div>) : ''
                                                                    }
                                                                    {
                                                                        nbElemsTracksDeleted > 0 ? (
                                                                            <div>
                                                                                {nbElemsTracksDeleted} suppression(s) camion
                                                                            </div>) : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <Divider style={{ height: '2px' }} />
                                                        </div>
                                                    )
                                                }
                                                else if (el.rootChange && el.rootChange.entityChangeAction === EntityChangeAction.Added) {
                                                    return (
                                                        <div key={`dccs:h:${index}:${index1}`}>
                                                            <div className="container-group-dc">
                                                                <div className="field-group-dc-detail">
                                                                    <div className="field-group-dc-title">
                                                                        <NavLink onClick={this.props.closeDrawer} to={objNavLinkElem} >
                                                                            {elem.businessId}
                                                                        </NavLink>
                                                                    </div>
                                                                    <div>
                                                                        Création tardive
                                                            </div>
                                                                </div>
                                                            </div>
                                                            <Divider style={{ height: '2px' }} />
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div key={`dccs:h:${index}:${index1}`}>
                                                            <div className="container-group-dc">
                                                                <div className="field-group-dc-detail">
                                                                    <div className="field-group-dc-title">
                                                                        <NavLink onClick={this.props.closeDrawer} to={objNavLinkElem} >
                                                                            {elem.businessId}
                                                                        </NavLink>
                                                                    </div>
                                                                    <div>
                                                                        Rejet du Flux
                                                            </div>
                                                                </div>
                                                            </div>
                                                            <Divider style={{ height: '2px' }} />
                                                        </div>
                                                    )
                                                }
                                            })))

                                            : <></>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }
}