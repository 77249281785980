import { Box, Checkbox } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import Utilities from '../../../../utils/Utilities';
import { SizingUtilities } from '../../SizingUtilities';
import { DispatcherLightModelExtended } from '../models/DispatcherLightModelExtended';

interface DispatchersComponentProps {
    dispatchersList: DispatcherLightModelExtended[],
    handleSelectionChange: (checked: boolean, dataItem: DispatcherLightModelExtended) => void,
    handleDispatcherRowClick: (dataItem: DispatcherLightModelExtended) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const DispatchersReferentialGridName = "dispatchersReferential";
const ModuleKey = AppModule.DispatchersReferential;

export const DispatchersComponent = (props: DispatchersComponentProps): JSX.Element => {

    const initialSort: SortDescriptor[] = [{ field: "lastName", dir: "asc" }];

    const [skip, setSkip] = useState<number>(0);
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);

    const forceUpdate = useForceUpdate();

    const getDispatchersWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, DispatchersReferentialGridName, fieldName, columnWidth);
    }

    const getDispatchersGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, DispatchersReferentialGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            DispatchersReferentialGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, DispatchersReferentialGridName, event.columns);
        forceUpdate();
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, dataItem: DispatcherLightModelExtended): void => {
        props.handleSelectionChange(e.target.checked, dataItem);
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const rowClick = (e: GridRowClickEvent): void => {
        props.handleDispatcherRowClick(e.dataItem);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.dispatchersList.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: DispatcherLightModelExtended[] = orderBy(props.dispatchersList, sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="dispatchers-referential-grid">
            <Grid
                className="dispatchers-grid"
                data={gridData}
                selectedField="selected"
                sortable
                reorderable
                resizable
                sort={sort}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={handleSortChange}
                onRowClick={rowClick}
                rowHeight={rowHeight}
                scrollable="virtual"
                skip={skip}
                total={totalGrid}
                pageSize={gridPageSize}
                onPageChange={pageChange}
                style={gridStyle}
            >
                <GridColumn field="selected" orderIndex={getDispatchersGridOrderIndexColumn("selected", 0)} width={getDispatchersWidth("selected", 50)}
                    cell={(props: GridCellProps) => <td>
                        <Checkbox
                            checked={(props.dataItem as DispatcherLightModelExtended).selected}
                            onChange={(e) => handleChange(e, props.dataItem)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </td>}
                    headerCell={() => <></>}
                />
                <GridColumn field="lastName" orderIndex={getDispatchersGridOrderIndexColumn("lastName", 1)} width={getDispatchersWidth("lastName", 120)} title="Nom" />
                <GridColumn field="firstName" orderIndex={getDispatchersGridOrderIndexColumn("firstName", 2)} width={getDispatchersWidth("firstName", 120)} title="Prénom" />
                <GridColumn field="phoneNumber" orderIndex={getDispatchersGridOrderIndexColumn("phoneNumber", 3)} width={getDispatchersWidth("phoneNumber", 120)} title="Téléphone"
                    cell={(props: GridCellProps) => <td className="phone-number-ref">{props.dataItem?.phoneNumber ? <a href={`callTo:${Utilities.formatPhoneNumber(props.dataItem.phoneNumber)}`}>{Utilities.formatPhoneNumber(props.dataItem.phoneNumber)}</a> : ''}</td>}
                />
                <GridColumn field="userName" orderIndex={getDispatchersGridOrderIndexColumn("userName", 4)} width={getDispatchersWidth("userName", 120)} title="Id du contact" />
            </Grid>
        </Box>
    );
}
