export enum OwnedEntity {
    AddressInfo = "AddressInfo",
    DeliveryAddress = "DeliveryAddress",
    PickupAddress = "PickupAddress",
    ContactInfo = "ContactInfo",
    JobForeman = "JobForeman",
    RequestedBy = "RequestedBy",
    GpsCoordinates = "GpsCoordinates",
    DeliveryCoordinates = "DeliveryCoordinates",
    PickupCoordinates = "PickupCoordinates",
    TransportRequest = "TransportRequest",
    RequestedTransportation = "RequestedTransportation",
    ProductInfo = "ProductInfo",
    SiteInfo = "SiteInfo",
    SenderSite = "SenderSite",
    ReceiverSite = "ReceiverSite"
}