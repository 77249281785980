import { Box, Button } from '@mui/material';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import React, { useEffect, useRef, useState } from 'react';
import Select, { ActionMeta, components, OptionProps } from 'react-select';
import { LogisticsUnitSelectModel } from '../../../../shared/models/LogisticsUnitSelectModel';
import { DateConfigModel } from '../models/DateConfigModel';
import { NegotiatedSellPriceLightModelExtended } from '../models/NegotiatedSellPriceLightModelExtended';
import { PriceKind } from '../models/PriceKind';
import { PriceKindModel } from '../models/PriceKindModel';
import { ProductionSiteSelectModel } from '../models/ProductionSiteSelectModel';
import { ReactSelectModel } from '../models/ReactSelectModel';
import { SiteKind } from '../models/SiteKind';
import { SiteModel } from '../models/SiteModel';
import { AddNegotiatedSellPricesArgs } from '../services/dataContracts/controller/AddNegotiatedSellPricesArgs';
import { AgencyLightModel } from '../services/dataContracts/queryStack/AgencyLightModel';
import { Level2ActivityLightModel } from '../services/dataContracts/queryStack/Level2ActivityLightModel';
import { ProductionSiteLightModel } from '../services/dataContracts/queryStack/ProductionSiteLightModel';
import { ReferencedCustomerLightModel } from '../services/dataContracts/queryStack/ReferencedCustomerLightModel';
import { VehicleTypeLightModel } from '../services/dataContracts/queryStack/VehicleTypeLightModel';
import { NegotiatedSellPricesReferentialApiClient } from '../services/NegotiatedSellPricesReferentialApiClient';

interface AddNegotiatedSellPricesDialogProps {
    selectedLogisticsUnit: LogisticsUnitSelectModel,
    negotiatedSellPrices: NegotiatedSellPriceLightModelExtended[],
    addNegotiatedSellPrices: (requestArgs: AddNegotiatedSellPricesArgs) => void
}

export interface OptionPropsModelExtended extends OptionProps<ReactSelectModel> {
    isCheckAllSelected?: boolean
}

const OptionItem = (props: OptionPropsModelExtended): JSX.Element => {

    const isSelectAllItemChecked: boolean = props.data.value === "selectAllVehicles" && props.isCheckAllSelected;

    return (
        <div>
            <components.Option {...props} className={isSelectAllItemChecked ? "selected-item" : ''}>
                <input
                    type="checkbox"
                    checked={props.data.value === "selectAllVehicles" ? props.isCheckAllSelected : props.isSelected}
                    onChange={() => null}
                />{" "}
                <label className={isSelectAllItemChecked ? "selected-item-label" : ''}>{props.data.label}</label>
            </components.Option>
        </div>
    );
}


export const AddNegotiatedSellPricesDialog = (props: AddNegotiatedSellPricesDialogProps): JSX.Element => {

    const selectAllOption: ReactSelectModel = { label: "Sélectionner tout", value: "selectAllVehicles" };

    const siteKindList = [
        { label: "Site de production", value: SiteKind.productionSite },
        { label: "Chantier", value: SiteKind.jobSite },
        { label: "Sté externe référencée", value: SiteKind.customer }
    ];

    const [senderSite, setSenderSite] = useState<SiteModel>({
        siteKind: null,
        productionSiteList: [],
        selectedProductionSite: null,
        productionSiteActivities: [],
        selectedProductionSiteActivity: null,
        siteAgencyList: [],
        selectedSiteAgency: null,
        jobSiteIdmdm: null,
        referencedSiteCustomerList: [],
        selectedReferencedSiteCustomer: null
    });
    const [receiverSite, setReceiverSite] = useState<SiteModel>({
        siteKind: null,
        productionSiteList: [],
        selectedProductionSite: null,
        productionSiteActivities: [],
        selectedProductionSiteActivity: null,
        siteAgencyList: [],
        selectedSiteAgency: null,
        jobSiteIdmdm: null,
        referencedSiteCustomerList: [],
        selectedReferencedSiteCustomer: null
    });
    const [priceKind, setPriceKind] = useState<PriceKindModel>(null);
    const [price, setPrice] = useState<number>(null);
    const [vehicleTypes, setVehicleTypes] = useState<ReactSelectModel[]>([]);
    const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<ReactSelectModel[]>([]);
    const [dates, setDates] = useState<DateConfigModel>({
        start: null,
        end: null,
        minEnd: null
    });
    const [startDateValid, setStartDateValid] = useState<boolean>(false);
    const [endDateValid, setEndDateValid] = useState<boolean>(false);
    const [isCheckAllSelected, setIsCheckAllSelected] = useState<boolean>(false);

    const startDateRef = useRef<DatePicker>();
    const endDateRef = useRef<DatePicker>();

    useEffect(() => {
        getVehicleTypes();
    }, []);

    useEffect(() => {
        setStartDateValid(isValidDate(startDateRef));
        setEndDateValid(isValidDate(endDateRef));
    }, [dates.start, dates.end]);

    const isValidDate = (refDate: React.MutableRefObject<DatePicker>): boolean => {
        return refDate.current?.validity.valid;
    }

    const getVehicleTypes = (): void => {
        NegotiatedSellPricesReferentialApiClient.GetVehicleTypes()
            .then((res) => {
                const vehicleTypeList: ReactSelectModel[] = [];
                res.data.forEach((element: VehicleTypeLightModel) => vehicleTypeList.push({ label: element.label, value: element.vehicleTypeId }));
                setVehicleTypes(vehicleTypeList);
            });
    }

    const handleSenderSiteKindChanged = (e: ReactSelectModel): void => {
        const value = e?.value;
        switch (value) {
            case SiteKind.productionSite:

                NegotiatedSellPricesReferentialApiClient.GetProductionSites(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const productionSiteArray: ProductionSiteSelectModel[] = [];
                        res.data.forEach((element: ProductionSiteLightModel) => productionSiteArray.push({
                            label: element.label,
                            value: element.productionSiteId,
                            agencyId: element.agencyId,
                            disabled: receiverSite.siteKind?.value === SiteKind.productionSite && receiverSite.selectedProductionSite?.value === element.productionSiteId ? true : false
                        }));
                        setSenderSite({
                            ...senderSite,
                            siteKind: { label: e.label, value: e.value },
                            productionSiteList: productionSiteArray,
                            selectedProductionSite: null,
                            selectedProductionSiteActivity: null,
                            productionSiteActivities: [],
                            selectedSiteAgency: null,
                            jobSiteIdmdm: null,
                            referencedSiteCustomerList: [],
                            selectedReferencedSiteCustomer: null
                        });
                    });
                break;

            case SiteKind.jobSite:

                NegotiatedSellPricesReferentialApiClient.GetWorkAgencies(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const siteAgencyArray: ReactSelectModel[] = [];
                        res.data.forEach((element: AgencyLightModel) => siteAgencyArray.push({ label: element.label, value: element.agencyId }));
                        setSenderSite({
                            ...senderSite,
                            siteKind: { label: e.label, value: e.value },
                            siteAgencyList: siteAgencyArray,
                            selectedProductionSite: null,
                            selectedProductionSiteActivity: null,
                            productionSiteActivities: [],
                            selectedSiteAgency: null,
                            jobSiteIdmdm: null,
                            referencedSiteCustomerList: [],
                            selectedReferencedSiteCustomer: null
                        });
                    });
                break;

            case SiteKind.customer:

                NegotiatedSellPricesReferentialApiClient.GetReferencedCustomers(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const referencedSiteCustomerArray: ReactSelectModel[] = [];
                        res.data.forEach((element: ReferencedCustomerLightModel) => referencedSiteCustomerArray.push({
                            label: element.name,
                            value: element.customerMdmId,
                            disabled: receiverSite.siteKind?.value === SiteKind.customer && receiverSite.selectedReferencedSiteCustomer?.value === element.customerMdmId ? true : false
                        }));
                        setSenderSite({
                            ...senderSite,
                            siteKind: { label: e.label, value: e.value },
                            siteAgencyList: [],
                            selectedProductionSite: null,
                            selectedProductionSiteActivity: null,
                            productionSiteActivities: [],
                            selectedSiteAgency: null,
                            jobSiteIdmdm: null,
                            referencedSiteCustomerList: referencedSiteCustomerArray,
                            selectedReferencedSiteCustomer: null
                        });
                    })
                break;

            default:

                setSenderSite({
                    siteKind: null,
                    productionSiteList: [],
                    selectedProductionSite: null,
                    productionSiteActivities: [],
                    selectedProductionSiteActivity: null,
                    siteAgencyList: [],
                    selectedSiteAgency: null,
                    jobSiteIdmdm: null,
                    referencedSiteCustomerList: [],
                    selectedReferencedSiteCustomer: null
                });

        }
    }

    const handleReceiverSiteKindChanged = (e: ReactSelectModel): void => {
        const value = e?.value;
        switch (value) {
            case SiteKind.productionSite:

                NegotiatedSellPricesReferentialApiClient.GetProductionSites(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const productionSiteArray: ProductionSiteSelectModel[] = [];
                        res.data.forEach((element: ProductionSiteLightModel) => productionSiteArray.push({
                            label: element.label,
                            value: element.productionSiteId,
                            agencyId: element.agencyId,
                            disabled: senderSite?.siteKind?.value === SiteKind.productionSite && senderSite.selectedProductionSite?.value === element.productionSiteId ? true : false
                        }));
                        setReceiverSite({
                            ...receiverSite,
                            siteKind: { label: e.label, value: e.value },
                            productionSiteList: productionSiteArray,
                            selectedProductionSite: null,
                            selectedProductionSiteActivity: null,
                            productionSiteActivities: [],
                            selectedSiteAgency: null,
                            jobSiteIdmdm: null,
                            referencedSiteCustomerList: [],
                            selectedReferencedSiteCustomer: null
                        });
                    });
                break;

            case SiteKind.jobSite:

                NegotiatedSellPricesReferentialApiClient.GetWorkAgencies(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const siteAgencyArray: ReactSelectModel[] = [];
                        res.data.forEach((element: AgencyLightModel) => siteAgencyArray.push({ label: element.label, value: element.agencyId }));
                        setReceiverSite({
                            ...receiverSite,
                            siteKind: { label: e.label, value: e.value },
                            siteAgencyList: siteAgencyArray,
                            selectedProductionSite: null,
                            selectedProductionSiteActivity: null,
                            productionSiteActivities: [],
                            selectedSiteAgency: null,
                            jobSiteIdmdm: null,
                            referencedSiteCustomerList: [],
                            selectedReferencedSiteCustomer: null
                        });
                    });
                break;

            case SiteKind.customer:

                NegotiatedSellPricesReferentialApiClient.GetReferencedCustomers(props.selectedLogisticsUnit?.value)
                    .then((res) => {
                        const referencedSiteCustomerArray: ReactSelectModel[] = [];
                        res.data.forEach((element: ReferencedCustomerLightModel) => referencedSiteCustomerArray.push({
                            label: element.name,
                            value: element.customerMdmId,
                            disabled: senderSite.siteKind?.value === SiteKind.customer && senderSite.selectedReferencedSiteCustomer?.value === element.customerMdmId ? true : false
                        }));
                        setReceiverSite({
                            ...senderSite,
                            siteKind: { label: e.label, value: e.value },
                            siteAgencyList: [],
                            selectedProductionSite: null,
                            selectedProductionSiteActivity: null,
                            productionSiteActivities: [],
                            selectedSiteAgency: null,
                            jobSiteIdmdm: null,
                            referencedSiteCustomerList: referencedSiteCustomerArray,
                            selectedReferencedSiteCustomer: null
                        });
                    })
                break;

            default:

                setReceiverSite({
                    siteKind: null,
                    productionSiteList: [],
                    selectedProductionSite: null,
                    productionSiteActivities: [],
                    selectedProductionSiteActivity: null,
                    siteAgencyList: [],
                    selectedSiteAgency: null,
                    jobSiteIdmdm: null,
                    referencedSiteCustomerList: [],
                    selectedReferencedSiteCustomer: null
                });

        }
    }

    const handleSenderProductionSiteChanged = (e: ProductionSiteSelectModel): void => {
        if (e) {
            NegotiatedSellPricesReferentialApiClient.GetLevel2Activities(e.value as string, props.selectedLogisticsUnit?.value)
                .then((res) => {
                    const productionSiteActivityList: ReactSelectModel[] = [];
                    res.data.forEach((element: Level2ActivityLightModel) => productionSiteActivityList.push({ label: element.label, value: element.id }));
                    setSenderSite({
                        ...senderSite,
                        selectedProductionSite: { label: e.label, value: e.value, agencyId: e.agencyId },
                        productionSiteActivities: productionSiteActivityList,
                        selectedProductionSiteActivity: productionSiteActivityList.length == 1 ? { label: productionSiteActivityList[0].label, value: productionSiteActivityList[0].value } : null
                    });
                    if (receiverSite.siteKind?.value === SiteKind.productionSite)
                        receiverSite.productionSiteList.forEach((element) => {
                            if (element.value === e.value)
                                element.disabled = true;
                            else
                                element.disabled = false;
                        });
                });
        }
        else {
            setSenderSite({
                ...senderSite,
                selectedProductionSite: null,
                productionSiteActivities: [],
                selectedProductionSiteActivity: null
            });
        }
    }

    const handleReceiverProductionSiteChanged = (e: ProductionSiteSelectModel): void => {
        if (e) {
            NegotiatedSellPricesReferentialApiClient.GetLevel2Activities(e.value as string, props.selectedLogisticsUnit?.value)
                .then((res) => {
                    const productionSiteActivityList: ReactSelectModel[] = [];
                    res.data.forEach((element: Level2ActivityLightModel) => productionSiteActivityList.push({ label: element.label, value: element.id }));
                    setReceiverSite({
                        ...receiverSite,
                        selectedProductionSite: { label: e.label, value: e.value, agencyId: e.agencyId },
                        productionSiteActivities: productionSiteActivityList,
                        selectedProductionSiteActivity: productionSiteActivityList.length == 1 ? { label: productionSiteActivityList[0].label, value: productionSiteActivityList[0].value } : null
                    });
                    if (senderSite.siteKind?.value === SiteKind.productionSite)
                        senderSite.productionSiteList.forEach((element) => {
                            if (element.value === e.value)
                                element.disabled = true;
                            else
                                element.disabled = false;
                        });
                });
        }
        else {
            setReceiverSite({
                ...receiverSite,
                selectedProductionSite: null,
                productionSiteActivities: [],
                selectedProductionSiteActivity: null
            });
        }
    }

    const handleSenderSiteAgencyChanged = (e: ReactSelectModel): void => {
        setSenderSite({ ...senderSite, selectedSiteAgency: e ? { label: e.label, value: e.value } : null });
    }

    const handleReceiverSiteAgencyChanged = (e: ReactSelectModel): void => {
        setReceiverSite({ ...receiverSite, selectedSiteAgency: e ? { label: e.label, value: e.value } : null });
    }

    const handleSenderJobSiteIdmdmChanged = (value: string): void => {
        setSenderSite({ ...senderSite, jobSiteIdmdm: value });
    }

    const handleReceiverJobSiteIdmdmChanged = (value: string): void => {
        setReceiverSite({ ...receiverSite, jobSiteIdmdm: value });
    }

    const handleSenderProductionSiteActivityChanged = (e: ReactSelectModel): void => {
        setSenderSite({ ...senderSite, selectedProductionSiteActivity: e ? { label: e.label, value: e.value } : null });
    }

    const handleReceiverProductionSiteActivityChanged = (e: ReactSelectModel): void => {
        setReceiverSite({ ...receiverSite, selectedProductionSiteActivity: e ? { label: e.label, value: e.value } : null });
    }

    const handleSenderReferencedSiteCustomerChanged = (e: ReactSelectModel): void => {
        setSenderSite({ ...senderSite, selectedReferencedSiteCustomer: e ? { label: e.label, value: e.value } : null });
        if (receiverSite.siteKind?.value === SiteKind.customer)
            receiverSite.referencedSiteCustomerList.forEach((element) => {
                if (e && element.value === e.value)
                    element.disabled = true;
                else
                    element.disabled = false;
            });
    }

    const handleReceiverReferencedSiteCustomerChanged = (e: ReactSelectModel): void => {
        setReceiverSite({ ...receiverSite, selectedReferencedSiteCustomer: e ? { label: e.label, value: e.value } : null });
        if (senderSite.siteKind?.value === SiteKind.customer)
            senderSite.referencedSiteCustomerList.forEach((element) => {
                if (e && element.value === e.value)
                    element.disabled = true;
                else
                    element.disabled = false;
            });
    }

    const handleChangeTransportersSelected = (opts: Array<ReactSelectModel>, event: ActionMeta<ReactSelectModel>): void => {
        if (event.action === "select-option" && event.option.value === "selectAllVehicles" && isCheckAllSelected)
        {
            setIsCheckAllSelected(false);
            setSelectedVehicleTypes([]);
            return;
        }

        switch (event.action) {
            case "select-option":
                setOptions(event.option.value as string, opts);
                break;
            case "deselect-option":
                removeOptions(event.option.value as string, opts);
                break;
            case "remove-value":
                removeOptions(event.removedValue.value as string, opts);
                break;
            case "clear":
                if (isCheckAllSelected)
                    setIsCheckAllSelected(false);

                setSelectedVehicleTypes([]);
                break;
            default:
                return;
        }
    }

    const setOptions = (value: string, opts: Array<ReactSelectModel>): void => {
        if (value === "selectAllVehicles") {
            setSelectedVehicleTypes(vehicleTypes);
            setIsCheckAllSelected(true);
        } else {
            if (opts.length === vehicleTypes.length)
                setIsCheckAllSelected(true);

            setSelectedVehicleTypes(opts);
        }
    }

    const removeOptions = (value: string, opts: Array<ReactSelectModel>): void => {
        if (value === "selectAllVehicles") {
            setSelectedVehicleTypes([]);
            setIsCheckAllSelected(false);
        } else {
            if (isCheckAllSelected)
                setIsCheckAllSelected(false);

            const options: Array<ReactSelectModel> = opts.filter(x => x.value !== value);
            setSelectedVehicleTypes(options);
        }
    }

    const handleStartDateChange = (e: DatePickerChangeEvent): void => {
        const selectedDate: Date = e.target.value;
        if (e.target.state.show) {
            (startDateRef.current as any).shouldFocusDateInput = false;
        }

        setDates({ ...dates, start: selectedDate, minEnd: selectedDate });
    }

    const handleEndDateChange = (e: DatePickerChangeEvent): void => {
        const selectedDate: Date = e.target.value;
        if (e.target.state.show) {
            (endDateRef.current as any).shouldFocusDateInput = false;
        }
        setDates({ ...dates, end: selectedDate });
    }

    const handleClickValidateSellPrices = (): void => {

        const vehicleTypeIds = selectedVehicleTypes.map(x => x.value as string);

        const requestArgs: AddNegotiatedSellPricesArgs = {
            logisticsUnitId: props.selectedLogisticsUnit?.value,
            senderSiteProductionSiteId: senderSite.selectedProductionSite ? senderSite.selectedProductionSite.value as string : null,
            senderSiteAgencyId: senderSite.selectedProductionSite ? senderSite.selectedProductionSite.agencyId as string :
                (senderSite.selectedSiteAgency ? senderSite.selectedSiteAgency.value as string : null),
            senderSiteJobSiteMdmId: senderSite.jobSiteIdmdm?.trim() ? senderSite.jobSiteIdmdm?.trim() : null,
            senderSiteCustomerMdmId: senderSite.selectedReferencedSiteCustomer ? senderSite.selectedReferencedSiteCustomer.value as string : null,
            senderLevel2ActivityId: senderSite.selectedProductionSiteActivity ? senderSite.selectedProductionSiteActivity.value as string : null,
            receiverSiteProductionSiteId: receiverSite.selectedProductionSite ? receiverSite.selectedProductionSite.value as string : null,
            receiverSiteAgencyId: receiverSite.selectedProductionSite ? receiverSite.selectedProductionSite.agencyId as string :
                (receiverSite.selectedSiteAgency ? receiverSite.selectedSiteAgency.value as string : null),
            receiverSiteJobSiteMdmId: receiverSite.jobSiteIdmdm?.trim() ? receiverSite.jobSiteIdmdm?.trim() : null,
            receiverSiteCustomerMdmId: receiverSite.selectedReferencedSiteCustomer ? receiverSite.selectedReferencedSiteCustomer.value as string : null,
            receiverLevel2ActivityId: receiverSite.selectedProductionSiteActivity ? receiverSite.selectedProductionSiteActivity.value as string : null,
            vehicleTypeIds: vehicleTypeIds,
            startDate: dates.start,
            endDate: dates.end,
            priceKind: priceKind.value,
            price: price
        }

        props.addNegotiatedSellPrices(requestArgs);
    }

    const checkSite = (site: SiteModel): boolean => {
        if (site.siteKind?.value === SiteKind.jobSite) {
            if (site.selectedSiteAgency && site.jobSiteIdmdm?.trim()) {
                return true;
            } else {
                return false;
            }
        }
        if (site.siteKind?.value === SiteKind.productionSite) {
            if (site.selectedProductionSite && site.selectedProductionSiteActivity) {
                return true;
            } else {
                return false;
            }
        }
        if (site.siteKind?.value === SiteKind.customer) {
            if (site.selectedReferencedSiteCustomer) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    const selectedVehicleTypeIds = selectedVehicleTypes.map(x => x.value as string);

    const validForm: boolean =
        startDateValid &&
        endDateValid &&
        props.selectedLogisticsUnit &&
        selectedVehicleTypeIds.length > 0 &&
        senderSite.siteKind &&
        checkSite(senderSite) &&
        receiverSite.siteKind &&
        checkSite(receiverSite) &&
        price > 0 &&
        priceKind !== null;

    const existsPricesOverlappingDates: boolean = props.negotiatedSellPrices.some(
        x => x.logisticsUnitId === props.selectedLogisticsUnit?.value &&
            selectedVehicleTypeIds.includes(x.vehicleTypeId) &&
            x.priceKind === priceKind?.value &&
            x.senderSiteProductionSiteId === (senderSite.selectedProductionSite ? senderSite.selectedProductionSite.value : null) &&
            x.senderSiteLevel2ActivityId === (senderSite.selectedProductionSiteActivity ? senderSite.selectedProductionSiteActivity.value : null) &&
            x.senderSiteAgencyId === (senderSite.selectedProductionSite ? senderSite.selectedProductionSite.agencyId :
                (senderSite.selectedSiteAgency ? senderSite.selectedSiteAgency.value : null)) &&
            x.senderSiteJobSiteMdmId === senderSite.jobSiteIdmdm &&
            x.senderSiteCustomerMdmId === (senderSite.selectedReferencedSiteCustomer ? senderSite.selectedReferencedSiteCustomer.value : null) &&
            x.receiverSiteProductionSiteId === (receiverSite.selectedProductionSite ? receiverSite.selectedProductionSite.value : null) &&
            x.receiverSiteLevel2ActivityId === (receiverSite.selectedProductionSiteActivity ? receiverSite.selectedProductionSiteActivity.value : null) &&
            x.receiverSiteAgencyId === (receiverSite.selectedProductionSite ? receiverSite.selectedProductionSite.agencyId :
                (receiverSite.selectedSiteAgency ? receiverSite.selectedSiteAgency.value : null)) &&
            x.receiverSiteJobSiteMdmId === receiverSite.jobSiteIdmdm &&
            x.receiverSiteCustomerMdmId === (receiverSite.selectedReferencedSiteCustomer ? receiverSite.selectedReferencedSiteCustomer.value : null) &&
            new Date(x.startDate) <= dates.end && new Date(x.endDate) >= dates.start
    );

    const isSenderJobSiteAndReceiverJobSiteAreEquals: boolean = senderSite.siteKind?.value === SiteKind.jobSite &&
        receiverSite.siteKind?.value === SiteKind.jobSite &&
        senderSite.selectedSiteAgency?.value === receiverSite.selectedSiteAgency?.value &&
        senderSite.jobSiteIdmdm?.trim() === receiverSite.jobSiteIdmdm?.trim();

    return (
        <Box display="flex" flexDirection="column" className="add-negotiated-sell-prices-dialog">
            <Box className="content">
                {existsPricesOverlappingDates &&
                    <Box display="flex" flexDirection="row" mb="10px" className="prices-overlapping-dates-error">
                        La plage de date saisie se chevauche avec un autre tarif négocié pour la même livraison.
                    </Box>
                }
                <fieldset className="site-fieldset">
                    <legend>Site départ</legend>
                    <Box display="flex" flexDirection="row">
                        <Box display="flex" flexDirection="column">
                            <Box className={!senderSite.siteKind ? "label required" : "label"}>Type de lieu de départ</Box>
                            <Box display="flex" flexDirection="row">
                                <Select
                                    className={!senderSite.siteKind ? "input-select required" : "input-select"}
                                    isClearable
                                    placeholder=""
                                    options={siteKindList}
                                    value={senderSite.siteKind}
                                    onChange={(e) => handleSenderSiteKindChanged(e)}
                                    menuPosition="fixed"
                                    menuShouldBlockScroll={true}
                                />
                            </Box>
                        </Box>
                        {senderSite.siteKind?.value === SiteKind.productionSite &&
                            <>
                                <Box display="flex" flexDirection="column" ml="15px">
                                    <Box className={!senderSite.selectedProductionSite ? "label required" : "label"}>Site de production</Box>
                                    <Box display="flex" flexDirection="row">
                                        <Select
                                            className={!senderSite.selectedProductionSite ? "input-select required" : "input-select"}
                                            isClearable
                                            placeholder=""
                                            options={senderSite.productionSiteList}
                                            isOptionDisabled={(option) => option.disabled === true}
                                            value={senderSite.selectedProductionSite}
                                            onChange={(e) => handleSenderProductionSiteChanged(e)}
                                            menuPosition="fixed"
                                            menuShouldBlockScroll={true}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="column" ml="15px">
                                    <Box className={!senderSite.selectedProductionSiteActivity ? "label required" : "label"}>Activité</Box>
                                    <Box display="flex" flexDirection="row">
                                        <Select
                                            className={!senderSite.selectedProductionSiteActivity ? "input-select required" : "input-select"}
                                            isClearable
                                            placeholder=""
                                            options={senderSite.productionSiteActivities}
                                            value={senderSite.selectedProductionSiteActivity}
                                            onChange={(e) => handleSenderProductionSiteActivityChanged(e)}
                                            menuPosition="fixed"
                                            menuShouldBlockScroll={true}
                                        />
                                    </Box>
                                </Box>
                            </>
                        }
                        {senderSite.siteKind?.value === SiteKind.jobSite &&
                            <>
                                <Box display="flex" flexDirection="column" ml="15px">
                                    <Box className={!senderSite.selectedSiteAgency || isSenderJobSiteAndReceiverJobSiteAreEquals ? "label required" : "label"}>Agence</Box>
                                    <Box display="flex" flexDirection="row">
                                        <Select
                                            className={!senderSite.selectedSiteAgency || isSenderJobSiteAndReceiverJobSiteAreEquals ? "input-select required" : "input-select"}
                                            isClearable
                                            placeholder=""
                                            options={senderSite.siteAgencyList}
                                            isOptionDisabled={(option) => option.disabled === true}
                                            value={senderSite.selectedSiteAgency}
                                            onChange={(e) => handleSenderSiteAgencyChanged(e)}
                                            menuPosition="fixed"
                                            menuShouldBlockScroll={true}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="column" ml="15px">
                                    <Box className={senderSite.jobSiteIdmdm?.trim() && !isSenderJobSiteAndReceiverJobSiteAreEquals ? "label" : "label required"}>IDMDM chantier</Box>
                                    <Box display="flex" flexDirection="row">
                                        <input type="text"
                                            className={senderSite.jobSiteIdmdm?.trim() && !isSenderJobSiteAndReceiverJobSiteAreEquals ? "input-text" : "input-text required"}
                                            value={senderSite.jobSiteIdmdm === null ? "" : senderSite.jobSiteIdmdm}
                                            onChange={(e) => handleSenderJobSiteIdmdmChanged(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </>
                        }
                        {senderSite.siteKind?.value === SiteKind.customer &&
                            <>
                                <Box display="flex" flexDirection="column" ml="15px">
                                    <Box className={!senderSite.selectedReferencedSiteCustomer ? "label required" : "label"}>Nom du site de départ</Box>
                                    <Box display="flex" flexDirection="row">
                                        <Select
                                            className={!senderSite.selectedReferencedSiteCustomer ? "input-select required" : "input-select"}
                                            isClearable
                                            placeholder=""
                                            options={senderSite.referencedSiteCustomerList}
                                            isOptionDisabled={(option) => option.disabled === true}
                                            value={senderSite.selectedReferencedSiteCustomer}
                                            onChange={(e) => handleSenderReferencedSiteCustomerChanged(e)}
                                            menuPosition="fixed"
                                            menuShouldBlockScroll={true}
                                        />
                                    </Box>
                                </Box>
                            </>
                        }
                    </Box>
                </fieldset>
                <fieldset className="site-fieldset">
                    <legend>Site arrivée</legend>
                    <Box display="flex" flexDirection="row">
                        <Box display="flex" flexDirection="column">
                            <Box className={!receiverSite.siteKind ? "label required" : "label"}>Type de lieu d'arrivée</Box>
                            <Box display="flex" flexDirection="row">
                                <Select
                                    className={!receiverSite.siteKind ? "input-select required" : "input-select"}
                                    isClearable
                                    placeholder=""
                                    options={siteKindList}
                                    value={receiverSite.siteKind}
                                    onChange={(e) => handleReceiverSiteKindChanged(e)}
                                    menuPosition="fixed"
                                    menuShouldBlockScroll={true}
                                />
                            </Box>
                        </Box>
                        {receiverSite.siteKind?.value === SiteKind.productionSite &&
                            <>
                                <Box display="flex" flexDirection="column" ml="15px">
                                    <Box className={!receiverSite.selectedProductionSite ? "label required" : "label"}>Site de production</Box>
                                    <Box display="flex" flexDirection="row">
                                        <Select
                                            className={!receiverSite.selectedProductionSite ? "input-select required" : "input-select"}
                                            isClearable
                                            placeholder=""
                                            options={receiverSite.productionSiteList}
                                            isOptionDisabled={(option) => option.disabled === true}
                                            value={receiverSite.selectedProductionSite}
                                            onChange={(e) => handleReceiverProductionSiteChanged(e)}
                                            menuPosition="fixed"
                                            menuShouldBlockScroll={true}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="column" ml="15px">
                                    <Box className={!receiverSite.selectedProductionSiteActivity ? "label required" : "label"}>Activité</Box>
                                    <Box display="flex" flexDirection="row">
                                        <Select
                                            className={!receiverSite.selectedProductionSiteActivity ? "input-select required" : "input-select"}
                                            isClearable
                                            placeholder=""
                                            options={receiverSite.productionSiteActivities}
                                            value={receiverSite.selectedProductionSiteActivity}
                                            onChange={(e) => handleReceiverProductionSiteActivityChanged(e)}
                                            menuPosition="fixed"
                                            menuShouldBlockScroll={true}
                                        />
                                    </Box>
                                </Box>
                            </>
                        }
                        {receiverSite.siteKind?.value === SiteKind.jobSite &&
                            <>
                                <Box display="flex" flexDirection="column" ml="15px">
                                    <Box className={!receiverSite.selectedSiteAgency || isSenderJobSiteAndReceiverJobSiteAreEquals ? "label required" : "label"}>Agence</Box>
                                    <Box display="flex" flexDirection="row">
                                        <Select
                                            className={!receiverSite.selectedSiteAgency || isSenderJobSiteAndReceiverJobSiteAreEquals ? "input-select required" : "input-select"}
                                            isClearable
                                            placeholder=""
                                            options={receiverSite.siteAgencyList}
                                            isOptionDisabled={(option) => option.disabled === true}
                                            value={receiverSite.selectedSiteAgency}
                                            onChange={(e) => handleReceiverSiteAgencyChanged(e)}
                                            menuPosition="fixed"
                                            menuShouldBlockScroll={true}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="column" ml="15px">
                                    <Box className={receiverSite.jobSiteIdmdm?.trim() && !isSenderJobSiteAndReceiverJobSiteAreEquals ? "label" : "label required"}>IDMDM chantier</Box>
                                    <Box display="flex" flexDirection="row">
                                        <input type="text"
                                            className={receiverSite.jobSiteIdmdm?.trim() && !isSenderJobSiteAndReceiverJobSiteAreEquals ? "input-text" : "input-text required"}
                                            value={receiverSite.jobSiteIdmdm === null ? "" : receiverSite.jobSiteIdmdm}
                                            onChange={(e) => handleReceiverJobSiteIdmdmChanged(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </>
                        }
                        {receiverSite.siteKind?.value === SiteKind.customer &&
                            <>
                                <Box display="flex" flexDirection="column" ml="15px">
                                    <Box className={!receiverSite.selectedReferencedSiteCustomer ? "label required" : "label"}>Nom du site d'arrivée</Box>
                                    <Box display="flex" flexDirection="row">
                                        <Select
                                            className={!receiverSite.selectedReferencedSiteCustomer ? "input-select required" : "input-select"}
                                            isClearable
                                            placeholder=""
                                            options={receiverSite.referencedSiteCustomerList}
                                            isOptionDisabled={(option) => option.disabled === true}
                                            value={receiverSite.selectedReferencedSiteCustomer}
                                            onChange={(e) => handleReceiverReferencedSiteCustomerChanged(e)}
                                            menuPosition="fixed"
                                            menuShouldBlockScroll={true}
                                        />
                                    </Box>
                                </Box>
                            </>
                        }
                    </Box>
                </fieldset>
                <Box display="flex" flexDirection="row" mb="10px">
                    <Box display="flex" flexDirection="column">
                        <Box className={priceKind === null ? "label required" : "label"}>Type de tarif</Box>
                        <Box display="flex" flexDirection="row">
                            <Select
                                className={priceKind === null ? "input-select required" : "input-select"}
                                options={[
                                    { label: "Tour", value: PriceKind.perTurn },
                                    { label: "Tonne", value: PriceKind.perTon }
                                ]}
                                isClearable
                                placeholder=""
                                value={priceKind}
                                onChange={(e) => { e !== null ? setPriceKind({ label: e.label, value: e.value }) : setPriceKind(null) }}
                                menuPosition="fixed"
                                menuShouldBlockScroll={true}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" ml="15px">
                        <Box className={price ? "label" : "label required"}>Coût</Box>
                        <Box display="flex" flexDirection="row">
                            <NumericTextBox className={price ? "numeric-input" : "numeric-input required"}
                                min={0}
                                format={{
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'EUR',
                                    currencyDisplay: 'symbol'
                                }}
                                spinners={false}
                                onChange={(e) => setPrice(e.target.value < 0 ? 0 : e.target.value)}
                                value={price}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" mb="10px">
                    <Box display="flex" flexDirection="column">
                        <Box className={dates.start && startDateValid ? "label" : "label required"}>Date de début</Box>
                        <Box display="flex" flexDirection="row">
                            <DatePicker onChange={(e) => handleStartDateChange(e)} value={dates.start}
                                required ref={startDateRef} />
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" ml="15px">
                        <Box className={dates.end && endDateValid ? "label" : "label required"}>Date de fin</Box>
                        <Box display="flex" flexDirection="row">
                            <DatePicker onChange={(e) => handleEndDateChange(e)} min={dates.minEnd} value={dates.end}
                                required ref={endDateRef} />
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" pb="10px">
                    <Box display="flex" flexDirection="column">
                        <Box className={selectedVehicleTypes.length > 0 ? "label" : "label required"}>Type de véhicule</Box>
                        <Box display="flex" flexDirection="row">
                            <Select
                                className={`input-select vehicle-types ${selectedVehicleTypes.length === 0 ? 'required' : ''}`}
                                placeholder="Type de véhicule"
                                options={[selectAllOption, ...vehicleTypes]}
                                value={selectedVehicleTypes}
                                onChange={(opts: ReactSelectModel[], event) => handleChangeTransportersSelected(opts, event)}
                                menuPosition="fixed"
                                menuShouldBlockScroll={true}
                                isMulti
                                components={{
                                    Option: (props) => <OptionItem {...props} isCheckAllSelected={isCheckAllSelected} />
                                }}
                                hideSelectedOptions={false}
                                blurInputOnSelect={false}
                                closeMenuOnSelect={false}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Button variant="contained" color="primary" title="Valider" onClick={handleClickValidateSellPrices} disabled={!validForm || existsPricesOverlappingDates || isSenderJobSiteAndReceiverJobSiteAreEquals}>
                    Valider
                </Button>
            </Box>
        </Box>
    );

}
