import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Tooltip } from '@mui/material';
import { GridCellProps, GridRowClickEvent } from '@progress/kendo-react-grid';
import { debounce, orderBy } from 'lodash';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ScaleLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.min.css';
import ToastService from 'src/ToastService';
import { PlanningVehicleStatusComponent } from '../../shared/components/PlanningVehicleStatus/PlanningVehicleStatusComponent';
import { DateRange } from '../../shared/models/DateRange';
import { LogisticsUnitChoice } from '../../shared/models/LogisticsUnitChoice';
import BusinessErrors from '../../utils/BusinessErrors';
import '../../utils/Date';
import { SessionStorage } from '../../utils/Storage';
import Utilities from '../../utils/Utilities';
import { ContentHeaderComponent } from './components/ContentHeaderComponent';
import { TransporterListComponent } from './components/TransporterListComponent';
import { TransportPurchasesComponent } from './components/TransportPurchasesComponent';
import { TransportRequestsDetailsComponent } from './components/TransportRequestsDetailsComponent';
import { AvailablePurchasePriceLightModelExtended } from './models/AvailablePurchasePriceLightModelExtended';
import { PlanningVehicleIdLogisticsUnitChoiceModel } from './models/PlanningVehicleIdLogisticsUnitChoiceModel';
import { PlanningVehicleLightModelExtended } from './models/PlanningVehicleLightModelExtended';
import { TransporterOrderRequestArgsExtended } from './models/TransporterOrderRequestArgsExtended';
import { VehiclePriceKind } from './models/VehiclePriceKind';
import { PlanningVehicleChangeRequestArgs } from './services/dataContracts/controller/PlanningVehicleChangeRequestArgs';
import { PlanningVehiclePublishedCostsRequestArgs } from './services/dataContracts/controller/PlanningVehiclePublishedCostsRequestArgs';
import { TransporterOrderLineRequestArgs } from './services/dataContracts/controller/TransporterOrderLineRequestArgs';
import { AvailablePurchasePriceLightModel } from './services/dataContracts/queryStack/AvailablePurchasePriceLightModel';
import { DriverLightModel } from './services/dataContracts/queryStack/DriverLightModel';
import { PlanningVehicleDetailsLightModel } from './services/dataContracts/queryStack/PlanningVehicleDetailsLightModel';
import { PlanningVehicleVisualisationLightModel } from './services/dataContracts/queryStack/PlanningVehicleVisualisationLightModel';
import { TransporterLightModel } from './services/dataContracts/queryStack/TransporterLightModel';
import { TransportFlowsDetailsLightModel } from './services/dataContracts/queryStack/TransportFlowsDetailsLightModel';
import { TransportPurchasesApiClient } from './services/TransportPurchasesApiClient';
import './TransportPurchasesStyles.scss';

interface TransportPurchasesProps {
    logisticsUnitIds: Array<string>,
    logisticsUnits: Array<LogisticsUnitChoice>
}

interface TransportPurchasesState {
    transporters: Array<TransporterLightModel>,
    lastTimeStampSearchTransporters: number,
    lastTimeStampGetVehicleTypeCount: number,
    lastTimeStampSearchVehiclesTrip: number,
    selectedTransporters: Array<string>,
    orders: Array<TransporterOrderRequestArgsExtended>,
    isHeaderBtnsEnabled: boolean,
    selectedProductionForeman: string,
    updateTotalFeesValues: boolean,
    driversList: Array<DriverLightModel>,
    availablePurchasePricesList: Array<AvailablePurchasePriceLightModel>,
    date: DateRange,
    loading: boolean,
    searchText: string,
    isTransporterDrawerOpened: boolean,
    isDcDetailsDrawerOpened: boolean,
    editField: string,
    showIsLessThenToDay: boolean,
    show: boolean,
    planningVehiclesList: Array<PlanningVehicleLightModelExtended>,
    planningVehicleDetails: Array<PlanningVehicleDetailsLightModel>,
    visualisationData: Array<PlanningVehicleVisualisationLightModel>,
    planningVehiclesLogisticsUnits: Array<PlanningVehicleIdLogisticsUnitChoiceModel>,
    planningVehiclesPublishedCosts: Array<PlanningVehiclePublishedCostsRequestArgs>,
    selectedAllPlanningVehicles: boolean,
    showOnlyWithCostsInDisagreement: boolean,
    showOnlyCanceledVehicles: boolean
}

const flowStatusLabels: Map<string, string> = new Map<string, string>([
    ['Pending', 'En attente'],
    ['Initialized', 'Initial'],
    ['Optimized', 'Optimisé'],
    ['InProgress', 'En cours'],
    ['Planned', 'Planifié'],
    ['Confirmed', 'Confirmé'],
    ['Finished', 'Terminé'],
    ['Canceled', 'Annulé']
]);

export class TransportPurchasesView extends React.Component<TransportPurchasesProps, TransportPurchasesState> {
    today = new Date();
    inputSearchVehiclesRef: React.RefObject<HTMLInputElement>;
    lastSelectedIndex = 0;
    preventExit: boolean;
    preventExitTimeout: ReturnType<typeof setTimeout>;
    blurTimeout: ReturnType<typeof setTimeout>;
    visuChef: boolean;
    enableEdit: boolean;
    agencies = new Array<string>();

    expectedPlanningVehicleIdForDetailsRefresh: string = null;

    constructor(props: TransportPurchasesProps) {
        super(props);

        this.inputSearchVehiclesRef = React.createRef();

        this.state = {
            transporters: [],
            lastTimeStampSearchTransporters: Date.now(),
            lastTimeStampGetVehicleTypeCount: Date.now(),
            lastTimeStampSearchVehiclesTrip: Date.now(),
            selectedTransporters: new Array<string>(),
            orders: new Array<TransporterOrderRequestArgsExtended>(),
            isHeaderBtnsEnabled: false,
            selectedProductionForeman: "",
            updateTotalFeesValues: false,
            driversList: [],
            availablePurchasePricesList: [],
            date: { start: SessionStorage.ActiveStartDate, end: SessionStorage.ActiveEndDate },
            loading: true,
            searchText: "",
            isTransporterDrawerOpened: false,
            isDcDetailsDrawerOpened: false,
            editField: null,
            showIsLessThenToDay: false,
            show: false,
            planningVehiclesList: [],
            planningVehicleDetails: [],
            visualisationData: [],
            planningVehiclesLogisticsUnits: [],
            planningVehiclesPublishedCosts: [],
            selectedAllPlanningVehicles: false,
            showOnlyWithCostsInDisagreement: false,
            showOnlyCanceledVehicles: false
        };
    }

    componentDidMount() {
        this.searchPlanningVehiclesTripWithTransporters(this.state.date, "", this.props.logisticsUnitIds);
    }

    searchPlanningVehiclesTripWithTransporters = (date: DateRange, searchText: string, logisticsUnitIds: Array<string>): void => {
        const fromTime = date.start;
        const untilTime = date.end;
        const _lastTimeStamp = this.state.lastTimeStampSearchTransporters;
        const _currentTimeStamp = Date.now();

        this.setState({
            loading: true
        });

        TransportPurchasesApiClient.SearchPlanningVehiclesWithTransporters(fromTime, untilTime, searchText, this.state.selectedTransporters, logisticsUnitIds)
            .then(res => {
                if (_lastTimeStamp != this.state.lastTimeStampSearchTransporters)
                    return;

                if (res.length > 0) {
                    const transporters: Array<TransporterLightModel> = res[0].data.pageItems;
                    const planningVehicles: Array<PlanningVehicleLightModelExtended> = res[1].data;

                    planningVehicles.forEach((Vehicle: PlanningVehicleLightModelExtended) => {
                        this.translatePlanningVehicles(Vehicle);
                    });

                    this.setState({
                        loading: false,
                        isDcDetailsDrawerOpened: false,
                        transporters: transporters,
                        orders: [],
                        planningVehiclesList: planningVehicles.map((dataItem: PlanningVehicleLightModelExtended) => Object.assign({
                            selected: false,
                            statusOrderValue: PlanningVehicleStatusComponent.getOrderValue(
                                dataItem.isCanceled,
                                dataItem.cancellationOrderSentDate,
                                dataItem.cancellationOrderConfirmationDate,
                                dataItem.orderConfirmationDate,
                                dataItem.transporterOrderId,
                                dataItem.isInternal
                            )
                        }, dataItem)),
                        selectedAllPlanningVehicles: false,
                        lastTimeStampSearchVehiclesTrip: _currentTimeStamp,
                        updateTotalFeesValues: true,
                        isHeaderBtnsEnabled: false
                    });
                }
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            });
    }

    handleOpenCloseTransporterDrawer = (): void => {
        this.setState((prevState, props) => ({
            isTransporterDrawerOpened: !prevState.isTransporterDrawerOpened
        }));
    }

    toggleCheckbox = debounce((transporter: TransporterLightModel): void => {
        const selectedTransportersArray = this.state.selectedTransporters;
        if (selectedTransportersArray.indexOf(transporter.transporterId == null ? null : transporter.transporterId) != -1) {
            selectedTransportersArray.splice(selectedTransportersArray.indexOf(transporter.transporterId == null ? null : transporter.transporterId), 1);

            this.setState({
                orders: [],
                selectedTransporters: selectedTransportersArray
            });
        } else {
            selectedTransportersArray.push(transporter.transporterId == null ? null : transporter.transporterId);

            this.setState({
                orders: [],
                selectedTransporters: selectedTransportersArray
            });
        }
        this.searchPlanningVehiclesTrip(this.state.date, selectedTransportersArray, this.props.logisticsUnitIds, this.state.searchText);
    }, 500);

    toggleAllCheckbox = debounce((isAllSelected: boolean): void => {
        const selectedTransportersArray = [];
        if (isAllSelected === true) {
            this.state.transporters.forEach(transporter => {
                selectedTransportersArray.push(transporter.transporterId)
            });
        }

        this.setState({
            orders: [],
            selectedTransporters: selectedTransportersArray
        });

        this.searchPlanningVehiclesTrip(this.state.date, selectedTransportersArray, this.props.logisticsUnitIds, this.state.searchText);
    }, 500);

    handleChangeDateRange = (range: DateRange): void => {
        if (range.end != null && range.start != null) {
            const fromTime = range.start.getDayStart();
            const toTime = range.end.getDayEnd();

            this.setState({
                date: { start: fromTime, end: toTime }
            });
            this.searchPlanningVehiclesTripWithTransporters({ start: fromTime, end: toTime }, this.state.searchText, this.props.logisticsUnitIds);
        }
    }

    clearSearchText = (): void => {
        this.inputSearchVehiclesRef.current.value = '';
        this.vehiclesTripsKeyPressed('');
    }

    handleVehiclesTripsKeyPress = debounce((text: string): void => {
        if (text.length >= 3 || text.length === 0) {
            this.vehiclesTripsKeyPressed(text);
        }
    }, 500);

    vehiclesTripsKeyPressed = (text: string): void => {
        this.setState({
            searchText: text
        });

        this.searchPlanningVehiclesTrip(this.state.date, this.state.selectedTransporters, this.props.logisticsUnitIds, text);
    }

    sendConfirmationBC = (): void => {
        let isLessThenToday = false;
        this.state.orders.forEach((order) => {
            if (new Date(order.transportRequestDate) <= new Date()) {
                isLessThenToday = true;
                return;
            }
        });
        if (isLessThenToday) {
            this.setState({
                showIsLessThenToDay: true
            });
        }
        else
            this.isManyDayConfirmation();
    }

    refreshGridAfterSendingProvisions = (): void => {
        this.setState({ planningVehiclesLogisticsUnits: [] })
        this.searchPlanningVehiclesTrip(this.state.date, this.state.selectedTransporters, this.props.logisticsUnitIds, this.state.searchText);
    }

    isManyDayConfirmation = (): void => {
        let isManyDays = false;
        const ordersGrouping1: Array<Array<TransporterOrderRequestArgsExtended>> = Utilities.groupBy(this.state.orders, "transporterId");

        ordersGrouping1.forEach((orders: Array<TransporterOrderRequestArgsExtended>) => {
            const ordersGrouping2: Array<Array<TransporterOrderRequestArgsExtended>> = Utilities.groupBy(orders, "date");
            if (ordersGrouping2.length > 1) {
                isManyDays = true;
                return;
            }
        });

        if (isManyDays) {
            this.handleShow();
        } else {
            this.confirmSend();
        }
    }

    handleShow = (): void => {
        this.setState({
            show: true
        });
    }

    confirmSend = (): void => {
        this.setState({
            loading: true,
            isHeaderBtnsEnabled: false
        });


        TransportPurchasesApiClient.SendConfirmation(this.state.orders.filter(o => o.transporterId))
            .then(json => {
                const data = json?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    this.setState({
                        loading: false,
                        show: false,
                        showIsLessThenToDay: false,
                    });
                    return;
                }
                this.searchPlanningVehiclesTripWithTransporters(this.state.date, this.state.searchText, this.props.logisticsUnitIds);
                this.state.planningVehiclesList.forEach((item: PlanningVehicleLightModelExtended) => {
                    item.selected = false;
                });

                ToastService.showSuccessToast("Confirmation de commande effectuée avec succès");

                this.setState({
                    show: false,
                    showIsLessThenToDay: false,
                    orders: [],
                    loading: false,
                    selectedAllPlanningVehicles: false
                });
            });
    }

    updateFilteredStyle = (searchText: string): void => {
        let vehicleGridElement = document.getElementById("vehicle-grid-id");
        let _isFiltered = false;
        if (searchText && searchText.length >= 3)
            _isFiltered = true;

        if (vehicleGridElement) {
            const tds: HTMLCollectionOf<Element> = vehicleGridElement.getElementsByClassName("k-grid-table");
            Utilities.updateFilteredStyle(tds, _isFiltered);
        }
    }

    searchPlanningVehiclesTrip = (date: DateRange, selectedTransporters: Array<string>, logisticsUnitIds: Array<string>, searchText: string, userName?: string): void => {
        const fromTime = date.start;
        const untilTime = date.end;

        const _lastTimeStamp = this.state.lastTimeStampSearchVehiclesTrip;
        const _currentTimeStamp = Date.now();
        this.setState({
            loading: true,
            orders: []
        });

        TransportPurchasesApiClient.SearchPlanningVehicles(fromTime, untilTime, searchText, null, selectedTransporters, logisticsUnitIds)
            .then(res => {
                if (_lastTimeStamp != this.state.lastTimeStampSearchVehiclesTrip)
                    return;

                const planningVehiclesArray: Array<PlanningVehicleLightModelExtended> = res.data;
                planningVehiclesArray.forEach((Vehicle: PlanningVehicleLightModelExtended) => {
                    this.translatePlanningVehicles(Vehicle);
                });

                if (_lastTimeStamp != this.state.lastTimeStampSearchVehiclesTrip)
                    return;

                this.setState({
                    planningVehiclesList: planningVehiclesArray.map((dataItem: PlanningVehicleLightModelExtended) => Object.assign({
                        selected: false,
                        statusOrderValue: PlanningVehicleStatusComponent.getOrderValue(
                            dataItem.isCanceled,
                            dataItem.cancellationOrderSentDate,
                            dataItem.cancellationOrderConfirmationDate,
                            dataItem.orderConfirmationDate,
                            dataItem.transporterOrderId,
                            dataItem.isInternal
                        )
                    }, dataItem)),
                    lastTimeStampSearchVehiclesTrip: _currentTimeStamp,
                    updateTotalFeesValues: true,
                    isHeaderBtnsEnabled: false,
                    loading: false,
                    isDcDetailsDrawerOpened: false,
                    selectedAllPlanningVehicles: false
                });
                this.updateFilteredStyle(searchText);
            })
            .catch(() => {
                this.setState({
                    loading: false
                });
                this.updateFilteredStyle(searchText);
            });
    }

    translatePlanningVehicles = (planningVehicle: PlanningVehicleLightModelExtended): void => {
        planningVehicle.updating = false;
        planningVehicle.displayDate = Date.getDateFromIsoString(planningVehicle.planningDate) as unknown as string;
        planningVehicle.expectedEndTime = planningVehicle.expectedEndTime == null ? null : Date.getTimeFromIsoString(planningVehicle.expectedEndTime);
        planningVehicle.expectedStartTime = planningVehicle.expectedStartTime == null ? null : Date.getTimeFromIsoString(planningVehicle.expectedStartTime);

        planningVehicle.sendingDate = null;
        if (planningVehicle.orderSentDate != null || planningVehicle.cancellationOrderSentDate != null) {
            planningVehicle.sendingDate = new Date(planningVehicle.cancellationOrderSentDate ?? planningVehicle.orderSentDate);
        }

        planningVehicle.billedHours = planningVehicle.overridenBilledHours ? planningVehicle.overridenBilledHours : planningVehicle.expectedHoursBillableOnMainPrice;
        planningVehicle.billedTime = planningVehicle.overridenBilledTime ? planningVehicle.overridenBilledTime : planningVehicle.expectedTimeBillableOnMainPrice;
        planningVehicle.internalExternalLabel = planningVehicle.isInternal === null
            ? ""
            : planningVehicle.isInternal === true ? "Interne" : "Externe";

        planningVehicle.totalMainPrice = Number((planningVehicle.totalMainPrice ?? 0).toFixed(2));
        planningVehicle.totalPurchasePrice = Number(planningVehicle.totalPurchasePrice.toFixed(2));
        planningVehicle.totalPurchasePriceWithFees = Number(planningVehicle.totalPurchasePriceWithFees.toFixed(2));

        planningVehicle.driverPhone = Utilities.formatPhoneNumber(planningVehicle.driverPhone);

        const hasSpecificOrNegotiatedPrice = planningVehicle.hasSpecificPrice === true || planningVehicle.hasNegotiatedPrice === true;
        planningVehicle.priceKindName = hasSpecificOrNegotiatedPrice ? "Multiple" : (planningVehicle.mainPriceKind === null ? '' : this.getLabelPrice(planningVehicle.mainPriceKind) + ':' + planningVehicle.mainUnitPrice + ' €');
        planningVehicle.hiddenPriceKind = hasSpecificOrNegotiatedPrice ? "Multiple" : (planningVehicle.mainPriceKind === null ? '' : this.getLabelPrice(planningVehicle.mainPriceKind));
        planningVehicle.hiddenUnitPrice = hasSpecificOrNegotiatedPrice ? "0" : (planningVehicle.mainPriceKind === null ? "0" : planningVehicle.mainPriceKind);

        planningVehicle.specificPrices = planningVehicle.specificPrices
            ? orderBy(planningVehicle.specificPrices, ["transportFlowBusinessId", "price"])
            : [];

        planningVehicle.negotiatedPurchasePrices = planningVehicle.negotiatedPurchasePrices
            ? orderBy(planningVehicle.negotiatedPurchasePrices, ["transportFlowBusinessId", "price"])
            : [];

        planningVehicle.flowsWithInconsistentOverridesArray = planningVehicle.flowsWithInconsistentOverrides?.split(",") ?? [];
    }

    getLabelStatusFromDict = (fieldName: string): string => {
        const res = flowStatusLabels.get(fieldName);
        return res ? res : 'INCONNU';
    }

    translateTransportFlow = (transportFlow: TransportFlowsDetailsLightModel): TransportFlowsDetailsLightModel => {
        transportFlow.firstStartTime = transportFlow.firstStartTime == null ? null : Date.getTimeFromIsoString(transportFlow.firstStartTime);
        transportFlow.lastEndTime = transportFlow.lastEndTime == null ? null : Date.getTimeFromIsoString(transportFlow.lastEndTime);
        transportFlow.status = this.getLabelStatusFromDict(transportFlow.status);
        return transportFlow;
    }

    onExpandChangeHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, dataItem: PlanningVehicleLightModelExtended): void => {

        const planningVehicle = dataItem;

        if (planningVehicle.hasSpecificPrice || planningVehicle.hasNegotiatedPrice) {
            planningVehicle.expanded = !planningVehicle.expanded;
            if (dataItem.transporterId != null && dataItem.availablePurchasePrices == undefined) {

                this.expectedPlanningVehicleIdForDetailsRefresh = planningVehicle.planningVehicleId;

                if (!planningVehicle.relatedDataIsLoaded) {
                    TransportPurchasesApiClient.SearchPlanningVehicleDetailsWithDriversAndPurchasePrices(planningVehicle.planningId, planningVehicle.expectedVehicleTypeId, planningVehicle.planningVehicleId, planningVehicle.transporterId, this.props.logisticsUnitIds)
                        .then(res => {

                            const dataPlanningVehicleDetails: Array<PlanningVehicleDetailsLightModel> = res[0].data;
                            const dataDrivers: Array<DriverLightModel> = res[1].data;
                            let availablePurchasePrices: Array<AvailablePurchasePriceLightModelExtended> = res[2].data as Array<AvailablePurchasePriceLightModelExtended>;

                            dataPlanningVehicleDetails.map((item: PlanningVehicleDetailsLightModel) => {
                                item.transportFlows.forEach((element: TransportFlowsDetailsLightModel) => {
                                    this.translateTransportFlow(element);
                                });
                            });

                            availablePurchasePrices?.forEach((element) => {
                                element.priceKindName = this.getLabelPrice(element.priceKind);
                            });

                            availablePurchasePrices = orderBy(availablePurchasePrices, o => [o.priceKindName, o.price], ['asc']);

                            this.setState((prevState, props) => {

                                const prevStatePlanningVehicle = prevState.planningVehiclesList.find(x => x.planningVehicleId == planningVehicle.planningVehicleId);

                                prevStatePlanningVehicle.availablePurchasePrices = availablePurchasePrices;
                                prevStatePlanningVehicle.relatedDataIsLoaded = true;

                                const newState = {
                                    planningVehiclesList: prevState.planningVehiclesList,
                                    planningVehicleDetails: (planningVehicle.planningVehicleId == this.expectedPlanningVehicleIdForDetailsRefresh)
                                        ? dataPlanningVehicleDetails : prevState.planningVehicleDetails,
                                    driversList: (planningVehicle.planningVehicleId == this.expectedPlanningVehicleIdForDetailsRefresh)
                                        ? dataDrivers : prevState.driversList
                                };
                                return newState;
                            });
                        });
                }

            }
            this.forceUpdate()
        }
    }
    cellRender = (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps): React.ReactElement<HTMLTableCellElement> => {
        const dataItem: PlanningVehicleLightModelExtended = cellProps.dataItem;
        const field = cellProps.field;
        let className = "k-icon k-plus";
        let title = "";
        if (cellProps.field === 'expanded') {
            if (dataItem.expanded == true)
                className = "k-icon k-minus"

            if (dataItem.hasSpecificPrice || dataItem.hasNegotiatedPrice) {
                className += " k-icon-custom";
                title = "détail tarifs";
            }
            else {
                className += " hidden";
            }
            return (
                <td>
                    <Tooltip title={title} placement="bottom">
                        <a className={className} onClick={(e) => this.onExpandChangeHandler(e, dataItem)}>
                            {dataItem.expanded == true ? <RemoveIcon /> : <AddIcon />}
                        </a>
                    </Tooltip>
                </td>
            );
        }

        const additionalProps = (dataItem.inEdit && (field === dataItem.inEdit)) ?
            {
                ref: (td) => {
                    const input = td && td.querySelector('input');
                    const activeElement = document.activeElement;
                    if (!input || !activeElement || input === activeElement || !activeElement.contains(input)) { return; }
                    if (input.type === 'checkbox') {
                        input.focus();
                    } else {
                        input.select();
                    }
                }
            } : {
                onClick: () => { this.enterEdit(dataItem, field); }
            };
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children as any);
    }

    enterEdit = (dataItem: PlanningVehicleLightModelExtended, field: string): void => {
        if (dataItem.purchaseIsCanceled || (dataItem.inEdit && field === this.state.editField)) {
            return;
        }

        this.exitEdit(field);
        dataItem.inEdit = field;
    }

    rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, dataItem: PlanningVehicleLightModelExtended): React.ReactElement<HTMLTableRowElement> => {
        const trProps = {
            ...trElement.props,
            onMouseDown: () => {
                this.preventExit = true;
                clearTimeout(this.preventExitTimeout);
                this.preventExitTimeout = setTimeout(() => { this.preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(this.blurTimeout);
                if (!this.preventExit) {
                    this.blurTimeout = setTimeout(() => { this.exitEdit(); });
                }
            },
            onFocus: () => { clearTimeout(this.blurTimeout); }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as any);
    }

    exitEdit = (field?: string): void => {
        let wasEditing = false;
        let vehicleIdToUpdate: number = null;
        let planningId: number = null;
        let planningVehicleId: string = null;
        let propertyName: string = null;
        let driverId: number = null;
        let vehicleTypeId: string = null;
        let isNightWork = false;
        let phoneNumber: string;
        let unitPrice: number;
        let priceKind: string;
        let driverName: string = null;
        let billedTimeToUpdate: string = null;
        let billedHoursToUpdate: number = null;
        let dayDate: Date;
        let remarksToUpdate: string = null;
        let costsRemarksOfLogistician: string = null;
        this.state.planningVehiclesList.forEach((dataItem: PlanningVehicleLightModelExtended) => {
            if (dataItem.inEdit != undefined && (dataItem.inEdit != "selected" && dataItem.inEdit != "expanded")) {

                wasEditing = true;
                propertyName = dataItem.inEdit == "totalPurchasePrice" ? "unitPrice" : dataItem.inEdit;
                vehicleIdToUpdate = dataItem.vehicleId;
                planningId = dataItem.planningId;
                planningVehicleId = dataItem.planningVehicleId;
                driverId = dataItem.driverId;
                dayDate = dataItem.planningDate;
                phoneNumber = dataItem.driverPhone;
                driverName = dataItem.driverName;
                vehicleTypeId = dataItem.expectedVehicleTypeId;
                isNightWork = dataItem.isNightWork;
                unitPrice = dataItem.mainUnitPrice;
                priceKind = dataItem.mainPriceKind;
                billedTimeToUpdate = dataItem.overridenBilledTime;
                billedHoursToUpdate = dataItem.overridenBilledHours;
                remarksToUpdate = dataItem.remarks;
                costsRemarksOfLogistician = dataItem.costsRemarksOfLogistician;
            }
            dataItem.inEdit = undefined;
        });

        if (!wasEditing
            || (propertyName !== "driverName"
                && propertyName !== "unitPrice"
                && propertyName !== "mainPriceKind"
                && propertyName != "billedTime"
                && propertyName !== "remarks"
                && propertyName !== "costsRemarksOfLogistician")) {
            this.setState({
                editField: null
            });
            return;
        }

        const delTripchange: PlanningVehicleChangeRequestArgs = {
            planningId: planningId,
            planningVehicleId: planningVehicleId,
            vehicleId: vehicleIdToUpdate,
            vehicleTypeId: vehicleTypeId,
            propertyName: propertyName,
            driverId: propertyName == "driverName" ? (driverId == -1 ? null : driverId) : null,
            driverName: driverName,
            driverPhoneNumber: phoneNumber,
            unitPrice: unitPrice,
            priceKind: priceKind,
            date: dayDate,
            isNightWork: isNightWork,
            billedTime: billedTimeToUpdate,
            billedHours: billedHoursToUpdate,
            remarks: remarksToUpdate,
            costsRemarksOfLogistician: costsRemarksOfLogistician
        };

        this.expectedPlanningVehicleIdForDetailsRefresh = planningVehicleId;

        TransportPurchasesApiClient.UpdatePlanningVehicleCell(delTripchange)
            .then(res => {
                const updateData = res.data;
                const errors = BusinessErrors.Get(updateData);
                if (errors.length > 0) {
                    this.searchPlanningVehiclesTrip(this.state.date, this.state.selectedTransporters, this.props.logisticsUnitIds, this.state.searchText);
                    ToastService.showErrorToast("", errors);
                    return;
                }

                TransportPurchasesApiClient.SearchPlanningVehicleWithDetails(planningVehicleId, this.state.date.start, this.state.date.end, "", this.state.selectedTransporters, this.props.logisticsUnitIds)
                    .then(res => {

                        const data: Array<PlanningVehicleDetailsLightModel> = res[1].data;
                        data.map((item: PlanningVehicleDetailsLightModel) => {
                            item.transportFlows.forEach((element: TransportFlowsDetailsLightModel) => {
                                this.translateTransportFlow(element);
                            });
                        });

                        this.setState((prevState, props) => {

                            const planningVehiclesList = prevState.planningVehiclesList;

                            let planningVehicle: PlanningVehicleLightModelExtended = planningVehiclesList.find(e => e.planningVehicleId == planningVehicleId);
                            planningVehicle = Object.assign(planningVehicle, res[0].data[0]);
                            this.translatePlanningVehicles(planningVehicle);

                            const newState = {
                                editField: field ? field : null,
                                updateTotalFeesValues: true,
                                planningVehicleDetails: (this.expectedPlanningVehicleIdForDetailsRefresh == planningVehicleId) ? data : prevState.planningVehicleDetails,
                                planningVehiclesList: planningVehiclesList
                            };

                            return newState;
                        });
                    });
            });
    }

    refreshPlanningVehicles = (): void => {
        this.searchPlanningVehiclesTrip(this.state.date, this.state.selectedTransporters, this.props.logisticsUnitIds, this.state.searchText);
    }

    selectionChange = (checked: boolean, dataItem: PlanningVehicleLightModelExtended): void => {


        const transportOrderLines = new Array<TransporterOrderLineRequestArgs>();
        transportOrderLines.push({
            buyerLogisticsUnitId: dataItem.buyerLogisticsUnitId,
            planningVehicleId: dataItem.planningVehicleId,
            planningVehicleIsCanceled: dataItem.isCanceled
        });

        let planningVehiclesLogisticsUnits = this.state.planningVehiclesLogisticsUnits;
        let planningVehiclesPublishedCosts: Array<PlanningVehiclePublishedCostsRequestArgs> = this.state.planningVehiclesPublishedCosts;

        if (checked === true) {
            var ordersList: TransporterOrderRequestArgsExtended[] = this.state.orders;
            ordersList.push({
                id: dataItem.planningVehicleId,
                date: new Date(dataItem.planningDate).toJsonTimezoned(),
                transporterId: dataItem.transporterId,
                transporterLabel: dataItem.transporter,
                isInternal: dataItem.isInternal,
                transporterOrdersLines: transportOrderLines,
                transportRequestDate: new Date(dataItem.planningDate)
            });

            planningVehiclesLogisticsUnits.push({
                planningVehicleId: dataItem.planningVehicleId,
                buyerLogisticsUnitId: dataItem.buyerLogisticsUnitId,
                isSended: dataItem.feesProvisioningSentDate != null || dataItem.vehicleProvisioningSentDate != null ? true : false
            });

            if (!(dataItem.transporterId === null && dataItem.vehicleId !== null)) {
                planningVehiclesPublishedCosts.push({
                    planningId: dataItem.planningId,
                    planningVehicleId: dataItem.planningVehicleId
                });
            }
        } else {
            var ordersList: TransporterOrderRequestArgsExtended[] = this.state.orders;
            ordersList = ordersList.filter(item => item.id !== dataItem.planningVehicleId);
            planningVehiclesLogisticsUnits = planningVehiclesLogisticsUnits.filter(item => item.planningVehicleId !== dataItem.planningVehicleId);
            planningVehiclesPublishedCosts = planningVehiclesPublishedCosts.filter(item => item.planningVehicleId !== dataItem.planningVehicleId);
        }

        const ordersListTransporters = ordersList.filter(o => o.transporterId);

        if (ordersList.length === 0) {

            this.setState((prevState, props) => {

                const planningVehiclesList = prevState.planningVehiclesList;
                const _elem = planningVehiclesList.find(p => p.planningVehicleId == dataItem.planningVehicleId);
                _elem.selected = checked;

                const newState = {
                    isHeaderBtnsEnabled: false,
                    orders: ordersList,
                    updateTotalFeesValues: true,
                    isDcDetailsDrawerOpened: false,
                    planningVehicleDetails: [],
                    planningVehiclesLogisticsUnits: planningVehiclesLogisticsUnits,
                    planningVehiclesPublishedCosts: planningVehiclesPublishedCosts,
                    planningVehiclesList: planningVehiclesList,
                    selectedAllPlanningVehicles: !checked ? false : planningVehiclesList.every(t => t.selected)
                };

                return newState;
            });

        }
        else if (ordersList.length === 1) {

            this.expectedPlanningVehicleIdForDetailsRefresh = dataItem.planningVehicleId;

            TransportPurchasesApiClient.SearchPlanningVehicleDetails(ordersList[0].id)
                .then(res => {

                    const data: Array<PlanningVehicleDetailsLightModel> = res.data;
                    data.map((item: PlanningVehicleDetailsLightModel) => {
                        item.transportFlows.forEach((element: TransportFlowsDetailsLightModel) => {
                            this.translateTransportFlow(element);
                        });
                    });

                    this.setState((prevState, props) => {

                        const planningVehiclesList = prevState.planningVehiclesList;
                        const _elem = planningVehiclesList.find(p => p.planningVehicleId == dataItem.planningVehicleId);
                        _elem.selected = checked;

                        const newState = {
                            orders: ordersList,
                            isHeaderBtnsEnabled: ordersListTransporters.length > 0,
                            updateTotalFeesValues: true,
                            isDcDetailsDrawerOpened: true,
                            planningVehicleDetails: (dataItem.planningVehicleId == this.expectedPlanningVehicleIdForDetailsRefresh)
                                ? data : prevState.planningVehicleDetails,
                            planningVehiclesLogisticsUnits: planningVehiclesLogisticsUnits,
                            planningVehiclesPublishedCosts: planningVehiclesPublishedCosts,
                            planningVehiclesList: planningVehiclesList,
                            selectedAllPlanningVehicles: !checked ? false : planningVehiclesList.every(t => t.selected)
                        };

                        return newState;
                    });

                });
        } else {

            this.setState((prevState, props) => {

                const planningVehiclesList = prevState.planningVehiclesList;
                const _elem = planningVehiclesList.find(p => p.planningVehicleId == dataItem.planningVehicleId);
                _elem.selected = checked;

                const newState = {
                    isHeaderBtnsEnabled: ordersListTransporters.length > 0,
                    orders: ordersList,
                    updateTotalFeesValues: true,
                    isDcDetailsDrawerOpened: false,
                    planningVehicleDetails: [],
                    planningVehiclesLogisticsUnits: planningVehiclesLogisticsUnits,
                    planningVehiclesPublishedCosts: planningVehiclesPublishedCosts,
                    planningVehiclesList: planningVehiclesList,
                    selectedAllPlanningVehicles: !checked ? false : planningVehiclesList.every(t => t.selected)
                };

                return newState;
            });

        }
    }

    headerSelectionChange = (checked: boolean): void => {
        const ordersList: TransporterOrderRequestArgsExtended[] = [];
        const planningVehiclesLogisticsUnits: PlanningVehicleIdLogisticsUnitChoiceModel[] = [];
        const planningVehiclesPublishedCosts: Array<PlanningVehiclePublishedCostsRequestArgs> = [];

        this.state.planningVehiclesList.forEach((dataItem: PlanningVehicleLightModelExtended) => {
            dataItem.selected = checked;

            if (checked == true) {
                const transportOrderLines = new Array<TransporterOrderLineRequestArgs>();
                transportOrderLines.push({
                    buyerLogisticsUnitId: dataItem.buyerLogisticsUnitId,
                    planningVehicleId: dataItem.planningVehicleId,
                    planningVehicleIsCanceled: dataItem.isCanceled
                });

                ordersList.push({
                    id: dataItem.planningVehicleId,
                    date: new Date(dataItem.planningDate).toJsonTimezoned(),
                    transporterId: dataItem.transporterId,
                    transporterLabel: dataItem.transporter,
                    isInternal: dataItem.isInternal,
                    transporterOrdersLines: transportOrderLines,
                    transportRequestDate: new Date(dataItem.planningDate)
                });

                planningVehiclesLogisticsUnits.push({
                    planningVehicleId: dataItem.planningVehicleId,
                    buyerLogisticsUnitId: dataItem.buyerLogisticsUnitId,
                    isSended: dataItem.feesProvisioningSentDate != null || dataItem.vehicleProvisioningSentDate != null ? true : false
                });
                if (!(dataItem.transporterId === null && dataItem.vehicleId !== null)) {
                    planningVehiclesPublishedCosts.push({
                        planningId: dataItem.planningId,
                        planningVehicleId: dataItem.planningVehicleId
                    });
                }
            }
        });

        const ordersListTransporters = ordersList.filter(o => o.transporterId);

        if (ordersList.length === 0) {
            this.setState({
                isHeaderBtnsEnabled: false,
                orders: ordersList,
                updateTotalFeesValues: true,
                isDcDetailsDrawerOpened: false,
                planningVehicleDetails: [],
                planningVehiclesLogisticsUnits: planningVehiclesLogisticsUnits,
                planningVehiclesPublishedCosts: planningVehiclesPublishedCosts,
                selectedAllPlanningVehicles: this.state.planningVehiclesList.every(t => t.selected)
            });
        } else {
            this.setState({
                isHeaderBtnsEnabled: ordersListTransporters.length > 0,
                orders: ordersList,
                updateTotalFeesValues: true,
                isDcDetailsDrawerOpened: false,
                planningVehicleDetails: [],
                planningVehiclesLogisticsUnits: planningVehiclesLogisticsUnits,
                planningVehiclesPublishedCosts: planningVehiclesPublishedCosts,
                selectedAllPlanningVehicles: this.state.planningVehiclesList.every(t => t.selected)
            });
        }
    }

    rowClick = debounce((event: GridRowClickEvent): void => {

        const dataItem: PlanningVehicleLightModelExtended = event.dataItem;
        const select = !dataItem.selected;

        if (event.nativeEvent.ctrlKey) {
            this.selectionChange(select, dataItem);
        }
        else {
            const planningVehiclesLogisticsUnits: PlanningVehicleIdLogisticsUnitChoiceModel[] = [];
            const planningVehiclesPublishedCosts: Array<PlanningVehiclePublishedCostsRequestArgs> = [];
            const transportOrderLines = new Array<TransporterOrderLineRequestArgs>();
            transportOrderLines.push({
                buyerLogisticsUnitId: dataItem.buyerLogisticsUnitId,
                planningVehicleId: dataItem.planningVehicleId,
                planningVehicleIsCanceled: dataItem.isCanceled
            });
            const ordersList: TransporterOrderRequestArgsExtended[] = [];
            ordersList.push({
                id: dataItem.planningVehicleId,
                date: new Date(dataItem.planningDate).toJsonTimezoned(),
                transporterId: dataItem.transporterId,
                transporterLabel: dataItem.transporter,
                isInternal: dataItem.isInternal,
                transporterOrdersLines: transportOrderLines,
                transportRequestDate: new Date(dataItem.planningDate)
            });
            planningVehiclesLogisticsUnits.push({
                planningVehicleId: dataItem.planningVehicleId,
                buyerLogisticsUnitId: dataItem.buyerLogisticsUnitId,
                isSended: dataItem.feesProvisioningSentDate != null || dataItem.vehicleProvisioningSentDate != null ? true : false
            });
            if (!(dataItem.transporterId === null && dataItem.vehicleId !== null)) {
                planningVehiclesPublishedCosts.push({
                    planningId: dataItem.planningId,
                    planningVehicleId: dataItem.planningVehicleId
                });
            }

            if (!dataItem?.selected) {
                if (dataItem.transporterId != null) {

                    this.expectedPlanningVehicleIdForDetailsRefresh = dataItem.planningVehicleId;

                    TransportPurchasesApiClient.SearchPlanningVehicleDetailsWithDriversAndPurchasePrices(dataItem.planningId, dataItem.expectedVehicleTypeId, dataItem.planningVehicleId, dataItem.transporterId, this.props.logisticsUnitIds)
                        .then(res => {

                            const dataPlanningVehicleDetails: Array<PlanningVehicleDetailsLightModel> = res[0].data;
                            const dataDrivers: Array<DriverLightModel> = res[1].data;
                            let availablePurchasePrices: Array<AvailablePurchasePriceLightModelExtended> = res[2].data as Array<AvailablePurchasePriceLightModelExtended>;

                            dataPlanningVehicleDetails.map((item: PlanningVehicleDetailsLightModel) => {
                                item.transportFlows.forEach((element: TransportFlowsDetailsLightModel) => {
                                    this.translateTransportFlow(element);
                                });
                            });

                            availablePurchasePrices?.forEach((element) => {
                                element.priceKindName = this.getLabelPrice(element.priceKind);
                            });
                            availablePurchasePrices = orderBy(availablePurchasePrices, o => [o.priceKindName, o.price], ['asc']);

                            this.setState((prevState, props) => {

                                const planningVehiclesList = prevState.planningVehiclesList;
                                const currentIndex = planningVehiclesList.findIndex((d: PlanningVehicleLightModelExtended) => d.planningVehicleId === dataItem.planningVehicleId);

                                let _lastIndex = this.lastSelectedIndex;

                                if (!event.nativeEvent.shiftKey) {
                                    this.lastSelectedIndex = _lastIndex = currentIndex;
                                }

                                if (!event.nativeEvent.ctrlKey) {
                                    planningVehiclesList.forEach((item: PlanningVehicleLightModelExtended) => item.selected = false);
                                }

                                for (let i = Math.min(_lastIndex, currentIndex); i <= Math.max(_lastIndex, currentIndex); i++) {
                                    planningVehiclesList[i].selected = select;
                                }

                                const currentPlanningVehicle = planningVehiclesList[currentIndex];
                                currentPlanningVehicle.availablePurchasePrices = availablePurchasePrices;
                                currentPlanningVehicle.relatedDataIsLoaded = true;

                                const newState = {
                                    orders: ordersList,
                                    isHeaderBtnsEnabled: true,
                                    updateTotalFeesValues: true,
                                    isDcDetailsDrawerOpened: true,
                                    planningVehicleDetails: (dataItem.planningVehicleId == this.expectedPlanningVehicleIdForDetailsRefresh)
                                        ? dataPlanningVehicleDetails : prevState.planningVehicleDetails,
                                    planningVehiclesLogisticsUnits: planningVehiclesLogisticsUnits,
                                    planningVehiclesPublishedCosts: planningVehiclesPublishedCosts,
                                    selectedAllPlanningVehicles: !select ? false : planningVehiclesList.every(t => t.selected),
                                    planningVehiclesList: planningVehiclesList,
                                    driversList: dataDrivers
                                };
                                return newState;
                            });
                        });
                }
                else {

                    this.expectedPlanningVehicleIdForDetailsRefresh = dataItem.planningVehicleId;

                    TransportPurchasesApiClient.SearchPlanningVehicleDetailsWithDrivers(dataItem.planningVehicleId, dataItem.transporterId)
                        .then(res => {

                            const dataPlanningVehicleDetails: Array<PlanningVehicleDetailsLightModel> = res[0].data;
                            const dataDrivers: Array<DriverLightModel> = res[1].data;

                            dataPlanningVehicleDetails.map((item: PlanningVehicleDetailsLightModel) => {
                                item.transportFlows.forEach((element: TransportFlowsDetailsLightModel) => {
                                    this.translateTransportFlow(element);
                                });
                            });

                            this.setState((prevState, props) => {

                                const planningVehiclesList = prevState.planningVehiclesList;
                                const currentIndex = planningVehiclesList.findIndex((d: PlanningVehicleLightModelExtended) => d.planningVehicleId === dataItem.planningVehicleId);

                                let _lastIndex = this.lastSelectedIndex;
                                if (!event.nativeEvent.shiftKey) {
                                    this.lastSelectedIndex = _lastIndex = currentIndex;
                                }

                                if (!event.nativeEvent.ctrlKey) {
                                    planningVehiclesList.forEach((item: PlanningVehicleLightModelExtended) => item.selected = false);
                                }

                                for (let i = Math.min(_lastIndex, currentIndex); i <= Math.max(_lastIndex, currentIndex); i++) {
                                    planningVehiclesList[i].selected = select;
                                }

                                const newState = {
                                    orders: ordersList,
                                    isHeaderBtnsEnabled: false,
                                    updateTotalFeesValues: true,
                                    isDcDetailsDrawerOpened: true,
                                    planningVehicleDetails: (dataItem.planningVehicleId == this.expectedPlanningVehicleIdForDetailsRefresh)
                                        ? dataPlanningVehicleDetails : prevState.planningVehicleDetails,
                                    planningVehiclesLogisticsUnits: planningVehiclesLogisticsUnits,
                                    planningVehiclesPublishedCosts: planningVehiclesPublishedCosts,
                                    selectedAllPlanningVehicles: !select ? false : planningVehiclesList.every(t => t.selected),
                                    planningVehiclesList: planningVehiclesList,
                                    driversList: dataDrivers
                                };
                                return newState;
                            });

                        });
                }
            }
            else {
                this.setState((prevState, props) => ({
                    selectedAllPlanningVehicles: !select ? false : prevState.planningVehiclesList.every(t => t.selected),
                }));
            }
        }
    }, 500);

    onChangeHandler = (dataItem: PlanningVehicleLightModelExtended): void => {
        this.exitEdit();
    }

    onHandleExpectedTimeChange = (dataItem: PlanningVehicleLightModelExtended): void => {
        const vehicle = this.state.planningVehiclesList.find(x => x.planningVehicleId === dataItem.planningVehicleId);
        vehicle.billedTime = dataItem.billedTime;
        vehicle.billedHours = dataItem.billedHours;
    }

    getVehicleContentClassName = (): string => {
        if (this.state.isTransporterDrawerOpened && this.state.isDcDetailsDrawerOpened) {
            return "vehicles-content open transporter-drawer-opened"
        } else if (this.state.isDcDetailsDrawerOpened) {
            return "vehicles-content open";
        } else if (this.state.isTransporterDrawerOpened && !this.state.isDcDetailsDrawerOpened) {
            return "vehicles-content transporter-drawer-opened"
        } else {
            return "vehicles-content";
        }
    }

    handleHide = (): void => {
        let ordersList: TransporterOrderRequestArgsExtended[] = this.state.orders;
        this.state.planningVehiclesList.forEach((item: PlanningVehicleLightModelExtended) => {
            item.selected = false;
            ordersList = ordersList.filter(item => item.id !== item.id);
        });
        if (ordersList.length === 0) {
            this.setState({
                isHeaderBtnsEnabled: false,
                orders: ordersList,
                show: false,
                showIsLessThenToDay: false,
                isDcDetailsDrawerOpened: false
            });
        }
        else {
            this.setState({
                isHeaderBtnsEnabled: true,
                orders: ordersList, show: false,
                showIsLessThenToDay: false
            });
        }
    };

    handleHideIsLessThenToDay = (): void => {
        let ordersList: TransporterOrderRequestArgsExtended[] = this.state.orders;

        const planningVehiclesArray = this.state.planningVehiclesList;
        planningVehiclesArray.forEach((item: PlanningVehicleLightModelExtended) => {
            item.selected = false;
            ordersList = ordersList.filter(item => item.id !== item.id);
        });
        this.setState({
            planningVehiclesList: planningVehiclesArray
        });

        if (ordersList.length === 0) {
            this.setState({
                isHeaderBtnsEnabled: false,
                orders: ordersList,
                show: false,
                showIsLessThenToDay: false,
                isDcDetailsDrawerOpened: false
            });
        }
        else {
            this.setState({
                isHeaderBtnsEnabled: true,
                orders: ordersList,
                show: false,
                showIsLessThenToDay: false
            });
        }
    };

    handleConfirm = (): void => {
        this.confirmSend()
    }

    handleConfirmIsLessThenToDay = (): void => {
        this.isManyDayConfirmation()
    }

    handleProductionForemanChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        this.setState({
            selectedProductionForeman: e.target.options[e.target.selectedIndex].value
        });

        this.searchPlanningVehiclesTrip(this.state.date, this.state.selectedTransporters, this.props.logisticsUnitIds, this.state.searchText, e.target.options[e.target.selectedIndex].value);
    }

    setUpdateTotalFeesValues = (value: boolean): void => {
        this.setState({
            updateTotalFeesValues: value
        });
    }

    getPlanningVehiclesVisualisationData = (): void => {
        const fromTime = this.state.date.start;
        const untilTime = this.state.date.end;

        TransportPurchasesApiClient.GetPlanningVehiclesVisualisation(fromTime, untilTime, this.state.searchText, this.state.selectedTransporters, this.props.logisticsUnitIds)
            .then(res => {
                this.setState({
                    visualisationData: res.data
                });
            });
    }

    getLabelPrice = (priceKind: string): string => {
        if (priceKind === VehiclePriceKind.Day) {
            return "Jour";
        } else if (priceKind === VehiclePriceKind.Night) {
            return "Nuit";
        } else if (priceKind === VehiclePriceKind.HalfDay) {
            return "Demi-journée";
        } else if (priceKind === VehiclePriceKind.PerTon) {
            return "Tonne";
        } else if (priceKind === VehiclePriceKind.PerHour) {
            return "Heure";
        }
        else if (priceKind === VehiclePriceKind.PerTurn) {
            return "Tour";
        }

        return "";
    }

    displayErrorsMessages = (errors: string[]): void => {
        if (errors.length > 0) {
            ToastService.showErrorToast("", errors);
        }
    }

    handlePublishVehiclesCosts = (): void => {
        const planningVehiclesPublishedCosts: Array<PlanningVehiclePublishedCostsRequestArgs> = this.state.planningVehiclesPublishedCosts;
        if (planningVehiclesPublishedCosts.length > 0) {
            TransportPurchasesApiClient.PublishVehiclesCosts(planningVehiclesPublishedCosts)
                .then((res) => {
                    if (res.status == 200) {
                        ToastService.showSuccessToast("Les camions sélectionnés sont désormais visibles par les transporteurs pour la préfacturation");

                        this.searchPlanningVehiclesTripWithTransporters(this.state.date, this.state.searchText, this.props.logisticsUnitIds);
                        this.state.planningVehiclesList.forEach((item: PlanningVehicleLightModelExtended) => {
                            item.selected = false;
                        });

                        this.setState({
                            orders: [],
                            planningVehiclesPublishedCosts: [],
                            planningVehiclesLogisticsUnits: [],
                            selectedAllPlanningVehicles: false,
                        });
                    }
                });
        }
    }

    handleDeliveredQuantityChanged = (planningVehicleId: string, transportRequestId: string, transportFlowId: string, overridenDeliveredQuantity: number): void => {

        this.setState((prevState, props) => {

            const planningVehicleDetails = prevState.planningVehicleDetails;
            const transportRequest: PlanningVehicleDetailsLightModel = planningVehicleDetails.find(e => e.transportRequestId == transportRequestId);
            const transportFlows = transportRequest.transportFlows;
            const flowDetails: TransportFlowsDetailsLightModel = transportFlows.find(e => e.transportFlowId == transportFlowId);
            flowDetails.overridenDeliveredQuantity = overridenDeliveredQuantity;

            const planningVehiclesList = prevState.planningVehiclesList;
            const planningVehicle = planningVehiclesList.find(x => x.planningVehicleId === planningVehicleId);
            if (planningVehicle) {
                planningVehicle.updating = true;
            }

            const newState = {
                planningVehiclesList: planningVehiclesList,
                planningVehicleDetails: planningVehicleDetails
            };
            return newState;
        });


        this.refreshPlanningVehicleWithDetails(planningVehicleId);
    }

    handleDeliveredNumberOfTurnsChanged = (planningVehicleId: string, transportRequestId: string, transportFlowId: string, overridenDeliveredNumberOfTurns: number): void => {

        this.setState((prevState, props) => {

            const planningVehicleDetails = prevState.planningVehicleDetails;
            const transportRequest: PlanningVehicleDetailsLightModel = planningVehicleDetails.find(e => e.transportRequestId == transportRequestId);
            const transportFlows = transportRequest.transportFlows;
            const flowDetails: TransportFlowsDetailsLightModel = transportFlows.find(e => e.transportFlowId == transportFlowId);
            flowDetails.overridenDeliveredNumberOfTurns = overridenDeliveredNumberOfTurns;

            const planningVehiclesList = prevState.planningVehiclesList;
            const planningVehicle = planningVehiclesList.find(x => x.planningVehicleId === planningVehicleId);
            if (planningVehicle) {
                this.translatePlanningVehicles(planningVehicle);
                planningVehicle.updating = true;
            }

            const newState = {
                planningVehiclesList: planningVehiclesList,
                planningVehicleDetails: planningVehicleDetails
            };
            return newState;
        });

        this.refreshPlanningVehicleWithDetails(planningVehicleId);
    }

    refreshPlanningVehicle = (planningVehicleId: string, needUpdateFeesValues = false): void => {
        TransportPurchasesApiClient.SearchPlanningVehicles(this.state.date.start, this.state.date.end, "", planningVehicleId, this.state.selectedTransporters, this.props.logisticsUnitIds)
            .then(res => {

                this.setState((prevState, props) => {

                    const planningVehiclesList: PlanningVehicleLightModelExtended[] = prevState.planningVehiclesList;
                    let planningVehicle: PlanningVehicleLightModelExtended = planningVehiclesList.find(e => e.planningVehicleId == planningVehicleId);
                    planningVehicle = Object.assign(planningVehicle, res.data[0]);
                    this.translatePlanningVehicles(planningVehicle);

                    const newState = {
                        updateTotalFeesValues: needUpdateFeesValues ? true : prevState.updateTotalFeesValues,
                        planningVehiclesList: planningVehiclesList
                    };
                    return newState;
                });
            });
    }

    refreshPlanningVehicleWithDetails = (planningVehicleId: string): void => {

        this.expectedPlanningVehicleIdForDetailsRefresh = planningVehicleId;

        TransportPurchasesApiClient.SearchPlanningVehiclesWithDetails(this.state.date.start, this.state.date.end, "", planningVehicleId, this.state.selectedTransporters, this.props.logisticsUnitIds)
            .then(res => {

                this.setState((prevState, props) => {

                    const planningVehiclesRes = res[0].data;
                    const planningVehicleDetailRes = res[1];

                    const planningVehiclesList: PlanningVehicleLightModelExtended[] = prevState.planningVehiclesList;
                    let planningVehicle: PlanningVehicleLightModelExtended = planningVehiclesList.find(e => e.planningVehicleId == planningVehicleId);
                    planningVehicle = Object.assign(planningVehicle, planningVehiclesRes[0]);
                    this.translatePlanningVehicles(planningVehicle);
                    const planningVehicleDetailData: Array<PlanningVehicleDetailsLightModel> = planningVehicleDetailRes.data;
                    planningVehicleDetailData.map((item: PlanningVehicleDetailsLightModel) => {
                        item.transportFlows.forEach((element: TransportFlowsDetailsLightModel) => {
                            this.translateTransportFlow(element);
                        });
                    });

                    const newState = {
                        updateTotalFeesValues: true,
                        planningVehicleDetails: (planningVehicleId == this.expectedPlanningVehicleIdForDetailsRefresh)
                            ? planningVehicleDetailData : prevState.planningVehicleDetails,
                        planningVehiclesList: planningVehiclesList
                    };
                    return newState;
                });
            });
    }

    setShowOnlyWithCostsInDisagreement = (value: boolean): void => {

        this.setState((prevState, props) => {

            const planningVehiclesList = prevState.planningVehiclesList;
            planningVehiclesList.forEach(t => t.selected = false);

            const newState = {
                showOnlyWithCostsInDisagreement: value,
                isDcDetailsDrawerOpened: false,
                planningVehicleDetails: [],
                planningVehiclesList: planningVehiclesList,
                orders: [],
                isHeaderBtnsEnabled: false,
                selectedAllPlanningVehicles: false
            };
            return newState;
        });

    }

    setShowOnlyCanceledVehicles = (value: boolean): void => {

        this.setState((prevState, props) => {

            const planningVehiclesList = prevState.planningVehiclesList;
            planningVehiclesList.forEach(t => t.selected = false);

            const newState = {
                showOnlyCanceledVehicles: value,
                isDcDetailsDrawerOpened: false,
                planningVehicleDetails: [],
                planningVehiclesList: planningVehiclesList,
                orders: [],
                isHeaderBtnsEnabled: false,
                selectedAllPlanningVehicles: false
            };
            return newState;
        });

    }

    handleUpdatingPlanningVehicle = (planningVehicleId: string): void => {

        this.setState((prevState, props) => {

            const planningVehiclesList = prevState.planningVehiclesList;
            const planningVehicle: PlanningVehicleLightModelExtended = planningVehiclesList.find(e => e.planningVehicleId == planningVehicleId);
            planningVehicle.updating = true;

            const newState = {
                planningVehiclesList: planningVehiclesList
            };
            return newState;
        });

    }

    render() {
        const { transporters, selectedTransporters, isHeaderBtnsEnabled, updateTotalFeesValues, visualisationData, planningVehiclesList, planningVehicleDetails,
            loading, driversList, isDcDetailsDrawerOpened, isTransporterDrawerOpened, show, showIsLessThenToDay, selectedAllPlanningVehicles, showOnlyWithCostsInDisagreement, showOnlyCanceledVehicles } = this.state;

        const inputSearchVehiclesValue = this.inputSearchVehiclesRef.current === null || this.inputSearchVehiclesRef.current === undefined ? '' : this.inputSearchVehiclesRef.current.value;

        const planningVehicleSelected = planningVehiclesList.find(x => x.selected === true);

        const contents = loading
            ? <div className='sweet-loading spinnerClass'>
                <ScaleLoader
                    width={5}
                    height={20}
                    radius={50}
                    color={'#000000'}
                    loading={loading}
                />
            </div>
            :
            <Box className="common-component-content-vehicles">
                <div className={this.getVehicleContentClassName()}>
                    <TransportPurchasesComponent
                        planningVehiclesList={planningVehiclesList}
                        driversList={driversList}
                        selectedAll={selectedAllPlanningVehicles}
                        showOnlyWithCostsInDisagreement={showOnlyWithCostsInDisagreement}
                        showOnlyCanceledVehicles={showOnlyCanceledVehicles}
                        cellRender={this.cellRender}
                        rowRender={this.rowRender}
                        selectionChange={this.selectionChange}
                        headerSelectionChange={this.headerSelectionChange}
                        rowClick={this.rowClick}
                        onChangeHandler={this.onChangeHandler}
                        handleBilledTimeChange={this.onHandleExpectedTimeChange}
                        getLabelPrice={this.getLabelPrice}
                        displayErrorsMessages={this.displayErrorsMessages}
                        refreshPlanningVehicles={this.refreshPlanningVehicles}
                        refreshPlanningVehicleWithDetails={this.refreshPlanningVehicleWithDetails}
                        handleUpdatingPlanningVehicle={this.handleUpdatingPlanningVehicle}
                    />
                </div>
                <TransportRequestsDetailsComponent
                    isDcDetailsDrawerOpened={isDcDetailsDrawerOpened}
                    planningVehicleDetails={planningVehicleDetails}
                    planningVehicle={planningVehicleSelected}
                    displayErrorsMessages={this.displayErrorsMessages}
                    handleDeliveredNumberOfTurnsChanged={this.handleDeliveredNumberOfTurnsChanged}
                    handleDeliveredQuantityChanged={this.handleDeliveredQuantityChanged}
                    refreshPlanningVehicles={this.refreshPlanningVehicles}
                    refreshPlanningVehicleWithDetails={this.refreshPlanningVehicleWithDetails}
                    handleUpdatingPlanningVehicle={this.handleUpdatingPlanningVehicle}
                />
            </Box>

        const communContent = <div className={isTransporterDrawerOpened ? "common-component-content open" : "common-component-content"}>
            <ContentHeaderComponent
                isTransporterDrawerOpened={isTransporterDrawerOpened}
                inputSearchVehiclesValue={inputSearchVehiclesValue}
                inputSearchVehiclesRef={this.inputSearchVehiclesRef}
                isHeaderBtnsEnabled={isHeaderBtnsEnabled}
                updateTotalFeesValues={updateTotalFeesValues}
                planningVehiclesList={planningVehiclesList}
                visualisationData={visualisationData}
                _setUpdateTotalFeesValues={this.setUpdateTotalFeesValues}
                _handleOpenCloseTransporterDrawer={this.handleOpenCloseTransporterDrawer}
                _handleChangeDateRange={this.handleChangeDateRange}
                _SendConfirmationBC={this.sendConfirmationBC}
                _handleVehiclesTripsKeyPress={this.handleVehiclesTripsKeyPress}
                _clearSearchText={this.clearSearchText}
                _getVisualisationData={this.getPlanningVehiclesVisualisationData}
                _getLabelPrice={this.getLabelPrice}
                handlePublishVehiclesCosts={this.handlePublishVehiclesCosts}
                showOnlyWithCostsInDisagreement={showOnlyWithCostsInDisagreement}
                showOnlyCanceledVehicles={showOnlyCanceledVehicles}
                setShowOnlyWithCostsInDisagreement={this.setShowOnlyWithCostsInDisagreement}
                setShowOnlyCanceledVehicles={this.setShowOnlyCanceledVehicles}
            />
            {contents}
        </div>

        const ret = <div className="vehicles-management-content">
            <Box sx={{
                display: "flex",
                height: "100%"
            }}>
                <TransporterListComponent
                    isTransporterDrawerOpened={isTransporterDrawerOpened}
                    transporters={transporters}
                    selectedTransporters={selectedTransporters}
                    //inputSearchTransporterValue={inputSearchTransporterValue}
                    //searchTransporterRef={this.searchTransporterRef}
                    //_clearSearchText={this.clearSearchTransporterText}
                    //_handleChangeTransporterInput={this.handleChangeTransporterInput}
                    _handleOpenCloseTransporterDrawer={this.handleOpenCloseTransporterDrawer}
                    _toggleCheckbox={this.toggleCheckbox}
                    _toggleAllCheckboxs={this.toggleAllCheckbox}
                />
                {communContent}
            </Box>
            <Modal show={show} onHide={this.handleHide} className='mt-5'>
                <Modal.Header closeButton>
                    <Modal.Title>ATTENTION</Modal.Title>
                </Modal.Header>
                <Modal.Body>ATTENTION la sélection comprend plusieurs journées, souhaitez-vous continuer l'action ?</Modal.Body>
                <Modal.Footer>
                    <Button className="secondary" onClick={this.handleHide}>
                        ANNULER
                    </Button>
                    <Button className="primary" onClick={this.handleConfirm}>
                        CONFIRMER
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showIsLessThenToDay} onHide={this.handleHideIsLessThenToDay} className='mt-5'>
                <Modal.Header closeButton>
                    <Modal.Title>ATTENTION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    La sélection comporte des camions dont la livraison devait être effectuée antérieurement ou aujourd'hui.<br></br>
                    Confirmez-vous l'envoi?
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary" onClick={this.handleHideIsLessThenToDay}>
                        ANNULER
                    </Button>
                    <Button className="primary" onClick={this.handleConfirmIsLessThenToDay}>
                        CONFIRMER
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>;

        return ret;
    }
}