import { Box, Button, Card, CardContent, FormControlLabel, Radio } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { ScaleLoader } from 'react-spinners';
import '../../utils/Date';
import { SessionStorage } from '../../utils/Storage';
import Utilities from '../../utils/Utilities';
import { MissingVehicleTypeGroupChartComponent } from './components/MissingVehicleTypeGroupChartComponent';
import { ProjectedNeedTabsComponent } from './components/ProjectedNeedTabsComponent';
import { ReservationDetailsComponent } from './components/ReservationDetailsComponent';
import { ReservationListComponent } from './components/ReservationListComponent';
import { TransporterListComponent } from './components/TransporterListComponent';
import { WeeklyRequestedVehiclesByBeneficiaryComponent } from './components/WeeklyRequestedVehiclesByBeneficiaryComponent';
import { AddedVehicleTypeToReservationModel } from './models/AddedVehicleTypeToReservationModel';
import { HistogramDataModel } from './models/HistogramDataModel';
import { ReservedVehicleTypeValueModel } from './models/ReservedVehicleTypeValueModel';
import { TotalNeededModel } from './models/TotalNeededModel';
import { TransporterReservationsModel } from './models/TransporterReservationsModel';
import { VehicleNeededByDayModel } from './models/VehicleNeededByDayModel';
import { WeeklyHistogramDataModel } from './models/WeeklyHistogramDataModel';
import { WeeklyRequestedByVehicleTypeIdModel } from './models/WeeklyRequestedByVehicleTypeIdModel';
import './ReservationStyles.scss';
import { ReservationDetailsToSendRequestArgs } from './services/dataContracts/controller/ReservationDetailsToSendRequestArgs';
import { TransporterReservationRequestArgs } from './services/dataContracts/controller/TransporterReservationRequestArgs';
import { NeededVehicleDetailLightModel } from './services/dataContracts/queryStack/NeededVehicleDetailLightModel';
import { NeededVehiclesWeeklySummaryLightModel } from './services/dataContracts/queryStack/NeededVehiclesWeeklySummaryLightModel';
import { TransporterLightModel } from './services/dataContracts/queryStack/TransporterLightModel';
import { VehicleReservationDetailLightModel } from './services/dataContracts/queryStack/VehicleReservationDetailLightModel';
import { VehicleTypeLightModel } from './services/dataContracts/queryStack/VehicleTypeLightModel';
import { ReservationApiClient } from './services/ReservationApiClient';

interface ReservationViewProperties {
    logisticsUnitIds?: Array<string>
}

interface ReservationViewState {
    neededVehicleDetailArray: Array<NeededVehicleDetailLightModel>,
    isWeekSelected: boolean,
    transportersList: Array<TransporterLightModel>,
    selectedTransporter: string,
    weeklyRequestedByVehicleTypeIdArray: Array<WeeklyRequestedByVehicleTypeIdModel>,
    weeklyTransporterVehicleReservationsDetailsArray: Array<VehicleReservationDetailLightModel>,
    isNightFilterSelected: boolean,
    allVehicleReservations: Array<VehicleReservationDetailLightModel>,
    neededVehiclesWeeklySummaryArray: Array<NeededVehiclesWeeklySummaryLightModel>,
    firstDayInSelectedWeek: Date,
    weekDays: string[],
    histogramData: HistogramDataModel[],
    isOpenVehicleTypeIdlist: boolean,
    vehicleTypesList: Array<VehicleTypeLightModel>,
    addedVehicleTypesToReservation: Array<AddedVehicleTypeToReservationModel>,
    loading: boolean
}

export class ReservationView extends React.Component<ReservationViewProperties, ReservationViewState> {
    _isMounted: boolean;
    today: Date = new Date();
    constructor(props: ReservationViewProperties) {
        super(props);
        this.state = {
            neededVehicleDetailArray: [],
            isWeekSelected: false,
            transportersList: [],
            selectedTransporter: '',
            weeklyRequestedByVehicleTypeIdArray: [],
            weeklyTransporterVehicleReservationsDetailsArray: [],
            allVehicleReservations: [],
            isNightFilterSelected: false,
            neededVehiclesWeeklySummaryArray: [],
            firstDayInSelectedWeek: moment(SessionStorage.ActiveStartDate).startOf('isoWeek').toDate(),
            weekDays: [],
            histogramData: [],
            isOpenVehicleTypeIdlist: false,
            vehicleTypesList: [],
            addedVehicleTypesToReservation: [],
            loading: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const { firstDayInSelectedWeek } = this.state;
        this.loadData(firstDayInSelectedWeek, this.props.logisticsUnitIds);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadData = (firstDayInSelectedWeek: Date, logisticsUnitIds: string[]): void => {
        this.setState({ loading: true });
        ReservationApiClient.LoadData(firstDayInSelectedWeek, logisticsUnitIds)
            .then((res) => {
                if (res.length > 0 && this._isMounted) {
                    const neededVehiclesWeeklySummaryData: NeededVehiclesWeeklySummaryLightModel[] = res[0].data.pageItems;
                    const transporterData: TransporterLightModel[] = res[1].data;
                    const vehiclesReservationDetailsData: VehicleReservationDetailLightModel[] = res[2].data.pageItems;
                    const projectedNeedOfVehiclesByWeekData: NeededVehicleDetailLightModel[] = res[3].data.pageItems;
                    const vehicleTypesData: VehicleTypeLightModel[] = res[4].data;

                    const addedVehicleTypesToReservationArray: AddedVehicleTypeToReservationModel[] = this.getAddedVehicleTypesToReservation(vehiclesReservationDetailsData, projectedNeedOfVehiclesByWeekData);
                    const histogramObject: WeeklyHistogramDataModel = this.fillHistogramData(firstDayInSelectedWeek, this.state.isNightFilterSelected, this.state.allVehicleReservations, projectedNeedOfVehiclesByWeekData);

                    this.setState({
                        neededVehiclesWeeklySummaryArray: neededVehiclesWeeklySummaryData,
                        transportersList: transporterData,
                        weeklyTransporterVehicleReservationsDetailsArray: vehiclesReservationDetailsData.filter(x => x.isNightWork === this.state.isNightFilterSelected),
                        selectedTransporter: '',
                        allVehicleReservations: vehiclesReservationDetailsData,
                        vehicleTypesList: vehicleTypesData,
                        neededVehicleDetailArray: projectedNeedOfVehiclesByWeekData,
                        isWeekSelected: projectedNeedOfVehiclesByWeekData.length > 0 ? true : false,
                        weeklyRequestedByVehicleTypeIdArray: this.groupVehicleTypeIdProjectedNeedByWeek(projectedNeedOfVehiclesByWeekData),
                        addedVehicleTypesToReservation: addedVehicleTypesToReservationArray,
                        weekDays: histogramObject.weekDays,
                        histogramData: histogramObject.histogramData,
                        loading: false
                    });
                }
            });
    }

    getProjectedNeedOfVehiclesByWeek = (fromDate: Date, allVehicleReservations: Array<VehicleReservationDetailLightModel>): void => {
        ReservationApiClient.GetNeededVehiclesWeekDetails(fromDate, this.props.logisticsUnitIds)
            .then(response => {
                if (this._isMounted) {
                    const addedVehicleTypesToReservationArray: AddedVehicleTypeToReservationModel[] = this.getAddedVehicleTypesToReservation(allVehicleReservations, response.data.pageItems);
                    const histogramObject: WeeklyHistogramDataModel = this.fillHistogramData(fromDate, this.state.isNightFilterSelected, this.state.allVehicleReservations, response.data.pageItems);

                    this.setState({
                        neededVehicleDetailArray: response.data.pageItems,
                        isWeekSelected: response.data.pageItems.length > 0 ? true : false,
                        weeklyRequestedByVehicleTypeIdArray: this.groupVehicleTypeIdProjectedNeedByWeek(response.data.pageItems),
                        weekDays: histogramObject.weekDays,
                        histogramData: histogramObject.histogramData,
                        addedVehicleTypesToReservation: addedVehicleTypesToReservationArray
                    });
                }
            });
    }

    getVehiclesReservationsDetails = (transporterId: string, fromDate: Date, isNightFilterSelected: boolean): void => {
        if (transporterId !== this.state.selectedTransporter) {
            ReservationApiClient.GetVehiclesReservationsDetails(fromDate, this.props.logisticsUnitIds)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({
                            weeklyTransporterVehicleReservationsDetailsArray: response.data.pageItems.filter(x => x.transporterId === transporterId && x.isNightWork === isNightFilterSelected),
                            selectedTransporter: transporterId,
                            allVehicleReservations: response.data.pageItems
                        });
                    }
                });
        } else {
            ReservationApiClient.GetVehiclesReservationsDetails(fromDate, this.props.logisticsUnitIds)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({
                            weeklyTransporterVehicleReservationsDetailsArray: response.data.pageItems.filter(x => x.isNightWork === isNightFilterSelected),
                            selectedTransporter: '',
                            allVehicleReservations: response.data.pageItems
                        });
                    }
                });
        }
    }

    groupVehicleTypeIdProjectedNeedByWeek = (neededVehicleDetailArray: Array<NeededVehicleDetailLightModel>): WeeklyRequestedByVehicleTypeIdModel[] => {
        const groupedneededVehicleDetailByWeekArray = Utilities.groupBy(neededVehicleDetailArray, "vehicleTypeId");
        const WeeklyRequestedByVehicleTypeIdArray: Array<WeeklyRequestedByVehicleTypeIdModel> = [];
        groupedneededVehicleDetailByWeekArray.forEach(function (neededVehicleDetailByVehicleTypeIdArray: Array<NeededVehicleDetailLightModel>) {
            const weeklyRequestedByJobForeman = {} as WeeklyRequestedByVehicleTypeIdModel;
            weeklyRequestedByJobForeman.vehicleTypeId = neededVehicleDetailByVehicleTypeIdArray[0].vehicleTypeId;
            weeklyRequestedByJobForeman.vehicleTypeLabel = neededVehicleDetailByVehicleTypeIdArray[0].vehicleTypeLabel;
            const vehicleNeededByDayArray: Array<VehicleNeededByDayModel> = [];
            neededVehicleDetailByVehicleTypeIdArray.forEach(function (ele: NeededVehicleDetailLightModel) {
                const vehicleNeededByDay = {} as VehicleNeededByDayModel;
                vehicleNeededByDay.deliveryDate = ele.deliveryDate;
                vehicleNeededByDay.dayTimePlannedNumberOfVehicles = ele.dayTimePlannedNumberOfVehicles;
                vehicleNeededByDay.nightTimePlannedNumberOfVehicles = ele.nightTimePlannedNumberOfVehicles;
                vehicleNeededByDay.dayTimePlannedNumberOfNoIdentifiedVehicles = ele.dayTimePlannedNumberOfNoIdentifiedVehicles;
                vehicleNeededByDay.nightTimePlannedNumberOfNoIdentifiedVehicles = ele.nightTimePlannedNumberOfNoIdentifiedVehicles;
                vehicleNeededByDayArray.push(vehicleNeededByDay);
            });
            weeklyRequestedByJobForeman.vehiclesNeededByDay = vehicleNeededByDayArray;
            WeeklyRequestedByVehicleTypeIdArray.push(weeklyRequestedByJobForeman);
        });

        return WeeklyRequestedByVehicleTypeIdArray;
    }

    onWeekChange = (event: React.ChangeEvent<{}>, newSelectedValue: Date): void => {
        if (!Date.equals(newSelectedValue, this.state.firstDayInSelectedWeek)) {
            ReservationApiClient.GetVehiclesReservationsDetails(new Date(newSelectedValue), this.props.logisticsUnitIds)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({
                            weeklyTransporterVehicleReservationsDetailsArray: this.state.selectedTransporter === '' ? response.data.pageItems.filter(x => x.isNightWork === this.state.isNightFilterSelected) : response.data.pageItems.filter(x => x.transporterId === this.state.selectedTransporter && x.isNightWork === this.state.isNightFilterSelected),
                            allVehicleReservations: response.data.pageItems,
                            firstDayInSelectedWeek: new Date(newSelectedValue)
                        });

                        this.getProjectedNeedOfVehiclesByWeek(new Date(newSelectedValue), response.data.pageItems);
                    }
                });
        }
    }

    onSelectTransporterChange = (transporterId: string): void => {
        const { firstDayInSelectedWeek } = this.state;
        this.getVehiclesReservationsDetails(transporterId, firstDayInSelectedWeek, this.state.isNightFilterSelected);
    }

    onReservationBlur = (from: Date, transporterId: string, vehicleTypeId: string, event: React.FocusEvent<HTMLInputElement>): void => {
        const { firstDayInSelectedWeek, isNightFilterSelected } = this.state;
        const requestArgs: TransporterReservationRequestArgs = {
            fromDate: from,
            transporterId: transporterId,
            vehicleTypeId: vehicleTypeId,
            vehicleNumbers: Number(event.target.value),
            isNightWork: isNightFilterSelected
        }

        if (event.target.value === "0") {
            ReservationApiClient.UnsetReservedVehicles(requestArgs)
                .then(response => {
                    ReservationApiClient.GetVehiclesReservationsDetails(firstDayInSelectedWeek, this.props.logisticsUnitIds)
                        .then(response => {
                            if (this._isMounted) {
                                const histogramObject: WeeklyHistogramDataModel = this.fillHistogramData(firstDayInSelectedWeek, this.state.isNightFilterSelected, response.data.pageItems, this.state.neededVehicleDetailArray);

                                this.setState({
                                    weeklyTransporterVehicleReservationsDetailsArray: response.data.pageItems.filter(x => x.transporterId === this.state.selectedTransporter && x.isNightWork === isNightFilterSelected),
                                    allVehicleReservations: response.data.pageItems,
                                    weekDays: histogramObject.weekDays,
                                    histogramData: histogramObject.histogramData
                                });
                            }
                        });
                });
        } else {
            ReservationApiClient.SetReservedVehicles(requestArgs)
                .then(response => {
                    ReservationApiClient.GetVehiclesReservationsDetails(firstDayInSelectedWeek, this.props.logisticsUnitIds)
                        .then(response => {
                            if (this._isMounted) {
                                const histogramObject: WeeklyHistogramDataModel = this.fillHistogramData(firstDayInSelectedWeek, this.state.isNightFilterSelected, response.data.pageItems, this.state.neededVehicleDetailArray);

                                this.setState({
                                    weeklyTransporterVehicleReservationsDetailsArray: response.data.pageItems.filter(x => x.transporterId === this.state.selectedTransporter && x.isNightWork === isNightFilterSelected),
                                    allVehicleReservations: response.data.pageItems,
                                    weekDays: histogramObject.weekDays,
                                    histogramData: histogramObject.histogramData
                                });
                            }
                        });
                });
        }
    }

    handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { firstDayInSelectedWeek } = this.state;
        const isNightFilterSelected: boolean = event.target.value === "day" ? false : true
        ReservationApiClient.GetVehiclesReservationsDetails(firstDayInSelectedWeek, this.props.logisticsUnitIds)
            .then(response => {
                if (this._isMounted) {
                    const histogramObject: WeeklyHistogramDataModel = this.fillHistogramData(firstDayInSelectedWeek, isNightFilterSelected, response.data.pageItems, this.state.neededVehicleDetailArray);

                    this.setState({
                        weeklyTransporterVehicleReservationsDetailsArray: this.state.selectedTransporter === '' ? response.data.pageItems.filter(x => x.isNightWork === isNightFilterSelected) : response.data.pageItems.filter(x => x.transporterId === this.state.selectedTransporter && x.isNightWork === isNightFilterSelected),
                        isNightFilterSelected: isNightFilterSelected,
                        allVehicleReservations: response.data.pageItems,
                        weekDays: histogramObject.weekDays,
                        histogramData: histogramObject.histogramData
                    });
                }
            });
    }

    onConfimReservationClick = (): void => {
        const { selectedTransporter, firstDayInSelectedWeek, isNightFilterSelected } = this.state;

        const requestArgs: ReservationDetailsToSendRequestArgs = {
            transporterId: selectedTransporter,
            firstDayInWeek: firstDayInSelectedWeek,
            confirmationDate: new Date(Date.now())
        };

        ReservationApiClient.ConfirmTransporterReservation(requestArgs)
            .then(response => {
                ReservationApiClient.GetVehiclesReservationsDetails(firstDayInSelectedWeek, this.props.logisticsUnitIds)
                    .then(response => {
                        if (this._isMounted) {
                            this.setState({
                                weeklyTransporterVehicleReservationsDetailsArray: response.data.pageItems.filter(x => x.transporterId === selectedTransporter && x.isNightWork === isNightFilterSelected),
                                allVehicleReservations: response.data.pageItems
                            });
                        }
                    });
            });

    }

    fillHistogramData = (selectedDate: Date, isNightFilterSelected: boolean, allVehicleReservations: VehicleReservationDetailLightModel[], neededVehicleDetailArray: NeededVehicleDetailLightModel[]): WeeklyHistogramDataModel => {
        const family6x4: number[] = [];
        const family8x4: number[] = [];
        const familySemi: number[] = [];
        const familyTracteurs: number[] = [];
        const familyAutres: number[] = [];
        const histogramData: HistogramDataModel[] = [];

        const weekDays: string[] = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
        for (let i = 0; i < 7; i++) {
            const date: Date = moment(selectedDate).add(i, 'days').toDate();
            weekDays[i] += " " + date.getDate();
            family6x4.push(this.getTotalRestNeededOfVehiclesByDateAndVehicleFamily(date, '6x4', isNightFilterSelected, allVehicleReservations, neededVehicleDetailArray));
            family8x4.push(this.getTotalRestNeededOfVehiclesByDateAndVehicleFamily(date, '8x4', isNightFilterSelected, allVehicleReservations, neededVehicleDetailArray));
            familySemi.push(this.getTotalRestNeededOfVehiclesByDateAndVehicleFamily(date, 'Semi', isNightFilterSelected, allVehicleReservations, neededVehicleDetailArray));
            familyTracteurs.push(this.getTotalRestNeededOfVehiclesByDateAndVehicleFamily(date, 'Tracteurs', isNightFilterSelected, allVehicleReservations, neededVehicleDetailArray));
            familyAutres.push(this.getTotalRestNeededOfVehiclesByDateAndVehicleFamily(date, 'Autres', isNightFilterSelected, allVehicleReservations, neededVehicleDetailArray));
        }

        histogramData.push({
            family: '6x4',
            weeklyNeededNumberOfVehiclesArray: family6x4
        });

        histogramData.push({
            family: '8x4',
            weeklyNeededNumberOfVehiclesArray: family8x4
        });

        histogramData.push({
            family: 'Semi',
            weeklyNeededNumberOfVehiclesArray: familySemi
        });

        histogramData.push({
            family: 'Tracteurs',
            weeklyNeededNumberOfVehiclesArray: familyTracteurs
        });

        histogramData.push({
            family: 'Autres',
            weeklyNeededNumberOfVehiclesArray: familyAutres
        });

        const weeklyHistogramData: WeeklyHistogramDataModel = {
            weekDays: weekDays,
            histogramData: histogramData
        };

        return weeklyHistogramData;
    }

    getTotalRestNeededOfVehiclesByDateAndVehicleFamily = (date: Date, vehicleFamily: string, isNightFilterSelected: boolean, allVehicleReservations: VehicleReservationDetailLightModel[], neededVehicleDetailArray: NeededVehicleDetailLightModel[]): number => {
        const neededVehicleDetailByVehicleFamilyArray: NeededVehicleDetailLightModel[] = neededVehicleDetailArray.filter(x => x.vehicleFamily === vehicleFamily);
        const allVehicleReservationsByVehicleFamilyArray: VehicleReservationDetailLightModel[] = allVehicleReservations.filter(x => x.vehicleFamily === vehicleFamily && x.isNightWork === isNightFilterSelected);
        let totalNeeded = 0;
        let totalNeededOfNoIdentifiedVehicles = 0;

        neededVehicleDetailByVehicleFamilyArray.forEach((e) => {
            if (Date.equals(e.deliveryDate, date)) {
                if (!isNightFilterSelected) {
                    totalNeeded += e.dayTimePlannedNumberOfVehicles;
                    totalNeededOfNoIdentifiedVehicles += e.dayTimePlannedNumberOfNoIdentifiedVehicles;
                } else {
                    totalNeeded += e.nightTimePlannedNumberOfVehicles;
                    totalNeededOfNoIdentifiedVehicles += e.nightTimePlannedNumberOfNoIdentifiedVehicles;
                }
            }
        });

        let totalReserved = 0;
        allVehicleReservationsByVehicleFamilyArray.forEach((e) => {
            if (Date.equals(e.date, date)) {
                totalReserved += e.reservedNumberOfVehicles
            }
        });

        return totalNeededOfNoIdentifiedVehicles - Math.max(totalReserved - (totalNeeded - totalNeededOfNoIdentifiedVehicles), 0);
    }

    handleOpenCloseVehicleTypeIdlist = (): void => {
        this.setState({
            isOpenVehicleTypeIdlist: !this.state.isOpenVehicleTypeIdlist
        });
    }

    onChangeReservationValue = (event: React.ChangeEvent<HTMLInputElement>, vehicleTypeId: string, date: Date): void => {
        const list: VehicleReservationDetailLightModel[] = this.state.weeklyTransporterVehicleReservationsDetailsArray;
        const index: number = list.findIndex(x => x.vehicleTypeId === vehicleTypeId && new Date(x.date).valueOf() === new Date(date).valueOf());
        let item: VehicleReservationDetailLightModel;
        if (index != -1) {
            item = list[index];
        }
        if (item !== undefined) {
            item.reservedNumberOfVehicles = Number(event.target.value);
            list[index] = item;
        } else {
            const transporter: TransporterLightModel = this.state.transportersList.find(x => x.transporterId == this.state.selectedTransporter);

            const newItem = {} as VehicleReservationDetailLightModel
            newItem.vehicleTypeId = vehicleTypeId;
            newItem.date = date;
            newItem.transporterId = this.state.selectedTransporter;
            newItem.transporterName = transporter?.transporterName;
            newItem.reservedNumberOfVehicles = Number(event.target.value);

            list.push(newItem);
        }

        this.setState({ weeklyTransporterVehicleReservationsDetailsArray: list });
    }

    getReservedTransporterVehiclesNumber = (date: Date, vehicleTypeId: string): number => {
        let value = 0;
        this.state.weeklyTransporterVehicleReservationsDetailsArray.filter(x => x.vehicleTypeId === vehicleTypeId).forEach((e) => {
            if (Date.equals(date, e.date)) {
                value += e.reservedNumberOfVehicles;
            }
        });

        return Number(value);
    }

    getTotalNeededByDate = (date: Date): TotalNeededModel => {
        let totalNeededByDateNumber = 0;
        let totalNoIdentifiedNeededByDateNumber = 0;

        this.state.neededVehicleDetailArray.forEach((e: NeededVehicleDetailLightModel) => {
            if (Date.equals(e.deliveryDate, date)) {
                if (!this.state.isNightFilterSelected) {
                    totalNeededByDateNumber += e.dayTimePlannedNumberOfVehicles;
                    totalNoIdentifiedNeededByDateNumber += e.dayTimePlannedNumberOfNoIdentifiedVehicles;
                } else {
                    totalNeededByDateNumber += e.nightTimePlannedNumberOfVehicles;
                    totalNoIdentifiedNeededByDateNumber += e.nightTimePlannedNumberOfNoIdentifiedVehicles;
                }
            }
        });

        const totalNeeded: TotalNeededModel = {
            totalNeededByDateNumber: totalNeededByDateNumber,
            totalNoIdentifiedNeededByDateNumber: totalNoIdentifiedNeededByDateNumber
        }

        return totalNeeded;
    }

    getAllReservedVehiclesNumber = (date: Date, vehicleTypeId: string): number => {
        let allReservedVehiclesNumber = 0;
        this.state.allVehicleReservations.filter(x => x.vehicleTypeId === vehicleTypeId && x.isNightWork === this.state.isNightFilterSelected).forEach((e) => {
            if (Date.equals(e.date, date)) {
                allReservedVehiclesNumber += e.reservedNumberOfVehicles;
            }
        });

        return Number(allReservedVehiclesNumber);
    }

    getTotalReservedByDate = (date: Date): number => {
        let value = 0;
        this.state.weeklyTransporterVehicleReservationsDetailsArray.forEach((e: VehicleReservationDetailLightModel) => {
            if (Date.equals(e.date, date)) {
                value += e.reservedNumberOfVehicles;
            }
        });
        return Number(value);
    }

    getTotalReservedByDateWithoutSelectedTransporter = (date: Date): number => {
        let value = 0;
        this.state.allVehicleReservations.filter(x => x.isNightWork === this.state.isNightFilterSelected).forEach((e) => {
            if (Date.equals(e.date, date)) {
                value += e.reservedNumberOfVehicles;
            }
        });

        return Number(value);
    }

    getAddedVehicleTypesToReservation = (allVehicleReservations: VehicleReservationDetailLightModel[], neededVehicleDetailArray: NeededVehicleDetailLightModel[]): AddedVehicleTypeToReservationModel[] => {
        const addedVehicleTypesToReservationArray: AddedVehicleTypeToReservationModel[] = [];
        allVehicleReservations.forEach((element: VehicleReservationDetailLightModel) => {
            if (!(neededVehicleDetailArray.filter(x => x.vehicleTypeId === element.vehicleTypeId).length > 0)) {
                if (!addedVehicleTypesToReservationArray.find(x => x.vehicleTypeId === element.vehicleTypeId)) {
                    const addedVehicleTypeToReservationObj = {} as AddedVehicleTypeToReservationModel;
                    addedVehicleTypeToReservationObj.vehicleTypeId = element.vehicleTypeId;
                    addedVehicleTypeToReservationObj.vehicleTypeLabel = element.vehicleTypeLabel;
                    const transporterReservationsArray: TransporterReservationsModel[] = [];
                    const vehicleReservationsGroupedByTransporter = Utilities.groupBy(allVehicleReservations.filter(x => x.vehicleTypeId === element.vehicleTypeId), "transporterId");
                    vehicleReservationsGroupedByTransporter.forEach((vehicleReservationDetailsArray: VehicleReservationDetailLightModel[]) => {
                        const transporterReservationsObject = {} as TransporterReservationsModel;
                        transporterReservationsObject.transporter = vehicleReservationDetailsArray[0].transporterId;
                        const reservedVehicleTypeValueArray: ReservedVehicleTypeValueModel[] = [];
                        vehicleReservationDetailsArray.forEach((vehicleReservationDetail: VehicleReservationDetailLightModel) => {
                            const ReservedVehicleTypeValue = {} as ReservedVehicleTypeValueModel;
                            ReservedVehicleTypeValue.value = vehicleReservationDetail.reservedNumberOfVehicles;
                            ReservedVehicleTypeValue.date = vehicleReservationDetail.date;
                            ReservedVehicleTypeValue.isNightWork = vehicleReservationDetail.isNightWork;
                            reservedVehicleTypeValueArray.push(ReservedVehicleTypeValue);
                        });
                        transporterReservationsObject.reservationsData = reservedVehicleTypeValueArray;
                        transporterReservationsArray.push(transporterReservationsObject);
                    });
                    addedVehicleTypeToReservationObj.transporterReservationsArray = transporterReservationsArray;
                    addedVehicleTypesToReservationArray.push(addedVehicleTypeToReservationObj);
                }
            }
        });

        return addedVehicleTypesToReservationArray;
    }

    onChangeAddedReservationValue = (event: React.ChangeEvent<HTMLInputElement>, vehicleTypeId: string, date: Date): void => {
        const addedVehicleTypesToReservationArray: AddedVehicleTypeToReservationModel[] = this.state.addedVehicleTypesToReservation;
        const addedVehicleTypesToReservationArrayByVehicleTypeItem: AddedVehicleTypeToReservationModel = addedVehicleTypesToReservationArray.find(x => x.vehicleTypeId === vehicleTypeId);
        const addedVehicleTypesToReservationArrayByVehicleTypeIndex: number = addedVehicleTypesToReservationArray.findIndex(x => x.vehicleTypeId === vehicleTypeId);

        const transporterReservationsArray: TransporterReservationsModel[] = addedVehicleTypesToReservationArrayByVehicleTypeItem.transporterReservationsArray;
        const dataTransporter: TransporterReservationsModel = transporterReservationsArray.find(x => x.transporter === this.state.selectedTransporter);
        const dataTransporterIndex: number = transporterReservationsArray.findIndex(x => x.transporter === this.state.selectedTransporter);

        if (dataTransporter !== undefined) {
            const reservationsData: ReservedVehicleTypeValueModel[] = dataTransporter.reservationsData;
            const index: number = reservationsData.findIndex(x => new Date(x.date).valueOf() === new Date(date).valueOf() && x.isNightWork === this.state.isNightFilterSelected);
            const item: ReservedVehicleTypeValueModel = reservationsData.find(x => new Date(x.date).valueOf() === new Date(date).valueOf() && x.isNightWork === this.state.isNightFilterSelected);
            if (item !== undefined) {
                item.value = Number(event.target.value);
                reservationsData[index] = item;
            } else {
                const newItem = {} as ReservedVehicleTypeValueModel;
                newItem.date = date;
                newItem.value = Number(event.target.value);
                newItem.isNightWork = this.state.isNightFilterSelected;
                reservationsData.push(newItem);
            }
            dataTransporter.reservationsData = reservationsData;
            transporterReservationsArray[dataTransporterIndex] = dataTransporter
        } else {
            const newTransporterReservations = {} as TransporterReservationsModel;
            newTransporterReservations.transporter = this.state.selectedTransporter;

            const reservedVehicleTypeValueArray: ReservedVehicleTypeValueModel[] = [];
            const reservedVehicleTypeValue = {} as ReservedVehicleTypeValueModel;
            reservedVehicleTypeValue.date = date;
            reservedVehicleTypeValue.value = Number(event.target.value);
            reservedVehicleTypeValue.isNightWork = this.state.isNightFilterSelected;
            reservedVehicleTypeValueArray.push(reservedVehicleTypeValue);

            newTransporterReservations.reservationsData = reservedVehicleTypeValueArray;
            transporterReservationsArray.push(newTransporterReservations);
        }
        addedVehicleTypesToReservationArray[addedVehicleTypesToReservationArrayByVehicleTypeIndex] = addedVehicleTypesToReservationArrayByVehicleTypeItem;

        this.setState({
            addedVehicleTypesToReservation: addedVehicleTypesToReservationArray
        });
    }

    render() {
        const {
            isWeekSelected,
            transportersList,
            selectedTransporter,
            weeklyRequestedByVehicleTypeIdArray,
            weeklyTransporterVehicleReservationsDetailsArray,
            isNightFilterSelected,
            allVehicleReservations,
            neededVehicleDetailArray,
            neededVehiclesWeeklySummaryArray,
            firstDayInSelectedWeek,
            weekDays,
            histogramData,
            isOpenVehicleTypeIdlist,
            vehicleTypesList,
            addedVehicleTypesToReservation
        } = this.state;

        const disabledReservationButton: boolean = (selectedTransporter === '' || weeklyTransporterVehicleReservationsDetailsArray.length === 0);

        let rendering: JSX.Element = null;

        if (this.state.loading) {
            rendering = <Box className="sweet-loading main-spinner">
                <ScaleLoader
                    width={5}
                    height={20}
                    radius={50}
                    color={'#000000'}
                    loading={this.state.loading}
                />
            </Box>
        } else {
            rendering = <Box display="flex" flexWrap="nowrap" className="reservation-content" flexDirection="column" p={1}>
                <Box pb={2} height="50%" >
                    <Card className="section">
                        <CardContent className="full-height full-width">
                            <Box display="flex" flexWrap="nowrap" flexDirection="column" className="full-height full-width">
                                <Box display="flex" flexWrap="wrap" flexDirection="row" className="full-width">
                                    <Box className="title align-center" mb={isWeekSelected ? "0" : "10px"}>
                                        Camions manquants
                                    </Box>
                                    {isWeekSelected &&
                                        <Box>
                                            <FormControlLabel className="filter-item" value="day" control={<Radio checked={!isNightFilterSelected} onChange={(event) => this.handleChangeFilter(event)} value="day" color="default" />} labelPlacement="end" label="Jour" />
                                            <FormControlLabel className="filter-item" value="night" control={<Radio checked={isNightFilterSelected} onChange={(event) => this.handleChangeFilter(event)} value="night" color="default" />} labelPlacement="end" label="Nuit" />
                                        </Box>
                                    }
                                </Box>
                                <ProjectedNeedTabsComponent
                                    neededVehiclesWeeklySummaryArray={neededVehiclesWeeklySummaryArray}
                                    firstDayInSelectedWeek={firstDayInSelectedWeek}
                                    onWeekChange={this.onWeekChange}
                                />
                                {isWeekSelected &&
                                    <Box display="flex" flexDirection="row" alignItems="flex-start" className="projected-need-details">
                                        <Box className="weekly-need-by-chef full-height" display="flex" flexWrap="nowrap" flexDirection="column" alignItems="flex-start">
                                            <Box className="title" mb={'10px'}>
                                                Total demandé
                                            </Box>
                                            <Box className="jobforeman-weekly-requested">
                                                <WeeklyRequestedVehiclesByBeneficiaryComponent neededVehicleDetailArray={neededVehicleDetailArray} />
                                            </Box>
                                        </Box>
                                        <Box className="full-height" display="flex" flexWrap="nowrap" flexDirection="column" alignItems="flex-start" width="75%">
                                            <Box className="title" mb={'10px'}>
                                                Reste à trouver
                                            </Box>
                                            <Box className="full-width full-height">
                                                <MissingVehicleTypeGroupChartComponent
                                                    weekDays={weekDays}
                                                    histogramData={histogramData}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                {isWeekSelected &&
                    <Box height="50%">
                        <Card className="section">
                            <CardContent className="full-height full-width">
                                <Box display="flex" flexWrap="nowrap" flexDirection="column" className="full-height full-width">
                                    <Box className="title" mb={'10px'}>
                                        Planning de réservation
                                    </Box>
                                    <Box display="flex" flexDirection="row" alignItems="flex-start" flexWrap="wrap" className="full-width" style={{ 'height': '90%' }}>
                                        <Box display="flex" flexDirection="column" alignItems="flex-start" className="transporter-list full-height">
                                            <TransporterListComponent transportersList={transportersList}
                                                onSelectTransporterChange={this.onSelectTransporterChange}
                                                selectedTransporter={selectedTransporter}
                                                weeklyTransporterVehicleReservationsDetailsArray={allVehicleReservations}
                                                firstDayInSelectedWeek={firstDayInSelectedWeek}
                                                isNightFilterSelected={isNightFilterSelected}
                                            />
                                        </Box>
                                        <Box className="full-height reservation-list">
                                            <ReservationListComponent weeklyRequestedByVehicleTypeIdArray={weeklyRequestedByVehicleTypeIdArray}
                                                firstDayInSelectedWeek={firstDayInSelectedWeek}
                                                selectedTransporter={selectedTransporter}
                                                onReservationBlur={this.onReservationBlur}
                                                weeklyTransporterVehicleReservationsDetailsArray={
                                                    selectedTransporter !== '' ?
                                                        weeklyTransporterVehicleReservationsDetailsArray.filter(x => x.transporterId === selectedTransporter && x.isNightWork === isNightFilterSelected)
                                                        :
                                                        weeklyTransporterVehicleReservationsDetailsArray}
                                                isNightFilterSelected={isNightFilterSelected}
                                                allVehicleReservations={allVehicleReservations}
                                                neededVehicleDetailByWeekArray={neededVehicleDetailArray}
                                                isOpenVehicleTypeIdlist={isOpenVehicleTypeIdlist}
                                                vehicleTypesList={vehicleTypesList}
                                                handleOpenCloseVehicleTypeIdlist={this.handleOpenCloseVehicleTypeIdlist}
                                                onChangeReservationValue={this.onChangeReservationValue}
                                                getReservedTransporterVehiclesNumber={this.getReservedTransporterVehiclesNumber}
                                                getTotalNeededByDate={this.getTotalNeededByDate}
                                                getAllReservedVehiclesNumber={this.getAllReservedVehiclesNumber}
                                                getTotalReservedByDate={this.getTotalReservedByDate}
                                                getTotalReservedByDateWithoutSelectedTransporter={this.getTotalReservedByDateWithoutSelectedTransporter}
                                                addedVehicleTypesToReservation={addedVehicleTypesToReservation}
                                                onChangeAddedReservationValue={this.onChangeAddedReservationValue} />
                                            <Box display="flex" flexWrap="wrap" flexDirection="row" mt={'10px'}>
                                                <Box className="blue-label" flexGrow={1}>Veuillez renseigner le nombre de camions que chaque transporteur peut mettre à disposition</Box>
                                                <Box>
                                                    <Button variant="contained" color="primary" disabled={disabledReservationButton} onClick={() => this.onConfimReservationClick()}>
                                                        Envoi Réservation
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="full-height reservation-details-list">
                                            <ReservationDetailsComponent weeklyTransporterVehicleReservationsDetailsArray={weeklyTransporterVehicleReservationsDetailsArray} />
                                        </Box>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                }
            </Box>
        }

        return rendering;
    }
}