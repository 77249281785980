import { faMapMarkerAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import { Grid, GridCellProps, GridColumn as Column, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridRowProps } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import { EditableHeaderComponent } from 'src/shared/components/Common/EditableHeaderComponent';
import { PlanningVehicleStatusComponent } from '../../../shared/components/PlanningVehicleStatus/PlanningVehicleStatusComponent';
import { AppModule, LocalStorage } from '../../../utils/Storage';
import { CustomCheckBoxCell } from '../components/CustomCheckBoxCell';
import { GroupLabelFilter } from '../models/GroupLabelFilter';
import { TransportSaleLightModelExtended } from '../models/TransportSaleLightModelExtended';
import { TransportSaleEntityLightModel } from '../services/dataContracts/queryStack/TransportSaleEntityLightModel';
import { CustomPricesCellComponent } from './CustomPricesCellComponent';
import { InvoiceCellComponent } from './InvoiceCellComponent';
import PreInvoiceCommentsCell from './PreInvoiceCommentsCell';

interface TransportSalesComponentProps {
    planningVehiclesList: Array<TransportSaleLightModelExtended>,
    path: string,
    role: string,
    enableEdit: boolean,
    selectedAll?: boolean,
    gridHeight: number,
    gridPageSize: number,
    groupLabelFilter: GroupLabelFilter,
    rowRender: (trElement: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps) => React.ReactElement,
    cellRender: (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps) => React.ReactElement,
    updateAllTransportSells: (checked: boolean) => void
    updateTransportSellChoices: (checked: boolean, businessId: string, entity: string, planningId?: number) => void,
    refreshComputedCells: (preInvoice: TransportSaleLightModelExtended) => void,
    handleSearchPreInvoices: (searchText: string) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const TransportSalesGridName = 'transportSales';
const ModuleKey = AppModule.TransportSales;

export const TransportSalesComponent = (props: TransportSalesComponentProps) => {
    const gridRef: React.RefObject<any> = React.useRef(null);

    const [skip, setSkip] = useState<number>(0);

    const forceUpdate = useForceUpdate();

    const getVehicleGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, TransportSalesGridName, fieldName, defaultIndex);
    }

    const getVehicleGridWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, TransportSalesGridName, fieldName, defaultWidth);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            TransportSalesGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, TransportSalesGridName, event.columns);
        forceUpdate();
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip ? event.page.skip : 0);
    }

    useEffect(() => {
        const firstTransportFlowSelected = planningVehiclesList.findIndex(x => x.checked === true && x.entity === TransportSaleEntityLightModel.transportFlow);

        if(firstTransportFlowSelected !== -1) {
            // ODA https://github.com/telerik/kendo-react/issues/643#issuecomment-795134046
            const vs = (gridRef.current as any).vs;
            const vsContainer = vs.container;
            // total: nombre total d'éléments
            const step = vsContainer.scrollHeight/vs.total;

            vsContainer.scroll(0, firstTransportFlowSelected * step);
        }
    }, []);

    const { planningVehiclesList, enableEdit, rowRender: _rowRender, cellRender: _cellRender, updateTransportSellChoices, updateAllTransportSells, selectedAll, refreshComputedCells, gridHeight, gridPageSize } = props;

    return (
        <Box id="vehicle-grid-id" className="vehicles-flows-grid">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Grid
                        ref={gridRef}
                        className="transport-sale"
                        data={planningVehiclesList.slice(skip, skip + gridPageSize)}
                        sortable={false}
                        reorderable
                        resizable
                        cellRender={_cellRender}
                        rowRender={_rowRender}
                        selectedField="checked"
                        onColumnResize={onResizeHandler}
                        onColumnReorder={(e) => onReorderHandler(e)}
                        editField="inEdit"
                        scrollable="virtual"
                        skip={skip}
                        pageSize={gridPageSize}
                        total={planningVehiclesList.length}
                        onPageChange={pageChange}
                        rowHeight={40}
                        style={{ height: gridHeight }}
                    >
                        <Column field="checked" orderIndex={getVehicleGridOrderIndexColumn("checked", 0)} width={getVehicleGridWidth("checked", 70)} title="A synchroniser"
                            headerCell={() =>
                                <CustomCheckBoxCell
                                    field="selectedAll"
                                    selectedAll={selectedAll}
                                    tooltipMessage="Sélectionner/Désélectionner tous les Flux de tous les Besoins"
                                    updateElements={updateAllTransportSells}
                                />
                            }
                            cell={(props: GridCellProps) =>
                                <CustomCheckBoxCell
                                    dataItem={props.dataItem}
                                    field={props.field}
                                    tooltipMessage={props.dataItem.entity == TransportSaleEntityLightModel.transportRequest
                                        ? "Sélectionner/Désélectionner tous les Flux du Besoin" : "Sélectionner/Désélectionner le Flux"}
                                    updateElements={updateTransportSellChoices}
                                />}
                            headerSelectionValue={planningVehiclesList.findIndex((dataItem: TransportSaleLightModelExtended) => !dataItem.checked) === -1} />
                        <Column field="statusValue" orderIndex={getVehicleGridOrderIndexColumn("statusValue", 1)} width={getVehicleGridWidth("statusValue", 50)} title="Statut" editable={false}
                            cell={(properties: GridCellProps) => {
                                if (properties.dataItem.planningVehicleStatus?.isCanceled)
                                    return <td>
                                        <PlanningVehicleStatusComponent
                                            isCanceled={properties.dataItem.planningVehicleStatus?.isCanceled}
                                            transporterConfirmationDate={properties.dataItem.planningVehicleStatus?.transporterConfirmationDate}
                                            transporterOrderId={properties.dataItem.planningVehicleStatus?.transporterOrderId}
                                            transporterIsInternal={properties.dataItem.planningVehicleStatus?.transporterIsInternal}
                                            cancellationReason={properties.dataItem.planningVehicleStatus?.cancellationReason}
                                            cancellationRemarks={properties.dataItem.planningVehicleStatus?.cancellationRemarks}
                                            purchaseIsCanceled={properties.dataItem.planningVehicleStatus?.purchaseIsCanceled}
                                            saleIsCanceled={properties.dataItem.planningVehicleStatus?.saleIsCanceled}
                                            cancellationOrderSentDate={properties.dataItem.planningVehicleStatus?.cancellationOrderSentDate}
                                            cancellationOrderConfirmationDate={properties.dataItem.planningVehicleStatus?.cancellationOrderConfirmationDate}
                                        />
                                    </td>;

                                return <td></td>;
                            }}
                        />
                        <Column field="isNightWork" orderIndex={getVehicleGridOrderIndexColumn("isNightWork", 2)} width={getVehicleGridWidth("isNightWork", 50)} title="J/N" editable={false}
                            cell={(properties: GridCellProps) => {
                                const isNightWork = properties.dataItem.isNightWork;
                                return <td>
                                    {properties.dataItem.entity !== TransportSaleEntityLightModel.transportRequest ?
                                        isNightWork === true
                                            ? <div className='night-work'></div>
                                            : <div className='day-work'></div>
                                        : ""}
                                </td>;
                            }}
                        />
                        <Column field="businessId" orderIndex={getVehicleGridOrderIndexColumn("businessId", 3)} width={getVehicleGridWidth("businessId", 150)}
                            title={`${props.groupLabelFilter === GroupLabelFilter.TransportRequestBusinessId
                                ? "Besoin"
                                : props.groupLabelFilter === GroupLabelFilter.ReceiverSiteResolutionLabel
                                    ? "Arrivée"
                                    : props.groupLabelFilter === GroupLabelFilter.PayerJobSiteMdmId
                                        ? "Chantier payeur"
                                        : "Payeur"} / Flux / Camion`} editable={false}
                            cell={(p: GridCellProps) => <InvoiceCellComponent {...p} field="businessId" />}
                        />
                        <Column field="planningDate" orderIndex={getVehicleGridOrderIndexColumn("planningDate", 4)} title="Date" format="{0:dd-MM-yyyy}" editable={false} width={getVehicleGridWidth("planningDate", 100)} />
                        <Column field="sentDate"
                            cell={(p: GridCellProps) => <InvoiceCellComponent {...p} field="sentDate" />}
                            orderIndex={getVehicleGridOrderIndexColumn("sentDate", 5)} title="Date intégration" editable={false} width={getVehicleGridWidth("sentDate", 120)} format="{0:dd-MM-yyyy}" />
                        <Column field="flowPriority" orderIndex={getVehicleGridOrderIndexColumn("flowPriority", 6)} width={getVehicleGridWidth("flowPriority", 150)} title="Prestation" editable={false} />
                        <Column field="regularizationNumber" orderIndex={getVehicleGridOrderIndexColumn("regularizationNumber", 7)} width={getVehicleGridWidth("regularizationNumber", 30)} title=" " editable={false}
                            cell={(p: GridCellProps) => {
                                return <td>
                                    {(p.dataItem.entity == TransportSaleEntityLightModel.transportFlow && p.dataItem.regularizationNumber) ?
                                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className="icon-regularization">
                                            R
                                        </Box>
                                        : ''}
                                </td>
                            }}
                        />
                        <Column field="notifications" orderIndex={getVehicleGridOrderIndexColumn("notifications", 8)} width={getVehicleGridWidth("notifications", 70)} title=" " editable={false}
                            cell={(p: GridCellProps) => {
                                return <td className="tf-has-diff-from-request">
                                    <Box display="flex" flexDirection="row">
                                        <Box width="33%">
                                            {!p.dataItem.regularizationNumber && p.dataItem.hasDifferentDeliveriesWithSameForeman ?
                                                <Tooltip title="Livraisons pour un même bénéficiaire" placement="bottom">
                                                    <Box className="icon-notification icon-notification-for-user" pr={1} onClick={() => props.handleSearchPreInvoices(p.dataItem.transportFlow.beneficiaryName)}>
                                                        <FontAwesomeIcon size="lg" icon={faUser} />
                                                    </Box>
                                                </Tooltip>
                                                : ''}
                                        </Box>
                                        <Box width="33%">
                                            {!p.dataItem.regularizationNumber && p.dataItem.hasDifferentDeliveriesWithSameReceiverSite ?
                                                <Tooltip title="Livraisons sur un même chantier" placement="bottom">
                                                    <Box className={`icon-notification-for-marker same-receiver-site ${p.dataItem.licencePlate ? 'icon-notification' : ''}`} pr={1} onClick={() => p.dataItem.licencePlate ? props.handleSearchPreInvoices(p.dataItem.licencePlate) : {}}>
                                                        <FontAwesomeIcon size="lg" icon={faMapMarkerAlt} />
                                                    </Box>
                                                </Tooltip>
                                                : ''}
                                        </Box>
                                        <Box width="33%">
                                            {!p.dataItem.regularizationNumber && p.dataItem.hasDifferentDeliveriesWithDifferentReceiverSites ?
                                                <Tooltip title="Livraisons sur plusieurs chantiers" placement="bottom">
                                                    <Box className={`icon-notification-for-marker different-receiver-sites ${p.dataItem.licencePlate ? 'icon-notification' : ''}`} pr={1} onClick={() => p.dataItem.licencePlate ? props.handleSearchPreInvoices(p.dataItem.licencePlate) : {}}>
                                                        <FontAwesomeIcon size="lg" icon={faMapMarkerAlt} />
                                                    </Box>
                                                </Tooltip>
                                                : ''}
                                        </Box>
                                    </Box>
                                </td>
                            }}
                        />
                        <Column field="transporter" orderIndex={getVehicleGridOrderIndexColumn("transporter", 9)} width={getVehicleGridWidth("transporter", 150)} title="Transporteur" editable={false} />
                        <Column field="vehicleTypeLabel" orderIndex={getVehicleGridOrderIndexColumn("vehicleTypeLabel", 10)} width={getVehicleGridWidth("vehicleTypeLabel", 100)} title="Type camion" editable={false} />
                        <Column field="transportSellPriceKind" orderIndex={getVehicleGridOrderIndexColumn("transportSellPriceKind", 11)} width={getVehicleGridWidth("transportSellPriceKind", 100)} title="Type tarification" editable={false} />
                        <Column field="plannedQuantity"
                            cell={(p: GridCellProps) => {
                                return <td className={p.dataItem.transportSellPriceKindBase == 'PerTon' ? "" : "field-disabled"}>
                                    {p.dataItem.plannedQuantity}
                                </td>
                            }}
                            orderIndex={getVehicleGridOrderIndexColumn("plannedQuantity", 12)} width={getVehicleGridWidth("plannedQuantity", 50)} title="Qté planifiée" editable={false} />
                        <Column field="plannedNumberOfTurns"
                            cell={(p: GridCellProps) => {
                                return <td className={p.dataItem.transportSellPriceKindBase == 'PerTurn' ? "" : "field-disabled"}>
                                    {p.dataItem.plannedNumberOfTurns}
                                </td>
                            }}
                            orderIndex={getVehicleGridOrderIndexColumn("plannedNumberOfTurns", 13)} width={getVehicleGridWidth("plannedNumberOfTurns", 50)} title="Tours planifiés" editable={false} />
                        <Column field="flowPrice"
                            cell={(p: GridCellProps) => {
                                return <td>
                                    {p.dataItem.flowPrice ? p.dataItem.flowPrice.toCurrencyString() : ""}
                                </td>
                            }}
                            orderIndex={getVehicleGridOrderIndexColumn("flowPrice", 14)} width={getVehicleGridWidth("flowPrice", 100)} title="Prix de vente Flux" editable={false} />
                        <Column field="deliveredQuantity"
                            cell={(p: GridCellProps) => {
                                return <td className={p.dataItem.transportSellPriceKindBase == 'PerTon' ? "" : "field-disabled"}>
                                    {p.dataItem.deliveredQuantity}
                                </td>
                            }}
                            orderIndex={getVehicleGridOrderIndexColumn("deliveredQuantity", 15)} width={getVehicleGridWidth("deliveredQuantity", 50)} title="Qté comptabilisée" editable={false} />
                        <Column field="deliveredNumberOfTurns"
                            cell={(p: GridCellProps) => {
                                return <td className={p.dataItem.transportSellPriceKindBase == 'PerTurn' ? "" : "field-disabled"}>
                                    {p.dataItem.deliveredNumberOfTurns}
                                </td>
                            }}
                            orderIndex={getVehicleGridOrderIndexColumn("deliveredNumberOfTurns", 16)} width={getVehicleGridWidth("deliveredNumberOfTurns", 50)} title="Tours comptabilisés" editable={false} />
                        <Column field="invoicePrice"
                            cell={(props: GridCellProps) => <CustomPricesCellComponent {...props} refreshComputedCells={refreshComputedCells} />}
                            orderIndex={getVehicleGridOrderIndexColumn("invoicePrice", 17)} width={getVehicleGridWidth("invoicePrice", 200)}
                            headerCell={enableEdit ? () => <EditableHeaderComponent field="invoicePrice" title="Prix de vente à facturer" /> : null} editable={enableEdit} />
                        <Column field="logisticsUnitLabel" orderIndex={getVehicleGridOrderIndexColumn("logisticsUnitLabel", 18)} width={getVehicleGridWidth("logisticsUnitLabel", 150)} title="Zone logistique" editable={false} />
                        <Column field="planningVehicleComments" orderIndex={getVehicleGridOrderIndexColumn("planningVehicleComments", 19)} width={getVehicleGridWidth("planningVehicleComments", 150)} title="Commentaire camion" editable={false} />
                        <Column field="preInvoiceComments" orderIndex={getVehicleGridOrderIndexColumn("preInvoiceComments", 20)} width={getVehicleGridWidth("preInvoiceComments", 150)}
                            headerCell={enableEdit ? () => <EditableHeaderComponent field="preInvoiceComments" title="Commentaire facturation" /> : null}
                            cell={(props: GridCellProps) => <PreInvoiceCommentsCell {...props} width={getVehicleGridWidth("preInvoiceComments", 200)} />} />
                        <Column field="senderSiteResolutionLabel" orderIndex={getVehicleGridOrderIndexColumn("senderSiteResolutionLabel", 21)} width={getVehicleGridWidth("senderSiteResolutionLabel", 80)} title="Site départ" editable={false} />
                        <Column field="receiverSiteResolutionLabel" orderIndex={getVehicleGridOrderIndexColumn("receiverSiteResolutionLabel", 22)} width={getVehicleGridWidth("receiverSiteResolutionLabel", 80)} title="Site d'arrivée" editable={false} />
                        <Column field="deliveryAddressCity" orderIndex={getVehicleGridOrderIndexColumn("deliveryAddressCity", 23)} width={getVehicleGridWidth("deliveryAddressCity", 80)} title="Ville arrivée" editable={false} />
                        <Column field="beneficiaryName" orderIndex={getVehicleGridOrderIndexColumn("beneficiaryName", 24)} width={getVehicleGridWidth("beneficiaryName", 80)} title="Bénéficaire" editable={false} />
                        <Column field="payerLabel" orderIndex={getVehicleGridOrderIndexColumn("payerLabel", 25)} width={getVehicleGridWidth("payerLabel", 200)} title="Payeur" editable={false} />
                        <Column field="payerJobSiteMdmId" orderIndex={getVehicleGridOrderIndexColumn("payerJobSiteMdmId", 26)} width={getVehicleGridWidth("payerJobSiteMdmId", 200)} title="IDMDM chantier payeur" editable={false} />
                        <Column field="payerJobForemanName" orderIndex={getVehicleGridOrderIndexColumn("payerJobForemanName", 27)} width={getVehicleGridWidth("payerJobForemanName", 200)} title="Chef de chantier payeur" editable={false} />
                        <Column field="lastWriteDate" orderIndex={getVehicleGridOrderIndexColumn("lastWriteDate", 28)} width={getVehicleGridWidth("lastWriteDate", 100)} title="Date synchronisation" editable={false} format="{0:dd-MM-yyyy HH:mm:ss}" />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}
