


// $Classes/Enums/Interfaces(filter)[template][separator]
// filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
// template: The template to repeat for each matched item
// separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

// More info: http://frhagn.github.io/Typewriter/

    
    
    export enum TextSearchField {
        licencePlate = "LicencePlate",
        transporterLabel = "TransporterLabel",
        beneficiaryName = "BeneficiaryName",
        transportFlowPayer = "TransportFlowPayer",
        driverFullName = "DriverFullName",
        expectedVehicleType = "ExpectedVehicleType",
        deliveryAddressCity = "DeliveryAddressCity",
        deliveryAddressZipCode = "DeliveryAddressZipCode",
        pickupAddressCity = "PickupAddressCity",
        pickupAddressZipCode = "PickupAddressZipCode",
        product = "Product",
        senderSite = "SenderSite",
        receiverSite = "ReceiverSite",
        senderAgency = "SenderAgency",
        receiverAgency = "ReceiverAgency",
        transportFlowBusinessId = "TransportFlowBusinessId",
        zephyrOrderNumber = "ZephyrOrderNumber",
        planningVehicleRemarks = "PlanningVehicleRemarks",
    }
