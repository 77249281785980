import { Box, Button, Radio } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import { CustomerCandidateToAddExtended } from '../../models/CustomerCandidateToAddExtended';
import { CustomerContactsReferentialApiClient } from '../../services/CustomerContactsReferentialApiClient';

interface SelectCustomerStepComponentProperties {
    customerChoices: CustomerCandidateToAddExtended[],
    handleSearchInReferentialGrid: (customerMdmId: string) => void,
    handleConfirmStep: (customer: CustomerCandidateToAddExtended) => void,
    handlePreviousStep: () => void
}

export const SelectCustomerStepComponent = (props: SelectCustomerStepComponentProperties): JSX.Element => {

    const initialSort: SortDescriptor[] = [{ field: 'date', dir: 'asc' }];

    const [customerChoices, setCustomerChoices] = useState<CustomerCandidateToAddExtended[]>([...props.customerChoices]);
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);

    const handleCustomerChoiceChanged = (checked: boolean, dataItem: CustomerCandidateToAddExtended): void => {
        const customerChoicesArray:CustomerCandidateToAddExtended[] = [...customerChoices];
        customerChoicesArray.forEach((item: CustomerCandidateToAddExtended) => {
            item.mdmId === dataItem.mdmId ? item.selected = true : item.selected = false;
        });

        setCustomerChoices(customerChoicesArray);
    }

    const handleClikAddCustomer = (): void => {
        const customerToAdd: CustomerCandidateToAddExtended = customerChoices.find(x => x.selected === true);

        CustomerContactsReferentialApiClient.CustomerExists(customerToAdd.mdmId)
            .then((res) => {
                if (res.data === true)
                    props.handleSearchInReferentialGrid(customerToAdd.mdmId);
                else
                    props.handleConfirmStep(customerToAdd);
            });
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const isAddButtonDisabled: boolean = customerChoices.every((item) => item.selected === false);

    return (
        <Box display="flex" flexDirection="column">
            <Box className="customer-choices">
                <LocalizationProvider language="fr-FR">
                    <IntlProvider locale="fr" >
                        <Grid
                            data={orderBy(customerChoices, sort)}
                            sort={sort}
                            className="customer-choices-grid"
                            sortable
                            onSortChange={handleSortChange}
                            rowHeight={40}
                        >
                            <GridColumn field="selected" title="Sélection" width={60}
                                cell={(props: GridCellProps) => <td>
                                    <Radio
                                        checked={props.dataItem.selected}
                                        onChange={(e, checked) => handleCustomerChoiceChanged(checked, props.dataItem)}
                                        color="default"
                                    />
                                </td>}
                            />
                            <GridColumn field="mdmId" title="IdMdM" width={60} />
                            <GridColumn field="customLongName" title="Nom du client" width={150} />
                            <GridColumn field="addressLine1" title="Adresse 1" width={170} />
                            <GridColumn field="addressLine2" title="Adresse 2" width={170} />
                            <GridColumn field="addressZipCode" title="Code postal" width={75} />
                            <GridColumn field="addressCity" title="Ville" width={100} />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                <Button variant="contained" title="Retour" className="previous-button" onClick={props.handlePreviousStep}>
                    Retour
                </Button>
                <Button variant="contained" color="primary" title="Ajouter" onClick={handleClikAddCustomer} disabled={isAddButtonDisabled} >
                    Ajouter
                </Button>
            </Box>
        </Box>
    );
}