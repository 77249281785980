import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Box, Button, Checkbox, ClickAwayListener, Drawer, FormControlLabel, Grow, MenuItem, MenuList, Paper, Popper, Tooltip } from '@mui/material';
import { Popup } from '@progress/kendo-react-popup';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import LorieIcon from 'src/shared/components/Common/LorieIcon';
import SimpleDialog from '../shared/components/Common/SimpleDialog';
import { LogisticsUnitChoice } from '../shared/models/LogisticsUnitChoice';
import TransportRequestChangesAllView from '../shared/TransportRequestChanges/TransportRequestChangesAllView';
import { UserApiClient } from '../shared/User/services/UserApiClient';
import { UserSignatureDialogComponent } from '../shared/UserSignature/UserSignatureDialogComponent';
import { LocalStorage } from '../utils/Storage';
import RequestedTransportationsExportForm from './Exports/RequestedTransportationsExport/RequestedTransportationsExportForm';
import TransportPlanExportForm from './Exports/TransportPlanExport/TransportPlanExportForm';
import './NavMenu.scss';

interface NavMenuState {
    dialogTitle: string,
    isDialogOpened: boolean,
    popupContentComponent: JSX.Element,
    cp: number,
    right: boolean,
    isUserSignatureDialogOpened: boolean
}

interface NavMenuProperties {
    role: string,
    userName: string,
    userInitials: string,
    userDisplayName: string,
    userPhoneNumber: string,
    userEmail: string,
    logisticsUnitIds: Array<string>,
    logisticsUnits: Array<LogisticsUnitChoice>,
    handleRefreshLogisticsUnitsChanges: (logisticsUnits: Array<LogisticsUnitChoice>) => void
}

interface MenuReferentielProps {
    role: string,
    isQuotationAdministrator: boolean
}

type DrawerSide = 'top' | 'left' | 'bottom' | 'right';

interface LogisticsUnitsComponentProps {
    persistLogisticsUnitsChoice: (logisticsUnits: Array<LogisticsUnitChoice>) => boolean,
    logisticsUnits: Array<LogisticsUnitChoice>,
    role: string
}

const LogisticsUnitsComponent = (props: LogisticsUnitsComponentProps): JSX.Element => {
    let menuLabel = "";
    const role: string = props.role;

    const [openEx, setOpenEx] = React.useState<boolean>(false);
    const [logisticsUnits, setLogisticsUnits] = React.useState<Array<LogisticsUnitChoice>>(props.logisticsUnits);
    const [isSelectAllChecked, setIsSelectAllChecked] = React.useState<boolean>();
    const anchorRefEx: React.RefObject<HTMLDivElement> = React.useRef(null);

    useEffect(() => {
        setSelectAllCheckbox(logisticsUnits);
    }, [])

    const setSelectAllCheckbox = (logisticsUnits: LogisticsUnitChoice[]): void => {
        const isSelectAllChecked = logisticsUnits.every((logisticsUnit) => logisticsUnit.checked === true);
        setIsSelectAllChecked(isSelectAllChecked);
    }

    const handleToggle = (): void => {
        setOpenEx(prevOpen => !prevOpen);
    }

    const handleClose = (event: MouseEvent | TouchEvent): void => {
        if (anchorRefEx.current && anchorRefEx.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpenEx(false);
    }

    const handleClickAway = (event: MouseEvent | TouchEvent): void => {
        setLogisticsUnits(props.logisticsUnits);
        setSelectAllCheckbox(props.logisticsUnits);
        handleClose(event);
    }

    const approveList = (event: React.MouseEvent<EventTarget>): void => {
        if (props.persistLogisticsUnitsChoice(logisticsUnits)) {
            setOpenEx(false);
        }
    }

    const handleLogisticsUnitsChanges = (logisticsUnitId: string, checked: boolean): void => {
        const logisticsUnitsArray: Array<LogisticsUnitChoice> = logisticsUnits.map((elem: LogisticsUnitChoice) => ({ ...elem }));
        const item: LogisticsUnitChoice = logisticsUnitsArray.find(x => x.logisticsUnitId === logisticsUnitId);
        const index: number = logisticsUnitsArray.findIndex(x => x.logisticsUnitId === logisticsUnitId);
        item.checked = !checked;
        logisticsUnitsArray[index] = item;

        setLogisticsUnits(logisticsUnitsArray);
        setSelectAllCheckbox(logisticsUnitsArray);
    }

    const handleSelectAllLogisticsUnitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setIsSelectAllChecked(event.target.checked);
        const logisticsUnitsArray: Array<LogisticsUnitChoice> = logisticsUnits.map((elem: LogisticsUnitChoice) => ({ ...elem }));
        logisticsUnitsArray.forEach((element: LogisticsUnitChoice) => {
            element.checked = event.target.checked;
        });

        setLogisticsUnits(logisticsUnitsArray);
    }

    if (logisticsUnits && logisticsUnits.length === 1) {
        var userAutorizedSite: number = logisticsUnits.length;
        if (userAutorizedSite === 1) {
            LocalStorage.RemoveLogisticsUnitsChoices();
            menuLabel = logisticsUnits[0].label;
        }
    } else {
        var checkedLogisticsUnits: Array<LogisticsUnitChoice> = logisticsUnits?.filter(x => x.checked === true);
        const checkedUENumber: number = checkedLogisticsUnits?.length;
        menuLabel = "";

        if (checkedUENumber === 0) {
            menuLabel = "Sélectionner votre/vos zones logistiques";
        } else if (checkedUENumber > 1) {
            menuLabel = "Sélectionner votre/vos zones logistiques (" + checkedUENumber + ")";
        } else {
            menuLabel = checkedLogisticsUnits ? checkedLogisticsUnits[0].label : '';
        }
    }

    return (
        <div className="logisticsUnits-menu">
            {userAutorizedSite === 1 && (role === "ADM" || role === "LOG") &&
                <>
                    <div className="logisticsUnitLabel">
                        {menuLabel}
                    </div>
                </>
            }
            {userAutorizedSite !== 1 && (role === "ADM" || role === "LOG") &&
                <>
                    <div ref={anchorRefEx} aria-controls="menu-list-grow" className="nav-link dropdown-toggle cursor-pointer" aria-haspopup="true" onClick={handleToggle}>
                        {menuLabel}
                    </div>
                    <Popper open={openEx} anchorEl={anchorRefEx.current} style={{ zIndex: 10 }} className='tooltip-menu' transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper id="menu-list-grow">
                                    <ClickAwayListener onClickAway={handleClickAway}>
                                        <MenuList className="logistics-unit-menu">
                                            <MenuItem className={isSelectAllChecked ? "logistics-unit-item logistics-unit-item-checked" : "logistics-unit-item"}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={isSelectAllChecked}
                                                            onChange={(e) => handleSelectAllLogisticsUnitChange(e)}
                                                            className={isSelectAllChecked ? "checked-logistics-unit" : ""}
                                                        />
                                                    }
                                                    label="Tout sélectionner"
                                                />
                                            </MenuItem>
                                            {logisticsUnits.map((item: LogisticsUnitChoice, index) => {
                                                return (
                                                    <MenuItem key={`logistic-unit-selected-${index}`} className={item.checked ? "logistics-unit-item logistics-unit-item-checked" : "logistics-unit-item"}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={item.checked}
                                                                    onChange={() => handleLogisticsUnitsChanges(item.logisticsUnitId, item.checked)}
                                                                    className={item.checked ? "checked-logistics-unit" : ""}
                                                                />
                                                            }
                                                            label={item.label}
                                                        />
                                                    </MenuItem>
                                                );
                                            })}
                                            <div className="approve-logistics-unit-section">
                                                {checkedLogisticsUnits.length === 0 &&
                                                    <p className="warning-select-logistics-unit">Veuillez sélectionner au moins une zone logistique</p>
                                                }
                                                <Button variant="contained" className="btn-approve-logistics-unit" onClick={approveList}>
                                                    Valider la sélection
                                                </Button>
                                            </div>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </>
            }
        </div>
    );
}

export class NavMenu extends React.Component<NavMenuProperties, NavMenuState> {
    _isMounted: boolean;
    envName: string = window.location.origin.includes("rec-isoprod", -1) ? "ISO-PROD RECETTTE" : (window.location.origin.includes("rec-2nd", -1) ? "RECETTE-2" :
        (window.location.origin.includes("rec", -1) ? "RECETTE" : (window.location.origin.includes("dev-2nd", -1) ? "DEV-2" : window.location.origin.includes("dev", -1) ? "DEV" : (window.location.origin.includes("local", -1) ? "LOCAL" : ""))));
    nbALIntervalId;

    constructor(props: NavMenuProperties) {
        super(props);
        this.state = {
            dialogTitle: '',
            isDialogOpened: false,
            popupContentComponent: null,
            cp: 0,
            right: false,
            isUserSignatureDialogOpened: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.nbALIntervalId);
    }

    handleClickOpen(dialogType: string): void {
        if (dialogType === 'transportPlan') {
            this.setState({ isDialogOpened: true, dialogTitle: 'Plan de transport', popupContentComponent: <TransportPlanExportForm onClose={() => this.handleClickClose()} logisticsUnitIds={this.props.logisticsUnitIds} /> });
        } else if (dialogType === 'transportRequests') {
            this.setState({ isDialogOpened: true, dialogTitle: 'Demande transport', popupContentComponent: <RequestedTransportationsExportForm onClose={() => this.handleClickClose()} logisticsUnitIds={this.props.logisticsUnitIds} /> });
        }
    }

    handleClickClose(): void {
        this.setState({ isDialogOpened: false });
    }

    MenuVisuTiers = (): JSX.Element => {
        const [open, setOpen] = React.useState<boolean>(false);
        const anchorRef: React.RefObject<HTMLDivElement> = React.useRef(null);

        function handleToggle(): void {
            setOpen(prevOpen => !prevOpen);
        }

        function handleClose(event: MouseEvent | TouchEvent): void {
            if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
                return;
            }

            setOpen(false);
        }

        const handleMenuItemClick = (): void => {
            setOpen(false);
        }

        return (
            <>
                <div ref={anchorRef} aria-controls="menu-list-grow1" className="nav-link dropdown-toggle cursor-pointer" aria-haspopup="true" onClick={handleToggle}>
                    VISU TIERS
                </div>
                <Popper open={open} anchorEl={anchorRef.current} style={{ zIndex: 10 }} className='tooltip-menu' transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: 'center bottom' }}
                        >
                            <Paper id="menu-list-grow1">
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList className="menu-list">
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/ThirdPartyBeneficiary">
                                                <span className="nav-item">Bénéficiaires / Demandeurs</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/ThirdPartyInternalTransporter">
                                                <span className="nav-item">Dispatcheur</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/ThirdPartyProduction">
                                                <span className="nav-item">Production</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/ThirdPartyExternalTransporter">
                                                <span className="nav-item">Transporteur</span>
                                            </NavLink>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
        );
    }

    MenuExports = (): JSX.Element => {
        const [openEx, setOpenEx] = React.useState<boolean>(false);
        const anchorRefEx: React.RefObject<HTMLDivElement> = React.useRef(null);

        function handleToggle(): void {
            setOpenEx(prevOpen => !prevOpen);
        }

        function handleClose(event: MouseEvent | TouchEvent): void {
            if (anchorRefEx.current && anchorRefEx.current.contains(event.target as HTMLElement)) {
                return;
            }

            setOpenEx(false);
        }

        const handleMenuItemClick = (): void => {
            setOpenEx(false);
        }

        return (
            <>
                <div ref={anchorRefEx} aria-controls="menu-list-grow" className="nav-link dropdown-toggle cursor-pointer" aria-haspopup="true" onClick={handleToggle}>
                    EXPORTS
                </div>
                <Popper open={openEx} anchorEl={anchorRefEx.current} style={{ zIndex: 10 }} className='tooltip-menu' transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper id="menu-list-grow">
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList className="menu-list">
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item">
                                            <div className="nav-link text-white" onClick={() => this.handleClickOpen('transportPlan')}><span className="nav-item">Plan de transport</span></div>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item">
                                            <div className="nav-link text-white" onClick={() => this.handleClickOpen('transportRequests')}><span className="nav-item">Demande de transport</span></div>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
        );
    }

    MenuReferentiel = (props: MenuReferentielProps): JSX.Element => {
        const [open, setOpenEx] = React.useState<boolean>(false);
        const anchorRef: React.RefObject<HTMLDivElement> = React.useRef(null);

        function handleToggle(): void {
            setOpenEx(prevOpen => !prevOpen);
        }

        function handleClose(event: MouseEvent | TouchEvent): void {
            if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
                return;
            }

            setOpenEx(false);
        }

        const handleMenuItemClick = (): void => {
            setOpenEx(false);
        }

        return (
            <>
                <div ref={anchorRef} aria-controls="menu-list-grow" className="nav-link dropdown-toggle cursor-pointer" aria-haspopup="true" onClick={handleToggle}>
                    RÉFÉRENTIELS
                </div>
                <Popper open={open} anchorEl={anchorRef.current} style={{ zIndex: 10 }} className='tooltip-menu' transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: 'center bottom' }}
                        >
                            <Paper id="menu-list-grow1">
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList className="menu-list">
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/Level2ActivitiesReferential">
                                                <span className="nav-item">Activité Industries</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/InternalContactsReferential">
                                                <span className="nav-item">Bénéficiaires et demandeurs Colas</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/InternalVehiclesReferential">
                                                <span className="nav-item">Camions Colas</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/InternalDriversReferential">
                                                <span className="nav-item">Chauffeurs Colas</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/CustomerContactsReferential">
                                                <span className="nav-item">Clients externes</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/DispatchersReferential">
                                                <span className="nav-item">Dispatcheurs Colas</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/OverheadExpensesReferential">
                                                <span className="nav-item">Frais de structure</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/DieselIndexesReferential">
                                                <span className="nav-item">Indice gazole</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/TemporaryDriversReferential">
                                                <span className="nav-item">Intérimaires Colas</span>
                                            </NavLink>
                                        </MenuItem>
                                        {props.role === "ADM" && <MenuItem onClick={() => handleMenuItemClick()} className="menu-item">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/LogisticsUnitLogisticiansReferential">
                                                <span className="nav-item">Logisticiens et options</span>
                                            </NavLink>
                                        </MenuItem>}
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/Level3SegmentationsReferential">
                                                <span className="nav-item">Segmentations niveau 3</span></NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/GenericPurchasePricesReferential">
                                                <span className="nav-item">Tarifs Achat génériques</span></NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/ExternalTransportPurchasePricesReferential">
                                                <span className="nav-item">Tarifs Achat Transporteurs externes</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/InternalTransportPurchasePricesReferential">
                                                <span className="nav-item">Tarifs Achat Transporteurs internes</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/NegotiatedPurchasePricesReferential">
                                                <span className="nav-item">Tarifs d'achat négociés</span>
                                            </NavLink>
                                        </MenuItem>
                                        {props.isQuotationAdministrator && <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/QuotationPricesReferential">
                                                <span className="nav-item">Tarifs de vente</span>
                                            </NavLink>
                                        </MenuItem>}
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item text-white">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/NegotiatedSellPricesReferential">
                                                <span className="nav-item">Tarifs de vente négociés</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/WorkAgenciesReferential">
                                                <span className="nav-item">Unité d’Exploitation Travaux Colas</span>
                                            </NavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick()} className="menu-item">
                                            <NavLink
                                                className="text-white nav-link"
                                                to="/ProductionSitesReferential">
                                                <span className="nav-item">Unité de Production Colas</span>
                                            </NavLink>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
        );
    }

    persistLogisticsUnitsChoice = (logisticsUnits: Array<LogisticsUnitChoice>): boolean => {
        if (logisticsUnits.find(x => x.checked === true) !== undefined) {
            LocalStorage.LogisticsUnitsChoices = logisticsUnits;
            this.props.handleRefreshLogisticsUnitsChanges(logisticsUnits);
            return true;
        }
        return false;
    }

    toggleDrawer = (side: DrawerSide, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ): void => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        this.setState({ ...this.state, [side]: open });
    };

    closeDrawer = (): void => {
        this.setState({ right: false });
    }

    sideList = (side: DrawerSide): JSX.Element => (
        <div style={{ width: 500 }}
            role="presentation"
            onKeyDown={this.toggleDrawer(side, false)}
        >
            <TransportRequestChangesAllView logisticsUnits={this.props.logisticsUnits ? UserApiClient.GetChosenLogisticsUnits(this.props.logisticsUnits) : null} closeDrawer={this.closeDrawer} />
        </div>
    );

    //Solution pour simuler la fermeture du menu 'Visu tiers' ou 'Export' lors du clic sur un NavLink alors que le menu est ouvert
    //Lien d'un forum d'un cas similaire mais avec un drawer au lieu d'un menu https://github.com/material-components/material-components-web/issues/644
    handleCloseMenus = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        //Pour éviter de recharger le composant si le composant est le même
        if (event.currentTarget.className.contains('active-link'))
            event.preventDefault();

        const tooltips: HTMLCollectionOf<Element> = document.getElementsByClassName('tooltip-menu');
        if (tooltips && tooltips.length > 0) {
            //Fermer le menu 'Visu tiers' ou 'Exports' si ouvert
            const toolTipItem: any = tooltips.item(0);
            toolTipItem.click();
        }
    }

    UserMenu = (props): JSX.Element => {
        const anchor = React.useRef<any>(null);
        const [show, setShow] = React.useState(false);

        const { role, userDisplayName, userInitials } = props;

        const onUserClick = (): void => {
            if (role === "ADM" || role === "LOG") {
                setShow(!show);
            }
        }

        const handleClickAway = (): void => {
            setShow(false);
        }

        const onUserSettingsClicked = (): void => {
            props.onClick();
            setShow(false);
        }

        return (
            <>
                <Box pl={2}>
                    <Tooltip title={userDisplayName} placement='left' id="user-tooltip">
                        <Avatar className={`initial-profile ${(role === "ADM" || role === "LOG") ? 'is-log' : ''}`}
                            onClick={() => onUserClick()}
                            ref={anchor}
                        >
                            {userInitials}
                        </Avatar>
                    </Tooltip>
                </Box>
                <Popup anchor={anchor.current} show={show} className="user-settings-popup" popupClass="content">
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Box display="flex" flexDirection="row" alignItems="center" style={{ cursor: 'pointer' }} onClick={onUserSettingsClicked}>
                            <FontAwesomeIcon icon={faCog} style={{ marginRight: "5px" }} />
                            Paramètres utilisateur
                        </Box>
                    </ClickAwayListener>
                </Popup>
            </>
        );
    }

    onUserSettingsClicked = (): void => {
        this.setState({ isUserSignatureDialogOpened: true });
    }

    onUserSignatureDialogClosed = (): void => {
        this.setState({ isUserSignatureDialogOpened: false });
    }

    render() {
        const { dialogTitle, popupContentComponent } = this.state;
        const { role, userName, userInitials, userDisplayName, userPhoneNumber, userEmail } = this.props;
        const isQuotationAdministrator = this.props.logisticsUnits?.some(x => x.checked && x.isQuotationAdministrator);

        return (
            <>
                <AppBar className="screenOnly header-menu navMenu" position="fixed">
                    <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center">
                        <Box width="70%" display="flex" pl={3} pr={2} justifyContent="flex-start" flexDirection="row" alignItems="center">
                            <Box>
                                <NavLink
                                    className="nav-link icon-header"
                                    onClick={(e) => this.handleCloseMenus(e)} to="/">
                                    <LorieIcon name="logoLorie" height="55px" width="55px" />
                                </NavLink>
                            </Box>
                            {(role === "ADM" || role === "LOG" || role === "PRD" || role === "DIS") && (
                                <Box>
                                    <NavLink
                                        className="nav-link"
                                        onClick={(e) => this.handleCloseMenus(e)} to="/">
                                        <span className="nav-item">SUIVI OPÉRATIONNEL</span>
                                    </NavLink>
                                </Box>
                            )}
                            {(role === "DIS") && (
                                <Box>
                                    <NavLink
                                        className="nav-link"
                                        onClick={(e) => this.handleCloseMenus(e)} to="/InternalTransporterOrder">
                                        <span className="nav-item">CONFIRMATION COMMANDES</span>
                                    </NavLink>
                                </Box>
                            )}
                            {(role === "PRD") && (
                                <Box>
                                    <NavLink
                                        className="nav-link"
                                        onClick={(e) => this.handleCloseMenus(e)} to="/Production">
                                        <span className="nav-item">PRODUCTION</span>
                                    </NavLink>
                                </Box>
                            )}
                            {(role === "ADM" || role === "LOG") && (
                                <Box>
                                    <NavLink
                                        className="nav-link"
                                        onClick={(e) => this.handleCloseMenus(e)} to="/TransportPlan">
                                        <span className="nav-item">PLAN DE TRANSPORT</span>
                                    </NavLink>
                                </Box>
                            )}
                            {(role === "ADM" || role === "LOG") && (
                                <Box>
                                    <NavLink
                                        className="nav-link"
                                        onClick={(e) => this.handleCloseMenus(e)} to="/TransportPurchases">
                                        <span className="nav-item">ACHATS</span>
                                    </NavLink>
                                </Box>
                            )}
                            {(role === "ADM" || role === "LOG") && (
                                <Box>
                                    <NavLink
                                        className="nav-link"
                                        onClick={(e) => this.handleCloseMenus(e)} to="/PurchaseProvisionings">
                                        <span className="nav-item">PROVISIONS</span>
                                    </NavLink>
                                </Box>
                            )}
                            {(role === "ADM" || role === "LOG") && (
                                <Box>
                                    <NavLink
                                        className="nav-link"
                                        onClick={(e) => this.handleCloseMenus(e)} to="/TransportSales">
                                        <span className="nav-item">VENTES</span>
                                    </NavLink>
                                </Box>
                            )}
                            {(role === "ADM" || role === "LOG") && (
                                <Box>
                                    <NavLink
                                        className="nav-link"
                                        onClick={(e) => this.handleCloseMenus(e)} to="/Reservation">
                                        <span className="nav-item">RÉSERVATION</span>
                                    </NavLink>
                                </Box>
                            )}
                            {(role === "ADM" || role === "LOG") && (
                                <Box>
                                    <this.MenuVisuTiers />
                                </Box>
                            )}
                            {(role === "ADM" || role === "LOG") && (
                                <Box>
                                    <this.MenuExports />
                                </Box>
                            )}
                            {(role === "ADM" || role === "LOG") && (
                                <Box>
                                    <this.MenuReferentiel role={role} isQuotationAdministrator={isQuotationAdministrator} />
                                </Box>
                            )}
                            {(role === "ADM" || role === "LOG") && (
                                <Box>
                                    <NavLink
                                        className="nav-link"
                                        onClick={(e) => this.handleCloseMenus(e)} to="/Cartography">
                                        <span className="nav-item">CARTOGRAPHIE</span>
                                    </NavLink>
                                </Box>
                            )}
                        </Box>
                        <Box width="30%" display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="flex-end" pl={2} pr={2} pt={1} pb={1} alignItems="center">
                            <Box pr={1}>
                                <span className="text-red rec-env-label">{this.envName}</span>
                            </Box>
                            {(role === "ADM" || role === "LOG") && (
                                <Box p={1} className='transport-agencies-selected'>
                                    <LogisticsUnitsComponent persistLogisticsUnitsChoice={this.persistLogisticsUnitsChoice} logisticsUnits={this.props.logisticsUnits} role={role} />
                                </Box>
                            )}
                            <this.UserMenu userDisplayName={userDisplayName} role={role} userInitials={userInitials} onClick={() => this.onUserSettingsClicked()} />
                            {this.state.isUserSignatureDialogOpened &&
                                <UserSignatureDialogComponent
                                    isUserSignatureDialogOpened={this.state.isUserSignatureDialogOpened}
                                    userName={userName}
                                    userEmail={userEmail}
                                    userPhoneNumber={userPhoneNumber}
                                    onClose={this.onUserSignatureDialogClosed}
                                />
                            }
                        </Box>
                    </Box>
                    <SimpleDialog isOpen={this.state.isDialogOpened} onClose={() => this.handleClickClose()} component={popupContentComponent} dialogTitle={dialogTitle} classNameVal='nav-menu' />
                </AppBar>
                <Drawer onClose={() => this.closeDrawer()} classes={{ paper: 'nav-menu-drawer-paper' }} anchor="right" open={this.state.right}>
                    <div className='toolbar-styles' />
                    {this.sideList('right')}
                </Drawer>
            </>
        );
    }
}