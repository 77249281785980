import { faChartBar, faPen, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Card, CardContent, Checkbox, Divider, FormControlLabel, Tooltip } from '@mui/material';
import { NumericTextBox, NumericTextBoxChangeEvent, NumericTextBoxHandle } from '@progress/kendo-react-inputs';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import imgSendZephyr from 'src/assets/images/logoSendZephyr.png';
import imgSendZephyrDisabled from 'src/assets/images/logoSendZephyrGray.png';
import { PurchaseProvisioningLightModelExtended } from '../models/PurchaseProvisioningLightModelExtended';

interface ProvisioningCardProperties {
    purchase: PurchaseProvisioningLightModelExtended,
    toIntegrate: boolean,
    sendingPurchaseProvisioning?: boolean,
    purchaseProvisioningMaxAllowedCostLimit: number,
    handleSelectedPurchaseProvisioning: (purchaseProvisioningId: string, selected: boolean, toIntegrate: boolean) => void,
    removePurchaseProvisioning?: (purchaseProvisioningId: string) => void,
    sendPurchaseProvisioning: (purchaseProvisioning: PurchaseProvisioningLightModelExtended, emailSendRequested: boolean) => void,
    updatePurchaseProvisioningVisibilityInBI: (purchaseProvisioningId: string, toIntegrate: boolean) => void,
    handleEditRemarks: (purchaseProvisioningId: string, toIntegrate: boolean) => void,
    handleEditGasoilIndex?: (purchaseProvisioningId: string) => void,
    handleUpdateOverridenGasoilIndex: (purchaseProvisioningId: string, isInternal: boolean, overridenGasoilIndexCost?: number, overridenGasoilIndexToUpdate?: number) => void,
    handleChangeGasoilIndex: (purchaseProvisioningId: string, value: number) => void,
    reInitializeGasoilIndex: (purchaseProvisioningId: string) => void,
    handleUpdateRemarks: (purchaseProvisioningId: string, toIntegrate: boolean, remarks: string, remarksToUpdate?: string) => void,
    handleChangeRemarks: (purchaseProvisioningId: string, value: string, toIntegrate: boolean) => void
}

export const ProvisioningCardComponent = (props: ProvisioningCardProperties): JSX.Element => {

    const [emailSendRequested, setEmailSendRequested] = useState<boolean>(false);

    const { purchase, toIntegrate, sendingPurchaseProvisioning, sendPurchaseProvisioning, updatePurchaseProvisioningVisibilityInBI, handleEditRemarks
        , handleEditGasoilIndex, handleChangeGasoilIndex, handleUpdateOverridenGasoilIndex, reInitializeGasoilIndex, handleChangeRemarks, handleUpdateRemarks, purchaseProvisioningMaxAllowedCostLimit } = props;

    const [showConfirmationRemovePurchaseProvisioning, setShowConfirmationRemovePurchaseProvisioning] = useState<boolean>(false);

    const remarksRef: React.RefObject<HTMLInputElement> = React.useRef(null);
    const overridenGasoilIndexRef: React.RefObject<NumericTextBoxHandle> = React.useRef(null);

    useEffect(() => {
        if (purchase.editRemarks) {
            remarksRef.current.focus();
        }
    }, [purchase.editRemarks]);

    useEffect(() => {
        if (purchase.editGasoilIndex) {
            overridenGasoilIndexRef.current.focus();
        }
    }, [purchase.editGasoilIndex]);

    const handleClick = (purchaseProvisioningId: string, selected: boolean): void => {
        props.handleSelectedPurchaseProvisioning(purchaseProvisioningId, selected, toIntegrate);
    }

    const handleHideModal = (): void => {
        setShowConfirmationRemovePurchaseProvisioning(false);
    }

    const handleShowConfirmationRemovePurchaseProvisioning = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        setShowConfirmationRemovePurchaseProvisioning(true);

        stopPropagationEvent(event);
    }

    const handleConfirmRemovePurchaseProvisioning = () => {
        props.removePurchaseProvisioning(purchase.purchaseProvisioningId);
    }

    const stopPropagationEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const handleSendZephyr = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!sendingPurchaseProvisioning && !purchase.zephyrReceiptId && !purchase.editGasoilIndex)
            sendPurchaseProvisioning(purchase, emailSendRequested);
        stopPropagationEvent(event);
    }

    const handleUpdatePurchaseProvisioningVisibilityInBI = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, toIntegrate: boolean): void => {
        stopPropagationEvent(event);
        updatePurchaseProvisioningVisibilityInBI(purchase.purchaseProvisioningId, toIntegrate);
    }

    const handleEmailSendRequestedChanged = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!sendingPurchaseProvisioning && !purchase.zephyrReceiptId)
            setEmailSendRequested(!emailSendRequested);
        stopPropagationEvent(event);
    }

    const isInternal = purchase.supplierMdmIdentifier === null ? true : false;

    const reInitialiseGasoilIndex = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        reInitializeGasoilIndex(purchase.purchaseProvisioningId)
        stopPropagationEvent(event);
    }

    const isEmailRequestedAndDidntSend = !sendingPurchaseProvisioning && purchase.emailSendRequested === true && purchase.emailSentSuccessfully === false;
    const sumOfPurchaseCostAndGasoilExceedsTheMaximumAllowedLimit: boolean = (purchase.purchaseCost + purchase.fees + (purchase.overridenGasoilIndexCost ? purchase.overridenGasoilIndexCost : purchase.gasoilIndexCost)) >= purchaseProvisioningMaxAllowedCostLimit;
    const isSendingZephyrDisabled: boolean = sendingPurchaseProvisioning || !!purchase.zephyrReceiptId || purchase.editGasoilIndex || sumOfPurchaseCostAndGasoilExceedsTheMaximumAllowedLimit;

    const currentGasoilIndex = (purchase.overridenGasoilIndexToUpdate !== null) ? purchase.overridenGasoilIndexToUpdate : purchase.gasoilIndexCost;

    const remarksEditable = (toIntegrate || !purchase.supplierMdmIdentifier);

    return (
        <>
            <Card className={`card ${purchase.selected ? 'card-selected' : ''}${purchase.isRegularization ? ' regularization-card' : ''}`} onClick={() => handleClick(purchase.purchaseProvisioningId, !purchase.selected)}>
                <CardContent className="card-content">
                    <Box display="flex" flexDirection="row" className="title-div">
                        <Box display="flex" flexDirection="row" width="90%">
                            {purchase.sentSuccessfully === null ?
                                <Box className="title">
                                    {purchase.businessId}
                                </Box>
                                : <Tooltip title={purchase.sentSuccessfully === true ? purchase.zephyrReceiptId : purchase.sendingError}>
                                    <Box className={`title${purchase.sentSuccessfully === false
                                        ? ' title-error'
                                        : purchase.emailSendRequested === true && purchase.emailSentSuccessfully === false ? ' title-partially-success' : ' title-success'}`}>
                                        {purchase.businessId}
                                    </Box>
                                </Tooltip>
                            }
                            {!purchase.isRegularization && purchase.diffFromPurchaseProvisioningCount > 0 &&
                                <Tooltip title="Lot présentant une/des différence(s) avec les Achats.">
                                    <Box className="triangle">
                                        <span className="number">{purchase.diffFromPurchaseProvisioningCount}</span>
                                    </Box>
                                </Tooltip>
                            }
                        </Box>
                        {(toIntegrate || isInternal) && <Tooltip title="Supprimer le lot">
                            <Box display="flex" flexDirection="row" className="icon-trash" onClick={handleShowConfirmationRemovePurchaseProvisioning}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Box>
                        </Tooltip>}
                    </Box>
                    <Divider className='divider-title' />
                    <div className="line-details">
                        <div className="field-content field-content-w100">
                            <div className="title-field">
                                Transporteur
                            </div>
                            <div className="title-value">
                                {purchase.transporterName}
                            </div>
                        </div>
                    </div>
                    <div className="line-details">
                        <div className="field-content field-content-w50">
                            <div className="title-field">
                                Date création
                            </div>
                            <div className="title-value">
                                {purchase.creationTime.toShortDateString()}
                            </div>
                        </div>
                        <div className="field-content field-content-w50">
                            <div className="title-field">
                                Date intégration
                            </div>
                            <div className="title-value">
                                {purchase.sendingDate ? purchase.sendingDate.toShortDateString() : ''}
                            </div>
                        </div>
                    </div>
                    <div className="line-details">
                        <div className="field-content field-content-w50">
                            <div className="title-field">
                                Transport (coût + frais)
                            </div>
                            <Tooltip title={!isInternal && sumOfPurchaseCostAndGasoilExceedsTheMaximumAllowedLimit ? `La somme des lignes "Transport" et "Gazole" ne doit pas être supérieure ou égale à ${purchaseProvisioningMaxAllowedCostLimit.toCurrencyString()}` : ''}>
                                <div className={`title-value${!isInternal && sumOfPurchaseCostAndGasoilExceedsTheMaximumAllowedLimit ? " sum-exceeded" : ""}`}>
                                    {(purchase.totalCosts).toCurrencyString()}
                                </div>
                            </Tooltip>
                        </div>
                        <div className="field-content field-content-w50">
                            <div className="title-field">
                                Gazole
                                {toIntegrate &&
                                    <>
                                        <div className="icon-pen">
                                            <FontAwesomeIcon size="1x" className='icon-pen-blue' icon={faPen} />
                                        </div>
                                        <div className="icon-pen" onClick={(e) => purchase.overridenGasoilIndexCost !== null ? reInitialiseGasoilIndex(e) : null}>
                                            <FontAwesomeIcon size="1x" className={purchase.overridenGasoilIndexCost !== null ? 'icon-pen-blue' : 'icon-pen-gray'} icon={faUndo} />
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="title-value" onClick={stopPropagationEvent}>
                                {purchase.editGasoilIndex && toIntegrate ?
                                    <div>
                                        <NumericTextBox
                                            className="gasoil-index-input"
                                            ref={overridenGasoilIndexRef}
                                            onChange={(event: NumericTextBoxChangeEvent) => handleChangeGasoilIndex(purchase.purchaseProvisioningId, event.target.value)}
                                            onBlur={() => handleUpdateOverridenGasoilIndex(purchase.purchaseProvisioningId, isInternal, purchase.overridenGasoilIndexCost, purchase.overridenGasoilIndexToUpdate)}
                                            format={{
                                                maximumFractionDigits: 2,
                                                currencyDisplay: 'symbol',
                                                currency: 'EUR',
                                                style: 'currency'
                                            }}
                                            value={currentGasoilIndex}
                                            spinners={false} />
                                    </div>
                                    :
                                    <Tooltip title={!isInternal && sumOfPurchaseCostAndGasoilExceedsTheMaximumAllowedLimit ? `La somme des lignes "Transport" et "Gazole" ne doit pas être supérieure ou égale à ${purchaseProvisioningMaxAllowedCostLimit.toCurrencyString()}` : ''}>
                                        <div className="edit-field gasoil-index-input" onClick={() => handleEditGasoilIndex ? handleEditGasoilIndex(purchase.purchaseProvisioningId) : null}>
                                            <div className={`value${!isInternal && sumOfPurchaseCostAndGasoilExceedsTheMaximumAllowedLimit ? ' sum-exceeded' : (purchase.overridenGasoilIndexCost != null ? ' gasoil-index-overriden' : '')}`}>
                                                {purchase.overridenGasoilIndexCost != null ? purchase.overridenGasoilIndexCost.toCurrencyString()
                                                    : purchase.gasoilIndexCost ? purchase.gasoilIndexCost.toCurrencyString() : ''}
                                            </div>
                                        </div>
                                    </Tooltip>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="line-details pb-1">
                        <div className="field-content field-content-w100 pb-1">
                            <div className="title-field">
                                Commentaire
                                {remarksEditable && <div className="icon-pen">
                                    <FontAwesomeIcon size="1x" className='icon-pen-blue' icon={faPen} />
                                </div>}
                            </div>
                            <div className="title-value" onClick={stopPropagationEvent}>
                                {purchase.editRemarks ?
                                    <div>
                                        <input type="text" className="input-remarks" value={purchase.remarksToUpdate} ref={remarksRef}
                                            onBlur={(e) => handleUpdateRemarks(purchase.purchaseProvisioningId, toIntegrate, purchase.remarks, purchase.remarksToUpdate)} onChange={(e) => handleChangeRemarks(purchase.purchaseProvisioningId, e.target.value, toIntegrate)} />
                                    </div>
                                    :
                                    <div className="edit-field input-remarks" onClick={() => remarksEditable ? handleEditRemarks(purchase.purchaseProvisioningId, toIntegrate) : null}>
                                        <div className="value">
                                            {purchase.remarks}
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <Divider className='divider-title' />
                    <div className="footer-div">
                        <div className="mail-transporter-div" onClick={!isInternal ? (e) => handleEmailSendRequestedChanged(e) : stopPropagationEvent}>
                            {isEmailRequestedAndDidntSend ? (<Tooltip title="Le mail n'a pas pu être envoyé suite à un problème technique">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            disabled={(sendingPurchaseProvisioning || purchase.zephyrReceiptId || isInternal) ? true : false}
                                            checked={true}
                                        />
                                    }
                                    className="label-mail label-mail-error"
                                    label="Mail au transporteur"
                                />
                            </Tooltip>)
                                :
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            disabled={(sendingPurchaseProvisioning || purchase.zephyrReceiptId || isInternal) ? true : false}
                                            checked={purchase.zephyrReceiptId ? purchase.emailSentSuccessfully : emailSendRequested}
                                        />
                                    }
                                    className="label-mail"
                                    label="Mail au transporteur"
                                />
                            }</div>
                        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" width="50%">
                            {isInternal &&
                                <Tooltip title={toIntegrate ? "Cliquer pour afficher dans les KPI" : "Cliquer pour ne plus prendre en compte les camions dans le KPI"}>
                                    <div className="to-be-visible-div" onClick={(e) => handleUpdatePurchaseProvisioningVisibilityInBI(e, toIntegrate)}>
                                        <FontAwesomeIcon size="2x" icon={faChartBar} className={toIntegrate ? "to-be-visible-BI-icon" : "to-be-invisible-BI-icon"} />
                                    </div>
                                </Tooltip>
                            }
                            {!purchase.zephyrReceiptId && !purchase.visibleInBI &&
                                <div className={`send-zephyr-div${isSendingZephyrDisabled || isInternal ? ' btn-disabled' : ''}`}>
                                    <Tooltip title={isSendingZephyrDisabled || isInternal ? "" : "Envoyer à Zéphyr"}>
                                        <img height="30px" src={isSendingZephyrDisabled || isInternal ? imgSendZephyrDisabled : imgSendZephyr} onClick={isSendingZephyrDisabled || isInternal ? stopPropagationEvent : handleSendZephyr} />
                                    </Tooltip>
                                </div>
                            }
                        </Box>

                    </div>
                </CardContent>
            </Card>
            <Modal show={showConfirmationRemovePurchaseProvisioning} onHide={handleHideModal} className='mt-5'>
                <Modal.Header closeButton>
                    <Modal.Title>ATTENTION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Confirmer-vous la suppression du lot {purchase.businessId} ?
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary" onClick={handleHideModal}>
                        ANNULER
                    </Button>
                    <Button className="primary" onClick={handleConfirmRemovePurchaseProvisioning}>
                        CONFIRMER
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}