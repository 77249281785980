import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardContent, Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../../../utils/Date';
import Utilities from '../../../../utils/Utilities';
import { TransportFlowInfoDetailRequestArgs } from '../services/dataContracts/controller/TransportFlowInfoDetailRequestArgs';
import { DeliveryTripInstructionLightModel } from '../services/dataContracts/queryStack/DeliveryTripInstructionLightModel';
import { PlanningVehicleTripsLightModel } from '../services/dataContracts/queryStack/PlanningVehicleTripsLightModel';
import { InstructionsComponent } from './InstructionsComponent';

interface IChildComponentProps {
    data: PlanningVehicleTripsLightModel,
    transportRequestId: string,
    transportFlowId: string,
    updateState: boolean,
    role: string,
    changeTransportFlowPlanningVehiclesTripsFinishedStatus: (transportRequestId: string, transportFlowId: string, shouldChangeToFinished: boolean, details: Array<TransportFlowInfoDetailRequestArgs>) => void
}

interface LoadingCardsState {
    expanded: boolean,
    firstLoadingTime?: Date,
    productionSite: string,
    supplierAgency: string,
    pickupAddressLine1: string,
    pickupAddressLine2: string,
    pickupAddressZipCode: string,
    pickupAddressCity: string,
    transporterLabel: string,
    vehicleTypeLabel: string,
    licensePlate: string,
    driverFullName: string,
    driverPhoneNumber: string,
    tripsCount: number,
    unloadingDuration: string,
    isJobsiteVehicle: boolean,
    isLocked: boolean,
    isFinished: boolean,
    isIndeterminate: boolean,
    deliveryTripIds: number[],
    planningVehicleId: string,
    transportersInstructions: string,
    instructions: DeliveryTripInstructionLightModel[],
}

export const LoadingsCardComponent = (props: IChildComponentProps): JSX.Element => {

    const [state, setState] = useState<LoadingCardsState>({
        expanded: false,
        firstLoadingTime: null,
        productionSite: "",
        supplierAgency: "",
        pickupAddressLine1: "",
        pickupAddressLine2: "",
        pickupAddressZipCode: "",
        pickupAddressCity: "",
        transporterLabel: "",
        vehicleTypeLabel: "",
        licensePlate: "",
        driverFullName: "",
        driverPhoneNumber: "",
        isJobsiteVehicle: false,
        unloadingDuration: "",
        tripsCount: null,
        transportersInstructions: "",
        isLocked: false,
        isFinished: false,
        isIndeterminate: false,
        deliveryTripIds: [],
        planningVehicleId: "",
        instructions: []
    });

    useEffect(() => {
        setCardsState(props);
    }, []);

    useEffect(() => {
        setCardsState(props);
    }, [props.updateState]);

    const setCardsState = (props: IChildComponentProps): void => {
        setState({
            ...state,
            firstLoadingTime: props.data.firstLoadingTime,
            productionSite: props.data.productionSite,
            supplierAgency: props.data.supplierAgency,
            pickupAddressLine1: props.data.pickupAddressLine1,
            pickupAddressLine2: props.data.pickupAddressLine2,
            pickupAddressZipCode: props.data.pickupAddressZipCode,
            pickupAddressCity: props.data.pickupAddressCity,
            transporterLabel: props.data.transporterLabel,
            vehicleTypeLabel: props.data.vehicleTypeLabel,
            licensePlate: props.data.licensePlate,
            driverFullName: props.data.driverFullName,
            driverPhoneNumber: props.data.driverPhoneNumber,
            isJobsiteVehicle: props.data.isJobsiteVehicle,
            unloadingDuration: props.data.unloadingDuration,
            tripsCount: props.data.tripsCount,
            isFinished: props.data.isFinished,
            isIndeterminate: props.data.isIndeterminate,
            isLocked: props.data.isLocked,
            deliveryTripIds: props.data.deliveryTripIds,
            planningVehicleId: props.data.planningVehicleId,
            transportersInstructions: props.data.transportersInstructions,
            instructions: props.data.instructions
        });
    }

    const { firstLoadingTime, productionSite, supplierAgency, pickupAddressLine1, pickupAddressLine2, pickupAddressZipCode, pickupAddressCity,
        transporterLabel, vehicleTypeLabel, licensePlate, driverFullName, driverPhoneNumber, tripsCount, isJobsiteVehicle, unloadingDuration, transportersInstructions, instructions, isLocked, isFinished, isIndeterminate, deliveryTripIds, planningVehicleId } = state;

    return (
        <Card className="job-foreman-dc-card tr_dc_section">
            <CardContent className="job-foreman-card-content">
                <Box p={1} display="flex" flexDirection="column" flexWrap="nowrap">
                    <Box width="100%">
                        <Box display="flex" width={'160px'} className={isFinished ? 'finish-checkbox-control bg-button-finished' : 'finish-checkbox-control bg-button'} flexWrap="wrap" flexDirection="row">
                            <FormControlLabel
                                control={
                                    <>
                                        <Checkbox
                                            className={isFinished ? 'finished-checkbox' : 'not-finished-checkbox'}
                                            checked={isFinished}
                                            onChange={() => props.changeTransportFlowPlanningVehiclesTripsFinishedStatus(props.transportRequestId, props.transportFlowId, !isFinished, [
                                                {
                                                    planningVehicleId: planningVehicleId,
                                                    deliveryTripIds: deliveryTripIds
                                                }
                                            ])}
                                            disabled={isLocked}
                                            indeterminate={isIndeterminate}
                                        />
                                    </>
                                }
                                disabled={isLocked}
                                label={<span className="finish-checkbox-label">Terminé</span>}
                                labelPlacement="start"
                            />
                            <Box pl={2}>
                                {isLocked && <FontAwesomeIcon size="1x" icon={faLock} />}
                            </Box>
                        </Box>
                        <Box display="flex" flexWrap="wrap" flexDirection="row">
                            <Box width="50%">
                                <span className="loading-card-font">1er chargement</span>
                                <p className="loading-card-font-p">{`${Date.getTimeFromIsoString(firstLoadingTime)}`}</p>
                            </Box>
                            <Box width="50%">
                                <span className="loading-card-font">Site départ</span>
                                {productionSite ? <div className="loading-card-font-p">{productionSite}</div> : supplierAgency ? <div className="loading-card-font-p">{supplierAgency}</div> : ''}
                                {pickupAddressLine1 !== null && pickupAddressLine1 !== '' && <div className="loading-card-font-p">{pickupAddressLine1}</div>}
                                {pickupAddressLine2 !== null && pickupAddressLine2 !== '' && <div className="loading-card-font-p">{pickupAddressLine2}</div>}
                                {pickupAddressZipCode !== null && pickupAddressZipCode !== '' && pickupAddressCity !== null && pickupAddressCity !== '' && <div className="loading-card-font-p">{pickupAddressZipCode + ' ' + pickupAddressCity}</div>}
                                {pickupAddressZipCode !== null && pickupAddressZipCode !== '' && (pickupAddressCity === null || pickupAddressCity === '') && <div className="loading-card-font-p">{pickupAddressZipCode}</div>}
                                {pickupAddressCity !== null && pickupAddressCity !== '' && (pickupAddressZipCode === null || pickupAddressZipCode === '') && <div className="loading-card-font-p">{pickupAddressCity}</div>}
                            </Box>
                        </Box>
                        <Box display="flex" flexWrap="wrap" flexDirection="row">
                            <Box width="50%">
                                <span className="loading-card-font">Transporteur</span>
                                {transporterLabel !== null && transporterLabel !== '' && <p className="loading-card-font-p">{transporterLabel}</p>}
                            </Box>
                            <Box width="50%">
                                <span className="loading-card-font">Type véhicule</span>
                                <p className="loading-card-font-p loading-card-mg-b">{vehicleTypeLabel}</p>
                            </Box>
                        </Box>
                        <Box display="flex" flexWrap="wrap" flexDirection="row">
                            <Box width="50%">
                                <span className="loading-card-font">Immatriculation</span>
                                {licensePlate !== null && licensePlate !== '' && <p className="loading-card-font-p loading-card-mg-b">{licensePlate}</p>}
                            </Box>
                            <Box width="50%">
                                <span className="loading-card-font">Chauffeur</span>
                                <p className="loading-card-font-p loading-card-mg-b">{driverFullName}</p>
                            </Box>
                        </Box>
                        <Box display="flex" flexWrap="wrap" flexDirection="row">
                            <Box width="50%">
                                <span className="loading-card-font">Tel chauffeur</span>
                                <Box width="100%" className="loading-card-font-phone loading-card-mg-b phone-number-ref">
                                    <a href={(props.role === "CCH" || props.role === "CTX") ? `tel:${driverPhoneNumber}` : `callTo:${driverPhoneNumber}`} data-rel="external">
                                        <p>{Utilities.formatPhoneNumber(driverPhoneNumber)}</p>
                                    </a>
                                </Box>
                            </Box>
                            {
                                isJobsiteVehicle
                                    ?
                                    <Box width="50%">
                                        <span className="loading-card-font">Durée sur chantier</span>
                                        <p className="loading-card-font-p loading-card-mg-b">{unloadingDuration}</p>
                                    </Box>
                                    :
                                    <Box width="50%">
                                        <span className="loading-card-font">Tour(s) estimé(s)</span>
                                        <p className="loading-card-font-p loading-card-mg-b">{tripsCount}</p>
                                    </Box>
                            }

                        </Box>
                        <Box display="flex" flexWrap="wrap" flexDirection="row">
                            {instructions && (instructions.length > 0 || (transportersInstructions && transportersInstructions !== '')) ?
                                <Box display="flex" pt={1} flexDirection="row" flexWrap="nowrap">
                                    <Box pr={2} width="100%">
                                        <div className="loading-card-font">
                                            Instructions
                                        </div>
                                        <Box width="100%">
                                            <InstructionsComponent instructions={instructions} transportersInstructions={transportersInstructions} />
                                        </Box>
                                    </Box>
                                </Box>
                                : ''
                            }
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

