import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult } from 'src/shared/models/WebAppActionResult';
import { Level2ActivityChoiceLogisticsUnitRequestArgs } from './dataContracts/controller/Level2ActivityChoiceLogisticsUnitRequestArgs';
import { Level2ActivityChoiceLogisticsUnitsRequestArgs } from './dataContracts/controller/Level2ActivityChoiceLogisticsUnitsRequestArgs';
import { Level2ActivityChoicesExportRequestArgs } from './dataContracts/controller/Level2ActivityChoicesExportRequestArgs';
import { Level2ActivityLightModel } from './dataContracts/queryStack/Level2ActivityLightModel';
import { LogisticsUnitChoiceOfLevel2ActivitiesLightModel } from './dataContracts/queryStack/LogisticsUnitChoiceOfLevel2ActivitiesLightModel';

const controllerUrl = 'api/Level2ActivitiesReferential/';

export class Level2ActivitiesReferentialApiClient {
    public static GetLevel2Activities = (searchText: string)
        : Promise<AxiosResponse<Array<Level2ActivityLightModel>>> => {
        return axios.get(`${controllerUrl}GetLevel2Activities?searchText=${encodeURIComponent(searchText)}`);
    }

    public static GetLevel2ActivityChoicesLogisticsUnit = ()
        : Promise<AxiosResponse<Array<LogisticsUnitChoiceOfLevel2ActivitiesLightModel>>> => {
        return axios.get(`${controllerUrl}GetLevel2ActivityChoicesLogisticsUnit`);
    }

    public static GetLevel2ActivitiesWithLevel2ActivityChoicesLogisticsUnit = (searchText: string)
        : Promise<[AxiosResponse<Array<Level2ActivityLightModel>>, AxiosResponse<Array<LogisticsUnitChoiceOfLevel2ActivitiesLightModel>>]> => {
        return Promise.all(
            [
                Level2ActivitiesReferentialApiClient.GetLevel2Activities(searchText),
                Level2ActivitiesReferentialApiClient.GetLevel2ActivityChoicesLogisticsUnit()
            ]);
    }

    public static AddOrRemoveLevel2ActivityChoice = (level2ActivityChoiceLogisticsUnit: Level2ActivityChoiceLogisticsUnitRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddOrRemoveLevel2ActivityChoice',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(level2ActivityChoiceLogisticsUnit)
        });
    }

    public static AddOrRemoveLevel2ActivityChoices = (level2ActivityChoiceLogisticsUnits: Level2ActivityChoiceLogisticsUnitsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddOrRemoveLevel2ActivityChoices',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(level2ActivityChoiceLogisticsUnits)
        });
    }

    public static ExportLevel2ActivityChoices = (level2ActivityChoicesExportRequestArgs: Level2ActivityChoicesExportRequestArgs)
        : Promise<AxiosResponse<Blob>> => {
        const _headers = {
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return axios.post(controllerUrl + 'ExportLevel2ActivityChoices', level2ActivityChoicesExportRequestArgs, { headers: _headers, responseType: 'blob' });
    }

    public static GetImportUrl = (): string => {
        return controllerUrl + 'ImportFile';
    }
}
