import React from 'react';
import { TransportRequestLightModelExtended } from '../models/TransportRequestLightModelExtended';

interface StatusCellProps {
    dataItem: TransportRequestLightModelExtended,
    selectRowHandler: (dataItem: TransportRequestLightModelExtended) => void
}

export const CustomTransportRequestStatusCellComponent = (props: StatusCellProps): JSX.Element => {

    const labelValue: string = props.dataItem.status;
    const fieldValue: string = props.dataItem.statusField;
    let defaultRendering: JSX.Element = null;

    defaultRendering = (
        <td>
            <div onClick={() => props.selectRowHandler(props.dataItem)} className={fieldValue + '-transport-request'}>{labelValue}</div>
        </td>
    );
    return defaultRendering;
}