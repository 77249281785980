import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import '../../../utils/Date';
import { VehicleReservationDetailLightModel } from '../services/dataContracts/queryStack/VehicleReservationDetailLightModel';

interface ReservationDetailsComponentProps {
    weeklyTransporterVehicleReservationsDetailsArray: Array<VehicleReservationDetailLightModel>
}

export const ReservationDetailsComponent = (props: ReservationDetailsComponentProps): JSX.Element => {

    const { weeklyTransporterVehicleReservationsDetailsArray } = props;

    return (
        <Table className="details-table">
            <TableHead>
                <TableRow className="table-header-cell">
                    <TableCell className="fixed-head" align="left">Type</TableCell>
                    <TableCell className="fixed-head" align="left">Nombre</TableCell>
                    <TableCell className="fixed-head" align="left">Date</TableCell>
                    <TableCell className="fixed-head" align="left">Transporteur</TableCell>
                    <TableCell className="fixed-head" align="left">Date confirmation</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {weeklyTransporterVehicleReservationsDetailsArray.map((weeklyTransporterVehicleReservationsDetails: VehicleReservationDetailLightModel, index: number) => {
                    return (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row" className="text-nowrap table-header-cell blue-label bold">
                                {weeklyTransporterVehicleReservationsDetails.vehicleTypeLabel}
                            </TableCell>
                            <TableCell align="left">
                                <p className="">{weeklyTransporterVehicleReservationsDetails.reservedNumberOfVehicles}</p>
                            </TableCell>
                            <TableCell align="left">
                                <p>{new Date(weeklyTransporterVehicleReservationsDetails.date).toShortDateString()}</p>
                            </TableCell>
                            <TableCell align="left">
                                <p className="text-nowrap">{weeklyTransporterVehicleReservationsDetails.transporterName}</p>
                            </TableCell>
                            <TableCell align="left">
                                <p>{`${!weeklyTransporterVehicleReservationsDetails.confirmedDate ? '' : Date.getDateTimeFromIsoString(weeklyTransporterVehicleReservationsDetails.confirmedDate)}`}</p>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}