import { Box, Button, Radio } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import { DriverCandidateToAddExtended } from '../../models/DriverCandidateToAddExtended';
import { InternalDriversReferentialApiClient } from '../../services/InternalDriversReferentialApiClient';

interface SelectDriverStepComponentProperties {
    driverChoices: DriverCandidateToAddExtended[],
    handleSearchInReferentialGrid: (mgrh: string) => void,
    handleConfirmStep: (driver: DriverCandidateToAddExtended) => void,
    handlePreviousStep: () => void
}

export const SelectDriverStepComponent = (props: SelectDriverStepComponentProperties): JSX.Element => {

    const initialSort: SortDescriptor[] = [{ field: 'date', dir: 'asc' }];

    const [driverChoices, setDriverChoices] = useState<DriverCandidateToAddExtended[]>([...props.driverChoices]);
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);

    const handleDriverChoiceChanged = (checked: boolean, dataItem: DriverCandidateToAddExtended): void => {
        const driverChoicesArray: DriverCandidateToAddExtended[] = [...driverChoices];
        driverChoicesArray.forEach((item: DriverCandidateToAddExtended) => {
            item.employeeId === dataItem.employeeId ? item.selected = true : item.selected = false;
        });

        setDriverChoices(driverChoicesArray);
    }

    const handleClikAddDriver = (): void => {
        const driverToAdd: DriverCandidateToAddExtended = driverChoices.find(x => x.selected === true);

        InternalDriversReferentialApiClient.DriverExists(driverToAdd.mgrh)
            .then((res) => {
                if (res.data === true)
                    props.handleSearchInReferentialGrid(driverToAdd.mgrh);
                else
                    props.handleConfirmStep(driverToAdd);
            });
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const isAddButtonDisabled: boolean = driverChoices.every((item) => item.selected === false);

    return (
        <Box display="flex" flexDirection="column">
            <Box className="driver-choices">
                <LocalizationProvider language="fr-FR">
                    <IntlProvider locale="fr" >
                        <Grid
                            data={orderBy(driverChoices, sort)}
                            sort={sort}
                            className="driver-choices-grid"
                            sortable
                            onSortChange={handleSortChange}
                            rowHeight={40}
                        >
                            <GridColumn field="selected" title="Sélection" width={60}
                                cell={(props: GridCellProps) => <td>
                                    <Radio
                                        checked={props.dataItem.selected}
                                        onChange={(e, checked) => handleDriverChoiceChanged(checked, props.dataItem)}
                                        color="default"
                                    />
                                </td>}
                            />
                            <GridColumn field="employeeId" title="Id Employé" width={100} />
                            <GridColumn field="lastName" title="Nom de famille" width={150} />
                            <GridColumn field="firstName" title="Prénom" width={150} />
                            <GridColumn field="mgrh" title="Matricule" width={150} />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                <Button variant="contained" title="Retour" className="previous-button" onClick={props.handlePreviousStep}>
                    Retour
                </Button>
                <Button variant="contained" color="primary" title="Ajouter" onClick={handleClikAddDriver} disabled={isAddButtonDisabled} >
                    Ajouter
                </Button>
            </Box>
        </Box>
    );
}
