import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Input, Tooltip } from '@mui/material';
import React from 'react';

interface SearchWorkAgencyStepComponentProperties {
    workAgencySearchLoading: boolean,
    agencyId: string,
    handleWorkAgencyIdentifierChange: (value: string) => void,
    searchWorkAgencyByIdentifier: (agencyId: string) => void
}

export const SearchWorkAgencyStepComponent = (props: SearchWorkAgencyStepComponentProperties): JSX.Element => {

    const AddWorkAgencyTooltipImage = "/static/Referentials/WorkAgenciesReferential/AddWorkAgencyTooltipImage.png";

    const searchWorkAgency = (): void => {
        if (props.agencyId !== '')
            props.searchWorkAgencyByIdentifier(props.agencyId);
    }

    const isAgencyIdValid = (): boolean => {
        const regExp = /^UE[1-9](\d{1,7})?$/i;
        return regExp.test(props.agencyId);
    }

    const isSearchButtonDisabled: boolean = props.workAgencySearchLoading || !isAgencyIdValid();

    return (
        <Box className="search-work-agency-component">
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column" mb={"20px"}>
                    Information de l'Unité d'Exploitation Travaux à ajouter:
                </Box>
                <Box display="flex" flexDirection="column" className="work-agency-infos">
                    <Box display="flex" flexDirection="row" justifyContent="flex-start">
                        <Box display="flex" flexDirection="column" className="input-text-content">
                            <Box className="work-agency-id-input-text-label">Identifiant</Box>
                            <Box display="flex" flexDirection="row">
                                <Input
                                    id="work-agency-id"
                                    value={props.agencyId}
                                    className="work-agency-id-input-text"
                                    error={!isAgencyIdValid}
                                    onChange={(e) => props.handleWorkAgencyIdentifierChange(e.target.value)}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center" ml={"10px"}>
                            <Tooltip id="add-work-agency-tooltip-image" placement="right-start" title={
                                <Box>
                                    <p className="info-user-text">Pour récupérer l'id de l'Unité d'Exploitation Travaux, vous pouvez accéder à EDispatch sur cet écran. L'identifiant doit obligatoirement commencer par « UE »: </p>
                                    <img src={AddWorkAgencyTooltipImage} />
                                </Box>
                            }>
                                <Avatar>
                                    <FontAwesomeIcon icon={faQuestion} />
                                </Avatar>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={"20px"}>
                    <Button variant="contained" color="primary" title="Chercher" onClick={() => searchWorkAgency()} disabled={isSearchButtonDisabled}>
                        Chercher
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
