import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridRowProps, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { LogisticsUnitSelectModel } from 'src/shared/models/LogisticsUnitSelectModel';
import { AppModule, LocalStorage } from 'src/utils/Storage';
import { EditedFieldModel } from '../models/EditedFieldModel';
import { GenericPurchasePricesModel } from '../models/GenericPurchasePricesModel';
import { VehicleTypeLightModel } from '../services/dataContracts/queryStack/VehicleTypeLightModel';
import { SizingUtilities } from '../SizingUtilities';
import { CustomPriceCellComponent } from './CustomPriceCellComponent';

interface PriceModelProps {
    vehiculeTypes: VehicleTypeLightModel[],
    selectedLogisticsUnit: LogisticsUnitSelectModel,
    handlePriceGridParsed: (data: Array<GenericPurchasePricesModel>) => void,
    handleLogisticsUnitChange: (event: LogisticsUnitSelectModel) => void,
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const GenericPurchasePricesReferentialGridName = 'genericPurchasePricesReferential';
const ModuleKey = AppModule.GenericPurchasePricesReferential;

export const GenericPurchasePricesComponent = (props: PriceModelProps) => {
    let preventExit: boolean;
    let preventExitTimeout: ReturnType<typeof setTimeout>;
    let blurTimeout: ReturnType<typeof setTimeout>;

    const [gridDataArray, setGridDataArray] = useState<Array<GenericPurchasePricesModel>>([]);
    const [sort, setSort] = useState<Array<SortDescriptor>>([{ field: 'vehiculeType', dir: 'asc' }]);
    const [editField, setEditField] = useState<EditedFieldModel>(null);
    const { selectedLogisticsUnit, vehiculeTypes } = props;

    useEffect(() => {
        if (selectedLogisticsUnit) {
            prepareData();
        } else {
            setGridDataArray([])
        }
    }, [selectedLogisticsUnit, vehiculeTypes]);

    const prepareData = (): void => {
        const dataArray: Array<GenericPurchasePricesModel> = [];
        vehiculeTypes.map((elem) => {
            let element: GenericPurchasePricesModel = {
                logisticUnitId: selectedLogisticsUnit?.value,
                vehicleTypeId: elem.vehicleTypeId,
                price: elem.price,
                change: elem.price,
                label: elem.label
            };
            dataArray.push(element);
        });
        setGridDataArray(dataArray);
    }

    const forceUpdate = useForceUpdate();

    const getPriceWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, GenericPurchasePricesReferentialGridName, fieldName, columnWidth);
    }

    const getGenericPriceGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, GenericPurchasePricesReferentialGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            GenericPurchasePricesReferentialGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, GenericPurchasePricesReferentialGridName, event.columns);
        forceUpdate();
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        forceUpdate();
    }
    window.onresize = resize;

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, dataItem: GridRowProps): React.ReactElement<HTMLTableRowElement> => {
        const trProps = {
            ...trElement.props,
            onMouseDown: () => {
                preventExit = true;
                clearTimeout(preventExitTimeout);
                preventExitTimeout = setTimeout(() => { preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(blurTimeout);
                if (!preventExit) {
                    preventExit = true;
                    blurTimeout = setTimeout(() => { exitEdit(); });
                }
            },
            onFocus: () => { clearTimeout(blurTimeout); }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as any);
    }

    const cellRender = (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps): React.ReactElement<HTMLTableCellElement> => {
        const dataItem: GenericPurchasePricesModel = cellProps.dataItem;
        const field: string = cellProps.field;
        const additionalProps = (dataItem.inEdit && (cellProps.field === dataItem.inEdit)) ?
            {
                ref: (td: any) => {
                    const input = td && td.querySelector('input');
                    if (!input || (input === document.activeElement)) {
                        return;
                    }
                    if (input.type === 'checkbox') {
                        input.focus();
                    } else {
                        input.select();
                    }
                }
            } : {
                onClick: () => { enterEdit(dataItem, field); }
            };
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children as any);
    }

    const enterEdit = (dataItem: GenericPurchasePricesModel, field: string) => {
        if (dataItem.inEdit && field === editField.field && editField.id === dataItem.vehicleTypeId)
            return;

        exitEdit();
        dataItem.inEdit = field;
        setEditField({
            field: field,
            id: dataItem.vehicleTypeId
        });
    };

    const exitEdit = () => {
        let wasChanged = false;
        gridDataArray.forEach(dataItem => {
            if (dataItem.inEdit !== undefined) {
                wasChanged = true;
            }
            dataItem.inEdit = undefined;
        });

        setEditField(null);

        if (!wasChanged)
            return;

        props.handlePriceGridParsed(gridDataArray);
    };

    const gridData: GenericPurchasePricesModel[] = orderBy(gridDataArray, sort);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="grid-content">
            <Grid
                className="grid"
                data={gridData}
                sortable
                reorderable
                resizable
                sort={sort}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={handleSortChange}
                editField="inEdit"
                rowHeight={rowHeight}
                rowRender={rowRender}
                cellRender={cellRender}
                style={gridStyle}>
                <GridColumn field="empty" width={50} title=" " sortable={false} reorderable={false} editable={false} />
                <GridColumn
                    headerClassName="cell-center"
                    className="cell-center"
                    field="label"
                    title="Type du véhicule"
                    orderIndex={getGenericPriceGridOrderIndexColumn("vehiculeType", 1)}
                    width={getPriceWidth("vehiculeType", 200)}
                    sortable={true}
                    reorderable={true}
                    editable={false} />
                <GridColumn
                    headerClassName="cell-center"
                    className="cell-center"
                    field="price"
                    orderIndex={getGenericPriceGridOrderIndexColumn("price", 2)}
                    width={getPriceWidth("price", 120)}
                    title="Coût"
                    sortable={true}
                    reorderable={true}
                    editable={false}
                    cell={(props: GridCellProps) => <CustomPriceCellComponent isReadOnly={false} {...props} />} />
            </Grid>
        </Box >
    )
}