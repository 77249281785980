import { Checkbox, FormControlLabel } from '@mui/material';
import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import { PlanningVehiclesPublishedCostsLineModel } from '../models/PlanningVehiclesPublishedCostsLineModel';

interface CustomCostsAreAgreedCellComponentProps extends GridCellProps {
    changeHandler: (dataItem: PlanningVehiclesPublishedCostsLineModel) => void
}

export const CustomCostsAreAgreedCellComponent = (props: CustomCostsAreAgreedCellComponentProps): JSX.Element => {
    let defaultRendering: JSX.Element = null;
    if (!props.dataItem.vehicleLine) {
        defaultRendering = (
            <td></td>
        );
    } else {

        const costsAreAgreed = props.dataItem.costsAreAgreed;
        const className = costsAreAgreed === true ? "costs-agreed-checkbox" : (costsAreAgreed == false ? "costs-not-agreed-checkbox" : "");
        const checked = costsAreAgreed === true ? true : (costsAreAgreed == false ? false : null);
        const indeterminate = costsAreAgreed === null ? true : false;
        const label = costsAreAgreed === true ? "OK" : (costsAreAgreed == false ? "KO" : "");

        defaultRendering = (
            <td className={className}>
                <FormControlLabel className="costs-checkbox"
                    control={
                        <Checkbox
                            checked={checked}
                            indeterminate={indeterminate}
                            onChange={() => props.changeHandler(props.dataItem)}
                        />
                    }
                    label={label}
                />
            </td>
        );
    }

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}
