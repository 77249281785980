import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import ToastService from 'src/ToastService';
import BusinessErrors from 'src/utils/BusinessErrors';
import Utilities from 'src/utils/Utilities';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { SizingUtilities } from '../../SizingUtilities';
import { LogisticsUnitLogisticianLightModelExtended } from '../models/LogisticsUnitLogisticianLightModelExtended';
import { UpdateLogisticsUnitLogisticianRequestArgs } from '../services/dataContracts/controller/UpdateLogisticsUnitLogisticianRequestArgs';
import { LogisticsUnitLogisticiansReferentialApiClient } from '../services/LogisticsUnitLogisticiansReferentialApiClient';

interface LogisticiansComponentProps {
    selectedLogisticsUnitId: string,
    logisticians: Array<LogisticsUnitLogisticianLightModelExtended>,
    sort: SortDescriptor[],
    handleSortChange: (e: GridSortChangeEvent) => void,
    handleSortColumnChange: (sortItems: SortDescriptor[]) => void,
    handleSelectedLogistician: (userName: string, userEmail: string, userPhoneNumber: string, selected: boolean) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const logisticiansGridName = 'logisticiansReferential';
const ModuleKey = AppModule.LogisticsUnitLogiticiansReferential;

export const LogisticiansComponent = (props: LogisticiansComponentProps): JSX.Element => {

    const [skip, setSkip] = useState<number>(0);
    const [logisticiansMap] = useState<Map<string, LogisticsUnitLogisticianLightModelExtended>>(new Map(props.logisticians.map((l) => [l.userName, l])));

    const forceUpdate = useForceUpdate();

    const getLogisticiansWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, logisticiansGridName, fieldName, columnWidth);
    }

    const getLogisticiansGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, logisticiansGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            logisticiansGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, logisticiansGridName, event.columns);
        forceUpdate();
    }

    const handleRowClick = (event: GridRowClickEvent): void => {
        props.handleSelectedLogistician(event.dataItem.userName, event.dataItem.email, event.dataItem.phoneNumber, !event.dataItem.selected);
    }

    const updateLogisticsUnitLogistician = (userName: string, isBccRecipient?: boolean, isQuotationAdministrator?: boolean): void => {
        const requestArgs: UpdateLogisticsUnitLogisticianRequestArgs = {
            logisticsUnitId: props.selectedLogisticsUnitId,
            logisticianUserName: userName,
            isBccRecipient: isBccRecipient,
            isQuotationAdministrator: isQuotationAdministrator
        };

        LogisticsUnitLogisticiansReferentialApiClient.UpdateLogisticsUnitLogistician(requestArgs)
            .then(res => {
                const errors = BusinessErrors.Get(res.data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                }
                else {
                    const currentLogistician = logisticiansMap.get(userName);
                    if (isBccRecipient !== null) {
                        currentLogistician.isBccRecipient = !currentLogistician.isBccRecipient;
                    }
                    else {
                        currentLogistician.isQuotationAdministrator = !currentLogistician.isQuotationAdministrator;
                    }

                    forceUpdate();
                }
            });

    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = logisticiansMap.size;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: LogisticsUnitLogisticianLightModelExtended[] = orderBy([...logisticiansMap.values()], props.sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="colas_logisticians-grid">
            <Grid
                className="logisticians-grid"
                data={gridData}
                selectedField="selected"
                sortable
                reorderable
                resizable
                sort={props.sort}
                onRowClick={e => handleRowClick(e)}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={props.handleSortChange}
                editField="inEdit"
                rowHeight={rowHeight}
                scrollable="virtual"
                skip={skip}
                total={totalGrid}
                pageSize={gridPageSize}
                onPageChange={pageChange}
                style={gridStyle}
            >
                <GridColumn field="empty" orderIndex={0} title=" " width={10} resizable={false} reorderable={false} />
                <GridColumn field="lastName" orderIndex={getLogisticiansGridOrderIndexColumn("lastName", 1)} width={getLogisticiansWidth("lastName", 120)} title="Nom Logisticien"
                    cell={(properties: GridCellProps) =>
                        <td className={!properties.dataItem.isEnabled ? "cell-disabled" : ""}>
                            {properties.dataItem.lastName}
                        </td>}
                />
                <GridColumn field="firstName" orderIndex={getLogisticiansGridOrderIndexColumn("firstName", 2)} width={getLogisticiansWidth("firstName", 120)} title="Prénom Logisticien"
                    cell={(properties: GridCellProps) =>
                        <td className={!properties.dataItem.isEnabled ? "cell-disabled" : ""}>
                            {properties.dataItem.firstName}
                        </td>}
                />
                <GridColumn field="phoneNumber" orderIndex={getLogisticiansGridOrderIndexColumn("phoneNumber", 3)} width={getLogisticiansWidth("phoneNumber", 90)} title="Téléphone"
                    cell={(properties: GridCellProps) =>
                        <td className={`phone-number-ref${!properties.dataItem.isEnabled ? " cell-disabled" : ""}`}>
                            {properties.dataItem.phoneNumber && <a href={`callTo:${properties.dataItem.phoneNumber}`} data-rel="external">
                                {Utilities.formatPhoneNumber(properties.dataItem.phoneNumber)}
                            </a>}
                        </td>}
                />
                <GridColumn field="userName" orderIndex={getLogisticiansGridOrderIndexColumn("userName", 4)} width={getLogisticiansWidth("userName", 220)} title="Email"
                    cell={(properties: GridCellProps) =>
                        <td className={!properties.dataItem.isEnabled ? "cell-disabled" : ""}>
                            {properties.dataItem.userName}
                        </td>}
                />
                <GridColumn className="" field="isBccRecipient" orderIndex={getLogisticiansGridOrderIndexColumn("isBccRecipient", 5)} width={getLogisticiansWidth("isBccRecipient", 110)} title="Copie cachée email"
                    cell={(properties: GridCellProps) =>
                        <td className="checkbox-cell">
                            <input type="checkbox" className="custom-checkbox" checked={properties.dataItem?.isBccRecipient}
                                onChange={() => updateLogisticsUnitLogistician(properties.dataItem?.userName, !properties.dataItem?.isBccRecipient, null)} />
                        </td>}
                />
                <GridColumn field="isQuotationAdministrator" orderIndex={getLogisticiansGridOrderIndexColumn("isQuotationAdministrator", 6)} width={getLogisticiansWidth("isQuotationAdministrator", 200)} title="Accès paramétrage tarif de vente"
                    cell={(properties: GridCellProps) =>
                        <td className="checkbox-cell">
                            <input type="checkbox" className="custom-checkbox" checked={properties.dataItem?.isQuotationAdministrator}
                                onChange={() => updateLogisticsUnitLogistician(properties.dataItem?.userName, null, !properties.dataItem?.isQuotationAdministrator)} />
                        </td>}
                />
                <GridColumn field="isEnabled" orderIndex={getLogisticiansGridOrderIndexColumn("isEnabled", 7)} width={getLogisticiansWidth("isEnabled", 90)} title="Statut"
                    cell={(properties: GridCellProps) =>
                        <td className={!properties.dataItem.isEnabled ? "cell-disabled" : ""}>
                            {properties.dataItem.isEnabled ? "Actif" : "Inactif"}
                        </td>}
                />
                <GridColumn field="hasSignature" orderIndex={getLogisticiansGridOrderIndexColumn("hasSignature", 8)} width={getLogisticiansWidth("hasSignature", 140)} title="Signature personnalisée"
                    cell={(properties: GridCellProps) =>
                        <td>
                            {properties.dataItem.hasSignature ? <FontAwesomeIcon icon={faCheck} /> : ''}
                        </td>}
                />
            </Grid>
        </Box>
    );
}
